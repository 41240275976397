import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { STEP, TEST_SCENARIOS } from "../../../../Constants";
import { showActionStep, showSubStep } from "../../../../Util";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import RecTestStepPlayActions from "../../StepInnerComponents/RecTestStepListHeader/ActionsComponents/RecTestStepPlayActions";
import RecTestStepMoreActions from "./RecTestStepMoreActions/RecTestStepMoreActions";
import RecTestStepAddActions from "../../StepInnerComponents/RecTestStepListHeader/ActionsComponents/RecTestStepAddActions";
import RecTestStepSubSteps from "../../StepInnerComponents/RecTestStepListHeader/ActionsComponents/RecTestStepSubSteps";
import styles from "./HoverActionsOnRecTestStep.module.scss";

const HoverActionsOnRecTestStep = (props) => {
  const {
    recTestStep,
    childStepDisabled,
    parentSkipStep,
    disabled,
    projectId,
    stepName,
    setMenuVisible,
    menuVisible,
    ignoreFailureDisable,
    actionMenuVisible,
    setActionMenuVisible,
    generalActionMenuVisible,
    setGeneralActionMenuVisible,
  } = props;
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedScenarioPlatform } = useSelector((state) => state.RecTestStepReducer);

  const isForEmptyStep = recTestStep?.recTestSteps?.length === 0 && recTestStep?.recTestStepProperty?.action === STEP;
  const subActionStep = recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action !== STEP) ? true : false;
  const actionStep = showActionStep(recTestStep, subActionStep);
  const isAddSubSteps = showSubStep(recTestStep, actionStep) && !subActionStep;
  const isSubHeaderActions = subActionStep || isForEmptyStep;
  const isTestBlockInTestScenario = stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1;
  const isSubTestBlockInTestScenario = stepType === TEST_SCENARIOS && recTestStep?.parentRecTestStepId;
  const importStep =
    recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
    (_.isEmpty(recTestStep?.recTestSteps) ? true : false);
  return (
    <div className={`${styles.hoverActionIcons} ${props.stepNo > 3 ? "hide_in_mobile" : ""}`}>
      <>
        {isAddSubSteps && stepName?.length < 7 && !isTestBlockInTestScenario && (
          <RecTestStepSubSteps projectId={projectId} stepType={stepType} recTestStep={recTestStep} recTestStepOnHover />
        )}
        {isSubHeaderActions && stepName?.length < 7 && !isTestBlockInTestScenario && (
          <RecTestStepAddActions
            disabled={disabled}
            projectId={props.projectId}
            actionStep={actionStep}
            platform={selectedScenarioPlatform}
            recTestStep={recTestStep}
            actionMenuVisible={actionMenuVisible}
            setActionMenuVisible={setActionMenuVisible}
            generalActionMenuVisible={generalActionMenuVisible}
            setGeneralActionMenuVisible={setGeneralActionMenuVisible}
            recTestStepOnHover
          />
        )}
        {!isSubTestBlockInTestScenario && (
          <>
            <RecTestStepPlayActions
              disabled={disabled}
              projectId={props.projectId}
              recTestStepOnHover
              recTestStep={recTestStep}
            />
            <RecTestStepMoreActions
              recTestStep={recTestStep}
              projectId={projectId}
              actionStep={actionStep}
              childStepDisabled={childStepDisabled}
              parentSkipStep={parentSkipStep}
              stepName={stepName}
              isAddSubSteps={isAddSubSteps}
              isSubHeaderActions={isSubHeaderActions}
              isForEmptyStep={isForEmptyStep}
              subActionStep={subActionStep}
              setMenuVisible={setMenuVisible}
              isTestBlockInTestScenario={isTestBlockInTestScenario}
              menuVisible={menuVisible}
              ignoreFailureDisable={ignoreFailureDisable}
              platform={selectedScenarioPlatform}
              importStep={importStep}
            />
            <VerticalLine className={styles.borderRight} />
          </>
        )}
      </>
    </div>
  );
};

export default HoverActionsOnRecTestStep;
