import { Button, Checkbox, Col, Drawer, Input, Row, Select, Space, Tooltip } from "antd";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchListOfMember } from "../../../actions/CommonActions";
import { APPLY_FOR, CUSTOM, EXECUTION_JOB, privacyOption, REMOVE_FOR, TOOLTIP_BG_COLOR } from "../../../Constants";
import _ from "lodash";
import TableComponent from "../TableComponent/TableComponent";
import SaveResetButton from "../SaveResetComponent/SaveResetButton";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { DELETE_ICON } from "../../../CdnImagePath";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const ButtonContainer = styled.div`
  float: right;
  .ant-btn-primary {
    background: #cbcde3;
    color: #111548;
  }
  .ant-btn-primary[disabled] {
    background: #e6e6e6;
    color: #6e7282;
  }
`;

const { Option } = Select;

const PermissionDrawer = (props) => {
  const {
    openPermissionDrawer,
    onClose,
    permissionData,
    savePermission,
    members,
    previousPermission,
    setPermissionData,
    notShowExecutionCheck,
    ownerEmailId,
    drawerTitle,
  } = props;

  const dispatch = useDispatch();
  const { memberList } = useSelector((state) => state.CommonReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const [applyFor, setApplyFor] = useState([]);
  const [removeFor, setRemoveFor] = useState([]);
  const [applyForOption, setApplyForOption] = useState([]);
  const [removeForOption, setRemoveForOption] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);
  const [isRemoveAddNew, setRemoveIsAddNew] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchRemoveMember, setSearchRemoveMember] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchedRemoveMemberColumn, setSearchedRemoveMemberColumn] = useState("");
  const searchInput = useRef(null);

  const isDisable = (emailId, isAdmin) => emailId === ownerEmailId || isAdmin;
  const isRemoveDisable = (emailId, isView) =>
    emailId === ownerEmailId || isView || loggedInUserDetails?.userEmail === emailId;

  const handleApplyMembersSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleApplyMembersReset = (clearFilters) => {
    clearFilters({
      confirm: true,
      closeDropdown: true,
    });
    setSearchText("");
    setSearchedColumn("");
  };

  const getApplyColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleApplyMembersReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleRemoveMembersSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchRemoveMember(selectedKeys[0]);
    setSearchedRemoveMemberColumn(dataIndex);
  };

  const handleRemoveMembersReset = (clearFilters) => {
    clearFilters({
      confirm: true,
      closeDropdown: true,
    });
    setSearchRemoveMember("");
    setSearchedRemoveMemberColumn("");
  };

  const getRemoveColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleRemoveMembersSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleRemoveMembersSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleRemoveMembersReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedRemoveMemberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchRemoveMember]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const applyMembersColumns = [
    { title: "Email", dataIndex: "email", key: "email", ...getApplyColumnSearchProps("email") },
    { title: "Admin", dataIndex: "admin", align: "center" },
    { title: "View", dataIndex: "view", align: "center" },
    { title: "Edit", dataIndex: "edit", align: "center" },
    { title: "Delete", dataIndex: "delete", align: "center" },
    { title: "Execution", dataIndex: "execution", hidden: notShowExecutionCheck, align: "center" },
    { title: "", dataIndex: "action", align: "center" },
  ]?.filter((item) => !item.hidden);

  const removeMembersColumns = [
    { title: "Email", dataIndex: "email", key: "email", ...getRemoveColumnSearchProps("email") },
    { title: "Admin", dataIndex: "admin", align: "center" },
    { title: "View", dataIndex: "view", align: "center" },
    { title: "Edit", dataIndex: "edit", align: "center" },
    { title: "Delete", dataIndex: "delete", align: "center" },
    { title: "Execution", dataIndex: "execution", hidden: notShowExecutionCheck, align: "center" },
    { title: "", dataIndex: "action", align: "center" },
  ]?.filter((item) => !item.hidden);

  useEffect(() => {
    if (openPermissionDrawer) {
      dispatch(fetchListOfMember());
      setRemoveForOption([]);
      setApplyForOption([]);
      setIsAddNew(false);
      setRemoveIsAddNew(false);
    }
  }, [openPermissionDrawer]);

  useEffect(() => {
    setApplyFor(
      members
        .filter((i) => i?.shouldAddOrRemove === 1)
        .map((i) =>
          i?.userId === loggedInUserDetails?.userID
            ? { ...i, isEdit: 1, isDelete: 1, isView: 1, isAdmin: 1 }
            : i && !notShowExecutionCheck && i?.userId === loggedInUserDetails?.userID
            ? { ...i, isEdit: 1, isDelete: 1, isView: 1, isAdmin: 1, isExec: 1 }
            : i
        )
    );
    setRemoveFor(members.filter((i) => i?.shouldAddOrRemove === 0).map((i) => i));
  }, [members]);

  useEffect(() => {
    setPermissionData();
  }, [permissionData]);

  const handleCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleCheckObj = { ...applyFor[i], [name]: checked ? 1 : 0, isView: 1 };
    let handleCheckArray = applyFor?.map((i) => (i?.userId === handleCheckObj?.userId ? handleCheckObj : i));
    setApplyFor(handleCheckArray);
  };

  const handleViewCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleViewObj = { ...applyFor[i], [name]: checked ? 1 : 0, isEdit: 0, isDelete: 0, isExec: 0 };
    let handleViewArray = applyFor?.map((i) => (i?.userId === handleViewObj?.userId ? handleViewObj : i));
    setApplyFor(handleViewArray);
  };

  const handleAdminCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleAdminObj = { ...applyFor[i], [name]: checked ? 1 : 0, isView: 1, isEdit: 1, isDelete: 1, isExec: 1 };
    let handleAdminArray = applyFor?.map((i) => (i?.userId === handleAdminObj?.userId ? handleAdminObj : i));
    setApplyFor(handleAdminArray);
  };

  const handleRemoveMemberCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleCheckObj = { ...removeFor[i], [name]: checked ? 1 : 0 };
    let handleCheckArray = removeFor?.map((i) => (i?.userId === handleCheckObj?.userId ? handleCheckObj : i));
    setRemoveFor(handleCheckArray);
  };
  const handleRemoveViewCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleCheckObj = { ...removeFor[i], [name]: checked ? 1 : 0, isAdmin: 1, isEdit: 1, isDelete: 1, isExec: 1 };
    let handleCheckArray = removeFor?.map((i) => (i?.userId === handleCheckObj?.userId ? handleCheckObj : i));
    setRemoveFor(handleCheckArray);
  };

  const handleSelectNewApplyMemberList = (e) => {
    setIsAddNew(false);
    const MemberUserId = memberList?.find((i) => i?.email === e)?.userId;
    const isChecked = MemberUserId === loggedInUserDetails?.userID || e === ownerEmailId ? 1 : 0;
    setApplyFor([
      ...applyFor,
      {
        userId: MemberUserId,
        email: e,
        isAdmin: isChecked,
        isView: isChecked,
        isEdit: isChecked,
        isDelete: isChecked,
        isExec: isChecked,
        shouldAddOrRemove: 1,
      },
    ]);
    const validApplyMember = removeFor?.filter((i) => i?.userId !== MemberUserId);
    setRemoveFor(validApplyMember);
  };

  const addForApplyMember = () => {
    setIsAddNew(true);
    const membersListFilter = memberList?.map((i) => i?.email);
    const ApplyMembers = membersListFilter?.filter((i) => !applyFor?.some((j) => j?.email === i));
    setApplyForOption(ApplyMembers);
  };
  const handleSelectNewRemoveMemberList = (e, i) => {
    setRemoveIsAddNew(false);
    const MemberUserId = memberList?.find((i) => i?.email === e)?.userId;
    const isChecked = MemberUserId === loggedInUserDetails?.userID || e === ownerEmailId ? 1 : 0;
    setRemoveFor([
      ...removeFor,
      {
        userId: MemberUserId,
        email: e,
        isAdmin: isChecked,
        isView: isChecked,
        isEdit: isChecked,
        isDelete: isChecked,
        isExec: isChecked,
        shouldAddOrRemove: 0,
      },
    ]);
    const validApplyMember = applyFor?.filter((i) => i?.userId !== MemberUserId);
    setApplyFor(validApplyMember);
  };

  const addForRemove = () => {
    setRemoveIsAddNew(true);
    const membersListFilter = memberList?.map((i) => i?.email);
    const removeMembers = membersListFilter?.filter((i) => !removeFor?.some((j) => j?.email === i));
    setRemoveForOption(removeMembers);
  };

  const handleDeleteApplyMember = (row) => {
    const removeForMembers = removeFor?.filter((i) => i !== row);
    setRemoveFor(removeForMembers);
  };

  const handleDeleteMember = (row) => {
    const applyForForMembers = applyFor?.filter((i) => i !== row);
    setApplyFor(applyForForMembers);
  };

  const RemoveMemberListDetails = removeFor && [
    ...removeFor?.map((row, j) => ({
      key: row?.userId,
      email: ownerEmailId === row?.email ? row?.email + ` (Owner)` : row?.email,
      admin: (
        <Checkbox
          checked={row?.isAdmin}
          name="isAdmin"
          onChange={(e) => handleRemoveMemberCheck(e, j)}
          disabled={isRemoveDisable(row?.email, row?.isView)}
        />
      ),
      view: (
        <Checkbox
          checked={row?.isView}
          name="isView"
          onChange={(e) => handleRemoveViewCheck(e, j)}
          disabled={ownerEmailId === row?.email || loggedInUserDetails?.userEmail === row?.email}
        />
      ),
      edit: (
        <Checkbox
          checked={row?.isEdit}
          name="isEdit"
          onChange={(e) => handleRemoveMemberCheck(e, j)}
          disabled={isRemoveDisable(row?.email, row?.isView)}
        />
      ),
      delete: (
        <Checkbox
          checked={row?.isDelete}
          name="isDelete"
          onChange={(e) => handleRemoveMemberCheck(e, j)}
          disabled={isRemoveDisable(row?.email, row?.isView)}
        />
      ),
      execution: (
        <Checkbox
          checked={row?.isExec}
          name="isExec"
          onChange={(e) => handleRemoveMemberCheck(e, j)}
          disabled={isRemoveDisable(row?.email, row?.isView)}
        />
      ),
      action: (
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
          <img
            src={DELETE_ICON}
            alt="Delete"
            className="pr-10 mt-less-3 cursor-pointer"
            onClick={() => handleDeleteApplyMember(row)}
          />
        </Tooltip>
      ),
    })),
    !!isRemoveAddNew && {
      email: (
        <div className="font-color-darkBlue p-0">
          <Select className="w-100" placeholder="Select Members" onChange={(e) => handleSelectNewRemoveMemberList(e)}>
            {removeForOption &&
              removeForOption?.map((i, k) => {
                return (
                  <Option value={i} key={k}>
                    {i}
                  </Option>
                );
              })}
          </Select>
        </div>
      ),
    },
  ];

  const ApplyMemberListDetails = applyFor && [
    ...applyFor?.map((row, j) => ({
      key: row?.userId,
      email: ownerEmailId === row?.email ? row?.email + ` (Owner)` : row?.email,
      admin: (
        <Checkbox
          checked={row?.isAdmin}
          name="isAdmin"
          onChange={(e) => handleAdminCheck(e, j)}
          disabled={ownerEmailId === row?.email || loggedInUserDetails?.userEmail === row?.email}
        />
      ),
      view: (
        <Checkbox
          checked={row?.isView}
          name="isView"
          onChange={(e) => handleViewCheck(e, j)}
          disabled={isDisable(row?.email, row?.isAdmin)}
        />
      ),
      edit: (
        <Checkbox
          checked={row?.isEdit}
          name="isEdit"
          onChange={(e) => handleCheck(e, j)}
          disabled={isDisable(row?.email, row?.isAdmin)}
        />
      ),
      delete: (
        <Checkbox
          checked={row?.isDelete}
          name="isDelete"
          onChange={(e) => handleCheck(e, j)}
          disabled={isDisable(row?.email, row?.isAdmin)}
        />
      ),
      execution: (
        <Checkbox
          checked={row?.isExec}
          name="isExec"
          onChange={(e) => handleCheck(e, j)}
          disabled={isDisable(row?.email, row?.isAdmin)}
        />
      ),
      action: (
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
          <img
            src={DELETE_ICON}
            alt="Delete"
            className="pr-10 mt-less-3 cursor-pointer"
            onClick={() => handleDeleteMember(row)}
          />
        </Tooltip>
      ),
    })),
    !!isAddNew && {
      email: (
        <div className="font-color-darkBlue p-0">
          <Select className="w-100" placeholder="Select Members" onChange={(e) => handleSelectNewApplyMemberList(e)}>
            {applyForOption &&
              applyForOption?.map((i, k) => {
                return (
                  <Option value={i} key={k}>
                    {i}
                  </Option>
                );
              })}
          </Select>
        </div>
      ),
    },
  ];

  return (
    <>
      <Drawer
        title={
          <Row justify="space-between" align="middle">
            <Col span={20}>
              <Row>
                <Col>{drawerTitle}: </Col>
                <Col span={18} className="font-size-14 font-weight-500 ml-2">
                  {permissionData?.name || permissionData?.displayName}
                </Col>
              </Row>
              <div>
                Rights:{" "}
                <span className="font-size-14 font-weight-500">
                  {permissionData?.isPrivate === 1 ? privacyOption[0] : privacyOption[1]}
                </span>
              </div>
            </Col>

            <Col span={4}>
              <SaveResetButton
                submitLabel={"Save"}
                resetLabel={"Reset"}
                submitDisable={_.isEqual([...applyFor, ...removeFor], previousPermission)}
                resetDisable={_.isEqual([...applyFor, ...removeFor], previousPermission)}
                handleSubmit={() => savePermission(CUSTOM, permissionData, [...applyFor, ...removeFor])}
                handleReset={setPermissionData}
              />
            </Col>
          </Row>
        }
        visible={openPermissionDrawer}
        onClose={onClose}
        placement="right"
        width={"736"}
      >
        <div className="display-flex justify-space-between">
          <div className="mt-5 ml-10 font-weight-500 text-color font-size-16">{APPLY_FOR}</div>
          <ButtonContainer>
            <Button
              type="primary"
              onClick={() => addForApplyMember()}
              disabled={memberList?.length === applyFor?.length || !_.isEmpty(searchText)}
            >
              Add
            </Button>
          </ButtonContainer>
        </div>
        <div className="mt-20">
          <TableComponent
            columns={applyMembersColumns}
            data={ApplyMemberListDetails?.filter(Boolean)}
            isScrollable={true}
            isExecution={stepType === EXECUTION_JOB ? true : false}
          />
        </div>
        <div className="display-flex justify-space-between mt-35">
          <div className="mt-5 ml-10 font-weight-500 text-color font-size-16">{REMOVE_FOR}</div>
          <ButtonContainer>
            <Button
              type="primary"
              onClick={() => addForRemove()}
              disabled={memberList?.length === removeFor?.length || !_.isEmpty(searchRemoveMember)}
            >
              Add
            </Button>
          </ButtonContainer>
        </div>
        <div className="mt-20">
          <TableComponent
            columns={removeMembersColumns}
            data={RemoveMemberListDetails?.filter(Boolean)}
            isScrollable={true}
            isExecution={stepType === EXECUTION_JOB ? true : false}
          />
        </div>
      </Drawer>
    </>
  );
};

export default PermissionDrawer;
