import React from "react";
import { Dropdown, Menu } from "antd";
import { FILTER_ICON_V2 } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./FilterComponentV2.module.scss";

const FilterComponentV2 = ({ filterList, handleFilterChange, selectedFilter, string }) => {
  const menu = (
    <Menu
      className={string ? styles["filter_List_Scroll"] : styles["filter_Menu"]}
      onClick={(e) => handleFilterChange(e.key)}
    >
      <Menu.Item className={styles["filter_Submenu_title"]}>
        <span className={styles["filter_Text"]}>Filter</span>
      </Menu.Item>
      {filterList?.map((s, k) => {
        return (
          <Menu.Item key={string ? s.locales : s.name} value={string ? s.displayName : s.name}>
            {string ? `${s.locales} ${s.locales && "- "}${s.displayCountry}` : s.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <span>
      <Dropdown overlay={menu}>
        <span className={styles["filter_Button"]}>
          <SvgLoader iconName={FILTER_ICON_V2} />
          {selectedFilter ? selectedFilter : string ? "All" : "Filter"}
        </span>
      </Dropdown>
    </span>
  );
};

export default FilterComponentV2;
