import { Row, Col, Spin, Radio } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeReportResultTab, getRunResultDetailsByRunNumber } from "../../../actions/TestPlanAction";
import TestPlanResultDetailsList from "./TestPlanResultDetailsList";
import TestPlanResultViewManager from "./ReportManagerView/TestPlanResultViewManager";
import {
  DEVICE_VIEW,
  EXECUTION_VIEW,
  LOCALE_VIEW,
  SCENARIO_LOADING,
  SCENARIO_VIEW,
  TEST_PLAN_RESULT_TYPE,
} from "../../../Constants";
import { isNullOrUndefinedOrEmpty } from "../../../Util";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import TestPlanResultDetailsPreview from "./PreviewDownload/TestPlanResultDetailsPreview";
import TestPlanResultDetailStepList from "./TestPlanResultDetailStepList";
import { getDeviceFarmList } from "../../../actions/ServerListAction";
import TestPlanResultViewManagerV2 from "../../TestPlanV2/TestPlanResultDetailsV2/ReportManagerViewV2/TestPlanResultViewManagerV2";
import ParentTestPlanResultDetailsStepV2 from "../../TestPlanV2/TestPlanResultDetailsV2/ParentTestPlanResultDetailsStepV2";

export const HeadingRow = styled(Row)`
  font-size: larger;
  align-items: center;
`;

export const StepsHeadingRow = styled(Row)`
  width: 98.5%;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  color: #65676b;
  border: 1px solid #80808047;
  background-color: #e7e9eb;
`;

export const SubStepsHeadingRow = styled(Row)`
  width: 98.4%;
  padding: 10px;
  font-size: 13px;
  font-weight: 600;
  margin-left: 10px;
  border-radius: 5px;
  display: flex;
  color: #65676b;
  border: 1px solid #80808047;
  background-color: #e7e9eb;
`;

export const StepsHeadingCol = styled(Col)`
  width: 30.6%;
  font-size: 13px;
  height: auto;
  color: #65676b7d;
`;

export const StepsHeadingIndexCol = styled(Col)`
  width: 5%;
  font-size: 13px;
  height: auto;
  color: #65676b7d;
`;

export const TestPlanRow = styled(Row)`
  margin: 12px;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  color: ${(props) => (props.selected ? "white" : "#65676b")};
  background-color: ${(props) => (props.selected ? "#338fcc" : "")};
  cursor: ${(props) => (props.selectView ? "pointer" : "")};
`;

export const VideoTextHeaderRow = styled(Row)`
  width: 98%;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  color: #000000a6;
  background-color: "#338fcc";
`;

export const PanelCol = styled(Col)`
  padding: 2px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const VideoStyled = styled.video`
  align-items: center;
  justify-content: center;
  border: "1px solid grey";
`;

export const TestPlanResultDetailsScenario = styled.div`
  margin-top: 20px;
  @media (min-width: 575px) {
    margin-left: 20px;
    margin-right: 20px;
  }
  .ant-collapse-item {
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 8%);
  }
  border: 0px solid #f7f8fa;
`;

var timer_testPlanChange;
const TestPlanResultDetails = (props) => {
  const { runNumber, orgId, projectId } = props;
  const [scenarioDetails, setScenarioDetails] = useState([]);

  const dispatch = useDispatch();
  const {
    runResultDetails,
    scenarioLoading,
    testPlanResultDetails,
    selectedReportTab,
    selectedRunResultView,
    testBlockResultDataByRunId,
  } = useSelector((state) => state.TestPlanReducer);

  const isTestBlock = runResultDetails?.find((i) => i)?.isTestBlock;
  useEffect(() => {
    const result = [];
    if (testPlanResultDetails && testPlanResultDetails[0]?.scenarios) {
      testPlanResultDetails &&
        testPlanResultDetails[0].scenarios.forEach((element) => {
          result.push(element?.devices?.map((i) => ({ ...i, scenarios: [i?.scenarios] })));
        });
    }
    setScenarioDetails(_.flatten(result));
  }, [testPlanResultDetails]);

  useEffect(() => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    clearTimeout(timer_testPlanChange);
    setTimeout(() => {
      dispatch(getRunResultDetailsByRunNumber({ runNumber: runNumber }, () => {}, projectId));
      if (
        !_.isEmpty(runResultDetails) &&
        (runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.IN_PROGRESS ||
          runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.IN_QUEUE ||
          runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.PREPARING_RUN_TO_EXECUTE ||
          runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.ASSIGNED_TO_DEVICES)
      ) {
        loadTestPlanStepList();
      }
    }, 2000);
  }, [selectedRunResultView]);

  useEffect(() => {
    dispatch({ type: SCENARIO_LOADING, response: { isLoading: true } });
    dispatch(getDeviceFarmList({ projectId: projectId }));
  }, []);

  useEffect(() => {
    if (
      !_.isEmpty(runResultDetails) &&
      (runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.IN_PROGRESS ||
        runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.IN_QUEUE ||
        runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.PREPARING_RUN_TO_EXECUTE ||
        runResultDetails[0].state === TEST_PLAN_RESULT_TYPE?.ASSIGNED_TO_DEVICES)
    ) {
      loadTestPlanStepList();
    }
  }, [runResultDetails]);

  const loadTestPlanStepList = () => {
    if (timer_testPlanChange || timer_testPlanChange === undefined) {
      timer_testPlanChange && clearTimeout(timer_testPlanChange);
      timer_testPlanChange = setTimeout(() => {
        getRunResultDetailsByRunNumberApiCall();
      }, 3000);
    }
  };

  const getRunResultDetailsByRunNumberApiCall = () => {
    dispatch(
      getRunResultDetailsByRunNumber(
        { runNumber: runNumber },
        () => {
          loadTestPlanStepList();
        },
        projectId
      )
    );
  };

  useEffect(() => {
    return () => {
      if (timer_testPlanChange) {
        clearTimeout(timer_testPlanChange);
        timer_testPlanChange = null;
      }
    };
  }, []);

  const testPlanResultDetail = {
    [EXECUTION_VIEW]: isTestBlock === 1 ? testPlanResultDetails : runResultDetails,
    [DEVICE_VIEW]: testPlanResultDetails?.[0]?.devices,
    [SCENARIO_VIEW]: scenarioDetails,
    [LOCALE_VIEW]: scenarioDetails,
  }[selectedRunResultView];

  return (
    <>
      <Row className="w-100">
        <Col span={24} className="w-100">
          <Spin
            spinning={selectedReportTab === "reportDetails" && (_.isEmpty(runResultDetails) || scenarioLoading)}
            tip="Loading"
            className="spinner-center"
          >
            {isTestBlock === 1 ? (
              <>
                <TestPlanResultDetailsList isTestBlock={isTestBlock} />
                <TestPlanResultDetailsScenario>
                  <ParentTestPlanResultDetailsStepV2
                    parentSteps={
                      testBlockResultDataByRunId !== null &&
                      testBlockResultDataByRunId[runResultDetails[0]?.runId].parentSteps
                    }
                    isTestBlock={isTestBlock}
                    runResultDetails={runResultDetails}
                  />
                </TestPlanResultDetailsScenario>
              </>
            ) : (
              <>
                <Col className="ml-10 font-size-16 font-weight-600 innerHeader p-5 display-flex align-items-center">
                  <div className="p-12">RUN REPORT FOR TEST PLAN : </div>{" "}
                  <span className="font-weight-400">
                    {runResultDetails && runResultDetails?.length > 0 ? runResultDetails[0]?.testPlanName : "NA"}
                  </span>
                </Col>
                <div className="height overflow-y-scroll">
                  <Row className="mt-10 ml-20">
                    <Radio.Group
                      buttonStyle="solid"
                      onChange={(e) => dispatch(changeReportResultTab(e.target.value))}
                      value={selectedReportTab}
                    >
                      <Radio.Button value={"reportDetails"} className="border-radius-4">
                        Report Details
                      </Radio.Button>
                      <Radio.Button value={"previewStepDetails"} className="mr-10 border-radius-4">
                        Preview Step Screenshots
                      </Radio.Button>
                    </Radio.Group>
                  </Row>
                  {selectedReportTab === "reportDetails" && (
                    <>
                      <TestPlanResultDetailsList isTestBlock={isTestBlock} />
                      <TestPlanResultViewManagerV2
                        runNumber={runNumber}
                        orgId={orgId}
                        isTestBlock={isTestBlock}
                        scenarioDetails={scenarioDetails}
                        setScenarioDetails={setScenarioDetails}
                      />
                    </>
                  )}
                  {selectedReportTab === "previewStepDetails" && (
                    <TestPlanResultDetailsPreview
                      orgId={orgId}
                      runNumber={runNumber}
                      testPlanResultDetail={testPlanResultDetail}
                    />
                  )}
                </div>
              </>
            )}
          </Spin>
        </Col>
      </Row>
      {isNullOrUndefinedOrEmpty(testPlanResultDetails) && !scenarioLoading && isTestBlock === 0 && (
        <EmptyRecords description={"No Scenario Found"} />
      )}
    </>
  );
};

export default TestPlanResultDetails;
