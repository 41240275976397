import { Collapse, Empty } from "antd";
import React from "react";
import _ from "lodash";
import ViewRecTestScenarioDefaultTestDataSet from "./ViewRecTestScenarioDefaultTestDataSet";

const ViewTestPlanDefaultTestDataSet = (props) => {
  return (
    <>
      {props.defaultTestData && (
        <div className="mt-10">
          {props.defaultTestData?.testScenarioTestDataSaveReq && 
            !_.isEmpty(props.defaultTestData?.testScenarioTestDataSaveReq) &&
              props.defaultTestData.testScenarioTestDataSaveReq.map((testScenarioTestData) => {
                return (!_.isEmpty(testScenarioTestData?.testBlockTestDataSaveReq) || 
                  !_.isEmpty(testScenarioTestData?.testDataValues)) &&
                    <Collapse className="test-data-collapse-border" defaultActiveKey={"Default"}>
                      <Collapse.Panel 
                        collapsible={true} 
                        header={testScenarioTestData.name
                          + " [Steps using Test Data: " +  testScenarioTestData?.totalstepCount
                          + " Unique Test Data Keys: " + testScenarioTestData?.totaluniqueTestDataCount + "]"} 
                        key={"Default"}>
                        <ViewRecTestScenarioDefaultTestDataSet
                          defaultTestData={testScenarioTestData}
                          title={"Test Scenario Level Test Data"
                            + " [Steps using Test Data: " +  testScenarioTestData?.stepCount
                            + " Unique Test Data Keys: " + testScenarioTestData?.uniqueTestDataCount + "]"}
                          stepType={props.stepType}
                          refTestDataList={props.refTestDataList}
                        />
                      </Collapse.Panel>
                    </Collapse>
                }
              )
            }
        </div>
      )}
    </>
  );
};

export default ViewTestPlanDefaultTestDataSet;
