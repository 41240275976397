import React, { Component, useEffect, useState } from "react";
import { Row, Col, Modal, Pagination, Tabs, Spin } from "antd";
import Search from "antd/lib/input/Search";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import SearchBox from "../../../CommonComponents/SearchBox";
import {
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  handleSaveBuild,
  isNotNullAndNotEmptyAndNotUndefined,
  isNotNullBuildDetails,
} from "../../../../Util";
import {
  defaultDateTimeFormat,
  INITIAL_APP_BUILD_DATA,
  SUCCESS_STATUS,
} from "../../../../Constants";
import {
  getAllBuildDetails,
  changeAppBUildListPageNumber,
  pushBuild,
  pushAnUploadBuild,
} from "../../../../actions/Recording";
import styled from "styled-components";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import AddAppBuild from "../../../BuildManagement/AddBuild/AddBuild";
import moment from "moment";
import styles from "./DevicePushBuild.module.scss";

library.add(faCloudUploadAlt);

export const BuildRow = styled(Row)`
  margin: 1%;
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  border: 0;
  margin-bottom: 1rem;
  border-radius: 10px;
  cursor: pointer;
  padding: 15px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  background: ${(props) => (props.selected ? "#008ecc" : "white")};
  :hover {
    background-color: ${(props) => (props.selected ? "#008ecc" : "#e8e8e885")};
  }
  color: ${(props) => (props.selected ? "white" : "black")};
`;

const getAllBuildTags = (tags) => {
  var str = tags;
  str = str.split(",");
  return str.map((v, k) => {
    if (v !== "") {
      return <span key={k}>{v}</span>;
    }
  });
};

const DevicePushBuild = (props) => {
  const { projectId, hidePushBuildModal, visible, targetUniqueId } = props;
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [activePanelId, setActivePanelId] = useState(null);
  const [saveBuild, setSaveBuild] = useState(false);
  const [buildId, setBuildId] = useState("");
  const [selectedTab, setSelectedTab] = useState("USE AN UPLOAD BUILD");
  const [appBuildData, setAppBuildData] = useState(INITIAL_APP_BUILD_DATA);
  const {
    allAppBuildDetails,
    pushBuildRequest,
    pushBuildResponse,
    appBuildDetailsById,
    sharedWithFarmInAddAppBuild,
    uploadAndSaveBuildRequest,
  } = useSelector((state) => state.RecordingReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);

  useEffect(() => {
    setBuildId("");
  }, [props.projectId]);

  useEffect(() => {
    if (visible) {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId));
      setSaveBuild(false);
      setSelectedTab("USE AN UPLOAD BUILD")
    }
  }, [visible]);

  const handleOnPageChange = (pagination) => {
    if (pagination) {
      dispatch(changeAppBUildListPageNumber(pagination));
      dispatch(getAllBuildDetails(false, searchKeyword, projectId));
    }
  };

  const handleOnClick = (id) => {
    if (id !== activePanelId) {
      setActivePanelId(id);
    } else {
      setActivePanelId(null);
    }
  };

  const handleOnClose = () => {
    hidePushBuildModal();
    setSearchKeyword(null);
    setActivePanelId(null);
    setAppBuildData(INITIAL_APP_BUILD_DATA);
  };

  const handleOnSearch = (keyword) => {
    setSearchKeyword(keyword);
    dispatch(getAllBuildDetails(true, keyword, projectId));
  };

  useEffect(() => {
    if (pushBuildResponse === SUCCESS_STATUS) {
      setSearchKeyword(null);
      setActivePanelId(null);
    }
  }, [pushBuildResponse]);

  const disableUploadButton = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return _.isEqual(appBuildData, appBuildDetailsById) || isNotNullBuildDetails(appBuildData);
    } else {
      return isNotNullBuildDetails(appBuildData);
    }
  };
  const selectedFarms = [];

  if (deviceFarmList && deviceFarmList.length > 0) {
    deviceFarmList.map((v, k) => {
      return selectedFarms.push(v.id);
    });
  }


  return (
    <Modal
      width="70%"
      title=" Install Build"
      visible={visible}
      destroyOnClose
      onOk={() =>
        selectedTab === "USE AN UPLOAD BUILD"
          ? dispatch(pushBuild([targetUniqueId], activePanelId))
          : saveBuild === true
          ? handleSaveBuild(
              selectedFarms,
              sharedWithFarmInAddAppBuild,
              selectedFarms,
              buildId,
              appBuildData,
              dispatch,
              true,
              targetUniqueId,
              setAppBuildData
            )
          : dispatch(pushAnUploadBuild(appBuildData))
      }
      onCancel={() => {
        if (!(pushBuildRequest || uploadAndSaveBuildRequest)) {
          handleOnClose();
        }
      }}
      okButtonProps={{
        disabled: selectedTab === "USE AN UPLOAD BUILD" ? activePanelId == null : disableUploadButton(),
      }}
      cancelButtonProps={{
        disabled: (pushBuildRequest || uploadAndSaveBuildRequest)

      }}
      closable={!pushBuildRequest && !uploadAndSaveBuildRequest}
      maskClosable={!pushBuildRequest && !uploadAndSaveBuildRequest}
      okText="Push Build"
      cancelText="Cancel"
      confirmLoading={pushBuildRequest || uploadAndSaveBuildRequest}
      loading={pushBuildRequest || uploadAndSaveBuildRequest}
      className="devicePushBuildModal"
    >
      <Tabs defaultActiveKey="USE AN UPLOAD BUILD" activeKey={selectedTab} onChange={(e) => setSelectedTab(e)}>
        <Tabs.TabPane tab="Use an uploaded Build" key={"USE AN UPLOAD BUILD"}>
          <Search
            placeholder="Search Build"
            value={searchKeyword}
            onChange={(e) => {
              handleOnSearch(e.target.value);
            }}
          />

          <Row className={styles.pageScroll}>
            {allAppBuildDetails &&
              allAppBuildDetails.data &&
              !_.isEmpty(allAppBuildDetails.data) &&
              allAppBuildDetails.data.map((AppBuild, k) => {
                return (
                  <BuildRow
                    key={k}
                    selected={activePanelId === AppBuild.id}
                    onClick={() => {
                      handleOnClick(AppBuild.id);
                    }}
                  >
                    {AppBuild?.displayName && <div className="font-size-14">Name: {AppBuild?.displayName} </div>}
                    <div className="font-size-14">Version: {AppBuild.buildVersion} </div>
                    <div className="font-size-12">Build Type: {AppBuild.buildType}</div>
                    <div className="font-size-12">
                      Created Date: {getDateTimeInUTCFormat(AppBuild.buildDate, defaultDateTimeFormat)}
                    </div>
                    {AppBuild.buildTags != null && (
                      <div className="font-size-12">Tags: {getAllBuildTags(AppBuild.buildTags)}</div>
                    )}
                    <div className="font-size-12">
                      {AppBuild.uploadedBy != null ? (
                        <div>
                          <b>{AppBuild.uploadedBy}</b>
                          <span className="mr-5 ml-5">Uploaded On</span>
                          {AppBuild.uploadedOn !== null ? getDateTimeInFormat(AppBuild.uploadedOn) : "--"}
                        </div>
                      ) : (
                        <span className="mr-5">Uploaded On</span>
                      )}
                    </div>
                  </BuildRow>
                );
              })}
          </Row>
          {!_.isEmpty(allAppBuildDetails.data) && (
            <Row className="pull-right">
              <Col span={24}>
                <PaginationComponent
                  currentPageItems={allAppBuildDetails?.pageItems}
                  totalItems={allAppBuildDetails?.totalItems}
                  currentPage={allAppBuildDetails?.currentPage}
                  totalPage={allAppBuildDetails?.totalCount}
                  callPaginationAPI={handleOnPageChange}
                />
              </Col>
            </Row>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Install Build from Local System" key={"UPLOAD BUILD"}>
          <Spin spinning={uploadAndSaveBuildRequest || pushBuildRequest} className="spinner-center">
            <AddAppBuild
              buildId={buildId}
              projectId={props.projectId}
              devicePushBuild={true}
              saveBuild={saveBuild}
              setSaveBuild={setSaveBuild}
              appBuildData={appBuildData}
              setAppBuildData={setAppBuildData}
            />
          </Spin>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
};

export default DevicePushBuild;
