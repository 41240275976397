import { BuildErrorInvestigationStateModal } from "../Components/ErrorInvestigation/BuildErrorInvestigationStateModal";
import {
  CHANGE_ERROR_INVESTIGATION_DATA,
  ERROR_INVESTIGATION_LOADING,
  FETCH_ERROR_INVESTIGATION_DATA,
  FETCH_OTHER_RUN_LIST,
  PREVIOUS_ERROR_INVESTIGATION_DATA,
} from "../Constants";

let defaultState = {
  errorInvLoading: false,
  errorInvestigationDetails: new BuildErrorInvestigationStateModal(),
  getErrorInvestigationDetails: null,
  previous_ErrorInvestigationDetails: new BuildErrorInvestigationStateModal(),
  currentPage: 1,
  totalPage: 0,
  fetchOtherRunList: [],
};

export default function ErrorInvestigationReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case FETCH_ERROR_INVESTIGATION_DATA:
      response = action?.response?.data;
      return {
        ...state,
        getErrorInvestigationDetails: response,
      };

    case CHANGE_ERROR_INVESTIGATION_DATA:
      response = action?.data;
      return {
        ...state,
        errorInvestigationDetails: action.data,
      };

    case PREVIOUS_ERROR_INVESTIGATION_DATA:
      response = action?.data;
      return {
        ...state,
        previous_ErrorInvestigationDetails: action.data,
      };

    case FETCH_OTHER_RUN_LIST:
      response = action?.response;
      return {
        ...state,
        fetchOtherRunList: response?.data?.data,
        currentPage: action?.pageNumber,
        totalPage: response?.data?.totalItems,
      };

    case ERROR_INVESTIGATION_LOADING:
      return {
        ...state,
        errorInvLoading: action.isLoading,
      };

    default:
      return state;
  }
}
