import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";
import debounce from "lodash/debounce";
import { ICON_SEARCH } from "../../../Constants/SvgConstants";
import { hasBlankSpaces } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";

const CommonSearchV2 = (props) => {
  const {
    setSearchKeyword,
    placeHolder,
    searchDetails,
    importSteps,
    className,
    testPlanSearch,
    autoFocus = true,
    nameInput,
  } = props;
  const [isError, setIsError] = useState(false);
  const debouncedSearch = useCallback(
    debounce((e) => {
      const searchWord = e.target.value;
      setSearchKeyword(e.target.value);
      if (hasBlankSpaces(searchWord)) {
        setIsError(true);
      } else {
        setIsError(false);
        searchDetails(searchWord);
      }
    }, 900),
    [setSearchKeyword, searchDetails]
  );

  useEffect(() => {
    if (nameInput && nameInput.current) {
      setTimeout(() => {
        nameInput?.current?.focus();
      }, 1000);
    }
  }, [nameInput]);

  return (
    <Input
      ref={nameInput}
      className={`${className && className} ${!importSteps && "common_Search_Box"} ${
        !testPlanSearch && "common_Search_Box_Width"
      }`}
      error={isError || undefined}
      onChange={debouncedSearch}
      placeholder={placeHolder}
      allowClear
      prefix={<SvgLoader iconName={ICON_SEARCH} />}
      autoFocus={autoFocus}
    />
  );
};

export default CommonSearchV2;
