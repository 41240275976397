import { Space } from "antd";
import React from "react";
import { CLOSE_ICON_BlACK, CLOSE_ICON_HEADER } from "../../../CdnImagePath";

const BackButton = (props) => {
  const { handleBack, isBlack } = props;
  return (
    <Space size={5}>
      <img
        src={isBlack ? CLOSE_ICON_BlACK : CLOSE_ICON_HEADER}
        alt="back"
        className="cursor-pointer"
        onClick={handleBack}
      />
    </Space>
  );
};

export default BackButton;
