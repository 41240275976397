import { Col, Modal, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/Recording";
import CustomTextStringBuilder from "./CustomTextStringBuilder";
import CustomTextVerificationItem from "./CustomTextVerificationItem";

class CustomTextVerification extends Component {
  constructor(props) {
    super(props);
    //  this.changeCustomTextBoxVerificationValue=this.changeCustomTextBoxVerificationValue.bind(this);
    //  this.changecustomTextVerificationDropdownValue=this.changecustomTextVerificationDropdownValue.bind(this);
    this.saveCustomTextVerification = this.saveCustomTextVerification.bind(this);
    this.discardCustomTextVerification = this.discardCustomTextVerification.bind(this);
    this.addCustomRefTextList = this.addCustomRefTextList.bind(this);
    this.state = { showVerificationDialog: true };
  }
  saveCustomTextVerification(e) {
    this.props.updateVerificationValue(this.props.customRefTextList, this.props.uuid);
    this.setState({
      showVerificationDialog: false,
    });
  }

  discardCustomTextVerification(e) {
    this.props.resetCustomRefList();
    this.setState({
      showVerificationDialog: false,
    });
  }

  addCustomRefTextList(e) {
    var tempObj = {
      index: this.props.customRefTextList.length,
    };
    this.props.customRefTextList.push(tempObj);
  }

  render() {
    const { selectedElement, customRefTextList } = this.props;
    return (
      <React.Fragment>
        {this.state.showVerificationDialog && (
          <Modal
            title="Build Verification Text (Custom)"
            visible={this.state.showVerificationDialog}
            onOk={this.saveCustomTextVerification}
            onCancel={this.discardCustomTextVerification}
            okText={"Add Verification"}
            width={"90%"}
            className={"modal-build-verification"}>
            <React.Fragment>
              <Row>
                <Col>
                  <div className={"custom-text-verification-selected-element"}>
                    <div className="label">Selected Element Text: </div>
                    <div> {selectedElement && selectedElement.attributes ? selectedElement.attributes.text : ""} </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  {customRefTextList &&
                    customRefTextList.map((item, index) => {
                      return <CustomTextVerificationItem item={item} key={item.order} />;
                    })}
                </Col>
              </Row>
              <Row>
                <Col>
                  <CustomTextStringBuilder />
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={"custom-text-verification-selected-element"}>
                    <div className="note">
                      *Please add space or special character at end of word or in new row to concate words.
                    </div>
                  </div>
                </Col>
              </Row>
            </React.Fragment>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return state.RecordingReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTextVerification);
