import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Row, Spin, Tooltip } from "antd";
import _ from "lodash";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { ICON_DELETE_WITH_BG, ICON_EDIT } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import {
  addDeviceStringsFormVisible,
  deleteDeviceString,
  getDeviceStrings,
  loadEditDeviceStringDetail,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import PaginationComponent from "../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import styles from "./DeviceStringsList.module.scss";

const DeviceStringsList = (props) => {
  const { projectId } = props;
  const { getDeviceString, deviceStringLoader, getDeviceStringPageCount, getDeviceStringCurrentCount } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [filter, setFilter] = useState("ALL");
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  const pageState =
    getDeviceStringPageCount instanceof Array && getDeviceStringPageCount.length === 2
      ? parseInt(getDeviceStringPageCount[1]) - parseInt(getDeviceStringPageCount[0]) > 0
        ? getDeviceStringCurrentCount
        : getDeviceStringCurrentCount > 1
        ? getDeviceStringCurrentCount - 1
        : 1
      : 1;

  useEffect(() => {
    dispatch(getDeviceStrings(projectId, "", "", ""));
    setFilter("ALL");
  }, [projectId, dispatch]);

  const columns = [
    {
      title: "Key",
      dataIndex: "name",
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const deviceStringsData =
    getDeviceString &&
    getDeviceString?.data?.map((row) => ({
      key: row?.id,
      name: row?.key,
      value: (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={row?.values.map((data) => {
            return <div>{data.locale + " : " + data.value}</div>;
          })}
        >
          {`${row?.locale} ${row?.locale === 1 ? " Locale" : " Locales"}`}
        </Tooltip>
      ),
      action: (
        <div className={styles.actionIcons}>
          <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader
                iconName={ICON_EDIT}
                handleClick={() => dispatch(loadEditDeviceStringDetail(projectId, row?.key, row?.isImport))}
              />
            </div>
          </Tooltip>
          <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={ICON_DELETE_WITH_BG} handleClick={() => setOpenConfirmModal(row?.key)} />
            </div>
          </Tooltip>
        </div>
      ),
    }));

  return (
    <div className={styles.pageScroll}>
      <Row>
        <Col span={24} className={styles.stylesCol}>
          <Row className={styles.styledRow}>
            <Col className={styles.addIcon}>
              <Tooltip title="Add Device Strings" color={TOOLTIP_BG_COLOR}>
                <div onClick={() => dispatch(addDeviceStringsFormVisible(true))}>
                  <PlusCircleOutlined disabled={false} style={{ fontSize: "150%" }} />
                </div>
              </Tooltip>
            </Col>
            <Col>
              <CommonSearch
                placeHolder="Search by Device string name"
                searchDetails={(searchText) =>
                  dispatch(getDeviceStrings(projectId, searchText, "", "", "", "", "", filter))
                }
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <>
        <Row>
          <Col span={24}>
            <div className={styles.table}>
              <Spin spinning={deviceStringLoader} tip="Loading">
                <TableComponent data={deviceStringsData} columns={columns} />
              </Spin>
            </div>
          </Col>
        </Row>

        {!_.isEmpty(getDeviceString?.data) && (
          <div className={styles.pagination}>
            <PaginationComponent
              currentPageItems={getDeviceString?.pageItems}
              totalItems={getDeviceString?.totalItems}
              currentPage={getDeviceString?.currentPage}
              totalPage={getDeviceString?.totalCount}
              callPaginationAPI={(pageNumber) =>
                dispatch(getDeviceStrings(projectId, searchKeyword, "", pageNumber, "", "", "", filter))
              }
            />
          </div>
        )}
      </>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(deleteDeviceString(openConfirmModal, filter, pageState));
          setOpenConfirmModal(null);
        }}
        handleCancel={() => setOpenConfirmModal(null)}
        modalOpen={!!openConfirmModal}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this item?"}
        okText={"Delete"}
        isDeleteModal={true}
        modalNote="Note: Device String will be removed from associated steps/elements."
      />
    </div>
  );
};

export default DeviceStringsList;
