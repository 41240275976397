import React from "react";
import { isEmpty } from "lodash";
import { Checkbox, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR, executionModeShortText } from "../../../../../Constants";
import ShowTooltipV2 from "../../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./TestPlanResultColumnsV2.module.scss";

const JobInfoDetailsColumnV2 = (props) => {
  const { row, openTestPlanResultDetails, showDeviceList, selectAllRun, setSelectAllRun } = props;

  const testJobSelectionCheckboxType = (reportData) => {
    if (
      (reportData?.runResult === "ABORTED" || reportData?.runResult === "DONE") &&
      !reportData?.resultObj?.UPLOADING_RESULT
    ) {
      return (
        <div>
          <span className={styles.checkBox}>
            <Checkbox
              className="selection-checkBox mr-26"
              checked={selectAllRun && selectAllRun?.includes(reportData?.runId)}
              onClick={(e) => {
                e.stopPropagation();
                e.target.checked
                  ? setSelectAllRun([...selectAllRun, reportData?.runId])
                  : setSelectAllRun(selectAllRun && selectAllRun?.filter((i) => i !== reportData?.runId));
              }}
            />
          </span>
        </div>
      );
    }
  };

  return (
    <div className=" display__flex ml-5">
      {testJobSelectionCheckboxType(row)}

      <div
        className={`${
          ((row?.runResult !== "ABORTED" && row?.runResult !== "DONE") || row?.resultObj?.UPLOADING_RESULT) &&
          styles["permission_Icons_And_Name"]
        } `}
      >
        <div className={styles["build_Jobs_Info_Details"]}>
          <ShowTooltipV2
            text={row?.testJobExecName}
            className={styles["step-name"]}
            handleOnClick={() => openTestPlanResultDetails(row, props.orgId)}
          />
          <div>
            <span className={styles["label"]}>Execution Mode: </span>
            <span className={styles["label-name"]}> {executionModeShortText[row?.testJobExecutionType]}</span>
          </div>
          <div>
            <span className={styles["label"]}>Build to be used: </span>
            <Tooltip color={TOOLTIP_BG_COLOR} title={row?.buildToBeUsedTitle === "URL" && row?.buildToBeUsed}>
              <span
                className={` ${styles["label-name"]} ${
                  row?.buildToBeUsedTitle === "URL" && styles["build_To_Be_Used_Text"]
                }`}
              >
                {row?.buildToBeUsedTitle}
              </span>
            </Tooltip>
          </div>
          <div>
            <span className={styles["label"]}>Execution Farm: </span>
            <span className={styles["label-name"]}> {row?.deviceServiceFarm} </span>
          </div>
          <div>
            <span className={styles["label"]}>Devices to Run on: </span>
            {!isEmpty(row?.devices) ? (
              showDeviceList(row?.devices,row?.deviceServiceFarm)
            ) : (
              <span className={styles["label-name"]}> Unknown </span>
            )}
          </div>
          <div>
            <span className={styles["label"]}>Capture Video: </span>
            <span className={styles["label-name"]}> {row?.isVideo === 1 ? "Yes" : "No"} </span>
          </div>
          <div>
            <span className={styles["label"]}>Capture Logs: </span>
            <span className={styles["label-name"]}> {row?.isLogs === 1 ? "Yes" : "No"} </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobInfoDetailsColumnV2;
