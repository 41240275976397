import { Col, Empty, Row } from "antd";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { ANDROID, IOS } from "../../../../Constants";
import SvgLoader from "../../../../Util/SvgLoader";
import { WARNING_NO_ELEMENT_DATA_FOUND } from "../../../../Constants/SvgConstants";
export const CommonCol = styled(Row)`
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  font-weight: 500;
  text-align: left;
`;

export const DataCol = styled(Col)`
  word-wrap: break-word;
  text-align: left;
`;

export const StyledCol = styled(Row)`
  height: "900px";
  border: 1px solid #e8e8e8;
  border-top: 0px !important;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;

export const StyledNoElementFound = styled.div`
  text-align: center;
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;
export const StyledDiv = styled.div`
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;
const ActualElements = (props) => {
  const { testExecutionElementDetail, failurePageSourceLink, devicePlatform } = props;
  let properties = {};
  for (let i = 0; i < testExecutionElementDetail?.testExecutionElementProperties.length; i++) {
    properties[testExecutionElementDetail?.testExecutionElementProperties[i]?.name] =
      testExecutionElementDetail?.testExecutionElementProperties[i]?.value;
  }

  return (
    <div>
      <StyledDiv className="font-weight-600">Actual Element Properties</StyledDiv>
      {!_.isEmpty(properties) ? (
        <>
          <CommonCol span={24}>
            <DataCol sm={12} md={12} lg={12} xl={8}>
              Items
            </DataCol>
            <DataCol sm={12} md={12} lg={12} xl={8}>
              Values
            </DataCol>
          </CommonCol>
          <>
            {devicePlatform?.toUpperCase() === ANDROID && (
              <>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Class"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["class"] || "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Resource-id"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["resource-id"] || "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Text"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["text"] || "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Content-Desc"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["content-desc"] || "Not Found"}
                  </DataCol>
                </StyledCol>
              </>
            )}
            {devicePlatform?.toUpperCase() === IOS && (
              <>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Type"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["type"] ? properties["type"] : "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Name"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["name"] ? properties["name"] : "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Label"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["label"] ? properties["label"] : "Not Found"}
                  </DataCol>
                </StyledCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {"Value"}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {properties["value"] ? properties["value"] : "Not Found"}
                  </DataCol>
                </StyledCol>
              </>
            )}
          </>
        </>
      ) : (
        <StyledNoElementFound>
          <SvgLoader iconName={WARNING_NO_ELEMENT_DATA_FOUND} iconColor="orange" />
          <div className="text-align-center">
            {testExecutionElementDetail.state ? "Unable to find Element on page!" : "No Data Found"}
          </div>
        </StyledNoElementFound>
      )}
    </div>
  );
};

export default ActualElements;
