import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setApplicationAssigneeDetail } from "../../actions/TestDataSet/TestDataSetCommonAction";
import BackButton from "../CoreComponents/BackButtonComponent/BackButton";
import ApplicationStringList from "./ApplicationStringList";

const ApplicationStringTab = (props) => {
  const { projectId, selectedStepId } = props;
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  return (
    <>
      <Row justify="space-between" align="middle" className="fixInnerHeader w-100">
        <Col>
          <div className="mb-0 mainHeaderText ml-18">Manage Applications Strings</div>
        </Col>
        <Col>
          <div className="closeIcon">
            <BackButton handleBack={() => dispatch(setApplicationAssigneeDetail(undefined))} isBlack={true} />
          </div>
        </Col>
      </Row>
      <div>
        <ApplicationStringList projectId={projectId} stepType={stepType} selectedStepId={selectedStepId}/>
      </div>
    </>
  );
};

export default ApplicationStringTab;
