import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { Col, Drawer, Row } from "antd";
import { CUSTOM, privacyOption } from "../../../../Constants";
import SaveResetButton from "../../SaveResetComponent/SaveResetButton";
import MemberListV2 from "./MemberListV2";
import styles from "./PermissionDrawerV2.module.scss";

const PermissionDrawerV2 = (props) => {
  const {
    openPermissionDrawer,
    onClose,
    permissionData,
    savePermission,
    members,
    previousPermission,
    setPermissionData,
    notShowExecutionCheck,
    ownerEmailId,
    drawerTitle,
  } = props;
  const [applyFor, setApplyFor] = useState([]);
  const [removeFor, setRemoveFor] = useState([]);

  useEffect(() => {
    setPermissionData();
  }, [permissionData]);

  return (
    <>
      <Drawer
        title={
          <Row justify="space-between" align="middle">
            <Col span={20}>
              <Row>
                <Col>{drawerTitle}: </Col>
                <Col span={18} className={styles["drawer-title-Text"]}>
                  {permissionData?.name || permissionData?.displayName}
                </Col>
              </Row>
              <div>
                Rights:{" "}
                <span className={styles["drawer-title-Text"]}>
                  {permissionData?.isPrivate === 1 ? privacyOption[0] : privacyOption[1]}
                </span>
              </div>
            </Col>

            <Col span={4}>
              <SaveResetButton
                submitLabel={"Save"}
                resetLabel={"Reset"}
                submitDisable={isEqual([...applyFor, ...removeFor], previousPermission)}
                resetDisable={isEqual([...applyFor, ...removeFor], previousPermission)}
                handleSubmit={() => savePermission(CUSTOM, permissionData, [...applyFor, ...removeFor])}
                handleReset={setPermissionData}
              />
            </Col>
          </Row>
        }
        visible={openPermissionDrawer}
        onClose={onClose}
        placement="right"
        width={"736"}
      >
        <MemberListV2
          notShowExecutionCheck={notShowExecutionCheck}
          ownerEmailId={ownerEmailId}
          applyFor={applyFor}
          setApplyFor={setApplyFor}
          setRemoveFor={setRemoveFor}
          removeFor={removeFor}
          members={members}
          openPermissionDrawer={openPermissionDrawer}
        />
      </Drawer>
    </>
  );
};

export default PermissionDrawerV2;
