import { BuildRegisterServerModal } from "../Components/ServerManagement/BuildRegisterServerModal";
import {
  ALL_SERVER,
  CHANGE_REGISTER_SERVER_DATA,
  CHANGE_SERVER_MANAGER_TAB,
  CLOSE_ADD_EMULATOR_MODAL_VISIBLE,
  EMULATOR_SUPPORTED_DEVICE_LIST,
  EMULATOR_SUPPORTED_OS_LIST,
  FETCH_ALL_PROJECTS,
  FETCH_ALL_SERVER,
  FETCH_DEVICE_DETAILS,
  FETCH_SERVER_TYPES,
  OPEN_ADD_EMULATOR_MODAL_VISIBLE,
  PREVIOUS_REGISTER_SERVER_DATA,
  RECORD_PER_PAGE,
  SERVER_DEVICE_LOADING,
  SERVER_LOADING,
  START_STOP_VIRTUAL_DEVICE_LOADER,
  UPDATE_ADD_EMULATOR,
} from "../Constants";

let defaultState = {
  selectedServerManagerTab: ALL_SERVER,
  serverTypes: null,
  serverList: null,
  currentServerListPage: 1,
  totalServerListPage: 0,
  registerServerData: new BuildRegisterServerModal(),
  previous_registerServerData: new BuildRegisterServerModal(),
  projectsList: [],
  serverLoading: false,
  serverDeviceLoading: false,
  virutalDeviceActionRequestLoader: {},
  addEmulatorModalVisible: false,
  addEmulatorServerId: undefined,
  addEmulatorServerIp: undefined,
  addEmulatorServerArmType: undefined,
  emualtorSupportedOSList:[],
  emualtorSupportedDeviceList:[],
  addEmulatorObj:undefined
};

export default function ServerReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case CHANGE_SERVER_MANAGER_TAB:
      return {
        ...state,
        selectedServerManagerTab: action?.selectedTab,
      };

    case FETCH_SERVER_TYPES:
      response = action?.response?.data;
      return {
        ...state,
        serverTypes: response,
      };

    case FETCH_ALL_SERVER:
      response = action?.response;
      return {
        ...state,
        serverList: response?.data?.data,
        currentServerListPage: action?.pageNumber,
        totalServerListPage: response?.data?.totalCount,
        totalPageItems: response?.data?.pageItems,
        totalItems: response?.data?.totalItems,
      };

    case CHANGE_REGISTER_SERVER_DATA:
      response = action?.data;
      return {
        ...state,
        registerServerData: action.data,
      };

    case PREVIOUS_REGISTER_SERVER_DATA:
      return {
        ...state,
        previous_registerServerData: action?.data,
      };

    case FETCH_ALL_PROJECTS:
      response = action?.response?.data?.data;
      return {
        ...state,
        projectsList: response,
      };

    case FETCH_DEVICE_DETAILS:
      response = action?.response;
      const devices = [
        ...response?.data?.connectedDevices,
        ...response?.data?.registeredDevices ?? [],
      ];
      return {
        ...state,
        serverList: state.serverList.map((x) => {
          if (x.id === response?.serverId) {
            x.deviceDetails = devices || [];
          }
          return x;
        }),
      };

    case SERVER_LOADING:
      return {
        ...state,
        serverLoading: action.isLoading,
      };

    case SERVER_DEVICE_LOADING:
      return {
        ...state,
        serverDeviceLoading: action.isLoading,
      };
    case START_STOP_VIRTUAL_DEVICE_LOADER:
      let virutalDeviceActionRequestLoader =
        state.virutalDeviceActionRequestLoader;
      virutalDeviceActionRequestLoader[action.serverId] = action.visible;
      return {
        ...state,
        virutalDeviceActionRequestLoader,
      };
    case OPEN_ADD_EMULATOR_MODAL_VISIBLE:
      return {
        ...state,
        addEmulatorModalVisible: true,
        addEmulatorServerId: action.serverId,
        addEmulatorServerIp: action.serverIp,
        addEmulatorServerArmType: action.serverArmType,
        addEmulatorObj:undefined
      };

    case CLOSE_ADD_EMULATOR_MODAL_VISIBLE:
      return {
        ...state,
        addEmulatorModalVisible: false,
        addEmulatorServerId: undefined,
        addEmulatorServerIp: undefined,
      };
    case EMULATOR_SUPPORTED_OS_LIST:
      return {
        ...state,
        emualtorSupportedOSList:action.data
      };
    case EMULATOR_SUPPORTED_DEVICE_LIST:
      return {
        ...state,
        emualtorSupportedDeviceList:action.data
      };
    case UPDATE_ADD_EMULATOR:
      return {
        ...state,
        addEmulatorObj:action.data
      };

    default:
      return state;
  }
}
