import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from './../../actions/CommonActions';
import { Row, Col, Typography} from 'antd'; 
import HeaderComponent from "../Header/Header";
class TermsAndCondition extends Component {

 constructor(props) {
  super(props);
 }

 render() {
  return (
        <React.Fragment>
          <HeaderComponent
          hideUserTag={true}/>
          <Row>
            <Col className={"privacy-terms-header"}>
            Terms of Service {"&"} Privacy Policy
            </Col>
          </Row>
          <Row>
            <Col span={18} offset = {3} className={"privacy-term-container"}>
              <p>
              By accepting these terms of service, either by registering with and using our company's website (https://xpress.lodestoneco.com) or by clicking a box indicating your acceptance or by executing an order form or any other documentation that references this agreement, you agree to the terms of this agreement.
              </p>
              <p>
              All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client or user in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to.
              </p>
              <p><h3><u>Definition</u></h3></p>
              <p>
              Unless the context otherwise requires, any words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to the same term or expression used in these Terms of Use shall be construed according to its ordinary meaning.
              </p>
              <p>
              This shall be without prejudice to the following;
              </p>
              <p>
              <b>"Company" </b> shall mean Lodestone Xpress Device Farm;
              </p>
              <p>
                <b>"Laws"</b> means all applicable local, state, federal, foreign and international laws, regulations and conventions, including, without limitation, those related to data privacy and data transfer, international communications, and the exportation of technical or personal data.
              </p>
              <p>
              <b>"Open Source Software"</b> means any open source, community or other free code or libraries of any type, including, without limitation, any code which is made generally available on the Internet without charge (such as Appium, libimobiledevice, openstf, npm, mitmproxy etc, any code licensed under any version of the MIT, BSD, Apache, Mozilla or GPL or LGPL licenses).
              </p>
              <p>
              <b>"Personal Information"</b> means any information that identifies, relates to, describes, or is capable of being associated with, or could reasonably be linked, directly or indirectly, to an identified or identifiable living natural person, including but not limited to: (i) Identifiers such as a real name, alias, postal address, unique personal identifier, online identifier Internet Protocol address, email address, account name, social security number, driver's license number, government identification card number, passport number, or other similar identifiers; (ii) any patient, medical records or other protected or regulated health information; (iii) any financial information (including bank account or payment card numbers) or any other information subject to regulation or protection under specific laws or regulations; or (iv) information defined as "personal information,” "personally identifiable information,” "personal data,” or similar expressions under applicable privacy laws or data security Laws.
              </p>
              <p>
              <b>"Services"</b> shall mean the provision of any services provided by the Company.
              </p>
              <p>
              <b>"User"</b> or <b>"Users"</b> shall mean anyone making use of the Services provided by the Company and/or anyone accessing the Website of the Company for whatever purpose;
              </p>
              <p>
              <b>"Website"</b> shall mean the website operated by the Company and having the following link (https://xpress.lodestoneco.com).
              </p>
              <p>
              <p><h3><u>Privacy Statement</u></h3></p>
              </p>
              <p>
              We are committed to protecting your privacy. Authorized employees within the company on a need to know basis only use any information collected from individual customers. We constantly review our systems and data to ensure the best possible service to our customers. In case(s) of specific offence(s) for unauthorised actions against computer systems and data. We will investigate any such actions with a view to prosecuting and/or taking civil proceedings to recover damages against those responsible.
              </p>
              <p>
                <h3><u>Confidentiality</u></h3>
              </p>
              <p>
              Client's confidential business information is a valuable asset that everyone must protect. Company has a duty to protect confidential information and also ensure the safety of Confidential Information of third parties which has been received by the Company  through Non-Disclosure Agreements. Confidential information includes, without limitation, proprietary data, trade secrets and know-how such as software and product designs, product plans, inventions, laboratory notebooks, processes, designs, drawings, engineering, customer lists, employee data (other than your own), financial information, budgets, pricing, business plans, or other business information. We will not sell, share, or rent your Personal Information to any third party or use your e-mail address for unsolicited mail. Any emails sent by this Company will only be in connection with the provision of agreed services and products. The Company will refrain from sharing confidential information internally beyond those persons who have a "need to know" it for purposes of work.
              </p>
              <p>
              <h3><u>Cookies</u></h3>
              </p>
              <p>
              Our website uses cookies to provide you with the best user experience. By accepting these terms & conditions, you agree to our use of cookies. A cookie is a piece of data stored on a user's hard drive containing information about the user. However the same will not be used for any other purpose. 
              </p>
              <p>
              <h3><u>Use of Devices and Services of the company</u></h3>                
              </p>
              <p>
              You understand and agree that the client or user should not use devices for any work outside the scope of the agreement and also do not engage in any activity that damages the devices of Company or interrupts the services of the Company. Client and/or user are responsible for any non-compliance of this agreement and for any consequences thereof.
              </p>
              <p>
              You shall not host, display, upload, modify, publish, transmit, update or share any information on device that
              </p>
              <p>
                <ul>
                  <li>belongs to another person and to which You don't have any right to;</li>
                  <li>is grossly harmful, harassing, blasphemous defamatory, obscene, pornographic, paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;</li>
                  <li>harm minors in any way;</li>
                  <li>infringes any patent, trademark, copyright or other proprietary rights;</li>
                  <li>violates any law for the time being in force;</li>
                  <li>deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;</li>
                  <li>impersonate another person;</li>
                  <li>contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource;</li>
                  <li> threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation;</li>
                </ul>     
              </p>
              <p>
                You shall not do any of the below mentioned activities during use of Company's device.
              </p>
              <p>
              <ul>
                  <li>
                  delete or copy files and applications that are not customers files.
                  </li>
                  <li>
                  Turn off debug mode etc/ or in any way intentionally trying to affect the stability of the device.
                  </li>
                  <li>
                  Use any device or service for any purpose that the Company (acting reasonably) believes is abusive, nuisance, illegal or fraudulent.
                  </li>
                  <li>
                  Intentionally do anything that causes the device(s) or service(s) to be impaired or damaged.
                  </li>
                  <li>
                  Create accounts not related to work or using accounts/device for Political or promoting other agenda
                  </li>
                  <li>
                  Use devices to make phone/VOIP calls not related to work calls and also to emergency service(s) or any other government services.
                  </li>
                  <li>
                  Should not share logins and/or passwords under any circumstance.
                  </li>
              </ul>
              </p>
              <p>
              You shall at all times ensure full compliance with the applicable provisions of the Information Technology Act, 2000 and rules thereunder as applicable and as amended from time to time and also all applicable laws regarding Your use of Our Services.
              </p>
              <p>
              <h3><u>Termination</u></h3>
              </p>
              <p>
              Both the Client and ourselves have the right to terminate any Services Agreement for any reason, including the ending of services that are already underway. No refunds shall be offered, where a Service is deemed to have begun and is, for all intents and purposes, underway.
              </p>
              <p>
              <h3><u>Intellectual Property Rights</u></h3>
              </p>
              <p>
              All rights, including copyright, in the content of the Website are owned or controlled for these purposes by the Company. Users may only download the content for your own personal non-commercial use.
              </p>
              <p>
              Users shall not be allowed to copy, broadcast, download, store (in any medium), transmit, show or play in part or in whole, adapt or change in any way the content of the website for any other purpose whatsoever without the written permission of the Company.
              </p>
              <p>
              Copyright and other relevant intellectual property rights exists on all text relating to the Company's services and the full content of this website. 
              </p>
              <p>
              <h3><u>Force Majeure</u></h3>
              </p>
              <p>
              Neither party shall be liable to the other for any failure to perform any obligation under any Agreement which is due to an event beyond the control of such party including but not limited to any Act of God, terrorism, war, Political insurgence, insurrection, riot, civil unrest, act of civil or military authority, uprising, earthquake, flood or any other natural or man made eventuality outside of our control, which causes the termination of an agreement or contract entered into, nor which could have been reasonably foreseen. Any Party affected by such event shall forthwith inform the other Party of the same and shall use all reasonable endeavours to comply with the terms and conditions of any Agreement contained herein. During any such situation, if company service is affected, Company will try its best to resume the service to normal in a reasonable timeline.
              </p>
              <p>
              <h3><u>Waiver</u></h3>
              </p>
              <p>
              Failure of either Party to insist upon strict performance of any provision of this or any Agreement or the failure of either Party to exercise any right or remedy to which it, they are entitled hereunder shall not constitute a waiver thereof and shall not cause a diminution of the obligations under this or any Agreement. No waiver of any of the provisions of this or any Agreement shall be effective unless it is expressly stated to be such and signed by both Parties.
              </p>
              <p> <i>
              These Terms & Conditions may be amended, revised, changed, and updated or modified by Lodestone Xpress Device Farm. Therefore, please review the said link on a regular basis for change. 
              </i> </p>
              <p></p>
              <p>
                Last updated: 11 August 2020
              </p>
            </Col>
          </Row>
        </React.Fragment>
  );
 }
}

function mapDispatchToProps(dispatch) {
 return  bindActionCreators(actions, dispatch)
}

function mapStateToProps(state) {
 return state.CommonReducer
}

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(TermsAndCondition); 