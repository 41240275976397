import styled from "styled-components";

export const ComponentContainer = styled.div`
 
`
export const PageHeader = styled.div`
    font-size: 18px;
    text-align: left;
    color: rgba(0, 0, 0, 0.80);
    margin-bottom: 15!important;
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
    background-color: transparent!important;
    padding: .5rem 1rem;
    margin-bottom: 15PX;
    background-color: rgba(0,0,0,.03);
    background-color: rgb(255 255 255) !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
    border-radius: .25rem;
`;