import { Button, Pagination, Select } from "antd";
import { default as React } from 'react';
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeFreeDeviceForRecording } from "../../actions/RecTestScenarioAction";


export const StyledPagination = styled(Pagination)`
    float: right;
    margin-top: 10px;
`
export const StyledRecordScenarioButton = styled(Button)`
margin-top: 10px !important;
background-color: #338fcc;
color: white;
font-size: 14px;
font-weight: 600;
float: right;
`

export default (props) => {
    const { freeDeviceListForRecording, selectedFreeDeviceForRecording } = useSelector(state => state.RecTestScenarioReducer);
    const dispatch = useDispatch();
    return (
        <select value={selectedFreeDeviceForRecording} onChange={(e) => dispatch(changeFreeDeviceForRecording(e.target.value))}>
            <option key={"1"} value={""}>{"Select Device for Recording"}</option>
            {
                freeDeviceListForRecording && freeDeviceListForRecording.map((v, k) => {
                    return (
                        <option key={v.device.targetUniqueId} value={v.device.targetUniqueId}>{v.device.name}</option>
                    );
                })
            }
        </select>
    );
}
