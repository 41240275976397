import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAwsDeviceFarmPools } from "../../../../../actions/AwsDeviceFarmAction";
import styles from "./AwsDeviceFarmV2.module.scss";

const { Option } = Select;

const SelectAwsDevicePoolsV2 = (props) => {
  const dispatch = useDispatch();
  const { testJobDetail, required, onChange } = props; 
  const { awsDeviceFarmPools } = useSelector((state) => state.AwsDeviceFarmReducer);

  useEffect(() => {
    if (testJobDetail.deviceFarmIds) {
      dispatch(getAwsDeviceFarmPools({ awsDeviceFarmProjectId: testJobDetail.deviceFarmIds[0] }));
    }
  }, []);
  
  return (
    <>
      <div className={styles["custom-input-wrapper"]}>
        <div className={`${styles["custom-input-label"]}`}>
          AWS Device Farm Pools :{required ? <span className="text-danger">*</span> : ""}
        </div>
        <Select
          className={styles["modal-select-box"]}
          key="Devices"
          mode="multiple"
          value={testJobDetail.devices}
          placeholder="Select Multiple Device Pool To Run Scenario Parallel On Device Pools"
          notFoundContent={
            testJobDetail.deviceFarmIds ? (
              <div>No Aws Device farm pool found</div>
            ) : (
              <div>Please Select Aws Device Farm project found</div>
            )
          }
          onChange={(value) => dispatch(onChange({ ...testJobDetail, devices: value }))}
        >
          {awsDeviceFarmPools && (
            <Option key={0} value={"0"}>
              Run on Free Device pool
            </Option>
          )}
          {awsDeviceFarmPools &&
            awsDeviceFarmPools.map((v, k) => {
              return (
                <Option value={v.arn} key={k}>
                  {v.name}
                </Option>
              );
            })}
        </Select>
      </div>
    </>
  );
};
export default SelectAwsDevicePoolsV2;
