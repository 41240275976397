import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Collapse, Radio, Row, Spin, Tooltip } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from "styled-components";
import {
  changeSelectedAllScenarioOption,
  changeSelectedComparisonPreviewView,
  downloadResultLogs,
  fetchPreviewDownloadsByRunNumber,
  fetchSameLocaleForPreviewByRunNumber,
  setResultType,
} from "../../../../actions/TestPlanAction";
import { COMPARISON_ALL_LOCALES, COMPARISON_SINGLE_LOCALE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import RefRunNumberPanel from "./RefRunNumberPanel";
import ScenarioCardForPreviewLocale from "./ScenarioCardForPreviewLocale";
import TestPlanResultAllLocalePreviewImages from "./TestPlanResultAllLocalePreviewImages";
import TestPlanResultPreviewImages from "./TestPlanResultPreviewImages";
import SelectScenarios from "../SelectScenarios";

const { Panel } = Collapse;
export const StyledCollapse = styled(Collapse)`
  .ant-collapse-content-box {
    padding: 20px !important;
  }
`;

const TestPlanResultDetailsPreview = (props) => {
  const dispatch = useDispatch();
  const { resultType } = useSelector((state) => state?.TestPlanReducer);
  const { orgId, testPlanResultDetail } = props;
  const runNumber = props?.runNumber;

  const {
    previewRunDetails,
    scenarioLoading,
    selectedTestScenarioForResultLogs,
    selectedAllScenario,
    selectedComparisonPreviewView,
    allScenario,
    testPlanResultDetails,
  } = useSelector((state) => state.TestPlanReducer);
  const [refRunNumberForPreview, setRefRunNumberForPreview] = useState(undefined);
  const [refRunNumberLocaleForPreview, setRefRunNumberLocaleForPreview] = useState(undefined);

  useEffect(() => {
    dispatch(fetchPreviewDownloadsByRunNumber(runNumber, resultType, "", "", true));
    dispatch(fetchSameLocaleForPreviewByRunNumber(runNumber));
  }, [resultType]);

  useEffect(() => {
    dispatch(setResultType("All"));
  }, []);
  
  return (
    <Spin spinning={scenarioLoading} tip=" Loading" className="spinner-center">
      <Row>
        <Col span={24}>
          <div className="display-flex justify-space-between ml-20 mr-20 pt-10">
            <h1 className="text-transform-uppercase font-size-16 font-weight-600">
              PREVIEW STEP SCREENSHOTS FOR RUN NUMBER : {runNumber}
            </h1>
            <SelectScenarios orgId={orgId} testPlanResultDetails={testPlanResultDetail} runNumber={runNumber} />
          </div>
          <div className="ml-20 mr-20 ">
            <Radio.Group
              value={selectedComparisonPreviewView}
              onChange={(e) =>
                dispatch(
                  changeSelectedComparisonPreviewView(
                    e.target.value,
                    runNumber,
                    resultType ? resultType : "All",
                    refRunNumberForPreview,
                    refRunNumberLocaleForPreview
                  )
                )
              }
              buttonStyle="solid">
              <Tooltip color={TOOLTIP_BG_COLOR} title="For each Locale, review Screenshots against each step">
                <Radio.Button value={COMPARISON_SINGLE_LOCALE}>Review By Locale</Radio.Button>
              </Tooltip>
              <Tooltip color={TOOLTIP_BG_COLOR} title="For each step, review Screenshots of all Locales side by side.">
                <Radio.Button value={COMPARISON_ALL_LOCALES}>Review All Locales By Step</Radio.Button>
              </Tooltip>
            </Radio.Group>
          </div>
          <RefRunNumberPanel
            runNumber={runNumber}
            resultType={resultType}
            setRefRunNumberForPreview={setRefRunNumberForPreview}
            setRefRunNumberLocaleForPreview={setRefRunNumberLocaleForPreview}
            refRunNumberForPreview={refRunNumberForPreview}
            refRunNumberLocaleForPreview={refRunNumberLocaleForPreview}
          />
          <>
            <div className="display-flex justify-space-between ml-20 mr-20 p-10">
              <div>
                {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
                  <>
                    <Checkbox
                      indeterminate={!selectedAllScenario && selectedTestScenarioForResultLogs.length > 0}
                      checked={allScenario.length === selectedTestScenarioForResultLogs.length}
                      onChange={(value) => {
                        dispatch(changeSelectedAllScenarioOption(value.target.checked));
                      }}>
                      Select All
                    </Checkbox>
                    <Button
                      className="font-size-14 "
                      type="primary"
                      disabled={selectedTestScenarioForResultLogs?.length === 0}
                      onClick={() =>
                        selectedTestScenarioForResultLogs?.length !== 0
                          ? dispatch(downloadResultLogs("RUN_" + runNumber))
                          : undefined
                      }>
                      Download Screenshots
                    </Button>
                  </>
                )}
              </div>
            </div>
            {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
              <TestPlanResultPreviewImages
                refRunNumberLocaleForPreview={refRunNumberLocaleForPreview}
                refRunNumberForPreview={refRunNumberForPreview}
              />
            )}
            {selectedComparisonPreviewView === COMPARISON_ALL_LOCALES && (
              <TestPlanResultDetailsScenario className="mt-50">
                {previewRunDetails &&
                  previewRunDetails?.map((v, k) => {
                    return (
                      <StyledCollapse className="border-none" expandIconPosition="right" key={k}>
                        <Panel
                          showArrow={true}
                          header={
                            <ScenarioCardForPreviewLocale
                              key={k}
                              scenarioData={v}
                              selectedTestScenarioForResultLogs={selectedTestScenarioForResultLogs}
                              selectedComparisonPreviewView={selectedComparisonPreviewView}
                            />
                          }>
                          <div className="pl-20 pr-20">
                            <TestPlanResultAllLocalePreviewImages
                              scenarioDetails={v}
                              refRunNumberLocaleForPreview={refRunNumberLocaleForPreview}
                              refRunNumberForPreview={refRunNumberForPreview}
                              previewRunDetails={previewRunDetails}
                              scenarioLoading={scenarioLoading}
                              selectedTestScenarioForResultLogs={selectedTestScenarioForResultLogs}
                              selectedComparisonPreviewView={selectedComparisonPreviewView}
                            />
                          </div>
                        </Panel>
                      </StyledCollapse>
                    );
                  })}
                {!scenarioLoading && _.isEmpty(previewRunDetails) && <EmptyRecords description="No Data Found" />}
              </TestPlanResultDetailsScenario>
            )}
          </>
        </Col>
      </Row>
    </Spin>
  );
};

export default TestPlanResultDetailsPreview;
