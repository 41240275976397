import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "antd";
import { CLOSE_ICON_RED } from "../../../../Constants/SvgConstants";
import { getSelectedElementText } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { selectElement, toggleOtherElementPopover } from "../../../../actions/Recording";
import ElementListV2 from "./ElementListV2";
import styles from "./OtherElementsOptionsModalV2.module.scss";

const getOtherElementText = (element, index, selectedElementPath) => {
  let textElement = "";
  let text = getSelectedElementText(element);

  const renderElement = (label, value) => (
    <div className={styles["select_Element_Text"]}>
      <div className={styles["index_And_Text"]}>
        {index + 1}
        <span>{label} :</span>
      </div>
      <div
        className={`${styles["text"]} ${selectedElementPath !== element?.path && styles["element_Not_Selected_Text"]}`}
      >
        {value}
      </div>
    </div>
  );

  if (element.attributes) {
    if (text !== "") {
      textElement = renderElement("Text", text);
    } else if (element.attributes.class) {
      textElement = renderElement("Class", element.attributes.class);
    } else if (element.attributes.type) {
      textElement = renderElement("Type", element.attributes.type);
    }
  }

  return textElement;
};

const getOtherNonClickableElement = (element, index, selectedElementPath) => {
  if (!element.attributes) return null;

  const { text, "content-desc": contentDesc, class: className } = element.attributes;
  const displayText = text || contentDesc || className;
  const label = text ? "Text" : contentDesc ? "Text" : "Class";

  return (
    <div className={styles["select_Element_Text"]}>
      <div className={styles["index_And_Text"]}>
        {index + 1}
        <span>{label} :</span>{" "}
      </div>
      <div
        className={`${styles["text"]} ${selectedElementPath !== element?.path && styles["element_Not_Selected_Text"]}`}
      >
        {displayText}
      </div>
    </div>
  );
};

const OtherElementsOptionsModalV2 = (props) => {
  const dispatch = useDispatch();
  const { serverId, targetUniqueId, selectedPath, elementPath, x, y } = props;
  const {
    otherElementsRelatedToCoordinates,
    selectedElementPath,
    currentDeviceScreenSource,
    otherElementCoordinateVisible,
  } = useSelector((state) => state.RecordingReducer);

  const [suggestedElement, setSuggestedElement] = useState([]);
  const [otherElement, setOtherElement] = useState([]);

  useEffect(() => {
    if (otherElementsRelatedToCoordinates && otherElementsRelatedToCoordinates.length > 0) {
      setSuggestedElement(
        otherElementsRelatedToCoordinates.filter(
          (item) =>
            (item.attributes && item.attributes.clickable && item.attributes.clickable === "true") ||
            (item.attributes.visible && item.attributes.visible === "true")
        )
      );
      setOtherElement(
        otherElementsRelatedToCoordinates.filter((item) => item.attributes && item.attributes.clickable === "false")
      );
    } else {
      setSuggestedElement([]);
      setOtherElement([]);
    }
  }, [otherElementsRelatedToCoordinates]);

  const handleElementClick = (path) => {
    dispatch(toggleOtherElementPopover(false));
    dispatch(selectElement(path, currentDeviceScreenSource, serverId, targetUniqueId, x, y));
  };

  return (
    <Modal
      title={
        <div className={styles['title_And_Close_Icon']}>
          <span className={styles["popover_Title"]}>Element Overlapping with selected element</span>
          <div>
            <SvgLoader
              iconName={CLOSE_ICON_RED}
              handleClick={() => dispatch(toggleOtherElementPopover(false))}
              width={40}
              height={40}
            />
          </div>
        </div>
      }
      visible={elementPath === selectedPath && otherElementCoordinateVisible}
      closable={false}
      footer={null}
      width={"60%"}
      className={`rounded_Border_Modal ${selectedPath !== elementPath && "display__none"}`}
    >
      <div className={styles["element_List"]}>
        <ElementListV2
          title="Clickable Elements"
          elements={suggestedElement}
          selectedElementPath={selectedElementPath}
          onClick={handleElementClick}
          getElementText={getOtherElementText}
        />
        <ElementListV2
          title="Non-Clickable Elements"
          elements={otherElement}
          selectedElementPath={selectedElementPath}
          onClick={handleElementClick}
          getElementText={getOtherNonClickableElement}
        />
      </div>
    </Modal>
  );
};

export default OtherElementsOptionsModalV2;

