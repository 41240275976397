import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/Recording";
import { Spin } from "antd";

class Loader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className={"spiner-center " + this.props.className}>
          <Spin spinning={true}></Spin> &nbsp; <span>{this.props.text}</span>
        </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return state.RecordingReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(Loader);
