import React from "react";
import { Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import SvgLoader from "../../../Util/SvgLoader";
import { ACTION_ICON_COLOR, DISABLE_ICON_COLOR, DISABLE_COLOR, BLUE_COLOR } from "../../../Constants/SvgConstants";
import styles from "./TextButton.module.scss";

const TextButton = (props) => {
  const { disabled, title, className, image, icon } = props;
  return (
    <div
      className={`${styles.textButton} ${
        !props.image && (disabled ? `${styles.disableTextButtonText}` : `${styles.textButtonText}`)
      }  ${className}`}
      onClick={() => {
        if (!disabled) {
          props.onClick();
        }
      }}
    >
      {image ? (
        <Tooltip title={title} color={TOOLTIP_BG_COLOR} overlayClassName={styles.tooltipVisible}>
          <div className={`${styles.subHeaderIcons} ${disabled && "cursor__not_allowed"}`}>
            <SvgLoader
              iconName={image}
              iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
              disabled={disabled}
            />
            <span className={`${disabled && "disable_Text"} cursor__pointer`}>{title}</span>
          </div>
        </Tooltip>
      ) : icon ? (
        <Tooltip title={title} color={TOOLTIP_BG_COLOR} overlayClassName={styles.tooltipVisible}>
          <div className={`${styles.actionButtons} ${disabled && styles.disableButton}`}>
            <SvgLoader iconName={icon} iconColor={disabled ? DISABLE_COLOR : BLUE_COLOR} disabled={disabled} />
            <div className={styles.titleText}>{title}</div>
          </div>
        </Tooltip>
      ) : (
        <div>{title}</div>
      )}
    </div>
  );
};

export default TextButton;
