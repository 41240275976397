import _ from "lodash";
import { Button, Drawer } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  disableResetButtonForIdentificationRulesDrawer,
  disableSaveButtonStepForIdentificationRulesDrawer,
  getRecElement,
} from "../../../../Util";
import {
  resetRecTestStepAction,
  saveRecTestStepActionWithCurrentDeviceImage,
  visibleIdentificationRulesDrawer,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import VerificationBuilder from "./VerificationBuilder";
import VerificationGroup from "./VerificationGroup";
import VerifyElementProperty from "./VerifyElementProperty";
import VerifyElementText from "./VerifyElementText";
import styles from "./Verification.module.scss";

const verificationRowComponent = {
  VerifyElementProperty: VerifyElementProperty,
  VerifyElementText: VerifyElementText,
  GROUP: VerificationGroup,
};
const VerificationComponent = (
  operator,
  disabled,
  props,
  containsVerification,
  recStepVerifyElementProperties,
  isPreviewRunning,
  visibleIdentificationRuleDrawer,
  visibleIdentificationRulesDrawer,
  dispatch,
  data,
  unSavedRecStepTag,
  tagForOpenRulesDrawer
) => {
  const { recTestStepList, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
  const unSavedRecElement = getRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements, unSavedRecElementGuid);

  const disableSaveButton = disableSaveButtonStepForIdentificationRulesDrawer(unSavedRecElement);
  const resetDisableButton = disableResetButtonForIdentificationRulesDrawer(unSavedRecElement);

  const applicationStringKeyToCheck =  unSavedRecElement?.recStepVerifyElementProperties?.filter(
    (item) => item.key === "Equals to Locale value of App String" || item?.key === "Contains Locale value of App String"
  );

  const deviceStringKeyToCheck =  unSavedRecElement?.recStepVerifyElementProperties?.filter(
    (item) =>
      item.key === "Equals to Locale value of Device String" || item?.key === "Contains Locale value of Device String"
  );
  const applicationStringsKey = unSavedRecElement?.applicationStrings && applicationStringKeyToCheck?.length === 0;
  const deviceStringsKey = unSavedRecElement?.deviceStrings && deviceStringKeyToCheck?.length === 0;

  return (
    <Drawer
      className={tagForOpenRulesDrawer !== data?.tag && styles.hideRulesDrawer}
      visible={visibleIdentificationRuleDrawer}
      title="Identification Rules"
      onClose={() => dispatch(visibleIdentificationRulesDrawer(false))}
      width={600}
      extra={
        props.recElement?.id ? (
          <div className={styles.footer}>
            <Button
              type="text"
              className="resetButton"
              disabled={resetDisableButton}
              onClick={() => {
                !resetDisableButton && dispatch(resetRecTestStepAction(data?.tag));
              }}
            >
              Reset
            </Button>
            <Button
              type="primary"
              className={styles.saveButton}
              disabled={disableSaveButton}
              onClick={() => {
                !disableSaveButton && dispatch(saveRecTestStepActionWithCurrentDeviceImage(data?.tag, undefined));
                dispatch(visibleIdentificationRulesDrawer(false));
              }}
            >
              Save
            </Button>
          </div>
        ) : (
          ""
        )
      }
    >
      <div className={styles.infoMessage}>
        {applicationStringsKey &&
          "This Step is linked to an Application String. Please add at least one relative identification rule before saving changes to ensure proper functionality."}
        {deviceStringsKey &&
          "This Step is linked to a Device String. Please add at least one relative identification rule before saving changes to ensure proper functionality."}
      </div>
      <span className={styles.blueText}> Apply Rules on Element:</span>
      {operator !== undefined && (
        <VerificationBuilder
          disabled={disabled}
          operator={operator}
          uuid={undefined}
          isFirstRow={true}
          recTestStep={props.data}
          recElement={props.recElement}
        />
      )}
      {!containsVerification && (
        <div className={styles.addVerification}>
          After an Element is located, these Rules will filter Element based on Element Text or Element Properties.
        </div>
      )}
      <div className={styles.verification_items}>
        {recStepVerifyElementProperties &&
          recStepVerifyElementProperties.length > 0 &&
          recStepVerifyElementProperties.map((verification, key) => {
            let Item = verificationRowComponent[verification.type];
            return (
              <React.Fragment key={key}>
                {Item && (
                  <Item
                    key={key}
                    data={verification}
                    recTestStep={props.data}
                    disabled={disabled || isPreviewRunning}
                    recElementVerification={props.recElement}
                    recTestSteps={props.recTestSteps}
                    projectId={props.projectId}
                  />
                )}
                {recStepVerifyElementProperties.length > 1 && recStepVerifyElementProperties.length - 1 !== key && (
                  // verification.type!=="GROUP"  &&
                  <div key={"OPERATOR"} className={styles.verification_operator_label}>
                    {operator}
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </Drawer>
  );
};

export default (props) => {
  const { recTestStepProperty } = props.data;
  const { disabled, recTestSteps } = props;
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { visibleIdentificationRuleDrawer, unSavedRecStepTag, tagForOpenRulesDrawer } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  let recStepVerifyElementProperties,
    operator = [];
  if (props.recElement) {
    recStepVerifyElementProperties = props.recElement.recStepVerifyElementProperties;
    operator = props.recElement.verificationOperator;
  } else {
    recStepVerifyElementProperties = recTestStepProperty?.recStepVerifyElementProperties;
    operator = recTestStepProperty?.operator;
  }
  const containsVerification = recStepVerifyElementProperties && recStepVerifyElementProperties.length > 0;

  return (
    <React.Fragment>
      {VerificationComponent(
        operator,
        disabled,
        props,
        containsVerification,
        recStepVerifyElementProperties,
        isPreviewRunning,
        visibleIdentificationRuleDrawer,
        visibleIdentificationRulesDrawer,
        dispatch,
        props.data,
        unSavedRecStepTag,
        tagForOpenRulesDrawer
      )}
    </React.Fragment>
  );
};
