import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Row, Select, Tabs } from "antd";
import { getChildComponentByTypeAndParentId } from "../../../actions/CommonActions";
import {
  getMyTeamMembers,
  myProjectsFromTeamId,
  saveProject,
  visibleCreateProjectModal,
} from "../../../actions/ProjectsAction";
import { getMyOrgUsers } from "../../../actions/TeamsAction";
import { xpressComponentType } from "../../../Constants";
import { LOGO } from "../../../Constants/SvgConstants";
import history from "../../../history";
import { isNullOrUndefinedOrEmpty, showNotification } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import CommonHeaderV2 from "../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import CommonModalV2 from "../../CommonComponents/CommonModalV2/CommonModalV2";
import InviteMemberModalV2 from "../../CommonComponents/InviteMemberV2/InviteMemberModalV2";
import VerticalLine from "../../CommonComponents/VerticalLine";
import BackButton from "../../CoreComponents/BackButtonComponent/BackButton";
import MembersByTeamV2 from "../../ProjectsV2/MembersByTeamV2/MembersByTeamV2";
import CreateProjectV2 from "../../ProjectsV2/ProjectDetailsV2/CreateProjectV2/CreateProjectV2";
import ProjectCardsV2 from "../../ProjectsV2/ProjectDetailsV2/ProjectCardsV2";
import styles from "./MyProjectsByTeamV2.module.scss";

const { Option } = Select;
const { TabPane } = Tabs;

export const MyProjectsByTeamV2 = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [selectedTab, setSelectedTab] = useState("teamMembers");
  const [projectData, setProjectData] = useState({
    name: "",
    appName: "",
    description: "",
    teamMembers: [],
    orgMembers: [],
    teamId: props.match.params.teamID,
    platformTypeId: null,
  });

  const orgId = props.match.params.organizationId;
  const dispatch = useDispatch();
  const { childComponentList } = useSelector((state) => state.CommonReducer);
  const { createProjectModalVisible } = useSelector((state) => state.ProjectsReducer);

  useEffect(() => {
    if (props.match.params.teamID != null) {
      dispatch(myProjectsFromTeamId(props.match.params.teamID));
      dispatch(getMyTeamMembers(props.match.params.teamID));
    }
  }, []);

  useEffect(() => {
    if (props.route && props.route.componentId) {
      dispatch(
        getChildComponentByTypeAndParentId({
          componentType: xpressComponentType.rights,
          parentId: props.route.componentId,
        })
      );
    }
    dispatch(getMyOrgUsers(props.match.params.organizationId));
  }, []);

  const { myProjectsList, myTeamMembers, teamNameOfProject } = useSelector((state) => state.ProjectsReducer);
  const children = [];
  const { myOrgUsers } = useSelector((state) => state.TeamsReducer);

  if (myOrgUsers) {
    for (let i = 0; i < myOrgUsers.length; i++) {
      children.push(<Option key={myOrgUsers[i].email}>{myOrgUsers[i].email}</Option>);
    }
  }

  const modalClick = () => {
    setModalStatus(true);
  };
  let removeUserRights =
    childComponentList && childComponentList.filter((item) => item.name == "Remove_User").length > 0;

  const handleSaveButton = () => {
    if (projectData?.name.length > 0) {
      if (projectData?.teamId == null) {
        return showNotification("error", "Please Select Team.", "");
      }
      let preparedObject = {
        ...{ ...projectData, description: projectData?.description !== "" ? projectData?.description : null },
        orgId: orgId,
      };
      dispatch(saveProject(preparedObject, props.match.params.teamID));
    } else {
      showNotification("error", "Please Insert Project Name.", "");
    }
    setProjectData({
      name: "",
      appName: "",
      description: "",
      teamMembers: [],
      orgMembers: [],
      teamId: props.match.params.teamID,
      platformTypeId: null,
    });
  };

  const isDisable = useMemo(() => {
    return (
      isNullOrUndefinedOrEmpty(projectData?.appName) ||
      isNullOrUndefinedOrEmpty(projectData?.name) ||
      isNullOrUndefinedOrEmpty(projectData?.platformTypeId)
    );
  }, [projectData]);

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["header"]}>
        <div className="display__flex align_Items__Center">
          <Link to="/">
            <a>
              <SvgLoader iconName={LOGO} width="2.85rem" height="2.90rem" />
            </a>
          </Link>
          <VerticalLine className={styles["border_Right"]} />
          <span className={styles["header_Title"]}>Development Team</span>
        </div>
        <div className="display__flex align_Items__Center">
          <div className={styles["back_Icon"]}>
            <BackButton handleBack={() => history.push(`/${orgId}/teams`)} />
          </div>
        </div>
      </Row>
      <div className="mt-70">
        <div>
          <Tabs
            className="m-10"
            defaultActiveKey={"teamMembers"}
            activeKey={selectedTab}
            onChange={(e) => setSelectedTab(e)}
          >
            <TabPane tab={"Team Members"} key={"teamMembers"}>
              <div className="mb-20">
                <CommonHeaderV2
                  headerText={"Team Members"}
                  label={"Add Member"}
                  handleOk={() => {
                    modalClick();
                  }}
                  className={styles["sub_Header_Text"]}
                />
              </div>
              <MembersByTeamV2 myTeamMembers={myTeamMembers} removeUserRights={removeUserRights} />
            </TabPane>
            <TabPane tab={"Assigned Projects"} key={"assignedProjects"}>
              <div className="mb-20">
                <CommonHeaderV2
                  headerText={"Assigned Projects"}
                  label={"Add Project"}
                  handleOk={() => {
                    dispatch(visibleCreateProjectModal(true));
                  }}
                  className={styles["sub_Header_Text"]}
                />
              </div>
              <ProjectCardsV2 data={myProjectsList} orgId={orgId} teamNameOfProject={teamNameOfProject} />
            </TabPane>
          </Tabs>
        </div>
        <InviteMemberModalV2
          modalClosed={() => {
            setModalStatus(false);
          }}
          modalStatus={modalStatus}
          teamId={props.match.params.teamID}
          orgId={orgId}
          addMembers={true}
        />
      </div>
      <CommonModalV2
        modalVisible={createProjectModalVisible}
        modalClose={() => {
          setProjectData({
            name: "",
            appName: "",
            description: "",
            teamMembers: [],
            orgMembers: [],
            teamId: props.match.params.teamID,
            platformTypeId: null,
          });
          dispatch(visibleCreateProjectModal(false));
        }}
        cancelText="Cancel"
        saveBtnDisabled={isDisable}
        handleSubmit={() => handleSaveButton()}
        children={
          <CreateProjectV2
            orgId={orgId}
            projectData={projectData}
            setProjectData={setProjectData}
            teamNameOfProject={teamNameOfProject}
          />
        }
      />
    </>
  );
};

export default MyProjectsByTeamV2;
