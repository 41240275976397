import { async } from "../api/automation";
import { GET_AWS_DEVICE_FARM_BUILDS, GET_AWS_DEVICE_FARM_POOLS, GET_AWS_DEVICE_FARM_PROJECTS } from "../Constants";
import { updateResponse } from "../Util";

export function getAwsDeviceFarmProjects() {
  return async (dispatch) => {
    return async("awsDeviceFarmProject/", "GET", {}).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_AWS_DEVICE_FARM_PROJECTS, response });
      dispatch({ type: GET_AWS_DEVICE_FARM_POOLS, response: { data: [] } });
    });
  };
}

export function getAwsDeviceFarmPools(data) {
  return async (dispatch) => {
    return async("awsDeviceFarmPool/getByAwsDeviceFarmProject", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_AWS_DEVICE_FARM_POOLS, response });
    });
  };
}

export function getAwsDeviceFarmBuilds(data) {
  return async (dispatch) => {
    return async("awsDeviceFarmBuild/getAwsUploads", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_AWS_DEVICE_FARM_BUILDS, response });
    });
  };
}
