import React from "react";
import ShowTooltipV2 from "../../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./AllJobsDetailsColumnsV2.module.scss";

const TestPlanColumnV2 = ({ row }) => {

  return (
    <>
      <div className={styles["test_Plan_Name_Label"]}>
        <span className={styles["label"]}>Name: </span>
        <span>
          <ShowTooltipV2
            text={row?.testPlanName}
            className={styles["test_Plan_Name"]}
          />
        </span>
      </div>
      <div>
        <span className={styles["label"]}>Test Details:</span>
        <span className={styles["label-name"]}>
          {" "}
          Scenarios: {row?.totalUniqueScenarios}, Steps: {row?.testPlanDetail?.totalSteps}, Screenshots:{" "}
          {row?.totalUniqueScreenShot}
        </span>
      </div>
      <div>
        <span className={styles["label"]}>Test Data Profile:</span>{" "}
        <span className={styles["label-name"]}>
          {" "}
          {row?.testDataProfile && row?.testDataProfile?.name ? row?.testDataProfile?.name : ""}
        </span>
      </div>
    </>
  );
};

export default TestPlanColumnV2;
