import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, Select } from "antd";
import { isEmpty } from "lodash";
import { DEVICE_TYPE, SUCCESS_STATUS } from "../../../Constants";
import { getDeviceName } from "../../../Util";
import { editDeviceList, getOrgDevice } from "../../../actions/OrgDeviceAction";
import styles from "./AddOrEditDeviceDetailsModalV2.module.scss";

const { Option } = Select;

const AddOrEditDeviceDetailsModalV2 = ({
  modalStatus,
  modalClosed,
  orgId,
  myProjectsList,
  myOrgUsers,
  searchKeyword,
  editDataList,
  mode,
}) => {
  const dispatch = useDispatch();
  const { postAssignDeviceRes, isLoading, currentOrgDevicePage, getAllDevice } = useSelector(
    (state) => state.OrgDeviceReducer
  );

  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState();

  useEffect(() => {
    if (mode === "edit" && editDataList) {
      initializeEditData();
    }
  }, [editDataList, mode]);

  const initializeEditData = () => {
    if (editDataList?.projects?.length > 0) {
      setSelectedProject(editDataList.projects.map((i) => JSON.stringify(i.projectName)));
    }
    if (editDataList?.users?.length > 0) {
      setSelectedUser(editDataList.users.map((i) => JSON.stringify(i.email)));
    }
    setSelectedDeviceType(editDataList.isSharedDevice === 1 ? DEVICE_TYPE.SHARED : DEVICE_TYPE.CUSTOM);
  };

  const resetData = () => {
    setSelectedDevice([]);
    setSelectedProject([]);
    setSelectedUser([]);
    setSelectedDeviceType();
  };

  const isValid = () => {
    return selectedDeviceType && (mode === "add" ? selectedDevice.length > 0 : true);
  };

  const handleSave = () => {
    const deviceObject = {
      orgId,
      targetUniqueIds: mode === "edit" ? [editDataList.targetUniqueId] : selectedDevice,
      projectIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedProjectIds,
      userIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedUserIds,
      isSharedDevice: selectedDeviceType === DEVICE_TYPE.SHARED ? 1 : 0,
    };

    dispatch(editDeviceList(deviceObject, searchKeyword, handleCloseModal));
  };

  const disabledSubmitButton =
    editDataList?.isSharedDevice === (selectedDeviceType === DEVICE_TYPE.CUSTOM ? 0 : 1) &&
    editDataList?.projects?.length === selectedProject?.length &&
    editDataList?.users?.length === selectedUser?.length;

  const handleCloseModal = () => {
    modalClosed();
    if (mode === "edit" && !disabledSubmitButton) {
      dispatch(
        getOrgDevice({ orgId, pageNumber: currentOrgDevicePage, recordPerPage: 10, searchKeywords: searchKeyword })
      );
    }
    resetData();
  };

  const selectedProjectName = selectedProject.map((name) => name.substring(1, name.length - 1));
  const selectedProjectIds = useMemo(
    () => myProjectsList?.filter((i) => selectedProjectName.includes(i.projectName))?.map((i) => i.projectId),
    [myProjectsList, selectedProjectName]
  );

  const selectedUserName = selectedUser.map((name) => name.substring(1, name.length - 1));
  const selectedUserIds = useMemo(
    () => myOrgUsers?.filter((i) => selectedUserName.includes(i.email))?.map((i) => i.id),
    [myOrgUsers, selectedUserName]
  );

  const renderProjects = () => {
    return myProjectsList
      ?.filter((x) => mode === "add" || x.platform === editDataList?.platformType?.name)
      ?.map((project) => <Option value={JSON.stringify(project.projectName)}>{project.projectName}</Option>);
  };

  const deviceList = [];
  if (!isEmpty(getAllDevice)) {
    for (let i = 0; i < getAllDevice.length; i++) {
      deviceList.push(
        <Option key={getAllDevice[i].targetUniqueId}>
          {getDeviceName(getAllDevice[i])} ({getAllDevice[i].osVer})
        </Option>
      );
    }
  }

  useEffect(() => {
    if (postAssignDeviceRes && postAssignDeviceRes.status === SUCCESS_STATUS) {
      handleCloseModal();
    }
  }, [postAssignDeviceRes]);

  return (
    <Modal
      title={<span className={styles["modal_Title"]}>{mode === "edit" ? "Edit Device" : "Device Assignment"}</span>}
      visible={modalStatus}
      onOk={handleCloseModal}
      onCancel={handleCloseModal}
      closable={false}
      footer={[
        <div className="modal-Content-Btn">
          <Button key="cancel" className={`modal_Buttons modal_Cancel_Btn`} onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className={`modal_Buttons modal-Ok-Btn`}
            onClick={handleSave}
            loading={isLoading}
            disabled={mode === "edit" ? disabledSubmitButton : !isValid()}
          >
            {mode === "edit" ? "Submit" : "Assign"}
          </Button>
        </div>,
      ]}
    >
      {mode === "add" && (
        <div className={styles["custom-input-wrapper"]}>
          <div className={styles["custom-input-label"]}>Select Device:</div>
          <Select
            mode="tags"
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e)}
            placeholder="Please select device"
            tokenSeparators={[","]}
            className={styles["modal-select-box"]}
          >
            {deviceList}
          </Select>
        </div>
      )}
      <div className={styles["custom-input-wrapper"]}>
        <div className={styles["custom-input-label"]}>Device Sharing:</div>
        <Select
          value={selectedDeviceType}
          onChange={setSelectedDeviceType}
          placeholder="Select device sharing"
          className={styles["modal-select-box"]}
        >
          <Option value={DEVICE_TYPE.SHARED}>Share with All Projects</Option>
          <Option value={DEVICE_TYPE.CUSTOM}>Custom</Option>
        </Select>
      </div>
      {selectedDeviceType === DEVICE_TYPE.CUSTOM && (
        <>
          <div className={styles["custom-input-wrapper"]}>
            <div className={styles["custom-input-label"]}>Share With Projects:</div>
            <Select
              mode="multiple"
              value={selectedProject}
              onChange={(e) => setSelectedProject(e)}
              placeholder="Select projects"
              tokenSeparators={[","]}
              className={styles["modal-select-box"]}
            >
              {renderProjects()}
            </Select>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AddOrEditDeviceDetailsModalV2;
