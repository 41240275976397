import { message } from "antd";
import _ from "lodash";
import { Cookies } from "react-cookie";
import { async, sync } from "../api/automation";
import {
  ACQUIRE_DEVICE,
  ACQUIRE_RELEASE_DEVICE_REQUEST,
  ANDROID,
  CHANGE_CURRENT_DEVICE_NOTE,
  CHANGE_DELETED_DEFAULT_APP_LIST,
  CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET,
  CURRENT_DEVICE_EDIT_NOTE_UDID,
  CURRENT_DEVICE_NOTE_HISTORY,
  DEVICE_FARM_LIST,
  DEVICE_LOADING,
  GET_DEVICE,
  HIDE_DEVICE_NOTE_MODAL,
  HIDE_SET_DEFAULT_APP_MODAL,
  INVALID_USER,
  LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP,
  LIST_OF_DEVICE_DEFAULT_APPS,
  RELEASE_DEVICE,
  RELEASE_DEVICE_BY_ID,
  REMOVE_DEVICE_INTERVAL_ID,
  RESET_DEVICE_FARM_LIST,
  RESET_STATE,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS,
  SEARCH_DEVICE_BY_FARM,
  RESET_SEARCH_DEVICE_BY_FARM,
  SERVER_DEVICES,
  SERVER_LIST,
  SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN,
  SET_DEVICE_INTERVAL_ID,
  SHOW_SET_DEFAULT_APP_MODAL,
  SUCCESS_STATUS,
  UPDATE_DEVICE_MODAL,
  UPDATE_DEVICE_NOTE,
  DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN,
  VISIBLE_DEVICE_MORE_DETAILS_MODAL,
  FETCHING_DEVICE_LIST,
  RELEASE_DEVICE_LOADING,
} from "../Constants";
import history from "../history";
import { updateResponse } from "./../../src/Util";
import {
  backToAppManagement,
  flushTheDashBoardAttribute,
  startDeviceScreenStreaming as startStreaming,
  stopAppiumStreaming,
} from "./Recording";

const cookies = new Cookies();

export function getServerList(data) {
  return async (dispatch) => {
    return async("server/", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: SERVER_LIST, response });
    });
  };
}

export function resetDeviceFarmList() {
  return async (dispatch, getState) => {
    dispatch({ type: RESET_SEARCH_DEVICE_BY_FARM});
  };
}

export function getDeviceFarmList(data) {
  return async (dispatch) => {
    dispatch({ type: DEVICE_LOADING, response: { isLoading: true } });
    dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
    return async("deviceFarm/", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: DEVICE_FARM_LIST, response });
        if (response.data.length === 1) {
          dispatch(getDevices(response.data[0].id));
        }
      }
      if (localStorage.getItem("xpressDeviceToken") === null) {
        dispatch(generateDeviceToken());
      }
      dispatch({ type: DEVICE_LOADING, response: { isLoading: false } });
    });
  };
}

export function resetDeviceList() {
  return async (dispatch, getState) => {
    dispatch({ type: RESET_DEVICE_FARM_LIST });
  };
}

export function getDevices(deviceFarmId, setDeviceCleanConfig = false) {
  return async (dispatch, getState) => {
    let selectedDeviceFarm = getState().ServerListReducer?.deviceFarmList?.filter((item) => item.id === deviceFarmId);
    if (selectedDeviceFarm?.length === 1) {
      selectedDeviceFarm = selectedDeviceFarm[0];
      if (selectedDeviceFarm?.id && selectedDeviceFarm?.id !== "" && selectedDeviceFarm.id !== "undefined") {
        let devices = [];
        dispatch(generateDeviceToken());
        dispatch({ type: DEVICE_LOADING, response: { isLoading: true } });
        if (!_.isEmpty(selectedDeviceFarm["serverDetails"])) {
          selectedDeviceFarm["serverDetails"].map((server) => {
            let response = sync("device/getDevices", "GET", { deviceFarmId: deviceFarmId }, server.serverIp);
            response = updateResponse(response);
            !_.isEmpty(response["data"]) &&
              response["data"].forEach((device) => {
                if (device.miscDetails.deviceDetail.autoServerDetail.serverIp === server.serverIp) {
                  device["serverDetail"] = server;
                  device["deviceFarm"] = { id: deviceFarmId };
                  devices.push(device);
                }
              });
            dispatch({ type: GET_DEVICE, response });
            dispatch({ type: DEVICE_LOADING, response: { isLoading: false } });
            dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: false } });
          });
        } else {
          dispatch({ type: DEVICE_LOADING, response: { isLoading: false } });
          dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: false } });
        }
        dispatch({ type: SERVER_DEVICES, data: selectedDeviceFarm, devices });
        if (setDeviceCleanConfig) {
          dispatch({ type: SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS });
        }
      }
    } else {
      return dispatch({ type: SERVER_DEVICES, data: selectedDeviceFarm, response: undefined });
    }
  };
}

export function getDeviceByFarmId(
  data,
  device,
  setDeviceCleanConfig = false,
  isForFirstTime = false,
  isSearch = false
) {
  return async (dispatch, getState) => {
    let selectedDeviceFarm = getState().ServerListReducer?.deviceFarmList?.filter((item) =>
      data.farmId.includes(item.id)
    );
    let serversForDevices = [];
    selectedDeviceFarm.forEach((element) => {
      if (element["serverDetails"]) {
        element["serverDetails"].forEach((item) => {
          var i = serversForDevices.findIndex((x) => x.id === item.id);
          if (i <= -1) {
            serversForDevices.push(item);
          }
        });
      }
    });
    dispatch(generateDeviceToken());
    if (serversForDevices.length > 0) {
      if (isForFirstTime) {
        dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
      }
      const promises = [];
      for (let i = 0; i < serversForDevices.length; i++) {
        const server = serversForDevices[i];
        const promise = new Promise((resolve) =>
          async(
            "device/getDevices",
            "GET",
            {
              deviceFarmId: data.farmId,
              deviceName: data.deviceName,
              deviceFarmName: data.deviceFarmName,
              osVersion: data.osVersion,
              searchKeyword: data.searchKeyword,
              deviceFarmUse: data.deviceFarmUse,
              serverId: server.id,
            },
            server.serverIp,
            undefined,
            dispatch
          )
            .then((response) => {
              response = updateResponse(response);
              response["data"] &&
                response["data"].forEach((device) => {
                  device["serverDetail"] = server;
                });

              resolve(response["data"] || []);
            })
            .catch(() => {
              resolve([]);
            })
        );
        promises.push(promise);
      }
      dispatch({ type: FETCHING_DEVICE_LIST, payload: true, isSearch });

      await Promise.all(promises).then((response) => {
        const flattenedArray = response.flat();
        dispatch({ type: SEARCH_DEVICE_BY_FARM, response: flattenedArray });
        if (setDeviceCleanConfig) {
          dispatch({ type: SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS });
        }
      });
      dispatch({ type: FETCHING_DEVICE_LIST, payload: false, isSearch: false });
      dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: false } });
    } else {
      dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: false } });
      return dispatch({ type: SERVER_DEVICES, data: selectedDeviceFarm, response: undefined });
    }
  };
}

export function acquireDevice(device, serverDetail) {
  return async (dispatch, getState) => {
    dispatch({ type: ACQUIRE_RELEASE_DEVICE_REQUEST, device });
    let serverIp = serverDetail.serverIp.split(":");
    return async(
      "device/acquireDevice",
      "POST",
      {
        deviceFarmId: getState().ServerListReducer.deviceFarmId,
        targetUniqueId: device.targetUniqueId,
        serverId: serverDetail.id,
        serverIp: serverIp[0] + ":" + serverIp[1],
      },
      serverDetail.serverIp,
      undefined,
      dispatch
    )
      .then((response) => {
        response = updateResponse(response);
        dispatch({ type: ACQUIRE_DEVICE, device, response });
      })
      .then((response) => {
        dispatch(getDevices(getState().ServerListReducer.deviceFarmId));
      });
    // return dispatch(getServerDevices({serverId:getState().ServerListReducer.serverId}));
  };
}

export function releaseDevice(device, serverDetail, projectId, farmIds) {
  return async (dispatch, getState) => {
    dispatch({ type: ACQUIRE_RELEASE_DEVICE_REQUEST, device });
    dispatch({
      type: RELEASE_DEVICE_LOADING,
      response: { releaseDeviceTargetUniqueId: device?.targetUniqueId },
    });
    // Set localStorage value when API is pending
    localStorage.setItem(
      "releaseDevicePending",
      JSON.stringify({
        device,
        serverDetail,
        projectId,
        farmIds,
        status: 'pending',
      })
    );
    try {
      const response = await async(
        "device/releaseDevice",
        "POST",
        { targetUniqueId: device.targetUniqueId, serverId: serverDetail.id },
        serverDetail.serverIp,
        undefined,
        dispatch
      );
      
      const updatedResponse = updateResponse(response);
      if (response?.status === SUCCESS_STATUS) {
        localStorage.setItem(
          "releaseDevice",
          JSON.stringify({
            device,
            serverDetail,
            projectId,
            farmIds,
          })
        );
        setTimeout(() => {
          localStorage.removeItem("releaseDevice");
        }, 1000);
        dispatch({ type: RELEASE_DEVICE, device, response: updatedResponse });
        await dispatch(getDeviceFarmList({ projectId: projectId }));
        await dispatch(getDevices(getState().ServerListReducer.deviceFarmId));
        await dispatch(
          getDeviceByFarmId({
            farmId: farmIds,
            deviceName: "",
            deviceFarmName: "",
            osVersion: "",
            searchKeyword: "",
            deviceFarmUse: "",
          })
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      localStorage.removeItem("releaseDevicePending");
      dispatch({
        type: RELEASE_DEVICE_LOADING,
        response: { releaseDeviceTargetUniqueId: null },
      });
    }
  };
}

export function releaseDeviceByAdmin(device, serverDetail, deviceFarmId) {
  return async (dispatch, getState) => {
    dispatch({ type: ACQUIRE_RELEASE_DEVICE_REQUEST, device });
    if (device["platform"] === ANDROID) {
      dispatch(stopAppiumStreaming(serverDetail.id, device.targetUniqueId));
    } else {
      dispatch(stopAppiumStreaming(serverDetail.id, device.targetUniqueId));
    }
    return async(
      "device/releaseDevice",
      "POST",
      { targetUniqueId: device.targetUniqueId, serverId: serverDetail.id },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    )
      .then((response) => {
        response = updateResponse(response);
        dispatch({ type: RELEASE_DEVICE, device, response });
      })
      .then((response) => {
        dispatch(getDevices(getState().ServerListReducer.deviceFarmId));
      });
  };
}
export function releaseDeviceByDeviceId(serverId, targetUniqueId, isDeviceLogStarted) {
  return async (dispatch, getState) => {
    return async(
      "device/releaseDevice",
      "POST",
      {
        targetUniqueId: targetUniqueId,
        serverId: serverId,
        isDeviceLogStarted: isDeviceLogStarted,
      },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      dispatch({ type: RELEASE_DEVICE_BY_ID, response });
    });
  };
}
// export function configureLocalService(data) {
//   return async (dispatch,getState) => {
//     dispatch({type:CONFIGURE_LOCAL_SERVICE_SETTING,data})
//     return async('device/getDevices', 'GET', {},data.callToLocalServer)
//           .then(response => {
//             response=updateResponse(response);
//             dispatch({type:SERVER_DEVICES,data,response})
//           });
// };
// }
export function updateDeviceDetail(device) {
  return async (dispatch) => {
    return dispatch({ type: UPDATE_DEVICE_MODAL, device: device });
  };
}
export function updateInvalidUser(data) {
  return async (dispatch) => {
    return dispatch({ type: INVALID_USER, data: data });
  };
}
export function removeChildComponent() {
  return (dispatch) => {
    dispatch({ type: "REMOVE_CHILD_COMPONENT", undefined });
  };
}
export function startDeviceScreenStreaming(platform, serverId, targetUniqueId, deviceFarmId) {
  return (dispatch, getState) => {
    let port = 0;
    if (getState().ServerListReducer.selectedServerDevices != null) {
      let devices = getState().ServerListReducer.selectedServerDevices;
      devices = devices.filter((item) => item.device.targetUniqueId == targetUniqueId);
      port = devices[0].deviceMinicapNodeServicePort;
    }
    dispatch(startStreaming(platform, serverId, targetUniqueId, deviceFarmId, port));
  };
}
export function getDeviceNodeServicePort(targetUniqueId) {
  return (dispatch, getState) => {
    let port = 0;
    if (getState().ServerListReducer.selectedServerDevices != null) {
      let devices = getState().ServerListReducer.selectedServerDevices;
      devices = devices.filter((item) => item.device.targetUniqueId == targetUniqueId);
      port = devices[0].deviceMinicapNodeServicePort;
    }
    return port;
  };
}

export function setCurrentDeviceEditNoteUdid(targetUniqueId, deviceNote) {
  return (dispatch) => {
    return dispatch({
      type: CURRENT_DEVICE_EDIT_NOTE_UDID,
      targetUniqueId: targetUniqueId,
      deviceNote: deviceNote,
    });
  };
}

export function getDeviceNotesHistory(targetUniqueId) {
  return async (dispatch, getState) => {
    return async("deviceNoteHistory/getByDeviceId", "GET", { targetUniqueId: targetUniqueId }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: CURRENT_DEVICE_NOTE_HISTORY, response });
    });
  };
}

export function onChangeDeviceNotes(deviceNote) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_CURRENT_DEVICE_NOTE, value: deviceNote });
  };
}

export function hideDeviceNotedModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DEVICE_NOTE_MODAL });
  };
}

export function saveDeviceNote(targetUniqueId, currentDeviceNote) {
  return async (dispatch, getState) => {
    return async("device/saveNotes", "POST", { targetUniqueId: targetUniqueId, note: currentDeviceNote }).then(
      (response) => {
        response = updateResponse(response);
        dispatch({ type: UPDATE_DEVICE_NOTE, response });
        dispatch(getDevices(getState().ServerListReducer.deviceFarmId));
      }
    );
  };
}
export function goToAppManagement(orgId) {
  return (dispatch) => {
    dispatch(backToAppManagement());
    history.push(`/${orgId}/appmanagement`);
  };
}

export function goToDeviceUsageReport(orgId) {
  return (dispatch) => {
    history.push(`/${orgId}/DeviceUsageReport`);
  };
}
export function goToDeviceFarmScreen(deviceFarmId, organizationId) {
  return (dispatch) => {
    history.push(`/${organizationId}/farms/${deviceFarmId ? deviceFarmId : ""}`);
  };
}
export function flushTheDashBoardAttributeInRecording() {
  return (dispatch) => {
    dispatch(flushTheDashBoardAttribute());
    dispatch({ type: RESET_STATE });
  };
}

export function getDeviceDefaultAppsForDeviceFarm(targetUniqueId, deviceFarmId) {
  return async (dispatch, getState) => {
    return async("appDeviceFarm/getDeviceDefaultApps", "GET", {
      targetUniqueId: targetUniqueId,
      deviceFarmId: deviceFarmId,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: LIST_OF_DEVICE_DEFAULT_APPS, response });
    });
  };
}
export function setCurrentDeviceDefaultApp(targetUniqueId) {
  return (dispatch) => {
    return dispatch({ type: SHOW_SET_DEFAULT_APP_MODAL, targetUniqueId: targetUniqueId });
  };
}
export function hideDeviceSetDefaultAppModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_SET_DEFAULT_APP_MODAL });
  };
}
export function getInstalledAppListForDefaultAppSet(targetUniqueId, serverDetail) {
  return async (dispatch, getState) => {
    async(
      "device/getInstalledThirdPartyApp",
      "POST",
      { targetUniqueId: targetUniqueId, serverId: serverDetail.id },
      getState().RecordingReducer.serverDetail.serverIp,
      undefined,
      dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP, response });
      }
    });
  };
}

export function setDefaultAppsForDevice(targetUniqueId, serverId, deviceFarmId, cleanDeviceSessionValue) {
  //for now we are setting the isDefault true for all
  return async (dispatch, getState) => {
    var cleanUpTheDevice = getState().ServerListReducer.setDeviceCleanOnOpenSession;
    if (cleanUpTheDevice == undefined) {
      cleanUpTheDevice = cleanDeviceSessionValue;
    }
    dispatch({ type: SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE });
    async("appDeviceFarm/defaultAppSaveForDevice", "POST", {
      targetUniqueId: targetUniqueId,
      serverId: serverId,
      deviceFarmId: deviceFarmId,
      listOfAppPackages: getState().ServerListReducer.selectedAppListForDefaultAppSet,
      isDefaultApp: true,
      cleanUpTheDevice: cleanUpTheDevice,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(getDevices(deviceFarmId, true));
        message.success("Saved Device Data Security Configuration successfully");
        // dispatch({type:SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS})
      } else {
        dispatch({ type: SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED });
        message.error("Failed To Save Device Data Security Configuration ");
      }
    });
  };
}
export function deleteDeviceDefaultApp(targetUniqueId, serverId, deviceFarmId) {
  //for now we are setting the isDefault true for all
  return async (dispatch, getState) => {
    dispatch({ type: SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE });
    async("appDeviceFarm/deleteDeviceDefaultApp", "POST", {
      targetUniqueId: targetUniqueId,
      serverId: serverId,
      deviceFarmId: deviceFarmId,
      listOfAppPackages: getState().ServerListReducer.deletedDefaultAppForDeviceList,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS });
        dispatch(getDeviceDefaultAppsForDeviceFarm(targetUniqueId, deviceFarmId));
        message.success("Saved Device Data Security Configuration successfully");
        return true;
      } else {
        dispatch({ type: SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED });
        message.error("Failed To Save Device Data Security Configuration ");
        return false;
      }
    });
  };
}
export function changeTheSelectedPackagesInAppDefault(value) {
  return async (dispatch, getState) => {
    dispatch({ type: CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET, value });
  };
}
export function changeTheDeletedDefaultAppList(value) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_DELETED_DEFAULT_APP_LIST, value });
  };
}
export function setCleanUpDeviceSessionCheckbox(value) {
  return async (dispatch) => {
    dispatch({ type: SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN, value });
  };
}

export function goToSSOConfig() {
  return (dispatch) => {
    dispatch(backToAppManagement());
    history.push("/SSOConfig");
  };
}

export function generateDeviceToken() {
  var self = this;
  return async (dispatch, getState) => {
    return async("device/generateDeviceToken", "GET", {}).then((response) => {
      response = updateResponse(response);
      localStorage.setItem("xpressDeviceToken", response?.data?.deviceToken);
      cookies.set("xpressDeviceToken", response?.data?.deviceToken);
      localStorage.setItem("requestedUser", response?.data?.userName);
      cookies.set("requestedUser", response?.data?.userName);
      if (getState().ServerListReducer.deviceTokenInterval == null) {
        let id = setInterval(() => {
          self.generateDeviceToken();
        }, 1000 * 60 * 59 * 2); //Every 118 Min
        dispatch({ type: SET_DEVICE_INTERVAL_ID, value: id });
      }
    });
  };
}

export function removeDeviceToken() {
  let self = this;
  return (dispatch, getState) => {
    async("device/removeDeviceToken?token=" + localStorage.getItem("xpressDeviceToken"), "PUT", {
      token: localStorage.getItem("xpressDeviceToken"),
    }).then((response) => {});

    cookies.set("xpressDeviceToken", "", { expires: 0 });
    cookies.set("requestedUser", "", { expires: 0 });
    localStorage.removeItem("xpressDeviceToken");
    localStorage.removeItem("requestedUser");
    cookies.remove("xpressDeviceToken");
    cookies.remove("requestedUser");

    if (getState().ServerListReducer.deviceTokenInterval) {
      clearInterval(getState().ServerListReducer.deviceTokenInterval);
      dispatch({ type: REMOVE_DEVICE_INTERVAL_ID, value: "" });
    }
  };
}

export const visibleDeviceMoreDetailsModal = (
  visible,
  udidForOpenModal,
  devicesMoreDetails,
  typeForDeviceModals,
  sharedWithDetails
) => {
  return (dispatch, getState) => {
    dispatch({
      type: VISIBLE_DEVICE_MORE_DETAILS_MODAL,
      visible,
      udidForOpenModal,
      devicesMoreDetails,
      typeForDeviceModals,
      sharedWithDetails,
    });
  };
};
