import React from "react";
import { useSelector } from "react-redux";
import AssignedTestDataListView from "./AssignedTestDataListView/AssignedTestDataListView";

const EditSystemKeyTestDataSet = (props) => {
  const { title } = props;
  const { testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      <AssignedTestDataListView title={title} testDataValues={testDataSetForEdit?.testDataValues} />
    </>
  );
};

export default EditSystemKeyTestDataSet;
