import React from "react";
import { Button, Space } from "antd";
import SvgLoader from "../../../Util/SvgLoader";

const CustomButtonV2 = (props) => {
  const { buttonIcon, buttonLabel, handleClick, disabled, iconColor, className } = props;

  return (
    <Button size="large" type="primary" className={className} onClick={() => handleClick()} disabled={disabled}>
      <Space size={10}>
        {buttonIcon && <SvgLoader iconName={buttonIcon} iconColor={iconColor && iconColor} />}
        <span>{buttonLabel}</span>
      </Space>
    </Button>
  );
};

export default CustomButtonV2;
