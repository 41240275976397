import React from "react";
import _ from "lodash";
import ViewDefaultTestDataListView from "./ViewDefaultTestDataListView";
import { Empty } from "antd";

const ViewTestBlockDefaultTestDataSet = (props) => {
  return (
    <>
      {(props.defaultTestData && !_.isEmpty(props.defaultTestData?.testDataValues)) &&
        <ViewDefaultTestDataListView
          testDataValues={props.defaultTestData?.testDataValues}
          title={props.title
              + " [Steps using Test Data: " +  props.defaultTestData?.stepCount
              + " Unique Test Data Keys: " + props.defaultTestData?.uniqueTestDataCount + "]"}
          stepType={props.stepType}
          refTestDataList={props.refTestDataList ? props.refTestDataList : []}
        />
      }
    </>
  );
};

export default ViewTestBlockDefaultTestDataSet;
