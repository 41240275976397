import { Col, Collapse, Spin } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ELEMENT_ACTION, STEP, VERIFICATIONS } from "../../../../Constants";
import { getRecElement, isActionNeedToSelectElement, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import RecTestStepPostActionSettings from "../RecTestStepPostActionSettings/RecTestStepPostActionSettings";

import ActionsVerificationItems from "../Actions/ActionsVerificationItems/ActionsVerificationItems";
import QuickActions from "../Actions/QuickActions/QuickActions";
import LaunchAppList from "../LaunchApp/LaunchAppList";
import RecTestStepElementList from "../RecTestStepElementList/RecTestStepElementList";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import { disableStepSaveButton } from "../../ValidateStepMandatoryProperty";
import styles from "./RecTestStepDetail.module.scss";

const Panel = Collapse.Panel;
const getActionMapping = (recTestStepProperty) => {
  const mapping = {
    actions: ActionsVerificationItems,
    tap: ActionsVerificationItems,
    longPress: ActionsVerificationItems,
    tapByIndex: ActionsVerificationItems,
    tapByCoordinates: ActionsVerificationItems,
    sendKeys: ActionsVerificationItems,
    clearText: ActionsVerificationItems,
    clear: ActionsVerificationItems,
    swipeOverElement: ActionsVerificationItems,
    launchApp: LaunchAppList,
    verifications: ActionsVerificationItems,
    step: ActionsVerificationItems,
    Swipe: QuickActions,
    BACK: QuickActions,
    HOME: QuickActions,
    ENTER: QuickActions,
    deviceInteraction: QuickActions,
    pause: QuickActions,
    insertValueToOutputTestData: QuickActions,
    setAppLocale: QuickActions,
    captureScreenshot: QuickActions,
  };
  return mapping[recTestStepProperty?.action];
};

export const StyledRecStepActionCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: 100% !important;
  flex: none;
  flex-grow: 0;
  margin-top: 10px !important;
  background-color: #f7f8fa !important;
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 6px !important;
    align-items: center;
  }
`;

export const StyledRecStepActionItemPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px !important;
  width: 100%;
  background: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-size: 12px;
  .ant-collapse-content > .ant-collapse-content-box {
    background: #ffffff !important;
  }
  .ant-collapse-content {
    width: 100%;
  }
  .ant-collapse-header-text {
    flex: auto !important;
    width: 100%;
    color: #40a9ff;
  }
  .ant-collapse-header {
    padding: 8px !important;
    margin: 0px !important ;
    width: 100%;
    background: ${(props) => (props.childPanel ? "#F5F5F5" : "")}!important;
  }
`;
const RecTestStepDetail = (props) => {
  const {
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
    selectedRecTestStepDisabled,
  } = props;
  const { findElementProcessing } = useSelector((state) => state.RecordingReducer);
  const { isPreviewRunning, selectedScenarioPlatform, recTestStepList, testBlockStep } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, stepType, unSavedRecElementGuid } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
  const unSavedRecElement = getRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements, unSavedRecElementGuid);
  let isSameStepForEdit = unSavedRecTestStep?.tag === data?.tag;
  const saveButtonDisable = !isSameStepForEdit || disableStepSaveButton(unSavedRecTestStep, unSavedRecElement);

  let ActionItem = undefined;
  if (data) {
    ActionItem = getActionMapping(data.recTestStepProperty);
  }

  const showPostActionOrSettings = ![ELEMENT_ACTION?.INSERT_VALUE_TO_OUTPUT_TEST_DATA, ELEMENT_ACTION?.PAUSE].includes(
    data?.recTestStepProperty?.action
  );

  return (
    <>
      <div>
        <div className={styles.actionSelection}>
          <Col span={24}>
            <Spin spinning={data.id === undefined && findElementProcessing}>
              {ActionItem && (
                <ActionItem
                  recTestSteps={recTestSteps}
                  data={data}
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag) ||
                    selectedRecTestStepDisabled
                  }
                  projectId={projectId}
                  isDeviceLoaded={isDeviceLoaded}
                  isSkipStep={isSkipStep}
                  isPreviewRunning={isPreviewRunning}
                  isTestBlock={isTestBlock}
                  stepType={stepType}
                  platform={selectedScenarioPlatform}
                  showPostActionOrSettings={showPostActionOrSettings}
                />
              )}
            </Spin>
          </Col>
        </div>

        {(isActionNeedToSelectElement(data.recTestStepProperty?.action) ||
          (data.recTestStepProperty?.action === STEP &&
            ((data.recTestStepProperty?.isStepHasPrecondition === 1 &&
              data.recTestStepProperty?.recStepVerifyElementProperties?.some((i) => i?.type === "VerifyExistence")) ||
              data.recTestStepProperty?.isLoopStep === 1))) && (
          <>
            <div className={styles.selectorOrSettings}>{`Element to perform ${
              data.recTestStepProperty?.action === VERIFICATIONS ? `verification` : `action`
            } on`}</div>
            <RecTestStepElementList {...props} saveButtonDisable={saveButtonDisable} />
          </>
        )}
        {data.recTestStepProperty?.action !== ELEMENT_ACTION.PAUSE && (
          <RecTestStepPostActionSettings
            data={props.data}
            disabled={disabled}
            projectId={projectId}
            isSkipStep={isSkipStep}
          />
        )}
      </div>
    </>
  );
};

export default RecTestStepDetail;
