import { Button, Col, Modal, Select } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import styled from "styled-components";
import { editDeviceList, getOrgDevice } from "../../actions/OrgDeviceAction";
import { DEVICE_TYPE, RECORD_10_PER_PAGE, SUCCESS_STATUS } from "../../Constants";
import { SelectCol } from "./DeviceAssignmentModal";

const { Option } = Select;

export const DataCol = styled(Col)`
  display: flex;
  padding: 5px;
`;

const EditDeviceDetailsModal = (props) => {
  const dispatch = useDispatch();
  const { postAssignDeviceRes, isLoading, currentOrgDevicePage } = useSelector((state) => state.OrgDeviceReducer);
  const { modalStatus, modalClosed, editDataList, orgId, myProjectsList, myOrgUsers, searchKeyword } = props;
  const [selectedDeviceType, setSelectedDeviceType] = useState();
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);

  const selectedProjectName = selectedProject.map((name) => name.substring(1, name.length - 1));

  const selectedProjectIds = useMemo(() => {
    return myProjectsList?.filter((i) => selectedProjectName?.includes(i.projectName))?.map((i) => i?.projectId);
  }, [myProjectsList, selectedProjectName]);

  const selectedUserName = selectedUser.map((name) => name.substring(1, name.length - 1));

  const selectedUserIds = useMemo(() => {
    return myOrgUsers?.filter((i) => selectedUserName?.includes(i.email))?.map((i) => i?.id);
  }, [myOrgUsers, selectedUserName]);

  useEffect(() => {
    if (editDataList) {
      if (editDataList.projects && editDataList.projects.length > 0) {
        const filterProjectDetails = editDataList.projects.map((i) => JSON.stringify(i.projectName));
        setSelectedProject(filterProjectDetails);
      } else {
        setSelectedProject([]);
      }
      if (editDataList?.users && editDataList.users.length > 0) {
        const filterUserDetails = editDataList?.users.map((i) => JSON.stringify(i.email));
        setSelectedUser(filterUserDetails);
      } else {
        setSelectedUser([]);
      }
      const isSharedDevice = editDataList.isSharedDevice === 0 ? DEVICE_TYPE.CUSTOM : DEVICE_TYPE.SHARED;
      setSelectedDeviceType(isSharedDevice);
    }
  }, [editDataList]);

  const disabledSubmitButton =
    editDataList?.isSharedDevice === (selectedDeviceType === DEVICE_TYPE.CUSTOM ? 0 : 1) &&
    editDataList?.projects?.length === selectedProject?.length &&
    editDataList?.users?.length === selectedUser?.length;

  const handleCloseModal = () => {
    modalClosed();
    if (!disabledSubmitButton) {
      dispatch(
        getOrgDevice({
          orgId: orgId,
          pageNumber: currentOrgDevicePage,
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeywords: searchKeyword,
        })
      );
    }
  };

  function handleChangeDeviceType(value) {
    setSelectedDeviceType(value);
  }

  const editDevice = () => {
    const deviceObject = {
      orgId: orgId,
      targetUniqueIds: [editDataList.targetUniqueId],
      projectIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedProjectIds,
      userIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedUserIds,
      isSharedDevice:
        selectedDeviceType === DEVICE_TYPE.SHARED
          ? 1
          : selectedDeviceType === DEVICE_TYPE.CUSTOM
          ? 0
          : editDataList.isSharedDevice,
    };
    dispatch(editDeviceList(deviceObject, searchKeyword));
  };

  const projects = [];
  if (!_.isEmpty(myProjectsList)) {
    for (const element of myProjectsList.filter((x) => x.platform === editDataList?.platformType?.name)) {
      projects.push(<Option value={JSON.stringify(element.projectName)}>{element.projectName}</Option>);
    }
  }

  const user = [];
  if (!_.isEmpty(myOrgUsers)) {
    for (const element of myOrgUsers) {
      user.push(<Option value={JSON.stringify(element.email)}>{element.email}</Option>);
    }
  }

  useEffect(() => {
    if (postAssignDeviceRes && postAssignDeviceRes.status === SUCCESS_STATUS) {
      handleCloseModal();
    }
    handleCloseModal();
  }, [postAssignDeviceRes]);

  return (
    <>
      <Modal
        title={`Edit "${editDataList && editDataList.brandName}" Device`}
        visible={modalStatus}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={editDevice} disabled={disabledSubmitButton}>
            Submit
          </Button>,
        ]}
      >
        <div>
          <Col span={24} className="display-flex">
            <Col span={24}>
              <Col className="m-5">Device Sharing:</Col>
              <Col className="m-5">
                <Select
                  className="w-100"
                  placeholder="Select Device Sharing"
                  value={selectedDeviceType}
                  onChange={handleChangeDeviceType}
                >
                  <Option value={DEVICE_TYPE.SHARED}>Share with All Projects</Option>
                  <Option value={DEVICE_TYPE.CUSTOM}>Custom</Option>
                </Select>
              </Col>
            </Col>
          </Col>
          {selectedDeviceType === DEVICE_TYPE.CUSTOM ? (
            <>
              <div className="mt-5">
                <DataCol>Share With Projects:</DataCol>
                <SelectCol
                  placeholder="Please Select Project"
                  mode="multiple"
                  className="select-app-for-default m-5"
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e)}
                  tokenSeparators={[","]}
                >
                  {projects}
                </SelectCol>
              </div>
              <div>
                <DataCol>Share With Users:</DataCol>
                <SelectCol
                  placeholder="Please Select Users"
                  mode="multiple"
                  className="select-app-for-default m-5"
                  value={selectedUser}
                  onChange={(e) => setSelectedUser(e)}
                  tokenSeparators={[","]}
                >
                  {user}
                </SelectCol>
              </div>
            </>
          ) : null}
        </div>
      </Modal>
    </>
  );
};

export default EditDeviceDetailsModal;
