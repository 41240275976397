import { faCheckCircle, faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Input, Row, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeSelectedTestJobCriteria, changeTestJobData } from "../../../../actions/TestJobActions";
import { CURRENT_JOB, INVALID_ICON, PENDING_ICON } from "../../../../CdnImagePath";
import {
  APP_MANAGER,
  BUILD_URL,
  DEVICE_EXECUTION_FARM,
  NONE,
  PARALLEL_OS,
  PARALLEL_OS_FOR_DISPLAY,
  PARALLEL_SPEED,
  PARALLEL_SPEED_FOR_DISPLAY,
  SCHEDULE_TEST_JOB,
  SERIAL,
  SERIAL_FOR_DISPLAY,
  TOOLTIP_BG_COLOR,
  USE_LATEST_BUILD,
} from "../../../../Constants";
import { checkNotUndefined, isNotNullAndNotEmptyAndNotUndefined, showNotification } from "../../../../Util";

const CriteriaItem = styled(Row)`
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 4px;
  padding: 10px;
  margin: 0px 10px 10px 0px;
  font-size: 14px;
  color: black;
  height: auto;
`;

const JobCriteria = ({ selectedCriteria, testJobDetail, errorInStep }) => {
  const dispatch = useDispatch();
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const { allAppBuildDetails } = useSelector((state) => state.RecordingReducer);
  const { getTestRailSuitesByTestPlanID, getSauceLabsDeviceList } = useSelector((state) => state.TestJobReducer);

  const deviceInfoNotification =
    getSearchDeviceList &&
    getSearchDeviceList.filter((deviceData) => testJobDetail.devices.includes(deviceData.device.targetUniqueId)).length;

  const testJobDetailDevicesCount =
    getSearchDeviceList &&
    getSearchDeviceList.filter((deviceData) => testJobDetail?.devices.includes(deviceData.device.targetUniqueId))
      ?.length;

  const attachedDevices = testJobDetail && testJobDetail.devices.length;

  const isDeviceOffile =
    getSearchDeviceList &&
    getSearchDeviceList.filter(
      (deviceData) =>
        testJobDetail.devices.includes(deviceData.device.targetUniqueId) && deviceData.device.isDeviceOffline
    ).length > 0;

  let buildDetails =
    allAppBuildDetails && allAppBuildDetails?.data?.find((i) => i?.id === testJobDetail?.buildDetail?.id);

  const checkDeviceSauceLabs = () => {
    if (testJobDetail?.deviceServiceFarm !== DEVICE_EXECUTION_FARM.SAUCELABS) {
      return true;
    }
    return getSauceLabsDeviceList?.some((x) => testJobDetail?.devices.includes(x?.device?.targetUniqueId));
  };

  const executionSettingForDisplay = (value) => {
    if (value === SERIAL) {
      return SERIAL_FOR_DISPLAY;
    } else if (value === PARALLEL_OS) {
      return PARALLEL_OS_FOR_DISPLAY;
    } else if (value === PARALLEL_SPEED) {
      return PARALLEL_SPEED_FOR_DISPLAY;
    } else {
      return value;
    }
  };

  return (
    <>
      <Col span={24} className="font-size-14 font-weight-500 display-flex align-items-center pr-10 pb-20 m-10">
        <Col span={8}>
          Job Name:<span className="text-danger">*</span>
        </Col>
        <Col span={16}>
          <Input
            className={"border-radius-4 w-100" + (errorInStep["name"] ? " border-color-red " : "")}
            onChange={(e) =>
              dispatch(
                changeTestJobData({
                  ...testJobDetail,
                  name: e.target.value,
                })
              )
            }
            placeholder="Please Enter Job Name"
            value={testJobDetail.name}
          />
        </Col>
      </Col>
      <CriteriaItem
        className={selectedCriteria === SCHEDULE_TEST_JOB.STEP1 ? "selectedJobCriteria" : "cursor-pointer"}
        onClick={() => {
          dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP1));
        }}
      >
        <Col span={3}>
          {testJobDetail?.testPlan && testJobDetail?.testPlan?.name ? (
            <ValidDataNotification />
          ) : selectedCriteria === SCHEDULE_TEST_JOB.STEP1 ? (
            <CurrentDataNotification />
          ) : (
            errorInStep[SCHEDULE_TEST_JOB.STEP1] === true && <InValidDataNotification />
          )}
        </Col>

        <Col span={20} className="font-size-13">
          <Col>Test Plan: {testJobDetail.testPlan ? testJobDetail.testPlan.name : ""}</Col>
          <Col className="display-flex flex-wrap">
            Test Data set:
            {testJobDetail.testDataSet && <span className="ml-5">{testJobDetail.testDataSet?.name}</span>}
          </Col>
        </Col>
      </CriteriaItem>

      <CriteriaItem
        className={selectedCriteria === SCHEDULE_TEST_JOB.STEP2 ? "selectedJobCriteria" : "cursor-pointer"}
        onClick={() => {
          dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP2));
        }}
      >
        <Col span={3}>
          {!_.isEmpty(testJobDetail?.devices) ? (
            <ValidDataNotification />
          ) : selectedCriteria === SCHEDULE_TEST_JOB.STEP2 ? (
            <CurrentDataNotification />
          ) : selectedCriteria < SCHEDULE_TEST_JOB.STEP2 ? (
            <PendingDataNotification />
          ) : (
            errorInStep[SCHEDULE_TEST_JOB.STEP2] === true && <InValidDataNotification />
          )}
        </Col>

        <Col span={deviceInfoNotification !== attachedDevices || isDeviceOffile ? 16 : 20} className="font-size-13">
          <Col>Execution Farm : {testJobDetail?.deviceServiceFarm || ""}</Col>
          <Col>
            Execution Device Count :
            {deviceInfoNotification > 0
              ? deviceInfoNotification
              : testJobDetailDevicesCount
              ? testJobDetailDevicesCount
              : testJobDetail?.devices?.length}
          </Col>
        </Col>
        {deviceInfoNotification !== attachedDevices ||
          (isDeviceOffile && (
            <Col span={4}>
              <Tooltip color={TOOLTIP_BG_COLOR} title="Some Selected Devices are Offline">
                <InfoDataNotification />
              </Tooltip>
            </Col>
          ))}
      </CriteriaItem>

      <CriteriaItem
        className={selectedCriteria === SCHEDULE_TEST_JOB.STEP3 ? "selectedJobCriteria" : "cursor-pointer"}
        onClick={() => {
          dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP3));
        }}
      >
        <Col span={3}>
          {(testJobDetail?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS &&
            !_.isEmpty(testJobDetail?.devices) &&
            testJobDetail.buildType === NONE) ||
          (testJobDetail.buildType === APP_MANAGER &&
            testJobDetail?.buildDetail?.id !== 0 &&
            (testJobDetail?.buildDetail?.displayName !== undefined || testJobDetail?.isUseLatestBuild !== 0)) ||
          (testJobDetail.buildType === BUILD_URL &&
            checkNotUndefined(testJobDetail?.fileUrl) &&
            testJobDetail?.fileUrl !== "https://") ? (
            <ValidDataNotification />
          ) : selectedCriteria === SCHEDULE_TEST_JOB.STEP3 ? (
            <CurrentDataNotification />
          ) : selectedCriteria < SCHEDULE_TEST_JOB.STEP3 ? (
            <PendingDataNotification />
          ) : (
            errorInStep[SCHEDULE_TEST_JOB.STEP3] === true && <InValidDataNotification />
          )}
        </Col>

        <Col span={20} className="font-size-13">
          <Col> Select Build : {testJobDetail.buildType !== NONE ? testJobDetail.buildType : ""}</Col>
          {testJobDetail.buildType === NONE && (
            <>
              <Col>Use Installed version on Device</Col>
            </>
          )}
          {testJobDetail.buildType === APP_MANAGER &&
            testJobDetail?.isUseLatestBuild === 0 &&
            testJobDetail?.buildDetail?.buildVersion && (
              <>
                <Col>Name : {testJobDetail?.buildDetail?.displayName || "N/A"}</Col>
                <Col>Version : {testJobDetail?.buildDetail?.buildVersion}</Col>
              </>
            )}
          {testJobDetail.buildType === APP_MANAGER && testJobDetail?.isUseLatestBuild === 1 && (
            <>
              <Col>Latest Build</Col>
            </>
          )}
          {testJobDetail.buildType === USE_LATEST_BUILD && (
            <>
              <Col>Use Latest Build from App Manager</Col>
            </>
          )}
          {testJobDetail.buildType === BUILD_URL && testJobDetail.fileUrl && (
            <Col className="display-flex">
              Build URL:
              <div className="ml-5 w-50 overflow-hidden overflow-ellipsis">
                {testJobDetail.fileUrl ? testJobDetail.fileUrl : ""}
              </div>
            </Col>
          )}
        </Col>
      </CriteriaItem>

      <CriteriaItem
        className={selectedCriteria === SCHEDULE_TEST_JOB.STEP4 ? "selectedJobCriteria" : "cursor-pointer"}
        onClick={() => {
          dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP4));
        }}
      >
        <Col span={3}>
          {errorInStep[SCHEDULE_TEST_JOB.STEP4] === false ? (
            <ValidDataNotification />
          ) : selectedCriteria < SCHEDULE_TEST_JOB.STEP3 ? (
            <PendingDataNotification />
          ) : (
            errorInStep[SCHEDULE_TEST_JOB.STEP4] === true && <InValidDataNotification />
          )}
        </Col>
        <Col span={20} className="font-size-13">
          <Col>
            Execution Mode :{testJobDetail.executionType ? executionSettingForDisplay(testJobDetail.executionType) : ""}
          </Col>
          <Col> Capture video : {testJobDetail.isCaptureVideo ? testJobDetail.isCaptureVideo : ""}</Col>
          <Col> Capture Logs : {testJobDetail.isLogs ? testJobDetail.isLogs : ""}</Col>
          {testJobDetail && testJobDetail.updateResultToTestRail === 1 && (
            <Col> Update Results To TestRail : {testJobDetail?.updateResultToTestRail}</Col>
          )}
          {testJobDetail?.testRailTestSuiteIds && !_.isEmpty(testJobDetail?.testRailTestSuiteIds) && (
            <Col>
              Test Rail Suites:
              {getTestRailSuitesByTestPlanID &&
                getTestRailSuitesByTestPlanID
                  .filter((i) => testJobDetail?.testRailTestSuiteIds?.includes(i?.id) && i)
                  ?.map((j, i) => (
                    <span key={i} className="ml-5">
                      {j.name}
                    </span>
                  ))}
            </Col>
          )}
        </Col>
      </CriteriaItem>
    </>
  );
};

export default JobCriteria;

const isSelectBuildType = (selectedBuildType, selectedBuildVersion, selectedBuildUrl) => {
  if (isNotNullAndNotEmptyAndNotUndefined(selectedBuildType)) {
    if (selectedBuildType === APP_MANAGER && !isNotNullAndNotEmptyAndNotUndefined(selectedBuildVersion)) {
      showNotification("info", "please select build version");
      return false;
    } else if (selectedBuildType === BUILD_URL && !isNotNullAndNotEmptyAndNotUndefined(selectedBuildUrl)) {
      showNotification("info", "Please Enter URL.");
      return false;
    } else {
      return true;
    }
  } else {
    showNotification("info", "Please Select Build type");
    return false;
  }
};

const ValidDataNotification = () => {
  return (
    <span className="font-color-lightGreen float-left font-size-16">
      <FontAwesomeIcon className="vertical-align-top" icon={faCheckCircle}></FontAwesomeIcon>
    </span>
  );
};

const InValidDataNotification = () => <img src={INVALID_ICON} alt="Invalid" />;

const CurrentDataNotification = () => <img src={CURRENT_JOB} alt="current" />;

const PendingDataNotification = () => <img src={PENDING_ICON} alt="current" />;

export const InfoDataNotification = () => {
  return (
    <span className="font-color-orange float-left font-size-20">
      <FontAwesomeIcon className="vertical-align-top" icon={faInfoCircle}></FontAwesomeIcon>
    </span>
  );
};
