import _ from "lodash";
import React, { useState } from "react";
import styles from "./RenderDeviceAttributes.module.scss";
import { ANDROID, IOS } from "../../../../../../Constants";
import { useSelector } from "react-redux";

const RenderDeviceAttributes = (props) => {
  const { attributes,platform } = props;
  return (
    <div>
      <div className={styles.drawerTitle}>
        <span className={styles.label}>Element Details</span>
      </div>
      {!_.isEmpty(attributes) &&  (
        <div className={styles.deviceAttributes}>
          <div className={styles.title}>Item Values:</div>
          {platform===ANDROID && 
          <div className={styles.attributesDetails}>
            <div>
              Bounds:
              <span>{attributes?.bounds || "N/A"}</span>
            </div>
            <div>
              Checkable:
              <span>{attributes?.checkable || "N/A"}</span>
            </div>
            <div>
              Checked:
              <span>{attributes?.checked || "N/A"}</span>
            </div>
            <div>
              Class:
              <span>{attributes?.class || "N/A"}</span>
            </div>
            <div>
              Clickable:
              <span>{attributes?.clickable || "N/A"}</span>
            </div>
            <div>
              Displayed:
              <span>{attributes?.displayed || "N/A"}</span>
            </div>
            <div>
              Visible:
              <span>{attributes?.visible || "N/A"}</span>
            </div>
            <div>
              Enabled:
              <span>{attributes?.enabled || "N/A"}</span>
            </div>
            <div>
              Focusable:
              <span>{attributes?.focusable || "N/A"}</span>
            </div>
            <div>
              focused:
              <span>{attributes?.focused || "N/A"}</span>
            </div>
            <div>
              Index:
              <span>{attributes?.index || "N/A"}</span>
            </div>
            <div>
              Long-Clickable:
              <span> {attributes["long-clickable"] || "N/A"}</span>
            </div>
            <div>
              Package:
              <span>{attributes?.package || "N/A"}</span>
            </div>
            <div>
              Scrollable:
              <span>{attributes?.scrollable || "N/A"}</span>
            </div>
            <div>
              Selected:
              <span>{attributes?.selected || "N/A"}</span>
            </div>
            <div>
              Text:
              <span>{attributes?.text || "N/A"}</span>
            </div>
          </div>
          }{platform===IOS && 
            <div className={styles.attributesDetails}>
              <div>
                X:
                <span>{attributes?.x || "N/A"}</span>
              </div>
              <div>
                Y:
                <span>{attributes?.y || "N/A"}</span>
              </div>
              <div>
                Width:
                <span>{attributes?.width || "N/A"}</span>
              </div>
              <div>
                Height:
                <span>{attributes?.height || "N/A"}</span>
              </div>
              <div>
              Accessible:
                <span>{attributes?.accessible || "N/A"}</span>
              </div>
              <div>
              Enabled:
                <span>{attributes?.enabled || "N/A"}</span>
              </div>
              <div>
                Index:
                <span>{attributes?.index || "N/A"}</span>
              </div>
              <div>
              Name:
                <span>{attributes?.name || "N/A"}</span>
              </div>
              <div>
              Label:
                <span>{attributes?.label || "N/A"}</span>
              </div>
              <div>
              Type:
                <span>{attributes?.type || "N/A"}</span>
              </div>
              <div>
              Visible:
                <span>{attributes?.visible || "N/A"}</span>
              </div>
            </div>
            }
        </div>
      )}
    </div>
  );
};

export default RenderDeviceAttributes;
