import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Row, Col, Collapse, Spin, Modal, Button } from "antd";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  changeServerManagerTab,
  fetchAllServer,
  fetchServerType,
  getAllDeviceByServerId,
  getServerDataById,
  saveRegisterServer,
} from "../../../actions/ServerAction";
import { defaultDateTimeFormat, REGISTER_SERVER, SERVER_DEVICE_LOADING } from "../../../Constants";
import RegisterDeviceDetails from "../DeviceRegister/RegisterDeviceDetails";
import CommonSearch from "../../CoreComponents/SearchComponent/CommonSearch";
import ToggleComponent from "../../CoreComponents/ToggleComponent/ToggleComponent";
import { DOWN_ARROW_ICON, EDIT_ICON, RIGHT_ARROW_ICON } from "../../../CdnImagePath";
import { Label } from "../../CoreComponents/StyledComponent/StyledComponents";
import PaginationComponent from "../../CoreComponents/PaginationComponent/PaginationComponent";
import { getFormateDateAndTime, displayUTCDateFormatWithToolTip } from "../../../Util";
import AddEmulatorModal from "../DeviceRegister/AddEmulatorModal";

const { confirm } = Modal;
const { Panel } = Collapse;

export const StyledCollapse = styled(Collapse)`
  width: 100%;
  .ant-collapse-item {
    margin-bottom: 0px !important;
    border-bottom: none !important;
  }
`;

export const StyledRow = styled(Row)`
  width: 100%;
  margin: 20px 0px;
  border: 1.2px solid #c3c5d2;
  border-radius: 10px 10px 0px 0px;
`;

export const StyledHeader = styled(Row)`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 14px 0px;
  background: #f6f6f6;
  font-weight: 500;
  font-size: 12px;
  color: #4a4c69;
  border-radius: 10px 10px 0px 0px;
`;

const AllServerList = (props) => {
  const { orgId } = props;
  const dispatch = useDispatch();
  const {
    currentServerListPage,
    totalServerListPage,
    serverList,
    serverLoading,
    serverDeviceLoading,
    totalPageItems,
    totalItems,
  } = useSelector((state) => state.ServerReducer);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [serverID, setServerID] = useState(null);

  useEffect(() => {
    dispatch(fetchServerType());
    dispatch(fetchAllServer(orgId, "", 1));
    setSearchKeyword(null);
  }, [dispatch, orgId]);

  function activeConfirm(serverData) {
    confirm({
      title: (
        <div>{`Are you sure you want to ${(serverData?.isActive === 1 && "In-Active") || "Active"} Server ?`}</div>
      ),
      onOk() {
        dispatch(saveRegisterServer(orgId, serverData, currentServerListPage));
      },
    });
  }

  return (
    <>
    <Row className="w-100 p-20">
      <Spin spinning={serverLoading} tip="Loading" wrapperClassName="w-100">
        <Row justify="space-between" className="mt-10 mb-10">
          <h1>Manage Device Servers</h1>
          <div className="display-flex">
            <div>
              <CommonSearch
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                searchDetails={(searchText) => dispatch(fetchAllServer(orgId, searchText, currentServerListPage))}
                placeHolder="Search by Server Name"
              />
            </div>
            <Button
              size="large"
              className="orange ml-10"
              onClick={() => dispatch(changeServerManagerTab(REGISTER_SERVER))}>
              Register Local Server
            </Button>
          </div>
        </Row>
        <div className="pageScroll">
          {!_.isEmpty(serverList) && (
            <Row justify="end">
              <PaginationComponent
                currentPageItems={totalPageItems}
                totalItems={totalItems}
                currentPage={currentServerListPage}
                totalPage={totalServerListPage}
                callPaginationAPI={(pageNumber) => dispatch(fetchAllServer(orgId, "", pageNumber))}
              />
            </Row>
          )}
          <StyledRow>
            <StyledHeader>
              <Col span={18} className="pl-20">
                Server Name & ID
              </Col>
              <Col span={6} className="text-align-center">
                Actions
              </Col>
            </StyledHeader>
            {!_.isEmpty(serverList) &&
              serverList.map((server, i) => {
                return (
                  <StyledCollapse
                    key={i}
                    expandIconPosition="start"
                    expandIcon={({ isActive }) =>
                      isActive ? <img src={DOWN_ARROW_ICON} alt="down" /> : <img src={RIGHT_ARROW_ICON} alt="arrow" />
                    }
                    onChange={(e) => {
                      e.length && dispatch({ type: SERVER_DEVICE_LOADING, isLoading: true });
                      e.length && setServerID(server?.id);
                      e.length &&
                        setTimeout(() => {
                          dispatch(getAllDeviceByServerId(server?.id, server?.serverIp));
                        }, 1000);
                    }}>
                    <Panel
                      disabled={server.isActive === 0}
                      header={
                        <Row className="w-100 text">
                          <Col span={6} className="text">
                            <span className="font-color-darkBlue font-weight-500 font-size-14">
                              {server.serverName}
                            </span>
                            <div>
                              <Label>Server Id: </Label>
                              {server.serverIp}
                            </div>
                            <div>
                              <Label>Server Type: </Label>
                              {server?.serverType?.name}
                            </div>
                          </Col>
                          <Col span={4} className="align-self-center">
                            <Label>Location: </Label>
                            {server.location || "N/A"}
                          </Col>
                          <Col span={8} className="align-self-center">
                            <Label>Last Updated by: </Label>
                            {displayUTCDateFormatWithToolTip(server?.lastUpdateOn, defaultDateTimeFormat)}
                          </Col>
                          <Col span={6} className="display-flex align-items-center justify-center">
                            <ToggleComponent
                              isActive={server?.isActive === 1}
                              activeConfirm={(serverData) =>
                                dispatch(saveRegisterServer(orgId, serverData, currentServerListPage))
                              }
                              data={server}
                            />
                            <img
                              className={"ml-10 " + (server?.isActive === 1 ? "cursor-pointer" : "cursor-not-allowed")}
                              src={EDIT_ICON}
                              alt="edit"
                              onClick={(e) => {
                                e.stopPropagation();
                                if (server?.isActive === 1) {
                                  dispatch(getServerDataById(server.id));
                                  dispatch(changeServerManagerTab(REGISTER_SERVER));
                                }
                              }}
                            />
                          </Col>
                        </Row>
                      }>
                      <Spin spinning={serverDeviceLoading && server?.id === serverID} tip="Loading">
                        <RegisterDeviceDetails
                          orgId={orgId}
                          isEmulatorServer={server?.isEmulatorServer}
                          serverId={server?.id}
                          deviceDetails={server?.deviceDetails}
                          serverIp={server?.serverIp}
                          serverArmType={server?.armType}
                        />
                      </Spin>
                    </Panel>
                  </StyledCollapse>
                );
              })}
          </StyledRow>
          {window.pageYOffset > 300 && (
            <div className="mt-10 mb-10">
              {!_.isEmpty(serverList) && (
                <Row justify="end">
                  <PaginationComponent
                    currentPageItems={totalPageItems}
                    totalItems={totalItems}
                    currentPage={currentServerListPage}
                    totalPage={totalServerListPage}
                    callPaginationAPI={(pageNumber) => dispatch(fetchAllServer(orgId, "", pageNumber))}
                  />
                </Row>
              )}
            </div>
          )}
        </div>
      </Spin>
    </Row>
    <AddEmulatorModal orgId={orgId} />
    </>
  );
};

export default AllServerList;
