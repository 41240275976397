import React from "react";
import styles from "../RecTestScenarioV2.module.scss";

const StepDetailsColumV2 = ({ row }) => {
  return (
    <>
      <div key={row.id}>
        <span className={styles["label-name"]}>Total Steps: </span>{" "}
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.totalSteps}</span>
      </div>
      <div>
        <span className={styles["label-name"]}>Screenshots: </span>{" "}
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.stepsWithScreenShot} </span>
      </div>
    </>
  );
};

export default StepDetailsColumV2;
