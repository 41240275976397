import { Modal } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUnSavedRecTestStepActionAndStopRecording,
  hideUnsavedRecTestStepActionModal,
} from "../../../actions/RecTestStepAction";

const UnSavedRecTestStepModal = (props) => {
  const { unSavedRecTestStepActionModalVisible } = useSelector((state) => state.RecTestStepReducer);
  const dispatch = useDispatch();
  return (
    <Modal
      title="Delete Unsaved Recorded Test Step"
      visible={unSavedRecTestStepActionModalVisible}
      okText="Stop Recording"
      onOk={() => dispatch(deleteUnSavedRecTestStepActionAndStopRecording())}
      onCancel={() => dispatch(hideUnsavedRecTestStepActionModal())}>
      Your Unsaved Step Action Will Lost. Are you sure you want to Stop <br />
      Recording ?
    </Modal>
  );
};

export default UnSavedRecTestStepModal;
