import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { POST_OPERATION_SETTINGS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  CROSS_REMOVE_ICON,
  DEEP_BLUE_COLOR,
  DISABLE_COLOR,
  RED_COLOR,
  SELECT_TEST_DATA_ICON_NEW,
} from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { selectOutputTestData } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import CustomButtonV2 from "../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import styles from "./RecTestStepPostActionSettingsV2.module.scss";

const SelectOutputTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, isSkipStep, data } = props;

  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);

  //Destructure of object
  const { tag } = data ? data : {};
  const { outputTestData, outputTestDataId } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};
  const { id: idOfOutputTestData } = outputTestData ? outputTestData : {};

  const disableRemoveSelectVariable = useMemo(
    () => disabled || outputTestData === null || (idOfOutputTestData === undefined && outputTestDataId === null),
    [disabled, outputTestData, idOfOutputTestData, outputTestDataId]
  );

  const disableSelectTestDataIcon = isSkipStep || disabled;

  const openTestDataDrawer = () => {
    dispatch(visibleTestDataDrawer(POST_OPERATION_SETTINGS, tag));
    dispatch(selectRecTestSteps(data));
  };

  return (
    <>
      <Row>
        <span className={styles["save_Element_Text"]}>Save Element text to:</span>
        <Col xs={24} className={styles["element_Test_Data"]}>
          <div className={styles["selectVariable"]}>
            <Select
              placeholder="Select a Test Data Key"
              className={styles["styled_Select_Test_Data"]}
              disabled={disabled || isSkipStep}
              value={outputTestData ? idOfOutputTestData : outputTestDataId}
              onChange={(value) => dispatch(selectOutputTestData(tag, value))}
            >
              {!isEmpty(testDataList) &&
                testDataList?.map((v) => (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name} {v.value && `(${v.value})`}
                  </Select.Option>
                ))}
            </Select>

            <div
              className={`${styles["remove_Select_Variable_Btn"]}  ${
                disableRemoveSelectVariable && styles["disable_Button"]
              }
            `}
              onClick={() => !disableRemoveSelectVariable && dispatch(selectOutputTestData(tag, undefined))}
            >
              <SvgLoader
                iconName={CROSS_REMOVE_ICON}
                disabled={disableRemoveSelectVariable}
                iconColor={disableRemoveSelectVariable ? DISABLE_COLOR : RED_COLOR}
                width="18px"
                height="1rem"
              />
              <span>Remove</span>
            </div>
            <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
              <CustomButtonV2
                buttonLabel="Select Test Data"
                handleClick={() => !disableSelectTestDataIcon && openTestDataDrawer()}
                buttonIcon={SELECT_TEST_DATA_ICON_NEW}
                disabled={disableSelectTestDataIcon}
                iconColor={disableSelectTestDataIcon ? DISABLE_COLOR : DEEP_BLUE_COLOR}
                className={`common_Purple_Btn ${styles["select_Test_Data_Btn"]}`}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default SelectOutputTestDataV2;
