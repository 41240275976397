import {
  HIDE_DELETE_USER_MODAL,
  REQUEST_MY_PROJECTS,
  REQUEST_MY_PROJECTS_IN_TEAM,
  REQUEST_TEAM_MEMBERS,
  SAVE_PROJECT,
  SHOW_DELETE_USER_MODAL,
  TEAM_PROJECT_DETAILS,
  UPDATED_PROJECT_SAVED,
  INVITE_MEMBER,
  UPDATED_INVITE_MEMBER,
  GET_INVITATION_LIST,
  GET_PROJECT_BY_USER_ID_AND_ORG_ID,
  GET_PROJECTS_BY_ORG_ID,
  CREATE_PROJECT_LOADER,
  VISIBLE_CREATE_PROJECT_MODAL,
  PREVIOUS_PROJECT_ID,
  GET_INVITATION_LOADING,
} from "../Constants";

let defaultState = {
  myProjectsList: undefined,
  saveProjectMsg: null,
  myTeamMembers: undefined,
  teamNameOfProject: undefined,
  projectName: undefined,
  teamIdOfProject: undefined,
  deleteUserId: undefined,
  deleteUserName: undefined,
  deleteUserModalVisible: false,
  getProjectByTeamId: null,
  getProjectByOrgId: null,
  projectDetails: null,
  inviteMembers: null,
  getInvitationList: null,
  teamId: null,
  getProjectDetailsById: undefined,
  platform: null,
  projectId: undefined,
  getProjectListByOrgId: [],
  createProjectLoader: false,
  currentProjectListPage: null,
  totalProjectListPage: null,
  totalPageItemsForProject: null,
  totalItemsForProject: null,
  createProjectModalVisible: false,
  prevProjectId:null,
  invitationLoader:false
};

export default function ProjectsReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case REQUEST_MY_PROJECTS:
      response = action.response;
      return {
        ...state,
        myProjectsList: response?.data?.data,
        currentProjectListPage: response?.data?.currentPage || 1,
        totalProjectListPage: response?.data?.totalCount,
        totalPageItemsForProject: response?.data?.pageItems,
        totalItemsForProject: response?.data?.totalItems,
      };

    case REQUEST_MY_PROJECTS_IN_TEAM:
      response = action.response.data;
      return {
        ...state,
        myProjectsList: response.data,
        teamNameOfProject: response.teamName,
        teamIdOfProject: response.teamId,
      };

    case GET_PROJECT_BY_USER_ID_AND_ORG_ID:
      response = action.response.data;
      return {
        ...state,
        getProjectByOrgId: response,
      };

    case TEAM_PROJECT_DETAILS:
      response = action.response.data.data;
      return {
        ...state,
        projectDetails: response,
        teamNameOfProject: response.teamName,
        projectName: response.projectName,
        projectId: response.projectId,
        teamId: response.teamId,
        platform: response.platform,
      };

    case REQUEST_TEAM_MEMBERS:
      response = action.response.data.data;

      return {
        ...state,
        deleteUserModalVisible: false,
        myTeamMembers: response,
      };

    case SAVE_PROJECT:
      response = action.response;
      return {
        ...state,
        saveProjectMsg: response.status,
      };

    case INVITE_MEMBER:
      response = action.response;
      return {
        ...state,
        inviteMembers: response.message,
      };

    case GET_INVITATION_LIST:
      response = action.response.data.data;
      return {
        ...state,
        getInvitationList: response,
      };

    case UPDATED_INVITE_MEMBER:
      return {
        ...state,
        inviteMembers: null,
      };

    case UPDATED_PROJECT_SAVED:
      return {
        ...state,
        saveProjectMsg: null,
      };

    case SHOW_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUserModalVisible: true,
        deleteUserId: action.userId,
        deleteUserName: action.userName,
      };

    case HIDE_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUserModalVisible: false,
        deleteUserId: undefined,
        deleteuserName: undefined,
      };

    case GET_PROJECTS_BY_ORG_ID:
      return {
        ...state,
        getProjectListByOrgId: action.response,
      };
    case CREATE_PROJECT_LOADER:
      return {
        ...state,
        createProjectLoader: action.data,
      };

    case VISIBLE_CREATE_PROJECT_MODAL:
      return {
        ...state,
        createProjectModalVisible: action?.visible,
      };

    case PREVIOUS_PROJECT_ID:
      return{
        ...state,
        prevProjectId: action?.prevProjectId,
      }

      case GET_INVITATION_LOADING:
      return {
        ...state,
        invitationLoader: action?.response?.isLoading,
      };

    default:
      return state;
  }
}
