import { Dropdown, Menu, Switch, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PLAY_TYPE, STEP, TOOLTIP_BG_COLOR, VERIFICATION, VERIFICATIONS } from "../../../../../Constants";
import {
  ACTION_ICON_COLOR,
  DISABLE_ICON,
  DISABLE_ICON_COLOR,
  ICON_ADD_ACTION,
  ICON_ADD_SUB_STEP,
  ICON_ADD_VERIFICATION,
  ICON_CONDITIONAL_STEP,
  ICON_DUPLICATE,
  ICON_GENERAL_ACTION,
  ICON_IMPORT_ACTION,
  ICON_IMPORT_STEP,
  ICON_PLAY_FOR_SUB_HEADER,
  ICON_PLAY_FROM_HERE,
  ICON_PLAY_TILL_HERE,
  MORE_ACTION_ICON_ON_HOVER,
  STEP_DELETE_ICON,
} from "../../../../../Constants/SvgConstants";
import { disableStep, isStepHaveLoopStep, isStepHavePrecondition, onDeleteAction, showMenuTitle, updateContinueOnFailure } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  addRecTestStep,
  addRecTestStepAction,
  changeDisableStep,
  duplicateRecTestStep,
  showImportRecTestStepModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { startPreview } from "../../../../../actions/RecTestStepAction";
import VerticalLine from "../../../../CommonComponents/VerticalLine";
import { addConditionalStepMenu } from "../../../StepInnerComponents/RecTestStepListHeader/ActionsComponents/ConditionalRecTestStep";
import {
  addActionOnElementMenu,
  generalActionMenu,
} from "../../../StepInnerComponents/RecTestStepListHeader/ActionsComponents/RecTestStepAddActions";
import styles from "./RecTestStepMoreActions.module.scss";

export const addPlayStepMenu = (recTestStep, isPreviewRunning, dispatch, projectId) => {
  const menuItems = [
    {
      key: PLAY_TYPE.PLAY_THIS_STEP,
      icon: ICON_PLAY_FOR_SUB_HEADER,
      title: showMenuTitle(recTestStep, "Play"),
    },
    {
      key: PLAY_TYPE.PLAY_FROM_HERE,
      icon: ICON_PLAY_FROM_HERE,
      title: "Play from here",
    },
    {
      key: PLAY_TYPE.PLAY_TILL_HERE,
      icon: ICON_PLAY_TILL_HERE,
      title: "Play till here",
    },
  ];

  const handleClick = (type) => {
    if (!isPreviewRunning) {
      dispatch(
        startPreview(
          projectId,
          type,
          recTestStep?.tag,
          recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep?.id
        )
      );
    }
  };

  const generateMenuItem = (item) => (
    <Menu.Item key={item.key} onClick={() => handleClick(item.key)}>
      <div className={styles.menuItems}>
        <SvgLoader iconName={item?.icon} iconColor={item.disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR} />
        <span className={styles.menuItemsTitle}>{item.title}</span>
      </div>
    </Menu.Item>
  );

  const playMenuItems = menuItems.map(generateMenuItem);

  return <Menu className={styles.moreActionsDropdown}>{playMenuItems}</Menu>;
};

export const addMoreActionOnElementMenu = (
  recTestStep,
  dispatch,
  projectId,
  actionStep,
  unSavedRecStepTag,
  stepType,
  isPreviewRunning,
  childStepDisabled,
  parentSkipStep,
  startDeviceStreamingStatus,
  isAddSubSteps,
  isTestBlockInTestScenario,
  setMenuVisible,
  menuVisible,
  ignoreFailureDisable,
  platform,
  importStep
) => {
  const isActionsDisabled =
    recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
    (recTestStep?.recTestSteps?.length !== 0 && recTestStep?.recTestStepProperty?.action !== STEP);
    const isConditionDisabled=isStepHaveLoopStep(recTestStep)||isStepHavePrecondition(recTestStep)
  let menuItems = [
    {
      key: "Play",
      icon: ICON_PLAY_FOR_SUB_HEADER,
      title: "Play",
      submenu: addPlayStepMenu(recTestStep, isPreviewRunning, dispatch, projectId),
      disabled: isPreviewRunning || !startDeviceStreamingStatus,
      extraContent: true,
    },
    {
      key: "DisableStep",
      icon: DISABLE_ICON,
      title: showMenuTitle(recTestStep, "Disable"),
      onClick: () =>
        !isPreviewRunning && dispatch(changeDisableStep(recTestStep.isSkipStep === 1 ? 0 : 1, recTestStep, stepType)),
      extraContent: disableStep(
        isTestBlockInTestScenario,
        unSavedRecStepTag,
        childStepDisabled,
        parentSkipStep,
        recTestStep,
        dispatch,
        isPreviewRunning,
        stepType,
        styles.disableSwitch,
        menuVisible
      ),
    },
  ];
  if (recTestStep?.recTestStepProperty?.action !== STEP) {
    menuItems = [
      {
        key: "IgnoreFailure",
        icon: DISABLE_ICON,
        title: "Ignore Failure & continue execution",
        onClick: () =>
          updateContinueOnFailure(
            recTestStep,
            dispatch,
            recTestStep.recTestStepProperty?.continueAfterFail === 1 ? 0 : 1
          ),
        extraContent: (
          <Switch
            size="small"
            checked={recTestStep.recTestStepProperty?.continueAfterFail === 1}
            disabled={ignoreFailureDisable}
          />
        ),
      },
      ...menuItems,
    ];
  }
  if (!isTestBlockInTestScenario && recTestStep?.recTestStepProperty?.action === STEP) {
    menuItems = [
      {
        key: "ACTION",
        icon: ICON_ADD_ACTION,
        title: "Action",
        submenu: addActionOnElementMenu(recTestStep, dispatch),
        disabled: isActionsDisabled,
      },
      {
        key: VERIFICATION,
        icon: ICON_ADD_VERIFICATION,
        title: "Verification",
        onClick: () => dispatch(addRecTestStepAction(VERIFICATIONS, recTestStep)),
        disabled: isActionsDisabled,
      },
      {
        key: "GENERAL_ACTION",
        icon: ICON_GENERAL_ACTION,
        title: "General Action",
        submenu: generalActionMenu(recTestStep, platform, dispatch),
        disabled: isActionsDisabled,
      },
      {
        key: "Import",
        icon: ICON_IMPORT_ACTION,
        title: "Import",
        onClick: () => {
          dispatch(showImportRecTestStepModal("", projectId, recTestStep, actionStep, recTestStep?.recTestSteps));
          setMenuVisible(false);
        },
        disabled: isActionsDisabled,
        extraContent: true,
      },
      {
        key: "SubStep",
        icon: ICON_ADD_SUB_STEP,
        title: "Sub- step",
        onClick: () => dispatch(addRecTestStep(recTestStep)),
        disabled: !isAddSubSteps,
      },
      {
        key: "ImportStep",
        icon: ICON_IMPORT_STEP,
        title: "Import",
        onClick: () =>
          dispatch(showImportRecTestStepModal(STEP, projectId, recTestStep, importStep, recTestStep?.recTestSteps)),
        disabled: !isAddSubSteps,
      },
      {
        key: "CONDITION",
        icon: ICON_CONDITIONAL_STEP,
        title: "Condition",
        submenu: addConditionalStepMenu(recTestStep, dispatch),
        extraContent: true,
        disabled: isConditionDisabled,
      },
      ...menuItems,
    ];
  }
  if (!isTestBlockInTestScenario) {
    menuItems = [
      ...menuItems,
      {
        key: "Duplicate",
        icon: ICON_DUPLICATE,
        title: "Duplicate",
        onClick: () => {
          dispatch(duplicateRecTestStep(recTestStep));
          setMenuVisible(false);
        },
      },
    ];
  }

  menuItems = [
    ...menuItems,
    {
      key: "Delete",
      icon: STEP_DELETE_ICON,
      iconColor: "#FF188F",
      title: isTestBlockInTestScenario ? "Remove" : "Delete",
      onClick: () => {
        onDeleteAction(recTestStep, dispatch,isTestBlockInTestScenario);
        setMenuVisible(false);
      },
    },
  ];

  return (
    <Menu className={styles.moreActionsDropdown}>
      {menuItems.map((item) => (
        <React.Fragment key={item.key}>
          {item.submenu ? (
            <Menu.SubMenu
              title={
                <div className={styles.menuItems}>
                  <SvgLoader iconName={item?.icon} iconColor={item.disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR} />
                  <span className={styles.menuItemsTitle}>{item.title}</span>
                </div>
              }
              disabled={item.disabled}
            >
              {item.submenu}
            </Menu.SubMenu>
          ) : (
            <Menu.Item key={item.key} disabled={item.disabled}>
              <div className={item.extraContent && styles.disableAction} onClick={!item.disabled && item.onClick}>
                <div className={styles.menuItems}>
                  <SvgLoader
                    iconName={item?.icon}
                    iconColor={
                      item.disabled ? DISABLE_ICON_COLOR : item?.iconColor ? item?.iconColor : ACTION_ICON_COLOR
                    }
                  />
                  <span className={styles.menuItemsTitle}>{item.title}</span>
                </div>
                {item.extraContent && <div>{item.extraContent}</div>}
              </div>
            </Menu.Item>
          )}
          {item.extraContent && <VerticalLine className={styles.borderBottom} />}
        </React.Fragment>
      ))}
    </Menu>
  );
};

const RecTestStepMoreActions = (props) => {
  const {
    recTestStep,
    projectId,
    actionStep,
    childStepDisabled,
    parentSkipStep,
    stepName,
    isAddSubSteps,
    setMenuVisible,
    isTestBlockInTestScenario,
    menuVisible,
    ignoreFailureDisable,
    platform,
    importStep,
  } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      setMenuVisible(false);
    };
  }, []);

  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);

  return (
    <Dropdown
      overlay={addMoreActionOnElementMenu(
        recTestStep,
        dispatch,
        projectId,
        actionStep,
        unSavedRecStepTag,
        stepType,
        isPreviewRunning,
        childStepDisabled,
        parentSkipStep,
        startDeviceStreamingStatus,
        isAddSubSteps,
        isTestBlockInTestScenario,
        setMenuVisible,
        menuVisible,
        ignoreFailureDisable,
        platform,
        importStep
      )}
      trigger={["click"]}
      onVisibleChange={(value) => setMenuVisible(value)}
      visible={menuVisible}
    >
      <Tooltip title={stepName?.length > 7 && "More Actions"} color={TOOLTIP_BG_COLOR}>
        <div className={`${styles.moreActions} moreActions`}>
          <SvgLoader iconName={MORE_ACTION_ICON_ON_HOVER} width="1.625rem" height="1.625rem" />
          {stepName?.length < 7 && <span>More</span>}
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default RecTestStepMoreActions;
