import _ from "lodash";
import {
  ADD_APPLICATION_STRINGS_FORM_VISIBLE,
  ADD_DEVICE_STRINGS_FORM_VISIBLE,
  ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT,
  APPLICATION_DRAWER_VISIBLE,
  CHANGE_APPLICATION_STRING_NAME,
  CHANGE_APPLICATION_STRING_VALUE,
  CHANGE_DEVICE_STRING_NAME,
  CHANGE_DEVICE_STRING_VALUE,
  CHANGE_SHOW_TEST_DATA_VISIBILITY,
  DEVICE_DRAWER_VISIBLE,
  DEVICE_INTERACTION,
  DEVICE_INTERACTION_MODE,
  ELEMENT_ACTION,
  LAUNCH_APP,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  SELECTOR_DEVICE_INTERACTION_MODE_MAPPING,
  SELECTOR_TYPE,
  SELECT_APPLICATION_STRING_LOCALE,
  SELECT_DEVICE_STRING_LOCALE,
  STEP_ITEMS,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
  VISIBLE_TEST_DATA_VISIBILITY,
  APPLICATION_STRING_CURRENT_TAB,
  APPLICATION_STRING_SEARCH_KEYWORD,
  CHANGE_SELECTED_TEST_BLOCKS,
  CLEAR_UNSAVED_STEP,
  SHOW_GENERATE_STEPS_FROM_AI_MODAL,
  HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP,
  CHANGE_AI_SCENARIO_OBJECTIVE,
  SCENARIO_GENERATED_STEPS,
  CHANGE_AI_SCENARIO_INSTRUCTIONS,
  STOP_GENERATE_STEPS_FROM_AI_MODAL,
  CHANGE_AI_MODEL_TYPE,
  SHOW_AI_GENERATE_STEPS_ROW,
  HIDE_AI_GENERATE_STEPS_ROW,
  CHANGE_SCENARIO_GENERATE_STEP_TYPE,
  SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL,
  HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL,
  ABORT_GENERATE_SCENARIO_STEPS,
} from "../../Constants";
import {
  getUnSavedRecElement,
  getUniqueId,
  isActionNeedToSelectElement,
  validForNLP,
} from "../../Util";
import {
  findRecTestStepByTag as customFindRecTestStepByTag,
  insertIntoRecTestStepRecTestProperty,
} from "../../reducer/RecTestStepReducer";
import { selectRecTestSteps } from "../RecTestStepAction";
import { changeDeviceInteractionMode, recordingStart, recordingStop, resetElement } from "../Recording";
import * as RecTestScenarioRecTestStepSingleColumn from "./RecTestScenarioRecTestStepSingleColumn.js";
import * as RecTestStepCommonAction from "./RecTestStepCommonAction";
import * as TestBlockRecTestStepSingleColumn from "./TestBlockRecTestStepSingleColumn.js";

const getMethodObject = (stepType) => {
  switch (stepType) {
    case TEST_SCENARIOS:
      return RecTestScenarioRecTestStepSingleColumn;
    case TEST_STEP_BLOCKS:
      return TestBlockRecTestStepSingleColumn;
    default:
      return RecTestScenarioRecTestStepSingleColumn;
  }
};
export function editRecTestStep() {}

export function findRecTestStepByTag(state) {
  const { unSavedRecStepTag } = state.RecTestStepCommonViewReducer;
  const { recTestStepList, testBlockStep } = state.RecTestStepReducer;
  return customFindRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
}

function updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, callBack, recElementGuid = undefined) {
  return (dispatch, getState) => {
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    let updatedRecTestStep = customFindRecTestStepByTag(recTestStepList, recTestStepTag, testBlockStep);
    dispatch(selectRecTestSteps(updatedRecTestStep));
    if (recElementGuid && updatedRecTestStep) {
      let unSavedRecElement = getUnSavedRecElement(updatedRecTestStep.recTestStepProperty?.recElements, recElementGuid);
      dispatch(RecTestStepCommonAction.editRecTestStepElement(recTestStepTag, recElementGuid));
      dispatch({
        type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
        updatedRecTestStep: callBack(updatedRecTestStep, unSavedRecElement),
      });
    } else {
      dispatch(RecTestStepCommonAction.editRecTestStep(recTestStepTag));
      dispatch({ type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW, updatedRecTestStep: callBack(updatedRecTestStep) });
    }
  };
}

export function addRecTestStep(recParentStep, stepItemType) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).addRecTestStep(recParentStep, stepItemType));
  };
}

export function deleteRecTestStep() {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;

    dispatch(getMethodObject(stepType).deleteRecTestStep());
  };
}

export function deleteTestBlock(selectedRecTestStepIds, searchKeyword, setSelectAllTestStepBlocks) {
  return (dispatch, getState) => {
    dispatch(
      RecTestStepCommonAction._deleteTestBlock(selectedRecTestStepIds, searchKeyword, setSelectAllTestStepBlocks)
    );
  };
}

export function duplicateRecTestStep(duplicateRecTestStep) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).duplicateRecTestStep(duplicateRecTestStep));
  };
}

export function setApplicationStringCurrentTab(tab) {
  return (dispatch, getState) => {
    dispatch({ type: APPLICATION_STRING_CURRENT_TAB, tab: tab });
  };
}

export function setApplicationStringSearchKeyword(search) {
  return (dispatch, getState) => {
    dispatch({ type: APPLICATION_STRING_SEARCH_KEYWORD, search: search });
  };
}

export function makeSubStep(recTestStep, parentRecTestStep) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(
      getMethodObject(stepType).makeSubStep(
        recTestStep,
        recTestStep?.recTestScenarioRecTestStepAssocId,
        parentRecTestStep
      )
    );
  };
}

export function importRecTestStepOrAction(isImportOriginal, requestData) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).importRecTestStepOrAction(isImportOriginal, requestData));
  };
}

export function importStepFromJson(jsonData) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._importStepFromJson(jsonData));
  };
}

export function onRecTestStepDrop(
  event,
  recTestStep,
  parentRecTestStep,
  index,
  innerRecTestStep,
  multiColumnView,
  childStepList
) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(
      getMethodObject(stepType).onRecTestStepDrop(
        event,
        recTestStep,
        parentRecTestStep,
        index,
        innerRecTestStep,
        multiColumnView,
        childStepList
      )
    );
  };
}

export function performAndSaveRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).performAndSaveRecTestStepAction(stepAction, parentRecTestStep));
    if (isActionNeedToSelectElement(stepAction)) {
      dispatch(recordingStart());
    } else if (
      [
        DEVICE_INTERACTION.HOME,
        DEVICE_INTERACTION.BACK,
        DEVICE_INTERACTION.ENTER,
        ELEMENT_ACTION.SWIPE,
        ELEMENT_ACTION.PAUSE,
        LAUNCH_APP,
        ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      ].includes(stepAction)
    ) {
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
    }
  };
}

export function addRecTestStepAction(stepAction, parentRecTestStep) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._addRecTestStepAction(stepAction, parentRecTestStep));
    if (isActionNeedToSelectElement(stepAction)) {
      dispatch(recordingStart());
    } else if (
      [
        ELEMENT_ACTION.SWIPE,
        ELEMENT_ACTION.PAUSE,
        LAUNCH_APP,
        ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      ].includes(stepAction)
    ) {
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
    }
  };
}

export function updateRecTestStep(updatedRecTestStep) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).updateRecTestStep(updatedRecTestStep));
  };
}

export function getPreviewDetail(data) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).getPreviewDetail(data));
  };
}

export function updateRecElementDetailInRecTestStep(
  elementScreenShot,
  elementDetailsFromServer,
  selectedElementAttributes,
  elementDetailsFromPageSource
) {
  return (dispatch, getState) => {
    const { unSavedRecStepTag, unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        unSavedRecStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._updateRecElementDetailInRecTestStep(
            elementScreenShot,
            selectedElementAttributes,
            elementDetailsFromServer,
            elementDetailsFromPageSource,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        unSavedRecElementGuid
      )
    );
  };
}

export function editRecTestStepElement(recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction.editRecTestStepElement(recTestStepTag, recElementGuid));
  };
}

export function performRecTestStepAction() {
  return (dispatch, getState) => {
    const { stepType, unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    let updatedRecTestStep = customFindRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
    dispatch(getMethodObject(stepType).performRecTestStepAction(updatedRecTestStep));
  };
}

export function saveRecTestStepAction() {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType).saveRecTestStepAction());
  };
}

export function selectedAppForLaunch(selectedApp, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        new Promise((resolve) => {
          resolve(dispatch(RecTestStepCommonAction.fetchAppActivity(selectedApp)));
        }).then(() => {
          return RecTestStepCommonAction._selectedAppForLaunch(selectedApp, unSavedRecTestStep);
        });
      })
    );
  };
}

export function selectedAppActivityForLaunch(selectedAppActivity, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._selectedAppActivityForLaunch(selectedAppActivity, unSavedRecTestStep);
      })
    );
  };
}

export function changeRemoveAppPreviousSession(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeRemoveAppPreviousSession(checked, unSavedRecTestStep);
      })
    );
  };
}

export function getInstalledApp(targetUniqueId, serverId) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._getInstalledApp(targetUniqueId, serverId));
  };
}

//separate Function
export function updateActionValue(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._updateActionValue(value, unSavedRecTestStep);
      })
    );
  };
}

//separate Function Need to check
export function changeTapByIndex(value, action, recTestStepTag) {
  return async (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) =>
        dispatch(RecTestStepCommonAction._changeTapByIndex(value, action, unSavedRecTestStep))
      )
    );
  };
}

//separate Function
export function changeEnterText(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeEnterText(value, unSavedRecTestStep);
      })
    );
  };
}

//separate Function
export function updateApplicationStringsLocale(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._updateApplicationStringsLocale(value, unSavedRecTestStep);
      })
    );
  };
}

//separate Function
export function changeAppendRandomText(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeAppendRandomText(checked, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeRecordScenarioTagsList(val) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._changeRecordScenarioTagsList(val));
  };
}

//Common Function
export function showDeleteRecTestStepConfirmationModal(
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  isSelectedRecTestStepForDelete
) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._showDeleteRecTestStepConfirmationModal(
        recTestStepId,
        recTestStepTag,
        recTestScenarioRecTestStepAssocId,
        isSelectedRecTestStepForDelete
      )
    );
  };
}

//Common Function
export function hideDeleteRecTestStepConfirmationModal(flag = 0) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._hideDeleteRecTestStepConfirmationModal(flag));
  };
}

//Common Function
export function showDeleteRecElementConfirmationModal(
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  recElementGuid
) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._showDeleteRecElementConfirmationModal(
        recTestStepId,
        recTestStepTag,
        recTestScenarioRecTestStepAssocId,
        recElementGuid
      )
    );
  };
}

//Common Function
export function selectChildElementPurpose(value, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._selectChildElementPurpose(value, recElementGuid, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function hideDeleteRecElementConfirmationModal() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._hideDeleteRecElementConfirmationModal());
  };
}

//Common Function
export function showDeleteRecTestStepPreConditionConfirmationModal(recTestStepTag, recTestStepPropertyId) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._showDeleteRecTestStepPreConditionConfirmationModal(recTestStepTag, recTestStepPropertyId)
    );
  };
}

//Common Function
export function hideDeleteRecTestStepPreConditionConfirmationModal() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._hideDeleteRecTestStepPreConditionConfirmationModal());
  };
}

export function resetElementSelectionAndStopRecording() {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction._resetElementSelectionAndStopRecording());
  };
}

export function deleteRecTestStepPreCondition(recTestStePropertyId) {
  return (dispatch, getState) => {
    const { unSavedRecStepTag, unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        unSavedRecStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return dispatch(
            RecTestStepCommonAction._deleteRecTestStepPreCondition(unSavedRecTestStep, unSavedRecElement)
          );
        },
        unSavedRecElementGuid
      )
    );
  };
}

//Common Function
export function changeRecTestStepActionName(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeRecTestStepActionName(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeRecTestStepElementXpathActive(checked, value, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeRecTestStepElementXpathActive(
            checked,
            value,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function Need to check
export function setElementDetails(
  elementDetailsFromServer,
  selectedElementAttributes,
  elementDetailsFromPageSource,
  elementScreenshot,
  highlightElementInDevice
) {
  return (dispatch, getState) => {
    const { stepType, unSavedRecStepTag, unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
    elementDetailsFromPageSource = {
      ...elementDetailsFromPageSource,
      screenShot: elementScreenshot,
    };
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        unSavedRecStepTag,
        (unSavedRecTestStep) => {
          new Promise((resolve) => {
            resolve(
              dispatch(
                RecTestStepCommonAction._setElementDetails(
                  elementDetailsFromServer,
                  selectedElementAttributes,
                  elementDetailsFromPageSource,
                  highlightElementInDevice,
                  unSavedRecElementGuid
                )
              )
            );
          }).then(() => {
            let unSavedRecElement = getUnSavedRecElement(unSavedRecTestStep.recTestStepProperty?.recElements);
            unSavedRecTestStep = RecTestStepCommonAction._updateRecElementDetailInRecTestStep(
              { ...unSavedRecTestStep },
              selectedElementAttributes,
              elementDetailsFromServer,
              elementDetailsFromPageSource,
              unSavedRecTestStep,
              unSavedRecElement
            );
            dispatch(getMethodObject(stepType)._updateUnSavedRecTestStep(unSavedRecTestStep));
          });
        },
        unSavedRecElementGuid
      )
    );
  };
}

//Common Function Need to check
export function swipe(direction, recTestStepTag) {
  return async (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) =>
        dispatch(RecTestStepCommonAction._swipe(direction, recTestStepTag, unSavedRecTestStep))
      )
    );
  };
}

//Common Function Not in Use
export function changeSwipePercentage(direction, value) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._changeSwipePercentage(direction, value));
  };
}

//Common Function
export function saveDeviceInteraction(deviceInteractionAction, screenshot, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        unSavedRecTestStep = RecTestStepCommonAction._saveDeviceInteraction(
          deviceInteractionAction,
          screenshot,
          unSavedRecTestStep
        );
        new Promise((resolve) => resolve(dispatch(saveRecTestStepAction(screenshot, unSavedRecTestStep?.tag)))).then(
          () => {
            dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          }
        );
      })
    );
  };
}

//Common Function
export function addVerificationRow(verificationType, uuid, recTestStepTag, recElementGuid, newUuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._addVerificationRow(
            verificationType,
            uuid,
            recElementGuid,
            unSavedRecTestStep,
            newUuid
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function removeVerificationRow(uuid, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._removeVerificationRow(uuid, recElementGuid, unSavedRecTestStep);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function operatorChange(value, uuid, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._operatorChange(value, uuid, recElementGuid, unSavedRecTestStep);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function updateVerificationKey(
  key,
  uuid,
  recTestStepTag,
  recElementGuid,
  testDataList,
  verifyElementPropertyValue
) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._updateVerificationKey(
            key,
            uuid,
            recElementGuid,
            unSavedRecTestStep,
            testDataList,
            verifyElementPropertyValue
          );
        },
        recElementGuid
      )
    );
    // If verification is 'does not exist' then set verification condition to AND by default.
    key === "does not exist" && dispatch(changeRecTestStepElementCondition("AND", recTestStepTag));
  };
}

//Common Function
export function updateVerificationValue(
  value,
  uuid,
  recTestStepTag,
  updateElementOnEditMode = false,
  recElementGuid,
  selectedTestData,
  testDataList
) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._updateVerificationValue(
            value,
            uuid,
            (updateElementOnEditMode = false),
            recElementGuid,
            unSavedRecTestStep,
            selectedTestData,
            testDataList
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function updateReferenceStepValue(value, uuid, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._updateReferenceStepValue(value, uuid, recElementGuid, unSavedRecTestStep);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function updateReferenceTestDataForVerification(value, uuid, recTestStepTag, refTestData, elementVerification) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._updateReferenceTestDataForVerification(
            value,
            uuid,
            refTestData,
            unSavedRecTestStep,
            elementVerification
          );
        },
        elementVerification
      )
    );
  };
}

//Common Function
export function changeSwipeDirectionOverElement(direction, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeSwipeDirectionOverElement(direction, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeSwipePercentageOverElement(percentage, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeSwipePercentageOverElement(percentage, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changePauseTime(pauseTime, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changePauseTime(pauseTime, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeIsCaptureScreenshot(isCaptureScreenshot, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeIsCaptureScreenshot(isCaptureScreenshot, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeCaptureScreenShotDelayTime(captureScreenShotDelayTime, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeCaptureScreenShotDelayTime(
          captureScreenShotDelayTime,
          unSavedRecTestStep
        );
      })
    );
  };
}

//Common Function
export function changeIsLoopStep(checked, recTestStepTag, data) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeIsLoopStep(checked, unSavedRecTestStep);
      })
    );
    if (!checked && data?.recTestStepProperty?.addLoopCondition) {
      dispatch(RecTestStepCommonAction.editRecTestStep());
      dispatch(recordingStop());
    }
  };
}

//Common Function
export function changeSwipeToFindElement(value, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeSwipeToFindElement(value, unSavedRecTestStep);
      })
    );
  };
}
export function changeReverseSwipeElementNotFound(value, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeReverseSwipeElementNotFound(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeSwipeToBringElementToCenter(value, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeSwipeToBringElementToCenter(
            value,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeSwipeCountToFindElement(value, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeSwipeCountToFindElement(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeSwipeDirectionToFindElement(value, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeSwipeDirectionToFindElement(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeElementLoadTime(value, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeElementLoadTime(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeRetryCount(value, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeRetryCount(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeInvertResult(checked, recTestStepTag) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeInvertResult(checked, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeHorizontalRelativePoint(value, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeHorizontalRelativePoint(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeVerticalRelativePoint(value, recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeVerticalRelativePoint(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeContinueOnFailure(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeContinueOnFailure(checked, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeDisableStep(checked, recTestStep) {
  return async (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    new Promise((resolve) =>
      resolve(dispatch(getMethodObject(stepType)._changeDisableStep(checked, recTestStep)))
    ).then(() => {
      new Promise((resolve) => resolve(dispatch(getMethodObject(stepType)._updateUnSavedRecTestStep()))).then(() => {
        dispatch(RecTestStepCommonAction.resetEditRecTestStep());
      });
    });
  };
}

//Common Function
export function changeIsStepHasPrecondition(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeIsStepHasPrecondition(checked, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeRecTestStepPreConditionTitle(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeRecTestStepPreConditionTitle(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeRecTestStepElementCondition(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeRecTestStepElementCondition(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function showDeleteLoopConditionModalVisible(recTestStepTag, deleteLoopCondition = false) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) =>
        dispatch(
          RecTestStepCommonAction._showDeleteLoopConditionModalVisible(
            recTestStepTag,
            unSavedRecTestStep,
            deleteLoopCondition
          )
        )
      )
    );
  };
}

//Common Function
export function hideDeleteLoopConditionModalVisible() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._hideDeleteLoopConditionModalVisible());
  };
}

//Common Function
export function removeLoopConditionFromRecTestStep(unSavedStepTag) {
  return async (dispatch, getState) => {
    const { stepType, unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(unSavedRecStepTag || unSavedStepTag, (unSavedRecTestStep) => {
        dispatch(getMethodObject(stepType)._removeLoopConditionFromRecTestStep(unSavedRecTestStep));
      })
    );
  };
}

//Common Function
export function changeRecTestStepLoopCount(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeRecTestStepLoopCount(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function addVerificationForRepeatStep(recTestStepTag) {
  return (dispatch) => {
    let recElementGuid = getUniqueId();
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          dispatch(recordingStart());
          return RecTestStepCommonAction._addVerificationForRepeatStep(recElementGuid, unSavedRecTestStep);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeShowTestData(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        unSavedRecTestStep = RecTestStepCommonAction._changeShowTestData(checked, unSavedRecTestStep);
        dispatch({ type: CHANGE_SHOW_TEST_DATA_VISIBILITY, checked });
      })
    );
  };
}

//Common Function
export function selectTestData(recTestStepTag, testDataId, testDataValue) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        unSavedRecTestStep = RecTestStepCommonAction._selectTestData(testDataId, testDataValue, unSavedRecTestStep);
        dispatch({ type: VISIBLE_TEST_DATA_VISIBILITY });
      })
    );
  };
}

//Common Function
export function selectOutputTestData(recTestStepTag, testDataId) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._selectOutputTestData(testDataId, unSavedRecTestStep);
      })
    );
  };
}

export function selectAIStepTestData(recTestStepTag, testDataId,testDataValue) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._selectAIStepTestData(testDataId,testDataValue, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeInsertValueToTestData(recTestStepTag, value) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeInsertValueToTestData(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function selectOutputTestDataForRecElement(recTestStepTag, recElementGuid, testData) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._selectOutputTestDataForRecElement(
            testData,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeTestDataCustomValue(value, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeTestDataCustomValue(value, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeIgnoreTestPlanData(checked, recTestStepTag) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        return RecTestStepCommonAction._changeIgnoreTestPlanData(checked, unSavedRecTestStep);
      })
    );
  };
}

export function updateRecTestStepAction(recTestStep, updateConditionMethod, setStepRename, updateStepActionName) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStep?.tag, (unSavedRecTestStep) =>
        dispatch(
          getMethodObject(stepType)._updateRecTestStepAction(
            recTestStep,
            updateConditionMethod,
            updateStepActionName,
            unSavedRecTestStep
          )
        )
      )
    );
  };
}

//Common Function
export function changeRecElementName(name, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeRecElementName(name, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeDisableElement(checked, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeDisableElement(checked, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeRecElementDescription(description, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeRecElementDescription(
            description,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeRecElementTags(tags, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeRecElementTags(tags, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function removeElementIntoStep(recTestStepId, recTestStepTag, recElementGuid) {
  return async (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) =>
          dispatch(RecTestStepCommonAction._removeElementIntoStep(unSavedRecTestStep, unSavedRecElement)),
        recElementGuid,
        false
      )
    );
  };
}

//Common Function
export function changeCustomElementSearch(value, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementSearch(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeCustomElementSearchValue(value, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementSearchValue(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

export function changeIsAIStep(checked, recTestStep) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStep?.tag, (unSavedRecTestStep) => {
        if (checked) {
          dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
        } else {
          if (validForNLP(recTestStep.recTestStepProperty.action)) {
            dispatch(recordingStart());
            let unSavedRecElement = getUnSavedRecElement(
              recTestStep?.recTestStepProperty?.recElements,
              recTestStep?.recTestStepProperty?.recElements[0]?.guid
            );
            dispatch(RecTestStepCommonAction.editRecTestStepElement(recTestStep.tag, unSavedRecElement?.guid));
          }
        }
        return RecTestStepCommonAction._changeIsAIStep(checked, unSavedRecTestStep);
      })
    );
  };
}

//Common Function
export function changeCustomElementFilterTypeValue(value, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementFilterTypeValue(
            value,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function changeCustomElementAddTestData(checked, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementAddTestData(
            checked,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

export function changeCustomElementAddApplicationString(checked, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementAddApplicationString(
            checked,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

export function changeAIStepUseTestData(checked, recTestStepTag) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._changeAIStepUseTestData(
            checked,
            unSavedRecTestStep
          );
        }
      )
    );
  };
}

export function changeAIStepUseApplicationString(checked, recTestStepTag) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._changeAIStepUseApplicationString(
            checked,
            unSavedRecTestStep
          );
        }
      )
    );
  };
}

//Common Function
export function changeCustomElementAddFilterType(checked, recTestStepTag, recElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._changeCustomElementAddFilterType(
            checked,
            unSavedRecTestStep,
            unSavedRecElement
          );
        },
        recElementGuid
      )
    );
  };
}

export function addElementIntoStep(recTestStepTag, parentElementGuid) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        new Promise((resolve) =>
          resolve(RecTestStepCommonAction._addElementIntoStep(parentElementGuid, unSavedRecTestStep))
        ).then((unSavedRecTestStep) => {
          let unSavedRecElement = getUnSavedRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements);
          dispatch(
            RecTestStepCommonAction._captureExpandedElements(
              unSavedRecElement.guid,
              unSavedRecTestStep.tag,
              unSavedRecTestStep?.recTestScenarioRecTestStepAssocId,
              true
            )
          );
          dispatch(editRecTestStepElement(recTestStepTag, unSavedRecElement?.guid));
          dispatch(RecTestStepCommonAction._setSelectedTabKey(unSavedRecTestStep?.selectedTabKey));
          dispatch(recordingStart(parentElementGuid ? DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD : undefined));
        });
      })
    );
  };
}

export function setSelectedTabKey(key, recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) =>
          dispatch(RecTestStepCommonAction._setSelectedTabKey(key, unSavedRecTestStep, unSavedRecElement)),
        recElementGuid
      )
    );
  };
}

//Common Function
export function updateStepType(stepType) {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._updateStepType(stepType));
  };
}

//Common Function
export const handleSelectRecTestStepOrAction = (
  selectedId,
  recTestStep,
  childRecTestSteps,
  recTestStepList,
  currentActionTab,
  projectId,
  importRecTestScenarioStepActionList
) => {
  return async (dispatch) => {
    dispatch(
      RecTestStepCommonAction._handleSelectRecTestStepOrAction(
        selectedId,
        recTestStep,
        childRecTestSteps,
        recTestStepList,
        currentActionTab,
        projectId,
        importRecTestScenarioStepActionList
      )
    );
  };
};

//Common Function
export function changeAdvanceElementSelectorDrawerVisibility(visibleAction) {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._changeAdvanceElementSelectorDrawerVisibility(visibleAction));
  };
}

//Common Function
export function changeSelectorType(value, recTestStepTag, recElementGuid) {
  return async (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          dispatch(changeDeviceInteractionMode(SELECTOR_DEVICE_INTERACTION_MODE_MAPPING[value]));
          dispatch(resetElement());
          return RecTestStepCommonAction._changeSelectorType(value, unSavedRecTestStep, unSavedRecElement);
        },
        recElementGuid
      )
    );
  };
}

//Common Function
export function resetRecTestStepAction(recTestStepTag, recTestStepList) {
  return (dispatch, getState) => {
    const { unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
     const { selectedTestStepBlocks } = getState().TestBlockReducer;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) => {
        unSavedRecTestStep = RecTestStepCommonAction._resetRecTestStepAction(unSavedRecElementGuid, unSavedRecTestStep);
        if (unSavedRecTestStep.recTestStepProperty?.packageName) {
          dispatch(RecTestStepCommonAction.fetchAppActivity(unSavedRecTestStep.recTestStepProperty?.packageName));
        }
        dispatch(recordingStop());
        dispatch(RecTestStepCommonAction.resetTestDataContent(unSavedRecTestStep));
        dispatch(RecTestStepCommonAction.resetEditRecTestStep());
        dispatch(resetElement());
        dispatch(clearPreview());
        if(unSavedRecTestStep?.id===selectedTestStepBlocks?.id && !_.isEmpty(recTestStepList)){
          dispatch(selectRecTestSteps(recTestStepList[0]));
        }
        if (
          unSavedRecTestStep?.recTestStepProperty.action === ELEMENT_ACTION.SEND_KEYS &&
          unSavedRecTestStep?.recTestStepProperty?.userInputValue !== undefined &&
          !unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_testData") 
        ) {
          dispatch({ type: VISIBLE_TEST_DATA_VISIBILITY });
        }
        return unSavedRecTestStep;
      })
    );
  };
}

//Common Function
export function showImportRecTestStepModal(typeText, projectId, recTestStep, actionStep, selectChildStep) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._showImportRecTestStepModal(typeText, projectId, recTestStep, actionStep, selectChildStep)
    );
  };
}

//Common Function
export function hideImportRecTestStepModal() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._hideImportRecTestStepModal());
  };
}

// Common Function
export function searchValueFromImportRecTestStepOrActionTree(value, projectId, currentPageNumber, currentActionTab) {
  return (dispatch, getState) => {
    dispatch(
      RecTestStepCommonAction._searchValueFromImportRecTestStepOrActionTree(
        value,
        projectId,
        currentPageNumber,
        currentActionTab
      )
    );
  };
}

//Common Function
export function addConditionToStep(recTestStepTag, condition) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    // Added New Element into step and Update the RecTestStepList
    let unSavedRecTestStep = customFindRecTestStepByTag(recTestStepList, recTestStepTag, testBlockStep);
    let newRecElementGuid = undefined;
    if (condition === STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT) newRecElementGuid = getUniqueId();
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      condition === STEP_ITEMS.REPEAT_TILL ? "isLoopStep" : "isStepHasPrecondition",
      1
    );
    unSavedRecTestStep = RecTestStepCommonAction.addUnsavedValueAfterRecTestStepSave(
      { ...unSavedRecTestStep },
      condition,
      newRecElementGuid
    );
    new Promise((resolve) =>
      resolve(
        dispatch({
          type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
          updatedRecTestStep: unSavedRecTestStep,
        })
      )
    ).then((resp) => {
      dispatch(
        updateUnSavedRecTestStepFromRecTestStepList(
          recTestStepTag,
          (unSavedRecTestStep) =>
            dispatch(getMethodObject(stepType)._addConditionToStep(condition, unSavedRecTestStep)),
          newRecElementGuid
        )
      );
    });
  };
}

//Common Function
export function saveRecTestStepActionWithCurrentDeviceImage(recTestStepTag, recElementGuid) {
  return (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) =>
          dispatch(RecTestStepCommonAction._saveRecTestStepActionWithCurrentDeviceImage(unSavedRecTestStep)),
        recElementGuid
      )
    );
  };
}

export function reRecordRecTestStepElement(recTestStepTag, recElementGuid, parentRecElement) {
  return (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          unSavedRecTestStep = RecTestStepCommonAction._reRecordRecTestStepElement(recElementGuid, unSavedRecTestStep);
          if (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
            dispatch(recordingStart(parentRecElement ? DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD : undefined));
          }
          if (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
            dispatch(recordingStart(DEVICE_INTERACTION_MODE.DRAW));
          }
          return unSavedRecTestStep;
        },
        recElementGuid
      )
    );
  };
}

export function removeConditionFromRecTestStep(recTestStepTag, condition) {
  return async (dispatch) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(recTestStepTag, (unSavedRecTestStep) =>
        dispatch(RecTestStepCommonAction._removeConditionFromRecTestStep(recTestStepTag, condition, unSavedRecTestStep))
      )
    );
  };
}

export function clearPreview(runId, serverId) {
  return (dispatch, getState) => {
    const { previewRunId } = getState().RecTestStepReducer;
    if ((runId === undefined, previewRunId)) {
      runId = previewRunId;
    }
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._clearPreview(runId, serverId));
  };
}

export function showRecTestStepActionElementDetailDrawer(
  recTestStepId,
  recTestStepTag,
  recElementGuid,
  selectedRecTestStepDetail,
  disableSteps
) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._showRecTestStepActionElementDetailDrawer(
        recTestStepId,
        recTestStepTag,
        recElementGuid,
        selectedRecTestStepDetail,
        disableSteps
      )
    );
  };
}

export function hideRecTestStepActionElementDetailDrawer() {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction._hideRecTestStepActionElementDetailDrawer());
  };
}

export function captureExpandedSteps(recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd) {
  return (dispatch, getState) => {
    dispatch(
      RecTestStepCommonAction._captureExpandedSteps(recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd)
    );
  };
}

export function clearExpandedSteps() {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction._clearExpandedSteps());
  };
}

export function captureExpandedElements(recElementGuid, recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd) {
  return (dispatch, getState) => {
    dispatch(
      RecTestStepCommonAction._captureExpandedElements(
        recElementGuid,
        recTestStepTag,
        recTestScenarioRecTestStepAssocId,
        shouldAdd
      )
    );
  };
}

export function clearExpandedElements() {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction._clearExpandedElements());
  };
}

export function captureDeviceSelection(startPositionX, startPositionY, width, height) {
  return async (dispatch, getState) => {
    dispatch(RecTestStepCommonAction._captureDeviceSelection(startPositionX, startPositionY, width, height));
  };
}

export function getApplicationStrings(
  projectId,
  searchText,
  selectedLocale,
  currentPageNumber,
  recElementGuid,
  recTestStepTag,
  verificationGuid,
  filter
) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._getApplicationStrings(
        projectId,
        searchText,
        selectedLocale,
        currentPageNumber,
        recElementGuid,
        recTestStepTag,
        verificationGuid,
        filter
      )
    );
  };
}

export function selectedApplicationStringValue(selectData, recTestStepTag, recElementGuid) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._selectedApplicationStringValue(
            selectData,
            unSavedRecTestStep,
            unSavedRecElement,
            projectId
          );
        },
        recElementGuid
      )
    );
    if (selectData !== undefined) {
      dispatch(
        updateUnSavedRecTestStepFromRecTestStepList(
          recTestStepTag,
          (unSavedRecTestStep, unSavedRecElement) => {
            return RecTestStepCommonAction._selectedDeviceStringValue(
              undefined,
              unSavedRecTestStep,
              unSavedRecElement,
              projectId
            );
          },
          recElementGuid
        )
      );
    }
  };
}

export function selectedAIApplicationStringValue(selectData, recTestStepTag) {
  return async (dispatch, getState) => {
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep) => {
          return RecTestStepCommonAction._selectedAIApplicationStringValue(
            selectData,
            unSavedRecTestStep
          );
        }
      )
    );
  };
}

export const applicationDrawerVisible = (visible, guidForOpenApplicationDrawer) => {
  return async (dispatch) => {
    dispatch({ type: APPLICATION_DRAWER_VISIBLE, visible, guidForOpenApplicationDrawer });
  };
};

export function getListOfLocalesForApplicationStrings() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._getListOfLocalesForApplicationStrings());
  };
}

export const addApplicationStringsFormVisible = (visible, editApplicationStringData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_APPLICATION_STRINGS_FORM_VISIBLE, visible, editApplicationStringData });
  };
};

export const changeApplicationStringName = (value) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_APPLICATION_STRING_NAME, value });
  };
};

export const changeApplicationStringValue = (value, editId) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_APPLICATION_STRING_VALUE, value, editId });
  };
};
export const selectApplicationStringLocale = (value, editId) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_APPLICATION_STRING_LOCALE, value, editId });
  };
};

export function addApplicationStrings(key, value, locale, objectList, isImport) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._addApplicationStrings(key, value, locale, objectList, undefined, isImport));
  };
}

export function visibleGeneralSettingsDrawer(visible, actionForOpenDrawer, recTestStepTag) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._visibleGeneralSettingsDrawer(visible, actionForOpenDrawer, recTestStepTag));
  };
}

export function visibleIdentificationRulesDrawer(visible, tagForOpenDrawer) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._visibleIdentificationRulesDrawer(visible, tagForOpenDrawer));
  };
}

export function loadEditApplicationStringDetail(projectId, editApplicationStringKey, isImport) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._loadEditApplicationStringDetail(projectId, editApplicationStringKey, isImport));
  };
}

export const deleteApplicationStringLocale = (deleteApplicationStringLocaleId) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._deleteApplicationStringLocale(deleteApplicationStringLocaleId));
  };
};

export const deleteApplicationString = (deleteApplicationStringKey, tab, pageState) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._deleteApplicationString(deleteApplicationStringKey, tab, pageState));
  };
};

export const addApplicationStringLocaleAndValue = () => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._addApplicationStringLocaleAndValue());
  };
};

export function getDeviceStrings(
  projectId,
  searchText,
  selectedLocale,
  currentPageNumber,
  recElementGuid,
  recTestStepTag,
  verificationGuid,
  filter
) {
  return (dispatch) => {
    dispatch(
      RecTestStepCommonAction._getDeviceStrings(
        projectId,
        searchText,
        selectedLocale,
        currentPageNumber,
        recElementGuid,
        recTestStepTag,
        verificationGuid,
        filter
      )
    );
  };
}

export function selectedDeviceStringValue(selectData, recTestStepTag, recElementGuid) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    dispatch(
      updateUnSavedRecTestStepFromRecTestStepList(
        recTestStepTag,
        (unSavedRecTestStep, unSavedRecElement) => {
          return RecTestStepCommonAction._selectedDeviceStringValue(
            selectData,
            unSavedRecTestStep,
            unSavedRecElement,
            projectId
          );
        },
        recElementGuid
      )
    );
    if (selectData !== undefined) {
      dispatch(
        updateUnSavedRecTestStepFromRecTestStepList(
          recTestStepTag,
          (unSavedRecTestStep, unSavedRecElement) => {
            return RecTestStepCommonAction._selectedApplicationStringValue(
              undefined,
              unSavedRecTestStep,
              unSavedRecElement,
              projectId
            );
          },
          recElementGuid
        )
      );
    }
  };
}

export const deviceDrawerVisible = (visible, guidForOpenDeviceDrawer) => {
  return async (dispatch) => {
    dispatch({ type: DEVICE_DRAWER_VISIBLE, visible, guidForOpenDeviceDrawer });
  };
};

export function getListOfLocalesForDeviceStrings() {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._getListOfLocalesForDeviceStrings());
  };
}

export const addDeviceStringsFormVisible = (visible, editDeviceStringData) => {
  return async (dispatch) => {
    dispatch({ type: ADD_DEVICE_STRINGS_FORM_VISIBLE, visible, editDeviceStringData });
  };
};

export const changeDeviceStringName = (value) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_DEVICE_STRING_NAME, value });
  };
};

export const changeDeviceStringValue = (value, editId) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_DEVICE_STRING_VALUE, value, editId });
  };
};
export const selectDeviceStringLocale = (value, editId) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_DEVICE_STRING_LOCALE, value, editId });
  };
};

export function addDeviceStrings(key, value, locale, objectList) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._addDeviceStrings(key, value, locale, objectList, undefined));
  };
}

export function loadEditDeviceStringDetail(projectId, editDeviceStringKey) {
  return (dispatch) => {
    dispatch(RecTestStepCommonAction._loadEditDeviceStringDetail(projectId, editDeviceStringKey));
  };
}

export const deleteDeviceStringLocale = (deleteDeviceStringLocaleId) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._deleteDeviceStringLocale(deleteDeviceStringLocaleId));
  };
};

export const deleteDeviceString = (deleteDeviceStringKey, filter, pageState) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._deleteDeviceString(deleteDeviceStringKey, filter, pageState));
  };
};

export const addDeviceStringLocaleAndValue = () => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._addDeviceStringLocaleAndValue());
  };
};

export const setTestDataSetProfileForPreview = (testDataSetId, testDataSetName) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._setTestDataSetProfileForPreview(testDataSetId, testDataSetName));
  };
};
export const setAppLocaleProfileForPreview = (appLocale) => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._setAppLocaleProfileForPreview(appLocale));
  };
};

export const openPreviewSettingDrawer = () => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._openPreviewSettingDrawer());
  };
};

export const closePreviewSettingDrawer = () => {
  return async (dispatch) => {
    dispatch(RecTestStepCommonAction._closePreviewSettingDrawer());
  };
};

export function changeSelectTestStepBlocks(testBlock, actionType, projectId) {
  return (dispatch) => {
    dispatch({ type: CHANGE_SELECTED_TEST_BLOCKS, testBlock, actionType, projectId });
  };
}

export function clearUnSavedStep() {
  return (dispatch, getState) => {
    dispatch(RecTestStepCommonAction.resetEditRecTestStep());
    dispatch({ type: CLEAR_UNSAVED_STEP });
  };
}

export function showGenerateStepsDebugFromAIModal(){
  return (dispatch, getState) => {
    dispatch({ type: SHOW_GENERATE_STEPS_DEBUG_FROM_AI_MODAL});
  };
}

export function hideGenerateStepsDebugFromAIModal(){
  return (dispatch, getState) => {
    dispatch({ type: HIDE_GENERATE_STEPS_DEBUG_FROM_AI_MODAL});
  };
}

export function showGenerateStepsFromAIHeader(aiGenerationsFor,selectedRecTestStep){
  return (dispatch, getState) => {
    dispatch({ type: SHOW_GENERATE_STEPS_FROM_AI_MODAL,aiGenerationsFor,selectedRecTestStep });
  };
}

export function showAIGenerateStepsRow(){
  return (dispatch, getState) => {
    dispatch({ type: SHOW_AI_GENERATE_STEPS_ROW });
  };
}

export function hideAIGenerateStepsRow(){
  return (dispatch, getState) => {
    dispatch({ type: HIDE_AI_GENERATE_STEPS_ROW });
  };
}

export function hideAIGenerateStepsFromRecTestStep(hiddenAIGenerateRecTestStep){
  return (dispatch, getState) => {
    dispatch({ type: HIDE_AI_GENERATE_STEPS_FROM_REC_TEST_STEP ,hiddenAIGenerateRecTestStep});
  };
}

export function stopGenerateStepsFromAIModal(data){
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._stopGenerateStepsFromAIModal(data));
  };
}

export function fetchGenerateStepStatus(data){
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._fetchGenerateStepStatus(data));
  };
}

export function changeScenarioObjective(value,recTestStepId){
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_AI_SCENARIO_OBJECTIVE,value,recTestStepId });
  };
}

export function changeScenarioGenerateStepType(value){
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_SCENARIO_GENERATE_STEP_TYPE,value });
  };
}

export function changeScenarioGenerateInstructions(value){
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_AI_SCENARIO_INSTRUCTIONS,value });
  };
}

export function changeAIModelType(value){
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_AI_MODEL_TYPE,value });
  };
}

export function generateScenarioSteps(data,recTestStep){
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch(getMethodObject(stepType)._generateScenarioSteps(data,recTestStep));
  };
}

export function changeGeneratedScenarioSteps(data){
  return (dispatch, getState) => {
    dispatch({type:SCENARIO_GENERATED_STEPS,data})
  };
}