import { Collapse, Empty } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import EditRecTestScenarioTestDataSet from "./EditRecTestScenarioTestDataSet";

const EditTestPlanTestDataSet = (props) => {
  const { isForView } = props;
  const { testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      {/* <AssignedTestDataListView
        title={title}
        testDataValues={testDataSetForEdit?.testDataValues}
        isForView={isForView}
      /> */}

      {testDataSetForEdit?.testScenarioTestDataSaveReq &&
        !_.isEmpty(testDataSetForEdit?.testScenarioTestDataSaveReq) &&
          testDataSetForEdit?.testScenarioTestDataSaveReq.map((testScenarioTestData) => {
            return (!_.isEmpty(testScenarioTestData.testBlockTestDataSaveReq) || 
              !_.isEmpty(testScenarioTestData.testDataValues)) &&
                <div className="m-10">
                  <Collapse className="mt-10">
                    <Collapse.Panel 
                      header={"Scenario:" + testScenarioTestData.name
                        + " [Steps using Test Data: " +  testScenarioTestData?.totalstepCount
                        + " Unique Test Data Keys: " + testScenarioTestData?.totaluniqueTestDataCount + "]"}>
                      <EditRecTestScenarioTestDataSet
                        title={"Scenario Level Test Data"
                          + " [Steps using Test Data: " +  testScenarioTestData?.stepCount
                          + " Unique Test Data Keys: " + testScenarioTestData?.uniqueTestDataCount + "]"}
                        testDataSetForEdit={testScenarioTestData}
                        isForView={isForView}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </div>
            } 
          )
      }   
    </>
  );
};

export default EditTestPlanTestDataSet;
