import { Button, Modal, Space } from "antd";
import React, { Component } from "react";
import styles from "./CountdownTimer.module.scss";
import SvgLoader from "../../Util/SvgLoader";
import { TIMER_OUT_IMAGE } from "../../Constants/SvgConstants";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/Recording";
class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = { time: undefined, setTime: undefined, modalVisible: false, continueLoadDevice: false };
    this.props.setIdleTimer(null);
    // this.onAction = this.onAction.bind(this);
    // this.onActive = this.onActive.bind(this);
    this.startCounter = document?.getElementById("imageAndHiligherContainer");
  }

  handleRelease = () => {
    this.props.timeoutAction();
    this.setState({ modalVisible: false });
    clearInterval(this.timer);
    clearTimeout(this.timeOutTimer);
  };

  handleTimeInterval = () => {
    clearInterval(this.timer);
    this.setState({ setTime: Date.now() + 15000, time: 15000 });
    this.timer = setInterval(() => {
      const timerCount = this.state.setTime - Date.now();
      if (timerCount < 0) {
        this.handleRelease();
        clearInterval(this.timer);
      } else {
        this.setState({
          time: timerCount,
        });
      }
    }, 1000);
  };

  handleOpenModal = () => {
    if (this.props.idleTimer && this.props.idleTimer.pause) {
      this.props.idleTimer.pause();
    }
    this.handleTimeInterval();
    this.setState({ modalVisible: true });
  };

  handleTimer = () => {
    clearTimeout(this.timeOutTimer);
    clearInterval(this.timer);
    this.setState({ modalVisible: false });
    this.timeOutTimer = setTimeout(() => {
      this.handleTimeInterval();
      this.setState({ modalVisible: true });
    }, this.props.sessionTimeoutAt);
  };

  componentWillUnmount() {
    this.props.setIdleTimer(null);
    clearInterval(this.timer);
    clearTimeout(this.timeOutTimer);
  }

  componentDidUpdate() {
    if (!this.startCounter) {
      this.startCounter = document?.getElementById("imageAndHiligherContainer");
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          title=""
          visible={this.state.modalVisible}
          closable={false}
          maskClosable={false}
          className="counterDownTimerModal"
          centered
          footer={[
            <div className={`${styles.modalFooter}`}>
              <Space size={10}>
                <Button
                  type="primary"
                  className={styles.continueBtn}
                  onClick={() => {
                    this.props.refreshDeviceSession(
                      this.props.RecTestStepReducer?.selectedDeviceByUdid["autoServerDetail"]?.id,
                      this.props.RecTestStepReducer?.selectedDeviceByUdid["targetUniqueId"]
                    );
                    this.handleTimer();
                  }}
                >
                  Continue
                </Button>
                <Button type="primary" onClick={this.handleRelease} className={styles.releaseDeviceBtn}>
                  Release
                </Button>
              </Space>
            </div>,
          ]}
        >
          <div className={styles.modalContent}>
            <SvgLoader iconName={TIMER_OUT_IMAGE} />
            The device session will be terminated due to inactivity. Press 'Continue' if you intend to continue using
            the device.
            <div className={styles.notes}>
              This session will end in
              <span className={styles.counterTime}>{new Date(this.state.time).getSeconds()}</span>seconds.
            </div>
            <div>
             <span className={styles.lastInteractionLabel}> Last Interaction Time:</span>{" "}
              {new Date(this.props.idleTimer && this.props.idleTimer?.state?.lastActive).toLocaleTimeString("it-IT")}
            </div>
          </div>
        </Modal>
        {this.startCounter && (
          <IdleTimer
            ref={(ref) => {
              this.props.setIdleTimer(ref);
            }}
            element={this.startCounter}
            onIdle={this.handleOpenModal}
            debounce={10}
            timeout={this.props.sessionTimeoutAt}
          />
        )}
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return {
    RecTestStepReducer: state.RecTestStepReducer,
    ManageFarmReducer: state.ManageFarmReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CountdownTimer);
