import { Collapse, Empty } from "antd";
import React from "react";
import isEmpty from "lodash/isEmpty";
import ViewRecTestScenarioDefaultTestDataSetV2 from "./ViewRecTestScenarioDefaultTestDataSetV2";

const ViewTestPlanDefaultTestDataSetV2 = ({ defaultTestData, stepType, refTestDataList }) => {
  return (
    <>
      {defaultTestData && (
        <div className="mt-10">
          {defaultTestData?.testScenarioTestDataSaveReq && 
            !isEmpty(defaultTestData?.testScenarioTestDataSaveReq) &&
            defaultTestData.testScenarioTestDataSaveReq.map((testScenarioTestData) => {
              return (!isEmpty(testScenarioTestData?.testBlockTestDataSaveReq) || 
                    !isEmpty(testScenarioTestData?.testDataValues)) &&
                      <Collapse className="test-data-collapse-border" defaultActiveKey={"Default"}>
                        <Collapse.Panel 
                          collapsible={true} 
                          header={testScenarioTestData.name
                            + " [Steps using Test Data: " +  testScenarioTestData?.totalstepCount
                            + " Unique Test Data Keys: " + testScenarioTestData?.totaluniqueTestDataCount + "]"}
                          key={"Default"}>
                          <ViewRecTestScenarioDefaultTestDataSetV2
                            defaultTestData={testScenarioTestData}
                            title={"Test Scenario Level Test Data "
                              + " [Steps using Test Data: " +  testScenarioTestData?.stepCount
                              + " Unique Test Data Keys: " + testScenarioTestData?.uniqueTestDataCount + "]"}
                            stepType={stepType}
                            refTestDataList={refTestDataList}
                          />
                        </Collapse.Panel>
                      </Collapse>
                }
              )
          }
        </div>
      )}
    </>
  );
};

export default ViewTestPlanDefaultTestDataSetV2;
