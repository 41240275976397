import { Collapse, Popover, Space, Spin } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_BLOCK, TEST_DATA_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../Constants";
import { INFO_ICON } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import { loadEditTestDataDetail } from "../../../actions/TestDataAction";
import {
  getStepDataInfo,
  getTestStepsData,
  testDataSetInfoModal,
} from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import AddTestData from "../../TestData/AddTestData/AddTestData";
import TestDataSetHeader from "../../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/TestDataSetHeader/TestDataSetHeader";
import styles from "./TestStepsData.module.scss";
import TestStepsDataInfo from "./TestStepsDataInfo.js";
import AddTestDataV2 from "../../TestDataV2/AddTestDataV2/AddTestDataV2.js";
const { Panel } = Collapse;
const TestStepsData = (props) => {
  const { selectedStepId, setManageKeys } = props;
  const { testStepsDataLoading, getTestStepsDataList, getTestScenarioDetailList } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { addTestDataFormVisible } = useSelector((state) => state.TestDataReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTestStepsData(selectedStepId));
  }, []);

  const columns = () => {
    let col = [
      {
        title: " ",
        dataIndex: "index",
        width: 20,
      },
      {
        title: "Key",
        dataIndex: "key",
        width: 280,
      },
      {
        title: "Value",
        dataIndex: "value",
        width: 280,
      },
      {
        title: "Used In",
        dataIndex: "usedIn",
      },
    ];
    if ([TEST_SCENARIOS, TEST_STEP_BLOCKS].includes(props.stepType)) {
      col.push({
        title: "Actions",
        dataIndex: "detail",
      });
    }
    return col;
  };

  const testStepsData = (data, selectedKeys, scenarioId) => {
    return (
      !_.isEmpty(data) &&
      data?.map((row, index) => ({
        index: index + 1,
        key: (
          <div>
            {row?.key}
            <div>[Scope : {row?.scope}]</div>
            {props.stepType !== TEST_STEP_BLOCKS && row?.testBlockName && (
              <div>[Test Block : {row?.testBlockName}]</div>
            )}
          </div>
        ),
        value: row?.value ? (
          row?.values ? (
            <Popover
              title="Locales"
              content={row?.values.map((k, i) => (
                <div>
                  <span style={{ marginRight: 5 }}>{i + 1}</span>
                  {k}
                  <br></br>
                </div>
              ))}>
              {row?.value}
            </Popover>
          ) : (
            row?.value
          )
        ) : (
          "-"
        ),
        usedIn: (
          <Space size={10}>
            <span>{row?.usedIn}</span>
            <span>
              <SvgLoader
                iconName={INFO_ICON}
                handleClick={() => {
                  dispatch(testDataSetInfoModal(true, row?.id, selectedKeys, row?.key, row?.value));
                  dispatch(getStepDataInfo(row?.recTestStepId, scenarioId ? scenarioId : selectedStepId));
                }}
                infoIcon={true}
              />
            </span>
          </Space>
        ),
        detail: ((props.stepType === TEST_SCENARIOS && row?.scope !== TEST_BLOCK) ||
          (props.stepType === TEST_STEP_BLOCKS && row?.scope === TEST_BLOCK)) && (
          <span className="blueFont cursor-pointer" onClick={() => dispatch(loadEditTestDataDetail(row.id, row))}>
            Edit
          </span>
        ),
      }))
    );
  };

  const testDataSetDetails = [TEST_SCENARIOS, TEST_STEP_BLOCKS].includes(props.stepType)
    ? testStepsData(props.stepType === TEST_SCENARIOS ? getTestStepsDataList[""] : getTestStepsDataList, TEST_DATA_KEYS)
    : [];
  return (
    <>
      <TestDataSetHeader
        editTestDataSetName={"Base Profile"}
        setIsEditTestDataSet={setManageKeys}
        isDefaultView={true}
        selectedTestPlanName={""}
        stepType={props.stepType}
      />
      {addTestDataFormVisible && <AddTestDataV2 projectId={props.projectId} selectedStepId={selectedStepId} />}
      <div className={styles.stepsData}>
        <Spin spinning={testStepsDataLoading} tip="Loading" className="spinner-center">
          <div className="mt-15">
            {[TEST_SCENARIOS, TEST_STEP_BLOCKS].includes(props.stepType) ? (
              <>
                <Collapse expandIconPosition="start" defaultActiveKey={1}>
                  <Panel
                    header={`Test ${props.stepType === TEST_SCENARIOS ? "Scenario" : "Block"}: Manage Keys`}
                    key={1}>
                    {testDataSetDetails && testDataSetDetails.length > 0 ? (
                      <TableComponent columns={columns()} data={testDataSetDetails} />
                    ) : (
                      ""
                    )}
                  </Panel>
                </Collapse>
                {props.stepType === TEST_SCENARIOS &&
                  getTestBlocksTestData(getTestStepsDataList, columns, testStepsData)}
              </>
            ) : (
              ""
            )}
            {props.stepType === TEST_PLAN &&
              getTestScenarioDetailList &&
              getTestScenarioDetailList.length > 0 &&
              getTestPlanTestData(columns, getTestStepsDataList, getTestScenarioDetailList, testStepsData)}
          </div>
        </Spin>
        <TestStepsDataInfo selectedStepId={selectedStepId} />
      </div>
    </>
  );
};

const getTestPlanTestData = (columns, testPlanTestStepsData, getTestScenarioDetailList, testStepsData) => {
  return (
    <>
      {Object.keys(testPlanTestStepsData) &&
        Object.keys(testPlanTestStepsData)?.map((value) => {
          let scenario = getTestScenarioDetailList.filter((item) => item.id === parseInt(value));
          return (
            value !== "" &&
            testPlanTestStepsData[value] && (
              <>
                <Collapse expandIconPosition="start" key={value}>
                  <Collapse.Panel
                    header={scenario && scenario.length > 0 ? scenario[0]?.name : "Test Scenario"}
                    key={value}>
                    <Collapse expandIconPosition="start" key={value}>
                      {testPlanTestStepsData[value][""] && (
                        <Collapse.Panel header={"Test Scenario : Manage Keys"} key={value}>
                          <TableComponent
                            columns={columns()}
                            data={testStepsData(testPlanTestStepsData[value][""], TEST_DATA_KEYS, value)}
                          />
                        </Collapse.Panel>
                      )}
                      {getTestBlocksTestData(testPlanTestStepsData[value], columns, testStepsData, value)}
                    </Collapse>
                  </Collapse.Panel>
                </Collapse>
              </>
            )
          );
        })}
    </>
  );
};

const getTestBlocksTestData = (testBlockTestData, columns, testStepsData, scenarioId) => {
  return (
    <Collapse expandIconPosition="start">
      <Collapse.Panel header={"Test Block : Manage Keys"} key={2}>
        {Object.keys(testBlockTestData) &&
          Object.keys(testBlockTestData)?.map((value) => {
            return (
              value !== "" &&
              testBlockTestData[value] && (
                <Collapse expandIconPosition="start" key={value}>
                  <Collapse.Panel
                    header={
                      testBlockTestData[value].length > 0 ? testBlockTestData[value][0]["testBlockName"] : "Test Block"
                    }
                    key={value}>
                    <TableComponent
                      columns={columns()}
                      data={testStepsData(testBlockTestData[value], TEST_DATA_KEYS, scenarioId)}
                    />
                  </Collapse.Panel>
                </Collapse>
              )
            );
          })}
      </Collapse.Panel>
    </Collapse>
  );
};

export default TestStepsData;
