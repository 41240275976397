import { Button, Modal, Select } from "antd";
import _ from "lodash";
import { default as React, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { addMemberToTeam, setAddMemberToTeamLoader } from "../../actions/TeamsAction";
import { ADD_MEMBERS, SUCCESS_STATUS } from "../../Constants";
import { showNotification } from "../../Util";

export const AddMemberModal = styled(Modal)`
  .ant-modal-content {
  }
  .ant-modal-footer {
    padding: 12px;
  }
  .ant-input {
    border-radius: 7px;
    margin-bottom: 10px;
  }
`;
export default (props) => {
  const { modalStatus, modalClosed, children } = props;
  const dispatch = useDispatch();
  const [selectedMembers, setSelectedMembers] = useState([]);
  const { addMemberToTeamLoader, addMemberTeamMsg } = useSelector((state) => state.TeamsReducer);

  function handleChange(value) {
    setSelectedMembers(value);
  }

  useEffect(() => {
      handleCloseModal();
  }, [addMemberTeamMsg, dispatch]);

  const handleCloseModal = () => {
    setSelectedMembers([]);
    dispatch(setAddMemberToTeamLoader({ data: false }));
    modalClosed();
  }

  const handleSaveButton = () => {
    if (selectedMembers.length > 0) {
      let preparedObject = {
        teamId: localStorage.getItem("teamId"),
        teamMembers: selectedMembers,
      };
      dispatch(addMemberToTeam(preparedObject));
    } else {
      showNotification("error", "Please add at least one member", "");
    }
  };
  return (
    <AddMemberModal
      title="Add Members"
      visible={modalStatus}
      onOk={() => handleCloseModal()}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="submit"
          loading={addMemberToTeamLoader}
          type="primary"
          className="orange font-size-12"
          onClick={() => handleSaveButton()}
          disabled={_.isEmpty(selectedMembers)}
        >
          Add Members
        </Button>,
      ]}
    >
      <div className="sub-text font-weight-500">
        Members<span className="text-danger">*</span>
      </div>

      <Select
        mode="tags"
        className="w-100 mt-10"
        placeholder={ADD_MEMBERS.team}
        onChange={handleChange}
        value={selectedMembers}
      >
        {children}
      </Select>
      <div className="w-100 mt-10">Note: Added members will be notified by email.</div>
    </AddMemberModal>
  );
};
