import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Col, Input, Row, Select, Tooltip } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_EXECUTION_FARM, locales, PARALLEL_OS, PARALLEL_SPEED, SERIAL } from "../../../../../Constants";
import { changeTestJobData, getTestRailSuitesByTestPlanId } from "../../../../../actions/TestJobActions";
import { showNotification, validateEmail } from "../../../../../Util";
import { ToTopOutlined } from "@ant-design/icons";
const { Option } = Select;

const getLocales = () => {
  let localeItems = [];
  _.forOwn(locales, (value, key) => {
    localeItems.push(
      <Option key={value} value={value}>
        {key + " - (" + value + ")"}
      </Option>
    );
  });
  return localeItems;
};

const ExecutionSettingsTab = (props) => {
  const { testJobDetail, projectId } = props;
  const { getTestRailSuitesByTestPlanID } = useSelector((state) => state.TestJobReducer);
  const { getTestRailConfigData } = useSelector((state) => state.ProjectSettingsReducer);
  const dispatch = useDispatch();
  const [selectedTestSuiteId, setSelectedTestSuiteId] = useState([]);

  useEffect(() => {
    if (testJobDetail?.testRailTestSuiteIds) {
      let iD =
        getTestRailSuitesByTestPlanID &&
        getTestRailSuitesByTestPlanID
          ?.filter((i) => testJobDetail?.testRailTestSuiteIds?.includes(i?.id) && i)
          ?.map((i) => i?.id.toString());

      setSelectedTestSuiteId(iD);
    }
  }, [getTestRailSuitesByTestPlanID]);

  useEffect(() => {
    if (testJobDetail && testJobDetail?.testPlan?.id) {
      dispatch(getTestRailSuitesByTestPlanId(projectId, testJobDetail?.testPlan?.id));
    }
  }, [testJobDetail?.testPlan]);

  let testRailSuites = [];
  if (getTestRailSuitesByTestPlanID) {
    for (let i = 0; i < getTestRailSuitesByTestPlanID.length; i++) {
      testRailSuites.push(
        <Option key={getTestRailSuitesByTestPlanID[i].id} value={getTestRailSuitesByTestPlanID[i].id.toString()}>
          {getTestRailSuitesByTestPlanID[i].name}
        </Option>
      );
    }
  }

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      if (validateEmail(event.target.value)) {
        dispatch(changeTestJobData({ ...testJobDetail, email: [...testJobDetail.email, event.target.value] }));
      } else {
        showNotification("error", "Please Enter Valid Email Id");
      }
      event.target.value = "";
    }
  };

  const removeTags = (index) => {
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        email: [...testJobDetail.email.filter((email) => testJobDetail.email.indexOf(email) !== index)],
      })
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(changeTestJobData({ ...testJobDetail, [name]: value }));
  };

  const handleSelect = (e) => {
    const { name } = e.target;
    if (e.target.checked) {
      dispatch(changeTestJobData({ ...testJobDetail, [name]: 1 }));
    } else {
      dispatch(changeTestJobData({ ...testJobDetail, [name]: 0, testRailTestSuiteIds: [] }));
    }
  };
  return (
    <div className="m-10">
      <div className="modal-title">Execution Settings</div>
      <div className="font-size-13 font-weight-500 font-color-darkBlue mt-10 ">Execution Mode </div>
      <div className="display-flex mt-10">
        <input
          className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
          type="radio"
          defaultChecked={testJobDetail.executionType === SERIAL}
          onChange={handleChange}
          name="executionType"
          value={SERIAL}
          disabled={testJobDetail.devices.length > 1}
        />
        <span className="font-color-darkBlue mr-5">Serial</span> (Test Plan execution on one device at a time. Best to
        keep other devices free for other tests)
      </div>
      <div className="display-flex mt-10">
        <input
          className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
          type="radio"
          defaultChecked={testJobDetail?.executionType === PARALLEL_SPEED && testJobDetail?.devices?.length > 1}
          onChange={handleChange}
          name="executionType"
          disabled={testJobDetail.devices.length === 1}
          value={PARALLEL_SPEED}
        />
        <span className="font-color-darkBlue mr-5">Parallel Distribution</span> (Split Test Plan execution across all
        devices. Best for faster test completion)
      </div>
      <div className="display-flex mt-10">
        <input
          className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
          type="radio"
          defaultChecked={testJobDetail?.executionType === PARALLEL_OS && testJobDetail?.devices?.length > 1}
          onChange={handleChange}
          name="executionType"
          value={PARALLEL_OS}
          disabled={testJobDetail.devices.length === 1}
        />
        <span className="font-color-darkBlue mr-5">Parallel Repetition </span> (Repeat Test Plan execution across all
        devices. Best for more devices or local coverage)
      </div>
      <div className="font-size-13 font-weight-500 font-color-darkBlue mt-20">Other Settings</div>
      <div className="mt-5">
        <Checkbox defaultChecked={testJobDetail.isLogs} onChange={handleSelect} name="isLogs">
          <span className="font-color-darkBlue font-size-13">Capture Logs</span>
        </Checkbox>
      </div>
      <div className="mt-5">
        <Checkbox defaultChecked={testJobDetail.isCaptureVideo} onChange={handleSelect} name="isCaptureVideo">
          <span className="font-color-darkBlue font-size-13"> Capture Videos</span>
        </Checkbox>
      </div>
      <div className="mt-5">
        <Checkbox
          defaultChecked={testJobDetail?.updateResultToTestRail}
          onChange={handleSelect}
          disabled={_.isEmpty(getTestRailConfigData) || _.isEmpty(getTestRailSuitesByTestPlanID)}
          name="updateResultToTestRail"
        >
          <span className="font-color-darkBlue font-size-13"> Update Results To TestRail</span>
        </Checkbox>
      </div>
      <div className="width-50 mt-10">
        <Select
          disabled={testJobDetail.updateResultToTestRail === 0}
          className="w-100"
          mode="tags"
          rows={2}
          placeholder="Select Multiple Test Rail Suites"
          onChange={(e) => {
            setSelectedTestSuiteId(e);
            dispatch(changeTestJobData({ ...testJobDetail, testRailTestSuiteIds: e && e.map(Number) }));
          }}
          value={(!_.isEmpty(selectedTestSuiteId) && selectedTestSuiteId) || undefined}
        >
          {testRailSuites}
        </Select>
      </div>
      <div className="font-size-13 font-weight-500 font-color-darkBlue mt-20">Email Run Results Report To:</div>
      <div className="width-50 mt-10">
        <div className="tags">
          {testJobDetail?.email?.map((tag, index) => (
            <li key={index} className="tag">
              <span>{tag}</span>
              <FontAwesomeIcon className="ml-10 cursor-pointer" onClick={() => removeTags(index)} icon={faTimes} />
            </li>
          ))}
        </div>
        <div className="font-size-12 font-color-darkBlue">Email Addresses</div>
        <Input
          className="w-100 border-textArea"
          type="text"
          onKeyUp={(event) => addTags(event)}
          name="email"
          placeholder="Enter email addresses"
        />
      </div>
      <div className="width-50 mt-10">
        <div className="font-size-12 font-color-darkBlue mb-5">Notes</div>
        <TextArea
          rows={3}
          className="border-textArea"
          type="text"
          onChange={handleChange}
          placeholder="Enter notes for this Job"
          value={testJobDetail.scheduleNote}
          name="scheduleNote"
        />
      </div>
    </div>
  );
};

export default ExecutionSettingsTab;
