import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Modal, Popover, Spin, Tooltip } from "antd";
import _ from "lodash";
import { openPreviewSettingDrawer, updateStepType } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { saveTestJobRights } from "../../../../actions/Recording";

import { ARROW_DOWN, ICON_EDIT, ICON_SCHEDULE, ICON_SCHEDULE_JOB } from "../../../../CdnImagePath";
import {
  ACTION_NOT_VIEW,
  ACTION_VIEW,
  ALL,
  All_RECORDS,
  CUSTOM,
  EXECUTION_JOB,
  INFO_TYPE,
  JOB_DESIGNER,
  MY_JOBS,
  ONETIME,
  OWNED,
  PRIVATE,
  PUBLIC,
  RECURRING,
  SHARED,
  SHARED_WITH_ME,
  SUCCESS_TYPE,
  TEST_PLAN,
  TOOLTIP_BG_COLOR,
  defaultDateTimeAmPm,
  defaultDateTimeFormat,
  executionModeShortText,
} from "../../../../Constants";
import { BLUE_COLOR, ICON_PLAY } from "../../../../Constants/SvgConstants";
import {
  CustomText,
  cloneModal,
  deleteModal,
  displayUTCDateFormatWithToolTip,
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  getFormateDateAndTime,
  getPermissionType,
  getSelectedFrequencyStatus,
  isSelectedAllIds,
  removeSelectedIds,
  showNotification,
  showPermissionTypeIcon,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { changeTestDataSetView } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import {
  changeBuildJobListFilter,
  changeExecutionManagerTabAndSubTab,
  cloneTestJob,
  getScheduleJobCurl,
  getTestJob,
  getTestJobByJobId,
  openTestJobScheduleDrawer,
  removeJob,
  scheduleNow,
} from "../../../../actions/TestJobActions";
import SelectAllBox from "../../../CommonComponents/SelectAllBox";
import ActionComponent from "../../../CoreComponents/ActionComponent/ActionComponent";
import FilterComponent from "../../../CoreComponents/FilterComponent/FilterComponent";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import PermissionDrawer from "../../../CoreComponents/PermissionDrawer/PermissionDrawer";
import CommonSearch from "../../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";
import PermissionFilter from "../../../PermissionFilter/PermissionFilter";
import JobSettingModal from "../../../RecTestStep/ScenarioHeader/ScenarioHeaderOptions/JobSettingModal/JobSettingModal";
import TestPlanResultDeviceDetails from "../../../TestPlan/TestPlanResultDetails/TestPlanResultDeviceDetails";
import styles from "./AllJobs.module.scss";

const AllJobs = (props) => {
  const { projectId, setCurrentMenuOptionsSelected } = props;
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchByFrequency, setSearchByFrequency] = useState("");
  const [curlModal, setCurlModal] = useState(false);
  const [selectAllTestJob, setSelectAllTestJob] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [permissionTestJobs, setPermissionTestJobs] = useState(null);
  const [selectedTestJob, setSelectedTestJob] = useState(undefined);
  const filterPermission = [
    { id: OWNED, value: MY_JOBS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];
  const {
    currentTestJobPage,
    totalTestJobPageItems,
    totalTestJobPage,
    getTestJobList,
    fetchedCurlForJob,
    curlFetchForJobLoading,
    totalPageItems,
    testJobListFilter,
  } = useSelector((state) => state.TestJobReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  useEffect(() => {
    dispatch(getTestJob(1, projectId, searchKeyword, searchByFrequency, testJobListFilter));
    setSelectAllTestJob([]);
  }, [projectId]);

  useEffect(() => {
    dispatch(updateStepType(EXECUTION_JOB));
  }, []);

  useEffect(() => {
    if (permissionTestJobs?.testJobRights?.data) {
      setMembers([...permissionTestJobs?.testJobRights?.data]);
    }
  }, [permissionTestJobs]);
  const handleCopyText = () => {
    navigator.clipboard.writeText(fetchedCurlForJob);
    showNotification(SUCCESS_TYPE, "Copied To Clipboard!");
  };

  function deleteConfirm(testJob) {
    deleteModal(
      testJob
        ? `Do you want to remove "${testJob?.name}"?`
        : `Do you want to delete the selected ${selectAllTestJob?.length > 1 ? "Test Jobs" : "Test Job"}?`,
      () => {
        setSearchKeyword("");
        setSearchByFrequency();
        dispatch(
          removeJob(
            { testJobId: testJob ? [testJob.id] : selectAllTestJob },
            projectId,
            setSelectAllTestJob,
            testJobListFilter,
            searchKeyword
          )
        );
      }
    );
  }

  function getModalForCurl() {
    return curlModal ? (
      <Modal
        centered
        title={`cURL for Scheduling Job - ${permissionTestJobs?.name}`}
        visible={curlModal}
        // onOk={()=>handleCopyText()}
        onCancel={() => setCurlModal(false)}
        // okText={"Copy to Clipboard!"}
        footer={[
          <Button
            className="lightPurple"
            onClick={() => {
              handleCopyText();
              setCurlModal(false);
            }}
          >
            Copy
          </Button>,
        ]}
        width={"90%"}
        className={"modal-build-verification"}
      >
        <div className={styles.curlContainer}>
          {curlFetchForJobLoading ? (
            <Spin className={styles.spinner}></Spin>
          ) : (
            <div className={styles.modalContent}>
              <span className={styles.modalText}>{CustomText(fetchedCurlForJob)}</span>
            </div>
          )}
        </div>
      </Modal>
    ) : null;
  }

  const testJobSelectionCheckboxType = (row) => {
    if (row?.allowDelete === ACTION_VIEW) {
      return (
        <div>
          <Checkbox
            className={styles.marginRight}
            checked={selectAllTestJob && selectAllTestJob?.includes(row?.id)}
            onChange={(e) => {
              e.target.checked
                ? setSelectAllTestJob([...selectAllTestJob, row?.id])
                : setSelectAllTestJob(selectAllTestJob && selectAllTestJob?.filter((i) => i !== row?.id));
            }}
          />
        </div>
      );
    }
  };

  const showCheckBoxInTableHeader =
    _.isEmpty(selectAllTestJob) &&
    !_.isEmpty(getTestJobList) &&
    getTestJobList?.some((i) => i?.allowDelete === ACTION_VIEW);

  const columns = [
    {
      title: (
        <div className={!showCheckBoxInTableHeader && "table_Details"}>
          {showCheckBoxInTableHeader && (
            <span className={styles.label}>
              <Checkbox
                className={styles.marginRight}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestJob([
                      ...selectAllTestJob,
                      ...(
                        getTestJobList &&
                        getTestJobList?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestJob.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestJob(removeSelectedIds(selectAllTestJob, getTestJobList));
                  }
                }}
              />
            </span>
          )}
          Job Info
        </div>
      ),
      dataIndex: "jobInfo",
    },
    {
      title: "Test Plan",
      dataIndex: "testPlan",
    },
    {
      title: "Test Coverage",
      dataIndex: "testCoverage",
    },
    {
      title: "Job History",
      dataIndex: "jobHistory",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const TestJobActions = (row) => {
    let arr = [
      {
        title: "Duplicate Job",
        name: "duplicateJob",
      },
    ];
    if (row?.allowExec === ACTION_VIEW) {
      arr.push({ title: "Get URL", name: "getUrl" });
    }
    if (row?.allowDelete === ACTION_VIEW) {
      arr.push({ title: "Delete Job", name: "deleteJob" });
    }
    if (loggedInUserDetails?.userEmail === row?.createdBy?.email || row?.allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdBy?.email) ||
            (row?.isPrivate === 1 &&
              !_.isEmpty(row?.testJobRights?.data) &&
              loggedInUserDetails?.userEmail === row?.createdBy?.email)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  const filterList = [
    {
      title: "One time",
      name: ONETIME,
    },
    {
      title: "Recurring",
      name: RECURRING,
    },
    {
      title: "All",
      name: ALL,
    },
  ];

  const setPermissionData = () => {
    if (permissionTestJobs?.isPrivate && !_.isEmpty(permissionTestJobs?.testJobRights?.data)) {
      setPreviousPermission(permissionTestJobs?.testJobRights?.data);
      setMembers([...permissionTestJobs?.testJobRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const savePermission = (Value, testJobData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isExec: i?.isExec,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let testJobObj = {
      testJob: { id: testJobData?.id },
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !_.isEmpty(result) ? result : [],
    };
    dispatch(saveTestJobRights(testJobObj, 1, projectId, searchKeyword, searchByFrequency, testJobListFilter));
    setOpenPermissionDrawer(false);
  };

  const handleActionChange = (actionName, testJobData) => {
    setPermissionTestJobs(() => ({ ...testJobData }));
    switch (actionName) {
      case "getUrl":
        dispatch(getScheduleJobCurl({ testJobId: testJobData?.id, isManual: 1 }));
        setCurlModal(true);
        break;
      case "duplicateJob":
        cloneModal(testJobData?.name, `job`, () => {
          dispatch(cloneTestJob(testJobData?.id, projectId, testJobListFilter));
        });
        break;
      case "deleteJob":
        deleteConfirm(testJobData);
        break;
      case PRIVATE:
        savePermission(PRIVATE, testJobData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, testJobData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const showDeviceList = (devices) => {
    return (
      <Popover
        content={
          Array.isArray(devices) &&
          devices?.map((i, index) => (
            <TestPlanResultDeviceDetails
              deviceResultDetails={{
                targetUniqueId: i?.targetUniqueId,
                brandName: i?.brand,
                name: i?.name,
                locale: i?.locale,
                platform: i?.platformName,
                osVer: i?.osVersion,
                devicesLength: devices.length,
                currentIndex: index,
              }}
            />
          ))
        }
        title="Devices"
        placement="bottom"
        overlayClassName={devices?.length > 3 && "showDeviceDetails"}
      >
        <span className={styles.deviceListPopover}>{devices?.length}</span>
      </Popover>
    );
  };

  const showTooltipTitle = (row) =>
    ` Execution on Every ${row?.minutes}th past the ${row?.hours}th
      ${row?.days}
      (${row?.timeZoneName})`;

  const handleFilters = (e) => {
    dispatch(changeBuildJobListFilter(e, projectId, searchKeyword));
  };

  const testJobDetails =
    getTestJobList &&
    getTestJobList?.map((row) => ({
      key: row?.id,
      jobInfo: (
        <div className={`display__flex ${row.allowDelete === ACTION_NOT_VIEW && "table_Details"}`}>
          {testJobSelectionCheckboxType(row)}
          <div>
            <div className={styles.tooltipContent}>
              <span className={styles.tooltip}>
                <Tooltip title={getPermissionType(row?.isPrivate, row?.testJobRights?.data)} color={TOOLTIP_BG_COLOR}>
                  <FontAwesomeIcon
                    icon={showPermissionTypeIcon(getPermissionType(row?.isPrivate, row?.testJobRights?.data))}
                  />
                </Tooltip>
              </span>
              <h3
                className={styles.blueFontColor}
                onClick={() => {
                  dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
                  dispatch(getTestJobByJobId({ testJobId: row?.id }));
                }}
              >
                {row?.name}
              </h3>
            </div>
            <div>
              <span className={styles.label}>Execution Mode: </span> {executionModeShortText[row?.executionType]}
            </div>
            <div>
              <span className={styles.label}>Execution Farm: </span>
              <span className={styles.fontSize}>{row?.deviceServiceFarm}</span>
            </div>
            <div>
              <span className={styles.label}>Build to be used: </span>
              <Tooltip color={TOOLTIP_BG_COLOR} title={row?.buildToBeUsedTitle === "URL" && row?.buildToBeUsed}>
                <span className={row?.buildToBeUsedTitle === "URL" && styles.buildTOBeUsedText}>
                  {row?.buildToBeUsedTitle}
                </span>
              </Tooltip>
            </div>
            <div>
              <span className={styles.label}>Devices to run on: </span>
              {!_.isEmpty(row?.devices) ? (
                showDeviceList(row?.devices)
              ) : (
                <span className={styles.noDeviceText}> Unknown </span>
              )}
            </div>
            {row?.lastRun?.status === "SCHEDULED" && (
              <div>
                <span className={styles.label}>Recurring Schedule: </span>
                {getSelectedFrequencyStatus(row?.frequency)}
              </div>
            )}
          </div>
          {row?.currentAndTotalTestDataProfile && (
            <div>
              <span className={styles.label}>Current TestData Profile & Total TestData Keys in Profile:</span>
            </div>
          )}
        </div>
      ),
      testPlan: (
        <>
          <div>
            <span className={styles.label}>Test Plan Name: </span> {row?.testPlanName}
          </div>
          <div>
            <span className={styles.label}>Test Details:</span> Scenarios: {row?.totalUniqueScenarios}, Steps:{" "}
            {row?.testPlanDetail?.totalSteps}, Screenshots: {row?.totalUniqueScreenShot}
          </div>
          <div>
            <span className={styles.label}>Test Data Profile:</span>{" "}
            {row?.testDataProfile && row?.testDataProfile?.name ? row?.testDataProfile?.name : ""}
          </div>
        </>
      ),
      testCoverage: (
        <>
          <div>
            <span className={styles.label}>
              Devices:
              {!_.isEmpty(row?.devices) ? (
                showDeviceList(row?.devices)
              ) : (
                <span className={styles.noDeviceText}> Unknown </span>
              )}{" "}
            </span>
          </div>
          <div>
            <span className={styles.label}>OS Coverage: </span>
            {row?.testCoverageDetails?.totalOsToCover?.toString().replaceAll(",", ", ")}
          </div>
          {row?.uniqueLocales && (
            <div>
              <span className={styles.label}>Locales:</span> {row?.uniqueLocales?.toString().replaceAll(",", ", ")}
            </div>
          )}
          <div>
            <span className={styles.label}>Tests: </span>
            <span>Scenarios: {row?.testCoverageDetails?.totalScenarioToExecute}, </span>
            <span>Steps: {row?.testCoverageDetails?.totalStepsToExecute}, </span>
            <span>ScreenShots: {row?.testCoverageDetails?.totalScreenShotToCapture}</span>
          </div>
        </>
      ),
      jobHistory: (
        <div>
          <div>
            <span className={styles.label}>Created By: </span>
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={
                row.createDate ? (
                  <>
                    {getDateTimeInFormat(row?.createDate)} (
                    {getDateTimeInUTCFormat(row?.createDate, defaultDateTimeFormat)} GMT)
                  </>
                ) : (
                  "NA"
                )
              }
            >
              <span className={styles.details}>{row?.createdBy?.name || "NA"}</span>
            </Tooltip>
          </div>
          <div>
            <span className={styles.label}>Last Updated By: </span>
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={
                row.updateDate ? (
                  <>
                    {getDateTimeInFormat(row?.updateDate)} (
                    {getDateTimeInUTCFormat(row?.updateDate, defaultDateTimeFormat)} GMT)
                  </>
                ) : (
                  "NA"
                )
              }
            >
              <span className={styles.details}>{row?.updatedBy?.name || "NA"}</span>
            </Tooltip>
          </div>
          <div>
            <span className={styles.label}>Last Executed On: </span>
            {row?.lastRun?.scheduleTime
              ? displayUTCDateFormatWithToolTip(row?.lastRun?.scheduleTime, defaultDateTimeFormat)
              : "NA"}
          </div>
        </div>
      ),
      action: (
        <div className={styles.actionIcon}>
          {row?.allowExec === ACTION_VIEW && (
            <>
              <div className={styles["buttonForActionStep"]}>
                <Tooltip color={TOOLTIP_BG_COLOR} title="Execute">
                  <div
                    className="cursor__pointer"
                    onClick={() => {
                      row?.testPlanDetail?.totalSteps === 0
                        ? showNotification(
                            INFO_TYPE,
                            "A Job needs at least 1 scenario to execute. Please update TestPlan and try again!"
                          )
                        : dispatch(scheduleNow({ testJobId: row?.id, isManual: 1 }, setCurrentMenuOptionsSelected));
                    }}
                  >
                    <SvgLoader iconName={ICON_PLAY} iconColor={BLUE_COLOR} width={"1.625rem"} height={"1.625rem"} />
                  </div>
                </Tooltip>
                <Tooltip color={TOOLTIP_BG_COLOR} title="Custom Job">
                  <div
                    className="cursor__pointer"
                    onClick={() => {
                      setSelectedTestJob(row);
                      dispatch(changeTestDataSetView(TEST_PLAN));
                      dispatch(openPreviewSettingDrawer());
                    }}
                  >
                    <SvgLoader iconName={ARROW_DOWN} iconColor={BLUE_COLOR} width={"1.625rem"} height={"1.625rem"} />
                  </div>
                </Tooltip>
              </div>
            </>
          )}
          {row.allowEdit === ACTION_VIEW && (
            <Tooltip color={TOOLTIP_BG_COLOR} title="Edit">
              <img
                src={ICON_EDIT}
                alt="Edit"
                className={styles.icons}
                onClick={() => {
                  dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
                  dispatch(getTestJobByJobId({ testJobId: row?.id }));
                }}
              />
            </Tooltip>
          )}

          {row?.allowExec === ACTION_VIEW && (
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={
                row?.lastRun?.status === "SCHEDULED"
                  ? row?.frequency === RECURRING
                    ? showTooltipTitle(row)
                    : getFormateDateAndTime(row?.updateDate, defaultDateTimeAmPm)
                  : "Schedule"
              }
            >
              <img
                src={row?.isJobScheduled === 1 && row?.frequency !== null ? ICON_SCHEDULE_JOB : ICON_SCHEDULE}
                alt="Schedule"
                className={styles.icons}
                onClick={() => dispatch(openTestJobScheduleDrawer(row?.id))}
              />
            </Tooltip>
          )}
          <ActionComponent actions={TestJobActions(row)} data={row} handleActionChange={handleActionChange} />
        </div>
      ),
    }));
  return (
    <>
      <div className={styles.innerHeader}>
        <div className={styles.mainHeaderText}>Execution Jobs</div>
        <div className={styles.buttonContainer}>
          <Button
            size="large"
            type="primary"
            className={styles.button}
            onClick={() => {
              dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, true));
            }}
          >
            Build Job
          </Button>
        </div>
      </div>
      <div className={styles.pageScroll}>
        <div className={styles.permissionFilters}>
          <div className="display__flex">
            <PermissionFilter
              permissionValue={filterPermission}
              active={testJobListFilter}
              handleFilters={handleFilters}
            />
          </div>

          <div className="display__flex">
            <div>
              <CommonSearch
                searchDetails={(searchWord) =>
                  dispatch(getTestJob(1, projectId, searchWord, searchByFrequency, testJobListFilter))
                }
                placeHolder="Search By Job Name"
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </div>

            <>
              <FilterComponent
                filterList={filterList}
                handleFilterChange={(filter) => {
                  setSearchByFrequency(filter);
                  dispatch(getTestJob(1, projectId, searchKeyword, filter, testJobListFilter));
                }}
                selectedFilter={getSelectedFrequencyStatus(searchByFrequency)}
              />
            </>
          </div>
        </div>
        {!_.isEmpty(selectAllTestJob) && (
          <div className={styles.selectAllBox}>
            <SelectAllBox
              isChecked={isSelectedAllIds(
                selectAllTestJob,
                getTestJobList?.filter((i) => i.allowDelete === ACTION_VIEW)
              )}
              selectAllIds={selectAllTestJob}
              handleChangeCheck={(isChecked) => {
                if (isChecked) {
                  setSelectAllTestJob([
                    ...selectAllTestJob,
                    ...(
                      getTestJobList &&
                      getTestJobList?.filter(
                        (i) => i.allowDelete === ACTION_VIEW && !selectAllTestJob.includes(i?.id) && i?.id
                      )
                    )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                  ]);
                } else {
                  setSelectAllTestJob(removeSelectedIds(selectAllTestJob, getTestJobList));
                }
              }}
              deleteConfirm={deleteConfirm}
              isDisable={_.isEmpty(getTestJobList)}
            />
          </div>
        )}
        <div className={styles.tableComponent}>
          <TableComponent columns={columns} data={testJobDetails} selected={selectAllTestJob} />
        </div>
        <div className={styles.pagination}>
          {!_.isEmpty(getTestJobList) && (
            <PaginationComponent
              currentPageItems={totalPageItems}
              totalItems={totalTestJobPageItems}
              currentPage={currentTestJobPage}
              totalPage={totalTestJobPage}
              callPaginationAPI={(pageNumber) =>
                dispatch(getTestJob(pageNumber, projectId, searchKeyword, searchByFrequency, testJobListFilter))
              }
            />
          )}
        </div>
        {window.pageYOffset > 300 && (
          <div className={styles.selectAllBox}>
            <SelectAllBox
              isChecked={isSelectedAllIds(
                selectAllTestJob,
                getTestJobList?.filter((i) => i.allowDelete === ACTION_VIEW)
              )}
              selectAllIds={selectAllTestJob}
              handleChangeCheck={(isChecked) => {
                if (isChecked) {
                  setSelectAllTestJob([
                    ...selectAllTestJob,
                    ...(
                      getTestJobList &&
                      getTestJobList?.filter(
                        (i) => i.allowDelete === ACTION_VIEW && !selectAllTestJob.includes(i?.id) && i?.id
                      )
                    )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                  ]);
                } else {
                  setSelectAllTestJob(removeSelectedIds(selectAllTestJob, getTestJobList));
                }
              }}
              deleteConfirm={deleteConfirm}
              isDisable={_.isEmpty(getTestJobList)}
            />
          </div>
        )}
      </div>
      {getModalForCurl()}
      <PermissionDrawer
        openPermissionDrawer={openPermissionDrawer}
        setOpenPermissionDrawer={setOpenPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        setPermissionData={setPermissionData}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        savePermission={savePermission}
        permissionData={permissionTestJobs}
        ownerEmailId={permissionTestJobs?.createdBy?.email}
        drawerTitle={"Execution Job"}
      />
      <JobSettingModal
        testPlanId={selectedTestJob?.testPlanId}
        testJobId={selectedTestJob?.id}
        setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
      />
    </>
  );
};

export default AllJobs;
