import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import {
  fetchSystemKeyByProjectIdDropdown,
  getListOfLocales,
  getTestDataForAddTestDataSystemKey,
  resetPreviousTestDataSetForEdit,
} from "../../../../../../actions/TestDataSetAction";
import EditTestDataSetHeaderV2 from "./EditTestDataSetHeaderV2";
import DeviceTestDataSetListV2 from "./DeviceTestDataSetListV2";
import EditTestBlockTestDataSetV2 from "./EditTestBlockTestDataSetV2";
import EditRecTestScenarioTestDataSetV2 from "./EditRecTestScenarioTestDataSetV2";
import EditTestPlanTestDataSetV2 from "./EditTestPlanTestDataSetV2";
import EditSystemKeyTestDataSetV2 from "./EditSystemKeyTestDataSetV2";
import { isArrayNotUndefinedAndNotNull } from "../../../../../../Util";
import { SYSTEM_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";

const EditTestDataSetV2 = (props) => {
  const dispatch = useDispatch();
  const { setIsEditTestDataSet, testPlanId, stepType, isSystemKeyView } = props;

  const { testDataSetForEdit, testDataSetView } = useSelector((state) => state.TestDataSetReducer);
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [dataSetList, setDataSetList] = useState([]);

  useEffect(() => {
    if (isArrayNotUndefinedAndNotNull(testDataList)) {
      setDataSetList(cloneDeep(testDataList));
    }
  }, [testDataList]);

  useEffect(() => {
    dispatch(fetchSystemKeyByProjectIdDropdown());
    dispatch(getListOfLocales());
    dispatch(resetPreviousTestDataSetForEdit());
  }, []);

  useEffect(() => {
    if (testDataSetForEdit && !testPlanId) {
      dispatch(getTestDataForAddTestDataSystemKey(testDataSetForEdit.id));
    }
  }, [testDataSetForEdit, testPlanId]);

  return (
    testDataSetForEdit && (
      <>
        <EditTestDataSetHeaderV2
          stepType={stepType}
          setIsEditTestDataSet={setIsEditTestDataSet}
          isSystemKeyView={isSystemKeyView}
        />

        <div className={stepType === TEST_PLAN ? "page-Scroll" : "page-Scroll-Of-Test-Data"}>
          {testDataSetView !== SYSTEM_KEYS && <DeviceTestDataSetListV2 testDataSetForEdit={testDataSetForEdit} />}
          {testDataSetView === TEST_STEP_BLOCKS && (
            <EditTestBlockTestDataSetV2
              title={"Test Block: Steps using Test Data"}
              testDataSetForEdit={testDataSetForEdit}
            />
          )}
          {testDataSetView === TEST_SCENARIOS && (
            <EditRecTestScenarioTestDataSetV2
              title={"Test Scenario: Steps using Test Data"
                + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
                + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
              testDataSetForEdit={testDataSetForEdit}
            />
          )}
          {testDataSetView === TEST_PLAN && <EditTestPlanTestDataSetV2 testDataSetForEdit={testDataSetForEdit} />}
          {testDataSetView === SYSTEM_KEYS && (
            <EditSystemKeyTestDataSetV2 
              title={"Test Data"
                + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
                + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
              testDataSetForEdit={testDataSetForEdit} />
          )}
        </div>
      </>
    )
  );
};

export default EditTestDataSetV2;