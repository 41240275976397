import React from "react";
import { addVerificationRow, removeVerificationRow } from "../../../../../reducer/RecTestStepCommonViewReducer";
import AddVerificationGroupV2 from "./AddVerificationGroupV2";
import AddVerificationRowV2 from "./AddVerificationRowV2";
import OperatorV2 from "./OperatorV2";
import RemoveVerificationRowV2 from "./RemoveVerificationRowV2";
import styles from "./VerificationsV2.module.scss";

const VerificationBuilderV2 = (props) => {
  const { isFirstRow } = props;
  return (
    <div style={{background:"#f3f6ff"}}>
        {isFirstRow && (
          <div className={styles["drawer-Main_Header"]}>
            <span> Apply Rules on Element:</span>
          </div>
        )}
      <div className={`${styles["operators-and-add-rules"]} ${!isFirstRow && styles['verifications_Border']}`} >
        <div className="display__flex gap-10">
          <OperatorV2
            disabled={props.disabled}
            operator={props.operator}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
          />
          <AddVerificationRowV2
            disabled={props.disabled}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
            addVerficationRow={addVerificationRow}
          />
          <AddVerificationGroupV2
            disabled={props.disabled}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
            addVerficationRow={addVerificationRow}
          />
        </div>
        {!props.isFirstRow && (
          <RemoveVerificationRowV2
            disabled={props.disabled}
            parentId={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
            removeVerificationRow={removeVerificationRow}
          />
        )}
      </div>
    </div>
  );
};

export default VerificationBuilderV2;
