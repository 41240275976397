import { Col, Input, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { changeCaptureScreenShotDelayTime } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { hasNotAllowedNegativeValue } from "../../../../Util";
import { StyledSpan } from "../RecTestStepPostActionSettings/RecTestStepPostActionSettings";

const StyledPercentageInput = styled(Input)`
  width: 30%;
  margin-right: 10px;
`;
export default (props) => {
  const { isForView, isInputDisabled, disabled, data } = props;
  const { recTestStepProperty } = props.data;

  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div className="display-flex align-items-center w-100 ml-10">
        <div className="font-size-13 display-flex align-items-center margin-top-3 margin-right-2">after</div>
        <div className="display-flex ml-5">
          <StyledPercentageInput
            disabled={isForView || isInputDisabled}
            value={data.recTestStepProperty?.captureScreenShotDelayTime}
            type="number"
            onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
            min={0}
            onChange={(e) =>
              !isForView && !isInputDisabled
                ? dispatch(changeCaptureScreenShotDelayTime(e.target.value, data?.tag))
                : undefined
            }
            placeholder="Pause Before Taking Screenshot"
            className="mt-5"
          />
          <StyledSpan className="margin-top-2">msecs</StyledSpan>
        </div>
      </div>
    </React.Fragment>
  );
};
