import { Popover } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import ShowTooltipV2 from "../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "../RecTestScenarioV2.module.scss";

const AddedToTestPlanColumnV2 = (props) => {
  const { row, index } = props;

  const showFirstTestPlan = (testPlan) => {
    return (
      <ShowTooltipV2
      text={testPlan}
      className={styles["test_Plan_Name"]}
    />
    );
  };

  const showListOfTestPlan = (testPlan, index) => {
    if (testPlan?.length > 2) {
      return (
        <>
          <span key={index} className={`${styles["label-name"]} ${styles["popover_Name"]}`}>
            {showFirstTestPlan(testPlan[0])}
            <Popover
              key={"Popover-" + index}
              content={testPlan?.slice(1)?.map((i, index) => (
                <div
                  className={` ${styles["popover-contents"]} ${
                    testPlan?.length - 2 !== index && styles["border-bottom"]
                  }`}
                >
                  <span className={styles.data}>{i}</span>
                </div>
              ))}
              title="Test Plan Names"
            >
              <span className={styles["popover-details"]}>
                {testPlan?.slice(1).length === 1
                  ? `+${testPlan?.slice(1).length} other`
                  : `+${testPlan?.slice(1).length} others`}
              </span>
            </Popover>
          </span>
        </>
      );
    } else {
      return <span key={index}>{!isEmpty(testPlan) ? showFirstTestPlan(testPlan[0]) : "N/A"}</span>;
    }
  };

  return (
    <div key={index}>
      <span className={styles["label-name"]}>Total: </span>
      <span className={styles["step-And-Test-Plan-Details"]}>{row?.testPlan?.length}</span>
      <div className="labelName">{!isEmpty(row?.testPlan) && showListOfTestPlan(row?.testPlan, row?.id)}</div>
    </div>
  );
};
export default AddedToTestPlanColumnV2;
