import React from "react";
import styles from "./TestPlanDetailsColumnsV2.module.scss";

const ScenariosAndStepsV2 = ({ row }) => {
  return (
    <div className={styles.detailsOfTable}>
      <div>
        <span className={styles["label-name"]}>Scenarios: </span>{" "}
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.testPlanCountDetails?.totalScenario}</span>
      </div>
      <div>
        <span className={styles["label-name"]}>Steps: </span>{" "}
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.testPlanCountDetails?.totalSteps}</span>
      </div>
      <div>
        <span className={styles["label-name"]}>Screenshots: </span>{" "}
        <span className={styles["step-And-Test-Plan-Details"]}>
          {row?.testPlanCountDetails?.captureScreenShotCounts}
        </span>
      </div>
    </div>
  );
};

export default ScenariosAndStepsV2;
