import { async } from "../api/automation";
import { notification } from "antd";
import { ADD_TEAM_MEMBER, 
  GET_ORG_LIST, 
  HIDE_DELETE_USER_MODAL, 
  REMOVE_TEAM_MEMBER, 
  REQUEST_MY_TEAMS, 
  REQUEST_PLATFORM, 
  REQUEST_USERS_FROM_ORG, 
  SAVE_TEAM,  
  SHOW_DELETE_USER_MODAL, 
  UPDATED_TEAM_SAVED, 
  CREATE_TEAM_LOADER,
  ADD_MEMBER_LOADER, 
  SUCCESS_STATUS,
  FAILED_STATUS,
  RECORD_10_PER_PAGE,
  LIST_OF_TEAM_MEMBERS,
  GET_TEAM_DETAILS_LOADER,
} from "../Constants";
import history from "../history";
import { showNotification, updateResponse } from "../Util";
import { getMyTeamMembers } from "./ProjectsAction";


export function goToTeams(organizationId) {
  return () => {
    history.push(`/${organizationId}/teams`);
  };
}

export function goToProjectsInTeam(id) {
  return () => {
    history.push(`/teams/${id}`);
  };
}

export function myTeams(orgId, pageNumber) {
  return async (dispatch, getState) => {
    let preparedObject = {
      orgId: orgId,
      pageNumber: pageNumber || 1,
      recordPerPage: RECORD_10_PER_PAGE,
      searchKeywords: "",
    };
    dispatch({ type: GET_TEAM_DETAILS_LOADER, isLoading: true });
    return async("teamUserAssoc/getByUserIdAndOrgId", "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEAM_DETAILS_LOADER, isLoading: false });
      dispatch({ type: REQUEST_MY_TEAMS, response, pageNumber });
      //Re call for update reducer variable
      // dispatch({ type: CLONE_REC_TEST_SCNEARIO });
    });
  };
}

export function getPlatformDevice() {
  return async (dispatch) => {
    return async("platformType/", "GET").then((response) => {
      response = updateResponse(response);
      dispatch({ type: REQUEST_PLATFORM, response });
    });
  };
}

export function getMyOrgUsers(id) {
  return async (dispatch, getState) => {
    let preparedObject = {
      orgId: id,
    };
    return async(`xpressUser/getListOfAllUsersFromOrg`, "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: REQUEST_USERS_FROM_ORG, response });
    });
  };
}

export function getOrgList() {
  return async (dispatch, getState) => {
    return async("orgUserAssoc/orgList", "GET").then((response) => {
      dispatch({ type: GET_ORG_LIST, response });
    });
  };
}

export function saveTeam(data,onClose) {
  return async (dispatch, getState) => {
    dispatch({ type: CREATE_TEAM_LOADER, data: true });
    return async("team/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        onClose()
        notification["success"]({
          message: SUCCESS_STATUS,
          description: response.message,
        });
        dispatch({ type: SAVE_TEAM, response });
        dispatch(
          myTeams(data.orgId)
        );
        dispatch({ type: UPDATED_TEAM_SAVED });
      } else {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      dispatch({ type: CREATE_TEAM_LOADER, data: false });
    }).catch(() => {
      dispatch({ type: CREATE_TEAM_LOADER, data: false });
    })
  };
}

export function addMemberToTeam(data) {
  return async (dispatch, getState) => {
    dispatch({ type: ADD_MEMBER_LOADER, data: true});
    return async("teamUserAssoc/saveListOfMembersInTeam", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: SUCCESS_STATUS,
          description: response.message,
        });
        dispatch(
          getMyTeamMembers(localStorage.getItem("teamId"))
        );
        dispatch({ type: UPDATED_TEAM_SAVED });
      } else {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      dispatch({ type: ADD_TEAM_MEMBER, response });
      dispatch({ type: ADD_MEMBER_LOADER, data: false});
      dispatch(getMyTeamMembers(data?.teamId));
      if (data?.orgId) {
        dispatch(myTeams(data?.orgId));
      }
    }).catch(() => {
      dispatch({ type: ADD_MEMBER_LOADER, data: false});
    })
  };
}

export function showDeleteUserModal(userId, userName) {
  return async (dispatch) => {
    dispatch({ type: SHOW_DELETE_USER_MODAL, userId, userName });
  };
}

export function hideDeleteUserModal() {
  return async (dispatch) => {
    dispatch({ type: HIDE_DELETE_USER_MODAL });
  };
}
export function removeMemberFromTeam(data) {
  return async (dispatch, getState) => {
    return async("teamUserAssoc/removeMemberFromTeam", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: REMOVE_TEAM_MEMBER, response });
      dispatch(
        getMyTeamMembers(localStorage.getItem("teamId"))
      );
      dispatch({ type: UPDATED_TEAM_SAVED });
    });
  };
}

export function getTeamMembersByTeamId(data) {
  return async (dispatch) => {
    return async("teamUserAssoc/getTeamMembers", "GET", {teamId: data}).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: LIST_OF_TEAM_MEMBERS, response });
      }
    });
  };
}

export function setLoaderForCreateTeam(flag) {
  return async (dispatch) => {
    dispatch({ type: CREATE_TEAM_LOADER, flag });
  };
} 

export function setAddMemberToTeamLoader(flag) {
  return async (dispatch) => {
    dispatch({ type: ADD_MEMBER_LOADER, flag });
  };
}