import React, { useCallback, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Select, Space, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { DELETE_ICON } from "../../../../CdnImagePath";
import { APPLY_FOR, EXECUTION_JOB, TOOLTIP_BG_COLOR } from "../../../../Constants";
import TableComponent from "../../TableComponent/TableComponent";
import styles from "./PermissionDrawerV2.module.scss";

const { Option } = Select;
const ApplyForMemberListV2 = (props) => {
  const {
    searchText,
    setSearchText,
    notShowExecutionCheck,
    ownerEmailId,
    applyFor,
    setApplyFor,
    removeFor,
    setRemoveFor,
    setApplyForOption,
    applyForOption,
    setIsAddNew,
    isAddNew,
  } = props;

  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { memberList } = useSelector((state) => state.CommonReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const isDisable = (emailId, isAdmin) => emailId === ownerEmailId || isAdmin;

  const addForApplyMember = useCallback(() => {
    setIsAddNew(true);
    const membersListFilter = memberList?.map((i) => i?.email);
    const ApplyMembers = membersListFilter?.filter((i) => !applyFor?.some((j) => j?.email === i));
    setApplyForOption(ApplyMembers);
  }, [memberList, applyFor]);

  const handleViewCheck = useCallback(
    (e, i) => {
      const { name, checked } = e.target;
      const handleViewObj = {
        ...applyFor[i],
        [name]: checked ? 1 : 0,
        isEdit: 0,
        isDelete: 0,
        isExec: 0,
      };
      const handleViewArray = applyFor.map((item, index) => (index === i ? handleViewObj : item));
      setApplyFor(handleViewArray);
    },
    [applyFor]
  );

  const handleApplyMembersSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleApplyMembersReset = (clearFilters) => {
    clearFilters({
      confirm: true,
      closeDropdown: true,
    });
    setSearchText("");
    setSearchedColumn("");
  };

  const handleAdminCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleAdminObj = {
      ...applyFor[i],
      [name]: checked ? 1 : 0,
      isView: 1,
      isEdit: 1,
      isDelete: 1,
      isExec: 1,
    };
    let handleAdminArray = applyFor?.map((i) => (i?.userId === handleAdminObj?.userId ? handleAdminObj : i));
    setApplyFor(handleAdminArray);
  };

  const handleSelectNewApplyMemberList = (e) => {
    setIsAddNew(false);
    const MemberUserId = memberList?.find((i) => i?.email === e)?.userId;
    const isChecked = MemberUserId === loggedInUserDetails?.userID || e === ownerEmailId ? 1 : 0;
    setApplyFor([
      ...applyFor,
      {
        userId: MemberUserId,
        email: e,
        isAdmin: isChecked,
        isView: isChecked,
        isEdit: isChecked,
        isDelete: isChecked,
        isExec: isChecked,
        shouldAddOrRemove: 1,
      },
    ]);
    const validApplyMember = removeFor?.filter((i) => i?.userId !== MemberUserId);
    setRemoveFor(validApplyMember);
  };

  const getApplyColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleApplyMembersSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleApplyMembersReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered && "#1890ff" }} />,
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleCheckBox = (row, index, name, onChangeFunc, isDisabledFunc) => (
    <Checkbox
      checked={row[name]}
      name={name}
      onChange={(e) => onChangeFunc(e, index)}
      disabled={
        name === "isAdmin"
          ? ownerEmailId === row?.email || loggedInUserDetails?.userEmail === row?.email
          : isDisabledFunc(row)
      }
    />
  );
  const handleDeleteMember = useCallback(
    (row) => {
      const applyForForMembers = applyFor.filter((item) => item !== row);
      setApplyFor(applyForForMembers);
    },
    [applyFor]
  );

  const handleCheck = useCallback(
    (e, i) => {
      const { name, checked } = e.target;
      const handleCheckObj = { ...applyFor[i], [name]: checked ? 1 : 0, isView: 1 };
      const handleCheckArray = applyFor.map((item, index) => (index === i ? handleCheckObj : item));
      setApplyFor(handleCheckArray);
    },
    [applyFor]
  );

  const applyMembersColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getApplyColumnSearchProps("email"),
    },
    { title: "Admin", dataIndex: "admin", align: "center" },
    { title: "View", dataIndex: "view", align: "center" },
    { title: "Edit", dataIndex: "edit", align: "center" },
    { title: "Delete", dataIndex: "delete", align: "center" },
    {
      title: "Execution",
      dataIndex: "execution",
      hidden: notShowExecutionCheck,
      align: "center",
    },
    { title: "", dataIndex: "action", align: "center" },
  ]?.filter((item) => !item.hidden);

  const ApplyMemberListDetails = applyFor && [
    ...applyFor?.map((row, j) => ({
      key: row?.userId,
      email: ownerEmailId === row?.email ? row?.email + ` (Owner)` : row?.email,
      admin: handleCheckBox(row, j, "isAdmin", handleAdminCheck),
      view: handleCheckBox(row, j, "isView", handleViewCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      edit: handleCheckBox(row, j, "isEdit", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      delete: handleCheckBox(row, j, "isDelete", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      execution: handleCheckBox(row, j, "isExec", handleCheck, (row) => isDisable(row?.email, row?.isAdmin)),
      action: (
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
          <img
            src={DELETE_ICON}
            alt="Delete"
            className={styles["delete-icon__drawer"]}
            onClick={() => handleDeleteMember(row)}
            width={40}
            height={40}
          />
        </Tooltip>
      ),
    })),
    !!isAddNew && {
      email: (
        <div>
          <Select
            className="width-full"
            placeholder="Select Members"
            onChange={(e) => handleSelectNewApplyMemberList(e)}
          >
            {applyForOption &&
              applyForOption?.map((i, k) => {
                return (
                  <Option value={i} key={k}>
                    {i}
                  </Option>
                );
              })}
          </Select>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={styles["members-list-details"]}>
        <div className={styles["table-header-text"]}>{APPLY_FOR}</div>
        <Button
          type="primary"
          onClick={() => addForApplyMember()}
          disabled={memberList?.length === applyFor?.length || !isEmpty(searchText)}
          className={styles["permission-table-header-btn"]}
        >
          Add
        </Button>
      </div>
      <div className={styles["table-content"]}>
        <TableComponent
          columns={applyMembersColumns}
          data={ApplyMemberListDetails?.filter(Boolean)}
          isScrollable={true}
          isExecution={stepType === EXECUTION_JOB ? true : false}
        />
      </div>
    </>
  );
};

export default ApplyForMemberListV2;