import React from "react";
import _ from "lodash";
import { Col, Input, Row, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { handleChangeErrorInvestigationData } from "../../../actions/ErrorInvestigationActions";
import { failureReasonOptions } from "../../../Constants";

const { Option } = Select;

const NewInvestigation = () => {
  const dispatch = useDispatch();
  const { errorInvestigationDetails } = useSelector((state) => state.ErrorInvestigationReducer);

  const handleChange = (e, name) => {
    dispatch(handleChangeErrorInvestigationData({ ...errorInvestigationDetails, [name]: e, previousTestStepId: 0 }));
  };

  return (
    <Row className="p-10 w-100">
      <Row className="mb-10 w-100">
        <Col span={6} className="font-size-14 font-weight-500 align-self-center">
          Failure Reason:
          {!errorInvestigationDetails?.previousTestStepId && <span className="font-color-red">*</span>}
        </Col>
        <Col span={18}>
          <Select
            className="border-radius-4 w-100"
            mode="tags"
            value={errorInvestigationDetails?.failureReason}
            onChange={(e) => handleChange(_.last(e), "failureReason")}
            placeholder="Please Select Failure reason"
          >
            {failureReasonOptions &&
              failureReasonOptions.map((i) => (
                <Option value={i} key={i}>
                  {i}
                </Option>
              ))}
          </Select>
        </Col>
      </Row>
      {errorInvestigationDetails && errorInvestigationDetails.failureReason === "Product Bug" && (
        <Row className="mb-10 w-100">
          <Col span={6} className="font-size-14 font-weight-500 align-self-center">
            Bug Url: {!errorInvestigationDetails?.previousTestStepId && <span className="font-color-red">*</span>}
          </Col>
          <Col span={18}>
            <Input
              className="border-radius-4"
              value={errorInvestigationDetails?.bugUrl}
              onChange={(e) => handleChange(e.target.value, "bugUrl")}
              placeholder="Please Enter Bug Url"
              name="bugUrl"
            />
          </Col>
        </Row>
      )}
      <Row className="mb-10 w-100">
        <Col span={6} className="font-size-14 font-weight-500 align-self-center">
          Description:
        </Col>
        <Col span={18}>
          <TextArea
            row={3}
            className="border-radius-4"
            value={errorInvestigationDetails?.errorDescription}
            onChange={(e) => handleChange(e.target.value, "errorDescription")}
            placeholder="Please Enter Error Description"
            name="errorDescription"
          />
        </Col>
      </Row>
    </Row>
  );
};

export default NewInvestigation;
