import { Switch, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { TOOLTIP_BG_COLOR } from "../../../../../../../Constants";
import { USE_FOR_EXECUTION } from "../../../../../../../Constants/TooltipConstants";
import { makeTestDataSetDefault } from "../../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import styles from "../AllTestDataSetV2.module.scss"

const DefaultSwitchColumnV2 = ({ testDataSet }) => {
  const dispatch = useDispatch();

  return (
    <span className={styles['default-switch-data']}>
      <Tooltip color={TOOLTIP_BG_COLOR} title={USE_FOR_EXECUTION}>
        <Switch
          checked={testDataSet?.isDefault === 1}
          onChange={(checked) => dispatch(makeTestDataSetDefault(checked, testDataSet))}
        />
      </Tooltip>
    </span>
  );
};

export default DefaultSwitchColumnV2;