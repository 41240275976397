import {
  ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT,
  CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE,
  ERROR_UPLOAD_APPLICATION_STRING,
  IMPORT_APPLICATION_STRINGS_FORM_VISIBLE,
  REMOVE_APPLICATION_STRINGS_XML,
  REMOVE_LOCALE_OPTIONS_FOR_IMPORT,
  REQUESTED_UPLOAD_APPLICATION_STRING,
  REQUEST_UPLOAD_APPLICATION_STRING,
  SELECT_APPLICATION_STRING_FILE_FOR_IMPORT,
  SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT,
  UPDATE_IMPORT_APPLICATION_STRINGS_DATA,
} from "../Constants";
import { getUniqueId } from "../Util";

let defaultState = {
  // New Variable
  importApplicationStringsData: [],
  removePreviousDataFromLocale: 1,
};
export default function ApplicationStringReducer(state = defaultState, action) {
  let importApplicationStringsData;
  switch (action.type) {
    case IMPORT_APPLICATION_STRINGS_FORM_VISIBLE:
      return {
        ...state,
        visibleImportApplicationStringForm: action?.visible,
        importApplicationStringsData: [
          {
            locale: "en-US",
            appFile: undefined,
            guid: getUniqueId(),
            projectId: action.projectId,
          },
        ],
      };
    case CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE:
      return {
        ...state,
        removePreviousDataFromLocale: action?.checked,
      };

    case UPDATE_IMPORT_APPLICATION_STRINGS_DATA:
      return {
        ...state,
        importApplicationStringsData: action.data,
      };
    case ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData.push({
        locale: "en-US",
        appFile: undefined,
        guid: getUniqueId(),
        projectId: action.projectId,
      });
      return {
        ...state,
        importApplicationStringsData,
      };
    case REMOVE_LOCALE_OPTIONS_FOR_IMPORT:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.filter((i) => i?.guid !== action?.guid);
      return {
        ...state,
        importApplicationStringsData,
      };

    case REMOVE_APPLICATION_STRINGS_XML:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i, index) => {
        return {
          ...i,
          appFile: action?.index === index ? undefined : i.appFile,
        };
      });
      return {
        ...state,
        importApplicationStringsData,
      };

    case SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i) => {
        if (i?.guid === action?.guid) {
          i.locale = action?.value;
        }
        return i;
      });
      return {
        ...state,
        importApplicationStringsData,
      };
    case SELECT_APPLICATION_STRING_FILE_FOR_IMPORT:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i) => {
        if (i?.guid === action?.guid) {
          i.appFile = action?.file[0];
        }
        return i;
      });
      return {
        ...state,
        importApplicationStringsData,
      };

    case REQUEST_UPLOAD_APPLICATION_STRING:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i) => {
        if (i?.guid === action?.item?.guid) {
          i.status = "uploading";
        }
        return i;
      });
      return {
        ...state,
        importApplicationStringsData,
      };

    case REQUESTED_UPLOAD_APPLICATION_STRING:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i) => {
        if (i?.guid === action?.item?.guid) {
          i.status = "done";
        }
        return i;
      });
      return {
        ...state,
        importApplicationStringsData,
      };
    case ERROR_UPLOAD_APPLICATION_STRING:
      importApplicationStringsData = [...state.importApplicationStringsData];
      importApplicationStringsData = importApplicationStringsData.map((i) => {
        if (i?.guid === action?.item?.guid) {
          i.status = "error";
        }
        return i;
      });
      return {
        ...state,
        requestForUploadingImportApplicationStringsData: false,
      };
    default:
      return {
        ...state,
      };
  }
}
