import React from "react";
import { AI_ICON, AUTH_BANNER } from "../../Constants/SvgConstants";
import SvgLoader from "../../Util/SvgLoader";
import styles from "./BannerV2.module.scss";

const BannerV2 = ({ imagName }) => {
  return (
    <div className={styles["banner_Container"]}>
      {!imagName && (
        <div className={styles["banner_Header_With_AI_Icon"]}>
          <div className={styles["AI_Icon_And_Header"]}>
            {" "}
            <SvgLoader iconName={AI_ICON} />
            <div className={styles["header"]}> AI Powered</div>
          </div>
          <div className={styles["banner_Sub_Header"]}>
            No Code Platform for E2E <span className={styles["android_Ios_Text"]}>Android</span> and{" "}
            <span className={styles["android_Ios_Text"]}>IOS</span> Testing.
          </div>
          <div className={styles["features"]}>
            <span>Scale</span> |<span>Speed</span> |<span>Accuracy</span> |<span>Cost Savings</span>
          </div>
        </div>
      )}
      <div className={styles["svg_container"]}>
        <SvgLoader iconName={imagName ? imagName : AUTH_BANNER} defaultCursor />
      </div>
    </div>
  );
};

export default BannerV2;