import React from "react";

const DeviceLoadingV2 = ({generateAISteps}) => {
  return (
    <div className={"device-loader-V2-wrapper display__flex align_Items__Center "}>
      <div className={`spinner ${generateAISteps && 'generateAIStepsSpinnerHight'}` }>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default DeviceLoadingV2;
