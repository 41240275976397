import { async } from "../api/automation";
import {
  ASSIGN_DEVICE_TO_FARM,
  CREATE_FARM,
  DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN,
  ERROR_TYPE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
} from "../Constants";
import history from "../history";
import { showNotification, updateResponse } from "../Util";
import { getDeviceFarmList } from "./ServerListAction";

export function createFarm(data) {
  return async (dispatch, getState) => {
    return async("deviceFarm/createFarm", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: CREATE_FARM, response });
      if (response && response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message, "");
      } else {
        showNotification(ERROR_TYPE, response.message, "");
      }
    });
  };
}

export function assignDeviceToFarm(data) {
  return async (dispatch, getState) => {
    dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
    return async("device/assignDeviceToFarm", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch(getDeviceFarmList({ projectId: data.projectId }));
      dispatch({ type: ASSIGN_DEVICE_TO_FARM, response });
      if (response && response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message, "");
      } else {
        showNotification(ERROR_TYPE, response.message, "");
      }
    });
  };
}

export function goToPoolManagement(organizationId, projectId) {
  return () => {
    history.push(`/${organizationId}/project/${projectId}/poolManagement`);
  };
}
