import { Button, Collapse, Drawer, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import {
  changeAIModelType,
  changeScenarioGenerateInstructions,
  changeScenarioObjective,
  fetchGenerateStepStatus,
  generateScenarioSteps,
  hideGenerateStepsDebugFromAIModal,
  stopGenerateStepsFromAIModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { JsonEditor } from "jsoneditor-react18";
import _ from "lodash"
import styles from "./GenerateScenarioStepsDebugModal.module.scss";
import ReactJson from "react-json-view";
import { basicScenarioGenerateInstruction } from "../../../../../Constants";
import TextArea from "antd/lib/input/TextArea";
import { deleteModal } from "../../../../../Util";
import { _fetchGenerateStepDebugStatus, _generateScenarioStepsDebug } from "../../../../../actions/RecTestStep/RecTestStepCommonAction";
const GenerateScenarioStepsDebugModal = (props) => {
  const { selectedRecTestScenarioId, testBlockStep, testPlanId } = props;
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const inputRef=useRef(null);
  const {
    showGenerateStepsDebugFromAiModal,
    scenarioObjective,
    scenarioGenerateSteps,
    scenarioGenerateInstructions,
    scenarioGenerateDebugRequestLoading,
    selectedModelType
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const [_scenarioGenerateSteps,set_scenarioGenerateSteps] = useState([])
  useEffect(() => {
    if (scenarioGenerateDebugRequestLoading) {
      intervalRef.current = setInterval(
        () => (dispatch(_fetchGenerateStepDebugStatus({
          objective: scenarioObjective,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          recTestScenarioId: selectedRecTestScenarioId,
          modelType: selectedModelType,
        }))),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [scenarioGenerateDebugRequestLoading]);
  
  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 1000);
    }
  }, [showGenerateStepsDebugFromAiModal]);

  useEffect(()=>{
      let temp = _.cloneDeep(scenarioGenerateSteps)
      temp = temp && temp.map(element => {
        delete element["screenshot"]
        delete element["pagesource"]  
        return element;
      });
      set_scenarioGenerateSteps(temp?temp:[]) 
  },[scenarioGenerateSteps])
  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>Generate Scenario Steps</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                scenarioGenerateDebugRequestLoading ? 
                deleteModal(
                 `Are you sure you want to stop generation of steps?`,
                  () => {
                    dispatch(stopGenerateStepsFromAIModal({
                      objective: scenarioObjective,
                      rules: scenarioGenerateInstructions,
                      deviceId: selectedDeviceByUdid?.targetUniqueId,
                      recTestScenarioId: selectedRecTestScenarioId,
                      modelType: selectedModelType,
                    }));
                  },
                  "",
                  true
                ):
                dispatch(hideGenerateStepsDebugFromAIModal());
              }}
            />
          </span>
        </div>
      }
      maskClosable={false}
      placement="right"
      closable={false}
      width={700}
      onClose={() => {
        dispatch(hideGenerateStepsDebugFromAIModal());
      }}
      visible={showGenerateStepsDebugFromAiModal}
      bodyStyle={{ padding: 15 }}
    >
      <div>
        <div>Scenario Objective:</div>
        <Input
          value={scenarioObjective}
          placeholder="Eg:'Create a post with the text 'Exploring the new park in our neighborhood!' and verify that the post has been created successfully.'"
          onChange={(e) => dispatch(changeScenarioObjective(e.target.value))}
          ref={inputRef}
        />
      </div>
      <div className="mt-5">
        <div>Model Type:</div>
        <Select
            className="w-100 h-40"
            placeholder={"Select Model"}
            value={selectedModelType}
            onChange={(e) => {
              dispatch(changeAIModelType(e))
            }}>
              <Select.Option value="gpt-4o-2024-05-13">Speed</Select.Option>
              <Select.Option value="ft:gpt-4o-2024-08-06:personal:xpressgpt4otrial2:A1lLr3XM">Reasoning</Select.Option>
              <Select.Option value="Anthropic">Anthropic</Select.Option>
            </Select>
      </div>
      <div>
        <div></div>
        <Collapse className="mt-5" expandIconPosition="start" key={0}>
          <Collapse.Panel
            header={"Scenario Generate Instructions:"}
            key={0}
          >
            <TextArea
              className={styles.instructionEditor}
              value={scenarioGenerateInstructions}
              size={"large"}
              onChange={(e) =>
                dispatch(changeScenarioGenerateInstructions(e.target.value))
              }
            />
          </Collapse.Panel>
        </Collapse>
      </div>
      <div className={styles.generateActionButtons}>
      {scenarioGenerateDebugRequestLoading?<Button
          type="primary"
          size="large"
          className="blue ml-10"
          disabled={selectedDeviceByUdid===undefined || scenarioObjective==null || scenarioObjective.length==0 || scenarioGenerateInstructions==null || scenarioGenerateInstructions.length==0}
          onClick={() =>
            dispatch(
              stopGenerateStepsFromAIModal({
                objective: scenarioObjective,
                rules: scenarioGenerateInstructions,
                deviceId: selectedDeviceByUdid?.targetUniqueId,
                recTestScenarioId: selectedRecTestScenarioId,
                modelType: selectedModelType,
              })
            )
          }
        >
          Stop Generating steps
        </Button>
        :<Button
          type="primary"
          size="large"
          className="blue ml-10"
          loading={scenarioGenerateDebugRequestLoading}
          disabled={selectedDeviceByUdid===undefined || scenarioObjective==null || scenarioObjective.length==0 || scenarioGenerateInstructions==null || scenarioGenerateInstructions.length==0}
          onClick={() =>
            dispatch(
              _generateScenarioStepsDebug({
                objective: scenarioObjective,
                rules: scenarioGenerateInstructions,
                deviceId: selectedDeviceByUdid?.targetUniqueId,
                recTestScenarioId: selectedRecTestScenarioId,
                modelType: selectedModelType,
              })
            )
          }
        >
          Generate Steps
        </Button>
        }
        {/* <Button
          type="primary"
          size="large"
          className="blue ml-10"
          disabled={
            scenarioGenerateRequestLoading || scenarioGenerateSteps == null || scenarioGenerateSteps.length == 0
          }
          onClick={() => dispatch(importStepFromJson(scenarioGenerateSteps))}
        >
          Save Steps
        </Button> */}
      </div>
      <div className={styles.jsonViewer}>
        {/* <JsonEditor
            sortObjectKeys={false}
            value={scenarioGenerateSteps}
            onChange={(value) => dispatch(changeGeneratedScenarioSteps(value))}
        /> */}
        <ReactJson src={_scenarioGenerateSteps} />
      </div>
    </Drawer>
  );
};

export default GenerateScenarioStepsDebugModal;