//testScenario
export const ADD_SCENARIO_STEP = "Add Step";
export const IMPORT_STEP_IN_SCENARIO = "Import an existing Step / TestBlock";
export const PLAY_SCENARIO = "Play Scenario";
export const IMPORT_SUB_STEP_IN_TEST_SCENARIO =
  "Add a new Sub-Step to the selected Step (by Importing an existing Step or TestBlock)";

//testBlock
export const ADD_TEST_BLOCK_STEP = "Add a new Step";
export const IMPORT_STEP_IN_TEST_BLOCK = "Import an existing Step / TestBlock";
export const IMPORT_SUB_STEP_IN_TEST_BLOCK = "Add a new Sub-Step to the selected Step (by Importing an existing Step)";
export const ADD_CONDITIONAL_STEP = "Add Condition to Test Block";
export const GENERATE_STEP = "Generate steps through AI.";
export const PLAY_TEST_BLOCK = "Play Test Block";

//action
export const ADD_ACTION_ELEMENT = "Add Action";
export const ADD_VERIFICATION = "Add Verification";
export const ADD_GENERAL_ACTION = "Add General Action";

//Common
export const ADD_STEP = "Add Step";
export const BUILD_TEST_DATA_SET = "Test Data";
export const PREVIEW_RUN = "Preview History";
export const STOP = "Stop";
export const RESULTS = "Results";
export const CLEAR_RESULTS = "Clear Results";
export const PREVIEW_SETTINGS = "Preview Settings";
export const ADD_SUB_STEP = "Add a new Sub-Step";
export const IMPORT_ACTION_IN_STEP = "Import an existing Action or Verification";
export const PLAY_THIS_STEP = "Play this step";
export const PLAY_FROM_HERE = "Play from here";
export const PLAY_TILL_HERE = "Play till here";
export const DUPLICATE = "Duplicate";
export const CONDITIONAL_STEP = "Add Condition (Repeat, When etc) to Selected Step.";
export const HIDE_DEVICE_VIEW = "Hide Device View";
export const SHOW_DEVICE_VIEW = "Show Device View";
export const ATTACH_APPLICATION_STRING = "Attach App String Key";
export const ATTACH_DEVICE_STRING = "Attach Device String Key";
export const APPLICATION_STRING_IN_SCENARIO = "View Application Strings used in Scenario Steps";
export const DEVICE_STRING_IN_SCENARIO = "View Device Strings used in Scenario Steps";
export const APPLICATION_STRING_IN_BLOCKS = "View Application Strings used in Block Steps";
export const DEVICE_STRING_IN_BLOCKS = "View Device Strings used in Block Steps";
export const APPLICATION_STRING = "Application Strings";
export const DEVICE_STRING = "Device Strings";

// Action
export const TAP = "Tap";
export const TAP_BY_INDEX = "Tap By Index";
export const LONG_PRESS = "Long Press";
export const ENTER_TEXT = "Enter Text";
export const CLEAR_TEXT = "Clear Text";
export const SWIPE_ELEMENT = "Swipe Element";
export const SCREENSHOT_ELEMENT = "Screenshot Element (TBD)";

//Build-Test-Plan-Data
export const TEST_DATA_SET_NAME =
  "Base Profile has Test Data values as Recorded in steps OR from the Profile (associated with Blocks/Scenarios) that is set to 'Use for Execution'";
export const MANAGE_KEYS = "Manage Keys";
export const USE_FOR_EXECUTION = "Use For Execution";
export const EDIT_TEST_DATA_VALUES = "Edit Test Data Values";
export const DELETE = "Delete";
export const VIEW_STEPS = "View Steps and associated Test Data";