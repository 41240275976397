import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAwsDeviceFarmPools, getAwsDeviceFarmProjects } from "../../../../actions/AwsDeviceFarmAction";

const { Option } = Select;
const SelectAwsDeviceProject = (props) => {
  const { awsDeviceFarmProjects } = useSelector((state) => state.AwsDeviceFarmReducer);
  const dispatch = useDispatch();
  const { required, onChange, testJobDetail } = props;
  useEffect(() => {
    dispatch(getAwsDeviceFarmProjects());
  }, []);
  return (
    <>
      <div className="font-size-12 font-weight-500">AWS Device Projects :{required ? <span className="text-danger">*</span> : ""}</div>
      <Select
        key="DeviceFarm"
        className={"select-app-for-default"}
        placeholder={"Please Select AWS Device Farm Project"}
        value={testJobDetail.deviceFarmIds}
        onChange={(e) => {
          dispatch(onChange({ ...testJobDetail, deviceFarmIds: [e] }));
          dispatch(getAwsDeviceFarmPools({ awsDeviceFarmProjectId: e }));
        }}>
        {awsDeviceFarmProjects &&
          awsDeviceFarmProjects.map((value, k) => {
            return (
              <Option key={k + 1} value={value.id}>
                {value.name}
              </Option>
            );
          })}
      </Select>
    </>
  );
};
export default SelectAwsDeviceProject;
