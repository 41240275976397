import { Select } from "antd";
import { default as React } from "react";
import { useDispatch } from "react-redux";
import { ELEMENT_ACTION, SELECTOR_TYPE, SELECTOR_TYPE_TEXT, STEP, VERIFICATIONS } from "../../../../Constants";
import { changeSelectorType } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepCustomElementDetail from "../RecTestStepCustomElementDetail/RecTestStepCustomElementDetail";
import RecTestStepElementSelectionDetail from "../RecTestStepElementSelectionDetail/RecTestStepElementSelectionDetail";
import RecTestStepImageSelectionDetail from "../RecTestStepImageSelectionDetail/RecTestStepImageSelectionDetail";
import styles from "./RecTestStepSelector.module.scss";

const RecTestStepSelector = (props) => {
  const { data, recElement, unSavedRecElementGuid, disabled, isPreviewRunning, isDeviceLoaded } = props;
  const dispatch = useDispatch();
  return (
    <>
      <div className={styles.selector}>
        <div className={styles.selectionText}>Selection Mode:</div>
        <Select
          disabled={
            unSavedRecElementGuid !== recElement.guid ||
            ![0, undefined].includes(recElement.id) ||
            disabled ||
            isPreviewRunning
          }
          value={recElement.elementSelectorType}
          onChange={(e) => {
            dispatch(changeSelectorType(e, data.tag, recElement.guid));
          }}
          className={styles.selectMode}>
          {/* <Select.Option value={SELECTOR_TYPE.CHILD_ELEMENT_WITHIN_HIERARCHY}>
            {SELECTOR_TYPE_TEXT.CHILD_ELEMENT_WITHIN_HIERARCHY}{" "}
          </Select.Option> */}
          <Select.Option value={SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN}>
            {SELECTOR_TYPE_TEXT.SELECT_ELEMENT_ON_SCREEN}
          </Select.Option>
          <Select.Option value={SELECTOR_TYPE.CUSTOM_ELEMENT}>{SELECTOR_TYPE_TEXT.CUSTOM_ELEMENT}</Select.Option>
          {[ELEMENT_ACTION.TAP, ELEMENT_ACTION.LONG_PRESS, VERIFICATIONS, STEP].includes(
            data?.recTestStepProperty?.action
          ) && (
            <Select.Option value={SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN}>
              {SELECTOR_TYPE_TEXT.SELECT_IMAGE_ON_SCREEN}
            </Select.Option>
          )}
        </Select>
      </div>
      {recElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN && (
        <RecTestStepElementSelectionDetail {...props} />
      )}
      {recElement.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT && <RecTestStepCustomElementDetail {...props} />}
      {recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN &&
        ([0, undefined].includes(recElement.id) && !isDeviceLoaded ? (
          <div className="font-size-13 font-weight-500 orangeText">Load Device to select an area on the screen</div>
        ) : (
          <RecTestStepImageSelectionDetail {...props} />
        ))}
    </>
  );
};

export default RecTestStepSelector;
