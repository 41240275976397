import { Button, Modal } from "antd";
import _ from "lodash";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPreviousSearchValue } from "../../../actions/CommonActions";
import {
  changeScenarioListFilter,
  changeSearchScenarioKeyword,
  createNewRecTestScenario,
  deleteRecTestScenario,
  getRecTestScenarioList,
  hideDeviceSelectionModal,
  navigateToRecordingScreen,
} from "../../../actions/RecTestScenarioAction";
import { ACTION_VIEW, All_RECORDS, MY_SCENARIOS, OWNED, SHARED, SHARED_WITH_ME } from "../../../Constants";
import { deleteModal, isSelectedAllIds, platformAbbrevMapping, removeSelectedIds } from "../../../Util";
import SelectAllBox from "../../CommonComponents/SelectAllBox";
import PaginationComponent from "../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../CoreComponents/SearchComponent/CommonSearch";
import PermissionFilter from "../../PermissionFilter/PermissionFilter";
import FreeDeviceList from "../FreeDeviceList";
import RecTestScenarioList from "../RecTestScenarioList/RecTestScenarioList";
import styles from "./RecTestScenarioPage.module.scss";

export default (props) => {
  const {
    selectedFreeDeviceForRecording,
    deviceSelectionModalVisible,
    freeDeviceListForRecording,
    recTestScenarioList,
    totalRecTestScenariosPage,
    currentRecTestScenariosPage,
    totalPageItem,
    totalItems,
    requestingForNewScenario,
    selectedRecTestScenarioId,
    recTestScenarioListModalVisible,
    scenarioListFilter,
    searchScenarioKeyword,
  } = useSelector((state) => state.RecTestScenarioReducer);
  const { fetchingRecTestStepList, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testDataSetView, testDataSetAssigneeDetail } = useSelector((state) => state.TestDataSetReducer);
  const { isScenarioLoadModel, projectId } = props;
  const dispatch = useDispatch();
  const [selectAllScenario, setSelectAllScenario] = useState([]);
  const filterPermission = [
    { id: OWNED, value: MY_SCENARIOS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];
  useEffect(() => {
    dispatch(changeSearchScenarioKeyword(""));
  }, [stepType]);

  useEffect(() => {
    dispatch(getRecTestScenarioList("", 1, projectId, searchScenarioKeyword, "", ""));
    dispatch(changeSearchScenarioKeyword(""));
    dispatch(setPreviousSearchValue(searchScenarioKeyword));
    setSelectAllScenario([]);
  }, [projectId, testDataSetAssigneeDetail]);

  const deleteConfirm = () => {
    deleteModal(
      `Do you want to delete the selected ${selectAllScenario?.length > 1 ? "Test Scenarios" : "Test Scenario"}?`,
      () => {
        dispatch(
          deleteRecTestScenario(
            {
              scenarioId: selectedRecTestScenarioId ? [selectedRecTestScenarioId] : selectAllScenario,
              projectId: projectId,
              isDelete: 1,
            },
            setSelectAllScenario
          )
        );
      }
    );
  };

  const handleFilters = (e) => {
    dispatch(changeScenarioListFilter(e, projectId, searchScenarioKeyword));
  };
  return (
    <div>
      <>
        <div className={styles.innerHeader}>
          {!isScenarioLoadModel && (
            <div className={styles.mainContainer}>
              <div className={styles.mainHeaderText}> Test Scenarios </div>
              <div className={styles.headerOrButton}>
                <Button
                  type="primary"
                  size="large"
                  className={styles.buildScenarioButton}
                  disabled={requestingForNewScenario || fetchingRecTestStepList || isPreviewRunning}
                  onClick={() => {
                    Modal.confirm({
                      title: <div>Do you want to create a new Scenario?</div>,
                      okText: "Create",
                      onOk: () =>
                        !requestingForNewScenario && !fetchingRecTestStepList && !isPreviewRunning
                          ? dispatch(
                              createNewRecTestScenario(
                                platformAbbrevMapping(props.platform, false),
                                "",
                                projectId,
                                props.navigateToEditBuildScenario
                              )
                            )
                          : undefined,
                    });
                  }}
                >
                  Build Scenario
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={styles.pageScroll}>
          <div className={!isScenarioLoadModel && `${styles.mainPermissionFilter}`}>
            <div className={styles.permissionFilter}>
              {!isScenarioLoadModel && (
                <PermissionFilter
                  permissionValue={filterPermission}
                  active={scenarioListFilter}
                  handleFilters={handleFilters}
                />
              )}
            </div>

            <div className={styles.commonSearch}>
              {(recTestScenarioListModalVisible || !isScenarioLoadModel) && (
                <CommonSearch
                  placeHolder="Search by Scenario name"
                  searchDetails={(searchText) =>
                    dispatch(getRecTestScenarioList("", 1, projectId, searchScenarioKeyword, "", ""))
                  }
                  setSearchKeyword={(value) => dispatch(changeSearchScenarioKeyword(value))}
                />
              )}
            </div>
            {!_.isEmpty(recTestScenarioList) && isScenarioLoadModel && (
              <div
                className={
                  isScenarioLoadModel ? `${styles.isScenarioLoadModelOpen}` : `${styles.isScenarioLoadModelClose}`
                }
              >
                <PaginationComponent
                  currentPageItems={totalPageItem}
                  totalItems={totalItems}
                  currentPage={currentRecTestScenariosPage}
                  totalPage={totalRecTestScenariosPage}
                  callPaginationAPI={(pageNumber) =>
                    dispatch(getRecTestScenarioList("", pageNumber, projectId, searchScenarioKeyword, "", ""))
                  }
                />
              </div>
            )}
          </div>

          <div className={styles.selectAllBox}>
            <div>
              {!isScenarioLoadModel && !_.isEmpty(selectAllScenario) && (
                <SelectAllBox
                  isChecked={isSelectedAllIds(
                    selectAllScenario,
                    recTestScenarioList?.filter((i) => i.allowDelete === ACTION_VIEW)
                  )}
                  selectAllIds={selectAllScenario}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllScenario([
                        ...selectAllScenario,
                        ...(
                          recTestScenarioList &&
                          recTestScenarioList?.filter(
                            (i) => i.allowDelete === ACTION_VIEW && !selectAllScenario.includes(i?.id) && i?.id
                          )
                        )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                      ]);
                    } else {
                      setSelectAllScenario(removeSelectedIds(selectAllScenario, recTestScenarioList));
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                  isDisable={_.isEmpty(recTestScenarioList)}
                />
              )}
            </div>
          </div>
          <RecTestScenarioList
            selectAllScenario={selectAllScenario}
            isScenarioLoadModel={isScenarioLoadModel}
            setSelectAllScenario={setSelectAllScenario}
            {...props}
          />
          <div className={styles.paginationSection}>
            {!_.isEmpty(recTestScenarioList) && (
              <PaginationComponent
                currentPageItems={totalPageItem}
                totalItems={totalItems}
                currentPage={currentRecTestScenariosPage}
                totalPage={totalRecTestScenariosPage}
                callPaginationAPI={(pageNumber) =>
                  dispatch(getRecTestScenarioList("", pageNumber, projectId, searchScenarioKeyword, "", ""))
                }
              />
            )}
          </div>

          <Modal
            title="Device selection for Record scenario"
            visible={deviceSelectionModalVisible}
            onOk={() => dispatch(navigateToRecordingScreen())}
            onCancel={() => dispatch(hideDeviceSelectionModal())}
            footer={[
              <Button key="back" onClick={() => dispatch(hideDeviceSelectionModal())}>
                Cancel
              </Button>,
              <Button
                disabled={selectedFreeDeviceForRecording && selectedFreeDeviceForRecording.length > 0 ? false : true}
                key="submit"
                type="primary"
                onClick={() => dispatch(navigateToRecordingScreen())}
              >
                Record
              </Button>,
            ]}
          >
            {freeDeviceListForRecording && <FreeDeviceList />}
          </Modal>

          {window.pageYOffset > 300 && (
            <div className={styles.selectAllBoxDelete}>
              {!isScenarioLoadModel && (
                <SelectAllBox
                  isChecked={isSelectedAllIds(
                    selectAllScenario,
                    recTestScenarioList?.filter((i) => i.allowDelete === ACTION_VIEW)
                  )}
                  selectAllIds={selectAllScenario}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllScenario([
                        ...selectAllScenario,
                        ...(
                          recTestScenarioList &&
                          recTestScenarioList?.filter(
                            (i) => i.allowDelete === ACTION_VIEW && !selectAllScenario.includes(i?.id) && i?.id
                          )
                        )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                      ]);
                    } else {
                      setSelectAllScenario(removeSelectedIds(selectAllScenario, recTestScenarioList));
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                  isDisable={_.isEmpty(recTestScenarioList)}
                />
              )}
            </div>
          )}
        </div>
      </>
    </div>
  );
};
