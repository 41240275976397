import { default as React, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Radio, Switch, Tooltip } from "antd";
import { SELECTOR_TYPE, TOOLTIP_BG_COLOR } from "../../../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";

import { useState } from "react";
import { DELETE_ICON_NEW, RED_COLOR } from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  changeDisableElement,
  changeRecElementName,
  selectChildElementPurpose,
  showDeleteRecElementConfirmationModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerticalLine from "../../../../CommonComponents/VerticalLine";
import RecElementSettingsV2 from "../../RecElementSettingsV2/RecElementSettingsV2";
import RecTestStepImageSelectionDetailV2 from "../../RecTestStepImageSelectionDetailV2/RecTestStepImageSelectionDetailV2";
import VerificationRulesV2 from "../../VerificationsV2/VerificationRulesV2/VerificationRulesV2";
import RecTestStepSelectorV2 from "../RecTestStepSelectorV2/RecTestStepSelectorV2";
import styles from "./RecTestStepElementV2.module.scss";

const RecTestStepElementV2 = (props) => {
  const dispatch = useDispatch();
  const {
    parentRecElement,
    recElement,
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
    stepId
  } = props;

  const { isPreviewRunning, selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [isInputActive, setIsInputActive] = useState(false);

  //Destructure of recElement object
  const {
    guid,
    isActive,
    recElementLocatorProperties,
    elementPurpose,
    recStepVerifyElementProperties,
    verificationOperator,
    name,
    elementSelectorType,
    childRecElements,
  } = recElement ? recElement : {};

  const allowRecElementToSave = useMemo(
    () =>
      recElementLocatorProperties?.length > 0 &&
      (parentRecElement === undefined ||
        (parentRecElement !== undefined &&
          (elementPurpose === "ACTION" ||
            (elementPurpose === "VERIFICATION" &&
              recStepVerifyElementProperties?.length > 0 &&
              recStepVerifyElementProperties?.filter(
                (recStepVerifyElementProperty) =>
                  recStepVerifyElementProperty.id === 0 || recStepVerifyElementProperty.id === undefined
              ).length > 0)))),
    [recElementLocatorProperties, parentRecElement, elementPurpose, recStepVerifyElementProperties]
  );

  let isElementDisable = useMemo(() => isActive === 0, [isActive]);

  const disableDeleteElement = useMemo(
    () =>
      disabled ||
      isSkipStep ||
      isPreviewRunning ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid) ||
      isElementDisable,
    [disabled, unSavedRecElementGuid, isSkipStep, isPreviewRunning, guid, isElementDisable]
  );
  const disabledElement =
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid) ||
    disabled;

  return (
    <>
      <Col span={24} className={`${styles["elementTitle"]} ${isInputActive && `${styles["elementActionButtonHide"]}`}`}>
        <div className="width-full">
          <div className={`${styles["elementTextInput"]}`}>
            <div className={styles["inputTitleOrInputBox"]}>
              <span className={styles["inputTitle"]}>Name:</span>
              <Input
                disabled={
                  disabledElement ||
                  isSkipStep ||
                  isPreviewRunning ||
                  isElementDisable ||
                  (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
                }
                placeholder="Element name will appear here on selection. You may edit it later..."
                type="text"
                value={name}
                onChange={(e) => dispatch(changeRecElementName(e.target.value, data.tag, guid))}
                onFocus={() => setIsInputActive(true)}
                onBlur={() => {
                  setIsInputActive(false);
                }}
                className={styles["inputStyle"]}
              />
            </div>
            {!isInputActive && (
              <>
                <div className={styles["elementDeleteOrDisable"]}>
                  <VerticalLine className={styles["border"]} />
                  {parentRecElement !== undefined && (
                    <div className="overflow-hidden overflow-ellipsis white-space-nowrap w-100">
                      <span className="font-size-13 font-weight-500">Element Purpose</span> :
                      <Radio.Group
                        disabled={
                          disabled ||
                          isElementDisable ||
                          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
                        }
                        buttonStyle="solid"
                        onChange={(e) => dispatch(selectChildElementPurpose(e.target.value, data.tag, guid))}
                        value={elementPurpose}
                        className="ml-10"
                      >
                        <Radio.Button value={"ACTION"} className="border-radius-4">
                          Action
                        </Radio.Button>
                        <Radio.Button value={"VERIFICATION"} className="mr-10 border-radius-4">
                          Verification
                        </Radio.Button>
                      </Radio.Group>
                    </div>
                  )}
                  <Tooltip title={!isElementDisable ? "Disable" : "Enable"} color={TOOLTIP_BG_COLOR}>
                    <div className={styles["switch"]}>
                      <Switch
                        checked={!isElementDisable}
                        onChange={(e) => dispatch(changeDisableElement(e, data.tag, guid))}
                        disabled={
                          disabledElement ||
                          isSkipStep ||
                          isPreviewRunning ||
                          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
                        }
                        size="small"
                        className="disable_Element"
                      />
                    </div>
                  </Tooltip>
                  <VerticalLine className={styles["border"]} />
                  <div>
                    <SvgLoader
                      iconName={DELETE_ICON_NEW}
                      handleClick={() =>
                        !disableDeleteElement &&
                        dispatch(
                          showDeleteRecElementConfirmationModal(
                            data.id,
                            data.tag,
                            data.recTestScenarioRecTestStepAssocId,
                            guid
                          )
                        )
                      }
                      disabled={disableDeleteElement}
                      iconColor={disableDeleteElement ? "#7d7e82" : RED_COLOR}
                      width="2rem"
                      height="2rem"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Col>
      <div className={styles["row"]}>
        <div className={styles["recTestStepSelectorBorder"]}>
          <div className={styles["recTestStepSelector"]}>
            <RecTestStepSelectorV2
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              data={data}
              isDeviceLoaded={isDeviceLoaded}
              disabled={disabledElement || isElementDisable}
              projectId={projectId}
              recElement={recElement}
              unSavedRecElementGuid={unSavedRecElementGuid}
              isPreviewRunning={isPreviewRunning}
              parentRecElement={parentRecElement}
              allowRecElementToSave={allowRecElementToSave}
              stepId={stepId}
            />
          </div>
          <RecElementSettingsV2
            recElement={recElement}
            data={data}
            isSkipStep={isSkipStep}
            disabled={disabledElement || isElementDisable}
          />
        </div>
        <div
          className={`${styles["verificationRules"]} ${
            recStepVerifyElementProperties &&
            recStepVerifyElementProperties.length === 0 &&
            elementSelectorType !== SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN &&
            styles["addRules"]
          }`}
        >
          {elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? (
            <RecTestStepImageSelectionDetailV2 {...props} />
          ) : (
            <VerificationRulesV2
              recTestSteps={recTestSteps}
              disabled={
                props.disabled ||
                isSkipStep ||
                isElementDisable ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
              }
              isElementSelected={props.disabled}
              operator={verificationOperator}
              uuid={guid}
              data={data}
              recElement={recElement}
              operatorChange={verificationOperator}
              projectId={projectId}
              isDeviceLoaded={selectedDeviceByUdid !== undefined}
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              isPreviewRunning={isPreviewRunning}
              isElementDisable={isElementDisable}
            />
          )}
        </div>
        {childRecElements?.map((childRecElement, index) => {
          return (
            <div className={styles["addChild"]}>
              <RecTestStepElementV2
                key={index}
                recElement={childRecElement}
                parentRecElement={recElement}
                data={data}
                disabled={disabled}
                projectId={projectId}
                isDeviceLoaded={isDeviceLoaded}
                isSkipStep={isSkipStep}
                isTestBlock={isTestBlock}
              />
              {index < childRecElements.length - 1 ? <span>And</span> : ""}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecTestStepElementV2;