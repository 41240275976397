import React, { useState } from "react";
import { useEffect } from "react";
import { Dropdown, Spin, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../../../Constants";
import SvgLoader from "../../../../../Util/SvgLoader";
import { ACTION_ICON_COLOR, DISABLE_ICON_COLOR, ICON_DOWN_ARROW } from "../../../../../Constants/SvgConstants";
import styles from "./RecTestStepOperation.module.scss";

const RecTestStepOperation = (props) => {
  const {
    name,
    handleClick,
    disabled,
    stopButton,
    spinning,
    isMenuItems,
    tooltipTitle,
    hideTooltipTitle,
    recTestStepOnHover,
    imageName,
    setMenuVisible,
    menuVisible,
  } = props;

  useEffect(() => {
    return () => {
      setMenuVisible && setMenuVisible(false);
    };
  }, []);

  const [tooltipVisible, setTooltipVisible] = useState(false);
  return (
    <Tooltip
      title={menuVisible ? undefined : tooltipTitle}
      color={TOOLTIP_BG_COLOR}
      overlayClassName={!recTestStepOnHover && hideTooltipTitle && styles.hideTooltip}
      visible={tooltipVisible}
      onVisibleChange={setTooltipVisible}
    >
      {isMenuItems ? (
        <Dropdown
          overlay={handleClick}
          trigger={["click"]}
          disabled={disabled}
          onVisibleChange={(value) => {
            setMenuVisible && setMenuVisible(value);
            if (value) {
              setTooltipVisible(false);
            }
          }}
          visible={menuVisible}
        >
          <div className={styles.subHeaderIcons}>
            <SvgLoader
              iconName={imageName}
              iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
              width="1.625rem"
              height="1.625rem"
              disabled={disabled}
            />
            {!recTestStepOnHover && (
              <span className={`${styles.label} ${disabled && "disable_Text"} ${styles.dropDownArrow}`}>
                {name}
                <SvgLoader
                  iconName={ICON_DOWN_ARROW}
                  iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
                  disabled={disabled}
                />
              </span>
            )}
          </div>
        </Dropdown>
      ) : (
        <div className={styles.subHeaderIcons} onClick={handleClick}>
          {spinning ? (
            <Spin spinning={spinning}></Spin>
          ) : (
            <SvgLoader
              iconName={imageName}
              iconColor={disabled ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
              width="1.625rem"
              height="1.625rem"
              disabled={disabled}
            />
          )}
          {!recTestStepOnHover && (
            <span className={`${styles.label} ${disabled ? "disable_Text" : stopButton && styles.stopButtonText}`}>
              {name}
            </span>
          )}
        </div>
      )}
    </Tooltip>
  );
};

export default RecTestStepOperation;
