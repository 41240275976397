import { async } from "../api/automation";
import {
  ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT,
  CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE,
  ERROR_UPLOAD_APPLICATION_STRING,
  IMPORT_APPLICATION_STRINGS_FORM_VISIBLE,
  REMOVE_APPLICATION_STRINGS_XML,
  REMOVE_LOCALE_OPTIONS_FOR_IMPORT,
  REQUEST_UPLOAD_APPLICATION_STRING,
  REQUESTED_UPLOAD_APPLICATION_STRING,
  SELECT_APPLICATION_STRING_FILE_FOR_IMPORT,
  SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT,
  SUCCESS_STATUS,
} from "../Constants";
import { updateResponse } from "../Util";

export const changeRemoveAppStringFromLocale = (checked) => {
  return async (dispatch) => {
    dispatch({ type: CHANGE_REMOVE_PREVIOUS_APPLICATION_STRING_VALUE, checked });
  };
};

export const importApplicationStringsFormVisible = (visible, projectId) => {
  return async (dispatch) => {
    dispatch({ type: IMPORT_APPLICATION_STRINGS_FORM_VISIBLE, visible, projectId });
  };
};

export const addEmptyLocaleOptionForImport = (projectId) => {
  return async (dispatch) => {
    dispatch({ type: ADD_EMPTY_LOCALE_OPTIONS_FOR_IMPORT, projectId });
  };
};

export const removeEmptyLocaleOptionForImport = (guid) => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_LOCALE_OPTIONS_FOR_IMPORT, guid });
  };
};

export const removeApplicationStringsXml = (index) => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_APPLICATION_STRINGS_XML, index });
  };
};

export const uploadApplicationStringsXml = () => {
  return async (dispatch, getState) => {
    const { importApplicationStringsData, removePreviousDataFromLocale } = getState().ApplicationStringReducer;
    importApplicationStringsData.forEach((item) => {
      dispatch({ type: REQUEST_UPLOAD_APPLICATION_STRING, item });
      var formData = new FormData();
      formData.append("projectId", parseInt(item.projectId));
      formData.append("appFile", item.appFile);
      formData.append("locale", item.locale);
      formData.append("guid", item.guid);
      formData.append("removePreviousDataFromLocale", removePreviousDataFromLocale);
      async("applicationStrings/importApplicationString", "POST_WITH_FILE", formData).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: REQUESTED_UPLOAD_APPLICATION_STRING, item });
        } else {
          dispatch({ type: ERROR_UPLOAD_APPLICATION_STRING, item });
        }
      });
    });
  };
};

export const selectApplicationStringLocaleForImport = (value, guid) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_APPLICATION_STRING_LOCALE_FOR_IMPORT, value, guid });
  };
};

export const selectApplicationStringFileForImport = (file, guid) => {
  return async (dispatch) => {
    dispatch({ type: SELECT_APPLICATION_STRING_FILE_FOR_IMPORT, file, guid });
  };
};
