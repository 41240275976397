import { faTimesCircle, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  changeSelectedComparisonPreviewView,
  exportRunComparisonDoc,
  fetchSameLocaleForPreviewByRunNumber,
  fetchSameRunNumberForPreview,
} from "../../../../actions/TestPlanAction";
import { COMPARISON_SINGLE_LOCALE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import { StyledCollapse } from "./TestPlanResultDetailsPreview";

const RefRunNumberDetailLabel = styled.div`
  line-height: 2.5em;
`;
const ClearRefFilter = styled.div`
  margin-left: 10px;
  line-height: 2em;
  color: #338fcc;
  cursor: pointer;
`;
const { Panel } = Collapse;
const { Option } = Select;

const RefRunNumberPanel = (props) => {
  const {
    runNumber,
    resultType,
    setRefRunNumberForPreview,
    setRefRunNumberLocaleForPreview,
    refRunNumberForPreview,
    refRunNumberLocaleForPreview,
  } = props;
  const dispatch = useDispatch();
  const {
    selectedComparisonPreviewView,
    refRunNumberForPreviewList,
    selectedTestScenarioForResultLogs,
    refRunNumberLocaleForPreviewList,
  } = useSelector((state) => state.TestPlanReducer);
  return (
    <TestPlanResultDetailsScenario>
      <StyledCollapse className="border-none" expandIconPosition="right">
        <Panel showArrow={true} header={<span className="font-weight-700">Show Comparison View with Base Locale</span>}>
          <div className="display-flex pt-10">
            <RefRunNumberDetailLabel>Reference Locale:</RefRunNumberDetailLabel>
            <Select
              showSearch
              className="w-190 search-text-input-device-usage bg-color-white ml-10"
              onChange={(value) => {
                setRefRunNumberLocaleForPreview(value);
                setRefRunNumberForPreview(undefined);
                dispatch(
                  changeSelectedComparisonPreviewView(
                    selectedComparisonPreviewView,
                    runNumber,
                    resultType,
                    undefined,
                    undefined
                  )
                );
                dispatch(fetchSameRunNumberForPreview(runNumber, value));
              }}
              placeholder="Search"
              value={refRunNumberLocaleForPreview}>
              {refRunNumberLocaleForPreviewList &&
                refRunNumberLocaleForPreviewList?.map((v, k) => {
                  return (
                    <Option key={v} value={v}>
                      {v}
                    </Option>
                  );
                })}
            </Select>
            <RefRunNumberDetailLabel className="ml-10">Reference Run Number:</RefRunNumberDetailLabel>
            <Select
              showSearch
              className="width-30 max-width-40 search-text-input-device-usage bg-color-white ml-10"
              onChange={(value) => {
                setRefRunNumberForPreview(value);
                dispatch(
                  changeSelectedComparisonPreviewView(
                    selectedComparisonPreviewView,
                    runNumber,
                    resultType,
                    value?.split(" ")[0],
                    refRunNumberLocaleForPreview
                  )
                );
              }}
              placeholder="Search"
              value={refRunNumberForPreview}>
              {refRunNumberForPreviewList &&
                refRunNumberForPreviewList.length > 0 &&
                refRunNumberForPreviewList.map((v, k) => {
                  return (
                    <Option key={v.runNumber} value={v.runNumber + " (" + v.testJobName + ")"}>
                      {v.runNumber + " (" + v.testJobName + ")"}
                    </Option>
                  );
                })}
            </Select>
            {refRunNumberLocaleForPreview && refRunNumberForPreview && (
              <ClearRefFilter>
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  onClick={() => {
                    setRefRunNumberLocaleForPreview(undefined);
                    setRefRunNumberForPreview(undefined);
                    dispatch(
                      changeSelectedComparisonPreviewView(
                        selectedComparisonPreviewView,
                        runNumber,
                        resultType,
                        undefined,
                        undefined
                      )
                    );
                  }}
                />
              </ClearRefFilter>
            )}
            {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
              <div className="flex-grow-1">
                <Tooltip color={TOOLTIP_BG_COLOR} title="Export Doc with Side By Side Screenshots">
                  <Button
                    className="font-size-14 float-right"
                    type="primary"
                    disabled={refRunNumberForPreview === undefined || refRunNumberLocaleForPreview === undefined}
                    onClick={() =>
                      refRunNumberForPreview &&
                      refRunNumberLocaleForPreview &&
                      selectedTestScenarioForResultLogs?.length !== 0
                        ? dispatch(
                            exportRunComparisonDoc(
                              runNumber,
                              resultType,
                              refRunNumberForPreview,
                              refRunNumberLocaleForPreview
                            )
                          )
                        : undefined
                    }>
                    Export As Doc
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
        </Panel>
      </StyledCollapse>
    </TestPlanResultDetailsScenario>
  );
};

export default RefRunNumberPanel;
