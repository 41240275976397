import { Spin } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetTestJobData } from "../../actions/TestJobActions";
import { ALL_JOBS, CHANGE_EXECUTION_MANAGER_TAB, JOB_DESIGNER, MY_JOBS, OWNED } from "../../Constants";
import BuildJobSchedule from "./JobDesigner/BuildJobSchedule/BuildJobSchedule";
import JobManager from "./JobDesigner/JobManager";

const ExecutionManager = (props) => {
  const { projectId, orgId, setCurrentMenuOptionsSelected, currentMenuOptionsSelected ,showDeviceView} = props;
  const { scheduleTestPlan } = useSelector((state) => state.TestPlanReducer);
  const { selectedExecutionManagerTab, jobLoading, selectedExecutionManagerSubTab, testJobSchedulerDrawerVisible } =
    useSelector((state) => state.TestJobReducer);
  const [rights, setRights] = useState({ id: OWNED, value: MY_JOBS });

  const dispatch = useDispatch();
  useEffect(() => {
    if (scheduleTestPlan) {
      dispatch({ type: CHANGE_EXECUTION_MANAGER_TAB, executionManagerSubTab: JOB_DESIGNER });
    } else {
      dispatch({ type: CHANGE_EXECUTION_MANAGER_TAB, executionManagerSubTab: ALL_JOBS });
    }
    dispatch(resetTestJobData(true));
  }, [projectId]);

  return (
    <div>
      <Spin spinning={jobLoading} tip="Loading" className="spinner-center">
        <div>
          <JobManager
            projectId={projectId}
            orgId={orgId}
            selectedExecutionManagerTab={selectedExecutionManagerTab}
            selectedExecutionManagerSubTab={selectedExecutionManagerSubTab}
            setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
            currentMenuOptionsSelected={currentMenuOptionsSelected}
            testJobSchedulerDrawerVisible={testJobSchedulerDrawerVisible}
            rights={rights}
            setRights={setRights}
            showDeviceView={showDeviceView}
          />
          {testJobSchedulerDrawerVisible && <BuildJobSchedule projectId={projectId} orgId={orgId} rights={rights} />}
        </div>
      </Spin>
    </div>
  );
};

export default ExecutionManager;
