import { Drawer, Row, Col, Tabs, Card } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { Tab } from "wd/lib/special-keys";
import { closeTestPlanTrendDrawer, getRunInsight } from "../../../actions/TestPlanAction";
import { FAIL_COLOR, ABORT_COLOR, PASS_COLOR, GRAY_COLOR } from "../../../Constants";
import { getDatesInRange, getMomentDateRange } from "../../../Util";
import DatePickerBox from "../../CommonComponents/DatePickerBox";
import DeviceHoverCard from "../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";

const getResult = (data) => {
  return (
    <>
      <span className="badge bg-secondary font-weight-700">{"No Of Times Executed : " + data.totalCount}</span>
      {data.passCount > 0 && (
        <span className="badge bg-success font-weight-700 ml-5">{"Passed : " + data.passCount}</span>
      )}
      {data.failCount > 0 && (
        <span className="badge bg-danger font-weight-700 ml-5">{"Failed : " + data.failCount}</span>
      )}
      {data.abortCount > 0 && (
        <span className="badge bg-abort font-weight-700 ml-5">{"Aborted : " + data.abortCount}</span>
      )}
    </>
  );
};
const TestPlanTrend = (props) => {
  const dispatch = useDispatch();
  const { testPlanTrendDrawerVisible, testPlanTrendData, selectedTestPlanForTrend } = useSelector(
    (state) => state.TestPlanReducer
  );

  let data = [["Run Date", "Pass", "Fail", "Abort"]];
  testPlanTrendData &&
    testPlanTrendData.statistic.map((v, k) => {
      data.push([v.date, v.passCount, v.failCount, v.abortCount]);
    });
  const [dateRange, setDateRange] = useState([
    moment(new Date()).subtract(2, "days").startOf("day"),
    moment(new Date()).endOf("day"),
  ]);

  const dateChange = (dateStrings) => {
    if (dateStrings) {
      setDateRange(getMomentDateRange(dateStrings[0], dateStrings[1]));
      dispatch(getRunInsight(selectedTestPlanForTrend, dateStrings[0], dateStrings[1]));
    }
  };
  return (
    <Drawer
      title={
        <>
          <span>Trends For Test Plan : </span>{" "}
          <span className="font-weight-300">
            {testPlanTrendData?.statistic?.length > 0 ? testPlanTrendData?.statistic[0].testPlanName : "N/A"}
          </span>
        </>
      }
      placement="right"
      width={736}
      onClose={() => dispatch(closeTestPlanTrendDrawer())}
      visible={testPlanTrendDrawerVisible}
    >
      {testPlanTrendData?.statistic?.length > 0 ? (
        <>
          <Row>
            <Col span={6}></Col>
            <Col span={18}>
              <DatePickerBox dateChange={dateChange} dateRange={dateRange} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Chart
                className="chart-height"
                chartType="Line"
                width="100%"
                height="400px"
                data={data}
                legendToggle
                options={{
                  legend: {
                    maxLines: 1,
                    textStyle: {
                      fontSize: 12,
                    },
                  },
                  colors: [PASS_COLOR, FAIL_COLOR, ABORT_COLOR],
                  hAxis: {
                    title: "Run Date",
                  },
                  vAxis: {
                    title: "Counts",
                  },
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Tabs defaultActiveKey="0">
                <Tabs.TabPane key={0} tab="Scenarios">
                  {testPlanTrendData?.scenarios &&
                    testPlanTrendData?.scenarios.map((v, k) => {
                      return (
                        <Card>
                          <Col span={18}>
                            <div>
                              <span className="font-weight-700">Name: </span> {v.scenarioName}
                            </div>
                            <div>
                              <span className="font-weight-700">Result: </span> {getResult(v)}
                            </div>
                          </Col>
                          <Col span={6}></Col>
                        </Card>
                      );
                    })}
                </Tabs.TabPane>
                <Tabs.TabPane key={1} tab="Locales">
                  {testPlanTrendData?.locales &&
                    testPlanTrendData?.locales.map((v, k) => {
                      return (
                        <Card>
                          <Col span={18}>
                            <div>
                              <span className="font-weight-700">Name: </span> {v.locale}
                            </div>
                            <div>
                              <span className="font-weight-700">Result: </span> {getResult(v)}
                            </div>
                          </Col>
                          <Col span={6}></Col>
                        </Card>
                      );
                    })}
                </Tabs.TabPane>
                <Tabs.TabPane key={2} tab="Devices">
                  {testPlanTrendData?.devices &&
                    testPlanTrendData?.devices.map((v, k) => {
                      return (
                        <Card>
                          <Col span={18}>
                            <div>
                              <span className="font-weight-700">Name: </span>
                              <DeviceHoverCard deviceDetails={v} deviceIndex={0} />
                            </div>
                            <div>
                              <span className="font-weight-700">Result: </span> {getResult(v)}
                            </div>
                          </Col>
                          <Col span={6}></Col>
                        </Card>
                      );
                    })}
                </Tabs.TabPane>
                <Tabs.TabPane key={3} tab="Investigation">
                  {testPlanTrendData?.investigations &&
                    testPlanTrendData?.investigations.map((v, k) => {
                      return (
                        <Card>
                          <Col span={18}>
                            <div>
                              <span className="font-weight-700">Investigation: </span> {v.failureReason}
                            </div>
                            <div>
                              <span className="font-weight-700">Description: </span> {v.failureDescription}
                            </div>
                            <div>
                              <span className="font-weight-700">Result: </span> {getResult(v)}
                            </div>
                          </Col>
                          <Col span={6}></Col>
                        </Card>
                      );
                    })}
                </Tabs.TabPane>
              </Tabs>
            </Col>
          </Row>
        </>
      ) : (
        <EmptyRecords
          description={
            <div className="text-align-center mt-5">
              <div className="font-size-15 font-weight-bold">No data here yet! </div>
              <div>Run an execution job with this test plan to populate data here.</div>
            </div>
          }
        />
      )}
    </Drawer>
  );
};

export default TestPlanTrend;
