import { Button, Col, Row } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { saveWorkspace } from '../../actions/WorkspaceAction';
import { showNotification } from '../../Util';

const CreateWorkspace = () => {
    const dispatch = useDispatch();
    // let { workSpaceName } = useSelector((state) => state.WorkspaceReducer);
    const [workspaceName, setWorkspaceName] = useState(null);

    const validateUserInputForEmail = (value) => {
        setWorkspaceName(value)
    }

    const handleSaveButton = () => {
        if (workspaceName) {
            dispatch(saveWorkspace(workspaceName));
        }
        else {
            showNotification("error", "Please Insert Workspace Name", "");
        }
    };
    return (
        <React.Fragment>
            <div className={"limiter"}>
                <div className={"container-login100"}>
                    <Row className={"wrap-login100"}>
                        <Col className={"login100-form validate-form"}>
                        <div><span className="font-size-22 font-weight-600 font-color-black">Create Your Workspace!</span></div>
                            <div className={"wrap-input100 validate-input"}>
                                <input className={"input100 "}
                                    type="text" name="UserEmail" placeholder="Workspace Name"
                                    onChange={(e) => { validateUserInputForEmail(e.target.value) }}
                                />
                            </div>
                            <div className={"container-login100-form-btn"}>
                                <div className={"wrap-login100-form-btn"}>
                                    <Button
                                        type="primary"
                                        className={"login100-form-btn"}
                                        onClick={() => handleSaveButton()}
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateWorkspace
