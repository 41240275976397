import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { removeVerificationRow } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
const StyledRemoveVerificationRow = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed!important" : "pointer")};
  float: right;
  svg {
    cursor: ${(props) => (props.disabled ? "not-allowed!important" : "pointer")};
  }
`;

export default (props) => {
  const dispatch = useDispatch();
  const { recElement } = props;

  return (
    <React.Fragment>
      <StyledRemoveVerificationRow
        disabled={props.disabled}
        onClick={() =>
          !props.disabled
            ? dispatch(removeVerificationRow(props.parentId, props.recTestStep.tag, props.recElement?.guid))
            : undefined
        }
        className={"remove-verification-group-icon"}
      >
        <FontAwesomeIcon icon={faMinusCircle} />
      </StyledRemoveVerificationRow>
    </React.Fragment>
  );
};
