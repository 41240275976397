import { Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAwsDeviceFarmPools, getAwsDeviceFarmProjects } from "../../../../../actions/AwsDeviceFarmAction";
import styles from "./AwsDeviceFarmV2.module.scss";

const { Option } = Select;

const SelectAwsDeviceProjectV2 = (props) => {
  const dispatch = useDispatch();
  const { required, onChange, testJobDetail } = props;

  const { awsDeviceFarmProjects } = useSelector((state) => state.AwsDeviceFarmReducer);

  useEffect(() => {
    dispatch(getAwsDeviceFarmProjects());
  }, []);

  return (
    <>
      <div className={styles["custom-input-wrapper"]}>
        <div className={`${styles["custom-input-label"]}`}>
          AWS Device Projects :{required ? <span className="text-danger">*</span> : ""}
        </div>
        <Select
          className={styles["modal-select-box"]}
          key="DeviceFarm"
          placeholder={"Please Select AWS Device Farm Project"}
          value={testJobDetail.deviceFarmIds}
          onChange={(e) => {
            dispatch(onChange({ ...testJobDetail, deviceFarmIds: [e] }));
            dispatch(getAwsDeviceFarmPools({ awsDeviceFarmProjectId: e }));
          }}
        >
          {awsDeviceFarmProjects &&
            awsDeviceFarmProjects.map((value, k) => {
              return (
                <Option key={k + 1} value={value.id}>
                  {value.name}
                </Option>
              );
            })}
        </Select>
      </div>
    </>
  );
};
export default SelectAwsDeviceProjectV2;
