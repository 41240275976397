import moment from "moment";
import { ONETIME } from "../../../Constants";

export class ScheduleJobModal {
  constructor(responseData) {
    //For Get API
    if (responseData["id"]) {
      this.id = responseData["id"];
      this.name = responseData["name"];
      this.testJobId = responseData["id"];
      this.executionFrequency = responseData["frequency"] ? responseData["frequency"] : undefined;
      this.scheduleTime = responseData["scheduleTime"] ? moment(responseData["scheduleTime"]) : undefined;
      this.scheduleDate = responseData["scheduleTime"] ? moment(responseData["scheduleTime"]) : undefined;
      this.scheduleDateTime = responseData["scheduleTime"] ? responseData["scheduleTime"] : undefined;
      this.timeZoneName = responseData["timeZoneName"] ? responseData["timeZoneName"] : undefined;
      this.timeZone = responseData["timezone"] ? responseData["timezone"] : undefined;
      this.days = responseData["days"] ? responseData["days"].split(",") : [];
      this.minutes = responseData["minutes"] ? responseData["minutes"].split(",") : [];
      this.hours = responseData["hours"] ? responseData["hours"].split(",") : [];
      this.isJobScheduled = responseData["isJobScheduled"];
      this.setDefaultValue = false;
    } else {
      this.executionFrequency = ONETIME;
      this.scheduleTime = moment(new Date());
      this.scheduleDate = moment(new Date());
      this.scheduleDateTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      this.days = [];
      this.timeZone = undefined;
      this.timeZoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.minutes = [];
      this.hours = [];
      this.id = undefined;
      this.name = undefined;
      this.testJobId = undefined;
      this.isJobScheduled = 0;
      this.setDefaultValue = false;
    }
  }
}
