import { Button, Col, Row, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { splitValue, verificationExistenceDisplayName, verificationExistenceValues } from "../../../../Constants";
import {
  updateReferenceStepValue,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { getAllowedElementsForReference } from "../../../../Util";
import { getEmptyVerificationRow } from "../../../../reducer/RecTestStepReducer";
import styles from "./Verification.module.scss";

const VerifyExistence = (props) => {
  const { disabled, recTestStep, recTestSteps } = props;
  let recStepVerifyProperty =
    recTestStep?.recTestStepProperty?.recStepVerifyElementProperties &&
    recTestStep?.recTestStepProperty?.recStepVerifyElementProperties.length === 1
      ? recTestStep?.recTestStepProperty?.recStepVerifyElementProperties[0]
      : [getEmptyVerificationRow("VerifyExistence", verificationExistenceValues.exist)];
  const dispatch = useDispatch();
  let filteredElementList = getAllowedElementsForReference(recTestSteps, [], recTestStep);
  recStepVerifyProperty.uuid = recStepVerifyProperty?.uuid ? recStepVerifyProperty.uuid : recStepVerifyProperty?.id;
  return (
    <React.Fragment>
      <Row className="verification-row">
        <Col span={24} className={styles.verification}>
          <>
            <div className={styles.verifyTextOrSelectBox}>
              <div className={styles.verifyText}>Verify Element</div>
              <Select
                disabled={disabled}
                className={"control"}
                defaultActiveFirstOption={true}
                value={recStepVerifyProperty?.key}
                onChange={(value) =>
                  dispatch(updateVerificationKey(value, recStepVerifyProperty.uuid, recTestStep.tag))
                }
              >
                <Select.Option key={verificationExistenceValues.exist} value={verificationExistenceValues.exist}>
                  {verificationExistenceDisplayName[verificationExistenceValues.exist]}
                </Select.Option>
                <Select.Option
                  key={verificationExistenceValues.does_not_exist}
                  value={verificationExistenceValues.does_not_exist}
                >
                  {verificationExistenceDisplayName[verificationExistenceValues.does_not_exist]}
                </Select.Option>
              </Select>
              {recStepVerifyProperty?.key === verificationExistenceValues.does_not_exist &&
                recStepVerifyProperty.referenceStep && (
                  <>
                    <Select
                      disabled={props.disabled}
                      className={"control"}
                      placeholder={"Select previous step"}
                      value={
                        recStepVerifyProperty.referenceStep
                          ? recStepVerifyProperty.referenceStep.tag +
                            splitValue +
                            recStepVerifyProperty.referenceStep.id
                          : ""
                      }
                      onChange={(value) =>
                        dispatch(updateReferenceStepValue(value, recStepVerifyProperty.uuid, recTestStep.tag))
                      }
                    >
                      {filteredElementList.map((v, k) => {
                        return (
                          <Select.Option key={k} value={v.tag + splitValue + (v.id ? v.id : 0)}>
                            <span>
                              <span className="font-weight-500 text-transform-capital">{v.action}:</span>
                              {v.name}
                            </span>
                          </Select.Option>
                        );
                      })}
                    </Select>
                    <Button
                      type="primary"
                      onClick={() =>
                        dispatch(updateReferenceStepValue(undefined, recStepVerifyProperty.uuid, recTestStep.tag))
                      }
                    >
                      Clear Reference Step
                    </Button>
                  </>
                )}
            </div>
          </>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default VerifyExistence;
