import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import { PROJECT_KEYS, TEST_DATA } from "../../Constants";
import { fetchProjectKeysData, fetchTestDataByProjectId } from "../../actions/TestDataAction";
import ProjectKeys from "./ProjectKeys/ProjectKeys";
import SystemKeys from "./SystemKeys/SystemKeys";

const { TabPane } = Tabs;
const TestDataSetManagement = (props) => {
  const { projectId } = props;
  const [getActiveKey, setActiveKey] = useState(PROJECT_KEYS);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setActiveKey(e);
    if (e === TEST_DATA) {
      dispatch(fetchTestDataByProjectId(props.projectId));
    } else {
      dispatch(fetchProjectKeysData(projectId));
    }
  };

  return (
    <div className="deviceTab">
      <Tabs defaultActiveKey={PROJECT_KEYS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
        <TabPane tab={"Project Keys"} key={PROJECT_KEYS}>
          <ProjectKeys projectId={projectId} />
        </TabPane>
        <TabPane tab={"System Keys"} key={TEST_DATA}>
          <SystemKeys projectId={projectId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default TestDataSetManagement;
