import { Col, Input, Radio, Switch, Tooltip } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SELECTOR_TYPE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";

import {
  changeDisableElement,
  changeRecElementName,
  selectChildElementPurpose,
  showDeleteRecElementConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepSelector from "../../SingleColumnView/RecTestStepSelector/RecTestStepSelector";
import RecElementSettings from "../RecElementSettings";
import { useState } from "react";
import VerificationRules from "../Verifications/VerificationRules/VerificationRules";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import SvgLoader from "../../../../Util/SvgLoader";
import { ACTION_ICON_COLOR, DELETE_ELEMENT_ICON, DISABLE_ICON_COLOR } from "../../../../Constants/SvgConstants";
import styles from "./RecTestStepElement.module.scss";

const RecTestStepElement = (props) => {
  const { isPreviewRunning, selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const {
    parentRecElement,
    recElement,
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
  } = props;
  const [isInputActive, setIsInputActive] = useState(false);
  const dispatch = useDispatch();
  const allowRecElementToSave =
    recElement?.recElementLocatorProperties?.length > 0 &&
    (parentRecElement === undefined ||
      (parentRecElement !== undefined &&
        (recElement.elementPurpose === "ACTION" ||
          (recElement.elementPurpose === "VERIFICATION" &&
            recElement.recStepVerifyElementProperties?.length > 0 &&
            recElement.recStepVerifyElementProperties?.filter(
              (recStepVerifyElementProperty) =>
                recStepVerifyElementProperty.id === 0 || recStepVerifyElementProperty.id === undefined
            ).length > 0))));
  let selectionImage =
    (recElement.id === undefined || recElement.previous_elementImage !== undefined) &&
    recElement.elementImage !== undefined &&
    !recElement.elementImage.includes("https://")
      ? "data:image/jpeg;base64," + recElement.elementImage
      : recElement.elementImage;

  const disableChildElement =
    disabled ||
    !startDeviceStreamingStatus ||
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) ||
    isPreviewRunning ||
    isSkipStep;

  const disableDeleteElement =
    disabled ||
    isSkipStep ||
    isPreviewRunning ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid);

  let isElementDisable = recElement?.isActive === 0;
  
  const disabledElement =
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid) ||
    disabled;
  return (
    <>
      <Col span={24} className={`${styles.elementTitle} ${isInputActive && `${styles.elementActionButtonHide}`}`}>
        <div className={`${styles.elementTextInput}`}>
          <div className={styles.inputTitleOrInputBox}>
            <span className={styles.inputTitle}>Name:</span>
            <Input
              disabled={
                disabledElement ||
                isSkipStep ||
                isPreviewRunning ||
                isElementDisable ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                  unSavedRecElementGuid !== recElement?.guid)
              }
              placeholder="Element name will appear here on selection. You may edit it later..."
              type="text"
              value={recElement?.name}
              onChange={(e) => dispatch(changeRecElementName(e.target.value, data.tag, recElement?.guid))}
              onFocus={() => setIsInputActive(true)}
              onBlur={() => {
                setIsInputActive(false);
              }}
              className={styles.inputStyle}
            />
          </div>
          {!isInputActive && (
            <>
              <div className={styles.elementDeleteOrDisable}>
                <VerticalLine className={styles.border} />
                {parentRecElement !== undefined && (
                  <div className="overflow-hidden overflow-ellipsis white-space-nowrap w-100">
                    <span className="font-size-13 font-weight-500">Element Purpose</span> :
                    <Radio.Group
                      disabled={
                        disabled ||
                        isElementDisable ||
                        (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                          unSavedRecElementGuid !== recElement?.guid)
                      }
                      buttonStyle="solid"
                      onChange={(e) => dispatch(selectChildElementPurpose(e.target.value, data.tag, recElement?.guid))}
                      value={recElement?.elementPurpose}
                      className="ml-10"
                    >
                      <Radio.Button value={"ACTION"} className="border-radius-4">
                        Action
                      </Radio.Button>
                      <Radio.Button value={"VERIFICATION"} className="mr-10 border-radius-4">
                        Verification
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                )}
                <Tooltip title={recElement.isActive === 1 ? "Disable" : "Enable"} color={TOOLTIP_BG_COLOR}>
                  <div className={styles.switch}>
                    <Switch
                      checked={recElement.isActive === 1}
                      onChange={(e) => dispatch(changeDisableElement(e, data.tag, recElement?.guid))}
                      disabled={
                        disabledElement ||
                        isSkipStep ||
                        isPreviewRunning ||
                        (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                          unSavedRecElementGuid !== recElement?.guid)
                      }
                      size="small"
                      className="disable_Element"
                    />
                  </div>
                </Tooltip>
                <VerticalLine className={styles.border} />
                <div>
                  <SvgLoader
                    iconName={DELETE_ELEMENT_ICON}
                    handleClick={() =>
                      !disableDeleteElement &&
                      dispatch(
                        showDeleteRecElementConfirmationModal(
                          data.id,
                          data.tag,
                          data.recTestScenarioRecTestStepAssocId,
                          recElement?.guid
                        )
                      )
                    }
                    disabled={disableDeleteElement}
                    iconColor={disableDeleteElement ? DISABLE_ICON_COLOR : ACTION_ICON_COLOR}
                    width="1.4rem"
                    height="1.4rem"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </Col>
      <div className={styles.row}>
        <div
          className={`${styles.recTestStepSelectorBorder} ${
            recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN && styles.recTestStepSelectionImage
          }`}
        >
          <div className={styles.recTestStepSelector}>
            <RecTestStepSelector
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              data={data}
              isDeviceLoaded={isDeviceLoaded}
              disabled={disabledElement || isElementDisable}
              projectId={projectId}
              recElement={recElement}
              unSavedRecElementGuid={unSavedRecElementGuid}
              isPreviewRunning={isPreviewRunning}
              parentRecElement={parentRecElement}
              allowRecElementToSave={allowRecElementToSave}
            />
          </div>
          <RecElementSettings
            recElement={recElement}
            data={data}
            isSkipStep={isSkipStep}
            disabled={disabledElement || isElementDisable}
          />
          {/* {recElement.elementSelectorType !== SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN && (
            <div className={styles.addChildElement}>
              <TextButton
                title={"+Add Child Element"}
                disabled={disableChildElement}
                onClick={() => dispatch(addElementIntoStep(data.tag, recElement?.guid))}
                className={`${styles.addChildElementText} ${disableChildElement && styles.disableText}`}
              />
            </div>
          )} */}
        </div>
        {recElement.elementSelectorType !== SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN && (
          <div
            className={`${styles.verificationRules} ${
              recElement?.recStepVerifyElementProperties &&
              recElement?.recStepVerifyElementProperties.length === 0 &&
              styles.addRules
            }`}
          >
            <VerificationRules
              recTestSteps={recTestSteps}
              disabled={
                props.disabled ||
                isSkipStep ||
                isElementDisable ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                  unSavedRecElementGuid !== recElement.guid)
              }
              isElementSelected={props.disabled}
              operator={recElement.verificationOperator}
              uuid={recElement.guid}
              data={data}
              recElement={recElement}
              operatorChange={recElement.verificationOperator}
              projectId={projectId}
              isDeviceLoaded={selectedDeviceByUdid !== undefined}
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              isPreviewRunning={isPreviewRunning}
              isElementDisable={isElementDisable}
            />
          </div>
        )}
        {recElement?.childRecElements?.map((childRecElement, index) => {
          return (
            <div className={styles.addChild}>
              <RecTestStepElement
                key={index}
                recElement={childRecElement}
                parentRecElement={recElement}
                data={data}
                disabled={disabled}
                projectId={projectId}
                isDeviceLoaded={isDeviceLoaded}
                isSkipStep={isSkipStep}
                isTestBlock={isTestBlock}
              />
              {index < recElement?.childRecElements.length - 1 ? <span>And</span> : ""}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default RecTestStepElement;
