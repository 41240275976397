import { omit } from "lodash";
import {
  ACTIONS,
  ADDING_BUILD_ALREADY_EXIST,
  ADD_ACTION_STEP,
  ADD_EXECUTION_REQUEST,
  ADD_LAUNCH_APP_STEP,
  ADD_NEW_SCENARIO,
  ADD_PREVIEW_REQUEST,
  ADD_SWIPE_OVER_ELEMENT_STEP,
  ADD_VERIFICATION_STEP,
  ADD_VERIFICATION_VALUES,
  ALL_APP_BUILD_DETAILS,
  ALL_APP_BUILD_DETAILS_FAILED,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED,
  ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST,
  ALL_BROWSER_INSTALLED_IN_DEVICE,
  ALL_SCENARIOS,
  ANDROID,
  APP_BUILD_DETAILS_BY_ID,
  APP_MANAGEMENT_SCREEN,
  BACK_PREVIOUS_PREVIEW_STEP,
  BACK_PREVIOUS_STEP,
  BACK_TO_APP_MANAGEMENT,
  BACK_TO_SCENARIO_LIST,
  blankVerificationItem,
  CHANGE_ABSENCE_VALUE,
  CHANGE_APP_BUILD_LIST_PAGE_NUMBER,
  CHANGE_APP_FOR_DEVICE_LOGS,
  CHANGE_APP_MANAGER_TAB,
  CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD,
  CHANGE_BUILD_FILE,
  CHANGE_BUILD_TYPE_IN_ADD_BUILD,
  CHANGE_BUILD_VERSION_IN_ADD_BUILD,
  CHANGE_DEVICE_INTERACTION_MODE,
  CHANGE_DEVICE_SESSION_LOGTYPE,
  CHANGE_DEVICE_TAB_ACTION_KEY,
  CHANGE_ELEMENT_ACTION,
  CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER,
  CHANGE_ORIENTATION,
  CHANGE_PLATFORM_TYPE_IN_ADD_BUILD,
  CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP,
  CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB,
  CHANGE_SELECTED_EXECUTION_PANEL_TAB,
  CHANGE_SHARED_IN_ADD_BUILD,
  CHANGE_SHELL_COMMAND_FOR_DEVICE,
  CHANGE_TEST_DATA_APPEND_RANDOM_TEXT,
  CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE,
  CHANGE_TEST_DATA_VARIABLE_NAME,
  CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION,
  CHANGE_VERIFICATION_DROPDOWN_VALUES,
  CHANGE_VERIFICATION_VALUES,
  CHANGE_VERIFICATION_VALUE_FOR_REF,
  CLEAR_EXECUTION_DATA,
  CLEAR_EXECUTION_DATA_DONE,
  CLEAR_THE_DEVICE_SESSION_LOGS,
  CONFIRM_STEP_REQUEST,
  CONFIRM_STEP_REQUESTED,
  CURRENT_SCREEN_ORIENTATION,
  customRefTextDefaultList,
  DEVICE_DISCONNECT,
  DEVICE_INTERACTION_MODE,
  DEVICE_LOADED,
  DEVICE_SESSION_LOGS_STREAM,
  DEVICE_SESSION_LOGS_STREAM_FAILED,
  DEVICE_SESSION_LOGS_STREAM_REQUEST,
  DEVICE_SESSION_LOGS_STREAM_STARTED,
  DEVICE_SESSION_LOGS_STREAM_STOPPED,
  DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED,
  DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST,
  DEVICE_SESSION_LOGTYPE,
  DEVICE_STATE,
  DEVICE_STATUS,
  DEVICE_USAGE_REPORT_DATA,
  DEVICE_USAGE_REPORT_DATA_REQUEST,
  DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED,
  DISABLE_KEYBOARD_IN_DEVICE,
  DOWNLOAD_BUILD_PROGRESS,
  DOWNLOAD_PREVIEW_STEP_IMAGES_REQUEST,
  DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED,
  EDIT_SCENARIO,
  ELEMENT_ACTION,
  ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE,
  EXECUTION_SCREEN_TAB,
  FAILED_START_LOGS_ON_DEVICE,
  FAILED_TO_ROTATE_SCREEN,
  FAILED_UPDATE_EXECUTION_REQUEST,
  FAST_INPUT_ICON_INDICATION,
  FIND_ELEMENT_REQUEST,
  FIND_ELEMENT_REQUESTED,
  FLUSH_THE_DASHBOARD_ATTRIBUTE,
  FLUSH_THE_RECORDING_DATA,
  FORCE_STOP_LOGS_ON_DEVICE,
  GET_APP_MANAGER_BUILD_DETAILS_REQUEST,
  GET_AWS_PROJECTS_LIST,
  GET_DEVICE_DETAILS,
  GET_DEVICE_SCREEN,
  GET_DEVICE_SCREEN_SOURCE,
  GET_PROXY_STREAMING_DATA,
  GET_UPLOAD_BUILD_OPTIONS,
  GROUP_ACTION,
  HIDE_ELEMENT_PROPERTIES_MODAL,
  HIDE_INPUT_TEXT_MODAL,
  HIDE_INSTALL_APP_MODAL,
  HIDE_LOG_MODAL,
  HIDE_NEW_VERIFICATION,
  HIDE_ORIENTATION_MODAL,
  HIDE_PUSH_BUILD_MODAL,
  HIDE_RESTART_DEVICE_MODAL,
  HIDE_TEST_DATA,
  INSERT_CUSTOM_TEXT_VERIFICATION_ROW,
  INSERT_TEXT_REQUEST,
  INSERT_TEXT_REQUESTED,
  INSTALLED_APP_IN_DEVICE_FROM_SFTP,
  INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST,
  INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED,
  IOS,
  LANDSCAPE,
  LAUNCH_APP,
  LIST_OFF_APPS_INSTALLED_FOR_LOGS,
  LOADING_SCENARIO_FOR_EDIT,
  LOADING_SCENARIO_LIST,
  LOAD_RECORDING_SCREEN_STATUS,
  LOAD_SELECTED_SCENARIO,
  LOAD_SELECTED_SCENARIO_FOR_EXECUTION,
  LOG_START_REQUEST,
  MINIMUM_SWIPE_VALUE,
  ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE,
  ON_ORIENTATION_CHANGE,
  OPEN_RECENT_APP_TRAY,
  OVERWRITE_UPLOAD_BUILD_REQUEST,
  OVERWRITE_UPLOAD_BUILD_REQUEST_COMPLETE,
  OVERWRITE_UPLOAD_BUILD_REQUEST_FAILED,
  PASSWORD_INPUT,
  PORTRAIT,
  PROXY_CLEAR_DATA,
  PROXY_STARTED,
  PROXY_START_REQUEST,
  PROXY_STATUS,
  PROXY_STOPPED,
  PROXY_STOP_REQUEST,
  PUSH_BUILD,
  PUSH_BUILD_REQUEST,
  RECORDING_RESUME,
  RECORDING_SCREEN,
  RECORDING_START,
  RECORDING_START_REQUEST,
  RECORDING_START_REQUEST_FAILED,
  RECORDING_STOP,
  RECORDING_STOP_REQUEST,
  REMOVE_BLANK_STEP,
  REMOVE_CUSTOM_TEXT_VERIFICATION_ROW,
  REMOVE_STEP,
  REMOVE_STEP_REQUEST,
  REMOVE_STEP_REQUESTED,
  REMOVE_VERIFICATION_VALUES,
  RESET_APP_BUILD_DETAILS_BY_ID,
  RESET_CUSTOM_REF_LIST,
  RESET_ELEMENT_SELECTION,
  RESET_PREVIOUS_STATE,
  RESET_SCREEN,
  RESET_SHELL_COMMAND_OUTPUT,
  RESET_SWIPE_BY_COORDINATES,
  RESTART_DEVICE,
  RESTART_DEVICE_REQUESTED,
  SAVE_SCENARIO_RECORDING,
  SAVE_SCENARIO_RECORDING_REQUEST,
  SAVE_SCENARIO_RECORDING_REQUESTED,
  SAVE_SHELL_COMMAND_OUTPUT,
  SAVE_TEST_DATA,
  SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST,
  SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST_FAILED,
  SEARCH_QUERY_APP_DETAILS_COMPLETED,
  SEARCH_QUERY_APP_DETAILS_FAILED,
  SEARCH_QUERY_APP_DETAILS_REQUEST,
  SEARCH_QUERY_RESULT_APP_DETAILS,
  SELECTED_STEP,
  SELECT_APP_BUILD_FARM_LIST_OPTION,
  SELECT_ELEMENT,
  SELECT_ELEMENT_SCREENSHOT,
  SELECT_HOVERED_ELEMENT,
  SELECT_THE_BUILD_TO_INSTALL,
  SET_ADD_APP_BUILD_DETAILS_FORM_ERROR,
  SET_CURRENT_EXECUTE_GROUP_FROM_URL,
  SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK,
  SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK,
  SET_CURRENT_RECORDING_GROUP_FROM_URL,
  SET_EXPANDED_PATHS,
  SET_INTERACTIONS_NOT_AVAILABLE,
  SET_SELECTED_APP_BUILD,
  SET_SELECTED_ELEMENT_ID,
  SET_SELECTED_GROUP,
  SET_SELECTED_PREVIEW_GROUP,
  SET_SPLITTER_DRAGGED,
  SHOW_ADD_APP_BUILD_MENU,
  SHOW_ELEMENT_PROPERTIES_MODAL,
  SHOW_INPUT_TEXT_MODAL,
  SHOW_INSTALL_APP_BUILD_MODAL,
  SHOW_LOAD_SCENARIO_MODAL,
  SHOW_LOG_MODAL,
  SHOW_MESSAGE,
  SHOW_NEW_VERIFICATION,
  SHOW_ORIENTATION_MODAL,
  SHOW_PUSH_BUILD_MODAL,
  SHOW_RESTART_DEVICE_MODAL,
  SHOW_TEST_DATA,
  SHOW_UNSAVED_STEP_MODAL_VISIBLE,
  SHOW_USER_DETAILS,
  STARTED_EXECUTION,
  START_DEVICE_SCREEN_LOADER,
  START_EXECUTE_SCREEN,
  START_LOGS_ON_DEVICE,
  STEP,
  STOPPED_EXECUTION,
  STOP_DEVICE_SCREEN_LOADER,
  STOP_EXECUTION_LOADER,
  STOP_EXECUTION_REQUEST,
  STOP_EXECUTION_REQUESTED,
  STOP_FAST_INPUT_INDICATION,
  STOP_LOGS_ON_DEVICE,
  SUCCESS_STATUS,
  SWIPE_OVER_ELEMENT,
  TABLE_EXECUTE_BUTTON_LOADER,
  TABLE_EXECUTE_BUTTON_LOADER_STOP,
  TAP_ON_COORDINATES,
  TAP_ON_COORDINATES_REQUESTED,
  TEST_SCENARIOS,
  TOGGLE_ELEMENT_PROPERTIES_MODAL,
  TOGGLE_OTHER_ELEMENT_POPOVER,
  UNSELECT_ELEMENT,
  UNSELECT_HOVERED_ELEMENT,
  UPDATE_CLIPBOARD_DATA,
  UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION,
  UPDATE_CUSTOM_REF_OPTION_VALUE,
  UPDATE_CUSTOM_REF_TEXT_VALUE,
  UPDATE_CUSTOM_REF_TYPE_VALUE,
  UPDATE_DEVICE_MODAL,
  UPDATE_DEVICE_STATE,
  UPDATE_EXECUTION_REQUEST,
  UPDATE_EXECUTION_RESULT,
  UPDATE_GROUP_ACTION_VALUE,
  UPDATE_INPUT_TEXT,
  UPDATE_RECORDING_SET_NAME,
  UPDATE_SERVER_DETAIL,
  UPDATE_SESSION_CREATE_STATUS,
  UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE,
  UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE,
  UPDATE_STEP_NAME,
  UPDATE_SWIPE_COORDINATES,
  UPDATE_TAKE_STEP_SCREENSHOT,
  UPLOAD_BUILD_PROGRESS,
  UPLOAD_BUILD_REQUEST,
  UPLOAD_BUILD_REQUEST_COMPLETE,
  UPLOAD_BUILD_REQUEST_FAILED,
  FETCHED_CURL_FOR_BUILD_UPLOAD,
  VERIFICATIONS,
  VIEW_NOTIFICATION,
  CURL_FETCH_FOR_BUILD_LOADING,
  CURL_FETCH_FOR_BUILD_LOADING_FALSE,
  RESET_DEVICE_SCREEN_SOURCE,
  HIDE_INSTALL_BUILD_ON_DEVICE,
  SHOW_INSTALL_BUILD_ON_DEVICE,
  REQUEST_INSTALL_BUILD_ON_DEVICE,
  REQUESTED_INSTALL_BUILD_ON_DEVICE,
  CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL,
  UNINSTALL_BUILD_BEFORE_INSTALL,
  ElementType,
  SELECTOR_TYPE,
  STOP_DEVICE_STREAMING,
  CHANGE_PAGE_SOURCE_FETCHING_INTERVAL,
  UPLOAD_AND_SAVE_BUILD_REQUEST,
  VISIBLE_RECORDING_SETTINGS_MODAL,
  EDIT_APP_BUILD,
} from "../Constants";
import * as util from "./../Util/index";

let defaultState = {
  recordingState: DEVICE_STATUS.INTERACTION,
  deviceScreen: undefined,
  currentDeviceScreenSource: undefined,
  deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
  currentDeviceScreenSourceXml: undefined,
  //Variable related to Actions => START
  searchedForElementBounds: null,
  selectedElement: null,
  selectedElementsDetailList: [],
  selectedElementId: "",
  expandedPaths: ["0"],
  hoveredElement: undefined,
  currentStepId: util.getUniqueId(),
  currentStepName: undefined,
  suggestedStepName: "Launch App",
  actionOnElement: undefined,
  selectedAction: undefined,
  tapByIndexValue: "",
  enterTextValue: undefined,
  appendRandomText: false,
  testDataModalVisible: false,
  testDataModalVisible: false,
  testDataVariableName: undefined,
  testDataVariableDefaultValue: undefined,
  testDataAppendRandomText: false,
  continueAfterFail: false,
  selectedElementScreenShot: "",
  swipeUpPercentage: MINIMUM_SWIPE_VALUE,
  swipeRightPercentage: MINIMUM_SWIPE_VALUE,
  swipeDownPercentage: MINIMUM_SWIPE_VALUE,
  swipeLeftPercentage: MINIMUM_SWIPE_VALUE,
  swipeDirection: undefined,
  // Old Verification
  verificationSelectedValues: [],
  selectedElementSize: undefined,
  selectedElementLocation: undefined,
  currentSelectedVerificationDropdownValue: undefined,
  currentSelectedVerificationValue: undefined,
  currentSelectedAbsenceValue: undefined,
  newVerifcationVisible: false,
  elementPropertiesModalVisible: true,
  selectedElementPath: null,
  // No Reset Variables
  previewStepIdList: [],
  testDataVariables: [],
  fetchedCurlForBuildUpload: "",
  platformName: undefined,
  deviceScreenStreamUrl: undefined,
  //Temp Verification
  elementList: [],
  // elementList:[{"tag":"1","recTestStepProperty":{"stepName":"Tap On Home"}}],
  //Variable related to Actions => END
  //Temp Verification
  elementTexts: [],
  // elementTexts:[
  //     {id:"1",value:"Home"}
  // ],
  selectedStep: 0,
  recordingSetName: "",
  recordingSetId: 0,
  apiMessage: "",
  apiStatus: "Success",
  sessionCreatingStatus: false,
  blankStepId: undefined,
  isStepAdeded: false,
  currentStepIndex: -1,
  // For Preview Releated Variables
  previewRunId: undefined,
  previewLicenseKey: undefined,
  previewUserName: undefined,
  previewRunStatus: undefined,
  previewOutputFolder: undefined,
  lastStepIdOfPreviewResult: 0,
  previewStepIdList: [],
  preparingForPreview: false,
  previewTempScriptFile: undefined,
  addNewStepStatus: false,
  shouldBlockNavigation: false,
  serverDetail: undefined,
  previewResultElementList: [],
  //For Push Build Related
  pushBuildModalVisible: false,
  pushBuildFile: undefined,
  pushBuildRequest: false,
  selectedBuildTestSceanrioTab: DEVICE_STATUS.RECORDING,
  previousDeviceStatus: null,
  saveRecordingRequest: false,
  allScenarios: [],
  isShowLoadScenarioModal: false,
  isClearingPreview: false,
  deviceDisconnectStatus: false,
  // showDeviceInteractionModeOnly: false,
  deviceDetail: undefined,
  keyboardEnable: false,
  swipeStart: undefined,
  swipeEnd: undefined,
  tapNotification: false,
  currentScreenOrientation: undefined,
  currentDevice: undefined,
  recordingRequest: false,
  showInputText: false,
  currentInputText: undefined,
  showRestartDeviceModalStatus: false,
  checkAndRestartNodeServiceCalled: false,
  inputTextSubmitRequest: false,
  takeStepScreenShot: false,
  closeLaunchAppPreviousSession: false,
  isDownloadStepImageButtonState: false,
  fastInputTextIndication: false,
  // For Execution Releated Variables
  executionRunId: undefined,
  executionLicenseKey: undefined,
  executionUserName: undefined,
  executionRunStatus: undefined,
  executionOutputFolder: undefined,
  lastStepIdOfExecutionResult: 0,
  executionStepIdList: [],
  preparingForExecution: false,
  executionTempScriptFile: undefined,
  ExecutionResultElementList: [],
  stepResult: [],
  selectedExecutionTab: EXECUTION_SCREEN_TAB.EXECUTE,
  recordingScreenStatus: RECORDING_SCREEN.SHOW_SCENARIO_LIST,
  editScenarioLoading: false,
  findElementProcessing: false,
  currentCustomTextSelectedVerificationDropdownValue: undefined,
  currentCustomTextSelectedVerificationValue: undefined,
  customPreviewTextForCustomText: "",
  customRefTextList: customRefTextDefaultList(),
  toggleElementPropertiesModal: true,
  confirmStepProcessing: false,
  previewStepIdListToExecute: [],
  stopProcessingPreview: false,
  stopProcessingExecute: false,
  removeStepRequesting: false,
  loadingScenarioList: false,
  isStepForView: false,
  currentStepVerificationList: blankVerificationItem(),
  unsavedStepModalVisible: false,
  fastInputTextIndication: false,
  //appManagement Variables
  allAppBuildDetails: [],
  noAppsBuildFoundForFarm: undefined,
  noAppsBuildFound: undefined,
  currentAppManagementScreen: APP_MANAGEMENT_SCREEN.APP_BUILDS_LISTS,
  appNameInputInAddAppBuild: "",
  buildTagsInputInAddBuild: "",
  buildTypeRadioInAddBuild: undefined,
  buildDateTimeInAddBuild: "",
  buildVersionInAddBuild: "",
  sharedWithFarmInAddAppBuild: false,
  isTheBuildLatestInAddAppBuild: false,
  selectedFarmListInAddAppBuildList: [],
  platformTypeInAddAppBuild: undefined,
  setAppNameInputFieldError: false,
  setplatformTypeRadioError: false,
  setFarmSelectionError: false,
  setPlatformTypeError: false,
  setUploadBuildUrlError: false,
  setBuildVersionError: false,
  setBuildTypeError: false,
  setBuildDateTimeError: false,
  setBuildFileError: false,
  buildUploadUrlInAddAppBuild: undefined,
  buildAppFileInAddBuild: null,
  buildUploadRequest: false,
  existingBuildDetails: undefined,
  buildFileName: "",
  percentageUpload: undefined,
  loadingAppManagerBuild: false,
  loadingInstallAppBuildScreen: false,
  //appBuildInstall variables
  allAppBuildDetailsInInstallApp: [],
  installAppFromSftpRequest: false,
  showInstallAppBuildModal: false,
  percentageDownload: undefined,
  selectedAppBuild: "",
  appBuildListCurrentSelectedPage: 1,
  installAppCurrentSelectedPage: 1,
  searchQueryTextInputInstallPage: "",
  getUploadBuildOptions: [],
  getAWSProjectsList: [],
  selectedUploadCloud: null,
  selectedAWSProject: null,
  // device Session Logs
  logsForDeviceSession: [],
  deviceSessionLogType: DEVICE_SESSION_LOGTYPE.VERBOSE,
  deviceSessionLogStopRequest: false,
  deviceSessionLogRequest: false,
  deviceSessionLogStarted: false,
  appsListForDeviceLogs: [],
  selectedAppForDeviceLogs: "ALL",
  ableToDownloadLogs: false,
  //dashboard
  selectedTabForDashBoard: "",
  clipboardData: "",
  installedBrowserDetails: undefined,
  urlNeedToBrowse: "",
  proxyStatus: PROXY_STATUS.STOPPED,
  flowRows: [],
  startDeviceStreamingStatus: false,
  newDeviceSession: true,
  shellCommandForDevice: "",
  shellCommandResult: [],
  //device usage report
  loadingDeviceUsageReport: false,
  installedBrowserErrorMessage: undefined,
  selectedGroupAction: GROUP_ACTION.ALWAYS_RUN,
  previousSelectedPreviewGroup: undefined,
  selectedGroup: undefined,
  selectedPreviewGroup: [],
  openedGroup: undefined,
  breadScrumItems: [],
  breadScrumPreviewItems: [],
  deviceScreenLoader: false,
  selectedDeviceTabActionKey: TEST_SCENARIOS,
  otherElementsRelatedToCoordinates: [],
  otherElementCoordinateVisible: false,
  curlFetchForBuildLoading: false,
  pushBuildResponse: undefined,
  installBuildOnDeviceModalVisible: false,
  requestInstallBuildOnMultipleDevice: false,
  installBuildDeviceDetails: [],
  selectedBuildDataToInstall: undefined,
  unInstallBUildBeforeInstall: 0,
  pageSourceFetchingInterval: 5,
  deviceAttributesDrawerVisible: false,
  uploadAndSaveBuildRequest: false,
  visibleRecordingSettingModal: false,
  unSavedAppBuildChanges:false
};

export default function RecordingReducer(state = defaultState, action) {
  let updatedElementList = state.elementList;
  let tempDataObj = {};
  let blankStepId = undefined;
  let customRefTextList = [];
  let suggestedStepName = state.elementList
    ? state.elementList.length == 0
      ? "Launch App"
      : "Step-" + (state.elementList.length + 1)
    : "";
  let selectedAction = undefined;
  let previewStarted = undefined;
  let currentStepVerificationList = [];
  let customRefTextListData = [];
  let elementTexts = [];
  let currentSteps = undefined;
  let selectedGroup = undefined;
  let selectedElement = undefined;

  switch (action.type) {
    case CHANGE_PAGE_SOURCE_FETCHING_INTERVAL:
      return {
        ...state,
        pageSourceFetchingInterval: action.value,
      };
    case STOP_DEVICE_SCREEN_LOADER:
      return {
        ...state,
        deviceScreenLoader: false,
      };
    case START_DEVICE_SCREEN_LOADER:
      return {
        ...state,
        deviceScreenLoader: true,
      };
    case RECORDING_START_REQUEST:
      return {
        ...state,
        recordingRequest: true,
        deviceScreenLoader: true,
      };
    case RECORDING_START_REQUEST_FAILED:
      return {
        ...state,
        recordingRequest: false,
        deviceScreenLoader: true,
      };
    case RECORDING_STOP_REQUEST:
      return {
        ...state,
        recordingRequest: true,
        deviceScreenLoader: true,
        deviceScreen: undefined,
      };
    case INSERT_TEXT_REQUEST:
      return {
        ...state,
        inputTextSubmitRequest: true,
      };
    case INSERT_TEXT_REQUESTED:
      return {
        ...state,
        inputTextSubmitRequest: false,
      };
    case UPDATE_STATUS_CHECK_AND_RESTART_ANDROID_NODE_SERVICE:
      return {
        ...state,
        checkAndRestartNodeServiceCalled: true,
        invalidUser: action.response.invalidUser ? action.response.invalidUser : null,
        newDeviceSession: false,
      };
    case DEVICE_LOADED:
      return {
        ...state,
        deviceLoaded: action.isLoaded,
      };
    case UPDATE_STATUS_CHECK_AND_RESTART_IOS_RECORDING_SERVICE:
      return {
        ...state,
        startDeviceStreamingStatus: true,
        invalidUser: action.response.invalidUser ? action.response.invalidUser : null,
      };
    case FIND_ELEMENT_REQUEST:
      return {
        ...state,
        findElementProcessing: true,
      };
    case FIND_ELEMENT_REQUESTED:
      return {
        ...state,
        findElementProcessing: false,
      };
    case CONFIRM_STEP_REQUEST:
      return {
        ...state,
        confirmStepProcessing: true,
      };
    case CONFIRM_STEP_REQUESTED:
      return {
        ...state,
        confirmStepProcessing: false,
      };
    case CHANGE_DEVICE_INTERACTION_MODE:
      return {
        ...state,
        deviceInteractionMode: DEVICE_INTERACTION_MODE[action.value],
      };
    case RECORDING_START:
      // updatedElementList.push({id:blankStepId,recTestStepProperty:{stepName:currentStepName}});
      if (state.elementList.length == 0) {
        if (state.blankStepId === undefined) {
          blankStepId = util.getUniqueId();
          util.insertStep(state, blankStepId, suggestedStepName);
          return {
            ...state,
            recordingState: DEVICE_STATUS.RECORDING,
            deviceInteractionMode: action.deviceInteractionMode
              ? action.deviceInteractionMode
              : DEVICE_INTERACTION_MODE.SELECT,
            currentStepId: blankStepId,
            blankStepId,
            currentStepName: undefined,
            suggestedStepName,
            isStepAdeded: true,
            actionOnElement: LAUNCH_APP,
            selectedAction: ELEMENT_ACTION.LAUNCH_APP,
            currentStepIndex: updatedElementList.length - 1,
            selectedStep: blankStepId,
            shouldBlockNavigation: true,
            recordingRequest: false,
            addNewStepStatus: false,
            toggleElementPropertiesModal: false,
            deviceScreenLoader: false,
          };
        } else {
          return {
            ...state,
            recordingState: DEVICE_STATUS.RECORDING,
            deviceInteractionMode: DEVICE_INTERACTION_MODE.SELECT,
            currentStepId: state.blankStepId,
            isStepAdeded: true,
            actionOnElement: VERIFICATIONS,
            currentStepIndex: updatedElementList.length - 1,
            selectedStep: state.blankStepId,
            recordingRequest: false,
            addNewStepStatus: false,
            toggleElementPropertiesModal: false,
            deviceScreenLoader: false,
          };
        }
      }
      return {
        ...state,
        recordingState: DEVICE_STATUS.RECORDING,
        deviceInteractionMode: action.deviceInteractionMode
          ? action.deviceInteractionMode
          : DEVICE_INTERACTION_MODE.SELECT,
        isStepAdeded: false,
        recordingRequest: false,
        addNewStepStatus: true,
        toggleElementPropertiesModal: false,
        deviceScreenLoader: false,
      };
    case RECORDING_RESUME:
      return {
        ...state,
        recordingState: DEVICE_STATUS.RECORDING,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.SELECT,
        toggleElementPropertiesModal: false,
      };
    case RECORDING_STOP:
      util.removeStepById(state);
      return {
        ...state,
        recordingState: DEVICE_STATUS.INTERACTION,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
        isStepAdeded: false,
        blankStepId: undefined,
        currentStepIndex: -1,
        recordingRequest: false,
        toggleElementPropertiesModal: false,
        deviceScreenLoader: false,
      };
    case STOP_DEVICE_STREAMING:
      return {
        ...state,
        recordingState: DEVICE_STATUS.INTERACTION,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
        startDeviceStreamingStatus: false,
      };
    case CHANGE_APP_MANAGER_TAB:
      state.selectedTab = action.data;
      return {
        ...state,
      };
    case SET_SELECTED_APP_BUILD:
      return {
        ...state,
        ...action.data,
      };
    case GET_DEVICE_SCREEN:
      return {
        ...state,
        deviceScreen: action.response ? action.response : state.deviceScreen,
      };
    case RESTART_DEVICE:
      return {
        ...state,
        recordingState: DEVICE_STATUS.INTERACTION,
      };
    case RESTART_DEVICE_REQUESTED:
      return {
        ...state,
        recordingState: DEVICE_STATUS.RESTART,
      };
    case GET_DEVICE_SCREEN_SOURCE:
      return {
        ...state,
        currentDeviceScreenSource: util.xmlToJSON(action.response.data),
        currentDeviceScreenSourceXml: action.response.data,
      };

    case RESET_DEVICE_SCREEN_SOURCE:
      return {
        ...state,
        currentDeviceScreenSource: undefined,
        currentDeviceScreenSourceXml: undefined,
      };

    case GET_DEVICE_DETAILS:
      let orientation = undefined;
      let isDeviceOffline = undefined;
      if (action.response.data["deviceModel"] && action.response.data["deviceModel"].includes("Portal+")) {
        //Reverse for Potral+ ( As we are supporting only potrait mode )
        orientation = util.isPotraitType(action.response.data["currentOrientation"]) ? LANDSCAPE : PORTRAIT;
      } else {
        orientation = action.response.data["currentOrientation"]
          ? util.isPotraitType(action.response.data["currentOrientation"])
            ? PORTRAIT
            : LANDSCAPE
          : PORTRAIT;
      }
      if (action.response.data["isDeviceOffline"]) {
        isDeviceOffline = action.response.data["isDeviceOffline"];
      }
      return {
        ...state,
        deviceDetail: action.response.data,
        platformName: action.response.data.platformName,
        deviceScreenStreamUrl: action.response.data.deviceNetworkIp,
        currentScreenOrientation: orientation,
        logStarted: action.response.data.IsDeviceLogStarted ? action.response.data.IsDeviceLogStarted : false,
        isDeviceOffline: action.response.data["isDeviceOffline"] ? action.response.data["isDeviceOffline"] : false,
        deviceActions: action.response.data.actions ? action.response.data.actions : null,
        recordingState:
          state.deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT
            ? DEVICE_STATUS.RECORDING
            : DEVICE_STATUS.INTERACTION,
        deviceInteractionMode:
          state.deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT
            ? DEVICE_INTERACTION_MODE.SELECT
            : state.deviceInteractionMode,
        recordingRequest: false,
        // recordingScreenStatus:RECORDING_SCREEN.SHOW_SCENARIO_LIST
      };
    case TAP_ON_COORDINATES:
      return {
        ...state,
        keyboardEnable: true,
        swipeStart: undefined,
        swipeEnd: undefined,
        tapNotification: false,
      };
    case SELECT_ELEMENT:
      if (action.path) {
        selectedElement = findElementByPath(action.path, action.source);
      } else if (action.bounds && state.deviceDetail["platformName"].toUpperCase() === ANDROID) {
        selectedElement = findAndroidElementByBounds(action.bounds, action.source);
        action.path = selectedElement.path;
      } else if (action.bounds && state.deviceDetail["platformName"].toUpperCase() === IOS) {
        selectedElement = findIosElementByBounds(action.bounds, action.source);
        action.path = selectedElement.path;
      }

      let otherElementsRelatedToCoordinates = [];
      if (action.xCoordinate && action.yCoordinate) {
        util.findOtherElementsInPage(
          action.source.children,
          action.xCoordinate,
          action.yCoordinate,
          otherElementsRelatedToCoordinates,
          1,
          1,
          state.deviceDetail.screenSize,
          selectedElement.attributes,
          state.deviceDetail["platformName"].toUpperCase()
        );
      }
      let recElement = util.getUnSavedRecElement(
        action.recTestStep.recTestStepProperty?.recElements,
        action.reRecordElementGuid
      );
      selectedElement = util.getElementCustomXpath(action.source, selectedElement);
      selectedElement["id"] = recElement ? recElement.id : 0;
      selectedElement["guid"] = recElement ? recElement.guid : util.getUniqueId();
      selectedElement["name"] = util.getSelectedElementText(selectedElement);
      selectedElement["tags"] = recElement ? recElement.tags : 0;
      selectedElement["description"] = recElement ? recElement.description : 0;
      selectedElement["elementType"] = recElement ? recElement.elementType : ElementType.DIFFERENT_VERSION;
      selectedElement["elementSelectorType"] = recElement
        ? recElement.elementSelectorType
        : SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN;
      return {
        ...state,
        otherElementsRelatedToCoordinates,
        selectedElement,
        selectedElementPath: action.path,
        elementInteractionsNotAvailable: false,
      };
    case SELECT_ELEMENT_SCREENSHOT:
      return {
        ...state,
        selectedElementScreenShot: action.selectedElementScreenShot,
      };
    case UNSELECT_ELEMENT:
      return {
        ...state,
        selectedElement: undefined,
        selectedElementPath: null,
        selectedElementId: null,
        otherElementsRelatedToCoordinates: [],
        otherElementCoordinateVisible: false,
      };
    case SET_EXPANDED_PATHS:
      return {
        ...state,
        expandedPaths: action.paths,
      };
    case SELECT_HOVERED_ELEMENT:
      return {
        ...state,
        hoveredElement: findElementByPath(action.path, action.source),
      };
    case UNSELECT_HOVERED_ELEMENT:
      return omit(state, "hoveredElement");
    case SET_SELECTED_ELEMENT_ID:
      // fetch the verification list data
      currentStepVerificationList = { ...state.currentStepVerificationList };
      // Prepare selected Action
      selectedAction = util.selectedAction(
        state.actionOnElement,
        action.elementDetails.length,
        state.selectedElement && state.selectedElement.attributes ? state.selectedElement.attributes : undefined
      );
      // prepare location and size object
      let startPostion = undefined;
      let endPostion = undefined;
      if (action.selectedElementAttributes.bounds) {
        startPostion = action.selectedElementAttributes.bounds.split("][")[0].split("[")[1].split(",");
        endPostion = action.selectedElementAttributes.bounds.split("][")[1].split("[")[0].split(",");
      } else {
        startPostion = [action.selectedElementAttributes.x, action.selectedElementAttributes.y];
        endPostion = [
          Number(startPostion[0]) + Number(action.selectedElementAttributes.width),
          Number(startPostion[1]) + Number(action.selectedElementAttributes.height),
        ];
      }
      let location = {
        x: parseInt(startPostion[0]),
        y: parseInt(startPostion[1]),
      };
      let size = {
        width: parseInt(endPostion[0]) - parseInt(startPostion[0]),
        height: parseInt(endPostion[1]) - parseInt(startPostion[1]),
      };
      let selectedElementId = "";
      if (
        action.elementDetails.recElementLocatorProperties &&
        action.elementDetails.recElementLocatorProperties[0].mobileElements &&
        action.elementDetails.recElementLocatorProperties[0].mobileElements.length > 0
      ) {
        selectedElementId = action.elementDetails.recElementLocatorProperties[0].mobileElements[0].id;
      }
      let tapByIndexValue = "";
      // select the tap by index option value through localtion and size
      action.elementDetails.recElementLocatorProperties.map((recElementLocatorProperty) => {
        recElementLocatorProperty?.mobileElements &&
          recElementLocatorProperty.mobileElements.map((v, k) => {
            if (
              JSON.stringify(v.location) === JSON.stringify(location) &&
              JSON.stringify(v.size) === JSON.stringify(size)
            ) {
              selectedElementId = v.id;
              tapByIndexValue = recElementLocatorProperty.mobileElements.length > 1 ? k + 1 : "";
            }
          });
      });

      //Reset the verificationList Data
      currentStepVerificationList.data = [];
      return {
        ...state,
        selectedElementsDetailList: action.elementDetails,
        selectedElementId: selectedElementId,
        selectedAction,
        tapByIndexValue: tapByIndexValue !== "" ? tapByIndexValue : "",
        searchedForElementBounds: { elLocation: location, size: size },
        suggestedStepName: util.suggestedStepNameFromElement(state.selectedElement, selectedAction),
        findElementProcessing: false,
        currentStepVerificationList,
        hoveredElement: [],
      };
    case SET_INTERACTIONS_NOT_AVAILABLE:
      return {
        ...state,
        elementInteractionsNotAvailable: true,
      };
    case UPDATE_STEP_NAME:
      return {
        ...state,
        currentStepName: action.value,
      };
    case FETCHED_CURL_FOR_BUILD_UPLOAD:
      return {
        ...state,
        fetchedCurlForBuildUpload: action.response.data.cURL,
      };
    case CURL_FETCH_FOR_BUILD_LOADING:
      return {
        ...state,
        curlFetchForBuildLoading: true,
      };
    case CURL_FETCH_FOR_BUILD_LOADING_FALSE:
      return {
        ...state,
        curlFetchForBuildLoading: false,
      };
    case CHANGE_ELEMENT_ACTION:
      selectedAction = util.selectedAction(
        action.value,
        state.selectedElementsDetailList.length,
        state.selectedElement.attributes
      );
      return {
        ...state,
        actionOnElement: action.value,
        selectedAction,
        // Old Verification
        verificationSelectedValues: [],
        currentStepVerificationList: blankVerificationItem(),
        tapByIndexValue: "",
        enterTextValue: undefined,
        appendRandomText: false,
        testDataModalVisible: false,
        testDataModalVisible: false,
        testDataVariableName: undefined,
        testDataVariableDefaultValue: undefined,
        testDataAppendRandomText: false,
        suggestedStepName: util.suggestedStepName(state.selectedElement, selectedAction),
      };
    case UPDATE_GROUP_ACTION_VALUE:
      return {
        ...state,
        selectedGroupAction: action.value,
        selectedElement: undefined,
        otherElementsRelatedToCoordinates: [],
        otherElementCoordinateVisible: false,
      };

    case UPDATE_TAKE_STEP_SCREENSHOT:
      return {
        ...state,
        takeStepScreenShot: !state.takeStepScreenShot,
      };
    case UPDATE_CLOSE_LAUNCH_APP_PREVIEW_SESSION:
      return {
        ...state,
        closeLaunchAppPreviousSession: !state.closeLaunchAppPreviousSession,
      };
    case SHOW_TEST_DATA:
      return {
        ...state,
        testDataModalVisible: true,
      };
    case HIDE_TEST_DATA:
      return {
        ...state,
        testDataModalVisible: false,
      };
    case UPDATE_RECORDING_SET_NAME:
      return {
        ...state,
        recordingSetName: action.value,
        changeScenrioName: true,
      };
    case CHANGE_TEST_DATA_VARIABLE_NAME:
      return {
        ...state,
        testDataVariableName: action.value,
      };
    case CHANGE_TEST_DATA_VARIABLE_DEFAULT_VALUE:
      return {
        ...state,
        testDataVariableDefaultValue: action.value,
      };
    case CHANGE_TEST_DATA_APPEND_RANDOM_TEXT:
      return {
        ...state,
        testDataAppendRandomText: action.checked,
      };
    case SAVE_TEST_DATA:
      let testData = {
        name: state.testDataVariableName,
        value: state.testDataVariableDefaultValue,
        appendRandomtext: state.testDataVariableDefaultValue,
      };
      let testDataVariables = [];
      testDataVariables = state.testDataVariables;
      testDataVariables.push(testData);
      return {
        ...state,
        testDataVariables,
        testDataModalVisible: false,
        testDataVariableName: undefined,
        testDataVariableDefaultValue: undefined,
        testDataAppendRandomText: false,
      };
    case VIEW_NOTIFICATION:
      if (state.recordingState === DEVICE_STATUS.RECORDING) {
        if (action.response.status === SUCCESS_STATUS) {
          updatedElementList = util.insertStepIntoElementList(state, suggestedStepName, action.deviceInteractionAction);
          return {
            ...state,
            elementList: updatedElementList,
            ...resetState(state),
            currentStepName: undefined,
            suggestedStepName,
            selectedStep: state.currentStepId,
            currentStepId: blankStepId,
            blankStepId,
            actionOnElement: undefined,
            isStepAdeded: false,
            addNewStepStatus: true,
          };
        }
      }
      return {
        ...state,
      };
    case SHOW_ELEMENT_PROPERTIES_MODAL:
      return {
        ...state,
        elementPropertiesModalVisible: true,
      };
    case HIDE_ELEMENT_PROPERTIES_MODAL:
      return {
        ...state,
        elementPropertiesModalVisible: false,
      };
    case RESET_SWIPE_BY_COORDINATES:
      return {
        ...state,
        swipeStart: undefined,
        swipeEnd: undefined,
      };
    case CHANGE_VERIFICATION_DROPDOWN_VALUES:
      return {
        ...state,
        currentSelectedVerificationDropdownValue: action.value,
      };
    case CHANGE_VERIFICATION_VALUES:
      return {
        ...state,
        currentSelectedVerificationValue: action.value,
      };
    case SHOW_NEW_VERIFICATION:
      return {
        ...state,
        newVerifcationVisible: true,
      };
    case HIDE_NEW_VERIFICATION:
      return {
        ...state,
        newVerifcationVisible: false,
      };
    // Old Verification
    case ADD_VERIFICATION_VALUES:
      tempDataObj = {
        tag: util.getUniqueId(),
        key: state.currentSelectedVerificationDropdownValue,
        value: state.currentSelectedVerificationValue,
      };
      state.verificationSelectedValues.push(tempDataObj);
      return {
        ...state,
        newVerifcationVisible: false,
        currentSelectedVerificationDropdownValue: undefined,
        currentSelectedVerificationValue: undefined,
      };
    //old Verification
    case CHANGE_VERIFICATION_VALUE_FOR_REF:
      tempDataObj = {
        tag: util.getUniqueId(),
        key: state.currentSelectedVerificationDropdownValue,
        value: state.elementTexts.filter((item) => item.tag === action.tag)[0].value,
        referenceStepTag: action.id,
      };
      state.verificationSelectedValues.push(tempDataObj);
      return {
        ...state,
        newVerifcationVisible: false,
        currentSelectedVerificationDropdownValue: undefined,
        currentSelectedVerificationValue: undefined,
      };
    //old verification
    case REMOVE_VERIFICATION_VALUES:
      let newVerificationSelectedValues = state.verificationSelectedValues.filter((item) => item.tag !== action.id);
      return {
        ...state,
        verificationSelectedValues: newVerificationSelectedValues,
      };
    //old verification
    case CHANGE_ABSENCE_VALUE:
      let value = state.elementList.filter((item) => item.id === action.id)[0];
      if (value == undefined) {
        value = state.suggestedStepName;
      }
      tempDataObj = {
        id: util.getUniqueId(),
        key: state.currentSelectedVerificationDropdownValue,
        value: value,
        referenceId: action.id,
      };
      state.verificationSelectedValues.push(tempDataObj);
      return {
        ...state,
        newVerifcationVisible: false,
        currentSelectedVerificationDropdownValue: undefined,
        currentSelectedVerificationValue: undefined,
      };
    case ADD_ACTION_STEP:
      if (state.selectedGroup) {
        if (state.selectedGroup.recTestSteps == undefined) {
          state.selectedGroup.recTestSteps = [];
        }
        currentSteps = state.selectedGroup.recTestSteps;
      } else {
        currentSteps = state.elementList;
      }

      blankStepId = util.getUniqueId();
      state.currentStepName = undefined;
      let insertAt = 0;
      if (currentSteps.length == 0) {
        suggestedStepName = "Step-1";
      }
      if (action.data.stepIndex == undefined) {
        action.data.stepIndex = currentSteps.length;
        insertAt = action.data.stepIndex;
      } else {
        insertAt = action.data.stepIndex;
        action.data.stepIndex = action.data.stepIndex + 1;
      }
      util.insertStep(state, blankStepId, suggestedStepName, insertAt, false, currentSteps);
      //State.elementList didnt insert the in group child elements if not added will add
      if (state.selectedGroup) {
        updateChildElementList(state.elementList, currentSteps, state.selectedGroup.tag);
      }
      //updatedElementList.push({id:blankStepId,recTestStepProperty:{stepName:currentStepName}});

      return {
        ...state,
        currentStepId: blankStepId,
        blankStepId,
        currentStepName: undefined,
        suggestedStepName,
        isStepAdeded: true,
        actionOnElement: ACTIONS,
        currentStepIndex: action.data.stepIndex,
        selectedStep: blankStepId,
        addNewStepStatus: false,
        shouldBlockNavigation: true,
        toggleElementPropertiesModal: true,
        selectedElement: null,
        isStepForView: false,
        enterTextValue: undefined,
      };
    case ADD_SWIPE_OVER_ELEMENT_STEP:
      if (state.selectedGroup) {
        if (state.selectedGroup.recTestSteps == undefined) {
          state.selectedGroup.recTestSteps = [];
        }
        currentSteps = state.selectedGroup.recTestSteps;
      } else {
        currentSteps = state.elementList;
      }

      blankStepId = util.getUniqueId();
      state.currentStepName = undefined;
      let sinsertAt = 0;
      if (currentSteps.length == 0) {
        suggestedStepName = "Step-1";
      }
      if (action.data.stepIndex == undefined) {
        action.data.stepIndex = currentSteps.length;
        sinsertAt = action.data.stepIndex;
      } else {
        sinsertAt = action.data.stepIndex;
        action.data.stepIndex = action.data.stepIndex + 1;
      }
      util.insertStep(state, blankStepId, suggestedStepName, sinsertAt, false, currentSteps);
      //State.elementList didnt insert the in group child elements if not added will add
      if (state.selectedGroup) {
        updateChildElementList(state.elementList, currentSteps, state.selectedGroup.tag);
      }
      //updatedElementList.push({id:blankStepId,recTestStepProperty:{stepName:currentStepName}});
      return {
        ...state,
        currentStepId: blankStepId,
        blankStepId,
        currentStepName: undefined,
        suggestedStepName,
        isStepAdeded: true,
        actionOnElement: SWIPE_OVER_ELEMENT,
        currentStepIndex: action.data.stepIndex,
        selectedStep: blankStepId,
        addNewStepStatus: false,
        shouldBlockNavigation: true,
        toggleElementPropertiesModal: true,
        selectedElement: null,
        isStepForView: false,
        enterTextValue: undefined,
      };

    case ADD_VERIFICATION_STEP:
      if (state.selectedGroup) {
        if (state.selectedGroup.recTestSteps == undefined) {
          state.selectedGroup.recTestSteps = [];
        }
        currentSteps = state.selectedGroup.recTestSteps;
      } else {
        currentSteps = state.elementList;
      }

      blankStepId = util.getUniqueId();
      state.currentStepName = undefined;
      let ainsertAt = 0;
      if (currentSteps.length == 0) {
        suggestedStepName = "Step-1";
      }
      if (action.data.stepIndex == undefined) {
        action.data.stepIndex = currentSteps.length;
        ainsertAt = action.data.stepIndex;
      } else {
        ainsertAt = action.data.stepIndex;
        action.data.stepIndex = action.data.stepIndex + 1;
      }
      util.insertStep(state, blankStepId, suggestedStepName, ainsertAt, false, currentSteps);
      //State.elementList didnt insert the in group child elements if not added will add
      if (state.selectedGroup) {
        updateChildElementList(state.elementList, currentSteps, state.selectedGroup.tag);
      }
      return {
        ...state,
        currentStepId: blankStepId,
        blankStepId,
        currentStepName: undefined,
        suggestedStepName,
        isStepAdeded: true,
        actionOnElement: VERIFICATIONS,
        currentStepIndex: action.data.stepIndex,
        selectedStep: blankStepId,
        addNewStepStatus: false,
        shouldBlockNavigation: true,
        toggleElementPropertiesModal: true,
        selectedElement: null,
        isStepForView: false,
        selectedAction: ELEMENT_ACTION.VERIFY_ELEMENT_PROPERTY,
      };
    case ADD_LAUNCH_APP_STEP:
      if (state.selectedGroup) {
        if (state.selectedGroup.recTestSteps == undefined) {
          state.selectedGroup.recTestSteps = [];
        }
        currentSteps = state.selectedGroup.recTestSteps;
      } else {
        currentSteps = state.elementList;
      }

      blankStepId = util.getUniqueId();
      state.currentStepName = undefined;
      let binsertAt = 0;
      if (action.data.stepIndex == undefined) {
        action.data.stepIndex = currentSteps.length;
        binsertAt = action.data.stepIndex;
      } else {
        binsertAt = action.data.stepIndex;
        action.data.stepIndex = action.data.stepIndex + 1;
      }
      util.insertStep(state, blankStepId, suggestedStepName, binsertAt, false, currentSteps);
      //State.elementList didnt insert the in group child elements if not added will add
      if (state.selectedGroup) {
        updateChildElementList(state.elementList, currentSteps, state.selectedGroup.tag);
      }
      //updatedElementList.push({id:blankStepId,recTestStepProperty:{stepName:currentStepName}});
      return {
        ...state,
        currentStepId: blankStepId,
        blankStepId,
        currentStepName: undefined,
        suggestedStepName,
        isStepAdeded: true,
        actionOnElement: ELEMENT_ACTION.LAUNCH_APP,
        selectedAction: ELEMENT_ACTION.LAUNCH_APP,
        currentStepIndex: action.data.stepIndex,
        selectedStep: blankStepId,
        addNewStepStatus: false,
        shouldBlockNavigation: true,
        toggleElementPropertiesModal: false,
        suggestedStepName: "Launch App",
        selectedElement: null,
        isStepForView: false,
        selectedAppLaunch: undefined,
      };
    case RESET_ELEMENT_SELECTION:
      return {
        ...state,
        ...resetState(state, state.actionOnElement),
        selectedElement: undefined,
        selectedElementPath: null,
        selectedElementId: null,
        otherElementsRelatedToCoordinates: [],
        otherElementCoordinateVisible: false,
        findElementProcessing: false,
      };
    case REMOVE_STEP_REQUEST:
      return {
        ...state,
        removeStepRequesting: true,
      };
    case REMOVE_STEP_REQUESTED:
      return {
        ...state,
        removeStepRequesting: false,
      };
    case REMOVE_STEP:
      currentSteps = undefined;
      let blankStepRemoved = false;
      if (action.tag === state.currentStepId) blankStepRemoved = true;
      //Remove the steps from existing group
      if (state.selectedGroup && state.selectedGroup.recTestSteps != undefined) {
        state.selectedGroup.recTestSteps = state.selectedGroup.recTestSteps.filter((item) => item.tag !== action.tag);
      } else if (state.elementTexts) {
        state.elementTexts = state.elementTexts.filter((item) => item.tag !== action.tag);
        if (state.elementTexts === undefined) state.elementTexts = [];
      }
      //Remove the steps at root level
      updatedElementList = updatedElementList.filter((item) => item.tag !== action.tag);

      //Attach latest steps to existing element
      if (action.response) {
        updatedElementList = action.response.data.recTestSteps;
      }

      if (action.tag == state.selectedStep) {
        return {
          ...state,
          ...resetState(state),
          elementList: updatedElementList,
          addNewStepStatus: updatedElementList.length === 0 ? true : blankStepRemoved,
          isStepAdeded: false,
          addNewStepStatus: true,
          removeStepRequesting: false,
          blankStepId: blankStepRemoved ? undefined : blankStepId,
          isStepForView: false,
        };
      }
      return {
        ...state,
        elementList: updatedElementList,
        addNewStepStatus: updatedElementList.length === 0 ? true : blankStepRemoved,
        isStepAdeded: false,
        addNewStepStatus: true,
        elementTexts,
        removeStepRequesting: false,
        blankStepId: blankStepRemoved ? undefined : blankStepId,
        isStepForView: false,
      };
    case SELECTED_STEP:
      currentSteps = undefined;

      if (state.selectedGroup) {
        currentSteps = state.selectedGroup.recTestSteps;
      } else {
        currentSteps = state.elementList;
      }

      let selectedSaveElement = currentSteps && currentSteps.filter((item) => item.tag === action.value.tag);
      if (
        state.blankStepId !== action.value &&
        selectedSaveElement &&
        selectedSaveElement.length > 0 &&
        state.selectedElement
      ) {
        selectedElement = util.prepareRecordingEditStep(state, selectedSaveElement[0]);
        return {
          ...state,
          selectedStep: action.value,
          selectedElement,
          isStepForView: true,
        };
      } else {
        return {
          ...state,
        };
      }
    case SHOW_MESSAGE:
      return {
        ...state,
        apiMessage: action.response.message,
        apiStatus: action.response.status,
        pushBuildRequest: false,
        invalidUser: action.response.invalidUser ? action.response.invalidUser : null,
      };
    case UPDATE_SESSION_CREATE_STATUS:
      return {
        ...state,
        sessionCreatingStatus: action.status,
        deviceScreen: action.status ? undefined : state.deviceScreen,
      };

    case DOWNLOAD_PREVIEW_STEP_IMAGES_REQUEST:
      return {
        ...state,
        isDownloadStepImageButtonState: false,
      };
    case DOWNLOAD_PREVIEW_STEP_IMAGES_REQUESTED:
      return {
        ...state,
        isDownloadStepImageButtonState: false,
      };

    case STOP_EXECUTION_REQUESTED:
      return {
        ...state,
        stopProcessingExecute: false,
      };

    case SAVE_SCENARIO_RECORDING:
      let unsavedSteps = state.elementList.filter((item) => item.id == undefined);
      elementTexts = state.elementTexts;
      updatedElementList = action.response.data.recTestSteps;
      if (state.selectedGroup) {
        if (state.selectedGroup.recTestSteps) {
          unsavedSteps = state.selectedGroup.recTestSteps.filter((item) => item.id == undefined);
        }
        state.selectedGroup = util.findCurrentGroup(action.response.data.recTestSteps, state.selectedGroup.id);
      }

      unsavedSteps.map((v, k) => {
        if (state.selectedGroup) {
          updatedElementList = updateElementListWithUnsavedStep(updatedElementList, v, state.selectedGroup.id);
        } else {
          updatedElementList = updateElementListWithUnsavedStep(updatedElementList, v);
        }
      });
      return {
        ...state,
        shouldBlockNavigation: false,
        recordingSetId: action.response.data.id,
        elementList: updatedElementList,
        elementTexts,
      };
    case UPDATE_SERVER_DETAIL:
      let tData = action.response.data;
      if (tData.serverIp) {
        let protocoal = tData.serverIp.split("://")[0] == "https" ? "https://" : "http://";
        tData.serverIpAddress = protocoal + tData.serverIp.split("://")[1].split(":")[0] + ":";
      }
      return {
        ...state,
        serverDetail: tData,
        logedInUser: action.response.logedInUser,
      };
    case SHOW_PUSH_BUILD_MODAL:
      return {
        ...state,
        pushBuildModalVisible: true,
        pushBuildFile: undefined,
      };
    case HIDE_PUSH_BUILD_MODAL:
      return {
        ...state,
        pushBuildModalVisible: false,
        pushBuildFile: undefined,
      };
    case CHANGE_BUILD_FILE:
      return {
        ...state,
        pushBuildFile: action.pushBuildFile,
      };
    case PUSH_BUILD_REQUEST:
      return {
        ...state,
        pushBuildRequest: true,
        pushBuildResponse: undefined,
      };
    case PUSH_BUILD:
      return {
        ...state,
        pushBuildModalVisible: false,
        pushBuildFile: undefined,
        pushBuildRequest: false,
        pushBuildResponse: action.response.status,
      };
    case CHANGE_SELECTED_BUILD_TEST_SCENARIO_TAB:
      let currentMode = state.previousDeviceStatus == null ? state.recordingState : state.previousDeviceStatus;
      let changeRecordingState = action.data.changeModeToPrevious ? state.previousDeviceStatus : currentMode;
      if (action.data.changeModeToPrevious) {
        if (state.previousDeviceStatus == null) currentMode = state.previousDeviceStatus;

        state.previousDeviceStatus = null;
      }
      return {
        ...state,
        selectedBuildTestSceanrioTab: action.data.selectedKey,
        recordingState: changeRecordingState,
        previousDeviceStatus: currentMode,
      };
    case RESET_PREVIOUS_STATE:
      return {
        ...state,
        previousDeviceStatus: null,
      };

    case SAVE_SCENARIO_RECORDING_REQUEST:
      return {
        ...state,
        saveRecordingRequest: true,
      };
    case ALL_SCENARIOS:
      return {
        ...state,
        allScenarios: action.response.data,
      };
    case LOADING_SCENARIO_LIST:
      return {
        ...state,
        loadingScenarioList: action.isStart,
      };
    case LOAD_SELECTED_SCENARIO:
      elementTexts = [];
      let recTestScenarioName = action.recTestScenarioName;
      if (recTestScenarioName == undefined && state.allScenarios && state.allScenarios.data) {
        let selectedScenarioObj =
          state.allScenarios.data && state.allScenarios.data.filter((item) => item.id === action.recTestScenarioId);
        recTestScenarioName = selectedScenarioObj.length > 0 ? selectedScenarioObj[0].name : undefined;
      }
      updatedElementList = action.response.data;
      updatedElementList.map((v, k) => {
        if (v.recTestStepProperty && v.recTestStepProperty?.recStepElementAttributes) {
          let data = undefined;
          v.recTestStepProperty.recStepElementAttributes.map((dataValue, key) => {
            if (dataValue.value !== "") {
              data = { id: v.id, value: dataValue.value, isSavedStep: true };
            }
          });
          data && elementTexts.push(data);
        }
      });
      return {
        ...state,
        recordingState: DEVICE_STATUS.INTERACTION,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
        isStepAdeded: false,
        blankStepId: undefined,
        currentStepIndex: -1,
        elementList: updatedElementList,
        isShowLoadScenarioModal: false,
        recordingSetId: action.recTestScenarioId,
        addNewStepStatus: true,
        recordingSetName: recTestScenarioName,
        previewResultElementList: [],
        previewRunStatus: undefined,
        elementTexts: elementTexts,
        editScenarioLoading: false,
        selectedPreviewGroup: undefined,
      };
    case LOADING_SCENARIO_FOR_EDIT:
      return {
        ...state,
        editScenarioLoading: true,
      };
    case SHOW_LOAD_SCENARIO_MODAL:
      return {
        ...state,
        isShowLoadScenarioModal: action.flag,
      };
    case SAVE_SCENARIO_RECORDING_REQUESTED:
      return {
        ...state,
        saveRecordingRequest: false,
      };
    case DEVICE_DISCONNECT:
      return {
        ...state,
        deviceDisconnectStatus: true,
      };

    case RESET_SCREEN:
      return {
        ...state,
        deviceDetail: undefined,
        deviceScreen: undefined,
        platformName: undefined,
        deviceScreenStreamUrl: undefined,
        recordingState: DEVICE_STATUS.INTERACTION,
        currentDeviceScreenSource: undefined,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
        currentDeviceScreenSourceXml: undefined,
        //logStarted:false,
        currentDevice: undefined,
        currentScreenOrientation: undefined,
        invalidUser: undefined,
        checkAndRestartNodeServiceCalled: false,
        startDeviceStreamingStatus: false,
      };
    case UPDATE_SWIPE_COORDINATES:
      let swipeStart = { x: action.xStart, y: action.yStart };
      let swipeEnd = action.xEnd && action.yEnd ? { x: action.xEnd, y: action.yEnd } : undefined;
      return {
        ...state,
        swipeStart,
        swipeEnd,
      };
    case TAP_ON_COORDINATES_REQUESTED:
      return {
        ...state,
        tapNotification: true,
        xCoordinate: action.xCoordinate,
        yCoordinate: action.yCoordinate,
      };
    case START_LOGS_ON_DEVICE:
      return {
        ...state,
        logStarted: true,
        shouldBlockNavigation: true,
        logModalVisible: false,
        logStartRequest: false,
      };
    case STOP_LOGS_ON_DEVICE:
      return {
        ...state,
        logStarted: false,
      };
    case FAILED_START_LOGS_ON_DEVICE:
      return {
        ...state,
        logModalVisible: false,
      };
    case FORCE_STOP_LOGS_ON_DEVICE:
      return {
        ...state,
        logStarted: false,
      };
    case CHANGE_ORIENTATION:
      let ChangeOrientation = undefined;
      ChangeOrientation = action.data.orientationType === "1" ? LANDSCAPE : PORTRAIT;
      return {
        ...state,
        orientationModalVisible: false,
        currentScreenOrientation: ChangeOrientation,
      };
    case FAILED_TO_ROTATE_SCREEN:
      return {
        ...state,
        orientationModalVisible: false,
        invalidUser: action.response.invalidUser ? action.response.invalidUser : null,
      };
    case HIDE_LOG_MODAL:
      return {
        ...state,
        logModalVisible: false,
      };
    case SHOW_LOG_MODAL:
      return {
        ...state,
        logModalVisible: true,
        logType: undefined,
      };

    case SHOW_INPUT_TEXT_MODAL:
      return {
        ...state,
        showInputText: true,
        isInputPassword: false,
      };

    case HIDE_INPUT_TEXT_MODAL:
      return {
        ...state,
        showInputText: false,
        currentInputText: "",
      };
    case UPDATE_INPUT_TEXT:
      return {
        ...state,
        currentInputText: action.value,
      };
    case LOG_START_REQUEST:
      return {
        ...state,
        logStartRequest: true,
      };
    case HIDE_ORIENTATION_MODAL:
      return {
        ...state,
        orientationModalVisible: false,
      };
    case SHOW_ORIENTATION_MODAL:
      return {
        ...state,
        orientationModalVisible: true,
        orientationType: undefined,
      };
    case SHOW_RESTART_DEVICE_MODAL:
      return {
        ...state,
        showRestartDeviceModalStatus: true,
      };
    case HIDE_RESTART_DEVICE_MODAL:
      return {
        ...state,
        showRestartDeviceModalStatus: false,
      };
    case ON_ORIENTATION_CHANGE:
      return {
        ...state,
        orientationType: action.orientationType,
      };
    case OPEN_RECENT_APP_TRAY:
      return {
        ...state,
        orientationType: action.orientationType,
      };
    case CURRENT_SCREEN_ORIENTATION:
      return {
        ...state,
        // imageStyle: action.response.style,
        currentScreenOrientation: action.response.orientationType,
      };
    case UPDATE_DEVICE_MODAL:
      orientation = undefined;
      if (action.device["deviceModel"].includes("Portal+")) {
        orientation = PORTRAIT;
      } else if (action.device["deviceModel"].includes("Portal")) {
        orientation = LANDSCAPE;
      } else {
        orientation = PORTRAIT;
      }

      return {
        ...state,
        currentDevice: action.device,
        currentScreenOrientation: orientation,
      };
    case FAST_INPUT_ICON_INDICATION:
      return {
        ...state,
        fastInputTextIndication: true,
      };
    case STOP_FAST_INPUT_INDICATION:
      return {
        ...state,
        fastInputTextIndication: false,
      };
    case DEVICE_STATE:
      return {
        ...state,
        isDeviceOffline: action.response.data["isDeviceOffline"] ? action.response.data["isDeviceOffline"] : null,
      };
    case PASSWORD_INPUT:
      return {
        ...state,
        isInputPassword: action.value,
      };
    case SHOW_USER_DETAILS:
      return {
        ...state,
        showUserDetailsMenu: true,
      };
    case ADD_NEW_SCENARIO:
      return {
        ...state,
        ...resetRecordingAttributes(state),
        recordingScreenStatus: RECORDING_SCREEN.SHOW_RECORDING,
      };
    case EDIT_SCENARIO:
      return {
        ...state,
        ...resetRecordingAttributes(state),
        recordingScreenStatus: RECORDING_SCREEN.SHOW_RECORDING,
      };
    case BACK_TO_SCENARIO_LIST:
      return {
        ...state,
        recordingScreenStatus: RECORDING_SCREEN.SHOW_SCENARIO_LIST,
        recordingState: DEVICE_STATUS.INTERACTION,
        deviceInteractionMode: DEVICE_INTERACTION_MODE.INTERACTIVE,
        isStepAdeded: false,
        blankStepId: undefined,
        currentStepIndex: -1,
        elementList: [],
        recordingSetId: 0,
        addNewStepStatus: false,
        recordingSetName: "",
        previewResultElementList: [],
        previewRunStatus: undefined,
        selectedGroup: undefined,
        previousSelectedPreviewGroup: undefined,
        actionOnElement: undefined,
        isStepForView: false,
        breadScrumItems: [],
        breadScrumPreviewItems: [],
      };
    case TABLE_EXECUTE_BUTTON_LOADER:
      return {
        ...state,
        previewButtonLoader: action.loaderId,
      };
    case TABLE_EXECUTE_BUTTON_LOADER_STOP:
      return {
        ...state,
        previewButtonLoader: "",
      };
    case LOAD_SELECTED_SCENARIO_FOR_EXECUTION:
      let scenarioName = action.recTestScenarioName;
      if (scenarioName === undefined && state.allScenarios && state.allScenarios.data) {
        let selectedScenario = state.allScenarios.data.filter((item) => item.id === action.recTestScenarioId);
        scenarioName = selectedScenario.length > 0 ? selectedScenario[0].name : undefined;
      }
      return {
        ...state,
        recordingState: DEVICE_STATUS.PREVIEW,
        elementList: action.response.data,
        executionSetId: action.recTestScenarioId,
        executionSetName: scenarioName,
        ExecutionResultElementList: [],
        executeRunStatus: undefined,
        selectedPreviewGroup: undefined,
      };
    case CLEAR_EXECUTION_DATA:
      return {
        ...state,
        ExecutionResultElementList: [],
        exectionRunId: undefined,
        executionLicenseKey: undefined,
        executionUserName: undefined,
        executionOutputFolder: undefined,
        isClearingExecution: true,
      };
    case CLEAR_EXECUTION_DATA_DONE:
      return {
        ...state,
        isClearingExecution: false,
        showResultTab: false,
      };
    case ADD_EXECUTION_REQUEST:
      return {
        ...state,
        preparingForExecution: true,
        recordingState: ADD_PREVIEW_REQUEST,
        executionButtonLoader: true,
      };
    case UPDATE_EXECUTION_REQUEST:
      let executeRunId = undefined;
      let executeLicenseKey = undefined;
      let executeOutputFolder = undefined;
      let executeUserName = undefined;
      if (action.response && action.response.status === SUCCESS_STATUS) {
        executeRunId = action.response.data.runId;
        executeLicenseKey = action.response.data.licenseKey;
        executeOutputFolder = action.response.data.outputFolder;
        executeUserName = action.response.data.userName;
      }
      return {
        ...state,
        executionRunId: executeRunId,
        executionLicenseKey: executeLicenseKey,
        executionOutputFolder: executeOutputFolder,
        executionUserName: executeUserName,
        preparingForExecution: false,
        recordingState: UPDATE_EXECUTION_REQUEST,
      };
    case FAILED_UPDATE_EXECUTION_REQUEST:
      return {
        ...state,
        recordingState: FAILED_UPDATE_EXECUTION_REQUEST,
        preparingForExecution: false,
        executionButtonLoader: false,
      };

    case STARTED_EXECUTION:
      // state.previewStepIdList.push({id:1,status:"pass",stepName:"1"});
      return {
        ...state,
        executionStepIdList: state.previewStepIdList,
        preparingForExecution: false,
        executionTempScriptFile: action.response.data,
        recordingState: DEVICE_STATUS.EXECUTION,
        isExecutionStarted: true,
        showResultTab: true,
        executionButtonLoader: false,
        selectedExecutionTab: EXECUTION_SCREEN_TAB.RESULT,
      };
    case STOP_EXECUTION_LOADER:
      return {
        ...state,
        isExecutionStarted: false,
      };
    case STOP_EXECUTION_REQUEST:
      state.executionStepIdList = [];
      return {
        ...state,
        executionStepIdList: state.executionStepIdList,
        showResultTab: undefined,
        stopProcessingExecute: true,
      };
    case STOPPED_EXECUTION:
      state.executionStepIdList = [];
      return {
        ...state,
        executionStepIdList: state.executionStepIdList,
        isExecutionStarted: false,
        showResultTab: undefined,
        stopProcessingExecute: false,
      };
    case START_EXECUTE_SCREEN:
      return {
        ...state,
        recordingScreenStatus: RECORDING_SCREEN.SHOW_EXECUTE_SCENARIO,
      };
    case UPDATE_EXECUTION_RESULT:
      let updatedExecuteElementList2 = [];
      if (action.response.data.length > 0 && action.response.status === SUCCESS_STATUS) {
        updatedExecuteElementList2 = state.elementList;
        if (action.response.data && action.response.data.length > 0) {
          addStatusForStepsFromResult(updatedExecuteElementList2, action.response.data);
          state.executionRunStatus = action.response.data[0]["runStatus"];
          if (action.response.data[0]["runStatus"] === "DONE") {
            state.executionRunId = undefined;
            state.executionLicenseKey = undefined;
            state.executionUserName = undefined;
            state.lastStepIdOfPreviewResult = 0;
          }
        }
      }
      return {
        ...state,
        ExecutionResultElementList: updatedExecuteElementList2,
        stepResult: action.response.data,
        executionRunStatus:
          action.response.data && action.response.data.length > 0 ? action.response.data[0]["runStatus"] : undefined,
      };
    case CHANGE_SELECTED_EXECUTION_PANEL_TAB:
      return {
        ...state,
        selectedExecutionTab: action.data.selectedKey,
      };
    case INSERT_CUSTOM_TEXT_VERIFICATION_ROW:
      customRefTextList = [...state.customRefTextList];
      // Reset Key value on insert
      customRefTextList.map((item, index) => {
        if (item.order > action.order) customRefTextList[index].order += 1;
      });
      customRefTextList.splice(action.order, 0, {
        order: action.order + 1,
        key: "Text",
        value: "",
      });
      //                   }
      return {
        ...state,
        customRefTextList,
      };
    case REMOVE_CUSTOM_TEXT_VERIFICATION_ROW:
      customRefTextList = [];

      if (state.customRefTextList.length == 1) {
        customRefTextList = customRefTextDefaultList();
      } else {
        customRefTextList = [...state.customRefTextList];
        customRefTextList.splice(action.order - 1, 1);
        customRefTextList.map((item, index) => {
          if (item.order > action.order) item.order -= 1;
        });
      }
      return {
        ...state,
        customRefTextList,
      };
    case UPDATE_CUSTOM_REF_TYPE_VALUE:
      customRefTextList = [];
      customRefTextListData = [...state.customRefTextList];
      customRefTextListData.map((item, index) => {
        if (item.order == action.order) {
          item.key = action.value;
          item.value = "";
        }
        customRefTextList.push(item);
      });
      return {
        ...state,
        customRefTextList,
      };
    case UPDATE_CUSTOM_REF_TEXT_VALUE:
      customRefTextList = [];
      customRefTextListData = [...state.customRefTextList];
      customRefTextListData.map((item, index) => {
        if (item.order == action.order) {
          item.value = action.value;
        }
        customRefTextList.push(item);
      });
      return {
        ...state,
        customRefTextList,
      };
    case UPDATE_CUSTOM_REF_OPTION_VALUE:
      customRefTextList = [];
      customRefTextListData = [...state.customRefTextList];
      customRefTextListData.map((item, index) => {
        if (item.key == action.key) {
          state.customRefTextList[index].value = action.value;
        }
        customRefTextList.push(item);
      });
      return {
        ...state,
        customRefTextList,
      };
    case RESET_CUSTOM_REF_LIST:
      return {
        ...state,
        customRefTextList: customRefTextDefaultList(),
      };
    case TOGGLE_ELEMENT_PROPERTIES_MODAL:
      return {
        ...state,
        toggleElementPropertiesModal: action.value,
      };
    case FLUSH_THE_RECORDING_DATA:
      return {
        ...state,
        ...resetRecordingAttributes(),
      };
    case SHOW_UNSAVED_STEP_MODAL_VISIBLE:
      return {
        ...state,
        unsavedStepModalVisible: true,
      };
    case ON_CANCEL_UNSAVED_STEP_MODAL_VISIBLE:
      return {
        ...state,
        unsavedStepModalVisible: false,
      };
    case REMOVE_BLANK_STEP:
      if (state.selectedGroup && state.selectedGroup.recTestSteps) {
        state.selectedGroup.recTestSteps = state.selectedGroup.recTestSteps.filter(
          (item) => item.tag != state.blankStepId
        );
      } else {
        state.elementList = state.elementList.filter((item) => item.tag != state.blankStepId);
      }

      return {
        ...state,
        unsavedStepModalVisible: false,
        isStepAdeded: false,
        addNewStepStatus: true,
        shouldBlockNavigation: true,
        blankStepId: undefined,
        toggleElementPropertiesModal: false,
        confirmStepProcessing: false,
      };
    case ALL_APP_BUILD_DETAILS:
      let currentPage = action.pageNumber;
      return {
        ...state,
        allAppBuildDetails: action.response.data,
        noAppsBuildFound: action.response.data.length == 0,
        appBuildListCurrentSelectedPage:
          currentPage > action.response.data.totalCount ? action.response.data.totalCount : currentPage,
        loadingAppManagerBuild: false,
      };
    case ALL_APP_BUILD_DETAILS_FAILED:
      return {
        ...state,
        noAppsBuildFound: true,
        loadingAppManagerBuild: false,
      };
    case SHOW_ADD_APP_BUILD_MENU:
      return {
        ...state,
        currentAppManagementScreen: APP_MANAGEMENT_SCREEN.ADD_APP_BUILD,
      };
    case CHANGE_SHARED_IN_ADD_BUILD:
      return {
        ...state,
        sharedWithFarmInAddAppBuild: action.value,
      };
    case BACK_TO_APP_MANAGEMENT:
      return {
        ...state,
        currentAppManagementScreen: APP_MANAGEMENT_SCREEN.APP_BUILDS_LISTS,
      };
    case SELECT_APP_BUILD_FARM_LIST_OPTION:
      return {
        ...state,
        selectedFarmListInAddAppBuildList: action.value,
      };
    case CHANGE_PLATFORM_TYPE_IN_ADD_BUILD:
      return {
        ...state,
        platformTypeInAddAppBuild: action.value,
      };
    case SET_ADD_APP_BUILD_DETAILS_FORM_ERROR:
      let errorField = action.field;
      state[errorField] = action.errorStatus;
      return {
        ...state,
      };
    case CHANGE_BUILD_VERSION_IN_ADD_BUILD:
      return {
        ...state,
        buildVersionInAddBuild: action.value,
      };
    case CHANGE_BUILD_DATE_TIME_IN_ADD_BUILD:
      return {
        ...state,
        buildDateTimeInAddBuild: action.value,
      };
    case CHANGE_BUILD_TYPE_IN_ADD_BUILD:
      return {
        ...state,
        buildTypeRadioInAddBuild: action.value,
      };
    case UPLOAD_BUILD_REQUEST:
      return {
        ...state,
        buildUploadRequest: true,
      };
    case UPLOAD_AND_SAVE_BUILD_REQUEST:
      return {
        ...state,
        uploadAndSaveBuildRequest: true,
      };
    case UPLOAD_BUILD_REQUEST_COMPLETE:
      return {
        ...state,
        buildUploadRequest: false,
        uploadAndSaveBuildRequest: false,
        currentAppManagementScreen: APP_MANAGEMENT_SCREEN.APP_BUILDS_LISTS,
      };
    case UPLOAD_BUILD_REQUEST_FAILED:
      return {
        ...state,
        buildUploadRequest: false,
        uploadAndSaveBuildRequest: false,
      };
    case SELECT_THE_BUILD_TO_INSTALL:
      return {
        ...state,
        selectedAppBuild: action.value,
      };
    case SHOW_INSTALL_APP_BUILD_MODAL:
      return {
        ...state,
        showInstallAppBuildModal: true,
      };
    case HIDE_INSTALL_APP_MODAL:
      return {
        ...state,
        showInstallAppBuildModal: false,
      };
    case CHANGE_APP_BUILD_LIST_PAGE_NUMBER:
      return {
        ...state,
        appBuildListCurrentSelectedPage: action.value,
      };

    case APP_BUILD_DETAILS_BY_ID:
      return {
        ...state,
        appBuildDetailsById: action?.appBuildDataByID,
      };

    case RESET_APP_BUILD_DETAILS_BY_ID:
      return {
        ...state,
        appBuildDetailsById: null,
      };

    case CHANGE_INSTALL_PAGE_APP_BUILD_LIST_PAGE_NUMBER:
      return {
        ...state,
        installAppCurrentSelectedPage: action.value,
      };
    case ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE:
      return {
        ...state,
        allAppBuildDetailsInInstallApp: action.response.data,
        noAppsBuildFoundForFarm: action.response.data.length == 0,
        installAppCurrentSelectedPage: action.pageNumber,
        loadingInstallAppBuildScreen: false,
      };
    case ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_FAILED:
      return {
        ...state,
        noAppsBuildFoundForFarm: true,
        loadingInstallAppBuildScreen: false,
      };
    case INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST:
      return {
        ...state,
        installAppFromSftpRequest: true,
      };
    case INSTALL_APP_IN_DEVICE_FROM_SFTP_REQUEST_FAILED:
      return {
        ...state,
        installAppFromSftpRequest: false,
        percentageDownload: undefined,
        selectedAppBuild: "",
      };
    case INSTALLED_APP_IN_DEVICE_FROM_SFTP:
      return {
        ...state,
        installAppFromSftpRequest: false,
        percentageDownload: undefined,
        selectedAppBuild: "",
      };
    case ADDING_BUILD_ALREADY_EXIST:
      return {
        ...state,
        existingBuildDetails: action.response.data,
      };
    case OVERWRITE_UPLOAD_BUILD_REQUEST:
      return {
        ...state,
        overwriteBuildSaveRequest: true,
      };
    case OVERWRITE_UPLOAD_BUILD_REQUEST_COMPLETE:
      return {
        ...state,
        overwriteBuildSaveRequest: false,
        currentAppManagementScreen: APP_MANAGEMENT_SCREEN.APP_BUILDS_LISTS,
      };
    case OVERWRITE_UPLOAD_BUILD_REQUEST_FAILED:
      return {
        ...state,
        overwriteBuildSaveRequest: false,
      };
    case CHANGE_SEARCH_TEXT_FOR_SEARCH_QUERY_INSTALL_APP:
      return {
        ...state,
        searchQueryTextInputInstallPage: action.value,
        selectedAppBuild: "",
      };
    case SEARCH_QUERY_RESULT_APP_DETAILS:
      let noAppsBuildFoundForSearchQuery = undefined;
      if (action.response.data.length == 0) {
        noAppsBuildFoundForSearchQuery = true;
      } else {
        noAppsBuildFoundForSearchQuery = false;
      }
      return {
        ...state,
        allAppBuildDetails: action.response.data,
        noAppsBuildFound: noAppsBuildFoundForSearchQuery,
      };

    case GET_UPLOAD_BUILD_OPTIONS:
      return {
        ...state,
        getUploadBuildOptions: action.response?.data,
      };

    case GET_AWS_PROJECTS_LIST:
      return {
        ...state,
        getAWSProjectsList: action.response?.data,
      };

    case SEARCH_QUERY_APP_DETAILS_REQUEST:
      return {
        ...state,
        searchQueryRequest: true,
      };
    case SEARCH_QUERY_APP_DETAILS_COMPLETED:
      return {
        ...state,
        searchQueryRequest: false,
      };
    case SEARCH_QUERY_APP_DETAILS_FAILED:
      return {
        ...state,
        searchQueryRequest: false,
      };
    case UPLOAD_BUILD_PROGRESS:
      return {
        ...state,
        percentageUpload: action.percentage,
      };
    case DOWNLOAD_BUILD_PROGRESS:
      return {
        ...state,
        percentageDownload: action.percentage,
        // installAppFromSftpRequest:false,
        showInstallAppBuildModal: false,
      };
    case DEVICE_SESSION_LOGS_STREAM:
      return {
        ...state,
        logsForDeviceSession: action.eventParseResponse["data"],
      };
    case DEVICE_SESSION_LOGS_STREAM_REQUEST:
      return {
        ...state,
        deviceSessionLogRequest: true,
        logsForDeviceSession: [],
        deviceSessionLogType: DEVICE_SESSION_LOGTYPE.VERBOSE,
      };
    case DEVICE_SESSION_LOGS_STREAM_FAILED:
      return {
        ...state,
        deviceSessionLogRequest: false,
      };
    case DEVICE_SESSION_LOGS_STREAM_STARTED:
      return {
        ...state,
        deviceSessionLogRequest: false,
        deviceSessionLogStarted: true,
        ableToDownloadLogs: false,
        logStarted: true,
      };
    case DEVICE_SESSION_LOGS_STREAM_STOPPED:
      return {
        ...state,
        deviceSessionLogStarted: false,
        deviceSessionLogStopRequest: false,
        ableToDownloadLogs: true,
        logStarted: false,
      };
    case DEVICE_SESSION_LOGS_STREAM_STOPPING_REQUEST:
      return {
        ...state,
        deviceSessionLogStopRequest: true,
      };
    case DEVICE_SESSION_LOGS_STREAM_STOPPING_FAILED:
      return {
        ...state,
        deviceSessionLogStopRequest: false,
      };
    case CHANGE_DEVICE_SESSION_LOGTYPE:
      return {
        ...state,
        deviceSessionLogType: action.value,
      };
    case CLEAR_THE_DEVICE_SESSION_LOGS:
      return {
        ...state,
        logsForDeviceSession: [],
        deviceSessionLogType: DEVICE_SESSION_LOGTYPE.VERBOSE,
      };
    case LIST_OFF_APPS_INSTALLED_FOR_LOGS:
      return {
        ...state,
        appsListForDeviceLogs: action.response["data"],
      };
    case CHANGE_APP_FOR_DEVICE_LOGS:
      return {
        ...state,
        selectedAppForDeviceLogs: action.value,
      };
    case FLUSH_THE_DASHBOARD_ATTRIBUTE:
      resetDashboardAttribute(state);
      return {
        ...state,
      };
    case UPDATE_CLIPBOARD_DATA:
      return {
        ...state,
        clipboardData: action.value,
      };
    case ALL_BROWSER_INSTALLED_IN_DEVICE:
      return {
        ...state,
        installedBrowserDetails: action.response.data,
      };
    case ERROR_ALL_BROWSER_INSTALLED_IN_DEVICE:
      return {
        ...state,
        installedBrowserErrorMessage: action.response.message,
      };
    case CHANGE_URL_FOR_DEVICE_BROWSER_NAVIGATION:
      return {
        ...state,
        urlNeedToBrowse: action.value,
      };
    case PROXY_STARTED:
      return {
        ...state,
        proxyStatus: PROXY_STATUS.STARTED,
      };
    case PROXY_STOPPED:
      return {
        ...state,
        proxyStatus: PROXY_STATUS.STOPPED,
      };
    case PROXY_CLEAR_DATA:
      return {
        ...state,
        flowRows: [],
      };
    case PROXY_START_REQUEST:
      return {
        ...state,
        proxyStatus: PROXY_STATUS.START_REQUEST,
      };
    case PROXY_STOP_REQUEST:
      return {
        ...state,
        flowRows: [],
        proxyStatus: PROXY_STATUS.STOP_REQUEST,
      };
    case GET_PROXY_STREAMING_DATA:
      let flowRows = state.flowRows;
      let data = action.flow.data;
      if (action.flow.cmd == "update" && flowRows.length > 0) {
        flowRows.map((v, k) => {
          if (v.id == data.id) {
            // v['id'] = data.id;
            v["tlsColumn"] = util.TLSColumn(data);
            v["iconColumn"] = util.IconColumn(data);
            // v['path']= util.prityURL(data.request);
            // v['method']= util.MethodColumn(data);
            v["methodStatus"] = util.methodStatus(data);
            v["statusCode"] = util.StatusCodeColumn(data);
            v["statusColor"] = util.StatusCodeColorColumn(data);
            v["size"] = util.SizeColumn(data);
            v["time"] = util.TimeColumn(data);
          }
        });
      } else {
        let flowRow = {
          id: data.id,
          tlsColumn: util.TLSColumn(data),
          iconColumn: util.IconColumn(data),
          path: util.prityURL(data.request),
          method: util.MethodColumn(data),
          methodStatus: util.methodStatus(data),
          statusCode: util.StatusCodeColumn(data),
          statusColor: util.StatusCodeColorColumn(data),
          size: util.SizeColumn(data),
          time: util.TimeColumn(data),
        };
        flowRows.push(flowRow);
      }

      return {
        ...state,
        flowRows,
      };
    case SET_SPLITTER_DRAGGED:
      return {
        ...state,
        splitterDraggedSize: action.splitterSize,
      };
    case DISABLE_KEYBOARD_IN_DEVICE:
      return {
        ...state,
        keyboardEnable: false,
      };
    case DEVICE_USAGE_REPORT_DATA:
      return {
        ...state,
        deviceUsageReportData: action.response.data,
        searchDeviceReportRequest: false,
        loadingDeviceUsageReport: false,
      };
    case SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST:
      return {
        ...state,
        searchDeviceReportRequest: true,
      };
    case SEARCH_DEVICE_USAGE_REPORT_FROM_KEYWORD_REQUEST_FAILED:
      return {
        ...state,
        searchDeviceReportRequest: false,
      };
    case DEVICE_USAGE_REPORT_DATA_REQUEST:
      return {
        ...state,
        loadingDeviceUsageReport: true,
      };
    case DEVICE_USAGE_REPORT_DATA_REQUEST_FAILED:
      return {
        ...state,
        loadingDeviceUsageReport: false,
      };
    case GET_APP_MANAGER_BUILD_DETAILS_REQUEST:
      return {
        ...state,
        loadingAppManagerBuild: true,
      };
    case ALL_APP_BUILD_DETAILS_FOR_FARM_AND_DEVICE_REQUEST:
      return {
        ...state,
        loadingInstallAppBuildScreen: true,
      };
    case SET_SELECTED_GROUP:
      state.breadScrumItems = util.insertBreadScrumItems(state.breadScrumItems, state, action.data);
      return {
        ...state,
        selectedGroup: action.data,
        isStepForView: false,
      };
    case SET_CURRENT_GROUP_ON_BREAD_CRUMB_CLICK:
      if (!util.checkIsLastBreadScrumItems(state.breadScrumItems, state, action.data.data.tag)) {
        state.breadScrumItems = util.updateBreadScrumItems(state.breadScrumItems, state, action.data);
        selectedGroup = findGroupChildElementByTagId(state.elementList, action.data.data.tag, undefined);
        //Replace Path
        util.updatePathOnBreadCrumsChange(action.data.data.tag);
      } else {
        selectedGroup = state.selectedGroup;
      }
      return {
        ...state,
        selectedGroup: selectedGroup,
        actionOnElement: undefined,
        isStepForView: false,
      };
    // case SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK:
    //      if(!util.checkIsLastBreadScrumItems(state.breadScrumPreviewItems, state,action.data.data.tag))
    //         {
    //         state.breadScrumPreviewItems = util.updateBreadScrumItems(state.breadScrumPreviewItems, state,action.data);
    //         state.selectedPreviewGroup = findGroupChildElementByTagId(state.previewResultElementList,action.data.data.tag,undefined);
    //     }else{
    //         state.selectedPreviewGroup = state.previewResultElementList;
    //     }
    //     addStatusForStepsFromResult(state.selectedPreviewGroup,state.stepResult)
    //     return {
    //         ...state,
    //         selectedPreviewGroup: state.selectedPreviewGroup,
    //         previousSelectedPreviewGroup: state.previousSelectedPreviewGroup
    //     }
    case SET_SELECTED_PREVIEW_GROUP:
      state.breadScrumPreviewItems = util.insertBreadScrumItems(state.breadScrumPreviewItems, state, action.data);
      addStatusForStepsFromResult(action.data.recTestSteps, state.stepResult);
      return {
        ...state,
        selectedPreviewGroup: action.data,
        previousSelectedPreviewGroup: state.previousSelectedPreviewGroup,
      };
    case SET_CURRENT_PREVIEW_GROUP_ON_BREAD_CRUMB_CLICK:
      if (!util.checkIsLastBreadScrumItems(state.breadScrumPreviewItems, state, action.data.data.tag)) {
        state.breadScrumPreviewItems = util.updateBreadScrumItems(state.breadScrumPreviewItems, state, action.data);
        state.selectedPreviewGroup = findGroupChildElementByTagId(state.elementList, action.data.data.tag, undefined);
        //Replace Path
        util.updatePathOnBreadCrumsChange(action.data.data.tag);
      }
      addStatusForStepsFromResult(state.selectedPreviewGroup, state.stepResult);
      return {
        ...state,
        selectedPreviewGroup: state.selectedPreviewGroup,
        previousSelectedPreviewGroup: state.previousSelectedPreviewGroup,
      };
    case LOAD_RECORDING_SCREEN_STATUS:
      return {
        ...state,
        recordingScreenStatus: action.recordingScreenStatus,
      };
    case BACK_PREVIOUS_STEP:
      selectedGroup = findParentElement(state.elementList, action.childId, undefined);
      let breadScrumUpdatedItems = util.updateBreadScrumItems(state.breadScrumItems, state, action.data);

      return {
        ...state,
        selectedGroup,
        isStepForView: false,
        breadScrumItems: breadScrumUpdatedItems,
      };

    case BACK_PREVIOUS_PREVIEW_STEP:
      let selectedPreviewGroup = findParentElement(state.previewResultElementList, action.childId, undefined);
      let breadScrumUpdatedPreviewItems = util.updateBreadScrumItems(state.breadScrumPreviewItems, state, action.data);
      addStatusForStepsFromResult(selectedPreviewGroup, state.stepResult);
      return {
        ...state,
        selectedPreviewGroup,
        breadScrumPreviewItems: breadScrumUpdatedPreviewItems,
      };
    case SET_CURRENT_RECORDING_GROUP_FROM_URL:
      let currentTag = util.getCurrentStepTagFromHashMap();
      let breadScrumItems = util.prepareBreadScrumOnRefesh(state.breadScrumItems, state, state.elementList);
      let currentRecordingGroup = undefined;
      if (currentTag != "") currentRecordingGroup = findGroupChildElementByTagId(state.elementList, currentTag);
      else currentRecordingGroup = state.elementList;

      return {
        ...state,
        selectedGroup: currentRecordingGroup,
        breadScrumItems,
      };
    case SET_CURRENT_EXECUTE_GROUP_FROM_URL:
      currentTag = util.getCurrentStepTagFromHashMap();
      breadScrumItems = util.prepareBreadScrumOnRefesh(state.breadScrumItems, state, state.elementList);
      let currentExecuteGroup = undefined;
      if (currentTag != "") {
        currentExecuteGroup = findGroupChildElementByTagId(state.elementList, currentTag);
        return {
          ...state,
          selectedGroup: currentExecuteGroup,
        };
      } else {
        return {
          ...state,
          elementList: state.elementList,
        };
      }
    case CHANGE_SHELL_COMMAND_FOR_DEVICE:
      return {
        ...state,
        shellCommandForDevice: action.value,
      };
    case SAVE_SHELL_COMMAND_OUTPUT:
      return {
        ...state,
        shellCommandResult: action.data,
      };
    case RESET_SHELL_COMMAND_OUTPUT:
      return {
        ...state,
        shellCommandResult: [],
        shellCommandForDevice: "",
      };
    case UPDATE_DEVICE_STATE:
      return {
        ...state,
        recordingState: action.state,
      };
    case CHANGE_DEVICE_TAB_ACTION_KEY:
      return {
        ...state,
        selectedDeviceTabActionKey: action.deviceTabActionKey,
      };
    case TOGGLE_OTHER_ELEMENT_POPOVER:
      return {
        ...state,
        otherElementCoordinateVisible: action.visible,
      };
    case SHOW_INSTALL_BUILD_ON_DEVICE:
      return {
        ...state,
        installBuildOnDeviceModalVisible: true,
        selectedBuildDataToInstall: action.buildData,
      };
    case HIDE_INSTALL_BUILD_ON_DEVICE:
      return {
        ...state,
        installBuildOnDeviceModalVisible: false,
        selectedBuildDataToInstall: undefined,
      };
    case UNINSTALL_BUILD_BEFORE_INSTALL:
      return {
        ...state,
        unInstallBUildBeforeInstall: action?.value,
      };
    case REQUEST_INSTALL_BUILD_ON_DEVICE:
      return {
        ...state,
        requestInstallBuildOnMultipleDevice: true,
      };
    case REQUESTED_INSTALL_BUILD_ON_DEVICE:
      return {
        ...state,
        requestInstallBuildOnMultipleDevice: false,
        selectedBuildDataToInstall: undefined,
        installBuildOnDeviceModalVisible: false,
        installBuildDeviceDetails: [],
      };
    case CHANGE_DEVICE_SELECTION_FOR_BUILD_INSTALL:
      return {
        ...state,
        installBuildDeviceDetails: action.devices,
      };

    case VISIBLE_RECORDING_SETTINGS_MODAL:
      return {
        ...state,
        visibleRecordingSettingModal: action?.visible,
      };
    
    case EDIT_APP_BUILD:
      return{
        ...state,
        unSavedAppBuildChanges:action.value
      }
      
    default:
      return state;
  }
}
function updateChildElementList(elementList, childElementList, parentId) {
  for (let i = 0; i < elementList.length; i++) {
    if (elementList[i].tag == parentId) {
      elementList[i].recTestSteps = childElementList;
      break;
    } else if (elementList[i].recTestSteps && elementList[i].recTestSteps.length > 0) {
      updateChildElementList(elementList[i].recTestSteps, childElementList, parentId);
    }
  }
}

function findParentElement(elementList, childId, parentObj) {
  let data = undefined;
  for (let i = 0; i < elementList.length; i++) {
    if (elementList[i].tag == childId) {
      data = parentObj;
      break;
    } else if (elementList[i].recTestSteps && elementList[i].recTestSteps.length > 0) {
      data = findParentElement(elementList[i].recTestSteps, childId, elementList[i]);
      break;
    }
  }
  return data;
}

function findGroupChildElementByTagId(elementList, groupTagId) {
  let data = undefined;
  for (let i = 0; i < elementList.length; i++) {
    if (elementList[i].tag == groupTagId) {
      data = elementList[i];
      break;
    } else if (elementList[i].recTestSteps && elementList[i].recTestSteps.length > 0) {
      data = findGroupChildElementByTagId(elementList[i].recTestSteps, groupTagId);
      if (data != undefined) {
        break;
      }
    }
  }
  return data;
}

function findElementByPath(path, source) {
  let selectedElement = source;
  if (selectedElement !== undefined) {
    for (let index of path?.split(".")) {
      selectedElement = selectedElement?.children?.length > 0 ? selectedElement?.children[index] : undefined;
    }
  }
  return selectedElement ? { ...selectedElement } : undefined;
}

function findAndroidElementByBounds(bounds, source) {
  let selectedElement = undefined;
  if (source.attributes && source.attributes["bounds"] === bounds) {
    selectedElement = source;
  } else if (source.children && source.children.length > 0) {
    for (let index = 0; index < source.children.length; index++) {
      selectedElement = findAndroidElementByBounds(bounds, source.children[index]);
      if (Object.keys(selectedElement).length > 0) {
        break;
      }
    }
  }
  return { ...selectedElement };
}
function getIosElementBound(source) {
  return (
    "[" +
    parseInt(source.attributes["x"]) +
    "," +
    parseInt(source.attributes["y"]) +
    "][" +
    (parseInt(source.attributes["x"]) + parseInt(source.attributes["width"])) +
    "," +
    (parseInt(source.attributes["y"]) + parseInt(source.attributes["height"])) +
    "]"
  );
}

function findIosElementByBounds(bounds, source) {
  let selectedElement = undefined;
  if (source.attributes && getIosElementBound(source) === bounds) {
    selectedElement = source;
  } else if (source.children && source.children.length > 0) {
    for (let index = 0; index < source.children.length; index++) {
      selectedElement = findIosElementByBounds(bounds, source.children[index]);
      if (Object.keys(selectedElement).length > 0) {
        break;
      }
    }
  }
  return { ...selectedElement };
}

function resetState(state, currentAction) {
  return {
    currentStepName: undefined,
    suggestedStepName: undefined,
    actionOnElement: currentAction == undefined ? VERIFICATIONS : currentAction,
    selectedAction: undefined,
    tapByIndexValue: "",
    enterTextValue: undefined,
    appendRandomText: false,
    testDataModalVisible: false,
    continueAfterFail: false,
    selectedElementScreenShot: "",
    swipeUpPercentage: MINIMUM_SWIPE_VALUE,
    swipeRightPercentage: MINIMUM_SWIPE_VALUE,
    swipeDownPercentage: MINIMUM_SWIPE_VALUE,
    swipeLeftPercentage: MINIMUM_SWIPE_VALUE,
    swipeDirection: undefined,
    //old Verification
    verificationSelectedValues: [],
    currentStepVerificationList: blankVerificationItem(),
    selectedElementSize: undefined,
    selectedElementLocation: undefined,
    currentSelectedVerificationDropdownValue: undefined,
    currentSelectedVerificationValue: undefined,
    currentSelectedAbsenceValue: undefined,
    newVerifcationVisible: false,
    elementPropertiesModalVisible: false,
    searchedForElementBounds: null,
    selectedElement: null,
    selectedElementsDetailList: [],
    selectedElementId: "",
    expandedPaths: ["0"],
    selectedElementPath: null,
    hoveredElement: undefined,
    keyboardEnable: false,
    invalidUser: undefined,
    takeStepScreenShot: false,
    isStepForView: false,
    testDataVariableDefaultValue: undefined,
    testDataVariableName: undefined,
    testDataAppendRandomText: false,
    selectedAppLaunch: undefined,
    shellCommandForDevice: "",
    shellCommandResult: [],
    stepResult: [],
  };
}

function resetRecordingAttributes(state) {
  return {
    ...state,
    elementTexts: [],
    currentStepVerificationList: blankVerificationItem(),
    selectedBuildTestSceanrioTab: DEVICE_STATUS.RECORDING,
    recordingState: DEVICE_STATUS.INTERACTION,
    previousDeviceStatus: null,
    elementList: [],
  };
}

function resetDashboardAttribute(state) {
  state.logsForDeviceSession = [];
  state.ableToDownloadLogs = false;
  state.selectedAppForDeviceLogs = "ALL";
  state.appsListForDeviceLogs = [];
  state.deviceSessionLogType = DEVICE_SESSION_LOGTYPE.VERBOSE;
}

// ############Recursion code for iterating Save Result in Test Steps Data##############
// function updateStepStatus(testSteps,data){
//     return testSteps.map((item)=>{
//             data && data.map((v,k)=>{
//             if(item.recTestSteps && item.recTestSteps.length>0){
//                 if(item.tag===v.stepTagId){
//                     item['status']=v.stepStatus
//                     updateGroupStepStatus(item.recTestSteps,data)
//                 }
//             }else if(item.tag===v.stepTagId) {
//                 item['status']=v.stepStatus
//             }
//         })
//     })
// }
// function updateGroupStepStatus(testSteps,data){
//     return testSteps.map((item)=>{
//             data && data.map((v,k)=>{
//             if(item.recTestSteps && item.recTestSteps.length>0){
//                 if(item.tag===v.stepTagId){
//                     item['status']=v.stepStatus
//                     updateGroupStepStatus(item.recTestSteps,data)
//                 }
//             }else if(item.tag===v.stepTagId) {
//                 item['status']=v.stepStatus
//             }
//         })
//     });
// }

function addStatusForStepsFromResult(steps, result) {
  return (
    steps &&
    steps.length > 0 &&
    steps.map((item) => {
      result &&
        result.map((v, k) => {
          if (item.tag === v.stepTagId) {
            item["status"] = v.stepStatus;
          }
        });
    })
  );
}

function resetStatusForSteps(steps) {
  steps &&
    steps.length > 0 &&
    steps.map((v, k) => {
      if (v.recTestStepProperty?.action.toUpperCase() == STEP) {
        v["status"] = undefined;
        resetStatusForSteps(v.recTestSteps);
      } else {
        v["status"] = undefined;
      }
    });
}

function updateElementListWithUnsavedStep(elementList, unSavedStep, groupId) {
  if (elementList) {
    if (groupId) {
      for (let i = 0; i < elementList.length; i++) {
        if (elementList[i].recTestStepProperty?.action.toUpperCase() == STEP) {
          if (groupId == elementList[i].id) {
            if (elementList[i].recTestSteps.filter((item) => item.tag == unSavedStep.tag).length == 0) {
              elementList[i].recTestSteps.splice(unSavedStep.orderId, 0, unSavedStep);
              // elementList[i].recTestSteps.push(unSavedStep);
              break;
            }
          } else {
            elementList[i].recTestSteps = updateElementListWithUnsavedStep(
              elementList[i].recTestSteps,
              unSavedStep,
              groupId
            );
          }
        }
      }
    } else if (elementList.filter((item) => item.tag == unSavedStep.tag).length == 0) {
      elementList.splice(unSavedStep.orderId, 0, unSavedStep);
      // elementList.push(unSavedStep)
    }
  }
  return elementList;
}
