import { library } from "@fortawesome/fontawesome-svg-core";
import { faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Dropdown, Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { addVerificationRow } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { showTextVerification, isArrayNotUndefinedNotNullNotEmpty } from "../../../../Util";
library.add(faTrash);

const StyleDropDown = styled(Dropdown)`
  cursor: ${(props) => (props.disabled ? "not-allowed!important" : "pointer")};
`;
export default (props) => {
  const { selectedElement } = useSelector((state) => state.RecordingReducer);
  const dispatch = useDispatch();
  let elementSelected =
    showTextVerification(selectedElement) ||
    isArrayNotUndefinedNotNullNotEmpty(props.recTestStep.recTestStepProperty?.recElements);
  const menu = (
    <Menu>
      {!props.disabled && elementSelected && (
        <Menu.Item
          key="VerifyElementProperty"
          onClick={() =>
            dispatch(
              addVerificationRow("VerifyElementProperty", props.uuid, props.recTestStep.tag, props.recElement?.guid)
            )
          }>
          <div>Verify Element Property</div>
        </Menu.Item>
      )}
      {!props.disabled && elementSelected && (
        <Menu.Item
          key="VerifyElementText"
          onClick={() =>
            dispatch(addVerificationRow("VerifyElementText", props.uuid, props.recTestStep.tag, props.recElement?.guid))
          }>
          <div>Verify Element Text</div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Col className="add-verification-group-icon">
      <StyleDropDown overlay={menu} disabled={props.disabled}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </StyleDropDown>
    </Col>
  );
};
