import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Space, Spin } from "antd";
import styled from "styled-components";
import {
  selectAIStepTestData,
  selectOutputTestData,
  selectOutputTestDataForRecElement,
  selectTestData,
  updateReferenceTestDataForVerification,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

import { loadEditTestDataDetail, visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import { AI_TARGET_ELEMENT, splitValue, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../Constants";

import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";

const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #636467;
`;

const StyledDiv = styled.span`
  overflow-wrap: anywhere;
`;

const TestData = (props) => {
  const dispatch = useDispatch();
  const { isSkipStep, data, setOpenAddTestDataModal } = props;

  const { testDataList, fetchingTestDataList, stepType } = useSelector((props) => props.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer, guidForOpenDrawer, verificationDataForTestDataDrawer } =
    useSelector((props) => props.TestDataReducer);

  const [truncated, setTruncated] = useState(false);
  let verifyElementPropertyValue =
    data?.recTestStepProperty?.recStepVerifyElementProperties &&
    data?.recTestStepProperty?.recStepVerifyElementProperties.length > 0
      ? data?.recTestStepProperty?.recStepVerifyElementProperties[0]
      : [];

  const handleSelectTestData = (v) => {
    const setTestData = {
      OPERATION: selectTestData(tagForOpenDrawer, v?.id, v?.value),
      ELEMENTS: selectOutputTestDataForRecElement(
        tagForOpenDrawer,
        guidForOpenDrawer,
        v ? v?.id + splitValue + v?.name + splitValue + v?.value : undefined
      ),
      POST_OPERATION_SETTINGS: selectOutputTestData(tagForOpenDrawer, v?.id),
      VERIFICATION_FOR_TEST_DATA: updateReferenceTestDataForVerification(
        verificationDataForTestDataDrawer?.key,
        verificationDataForTestDataDrawer?.uuid,
        tagForOpenDrawer,
        v,
        guidForOpenDrawer
      ),
      VERIFY_TEST_DATA: updateVerificationKey(
        v?.id,
        verificationDataForTestDataDrawer?.uuid,
        tagForOpenDrawer,
        guidForOpenDrawer,
        testDataList,
        verifyElementPropertyValue?.value
      ),
      AI_TARGET_ELEMENT: selectAIStepTestData(
        tagForOpenDrawer,
        guidForOpenDrawer,
        v ? v?.id + splitValue + v?.name + splitValue + v?.value : undefined
      ),
    };
    return dispatch(setTestData[openTestDataSetDrawerByTabName]);
  };

  const columns = [
    {
      title: "Key",
      dataIndex: "key",
    },
    {
      title: "Value",
      dataIndex: "value",
    },
    {
      title: "Used in Scenarios",
      dataIndex: "usedInScenario",
    },
    {
      title: "Used in Test Blocks",
      dataIndex: "usedInSteps",
    },
    {
      title: "Actions",
      dataIndex: "actions",
    },
  ];

  const fetchTestDataList = () => {
    let data = [];
    if (testDataList) {
      //
      testDataList.forEach((row) => {
        if (stepType === TEST_STEP_BLOCKS || (stepType === TEST_SCENARIOS && row.scope !== "Test Block")) {
          data.push({
            key: (
              <>
                <StyledDiv>
                  <div className="stepText font-size-14">{row.name}</div>
                </StyledDiv>
                <div>
                  <Label>Scope:</Label>
                  <span className="labelDevice">{row.scope}</span>
                </div>
              </>
            ),
            value: (
              <ShowTooltipV2
                text={row.value || "-"}
                className={"text word-wrap-break mr-15 max-width-163 mb-0"}
              />
            ),
            actions:
              !isSkipStep &&
              (stepType === TEST_STEP_BLOCKS || (stepType === TEST_SCENARIOS && row.scope !== "Test Block")) ? (
                <Space size={10}>
                  <span
                    className={"blueFont cursor-pointer"}
                    onClick={(e) => {
                      e.preventDefault();
                      handleSelectTestData(row);
                      dispatch(visibleTestDataDrawer());
                    }}
                  >
                    Select
                  </span>
                  <span
                    className="blueFont cursor-pointer"
                    onClick={() => {
                      dispatch(loadEditTestDataDetail(row.id));
                      setOpenAddTestDataModal(true);
                    }}
                  >
                    Edit
                  </span>
                </Space>
              ) : (
                ""
              ),
            usedInScenario: (
              <div>
                <Label>Used In Scenario:</Label>
                <span className="labelDevice">{row.usedInScenarios}</span>
              </div>
            ),
            usedInSteps: (
              <div>
                <Label>Used In Steps:</Label>
                <span className="labelDevice">{row.usedInSteps}</span>
              </div>
            ),
          });
        }
      });
    }
    return data;
  };
  return (
    <>
      <Spin spinning={fetchingTestDataList}>
        <div className="mt-10">
          <TableComponent columns={columns} data={fetchTestDataList()} />
        </div>
      </Spin>
    </>
  );
};

export default TestData;
