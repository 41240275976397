import { Input, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeEnterText, selectTestData } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { ELEMENT_ACTION, OPERATION, TOOLTIP_BG_COLOR, splitValue } from "../../../../../Constants";
import AppendRandomText from "./AppendRandomText";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import ManageTestData from "../../../SingleColumnView/ManageTestData";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import SvgLoader from "../../../../../Util/SvgLoader";
import { DISABLE_ICON_COLOR, SELECT_TEST_DATA_ICON, WHITE_COLOR } from "../../../../../Constants/SvgConstants";
import styles from "./EnterText.module.scss";

export const StyleAddNewTestDataIcon = styled.div`
  color: black;
  font-size: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -4px;
  text-align: right;
  line-height: 2;
`;
export const StyledSelectedTestDataValueInput = styled(Input)`
  width: 75%;
  margin-left: 10px;
`;
export const StyledInsertTextOuterText = styled.div`
  background: #dcdcdc;
  width: 150px;
  padding: 8px;
`;

const EnterText = (props) => {
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const { isForView, data, isInputDisabled, showTestDataContent, testDataChecked, disabled } = props;
  const { action, userInputValue } = data.recTestStepProperty;

  return (
    <div className={styles.enterText}>
      {!!testDataChecked ? (
        <div className={styles.inputTestDataOrText}>
          <div className={styles.insertTestDataText}>Test Data</div>
          <Select
            className={styles.selectTestData}
            disabled={isForView || isInputDisabled || disabled}
            showSearch
            addonBefore="App Package Name"
            placeholder="Select a Test Data Key"
            value={
              data.recTestStepProperty?.testData
                ? data.recTestStepProperty?.testData.id + splitValue + data.recTestStepProperty?.testData.value
                : undefined
            }
            onChange={(value) =>
              dispatch(selectTestData(data.tag, value.split(splitValue)[0], value.split(splitValue)[1]))
            }
          >
            {testDataList &&
              testDataList.length > 0 &&
              testDataList.map((v, k) => {
                return (
                  <Select.Option key={v.id + splitValue + v.value} value={v.id + splitValue + v.value}>
                    {v.name + (v.value ? " (" + v.value + ")" : "")}
                  </Select.Option>
                );
              })}
          </Select>
          <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
            <div
              className={`${styles.selectTestDataButton} ${disabled && styles.disableSelectTestData}`}
              onClick={() => {
                if (!props.disabled) {
                  dispatch(visibleTestDataDrawer(OPERATION, data?.tag));
                  dispatch(selectRecTestSteps(data));
                }
              }}
            >
              <SvgLoader
                iconName={SELECT_TEST_DATA_ICON}
                disabled={props.disabled}
                iconColor={disabled ? DISABLE_ICON_COLOR : WHITE_COLOR}
              />
            </div>
          </Tooltip>
        </div>
      ) : (
        <div className={styles.inputTestDataOrText}>
          <div className={styles.insertTestDataText}>Text to Input:</div>
          <Input
            required={action === ELEMENT_ACTION.SEND_KEYS}
            type="text"
            name=""
            value={userInputValue}
            onChange={(e) => {
              dispatch(changeEnterText(e.target.value, data.tag));
            }}
            placeholder="Enter the text"
            disabled={isInputDisabled || showTestDataContent || disabled}
            className={styles.enterTestDataSet}
          />
        </div>
      )}
      <div className={styles.appendText}>
        <AppendRandomText data={data} disabled={isInputDisabled || props.isSkipStep || disabled} />
      </div>

      <ManageTestData {...props} tabName={OPERATION}/>
    </div>
  );
};

export default EnterText;
