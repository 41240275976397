import React, { Component } from "react";
import { Spin } from "antd";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../../actions/Recording";
import * as RecTestStepActions from "../../../actions/RecTestStepAction";
import * as RecTestStepRedirectActions from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  DEVICE_STATUS,
  LANDSCAPE,
  PORTRAIT,
  RECORDING_SCREEN,
  DEVICE_INTERACTION_MODE,
  TAP_CATEGORY,
} from "../../../Constants";

import { isPortrait, validKeyPress } from "../../../Util";
import Loader from "../../Loader/Loader";
import HighlighterRects from "./HighlighterRects";
import "./inspector.css";

/**
 * Shows screenshot of running application and divs that highlight the elements' bounding boxes
 */
class Screenshot extends Component {
  imgEl: HTMLImageElement;

  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.containerEl = null;
    this.buttonPressTimer = null;
    this.tapMethodCalled = false;
    this.debouncedClickEvents = [];
    this.clicks = 0;
    this.listener = null;
    this.listOfSwipeCoordinates = [];
    this.points = "";
    this.longPress = false;
    this.state = {
      width: 0,
      height: 0,
      imgWidth: 0,
      imgHeight: 0,
      imgHeightMax: 0,
      imgWidthMax: 0,
      scaleRatio: 1,
      screenSizeRatio: 1,
      appendBase64: "data:image/gif;base64,",
      screen: "data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
      style: {},
      screenUrl: undefined,
      x: 0,
      y: 0,
      xOnMouseDown: undefined,
      xOnMouseUp: undefined,
      yOnMouseUp: undefined,
      yOnMouseDown: undefined,
      time: new Date().getTime(),
      recElementId: undefined,
      imageError: false,
    };
    this.screenRefreshInterval = undefined;
    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.clickEffect = this.clickEffect.bind(this);
    this.handleOnLoadImage = this.handleOnLoadImage.bind(this);
    this.handleTouchPress = this.handleTouchPress.bind(this);
    this.handleTouchRelease = this.handleTouchRelease.bind(this);
    this.pasteText = this.pasteText.bind(this);
  }

  componentWillMount() {
    window.addEventListener("paste", this.pasteText);
    this.props.getServerDetail(this.props.serverId, this.props.targetUniqueId, true, this.props.deviceFarmId);
    this.updateDimensions();
    // this.setState({screenUrl:this.props.deviceDetail.httpsMjpegPort+"?"+new Date().getTime()})
    this.screenRefreshInterval = setInterval(() => {
      this.setState({ time: new Date().getTime() });
    }, 10000);
    if (this.state.recElementId !== this.props.unSavedRecElementGuid) {
      this.setState({
        recElementId: this.props.unSavedRecElementGuid,
        xOnMouseDown: undefined,
        xOnMouseUp: undefined,
        yOnMouseUp: undefined,
        yOnMouseDown: undefined,
      });
    }
  }

  pasteText(event) {
    if (document.activeElement.tagName !== "INPUT") {
      let paste = (event.clipboardData || window.clipboardData).getData("text");
      this.props.insertTextInIOS(this.props.serverId, this.props.targetUniqueId, paste);
    }
  }

  componentDidUpdate(previousProps) {
    if (
      this.state.recElementId !== this.props.unSavedRecElementGuid ||
      previousProps.deviceInteractionMode !== this.props.deviceInteractionMode
    ) {
      this.setState({
        recElementId: this.props.unSavedRecElementGuid,
        xOnMouseDown: undefined,
        xOnMouseUp: undefined,
        yOnMouseUp: undefined,
        yOnMouseDown: undefined,
      });
    }
    if (this.state.recElementId !== this.props.unSavedRecElementGuid) {
    }
    if (this.props.deviceDetail !== undefined && this.props.deviceScreen === undefined) {
      window.addEventListener("resize", this.updateDimensions);
      if (this.listener == null) {
        this.updateDimensions();
      }
      this.listener = 1;
    }
    // if (this.props.invalidUser) {
    //   history.push("/farms/" + (this.props.deviceFarmId ? this.props.deviceFarmId : ""));
    //   this.props.updateUser({ targetUniqueId: this.props.targetUniqueId });
    // }
    if (this.props.fastInputTextIndication) {
      setTimeout(() => {
        this.props.stopFastInputIndication();
      }, 10000);
    }
  }

  updateDimensions() {
    if (this.props.currentScreenOrientation) {
      let w = window,
        d = document,
        inner = d.querySelector(".inner");
      if (inner) {
        let baseWidth = 611;
        let baseHeight = 1100;
        let padding = this.props.recording ? 0.15 : 0.05;

        if (
          this.props.deviceDetail &&
          this.props.deviceDetail["deviceModel"] &&
          this.props.deviceDetail["deviceModel"].includes("Portal+")
        ) {
          baseWidth = 400; //1080 //baseHeight
          baseHeight = 711; //1920 //baseWidth
          padding = this.props.recording ? 0.18 : 0.15;
        } else if (
          this.props.deviceDetail &&
          this.props.deviceDetail["deviceModel"] &&
          this.props.deviceDetail["deviceModel"].includes("Portal")
        ) {
          baseWidth = 800;
          baseHeight = 1280;
          padding = this.props.recording ? 0.18 : 0.01;
        } else if (
          this.props.deviceDetail &&
          this.props.deviceDetail.device &&
          this.props.deviceDetail.device.toLowerCase().includes("ipad")
        ) {
          baseWidth = 768;
          baseHeight = 1024;
          padding = 0.01;
          if (this.props.currentScreenOrientation) {
            //Use for avoiding the overlapping of image on actions
            padding = 0.2;
          }
        }

        const imgBaseWidth = this.props.currentScreenOrientation === PORTRAIT ? baseWidth : baseHeight;
        const imgBaseHeight = this.props.currentScreenOrientation === PORTRAIT ? baseHeight : baseWidth;

        //this calculates the padding %
        const height = inner.clientHeight - inner.clientHeight * padding;
        const width = inner.clientWidth - inner.clientWidth * padding;

        let imgWidth = width;
        //calculates hight based on aspect ratio
        let imgHeight = (imgWidth * imgBaseHeight) / imgBaseWidth;

        //if height is greater than the inner container, set the maximun size and recalculate width base on max-height
        if (imgHeight > height && this.props.currentScreenOrientation === PORTRAIT) {
          imgHeight = height;
          imgWidth = (imgHeight * imgBaseWidth) / imgBaseHeight;
        }
        this.setState({
          width,
          height,
          imgWidth,
          imgHeight,
          style: { width: imgWidth, height: imgHeight },
        });
      }
      this.updateScaleRatio();
    }
  }

  clickEffect() {
    var d = document.createElement("div");
    d.className = "clickEffect";
    d.style.top = this.state.x / this.state.scaleRatioX + "px";
    d.style.left = this.state.y / this.state.scaleRatioY + "px";
    this.containerEl.appendChild(d);
    d.addEventListener(
      "animationend",
      function () {
        this.containerEl.removeChild(d);
      }.bind(this)
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("keydown", this.handleKeyPress);
    window.removeEventListener("paste", this.pasteText);
    this.screenRefreshInterval = undefined;
  }

  /**
   * Calculates the ratio that the image is being scaled by
   */
  updateScaleRatio() {
    let deviceWidth = undefined;
    let deviceHeight = undefined;
    let scaleRatioX = 1;
    let scaleRatioY = 1;
    let scaleWidthRatio = 1;
    let scaleHeightRatio = 1;
    let screenSizeRatio = { width: 1, height: 1 };

    if (this.props.deviceDetail) {
      if (this.props.currentScreenOrientation === PORTRAIT) {
        if (
          this.props.deviceDetail &&
          this.props.deviceDetail["deviceModel"] &&
          this.props.deviceDetail["deviceModel"].includes("Portal+")
        ) {
          deviceWidth = this.props.deviceDetail.screenSize.split("x")[1];
          deviceHeight = this.props.deviceDetail.screenSize.split("x")[0];
        } else if (this.props.deviceDetail.screenSize) {
          deviceWidth = this.props.deviceDetail.screenSize.split("x")[0];
          deviceHeight = this.props.deviceDetail.screenSize.split("x")[1];
        } else if (this.props.deviceDetail.viewportRect) {
          deviceWidth = this.props.deviceDetail.viewportRect.width;
          deviceHeight = this.props.deviceDetail.viewportRect.height;
        }
        scaleRatioX = deviceWidth / this.state.imgWidth;
        scaleRatioY = deviceHeight / this.state.imgHeight;
      } else {
        if (
          this.props.deviceDetail &&
          this.props.deviceDetail["deviceModel"] &&
          this.props.deviceDetail["deviceModel"].includes("Portal+")
        ) {
          deviceWidth = this.props.deviceDetail.screenSize.split("x")[1];
          deviceHeight = this.props.deviceDetail.screenSize.split("x")[0];
        } else if (
          this.props.deviceDetail &&
          this.props.deviceDetail["deviceModel"] &&
          this.props.deviceDetail["deviceModel"].includes("Portal")
        ) {
          deviceWidth = this.props.deviceDetail.screenSize.split("x")[0];
          deviceHeight = this.props.deviceDetail.screenSize.split("x")[1];
        } else if (this.props.deviceDetail.screenSize) {
          deviceWidth = this.props.deviceDetail.screenSize.split("x")[1];
          deviceHeight = this.props.deviceDetail.screenSize.split("x")[0];
        } else if (this.props.deviceDetail.viewportRect) {
          deviceWidth = this.props.deviceDetail.viewportRect.height;
          deviceHeight = this.props.deviceDetail.viewportRect.width;
        }
        scaleRatioX = deviceWidth / this.state.imgHeight;
        scaleRatioY = deviceHeight / this.state.imgWidth;
      }
      // if (this.props.deviceDetail.viewportRect && this.props.deviceDetail.screenSize) {
      //   screenSizeRatio["width"] =
      //     (this.props.deviceDetail.viewportRect.left + this.props.deviceDetail.viewportRect.width) /
      //     this.props.deviceDetail.screenSize.split("x")[0];
      //   screenSizeRatio["height"] =
      //     (this.props.deviceDetail.viewportRect.top + this.props.deviceDetail.viewportRect.height) /
      //     this.props.deviceDetail.screenSize.split("x")[1];
      // }
      if (this.props.deviceDetail.pixelRatio) {
        screenSizeRatio["width"] = this.props.deviceDetail.pixelRatio;
        screenSizeRatio["height"] = this.props.deviceDetail.pixelRatio;
      }
      if (this.props.deviceDetail.screenSize) {
        scaleWidthRatio = this.state.imgWidth / this.props.deviceDetail.screenSize.split("x")[0];
        scaleHeightRatio = this.state.imgHeight / this.props.deviceDetail.screenSize.split("x")[1];
      }

      this.setState({
        scaleRatioX: scaleRatioX,
        scaleRatioY: scaleRatioY,
        screenSizeRatio: screenSizeRatio,
        scaleWidthRatio: scaleWidthRatio,
        scaleHeightRatio: scaleHeightRatio,
      });
    }
  }

  async handleScreenshotClick(e) {
    let { x, y } = this.state;
    if ((x === null && y === null) || (x === Infinity && y === Infinity)) {
      x = e.nativeEvent.offsetX * this.state.scaleRatioX;
      y = e.nativeEvent.offsetY * this.state.scaleRatioY;
    }
    if (this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.INTERACTIVE && !this.tapMethodCalled) {
      // this.clickEffect();
      this.tapMethodCalled = true;
      this.clicks++;
      if (this.clicks === 1) {
        let self = this;
        setTimeout(function () {
          if (self.clicks === 1) {
            self.props.handleTap(
              self.props.serverId,
              self.props.targetUniqueId,
              x,
              y,
              TAP_CATEGORY.SINGLE_TAP,
              self.props.projectId
            );
          } else {
            self.props.handleTap(
              self.props.serverId,
              self.props.targetUniqueId,
              x,
              y,
              TAP_CATEGORY.DOUBLE_TAP,
              self.props.projectId
            );
          }
          self.clicks = 0;
        }, 300);
      }
    }
  }

  handleMouseMove(e) {
    const { scaleRatio } = this.state;
    // if (this.props.deviceInteractionMode !== SELECT) {
    let offsetX = e.nativeEvent.offsetX;
    let offsetY = e.nativeEvent.offsetY;

    if (this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT && this.containerEl) {
      offsetX =
        e.nativeEvent.target.getBoundingClientRect().x +
        e.nativeEvent.offsetX -
        document.getElementById("imageAndHiligherContainer").getBoundingClientRect().x;

      offsetY =
        e.nativeEvent.target.getBoundingClientRect().y +
        e.nativeEvent.offsetY -
        document.getElementById("imageAndHiligherContainer").getBoundingClientRect().y;
    }
    const x = offsetX * this.state.scaleRatioX;
    const y = offsetY * this.state.scaleRatioY;
    if (this.buttonPressTimer != null && new Date() - this.buttonPressTimer > 1500) {
      this.longPress = true;
    }
    if (e.nativeEvent.which === 1) {
      this.listOfSwipeCoordinates.push({ x: parseInt(x), y: parseInt(y) });
    }
    this.setState({
      ...this.state,
      x: Math.round(x),
      y: Math.round(y),
    });
    // }
  }

  handleTouchMove(e) {
    if (this.props.deviceInteractionMode !== DEVICE_INTERACTION_MODE.SELECT) {
      const offsetX = e.touches[0].clientX;
      const offsetY = e.touches[0].clientY;
      const x = offsetX * this.state.scaleRatioX;
      const y = offsetY * this.state.scaleRatioY;
      this.setState({
        ...this.state,
        x: Math.round(x),
        y: Math.round(y),
      });
      if (this.buttonPressTimer != null && new Date() - this.buttonPressTimer > 1500) {
        this.longPress = true;
      }
      this.listOfSwipeCoordinates.push({ x: parseInt(x), y: parseInt(y) });
    }
  }

  handleMouseOut() {
    if (this.props.deviceInteractionMode !== DEVICE_INTERACTION_MODE.SELECT) {
      this.setState({
        ...this.state,
        x: null,
        y: null,
      });
      this.listOfSwipeCoordinates = [];
    }
  }

  handleKeyPress(e) {
    //Prevent with showInputText when User has open the Input Text Modal.
    if (
      (navigator.userAgent.includes("Windows") && e.ctrlKey && e.keyCode === 86) ||
      (navigator.userAgent.includes("Mac") && e.metaKey && e.keyCode === 86)
    ) {
    } else {
      if (!this.props.showInputText) {
        if (this.props.keyboardEnable && validKeyPress(e.key)) {
          // if (this.props.platform == "A") {
          //   this.props.insertTextInAndroid(this.props.serverId, this.props.targetUniqueId, e.key);
          // } else {
          this.props.insertTextInIOS(this.props.serverId, this.props.targetUniqueId, e.key);
          // }
        }
      }
    }
  }

  handleTouchPress(e) {
    this.buttonPressTimer = new Date();
    if (this.props.deviceInteractionMode !== DEVICE_INTERACTION_MODE.SELECT) {
      var rect = e.target.getBoundingClientRect();
      const offsetX = e.targetTouches[0].pageX - rect.left; //e.touches[0].clientX;
      const offsetY = e.targetTouches[0].pageY - rect.top; //e.touches[0].clientY;
      const x = offsetX * this.state.scaleRatioX;
      const y = offsetY * this.state.scaleRatioY;
      this.props.startSwipe(x, y);
      this.setState({
        ...this.state,
        xOnMouseDown: x,
        yOnMouseDown: y,
        xOnMouseUp: undefined,
        yOnMouseUp: undefined,
      });
      this.listOfSwipeCoordinates.push({ x: parseInt(x), y: parseInt(y) });
    }

    e.preventDefault();
  }

  handleTouchRelease(e) {
    const { x, y, xOnMouseDown, yOnMouseDown } = this.state;
    var rect = e.target.getBoundingClientRect();
    const offsetX = e.changedTouches[0].pageX - rect.left; //e.changedTouches[0].clientX;
    const offsetY = e.changedTouches[0].pageY - rect.top; //e.changedTouches[0].clientY;
    const xOnMouseUp = offsetX * this.state.scaleRatioX;
    const yOnMouseUp = offsetY * this.state.scaleRatioY;
    if (this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.INTERACTIVE) {
      if (xOnMouseDown !== xOnMouseUp && yOnMouseDown !== yOnMouseUp) {
        this.listOfSwipeCoordinates.push({
          x: parseInt(xOnMouseUp),
          y: parseInt(yOnMouseUp),
        });
        this.props.swipeByCordinates(
          this.props.serverId,
          this.props.targetUniqueId,
          parseInt(xOnMouseDown),
          parseInt(yOnMouseDown),
          parseInt(xOnMouseUp),
          parseInt(yOnMouseUp),
          this.listOfSwipeCoordinates,
          this.longPress,
          this.props.projectId
        );
        this.listOfSwipeCoordinates = [];
        this.tapMethodCalled = true;
        this.longPress = false;
      } else if (new Date() - this.buttonPressTimer > 1000) {
        this.tapMethodCalled = true;
        this.props.handleTap(
          this.props.serverId,
          this.props.targetUniqueId,
          x,
          y,
          TAP_CATEGORY.LONG_PRESS,
          this.props.projectId
        );
      } else {
        this.tapMethodCalled = false;
      }
    }
  }

  handleButtonPress(e) {
    this.buttonPressTimer = new Date();
    if (this.props.deviceInteractionMode !== DEVICE_INTERACTION_MODE.SELECT) {
      const offsetX = e.nativeEvent.offsetX;
      const offsetY = e.nativeEvent.offsetY;
      const x = offsetX * this.state.scaleRatioX;
      const y = offsetY * this.state.scaleRatioY;

      this.props.startSwipe(x, y);
      this.setState({
        ...this.state,
        xOnMouseDown: x,
        yOnMouseDown: y,
        xOnMouseUp: undefined,
        yOnMouseUp: undefined,
      });
      this.listOfSwipeCoordinates.push({ x: parseInt(x), y: parseInt(y) });
    }

    e.preventDefault();
  }

  handleButtonRelease(e) {
    const { xOnMouseDown, yOnMouseDown } = this.state;
    const offsetX = e.nativeEvent.offsetX;
    const offsetY = e.nativeEvent.offsetY;
    const xOnMouseUp = offsetX * this.state.scaleRatioX;
    const yOnMouseUp = offsetY * this.state.scaleRatioY;
    const tapDuration = new Date() - this.buttonPressTimer;
    if (this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.INTERACTIVE) {
      if (xOnMouseDown !== xOnMouseUp && yOnMouseDown !== yOnMouseUp) {
        this.listOfSwipeCoordinates.push({
          x: parseInt(xOnMouseUp),
          y: parseInt(yOnMouseUp),
        });
        this.props.swipeByCordinates(
          this.props.serverId,
          this.props.targetUniqueId,
          parseInt(xOnMouseDown),
          parseInt(yOnMouseDown),
          parseInt(xOnMouseUp),
          parseInt(yOnMouseUp),
          this.listOfSwipeCoordinates,
          this.longPress,
          this.props.projectId
        );

        this.tapMethodCalled = true;
        this.longPress = false;
      } else if (tapDuration > 1000) {
        this.tapMethodCalled = true;
        this.props.handleTap(
          this.props.serverId,
          this.props.targetUniqueId,
          this.state.x,
          this.state.y,
          TAP_CATEGORY.LONG_PRESS,
          tapDuration,
          this.props.projectId
        );
      } else {
        this.tapMethodCalled = false;
      }
    }
    if (this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.DRAW) {
      this.props.captureDeviceSelection(
        parseInt(xOnMouseDown * this.state.screenSizeRatio["width"]),
        parseInt(yOnMouseDown * this.state.screenSizeRatio["height"]),
        parseInt((xOnMouseUp - xOnMouseDown) * this.state.screenSizeRatio["width"]),
        parseInt((yOnMouseUp - yOnMouseDown) * this.state.screenSizeRatio["height"])
      );
    }
    this.points = "";
    // if(this.listOfSwipeCoordinates.length>0 ){
    // this.listOfSwipeCoordinates.map((v,k)=>{
    //   this.points+= (v.x / this.state.scaleRatioX ) +","+ ( v.y  / this.state.scaleRatioY)  +" ";
    // });
    // }
    if (xOnMouseDown && yOnMouseDown && xOnMouseUp && yOnMouseUp) {
      this.points += xOnMouseDown / this.state.scaleRatioX + "," + yOnMouseDown / this.state.scaleRatioY + " ";
      this.points += xOnMouseUp / this.state.scaleRatioX + "," + yOnMouseUp / this.state.scaleRatioY + " ";
    } else {
      this.points = "";
    }
    this.listOfSwipeCoordinates = [];
    this.buttonPressTimer = null;
    this.setState({ ...this.state, xOnMouseUp, yOnMouseUp });
  }

  handleOnLoadImage() {
    this.setState({ imageError: false });
    if (document.getElementById("screenshotimage") != null) {
      let w = window,
        d = document,
        inner = d.querySelector(".inner");
      let naturalWidth = document.getElementById("screenshotimage").naturalWidth;
      let naturalHeight = document.getElementById("screenshotimage").naturalHeight;

      if (this.props.deviceDetail["deviceModel"] && this.props.deviceDetail["deviceModel"].includes("Portal+")) {
        let style = {};
        style.width = this.state.imgWidth > 0 ? this.state.imgWidth : null;
        style.height = this.state.imgHeight > 0 ? this.state.imgHeight : null;
        if (this.props.currentScreenOrientation === PORTRAIT) {
          this.setState({
            style: style,
            displayMode: PORTRAIT,
          });
          this.props.setCurrentOrientationChange(PORTRAIT);
        } else {
          this.setState({
            style: style,
            displayMode: LANDSCAPE,
          });
          this.props.setCurrentOrientationChange(LANDSCAPE);
        }
      } else if (naturalWidth > naturalHeight && isPortrait(this.props.currentScreenOrientation)) {
        let style = {};
        style.width = this.state.imgHeight;
        style.height = this.state.imgWidth;
        this.setState({
          style: style,
          displayMode: LANDSCAPE,
        });
        this.props.setCurrentOrientationChange(LANDSCAPE);
      } else if (naturalWidth < naturalHeight) {
        let style = {};
        style.width = this.state.imgWidth;
        style.height = this.state.imgHeight;
        this.setState({
          style: style,
          displayMode: PORTRAIT,
        });
        this.props.setCurrentOrientationChange(PORTRAIT);
      }
    } else {
      let style = {};
      style.width = this.state.imgWidth > 0 ? this.state.imgWidth : null;
      style.height = this.state.imgHeight > 0 ? this.state.imgHeight : null;
      this.setState({
        style: style,
        displayMode: PORTRAIT,
      });
      this.props.setCurrentOrientationChange(PORTRAIT);
    }

    this.updateScaleRatio();
  }
  reloadImage = () => {
    this.setState({ imageError: true });
  };

  render() {
    const {
      serverDetail,
      deviceScreen,
      recordingState,
      keyboardEnable,
      swipeStart,
      swipeEnd,
      tapNotification,
      xCoordinate,
      yCoordinate,
      recordingScreenStatus,
      isExecutionStarted,
      isPreviewStarted,
      deviceScreenLoader,
      findElementProcessing,
    } = this.props;

    // If we're tapping or swiping, show the 'crosshair' cursor style
    const screenshotStyle = {};
    if (
      [DEVICE_INTERACTION_MODE.INTERACTIVE, DEVICE_INTERACTION_MODE.SWIPE].includes(this.props.deviceInteractionMode)
    ) {
      screenshotStyle.cursor = "";
    }

    const disableRecordingScreen = "";
    let blockScreenInteraction = "";
    let blockScreenInteractionForPreview = "";

    if (
      recordingState === DEVICE_STATUS.PREVIEW ||
      (recordingScreenStatus === RECORDING_SCREEN.SHOW_EDIT_PREVIEW && isPreviewStarted) ||
      (recordingScreenStatus === RECORDING_SCREEN.SHOW_EXECUTE_SCENARIO && isExecutionStarted)
    ) {
      blockScreenInteractionForPreview = " block-screen-interaction";
    }
    if (keyboardEnable) {
      window.addEventListener("keydown", this.handleKeyPress);
    } else {
      window.removeEventListener("keydown", this.handleKeyPress);
    }
    let deviceCaseClass = "";
    if (
      this.props.deviceDetail &&
      this.props.deviceDetail["deviceModel"] &&
      this.props.deviceDetail["deviceModel"].includes("Portal")
    ) {
      deviceCaseClass = " portal ";
    } else if (this.props.platform === "I") {
      deviceCaseClass = "device-ios-gold-white";
    } else if (this.props.platform === "A") {
      deviceCaseClass = "smartphone";
    }
    let time = 0;
    if (new Date().getSeconds() % 5 === 0) {
      time = new Date().getSeconds();
    }
    return (
      <React.Fragment>
        <Spin spinning={findElementProcessing}>
          <div className="content deviceView">
            <div id="inner" className="inner">
              {recordingState === DEVICE_STATUS.RESTART ? (
                <Loader text="Restarting device..."></Loader>
              ) : !deviceScreenLoader &&
                (deviceScreen ||
                  ((this.props.platform === "I" ||
                    this.props.platform === "A" ||
                    recordingState === DEVICE_STATUS.RECORDING) &&
                    this.props.deviceDetail)) ? (
                <div className={blockScreenInteraction + " container screenshotBox"} id="screenshotContainer">
                  {blockScreenInteraction !== "" ? (
                    <React.Fragment>
                      <div className={"device-view-screen-zindex device-block-screen-interaction-container"}></div>
                      <div className={"device-view-screen-zindex device-block-screen-interaction-message"}>
                        <div>To select an Element, add an Action or Verification</div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  {blockScreenInteractionForPreview !== "" ? (
                    <React.Fragment>
                      <div className={"device-view-screen-zindex device-block-screen-interaction-container"}></div>
                      <div className={"device-view-screen-zindex device-block-screen-interaction-message"}></div>
                    </React.Fragment>
                  ) : null}
                  <div
                    className={
                      "no-selection custom-cursors " + deviceCaseClass + (this.props.recording ? " recording " : "")
                    }
                  >
                    <div
                      ref={(containerEl) => {
                        this.containerEl = containerEl;
                      }}
                      style={screenshotStyle}
                      onClick={this.handleScreenshotClick.bind(this)}
                      onMouseMove={this.handleMouseMove.bind(this)}
                      onMouseOut={this.handleMouseOut.bind(this)}
                      onTouchMove={this.handleTouchMove.bind(this)}
                      onMouseDown={this.handleButtonPress}
                      onMouseUp={this.handleButtonRelease}
                      onTouchStart={this.handleTouchPress}
                      onTouchEnd={this.handleTouchRelease}
                      tabIndex="0"
                      // className={"no-selection custom-cursors " +  deviceCaseClass + ( this.props.recording ? " recording " : "") }
                      id="imageAndHiligherContainer"
                    >
                       {this.state.imageError && (
                        <div style={this.state.style} className="image_Load_Text">
                          Live stream interrupted, reconnecting...
                        </div>)}
                       <img
                          id="screenshotimage"
                          ref={(el) => (this.imgEl = el)}
                          style={this.state.style}
                          src={
                            serverDetail.serverIpAddress +
                            this.props.deviceDetail.httpsMjpegPort +
                            "?t=" +
                            this.state.time
                          }
                          onError={this.reloadImage}
                          // onMouseDown={this.handleButtonPress}
                          // onMouseUp={this.handleButtonRelease}
                          // onTouchStart={this.handleTouchPress}
                          // onTouchEnd={this.handleTouchRelease}
                          onLoad={this.handleOnLoadImage}
                          alt="Device Screen"
                          className={this.state.imageError ? "hidden_Device_Image" : "visible_Device_Image"} 
                        />
                      <div className={disableRecordingScreen}></div>
                      {this.props.requestingPerformRecTestStepAction === false &&
                        [DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(
                          this.props.deviceInteractionMode
                        ) &&
                        this.containerEl && (
                          <HighlighterRects
                            x={this.state.x}
                            y={this.state.y}
                            platformType={this.props.deviceDetail["platformName"]}
                            scaleRatioX={this.state.scaleRatioX}
                            scaleRatioY={this.state.scaleRatioY}
                            scaleWidthRatio={this.state.scaleWidthRatio}
                            scaleHeightRatio={this.state.scaleHeightRatio}
                            screenSizeRatio={this.state.screenSizeRatio}
                            tap={this.props.handleTap}
                            projectId={this.props.projectId}
                            imgHeight={this.state.imgHeight}
                            imgWidth={this.state.imgWidth}
                            {...this.props}
                            containerEl={this.containerEl}
                          />
                        )}
                      {this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.DRAW &&
                        this.containerEl &&
                        this.state.xOnMouseDown &&
                        this.state.yOnMouseDown &&
                        ((this.state.xOnMouseUp && this.state.yOnMouseUp) || (this.state.x && this.state.y)) && (
                          <svg className="swipeSvg">
                            <rect
                              x={this.state.xOnMouseDown / this.state.scaleRatioX}
                              y={this.state.yOnMouseDown / this.state.scaleRatioY}
                              width={
                                ((this.state.xOnMouseUp ? this.state.xOnMouseUp : this.state.x) -
                                  this.state.xOnMouseDown) /
                                this.state.scaleRatioX
                              }
                              height={
                                ((this.state.yOnMouseUp ? this.state.yOnMouseUp : this.state.y) -
                                  this.state.yOnMouseDown) /
                                this.state.scaleRatioY
                              }
                            />
                          </svg>
                        )}
                      {this.props.deviceInteractionMode === DEVICE_INTERACTION_MODE.INTERACTIVE &&
                        tapNotification &&
                        xCoordinate &&
                        yCoordinate && (
                          <svg className="swipeSvg">
                            <circle
                              cx={xCoordinate / this.state.scaleRatioX}
                              cy={yCoordinate / this.state.scaleRatioY}
                            />
                          </svg>
                        )}

                      {swipeStart && swipeEnd && (
                        <svg className="swipeSvg" width="400" height="200">
                          <defs>
                            <marker
                              id="arrowhead"
                              markerWidth="10"
                              markerHeight="10"
                              refX="3.5"
                              refY="5"
                              orient="auto"
                              stroke="#ffaa80"
                              stroke-width="1"
                              fill="none"
                            >
                              <path d="M0,3 L4,5 L0,7" stroke="#ffaa80" stroke-width="1" fill="none" />
                            </marker>
                          </defs>

                          <polyline points={this.points} className="swipe-highlighter" marker-end="url(#arrowhead)" />
                        </svg>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <Spin spinning={deviceScreenLoader}></Spin>
              )}
            </div>
          </div>
          {/* Need to identify the other elements coordinate */}
          {/* <span style={{ position: "relative", top: "10px" }}>
            {this.state.x +
              "," +
              this.state.y +
              " \n scaleRatioX = " +
              this.state.scaleRatioX +
              " \n  scaleRatioY = " +
              this.state.scaleRatioY +
              " \n scaleWidthRatio = " +
              this.state.scaleWidthRatio +
              " \n scaleHeightRatio = " +
              this.state.scaleHeightRatio +
              " \n screenSizeRatio = " +
              this.state.screenSizeRatio}
          </span> */}
        </Spin>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators({ ...Actions, ...RecTestStepActions, ...RecTestStepRedirectActions }, dispatch) };
}

function mapStateToProps(state) {
  return { ...state.RecordingReducer, ...state.RecTestStepReducer, ...state.RecTestStepCommonViewReducer };
}

export default connect(mapStateToProps, mapDispatchToProps)(Screenshot);
