import React from "react";
import { Col, Row } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchOtherRuns, handleChangeErrorInvestigationData } from "../../../actions/ErrorInvestigationActions";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import { openTestPlanResultUrl } from "../../../Util";
import { defaultDateTimeFormat } from "../../../Constants";
import moment from "moment";

export const ErrorStyledRow = styled(Row)`
  width: 100%;
  .ant-collapse-item {
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 8%);
  }
  border: 0px solid #f7f8fa;
`;

const PreviousInvestigation = (props) => {
  const dispatch = useDispatch();
  const { errorInvestigationDetails, getErrorInvestigationDetails, projectId } = useSelector(
    (state) => state.ErrorInvestigationReducer
  );

  return (
    <div className="Container w-100">
      <Row className="w-100 display-flex justify-evenly p-10 font-weight-500 bg-color-gray">
        <Col span={5} className="text-align-center">
          From Run Number
        </Col>
        <Col span={5} className="text-align-center">
          Investigation
        </Col>
        <Col span={5} className="text-align-center">
          Error Description
        </Col>
        <Col span={5} className="text-align-center">
          Error Found On
        </Col>
        <Col span={2} className="text-align-center"></Col>
      </Row>
      <div className="max-h-200 overflow-auto">
        {getErrorInvestigationDetails &&
          !_.isEmpty(getErrorInvestigationDetails?.previousRunId) &&
          getErrorInvestigationDetails?.previousRunId?.map((i, j) => {
            return (
              <>
                <Row key={j} className="w-100 display-flex justify-evenly p-10 border-top">
                  <Col span={5} className="text-align-center align-self-center word-break">
                    <div
                      className="font-color-blue cursor-pointer text-decoration-underline"
                      onClick={() => openTestPlanResultUrl(props.orgId, i?.runNumber, projectId)}
                    >
                      Run-{i?.runNumber}
                    </div>
                  </Col>
                  <Col span={5} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails?.failureReason || "N/A"}
                  </Col>
                  <Col span={5} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails?.errorDescription || "N/A"}
                  </Col>
                  <Col span={5} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails?.errorFoundOn
                      ? moment(i?.investigationDetails?.errorFoundOn).format(defaultDateTimeFormat)
                      : "N/A"}
                  </Col>
                  <Col span={2} className="text-align-center">
                    <input
                      className="align-self-center h-15 w-18 cursor-pointer"
                      type="radio"
                      name="radioGroup"
                      value={i.testStepId}
                      checked={
                        errorInvestigationDetails && errorInvestigationDetails?.previousTestStepId == i.testStepId
                      }
                      onChange={(e) => {
                        dispatch(
                          handleChangeErrorInvestigationData({
                            ...errorInvestigationDetails,
                            previousTestStepId: e.target.value,
                            previousInvestigationId: i?.investigationDetails?.id,
                          })
                        );
                        dispatch(fetchOtherRuns(props.failedTestStepId, 1, props.searchOption));
                      }}
                    />
                  </Col>
                </Row>
              </>
            );
          })}
        {_.isEmpty(getErrorInvestigationDetails?.previousRunId) && <EmptyRecords description={"No Data Found"} />}
      </div>
    </div>
  );
};

export default PreviousInvestigation;
