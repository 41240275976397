import React from "react";
import { CLOSE_ICON, LINE_ICON, TICK_ICON } from "../../../CdnImagePath";
import { Toggle } from "../StyledComponent/StyledComponents";
import "./toggleComponent.css";
import { confirmModal } from "../../../Util";

const ToggleComponent = ({ isActive, activeConfirm, data }) => {
  const title = isActive
    ? "Are you sure you want to deactivate this device?"
    : "Are you sure you want to activate this device?";
  const name = isActive ? "DeActivate Device" : "Activate Device";
  return (
    <div className="display-flex align-items-center mr-30">
      <span className={isActive ? "activeLabel" : "inActiveLabel"}>Active</span>
      <Toggle isActive={isActive}>
        {isActive && <img src={TICK_ICON} alt="right" className=" pl-10 pr-10" onClick={(e) => e.stopPropagation()} />}
        <span
          className="toggle"
          onClick={(e) => {
            e.stopPropagation();
            confirmModal(() => activeConfirm(data), title, name, name);
          }}
        >
          <img src={LINE_ICON} alt="line" />
        </span>
        {!isActive && <img src={CLOSE_ICON} alt="right" className="pl-10 pr-10" onClick={(e) => e.stopPropagation()} />}
      </Toggle>
      <span className={!isActive ? "activeLabel" : "inActiveLabel"}>In-Active</span>
    </div>
  );
};

export default ToggleComponent;
