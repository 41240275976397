import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { ACTION_VIEW, CUSTOM, PRIVATE, PUBLIC, TEST_BLOCK_DESIGNER, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  ICON_APPLICATION_STRING_NEW,
  ICON_BUILD_TEST_DATA_SET_NEW,
  ICON_EDIT_NEW,
} from "../../../../Constants/SvgConstants";
import { BUILD_TEST_DATA_SET } from "../../../../Constants/TooltipConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { editTestStepBlocks } from "../../../../actions/TestBlocksAction";
import {
  setApplicationAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import ActionComponentV2 from "../../../CoreComponents/ActionComponentV2/ActionComponentV2";
import styles from "./TestBlockDetailsColumnsV2.module.scss";

const TestBlockActionsColumnV2 = (props) => {
  const dispatch = useDispatch();

  const { row, navigateToEditBuildScenario, projectId, handleActionChange } = props;
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const handleAction = (row) => {
    let arr = [];
    arr.push({
      title: "Device Strings",
      name: "deviceStrings",
    });
    if (row?.allowDelete === ACTION_VIEW) {
      arr.push({
        title: "Delete",
        name: "delete",
      });
    }
    if (loggedInUserDetails?.userEmail === row?.createdByEmail) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdByEmail) ||
            (row?.isPrivate === 1 &&
              !isEmpty(row?.recTestStepRights) &&
              loggedInUserDetails?.userEmail === row?.createdByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }

    return arr;
  };

  return (
    <div className={styles["action-icon"]}>
      {row?.allowEdit === ACTION_VIEW && (
        <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
          <div className={styles["test--Blocks-Action__Buttons"]}>
            <SvgLoader
              iconName={ICON_EDIT_NEW}
              handleClick={() => {
                navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
                dispatch(editTestStepBlocks(row, projectId));
              }}
            />
          </div>
        </Tooltip>
      )}
      <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR}>
        <div className={styles["test--Blocks-Action__Buttons"]}>
          <SvgLoader
            iconName={ICON_BUILD_TEST_DATA_SET_NEW}
            handleClick={() => {
              dispatch(setTestDataSetAssigneeDetail(row));
              navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
              dispatch(editTestStepBlocks(row, projectId));
            }}
          />
        </div>
      </Tooltip>
      <Tooltip title={"Application Strings"} color={TOOLTIP_BG_COLOR}>
        <div className={styles["test--Blocks-Action__Buttons"]}>
          <SvgLoader
            iconName={ICON_APPLICATION_STRING_NEW}
            handleClick={() => {
              dispatch(setApplicationAssigneeDetail(row));
              navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
              dispatch(editTestStepBlocks(row, projectId));
            }}
          />
        </div>
      </Tooltip>
      <ActionComponentV2 actions={handleAction(row)} data={row} handleActionChange={handleActionChange} />
    </div>
  );
};

export default TestBlockActionsColumnV2;
