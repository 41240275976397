import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/ServerListAction";
import { Button } from 'antd';
import {Cookies} from 'react-cookie';
class CookieBanner extends Component {

constructor(props) {
 super(props);  
 this.state = {
  showCookieHeader: false
};
 
}

componentWillMount(){
  const cookies=new Cookies();
  if(cookies.get("xpressTermsAndService") == undefined){
    this.setState({
      showCookieHeader : true
     });
  }
}

acceptCookie(){
  var date, expires;
  var name = "xpressTermsAndService";
  date = new Date();
  date.setTime(date.getTime()+(30*24*60*60*1000)); // 30 days
  expires = "; expires="+date.toGMTString();
  document.cookie = name+"="+name+expires+"; path=/";
  this.setState({
    showCookieHeader: false
   });
}

render() {
    return (
      this.state.showCookieHeader && 
      <React.Fragment>
          <div id="cookieConsent">
          We use cookies to enhance user experience and analyse site usage. By continuing to browse or closing this banner, you acknowledge that you have read and agree to our&nbsp; 
              <a href="/TermsAndPrivacyPolicy" target="_blank">Terms Of Service and Privacy Policy</a>.  
              <Button 
              onClick={this.acceptCookie.bind(this)}
              type={"primary"} class="cookieConsentOK">Got It</Button>
          </div>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
    return  bindActionCreators(actions,dispatch)
  }
  
  function mapStateToProps(state) {
    return state.RecordingReducer
  }
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CookieBanner);