import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProjects } from "../../../actions/ProjectsAction";
import { getMyOrgUsers, getOrgList } from "../../../actions/TeamsAction";
import history from "../../../history";
import styles from "./OrganizationButtonV2.module.scss";

const OrganizationButtonV2 = () => {
  const dispatch = useDispatch();
  let { orgList } = useSelector((state) => state.TeamsReducer);
  const [selectOrgList, setSelectOrgList] = useState();

  useEffect(() => {
    if (selectOrgList && selectOrgList.id) {
      dispatch(myProjects(selectOrgList.id));
      dispatch(getMyOrgUsers(selectOrgList.id));
    }
  }, [selectOrgList]);

  const handleSelectProjectName = (id, name) => {
    setSelectOrgList({
      id: id,
      name: name,
    });
    history.push(`/${id}/projects`);
    dispatch(getOrgList());
  };

  const shortNameOfOrganization = (str) => {
    const sanitizedStr = str.replace(/[^a-zA-Z\s]/g, " ").trim();
    const words = sanitizedStr.split(/\s+/);
    if (words.length >= 2) {
      const firstWordLetter = words[0].charAt(0);
      const secondWordLetter = words[1].charAt(0);
      return firstWordLetter + secondWordLetter;
    } else {
      const firstTwoLetter = words[0].charAt(0) + words[0].charAt(1);
      return firstTwoLetter;
    }
  };

  return (
    <div className={styles["organization_List"]}>
      {orgList &&
        orgList.map((s, k) => {
          let activeOrganization = selectOrgList?.id === s?.organization?.id;
          return (
            <div
              key={s.organization.id}
              value={s.organization.id}
              onClick={() => {
                handleSelectProjectName(s.organization.id, s.organization.name);
              }}
            >
              <div className={styles["organization_Name_Details"]}>
                <span
                  className={`${styles["organization_Short_Name"]} ${
                    activeOrganization ? styles["active_Organization_Short_Name"] : ""
                  }`}
                >
                  {shortNameOfOrganization(s.organization.name)}
                </span>
                <span
                  className={`${styles["organization_Name"]} ${
                    activeOrganization ? styles["active_Organization_Name"] : ""
                  }`}
                >
                  {s.organization.name}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default OrganizationButtonV2;
