import { default as React, useState } from "react";
import { useDispatch } from "react-redux";
import { dragHoverOnStepOrAction } from "../../../Util";
import RecTestStepActionDetailV2 from "./RecTestStepActionDetailV2/RecTestStepActionDetailV2";

const RecTestStepActionV2 = (props) => {
  const {
    recTestStep,
    disableAction,
    isDeviceLoaded,
    projectId,
    recTestSteps,
    isTestBlock,
    stepType,
    showToggle,
    stepId,
    stepNo,
  } = props;
  const [isDragOver, setIsDragOver] = useState(false);

  const dispatch = useDispatch();
  return (
    <>
      {recTestStep?.recTestSteps.map((childRecTestStep, index) => {
        return (
          <RecTestStepActionDetailV2
            recTestSteps={recTestSteps}
            key={index}
            index={index}
            data={childRecTestStep}
            parentIndex={recTestStep.orderId}
            disableAction={disableAction}
            isDeviceLoaded={isDeviceLoaded}
            projectId={projectId}
            parentRecTestStep={recTestStep}
            parentSkipStep={props.isSkipStep}
            isTestBlock={isTestBlock}
            stepType={stepType}
            showToggle={showToggle}
            stepId={stepId}
            stepNo={stepNo}
          />
        );
      })}
      {!props.isSkipStep &&
        dragHoverOnStepOrAction(
          isDragOver,
          setIsDragOver,
          dispatch,
          false,
          recTestStep?.recTestSteps[recTestStep?.recTestSteps?.length - 1],
          recTestStep,
          recTestStep?.recTestSteps?.length
        )}
    </>
  );
};

export default RecTestStepActionV2;
