import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from './../../actions/ServerListAction';
import { Row, Col, Button, Card, Checkbox,Select,Tag,Spin, message} from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus,faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

const { Meta } = Card;
const { Option, OptGroup } = Select;

class DeviceDefaultAppSet extends Component {

 constructor(props) {
  super(props);
  this.state = {
    deletedDefaultApp:false,
};

 }

componentWillMount(){
    this.props.getInstalledAppListForDefaultAppSet(this.props.device.targetUniqueId,this.props.serverDetail)
    //this.props.getInstalledAppListForDefaultAppSet(this.props.device.targetUniqueId,"",this.props.serverDetail)
}
handleDeleteDefaultsApp(value){
    this.setState({deletedApps:true})
    var deletedApps=[];
    deletedApps=this.props.deletedDefaultAppForDeviceList;
    deletedApps.push(value)
    this.props.changeTheDeletedDefaultAppList(deletedApps)
}
handleSaveDeletedDefaultApps(){
    var deleted = this.props.deleteDeviceDefaultApp(this.props.device.targetUniqueId,this.props.serverId,this.props.deviceFarmId)
    if(deleted){
        this.setState({deletedApps:false})
    }
}
 render() {
  const {appListForDefaultAppSet,listOfDeviceDefaultApps,selectedAppListForDefaultAppSet,savingTheDefaultAppDetails,setDefaultsAppRightsForDevice,deletedDefaultAppForDeviceList,deletingTheDefaultAppDetails,noDefaultAppSet,setDeviceCleanOnOpenSession} = this.props;
  let defaultAppList=[]
  let selectAppListToSet=[]
  if(appListForDefaultAppSet && appListForDefaultAppSet.length>0 && listOfDeviceDefaultApps ){
    listOfDeviceDefaultApps.map((v,k)=>{
        return defaultAppList.push(v.packageName)
    })
    appListForDefaultAppSet.forEach((element)=>{
        return (defaultAppList.length==0 || !defaultAppList.includes(element)?selectAppListToSet.push(element):null);
    })
  }
  let disableSaveButton=undefined;
  if(setDeviceCleanOnOpenSession!=undefined && this.props.device.cleanDeviceSession!=setDeviceCleanOnOpenSession && selectedAppListForDefaultAppSet.length==0){
    disableSaveButton=false
  }else if(setDeviceCleanOnOpenSession!=undefined && this.props.device.cleanDeviceSession!=setDeviceCleanOnOpenSession && selectedAppListForDefaultAppSet.length>0){
    disableSaveButton=false
  }else if(setDeviceCleanOnOpenSession==undefined && this.props.device.cleanDeviceSession && selectedAppListForDefaultAppSet.length==0){
    disableSaveButton=true
  }else if(setDeviceCleanOnOpenSession==undefined && this.props.device.cleanDeviceSession && selectedAppListForDefaultAppSet.length>0){
    disableSaveButton=false
  }else if(setDeviceCleanOnOpenSession==undefined && !this.props.device.cleanDeviceSession && selectedAppListForDefaultAppSet.length==0 ){
    disableSaveButton=true
  }else if(setDeviceCleanOnOpenSession==undefined && !this.props.device.cleanDeviceSession && selectedAppListForDefaultAppSet.length>0 ){
    disableSaveButton=true
  }else if(setDeviceCleanOnOpenSession!=undefined && this.props.device.cleanDeviceSession==setDeviceCleanOnOpenSession && selectedAppListForDefaultAppSet.length==0){
    disableSaveButton=true
  }else if(setDeviceCleanOnOpenSession!=undefined && this.props.device.cleanDeviceSession==setDeviceCleanOnOpenSession && selectedAppListForDefaultAppSet.length>0 ){
    disableSaveButton=false
  }

  return (

    <React.Fragment>
        <Row>
          { setDefaultsAppRightsForDevice && 
            <Checkbox className={"checkbox-device-cleanUp"}
                      defaultChecked={this.props.device.cleanDeviceSession} 
                      value={setDeviceCleanOnOpenSession} 
                      onChange={(value)=>{this.props.setCleanUpDeviceSessionCheckbox(value.target.checked)}}>
                       Remove all Installed Apps when this Device is Released
            </Checkbox> }
        </Row>
           <Row>
            
            {((setDeviceCleanOnOpenSession || this.props.device.cleanDeviceSession) && setDefaultsAppRightsForDevice) || !setDefaultsAppRightsForDevice?
                <div>
                    <div className={"exluded-app-title"}>Retained Apps List</div>
                    <Card className={"default-app-list-container"}>
                        {listOfDeviceDefaultApps && listOfDeviceDefaultApps.length>0 && !noDefaultAppSet ?     
                            (<Meta className={"default-app-tags"}
                                description={listOfDeviceDefaultApps.map((v,k)=>{
                                                return (<Tag closable={setDefaultsAppRightsForDevice} onClose={()=>{this.handleDeleteDefaultsApp(v.packageName)}} color={v.isDefaultApp?"":"#ff6666"}>{v.packageName}</Tag>);})
                                                }
                            />):
                            (noDefaultAppSet?
                                (<div className={"loading-default-app-list"}>No Apps to Retain</div>):
                                (<div className={"loading-default-app-list"}><Spin size={"small"}/></div>))
                        }
                    </Card>
                </div>
            :null}
            {setDefaultsAppRightsForDevice && (setDeviceCleanOnOpenSession || this.props.device.cleanDeviceSession) && appListForDefaultAppSet ? 
                (<div>
                    <div className={"installed-app-list-title"}>Select Apps to Retain</div>
                    <Select  
                        className={"select-app-for-default"}
                        mode="multiple"
                        placeholder="Select Installed Apps To Add To Retained Apps List."
                        notFoundContent	={ listOfDeviceDefaultApps && appListForDefaultAppSet &&  listOfDeviceDefaultApps.length==0 && appListForDefaultAppSet.length==0 ? <div>No installed App Found</div>:(<div>All Installed Apps Added In Retain Apps List</div>)}
                        onChange={(value)=>{this.props.changeTheSelectedPackagesInAppDefault(value)}}>
                            {selectAppListToSet.map((v,k)=>{
                                    return (<Option value={v} key={k}> {v} </Option> );
                            })}
                    </Select>
                    <div className={"exluded-app-info-msg"}><FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>&nbsp;Selected Apps from list will not be removed on Releasing device</div>
                </div>):null}
            </Row>  
            {setDefaultsAppRightsForDevice && (setDeviceCleanOnOpenSession || this.props.device.cleanDeviceSession)?   
                   ([<Row>
                        {/* <Button type={"primary"} 
                                onClick={()=>{this.props.getInstalledAppListForDefaultAppSet(this.props.device.targetUniqueId,this.props.serverId,this.props.serverDetail)}} 
                                disabled={disableAddButton}
                                className={"add-app-button-for-default-app"}>
                            <FontAwesomeIcon icon={faPlus}/>&nbsp;&nbsp;Add 
                        </Button> */}
                        {this.state.deletedApps ?
                            (<Button type={"danger"} 
                                     loading={deletingTheDefaultAppDetails}  
                                     onClick={()=>{this.handleSaveDeletedDefaultApps()}} 
                                     className={"save-changes-of-device-config"}>
                                Save 
                            </Button>):
                            (<Button type={"primary"} 
                                     loading={savingTheDefaultAppDetails} 
                                     disabled={disableSaveButton}
                                     onClick={()=>{this.props.setDefaultAppsForDevice(this.props.device.targetUniqueId,this.props.serverId,this.props.deviceFarmId,this.props.device.cleanDeviceSession)}} 
                                     className={"save-changes-of-device-config"}>
                                Save 
                            </Button>)
                        }
                    </Row>]):null
            }
       </React.Fragment>
  );
 }

}

function mapDispatchToProps(dispatch) {
 return  bindActionCreators(actions, dispatch)
}

function mapStateToProps(state) {
 return state.ServerListReducer
}

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(DeviceDefaultAppSet); 

