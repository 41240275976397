import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "antd";
import AppBuildListV2 from "./AppBuildListV2/AppBuildListV2";
import { ADD_BUILDS, ALL_BUILDS, APP_MANAGER, INITIAL_APP_BUILD_DATA } from "../../Constants";
import AddAppBuild from "../BuildManagement/AddBuild/AddBuild";
import { changeAppManagerTab, resetAppBuildData } from "../../actions/Recording";

const BuildManagerV2 = (props) => {
  const { projectId, orgId } = props;
  const dispatch = useDispatch();
  const { selectedTab, selectedDeviceTabActionKey } = useSelector((state) => state.RecordingReducer);
  const [buildId, setBuildId] = useState("");
  const [appBuildData, setAppBuildData] = useState(INITIAL_APP_BUILD_DATA);

  useEffect(() => {
    dispatch(changeAppManagerTab(ALL_BUILDS));
    setBuildId("");
    dispatch(resetAppBuildData());
  }, [projectId]);

  useEffect(() => {
    if (selectedTab === ALL_BUILDS && buildId) {
      setBuildId("");
      dispatch(resetAppBuildData());
    }
  }, [selectedTab, buildId]);

  useEffect(() => {
    if (selectedDeviceTabActionKey !== APP_MANAGER) {
      dispatch(changeAppManagerTab(ALL_BUILDS));
    }
  }, [selectedDeviceTabActionKey]);

  return (
    <Col span={24}>
      {selectedTab === ALL_BUILDS && <AppBuildListV2 setBuildId={setBuildId} projectId={projectId} orgId={orgId} />}
      {selectedTab === ADD_BUILDS && (
        <AddAppBuild
          buildId={buildId}
          projectId={projectId}
          appBuildData={appBuildData}
          setAppBuildData={setAppBuildData}
        />
      )}
    </Col>
  );
};

export default BuildManagerV2;
