import { Collapse, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { GRAY_COLOR, STEP, TEST_PLAN_RESULT_TYPE, TOOLTIP_BG_COLOR } from "../../../Constants";
import { getTestPlanStepsList, testPlanStepsListId } from "../../../actions/TestPlanAction";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import ErrorInvestigationModal from "../../ErrorInvestigation/ErrorInvestigationModal";
import TestPlanResultDetailPreconditionList from "./TestPlanResultDetailPreconditionList";
import TestPlanResultDetailStep from "./TestPlanResultDetailStep";
import TestPlanResultScenarioEvidence from "./TestPlanResultScenarioEvidence";
import TestPlanResultStepElementDetail from "./TestPlanResultStepElementDetails";
const { Panel } = Collapse;

const StyledSpan = styled.span`
  width: 65px;
  height: 44px;
  margin-right: 8px;
  border: 0.2 solid ${(props) => props.GRAY_COLOR};
  border-radius: 5px;
  padding-top: 10px;
  text-align: center;
  box-shadow: 0 0 10px 2px rgb(0 0 0 / 13%);
  background: white !important;
  margin-top: 10px;
`;

function getSubChildStepFailedAndHaveContinueAfterFailFlag(steps, scenarioId, parentId) {
  if (
    steps &&
    scenarioId &&
    steps[scenarioId] &&
    steps[scenarioId].childSteps &&
    steps[scenarioId].childSteps[parentId]
  ) {
    return (
      steps[scenarioId].childSteps[parentId].filter(
        (item) =>
          (item.action === STEP && item.result === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) ||
          (item.action !== STEP &&
            item.result === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED &&
            item.continueAfterFail === 1)
      ).length === steps[scenarioId].childSteps[parentId].length
    );
  }
  return false;
}
function fetchParentSteps(testPlanResultStepsDetails, scenarioId) {
  if (
    testPlanResultStepsDetails &&
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId].parentSteps
  ) {
    return testPlanResultStepsDetails[scenarioId].parentSteps;
  }
  return [];
}
function fetchPreconditionSteps(testPlanResultStepsDetails, scenarioId, parentId) {
  if (
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId].preConditionStpes &&
    testPlanResultStepsDetails[scenarioId].preConditionStpes[parentId]
  ) {
    return testPlanResultStepsDetails[scenarioId].preConditionStpes[parentId];
  }
  return [];
}

function fetchChildSteps(testPlanResultStepsDetails, scenarioId, parentId) {
  if (
    testPlanResultStepsDetails &&
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId].childSteps &&
    testPlanResultStepsDetails[scenarioId].childSteps[parentId]
  ) {
    return testPlanResultStepsDetails[scenarioId].childSteps[parentId];
  }
  return [];
}

const getResult = (
  resultString,
  isStepHasPreCondition,
  preconditionResult,
  continueAfterFail,
  subChildStepFailedAndHaveContinueAfterFailFlag,
  parentModifiedResult,
  isSkipStep,
  isLoopStep
) => {
  let result = resultString;
  //In-Progress Result
  if (resultString === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    result = TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT;
  } else if (resultString === TEST_PLAN_RESULT_TYPE.PASSED) {
    result = TEST_PLAN_RESULT_TYPE.PASSED;
  }
  //Failed Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.FAILED) {
    if (preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED && isStepHasPreCondition === 1 && continueAfterFail === 1) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED;
    } else if (
      preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED &&
      isStepHasPreCondition === 1 &&
      (continueAfterFail === undefined || continueAfterFail === 0)
    ) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET;
    } else if (
      preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED &&
      isLoopStep === 1 &&
      (continueAfterFail === undefined || continueAfterFail === 0)
    ) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_LOOP_STEP_CONDITION_NOT_MET;
    } else if (preconditionResult === TEST_PLAN_RESULT_TYPE.PASSED && resultString === TEST_PLAN_RESULT_TYPE.FAILED) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.FAILED;
    }
  }
  //Skipped Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    if (isSkipStep === 1) {
      result = TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED;
    } else if (parentModifiedResult === TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED && isSkipStep !== 1) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED;
    }
  }
  //Continue After Failed Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
    if (preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED || subChildStepFailedAndHaveContinueAfterFailFlag) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE;
    }
  }
  return result;
};

const getExecutionType = (data, subChildFailedAndHaveContinueAfterFailFlag) => {
  if (data.action === STEP) {
    if (data.isStepHasPreCondition === 0 && data.continueAfterFail === 0 && data.isLoopStep === 0) {
      if (subChildFailedAndHaveContinueAfterFailFlag) {
        return "Only if Precondition Met";
      } else {
        return "Always (no precondition)";
      }
    } else if (data.isStepHasPreCondition === 1) {
      return "Only if Precondition Met";
    } else if (data.isLoopStep === 1) {
      return "Repeat till Condition Met";
    }
  } else {
    if (data.continueAfterFail === 1) {
      return "Ignore Failure and Continue Execution";
    } else {
      return "Always";
    }
  }
};

export const leftBorderColor = (data) => {
  if (data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    return "border-left-inProgress";
  } else if (data === TEST_PLAN_RESULT_TYPE.PASSED) {
    return "border-left-pass";
  } else if (data === TEST_PLAN_RESULT_TYPE.FAILED) {
    return "border-left-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    return "border-left-skip";
  } else if (data === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
    return "border-left-continue-after-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.ABORTED) {
    return "border-left-abort";
  } else if (data === TEST_PLAN_RESULT_TYPE.TERMINATED) {
    return "border-left-abort";
  } else {
    return "";
  }
};

export const backGroundColorForSteps = (data) => {
  if (data.isSkipStep === 1) {
    return "panel-background-color-disable mt-10";
  } else {
    return "panel-background-color mt-10";
  }
};

function addAdditionalStepDetail(
  data,
  testPlanResultStepsDetails,
  scenarioId,
  parentModifiedResult,
  isLoopStep,
  loopStepCount
) {
  // update subChildStepFailedAndHaveContinueAfterFailFlag
  data["subChildStepFailedAndHaveContinueAfterFailFlag"] = getSubChildStepFailedAndHaveContinueAfterFailFlag(
    testPlanResultStepsDetails,
    scenarioId,
    data.id
  );
  // Update Result
  data["modifiedResult"] = getResult(
    data.result,
    data.isStepHasPreCondition,
    data.preconditionResult,
    data.continueAfterFail,
    data["subChildStepFailedAndHaveContinueAfterFailFlag"],
    parentModifiedResult,
    data.isSkipStep,
    isLoopStep
  );
  if (data["isLoopStep"] === 0 && isLoopStep !== 0) {
    data["isParentLoopStep"] = isLoopStep;
    data["parentLoopStepCount"] = loopStepCount;
  }

  // Update Execution Type
  data["executionType"] = getExecutionType(data, data["subChildStepFailedAndHaveContinueAfterFailFlag"]);
  return data;
}
const getChildStep = (
  testPlanResultStepsDetails,
  scenarioId,
  parentId,
  showSubHeader,
  parentModifiedResult,
  count,
  setTestStepId,
  setOpenErrorInvestigationModal,
  isLoopStep,
  loopCount,
  isTestBlock
) => {
  const data = fetchChildSteps(testPlanResultStepsDetails, scenarioId, parentId);
  return (
    <React.Fragment>
      {data &&
        data.map((v, k) => {
          v = addAdditionalStepDetail(
            v,
            testPlanResultStepsDetails,
            scenarioId,
            parentModifiedResult,
            isLoopStep,
            loopCount
          );
          let stepName = `S${count}.${v.stepIndex}`;
          return (
            <div className="display-flex" key={k}>
              {v.action !== STEP ? <StyledSpan GRAY_COLOR={GRAY_COLOR}>{k + 1}</StyledSpan> : null}
              {v.action === STEP ? (
                <Tooltip
                  color={TOOLTIP_BG_COLOR}
                  title={`${stepName?.length >= 10 ? stepName : `Grouped-Step-${k + 1}`}`}
                  placement="bottom">
                  <StyledSpan>{stepName?.length >= 10 ? v.stepIndex : stepName}</StyledSpan>
                </Tooltip>
              ) : null}
              <div className="w-98">
                <Collapse className={leftBorderColor(v.result)} key={k} expandIconPosition="right">
                  <Panel
                    className={backGroundColorForSteps(v)}
                    showArrow={true}
                    header={
                      <div>
                        <TestPlanResultDetailStep
                          data={v}
                          index={k}
                          parentModifiedResult={parentModifiedResult}
                          preConditionList={fetchPreconditionSteps(testPlanResultStepsDetails, scenarioId, v.id)}
                          modifiedResult={v.modifiedResult}
                          setTestStepId={setTestStepId}
                          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
                          isTestBlock={isTestBlock}
                          repeatSteps={v?.repeatSteps}
                          retrySteps={v.retrySteps}
                        />
                      </div>
                    }
                    key={k}>
                    {
                      <TestPlanResultDetailPreconditionList
                        preConditionList={fetchPreconditionSteps(testPlanResultStepsDetails, scenarioId, v.id)}
                        isStepHasPreCondition={v.isStepHasPreCondition}
                        isLoopStep={v.isLoopStep}
                        index={k}
                        recordedStepImageLink={v.recordedStepImageLink}
                        failureImage={v.failureScreenshotLink}
                        failurePageSourceLink={v.failurePagesourceLink}
                        result={v.result}
                        loopCount={v?.loopCount}
                        modifiedResult={v.modifiedResult}
                        testExecutionElementDetails={v.testExecutionElementDetails}
                        executedLoopCount={
                          !_.isEmpty(testPlanResultStepsDetails) &&
                          testPlanResultStepsDetails[scenarioId]?.childSteps[v?.id] &&
                          testPlanResultStepsDetails[scenarioId]?.childSteps[v?.id]?.length > 0
                            ? testPlanResultStepsDetails[scenarioId].childSteps[v?.id][0]?.executedLoopCount
                            : 0
                        }
                        continueAfterFail={v.continueAfterFail}
                        testDataName={v.testDataName}
                        testDataValue={v.testDataValue}
                        testDataCustomValue={v.testDataCustomValue}
                        outputTestDataName={v.outputTestDataName}
                        outputTestDataValue={v.outputTestDataValue}
                        selectedElementImageUrl={v.selectedElementImageUrl}
                        selectorType={v.selectorType}
                        retryCounts={v.retryCounts}
                        retryIndex={v.retryIndex}
                        retrySteps={v.retrySteps}
                        repeatSteps={v?.repeatSteps}
                        testStepFailDetails={v?.testStepFailDetails}
                        action={v.action}
                        testExecutionErrorInvestigationDetails={v?.testExecutionErrorInvestigationDetails}
                        text={v.textRef}
                        data={v}
                      />
                    }
                    {getChildStep(
                      testPlanResultStepsDetails,
                      scenarioId,
                      v.id,
                      true,
                      v.modifiedResult,
                      count + "." + v.stepIndex,
                      setTestStepId,
                      setOpenErrorInvestigationModal,
                      v.isLoopStep,
                      v.loopCount,
                      v.isTestBlock
                    )}
                    {v.action !== STEP ? (
                      <TestPlanResultStepElementDetail
                        recordedStepImageLink={v.recordedStepImageLink}
                        testExecutionElementDetails={v.testExecutionElementDetails}
                        failureImage={v.failureScreenshotLink}
                        failurePageSourceLink={v.failurePagesourceLink}
                        continueAfterFail={v.continueAfterFail}
                        result={v.result}
                        modifiedResult={v.modifiedResult}
                        testDataName={v.testDataName}
                        testDataValue={v.testDataValue}
                        outputTestDataName={v.outputTestDataName}
                        outputTestDataValue={v.outputTestDataValue}
                        testDataCustomValue={v.testDataCustomValue}
                        selectedElementImageUrl={v.selectedElementImageUrl}
                        selectorType={v.selectorType}
                        retryCounts={v.retryCounts}
                        retryIndex={v.retryIndex}
                        retrySteps={v.retrySteps}
                        repeatSteps={v?.repeatSteps}
                        testStepFailDetails={v?.testStepFailDetails}
                        action={v.action}
                        testExecutionErrorInvestigationDetails={v?.testExecutionErrorInvestigationDetails}
                        text={v.textRef}
                        data={v}
                      />
                    ) : undefined}
                  </Panel>
                </Collapse>
              </div>
            </div>
          );
        })}
    </React.Fragment>
  );
};

// Main Function
var timerID = {};

const TestPlanResultDetailStepList = (props) => {
  const dispatch = useDispatch();
  const { scenarioData, isActive, isTestBlock } = props;
  const { runResultDetails, testPlanResultStepsDetails, testPlanLoading, testStepResults,testPlanStepsList } = useSelector(
    (state) => state.TestPlanReducer
  );

  const runId = runResultDetails?.find((i) => i)?.runId;
  const [openErrorInvestigationModal, setOpenErrorInvestigationModal] = useState(false);
  const [testStepId, setTestStepId] = useState(null);
  let parentSteps =
    isTestBlock === 0
      ? fetchParentSteps(testPlanResultStepsDetails, scenarioData?.scenarioId)
      : fetchParentSteps(testStepResults, runId);

  let count = 0;

  const loadTestPlanStepList = (restart = false) => {
    if (timerID[scenarioData?.scenarioId] || timerID[scenarioData?.scenarioId] === undefined || restart) {
      timerID[scenarioData?.scenarioId] = setTimeout(() => {
        dispatch(
          getTestPlanStepsList({ scenarioId: scenarioData?.scenarioId }, () => {
            loadTestPlanStepList();
          })
        );
        dispatch(testPlanStepsListId(scenarioData?.scenarioId));
      }, 3000);
    }
  };

  useEffect(() => {
    if (
      isActive &&
      (scenarioData?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
        scenarioData?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
        scenarioData?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED)
    ) {
      loadTestPlanStepList(true);
    } else {
      if (timerID[scenarioData?.scenarioId]) {
        clearTimeout(timerID[scenarioData?.scenarioId]);
        timerID[scenarioData?.scenarioId] = null;
      }
    }
  }, [isActive, scenarioData?.state]);

  useEffect(() => {
    return () => {
      if (timerID[scenarioData?.scenarioId]) {
        clearTimeout(timerID[scenarioData?.scenarioId]);
        timerID[scenarioData?.scenarioId] = null;
      }
    };
  }, []);

  return (
    <React.Fragment>
      <>
        {parentSteps?.length > 0 ? (
          parentSteps.map((v, k) => {
            v =
              isTestBlock === 0
                ? addAdditionalStepDetail(v, testPlanResultStepsDetails, scenarioData?.scenarioId)
                : addAdditionalStepDetail(v, testStepResults, runId);
            return v && v.action && v.action.length > 0 && v.name && v.name.length > 0 ? (
              <div className="display-flex" key={k}>
                {v.action === STEP ? (
                  <Tooltip color={TOOLTIP_BG_COLOR} title={`Step-${(count += 1)}`} placement="bottom">
                    <StyledSpan>S{count}</StyledSpan>
                  </Tooltip>
                ) : null}
                <div className="w-98">
                  <Collapse className={leftBorderColor(v.result)} key={k} expandIconPosition="right">
                    <Panel
                      className={backGroundColorForSteps(v)}
                      showArrow={true}
                      header={
                        <TestPlanResultDetailStep
                          data={v}
                          index={k}
                          preConditionList={
                            isTestBlock === 0
                              ? fetchPreconditionSteps(testPlanResultStepsDetails, scenarioData?.scenarioId, v.id)
                              : fetchPreconditionSteps(testStepResults, runId, v.id)
                          }
                          modifiedResult={v.modifiedResult}
                          repeatSteps={v?.repeatSteps}
                          retrySteps={v.retrySteps}
                        />
                      }
                      key={k}>
                      <div
                        className={
                          !_.isEmpty(
                            isTestBlock === 0
                              ? fetchPreconditionSteps(testPlanResultStepsDetails, scenarioData?.scenarioId, v.id)
                              : fetchPreconditionSteps(testStepResults, runId, v.id)
                          ) && "bg-color-gray p-10 border-radius-10"
                        }>
                        {
                          <TestPlanResultDetailPreconditionList
                            preConditionList={
                              isTestBlock === 0
                                ? fetchPreconditionSteps(testPlanResultStepsDetails, scenarioData?.scenarioId, v.id)
                                : fetchPreconditionSteps(testStepResults, runId, v.id)
                            }
                            index={k}
                            isStepHasPreCondition={v.isStepHasPreCondition}
                            isLoopStep={v.isLoopStep}
                            recordedStepImageLink={v.recordedStepImageLink}
                            failureImage={v.failureScreenshotLink}
                            failurePageSourceLink={v?.failurePagesourceLink}
                            result={v.result}
                            executedLoopCount={
                              !_.isEmpty(testPlanResultStepsDetails) &&
                              testPlanResultStepsDetails[scenarioData?.scenarioId]?.childSteps?.[v?.id]?.length > 0
                                ? testPlanResultStepsDetails[scenarioData?.scenarioId]?.childSteps[v?.id][0]
                                    ?.executedLoopCount
                                : 0
                            }
                            loopCount={v?.loopCount}
                            continueAfterFail={v.continueAfterFail}
                            modifiedResult={v.modifiedResult}
                            testExecutionElementDetails={v.testExecutionElementDetails}
                            testDataName={v.testDataName}
                            testDataValue={v.testDataValue}
                            testDataCustomValue={v.testDataCustomValue}
                            outputTestDataName={v.outputTestDataName}
                            outputTestDataValue={v.outputTestDataValue}
                            selectedElementImageUrl={v.selectedElementImageUrl}
                            selectorType={v.selectorType}
                            retryCounts={v.retryCounts}
                            retryIndex={v.retryIndex}
                            retrySteps={v.retrySteps}
                            repeatSteps={v?.repeatSteps}
                            testStepFailDetails={v?.testStepFailDetails}
                            action={v.action}
                            testExecutionErrorInvestigationDetails={v?.testExecutionErrorInvestigationDetails}
                            text={v.textRef}
                            data={v}
                          />
                        }
                        {isTestBlock === 0
                          ? getChildStep(
                              testPlanResultStepsDetails,
                              scenarioData?.scenarioId,
                              v.id,
                              true,
                              v.modifiedResult,
                              count,
                              setTestStepId,
                              setOpenErrorInvestigationModal,
                              v.isLoopStep,
                              v.LoopCount,
                              v.isTestBlock
                            )
                          : getChildStep(
                              testStepResults,
                              runId,
                              v.id,
                              true,
                              v.modifiedResult,
                              count,
                              setTestStepId,
                              setOpenErrorInvestigationModal,
                              v.isLoopStep,
                              v.LoopCount,
                              v.isTestBlock
                            )}
                      </div>
                    </Panel>
                  </Collapse>
                </div>
              </div>
            ) : null;
          })
        ) : (
          <Spin
            spinning={testPlanLoading || testPlanStepsList?.includes(scenarioData?.scenarioId)}
            tip="Loading"
            className="spinner-center"
          />
        )}
      </>
      {_.isEmpty(parentSteps) && !testPlanLoading && !testPlanStepsList?.includes(scenarioData?.scenarioId) && (
        <EmptyRecords description={"No Data Found"} />
      )}
      {isTestBlock === 0 && <TestPlanResultScenarioEvidence scenarioData={scenarioData} />}
      {openErrorInvestigationModal && (
        <ErrorInvestigationModal
          openErrorInvestigationModal={openErrorInvestigationModal}
          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
          failedTestStepId={testStepId}
          projectId={runResultDetails[0]?.projectId}
          orgId={runResultDetails[0]?.organizationId}
        />
      )}
    </React.Fragment>
  );
};
export default TestPlanResultDetailStepList;
