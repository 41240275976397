import React, { useState } from "react";
import _ from "lodash";
import { Col, Input, Row, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  changeServerManagerTab,
  handleChangeRegisterServerData,
  saveRegisterServer,
} from "../../../actions/ServerAction";
import { confirmModal, isNullOrUndefinedOrEmptyOrNaN, isTrim } from "../../../Util";
import { ALL_SERVER, CONFIRM_MODAL_CONTENT } from "../../../Constants";
import SaveResetButton from "../../CoreComponents/SaveResetComponent/SaveResetButton";
import BackButton from "../../CoreComponents/BackButtonComponent/BackButton";
import { Label, Title, Toggle } from "../../CoreComponents/StyledComponent/StyledComponents";
import { CLOSE_ICON, LINE_ICON, TICK_ICON } from "../../../CdnImagePath";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const { Option } = Select;

const RegisterServer = (props) => {
  const dispatch = useDispatch();
  const { registerServerData, serverTypes, previous_registerServerData, serverLoading } = useSelector(
    (state) => state.ServerReducer
  );

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const isEmptyValue =
    Object.values(registerServerData).some((i) => isNullOrUndefinedOrEmptyOrNaN(i)) ||
    isTrim(registerServerData?.serverName);
  const isEqual = _.isEqual(JSON.stringify(previous_registerServerData), JSON.stringify(registerServerData));

  const onBackClick = () => {
    if (isEqual) {
      dispatch(changeServerManagerTab(ALL_SERVER));
    } else {
      setOpenConfirmModal(true);
    }
  };

  return (
    <>
      <Spin spinning={serverLoading} tip="Loading" className="spinner-center">
        <Row justify="space-between" className="mt-20 mb-10">
          <div className="display-flex">
            <div className="ml-10  mt-5 align-self-center">
              <BackButton handleBack={onBackClick} isBlack={true} />
            </div>
            <span className="ml-15 mt-5">
              <Title>Register Local Server</Title>
            </span>
          </div>
          <div className="mr-15">
            <SaveResetButton
              submitLabel={registerServerData?.id === 0 ? "Save" : "Update"}
              resetLabel={"Reset"}
              submitDisable={isEqual || isEmptyValue}
              resetDisable={isEqual}
              handleSubmit={() => {
                dispatch(saveRegisterServer(props.orgId));
              }}
              handleReset={() => dispatch(handleChangeRegisterServerData(previous_registerServerData))}
            />
          </div>
        </Row>
        <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 14 }}>
            <Row className="mt-30 mb-30" gutter={50}>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
                <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Server Details</h3>
                <p className="sub-title">
                  Carefully fill out the following details to configure your server settings. Ensure accurate
                  information is entered to avoid any disruptions.
                </p>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
                <Label className="text-light1">
                  Server Name<span className="text-danger">*</span>
                </Label>
                <div>
                  <Input
                    className="border-radius-4 mb-20 mt-5"
                    type="text"
                    name="serverName"
                    placeholder="Please Enter Server Name "
                    value={registerServerData.serverName}
                    onChange={(e) =>
                      dispatch(handleChangeRegisterServerData({ ...registerServerData, serverName: e.target.value }))
                    }
                    autoFocus
                  />
                </div>
                <Label>
                  Server Type<span className="text-danger">*</span>
                </Label>
                <Select
                  className="border-radius-4 mb-20 mt-5 w-100"
                  type="text"
                  name="serverType"
                  value={registerServerData.serverType || undefined}
                  placeholder="Please Enter Server Type"
                  onChange={(e) => dispatch(handleChangeRegisterServerData({ ...registerServerData, serverType: e }))}
                >
                  {serverTypes &&
                    serverTypes.map((server, i) => {
                      return (
                        <Option key={server.id} value={server.id}>
                          {server.name}
                        </Option>
                      );
                    })}
                </Select>
                <Label>
                  Server Ip<span className="text-danger">*</span>
                </Label>
                <Input
                  className="border-radius-4 mb-20 mt-5"
                  type="text"
                  name="serverIp"
                  value={registerServerData.serverIp}
                  placeholder="Please Enter Server Ip"
                  onChange={(e) =>
                    dispatch(
                      handleChangeRegisterServerData({
                        ...registerServerData,
                        serverIp: e.target.value,
                        localIp: e.target.value,
                      })
                    )
                  }
                />
                <Label>Status</Label>

                <div className="display-flex align-items-center mr-30">
                  <span className={registerServerData.isActive ? "activeLabel" : "inActiveLabel"}>Active</span>
                  <Toggle isActive={registerServerData.isActive}>
                    {registerServerData.isActive ? (
                      <img src={TICK_ICON} alt="right" className="pl-10 pr-10" onClick={(e) => e.stopPropagation()} />
                    ) : (
                      ""
                    )}
                    <span
                      className="toggle"
                      onClick={() =>
                        dispatch(
                          handleChangeRegisterServerData({
                            ...registerServerData,
                            isActive: registerServerData.isActive ? 0 : 1,
                          })
                        )
                      }
                    >
                      <img src={LINE_ICON} alt="line" />
                    </span>
                    {!registerServerData.isActive && (
                      <img src={CLOSE_ICON} alt="right" className="pl-10 pr-10" onClick={(e) => e.stopPropagation()} />
                    )}
                  </Toggle>
                  <span className={!registerServerData.isActive ? "activeLabel" : "inActiveLabel"}>In-Active</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeServerManagerTab(ALL_SERVER));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default RegisterServer;
