import { Col, Radio, Row } from "antd";
import { useEffect } from "react";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAppManagerTab, resetAppBuildData } from "../../actions/Recording";
import { ADD_BUILDS, ALL_BUILDS, APP_MANAGER, INITIAL_APP_BUILD_DATA } from "../../Constants";
import AddBuild from "./AddBuild/AddBuild";
import BuildList from "./BuildList";

const BuildManager = (props) => {
  const dispatch = useDispatch();
  const { selectedTab, selectedDeviceTabActionKey } = useSelector((state) => state.RecordingReducer);
  const [buildId, setBuildId] = useState("");
  const [appBuildData, setAppBuildData] = useState(INITIAL_APP_BUILD_DATA);

  useEffect(() => {
    dispatch(changeAppManagerTab(ALL_BUILDS));
    setBuildId("");
    dispatch(resetAppBuildData());
  }, [props.projectId]);

  useEffect(() => {
    if (selectedTab === ALL_BUILDS && buildId) {
      setBuildId("");
      dispatch(resetAppBuildData());
    }
  }, [selectedTab, buildId]);

  useEffect(() => {
    if (selectedDeviceTabActionKey !== APP_MANAGER) {
      dispatch(changeAppManagerTab(ALL_BUILDS));
    }
  }, [selectedDeviceTabActionKey]);

  const handleOnChange = (e) => {
    dispatch(changeAppManagerTab(e));
    if (e === ALL_BUILDS && buildId) {
      setBuildId("");
      dispatch(resetAppBuildData());
    }
  };

  return (
      <Col span={24}>
        {/* <Radio.Group
          value={selectedTab}
          onChange={(e) => {
            handleOnChange(e.target.value);
          }}
          buttonStyle="solid"
          className="m-5">
          <Radio.Button value={ALL_BUILDS}>{ALL_BUILDS}</Radio.Button>
          <Radio.Button value={ADD_BUILDS}>{ADD_BUILDS}</Radio.Button>
        </Radio.Group> */}

        {selectedTab === ALL_BUILDS && (
          <BuildList setBuildId={setBuildId} projectId={props.projectId} orgId={props.orgId} />
        )}
        {selectedTab === ADD_BUILDS && <AddBuild buildId={buildId} projectId={props.projectId} appBuildData={appBuildData} setAppBuildData ={setAppBuildData}/>}
      </Col>
  );
};

export default BuildManager;
