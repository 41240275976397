import { Input } from "antd";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { hasNotAllowedNegativeValue } from "../../../../../Util";
import { changeCaptureScreenShotDelayTime } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./QuickActionsV2.module.scss";

const CaptureScreenshotV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, isInputDisabled, data } = props;
  const { recTestStepProperty } = data;

  const disabledInput = useMemo(() => isForView || isInputDisabled, [isForView, isInputDisabled]);

  return (
    <div className={styles["capture_Screenshot_Details"]}>
      <div className={styles["actions_Label"]}>After</div>
      <div className={`${styles["input_And_Label"]} ${disabledInput && styles["disable_Input_And_Label"]}`}>
        <Input
          disabled={isForView || isInputDisabled}
          value={recTestStepProperty?.captureScreenShotDelayTime}
          type="number"
          onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
          min={0}
          onChange={(e) =>
            !isForView && !isInputDisabled
              ? dispatch(changeCaptureScreenShotDelayTime(e.target.value, data?.tag))
              : undefined
          }
          placeholder="Pause Before Taking Screenshot"
          className={`${disabledInput ? styles["disabled_Input_Box"] : styles["input_Box"]}`}
        />
        <span className={styles["input_Label"]}>msecs</span>
      </div>
    </div>
  );
};

export default CaptureScreenshotV2;
