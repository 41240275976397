import { Badge, Button, Collapse, Input, Select } from "antd";
import styled from "styled-components";

const { TextArea } = Input;

export const Label = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #636467;
`;

export const StyledSearch = styled(Input)`
  .ant-input {
    height: 25px;
    font-size: 13px;
  }
  border-radius: 5px !important;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #DEDEDE")};
  box-shadow: none !important;
`;

export const FilterButton = styled.span`
  display: flex;
  align-items: center;
  padding: 0px 15px;
  gap: 10px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
`;

export const BuildTag = styled.div`
  padding: 5px 10px;
  background: #f6f6f6;
  border: 1px solid #c3c5d2;
  border-radius: 5px;
  margin: 5px;
  white-space: nowrap;
`;

export const RunningTag = styled.span`
  padding: 6px 10px;
  background: #d0e2ec;
  border-radius: 100px;
  font-size: 12px;
  color: #306a9f;
  margin: 2px;
  white-space: nowrap;
`;

export const PassTag = styled.span`
  padding: 6px 10px;
  background: #d8e7dd;
  border-radius: 100px;
  font-size: 12px;
  color: #297042;
  margin: 2px;
  white-space: nowrap;
`;

export const FailTag = styled.span`
  padding: 6px 10px;
  background: #f0dbdb;
  border-radius: 100px;
  font-size: 12px;
  color: #b43131;
  margin: 2px;
  white-space: nowrap;
`;

export const AbortTag = styled.span`
  padding: 6px 10px;
  background: #f1e3c6;
  border-radius: 100px;
  font-size: 12px;
  color: #7d5e0f;
  margin: 2px;
  white-space: nowrap;
`;

export const InQueueTag = styled.span`
  padding: 6px 10px;
  background: #ececec;
  border-radius: 100px;
  font-size: 12px;
  color: #808080;
  margin: 2px;
  white-space: nowrap;
`;

export const UploadingResultTag = styled.span`
  padding: 6px 10px;
  background: #fff15c85;
  color: #000;
  border-radius: 100px;
  font-size: 12px;
  margin: 2px;
  white-space: nowrap;
`;

export const ScheduledTag = styled.span`
  padding: 6px 10px;
  background: #e6f3fe;
  border-radius: 100px;
  font-size: 12px;
  color: #10508b;
  margin: 2px;
  white-space: nowrap;
`;

export const BadgeStyle = styled(Badge)`
  color: ${(props) => props.color};
  margin-right: 0.08rem;
  vertical-align: middle;
  white-space: nowrap;
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 22px;
  color: #111548;
`;

export const Required = styled.span`
  color: red;
  font-size: 14px;
  margin-left: 3px;
`;

export const StyledInput = styled(Input)`
  padding: 12px;
  height: 40px;
  border: 1px solid #c3c5d2;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #111548;
`;

export const StyledTextArea = styled(TextArea)`
  padding: 12px;
  height: 40px;
  border: 1px solid #c3c5d2;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  color: #111548;
`;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid #b0b1c1 !important;
  width: 90%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
`;

export const Toggle = styled.div`
  border-radius: 5px;
  padding: 5px;
  display: flex;
  margin: 0px 20px;
  background-color: ${(props) => (props.isActive ? "#62B474" : "#D3D4DE")};
`;

export const ProjectCard = styled.div`
  padding: 15px;
  background: #ffffff;
  border: 0.6px solid #dedede;
  border-radius: 5px;
  height: 200px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
`;

export const TeamCard = styled.div`
  padding: 15px;
  background: #ffffff;
  border: 0.6px solid #dedede;
  border-radius: 5px;
  height: 133px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
`;

export const StyledSearchBox = styled(Input)`
  .ant-input {
    height: 25px;
    font-size: 13px;
  }
  border-radius: 4px !important;
  border: ${(props) => (props.error ? "1px solid red" : "1px solid #E0E0E0")};
  box-shadow: none !important;
`;

export const StyledScenarioPlatformLogo = styled.img`
  float: left;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
  border: 1px solid #dee2e6 !important;
`;

export const StyledActionLabel = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #636467;
  margin-bottom: 10px;
`;

export const StyledActionsButton = styled.div`
  .ant-btn-primary {
    height: 30px;
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #444aea;
    margin-bottom: 10px;
  }
`;

export const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100% !important;
  background-color: #f7f8fa !important;
  flex: none;
  flex-grow: 0;
`;
export const StyledCollapsePanel = styled(Collapse.Panel)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px !important;
  width: 100%;
  background: #ffffff !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  .ant-collapse-content {
    background: #ffffff !important;
    width: 100%;
  }
  .ant-collapse-header-text {
    flex: auto !important;
    width: 100%;
  }
  .ant-collapse-header {
    padding: 5px !important;
    margin: 0px !important ;
    width: 100%;
  }
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 15px !important;
  }
`;
