import { default as React } from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, TOOLTIP_BG_COLOR } from "../../Constants";
import { HIDE_DEVICE_VIEW_ICON_NEW, SHOW_DEVICE_VIEW_ICON_NEW } from "../../Constants/SvgConstants";
import { HIDE_DEVICE_VIEW, SHOW_DEVICE_VIEW } from "../../Constants/TooltipConstants";
import SvgLoader from "../../Util/SvgLoader";
import { resetDeviceFarmList } from "../../actions/ServerListAction";

const ShowDeviceViewButtonV2 = (props) => {
  const dispatch = useDispatch();
  const { showDeviceView, setShowDeviceView } = props;

  return (
    <Tooltip
      color={TOOLTIP_BG_COLOR}
      title={showDeviceView ? HIDE_DEVICE_VIEW : SHOW_DEVICE_VIEW}
      placement={showDeviceView ? "top" : "topLeft"}
    >
      <span
        onClick={(e) => {
          setShowDeviceView();
          dispatch(resetDeviceFarmList());
          dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
        }}
      >
        <SvgLoader
          iconName={showDeviceView ? HIDE_DEVICE_VIEW_ICON_NEW : SHOW_DEVICE_VIEW_ICON_NEW}
          width="2.25rem"
          height="2.25rem"
        />
      </span>
    </Tooltip>
  );
};
export default ShowDeviceViewButtonV2;