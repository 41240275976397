import { Col, Divider, Input, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeHorizontalRelativePoint,
  changeVerticalRelativePoint,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { hasNotAllowedNegativeValue, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import styles from "./ActionOnElementRelativePoint.module.scss";

const ActionOnElementRelativePoint = (props) => {
  const { data, disabled, recElement } = props;
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  return (
    <>
      <Row>
        <Col span={24} className={styles.divider}>
          <Divider orientation="left" className={styles.settingsModalDivider}>
            <span className={styles.relative_Text}>Tap on Relative Point</span>
            <span className={`${styles.relative_Text} ${styles.text}`}>(from Top Left)</span>
          </Divider>
        </Col>
      </Row>
      <div className={styles.relative_Point}>
        <div className={styles.inputOrText}>
          <div className="display__flex">
            <span className={styles.relative_Point_Text}>Horizontal</span>
            <Col span={8}>
              <Input
                disabled={
                  disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                }
                value={data?.recTestStepProperty?.horizontalRelativePoint}
                type="number"
                onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                onChange={(e) => dispatch(changeHorizontalRelativePoint(e.target.value, data.tag, recElement?.guid))}
                placeholder="Percentage from 0 to 100"
                className={styles.input}
              />
            </Col>
            <span className={styles.percentage}>%</span>
          </div>
        </div>
        <div className={styles.inputOrText}>
          <div className="display__flex">
            <span className={styles.relative_Point_Text}>Vertical</span>
            <Col span={8}>
              <Input
                disabled={
                  disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                }
                value={data?.recTestStepProperty?.verticalRelativePoint}
                type="number"
                onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                onChange={(e) => dispatch(changeVerticalRelativePoint(e.target.value, data.tag, recElement?.guid))}
                placeholder="Percentage from 0 to 100"
                className={styles.input}
              />
            </Col>
            <span className={styles.percentage}>%</span>
          </div>
        </div>
        {/* */}
      </div>
    </>
  );
};

export default ActionOnElementRelativePoint;
