import React from "react";
import { Button, Input, Modal, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { changePageSourceFetchingInterval, visibleRecordingSettingsModal } from "../../../../actions/Recording";
import SvgLoader from "../../../../Util/SvgLoader";
import { ACTION_ICON_COLOR, INFO_ICON, RESET_ICON } from "../../../../Constants/SvgConstants";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";
import { useState } from "react";
import styles from "./RecordingSettings.module.scss";

const RecordingSettings = () => {
  const { visibleRecordingSettingModal, pageSourceFetchingInterval } = useSelector((state) => state.RecordingReducer);
  const [fetchingInterval, setFetchingInterval] = useState(pageSourceFetchingInterval);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const dispatch = useDispatch();

  return (
    <>
      <Modal
        title="Recording Settings"
        visible={visibleRecordingSettingModal}
        onCancel={() => dispatch(visibleRecordingSettingsModal(false))}
        className="recordingSettingsModal"
        footer={[
          <div className={styles.modalFooter}>
            <Button
              type="primary"
              className={styles.cancelBtn}
              onClick={() => {
                dispatch(visibleRecordingSettingsModal(false));
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={saveDisabled}
              type="primary"
              className={styles.saveBtn}
              onClick={() => {
                dispatch(changePageSourceFetchingInterval(fetchingInterval));
                dispatch(visibleRecordingSettingsModal(false));
              }}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <div className={styles.modalDetails}>
          <div className={styles.modalText}>
            Set page source fetching intervals during recording to prevent slowness or timeouts caused by large or
            complex DOMs.
          </div>
          <div className={styles.modalContent}>
            <span className={styles.modalLabel}>Page Source Refresh Interval</span>:
            <Input
              type="number"
              style={{ width: "6rem" }}
              value={fetchingInterval}
              onChange={(e) => {
                setSaveDisabled(false);
                setFetchingInterval(e.target.value);
              }}
            />
            <span>seconds</span>
            {fetchingInterval !== 5 && (
              <Tooltip title={"Set to Default (5 seconds)"} color={TOOLTIP_BG_COLOR}>
                <span>
                  <SvgLoader
                    iconColor={ACTION_ICON_COLOR}
                    iconName={RESET_ICON}
                    handleClick={() => {
                      dispatch(changePageSourceFetchingInterval(5));
                      setFetchingInterval(5);
                      setSaveDisabled(false);
                    }}
                  />
                </span>
              </Tooltip>
            )}
            <Tooltip
              trigger="click"
              title={
                <div className={styles.tooltipNote}>
                  <span className={styles.tooltipLabel}>Note:</span> This setting is not retained across sessions.
                  Although there is no upper limit on this interval, it is recommended to use 60-120 secs.
                </div>
              }
              color={TOOLTIP_BG_COLOR}
            >
              <span>
                <SvgLoader iconName={INFO_ICON} />
              </span>
            </Tooltip>
          </div>
          <div className={styles.otherDetails}>
            Adjust this interval higher if experiencing issues, especially on complex pages, to enable successful
            element recording.
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RecordingSettings;
