import { Button, Col, Input, Row, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_DATA_SCOPE } from "../../../../Constants";
import { changeNewProjectKeyName, changeNewProjectKeyValue, saveNewTestData } from "../../../../actions/TestDataAction";
import { hideAddProjectKeysForm } from "../../../../actions/TestDataAction";
const AddProjectKeys = (props) => {
  const {
    newProjectKeyId,
    newProjectKeyName,
    newProjectKeyValue,
    requestSaveProjectKeyForm,

    previous_newProjectKeyValue,
  } = useSelector((props) => props.TestDataReducer);

  const dispatch = useDispatch();
  let enableSaveTestDataButton =
    !requestSaveProjectKeyForm &&
    newProjectKeyName &&
    newProjectKeyName.trim().length > 0 &&
    previous_newProjectKeyValue !== newProjectKeyValue &&
    newProjectKeyValue &&
    newProjectKeyValue.trim().length > 0;

  return (
    <>
      <Row className="ml-20">
        <Col className="mb-10" md={24}>
          <b>Add Project Keys</b>
        </Col>
      </Row>

      <Row className="mt-10 ml-20">
        <Col md={12} className="display-flex align-items-center gap-10">
          <Col md={2}>
            <span>Name:</span>
          </Col>
          {newProjectKeyId ? (
            newProjectKeyName
          ) : (
            <>
              <Col md={10}>
                <Input
                  type="text"
                  value={newProjectKeyName}
                  placeholder="Test Data Name"
                  onChange={(e) => dispatch(changeNewProjectKeyName(e.target.value))}
                />
              </Col>
            </>
          )}
        </Col>
        <Col md={12} className="pl-10 display-flex align-items-center gap-10">
          <Col md={2}>
            <span>Value:</span>
          </Col>
          <Col md={10}>
            <Input
              type="text"
              value={newProjectKeyValue}
              placeholder="Test Data Value"
              onChange={(e) => dispatch(changeNewProjectKeyValue(e.target.value))}
            />
          </Col>
        </Col>
      </Row>
      <Row className="mt-10 ml-20">
        <Col md={24}>
          <Space size={10}>
            <Button
              disabled={!enableSaveTestDataButton}
              type="primary"
              onClick={() =>
                enableSaveTestDataButton
                  ? dispatch(
                      saveNewTestData(
                        newProjectKeyName,
                        newProjectKeyValue,
                        newProjectKeyId,
                        props.projectId,
                        TEST_DATA_SCOPE.PROJECT,
                        0,
                        undefined,
                        TEST_DATA_SCOPE.PROJECT,
                        true
                      )
                    )
                  : ""
              }
            >
              {newProjectKeyId ? "Update" : "Add"}
            </Button>
            <Button onClick={() => dispatch(hideAddProjectKeysForm())}>Cancel</Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default AddProjectKeys;
