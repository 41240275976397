import React from "react";
import { useDispatch } from "react-redux";
import { ADD_CIRCLE_ICON, DARK_GREEN } from "../../../../../Constants/SvgConstants";
import { addVerificationRow } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import CustomButtonV2 from "../../../../CommonComponents/CustomButtonV2/CustomButtonV2";

const AddVerificationGroupV2 = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <CustomButtonV2
        buttonLabel={"Add Group"}
        handleClick={() =>
          !props.disabled
            ? dispatch(addVerificationRow("GROUP", props.uuid, props.recTestStep.tag, props.recElement?.guid))
            : undefined
        }
        buttonIcon={ADD_CIRCLE_ICON}
        disabled={props.disabled}
        iconColor={DARK_GREEN}
        className="common_Light_Green_Button"
      />
    </React.Fragment>
  );
};

export default AddVerificationGroupV2;
