export class BuildGeneralTabModal {
  constructor(responseData) {
    //For Get API
    if (responseData) {
      this.projectName = responseData["projectName"];
      this.appName = responseData["appName"];
      this.projectDes = responseData["description"];
      this.selectedTeam = responseData["teamId"];
      this.platform = responseData["platform"];
      this.selectedMembers = responseData["members"] && responseData["members"].map((i) => i.email);
    } else {
      this.projectName = undefined;
      this.appName = undefined;
      this.projectDes = undefined;
      this.selectedMembers = [];
      this.selectedTeam = null;
    }
  }
}

export class BuildS3ConfigTabModal {
  constructor(responseData) {
    //For Get API
    if (responseData) {
      this.CfDistributionDomain = responseData["CfDistributionDomain"];
      this.CfKeyPairId = responseData["CfKeyPairId"];
      this.PreSignedUrlExpirationDays = responseData["PreSignedUrlExpirationDays"];
      this.S3AccessKeyId = responseData["S3AccessKeyId"];
      this.S3Bucket = responseData["S3Bucket"];
      this.S3BucketRegion = responseData["S3BucketRegion"];
      this.S3Host = responseData["S3Host"];
      this.S3SecretAccessKey = responseData["S3SecretAccessKey"];
      this.cloudFile = responseData["fileName"];
    } else {
      this.CfDistributionDomain = undefined;
      this.CfKeyPairId = undefined;
      this.PreSignedUrlExpirationDays = undefined;
      this.S3AccessKeyId = undefined;
      this.S3Bucket = undefined;
      this.S3BucketRegion = undefined;
      this.S3Host = undefined;
      this.S3SecretAccessKey = undefined;
      this.cloudFile = undefined;
    }
  }
}
export class BuildAWSDeviceFarmModal {
  constructor(responseData) {
    //For Get API
    if (responseData) {
      this.accessKeyId = responseData["accessKey"];
      this.secretAccessKey = responseData["secretAccessKey"];
      this.awsDeviceFarmProjects = responseData["projects"] || [];
    } else {
      this.accessKeyId = null;
      this.secretAccessKey = null;
      this.awsDeviceFarmProjects = [];
      
    }
  }
}
