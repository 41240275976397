import { Col, Row } from "antd";
import React from "react";
import { operatorChange } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerificationBuilder from "./VerificationBuilder";
import VerifyAbsence from "./VerifyAbsence";
import VerifyElementProperty from "./VerifyElementProperty";
import VerifyElementText from "./VerifyElementText";
import VerifyPresence from "./VerifyPresence";

const VerificationGroup = (props) => {
  const { data, disabled } = props;
  const prepareGroupItems = (itemsData) => {
    let items = [];
    itemsData.childRecStepVerifyElementProperties.map((v, k) => {
      let Item = verificationRowComponent[v.type];
      if (Item) {
        items.push(
          <React.Fragment>
            <Item
              key={k}
              data={v}
              uuid={itemsData.uuid}
              recTestStep={props.recTestStep}
              disabled={disabled}
              recElementVerification={props.recElementVerification}
              operatorChange={v.type === "GROUP" ? operatorChange : () => {}}
            />
            {itemsData.childRecStepVerifyElementProperties.length > 1 &&
              itemsData.childRecStepVerifyElementProperties.length - 1 !== k && (
                <div className={"verification-operator-label"}>{data.operator}</div>
              )}
          </React.Fragment>
        );
      }
    });
    return items;
  };

  return (
    <React.Fragment>
      <Row className={"verification-group-main-handler"}>
        <Col md={24}>
          {data.operator !== undefined && (
            <VerificationBuilder
              disabled={props.disabled}
              isElementSelected={props.disabled}
              operator={data.operator}
              uuid={data.uuid ? data.uuid : data.id}
              recTestStep={props.recTestStep}
              recElement={props.recElementVerification}
              operatorChange={operatorChange}
            />
          )}
          {data?.childRecStepVerifyElementProperties ? prepareGroupItems(data) : ""}
        </Col>
      </Row>
    </React.Fragment>
  );
};
const verificationRowComponent = {
  VerifyElementProperty: VerifyElementProperty,
  VerifyElementText: VerifyElementText,
  GROUP: VerificationGroup,
};
export default VerificationGroup;
