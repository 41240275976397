import React from "react";
import RecTestStepOperation from "../RecTestStepOperation/RecTestStepOperation";
import { useDispatch, useSelector } from "react-redux";
import { PLAY_TYPE } from "../../../../../Constants";
import { selectRecTestStepForPlay, startPreview, stopPreview } from "../../../../../actions/RecTestStepAction";
import { PLAY_FROM_HERE, PLAY_THIS_STEP, PLAY_TILL_HERE, STOP } from "../../../../../Constants/TooltipConstants";
import {
  ICON_PLAY_FOR_SUB_HEADER,
  ICON_PLAY_FROM_HERE,
  ICON_PLAY_TILL_HERE,
  ICON_STOP,
} from "../../../../../Constants/SvgConstants";
import styles from "./ActionsComponents.module.scss";

const RecTestStepPlayActions = (props) => {
  const { disabled, projectId, recTestStepOnHover, recTestStep } = props;
  const dispatch = useDispatch();

  const { isPreviewRunning, requestingToStopPreview, requestingForPreview,selectedRecTestStepForPlay } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const { unSavedRecTestStep, unSavedRecElementGuid, unSavedRecTestStepTag } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const handleClick = (type) => {
    if (!isPreviewRunning) {
      dispatch(
        startPreview(
          projectId,
          type,
          recTestStep?.tag,
          recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep?.id
        )
      );
      dispatch(selectRecTestStepForPlay(recTestStep));
    }
  };

  const isDisableAll =
    unSavedRecTestStepTag !== undefined ||
    unSavedRecElementGuid !== undefined ||
    disabled ||
    isPreviewRunning ||
    !startDeviceStreamingStatus ||
    unSavedRecTestStep !== undefined ||
    recTestStep?.isSkipStep === 1;

  const stopPlayThisStep = isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP);
  const stopPlayFromHere = isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE);
  const stopPlayTillHere = isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE);
  const recTestStepPlaySteps = [
    {
      name: stopPlayThisStep ? "Stop" : "Play",
      spinning: stopPlayThisStep
        ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP)
        : requestingForPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP) &&
          !isPreviewRunning &&
          recTestStep?.tag === selectedRecTestStepForPlay?.tag,
      stopButton: stopPlayThisStep,
      handleClick: () =>
        stopPlayThisStep
          ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP)
            ? dispatch(stopPreview())
            : undefined
          : !isDisableAll && handleClick(PLAY_TYPE.PLAY_THIS_STEP),
      disabled: !stopPlayThisStep && isDisableAll,
      tooltipTitle: stopPlayThisStep ? STOP : PLAY_THIS_STEP,
      imageName: stopPlayThisStep ? ICON_STOP : ICON_PLAY_FOR_SUB_HEADER,
    },
    {
      name: stopPlayFromHere ? "Stop" : "Play from here",
      spinning: stopPlayFromHere
        ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE)
        : requestingForPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE) &&
          !isPreviewRunning &&
          recTestStep?.tag === selectedRecTestStepForPlay?.tag,
      stopButton: stopPlayFromHere,
      handleClick: () =>
        stopPlayFromHere
          ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE)
            ? dispatch(stopPreview())
            : undefined
          : !isDisableAll && handleClick(PLAY_TYPE.PLAY_FROM_HERE),
      disabled: !stopPlayFromHere && isDisableAll,
      tooltipTitle: stopPlayFromHere ? STOP : PLAY_FROM_HERE,
      imageName: stopPlayFromHere ? ICON_STOP : ICON_PLAY_FROM_HERE,
    },
    {
      name: stopPlayTillHere ? "Stop" : "Play till here",
      spinning: stopPlayTillHere
        ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE)
        : requestingForPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE) &&
          !isPreviewRunning &&
          recTestStep?.tag === selectedRecTestStepForPlay?.tag,
      stopButton: stopPlayTillHere,
      handleClick: () =>
        stopPlayTillHere
          ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE)
            ? dispatch(stopPreview())
            : undefined
          : !isDisableAll && handleClick(PLAY_TYPE.PLAY_TILL_HERE),
      disabled: !stopPlayTillHere && isDisableAll,
      tooltipTitle: stopPlayTillHere ? STOP : PLAY_TILL_HERE,
      imageName: stopPlayTillHere ? ICON_STOP : ICON_PLAY_TILL_HERE,
    },
  ];

  return (
    <div className={`${styles.subHeader} ${!recTestStepOnHover && styles.actionIconInHeader} subHeader`}>
      {recTestStepPlaySteps.map((playStep, index) => (
        <RecTestStepOperation key={index} {...playStep} hideTooltipTitle recTestStepOnHover={recTestStepOnHover} />
      ))}
    </div>
  );
};

export default RecTestStepPlayActions;
