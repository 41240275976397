import _ from "lodash";

import { Drawer } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { TEST_DATA_SCOPE, TEST_STEP_BLOCKS } from "../../../../Constants";
import { CLOSE_ICON_RED, CREATE_ICON, WHITE_COLOR } from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  changeTestDataListFilterValue,
  changeTestDataListSearchValue,
  fetchTestData,
  showAddTestDataForm,
  visibleTestDataDrawer,
} from "../../../../actions/TestDataAction";
import CustomButtonV2 from "../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import HeaderButtonsGroupV2 from "../../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import CommonSearchV2 from "../../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./ManageTestDataV2.module.scss";
import TestDataDetailsV2 from "./TestDataDetailsV2";
import AddTestDataV2 from "../../../TestDataV2/AddTestDataV2/AddTestDataV2";

const ManageTestDataV2 = (props) => {
  const nameInput = useRef(null);
  const dispatch = useDispatch();
  const { data, projectId, setCurrentActionTab, tabName } = props;

  const { stepType, testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName } = useSelector((props) => props.TestDataReducer);
  const { addTestDataFormVisible, showTestDataDrawer, filterFetchingTestDataList, tagForOpenDrawer } = useSelector(
    (state) => state.TestDataReducer
  );

  const [activeFilter, setActiveFilter] = useState({ id: TEST_DATA_SCOPE.ALL, value: TEST_DATA_SCOPE.ALL });
  const [openAddTestDataModal, setOpenAddTestDataModal] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const stepScope = stepType === TEST_STEP_BLOCKS ? TEST_DATA_SCOPE.TEST_STEP_BLOCK : TEST_DATA_SCOPE.TEST_SCENARIO;

  const filterPermission = [
    { id: TEST_DATA_SCOPE.ALL, value: TEST_DATA_SCOPE.ALL },
    { id: TEST_DATA_SCOPE.PROJECT, value: TEST_DATA_SCOPE.PROJECT },
    { id: stepScope, value: stepScope },
    { id: TEST_DATA_SCOPE.SYSTEM_KEYS, value: TEST_DATA_SCOPE.SYSTEM_KEYS },
  ];

  useEffect(() => {
    if (testDataList === undefined) {
      dispatch(fetchTestData(projectId));
      dispatch(changeTestDataListFilterValue(TEST_DATA_SCOPE.ALL, projectId));
    }
  }, [filterFetchingTestDataList, projectId, showTestDataDrawer]);

  useEffect(() => {
    dispatch(changeTestDataListSearchValue(""));
    dispatch(fetchTestData(projectId, TEST_DATA_SCOPE.ALL, inputValue));
  }, [dispatch, showTestDataDrawer]);

  const debounce = _.debounce((searchWord) => {
    dispatch(fetchTestData(projectId, true, searchWord));
  }, 700);

  const handleChangeValue = (searchWord) => {
    setInputValue(searchWord);
    debounce(searchWord);
  };

  const handleFilters = (e) => {
    setActiveFilter(e);
    dispatch(changeTestDataListFilterValue(e?.id, projectId));
    dispatch(fetchTestData(projectId, e?.id, inputValue));
  };

  return (
    <Drawer
      title={
        <div className={styles["test_Data_label"]}>
          <div className={styles["drawer_Title"]}>{"Test Data"}</div>
          <SvgLoader
            iconName={CLOSE_ICON_RED}
            handleClick={() => {
              dispatch(changeTestDataListFilterValue(TEST_DATA_SCOPE.ALL, projectId));
              dispatch(fetchTestData(projectId));
              dispatch(visibleTestDataDrawer());
            }}
            width={40}
            height={40}
          />
        </div>
      }
      closable={false}
      visible={showTestDataDrawer}
      width={830}
      onClose={() => {
        dispatch(changeTestDataListFilterValue(TEST_DATA_SCOPE.ALL, projectId));
        dispatch(fetchTestData(projectId));
        dispatch(visibleTestDataDrawer());
      }}
      className={openTestDataSetDrawerByTabName !== tabName || data?.tag !== tagForOpenDrawer ? "hideDrawer" : ""}
    >
      <div className={styles["test_Data_Details"]}>
        <div className={styles["add_Test_Data_Btn"]}>
          <CustomButtonV2
            buttonLabel={"Add Test Data"}
            handleClick={() => dispatch(showAddTestDataForm(projectId))}
            buttonIcon={CREATE_ICON}
            iconColor={WHITE_COLOR}
            className="common_Green_Button"
          />
        </div>
        <div className={styles["test_Data_label"]}>
          <HeaderButtonsGroupV2
            data={filterPermission}
            activeButton={activeFilter?.id}
            onClick={(e) => handleFilters(e)}
          />
          <CommonSearchV2
            searchDetails={(searchWord) => handleChangeValue(searchWord)}
            placeHolder="Search"
            searchKeyword={inputValue}
            setSearchKeyword={setInputValue}
            nameInput={nameInput}
          />
        </div>
      </div>
      <div className={styles["table_Details"]}>
        <TestDataDetailsV2
          recTestStepAction={data?.recTestStepProperty?.action}
          recTestStepTag={data?.tag}
          recTestStepId={data?.Id}
          setCurrentActionTab={setCurrentActionTab}
          projectId={projectId}
          isSkipStep={props.isSkipStep}
          data={data}
          setOpenAddTestDataModal={setOpenAddTestDataModal}
        />
      </div>
      {addTestDataFormVisible && openTestDataSetDrawerByTabName === tabName && data?.tag === tagForOpenDrawer && (
        <AddTestDataV2
          recTestStepTag={data.tag}
          recTestStepId={data.Id}
          projectId={props.projectId}
          openAddTestDataModal={openAddTestDataModal}
          setOpenAddTestDataModal={setOpenAddTestDataModal}
        />
      )}
    </Drawer>
  );
};

export default ManageTestDataV2;
