import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from './../../actions/CommonActions';
import { Row, Col, Button} from 'antd';
import { Link } from 'react-router-dom';
import Lodestone from "../../images/Lodestone.png";
class ForgotPassword extends Component {

 constructor(props) {
  super(props);
  this.state = { setUserNameError: false, userName: "", password: "", isAuthenicating: false, grant_type: "" };
  this.forgotPassword = this.forgotPassword.bind(this);
  this.handleChangeUserName = this.handleChangeUserName.bind(this);
  this.handleChangeEmail = this.handleChangeEmail.bind(this);
 }

 componentDidMount() {
  this.setState({ grant_type: "password" });
 }
 componentDidUpdate(prevProps) {
 }

 forgotPassword(e) {
  e.preventDefault();
  if(this.state.userName == ""){
    this.setState({ setUserNameError: true });
    return;
  }
  if(this.state.email == ""){
    this.setState({ setEmailError: true });
    return;
  }
  this.setState({ isAuthenicating: true });
  this.props.forgotPassword({"userName":this.state.userName,"email":this.state.email});
 }
 handleChangeUserName(e) {
  this.setState({ userName: e.target.value });
 }
 handleChangeEmail(e) {
  this.setState({ email: e.target.value });
 }


 render() {
   const {showPasswordSendNotificaton} = this.props;
  return (
    <div className={"limiter"}>
    <div className={"container-login100"}>
      <Row className={"wrap-login100"}>
        <Col className={"login100-form validate-form"}>
          <div className={"logo-container"}>
            <img src={Lodestone} className={"center-logo"}/>
          </div>
          <span className={"login100-form-title p-b-16"}>
            Welcome To
          </span>
          <span className={"login100-form-title p-b-48 login-product-name"}>
            Xpress
          </span>
          <div className={"wrap-input100 validate-input"}>
            <input className={"input100" + (this.state.setUserNameError ? " error " : "") } type="text" name="Username"  placeholder="User Name" 
            onChange={this.handleChangeUserName}/>  
          </div>
          <div className={"wrap-input100 validate-input"}>
            <input className={"input100" + (this.state.setEmailError ? " error " : "") } type="email" name="email"  placeholder="Sign-up Email" 
            onChange={this.handleChangeEmail}/>  
          </div>
          
          {showPasswordSendNotificaton ? 
            <div className="success-email-sent-message">
              An Email was sent to your registerd email. It containa a your new password.
            </div>
          : null
          }
          <div className={"container-login100-form-btn"}>
            <div className={"wrap-login100-form-btn"}>
              <Button type="primary" className={"login100-form-btn"} onClick={this.forgotPassword}>
                Reset Password
              </Button>
            </div>
          </div>
           <div className={"already-on-xpress"}>
              Already on Xpress?<Link to={"/Login"} > Log In </Link>
          </div>
        </Col>
      </Row>
    </div>
  </div>
  
    );
 }

}

function mapDispatchToProps(dispatch) {
 return  bindActionCreators(actions, dispatch)
}

function mapStateToProps(state) {
 return state.CommonReducer
}

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(ForgotPassword); 

