import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { TOOLTIP_BG_COLOR, VERIFY_TEST_DATA } from "../../../../../Constants";
import { DEEP_BLUE_COLOR, DISABLE_COLOR, SELECT_TEST_DATA_ICON_NEW } from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import {
  updateVerificationKey,
  updateVerificationValue,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import CustomButtonV2 from "../../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import ManageTestDataV2 from "../../ManageTestDataV2/ManageTestDataV2";
import styles from "./VerifyTestDataV2.module.scss";

const VerifyTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { data, disabled, recElementVerification } = props;

  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { projectId } = useSelector((state) => state.ProjectsReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);

  //Destructure of object
  const { tag } = data ? data : {};
  const { recStepVerifyElementProperties } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const recStepVerifyElementProperty = useMemo(() => {
    const verifyElementProperties = recStepVerifyElementProperties?.[0];
    return {
      ...verifyElementProperties,
      uuid: verifyElementProperties?.id ?? verifyElementProperties?.uuid ?? null,
    };
  }, [recStepVerifyElementProperties]);

  const disableSelectTestData = useMemo(
    () => disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag),
    [disabled, unSavedRecStepTag, tag]
  );
  return (
    <>
      <div>
        <div className={styles["select_Test_Data_Label"]}>Select Test Data value to verify</div>
        <div className={styles["select_Test_Data_Details"]}>
          <Select
            placeholder="Please select Test Data (Type: Output)"
            className={styles["styled_Select_Test_Data"]}
            disabled={disableSelectTestData}
            value={(recStepVerifyElementProperty.key && parseInt(recStepVerifyElementProperty.key)) || null}
            onChange={(value) => {
              dispatch(
                updateVerificationKey(
                  value,
                  recStepVerifyElementProperty.uuid,
                  tag,
                  recElementVerification?.guid,
                  testDataList,
                  recStepVerifyElementProperty.value
                )
              );
            }}
          >
            {!isEmpty(testDataList) &&
              testDataList?.map((v, k) => {
                return (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name + (v.value ? " (" + v.value + ")" : "")}
                  </Select.Option>
                );
              })}
          </Select>
          <Input
            className={styles["selected_Test_Data_Value"]}
            type="text"
            disabled={disableSelectTestData}
            placeholder="Value to compare with Test Data"
            value={recStepVerifyElementProperty.value}
            onChange={(e) =>
              dispatch(
                updateVerificationValue(
                  e.target.value,
                  recStepVerifyElementProperty.uuid,
                  tag,
                  true,
                  recElementVerification?.guid,
                  recStepVerifyElementProperty.key,
                  testDataList
                )
              )
            }
          />
          <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
            <CustomButtonV2
              buttonLabel="Select Test Data"
              handleClick={() => {
                !disableSelectTestData &&
                  dispatch(
                    visibleTestDataDrawer(
                      VERIFY_TEST_DATA,
                      tag,
                      recElementVerification?.guid,
                      recStepVerifyElementProperty
                    )
                  );
                dispatch(selectRecTestSteps(data));
              }}
              buttonIcon={SELECT_TEST_DATA_ICON_NEW}
              disabled={disableSelectTestData}
              iconColor={disableSelectTestData ? DISABLE_COLOR : DEEP_BLUE_COLOR}
              className={"common_Purple_Btn"}
            />
          </Tooltip>
        </div>
      </div>
      {openTestDataSetDrawerByTabName === VERIFY_TEST_DATA && tag === tagForOpenDrawer && (
        <ManageTestDataV2 {...props} projectId={projectId} tabName={VERIFY_TEST_DATA} />
      )}
     
    </>
  );
};
export default VerifyTestDataV2;
