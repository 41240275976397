import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import BackButton from "../CoreComponents/BackButtonComponent/BackButton";
import DeviceStringList from "./DeviceStringList";
import { setDeviceAssigneeDetail } from "../../actions/TestDataSet/TestDataSetCommonAction";

const DeviceStringTab = (props) => {
  const { projectId, selectedStepId } = props;
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  return (
    <>
      <Row justify="space-between" align="middle" className="fixInnerHeader w-100">
        <Col>
          <div className="mb-0 mainHeaderText ml-18">Manage Device Strings</div>
        </Col>
        <Col>
          <div className="closeIcon">
            <BackButton handleBack={() => dispatch(setDeviceAssigneeDetail(undefined))} isBlack={true} />
          </div>
        </Col>
      </Row>
      <div>
        <DeviceStringList projectId={projectId} stepType={stepType} selectedStepId={selectedStepId} />
      </div>
    </>
  );
};

export default DeviceStringTab;
