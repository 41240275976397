import React, { useEffect, useRef } from "react";
import _ from "lodash";
import "antd/dist/antd.css";
import { Drawer, Button, Select, Col, Input } from "antd";
import { useDispatch } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../CdnImagePath";
import { getDeviceByFarmId, resetDeviceFarmList } from "../../actions/ServerListAction";
import {
  BOTH,
  BOTH_LABEL,
  EXECUTION_LABEL,
  RECORDING_LABEL,
  SCENARIO_RECORDING,
  TEST_RUN_EXECUTION,
} from "../../Constants";
import { StyledSearchBox } from "../CoreComponents/StyledComponent/StyledComponents";
import styled from "styled-components";
const { Option } = Select;
export const StyledLabel = styled.span`
  font-weight: 500;
  font-size: 13px;
  color: #3d3d49;
`;

export const StyledDrawer = styled(Drawer)`
.ant-drawer-body {
  background-color: #FFFFFF !important;
}
`
const SearchDevices = ({
  showSearchPanel,
  closeSearchPanel,
  farmId,
  buildDevice,
  searchKeyword,
  setSearchData,
  searchData,
}) => {
  const dispatch = useDispatch();

  const searchDevice = async () => {
    closeSearchPanel();
    dispatch(resetDeviceFarmList());
    dispatch(
      getDeviceByFarmId({
        farmId: farmId,
        deviceName: searchData.deviceName,
        deviceFarmName: searchData.deviceFarmName,
        osVersion: searchData.osVersion,
        searchKeyword: searchKeyword ? searchKeyword : "",
        deviceFarmUse: searchData.deviceFarmUse.toString(),
      }, "", "", true, true)
    );
  };

  const resetDevice = () => {
    closeSearchPanel();
    resetData();
    dispatch(
      getDeviceByFarmId({
        farmId: farmId,
        deviceName: "",
        deviceFarmName: "",
        osVersion: "",
        searchKeyword: searchKeyword ? searchKeyword : "",
        deviceFarmUse: "",
      }, "", "", false, true)
    );
  };

  const closeDrawer = () => {
    closeSearchPanel();
  };

  const resetData = () => {
    setSearchData({
      deviceName: "",
      deviceFarmName: "",
      osVersion: "",
      searchKeyword: "",
      deviceFarmUse: [],
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSearchData({ ...searchData, [name]: value });
  };

  return (
    <>
      <StyledDrawer
        title={
          <div className="display-flex justify-space-between">
            <span className="font-color-darkBlue font-weight-500">Search Devices by</span>
            <span>
              <img src={CLOSE_ICON_GRAY} alt="close" className="cursor-pointer" onClick={() => closeDrawer()} />
            </span>
          </div>
        }
        closable={false}
        className="mt-100 "
        width={400}
        placement={buildDevice ? "left" : "right"}
        onClose={() => {
          closeDrawer();
        }}
        visible={showSearchPanel}
        footer={[
          <div className="display-flex justify-flex-end">
            <Button type="primary" className="lightGray" onClick={resetDevice}>
              Reset
            </Button>
            <Button type="primary" className="blue ml-10" onClick={searchDevice}>
              Apply
            </Button>
          </div>,
        ]}
      >
        <Col span={24}>
          <StyledLabel>Pool Name</StyledLabel>
          <StyledSearchBox
            className="mb-20 mt-5"
            placeholder="Enter Pool Name"
            value={searchData.deviceFarmName}
            allowClear
            name="deviceFarmName"
            onChange={handleChange}
          />

          <StyledLabel>Device Name</StyledLabel>
          <StyledSearchBox
            className="mb-20 mt-5"
            placeholder="Enter Device Name"
            value={searchData.deviceName}
            name="deviceName"
            allowClear
            onChange={handleChange}
          />
          <StyledLabel>Device Usage</StyledLabel>
          <Select
            placeholder="Enter Device Usage"
            mode="tags"
            className="mb-20 mt-5 w-100 border-radius-4 "
            value={searchData.deviceFarmUse}
            name="deviceFarmUses"
            onChange={(e) => {
              setSearchData({ ...searchData, deviceFarmUse: e });
            }}
            tokenSeparators={[","]}
          >
            <Option value={SCENARIO_RECORDING}>{RECORDING_LABEL}</Option>
            {!buildDevice && <Option value={TEST_RUN_EXECUTION}>{EXECUTION_LABEL}</Option>}
            <Option value={BOTH}>{BOTH_LABEL}</Option>
          </Select>
          <StyledLabel>OS Version</StyledLabel>
          <StyledSearchBox
            className="mb-20 mt-5"
            placeholder="Enter Os Version"
            value={searchData.osVersion}
            name="osVersion"
            allowClear
            onChange={handleChange}
          />
        </Col>
      </StyledDrawer>
    </>
  );
};

export default SearchDevices;