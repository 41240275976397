import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Input, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { ELEMENTS, TOOLTIP_BG_COLOR, splitValue } from "../../../../Constants";
import {
  applicationDrawerVisible,
  changeCustomElementAddApplicationString,
  changeCustomElementAddTestData,
  changeCustomElementFilterTypeValue,
  changeCustomElementSearch,
  changeCustomElementSearchValue,
  getApplicationStrings,
  getListOfLocalesForApplicationStrings,
  selectOutputTestDataForRecElement,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import ApplicationStringDrawer from "../../../RecTestStep/SingleColumnView/ApplicationStringDrawer/ApplicationStringDrawer";
import ManageTestDataV2 from "../ManageTestDataV2/ManageTestDataV2";
import styles from "./RecTestStepCustomElementDetailV2.module.scss";

const RecTestStepCustomElementDetailV2 = (props) => {
  const dispatch = useDispatch();
  const { data, recElement, disabled, isSkipStep, projectId } = props;

  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { getListOfLocales } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);

  //Destructure of recElement object
  const { searchKey, guid, useApplicationString, useTestData, testData, searchValue, filterType } = recElement
    ? recElement
    : {};

  //select use test data and application string details
  const testDataAndStringsCheckBox = [
    {
      label: "Use Test Data",
      checked: useTestData === 1,
      onChange: (e) => dispatch(changeCustomElementAddTestData(e.target.checked, data.tag, guid)),
      disableCondition: disabled || useApplicationString === 1,
    },
    {
      label: "Use Application String",
      checked: useApplicationString === 1,
      onChange: (e) => dispatch(changeCustomElementAddApplicationString(e.target.checked, data.tag, guid)),
      disableCondition: disabled || useTestData === 1,
    },
  ];

  const renderCustomDetails = () => {
    if (useTestData === 1) {
      return (
        <>
          <div className={styles["text-And-Button"]}>
            <div className={styles["label"]}>Test Data</div>
            <div>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <Button
                  className={`${styles.button} ${!disabled && styles.blue}`}
                  disabled={disabled}
                  onClick={() => {
                    dispatch(visibleTestDataDrawer(ELEMENTS, data?.tag, recElement?.guid));
                    dispatch(selectRecTestSteps(data));
                  }}
                >
                  <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                </Button>
              </Tooltip>
            </div>
          </div>
          <Select
            disabled={disabled}
            className={styles["selectMode"]}
            placeholder="Please Select Test Data"
            value={testData ? testData.id : undefined}
            onChange={(value) => {
              let selectedTestData = testDataList.find((item) => item.id == value);
              dispatch(
                selectOutputTestDataForRecElement(
                  data.tag,
                  guid,
                  selectedTestData.id + splitValue + selectedTestData.name + splitValue + selectedTestData.value
                )
              );
            }}
          >
            {!isEmpty(testDataList) &&
              testDataList?.map((v, k) => {
                return (
                  <Select.Option key={v.id} value={v.id}>
                    <span>
                      <span>{v.name + (v.value ? " (" + v.value + ")" : "")}</span>
                    </span>
                  </Select.Option>
                );
              })}
          </Select>
        </>
      );
    } else if (useApplicationString === 1) {
      return (
        <>
          <div className={styles["label"]}>Application String</div>
          <div className={styles["text-And-Button"]}>
            <div className={`${styles["label"]} ${styles["application-String-key"]}`}>
              {recElement?.applicationStringsKey ||
                recElement?.applicationStrings?.key ||
                "Please Select the application String"}
            </div>
            <div>
              <Tooltip title="Select Application String" color={TOOLTIP_BG_COLOR}>
                <Button
                  className={`${styles.button} ${!disabled && styles.blue}`}
                  disabled={disabled}
                  onClick={() => {
                    dispatch(applicationDrawerVisible(true, guid));
                    dispatch(getApplicationStrings(projectId, "", "", "", guid, data.tag));
                    isEmpty(getListOfLocales) && dispatch(getListOfLocalesForApplicationStrings());
                  }}
                >
                  <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                </Button>
              </Tooltip>
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className={styles["label"]}>Text to match</div>
          <Input
            placeholder="Enter the text"
            disabled={disabled}
            value={searchValue}
            onChange={(event) => dispatch(changeCustomElementSearchValue(event.target.value, data.tag, guid))}
            className={styles["input-box"]}
          />
        </>
      );
    }
  };

  return (
    <>
      <div>
        <div className={styles["custom-element-details"]}>
          <span className={styles["select-Element-label"]}>Select Element</span>
          <Select
            disabled={disabled}
            value={searchKey}
            onChange={(value) => dispatch(changeCustomElementSearch(value, data.tag, guid))}
            className={styles["selectMode"]}
          >
            <Select.Option value="contains_text">containing Text</Select.Option>
            <Select.Option value="text">having exact Text (Case Insensitive)</Select.Option>
            <Select.Option value="exact_text">having exact Text (Case Sensitive)</Select.Option>
          </Select>
          <div className={styles["select-use-data-or-string"]}>
            {testDataAndStringsCheckBox?.map((checkbox, index) => (
              <div className={styles.checkbox} key={index}>
                <Checkbox
                  disabled={checkbox.disableCondition}
                  checked={checkbox.checked}
                  onChange={checkbox.onChange}
                  className="selection-checkBox"
                />
                <span className={styles["checkbox-Text"]}>{checkbox.label}</span>
              </div>
            ))}
          </div>
        </div>
        {renderCustomDetails()}
        <>
          <div className={styles["label"]}>Element Type to match</div>
          <Input
            disabled={disabled}
            value={filterType}
            onChange={(event) => dispatch(changeCustomElementFilterTypeValue(event.target.value, data.tag, guid))}
            className={styles["input-box"]}
          />
        </>
        {openTestDataSetDrawerByTabName === ELEMENTS && data?.tag === tagForOpenDrawer && (
          <ManageTestDataV2 {...props} tabName={ELEMENTS} />
        )}
      </div>
      <ApplicationStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={projectId}
        disabled={disabled || isSkipStep}
      />
    </>
  );
};
export default RecTestStepCustomElementDetailV2;
