import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Popover, Spin, Tooltip } from "antd";
import confirm from "antd/lib/modal/confirm";
import _ from "lodash";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICON_BUILD_TEST_DATA, ICON_EDIT } from "../../../CdnImagePath";
import {
  ACTION_NOT_VIEW,
  ACTION_VIEW,
  All_RECORDS,
  CUSTOM,
  MY_TEST_BLOCKS,
  OWNED,
  PRIVATE,
  PUBLIC,
  SHARED,
  SHARED_WITH_ME,
  TEST_BLOCK_DESIGNER,
  TOOLTIP_BG_COLOR,
  defaultDateTimeFormat,
} from "../../../Constants";
import { APPLICATION_STRING_BLACK_ICON } from "../../../Constants/SvgConstants";
import { BUILD_TEST_DATA_SET } from "../../../Constants/TooltipConstants";
import {
  deleteModal,
  displayUTCDateFormatWithToolTip,
  getAdmins,
  getPermissionType,
  isSelectedAllIds,
  removeSelectedIds,
  showPermissionTypeIcon,
} from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { changeSelectTestStepBlocks, deleteTestBlock } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  addTestBlockStep,
  changeTestBlockListFilter,
  editTestStepBlocks,
  getListOfBlocksByProjectId,
  saveTestStepBlockRights,
  setSelectedTestStepBlocks,
} from "../../../actions/TestBlocksAction";
import {
  setApplicationAssigneeDetail,
  setDeviceAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import SelectAllBox from "../../CommonComponents/SelectAllBox";
import ActionComponent from "../../CoreComponents/ActionComponent/ActionComponent";
import PaginationComponent from "../../CoreComponents/PaginationComponent/PaginationComponent";
import PermissionDrawer from "../../CoreComponents/PermissionDrawer/PermissionDrawer";
import CommonSearch from "../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import PermissionFilter from "../../PermissionFilter/PermissionFilter";
import ImportTestStepActionsModal from "../../RecTestStep/ScenarioHeader/ScenarioSubHeader/ImportTestStepActionsModal/ImportTestStepActionsModal";
import styles from "./AllTestBlockStepList.module.scss";

const AllTestBlockStepList = (props) => {
  const { projectId, isTestStepBlocksLoadModel = false, isTestStepBlocksForImport = false, currentActionTab } = props;

  const dispatch = useDispatch();
  const { fetchingTestBlockList, testBlockListFilter } = useSelector((state) => state.TestBlockReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const { requestingDeleteRecTestStep, listOfBlocksByProjectId } = useSelector((state) => state.RecTestStepReducer);
  const {
    importRecTestStepModalVisible,
    stepType,
    selectedRecTestStepOrActionForImport,
    selectedRecTestStepOrActionForImportObject,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const [selectAllTestStepBlocks, setSelectAllTestStepBlocks] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionTestStepBlock, setPermissionTestStepBlock] = useState(null);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const filterPermission = [
    { id: OWNED, value: MY_TEST_BLOCKS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];

  useEffect(() => {
    if (permissionTestStepBlock?.recTestStepRights) {
      setMembers([...permissionTestStepBlock?.recTestStepRights]);
    }
  }, [permissionTestStepBlock]);

  useEffect(() => {
    !importRecTestStepModalVisible &&
      dispatch(getListOfBlocksByProjectId(projectId, 1, "", testBlockListFilter, importRecTestStepModalVisible));
  }, [projectId]);

  useEffect(() => {
    setSelectAllTestStepBlocks([]);
    setSearchKeyword([]);
  }, [importRecTestStepModalVisible]);

  const deleteConfirm = (recTestStep) => {
    deleteModal(
      recTestStep?.recTestStepProperty?.stepName
        ? `Do you want to delete "${recTestStep?.recTestStepProperty?.stepName}"?`
        : `Do you want to delete the selected ${selectAllTestStepBlocks?.length > 1 ? "Test Blocks" : "Test Block"}?`,
      () => {
        dispatch(
          deleteTestBlock(
            recTestStep ? [recTestStep.id] : selectAllTestStepBlocks,
            searchKeyword,
            setSelectAllTestStepBlocks
          )
        );
      }
    );
  };
  const testStepBlocksSelectionCheckboxType = (row) => {
    if (row?.allowDelete === ACTION_VIEW) {
      return (
        <div>
          <Checkbox
            className={styles.checkBox}
            checked={
              isTestStepBlocksForImport
                ? selectedRecTestStepOrActionForImport && selectedRecTestStepOrActionForImport?.includes(row?.id)
                : selectAllTestStepBlocks && selectAllTestStepBlocks?.includes(row?.id)
            }
            onChange={(e) => {
              if (isTestStepBlocksForImport) {
                dispatch(changeSelectTestStepBlocks(row, e.target.checked ? "ADD" : "REMOVE", projectId));
              }
              if (e.target.checked) {
                setSelectAllTestStepBlocks([...selectAllTestStepBlocks, row.id]);
              } else {
                setSelectAllTestStepBlocks(
                  selectAllTestStepBlocks && selectAllTestStepBlocks?.filter((i) => i !== row?.id)
                );
              }
            }}
          />
        </div>
      );
    }
  };

  const handleAction = (row) => {
    let arr = [];
    arr.push({
      title: "Device Strings",
      name: "deviceStrings",
    });
    if (row?.allowDelete === ACTION_VIEW) {
      arr.push({
        title: "Delete",
        name: "delete",
      });
    }
    if (loggedInUserDetails?.userEmail === row?.createdByEmail) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdByEmail) ||
            (row?.isPrivate === 1 &&
              !_.isEmpty(row?.recTestStepRights) &&
              loggedInUserDetails?.userEmail === row?.createdByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }

    return arr;
  };

  const handleActionChange = (actionName, data) => {
    setPermissionTestStepBlock(() => ({ ...data }));
    switch (actionName) {
      case "deviceStrings":
        dispatch(setDeviceAssigneeDetail(data));
        props.navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
        dispatch(editTestStepBlocks(data, projectId));
        break;
      case "delete":
        deleteConfirm(data);
        break;
      case PRIVATE:
        savePermission(PRIVATE, data);
        break;
      case PUBLIC:
        savePermission(PUBLIC, data);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const callPaginationAPI = (pageNumber) => {
    dispatch(
      getListOfBlocksByProjectId(
        projectId,
        pageNumber,
        searchKeyword,
        testBlockListFilter,
        importRecTestStepModalVisible
      )
    );
  };

  const savePermission = (Value, recTestStepData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      recTestStepId: recTestStepData?.id,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !_.isEmpty(result) ? result : [],
    };
    dispatch(saveTestStepBlockRights(obj, projectId, testBlockListFilter));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const setPermissionData = () => {
    if (permissionTestStepBlock?.isPrivate && !_.isEmpty(permissionTestStepBlock?.recTestStepRights)) {
      setPreviousPermission(permissionTestStepBlock?.recTestStepRights);
      setMembers([...permissionTestStepBlock?.recTestStepRights]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const handleFilters = (e) => {
    dispatch(changeTestBlockListFilter(e, projectId, searchKeyword));
  };

  const showCheckBoxInTableHeader =
    _.isEmpty(selectAllTestStepBlocks) &&
    !isTestStepBlocksLoadModel &&
    !_.isEmpty(listOfBlocksByProjectId?.data) &&
    listOfBlocksByProjectId?.data?.some((i) => i?.allowDelete === ACTION_VIEW);

  const columns = [
    {
      title: (
        <div className={!showCheckBoxInTableHeader && "table_Details"}>
          {showCheckBoxInTableHeader && (
            <span>
              <Checkbox
                className={styles.checkBox}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestStepBlocks([
                      ...selectAllTestStepBlocks,
                      ...(
                        listOfBlocksByProjectId?.data &&
                        listOfBlocksByProjectId?.data?.filter(
                          (i) => i?.allowDelete === ACTION_VIEW && !selectAllTestStepBlocks.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i?.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestStepBlocks(
                      removeSelectedIds(selectAllTestStepBlocks, listOfBlocksByProjectId?.data)
                    );
                  }
                }}
              />
            </span>
          )}
          Test Block Info
        </div>
      ),
      dataIndex: "testBlockInfo",
    },
    {
      title: "Step Details",
      dataIndex: "stepDetails",
    },
    {
      title: "Used In Scenarios",
      dataIndex: "usedInScenarios",
    },
    { title: "Actions", dataIndex: "actions", align: "center", hidden: isTestStepBlocksLoadModel },
  ]?.filter((item) => !item.hidden);

  const showListOfTestScenario = (testScenario, index) => {
    if (testScenario?.length > 1) {
      return (
        <>
          <span key={index}>
            {testScenario[0]}
            <Popover
              key={"Popover-" + index}
              content={testScenario?.slice(1)?.map((i, index) => (
                <div
                  className={` ${styles.popoverContents} ${testScenario?.length - 2 !== index && styles.borderBottom}`}
                >
                  <span className={styles.data}>{i}</span>
                </div>
              ))}
              title="Test Scenario Names"
            >
              <span className={styles.popoverDetails}>
                {testScenario?.slice(1).length === 1
                  ? `+${testScenario?.slice(1).length} other`
                  : `+${testScenario?.slice(1).length} others`}
              </span>
            </Popover>
          </span>
        </>
      );
    } else {
      return <span key={index}>{!_.isEmpty(testScenario) ? testScenario[0] : "N/A"}</span>;
    }
  };

  const testStepBlockDetails =
    listOfBlocksByProjectId &&
    listOfBlocksByProjectId?.data?.map((row, index) => ({
      key: row?.id,
      testBlockInfo: (
        <div className="display__flex">
          {(!isTestStepBlocksLoadModel || isTestStepBlocksForImport) && testStepBlocksSelectionCheckboxType(row)}
          <div className={row.allowDelete === ACTION_NOT_VIEW && "table_Details"}>
            <div className="display__flex">
              <span className={styles.permissionTooltip}>
                <Tooltip title={getPermissionType(row?.isPrivate, row?.recTestStepRights)} color={TOOLTIP_BG_COLOR}>
                  <FontAwesomeIcon
                    icon={showPermissionTypeIcon(getPermissionType(row?.isPrivate, row?.recTestStepRights))}
                  />
                </Tooltip>
              </span>
              <div className={styles.stepName}>{row?.recTestStepProperty?.stepName}</div>
            </div>
            <div>
              <span className={styles.label}> Created By: </span>
              {row?.createdBy || "NA"} On{" "}
              {row?.createdOn ? displayUTCDateFormatWithToolTip(row?.createdOn, defaultDateTimeFormat) : "NA"}
            </div>
            <div>
              <span className={styles.label}>Last Updated By: </span>
              {row?.lastUpdatedBy || "NA"} On{" "}
              {row?.updatedOn ? displayUTCDateFormatWithToolTip(row?.updatedOn, defaultDateTimeFormat) : "NA"}
            </div>
            <div className={styles.recTestStepRights}>
              <span className={styles.label}>Admins: </span>{" "}
              <div className={styles.admins}>
                {row?.recTestStepRights &&
                  getAdmins([
                    { name: row?.createdBy },
                    ...row?.recTestStepRights?.filter(
                      (i) => i?.isAdmin && i?.shouldAddOrRemove === 1 && i?.name !== row?.createdBy
                    ),
                  ])}
              </div>
            </div>
            {row?.stepsWithTestData && (
              <div>
                {" "}
                <span className={styles.label}>Steps with TestData:</span>
              </div>
            )}
            {row?.currentAndTotalTestDataProfile && (
              <div>
                {" "}
                <span className={styles.label}>Current TestData Profile & Total TestData Keys in Profile:</span>
              </div>
            )}
          </div>
        </div>
      ),

      stepDetails: (
        <>
          <div>
            <span className={styles.label}>Total Steps: </span>
            {row?.totalSteps}
          </div>
          <div>
            <span className={styles.label}>Screenshots: </span>
            {row?.stepsWithScreenShot}
          </div>
        </>
      ),
      usedInScenarios: (
        <div key={index}>
          <span className={styles.label}>Total: </span>
          {row?.testScenarios?.length}
          <div>{row?.testScenarios?.length >= 1 && showListOfTestScenario(row?.testScenarios, row?.id)}</div>
        </div>
      ),
      actions: !isTestStepBlocksLoadModel && (
        <div className={styles.ActionIcon}>
          {row?.allowEdit === ACTION_VIEW && (
            <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
              <img
                src={ICON_EDIT}
                alt="Edit"
                className={styles.editIcon}
                onClick={() => {
                  props.navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
                  dispatch(editTestStepBlocks(row, projectId));
                }}
              />
            </Tooltip>
          )}
          <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR}>
            <img
              src={ICON_BUILD_TEST_DATA}
              alt="BuildDataIcon"
              className={styles.buildTestDataIcon}
              onClick={() => {
                dispatch(setTestDataSetAssigneeDetail(row));
                props.navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
                dispatch(editTestStepBlocks(row, projectId));
              }}
            />
          </Tooltip>
          <Tooltip title={"Application Strings"} color={TOOLTIP_BG_COLOR}>
            <div className={styles.applicationStrings}>
              <SvgLoader
                iconName={APPLICATION_STRING_BLACK_ICON}
                handleClick={() => {
                  dispatch(setApplicationAssigneeDetail(row));
                  props.navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
                  dispatch(editTestStepBlocks(row, projectId));
                }}
              />
            </div>
          </Tooltip>
          <ActionComponent actions={handleAction(row)} data={row} handleActionChange={handleActionChange} />
        </div>
      ),
    }));

  return (
    <Spin
      spinning={!importRecTestStepModalVisible && (fetchingTestBlockList || requestingDeleteRecTestStep)}
      tip="Loading"
      className={!importRecTestStepModalVisible && !isTestStepBlocksLoadModel ? `${styles.spinnerCenter}` : ""}
    >
      <div>
        <div className={styles.innerHeader}>
          {!isTestStepBlocksLoadModel && (
            <div className={styles.testStepBlocksHeader}>
              <div className={styles.testBlocksText}> Test Blocks </div>
              <div className={styles.testBlockList}>
                <Button
                  type="primary"
                  size="large"
                  className={styles.BuildTestBlockButton}
                  onClick={() =>
                    confirm({
                      title: <div>Do you want to create a new Test Block?</div>,
                      okText: "Create",
                      onOk: () => dispatch(addTestBlockStep(searchKeyword, props.navigateToEditBuildScenario)),
                    })
                  }
                >
                  Build Test Block
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={!isTestStepBlocksForImport && `${styles.pageScroll}`}>
          <div className={!isTestStepBlocksForImport && `${styles.importModel}`}>
            <div>
              {!isTestStepBlocksLoadModel && (
                <div className="display__flex">
                  <PermissionFilter
                    permissionValue={filterPermission}
                    active={testBlockListFilter}
                    handleFilters={handleFilters}
                  />
                </div>
              )}
            </div>
            {!importRecTestStepModalVisible && (
              <div className={styles.importRecTestStepModal}>
                <CommonSearch
                  searchDetails={(searchWord) => {
                    dispatch(getListOfBlocksByProjectId(props.projectId, 1, searchWord, testBlockListFilter));
                  }}
                  placeHolder={isTestStepBlocksForImport ? "Search TestBlockSteps" : "Search By Test Blocks Name"}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                  importSteps={isTestStepBlocksForImport ? true : false}
                />
              </div>
            )}
          </div>
          <div className={!isTestStepBlocksForImport && `${styles.testStepBlockForImport}`}>
            <div>
              {!isTestStepBlocksLoadModel && !_.isEmpty(selectAllTestStepBlocks) && (
                <SelectAllBox
                  isChecked={isSelectedAllIds(
                    selectAllTestStepBlocks,
                    listOfBlocksByProjectId?.data?.filter((i) => i.allowDelete === ACTION_VIEW)
                  )}
                  selectAllIds={selectAllTestStepBlocks}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllTestStepBlocks([
                        ...selectAllTestStepBlocks,
                        ...(
                          listOfBlocksByProjectId?.data &&
                          listOfBlocksByProjectId?.data?.filter(
                            (i) => i?.allowDelete === ACTION_VIEW && !selectAllTestStepBlocks.includes(i?.id) && i?.id
                          )
                        )?.map((i) => i?.allowDelete === ACTION_VIEW && i.id),
                      ]);
                    } else {
                      setSelectAllTestStepBlocks(
                        removeSelectedIds(selectAllTestStepBlocks, listOfBlocksByProjectId?.data)
                      );
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                  isDisable={_.isEmpty(listOfBlocksByProjectId?.data)}
                />
              )}
            </div>
          </div>
          {isTestStepBlocksForImport ? (
            !_.isEmpty(listOfBlocksByProjectId?.data) && (
              <>
                {listOfBlocksByProjectId?.data.length > 0 &&
                  listOfBlocksByProjectId?.data.map((recTestStep, index) => (
                    <ImportTestStepActionsModal
                      recTestStep={recTestStep}
                      key={index}
                      index={index}
                      projectId={projectId}
                      stepType={stepType}
                      currentActionTab={currentActionTab}
                      name={recTestStep.name}
                    />
                  ))}
              </>
            )
          ) : (
            <div className={styles.mainTableComponents}>
              <TableComponent
                columns={columns}
                data={testStepBlockDetails}
                selected={selectAllTestStepBlocks}
                handleSelectRow={(selectedRow) => ({
                  onClick: () => {
                    let data =
                      listOfBlocksByProjectId && listOfBlocksByProjectId?.data?.find((i) => i?.id === selectedRow?.key);
                    dispatch(
                      setSelectedTestStepBlocks({
                        id: data.id,
                        name: data.recTestStepProperty?.stepName,
                        tag: data.tag,
                        testRailTestCaseId: data.testRailTestCaseId,
                        testRailTestSuiteId: data.testRailTestSuiteId,
                        platform: data.platform,
                        recTestStepProperty: data.recTestStepProperty,
                      })
                    );
                  },
                })}
                selectedRowId={[props.selectedTestBlocksId]}
              />
            </div>
          )}
          <div className={styles.paginationSection}>
            {!_.isEmpty(listOfBlocksByProjectId?.data) && !isTestStepBlocksLoadModel && (
              <PaginationComponent
                currentPageItems={listOfBlocksByProjectId?.pageItems}
                totalItems={listOfBlocksByProjectId?.totalItems}
                currentPage={listOfBlocksByProjectId?.currentPage}
                totalPage={listOfBlocksByProjectId?.totalCount}
                callPaginationAPI={callPaginationAPI}
              />
            )}
          </div>
        </div>

        {window.pageYOffset > 300 && (
          <div className={styles.selectAllBox}>
            {!isTestStepBlocksLoadModel && (
              <SelectAllBox
                isChecked={isSelectedAllIds(
                  selectAllTestStepBlocks,
                  listOfBlocksByProjectId?.data?.filter((i) => i.allowDelete === ACTION_VIEW)
                )}
                selectAllIds={selectAllTestStepBlocks}
                handleChangeCheck={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestStepBlocks([
                      ...selectAllTestStepBlocks,
                      ...(
                        listOfBlocksByProjectId?.data &&
                        listOfBlocksByProjectId?.data?.filter(
                          (i) => i?.allowDelete === ACTION_VIEW && !selectAllTestStepBlocks.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i?.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestStepBlocks(
                      removeSelectedIds(selectAllTestStepBlocks, listOfBlocksByProjectId?.data)
                    );
                  }
                }}
                deleteConfirm={deleteConfirm}
                isDisable={_.isEmpty(listOfBlocksByProjectId?.data)}
              />
            )}
          </div>
        )}
      </div>
      <PermissionDrawer
        openPermissionDrawer={openPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        permissionData={permissionTestStepBlock}
        savePermission={savePermission}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        setPermissionData={setPermissionData}
        ownerEmailId={permissionTestStepBlock?.createdByEmail}
        notShowExecutionCheck={true}
        drawerTitle={"Test Block"}
      />
    </Spin>
  );
};
export default AllTestBlockStepList;
