import React from "react";
import { Table } from "antd";

const TableComponentV2 = (props) => {
  const { data, columns, handleSelectRow, selected, selectedRowId, testDataSetModal, className } = props;

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        onRow={handleSelectRow}
        pagination={false}
        rowClassName={(record) =>
          selected?.includes(record?.key) || selectedRowId?.includes(record?.key) ? "activeRow" : ""
        }
        scroll={{
          x: testDataSetModal && 1000,
          y: "60vh",
        }}
        className={className}
      />
    </>
  );
};

export default TableComponentV2;
