import { PlusCircleOutlined } from "@ant-design/icons";
import { Checkbox, Col, Dropdown, Menu, Row, Spin, Tabs, Tooltip, Popover } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { APPLICATION_STRING_TAB, TOOLTIP_BG_COLOR } from "../../../Constants";
import { ICON_DELETE_WITH_BG, ICON_EDIT } from "../../../Constants/SvgConstants";
import { removeSelectedKeys } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { importApplicationStringsFormVisible } from "../../../actions/ApplicationStrings";
import {
  addApplicationStringsFormVisible,
  deleteApplicationString,
  getApplicationStrings,
  loadEditApplicationStringDetail,
  setApplicationStringCurrentTab,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import SelectAllBox from "../../CommonComponents/SelectAllBox";
import PaginationComponent from "../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./ApplicationStringsList.module.scss";

const ApplicationStringsList = (props) => {
  const { projectId } = props;
  const {
    getApplicationString,
    applicationStringLoader,
    getApplicationStringPageCount,
    getApplicationStringCurrentCount,
    getApplicationStringCurrentTab,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectImportCheckBox, setSelectImportCheckBox] = useState([]);
  const [selectAllCustomCheckBox, setSelectAllCustomCheckBox] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(null);
  const selectCustomStringsTab = getApplicationStringCurrentTab === APPLICATION_STRING_TAB.CUSTOM;

  useEffect(() => {
    dispatch(
      getApplicationStrings(
        projectId,
        searchKeyword,
        "",
        getApplicationStringCurrentCount,
        "",
        "",
        "",
        getApplicationStringCurrentTab
      )
    );
  }, [projectId, dispatch]);

  const confirmClear = (deleteAllStrings) => {
    const action = deleteAllStrings ? "delete all" : "delete the selected";
    const stringsType = selectCustomStringsTab ? "custom" : "imported";
    const message = `Are you sure you want to ${action} items?`;
    setOpenConfirmModal({
      key: selectCustomStringsTab ? [...selectAllCustomCheckBox] : [...selectImportCheckBox],
      message,
    });
  };

  const isSelectedAllIStrings = (selectedIds, records) => {
    let selectedRecords = records && records?.filter((i) => selectedIds?.includes(i?.key));
    return records?.length && selectedRecords?.length === records?.length;
  };

  const clearAllApplicationStrings = () => {
    return (
      <div
        className={`${applicationStringLoader && styles.disableclearAllString} ${styles.clearAllStrings}`}
        onClick={() => !applicationStringLoader && confirmClear(true)}
      >{`Delete All ${selectCustomStringsTab ? "Custom" : "Imported"} Strings`}</div>
    );
  };

  const applicationStringSelectionCheckboxType = (row) => {
    return (
      <span>
        <Checkbox
          className={styles.checkBox}
          checked={
            selectCustomStringsTab
              ? selectAllCustomCheckBox && selectAllCustomCheckBox?.includes(row?.key)
              : selectImportCheckBox && selectImportCheckBox?.includes(row?.key)
          }
          onChange={(e) => {
            if (e.target.checked) {
              selectCustomStringsTab
                ? setSelectAllCustomCheckBox([...selectAllCustomCheckBox, row?.key])
                : setSelectImportCheckBox([...selectImportCheckBox, row?.key]);
            } else {
              selectCustomStringsTab
                ? setSelectAllCustomCheckBox(
                    selectAllCustomCheckBox && selectAllCustomCheckBox?.filter((i) => i !== row?.key)
                  )
                : setSelectImportCheckBox(selectImportCheckBox && selectImportCheckBox?.filter((i) => i !== row?.key));
            }
          }}
        />
      </span>
    );
  };

  const handleCheckboxChange = (isChecked, selectedCheckBox, setSelectedCheckBox) => {
    if (isChecked) {
      setSelectedCheckBox([
        ...selectedCheckBox,
        ...(
          getApplicationString?.data &&
          getApplicationString?.data?.filter((i) => !selectedCheckBox.includes(i?.key) && i?.key)
        )?.map((i) => i?.key),
      ]);
    } else {
      setSelectedCheckBox(removeSelectedKeys(selectedCheckBox, getApplicationString?.data));
    }
  };

  const columns = [
    {
      title: (
        <div>
          {(selectCustomStringsTab ? _.isEmpty(selectAllCustomCheckBox) : _.isEmpty(selectImportCheckBox)) &&
            getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL && (
              <span>
                <Checkbox
                  className="mr-10"
                  onChange={(e) => {
                    selectCustomStringsTab
                      ? handleCheckboxChange(e.target.checked, selectAllCustomCheckBox, setSelectAllCustomCheckBox)
                      : handleCheckboxChange(e.target.checked, selectImportCheckBox, setSelectImportCheckBox);
                  }}
                />
              </span>
            )}
          Key
        </div>
      ),
      dataIndex: "name",
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];

  const applicationStringsData =
    getApplicationString &&
    getApplicationString?.data?.map((row) => ({
      key: row?.id,
      name: (
        <div className={styles.checkBoxOrKey}>
          {getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL && applicationStringSelectionCheckboxType(row)}
          <div className={styles.applicationStringKey}>{row?.key}</div>
        </div>
      ),
      value: (
        <Popover
          color={TOOLTIP_BG_COLOR}
          title="Locales"
          content={row?.values.map((data, i) => 
            <div key={data}>
              <span style={{ marginRight: 5 }}>{i + 1}</span>
              {data.locale + " : " + data.value}
              <br></br>
            </div>)}
        >
          {`${row?.locale} ${row?.locale === 1 ? " Locale" : " Locales"}`}
        </Popover>
      ),
      action: (
        <div className={styles.actionIcons}>
          <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader
                iconName={ICON_EDIT}
                handleClick={() => dispatch(loadEditApplicationStringDetail(projectId, row?.key, row?.isImport))}
              />
            </div>
          </Tooltip>
          <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={ICON_DELETE_WITH_BG} handleClick={() => setOpenConfirmModal([row.key])} />
            </div>
          </Tooltip>
        </div>
      ),
    }));

  const handleOnChange = (e) => {
    dispatch(setApplicationStringCurrentTab(e));
    dispatch(getApplicationStrings(projectId, searchKeyword, "", "", "", "", "", e));
  };

  const selectAllStrings = (selectedStrings, setSelectedStrings) => {
    return (
      <div className={styles.selectBox}>
        <SelectAllBox
          selectAllIds={selectedStrings}
          isChecked={isSelectedAllIStrings(selectedStrings, getApplicationString?.data)}
          handleChangeCheck={(isChecked) => {
            if (isChecked) {
              setSelectedStrings([
                ...selectedStrings,
                ...(
                  getApplicationString?.data &&
                  getApplicationString?.data?.filter((i) => !selectedStrings.includes(i?.key) && i?.key)
                )?.map((i) => i.key),
              ]);
            } else {
              setSelectedStrings(removeSelectedKeys(selectedStrings, getApplicationString?.data));
            }
          }}
          deleteConfirm={confirmClear}
          isDisable={_.isEmpty(selectedStrings)}
        />
      </div>
    );
  };

  return (
    <div className={styles.pageScroll}>
      <Row>
        <Col span={24} className={styles.stylesCol}>
          <Row className={styles.styledRow}>
            <Col className={styles.addIcon}>
              <Tooltip title="Add Application Strings" color={TOOLTIP_BG_COLOR}>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item onClick={() => dispatch(importApplicationStringsFormVisible(true, projectId))}>
                        Import From XML
                      </Menu.Item>
                      <Menu.Item onClick={() => dispatch(addApplicationStringsFormVisible(true))}>
                        Add Custom Value
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <PlusCircleOutlined disabled={false} style={{ fontSize: "150%" }} />
                </Dropdown>
              </Tooltip>
            </Col>
            <Col>
              <CommonSearch
                placeHolder="Search by Application string name"
                searchDetails={(searchText) =>
                  dispatch(
                    getApplicationStrings(projectId, searchText, "", "", "", "", "", getApplicationStringCurrentTab)
                  )
                }
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Tabs defaultActiveKey={"ALL"} activeKey={getApplicationStringCurrentTab} onChange={(e) => handleOnChange(e)}>
        <Tabs.TabPane tab={"All"} key={APPLICATION_STRING_TAB.ALL}>
          <Row>
            <Col span={24}>
              <div className={styles.table}>
                <Spin spinning={applicationStringLoader} tip="Loading">
                  <TableComponent data={applicationStringsData} columns={columns} />
                </Spin>
              </div>
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Imported"} key={APPLICATION_STRING_TAB.IMPORTED}>
          <div className={styles.table}>
            <Spin spinning={applicationStringLoader} tip="Loading">
              <div
                className={`${styles.selectBoxOrClear} ${
                  getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL &&
                  !_.isEmpty(selectImportCheckBox) &&
                  styles.justify_end
                }`}
              >
                {getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL &&
                  !_.isEmpty(selectImportCheckBox) &&
                  selectAllStrings(selectImportCheckBox, setSelectImportCheckBox)}
                <div>
                  {!_.isEmpty(getApplicationString?.data) && (
                    <div className={styles.stylesCol}>{clearAllApplicationStrings()}</div>
                  )}
                </div>
              </div>
              <TableComponent data={applicationStringsData} columns={columns} />
            </Spin>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={"Custom"} key={APPLICATION_STRING_TAB.CUSTOM}>
          <div className={styles.table}>
            <div
              className={`${styles.selectBoxOrClear} ${
                getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL &&
                !_.isEmpty(selectAllCustomCheckBox) &&
                styles.justify_end
              }`}
            >
              {getApplicationStringCurrentTab !== APPLICATION_STRING_TAB.ALL &&
                !_.isEmpty(selectAllCustomCheckBox) &&
                selectAllStrings(selectAllCustomCheckBox, setSelectAllCustomCheckBox)}
              <div>
                {!_.isEmpty(getApplicationString?.data) && (
                  <div className={styles.stylesCol}>{clearAllApplicationStrings()}</div>
                )}
              </div>
            </div>
            <Spin spinning={applicationStringLoader} tip="Loading">
              <TableComponent data={applicationStringsData} columns={columns} />
            </Spin>
          </div>
        </Tabs.TabPane>
      </Tabs>
      {!_.isEmpty(getApplicationString?.data) && (
        <div className={styles.pagination}>
          <PaginationComponent
            currentPageItems={getApplicationString?.pageItems}
            totalItems={getApplicationString?.totalItems}
            currentPage={getApplicationString?.currentPage}
            totalPage={getApplicationString?.totalCount}
            callPaginationAPI={(pageNumber) =>
              dispatch(
                getApplicationStrings(
                  projectId,
                  searchKeyword,
                  "",
                  pageNumber,
                  "",
                  "",
                  "",
                  getApplicationStringCurrentTab
                )
              )
            }
          />
        </div>
      )}
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            deleteApplicationString(
              openConfirmModal?.key ? openConfirmModal?.key : openConfirmModal,
              getApplicationStringCurrentTab,
              getApplicationStringPageCount
            )
          );
          if (selectCustomStringsTab) {
            setSelectAllCustomCheckBox([]);
          } else {
            setSelectImportCheckBox([]);
          }
          setOpenConfirmModal(null);
        }}
        handleCancel={() => setOpenConfirmModal(null)}
        modalOpen={!!openConfirmModal}
        modalTitle={"Confirm Deletion"}
        modalContent={
          openConfirmModal?.message ? openConfirmModal?.message : "Are you sure you want to delete this item?"
        }
        okText={"Delete"}
        isDeleteModal={true}
        modalNote="Note: Application String will be removed from associated steps/elements."
      />
    </div>
  );
};

export default ApplicationStringsList;
