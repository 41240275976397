import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Modal } from "antd";
import isEmpty from "lodash/isEmpty";
import { platformAbbrevMapping, selectAllData } from "../../Util";
import { All_RECORDS, MY_SCENARIOS, OWNED, SHARED, SHARED_WITH_ME } from "../../Constants";
import { setPreviousSearchValue } from "../../actions/CommonActions";
import {
  changeScenarioListFilter,
  changeSearchScenarioKeyword,
  createNewRecTestScenario,
  deleteRecTestScenario,
  getRecTestScenarioList,
  hideDeviceSelectionModal,
  navigateToRecordingScreen,
} from "../../actions/RecTestScenarioAction";
import CommonHeaderV2 from "../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import HeaderButtonsGroupV2 from "../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import PaginationComponentV2 from "../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../CoreComponents/SearchComponent/CommonSearchV2";
import FreeDeviceList from "../RecTestScenario/FreeDeviceList";
import RecTestScenarioListV2 from "./RecTestScenarioListV2";
import styles from "./RecTestScenarioV2.module.scss";

const filterPermission = [
  { id: OWNED, value: MY_SCENARIOS },
  { id: SHARED, value: SHARED_WITH_ME },
  { id: "", value: All_RECORDS },
];

const RecTestScenarioPageV2 = (props) => {
  const dispatch = useDispatch();
  const { isScenarioLoadModel, projectId, navigateToEditBuildScenario, platform } = props;

  const {
    selectedFreeDeviceForRecording,
    deviceSelectionModalVisible,
    freeDeviceListForRecording,
    recTestScenarioList,
    totalRecTestScenariosPage,
    currentRecTestScenariosPage,
    totalPageItem,
    totalItems,
    selectedRecTestScenarioId,
    searchScenarioKeyword,
    scenarioListFilter,
  } = useSelector((state) => state.RecTestScenarioReducer);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testDataSetAssigneeDetail } = useSelector((state) => state.TestDataSetReducer);

  const [selectAllScenario, setSelectAllScenario] = useState([]);
  const [openDeleteTestScenarioModal, setOpenDeleteTestScenarioModal] = useState({ visible: false, data: {} });
  const [openCreateTestScenarioModal, setOpenCreateTestScenarioModal] = useState(false);

  useEffect(() => {
    dispatch(changeSearchScenarioKeyword(""));
  }, [stepType]);

  useEffect(() => {
    dispatch(getRecTestScenarioList("", 1, projectId, searchScenarioKeyword, "", ""));
    dispatch(changeSearchScenarioKeyword(""));
    dispatch(setPreviousSearchValue(searchScenarioKeyword));
    setSelectAllScenario([]);
  }, [projectId, testDataSetAssigneeDetail]);

  const selectedScenarioId = useMemo(
    () =>
      openDeleteTestScenarioModal?.data?.id
        ? [openDeleteTestScenarioModal?.data?.id]
        : selectedRecTestScenarioId
        ? [selectedRecTestScenarioId]
        : selectAllScenario,
    [openDeleteTestScenarioModal?.data?.id, selectedRecTestScenarioId, selectAllScenario]
  );

  const handleDelete = () => {
    dispatch(
      deleteRecTestScenario(
        {
          scenarioId: selectedScenarioId,
          projectId: projectId,
          isDelete: 1,
        },
        setSelectAllScenario
      )
    );
    setOpenDeleteTestScenarioModal({ visible: false, data: {} });
  };

  const deleteConfirm = () => {
    setOpenDeleteTestScenarioModal({ visible: true, data: {} });
  };

  const handleFilters = useCallback(
    (e) => dispatch(changeScenarioListFilter(e, projectId, searchScenarioKeyword)),
    [dispatch, projectId, searchScenarioKeyword]
  );

  const generateTestScenarioModalProps = () => {
    if (openCreateTestScenarioModal) {
      return {
        handleOk: () => {
          dispatch(
            createNewRecTestScenario(platformAbbrevMapping(platform, false), "", projectId, navigateToEditBuildScenario)
          );
          setOpenCreateTestScenarioModal(false);
        },
        handleCancel: () => setOpenCreateTestScenarioModal(false),
        modalOpen: openCreateTestScenarioModal,
        modalTitle: "Confirm Test Scenario Creation",
        modalContent: "Are you sure you want to create new Test Scenario?",
        okText: "Create",
      };
    } else {
      return {
        handleOk: () => handleDelete(),
        handleCancel: () => setOpenDeleteTestScenarioModal({ visible: false, data: {} }),
        modalOpen: openDeleteTestScenarioModal?.visible,
        modalTitle: "Confirm Deletion",
        modalContent: `Are you sure you want to delete ${
          selectAllScenario && selectAllScenario.length > 1 ? "these items?" : "this item?"
        } This action cannot be undone.`,
        okText: "Delete",
        isDeleteModal: true,
      };
    }
  };

  return (
    <div>
      <>
        {!isScenarioLoadModel && (
          <CommonHeaderV2
            headerText={"Test Scenarios"}
            label={"Build Scenario"}
            handleOk={() => setOpenCreateTestScenarioModal(true)}
          />
        )}
        <div className={styles["pageScroll"]}>
          <div className={styles["sub-header"]}>
            {!isScenarioLoadModel && (
              <HeaderButtonsGroupV2
                data={filterPermission}
                activeButton={scenarioListFilter?.id}
                onClick={(e) => handleFilters(e)}
              />
            )}
            {!isScenarioLoadModel && (
              <CommonSearchV2
                placeHolder="Search by Scenario name"
                searchDetails={(searchText) =>
                  dispatch(getRecTestScenarioList("", 1, projectId, searchScenarioKeyword, "", ""))
                }
                setSearchKeyword={(value) => dispatch(changeSearchScenarioKeyword(value))}
              />
            )}
          </div>
          <Divider className="m-0" />
          <div className="table_Details_scroll">
            <div className="table_Details__wrapper">
              {!isScenarioLoadModel && !isEmpty(selectAllScenario) && (
                <div className={styles["select--all-box"]}>
                  {selectAllData(selectAllScenario, setSelectAllScenario, recTestScenarioList, deleteConfirm)}
                </div>
              )}
              <RecTestScenarioListV2
                selectAllScenario={selectAllScenario}
                isScenarioLoadModel={isScenarioLoadModel}
                setSelectAllScenario={setSelectAllScenario}
                setOpenDeleteTestScenarioModal={setOpenDeleteTestScenarioModal}
                {...props}
              />
              <div className="pagination_Section">
                {!isEmpty(recTestScenarioList) && (
                  <PaginationComponentV2
                    currentPageItems={totalPageItem}
                    totalItems={totalItems}
                    currentPage={currentRecTestScenariosPage}
                    totalPage={totalRecTestScenariosPage}
                    callPaginationAPI={(pageNumber) =>
                      currentRecTestScenariosPage !== pageNumber &&
                      dispatch(getRecTestScenarioList("", pageNumber, projectId, searchScenarioKeyword, "", ""))
                    }
                  />
                )}
              </div>
            </div>
          </div>
          <Modal
            title="Device selection for Record scenario"
            visible={deviceSelectionModalVisible}
            onOk={() => dispatch(navigateToRecordingScreen())}
            onCancel={() => dispatch(hideDeviceSelectionModal())}
            footer={[
              <Button key="back" onClick={() => dispatch(hideDeviceSelectionModal())}>
                Cancel
              </Button>,
              <Button
                disabled={selectedFreeDeviceForRecording && selectedFreeDeviceForRecording.length > 0 ? false : true}
                key="submit"
                type="primary"
                onClick={() => dispatch(navigateToRecordingScreen())}
              >
                Record
              </Button>,
            ]}
          >
            {freeDeviceListForRecording && <FreeDeviceList />}
          </Modal>
        </div>
      </>
      {(openDeleteTestScenarioModal?.visible || openCreateTestScenarioModal) && (
        <CommonConfirmationModalV2 {...generateTestScenarioModalProps()} />
      )}
    </div>
  );
};

export default RecTestScenarioPageV2;
