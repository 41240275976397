import React from "react";
import SvgLoader from "../../Util/SvgLoader";
import styles from "./EmptyRecordsV2.module.scss";

const EmptyRecordsV2 = ({ emptyRecordsImg, description }) => {
  return (
    <div className={styles['empty_Records']}>
      <SvgLoader iconName={emptyRecordsImg} defaultCursor={true} />
      <div>
        <span className={styles['description']}>{description}</span>
      </div>
    </div>
  );
};

export default EmptyRecordsV2;
