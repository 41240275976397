import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/HomeAction";
import * as recordingActions from "../../actions/Recording";
import {Modal} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import {  DEVICE_STATUS} from '../../Constants';
import { library } from "@fortawesome/fontawesome-svg-core";
import history from "../../history";
import {PROXY_STATUS} from "../../Constants";
import {endDeviceSession} from "./../../Util";
library.add(faArrowLeft);

class GoToScreenComponent extends Component {

constructor(props) {
 super(props); 
 this.state = {
  color: props.color,
  showConfirmDialog: false,
  selectedKey: null,
  backClicked:false,
  abortMessage: "You Are In Recording Mode. Are You Sure Want To Abort?",
  abortTitle: "Abort Recording"
};
}

componentDidMount() {
  this._isMounted = true;
  //window.history.pushState(null, null, null);
  window.onpopstate = (event)=> {
    if(this._isMounted) {
      if(this.props.tipmessage == "Back To Home"){
        const {forceStopLogs} = this.props.actions;
        var isConfirm = forceStopLogs(this.props.serverId, this.props.targetUniqueId);
        if(!isConfirm){
          history.push("/farms/"+(this.props.deviceFarmId?this.props.deviceFarmId:""));
        }
      }
    } 
  }
}
onCancel(){
  this.setState({
      showConfirmDialog: false
  });
} 

handleCloseSession(e){
  
  if(this.props.getCurrentRecordingState() == DEVICE_STATUS.INTERACTION){
      if(this.props.actions.logStarted()){
        endDeviceSession(this.props.actions,this.props.match.params.platform,this.props.match.params.serverId,this.props.match.params.targetUniqueId,
          this.props.match.params.deviceFarmId,this.props.RecordingReducer.logStarted, ( this.props.RecordingReducer.proxyStatus == PROXY_STATUS.STARTED ));
      }else if(this.props.getCurrentRecordingState()==DEVICE_STATUS.RECORDING){
          this.setState({
              abortMessage: "You are in Recording mode. Are you sure want to abort?",
              abortTitle: "Abort Recording",
              showConfirmDialog: true,
          }); 
        }
        e.preventDefault();
  }
}

confirmAbort(){
  
  if(this.props.getCurrentRecordingState()==DEVICE_STATUS.RECORDING){
    endDeviceSession(this.props.actions,this.props.match.params.platform,this.props.match.params.serverId,this.props.match.params.targetUniqueId,
      this.props.match.params.deviceFarmId,this.props.RecordingReducer.logStarted, ( this.props.RecordingReducer.proxyStatus == PROXY_STATUS.STARTED ));
    this.setState({
      showConfirmDialog: false,
     });
  }
}
 
// handleAlertConfirm(){
//   this.props.forceStopLogs(this.props.serverId, this.props.targetUniqueId);
// }

  render() {
    const {testElement,testaction}=this.props;
    return (
      <React.Fragment>
          <div className={"goto-icon"} onClick={this.handleCloseSession.bind(this)} title={this.props.tipmessage} >
            {/* <Tooltip title={this.props.tipmessage} placement={this.props.toolTipPlacement ? this.props.toolTipPlacement : "bottom"} > */}
             {/* <Link to={this.props.screenRoute} > */}
                <FontAwesomeIcon icon={faArrowLeft} />  
              {/* </Link> */}
            {/* </Tooltip> */}
        </div>
       {/* <Popconfirm
          disabled={this.props.alertUser}
          title="Are you sure delete this task?"
          onConfirm={this.handleAlertConfirm}
          onCancel={this.props.alertCancel()}
          okText="Yes"
          cancelText="No">
        </Popconfirm> */}
        <Modal
            title={this.state.abortTitle}
            visible={this.state.showConfirmDialog}
            onOk={this.confirmAbort.bind(this)}
            onCancel={this.onCancel.bind(this)}
            okText='Yes'
            okType='danger'
            cancelText='No'
        >
          {this.state.abortMessage}
        </Modal>
     </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
    return  {
      actions: bindActionCreators(actions,dispatch),
      recordingActions: bindActionCreators(recordingActions,dispatch)
    }
}

function mapStateToProps(state) {
     return {
              RecordingReducer:state.RecordingReducer,
              HomeReducer:state.HomeReducer
            }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoToScreenComponent); 
