import React, { useEffect, useState } from "react";
import {
  ALL_RUNS,
  COMPLETED_JOB,
  IN_QUEUE_JOB,
  RUNNING_JOB,
  TEST_PLAN_RESULT_TYPE
} from "../../Constants";
import TestPlanResultV2 from "../TestPlanV2/TestPlanResultV2/TestPlanResultListsV2/TestPlanResultV2";

const ReportManagerV2 = (props) => {
  const { projectId, orgId } = props;
  const [selectedExecutionManagerSubTab, setSelectedExecutionManagerSubTab] = useState(ALL_RUNS);

  useEffect(() => {
    setSelectedExecutionManagerSubTab(ALL_RUNS);
  }, [projectId]);


  return (
    <div>
      {selectedExecutionManagerSubTab === ALL_RUNS && (
        <TestPlanResultV2 orgId={orgId} isForModal={true} projectId={projectId} />
      )}
      {selectedExecutionManagerSubTab === RUNNING_JOB && (
        <TestPlanResultV2
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.IN_PROGRESS}
        />
      )}
      {selectedExecutionManagerSubTab === IN_QUEUE_JOB && (
        <TestPlanResultV2
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED}
        />
      )}
      {selectedExecutionManagerSubTab === COMPLETED_JOB && (
        <TestPlanResultV2
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.DONE}
        />
      )}
    </div>
  );
};

export default ReportManagerV2;
