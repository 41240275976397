import {
  SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE,
  HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE,
  ADD_TEST_BLOCK_CHILD_STEP,
  RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS,
  SELECTOR_TYPE,
  SWIPE_OVER_ELEMENT,
  CAPTURE_SCREENSHOT,
  ELEMENT_DEFAULT_LOAD_TIME,
  ADD_TEST_BLOCK_STEP_ACTION,
  ELEMENT_ACTION,
  SWIPE_DIRECTION,
  STEP,
  TEST_BLOCK_REC_TEST_STEP_LIST,
  SET_SELECTED_TEST_BLOCK,
  LOADING_LIST_OF_BLOCKS,
  SET_TEST_BLOCK_STEP,
  RESET_TEST_BLOCK_STEP,
  CHANGE_TEST_BLOCK_LIST_FILTER,
  OWNED,
  MY_TEST_BLOCKS,
  UPDATED_TEST_BLOCK_NAME,
} from "../Constants";
import _ from "lodash";

import {
  checkActionHaveRelativePointOption,
  getNewRecElement,
  getUniqueId,
  isActionNeedToSelectElement,
} from "../Util";

let defaultState = {
  testBlockChildStepList: undefined,
  testBlockStepList: undefined,
  selectedTestStepChildStepId: [],
  selectedTestBLockStepIdForDelete: undefined,
  selectedTestBlockStepTagForDelete: undefined,
  isSelectedTestBlockStepForDelete: false,
  selectedTestStepBlocks: {},
  elementListByServer: [],
  deviceSelectedElementAttributes: undefined,
  recTestStepActionSelectedElement: undefined,
  searchedForElementBounds: undefined,
  fetchingTestBlockList: false,
  testBlockStep: undefined,
  testBlockListFilter: { id: OWNED, value: MY_TEST_BLOCKS },
  updateTestBlockNameLoading: false,
};

export default function TestBlockReducer(state = defaultState, action) {
  let response = undefined;
  let testBlockChildStepList = [];
  let testBlockStepList = [];
  let selectedRecTestStepDetail = undefined;
  switch (action.type) {
    case SHOW_TEST_BLOCK_LIST_MODAL_VISIBLE:
      return {
        ...state,
        testBlockListModalVisible: true,
      };

    case HIDE_TEST_BLOCK_LIST_MODAL_VISIBLE:
      return {
        ...state,
        testBlockListModalVisible: false,
      };

    case TEST_BLOCK_REC_TEST_STEP_LIST:
      return {
        ...state,
        selectedTestBlockStepName: action.recTestScenarioName,
        selectedTestBlockStepId: action.recTestScenarioId,
      };

    case LOADING_LIST_OF_BLOCKS:
      return {
        ...state,
        fetchingTestBlockList: action.flag,
      };

    case ADD_TEST_BLOCK_CHILD_STEP:
      let recElementGuid = undefined;
      if (state.testBlockChildStepList === undefined) {
        testBlockChildStepList = [];
      } else {
        testBlockChildStepList = state.testBlockChildStepList ? [...state.testBlockChildStepList] : [];
      }
      if (action.withPrecondition) {
        recElementGuid = getUniqueId();
        action.response.data.recTestStepProperty["preConditionTitle"] = "";
        action.response.data.recTestStepProperty["isStepHasPrecondition"] = 1;
        action.response.data.recTestStepProperty["isStepHasVerifyTestData"] = action.withVerifyTestData ? 1 : 0;
        action.response.data.recTestStepProperty["recElements"].push(
          getNewRecElement(action.response.data.recTestStepProperty?.action, recElementGuid)
        );
        state.reRecordElementGuid = recElementGuid;
      }
      if (action.withVerifyTestData) {
        action.response.data.recTestStepProperty["preConditionTitle"] = "";
        action.response.data.recTestStepProperty["isStepHasPrecondition"] = 1;
        action.response.data.recTestStepProperty["isStepHasVerifyTestData"] = action.withVerifyTestData ? 1 : 0;
        action.response.data.recTestStepProperty["recStepVerifyElementProperties"].push(
          getEmptyVerificationRow("VerifyTestData")
        );
      }
      testBlockChildStepList.push(action.response.data);

      return {
        ...state,
        testBlockChildStepList,
      };

    case SET_SELECTED_TEST_BLOCK:
      response = action.data;
      return {
        ...state,
        selectedTestStepBlocks: response,
      };

    case ADD_TEST_BLOCK_STEP_ACTION:
      let recTestStep = undefined;
      testBlockStepList = state.testBlockChildStepList
        ? [...state.testBlockChildStepList]
        : [...state.testBlockStepList];
      selectedRecTestStepDetail = findTestBlockStepById(
        state.testBlockChildStepList ? [...state.testBlockChildStepList] : [...state.testBlockStepList],
        action.parentRecTestStepId
      );
      if (selectedRecTestStepDetail["recTestSteps"] === undefined) {
        selectedRecTestStepDetail["recTestSteps"] = [];
      }
      recTestStep = {
        tag: action.newRecTestStepTag,
        orderId: selectedRecTestStepDetail["recTestSteps"].length + 1,
        recTestStepProperty: {
          action: action.newRecTestStepActionDetail,
          stepName: ["actions", "verifications"].includes(action.newRecTestStepActionDetail)
            ? undefined
            : action.newRecTestStepActionDetail,
          operator: "AND",
          uuid: getUniqueId(),
          recStepVerifyElementProperties: [],
          elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
          retryCount: 2,
          invertResult: 0,
          isSkipStep: 0,
          pauseTime: 0,
          isCaptureScreenshot: action.newRecTestStepActionDetail === CAPTURE_SCREENSHOT ? 1 : 0,
          captureScreenShotDelayTime: 0,
          horizontalRelativePoint: checkActionHaveRelativePointOption(action.newRecTestStepActionDetail) ? 50 : 0,
          verticalRelativePoint: checkActionHaveRelativePointOption(action.newRecTestStepActionDetail) ? 50 : 0,
          selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
          isRefElementSelected: 0,
          recSwipeAttributes:
            action.newRecTestStepActionDetail === SWIPE_OVER_ELEMENT ||
            action.newRecTestStepActionDetail === ELEMENT_ACTION.SWIPE
              ? { direction: SWIPE_DIRECTION.UP, percentage: 50 }
              : undefined,
          recElements: isActionNeedToSelectElement(action.newRecTestStepActionDetail)
            ? [getNewRecElement(action.newRecTestStepElementGuid)]
            : [],
        },

        recTestScenario: { id: state.selectedRecTestScenarioId },
        parentRecTestStep: { id: action.parentRecTestStepId },
      };
      selectedRecTestStepDetail["recTestSteps"].push(recTestStep);
      testBlockStepList = updateTestBlockStep(testBlockStepList, selectedRecTestStepDetail);
      return {
        ...state,
        selectedRecTestStepDetail,
        testBlockStepList,
        requestingPerformRecTestStepAction: false,
        activeRecTestStepAction: recTestStep,
      };

    case RESET_ALL_TEST_BLOCK_CHILD_STEP_IDS:
      return {
        ...state,
        selectedTestStepChildStepId: [],
      };

    case SET_TEST_BLOCK_STEP:
      return {
        ...state,
        testBlockStep: action.testBlockStep,
      };
    case RESET_TEST_BLOCK_STEP:
      return {
        ...state,
        testBlockStep: undefined,
      };

    case CHANGE_TEST_BLOCK_LIST_FILTER:
      return {
        ...state,
        testBlockListFilter: action.value,
      };

    case UPDATED_TEST_BLOCK_NAME:
      response = action?.response;
      return {
        ...state,
        updateTestBlockNameLoading: response?.isLoading,
      };
    default:
      return state;
  }
}

function getEmptyVerificationRow(type) {
  if (type === "GROUP") {
    return {
      type,
      uuid: getUniqueId(),
      key: "",
      value: "",
      childRecStepVerifyElementProperties: [],
      operator: "And",
    };
  } else {
    return {
      type,
      uuid: getUniqueId(),
      key: "",
      value: "",
    };
  }
}

function updateTestBlockStep(testBlockChildStepList, selectedRecTestStep) {
  for (let i = 0; i < testBlockChildStepList.length; i++) {
    if (testBlockChildStepList[i].recTestStepProperty?.action === STEP) {
      if (
        testBlockChildStepList[i].tag === selectedRecTestStep.tag ||
        testBlockChildStepList[i]?.id === selectedRecTestStep?.id
      ) {
        testBlockChildStepList[i] = selectedRecTestStep;
      } else if (
        testBlockChildStepList[i].recTestSteps &&
        testBlockChildStepList[i].recTestSteps.length > 0 &&
        testBlockChildStepList[i].recTestSteps[0].recTestStepProperty?.action === STEP
      ) {
        updateTestBlockStep(testBlockChildStepList[i].recTestSteps, selectedRecTestStep);
      }
    }
  }
  return testBlockChildStepList;
}

export function findTestBlockStepById(testBlockChildStepList, id, parentRecTestStep = undefined) {
  let selectedRecTestStep = undefined;
  for (let i = 0; i < testBlockChildStepList.length; i++) {
    if (testBlockChildStepList[i].id !== undefined && testBlockChildStepList[i].id === id) {
      selectedRecTestStep = testBlockChildStepList[i];
      break;
    } else {
      if (
        testBlockChildStepList[i].recTestStepProperty?.action === STEP &&
        testBlockChildStepList[i].recTestSteps &&
        testBlockChildStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = findTestBlockStepById(
          testBlockChildStepList[i].recTestSteps,
          id,
          testBlockChildStepList[i]
        );
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function findTestBlockStepByTag(testBlockChildStepList, tag, parentRecTestStep = undefined) {
  let selectedRecTestStep = undefined;
  if (testBlockChildStepList) {
    for (let i = 0; i < testBlockChildStepList.length; i++) {
      if (testBlockChildStepList[i].tag === tag) {
        selectedRecTestStep = testBlockChildStepList[i];
        break;
      } else {
        if (
          testBlockChildStepList[i].recTestStepProperty?.action === STEP &&
          testBlockChildStepList[i].recTestSteps &&
          testBlockChildStepList[i].recTestSteps.length > 0
        ) {
          selectedRecTestStep = findTestBlockStepByTag(
            testBlockChildStepList[i].recTestSteps,
            tag,
            testBlockChildStepList[i]
          );
          if (selectedRecTestStep) {
            break;
          }
        }
      }
    }
  }
  return selectedRecTestStep;
}
