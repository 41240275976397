import {
  LOGIN,
  LOGOUT,
  GET_CHILD_COMPONENTS,
  GET_CHILD_COMPONENTS_BY_TYPE_PARENT_ID,
  FORGOT_PASSWORD,
  CHECK_USER_NAME_EXISTS,
  SIGN_UP,
  SUCCESS_STATUS,
  VERIFY_SIGNUP_REQUEST,
  CHANGE_SIGNUP_FIRST_NAME,
  CHANGE_SIGNUP_LAST_NAME,
  CHANGE_SIGNUP_USER_NAME,
  CHANGE_SIGNUP_PASSWORD,
  CHANGE_SIGNUP_EMAIL,
  SET_SIGNUP_ERROR,
  SET_PASSWORD_VALIDATE_ERROR,
  SIGNUP_REQUEST,
  ERROR_STATUS,
  CHANGE_LOGIN_USER_NAME,
  CHANGE_LOGIN_PASSWORD,
  LOGIN_REQUEST,
  CHANGE_LOGIN_USER_EMAIL,
  CHANGE_LOGIN_USER_TOKEN,
  GENERATE_TOKEN,
  CHECK_USER_EMAIL_EXISTS,
  GET_ROUTE_LIST,
  GENERATE_TOKEN_REQUEST,
  ENTER_OTP,
  ENTER_OTP_OFF,
  CHANGE_LOGIN_OTP,
  FAILED_GENERATE_TOKEN,
  SIGN_UP_VERIFICATION_CONFIRMED,
  SIGN_UP_FAIL,
  VALIDATE_USER_SIGNUP_SSO,
  VALIDATE_USER_SIGNUP_SSO_REQUEST,
  USER_SIGNUP_SSO_BACK,
  VALIDATE_USER_LOGIN_SSO_REQUEST,
  VALIDATE_USER_LOGIN_SSO,
  USER_LOGIN_SSO_BACK,
  LOGIN_SUCCESS,
  UPDATE_PREVIOUS_SEARCH_VALUE,
  FETCH_LIST_OF_MEMBER,
  CHANGE_SIGNUP_FULL_NAME,
} from "../Constants";
import _ from "lodash";

let defaultState = {
  testElement: "",
  routeList: [],
  childComponentList: undefined,
  childComponentListByType: [],
  loginStatus: false,
  showPasswordSendNotificaton: false,
  showUserNameExistsError: undefined,
  signUpStatus: undefined,
  signUpMessage: "",
  redirectTo: undefined,
  verifiedSignup: undefined,
  signupFullName: "",
  signupUserName: "",
  signupEmail: "",
  signupPassword: "",
  signupFullNameError: false,
  signupFirstNameError: false,
  signupLastNameError: false,
  signupUserNameError: false,
  signupEmailError: false,
  signupPasswordError: false,
  singUpPasswordValidateMessage: undefined,
  singupRequested: false,
  loginUserName: "",
  loginPassword: "",
  loginUserNameError: false,
  loginPassowrdError: false,
  loginAuthenicating: false,
  loginUserEmail: "",
  loginUserEmailError: false,
  loginUserToken: undefined,
  showEmailExistsError: true,
  generateTokenRequest: false,
  verifyForSSO: true,
  verifyForSSORequest: false,
  loginForSSO: true,
  loginForSSORequest: false,
  loginMessage: undefined,
  signupRedirect: false,
  loginRedirect: false,
  loginSuccess: null,
  previousSearchValue: null,
  memberList: [],
  selectedTestStepChildStepId: [],
};

export default function CommonReducer(state = defaultState, action) {
  switch (action.type) {
    case LOGIN:
      let resp = action.response;
      if (resp.status === ERROR_STATUS) {
        state.loginStatus = false;
        state.loginMessage = resp.message; // "Invalid username or password.";
      } else {
        state.loginStatus = true;
        // document.cookie='access_token=Bearer '+resp.access_token+';';
        // localStorage.setItem("access_token",'Bearer '+resp.access_token);
      }
      return {
        ...state,
        loginAuthenicating: false,
      };
    case "REMOVE_CHILD_COMPONENT":
      return {
        ...state,
        childComponentList: undefined,
      };
    case GENERATE_TOKEN_REQUEST:
      return {
        ...state,
        generateTokenRequest: true,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        showPasswordSendNotificaton: true,
      };
    case CHECK_USER_NAME_EXISTS:
      return {
        ...state,
        showUserNameExistsError: action.response.status === SUCCESS_STATUS ? true : false,
      };
    case VERIFY_SIGNUP_REQUEST:
      let verifiedSignup = "NOT_VERIFIED";
      if (action.response.status === SUCCESS_STATUS) {
        verifiedSignup = "VERIFIED";
      }
      return {
        ...state,
        verifiedSignup,
      };
    case SIGN_UP:
      return {
        ...state,
        signUpStatus: action.response.status == SUCCESS_STATUS ? true : false,
        signUpMessage: action.response.message,
        singupRequested: false,
      };
    case SIGN_UP_FAIL:
      return {
        ...state,
        singupRequested: false,
      };
    case SIGN_UP_VERIFICATION_CONFIRMED:
      return {
        ...state,
        signUpStatus: false,
      };
    case GET_ROUTE_LIST:
      return {
        ...state,
        routeList: action.response.data,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        loginSuccess: action.response,
      };
    case GET_CHILD_COMPONENTS:
      return {
        ...state,
        childComponentList: action.response.data,
      };
    case GET_CHILD_COMPONENTS_BY_TYPE_PARENT_ID:
      return {
        ...state,
        childComponentListByType: action.response.data,
      };
    case LOGOUT:
      // if(localStorage.getItem("access_token")!=null){
      //     res = sync("User/logout", "PUT", action, "");
      // }
      // newState=[];
      return state;
    case CHANGE_SIGNUP_FULL_NAME:
      return {
        ...state,
        signupFullName: action.value,
      };
    case SIGNUP_REQUEST:
      return {
        ...state,
        singupRequested: true,
      };
    case CHANGE_SIGNUP_LAST_NAME:
      return {
        ...state,
        signupLastName: action.value,
      };
    case CHANGE_SIGNUP_USER_NAME:
      return {
        ...state,
        signupUserName: action.value,
      };
    case CHANGE_SIGNUP_EMAIL:
      return {
        ...state,
        signupEmail: action.value,
      };
    case CHANGE_SIGNUP_PASSWORD:
      return {
        ...state,
        signupPassword: action.value,
      };
    case SET_SIGNUP_ERROR:
      let errorField = action["field"];
      state[errorField] = action.errorStatus;
      return {
        ...state,
      };
    case CHANGE_LOGIN_USER_NAME:
      return {
        ...state,
        loginUserName: action.value,
      };
    case CHANGE_LOGIN_PASSWORD:
      return {
        ...state,
        loginPassword: action.value,
      };
    case SET_PASSWORD_VALIDATE_ERROR:
      return {
        ...state,
        signupPasswordError: true,
        singUpPasswordValidateMessage: "Password should be minimum six characters, at least one letter and one number",
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        loginAuthenicating: true,
      };
    case CHANGE_LOGIN_USER_EMAIL:
      return {
        ...state,
        loginUserEmail: action.value,
      };
    case CHANGE_LOGIN_USER_TOKEN:
      return {
        ...state,
        loginUserToken: action.value,
      };
    case GENERATE_TOKEN:
      let respGT = action.response;
      if (respGT.status === ERROR_STATUS) {
        state.loginStatus = false;
        state.loginMessage = respGT.message;
      } else {
        state.loginStatus = true;
      }
      return {
        ...state,
        showPasswordSendNotificaton: action.response.status == SUCCESS_STATUS ? true : false,
        generateTokenRequest: false,
        tokenSendSuccess: true,
      };
    case CHECK_USER_EMAIL_EXISTS:
      return {
        ...state,
        showEmailExistsError: action.response.status == SUCCESS_STATUS ? true : false,
      };
    case FAILED_GENERATE_TOKEN:
      return {
        ...state,
        generateTokenRequest: false,
      };
    case ENTER_OTP:
      return {
        ...state,
        alreadyHaveToken: true,
      };
    case ENTER_OTP_OFF:
      return {
        ...state,
        alreadyHaveToken: false,
        tokenSendSuccess: false,
      };
    case CHANGE_LOGIN_OTP:
      return {
        ...state,
        loginOtp: action.value,
      };
    case VALIDATE_USER_SIGNUP_SSO_REQUEST:
      return {
        ...state,
        verifyForSSORequest: true,
      };
    case USER_SIGNUP_SSO_BACK:
      return {
        ...state,
        verifyForSSO: true,
      };
    case VALIDATE_USER_SIGNUP_SSO:
      let signUpMessage = undefined;
      let signupRedirect = false;
      if (action.response.status === ERROR_STATUS && action.response.message !== "Email not Exist.") {
        signUpMessage = action.response.message;
      } else if (
        action.response.status === SUCCESS_STATUS &&
        action.response.message === "User is registered with SSO!"
      ) {
        if (action.response.data && action.response.data != "") {
          window.location.href = action.response.data;
          signupRedirect = true;
        } else {
          signUpMessage = action.response.message;
        }
      } else if (action.response.status === SUCCESS_STATUS && action.response.message === "Email Exist.") {
        signUpMessage = action.response.message;
      }

      if (signUpMessage || signupRedirect) {
        return {
          ...state,
          signUpMessage,
          signupRedirect,
          verifyForSSORequest: false,
        };
      } else {
        return {
          ...state,
          signUpMessage,
          verifyForSSORequest: false,
          verifyForSSO: false,
        };
      }
    case VALIDATE_USER_LOGIN_SSO_REQUEST:
      return {
        ...state,
        verifyForSSORequest: true,
      };
    case USER_LOGIN_SSO_BACK:
      return {
        ...state,
        tokenSendSuccess: false,
        loginMessage: "",
      };
    case VALIDATE_USER_LOGIN_SSO:
      let loginMessage = undefined;
      let loginRedirect = false;
      if (action.response.status === ERROR_STATUS) {
        loginMessage = action.response.message;
      } else if (
        action.response.status === SUCCESS_STATUS &&
        action.response.message === "User is registered with SSO!"
      ) {
        if (action.response.data && action.response.data != "") {
          window.location.href = action.response.data;
          loginRedirect = true;
        } else {
          loginMessage = action.response.message;
        }
      }
      if (loginMessage || loginRedirect) {
        return {
          ...state,
          loginMessage,
          loginRedirect,
          verifyForSSORequest: false,
        };
      } else {
        return {
          ...state,
          loginMessage,
          verifyForSSORequest: false,
          loginForSSO: false,
        };
      }
    case UPDATE_PREVIOUS_SEARCH_VALUE:
      return {
        ...state,
        previousSearchValue: action.searchValue,
      };
    case FETCH_LIST_OF_MEMBER:
      return {
        ...state,
        memberList: action?.response?.data,
      };
    default:
      return state;
  }
}
