import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchListOfMember } from "../../../../actions/CommonActions";
import ApplyForMemberListV2 from "./ApplyForMemberListV2";
import RemoveForMemberListV2 from "./RemoveForMemberListV2";

const MemberListV2 = (props) => {
  const {
    notShowExecutionCheck,
    applyFor,
    setApplyFor,
    removeFor,
    setRemoveFor,
    members,
    openPermissionDrawer,
    ownerEmailId,
  } = props;
  const dispatch = useDispatch();

  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const [searchText, setSearchText] = useState("");
  const [isRemoveAddNew, setRemoveIsAddNew] = useState(false);
  const [applyForOption, setApplyForOption] = useState([]);
  const [removeForOption, setRemoveForOption] = useState([]);
  const [isAddNew, setIsAddNew] = useState(false);

  useEffect(() => {
    if (openPermissionDrawer) {
      dispatch(fetchListOfMember());
      setRemoveForOption([]);
      setApplyForOption([]);
      setIsAddNew(false);
      setRemoveIsAddNew(false);
    }
  }, [openPermissionDrawer]);

  useEffect(() => {
    setApplyFor(
      members
        .filter((i) => i?.shouldAddOrRemove === 1)
        .map((i) =>
          i?.userId === loggedInUserDetails?.userID
            ? { ...i, isEdit: 1, isDelete: 1, isView: 1, isAdmin: 1 }
            : i && !notShowExecutionCheck && i?.userId === loggedInUserDetails?.userID
            ? { ...i, isEdit: 1, isDelete: 1, isView: 1, isAdmin: 1, isExec: 1 }
            : i
        )
    );
    setRemoveFor(members.filter((i) => i?.shouldAddOrRemove === 0).map((i) => i));
  }, [members]);

  return (
    <>
      <ApplyForMemberListV2
        setSearchText={setSearchText}
        searchText={searchText}
        notShowExecutionCheck={notShowExecutionCheck}
        ownerEmailId={ownerEmailId}
        applyFor={applyFor}
        setApplyFor={setApplyFor}
        setRemoveFor={setRemoveFor}
        removeFor={removeFor}
        setApplyForOption={setApplyForOption}
        applyForOption={applyForOption}
        setIsAddNew={setIsAddNew}
        isAddNew={isAddNew}
      />
      <RemoveForMemberListV2
        applyFor={applyFor}
        setApplyFor={setApplyFor}
        setRemoveFor={setRemoveFor}
        removeFor={removeFor}
        removeForOption={removeForOption}
        setRemoveForOption={setRemoveForOption}
        notShowExecutionCheck={notShowExecutionCheck}
        ownerEmailId={ownerEmailId}
        setRemoveIsAddNew={setRemoveIsAddNew}
        isRemoveAddNew={isRemoveAddNew}
      />
    </>
  );
};

export default MemberListV2;
