import { Col, Popover, Row } from "antd";
import React from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";
export const CommonCol = styled(Row)`
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  font-weight: 500;
  text-align: left;
`;

export const DataCol = styled(Col)`
  word-wrap: break-word;
  text-align: left;
`;

export const StyledCol = styled(Row)`
  height: "900px";
  border: 1px solid #e8e8e8;
  border-top: 0px !important;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;

const ExecutionDetailsTab = (props) => {
  const { testStepFailDetails, failurePageSourceLink,aiGeneratedStep,aiGeneratedRecordedStep,aiGeneratedRecordedPagesource } = props;
  return (
    <div className="w-98">
      <CommonCol span={24}>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Items
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Values
        </DataCol>
      </CommonCol>
      <StyledCol span={24}>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Exception Type
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          {testStepFailDetails?.exceptionType ? testStepFailDetails?.exceptionType : "N/A"}
        </DataCol>
      </StyledCol>

      <StyledCol span={24}>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Failed Error
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          {testStepFailDetails?.failedError ? testStepFailDetails?.failedError : "N/A"}
        </DataCol>
      </StyledCol>

      <StyledCol span={24}>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Sub Exception Type
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          {testStepFailDetails?.subExceptionType ? testStepFailDetails?.subExceptionType : "N/A"}
        </DataCol>
      </StyledCol>

      <StyledCol span={24}>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          logical Error
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          {testStepFailDetails?.logicalError ? testStepFailDetails?.logicalError : "N/A"}
        </DataCol>
      </StyledCol>
      {aiGeneratedStep && <StyledCol span={24}>
          <DataCol sm={24} md={24} lg={24} xl={16}>
            <Popover overlayStyle={{ width: "60%" }} content={
              <div>
                <ReactJson src={JSON.parse(aiGeneratedStep)} />
              </div>
              } title="AI Generated Step">
              <span>Execution AI Step</span>
            </Popover>
          </DataCol>
        </StyledCol>
      }
      {aiGeneratedRecordedStep && <StyledCol span={24}>
          <DataCol sm={24} md={24} lg={24} xl={16}>
          <div onClick={()=>{
                        var file = new Blob([aiGeneratedRecordedStep], { type: 'text/json' });
                        var fileURL = URL.createObjectURL(file);
                        let tab= window.open(fileURL,"_blank")
                      }}>
              Recorded AI Step
            </div>
          </DataCol>
        </StyledCol>
      }
      {aiGeneratedRecordedPagesource && (
        <StyledCol span={24}>
          <DataCol sm={24} md={24} lg={24} xl={16}>
            <a href={aiGeneratedRecordedPagesource} target="_blank">
              Recorded Page Source Log
            </a>
          </DataCol>
        </StyledCol>
      )}
      {failurePageSourceLink && (
        <StyledCol span={24}>
          <DataCol sm={24} md={24} lg={24} xl={16}>
            <a href={failurePageSourceLink} target="_blank">
              Page Source Log
            </a>
          </DataCol>
        </StyledCol>
      )}
    </div>
  );
};

export default ExecutionDetailsTab;
