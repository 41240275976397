import {
  faArrowDown,
  faArrowUp,
  faTasks,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Col, Input, Row, Select, Tooltip } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectOutputTestDataForRecElement,
  changeCustomElementAddFilterType,
  changeCustomElementAddTestData,
  changeCustomElementFilterTypeValue,
  changeCustomElementSearch,
  changeCustomElementSearchValue,
  selectApplicationStringLocale,
  changeCustomElementAddApplicationString,
  getApplicationStrings,
  getListOfLocalesForApplicationStrings,
  applicationDrawerVisible,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import _ from "lodash";
import { ELEMENTS, TOOLTIP_BG_COLOR, splitValue } from "../../../../Constants";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import ManageTestData from "../ManageTestData";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import styles from "./RecTestStepCustomElementDetail.module.scss";
import ApplicationStringDrawer from "../ApplicationStringDrawer/ApplicationStringDrawer";

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  border: 1px solid #e9e9e9 !important;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
`;
export const StyledDiv = styled.div`
  background: #dcdcdc;
  width: 150px;
  padding: 7px;
`;

const RecTestStepCustomElementDetail = (props) => {
  const { data, recElement, disabled } = props;
  const { testDataList, unSavedRecElementGuid, unSavedRecStepTag } =
    useSelector((state) => state.RecTestStepCommonViewReducer);
  let shouldAllowToEdit =
    unSavedRecElementGuid === undefined ||
    unSavedRecElementGuid === "" ||
    (unSavedRecStepTag === data.tag &&
      unSavedRecElementGuid === recElement.guid);
  const {  getListOfLocales } = useSelector(
        (state) => state.RecTestStepCommonViewReducer
      );
  const dispatch = useDispatch();
  return (
    <>
      <Col span={24}>
        <Row className={styles.selectElementRow}>
          <Col lg={24} className={styles.selectElementCol}>
            <span className={styles.selectElement}>Select Element</span>
            <Select
              className={styles.styledSelect}
              disabled={disabled}
              value={recElement.searchKey}
              onChange={(value) =>
                dispatch(
                  changeCustomElementSearch(value, data.tag, recElement.guid)
                )
              }
            >
              <Select.Option value="contains_text">
                containing Text
              </Select.Option>
              <Select.Option value="text">
                having exact Text (Case Insensitive)
              </Select.Option>
              <Select.Option value="exact_text">
                having exact Text (Case Sensitive)
              </Select.Option>
            </Select>
          </Col>
          <Col lg={24} >
          <div className={styles.checkboxDiv}>
            <Checkbox
              disabled={disabled || recElement.useApplicationString === 1}
              checked={recElement.useTestData === 1}
              onChange={(event) =>
                dispatch(
                  changeCustomElementAddTestData(
                    event.target.checked,
                    data.tag,
                    recElement.guid
                  )
                )
              }
            />
            <span className={styles.checkboxText}> Use Test Data</span>
          </div>
          <div className={styles.checkboxDiv}>
            <Checkbox
              disabled={disabled || recElement.useTestData === 1}
              checked={recElement.useApplicationString === 1}
              onChange={(event) => {
                dispatch(
                  changeCustomElementAddApplicationString(
                    event.target.checked,
                    data.tag,
                    recElement.guid
                  )
                );
              }}
            />
            <span className={styles.checkboxText}> Use Application String</span>
          </div>
          </Col>
        </Row>
        {recElement.useTestData === 1 ? (
          <Row className={styles.useTestDataRow}>
            <Col lg={24} className={styles.useTestDataCol}>
              <div className={styles.label}>Test Data</div>
              <Select
                disabled={disabled}
                className={`${styles.styledSelect} ${styles.useTestDataSelect}`}
                placeholder="Please Select Test Data"
                value={
                  recElement.testData
                    ? recElement.testData.id +
                      splitValue +
                      recElement.testData.name +
                      splitValue +
                      recElement.testData.value
                    : undefined
                }
                onChange={(value) => {
                  dispatch(
                    selectOutputTestDataForRecElement(
                      data.tag,
                      recElement.guid,
                      value
                    )
                  );
                }}
              >
                {testDataList &&
                  testDataList.length > 0 &&
                  testDataList.map((v, k) => {
                    return (
                      <Select.Option
                        key={v.id}
                        value={
                          v.id + splitValue + v.name + splitValue + v.value
                        }
                      >
                        <span>
                          <span>
                            {v.name + (v.value ? " (" + v.value + ")" : "")}
                          </span>
                        </span>
                      </Select.Option>
                    );
                  })}
              </Select>
              {recElement.useTestData === 1 && (
                <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                  <Button
                    className={`${styles.button} ${!disabled && styles.blue}`}
                    disabled={disabled}
                    onClick={() => {
                      dispatch(
                        visibleTestDataDrawer(
                          ELEMENTS,
                          data?.tag,
                          recElement?.guid
                        )
                      );
                      dispatch(selectRecTestSteps(data));
                    }}
                  >
                    <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                  </Button>
                </Tooltip>
              )}
            </Col>
          </Row>
        ) : recElement.useApplicationString === 1 ? (
          <Row className={styles.useTestDataRow}>
            <Col lg={24} className={styles.useTestDataCol}>
              <div className={styles.label}>Application String</div>
              <div className={`${styles.useTestDataSelect}`}>
                <span>{recElement?.applicationStringsKey || recElement?.applicationStrings?.key || "Please Select the application String"}</span>
                {recElement.useApplicationString === 1 && (
                  <Tooltip title="Select Application String" color={TOOLTIP_BG_COLOR}>
                    <Button
                      className={`${styles.button} ${!disabled && styles.blue}`}
                      disabled={disabled}
                      onClick={() => {
                        dispatch(applicationDrawerVisible(true,recElement.guid));
                        dispatch(
                          getApplicationStrings(
                            props.projectId,
                            "",
                            "",
                            "",
                            recElement.guid,
                            data.tag
                          )
                        );
                        _.isEmpty(getListOfLocales) &&
                          dispatch(getListOfLocalesForApplicationStrings());
                      }}
                    >
                      <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                    </Button>
                  </Tooltip>
                )}
              </div>
              
            </Col>
          </Row>
        ) : (
          <Row className={styles.useTestDataRow}>
            <Col lg={24} className={styles.useTestDataCol}>
              <div className={styles.label}>Text to match</div>
              <Input
                placeholder="Enter the text"
                disabled={disabled}
                value={recElement.searchValue}
                onChange={(event) =>
                  dispatch(
                    changeCustomElementSearchValue(
                      event.target.value,
                      data.tag,
                      recElement.guid
                    )
                  )
                }
                className={styles.useTestDataInput}
              />
            </Col>
          </Row>
        )}

        <Row className={styles.useTestDataRow}>
          <Col
            lg={24}
            className="font-size-12 font-weight-500 ml-less-5 display-flex w-100"
          >
            <div className={`${styles.label} ${styles.elementType}`}>
              Element Type to match
            </div>

            <Input
              disabled={disabled}
              value={recElement.filterType}
              onChange={(event) =>
                dispatch(
                  changeCustomElementFilterTypeValue(
                    event.target.value,
                    data.tag,
                    recElement.guid
                  )
                )
              }
              className={`${styles.useTestDataInput} ${styles.elementType}`}
            />
          </Col>
        </Row>

        <ManageTestData {...props} tabName={ELEMENTS} />
      </Col>
      <ApplicationStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={props.projectId}
        disabled={props.disabled || props.isSkipStep}
      />
    </>
  );
};
export default RecTestStepCustomElementDetail;
