import { Col, Row } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/Recording";

class CustomTextStringBuilder extends Component {
  constructor(props) {
    super(props);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
    this.updateCustomRefTypeValue = this.updateCustomRefTypeValue.bind(this);
    this.updateCustomRefTextValue = this.updateCustomRefTextValue.bind(this);
    this.updateCustomRefOptionValue = this.updateCustomRefOptionValue.bind(this);
  }

  addRow(key) {
    this.props.insertCustomTextVerificationRow(key);
  }

  removeRow(key) {
    this.props.removeCustomTextVerificationRow(key);
  }

  updateCustomRefTypeValue(e, key) {
    this.props.updateCustomRefTypeValue(e, key);
  }

  updateCustomRefTextValue(e, key) {
    this.props.updateCustomRefTextValue(e, key);
  }

  updateCustomRefOptionValue(e, key) {
    this.props.updateCustomRefOptionValue(e, key);
  }

  getReferenceText(value) {
    // this.props.elementTexts && this.props.elementTexts.map((element, eindex) => {
    //     if(element.id == value){
    //         return <span> {element.value} </span>
    //     }
    // });
    return this.props.elementTexts.filter((eitem) => (eitem.id = value));
  }
  prepareElementText(data) {
    return (
      data &&
      data.map((item, index) => {
        if (item.key == "Text") {
          return <span key={index}>{item.value}</span>;
        } else if (item.key == "ReferenceText" && item.value != "") {
          const property = this.props.elementList.filter((eitem) => eitem.id == item.value || eitem.tag == item.value);
          return (
            property.length > 0 &&
            property[0].recTestStepProperty && (
              <span
                key={index}
                className="span-reference-text-preview-item"
                title={"Step Name: " + property[0].recTestStepProperty?.stepName}>
                {property[0].recTestStepProperty?.uniqueByText}
              </span>
            )
          );
        }
      })
    );
  }
  render() {
    const { customRefTextList, elementList } = this.props;
    return (
      <React.Fragment>
        <Row className={"custom-verification-string-builder-header"}>
          <Col>Preview Text:</Col>
        </Row>
        <Row className={"custom-verification-string-builder-text"}>
          <Col>{this.prepareElementText(customRefTextList)}</Col>
        </Row>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return state.RecordingReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTextStringBuilder);
