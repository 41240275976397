import { Col, Empty, Row } from "antd";
import _ from "lodash";
import React from "react";
import { SELECTOR_TYPE, SELECTOR_TYPE_TEXT } from "../../../../Constants";
import ActualElements from "./ActualElements";
import { CommonCol } from "./ErrorInvestigationDetailsTab";
import ExpectedElement, { DataCol, StyledCol } from "./ExpectedElements";
import TestStepVerifyElementPropertiesDetail from "./TestStepVerifyElementPropertiesDetail";
import { useSelector } from "react-redux";
import { highlightResult } from "../TestPlanResultDetailPreconditionList";
import styled from "styled-components";

export const StyledDiv = styled.div`
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  text-align: center;
`;

const splitTextValuesWithDoubleHash = (value) => {
  let splitValues = [];
  if (value.includes("##")) {
    value.split("##").map((i, key) => {
      splitValues.push(
        <>
          <span key={key}>{key + 1 + ". " + i}</span>
          <br />
        </>
      );
    });
  } else {
    splitValues.push(<span>{"1. " + value}</span>);
  }
  return splitValues;
};
const ElementsDetail = (props) => {
  const { testExecutionElementDetail, elementText, failurePageSourceLink, outputTestDataName, outputTestDataValue } =
    props;
  const { getTestStepDetails, deviceResultDetails } = useSelector((state) => state.TestPlanReducer);
  return (
    <>
      <div className="w-100">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Col span={24} className="text-align-left">
              <span className="font-weight-600">Element Recording Mode : </span>
              {SELECTOR_TYPE_TEXT[testExecutionElementDetail?.elementSelectorType] || <span className="red-font-color">NA</span>}
            </Col>
            {testExecutionElementDetail?.filterType && (
              <Col span={24} className="text-align-left mt-10">
                <span className="font-weight-600">Filter Type : </span>
                {testExecutionElementDetail?.filterType || "NA"}
              </Col>
            )}
            {testExecutionElementDetail.applicationStringsKey && (
              <>
                <Col span={24} className="text-align-left mt-10">
                  <StyledDiv className={"font-weight-600"}>Associated Application Strings</StyledDiv>
                </Col>
                <CommonCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Key
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Value(s)
                  </DataCol>
                </CommonCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.applicationStringsKey || <span className="red-font-color">NA</span>}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.applicationStringsValue
                      ? splitTextValuesWithDoubleHash(testExecutionElementDetail?.applicationStringsValue)
                      : <span className="red-font-color">NA</span>}
                  </DataCol>
                </StyledCol>
              </>
            )}
            {testExecutionElementDetail.deviceStringsKey && (
              <>
                <Col span={24} className="text-align-left mt-10">
                  <StyledDiv className={"font-weight-600"}>Associated Device Strings</StyledDiv>
                </Col>
                <CommonCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Key
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Value(s)
                  </DataCol>
                </CommonCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.deviceStringsKey || <span className="red-font-color">NA</span>}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.deviceStringsValue
                      ? splitTextValuesWithDoubleHash(testExecutionElementDetail?.deviceStringsValue)
                      : <span className="red-font-color">NA</span>}
                  </DataCol>
                </StyledCol>
              </>
            )}
            {testExecutionElementDetail.useTestData === 1 && (
              <>
                <Col span={24} className="text-align-left mt-10">
                  <StyledDiv className={"font-weight-600"}>Associated Test Data</StyledDiv>
                </Col>
                <CommonCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Key
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    Value(s)
                  </DataCol>
                </CommonCol>
                <StyledCol span={24}>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.testDataName || <span className="red-font-color">NA</span>}
                  </DataCol>
                  <DataCol sm={12} md={12} lg={12} xl={8}>
                    {testExecutionElementDetail?.testDataValue ||<span className="red-font-color">NA</span>}
                  </DataCol>
                </StyledCol>
              </>
            )}
          </Col>
        </Row>
        <Row className="w-100 mt-10" gutter={15}>
          {!_.isEmpty(testExecutionElementDetail) &&
            [SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN, SELECTOR_TYPE.CUSTOM_ELEMENT].includes(
              testExecutionElementDetail["elementSelectorType"]
            ) && (
              <>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <ExpectedElement
                    testExecutionElementDetail={testExecutionElementDetail}
                    devicePlatform={getTestStepDetails.platform || deviceResultDetails.platformName}
                  />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                  <ActualElements
                    testExecutionElementDetail={testExecutionElementDetail}
                    failurePageSourceLink={failurePageSourceLink}
                    devicePlatform={getTestStepDetails.platform || deviceResultDetails.platformName}
                  />
                </Col>
              </>
            )}
        </Row>

        {testExecutionElementDetail?.testStepVerifyElementProperties &&
          testExecutionElementDetail?.testStepVerifyElementProperties.length > 0 && (
            <>
              <Row className="mt-10">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-align-left">
                  <span className="font-weight-600">{"Identification Rules Status "}</span>

                  {testExecutionElementDetail.verificationResult
                    ? highlightResult(testExecutionElementDetail.verificationResult)
                    : ""}
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <TestStepVerifyElementPropertiesDetail
                    operator={testExecutionElementDetail?.verificationOperator}
                    data={testExecutionElementDetail?.testStepVerifyElementProperties}
                    // elementText={elementText}
                  />
                </Col>
              </Row>
            </>
          )}
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-align-left mt-10">
            {failurePageSourceLink && (
              <span>
                <span className="font-weight-600">{"Page Source : "}</span>
                <a href={failurePageSourceLink} target="_blank">
                  Link
                </a>
              </span>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default ElementsDetail;
