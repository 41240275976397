import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Dropdown, Menu } from "antd";
import { ADD_CIRCLE_ICON, DARK_GREEN } from "../../../../../Constants/SvgConstants";
import { isArrayNotUndefinedNotNullNotEmpty, showTextVerification } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { addVerificationRow } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./VerificationsV2.module.scss";

const AddVerificationRowV2 = (props) => {
  const dispatch = useDispatch();
  const { selectedElement } = useSelector((state) => state.RecordingReducer);
  let elementSelected =
    showTextVerification(selectedElement) ||
    isArrayNotUndefinedNotNullNotEmpty(props.recTestStep.recTestStepProperty?.recElements);

  const menu = (
    <Menu>
      {!props.disabled && elementSelected && (
        <Menu.Item
          key="VerifyElementProperty"
          onClick={() =>
            dispatch(
              addVerificationRow("VerifyElementProperty", props.uuid, props.recTestStep.tag, props.recElement?.guid)
            )
          }
        >
          <div>Verify Element Property</div>
        </Menu.Item>
      )}
      {!props.disabled && elementSelected && (
        <Menu.Item
          key="VerifyElementText"
          onClick={() =>
            dispatch(addVerificationRow("VerifyElementText", props.uuid, props.recTestStep.tag, props.recElement?.guid))
          }
        >
          <div>Verify Element Text</div>
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Col>
      <Dropdown overlay={menu} disabled={props.disabled} overlayClassName="more-Actions-Dropdown">
        <div className={styles["add-Rules-Dropdown"]}>
          <SvgLoader iconName={ADD_CIRCLE_ICON} height={"1.5rem"} iconColor={DARK_GREEN} />
          <span>Add Rule</span>
        </div>
      </Dropdown>
    </Col>
  );
};

export default AddVerificationRowV2;
