import { Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { updateActionValue } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  ACTIONS_OPTIONS,
  ANDROID,
  SET_APP_LOCALE,
  SET_APP_LOCALE_TXT,
  DEVICE_INTERACTION,
  ELEMENT_ACTION,
  INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT,
} from "../../../../../Constants";
import CaptureScreenshot from "../CaptureScreenshot";
import InsertValueToOutputTestData from "../InsertValueToOutputTestData";
import Pause from "../Pause";
import SwipeElement from "../SwipeElement/SwipeElement";
import styles from "./QuickActions.module.scss";
import SetAppLocaleVariable from "../SetAppLocaleVariable";

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  margin-top: 5px;
  border: 1px solid #e9e9e9 !important;
  width: 90%;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
`;
const QuickActions = (props) => {
  const { data, isSkipStep, isPreviewRunning, platform } = props;
  let { disabled } = props;
  const { action } = data.recTestStepProperty;
  const dispatch = useDispatch();

  disabled = disabled || isPreviewRunning || isSkipStep;
  return (
    <React.Fragment>
      <div>
        <div className={styles.quickActions}>
          <div className={styles.selectQuickActions}>
            <div className={styles.label}>Action: </div>
            <Select
              value={action === "actions" ? ACTIONS_OPTIONS : action}
              disabled={disabled}
              defaultValue={ELEMENT_ACTION.TAP}
              dropdownMatchSelectWidth
              onChange={(value) => {
                dispatch(updateActionValue(value, data.tag));
              }}
              className={styles.selectBoxForActions}>
              <Select.Option value={DEVICE_INTERACTION.HOME}>Navigate to Home</Select.Option>
              {platform === ANDROID && <Select.Option value={DEVICE_INTERACTION.BACK}>Navigate Back</Select.Option>}
              {platform === ANDROID && <Select.Option value={DEVICE_INTERACTION.ENTER}>Keypad Enter</Select.Option>}
              <Select.Option value={ELEMENT_ACTION.PAUSE}>Add Delay</Select.Option>
              <Select.Option value={ELEMENT_ACTION.CAPTURE_SCREENSHOT}>Screenshot Page</Select.Option>
              <Select.Option value={ELEMENT_ACTION.SWIPE}>Swipe</Select.Option>
              <Select.Option value={ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA}>
                {INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT}
              </Select.Option>
              <Select.Option value={SET_APP_LOCALE}>{SET_APP_LOCALE_TXT}</Select.Option>
            </Select>
            {action === ELEMENT_ACTION.CAPTURE_SCREENSHOT ? <CaptureScreenshot {...props} /> : ""}
          </div>
        </div>
        <div span={action !== ELEMENT_ACTION.CAPTURE_SCREENSHOT && 24}>
          {action === ELEMENT_ACTION.PAUSE ? <Pause {...props} /> : ""}
          {action === ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA ? <InsertValueToOutputTestData {...props} /> : ""}
          {action === SET_APP_LOCALE ? <SetAppLocaleVariable {...props} /> : ""}
          {action === ELEMENT_ACTION.SWIPE ? <SwipeElement {...props} /> : ""}
        </div>
      </div>
    </React.Fragment>
  );
};

export default QuickActions;
