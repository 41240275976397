import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import PropTypes from "prop-types";
import {
  ACTIONS,
  ACTIONS_OPTIONS,
  ELEMENT_ACTION,
  STEP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
} from "../../../../../Constants";
import { updateActionValue } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerifyExistenceV2 from "../../VerificationsV2/VerifyExistenceV2/VerifyExistenceV2";
import VerifyTestDataV2 from "../../VerificationsV2/VerifyTestDataV2/VerifyTestDataV2";
import EnterTextV2 from "../EnterTextV2/EnterTextV2";
import TestDataOptionV2 from "../EnterTextV2/TestDataOptionV2";
import SwipeElementV2 from "../SwipeElementV2/SwipeElementV2";
import TapByIndexV2 from "../TapByIndexV2/TapByIndexV2";
import styles from "./ActionsVerificationItemsV2.module.scss";

const ActionsVerificationItemsV2 = (props) => {
  const dispatch = useDispatch();
  let { disabled } = props;
  const { data, isSkipStep, isPreviewRunning, isForView, isInputDisabled, projectId, showPostActionOrSettings } = props;
  const { showTestDataVisibility } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const {
    action,
    isStepHasVerifyTestData,
    isLoopStep,
    recStepVerifyElementProperties,
    testData,
    isStepHasPrecondition,
  } = data.recTestStepProperty;

  disabled = disabled || isPreviewRunning || isSkipStep;

  const showTestDataContent =
    !isInputDisabled &&
    ((showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) ||
      (testData && testData.id ? true : false));

  let testDataChecked =
    (showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) || (testData && testData.id)
      ? true
      : false;

  return (
    <React.Fragment>
      <div>
        {getActionType(action) === ACTIONS && (
          <div className={styles["actionsSelection"]}>
            <div className={styles["contentInCenter"]}>
              <div className={styles["selectActionsOrText"]}>
                <span className={styles["operation_Text"]}>Operation :</span>
                <div className={styles['selectBoxAndLabel']}>
                  <div className={styles["actionTitle"]}>Perform Action</div>
                  <Select
                    className={`${styles["selectActions"]} ${disabled && styles["selectBoxBgColor"]}`}
                    value={action === "actions" ? ACTIONS_OPTIONS : action}
                    disabled={disabled}
                    defaultValue={ELEMENT_ACTION.TAP}
                    dropdownMatchSelectWidth
                    onChange={(value) => {
                      dispatch(updateActionValue(value, data.tag));
                    }}
                  >
                    <Select.Option value={ELEMENT_ACTION.TAP}>Tap</Select.Option>
                    {/* <Select.Option value={ELEMENT_ACTION.TAP_BY_INDEX}>Tap By Index</Select.Option> */}
                    <Select.Option value={ELEMENT_ACTION.LONG_PRESS}>Long Press</Select.Option>
                    <Select.Option value={ELEMENT_ACTION.SEND_KEYS}>Enter Text</Select.Option>
                    <Select.Option value={ELEMENT_ACTION.CLEAR_TEXT}>Clear Text</Select.Option>
                    <Select.Option value={SWIPE_OVER_ELEMENT}>Swipe Element</Select.Option>
                  </Select>
                  <div className={styles["actionTitle"]}>on Element</div>
                </div>
              </div>
              <div className={styles["contentInCenter"]}>
                {action === ELEMENT_ACTION.TAP_BY_INDEX && (
                  <div className={styles["enterTextOrTapIndex"]}>
                    <TapByIndexV2 {...props} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div
          className={
            action !== VERIFICATIONS &&
            (action === ELEMENT_ACTION.SEND_KEYS ||
              action === SWIPE_OVER_ELEMENT ||
              !(isLoopStep === 1 && isStepHasPrecondition === 0)) &&
            styles["action_Details"]
          }
        >
          {action === ELEMENT_ACTION.SEND_KEYS && (
            <>
              <div className={styles["showActionDetails"]}>
                <EnterTextV2
                  {...props}
                  showTestDataContent={showTestDataContent}
                  testDataChecked={testDataChecked}
                  disabled={disabled}
                />
                <TestDataOptionV2
                  isForView={isForView}
                  data={data}
                  isInputDisabled={isInputDisabled}
                  projectId={projectId}
                  showTestDataContent={showTestDataContent}
                  testDataChecked={testDataChecked}
                  disabled={disabled}
                />
              </div>
            </>
          )}
          {action === SWIPE_OVER_ELEMENT && data.recTestStepProperty.isAIStep === 0 ? <SwipeElementV2 {...props} /> : ""}
          {action === VERIFICATIONS && (
            <VerifyExistenceV2
              disabled={disabled}
              recTestStep={data}
              recTestSteps={props.recTestSteps}
              showPostActionOrSettings={showPostActionOrSettings}
            />
          )}
          {action === STEP &&
            (isStepHasVerifyTestData === 1 &&
            recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") ? (
              <VerifyTestDataV2 {...props} />
            ) : (
              <VerifyExistenceV2
                disabled={disabled}
                recTestStep={data}
                recTestSteps={props.recTestSteps}
                showPostActionOrSettings={showPostActionOrSettings}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

ActionsVerificationItemsV2.propTypes = {
  data: PropTypes.object.isRequired,
};

const getActionType = (action) => {
  switch (action) {
    case ELEMENT_ACTION.SWIPE:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.TAP_BY_COORDINATES:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case ELEMENT_ACTION.CLEAR:
    case ELEMENT_ACTION.SWIPE_OVER_ELEMENT:
      return ACTIONS;
    case VERIFICATIONS:
    case STEP:
      return VERIFICATIONS;
    default:
      return ACTIONS;
  }
};
export default ActionsVerificationItemsV2;
