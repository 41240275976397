import { async } from "../api/automation";
import history from "../history";
import _ from "lodash";

import {
  ERROR_TYPE,
  GET_ORG_STEP_DETAIL,
  JOIN_ORGANIZATION,
  LIST_OF_ORG_MEMBERS,
  ORGANIZATION_LOADING,
  SAVE_ORGANIZATION,
  SAVE_PROJECT_DATA,
  SAVE_TEAM_DATA,
  SET_ORGANIZATION_CURRENT_STEP,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
} from "../Constants";
import { showMessage, updateResponse } from "../Util";

export function getSignUpSteps(data) {
  return async (dispatch, getState) => {
    return async("xpressUser/checkSignupSteps", "GET").then((response) => {
      dispatch({ type: GET_ORG_STEP_DETAIL, response });
    });
  };
}

export function setOrganizationCurrentStep(response) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_ORGANIZATION_CURRENT_STEP, response });
  };
}

export function saveOrganization(obj) {
  return async (dispatch) => {
    dispatch({ type: ORGANIZATION_LOADING, isLoading: true });
    return async("organization/save", "POST", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SAVE_ORGANIZATION, response: response?.data?.data });
        dispatch(setOrganizationCurrentStep(34));
        showMessage(SUCCESS_TYPE, response.message);
      } else {
        showMessage(ERROR_TYPE, response.message);
      }
      dispatch({ type: ORGANIZATION_LOADING, isLoading: false });
    });
  };
}

export function onTeamSubmit(obj) {
  return async (dispatch) => {
    dispatch({ type: ORGANIZATION_LOADING, isLoading: true });
    return async("team/save", "POST", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SAVE_TEAM_DATA, response: response?.data?.data });
        dispatch(setOrganizationCurrentStep(64));
        showMessage(SUCCESS_TYPE, response.message);
      } else {
        showMessage(ERROR_TYPE, response.message);
      }
      dispatch({ type: ORGANIZATION_LOADING, isLoading: false });
    });
  };
}

export function onProjectSubmit(obj) {
  return async (dispatch, getState) => {
    dispatch({ type: ORGANIZATION_LOADING, isLoading: true });
    return async(`project/save`, "POST", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        history.push(`${obj.orgId}/projects`);
        dispatch(setOrganizationCurrentStep(100));
        dispatch({ type: SAVE_PROJECT_DATA, response });
        showMessage(SUCCESS_TYPE, response.message);
      } else {
        showMessage(ERROR_TYPE, response.message);
      }
      dispatch({ type: ORGANIZATION_LOADING, isLoading: false });
    });
  };
}

export function getOrgMembersByOrgId(orgId, teamId) {
  return async (dispatch) => {
    return async(`orgUserAssoc/getListOfMembersByOrgId`, "GET", {
      orgId: orgId,
      teamId: teamId,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: LIST_OF_ORG_MEMBERS, response });
      }
    });
  };
}

export function joinOrganization(data) {
  return async (dispatch, getState) => {
    return async(`orgUserAssoc/join`, "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        history.push(`${data.orgId}/projects`);
      }
      dispatch({ type: JOIN_ORGANIZATION, response });
    });
  };
}