import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Col, Row, Tooltip } from "antd";
import { hideDeleteUserModal, removeMemberFromTeam, showDeleteUserModal } from "../../../actions/TeamsAction";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { TRASH_ICON } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./MembersByTeamV2.module.scss";

const MembersByTeamV2 = (props) => {
  const dispatch = useDispatch();
  const { myTeamMembers, removeUserRights } = props;
  const { teamNameOfProject, deleteUserId, deleteUserName, deleteUserModalVisible } = useSelector(
    (state) => state.ProjectsReducer
  );

  return (
    <div className={styles["members_By_Team_Details"]}>
      {myTeamMembers &&
        myTeamMembers.map((item) => {
          return (
            <Row span={24} className={styles["member_Row"]}>
              <Col span={24} className={styles["member_Col"]}>
                <Avatar className="avatar-font" style={{ backgroundColor: "#FCC7C7" }}>
                  {item?.userName
                    .split(" ")
                    .map((i) => i[0])
                    .join("")}
                </Avatar>
                <titleName className={styles['member_Name']}>{item?.userName}</titleName>
                <div className="memberRemove">
                  {removeUserRights && item?.isTL === 0 ? (
                    <Tooltip title="Remove User" color={TOOLTIP_BG_COLOR}>
                      <div>
                        <SvgLoader
                          iconName={TRASH_ICON}
                          handleClick={(e) => dispatch(showDeleteUserModal(item.userId, item.userName))}
                        />
                      </div>
                    </Tooltip>
                  ) : null}
                </div>
              </Col>
            </Row>
          );
        })}
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            removeMemberFromTeam({
              userId: deleteUserId,
              teamId: parseInt(localStorage.getItem("teamId")),
            })
          );
          dispatch(hideDeleteUserModal());
        }}
        handleCancel={() => dispatch(hideDeleteUserModal())}
        modalOpen={deleteUserModalVisible}
        modalTitle={"Confirm Removal"}
        modalContent={`Are you sure you want to remove ${deleteUserName} from ${teamNameOfProject} team?`}
        okText={"Remove"}
        isDeleteModal={true}
      />
    </div>
  );
};

export default MembersByTeamV2;
