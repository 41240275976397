import { Button, Col, Input, Row, Select, Space } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_DATA_SCOPE } from "../../Constants";
import {
  changeNewTestDataName,
  changeNewTestDataValue,
  hideAddTestDataForm,
  saveNewTestData,
} from "../../actions/TestDataAction";
const { Option } = Select;
const AddTestDataSystemKey = (props) => {
  const {
    newTestDataId,
    newTestDataName,
    newTestDataValue,
    requestSaveTestDataForm,
    newTestDataScope,
    searchValue,
    filterValue,
    previous_newTestDataValue,
  } = useSelector((props) => props.TestDataReducer);
  const { stepType } = useSelector((props) => props.RecTestStepReducer);
  const dispatch = useDispatch();
  let enableSaveTestDataButton =
    !requestSaveTestDataForm &&
    newTestDataName &&
    newTestDataName.trim().length > 0 &&
    previous_newTestDataValue !== newTestDataValue &&
    newTestDataValue &&
    newTestDataValue.trim().length > 0;
  // let scopeDropdownList = [];
  // scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.PROJECT}> {TEST_DATA_SCOPE.PROJECT} </Option>);
  // if (stepType === TEST_SCENARIOS) {
  //   scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.TEST_SCENARIO}> {TEST_DATA_SCOPE.TEST_SCENARIO} </Option>);
  // } else if (stepType === TEST_STEP_BLOCKS) {
  //   scopeDropdownList.push(<Option value={"Test Blocks"}> Test Blocks </Option>);
  // }

  return (
    <>
      <Row>
        <Col className="mb-10 ml-20" md={24}>
          <b>Add Test Data</b>
        </Col>
      </Row>
      {/* <Row>
        <Col className="display-flex" md={24}>
          <Col className="display-flex" md={12}>
            <Col md={2}>
              <span>Scope</span>
            </Col>
            <Col md={5}>
              <Select
                placeholder="Select Scope"
                value={newTestDataScope}
                onChange={(e) => dispatch(changeNewTestDataScope(e))}>
                {scopeDropdownList}
              </Select>
            </Col>
          </Col>
          <Col className="display-flex" md={12}>
            <Col md={2}>
              <span>Type</span>
            </Col>
            <Col>
              <Select
                placeholder="Select Type"
                value={newTestDataType}
                onChange={(e) => dispatch(changeNewTestDataType(e))}>
                {typeDropdownList}
              </Select>
            </Col>
          </Col>
        </Col>
      </Row> */}
      <Row className="mt-10 ml-20">
        <Col md={12} className="display-flex align-items-center gap-10">
          <Col md={2}>
            <span>Name:</span>
          </Col>
          {newTestDataId ? (
            newTestDataName
          ) : (
            <>
              <Col md={10}>
                <Input
                  type="text"
                  value={newTestDataName}
                  placeholder="Test Data Name"
                  onChange={(e) => dispatch(changeNewTestDataName(e.target.value))}
                />
              </Col>
            </>
          )}
        </Col>
        <Col md={12} className="pl-10 display-flex align-items-center gap-10">
          <Col md={2}>
            <span>Value:</span>
          </Col>
          <Col md={10}>
            <Input
              type="text"
              value={newTestDataValue}
              placeholder="Test Data Value"
              onChange={(e) => dispatch(changeNewTestDataValue(e.target.value))}
            />
          </Col>
        </Col>
      </Row>
      <Row className="mt-10 ml-20">
        <Col md={24}>
        <Space size={10}>
          <Button
            disabled={!enableSaveTestDataButton}
            type="primary"
            onClick={() =>
              enableSaveTestDataButton
                ? dispatch(
                    saveNewTestData(
                      newTestDataName,
                      newTestDataValue,
                      newTestDataId,
                      props.projectId,
                      TEST_DATA_SCOPE.PROJECT,
                      1,
                      
                    )
                  )
                : ""
            }
          >
            {newTestDataId ? "Update" : "Add"}
          </Button>
          <Button onClick={() => dispatch(hideAddTestDataForm())}>Cancel</Button>
          </Space>
        </Col>
      </Row>
    </>
  );
};

export default AddTestDataSystemKey;
