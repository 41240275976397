import { Button, Col, Row } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  openAddEmulatorModalVisible,
  saveProjects,
  startVirtualDevice,
  stopVirtualDevice,
} from "../../../actions/ServerAction";
import ProjectListModal from "./ProjectListModal";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";

const RegisterDeviceDetails = (props) => {
  const {
    orgId,
    serverId,
    serverIp,
    deviceDetails,
    isEmulatorServer,
    serverArmType,
  } = props;
  const dispatch = useDispatch();
  const [deviceData, setDeviceData] = useState({});
  const [openProjectModal, setOpenProjectModal] = useState(false);
  const { virutalDeviceActionRequestLoader } = useSelector(
    (state) => state.ServerReducer
  );
  return (
    <>
      {isEmulatorServer ? (
        <Row>
          <Col span={24} className="text-align-right">
            <Button
              type="primary"
              onClick={() => dispatch(openAddEmulatorModalVisible(serverId, serverIp, serverArmType))}
            >
              Add Emulator
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <Row className="w-100 display-flex justify-evenly p-10 font-weight-500 bg-color-gray">
        <Col span={2} className="text-align-center">
          TargetUniqueID
        </Col>
        <Col span={2} className="text-align-center">
          Device ID
        </Col>
        <Col span={2} className="text-align-center">
          Name
        </Col>
        <Col span={2} className="text-align-center">
          Manufacturer
        </Col>
        <Col span={2} className="text-align-center">
          Model
        </Col>
        <Col span={2} className="text-align-center">
          OS Type
        </Col>
        <Col span={2} className="text-align-center">
          OS Version
        </Col>
        <Col span={2} className="text-align-center">
          Locale
        </Col>
        <Col span={2} className="text-align-center">
          Status
        </Col>
        <Col span={3} className="text-align-center">
          Action
        </Col>
      </Row>

      {!_.isEmpty(deviceDetails) &&
        deviceDetails.map((i, j) => {
          if (i?.isActive == 1) {
            return (
              <Row key={j} className="w-100 display-flex justify-evenly p-10 border-top">
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.targetUniqueId || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.udid || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.displayName || i?.name || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {(i?.brand ? i?.brand : "N/A") + (i.isVirtualDevice === 1 ? " (Virtual Device) " : " ")}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.model || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.platform || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.osVersion || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.locale || "N/A"}
                </Col>
                <Col span={2} className="text-align-center align-self-center word-break">
                  {i?.status || "N/A"}
                </Col>
                <Col span={3} className="text-align-center align-self-center">
                  {((i?.status === "NOT_CONNECTED" || i.id === 0) && i.isVirtualDevice === 0 && (
                    <Button
                      className="orange"
                      size="small"
                      onClick={() => {
                        setOpenProjectModal(true);
                        setDeviceData(i);
                      }}
                    >
                      Authorize
                    </Button>
                  )) ||
                    null}
                  {(i?.status === "CONNECTED" &&
                    i.id !== 0 &&
                    i.isVirtualDevice === 0 && (
                      <Button
                        className="orange"
                        size="small"
                        onClick={() =>
                          dispatch(
                            saveProjects(orgId, serverId, serverIp, i, true)
                          )
                        }
                      >
                        De-Authorize
                      </Button>
                    )) ||
                    null}
                  {(i?.isVirtualDevice === 1 &&
                    i?.status === "NOT_CONNECTED" && (
                      <Button
                        loading={virutalDeviceActionRequestLoader[serverId]}
                        disabled={virutalDeviceActionRequestLoader[serverId]}
                        type="primary"
                        onClick={() => {
                          dispatch(startVirtualDevice(i, serverId, serverIp));
                        }}
                      >
                        {"Start Virtual Device"}
                      </Button>
                    )) ||
                    null}
                  {(i?.isVirtualDevice === 1 && i?.status === "CONNECTED" && (
                    <Button
                      type="danger"
                      loading={virutalDeviceActionRequestLoader[serverId]}
                      disabled={virutalDeviceActionRequestLoader[serverId]}
                      onClick={() => {
                        dispatch(stopVirtualDevice(i, serverId, serverIp));
                      }}
                    >
                      {"Stop Virtual Device"}
                    </Button>
                  )) ||
                    null}
                  {(i?.status === "NOT_REGISTERED" &&
                    i.id === 0 &&
                    i.isVirtualDevice === 1 && (
                      <Button
                        className="orange"
                        size="small"
                        onClick={() => {
                          setOpenProjectModal(true);
                          setDeviceData(i);
                        }}
                      >
                        Register
                      </Button>
                    )) ||
                    null}
                </Col>
              </Row>
            );
          }
        })}
      {_.isEmpty(deviceDetails) && (
        <EmptyRecords description={"No Data Found"} />
      )}
      {openProjectModal && (
        <ProjectListModal
          orgId={orgId}
          openProjectModal={openProjectModal}
          setOpenProjectModal={setOpenProjectModal}
          deviceData={deviceData}
          serverId={serverId}
          serverIp={serverIp}
        />
      )}
    </>
  );
};

export default RegisterDeviceDetails;
