import { Button, Modal, Select } from "antd";
import { isEmpty } from "lodash";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setLoaderForInvitation } from "../../../actions/InvitationAction";
import { inviteMember } from "../../../actions/ProjectsAction";
import { addMemberToTeam } from "../../../actions/TeamsAction";
import { CLOSE_ICON_RED } from "../../../Constants/SvgConstants";
import { showNotification } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./InviteMemberModalV2.module.scss";

const { Option } = Select;

const InviteMemberModalV2 = (props) => {
  const { modalStatus, modalClosed, projectId, teamId, orgId, addMembers } = props;
  const [selectedMembers, setSelectedMembers] = useState([]);
  const dispatch = useDispatch();
  let { inviteMembers } = useSelector((state) => state.ProjectsReducer);
  const { invitationLoader } = useSelector((state) => state.InvitationReducer);

  useEffect(() => {
    if (inviteMembers) {
      handleCloseModal();
    }
  }, [modalClosed, inviteMembers]);

  const handleCloseModal = () => {
    setSelectedMembers([]);
    dispatch(setLoaderForInvitation({ data: false }));
    modalClosed();
  };
  const handleSaveButton = () => {
    if (selectedMembers.length > 0) {
      if (addMembers) {
        let preparedObject = {
          teamId: localStorage.getItem("teamId"),
          teamMembers: selectedMembers,
        };
        dispatch(addMemberToTeam(preparedObject));
      } else {
        if (projectId) {
          let preparedObject = {
            inviteeMail: selectedMembers,
            projectId: projectId && projectId,
          };
          dispatch(inviteMember(preparedObject));
        } else {
          let preparedObject = {
            teamMembers: selectedMembers,
            teamId: teamId && teamId,
            orgId: orgId,
          };
          dispatch(addMemberToTeam(preparedObject));
        }
      }
    } else {
      showNotification("error", "Please Select Member.", "");
    }
    modalClosed();
  };

  const children = [];
  const { myOrgUsers } = useSelector((state) => state.TeamsReducer);
  if (myOrgUsers) {
    for (const element of myOrgUsers) {
      children.push(<Option key={element.email}>{element.email}</Option>);
    }
  }
  function handleChange(value) {
    setSelectedMembers(value);
  }
  return (
    <>
      <Modal
        title={<span className={styles["modal_Title"]}>Add New Members</span>}
        visible={modalStatus}
        closeIcon={<SvgLoader iconName={CLOSE_ICON_RED} />}
        closable={false}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <div className="modal-Content-Btn">
            <Button
              key="submit"
              className={`modal_Buttons modal_Cancel_Btn modal-Button-Width`}
              loading={invitationLoader}
              onClick={() => handleCloseModal()}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              loading={invitationLoader}
              className={`modal_Buttons modal-Ok-Btn modal-Button-Width`}
              onClick={() => handleSaveButton()}
              disabled={isEmpty(selectedMembers)}
            >
              Add
            </Button>
          </div>,
        ]}
        className="invite_Members_Modal"
      >
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>
            Members
            <span className={styles["field-required"]}>*</span>
          </div>
          <Select
            className={styles["modal-select-box"]}
            mode="tags"
            placeholder="Add Team Member by Email"
            onChange={handleChange}
            value={selectedMembers}
          >
            {children}
          </Select>
        </div>
        {addMembers && <div className="w-100 mb-10">Note: Added members will be notified by email.</div>}
      </Modal>
    </>
  );
};

export default InviteMemberModalV2;