import React from "react";
import _ from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import { Tooltip } from "antd";
import styled from "styled-components";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { getDeviceName, getMaxRetryStep, isNotNullAndNotEmptyAndNotUndefined, moveArrayItem } from "../../../../Util";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";

const StepName = styled.div`
  width: 100%;
`;
const StepIndex = styled.span`
  font-weight: 700;
  float: left;
  padding-right: 10px;
`;
const ImageItem = styled.div`
  width: 225px !important;
  display: inline-block;
  border: ${(props) =>
    "1px solid " + (props.result === "Passed" ? "green" : props.result === "Failed" ? "red" : "lightgrey")};
  div {
    text-align: center;
  }
  ,
  img {
    display: inline-block !important;
    float: left;
    border: ${(props) =>
      "1px solid " + (props.result === "Passed" ? "green" : props.result === "Failed" ? "red" : "lightgrey")};
  }
`;
const CountDetail = styled.div`
  width: 225px !important;
  display: inline-block;
  margin-right: 60px;
  div {
    color: ${(props) => (props.result ? "green" : props.result !== undefined ? "red" : "lightgrey")};
    text-align: center;
  }
  ,
  img {
    display: inline-block !important;
    float: left;
  }
`;
const getSelectedDeviceName = (deviceDetails = [], targetUniqueId) => {
  let deviceDetail = deviceDetails.filter((item) => item.targetUniqueId === targetUniqueId);
  return deviceDetail.length > 0 ? getDeviceName(deviceDetail[0]) + " | " + deviceDetail[0].osVer : "";
};

const TestPlanResultAllLocalePreviewImages = (props) => {
  const { scenarioDetails, refRunNumberLocaleForPreview, refRunNumberForPreview } = props;
  let isRefSet = refRunNumberLocaleForPreview !== undefined && refRunNumberForPreview !== undefined;
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  let expectedCountsOfKey = {};
  let capturedCountsOfKey = {};
  if (scenarioDetails?.steps?.length > 0) {
    Object.keys(scenarioDetails?.steps[0]).forEach((key) => {
      if (
        ![
          "tag",
          "name",
          "expectedScreenshots",
          "capturedScreenshots",
          "refExpectedScreenshots",
          "refCapturedScreenshots",
        ].includes(key)
      ) {
        return scenarioDetails?.steps.map((step) => {
          if (capturedCountsOfKey[key] === undefined) {
            capturedCountsOfKey[key] = parseInt(0);
            expectedCountsOfKey[key] = parseInt(0);
          }
          if (step[key]) {
            capturedCountsOfKey[key] = parseInt(capturedCountsOfKey[key]) + 1;
          }
          expectedCountsOfKey[key] = parseInt(expectedCountsOfKey[key]) + 1;
        });
      }
    });
  }
  return (
    <>
      {scenarioDetails &&
        scenarioDetails?.steps?.map((step, index) => {
          const stepName = step?.name?.replaceAll("##", "/").split("$$");
          let refDeviceId = "";
          let refImageLink = "";
          if (isRefSet && step["refLocale"] && step["refLocale"].includes("$$")) {
            refDeviceId = step["refLocale"].split("$$")[0];
            refImageLink = step["refLocale"].split("$$")[3];
          }
          return (
            <>
              {index === 0 && step.isSkipStep === 0 && (
                <div className={"" + (index > 0 ? "ml-5  mt-10" : "")} key={index}>
                  {isRefSet && (
                    <CountDetail className="">
                      <div>
                        <span className="font-weight-700">Reference Locale :</span> {refRunNumberLocaleForPreview}
                      </div>
                      <div>
                        <span className="font-weight-700">Expected Count :</span> {expectedCountsOfKey["refLocale"]}
                      </div>
                      <div>
                        <span className="font-weight-700">Captured Count :</span> {capturedCountsOfKey["refLocale"]}
                      </div>
                    </CountDetail>
                  )}
                  <Slider {...settings}>
                    {Object.keys(expectedCountsOfKey).map((key, sub_index) => {
                      return (
                        !["refLocale"].includes(key) && (
                          <CountDetail
                            key={sub_index}
                            result={
                              expectedCountsOfKey["refLocale"]
                                ? expectedCountsOfKey["refLocale"] === capturedCountsOfKey[key]
                                : undefined
                            }>
                            <div>
                              <span className="font-weight-700">Locale :</span> {key}
                            </div>
                            <div>
                              <span className="font-weight-700">Expected Count :</span> {expectedCountsOfKey[key]}
                            </div>
                            <div>
                              <span className="font-weight-700">Captured Count :</span> {capturedCountsOfKey[key]}
                            </div>
                          </CountDetail>
                        )
                      );
                    })}
                  </Slider>
                </div>
              )}
              <div className={"" + (index > 0 ? "ml-5  mt-10" : "")}>
                <Tooltip color={TOOLTIP_BG_COLOR} title={stepName}>
                  <StepName
                    className="w-100 overflow-hidden overflow-ellipsis white-space-nowrap font-weight-500 mb-5 cursor-pointer"
                    isRefSet={isRefSet}>
                    <StepIndex>{index + 1 + " "}</StepIndex>
                    {stepName}
                  </StepName>
                </Tooltip>
                {isRefSet && (
                  <ImageItem className="ml-10 mr-50" result={step?.result}>
                    <div className="overflow-hidden overflow-ellipsis white-space-nowrap">
                      <span className="font-weight-700">Ref Locale :</span>
                      <span>
                        {refRunNumberLocaleForPreview +
                          "(" +
                          getSelectedDeviceName(scenarioDetails?.deviceDetails, refDeviceId) +
                          ")"}
                      </span>
                    </div>
                    <img height={350} width={225} className="float-right" src={refImageLink} alt={"Not Executed"} />
                  </ImageItem>
                )}
                <Slider {...settings}>
                  {Object.keys(step).map((value) => {
                    let isSkipStep = "";
                    let retryIndex = "";
                    let targetUniqueId = "";
                    let imageLink = "";
                    if (
                      step[value] &&
                      ![
                        "refLocale",
                        "tag",
                        "name",
                        "expectedScreenshots",
                        "capturedScreenshots",
                        "refExpectedScreenshots",
                        "refCapturedScreenshots",
                      ].includes(value) &&
                      step[value].includes("$$")
                    ) {
                      isSkipStep = step[value].split("$$")[0];
                      retryIndex = step[value].split("$$")[1];
                      targetUniqueId = step[value].split("$$")[2];
                      imageLink = step[value].split("$$")[3];
                    }
                    return (
                      ![
                        "refLocale",
                        "tag",
                        "name",
                        "expectedScreenshots",
                        "capturedScreenshots",
                        "refExpectedScreenshots",
                        "refCapturedScreenshots",
                      ].includes(value) && (
                        <ImageItem className="ml-10" result={step?.result}>
                          <div>
                            {value + " ( " + getSelectedDeviceName(scenarioDetails?.deviceDetails, targetUniqueId) + ")"}
                          </div>
                          {isNotNullAndNotEmptyAndNotUndefined(imageLink) ? (
                            <Zoom>
                              <picture>
                                <source media="(max-width: 800px)" srcSet={imageLink} />
                                <img
                                  height={350}
                                  width={225}
                                  className="float-right"
                                  src={imageLink}
                                  alt={"Screenshot"}
                                />
                              </picture>
                            </Zoom>
                          ) : (
                            <img height={350} width={225} className="float-right" src={imageLink} alt={"Screenshot"} />
                          )}
                        </ImageItem>
                      )
                    );
                  })}
                </Slider>
              </div>
            </>
          );
        })}
      {_.isEmpty(scenarioDetails?.steps) && <EmptyRecords description="No Data Found" />}
    </>
  );
};

export default TestPlanResultAllLocalePreviewImages;
