import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Checkbox, Select, Spin, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../../../Constants";
import { CONDITION_DELETE_IMAGE } from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { fetchAppActivity } from "../../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  changeRemoveAppPreviousSession,
  selectedAppActivityForLaunch,
  selectedAppForLaunch,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./LaunchAppListV2.module.scss";

const LaunchAppListV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, data, isInputDisabled, disabled } = props;
  const { packageName, packageActivity } = data.recTestStepProperty;
  const { selectedDeviceByUdid, requestingLaunchAppActivity, selectedDeviceIdForRecording } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, installedApps, selectedAppActivities } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  useEffect(() => {
    if (
      selectedDeviceIdForRecording &&
      packageName &&
      packageActivity &&
      selectedDeviceByUdid?.platformType?.name === "ANDROID"
    ) {
      dispatch(fetchAppActivity(packageName));
    }
  }, [selectedDeviceIdForRecording]);

  const disabledSelectBox = useMemo(
    () => isForView || isInputDisabled || disabled,
    [isForView, isInputDisabled, disabled]
  );

  return (
    <Spin spinning={requestingLaunchAppActivity}>
      <div className={styles["launch_App_Details"]}>
        <div className={styles['title_And_Launch_App']}>
          <span class={styles["operation_Text"]}>Operation :</span>
          <span class={styles["action_Launch_App"]}>Launch App</span>
        </div>
        <div className={styles["Label"]}>Package Name</div>
        <Select
          className={`${styles["select_Box"]} ${disabledSelectBox && styles["disabled_Select_Box"]}`}
          disabled={disabledSelectBox}
          value={packageName}
          onChange={(value) => {
            dispatch(selectedAppForLaunch(value, data.tag));
          }}
          showSearch
          placeholder="Select a Package Name"
        >
          {installedApps?.map((v, k) => {
            return (
              <Select.Option key={k} value={v.name}>
                {v.name}
              </Select.Option>
            );
          })}
        </Select>
        {selectedDeviceByUdid?.platformType?.name === "ANDROID" && (
          <>
            <div className={styles["Label"]}>Activity Name</div>
            <div className={styles["select_Box_And_Remove_Icon"]}>
              <Select
                placeholder="Select a Activity Name"
                className={`${styles["select_Box"]} ${disabledSelectBox && styles["disabled_Select_Box"]}`}
                disabled={disabledSelectBox}
                value={packageActivity}
                onChange={(value) => {
                  dispatch(selectedAppActivityForLaunch(value, data.tag));
                }}
                showSearch
              >
                {!isEmpty(selectedAppActivities) &&
                  selectedAppActivities?.map((v, k) => {
                    return (
                      <Select.Option key={k} value={v}>
                        {v}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Tooltip title={"Remove App Activity"} color={TOOLTIP_BG_COLOR}>
                <div>
                  <SvgLoader
                    disabled={!packageActivity}
                    iconName={CONDITION_DELETE_IMAGE}
                    handleClick={() => packageActivity && dispatch(selectedAppActivityForLaunch(null, data.tag))}
                    width="2rem"
                    height="2rem"
                  />
                </div>
              </Tooltip>
            </div>
          </>
        )}
        <div className={styles["checkBox_And_Note"]}>
          <Checkbox
            className="selection-checkBox"
            disabled={
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag) ||
              selectedDeviceByUdid?.platformType?.name !== "ANDROID" ||
              disabled
            }
            checked={data.recTestStepProperty?.removeAppPreviousSession === 1 ? true : false}
            onChange={(e) =>
              selectedDeviceByUdid?.platformType?.name === "ANDROID"
                ? dispatch(changeRemoveAppPreviousSession(e.target.checked ? 1 : 0, data.tag))
                : undefined
            }
          />
          <span className={styles["checkBox_Note"]}>
            Clear cache to remove previous session before Launching App
            <span className={styles["android_App_text"]}>(Android App Only)</span>
          </span>
        </div>
      </div>
    </Spin>
  );
};

export default LaunchAppListV2;
