import {
  ADD_MULTI_COLUMN_VIEW_STEP,
  ADD_REC_TEST_STEP_ACTION_MULTI_COLUMN_VIEW,
  CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME,
  CHANGE_MULTI_COLUMN_VIEW_DISABLE_STEP,
  CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW,
  LOAD_STEP_CONDITION_STEP,
  RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME,
  SELECTED_REC_TEST_STEP_DETAIL,
  SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL,
  SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL,
  SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME,
  SELECTED_RENAME_STEP_TAG,
  SUCCESS_STATUS,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UNLOAD_STEP_CONDITION_STEP,
  UPDATE_MULTIPLE_COLUMN_VIEW_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  MULTI_COLUMN_UPDATE_PREVIEW_STATUS,
  UPDATE_REC_TEST_STEP_MULTI_COLUMN_VIEW,
  UPDATE_UNSAVED_REC_TEST_STEP_PATH,
  CLEAR_PREVIEW_FOR_MULTI_COLUMN_VIEW,
  DELETE_MULTI_COLUMN_VIEW_STEP,
  CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW,
} from "../Constants";
import _ from "lodash";
let defaultState = {
  childRecTestSteps: [],
  selectedRecTestStepDetail: undefined,
  parentRecTestSteps: undefined,
  childTestBlockSteps: undefined,
  childRecTestStepData: undefined,
  unSavedRecTestStepPath: [],
  loadStepConditionalStep: false,
  changedRecTestScenarioName: false,
  changedRecTestStepConditionTitleName: false,
};

export default function RecTestStepMultiColumnViewReducer(state = defaultState, action) {
  let childRecTestSteps = [];
  let unSavedRecTestStepPath = [];
  let parentId = undefined;
  switch (action.type) {
    case ADD_MULTI_COLUMN_VIEW_STEP:
      let data = action?.response?.data.recTestSteps ? action?.response?.data.recTestSteps : action?.response?.data;
      const childRecTestStepsValues = data?.childRecTestSteps && Object.values(data?.childRecTestSteps);
      const childRecTestStepsResult =
        childRecTestStepsValues && childRecTestStepsValues.reduce((r, e) => (r.push(...e), r), []);
      return {
        ...state,
        parentRecTestSteps: data?.parentRecTestSteps,
        childRecTestSteps: childRecTestStepsResult,
        childTestBlockSteps: data?.childTestBlockSteps,
        childRecTestStepData: data.childRecTestSteps,
      };
    case UPDATE_MULTIPLE_COLUMN_VIEW_ACTION_STEP_NAME_CONTINUE_ON_FAILURE:
      let updateParentStep = [...state.parentRecTestSteps];
      let updateChildStep = [...state.childRecTestSteps];
      if (action.response.status === SUCCESS_STATUS) {
        if (
          state.parentRecTestSteps.some((i) =>
            i?.isTestBlock === 0
              ? i.recTestScenarioRecTestStepAssocId === action.recTestStep.recTestScenarioRecTestStepAssocId
              : i?.recTestStepId === action.recTestStepId
          )
        ) {
          updateParentStep.map((obj) => {
            if (
              obj?.isTestBlock === 0
                ? obj.recTestScenarioRecTestStepAssocId === action.recTestStep.recTestScenarioRecTestStepAssocId
                : obj?.recTestStepId === action.recTestStepId
            ) {
              obj.stepName = action.updateStepActionName;
            }
            return obj;
          });
        } else {
          updateChildStep.map((obj) => {
            if (
              obj?.isTestBlock === 0
                ? obj.recTestScenarioRecTestStepAssocId === action.recTestStep.recTestScenarioRecTestStepAssocId
                : obj?.recTestStepId === action.recTestStepId
            ) {
              obj.stepName = action.updateStepActionName;
            }
            return obj;
          });
        }
      }
      return {
        ...state,
        requestingUpdateRecTestStepAction: true,
        changedRecTestScenarioName: false,
        parentRecTestSteps: updateParentStep,
        childRecTestSteps: updateChildStep,
        updateStepActionName: undefined,
      };

    case CHANGE_MULTI_COLUMN_VIEW_DISABLE_STEP:
      let disabledParentStep = [...state.parentRecTestSteps];
      let disabledChildStep = [...state.childRecTestSteps];
      let recTestStepId = action.recTestSteps.recTestStepId;
      if (
        state.parentRecTestSteps.some((i) =>
          i?.isTestBlock === 0
            ? i.recTestScenarioRecTestStepAssocId === action.recTestSteps.recTestScenarioRecTestStepAssocId
            : i?.recTestStepId === recTestStepId
        )
      ) {
        disabledParentStep.map((obj) => {
          if (
            obj.isTestBlock === 0
              ? obj.recTestScenarioRecTestStepAssocId === action.recTestSteps.recTestScenarioRecTestStepAssocId
              : obj?.recTestStepId === recTestStepId
          ) {
            obj.isSkipStep = action.checked;
          }
          return obj;
        });
      } else {
        disabledChildStep.map((obj) => {
          if (
            obj.isTestBlock === 0
              ? obj.recTestScenarioRecTestStepAssocId === action.recTestSteps.recTestScenarioRecTestStepAssocId
              : obj?.recTestStepId === recTestStepId || obj?.recTestStepId === action?.recTestSteps?.id
          ) {
            obj.isSkipStep = action.checked;
          }
          return obj;
        });
      }
      allChildDisabled(action.recTestSteps, action.checked, disabledChildStep);
      return {
        ...state,
        requestingUpdateRecTestStepAction: true,
        changedRecTestScenarioName: false,
        parentRecTestSteps: disabledParentStep,
        childRecTestSteps: disabledChildStep,
        updateStepActionName: undefined,
      };
    case CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME:
      return {
        ...state,
        updateStepActionName: action.value,
        changedRecTestStepConditionTitleName: action.isForConditionTitle ? true : false,
        changedRecTestScenarioName: action.isForConditionTitle === undefined,
      };
    case RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME:
      return {
        ...state,
        changedRecTestScenarioName: false,
        updateStepActionName: action.value,
      };
    case SELECTED_REC_TEST_STEP_DETAIL:
      return {
        ...state,
        selectedRecTestStepDetail: action?.response?.data,
        loadStepConditionalStep: action.loadStepConditionalStep ? true : false,
      };
    case CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW:
      return {
        ...state,
        selectedRecTestStepDetail: undefined,
      };
    case SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL:
      action.selectedRecTestStepDetail["recTestStepProperty"]["stepName"] =
        action.selectedRecTestStepDetail["recTestStepProperty"]["preconditionTitle"];
      if (
        unSavedRecTestStepPath.filter(
          (item) =>
            item.recTestStepId === action.selectedRecTestStepDetail.id ||
            item.recTestScenarioRecTestStepAssocId ===
              action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId
        ).length === 0
      ) {
        state.unSavedRecTestStepPath.push({
          isTestBlock: action.selectedRecTestStepDetail.isTestBlock,
          key: action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId,
          label: action.selectedRecTestStepDetail["recTestStepProperty"]["preConditionTitle"],
          recTestScenarioRecTestStepAssocId: action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId,
          recTestStepId: action.selectedRecTestStepDetail.id,
          siblingSteps: [],
        });
      }
      return {
        ...state,
        selectedRecTestStepDetail: action.selectedRecTestStepDetail,
        loadStepConditionalStep: true,
      };
    case LOAD_STEP_CONDITION_STEP:
      return {
        ...state,
        loadStepConditionalStep: true,
      };
    case UNLOAD_STEP_CONDITION_STEP:
      return {
        ...state,
        loadStepConditionalStep: false,
      };
    case SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL:
      action.selectedRecTestStepDetail["recTestStepProperty"]["stepName"] =
        action.selectedRecTestStepDetail["recTestStepProperty"]["preconditionTitle"];
      if (
        unSavedRecTestStepPath.filter(
          (item) =>
            item.recTestStepId === action.selectedRecTestStepDetail.id ||
            item.recTestScenarioRecTestStepAssocId ===
              action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId
        ).length === 0
      ) {
        state.unSavedRecTestStepPath.push({
          isTestBlock: action.selectedRecTestStepDetail.isTestBlock,
          key: action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId,
          label: "Repeat Condition",
          recTestScenarioRecTestStepAssocId: action.selectedRecTestStepDetail.recTestScenarioRecTestStepAssocId,
          recTestStepId: action.selectedRecTestStepDetail.id,
          siblingSteps: [],
        });
      }
      return {
        ...state,
        selectedRecTestStepDetail: action.selectedRecTestStepDetail,
        loadStepConditionalStep: true,
      };
    case SELECTED_RENAME_STEP_TAG:
      return {
        ...state,
        selectedRenameStepTag: action.selectedRenameStepTag,
      };
    case UPDATE_UNSAVED_REC_TEST_STEP_PATH:
      return {
        ...state,
        unSavedRecTestStepPath: action.stepPath,
      };
    case ADD_REC_TEST_STEP_ACTION_MULTI_COLUMN_VIEW:
      let recTestStep = action.recTestStep;
      // Added UnSaved Step Step Path
      unSavedRecTestStepPath = state.unSavedRecTestStepPath ? state.unSavedRecTestStepPath : [];
      if (
        unSavedRecTestStepPath.filter(
          (item) =>
            item.recTestStepId === recTestStep.id ||
            item.recTestScenarioRecTestStepAssocId === recTestStep.recTestScenarioRecTestStepAssocId
        ).length === 0
      ) {
        unSavedRecTestStepPath.push({
          isTestBlock: recTestStep?.isTestBlock,
          key: recTestStep.recTestScenarioRecTestStepAssocId
            ? recTestStep.recTestScenarioRecTestStepAssocId
            : recTestStep.tag,
          label: recTestStep["recTestStepProperty"]["stepName"],
          recTestScenarioRecTestStepAssocId: recTestStep.recTestScenarioRecTestStepAssocId,
          recTestStepId: recTestStep.id,
          siblingSteps: [],
        });
      }
      // Added Step in OverList view
      if (
        recTestStep.parentRecTestStep &&
        (action.stepType === TEST_SCENARIOS ||
          (action.stepType === TEST_STEP_BLOCKS &&
            recTestStep?.testBlockRecTestStep?.id !== recTestStep?.parentRecTestStep.id))
      ) {
        parentId =
          action.stepType === TEST_SCENARIOS
            ? recTestStep.parentRecTestScenarioRecTestStepsAssoc.id
            : recTestStep.parentRecTestStep.id;
        if (state.childRecTestStepData[parentId]) {
          childRecTestSteps = [...state.childRecTestStepData[parentId]];
          childRecTestSteps.push(prepareRecTestStepObjectWithBasicDetails(recTestStep));
          state.childRecTestStepData[parentId] = childRecTestSteps;

          const childRecTestStepsValues = state.childRecTestStepData && Object.values(state.childRecTestStepData);
          state.childRecTestSteps =
            childRecTestStepsValues && childRecTestStepsValues.reduce((r, e) => (r.push(...e), r), []);
        } else if (state.childRecTestStepData[parentId] === undefined) {
          state.childRecTestStepData[parentId] = [prepareRecTestStepObjectWithBasicDetails(recTestStep)];
        }

        if (state.childTestBlockSteps[recTestStep.parentRecTestStep.id]) {
          let childTestBlockSteps = [...state.childTestBlockSteps[recTestStep.parentRecTestSteps.id]];
          childTestBlockSteps.push(prepareRecTestStepObjectWithBasicDetails(recTestStep));
          state.childTestBlockSteps[recTestStep.parentRecTestStep.id] = childTestBlockSteps;
        }
      } else {
        state.parentRecTestSteps.push(prepareRecTestStepObjectWithBasicDetails(recTestStep));
      }
      return {
        ...state,
        unSavedRecTestStepPath,
        selectedRecTestStepDetail: recTestStep,
      };

    case SELECTED_REC_TEST_STEP_UPDATED_STEP_NAME:
      let updateSelectedRecTestStepDetail = state.selectedRecTestStepDetail;
      if (action.response.status === SUCCESS_STATUS) {
        if (state.selectedRecTestStepDetail?.recTestSteps?.some((i) => i.id === action.recTestStepId)) {
          updateSelectedRecTestStepDetail.recTestSteps.map((obj) => {
            if (obj.id === action.recTestStepId && obj.recTestStepProperty) {
              obj.recTestStepProperty.stepName = action.data.stepName;
            }
            return obj;
          });
        }
      }
      return {
        ...state,
        selectedRecTestStepDetail: updateSelectedRecTestStepDetail,
      };
    case UPDATE_REC_TEST_STEP_MULTI_COLUMN_VIEW:
      // Added Step in OverList view
      parentId = undefined;
      if (action.stepType === TEST_SCENARIOS) {
        if (action.updatedRecTestStep?.parentRecTestScenarioRecTestStepsAssoc) {
          parentId = action.updatedRecTestStep?.parentRecTestScenarioRecTestStepsAssoc.id;
        } else if (action.updatedRecTestStep?.parentId) {
          parentId = action.updatedRecTestStep?.parentId;
        }
      } else if (action.stepType === TEST_STEP_BLOCKS) {
        if (action.updatedRecTestStep?.parentRecTestStep) {
          parentId = action.updatedRecTestStep?.parentRecTestStep?.id;
        } else if (
          action.updatedRecTestStep?.parentId &&
          action.updatedRecTestStep?.parentId !== action.updatedRecTestStep?.testBlockRecTestStepId
        ) {
          parentId = action.updatedRecTestStep?.parentId;
        }
      }
      if (action.updatedRecTestStep?.parentRecTestStep || parentId) {
        if (state.childRecTestStepData[parentId]) {
          childRecTestSteps = [...state.childRecTestStepData[parentId]];
          childRecTestSteps = childRecTestSteps.map((childRecTestStep) => {
            if (childRecTestStep.tag === action.updatedRecTestStep.tag) {
              childRecTestStep = prepareRecTestStepObjectWithBasicDetails(action.updatedRecTestStep);
            }
            return childRecTestStep;
          });
          state.childRecTestStepData[parentId] = childRecTestSteps;
          const childRecTestStepsValues = state.childRecTestStepData && Object.values(state.childRecTestStepData);
          state.childRecTestSteps =
            childRecTestStepsValues && childRecTestStepsValues.reduce((r, e) => (r.push(...e), r), []);
        }
        if (state.childTestBlockSteps[parentId]) {
          let childTestBlockSteps = [...state.childTestBlockSteps[parentId]];
          childTestBlockSteps = childTestBlockSteps.map((childRecTestStep) => {
            if (childRecTestStep.tag === action.updatedRecTestStep.tag) {
              childRecTestStep = prepareRecTestStepObjectWithBasicDetails({ ...action.updatedRecTestStep });
            }
            return childRecTestStep;
          });
          state.childTestBlockSteps[parentId] = childTestBlockSteps;
        }
      } else {
        state.parentRecTestSteps = state.parentRecTestSteps.map((recTestStep) => {
          if (recTestStep.tag === action.updatedRecTestStep?.tag) {
            recTestStep = prepareRecTestStepObjectWithBasicDetails({ ...action.updatedRecTestStep });
          }
          return recTestStep;
        });
      }
      unSavedRecTestStepPath = state.unSavedRecTestStepPath ? state.unSavedRecTestStepPath : [];
      if (
        unSavedRecTestStepPath.filter(
          (item) =>
            item?.recTestStepId === action.updatedRecTestStep?.id ||
            item?.recTestScenarioRecTestStepAssocId === action.updatedRecTestStep?.recTestScenarioRecTestStepAssocId
        ).length === 0
      )
        unSavedRecTestStepPath.push({
          isTestBlock: action.updatedRecTestStep?.isTestBlock,
          key: action.updatedRecTestStep?.recTestScenarioRecTestStepAssocId
            ? action.updatedRecTestStep?.recTestScenarioRecTestStepAssocId
            : action.updatedRecTestStep?.tag,
          label: action.updatedRecTestStep && action.updatedRecTestStep["recTestStepProperty"]["stepName"],
          recTestScenarioRecTestStepAssocId: action.updatedRecTestStep?.recTestScenarioRecTestStepAssocId,
          recTestStepId: action.updatedRecTestStep?.id,
          siblingSteps: [],
        });
      return {
        ...state,
        unSavedRecTestStepPath,
        selectedRecTestStepDetail: action.updatedRecTestStep,
      };

    case MULTI_COLUMN_UPDATE_PREVIEW_STATUS:
      let updateParentStepPreviewStatus = [...state.parentRecTestSteps];
      let updateChildStepPreviewStatus = { ...state.childRecTestStepData };
      let updateChildTestBlockStepPreviewStatus = { ...state.childTestBlockSteps };
      if (action.response.data.length > 0 && action.response.status === SUCCESS_STATUS) {
        var executedStep = action.response.data.filter((item) => item.stepStatus && item.stepStatus !== "null");
        for (let x in updateChildStepPreviewStatus) {
          updateChildStepPreviewStatus[x].forEach((element) => {
            const stepStatus = executedStep?.find((i) => i.stepTagId === element.tag)?.stepStatus;
            if (stepStatus) {
              element.previewStatus = stepStatus;
            }
          });
        }
        for (let x in updateChildTestBlockStepPreviewStatus) {
          updateChildTestBlockStepPreviewStatus[x].forEach((element) => {
            const stepStatus = executedStep?.find((i) => i.stepTagId === element.tag)?.stepStatus;
            if (stepStatus) {
              element.previewStatus = stepStatus;
            }
          });
        }
        updateParentStepPreviewStatus = state.parentRecTestSteps.map((data) => {
          let stepPreviewStatus = executedStep?.find((i) => i.stepTagId === data.tag)?.stepStatus;
          return {
            ...data,
            previewStatus: stepPreviewStatus,
          };
        });
      }
      return {
        ...state,
        parentRecTestSteps: updateParentStepPreviewStatus,
        childRecTestStepData: updateChildStepPreviewStatus,
        childTestBlockSteps: updateChildTestBlockStepPreviewStatus,
      };
    case CLEAR_PREVIEW_IN_MULTI_COLUMN_VIEW:
      let parentStepPreviewStatus = state.parentRecTestSteps ? [...state.parentRecTestSteps] : [];
      let childStepPreviewStatus = state.childRecTestStepData ? { ...state.childRecTestStepData } : {};
      let childTestBlockStepPreviewStatus = state.childTestBlockSteps ? { ...state.childTestBlockSteps } : {};
      for (let x in childStepPreviewStatus) {
        childStepPreviewStatus[x].forEach((element) => {
          if (element.previewStatus) delete element.previewStatus;
        });
      }
      for (let x in childTestBlockStepPreviewStatus) {
        childTestBlockStepPreviewStatus[x].forEach((element) => {
          if (element.previewStatus) delete element.previewStatus;
        });
      }
      if (state.parentRecTestSteps) {
        parentStepPreviewStatus = state.parentRecTestSteps.map((data) => {
          if (data.previewStatus) {
            delete data.previewStatus;
          }
          return data;
        });
      }

      return {
        ...state,
        parentRecTestSteps: parentStepPreviewStatus,
        childRecTestStepData: childStepPreviewStatus,
        childTestBlockSteps: childTestBlockStepPreviewStatus,
      };
    case DELETE_MULTI_COLUMN_VIEW_STEP:
      // Remove Step in OverList view
      if (action.stepType === TEST_SCENARIOS) {
        if (state.selectedRecTestStepDetail?.parentRecTestScenarioRecTestStepsAssoc) {
          parentId = state.selectedRecTestStepDetail?.parentRecTestScenarioRecTestStepsAssoc.id;
        } else if (state.selectedRecTestStepDetail?.parentId) {
          parentId = state.selectedRecTestStepDetail?.parentId;
        }
      } else if (action.stepType === TEST_STEP_BLOCKS) {
        if (state.selectedRecTestStepDetail?.parentRecTestStep) {
          parentId = state.selectedRecTestStepDetail?.parentRecTestStep?.id;
        } else if (
          state.selectedRecTestStepDetail?.parentId &&
          state.selectedRecTestStepDetail?.parentId !== state.selectedRecTestStepDetail?.testBlockRecTestStepId
        ) {
          parentId = state.selectedRecTestStepDetail?.parentId;
        }
      }
      if (state.selectedRecTestStepDetail?.parentRecTestStep || parentId) {
        if (state.childRecTestStepData[parentId]) {
          childRecTestSteps = [...state.childRecTestStepData[parentId]];
          childRecTestSteps = childRecTestSteps.filter(
            (childRecTestStep) => childRecTestStep.tag !== action.selectedRecTestStepTagForDelete
          );
          state.childRecTestStepData[parentId] = childRecTestSteps;
          const childRecTestStepsValues = state.childRecTestStepData && Object.values(state.childRecTestStepData);
          state.childRecTestSteps =
            childRecTestStepsValues && childRecTestStepsValues.reduce((r, e) => (r.push(...e), r), []);
        }
        if (state.childTestBlockSteps[parentId]) {
          let childTestBlockSteps = [...state.childTestBlockSteps[parentId]];
          childTestBlockSteps = childTestBlockSteps.filter(
            (childRecTestStep) => childRecTestStep.tag !== action.selectedRecTestStepTagForDelete
          );
          state.childTestBlockSteps[parentId] = childTestBlockSteps;
        }
      } else {
        state.parentRecTestSteps = state.parentRecTestSteps.filter(
          (recTestStep) => recTestStep.tag !== action.selectedRecTestStepTagForDelete
        );
      }
      return {
        ...state,
        selectedRecTestStepDetail: undefined,
        unSavedRecTestStepPath: [],
        loadStepConditionalStep: false,
      };

    case CLEAR_PREVIEW_FOR_MULTI_COLUMN_VIEW:
      let clearParentStepPreviewStatus = [...state.parentRecTestSteps];
      let clearChildStepPreviewStatus = { ...state.childRecTestStepData };
      let clearChildTestBlockStepPreviewStatus = { ...state.childTestBlockSteps };
      clearParentStepPreviewStatus = state.parentRecTestSteps.map((data) => {
        return {
          ...data,
          previewStatus: undefined,
        };
      });
      removePreviewStatus(state.childRecTestStepData);
      removePreviewStatus(state.childTestBlockSteps);
      return {
        ...state,
        parentRecTestSteps: clearParentStepPreviewStatus,
        childRecTestStepData: clearChildStepPreviewStatus,
        childTestBlockSteps: clearChildTestBlockStepPreviewStatus,
      };

    default:
      return state;
  }
}

export const removePreviewStatus = (childData) => {
  for (let x in childData) {
    childData[x].forEach((element) => {
      element.previewStatus = undefined;
    });
  }
};
export const allChildDisabled = (recTestSteps, checked, disabledChildStep) => {
  let childData = disabledChildStep.filter((i) =>
    i?.isTestBlock === 0
      ? i.parentRecTestScenarioRecTestStepsAssocId === recTestSteps?.recTestScenarioRecTestStepAssocId
      : i?.parentRecTestStepId === recTestSteps?.recTestStepId || i?.parentRecTestStepId === recTestSteps?.id
  );
  if (!_.isEmpty(childData)) {
    childData.map((obj) => {
      obj.isSkipStep = checked;
      allChildDisabled(obj, checked, disabledChildStep);
      return obj;
    });
  }
};

function prepareRecTestStepObjectWithBasicDetails(recTestStep) {
  return {
    orderId: recTestStep.orderId,
    tag: recTestStep.tag,
    stepName: recTestStep.recTestStepProperty?.stepName,
    recTestStepPropertyId: recTestStep.recTestStepProperty?.id,
    isTestBlock: recTestStep.isTestBlock,
    preConditionTitle: recTestStep.recTestStepProperty?.preConditionTitle,
    isCaptureScreenshot: recTestStep.recTestStepProperty?.isCaptureScreenshot,
    action: recTestStep.recTestStepProperty?.action,
    isStepHasPrecondition: recTestStep.recTestStepProperty?.isStepHasPrecondition,
    recTestScenarioRecTestStepAssocId: recTestStep.recTestScenarioRecTestStepAssocId,
    isSkipStep: recTestStep.recTestStepProperty?.isSkipStep,
    recTestStepId: recTestStep.id,
    isLoopStep: recTestStep.recTestStepProperty?.isLoopStep,
  };
}

export function getParentRecTestStep(parentRecTestSteps, childRecTestStepData, recTestStepTag) {
  let fetchedParentRecTestStepId = undefined;
  Object.keys(childRecTestStepData).forEach((parentRecTestStepId) => {
    if (
      childRecTestStepData[parentRecTestStepId].filter((childRecTestStep) => childRecTestStep.tag === recTestStepTag)
        .length > 0
    ) {
      fetchedParentRecTestStepId = parentRecTestStepId;
    }
  });
  let fetchParentRecTestStep = fetchedParentRecTestStepId
    ? parentRecTestSteps.filter((parentRecTestStep) => parentRecTestStep.id === fetchedParentRecTestStepId)
    : [];
  return fetchParentRecTestStep.length > 0 ? fetchParentRecTestStep[0] : undefined;
}
