import React from "react";
import { useDispatch } from "react-redux";
import SvgLoader from "../../../../../Util/SvgLoader";
import { REMOVE_ICON } from "../../../../../Constants/SvgConstants";
import { removeVerificationRow } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";

const RemoveVerificationRowV2 = (props) => {
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <SvgLoader
        iconName={REMOVE_ICON}
        handleClick={() =>
          !props.disabled
            ? dispatch(removeVerificationRow(props.parentId, props.recTestStep.tag, props.recElement?.guid))
            : undefined
        }
        width={"2rem"}
        height={"2rem"}
      />
    </React.Fragment>
  );
};

export default RemoveVerificationRowV2;
