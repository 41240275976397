import React from "react";
import { useDispatch } from "react-redux";
import { Col, Divider, Dropdown, Menu, Row, Space } from "antd";
import { PROJECTCHECK_ICON, PROJECT_ICON } from "../../../CdnImagePath";
import { CLOSE_DEVICE, DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, RESET_STATE } from "../../../Constants";
import { PROJECT_DOWN_ARROW } from "../../../Constants/SvgConstants";
import { handleSelectProjectName, showPlatformTag } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { previousProjectId } from "../../../actions/ProjectsAction";
import styles from "./ProjectBreadcrumbDetails.module.scss";
import { resetDeviceFarmList, resetDeviceList } from "../../../actions/ServerListAction";

const ProjectBreadcrumbsDetails = (props) => {
  const dispatch = useDispatch();
  const {
    getProjectByOrgId,
    setSelectProjectName,
    organizationId,
    projectDetails,
    disableOptions,
    currentMenuOptionsSelected,
    runNumber,
    projectId,
  } = props;

  const menu = (
    <Menu className="projects-menu">
      <Menu.Item className={styles.submenu_Title} key="projects-title">
        <span className={styles.projectTitle}>Projects</span>
      </Menu.Item>
      {getProjectByOrgId &&
        getProjectByOrgId.map((s, k) => {
          return (
            <React.Fragment key={s.id}>
              <Menu.Item
                key={`menu-item-${s.id}`}
                value={s.id}
                onClick={() => {
                  if (s?.id !== parseInt(projectId)) {
                    dispatch({ type: RESET_STATE });
                    dispatch({ type: CLOSE_DEVICE, deviceClose: true });
                    dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
                    handleSelectProjectName(
                      s.id,
                      organizationId,
                      setSelectProjectName,
                      currentMenuOptionsSelected,
                      runNumber
                    );
                    dispatch(previousProjectId(parseInt(projectId)));
                    dispatch(resetDeviceFarmList());
                    dispatch(resetDeviceList());
                  }
                }}
              >
                <Row
                  type="flex"
                  justify="space-between"
                  align="start"
                  className={parseInt(projectId) === s?.id && styles.active_Project}
                >
                  <Col>
                    <Space align="start">
                      <img src={PROJECT_ICON} alt="Project Icon" />
                      <div>
                        <p className={styles.project_List_Name}>{s.name}</p>
                        {showPlatformTag(s?.platform)}
                      </div>
                    </Space>
                  </Col>
                  <Col>
                    {parseInt(projectId) === s?.id && (
                      <img src={PROJECTCHECK_ICON} alt="Project Icon" className={styles.projectCheckIcon} />
                    )}
                  </Col>
                </Row>
              </Menu.Item>
              <Divider key={`divider-${s.id}`} className={styles.projectDivider} />
            </React.Fragment>
          );
        })}
    </Menu>
  );
  return (
    <div className={styles.projectBreadcrumbs}>
      <Dropdown overlay={menu} disabled={disableOptions} trigger={["click"]} placement="bottomLeft">
        <div>
          <span className={styles.selectedProject}>
            <span className={styles.project_select_title}>{projectDetails && projectDetails?.projectName}</span>{" "}
            <SvgLoader iconName={PROJECT_DOWN_ARROW} />
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default ProjectBreadcrumbsDetails;
