import { Col, Radio, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeServerManagerTab } from "../../actions/ServerAction";
import {
  ALL_SERVER,
  CHANGE_REGISTER_SERVER_DATA,
  PREVIOUS_REGISTER_SERVER_DATA,
  REGISTER_SERVER,
} from "../../Constants";
import HeaderComponent from '../Header/Header';
import { BuildRegisterServerModal } from "./BuildRegisterServerModal";
import AllServerList from "./ServerManagerTab/AllServerList";
import RegisterServer from "./ServerManagerTab/RegisterServer";

const ServerManager = (props) => {
  const orgId = props.match.params && props.match.params.organizationId;
  const dispatch = useDispatch();
  const { selectedServerManagerTab } = useSelector((state) => state.ServerReducer);

  useEffect(() => {
    dispatch(changeServerManagerTab(ALL_SERVER));
  }, [dispatch, orgId]);

  useEffect(() => {
    if (selectedServerManagerTab !== REGISTER_SERVER) {
      dispatch({
        type: CHANGE_REGISTER_SERVER_DATA,
        data: new BuildRegisterServerModal(),
      });
      dispatch({
        type: PREVIOUS_REGISTER_SERVER_DATA,
        data: new BuildRegisterServerModal(),
      });
    }
  }, [dispatch, selectedServerManagerTab]);

  return (
    <>
      <HeaderComponent showHumbergerMenu={true} organizationId={orgId} />
      <Radio.Group
        value={selectedServerManagerTab}
        onChange={(e) => dispatch(changeServerManagerTab(e.target.value))}
        buttonStyle="solid"
        className="m-5"
      >
        <Radio.Button value={ALL_SERVER}>{ALL_SERVER}</Radio.Button>
        <Radio.Button value={REGISTER_SERVER}>{REGISTER_SERVER}</Radio.Button>
      </Radio.Group>
      {selectedServerManagerTab === ALL_SERVER && <AllServerList orgId={orgId} />}
      {selectedServerManagerTab === REGISTER_SERVER && <RegisterServer orgId={orgId} />}
    </>
  );
};

export default ServerManager;
