import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { RESET_DEVICE_SCREEN_SOURCE, STEP, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  ICON_DUPLICATE,
  ICON_PERFORM_AND_SAVE,
  ICON_RESET,
  ICON_SAVE,
  STEP_DELETE_ICON,
} from "../../../../Constants/SvgConstants";
import { DUPLICATE } from "../../../../Constants/TooltipConstants";
import {
  isCurrentRecTestStepDisable,
  selectedRecTestStepPath,
  showActionStep,
  showSubStep
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { resetAllRecScenarioStepIds, selectAllRecScenarioStepIds } from "../../../../actions/RecTestScenarioAction";
import {
  deleteRecTestStep,
  duplicateRecTestStep
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import {
  findRecTestStepByTag
} from "../../../../reducer/RecTestStepReducer";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import PerformRecTestStepActionV2 from "../../StepDetailsV2/PerformRecTestStepActionV2/PerformRecTestStepActionV2";
import styles from "./RecTestStepListHeaderV2.module.scss";
import ConditionalRecTestStepV2 from "./StepActionsComponents/ConditionalRecTestStepV2";
import RecTestStepAddActionsV2 from "./StepActionsComponents/RecTestStepAddActionsV2";
import RecTestStepOperationV2 from "./StepActionsComponents/RecTestStepOperationV2";
import RecTestStepPlayActionsV2 from "./StepActionsComponents/RecTestStepPlayActionsV2";
import RecTestStepSubStepsV2 from "./StepActionsComponents/RecTestStepSubStepsV2";

const RecTestStepListHeaderV2 = (props) => {
  const dispatch = useDispatch();

  const { stepType, recTestStepList, MultiViewDesign, platform, testBlockStep, projectId } = props;

  const { selectedRectTestScenarioStepId, isPreviewRunning, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { requestingPerformRecTestStepAction } = useSelector((state) => state.RecTestScenarioReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [generalActionMenuVisible, setGeneralActionMenuVisible] = useState(false);
  const [conditionMenuVisible, setConditionMenuVisible] = useState(false);
  const [openDeleteStepsModal, setOpenDeleteStepsModal] = useState(false);

  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);

  //destructure of unSavedRecTestStep and selectedRecStepForOperation object
  const {
    isStepHasPrecondition: preConditionOfUnsavedStep,
    isLoopStep: loopStepOfUnsavedStep,
    recElements: recElementsOfUnsavedStep,
  } = unSavedRecTestStep && unSavedRecTestStep.recTestStepProperty ? unSavedRecTestStep.recTestStepProperty : {};

  const {
    isStepHasPrecondition: preConditionOfSelectedStep,
    isLoopStep: loopStepOfSelectedStep,
    action: actionOfSelectedStep,
  } = selectedRecStepForOperation && selectedRecStepForOperation?.recTestStepProperty
    ? selectedRecStepForOperation?.recTestStepProperty
    : {};

  const {
    isTestBlock: testBlockOfSelectedStep,
    recTestScenarioRecTestStepAssocId: recTestStepAssocIdOFSelectedStep,
    recTestSteps: recTestStepsOfSelectedStep,
    id: idOfSelectedStep,
  } = selectedRecStepForOperation ? selectedRecStepForOperation : {};

  const isTestBlockInsideScenario = useMemo(() => {
    return stepType === TEST_SCENARIOS && testBlockOfSelectedStep === 1;
  }, [stepType, testBlockOfSelectedStep]);

  const deleteDisabled = useMemo(() => {
    return (
      isCurrentRecTestStepDisable(unSavedRecStepTag, selectedRecStepForOperation, requestingPerformRecTestStepAction) ||
      isPreviewRunning ||
      (isTestBlockInsideScenario && recTestStepAssocIdOFSelectedStep === undefined)
    );
  }, [
    unSavedRecStepTag,
    selectedRecStepForOperation,
    requestingPerformRecTestStepAction,
    isPreviewRunning,
    isTestBlockInsideScenario,
    recTestStepAssocIdOFSelectedStep,
  ]);

  const disabled = useMemo(() => {
    return unSavedRecStepTag !== undefined || deleteDisabled;
  }, [unSavedRecStepTag, deleteDisabled]);

  const subActionStep = useMemo(() => {
    if (!recTestStepsOfSelectedStep || !recTestStepList) {
      return false;
    }
    return (
      recTestStepsOfSelectedStep.some((i) => i?.recTestStepProperty?.action !== STEP) &&
      isEmpty(selectedRectTestScenarioStepId)
    );
  }, [recTestStepsOfSelectedStep, selectedRectTestScenarioStepId, recTestStepList]);

  const actionStep = useMemo(() => {
    return (
      showActionStep(selectedRecStepForOperation, subActionStep) &&
      isEmpty(selectedRectTestScenarioStepId) &&
      !isEmpty(recTestStepList)
    );
  }, [selectedRecStepForOperation, selectedRectTestScenarioStepId, subActionStep, recTestStepList]);

  const subStep = useMemo(() => {
    if (!selectedRecStepForOperation || !recTestStepList) {
      return false;
    }
    return showSubStep(selectedRecStepForOperation, actionStep) && isEmpty(selectedRectTestScenarioStepId);
  }, [selectedRecStepForOperation, selectedRectTestScenarioStepId, actionStep, recTestStepList]);

  const selectedRecTestStepListPath = useMemo(() => {
    return selectedRecTestStepPath(recTestStepList, selectedRecStepForOperation);
  }, [recTestStepList, selectedRecStepForOperation]);

  const isPreConditionStep = useMemo(() => {
    const preConditionStepValue =
      preConditionOfUnsavedStep === 1 || (loopStepOfUnsavedStep === 1 && recElementsOfUnsavedStep?.length > 0);
    return preConditionStepValue;
  }, [preConditionOfUnsavedStep, loopStepOfUnsavedStep, recElementsOfUnsavedStep]);

  const isForEmptyStep = useMemo(() => {
    return (
      recTestStepsOfSelectedStep?.length === 0 &&
      isEmpty(selectedRectTestScenarioStepId) &&
      actionOfSelectedStep === STEP
    );
  }, [recTestStepsOfSelectedStep, selectedRectTestScenarioStepId, actionOfSelectedStep]);

  const isSubHeaderActions = useMemo(() => subActionStep || isForEmptyStep, [subActionStep, isForEmptyStep]);
  const isSubHeaderSteps = useMemo(() => subStep && !subActionStep, [subStep, subActionStep]);
  const isSubHeaderPlayActions = useMemo(
    () => subStep || actionStep || subActionStep,
    [subStep, actionStep, subActionStep]
  );
  const isConditionalStep = useMemo(
    () => preConditionOfSelectedStep === 0 && loopStepOfSelectedStep === 0,
    [preConditionOfSelectedStep, loopStepOfSelectedStep]
  );

  const isCheckBoxChecked = useMemo(() => {
    if (isEmpty(selectedRectTestScenarioStepId)) {
      return false;
    }
    return recTestStepList
      ?.filter((j) => j?.isSkipStep === 0)
      ?.every((i) =>
        selectedRectTestScenarioStepId?.includes(i?.recTestStepId || i.recTestScenarioRecTestStepAssocId || i?.id)
      );
  }, [selectedRectTestScenarioStepId, recTestStepList]);

  const allSelectSteps = useCallback(
    (e) => {
      if (e.target.checked) {
        dispatch(selectAllRecScenarioStepIds("", MultiViewDesign, recTestStepList));
      } else {
        dispatch(resetAllRecScenarioStepIds());
        dispatch(selectRecTestSteps(recTestStepList[0]));
      }
    },
    [dispatch, MultiViewDesign, recTestStepList]
  );

  return (
    <>
      {(!isEmpty(recTestStepList) || (testBlockStep && isPreConditionStep)) && (
        <div className={styles["header"]}>
          <div className={styles["selectRecTestStepCheckBoxDiv"]}>
            <Checkbox
              checked={isCheckBoxChecked}
              onChange={(e) => allSelectSteps(e)}
              className={styles["selectRecTestStepCheckBox"]}
            />
          </div>
          <div
            className={`${styles["actionIconAndDeleteIcon"]} ${
              !isEmpty(selectedRectTestScenarioStepId) && `${styles["selectedStepIsNotEmpty"]}`
            }`}
          >
            <div
              className={`${styles["actionIconsInHeader"]} ${
                !isEmpty(selectedRectTestScenarioStepId) && `${styles["visibleDeleteIcon"]}`
              }`}
            >
              {!isEmpty(selectedRecTestStepListPath) && (
                <>
                  <div className={styles["selectedRecTestStepOrderId"]}>
                    {selectedRecTestStepListPath[selectedRecTestStepListPath.length - 1]}
                  </div>
                  <VerticalLine className={styles["borderRight"]} />
                </>
              )}

              <div className={styles["selectedRecTestSteps"]}>
                {!isEmpty(selectedRectTestScenarioStepId) && ` ${selectedRectTestScenarioStepId.length} Selected`}
              </div>

              <div className={styles["centerMenu"]}>
                {(actionStep || isPreConditionStep) && (
                  <>
                    <PerformRecTestStepActionV2
                      action={actionOfSelectedStep}
                      data={selectedRecStepForOperation}
                      recTestStepId={idOfSelectedStep}
                      recTestStepTag={unSavedRecStepTag}
                      projectId={projectId}
                      resetImage={ICON_RESET}
                      saveImage={ICON_SAVE}
                      SaveAndPerformImage={ICON_PERFORM_AND_SAVE}
                    />
                    <VerticalLine className={styles["borderRight"]} />
                  </>
                )}

                {isSubHeaderActions && (
                  <>
                    <RecTestStepAddActionsV2
                      disabled={disabled || isTestBlockInsideScenario}
                      projectId={projectId}
                      actionStep={actionStep}
                      platform={platform}
                      recTestStep={selectedRecStepForOperation}
                      actionMenuVisible={actionMenuVisible}
                      setActionMenuVisible={setActionMenuVisible}
                      generalActionMenuVisible={generalActionMenuVisible}
                      setGeneralActionMenuVisible={setGeneralActionMenuVisible}
                    />
                    <VerticalLine className={styles["borderRight"]} />
                  </>
                )}

                {isSubHeaderSteps && (
                  <RecTestStepSubStepsV2
                    disabled={disabled || isTestBlockInsideScenario}
                    projectId={projectId}
                    stepType={stepType}
                    recTestStep={selectedRecStepForOperation}
                  />
                )}
                {subStep && isConditionalStep && (
                  <ConditionalRecTestStepV2
                    disabled={disabled || isTestBlockInsideScenario}
                    conditionMenuVisible={conditionMenuVisible}
                    setConditionMenuVisible={setConditionMenuVisible}
                  />
                )}
                {subStep && (isSubHeaderSteps || isConditionalStep) && (
                  <VerticalLine className={styles["borderRight"]} />
                )}
                {isSubHeaderPlayActions && (
                  <>
                    <RecTestStepPlayActionsV2
                      disabled={disabled}
                      projectId={projectId}
                      recTestStep={selectedRecStepForOperation}
                    />
                  </>
                )}
              </div>

              {isSubHeaderPlayActions && <VerticalLine className={styles["borderRight"]} />}
              {isSubHeaderPlayActions && (
                <div className={styles["padding_stepOperation"]}>
                  <RecTestStepOperationV2
                    name="Duplicate"
                    disabled={disabled || isTestBlockInsideScenario}
                    tooltipTitle={DUPLICATE}
                    imageName={ICON_DUPLICATE}
                    handleClick={() =>
                      !disabled && !isTestBlockInsideScenario
                        ? dispatch(duplicateRecTestStep(selectedRecStepForOperation))
                        : undefined
                    }
                    hideTooltipTitle
                  />
                </div>
              )}
            </div>
            {!isEmpty(selectedRectTestScenarioStepId) && (
              <div className={styles["deleteInHeader"]}>
                <Tooltip color={TOOLTIP_BG_COLOR} title="Delete">
                  <SvgLoader
                    iconName={STEP_DELETE_ICON}
                    iconColor="#FF188F"
                    handleClick={() => {
                      setOpenDeleteStepsModal(true)
                    }}
                  />
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
          dispatch(deleteRecTestStep());
          setOpenDeleteStepsModal(false);
        }}
        handleCancel={() => setOpenDeleteStepsModal( false)}
        modalOpen={openDeleteStepsModal}
        modalTitle={"Confirm Deletion"}
        modalContent={`Are you sure you want to delete ${
          selectedRectTestScenarioStepId && selectedRectTestScenarioStepId.length > 1 ? "these items?" : "this item?"
        } `}
        okText={"Delete"}
        isDeleteModal={true}
      />
    </>
  );
};

export default RecTestStepListHeaderV2;
