import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row, Space } from "antd";
import isEmpty from "lodash/isEmpty";
import { ALL_TEST_PLANS, CONFIRM_MODAL_CONTENT } from "../../../../../Constants";
import { ICON_CLOSE } from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmpty, isSameValue } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { saveTestPlan } from "../../../../../actions/TestDataSetAction";
import { changeTestPlanTab, editTestPlan, getTestPlanByTestPlanId } from "../../../../../actions/TestPlanAction";
import CommonConfirmationModalV2 from "../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import SaveResetButtonV2 from "../../../../CoreComponents/SaveResetButtonV2/SaveResetButtonV2";
import BuildNewTestPlanV2 from "./BuildNewTestPlanV2";
import styles from "./BuildTestPlanV2.module.scss";

const initialTestPlan = {
  testPlanName: "",
  testScenario: [],
  testPlanList: [],
  isPrivate: 1,
};

const BuildTestPlanV2 = ({ projectId }) => {
  const dispatch = useDispatch();
  const { testPlanDetails, getTestPlanById } = useSelector((state) => state.TestPlanReducer);

  const [searchScenario, setSearchScenario] = useState("");
  const [saveTestPlanData, setSaveTestPlanData] = useState(initialTestPlan);
  const [testPlanData, setTestPlanData] = useState([[], []]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (testPlanDetails && testPlanDetails !== undefined) {
      setSaveTestPlanData({
        ...saveTestPlanData,
        testPlanName: testPlanDetails ? testPlanDetails?.name : "",
        isPrivate: 1,
      });
    }
  }, [testPlanDetails]);

  useEffect(() => {
    isEmpty(saveTestPlanData?.testPlanList);
  }, [saveTestPlanData]);

  const resetTestPlanData = useCallback(() => {
    dispatch(getTestPlanByTestPlanId((testPlanDetails && testPlanDetails?.id) || 0));
    if (isNotNullAndNotEmptyAndNotUndefined(testPlanDetails)) {
      setSaveTestPlanData({ ...saveTestPlanData, testPlanName: testPlanDetails ? testPlanDetails?.name : "" });
    } else {
      setSaveTestPlanData(initialTestPlan);
    }
    dispatch(editTestPlan(false));
  }, [dispatch, getTestPlanByTestPlanId, testPlanDetails, setSaveTestPlanData, initialTestPlan]);

  const areTestPlanIdsDifferent = useCallback(() => {
    return getTestPlanById?.map((item) => item.id).join(",") !== testPlanData[1]?.map((item) => item.id).join(",");
  }, [getTestPlanById, testPlanData]);

  const disableSaveButton = () => {
    if (isEmpty(testPlanData[1])) {
      return true;
    }
    if (
      getTestPlanById &&
      getTestPlanById.length > 0 &&
      testPlanData.length > 1 &&
      Array.prototype.map
        .call(getTestPlanById, function (item) {
          return item.id;
        })
        .join(",") !==
        Array.prototype.map
          .call(testPlanData[1], function (item) {
            return item.id;
          })
          .join(",")
    ) {
      return false;
    }
    if (isNotNullAndNotEmptyAndNotUndefined(testPlanDetails)) {
      return (
        (isSameValue(testPlanDetails?.name, saveTestPlanData?.testPlanName) &&
          testPlanDetails?.testPlanCountDetails?.totalScenario === testPlanData[1]?.length) ||
        isEmpty(testPlanData[1]) ||
        isNullOrUndefinedOrEmpty(saveTestPlanData.testPlanName)
      );
    } else {
      return (
        isNullOrUndefinedOrEmpty(saveTestPlanData.testPlanName) ||
        saveTestPlanData.testPlanName.trim() === "" ||
        isEmpty(testPlanData[1])
      );
    }
  };
  const disableResetButton = useCallback(() => {
    if (getTestPlanById && testPlanData?.length > 1 && areTestPlanIdsDifferent()) {
      return false;
    }

    if (isNotNullAndNotEmptyAndNotUndefined(testPlanDetails)) {
      return (
        isSameValue(testPlanDetails?.name, saveTestPlanData?.testPlanName) &&
        testPlanDetails?.testPlanCountDetails?.totalScenario === testPlanData[1]?.length
      );
    } else {
      return isNullOrUndefinedOrEmpty(saveTestPlanData?.testPlanName) && isEmpty(testPlanData[1]);
    }
  }, [getTestPlanById, testPlanData, testPlanDetails, saveTestPlanData]);

  useEffect(() => {
    if (disableResetButton()) {
      dispatch(editTestPlan(false));
    } else {
      dispatch(editTestPlan(true));
    }
  }, [disableResetButton, dispatch]);

  const onBackClick = useCallback(() => {
    if (disableResetButton()) {
      dispatch(changeTestPlanTab(ALL_TEST_PLANS));
    } else {
      setOpenConfirmModal(true);
    }
  }, [disableResetButton, dispatch]);

  return (
    <>
      <Row justify="space-between" align="middle" className={styles["main-header"]}>
        <Col>
          <div className={styles["main-Header-Text"]}>Build Test Plans</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButtonV2
              submitLabel={"Save"}
              resetLabel={"Reset"}
              submitDisable={disableSaveButton()}
              resetDisable={disableResetButton()}
              handleSubmit={() =>
                dispatch(saveTestPlan(saveTestPlanData, testPlanDetails && testPlanDetails.id, projectId))
              }
              handleReset={resetTestPlanData}
            />
            <div className={styles["close_Icon"]}>
              <SvgLoader iconName={ICON_CLOSE} width={36} height={36} handleClick={onBackClick} />
            </div>
          </Space>
        </Col>
      </Row>
      <BuildNewTestPlanV2
        projectId={projectId}
        setSaveTestPlan={setSaveTestPlanData}
        saveTestPlan={saveTestPlanData}
        testPlanData={testPlanData}
        setTestPlanData={setTestPlanData}
        searchScenario={searchScenario}
        setSearchScenario={setSearchScenario}
      />
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeTestPlanTab(ALL_TEST_PLANS));
          dispatch(editTestPlan(false));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default BuildTestPlanV2;
