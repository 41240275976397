import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { hasNotAllowedNegativeValue } from "../../../../../Util";
import { changePauseTime } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./QuickActionsV2.module.scss";

const PauseV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, isInputDisabled, data } = props;
  
  //Destructure object
  const { recTestStepProperty, tag } = data ? data : {};
  const disabledInput = useMemo(() => isForView || isInputDisabled, [isForView, isInputDisabled]);

  return (
    <div className={styles["actions"]}>
      <span className={styles["actions_Label"]}>Delay Execution for</span>
      <div className={`${styles["input_And_Label"]} ${disabledInput && styles["disable_Input_And_Label"]}`}>
        <Input
          type="number"
          min={0}
          disabled={disabledInput}
          placeholder="Enter Pause Time In Milliseconds"
          value={recTestStepProperty?.pauseTime}
          onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
          onChange={(e) =>
            !isForView && !isInputDisabled ? dispatch(changePauseTime(e.target.value, tag)) : undefined
          }
          className={`${disabledInput ? styles["disabled_Input_Box"] : styles["input_Box"]}`}
        />
        <span className={styles["input_Label"]}>msecs</span>
      </div>
    </div>
  );
};

export default PauseV2;
