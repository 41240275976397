import React, { useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input, Select, Space, Tooltip } from "antd";
import Highlighter from "react-highlight-words";
import { useSelector } from "react-redux";
import { DELETE_ICON } from "../../../../CdnImagePath";
import { EXECUTION_JOB, REMOVE_FOR, TOOLTIP_BG_COLOR } from "../../../../Constants";
import TableComponent from "../../TableComponent/TableComponent";
import styles from "./PermissionDrawerV2.module.scss";

const { Option } = Select;

const RemoveForMemberListV2 = (props) => {
  const {
    removeFor,
    setRemoveForOption,
    removeForOption,
    notShowExecutionCheck,
    ownerEmailId,
    setRemoveFor,
    applyFor,
    setApplyFor,
    setRemoveIsAddNew,
    isRemoveAddNew,
  } = props;

  const [searchRemoveMember, setSearchRemoveMember] = useState("");
  const [searchedRemoveMemberColumn, setSearchedRemoveMemberColumn] = useState("");
  const searchInput = useRef(null);

  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { memberList } = useSelector((state) => state.CommonReducer);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const isRemoveDisable = (emailId, isView) =>
    emailId === ownerEmailId || isView || loggedInUserDetails?.userEmail === emailId;

  const addForRemove = () => {
    setRemoveIsAddNew(true);
    const membersListFilter = memberList?.map((i) => i?.email);
    const removeMembers = membersListFilter?.filter((i) => !removeFor?.some((j) => j?.email === i));
    setRemoveForOption(removeMembers);
  };

  const handleRemoveMembersSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchRemoveMember(selectedKeys[0]);
    setSearchedRemoveMemberColumn(dataIndex);
  };

  const handleRemoveMembersReset = (clearFilters) => {
    clearFilters({
      confirm: true,
      closeDropdown: true,
    });
    setSearchRemoveMember("");
    setSearchedRemoveMemberColumn("");
  };

  const handleRemoveMemberCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleCheckObj = { ...removeFor[i], [name]: checked ? 1 : 0 };
    let handleCheckArray = removeFor?.map((i) => (i?.userId === handleCheckObj?.userId ? handleCheckObj : i));
    setRemoveFor(handleCheckArray);
  };

  const handleRemoveViewCheck = (e, i) => {
    const { name, checked } = e.target;
    let handleCheckObj = {
      ...removeFor[i],
      [name]: checked ? 1 : 0,
      isAdmin: 1,
      isEdit: 1,
      isDelete: 1,
      isExec: 1,
    };
    let handleCheckArray = removeFor?.map((i) => (i?.userId === handleCheckObj?.userId ? handleCheckObj : i));
    setRemoveFor(handleCheckArray);
  };

  const handleDeleteApplyMember = (row) => {
    const removeForMembers = removeFor?.filter((i) => i !== row);
    setRemoveFor(removeForMembers);
  };

  const getRemoveColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleRemoveMembersSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleRemoveMembersSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleRemoveMembersReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered && "#1890ff" }} />,
    onFilter: (value, record) => {
      return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select());
      }
    },
    render: (text) =>
      searchedRemoveMemberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchRemoveMember]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSelectNewRemoveMemberList = (e, i) => {
    setRemoveIsAddNew(false);
    const MemberUserId = memberList?.find((i) => i?.email === e)?.userId;
    const isChecked = MemberUserId === loggedInUserDetails?.userID || e === ownerEmailId ? 1 : 0;
    setRemoveFor([
      ...removeFor,
      {
        userId: MemberUserId,
        email: e,
        isAdmin: isChecked,
        isView: isChecked,
        isEdit: isChecked,
        isDelete: isChecked,
        isExec: isChecked,
        shouldAddOrRemove: 0,
      },
    ]);
    const validApplyMember = applyFor?.filter((i) => i?.userId !== MemberUserId);
    setApplyFor(validApplyMember);
  };

  const handleCheckBox = (row, index, name, onChangeFunc, isDisabledFunc) => (
    <Checkbox
      checked={row[name]}
      name={name}
      onChange={(e) => onChangeFunc(e, index)}
      disabled={
        name === "isView"
          ? ownerEmailId === row?.email || loggedInUserDetails?.userEmail === row?.email
          : isDisabledFunc(row)
      }
    />
  );

  const removeMembersColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      ...getRemoveColumnSearchProps("email"),
    },
    { title: "Admin", dataIndex: "admin", align: "center" },
    { title: "View", dataIndex: "view", align: "center" },
    { title: "Edit", dataIndex: "edit", align: "center" },
    { title: "Delete", dataIndex: "delete", align: "center" },
    {
      title: "Execution",
      dataIndex: "execution",
      hidden: notShowExecutionCheck,
      align: "center",
    },
    { title: "", dataIndex: "action", align: "center" },
  ]?.filter((item) => !item.hidden);

  const RemoveMemberListDetails = removeFor && [
    ...removeFor?.map((row, j) => ({
      key: row?.userId,
      email: ownerEmailId === row?.email ? row?.email + ` (Owner)` : row?.email,
      admin: handleCheckBox(row, j, "isAdmin", handleRemoveMemberCheck, (row) =>
        isRemoveDisable(row?.email, row?.isView)
      ),
      view: handleCheckBox(row, j, "isView", handleRemoveViewCheck),
      edit: handleCheckBox(row, j, "isEdit", handleRemoveMemberCheck, (row) =>
        isRemoveDisable(row?.email, row?.isView)
      ),
      delete: handleCheckBox(row, j, "isDelete", handleRemoveMemberCheck, (row) =>
        isRemoveDisable(row?.email, row?.isView)
      ),
      execution: handleCheckBox(row, j, "isExec", handleRemoveMemberCheck, (row) =>
        isRemoveDisable(row?.email, row?.isView)
      ),
      action: (
        <Tooltip title="Delete" color={TOOLTIP_BG_COLOR}>
          <img
            src={DELETE_ICON}
            alt="Delete"
            className={styles["delete-icon__drawer"]}
            onClick={() => handleDeleteApplyMember(row)}
            width={40}
            height={40}
          />
        </Tooltip>
      ),
    })),
    !!isRemoveAddNew && {
      email: (
        <div>
          <Select
            className="width-full"
            placeholder="Select Members"
            onChange={(e) => handleSelectNewRemoveMemberList(e)}
          >
            {removeForOption &&
              removeForOption?.map((i, k) => {
                return (
                  <Option value={i} key={k}>
                    {i}
                  </Option>
                );
              })}
          </Select>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className={`${styles["members-list-details"]} ${styles["apply-for-details"]}`}>
        <div className={styles["table-header-text"]}>{REMOVE_FOR}</div>
        <Button
          type="primary"
          onClick={() => addForRemove()}
          disabled={memberList?.length === removeFor?.length || !isEmpty(searchRemoveMember)}
          className={styles["permission-table-header-btn"]}
        >
          Add
        </Button>
      </div>
      <div className={styles["table-content"]}>
        <TableComponent
          columns={removeMembersColumns}
          data={RemoveMemberListDetails?.filter(Boolean)}
          isScrollable={true}
          isExecution={stepType === EXECUTION_JOB ? true : false}
        />
      </div>
    </>
  );
};

export default RemoveForMemberListV2;
