import { Button, Col, Drawer, Radio, Row, Select, Space, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import {
  closePreviewSettingDrawer,
  getListOfLocalesForApplicationStrings,
  setAppLocaleProfileForPreview,
  setTestDataSetProfileForPreview,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { fetchTestDataSetByProfileId } from "../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import {
  EXECUTION_JOB,
  TEST_PLAN,
  TEST_SCENARIOS,
  splitValue,
} from "../../../../../Constants";
import { Label } from "../../../../CoreComponents/StyledComponent/StyledComponents";
import styles from "./PreviewSettingModal.module.scss";
const PreviewSettingModal = (props) => {
  const { selectedRecTestScenarioId, testBlockStep, testPlanId } = props;
  const dispatch = useDispatch();
  const {
    previewSettingDrawerVisible,
    previewTestDataSetId,
    previewTestDataSetName,
    previewAppLocale,
    stepType,
    getListOfLocales,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testDataSetList } = useSelector((state) => state.TestDataSetReducer);

  useEffect(() => {
    if (previewSettingDrawerVisible) {
      if (stepType === EXECUTION_JOB) {
        dispatch(fetchTestDataSetByProfileId(testPlanId));
      } else if (stepType === TEST_SCENARIOS) {
        dispatch(fetchTestDataSetByProfileId(selectedRecTestScenarioId));
      } else {
        dispatch(fetchTestDataSetByProfileId(testBlockStep?.id));
      }
      dispatch(getListOfLocalesForApplicationStrings());
    }
  }, [previewSettingDrawerVisible, stepType]);
  useEffect(() => {
    if (testDataSetList && previewTestDataSetName === undefined) {
      let defaultTestDataList =
        testDataSetList &&
        testDataSetList.filter((item) => item.isDefault === 1);
      dispatch(
        setTestDataSetProfileForPreview(
          defaultTestDataList.length > 0
            ? defaultTestDataList[0]?.id
            : undefined,
          defaultTestDataList.length > 0
            ? defaultTestDataList[0]?.name
            : undefined
        )
      );
    }
  }, [testDataSetList]);

  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>Preview Settings</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                dispatch(closePreviewSettingDrawer());
              }}
            />
          </span>
        </div>
      }
      placement="right"
      closable={false}
      width={700}
      onClose={() => {
        dispatch(closePreviewSettingDrawer());
      }}
      visible={previewSettingDrawerVisible}
      bodyStyle={{ padding: 15 }}
    >
      <div>
        <div>Profile Selected: {previewTestDataSetName}</div>
        <div>App Locale Selected: {previewAppLocale}</div>
        <Tabs defaultActiveKey="TEST DATA PROFILE">
          <Tabs.TabPane tab="TEST DATA PROFILE" key={"TEST DATA PROFILE"}>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>
                  Set Test Data Profile for Run
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} className="text-align-right">
                <Button
                  onClick={() => {
                    let defaultTestDataList =
                      testDataSetList &&
                      testDataSetList.filter((item) => item.isDefault === 1);
                    dispatch(
                      setTestDataSetProfileForPreview(
                        defaultTestDataList.length > 0
                          ? defaultTestDataList[0]?.id
                          : undefined,
                        defaultTestDataList.length > 0
                          ? defaultTestDataList[0]?.name
                          : undefined
                      )
                    );
                  }}
                >
                  Reset{" "}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Radio.Group
                  onChange={(e) => {
                    dispatch(
                      setTestDataSetProfileForPreview(
                        e.target.value.split(splitValue)[0],
                        e.target.value.split(splitValue)[1]
                      )
                    );
                  }}
                  value={
                    previewTestDataSetId + splitValue + previewTestDataSetName
                  }
                >
                  <Space direction="vertical">
                    {testDataSetList && (
                      <Radio
                        value={undefined + splitValue + testDataSetList[0].name}
                      >
                        {testDataSetList[0].name}
                      </Radio>
                    )}
                    <Label>Custom Profiles</Label>
                    {testDataSetList && testDataSetList.length > 1
                      ? testDataSetList.map((value) => {
                          return (
                            value.id && (
                              <Radio value={value.id + splitValue + value.name}>
                                {value.name}
                              </Radio>
                            )
                          );
                        })
                      : "No Data"}
                  </Space>
                </Radio.Group>
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="APP LOCALE" key={"APP LOCALE"}>
            <Row>
              <Col span={24}>
                <div className={styles.headerTitle}>
                  Set Application Locale for Run
                </div>
              </Col>
              <Col span={24} className="text-align-right">
                <Button
                  onClick={() => {
                    dispatch(setAppLocaleProfileForPreview(undefined));
                  }}
                >
                  Reset{" "}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span>Set Application String Locale Value</span>
                <div>
                  <Select
                    showSearch={true}
                    className="setAppLocalValue"
                    value={previewAppLocale}
                    placeholder="Select Locale"
                    onChange={(e) => {
                      dispatch(setAppLocaleProfileForPreview(e));
                    }}
                  >
                    {getListOfLocales &&
                      getListOfLocales?.map((i) => (
                        <Select.Option value={i?.locales}>
                          {i?.locales}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              </Col>
            </Row>
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Drawer>
  );
};

export default PreviewSettingModal;
