import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { TEST_SCENARIOS } from "../../../../../Constants";
import { CREATE_ICON, DARK_GREEN, DISABLE_COLOR, EDIT_RULES_ICON_NEW } from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import { visibleIdentificationRulesDrawer } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import CustomButtonV2 from "../../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import Verifications from "../../../../RecTestStep/StepInnerComponents/Verifications/Verifications";
import styles from "./VerificationRulesV2.module.scss";
import VerificationRulesDrawerV2 from "../VerificationRulesDrawerV2/VerificationRulesDrawerV2";

const VerificationRulesV2 = (props) => {
  const dispatch = useDispatch();
  const {
    recTestSteps,
    isSkipStep,
    recElement,
    data,
    selectedDeviceByUdid,
    unSavedRecElementGuid,
    disabled,
    projectId,
  } = props;
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);

  //Destructure of object
  const { isTestBlock: testBlockOfData } = data ? data : {};
  const { recStepVerifyElementProperties: verifyElementProperties, operator: operatorOfRecTestStepProperty } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};
  const {
    recStepVerifyElementProperties: verifyElementPropertiesOfRecElement,
    verificationOperator: verificationOperatorOfRecElement,
  } = recElement ? recElement : {};

  const [recStepVerifyElementProperties, operator] = useMemo(() => {
    if (recElement) {
      return [verifyElementPropertiesOfRecElement, verificationOperatorOfRecElement];
    } else {
      return [verifyElementProperties, operatorOfRecTestStepProperty];
    }
  }, [
    recElement,
    verifyElementPropertiesOfRecElement,
    verificationOperatorOfRecElement,
    verifyElementProperties,
    operatorOfRecTestStepProperty,
  ]);

  const containsVerification = useMemo(
    () => recStepVerifyElementProperties && recStepVerifyElementProperties.length > 0,
    [recStepVerifyElementProperties]
  );
  let isTestBlock = useMemo(() => testBlockOfData === 1 && stepType === TEST_SCENARIOS, [testBlockOfData, stepType]);

  let isEditRulesButton = useMemo(
    () => isPreviewRunning || isTestBlock || disabled,
    [isPreviewRunning, isTestBlock, disabled]
  );

  const verificationRulesDetails = (verification) => {
    return (
      <>
        <div className={styles["verification-Type"]}>{verification?.type}</div>
        <div className={styles["verification-Details"]}>
          <span className={styles["verification-Key-Or-Value"]}>{verification?.key && `[${verification?.key}]`}</span>
          <span className={styles["verification-Key-Or-Value"]}>
            {verification?.value && `[${verification?.value}]`}
          </span>
          <span className={styles["verification-Key-Or-Value"]}>
            {verification?.refTestData && `[${verification?.refTestData?.name}]`}
          </span>
        </div>
      </>
    );
  };

  const renderOperators = (verifyElementProperties, index, operator) => {
    return (
      verifyElementProperties.length > 1 &&
      verifyElementProperties.length - 1 !== index && (
        <div className={styles["container-Of-Operators"]}>
          <div className={styles["border-blue"]}></div>
          <div key={"OPERATOR"} className={styles["and-Or-Operators"]}>
            {operator}
          </div>
        </div>
      )
    );
  };
  return (
    <div className={`${styles["verification-rules-details"]} ${!containsVerification && "display__flex"} `}>
      {containsVerification ? (
        <>
          <div className={styles["rules-Title-and-edit-button"]}>
            <div className={styles["identification-Rules-Title"]}>Identification Rules</div>
            <CustomButtonV2
              buttonLabel={"Edit"}
              handleClick={() => !isEditRulesButton && dispatch(visibleIdentificationRulesDrawer(true, data?.tag))}
              buttonIcon={EDIT_RULES_ICON_NEW}
              disabled={isEditRulesButton}
              iconColor={isEditRulesButton ? DISABLE_COLOR : DARK_GREEN}
              className={`common_Light_Green_Button ${styles["add_And_Edit_Text"]}`}
            />
          </div>
          <div className={styles["details-Of-Verification-Rules"]}>
            {!isEmpty(recStepVerifyElementProperties) &&
              recStepVerifyElementProperties?.map((verification, key) => {
                return (
                  <React.Fragment key={key}>
                    {verification.type !== "GROUP" && (
                      <div className={styles["identification-Rules"]}>{verificationRulesDetails(verification)}</div>
                    )}
                    {verification.type === "GROUP" && !isEmpty(verification?.childRecStepVerifyElementProperties) && (
                      <div className={styles["identification-Rules"]}>
                        {verification?.childRecStepVerifyElementProperties?.map((groupVerification, index) => {
                          return (
                            <React.Fragment>
                              {verificationRulesDetails(groupVerification)}
                              {renderOperators(
                                verification?.childRecStepVerifyElementProperties,
                                index,
                                verification?.operator
                              )}
                            </React.Fragment>
                          );
                        })}
                      </div>
                    )}
                    {renderOperators(recStepVerifyElementProperties, key, operator)}
                  </React.Fragment>
                );
              })}
          </div>
        </>
      ) : (
        <div className={styles["add-Rules"]}>
          <div className={styles["identification-Rules-Title"]}>Identification Rules</div>
          <span className={styles["add-Rules-Text"]}>
            Specify Rules on Element Properties and Element States to filter and identify a particular Element.
          </span>
          <CustomButtonV2
            buttonLabel={"Add"}
            buttonIcon={CREATE_ICON}
            disabled={disabled}
            handleClick={() => !disabled && dispatch(visibleIdentificationRulesDrawer(true, data?.tag))}
            iconColor={disabled ? DISABLE_COLOR : DARK_GREEN}
            className={`common_Light_Green_Button ${styles["add_And_Edit_Text"]}`}
          />
        </div>
      )}
      <VerificationRulesDrawerV2
        recTestSteps={recTestSteps}
        disabled={
          disabled ||
          isSkipStep ||
          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement.guid)
        }
        isElementSelected={props.disabled}
        uuid={recElement.guid}
        data={data}
        recElement={recElement}
        operatorChange={recElement.verificationOperator}
        projectId={projectId}
        isDeviceLoaded={selectedDeviceByUdid !== undefined}
        isSkipStep={isSkipStep}
        isTestBlock={isTestBlock}
        isPreviewRunning={isPreviewRunning}
        containsVerification={containsVerification}
        operator={operator}
        recStepVerifyElementProperties={recStepVerifyElementProperties}
      />
    </div>
  );
};

export default VerificationRulesV2;
