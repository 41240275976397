import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { ACTION_NOT_VIEW, BUILD_TEST_PLAN, TOOLTIP_BG_COLOR, defaultDateTimeFormat } from "../../../../Constants";
import { displayUTCDateFormatWithToolTip, getPermissionType, permissionTypeIcon } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { changeTestPlanDetails, changeTestPlanTab } from "../../../../actions/TestPlanAction";
import SelectionCheckBoxV2 from "../../../CommonComponents/SelectionCheckBoxV2/SelectionCheckBoxV2";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import TestPlanSelectionRadioTypeV2 from "./TestPlanSelectionRadioTypeV2";
import styles from "./TestPlanDetailsColumnsV2.module.scss";

const TestPlanInfoColumnsV2 = (props) => {
  const { row, testPlanTab, testJobDetail, selectAllTestPlan, setSelectAllTestPlan } = props;
  const dispatch = useDispatch();
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const handleOnClick = () => {
    dispatch(changeTestPlanDetails(row));
    dispatch(changeTestPlanTab(BUILD_TEST_PLAN));
  };

  return (
    <div
      className={`${
        !testPlanTab && row.allowDelete === ACTION_NOT_VIEW && styles["table-details"]
      }  display__flex ml-5`}
    >
      {testPlanTab ? (
        <TestPlanSelectionRadioTypeV2 row={row} testJobDetail={testJobDetail} />
      ) : (
        <SelectionCheckBoxV2 row={row} selectAllData={selectAllTestPlan} setSelectAllData={setSelectAllTestPlan} />
      )}
      <div
        className={`${row?.allowDelete === ACTION_NOT_VIEW && styles["permission_Icons_And_Name"]} ${
          styles["icon-and-details"]
        }`}
      >
        <div className={styles["permission-tooltip"]}>
          <Tooltip title={getPermissionType(row?.isPrivate, row?.tesPlanRights?.data)} color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={permissionTypeIcon(getPermissionType(row?.isPrivate, row?.tesPlanRights?.data))} />
            </div>
          </Tooltip>
        </div>
        <div className={styles["test_Plan_Info_Details"]}>
          <ShowTooltipV2 text={row?.name} className={styles["step-name"]} handleOnClick={() => handleOnClick()} />
          {loggedInUserDetails?.userEmail !== row?.createdBy && (
            <div>
              <span className={styles["label"]}>Created By: </span>{" "}
              <span className={styles["label-name"]}>{row?.createdByName || "NA"}</span>
            </div>
          )}
          <div>
            <span className={styles["label"]}>Updated By: </span>{" "}
            <span className={styles["label-name"]}>
              {row?.updatedByName || "NA"} on {displayUTCDateFormatWithToolTip(row?.modifiedOn, defaultDateTimeFormat)}
            </span>
          </div>
          <div>
            {row?.currentAndTotalTestDataProfile && (
              <div>
                <span className={styles["label"]}>Current TestData Profile & Total TestData Keys in Profile:</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestPlanInfoColumnsV2;
