import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Popover, Space, Spin } from "antd";
import { testDataSetInfoModal } from "../../../actions/TestDataSet/TestDataSetRedirectAction";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import styles from "./TestStepsData.module.scss";

const TestStepsDataInfo = (props) => {
  const dispatch = useDispatch();
  const { visibleTestDataSetInfoModal, testDataBase, selectedStepsValue, selectedStepsKey, testDataBaseListLoading } =
    useSelector((state) => state.TestDataSetReducer);

  const testStepsData = () => {
    return (
      (!_.isEmpty(testDataBase) &&
        testDataBase?.map((row, index) => ({
          index: index + 1,
          pathToTestData: (
            <Popover
              content={
                <span>
                  {row.stepOrderPath} {row.pathToTestData}
                </span>
              }
              title={"Step Path"}>
              <div className={styles.stepName}>{row.stepName}</div>
            </Popover>
          ),
          elementImages: row?.elementImages ? (
            <Popover
              content={<img src={row?.elementImages} height={450} weight={225} alt="Screenshot" />}
              placement="left"
              title="Element Image"
              trigger="hover">
              <img className={styles.elementImage} src={row?.elementImages} height={50} weight={50} alt="Screenshot" />
            </Popover>
          ) : (
            "-"
          ),
          useFor: <div>{row?.useFor}</div>,
          overrideDefault: row?.overrideDefault,
        }))) ||
      []
    );
  };

  const columns = [
    {
      title: "",
      dataIndex: "index",
    },
    {
      title: "Step",
      dataIndex: "pathToTestData",
      width: 350,
    },
    {
      title: "Element Image",
      dataIndex: "elementImages",
      align: "center",
    },
    {
      title: "Used For",
      dataIndex: "useFor",
      align: "center",
    },
    {
      title: "Value",
      dataIndex: "overrideDefault",
    },
  ];

  return (
    <Modal
      visible={visibleTestDataSetInfoModal}
      title={
        <Space>
          <div>
            Key: <span className={styles.modalTitleLabel}>{selectedStepsKey}</span>
          </div>
          <div></div>
          {selectedStepsValue && (
            <div>
              Default Value: <span className={styles.modalTitleLabel}>{selectedStepsValue}</span>
            </div>
          )}
        </Space>
      }
      width={1050}
      onCancel={() => dispatch(testDataSetInfoModal(false))}
      footer={null}>
      <Spin spinning={testDataBaseListLoading}>
        <TableComponent columns={columns} data={testStepsData()} />
      </Spin>
    </Modal>
  );
};

export default TestStepsDataInfo;
