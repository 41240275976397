import { default as React } from "react";
import { ALL_JOBS, JOB_DESIGNER} from "../../../Constants";
import AllJobs from "./AllJobs/AllJobs";
import BuildJob from "./BuildJob/BuildJob";

const JobManager = (props) => {
  const { projectId, orgId, selectedExecutionManagerSubTab, setCurrentMenuOptionsSelected,rights,setRights,showDeviceView } = props;
  return (
    <div>
      {selectedExecutionManagerSubTab === ALL_JOBS && (
        <AllJobs
          projectId={projectId}
          orgId={orgId}
          setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
          rights={rights}
          setRights={setRights}
        />
      )}
      {selectedExecutionManagerSubTab === JOB_DESIGNER && (
        <BuildJob projectId={projectId} orgId={orgId} rights={rights} showDeviceView={showDeviceView}/>
      )}
    </div>
  );
};

export default JobManager;
