import React from "react";

const PermissionFilter = ({ permissionValue, handleFilters, active }) => {
  return (
    <div className="display-flex">
      {permissionValue?.map((i) => (
        <div className={active?.id === i.id ? "activeFilter mr-5" : "filter mr-5"} onClick={() => handleFilters(i)}>
          {i.value}
        </div>
      ))}
    </div>
  );
};

export default PermissionFilter;
