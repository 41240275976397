import React from "react";
import { Popover, Space } from "antd";
import { EYE_ICON } from "../../../../../../../../CdnImagePath";
import styles from "../AssignedTestDataListViewV2.module.scss";
import { elementImage } from "../../../../../../../../Util";

const UsedForColumn = ({ testDataValue }) => {
  const { useFor, stepImage } = testDataValue;
  return (
    <Space>
      <div className={styles.tableData}>{useFor}</div>
      {elementImage(stepImage) ? (
        <Popover
          content={
            stepImage.length > 0
              ? stepImage.map((value, key) => {
                  return <img key={key} className="stepImage" src={value} height="450" weight="225" alt="Screenshot" />;
                })
              : ""
          }
          placement="left"
          title="Element Image"
          trigger="hover"
        >
          <img src={EYE_ICON} alt="Element Screenshot" />
        </Popover>
      ) : (
        ""
      )}
    </Space>
  );
};

export default UsedForColumn;
