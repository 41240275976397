import React from "react";
import { Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import {
  ACTION_ICON_COLOR,
  DARK_GRAY_COLOR,
  DEEP_BLUE_COLOR,
  SHADOW_GRAY_COLOR,
} from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./TextButtonV2.module.scss";

const TextButtonV2 = (props) => {
  const { disabled, title, className, image, icon, onClick } = props;
  
  return (
    <div
      className={`${styles["textButton"]} ${
        !image && (disabled ? `${styles["disableTextButtonText"]}` : `${styles["textButtonText"]}`)
      }  ${className}`}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {image ? (
        <Tooltip title={title} color={TOOLTIP_BG_COLOR} overlayClassName={styles["tooltipVisible"]}>
          <div className={`${styles.subHeaderIcons} ${disabled && "cursor__not_allowed"}`}>
            <SvgLoader
              iconName={image}
              iconColor={disabled ? DARK_GRAY_COLOR : ACTION_ICON_COLOR}
              disabled={disabled}
            />
            <span className={`${disabled && styles["disable_Text"]} cursor__pointer`}>{title}</span>
          </div>
        </Tooltip>
      ) : icon ? (
        <Tooltip title={title} color={TOOLTIP_BG_COLOR} overlayClassName={styles["tooltipVisible"]}>
          <div className={`${styles["actionButtons"]} ${disabled && styles["disableButton"]}`}>
            <SvgLoader iconName={icon} iconColor={disabled ? SHADOW_GRAY_COLOR : DEEP_BLUE_COLOR} disabled={disabled} />
            <div className={styles["titleText"]}>{title}</div>
          </div>
        </Tooltip>
      ) : (
        <div>{title}</div>
      )}
    </div>
  );
};

export default TextButtonV2;
