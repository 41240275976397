// import React, { Component } from 'react';
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_INTERACTION_MODE, checkVisibility, parseCoordinates } from "../../../Constants";
// import * as Actions from "../../../actions/Recording";

import { INSPECTOR_ELEMENT_IMAGE } from "../../../Constants/SvgConstants";
import {
  isNotNullAndNotEmptyAndNotUndefined,
  removeAndroidElement,
  removeChildElement,
  removeIosElement,
} from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import {
  selectElement,
  selectHoveredElement,
  toggleOtherElementPopover,
  unselectElement,
  unselectHoveredElement,
} from "../../../actions/Recording";
import { fetchNewRecTestStepAction } from "../../../reducer/RecTestStepReducer";
import OtherElementsOptionsModalV2 from "../../DeviceViewV2/DeviceV2/OtherElementsOptionsModalV2/OtherElementsOptionsModalV2";
/**
 * Absolute positioned divs that overlay the app screenshot and highlight the bounding
 * boxes of the elements in the app
 */

export default (props) => {
  const { selectedElement, hoveredElement, actionOnElement, currentDeviceScreenSource, findElementProcessing } =
    useSelector((state) => state.RecordingReducer);
  const { recTestStepList } = useSelector((state) => state.RecTestStepReducer);
  const { isAdvanceElementSelectorDrawerVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { testBlockChildStepList } = useSelector((state) => state.TestBlockReducer);
  let newRecTestStepActionDetail = undefined;
  if (recTestStepList) {
    newRecTestStepActionDetail = fetchNewRecTestStepAction(recTestStepList);
  } else if (testBlockChildStepList) {
    newRecTestStepActionDetail = fetchNewRecTestStepAction(testBlockChildStepList);
  }

  const dispatch = useDispatch();

  let selectedElements = selectedElement ? selectedElement : {};
  const hoveredElements = hoveredElement ? hoveredElement : {};

  let element = props.element;
  let zIndex = props.zIndex;
  const scaleRatio = props.scaleRatio;
  const xOffset = props.xOffset;
  const elLocation = props.elLocation;
  const elSize = props.elSize;

  const scaleRatioX = props.scaleRatioX;
  const scaleRatioY = props.scaleRatioY;
  const { path: hoveredPath } = hoveredElements;
  const { path: selectedPath } = selectedElements;

  const imgWidth = props.imgWidth;
  const imgHeight = props.imgHeight;
  let width = 0,
    height = 0,
    left = 0,
    top = 0,
    highlighterClasses,
    key,
    tag;
  highlighterClasses = ["highlighter-box"];

  let advanceSelectorClasses;

  advanceSelectorClasses = ["advanceSelector-box"];

  if (element) {
    const draw = checkVisibility(element);
    tag = JSON.stringify(element.attributes) + element.path;
    if (draw) {
      // Calculate left, top, width and height coordinates
      const { x1, y1, x2, y2 } = parseCoordinates(element);

      if (x1 !== undefined && y1 && x2 && y2) {
        left = x1 / scaleRatioX;
        top = y1 / scaleRatioY;
        width = (x2 - x1) / scaleRatioX;
        height = (y2 - y1) / scaleRatioY;
        if (props.x && props.y) {
          if (
            element.attributes["clickable"] == "true" &&
            (((x1 + x2) / 2 == props.x && (y1 + y2) / 2 == props.y) ||
              (((x1 + x2) / 2 + 10 < props.x || (x1 + x2) / 2 + 10 > props.x) &&
                ((y1 + y2) / 2 + 10 < props.y || (y1 + y2) / 2 + 10 > props.y)))
          ) {
            // zIndex = 5000;
          }
        }

        if (
          props.isIOS &&
          removeIosElement(
            element,
            scaleRatioX,
            scaleRatioY,
            imgWidth,
            imgHeight,
            newRecTestStepActionDetail && newRecTestStepActionDetail.recTestStepProperty
              ? newRecTestStepActionDetail.recTestStepProperty?.action
              : undefined,
            isAdvanceElementSelectorDrawerVisible
              ? isAdvanceElementSelectorDrawerVisible
              : selectedPath === element.path
          )
        ) {
          left = 0;
          top = 0;
          width = 0;
          height = 0;
        }

        if (
          props.isAndroid &&
          removeAndroidElement(
            element,
            scaleRatioX,
            scaleRatioY,
            imgWidth,
            imgHeight,
            newRecTestStepActionDetail && newRecTestStepActionDetail.recTestStepProperty
              ? newRecTestStepActionDetail.recTestStepProperty?.action
              : undefined,
            isAdvanceElementSelectorDrawerVisible
              ? isAdvanceElementSelectorDrawerVisible
              : selectedPath === element.path
          )
        ) {
          left = 0;
          top = 0;
          width = 0;
          height = 0;
        }
        //Remove Child Element With condition if not required
        props.isAndroid && removeChildElement(element);

        if (elLocation && elSize) {
          width = elSize.width / scaleRatioX;
          height = elSize.height / scaleRatioY;
          top = elLocation.y / scaleRatioY;
          left = elLocation.x / scaleRatioX + xOffset;
          key = "searchedForElement";
          if (selectedPath === element.path) {
            highlighterClasses.push("inspected-element-box");
          } else {
            highlighterClasses.push("suggested-element-box");
          }
        } else {
          // Add class + special classes to hovered and selected elements
          if (hoveredPath === element.path) {
            highlighterClasses.push("hovered-element-box");
            element.path === selectedPath && advanceSelectorClasses.push("inspected-element-box");
          } else if (selectedPath === element.path) {
            advanceSelectorClasses.push("inspected-element-box");
            highlighterClasses.push("inspected-element-box");
          }
        }
        tag += "-" + top + "-" + left + "-" + width + "-" + height;
        key = element.path;
      }
    }
  }

  return (
    <>
      {!(left === 0 && top === 0 && width === 0 && height === 0) ? (
        <>
          {element.path === selectedPath && !findElementProcessing && (
            <div
              className={advanceSelectorClasses.join(" ").trim() + " " + tag}
              style={{
                left: props.isIOS ? imgWidth + 25 : imgWidth + 10,
                top:  top - 22,
                // top:imgHeight * 0.1 > top ? top + height : top - 22,
                width: width || 0,
                height: height || 0,
              }}
              key={advanceSelectorClasses + key}
            >
              {element.path === selectedPath && !findElementProcessing && (
                <div
                  onClick={() =>
                    isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) &&
                    selectedPath === element.path &&
                    dispatch(toggleOtherElementPopover(true))
                  }
                  className="inspectorElement"
                >
                  <SvgLoader iconName={INSPECTOR_ELEMENT_IMAGE} width={"2.16rem"} height={"2.16rem"} />
                </div>
              )}
            </div>
          )}
          <div
            className={highlighterClasses.join(" ").trim() + " " + tag}
            onMouseOver={(event) =>
              DEVICE_INTERACTION_MODE.SELECT === props.deviceInteractionMode &&
              dispatch(selectHoveredElement(key, currentDeviceScreenSource))
            }
            onMouseOut={DEVICE_INTERACTION_MODE.SELECT === props.deviceInteractionMode && unselectHoveredElement}
            onClick={() =>
              DEVICE_INTERACTION_MODE.SELECT === props.deviceInteractionMode &&
              (key === selectedPath
                ? dispatch(unselectElement())
                : dispatch(
                    selectElement(
                      key,
                      currentDeviceScreenSource,
                      props.serverId,
                      props.targetUniqueId,
                      props.x,
                      props.y
                    )
                  ),
              dispatch(toggleOtherElementPopover(false)))
            }
            key={key}
            style={{
              zIndex,
              left: left || 0,
              top: top || 0,
              width: width || 0,
              height: height || 0,
            }}
          >
            <div></div>
          </div>
        </>
      ) : (
        ""
      )}
      {element.path === selectedPath && (
        <OtherElementsOptionsModalV2
          serverId={props.serverId}
          targetUniqueId={props.targetUniqueId}
          x={props.x}
          y={props.y}
          projectId={props.projectId}
          selectedPath={selectedPath}
          elementPath={element.path}
        />
      )}
    </>
  );
};