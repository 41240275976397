import React, { useState } from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { ICON_MORE_NEW } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./ActionComponentV2.module.scss";

const ActionComponentV2 = (props) => {
  const { actions, handleActionChange, data, disabled } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const menu = (
    <Menu>
      <h4 className={styles["action-title"]}>Actions</h4>
      {actions?.map((i) => {
        return (
          <React.Fragment key={i?.name || i?.title}>
            {!i?.children && (
              <Menu.Item
                key={i?.name}
                value={i?.name}
                onClick={() => handleActionChange(i?.name, data)}
                className={styles["menu-options"]}
              >
                {i?.title}
              </Menu.Item>
            )}
            {i?.children && (
              <Menu.SubMenu
                key="SubMenu"
                title={<span className={styles["menu-options"]}>{i?.children && i.title}</span>}
              >
                {i?.children.map((k) => {
                  return (
                    k?.title && (
                      <Menu.Item
                        key={k?.name}
                        value={k?.name}
                        onClick={() => {
                          handleActionChange(k?.name, data);
                        }}
                      >
                        {k?.title}
                      </Menu.Item>
                    )
                  );
                })}
              </Menu.SubMenu>
            )}
          </React.Fragment>
        );
      })}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      placement="bottomRight"
      disabled={disabled}
      overlayClassName="more-Actions-Dropdown"
    >
      <Tooltip trigger="hover" title="More Actions" color={TOOLTIP_BG_COLOR} visible={tooltipVisible}>
        <div
          className={styles["iconMoreButton"]}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          onClick={() => setTooltipVisible(false)}
        >
          <SvgLoader iconName={ICON_MORE_NEW} handleClick={() => setTooltipVisible(false)} />
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default ActionComponentV2;
