import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Popover, Select, Tooltip } from "antd";
import { ANDROID, CLOSE_DEVICE, DEVICE_STATUS, IOS, PROXY_STATUS, TOOLTIP_BG_COLOR } from "../../../Constants";
import { ANDROID_LOGO, CLOCK_ICON, IOS_LOGO } from "../../../Constants/SvgConstants";
import { endDeviceSession, isLandscape, platformAbbrevMapping } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import * as actions from "../../../actions/HomeAction";
import { deleteRecTestStep } from "../../../actions/RecTestStep/RecTestScenarioRecTestStepSingleColumn";
import { resetEditRecTestStep } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  removeLoopConditionFromRecTestStep,
  resetRecTestStepAction,
  showDeleteRecTestStepConfirmationModal,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { findByUdId, manageStartSession } from "../../../actions/RecTestStepAction";
import * as recordingActions from "../../../actions/Recording";
import { startDeviceSession } from "../../../actions/Recording";
import { getDevices } from "../../../actions/ServerListAction";
import history from "../../../history";
import VerticalLine from "../../CommonComponents/VerticalLine";
import CloseSession from "../../DevicesView/Device/CloseSession";
import DeviceInteractActions from "../../DevicesView/Device/DeviceInteractActions/DeviceInteractActions";
import Screenshot from "../../DevicesView/Device/Screenshot";
import DeviceListV2 from "../../FarmManagementV2/DeviceListV2/DeviceListV2";
import TestPlanResultDeviceDetails from "../../TestPlan/TestPlanResultDetails/TestPlanResultDeviceDetails";
import DeviceLoadingSpinnerV2 from "../DeviceLoadingV2/DeviceLoadingSpinnerV2";
import ShowDeviceViewButtonV2 from "../ShowDeviceViewButtonV2";
import styles from "./DeviceSelectionForRecordingV2.module.scss";

const { Option } = Select;

const DeviceSelectionForRecordingV2 = (props) => {
  const dispatch = useDispatch();
  const {
    targetUniqueId,
    showDeviceView,
    deviceFarmId,
    platform,
    serverId,
    projectId,
    organizationId,
    showDeviceInteractionModeOnly,
    setShowDeviceView,
    handleSession,
  } = props;
  const { currentScreenOrientation, isStepAdeded, recordingState, deviceLoaded, proxyStatus, logStarted } = useSelector(
    (state) => state.RecordingReducer
  );
  const { selectedServerDevices } = useSelector((state) => state.ServerListReducer);
  const { selectedDeviceByUdid, deviceLoading, deviceUnloading, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { childComponentList } = useSelector((state) => state.CommonReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [state, setState] = useState({
    selectedDeviceId: null,
    showContent: true,
  });
  const [releaseAPIPending, setReleaseAPIPending] = useState(false);
  //Destructure object
  const {
    brandName,
    displayName,
    platformType,
    autoServerDetail,
    deviceFarm,
    osVer: osVerOfSelectedDevice,
    targetUniqueId: targetUniqueIdOfSelectedDevice,
  } = selectedDeviceByUdid ? selectedDeviceByUdid : {};

  useEffect(() => {
    if (!state.showContent && !deviceLoading && !deviceUnloading) {
      setState({ ...state, showContent: true });
    }
  }, [deviceLoading, deviceUnloading]);

  useEffect(() => {
    if (targetUniqueId != null) {
      setState({ ...state, showContent: false });
      dispatch(getDevices(parseInt(deviceFarmId)));
      dispatch(
        startDeviceSession(
          platform,
          serverId,
          targetUniqueId,
          deviceFarmId,
          false,
          projectId,
          organizationId,
          DEVICE_STATUS.RECORDING
        )
      );
      dispatch(findByUdId(targetUniqueId));
    }
  }, []);

  useEffect(() => {
    if (!state.showContent) {
      handleSessionClose();
    }
  }, [projectId]);

  useEffect(() => {
    if (handleSession) {
      handleSessionClose();
    }
  }, [handleSession]);

  const showDeviceDetails = useMemo(
    () => !deviceLoading && !deviceUnloading && state.showContent && !deviceLoaded && showDeviceView,
    [deviceLoading, deviceUnloading, state.showContent, deviceLoaded, showDeviceView]
  );
  const showDeviceNameAfterLoadDevice = useMemo(
    () => selectedDeviceByUdid && showDeviceView,
    [selectedDeviceByUdid, showDeviceView]
  );

  let title = useMemo(() => brandName + " " + displayName, [brandName, displayName]);

  let centerDeviceMode = "";
  if (isLandscape(currentScreenOrientation)) {
    if (!props.recording) centerDeviceMode = "landscape-center-device-mode";
  } else if (showDeviceInteractionModeOnly) {
    centerDeviceMode = "center-device-mode";
  }
  const screenRecording =
    childComponentList && childComponentList.filter((item) => item.name === "Device_Recording").length > 0;
  let blockActionInteraction = "";
  if (!isStepAdeded && recordingState == DEVICE_STATUS.RECORDING) {
    blockActionInteraction = " block-action-interation";
  }

  const deviceList = [];
  if (selectedServerDevices) {
    for (let i = 0; i < selectedServerDevices.length; i++) {
      deviceList.push(
        <Option key={selectedServerDevices[i].device.targetUniqueId}>{selectedServerDevices[i].device.name}</Option>
      );
    }
  }

  const handleStartSession = (targetUniqueId, deviceFarmId) => {
    setState({ ...state, showContent: false });
    dispatch({ type: CLOSE_DEVICE, deviceClose: false });
    dispatch(manageStartSession(targetUniqueId, deviceFarmId, projectId, organizationId));
  };

  const handleSessionClose = () => {
    setState({ ...state, showContent: true });
    dispatch(getDevices(null));
  };

  const showDeviceList = (devices) => {
    return (
      <Popover
        content={
          <TestPlanResultDeviceDetails
            deviceResultDetails={{
              targetUniqueId: devices?.targetUniqueId,
              brandName: devices?.brandName,
              name: devices?.name,
              locale: devices?.locale,
              platform: devices?.platformName,
              osVer: devices?.osVer,
              devicesLength: devices.length,
              displayName: devices?.displayName,
            }}
            selectedDeviceDetail
          />
        }
        placement="bottom"
      >
        <div className={styles["deviceNameOrIcon"]}>
          <div className={styles["deviceTitle"]}>
            <span>
              {title}
              <span disabled> | </span>
              {selectedDeviceByUdid && "(" + osVerOfSelectedDevice + ")"}
            </span>
          </div>
          <div className={styles["deviceIconOrConnectionName"]}>
            <SvgLoader
              iconName={selectedDeviceByUdid && platformType?.name == IOS ? IOS_LOGO : ANDROID_LOGO}
              width="1.25rem"
              height="1.25rem"
            />
          </div>
        </div>
      </Popover>
    );
  };

  const showDeviceSpinner = (releaseDevice) => {
    return (
      <div
        className={`${(platformType?.name === ANDROID && "smartphones") || (platformType?.name === IOS && "iPhone")}`}
      >
        <div className={`${styles["device_Loader"]}`}>
          <DeviceLoadingSpinnerV2 platformType={platformType} releaseDevice={releaseDevice} />
        </div>
      </div>
    );
  };

  const handleReleaseDevice = (e) => {
    if (proxyStatus == PROXY_STATUS.STARTED) {
      dispatch(
        recordingActions.stopProxy({
          targetUniqueId: targetUniqueIdOfSelectedDevice,
          serverId: autoServerDetail.id,
          platformName: platformType?.name,
          deviceFarmId: deviceFarm.id,
        })
      );
    }
    if (recordingState === DEVICE_STATUS.RECORDING) {
      endDeviceSession(
        recordingActions,
        platformType?.name,
        autoServerDetail.id,
        targetUniqueIdOfSelectedDevice,
        deviceFarm.id,
        logStarted,
        proxyStatus == PROXY_STATUS.STARTED
      );
      e && e.preventDefault();
    }
    if (dispatch(actions.logStarted())) {
      if (!dispatch(actions.forceStopLogs(autoServerDetail?.id, targetUniqueIdOfSelectedDevice))) {
        e && e.preventDefault();
      } else {
        history.push("/" + organizationId + "/project/" + (projectId ? projectId : "") + "/TestScenario");
        dispatch(actions.stopScreenStreaming(autoServerDetail?.id, targetUniqueIdOfSelectedDevice, projectId));
        e && e.preventDefault();
      }
    } else {
      history.push("/" + organizationId + "/project/" + (projectId ? projectId : "") + "/TestScenario");
      dispatch(actions.stopScreenStreaming(autoServerDetail?.id, targetUniqueIdOfSelectedDevice, projectId));
      e && e.preventDefault();
    }
    handleSessionClose();
  };

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "releaseDevice") {
        const releaseData = JSON.parse(event.newValue);
        if (releaseData) {
          handleReleaseDevice();
          if (unSavedRecStepTag) {
            dispatch(resetEditRecTestStep());
            dispatch(recordingActions.resetElement());
            if (selectedRecStepForOperation?.recTestStepProperty?.isLoopStep === 1) {
              dispatch(removeLoopConditionFromRecTestStep(unSavedRecStepTag));
            }
            dispatch(resetRecTestStepAction(unSavedRecStepTag));
            if (selectedRecStepForOperation?.project) {
              dispatch(showDeleteRecTestStepConfirmationModal(undefined, unSavedRecStepTag));
              dispatch(deleteRecTestStep());
            }
          }
        }
      }
      if (event.key === "releaseDevicePending") {
        const pendingData = JSON.parse(event.newValue);
        if (pendingData && pendingData.status === "pending") {
          setReleaseAPIPending(true);
        } else {
          setReleaseAPIPending(false);
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
      setTimeout(() => {
        localStorage.removeItem("releaseDevice");
      }, 1000);
    };
  }, [dispatch, unSavedRecStepTag, selectedRecStepForOperation, releaseAPIPending]);

  return (
    <div className={showDeviceView ? styles["device_List"] : "h-100"}>
      {showDeviceView && !deviceLoading && (
        <div className={styles["showDeviceViewButton"]}>
          <ShowDeviceViewButtonV2
            showDeviceView={showDeviceView}
            setShowDeviceView={setShowDeviceView}
            buildDevice={true}
          />
        </div>
      )}
      {showDeviceDetails ? (
        <Col>
          <DeviceListV2
            projectId={projectId}
            buildDevice={true}
            handleStartSession={handleStartSession}
            setShowDeviceView={setShowDeviceView}
            showDeviceView={showDeviceView}
            hideDeviceViewButtonVisibility={true}
            organizationId={organizationId}
          />
        </Col>
      ) : (
        <Col span={24} className={showDeviceView && styles["deviceSection"]}>
          {showDeviceNameAfterLoadDevice && (
            <div className={styles["recording_device_recording_section"]}>
              {/* Device Name and Recording Icon */}
              <div className={styles["deviceName"]}>
                {selectedDeviceByUdid && (
                  <>
                    <div className={styles["selected_device_name"]}>
                      {showDeviceList(selectedDeviceByUdid)}
                      <VerticalLine className={styles["borderRight"]} />
                      <Tooltip
                        title={
                          <>
                            <div>
                              <span className={styles["tooltipLabel"]}>Last Interaction Time:</span>
                              {new Date(props.idleTimer && props.idleTimer?.state?.lastActive).toLocaleTimeString(
                                "it-IT"
                              )}
                            </div>
                            <div className={styles["tooltipNote"]}>
                              <span className={styles["tooltipLabel"]}>Note:</span> Device will auto-release after 30
                              min of inactivity.
                            </div>
                          </>
                        }
                        color={TOOLTIP_BG_COLOR}
                      >
                        <div className={styles["clockIcon"]}>
                          <SvgLoader iconName={CLOCK_ICON} />
                        </div>
                      </Tooltip>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
          {releaseAPIPending
            ? platformType?.name && showDeviceSpinner(true)
            : !deviceLoading && !deviceUnloading
            ? showDeviceNameAfterLoadDevice &&
              deviceLoaded && (
                <>
                  <div className={styles["device"]}>
                    <div>
                      <div className={styles["closeSession"]}>
                        <CloseSession
                          targetUniqueId={targetUniqueIdOfSelectedDevice}
                          serverId={autoServerDetail.id}
                          deviceFarmId={deviceFarm.id}
                          platform={platformType?.name}
                          projectId={projectId}
                          organizationId={organizationId}
                          handleSessionClose={handleSessionClose}
                          handleReleaseDevice={handleReleaseDevice}
                        />
                      </div>
                      <DeviceInteractActions
                        targetUniqueId={targetUniqueIdOfSelectedDevice}
                        serverId={autoServerDetail.id}
                        platformName={platformType?.name}
                        blockActionInteration={blockActionInteraction}
                        deviceFarmId={deviceFarm.id}
                        osVersion={osVerOfSelectedDevice}
                        projectId={projectId}
                      />
                    </div>
                    <div className="width-full">
                      <Screenshot
                        targetUniqueId={targetUniqueIdOfSelectedDevice}
                        serverId={autoServerDetail.id}
                        platform={platformAbbrevMapping(platformType?.name)}
                        recording={screenRecording}
                        deviceFarmId={deviceFarm.id}
                      />
                    </div>
                  </div>
                </>
              )
            : platformType?.name && showDeviceSpinner()}
        </Col>
      )}
    </div>
  );
};

export default DeviceSelectionForRecordingV2;
