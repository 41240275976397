import { notification } from "antd";
import {
  GET_INVITATION_LIST,
  SUCCESS_STATUS,
  FAILED_STATUS,
  RESEND_INVITATION_REQUEST,
  DELETE_INVITATION_REQUEST,
  ACCEPT_INVITATION_REQUEST,
  INVITATION_LOADING
} from "../Constants";

let defaultState = {
  invitationList: null,
  resendInvitation: null,
  deleteInvitation: null,
  acceptInvitationList: null,
  invitationLoader: false
};

export default function InvitationReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case RESEND_INVITATION_REQUEST:
      response = action.response;
      state.resendInvitation = null;

      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: response.data,
          description: response.message,
        });
        state.resendInvitation = response;
      } else if (response.status !== SUCCESS_STATUS) {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      return {
        ...state,
      };

    case DELETE_INVITATION_REQUEST:
      response = action.response;
      state.deleteInvitation = null;

      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: response.data,
          description: response.message,
        });
        state.deleteInvitation = response;
      } else if (response.status !== SUCCESS_STATUS) {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      return {
        ...state,
      };

    case ACCEPT_INVITATION_REQUEST:
      response = action.response;
      state.acceptInvitationList = null;
      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: response.status,
          description: response.message,
        });
        state.acceptInvitationList = response;
      } else if (response.status !== SUCCESS_STATUS) {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      return {
        ...state,
      };

    case GET_INVITATION_LIST:
      response = action.response.data;
      return {
        ...state,
        invitationList: response,
      };

    case INVITATION_LOADING:
      return {
        ...state,
        invitationLoader: action.data
      }

    default:
      return state;
  }
}
