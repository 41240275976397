import React, { useEffect } from "react";
import _ from "lodash";
import { Col, Row, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDeviceFarmList, getDevices } from "../../actions/ServerListAction";
import EmptyRecords from "../CommonComponents/EmptyRecords/EmptyRecords";
const { Option } = Select;
export default (props) => {
  const { deviceFarmId, deviceFarmList } = useSelector((state) => state.ServerListReducer);

  const dispatch = useDispatch();
  //WillMount
  useEffect(() => {
    dispatch(getDeviceFarmList({ projectId: props.projectId }));
  }, [props.projectId]);

  const selectDevice = (e) => {
    dispatch(getDevices(e));
    if (props.handleOnFarmChange) props.handleOnFarmChange();
  };

  useEffect(() => {
    if (deviceFarmList && deviceFarmList.length > 0 && deviceFarmId === undefined && props.routeServerId) {
      dispatch(getDevices(parseInt(props.routeServerId)));
    }
  }, [deviceFarmId, deviceFarmList, dispatch, props.routeServerId]);

  let value = deviceFarmList && deviceFarmList.length > 0 && deviceFarmList.filter((item) => item.id === deviceFarmId);
  return (
    <>
      <Row className="w-100" justify="space-between">
        <Col>
          <h1 className="ml-10">Remote Devices</h1>
        </Col>
        <Col className="display-flex mr-10">
          {deviceFarmList != undefined &&
            deviceFarmList &&
            deviceFarmList.length > 0 &&
            (deviceFarmList.length > 1 ? (
              <Select
                className="w-178 font-size-12 text-light1"
                placeholder="Device Farm"
                onChange={selectDevice}
                value={value && value.length > 0 ? value[0].name : undefined}
              >
                <Option className="dd-title">Device Farm</Option>
                {deviceFarmList &&
                  deviceFarmList.map((v, k) => {
                    return (
                      <Option key={k} value={v.id}>
                        {v.name}
                      </Option>
                    );
                  })}
              </Select>
            ) : (
              <span className={"farm-name"}> {"Farm: " + deviceFarmList[0].name} </span>
            ))}
        </Col>
      </Row>
      {deviceFarmList === undefined && (
        <Row justify="center" className="w-100">
          <Spin spinning={true}></Spin> &nbsp; <span>Preparing Farms And Devices...</span>
        </Row>
      )}
      {_.isEmpty(deviceFarmList) && (
        <Row justify="center" className="w-100 no-device-available">
          <EmptyRecords
            ignoreMargin={true}
            description={"No Devices Are Available / Assigned To You. Please Contact To Administrator."}
          />
        </Row>
      )}
    </>
  );
};
