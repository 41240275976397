import { RECORD_10_PER_PAGE, UPDATE_TEST_DATA_SET_DATA } from "../../Constants";
import * as TestDataSetCommonAction from "./TestDataSetCommonAction";
import _ from "lodash";
import { fetchTestDataSetByProfileId } from "./TestDataSetRedirectAction";

export function _fetchTestDataSetByProfileId(itemId, search = "", pageNumber = 1) {
  return (dispatch) => {
    let data = { projectId: itemId, search: search, pageNumber: pageNumber, recordPerPage: RECORD_10_PER_PAGE };
    dispatch(TestDataSetCommonAction._getDataProfileByItemId(data));
  };
}

export function _getTestDataSetById(testDataSetId) {
  return async (dispatch, getState) => {
    let data = { testDataSetId };
    dispatch(TestDataSetCommonAction._callTestDataSetById(data));
  };
}

export function _changeTestDataCustomValue(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _resetTestDataCustomValue(testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      undefined
    );
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", false);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      undefined
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataIsCustomReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", value);
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

function _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, key, value) {
  // Update For Scenario Level Test data
  updateTestDataSetForEdit["testDataValues"] = TestDataSetCommonAction.updateTestDataValues(
    testDataRow,
    updateTestDataSetForEdit?.testDataValues,
    key,
    value
  );
  return updateTestDataSetForEdit;
}

export function _saveTestDataSet(testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = { ...updateTestDataSetForEdit };
    new Promise((resolve) =>
      resolve(dispatch(TestDataSetCommonAction._callSaveTestDataSet(updateTestDataSetForEdit)))
    ).then(() => {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    });
  };
}
