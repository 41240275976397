import { default as React, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEP } from "../../../../Constants";
import { SELECT_AREA_IMAGE } from "../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmpty } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { reRecordRecTestStepElement } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import CustomButtonV2 from "../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import styles from "./RecTestStepImageSelectionDetailV2.module.scss";

const RecTestStepImageSelectionDetailV2 = (props) => {
  const dispatch = useDispatch();
  const { data, recElement, isSkipStep, parentRecElement } = props;
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, unSavedRecElementGuid, requestingPerformRecTestStepAction } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);

  //Destructure object
  const { tag, recTestStepProperty } = data ? data : {};
  const { action, recStepVerifyElementProperties, isStepHasPrecondition, isLoopStep, recElements, loopCount } =
    recTestStepProperty ? recTestStepProperty : {};

  const {
    guid,
    selectedImageCoordinates,
    id: recElementId,
    previous_elementImage,
    elementPurpose,
    elementImage,
    childRecElements,
  } = recElement ? recElement : {};

  // Re-Record Button when to display
  const hasAllItemsWithId = (array) => {
    return array.length > 0 && array.every((item) => item.id !== undefined);
  };

  const checkStepConditions = useCallback(() => {
    return (
      action === STEP &&
      isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
      ((isStepHasPrecondition === 1 && hasAllItemsWithId(recStepVerifyElementProperties)) ||
        (isLoopStep === 1 &&
          loopCount > 0 &&
          (recElements.length === 0 || (recElements.length > 0 && hasAllItemsWithId(recStepVerifyElementProperties)))))
    );
  }, [
    action,
    unSavedRecStepTag,
    isStepHasPrecondition,
    recStepVerifyElementProperties,
    isLoopStep,
    loopCount,
    recElements,
  ]);

  const checkNonStepConditions = useCallback(() => {
    return (
      action !== STEP &&
      data.id !== undefined &&
      isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
      isNullOrUndefinedOrEmpty(unSavedRecElementGuid)
    );
  }, [action, data.id, unSavedRecStepTag, unSavedRecElementGuid]);

  // Function to determine if the re-record button should be shown
  const shouldShowReRecordButton = useCallback(() => {
    if (parentRecElement === undefined) {
      return checkStepConditions() || checkNonStepConditions();
    } else {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecElementGuid) &&
        (elementPurpose === "ACTION" ||
          (elementPurpose === "VERIFICATION" &&
            recStepVerifyElementProperties.length > 0 &&
            hasAllItemsWithId(recStepVerifyElementProperties)))
      );
    }
  }, [
    parentRecElement,
    unSavedRecElementGuid,
    elementPurpose,
    recStepVerifyElementProperties,
    checkStepConditions,
    checkNonStepConditions,
  ]);

  let selectionImage =
    (recElementId === undefined || previous_elementImage !== undefined) &&
    elementImage !== undefined &&
    !elementImage.includes("https://")
      ? "data:image/jpeg;base64," + elementImage
      : elementImage;

  let disableReRecordButton = useMemo(
    () =>
      selectedDeviceByUdid === undefined ||
      !startDeviceStreamingStatus ||
      requestingPerformRecTestStepAction ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
        unSavedRecStepTag !== tag &&
        unSavedRecElementGuid !== guid),
    [
      selectedDeviceByUdid,
      startDeviceStreamingStatus,
      requestingPerformRecTestStepAction,
      unSavedRecStepTag,
      tag,
      guid,
      unSavedRecElementGuid,
    ]
  );

  const disableReplaceElement = useMemo(
    () => isPreviewRunning || disableReRecordButton || isSkipStep,
    [isPreviewRunning, disableReRecordButton, isSkipStep]
  );

  // Assigning the final result
  const showReRecordButton = useMemo(() => {
    return (
      shouldShowReRecordButton() &&
      (isPreviewRunning || !disableReRecordButton || isSkipStep) &&
      (childRecElements === undefined || recElement?.childRecElements?.length === 0)
    );
  }, [shouldShowReRecordButton, isPreviewRunning, disableReRecordButton, isSkipStep, childRecElements, recElement]);

  const boundsMessage = useMemo(() => {
    return selectedImageCoordinates
      ? `Bounds : [${selectedImageCoordinates.x},${selectedImageCoordinates.y}]
                [${selectedImageCoordinates.x + selectedImageCoordinates.width},
                ${selectedImageCoordinates.y + selectedImageCoordinates.height}]`
      : "";
  }, [selectedImageCoordinates]);

  return (
    <div className={`${styles["selected_Image_Details"]} `}>
      {selectionImage ? (
        <>
          <div className={styles["selected_Image_Container"]}>
            <div className={styles["selected_Image"]}>
              <img className={styles["image"]} src={selectionImage} alt="Screenshot" />
            </div>
          </div>
          <div className={styles['bound_Details_And_Replace_Btn']}>
            <div className={styles['bounds']}>{boundsMessage}</div>
            {showReRecordButton && (
              <CustomButtonV2
                buttonLabel="Replace Element"
                handleClick={() =>
                  !disableReplaceElement && dispatch(reRecordRecTestStepElement(data.tag, guid, parentRecElement))
                }
                disabled={disableReplaceElement}
                className={`common_Purple_Btn ${styles["replace_Element_Btn"]}`}
              />
            )}
          </div>
        </>
      ) : (
        <SvgLoader iconName={SELECT_AREA_IMAGE} />
      )}
    </div>
  );
};

export default RecTestStepImageSelectionDetailV2;
