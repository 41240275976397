import { default as React, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Popover, Row, Select, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { SELECTOR_TYPE, STEP, TOOLTIP_BG_COLOR, VERIFICATIONS } from "../../../../../Constants";
import {
  ADD_ICON,
  BLACK_COLOR,
  DEEP_BLUE_COLOR,
  DISABLE_ELEMENT_ICON,
  DISABLE_ICON_COLOR,
  GENERAL_SETTING_NEW_ICON,
  LIGHT_GRAY_COLOR,
  LOAD_DEVICE_ICON,
  WHITE_COLOR,
} from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  addElementIntoStep,
  changeRecTestStepElementCondition,
  visibleGeneralSettingsDrawer,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import TextButtonV2 from "../../../../CommonComponents/TextButtonV2/TextButtonV2";
import RecStepGeneralSetting from "../../../../RecTestStep/StepInnerComponents/RecStepGeneralSetting/RecStepGeneralSetting";
import RecTestStepElementV2 from "../RecTestStepElementV2/RecTestStepElementV2";
import styles from "./RecTestStepElementListV2.module.scss";

const RecTestStepElementListV2 = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    disabled,
    projectId,
    isDeviceLoaded,
    isSkipStep,
    recTestSteps,
    isTestBlock,
    saveButtonDisable,
    stepId,
  } = props;

  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const { isPreviewRunning, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, unSavedRecElementGuid, requestingPerformRecTestStepAction } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const [elementCount, setElementCount] = useState(1);
  //Destructure of data object
  const { recStepVerifyElementProperties, recElements, action, recElementOperator } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const { tag, id } = data ? data : {};

  useEffect(() => {
    if (data?.recTestStepProperty?.recElements?.length !== elementCount) {
      setElementCount(1);
    }
  }, [data?.recTestStepProperty?.recElements?.length]);

  const memorizedValue = useMemo(() => {
    const isSaveButtonDisable =
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && tag !== unSavedRecStepTag) ||
      isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) ||
      disabled ||
      isPreviewRunning ||
      isSkipStep;
    const disableAddElement = (isSaveButtonDisable && !isEmpty(recElements)) || requestingPerformRecTestStepAction;
    const allowOrCondition =
      !isEmpty(recStepVerifyElementProperties) && recStepVerifyElementProperties[0].key === "exist";
    const allowAndCondition = action === STEP || action === VERIFICATIONS;
    const elementOperator =
      recElementOperator != null && allowOrCondition
        ? recElementOperator
        : !allowOrCondition && allowAndCondition
        ? "AND"
        : "OR";
    const isElementOperatorOptionDisabled =
      isEmpty(recStepVerifyElementProperties) || !allowOrCondition || disableAddElement;
    return {
      elementOperator,
      isElementOperatorOptionDisabled,
      disableAddElement,
    };
  }, [
    disabled,
    unSavedRecStepTag,
    isPreviewRunning,
    requestingPerformRecTestStepAction,
    unSavedRecElementGuid,
    tag,
    recElementOperator,
    recStepVerifyElementProperties,
    action,
    isSkipStep,
    recElements,
  ]);

  const { disableAddElement, elementOperator, isElementOperatorOptionDisabled } = memorizedValue;

  const selectionImage = (recElement) => {
    return (recElement.id === undefined ||
      recElement.previous_elementImage !== undefined ||
      recElement.elementImage !== null) &&
      recElement.elementImage !== undefined &&
      !recElement.elementImage.includes("https://")
      ? "data:image/jpeg;base64," + recElement.elementImage
      : recElement.elementImage;
  };

  const selectedElement = (index) => {
    return index + 1 === elementCount && selectedRecStepForOperation?.id === id;
  };

  return (
    <div className={styles["recElementDetails"]}>
      <div className={styles["recElementsList"]}>
        <div className={styles["addElement"]}>
          <TextButtonV2
            title={
              <div className={styles["element_Text_And_Icon"]}>
                <Tooltip
                  title={"Add New Element"}
                  color={TOOLTIP_BG_COLOR}
                  overlayClassName={!(stepId?.length >= 3) ? styles["tooltip_Visible"] : ""}
                >
                  <div className={styles["newElementText"]}>
                    <SvgLoader
                      iconName={ADD_ICON}
                      iconColor={disableAddElement ? DISABLE_ICON_COLOR : DEEP_BLUE_COLOR}
                      disabled={disableAddElement}
                      width="1.6rem"
                      height="1.6rem"
                    />
                    <span
                      className={`${disableAddElement && styles["disableAddELementText"]} ${styles["addElementText"]} ${
                        stepId?.length >= 3 && styles["hideAddElementText"]
                      }`}
                    >
                      New Element
                    </span>
                  </div>
                </Tooltip>
                {!startDeviceStreamingStatus && !isTestBlock && (
                  <Tooltip title={"Load Device to add a new Element."} color={TOOLTIP_BG_COLOR}>
                    <div className={styles["load_Device_Icon"]}>
                      <SvgLoader iconName={LOAD_DEVICE_ICON} />
                    </div>
                  </Tooltip>
                )}
              </div>
            }
            disabled={disableAddElement}
            onClick={() => {
              dispatch(addElementIntoStep(tag, undefined));
              setElementCount(data?.recTestStepProperty?.recElements?.length);
            }}
          />
          <div className={styles["condition-And-Settings-Icon"]}>
            <Tooltip title="Set Element condition" color={TOOLTIP_BG_COLOR}>
              <Select
                value={elementOperator}
                disabled={isElementOperatorOptionDisabled}
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                className={`select_Operators_For_Verifications ${
                  isElementOperatorOptionDisabled && "disabled_Operators_Select_Box"
                }`}
                onChange={(e) => dispatch(changeRecTestStepElementCondition(e, tag))}
              ></Select>
            </Tooltip>
            <Tooltip title={"Settings"} color={TOOLTIP_BG_COLOR}>
              <div className={styles["settingsIcon"]}>
                <SvgLoader
                  iconName={GENERAL_SETTING_NEW_ICON}
                  iconColor={isPreviewRunning ? DISABLE_ICON_COLOR : BLACK_COLOR}
                  handleClick={() => !isPreviewRunning && dispatch(visibleGeneralSettingsDrawer(true, action, tag))}
                  disabled={isPreviewRunning}
                />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles["allElementsList"]}>
          {recElements?.map((recElement, index) => (
            <>
              <div
                className={`${styles["elementCount"]} ${disabled ? "cursor__not_allowed" : "cursor__pointer"} 
                ${
                  selectedElement(index)
                    ? recElement?.isActive === 0
                      ? styles["disableElementBgColor"]
                      : styles["elementBgColor"]
                    : ""
                }`}
                onClick={() => {
                  setElementCount(index + 1);
                  dispatch(selectRecTestSteps(data));
                }}
              >
                <div className={styles["imageOrName"]}>
                  <div className={styles["disableElementIcon"]}>
                    <span
                      className={`${styles["elementCountText"]} ${
                        selectedElement(index) && styles["selectedElementText"]
                      }`}
                    >
                      Element {index + 1}
                    </span>
                  </div>
                  <div>
                    {recElement !== undefined && selectionImage(recElement) && (
                      <Popover
                        content={
                          <img
                            src={selectionImage(recElement)}
                            height={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 50 : 450}
                            width={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 50 : 225}
                            alt="Screenshot"
                          />
                        }
                        placement="left"
                        title="Element Image"
                        trigger="hover"
                      >
                        <img
                          className={styles["screenshot"]}
                          src={selectionImage(recElement)}
                          height={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 15 : 30}
                          alt="Screenshot"
                        />
                      </Popover>
                    )}
                  </div>
                </div>
                <div className={styles["disabled_Icon"]}>
                  {recElement?.isActive === 0 && (
                    <SvgLoader
                      iconName={DISABLE_ELEMENT_ICON}
                      iconColor={selectedElement(index) ? WHITE_COLOR : LIGHT_GRAY_COLOR}
                      width="18.333px"
                      height="18.333px"
                    />
                  )}
                </div>
              </div>
              {index !== data?.recTestStepProperty?.recElements?.length - 1 && (
                <div className={styles["operator_Divider"]}>
                  <div className={styles["operator_Label"]}>{elementOperator}</div>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      {recElements?.map((recElement, index) => {
        return (
          index + 1 === elementCount && (
            <div className={styles["recElementContent"]}>
              <div
                className={`${styles["element"]} ${
                  selectedRecStepForOperation?.id === id && styles["selected_Element"]
                } ${recElement?.isActive === 0 && styles["disableElement"]}`}
              >
                <RecTestStepElementV2
                  recTestSteps={recTestSteps}
                  key={index}
                  recElement={recElement}
                  data={data}
                  disabled={disabled}
                  projectId={projectId}
                  isDeviceLoaded={isDeviceLoaded}
                  isSkipStep={isSkipStep}
                  isTestBlock={isTestBlock}
                  elementCount={elementCount}
                  stepId={stepId}
                />
              </div>
            </div>
          )
        );
      })}
      <RecStepGeneralSetting
        data={data}
        disabled={disabled}
        disableMaxNoOfSwipes={disabled || isSkipStep || isPreviewRunning}
        isPreviewRunning={isPreviewRunning}
        isSkipStep={isSkipStep}
        saveButtonDisable={saveButtonDisable}
      />
    </div>
  );
};

export default RecTestStepElementListV2;
