import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Col, Row, Select } from "antd";
import { Option } from "antd/lib/mentions";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { saveProject } from "../../actions/ProjectsAction";
import { getPlatformDevice, myTeams } from "../../actions/TeamsAction";
import { SHARE_ICON, XPRESS_LOGO } from "../../CdnImagePath";
import {
  ADD_MEMBERS,
  CF_DISTRIBUTION_DOMAIN,
  CF_KEY_PAIR_ID,
  PRE_SIGNED_URL_EXPIRATION_DAYS,
  S3ACCESS_KEY_ID,
  S3BUCKET,
  S3BUCKET_REGION,
  S3HOST,
  S3SECRET_ACCESS_KEY,
} from "../../Constants";
import { confirmModal, isNullOrUndefinedOrEmpty, showNotification } from "../../Util";
import BackButton from "../CoreComponents/BackButtonComponent/BackButton";
import { Label, Required, StyledInput, StyledTextArea, Title } from "../CoreComponents/StyledComponent/StyledComponents";

const initialState = {
  name: "",
  appName: "",
  description: "",
  projectMembers: [],
  teamId: null,
  platformTypeId: null,
};

const CreateProject = (props) => {
  const { orgId, onClose,teamNameOfProject } = props;
  const dispatch = useDispatch();
  const { teamIdOfProject } = useSelector((state) => state.ProjectsReducer);
  const { platformDataList } = useSelector((state) => state.TeamsReducer);
  const [projectData, setProjectData] = useState(initialState);

  useEffect(() => {
    dispatch(getPlatformDevice());
    dispatch(myTeams(orgId));
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProjectData({ ...projectData, [name]: value });
  };

  const handleSelectChange = (name, value) => {
    setProjectData({ ...projectData, [name]: value });
  };

  const handleSaveButton = () => {
    if (projectData?.name.length > 0) {
      if (projectData?.teamId == null) {
        return showNotification("error", "Please Select Team.", "");
      }
      let preparedObject = {
        ...{ ...projectData, description: projectData?.description !== "" ? projectData?.description : null },
        orgId: orgId,
      };
      dispatch(saveProject(preparedObject, onClose));
    } else {
      showNotification("error", "Please Insert Project Name.", "");
    }
  };
  const children = [];
  const childrenTeam = [];
  const platformList = [];
  const { myOrgUsers, myTeamsList } = useSelector((state) => state.TeamsReducer);

  if (myOrgUsers) {
    for (const element of myOrgUsers) {
      children.push(<Option key={element.email}>{element.email}</Option>);
    }
  }
  if (myTeamsList) {
    for (const element of myTeamsList) {
      childrenTeam.push(<Option value={element.teamId}>{element.teamName}</Option>);
    }
  }
  if (platformDataList) {
    for (const element of platformDataList) {
      platformList.push(<Option value={element.id}>{element.name}</Option>);
    }
  }

  const isDisable = () =>
    isNullOrUndefinedOrEmpty(projectData?.appName) ||
    isNullOrUndefinedOrEmpty(projectData?.name) ||
    isNullOrUndefinedOrEmpty(projectData?.teamId) ||
    isNullOrUndefinedOrEmpty(projectData?.platformTypeId);

  return (
    <>
      <Row justify="space-between" align="middle" className="innerheader-fixed">
        <div className="display-flex align-items-center">
          <Link to="/">
            <a>
              <img src={XPRESS_LOGO} className={"logo"} alt="Company Logo" />
            </a>
          </Link>
          <span className="headerDetail">Create New Project</span>
        </div>
        <div className="display-flex align-items-center">
          <Button
            size="large"
            className="orange"
            type="primary"
            onClick={() => handleSaveButton()}
            disabled={isDisable()}
          >
            Save
          </Button>
          <div className="close-seperator">
            <BackButton handleBack={() => (_.isEqual(projectData, initialState) ? onClose() : confirmModal(onClose))} />
          </div>
        </div>
      </Row>
      <Row justify="center" className="mt-50">
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <Row className="mt-30 mb-30" gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Build Details</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <span className="text-color font-weight-500">App Name</span>
              <Required>*</Required>
              <StyledInput
                className="border-radius-4 mb-20 mt-5"
                name="appName"
                value={projectData?.appName}
                onChange={handleInputChange}
                placeholder="Please Enter App name"
                autoFocus
              />
              <span className="text-color font-weight-500">Project Name</span>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="name"
                value={projectData?.name}
                onChange={handleInputChange}
                placeholder="For Example: Development Or QA"
              />
              <span className="text-color font-weight-500">Project Description</span>
              <StyledTextArea
                className="mb-20 mt-5"
                rows={4}
                name="description"
                value={projectData?.description}
                onChange={handleInputChange}
                placeholder="Insert Project Description."
              />
              <span className="text-color font-weight-500">Platform</span>
              <Required>*</Required>
              <Select
                className="mb-20 mt-5 w-100"
                value={projectData?.selectedPlatform}
                placeholder={"Select Platform"}
                onChange={(e) => handleSelectChange("platformTypeId", e)}
              >
                {platformList}
              </Select>
            </Col>
          </Row>
          <hr className="custom-hr" />
          <Row className="mt-30 mb-30" gutter={50}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 1 }}
              className="font-size-16 font-weight-500 font-color-darkBlue"
            >
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Team Details</h3>
              <span className="display-fle align-items-center font-color-primaryGray font-weight-500 font-size-13">
               Manage Team
              </span>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <span className="text-color font-weight-500">Team</span>
              <Required>*</Required>
              <Select
                className="mb-20 mt-5 w-100"
                placeholder="Select Team"
                defaultValue={teamIdOfProject}
                value={teamNameOfProject?teamNameOfProject :projectData?.teamId}
                onChange={(e) => handleSelectChange("teamId", e)}
              >
                {childrenTeam}
              </Select>

              <span className="text-color font-weight-500">Team Members</span>
              <Required>*</Required>
              <Select
                className="mb-20 mt-5 w-100"
                placeholder="Selected Team Members"
                defaultValue={teamIdOfProject}
                value={teamNameOfProject?teamNameOfProject :projectData?.teamId}
                onChange={(e) => handleSelectChange("teamId", e)}
              >
              {childrenTeam}
              </Select>

              <span className="text-color font-weight-500">Members</span>
              <Select
                className="mt-5 w-100"
                mode="tags"
                placeholder={ADD_MEMBERS.project}
                value={projectData?.projectMembers}
                onChange={(e) => handleSelectChange("projectMembers", e)}
              >
                {children}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default CreateProject;
