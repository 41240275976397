import { Col, Select, Spin } from "antd";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  APP_MANAGER,
  DEFAULT_PAGE,
  DEVICE_EXECUTION_FARM,
  DEVICE_LOADING,
  LAMBDA_TEST,
  NONE,
  RECORD_10_PER_PAGE,
  SAUCELABS,
  SERIAL,
} from "../../../../../Constants";
import { checkNotUndefined, showDeviceTabCheckBox } from "../../../../../Util";
import { getUploadBuildOption } from "../../../../../actions/Recording";
import {
  changeExternalFarmDeviceListPageNumber,
  changeTestJobData,
  getExternalFarmDevice,
} from "../../../../../actions/TestJobActions";
import EmptyRecords from "../../../../CommonComponents/EmptyRecords/EmptyRecords";
import PaginationComponent from "../../../../CoreComponents/PaginationComponent/PaginationComponent";
import PoolManagement from "../../../../FarmManagement/PoolManagement/PoolManagement";
import DeviceDetailsCardV2 from "../../../../FarmManagementV2/DeviceDetailsCardV2/DeviceDetailsCardV2";
import SelectAwsDevicePools from "../../../../TestPlan/TestPlanDetail/AwsDevicePool/SelectAwsDevicePools";
import SelectAwsDeviceProject from "../../../../TestPlan/TestPlanDetail/AwsDevicePool/SelectAwsDeviceProject";

const { Option } = Select;

export const StyledSelect = styled(Select)`
  border-radius: 5px;
  border: 1px solid #e9e9e9 !important;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
`;

const DeviceTab = ({ projectId, orgId, testJobDetail, previousTestJobDetail, showDeviceView }) => {
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const {
    totalItems,
    totalPage,
    lambdaTestDeviceLoading,
    externalFarmDeviceCurrentPageNumber,
    externalFarmDeviceLoading,
    externalFarmDeviceList,
  } = useSelector((state) => state.TestJobReducer);
  const dispatch = useDispatch();
  const [lambdaTestDevicePageItems, setLambdaTestDevicePageItems] = useState("");

  useEffect(() => {
    dispatch(getUploadBuildOption(projectId));
    if (
      testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS ||
      testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
    ) {
      dispatch(changeExternalFarmDeviceListPageNumber(DEFAULT_PAGE));
      dispatch(
        getExternalFarmDevice(
          testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS
            ? DEVICE_EXECUTION_FARM.SAUCELABS
            : DEVICE_EXECUTION_FARM.LAMBDA_TEST
        )
      );
    }
  }, []);

  const currentExternalFarmTableData = useMemo(() => {
    const firstPageIndex = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE;
    const lastPageIndex = firstPageIndex + RECORD_10_PER_PAGE;

    return externalFarmDeviceList?.slice(firstPageIndex, lastPageIndex);
  }, [externalFarmDeviceCurrentPageNumber, externalFarmDeviceList]);

  const getExternalFarmTableMeta = () => {
    const lengthOfTable = currentExternalFarmTableData?.length;
    let from = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE + 1;
    let to = (externalFarmDeviceCurrentPageNumber - 1) * RECORD_10_PER_PAGE + lengthOfTable;
    setLambdaTestDevicePageItems(`${from}- ${to}`);
  };

  useEffect(() => {
    getExternalFarmTableMeta();
  }, [externalFarmDeviceCurrentPageNumber, currentExternalFarmTableData]);

  const onChangeDeviceSelection = (devices) => {
    const getDeviceList =
      {
        [DEVICE_EXECUTION_FARM.LAMBDA_TEST]: externalFarmDeviceList,
        [DEVICE_EXECUTION_FARM.SAUCELABS]: externalFarmDeviceList,
      }[testJobDetail.deviceServiceFarm] || getSearchDeviceList;
    const updatedDevices =
      getDeviceList
        ?.filter((deviceData) => {
          const deviceIdentifier = deviceData?.device?.targetUniqueId || deviceData?.id;

          return devices?.includes(deviceIdentifier) && deviceIdentifier;
        })
        ?.map((filteredData) => filteredData?.device?.targetUniqueId || filteredData?.id) || [];

    if (updatedDevices?.length < 2) {
      dispatch(changeTestJobData({ ...testJobDetail, devices: updatedDevices, executionType: SERIAL }));
    } else {
      dispatch(changeTestJobData({ ...testJobDetail, devices: updatedDevices }));
    }
  };
  const selectedDevices = testJobDetail?.devices;
  const previousSelectedDevices = previousTestJobDetail?.devices;
  const disableDeviceSelection = checkNotUndefined(testJobDetail?.id);

  return (
    <>
      <div className="m-10">
        <div className="modal-title">Execution Farm</div>
        <div className="mb-5 mt-10 font-size-12 font-weight-500">Execution Farm :</div>
        <StyledSelect
          key="ExecutionFarm"
          className="w-35"
          placeholder="Please Select Execution Farm"
          value={testJobDetail.deviceServiceFarm || undefined}
          onChange={(e) => {
            (e === DEVICE_EXECUTION_FARM.SAUCELABS || e === DEVICE_EXECUTION_FARM.LAMBDA_TEST) &&
              dispatch(
                getExternalFarmDevice(
                  e === DEVICE_EXECUTION_FARM.SAUCELABS
                    ? DEVICE_EXECUTION_FARM.SAUCELABS
                    : DEVICE_EXECUTION_FARM.LAMBDA_TEST
                )
              );
            e === DEVICE_EXECUTION_FARM.XPRESS && dispatch({ type: DEVICE_LOADING, response: { isLoading: true } });
            dispatch(
              changeTestJobData({
                ...testJobDetail,
                deviceServiceFarm: e,
                devices: [],
                deviceFarmIds: undefined,
                buildType: e === DEVICE_EXECUTION_FARM.XPRESS ? NONE : APP_MANAGER,
                buildDetail: {
                  id:
                    e !== DEVICE_EXECUTION_FARM.XPRESS &&
                    testJobDetail?.buildDetail &&
                    testJobDetail?.buildDetail?.id !== 0
                      ? 0
                      : testJobDetail?.buildDetail?.id || 0,
                },
              })
            );
          }}
        >
          {DEVICE_EXECUTION_FARM &&
            Object.keys(DEVICE_EXECUTION_FARM).map((key, index) => {
              return (
                <Option key={index + 1} value={DEVICE_EXECUTION_FARM[key]}>
                  {DEVICE_EXECUTION_FARM[key]}
                </Option>
              );
            })}
        </StyledSelect>
      </div>
      <Col span={24} className="m-10">
        {testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS && (
          <PoolManagement
            projectId={projectId}
            deviceTab={true}
            testJobDetail={testJobDetail}
            orgId={orgId}
            selectedDevices={selectedDevices}
            previousSelectedDevices={previousSelectedDevices}
            disableDeviceSelection={disableDeviceSelection}
            onChangeDeviceSelection={onChangeDeviceSelection}
            showDeviceView={showDeviceView}
          />
        )}
        {testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.AWS && (
          <div className="w-40">
            <div className="modal-title mb-10 mt-20">AWS Settings</div>
            <div className="mb-10">
              <SelectAwsDeviceProject required={true} testJobDetail={testJobDetail} onChange={changeTestJobData} />
            </div>
            <SelectAwsDevicePools required={true} testJobDetail={testJobDetail} onChange={changeTestJobData} />
          </div>
        )}
        {(testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST ||
          testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS) && (
          <Spin className="display-flex justify-center" spinning={externalFarmDeviceLoading}>
            <div className="display-flex justify-space-between align-items-center">
              <div className="mainHeaderText font-size-16 ml-12">
                {" "}
                {testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
                  ? "LambdaTest Device"
                  : "SauceLabs Device"}{" "}
              </div>
              {!_.isEmpty(currentExternalFarmTableData) && (
                <PaginationComponent
                  currentPageItems={lambdaTestDevicePageItems}
                  totalItems={totalItems}
                  currentPage={externalFarmDeviceCurrentPageNumber}
                  totalPage={totalPage}
                  callPaginationAPI={(pageNumber) => {
                    dispatch(changeExternalFarmDeviceListPageNumber(pageNumber));
                  }}
                />
              )}
            </div>
            <div
              className={
                _.isEmpty(currentExternalFarmTableData)
                  ? "display-flex flex-wrap justify-center"
                  : "display-flex flex-wrap"
              }
            >
              {!_.isEmpty(currentExternalFarmTableData)
                ? currentExternalFarmTableData?.map((item) => {
                    return (
                      <div className="deviceCard">
                        <DeviceDetailsCardV2
                          deviceData={item}
                          deviceTab={true}
                          showDeviceTabCheckBox={() => showDeviceTabCheckBox(item, selectedDevices)}
                          selectedDevices={selectedDevices}
                          previousSelectedDevices={previousSelectedDevices}
                          disableDeviceSelection={disableDeviceSelection}
                          onChangeDeviceSelection={onChangeDeviceSelection}
                          typeForDeviceModals={
                            testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
                              ? LAMBDA_TEST
                              : SAUCELABS
                          }
                        />
                      </div>
                    );
                  })
                : !lambdaTestDeviceLoading && <EmptyRecords description="No Device available" />}
            </div>
          </Spin>
        )}
      </Col>
    </>
  );
};

export default DeviceTab;
