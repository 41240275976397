import { default as React } from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { ELEMENT_ACTION, SELECTOR_TYPE, SELECTOR_TYPE_TEXT, STEP, VERIFICATIONS } from "../../../../../Constants";
import { LOAD_DEVICE_ICON } from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import { changeSelectorType } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepCustomElementDetailV2 from "../../RecTestStepCustomElementDetailV2/RecTestStepCustomElementDetailV2";
import RecTestStepElementSelectionDetailV2 from "../RecTestStepElementSelectionDetailV2/RecTestStepElementSelectionDetailV2";
import styles from "./RecTestStepSelectorV2.module.scss";

const RecTestStepSelectorV2 = (props) => {
  const dispatch = useDispatch();
  const { data, recElement, unSavedRecElementGuid, disabled, isPreviewRunning, isDeviceLoaded } = props;

  //Destructure of recElement object
  const { guid, id, elementSelectorType } = recElement ? recElement : "";
  return (
    <>
      <div className={styles["selectionText"]}>Selection Mode:</div>
      <Select
        disabled={unSavedRecElementGuid !== guid || ![0, undefined].includes(id) || disabled || isPreviewRunning}
        value={elementSelectorType}
        onChange={(e) => {
          dispatch(changeSelectorType(e, data.tag, guid));
        }}
        className={styles["selectMode"]}
      >
        <Select.Option value={SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN}>
          {SELECTOR_TYPE_TEXT.SELECT_ELEMENT_ON_SCREEN}
        </Select.Option>
        <Select.Option value={SELECTOR_TYPE.CUSTOM_ELEMENT}>{SELECTOR_TYPE_TEXT.CUSTOM_ELEMENT}</Select.Option>
        {[ELEMENT_ACTION.TAP, ELEMENT_ACTION.LONG_PRESS, VERIFICATIONS, STEP].includes(
          data?.recTestStepProperty?.action
        ) && (
          <Select.Option value={SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN}>
            {SELECTOR_TYPE_TEXT.SELECT_IMAGE_ON_SCREEN}
          </Select.Option>
        )}
      </Select>
      {elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN && (
        <RecTestStepElementSelectionDetailV2 {...props} />
      )}
      {elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT && <RecTestStepCustomElementDetailV2 {...props} />}
      {elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN &&
        ([0, undefined].includes(id) && !isDeviceLoaded && (
          <div className={styles["load-device-icon-and-text"]}>
            <span className={styles["load-device-text"]}>Load Device to select an area on the screen</span>
            <SvgLoader iconName={LOAD_DEVICE_ICON} />
          </div>
        ) 
        )}
    </>
  );
};

export default RecTestStepSelectorV2;
