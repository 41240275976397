import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Col, Popover, Spin, Tooltip } from "antd";
import _ from "lodash";
import { default as React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecTestScenario,
  duplicateRecTestScenario,
  rectTestScenarioPermissionAPI,
  setRecTestScenarioIdForPreview,
  setSelectedScenario,
} from "../../../actions/RecTestScenarioAction";
import { editScenario } from "../../../actions/TestPlanAction";
import { ICON_EDIT, ICON_BUILD_TEST_DATA } from "../../../CdnImagePath";
import {
  ACTION_NOT_VIEW,
  ACTION_VIEW,
  BUILD_TEST_SCENARIO,
  CUSTOM,
  defaultDateTimeFormat,
  PRIVATE,
  PUBLIC,
  TOOLTIP_BG_COLOR,
} from "../../../Constants";
import {
  cloneModal,
  deleteModal,
  displayUTCDateFormatWithToolTip,
  getAdmins,
  getPermissionType,
  removeSelectedIds,
  showPermissionTypeIcon,
} from "../../../Util";
import ActionComponent from "../../CoreComponents/ActionComponent/ActionComponent";
import PermissionDrawer from "../../CoreComponents/PermissionDrawer/PermissionDrawer";
import { Label } from "../../CoreComponents/StyledComponent/StyledComponents";
import TableComponent from "../../CoreComponents/TableComponent/TableComponent";
import PreviewRunDrawer from "../PreviewRunDrawer";
import {
  setApplicationAssigneeDetail,
  setDeviceAssigneeDetail,
  setTestDataSetAssigneeDetail,
} from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { BUILD_TEST_DATA_SET } from "../../../Constants/TooltipConstants";
import SvgLoader from "../../../Util/SvgLoader";
import { APPLICATION_STRING_BLACK_ICON } from "../../../Constants/SvgConstants";
import styles from "./RecTestScenarioList.module.scss";

const RecTestScenarioList = (props) => {
  const { recTestScenarioList, fetchingRecTestScenarioList } = useSelector((state) => state.RecTestScenarioReducer);
  const [openPreviewRunDrawer, setOpenPreviewRunDrawer] = useState(false);
  const [recTestScenarioName, setRecTestScenarioName] = useState(null);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionScenario, setPermissionScenario] = useState(null);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  const { projectId, selectAllScenario, setSelectAllScenario, navigateToEditBuildScenario, isScenarioLoadModel } =
    props;
  const dispatch = useDispatch();

  const showCheckBoxInTableHeader =
    _.isEmpty(selectAllScenario) &&
    !isScenarioLoadModel &&
    !_.isEmpty(recTestScenarioList) &&
    recTestScenarioList?.some((i) => i?.allowDelete === ACTION_VIEW);

  const columns = [
    {
      title: (
        <div className={!showCheckBoxInTableHeader && "table_Details"}>
          {showCheckBoxInTableHeader && (
            <span>
              <Checkbox
                className={styles.checkBox}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllScenario([
                      ...selectAllScenario,
                      ...(
                        recTestScenarioList &&
                        recTestScenarioList?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllScenario.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllScenario(removeSelectedIds(selectAllScenario, recTestScenarioList));
                  }
                }}
              />
            </span>
          )}
          Scenario Info
        </div>
      ),
      dataIndex: "scenarioInfo",
    },
    {
      title: "Step Details",
      dataIndex: "stepDetails",
    },
    {
      title: "Added To Test Plans",
      dataIndex: "addedToTestPlans",
    },
    {
      title: "Actions",
      dataIndex: "action",
      hidden: isScenarioLoadModel,
      align: "center",
    },
  ]?.filter((item) => !item.hidden);

  const rectTestScenarioSelectionCheckboxType = (row) => {
    if (row?.allowDelete === ACTION_VIEW) {
      return (
        <div>
          {!isScenarioLoadModel && (
            <Col className={styles.checkBox}>
              <Checkbox
                checked={selectAllScenario && selectAllScenario?.includes(row?.id)}
                onChange={(e) => {
                  e.target.checked
                    ? setSelectAllScenario([...selectAllScenario, row?.id])
                    : setSelectAllScenario(selectAllScenario && selectAllScenario?.filter((i) => i !== row?.id));
                }}
              />
            </Col>
          )}
        </div>
      );
    }
  };

  const showListOfTestPlan = (testPlan, index) => {
    if (testPlan?.length > 1) {
      return (
        <>
          <span key={index}>
            {testPlan[0]}
            <Popover
              key={"Popover-" + index}
              content={testPlan?.slice(1)?.map((i, index) => (
                <div className={` ${styles.popoverContents} ${testPlan?.length - 2 !== index && styles.borderBottom}`}>
                  <span className={styles.data}>{i}</span>
                </div>
              ))}
              title="Test Plan Names"
            >
              <span className={styles.popoverDetails}>
                {testPlan?.slice(1).length === 1
                  ? `+${testPlan?.slice(1).length} other`
                  : `+${testPlan?.slice(1).length} others`}
              </span>
            </Popover>
          </span>
        </>
      );
    } else {
      return <span key={index}>{!_.isEmpty(testPlan) ? testPlan[0] : "N/A"}</span>;
    }
  };

  useEffect(() => {
    if (permissionScenario?.recTestScenarioRights?.data) {
      setMembers([...permissionScenario.recTestScenarioRights.data]);
    }
  }, [permissionScenario]);

  // reset privacy
  const setPermissionData = () => {
    if (permissionScenario?.isPrivate && !_.isEmpty(permissionScenario?.recTestScenarioRights?.data)) {
      setPreviousPermission(permissionScenario?.recTestScenarioRights?.data);
      setMembers([...permissionScenario?.recTestScenarioRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  // save privacy
  const savePermission = (Value, data, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      recTestScenairoId: data?.id,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !_.isEmpty(result) ? result : [],
    };
    dispatch(rectTestScenarioPermissionAPI(obj, projectId));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const handleAction = (row) => {
    let arr = [];
    arr.push({
      title: "Device Strings",
      name: "deviceStrings",
    });
    arr.push({
      title: "Duplicate",
      name: "duplicate",
    });
    arr.push({
      title: "Preview History",
      name: "previewHistory",
    });

    if (row.allowDelete === ACTION_VIEW) {
      arr.push({
        title: "Delete",
        name: "delete",
      });
    }
    if (loggedInUserDetails?.userEmail === row?.createdByEmail || row?.allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdByEmail) ||
            (row?.isPrivate === 1 &&
              !_.isEmpty(row?.recTestScenarioRights?.data) &&
              loggedInUserDetails?.userEmail === row?.createdByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  const handleActionChange = (actionName, data) => {
    setPermissionScenario(() => ({ ...data }));
    switch (actionName) {
      case "deviceStrings":
        dispatch(setDeviceAssigneeDetail({ id: data?.id, name: data?.name }));
        navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
        dispatch(editScenario(data, projectId));
        break;
      case "duplicate":
        cloneModal(data?.name, `scenario`, () => {
          dispatch(
            duplicateRecTestScenario({
              recTestScenarioId: data?.id,
              projectId: projectId,
            })
          );
        });
        break;
      case "previewHistory":
        setOpenPreviewRunDrawer(true);
        dispatch(setRecTestScenarioIdForPreview(data?.id));
        setRecTestScenarioName(data?.name);
        break;
      case "delete":
        deleteModal(
          data?.name
            ? `Are you sure you want to delete scenario "${data?.name}" ?`
            : "Are you sure you want to delete selected scenarios ?",
          () => {
            dispatch(
              deleteRecTestScenario(
                {
                  scenarioId: data?.id ? [data?.id] : selectAllScenario,
                  projectId: projectId,
                  isDelete: 1,
                },
                setSelectAllScenario
              )
            );
          }
        );
        break;
      case PRIVATE:
        savePermission(PRIVATE, data);
        break;
      case PUBLIC:
        savePermission(PUBLIC, data);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const recTestScenarioDetails =
    recTestScenarioList &&
    recTestScenarioList?.map((row, index) => ({
      key: row?.id,
      scenarioInfo: (
        <div className={`display__flex ${row.allowDelete === ACTION_NOT_VIEW && "table_Details"}`}>
          {rectTestScenarioSelectionCheckboxType(row)}
          <div>
            <div className="display__flex">
              <span className={styles.permissionTooltip}>
                <Tooltip
                  title={getPermissionType(row?.isPrivate, row?.recTestScenarioRights?.data)}
                  color={TOOLTIP_BG_COLOR}
                >
                  <FontAwesomeIcon
                    icon={showPermissionTypeIcon(getPermissionType(row?.isPrivate, row?.recTestScenarioRights?.data))}
                  />
                </Tooltip>
              </span>
              <div className={styles.stepName}>{row?.name}</div>
            </div>

            <div>
              <span className={styles.label}> Created By: </span> {row?.createdBy || "NA"} On{" "}
              {row?.createdOn ? displayUTCDateFormatWithToolTip(row?.createdOn, defaultDateTimeFormat) : "NA"}
            </div>
            <div>
              <span className={styles.label}>Last Updated By: </span> {row?.lastUpdatedBy || "NA"} On{" "}
              {row?.lastUpdatedOn ? displayUTCDateFormatWithToolTip(row?.lastUpdatedOn, defaultDateTimeFormat) : "NA"}
            </div>
            <div className={styles.recTestStepRights}>
              <span className={styles.label}>Admins: </span>{" "}
              <div className={styles.recTestStepRights}>
                {row?.recTestScenarioRights &&
                  getAdmins([
                    { name: row?.createdBy },
                    ...row?.recTestScenarioRights?.data?.filter(
                      (i) => i?.isAdmin && i?.shouldAddOrRemove === 1 && i?.name !== row?.createdBy
                    ),
                  ])}
              </div>
            </div>
          </div>
          {row?.stepsWithTestData && (
            <div>
              <span className={styles.label}>Steps with TestData:</span>
            </div>
          )}
          {row?.currentAndTotalTestDataProfile && (
            <div>
              <span className={styles.label}>Current TestData Profile & Total TestData Keys in Profile:</span>
            </div>
          )}
        </div>
      ),

      stepDetails: (
        <>
          <div>
            <span className={styles.label}>Total Steps: </span> {row?.totalSteps}
          </div>
          <div>
            <span className={styles.label}>Screenshots: </span> {row?.stepsWithScreenShot}{" "}
          </div>
        </>
      ),
      addedToTestPlans: (
        <div key={index}>
          <span className={styles.label}>Total: </span> {row?.testPlan?.length}
          <div>{!_.isEmpty(row?.testPlan) && showListOfTestPlan(row?.testPlan, row?.id)}</div>
        </div>
      ),
      action: !isScenarioLoadModel && (
        <div className={styles.ActionIcon}>
          {row?.allowEdit === ACTION_VIEW && (
            <Tooltip color={TOOLTIP_BG_COLOR} title="Edit">
              <img
                src={ICON_EDIT}
                alt="Edit"
                className={styles.editIcon}
                onClick={() => {
                  navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                  dispatch(editScenario(row, projectId));
                }}
              />
            </Tooltip>
          )}
          <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR}>
            <img
              src={ICON_BUILD_TEST_DATA}
              alt="BuildDataIcon"
              className={styles.icons}
              onClick={() => {
                navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                dispatch(editScenario(row, projectId));
                dispatch(setTestDataSetAssigneeDetail(row));
              }}
            />
          </Tooltip>
          <Tooltip title={"Application Strings"} color={TOOLTIP_BG_COLOR}>
            <div className={styles.icons}>
              <SvgLoader
                iconName={APPLICATION_STRING_BLACK_ICON}
                handleClick={() => {
                  dispatch(setApplicationAssigneeDetail({ id: row?.id, name: row?.name }));
                  navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
                  dispatch(editScenario(row, projectId));
                }}
              />
            </div>
          </Tooltip>
          {/* {(row.allowDelete === ACTION_VIEW || row.allowAdmin === ACTION_VIEW) && ( */}
          <ActionComponent actions={handleAction(row)} data={row} handleActionChange={handleActionChange} />
          {/* )} */}
        </div>
      ),
    }));

  return (
    <Spin spinning={fetchingRecTestScenarioList} tip="Loading" className={styles.spinnerCenter}>
      <div className={styles.table}>
        <TableComponent
          selected={selectAllScenario}
          columns={columns}
          data={recTestScenarioDetails}
          handleSelectRow={(selectedRow) => ({
            onClick: () => {
              let data = recTestScenarioList && recTestScenarioList?.find((i) => i?.id === selectedRow?.key);
              dispatch(
                setSelectedScenario({
                  id: data.id,
                  name: data.name,
                  tags: data.tags,
                  testRailTestCaseId: data.testRailTestCaseId,
                  testRailTestSuiteId: data.testRailTestSuiteId,
                  platform: data.platform,
                })
              );
            },
          })}
          selectedRowId={[props.selectedScenarioId]}
        />
      </div>
      <PreviewRunDrawer
        openPreviewRunDrawer={openPreviewRunDrawer}
        setOpenPreviewRunDrawer={setOpenPreviewRunDrawer}
        recTestScenarioName={recTestScenarioName}
        projectId={projectId}
      />
      <PermissionDrawer
        openPermissionDrawer={openPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        permissionData={permissionScenario}
        savePermission={savePermission}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        setPermissionData={setPermissionData}
        ownerEmailId={permissionScenario?.createdByEmail}
        notShowExecutionCheck={true}
        drawerTitle={"Test Scenario"}
      />
    </Spin>
  );
};

export default RecTestScenarioList;
