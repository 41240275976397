import { library } from "@fortawesome/fontawesome-svg-core";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Collapse, Tabs, Typography } from "antd";
import { default as React, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import * as commonActions from "../../actions/CommonActions";
import * as actions from "../../actions/ProjectsAction";
import { getTeamAndProjectName } from "../../actions/ProjectsAction";
import { setScheduleTestPlan } from "../../actions/TestPlanAction";
import {
  APP_MANAGER,
  EXECUTION_JOB,
  POOL_MANAGEMENTS,
  REMOTE_DEVICE,
  REPORT_MANAGER,
  RESET_STATE,
  SERVER_MANAGEMENT,
  SETTINGS,
  TEST_DATA_SET_MANAGEMENT,
  TEST_PLAN,
  xpressComponentType,
} from "../../Constants";
import BuildManager from "../BuildManagement/BuildManager";
import DeviceFarmView from "../DeviceFarmList/DeviceFarmView";
import ExecutionManager from "../ExecutionManager/ExecutionManager";
import HeaderComponent from "../Header/Header";
import ProjectSettings from "../ProjectSettings/ProjectSettings";
import ReportManager from "../ReportManager/ReportManager";
import ServerManager from "../ServerManagement/ServerManager";
import TestDataSetManagement from "../TestDataSetManagement/TestDataSetManagement";
import TestPlanManager from "../TestPlan/TestPlanManager/TestPlanManager";
import PoolManagement from "../FarmManagement/PoolManagement/PoolManagement";
const { TabPane } = Tabs;

const { Title } = Typography;

library.add(faCog);
const { Panel } = Collapse;
export const ProjectRow = styled(Card)`
  background: white;
  border: none;
  overflow:hidden;
  height:120px;
  text-align: center;
  max-width:15vw
  min-width:10vw;
  min-height:10vh;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  border: 0;
  margin-bottom: 1.5rem;
  border-radius: 10px;
  padding-top: 20px;
  font-size:30px;
  margin-top:10px;
  :hover {
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, .15);
  }
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .15);
  
  @media (max-width: 550px){
    max-height:180px;
    max-width:250px;
    font-size:18px;
  }
  @media (max-width: 320px){
    max-height:150px;
    font-size:14px;
  }
  
  .ant-card-body{
    padding: 0px;
  }
  
  cursor:pointer;
    `;
export const ProjectRowAdd = styled(Card)`
  cursor: pointer;
  background: white;
  .ant-card-head {
    border: 0;
  }
  overflow: hidden;
  height: 120px;
  text-align: center;
  max-width: 15vw;
  min-width: 10vw;
  min-height: 10vh;
  word-wrap: break-word;
  background-color: #fff;
  overflow: "hidden";
  :hover {
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  }
  border: 0;
  margin-bottom: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 0.15);

  padding-top: 15px;
  margin-top: 10px;
  background: transparent;
  border-radius: 10px;
  @media (max-width: 550px) {
    max-height: 180px;
    max-width: 250px;
    font-size: 18px;
  }
  @media (max-width: 320px) {
    max-height: 150px;
    font-size: 14px;
  }
  .ant-card-body {
    padding: 0px;
  }
`;
export const ProjectIcons = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 10px;
  right: 8px !important;
  height: 18px !important;
  width: 18px !important;
  opacity: 0.2;
  :hover {
    opacity: 0.8;
  }
`;

export const ProjectSettingIcon = styled.span`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  padding: 1px;
  text-align: center;
  font-size: 24px;
  top: 55px;
  position: absolute;
  border: 0px;
  right: 5px;
  color: ${(props) => (props.selected ? "#1a90ff" : "")};
`;
export const SelectedProjectSettingOption = styled.span`
  padding: 1px;
  font-size: 12px;
  top: 64px;
  position: absolute;
  border: 0px;
  right: 45px;
  color: #1a90ff;
`;
export const LatestRunResults = styled.div``;
export const MyProjects = styled.div``;

export const StyledProjectCollapse = styled(Collapse)`
  background: none;
  border: 0px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  .ant-collapse-content {
    border: 0px;
    background: none;
  }
  .ant-collapse-content-box {
    border: 0px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    padding: 0px 30px;
    .ant-list {
      .ant-list-item {
        border: 0;
        padding: 8px 0;
        .ant-avatar {
          margin-right: 16px;
        }
      }
    }
  }

  .ant-collapse-item {
    border: 0px;

    .ant-collapse-header {
      color: black !important;
    }
  }
`;

export const StyledPanel = styled(Panel)`
  colour: black;
  text-align: left;
  padding: 15px 30px 15px 15px;
  font-size: 14px;
  font-weight: 600;
`;

export const titleName = styled(Title)`
  font-size: 14px !important;
  display: block;
`;

export const InnerDiv = styled.div`
  display: block;
  background: #f7f8fa;
  border-radius: 5px;
`;

export const StyledDiv = styled.div`
  margin: 1% 15%;
  background: white;
  padding: 20px;
  border: 0px;
  margin-bottom: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0px;
  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  background-clip: border-box;
  flex: 1 1 auto;
  /* padding: 1rem; */
  border-radius: 10px;
`;

export const DropDownButton = styled(Button)`
  width: 35px;
  height: 30px;
`;

export const MyProject = (props) => {
  const dispatch = useDispatch();
  const { isForModal, currentMenuOptionsSelected } = props;
  const { teamNameOfProject, getProjectByOrgId, projectDetails, teamId } = useSelector(
    (state) => state.ProjectsReducer
  );
  const [handleSession, setHandleSession] = useState(false);
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { deviceLoaded } = useSelector((state) => state.RecordingReducer);
  const userDetails = useSelector((state) => state.HomeReducer);
  const [selectProjectName, setSelectProjectName] = useState();
  const projectId = props.match.params && props.match.params.projectID;
  const orgId = props.match.params && props.match.params.organizationId;
  const projectDetailScreen = props.match.params && props.match.params.projectDetailScreen;
  const runNumber = props?.match?.params?.runNumber;
  useEffect(() => {
    if (projectId) {
      dispatch(getTeamAndProjectName({ projectId: parseInt(projectId) }));
    }
    if (props.route && props.route.componentId) {
      props.commonActions.getChildComponentByTypeAndParentId({
        componentType: xpressComponentType.rights,
        parentId: props.route.componentId,
      });
    }
  }, [selectProjectName]);

  useEffect(() => {
    dispatch({ type: RESET_STATE });
    if (teamId != null) {
      dispatch(actions.myProjectsFromTeamId(teamId));
    }
  }, []);

  useEffect(() => {
    if (teamId !== null && userDetails?.loggedInUserDetails?.userID !== null) {
      dispatch(actions.getProjectsByUserIdAndOrgId(orgId, userDetails.loggedInUserDetails.userID));
    }
  }, [userDetails, teamId]);

  useEffect(() => {
    if (projectDetails) {
      setSelectProjectName(projectDetails.projectName);
    }
  }, []);

  let editRightsForDeviceNote =
    props.CommonReducer.childComponentList &&
    props.CommonReducer.childComponentList.filter((item) => item.name === "Device_Notes_Edit").length > 0;
  let setDefaultsAppRightsForDevice =
    props.CommonReducer.childComponentList &&
    props.CommonReducer.childComponentList.filter((item) => item.name === "DeviceDefaultAppSet").length > 0;

  useEffect(() => {
    if (currentMenuOptionsSelected !== EXECUTION_JOB) {
      dispatch(setScheduleTestPlan(null));
    }
  }, [currentMenuOptionsSelected]);

  return (
    <React.Fragment>
      {!isForModal ? (
        <HeaderComponent
          organizationId={orgId}
          projectId={projectId}
          showHumbergerMenu={true}
          setSelectProjectName={setSelectProjectName}
          getProjectByOrgId={getProjectByOrgId}
          teamNameOfProject={teamNameOfProject}
          projectDetails={projectDetails}
          currentMenuOptionsSelected={projectDetailScreen}
          disableOptions={isPreviewRunning}
          runNumber={runNumber}
        />
      ) : (
        ""
      )}

      {/* {currentMenuOptionsSelected === TEST_SCENARIOS && (
        <RecTestScenarioTab
          isForModal={true}
          isProjectModal={true}
          projectId={projectId}
          organizationId={orgId}
          childComponentList={props.CommonReducer.childComponentList}
          handleSession={handleSession}
          disableOptions={isPreviewRunning}
          currentMenuOptionsSelected={currentMenuOptionsSelected}
          stepType={TEST_SCENARIOS}
        />
      )} */}
      {currentMenuOptionsSelected === TEST_PLAN && <TestPlanManager projectId={projectId} />}
      {currentMenuOptionsSelected === REPORT_MANAGER && <ReportManager projectId={projectId} orgId={orgId} />}
      {currentMenuOptionsSelected === EXECUTION_JOB && (
        <ExecutionManager projectId={projectId} orgId={orgId} currentMenuOptionsSelected={currentMenuOptionsSelected} />
      )}
      {currentMenuOptionsSelected === APP_MANAGER && <BuildManager projectId={projectId} orgId={orgId} />}
      {currentMenuOptionsSelected === TEST_DATA_SET_MANAGEMENT && (
        <TestDataSetManagement projectId={projectId} orgId={orgId} />
      )}
      {currentMenuOptionsSelected === REMOTE_DEVICE && (
        <DeviceFarmView
          projectId={projectId}
          orgId={orgId}
          setDefaultsAppRightsForDevice={setDefaultsAppRightsForDevice}
          editRightsForDeviceNote={editRightsForDeviceNote}
          childComponentList={props.CommonReducer.childComponentList}
          deviceFarmList={props.ServerListReducer.deviceFarmList}
        />
      )}
      {currentMenuOptionsSelected === POOL_MANAGEMENTS && <PoolManagement projectId={projectId} orgId={orgId} />}
      {currentMenuOptionsSelected === SETTINGS && <ProjectSettings projectId={projectId} orgId={orgId} />}
      {currentMenuOptionsSelected === SERVER_MANAGEMENT && <ServerManager projectId={projectId} orgId={orgId} />}
    </React.Fragment>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    HomeReducer: state.HomeReducer,
    CommonReducer: state.CommonReducer,
    ServerListReducer: state.ServerListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProject);
