import { Checkbox, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { changeAppendRandomText } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { TOOLTIP_BG_COLOR } from "../../../../../Constants";
import styles from "./EnterTextV2.module.scss";

const AppendRandomTextV2 = (props) => {
  const dispatch = useDispatch();
  const { data, disabled } = props;
  const { appendRandomText } = data.recTestStepProperty;

  return (
    <Tooltip color={TOOLTIP_BG_COLOR} title="Append Random Text">
      <div className={styles["checkBox_And_Text"]}>
        <Checkbox
          className={"selection-checkBox"}
          disabled={disabled}
          onChange={(e) => {
            dispatch(changeAppendRandomText(e.target.checked, data.tag));
          }}
          checked={appendRandomText}
        />
        <span className={styles["input_Or_Select_Label"]}> Append Random Text</span>
      </div>
    </Tooltip>
  );
};
export default AppendRandomTextV2;
