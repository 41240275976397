import React from "react";
import _ from "lodash";
import RecTestStepOperation from "../RecTestStepOperation/RecTestStepOperation";
import { DISABLE_ICON_ADD_SUB_STEP, DISABLE_ICON_IMPORT_STEP } from "../../../../../CdnImagePath";
import { STEP, TEST_SCENARIOS } from "../../../../../Constants";
import { useDispatch, useSelector } from "react-redux";
import {
  showImportRecTestStepModal,
  addRecTestStep,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./ActionsComponents.module.scss";
import {
  ADD_SUB_STEP,
  IMPORT_SUB_STEP_IN_TEST_BLOCK,
  IMPORT_SUB_STEP_IN_TEST_SCENARIO,
} from "../../../../../Constants/TooltipConstants";
import { ADD_SUB_STEP_ICON_ON_HOVER, ICON_ADD_SUB_STEP, ICON_IMPORT_STEP } from "../../../../../Constants/SvgConstants";

const RecTestStepSubSteps = (props) => {
  const { disabled, projectId, stepType, recTestStep, recTestStepOnHover } = props;
  const dispatch = useDispatch();

  const actionStep =
    recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
    (_.isEmpty(recTestStep?.recTestSteps) ? true : false);

  return (
    <div className={`${styles.subHeader} ${!recTestStepOnHover && styles.actionIconInHeader}`}>
      <RecTestStepOperation
        name="Sub-step"
        handleClick={() => !disabled && dispatch(addRecTestStep(recTestStep))}
        disabled={disabled}
        tooltipTitle={ADD_SUB_STEP}
        imageName={recTestStepOnHover ? ADD_SUB_STEP_ICON_ON_HOVER : ICON_ADD_SUB_STEP}
        recTestStepOnHover={recTestStepOnHover}
      />
      {!recTestStepOnHover && (
        <RecTestStepOperation
          name="Import"
          handleClick={() =>
            !disabled &&
            dispatch(showImportRecTestStepModal(STEP, projectId, recTestStep, actionStep, recTestStep?.recTestSteps))
          }
          disabled={disabled}
          tooltipTitle={stepType === TEST_SCENARIOS ? IMPORT_SUB_STEP_IN_TEST_SCENARIO : IMPORT_SUB_STEP_IN_TEST_BLOCK}
          imageName={ICON_IMPORT_STEP}
        />
      )}
    </div>
  );
};

export default RecTestStepSubSteps;
