import React, { useEffect, useState } from "react";
import { Col, Row, Tabs } from "antd";
import { AWS_DEVICE_FARM, GENERAL, S3_CONFIGURATION, TEST_RAILS_CONFIG, SAUCELABS } from "../../Constants";
import GeneralTab from "./GeneralTab";
import S3Configuration from "./S3Configuration";
import { useDispatch } from "react-redux";
import { getProjectDetailsForSetting } from "../../actions/ProjectSettingsActions";
import TestRailsConfig from "./TestRailsConfig";
import AWSDeviceFarmManager from "./AWSDeviceFarm/AWSDeviceFarmManager";
import SauceLabs from "./SauceLabs";

const { TabPane } = Tabs;

const ProjectSettings = (props) => {
  const dispatch = useDispatch();
  const { projectId, orgId } = props;
  const [selectedTab, setSelectedTab] = useState(GENERAL);

  useEffect(() => {
    dispatch(getProjectDetailsForSetting(projectId));
  }, [projectId]);

  return (
      <Col span={24}>
        <Tabs className="m-20" defaultActiveKey={GENERAL} activeKey={selectedTab} onChange={(e) => setSelectedTab(e)}>
          <TabPane  tab={GENERAL} key={GENERAL}>
            <GeneralTab projectId={projectId} orgId={orgId} selectedTab={selectedTab}/>
          </TabPane>
          <TabPane tab={S3_CONFIGURATION} key={S3_CONFIGURATION}>
            <S3Configuration projectId={projectId} orgId={orgId} selectedTab={selectedTab} />
          </TabPane>
          <TabPane tab={AWS_DEVICE_FARM} key={AWS_DEVICE_FARM}>
            <AWSDeviceFarmManager projectId={projectId} orgId={orgId} selectedTab={selectedTab} />
          </TabPane>
          <TabPane tab={TEST_RAILS_CONFIG} key={TEST_RAILS_CONFIG}>
            <TestRailsConfig projectId={projectId} orgId={orgId} selectedTab={selectedTab} />
          </TabPane>
          <TabPane tab={SAUCELABS} key={SAUCELABS}>
            <SauceLabs projectId={projectId} orgId={orgId} selectedTab={selectedTab} />
          </TabPane>
        </Tabs>
      </Col>
  );
};

export default ProjectSettings;
