import { Checkbox, Col, Input, Row, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  changeCaptureScreenShotDelayTime,
  changeInvertResult,
  changeIsCaptureScreenshot,
  changeIsLoopStep,
  changePauseTime,
  changeRecTestStepLoopCount,
  selectOutputTestData,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  CAPTURE_SCREENSHOT_AFTER,
  DELAY_EXECUTION_ACTION,
  POST_OPERATION_SETTINGS,
  REPEAT_EXECUTION_STEP,
  SELECTOR_TYPE,
  SELECT_TEXT_TO_TEST_DATA,
  STEP,
  TOOLTIP_BG_COLOR,
  VERIFICATIONS,
} from "../../../../Constants";
import {
  hasNotAllowedNegativeValue,
  isNotNullAndNotEmptyAndNotUndefined,
  isStepHavePrecondition,
} from "../../../../Util";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import ManageTestData from "../../SingleColumnView/ManageTestData";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import SvgLoader from "../../../../Util/SvgLoader";
import { BLUE_COLOR, DISABLE_ICON_COLOR, SELECT_TEST_DATA_ICON } from "../../../../Constants/SvgConstants";
import styles from "./RecTestStepPostActionSettings.module.scss";

export const StyledPercentageInput = styled(Input)`
  margin-right: 10px;
`;
export const Label = styled.span`
  font-weight: 500;
  font-size: 12px;
`;

export const StyledSpan = styled.span`
  align-items: center;
  display: flex;
  font-size: 12px;
  font-weight: 400;
  color: #636467;
`;

const RecTestStepPostActionSettings = (props) => {
  const { data, disabled, isSkipStep } = props;
  const { unSavedRecStepTag, testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const postActionMapping = {
    sendKeys: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    tap: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    longPress: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    tapByIndex: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    BACK: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    HOME: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    ENTER: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    clear: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    launchApp: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    swipeOverElement: [
      SELECT_TEXT_TO_TEST_DATA,
      CAPTURE_SCREENSHOT_AFTER,
      DELAY_EXECUTION_ACTION,
      REPEAT_EXECUTION_STEP,
    ],
    Swipe: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    pause: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    captureScreenshot: [DELAY_EXECUTION_ACTION],
    clearText: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    insertValueToOutputTestData: [CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    setAppLocale: [],
    verifications: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION],
    actions: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
    step: [SELECT_TEXT_TO_TEST_DATA, CAPTURE_SCREENSHOT_AFTER, DELAY_EXECUTION_ACTION, REPEAT_EXECUTION_STEP],
  };
  const disableRemoveSelectVariable =
    disabled ||
    data?.recTestStepProperty?.outputTestData === null ||
    (data?.recTestStepProperty?.outputTestData?.id === undefined &&
      data?.recTestStepProperty?.outputTestDataId === null);

  const disableSelectTestDataIcon = isSkipStep || disabled;

  const openTestDataDrawer = () => {
    dispatch(visibleTestDataDrawer(POST_OPERATION_SETTINGS, data?.tag));
    dispatch(selectRecTestSteps(data));
  };
  const disabledCaptureScreenshot =
    disabled ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag) ||
    data?.recTestStepProperty?.isCaptureScreenshot === 0 ||
    isSkipStep;

  const disabledDelayExecution =
    disabled ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag) ||
    isSkipStep;

  const disabledRepeatExecution =
    disabled ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag) ||
    data?.recTestStepProperty?.isLoopStep === 0 ||
    isSkipStep;

  return (
    <div className={styles.recTestStepPostActions}>
      <span className={styles.postActionTitle}>{`post ${
        data?.recTestStepProperty?.action === VERIFICATIONS ? `verification` : `action`
      } operations`}</span>
      {((data?.recTestStepProperty &&
        data?.recTestStepProperty?.recElements.length > 0 &&
        data?.recTestStepProperty?.recElements.filter(
          (item) => item.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN
        ).length !== data?.recTestStepProperty?.recElements.length &&
        postActionMapping[data?.recTestStepProperty?.action].includes(SELECT_TEXT_TO_TEST_DATA)) ||
        (data?.recTestStepProperty?.action === STEP &&
          (data?.recTestStepProperty?.isStepHasPrecondition === 1 || data?.recTestStepProperty?.isLoopStep === 1))) && (
        <>
          <Row>
            <Col xs={24} className={styles.elementTestData}>
              <span className={styles.text}>Save Element text to:</span>
              <div className={styles.selectVariable}>
                <Select
                  placeholder="Select a Test Data Key"
                  className={styles.styledSelect}
                  disabled={disabled || isSkipStep}
                  value={
                    data.recTestStepProperty?.outputTestData
                      ? data.recTestStepProperty?.outputTestData.id
                      : data.recTestStepProperty?.outputTestDataId
                  }
                  onChange={(value) => dispatch(selectOutputTestData(data?.tag, value))}>
                  {testDataList &&
                    testDataList.length > 0 &&
                    testDataList.map((v, k) => {
                      return (
                        <Select.Option key={v.id} value={v.id}>
                          {v.name + (v.value ? " (" + v.value + ")" : "")}
                        </Select.Option>
                      );
                    })}
                </Select>
                <div
                  className={`${styles.removeSelectVariable} ${disableRemoveSelectVariable && styles.disableButton}`}
                  onClick={(e) => !disableRemoveSelectVariable && dispatch(selectOutputTestData(data?.tag, undefined))}>
                  Remove
                </div>
                <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                  <div
                    className={`${styles.selectTestData} ${disableSelectTestDataIcon && styles.disableSelectTestData}`}
                    onClick={() => {
                      !disableSelectTestDataIcon && openTestDataDrawer();
                    }}>
                    <SvgLoader
                      iconName={SELECT_TEST_DATA_ICON}
                      disabled={disableSelectTestDataIcon}
                      iconColor={disableSelectTestDataIcon ? DISABLE_ICON_COLOR : BLUE_COLOR}
                    />
                  </div>
                </Tooltip>
              </div>
            </Col>
          </Row>
        </>
      )}

      <div
        className={`${styles.postActionSettings} ${
          data?.recTestStepProperty &&
          postActionMapping[data?.recTestStepProperty?.action].includes(REPEAT_EXECUTION_STEP) &&
          styles.repeatStep
        }`}>
        {data?.recTestStepProperty &&
          postActionMapping[data?.recTestStepProperty?.action].includes(CAPTURE_SCREENSHOT_AFTER) && (
            <div className={styles.actions}>
              <div>
                <Checkbox
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
                  }
                  checked={data?.recTestStepProperty?.isCaptureScreenshot === 1 ? true : false}
                  onChange={(e) => dispatch(changeIsCaptureScreenshot(e.target.checked ? 1 : 0, data?.tag))}
                />
                <span className={styles.title}>Capture Screenshot after</span>
              </div>
              <div className={`${styles.inputOrLabel} ${disabledCaptureScreenshot && styles.disableInputOrLabel}`}>
                <Input
                  disabled={disabledCaptureScreenshot}
                  value={data?.recTestStepProperty?.captureScreenShotDelayTime}
                  type="number"
                  min={0}
                  onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                  onChange={(e) => dispatch(changeCaptureScreenShotDelayTime(e.target.value, data?.tag))}
                  placeholder="Pause Before Taking Screenshot"
                  className={styles.styledInput}
                />
                <span className={styles.title}>msecs</span>
              </div>
            </div>
          )}
        <div className={styles.actions}>
          <span className={styles.title}>Delay Execution for</span>
          <div className={`${styles.inputOrLabel} ${disabledDelayExecution && styles.disableInputOrLabel}`}>
            <Input
              disabled={disabledDelayExecution}
              value={data?.recTestStepProperty?.pauseTime}
              type="number"
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              onChange={(e) => dispatch(changePauseTime(e.target.value, data?.tag))}
              placeholder="Pause after performing action"
              className={styles.styledInput}
            />
            <span className={styles.title}>msecs</span>
          </div>
        </div>
        {isStepHavePrecondition(data) ? (
          <div className={`${styles.actions} ${styles.invertResult}`}>
            <Checkbox
              disabled={
                disabled ||
                isSkipStep ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
              }
              checked={data?.recTestStepProperty?.invertResult === 1}
              onChange={(e) => dispatch(changeInvertResult(e.target.checked ? 1 : 0, data?.tag))}>
              Invert Result
            </Checkbox>
          </div>
        ) : (
          ""
        )}
        {data?.recTestStepProperty &&
          postActionMapping[data?.recTestStepProperty?.action].includes(REPEAT_EXECUTION_STEP) &&
          data.recTestStepProperty?.isStepHasPrecondition === 0 && (
            <div className={styles.actions}>
              <div>
                <Checkbox
                  disabled={
                    disabled ||
                    isSkipStep ||
                    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
                  }
                  checked={data?.recTestStepProperty?.isLoopStep === 1 ? true : false}
                  onChange={(e) => dispatch(changeIsLoopStep(e.target.checked, data?.tag, data))}
                />
                <span className={styles.title}>Repeat Execution of this step</span>
              </div>
              <div className={`${styles.inputOrLabel} ${disabledRepeatExecution && styles.disableInputOrLabel}`}>
                <Input
                  disabled={disabledRepeatExecution}
                  value={data?.recTestStepProperty?.loopCount}
                  type="number"
                  min={0}
                  onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                  onChange={(e) => dispatch(changeRecTestStepLoopCount(e.target.value, data?.tag))}
                  placeholder="Pause Before Taking Screenshot"
                  className={styles.styledInput}
                />
                <span className={styles.title}>times</span>
              </div>
            </div>
          )}
        <ManageTestData {...props} tabName={POST_OPERATION_SETTINGS} />
      </div>
    </div>
  );
};

export default RecTestStepPostActionSettings;
