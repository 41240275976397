import { Col, Collapse, Row } from "antd";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_PLAN_RESULT_TYPE } from "../../../../Constants";
import {
  fetchParentSteps,
  getDuration,
  getTotalDuration,
  isNotNullAndNotEmptyAndNotUndefined,
  leftBorderColor,
  navigateToTestRail,
} from "../../../../Util";
import { getTestPlanStepsList, setRunResultScenarioId, testPlanStepsListId } from "../../../../actions/TestPlanAction";
import DeviceHoverCard from "../../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import ErrorInvestigationModal from "../../../ErrorInvestigation/ErrorInvestigationModal";
import ParentTestPlanResultDetailsStepV2 from "../../../TestPlanV2/TestPlanResultDetailsV2/ParentTestPlanResultDetailsStepV2";
import { durationTime, getTimeTitle, highlightResult, isShowDuration } from "../TestPlanResultScenarioDetails";
import TestPlanResultScenarioEvidence from "../TestPlanResultScenarioEvidence";

const { Panel } = Collapse;

const LocaleViewDeviceCard = (props) => {
  const { deviceDetails, runResultDetails, isTestBlock,runNumber } = props;
  const dispatch = useDispatch();
  const { testPlanResultStepsDetails, testStepResults } = useSelector((state) => state.TestPlanReducer);

  const [openErrorInvestigationModal, setOpenErrorInvestigationModal] = useState(false);
  const [failedTestStepId, setFailedTestStepId] = useState(null);
  const [activeScenarioId, setActiveScenarioId] = useState([]);
  const [ActiveScenario, setActiveScenario] = useState(null);
  const [currentActiveScenarioId, setCurrentActiveScenarioId] = useState(null);

  const getTestStepsDetailsOnClick = (data, k) => {
    const scenarioId = data?.scenarioId;
    dispatch(setRunResultScenarioId(scenarioId));
    let scenarioIds = [...activeScenarioId];
    if (scenarioIds.includes(scenarioId)) {
      const dId = scenarioIds.indexOf(scenarioId);
      scenarioIds.splice(dId, 1);
    } else {
      scenarioIds.push(scenarioId);
    }
    if (
      data?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
      data?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
      data?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED
    ) {
      setActiveScenarioId(scenarioIds);
    }
    if (k !== ActiveScenario) {
      dispatch(getTestPlanStepsList({ scenarioId: scenarioId }, () => {}, "", true));
      dispatch(testPlanStepsListId(scenarioId));
      setCurrentActiveScenarioId(scenarioIds);
      setActiveScenario(k);
    } else {
      setActiveScenario(null);
      setCurrentActiveScenarioId(null);
    }
  };

  return (
    <Col span={24}>
      <div>
        {!isEmpty(deviceDetails) &&
          deviceDetails?.map((v, k) => {
            const runId = runResultDetails?.find((i) => i)?.runId;
            const parentSteps =
              isTestBlock === 0
                ? fetchParentSteps(testPlanResultStepsDetails, v?.scenarios?.scenarioId)
                : fetchParentSteps(testStepResults, runId);
            return (
              <Collapse
                className={leftBorderColor(v?.scenarios?.result)}
                key={k}
                accordion
                ghost
                onChange={() => getTestStepsDetailsOnClick(v?.scenarios, k)}
                expandIconPosition="right"
              >
                <Panel
                  className="mt-10"
                  showArrow={true}
                  header={
                    <Row className="display-flex">
                      <div className="w-100 display-flex justify-space-between">
                        <div>
                          <div className="font-weight-500">
                            <span className="font-weight-700">Scenario:</span>
                            <span className="ml-2">{v?.scenarios?.name}</span>
                          </div>
                          <div className="w-100 display-flex justify-space-between">
                            <div>
                              <div className="font-weight-500">
                                <span className="font-weight-700">Total Steps:</span>
                                <span className="ml-2">{v?.scenarios.totalSteps ? v.scenarios.totalSteps : 0}</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <span className="font-weight-700"> Screenshots Captured:</span>
                            <span className="font-weight-500 ml-2">
                              {v?.scenarios.totalCapturedScreenShotCounts
                                ? v?.scenarios.totalCapturedScreenShotCounts
                                : 0}{" "}
                              of{" "}
                            </span>
                            <span className="font-weight-500 ml-2">
                              {v?.scenarios.totalExpectedScreenShotCounts
                                ? v?.scenarios.totalExpectedScreenShotCounts
                                : 0}
                            </span>
                          </div>
                          {v?.scenarios?.result !== TEST_PLAN_RESULT_TYPE.IN_PROGRESS && (
                            <>
                              <span className="font-weight-700">Total Duration:</span>
                              <span className="ml-2">
                                {getTimeTitle(
                                  "Scenario Execution Details",
                                  durationTime(getTotalDuration(v?.scenarios).split(":")),
                                  undefined,
                                  undefined
                                )}
                              </span>
                              (<span className="font-weight-500">Waiting for Device Availability:</span>
                              {isShowDuration(getDuration(v?.scenarios)) &&
                                getTimeTitle(
                                  "Waiting for Device Availability",
                                  durationTime(v?.scenarios?.waitDuration?.split(":")),
                                  undefined,
                                  undefined
                                )}
                              ,<span className="font-weight-500 ml-5">Execution:</span>
                              {isShowDuration(getDuration(v?.scenarios)) &&
                                getTimeTitle(
                                  "Scenario Execution Timing Details",
                                  durationTime(getDuration(v?.scenarios)),
                                  v?.scenarios?.startTime,
                                  v?.scenarios?.endTime
                                )}
                              ,<span className="font-weight-500 ml-5">Result Processing:</span>
                              {getTimeTitle(
                                "Report Processing Details",
                                v?.scenarios?.reportProcessingDuration !== null &&
                                  durationTime(v?.scenarios?.reportProcessingDuration.split(":")),
                                v?.scenarios?.awsUploadStartTime,
                                v?.scenarios?.awsUploadEndTime
                              )}
                              )
                            </>
                          )}
                          <div className="font-weight-500">
                            <span className="font-weight-700">Device:</span>
                            <span className="ml-5">
                              <DeviceHoverCard deviceDetails={v} />
                            </span>
                          </div>
                          <div>
                            <span className="font-weight-700">Testrail Case:</span>
                            {isNotNullAndNotEmptyAndNotUndefined(v?.scenarios?.testRailTestCaseLabel) &&
                            isNotNullAndNotEmptyAndNotUndefined(v?.scenarios?.testRailTestCaseUrl) ? (
                              v?.scenarios?.testRailTestCaseLabel?.split(",").map((label, i) => {
                                return (
                                  <span
                                    className="font-color-blue cursor-pointer text-decoration-underline mr-5"
                                    onClick={(e) => {
                                      navigateToTestRail(e, v?.scenarios, i);
                                    }}
                                  >
                                    {label}
                                  </span>
                                );
                              })
                            ) : (
                              <span className="font-weight-500 ml-2">0</span>
                            )}
                          </div>
                        </div>
                        <div className="">
                          {v?.scenarios?.state
                            ? highlightResult(
                                v?.scenarios?.state === "DONE" ? v?.scenarios?.result : v?.scenarios?.state,
                                setOpenErrorInvestigationModal,
                                v?.scenarios?.failedTestStepId,
                                setFailedTestStepId,
                                v?.scenarios?.testExecutionErrorInvestigationDetails
                              )
                            : "N.A."}
                        </div>
                      </div>
                    </Row>
                  }
                >
                  <ParentTestPlanResultDetailsStepV2
                    parentSteps={parentSteps}
                    data={v?.scenarios}
                    isTestBlock={isTestBlock}
                    activeScenarioId={activeScenarioId}
                    runResultDetails={runResultDetails}
                    setCurrentActiveScenarioId={setCurrentActiveScenarioId}
                    currentActiveScenarioId={currentActiveScenarioId}
                  />
                  {isTestBlock === 0 && (
                    <div className="mt-10">
                      <TestPlanResultScenarioEvidence scenarioData={v?.scenarios} />
                    </div>
                  )}
                </Panel>
              </Collapse>
            );
          })}
      </div>
      {openErrorInvestigationModal && (
        <ErrorInvestigationModal
          openErrorInvestigationModal={openErrorInvestigationModal}
          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
          failedTestStepId={failedTestStepId}
          projectId={runResultDetails[0]?.projectId}
          orgId={runResultDetails[0]?.organizationId}
          runNumber={runNumber}
        />
      )}
    </Col>
  );
};

export default LocaleViewDeviceCard;
