import React from "react";
import ViewDefaultTestDataListView from "./ViewDefaultTestDataListView";
import ViewTestBlockDefaultTestDataSet from "./ViewTestBlockDefaultTestDataSet";
import _ from "lodash";
import { Collapse } from "antd";
const ViewRecTestScenarioDefaultTestDataSet = (props) => {
  let refTestDataList = _.cloneDeep(props.defaultTestData?.testDataValues);
  for (let index = 0; index < props.defaultTestData?.testBlockTestDataSaveReq?.length; index++) {
    Array.prototype.push.apply(
      refTestDataList,
      _.cloneDeep(props.defaultTestData?.testBlockTestDataSaveReq[index]?.testDataValues)
    );
  }

  return (
    <>
      {props.defaultTestData && (
        <>
          {!_.isEmpty(props.defaultTestData?.testDataValues) &&
            <ViewDefaultTestDataListView
              testDataValues={props.defaultTestData.testDataValues}
              title={props.title}
              name={props.defaultTestData.name}
              stepType={props.stepType}
              refTestDataList={refTestDataList ? refTestDataList : []}
            />}
          <div className="m-10">
            {props.defaultTestData?.testBlockTestDataSaveReq && props.defaultTestData?.testBlockstepCount > 0 &&
              <Collapse defaultActiveKey={[]}>
                <Collapse.Panel 
                  collapsible={true} 
                  header={"Test Block: Steps using Test Data"
                      + " [Steps using Test Data: " + props.defaultTestData?.testBlockstepCount
                      + " Unique Test Data Keys: " + props.defaultTestData?.testBlockuniqueTestDataCount + "]"}
                  key={"Default"}>
                  {props.defaultTestData?.testBlockTestDataSaveReq.map((testBlockTestData) => {
                    return (
                      <ViewTestBlockDefaultTestDataSet
                        defaultTestData={testBlockTestData}
                        title={"Test Block:" + testBlockTestData.name}
                        stepType={props.stepType}
                        refTestDataList={refTestDataList ? refTestDataList : []}
                      />
                    );
                  })}
                </Collapse.Panel>
              </Collapse>
            }
          </div>
        </>
      )}
    </>
  );
};

export default ViewRecTestScenarioDefaultTestDataSet;
