import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TestData from "./TestData";
import AddTestDataSystemKey from "./AddTestDataSystemKey";

export default (props) => {
  const { projectId, setCurrentActionTab } = props;
  const dispatch = useDispatch();
  const { addTestDataFormVisible } = useSelector((state) => state.TestDataReducer);

  return (
    <>
      <Row>
        <Col span={24}>
          {addTestDataFormVisible ? (
            <AddTestDataSystemKey projectId={props.projectId} />
          ) : (
            <TestData setCurrentActionTab={setCurrentActionTab} projectId={projectId} isSkipStep={props.isSkipStep} />
          )}
        </Col>
      </Row>
    </>
  );
};
