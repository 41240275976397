import { Button, Col, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { BLACK_COLOR, ICON_AI, INFO_ICON } from "../../Constants/SvgConstants";
import SvgLoader from "../../Util/SvgLoader";
import {
  changeScenarioGenerateStepType,
  changeScenarioObjective,
  showGenerateStepsFromAIHeader,
  stopGenerateStepsFromAIModal,
} from "../../actions/RecTestStep/RecTestStepRedirectAction";
import DeviceLoadingV2 from "../DeviceViewV2/DeviceLoadingV2/DeviceLoadingV2";
import { generateScenarioSteps } from "../../actions/RecTestStep/RecTestStepRedirectAction";
import { AI_SCENARIO_GENERATE_STEP_TYPE, TEST_SCENARIOS } from "../../Constants";
import styles from "./RecTestScenarioV2.module.scss";

const AIGenerateHeaderV2 = (props) => {
  const {
    isShowAIGenerateStepsRow,
    scenarioObjective,
    scenarioGenerateRequestLoading,
    scenarioGenerateInstructions,
    selectedModelType,
    scenarioGenerateStepType,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);

  const dispatch = useDispatch();

  const isStartAndStopButtonDisabled =
    selectedDeviceByUdid === undefined || scenarioObjective == null || scenarioObjective.length === 0;

  const handleStartAndStopClick = () => {
    const action = scenarioGenerateRequestLoading ? stopGenerateStepsFromAIModal : generateScenarioSteps;
    dispatch(
      action({
        objective: scenarioObjective,
        rules: scenarioGenerateInstructions,
        deviceId: selectedDeviceByUdid?.targetUniqueId,
        modelType: selectedModelType,
      })
    );
  };

  return (
    <>
      {isShowAIGenerateStepsRow && (
        <Row className={styles["ai_Objective_With_Loader"]}>
          <Col span={22} className={styles["ai_objective"]}>
            <SvgLoader iconName={ICON_AI} iconColor={BLACK_COLOR} width={"1.625rem"} height={"1.625rem"} />
            <div className={styles["ai_objective_input_label"]}>High-Level Test Scenario Objective:</div>
            <Input
              className={styles["ai_objective_input"]}
              value={scenarioObjective}
              placeholder="Eg:'Create a post with the text 'Exploring the new park in our neighborhood!' and verify that the post has been created successfully.'"
              onChange={(e) => dispatch(changeScenarioObjective(e.target.value))}
              autoFocus
            />
            <Select
              variant="outlined"
              className={styles["ai_objective_select"]}
              value={scenarioGenerateStepType}
              onChange={(value) => dispatch(changeScenarioGenerateStepType(value))}
              placeholder="Generate Steps"
            >
              <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.APPEND_WITH_EXISTING_STEPS}>
                Append with existing steps
              </Select.Option>
              <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.CLEAR_EXISTING_STEPS_AND_START}>
                Clear and regenerate
              </Select.Option>
            </Select>
            <Button
              type="primary"
              size="large"
              className={styles["start_Or_Stop_Btn"]}
              disabled={isStartAndStopButtonDisabled}
              onClick={handleStartAndStopClick}
            >
              {scenarioGenerateRequestLoading ? "Stop" : "Start"}
            </Button>

            <div onClick={() => dispatch(showGenerateStepsFromAIHeader(TEST_SCENARIOS))}>
              <SvgLoader iconName={INFO_ICON} iconColor={BLACK_COLOR} width={"1.625rem"} height={"1.625rem"} />
            </div>
          </Col>
          <Col span={2}>{scenarioGenerateRequestLoading && <DeviceLoadingV2 generateAISteps={true} />}</Col>
        </Row>
      )}
    </>
  );
};

export default AIGenerateHeaderV2;
