import {
    TEST_ELEMENT,
    ALERT_USER,
    ALERT_CANCEL,
    LOGGED_IN_USER_DETAILS
} from "../Constants";

let defaultState = {
    testElement : "",
    alertUser:false
};

export default function HomeReducer(state = defaultState, action){

    switch (action.type) {  
        case TEST_ELEMENT:
                state.testElement = "Welcome to xpress!";
                return {
                    ...state
                };  

             case ALERT_USER:
                    return {
                        ...state,
                        alertUser:true
                    };
            case ALERT_CANCEL:
                    return {
                        ...state,
                        alertUser:false
                    };
            case LOGGED_IN_USER_DETAILS:
                    return {
                        ...state,
                        loggedInUserDetails:action.response.userDetails?action.response.userDetails:null,
                    } 
        default:
                return state; 
        }   
}