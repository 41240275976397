import { Col, Input, Row, Select, Space } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTestDatasetLocale,
  changeTestDataSetName,
  resetTestDataSet,
  saveTestDataSet,
  fetchTestDataSetByProfileId,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import {
  fetchSystemKeyByProjectIdDropdown,
  getListOfLocales,
  getTestDataForAddTestDataSystemKey,
  fetchSystemKeyByProjectId,
  resetPreviousTestDataSetForEdit,
} from "../../../../../../actions/TestDataSetAction";
import { BACK_BUTTON } from "../../../../../../CdnImagePath";
import { SYSTEM_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import { confirmModal, isArrayNotUndefinedAndNotNull } from "../../../../../../Util";
import BackButton from "../../../../../CoreComponents/BackButtonComponent/BackButton";
import SaveResetButton from "../../../../../CoreComponents/SaveResetComponent/SaveResetButton";
import DeviceTestDataSetList from "../DeviceTestDataSetList";
import EditRecTestScenarioTestDataSet from "../EditRecTestScenarioTestDataSet";
import EditSystemKeyTestDataSet from "../EditSystemKeyTestDataSet";
import EditTestBlockTestDataSet from "../EditTestBlockTestDataSet";
import EditTestPlanTestDataSet from "../EditTestPlanTestDataSet";
import styles from "./EditTestDataSet.module.scss";


const { Option } = Select;

const EditTestDataSet = (props) => {
  const dispatch = useDispatch();
  const { setIsEditTestDataSet, testPlanId, stepType, isSystemKeyView } = props;
  const { testDataSetForEdit, listOfLocales, previous_testDataSetForEdit, testDataSetView, testDataSetAssigneeDetail } =
    useSelector((state) => state.TestDataSetReducer);
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const [dataSetList, setDataSetList] = useState([]);
  const [editName, setEditName] = useState(false);

  useEffect(() => {
    if (isArrayNotUndefinedAndNotNull(testDataList)) {
      setDataSetList(_.cloneDeep(testDataList));
    }
  }, [testDataList]);

  useEffect(() => {
    dispatch(fetchSystemKeyByProjectIdDropdown());
    dispatch(getListOfLocales());
    dispatch(resetPreviousTestDataSetForEdit());
  }, []);

  useEffect(() => {
    if (testDataSetForEdit) {
      if (testPlanId) {
        // dispatch(getTestDataForAddTestData(testDataSetForEdit.id, testPlanId, stepType));
      } else {
        dispatch(getTestDataForAddTestDataSystemKey(testDataSetForEdit.id));
      }
    }
  }, [testDataSetForEdit]);

  const onBackClick = () => {
    if (previous_testDataSetForEdit) {
      confirmModal(() => {
        setIsEditTestDataSet(false);
        dispatch(resetPreviousTestDataSetForEdit());
      });
    } else {
      setIsEditTestDataSet(false);
    }
    if (testDataSetView === SYSTEM_KEYS) {
      dispatch(fetchSystemKeyByProjectId());
    } else {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    }
  };

  return (
    testDataSetForEdit && (
      <>
        <Row align="middle" className={styles.editTestDataRow}>
          <Col className={styles.editTestDataCol}>
            {stepType !== TEST_PLAN && (
              <div className="backIcon">
                <img src={BACK_BUTTON} alt="Back" onClick={onBackClick} className={styles.backBtn} />
              </div>
            )}
            <div className={styles.container}>
              <label className={styles.inputTitle}>Profile Name: </label>
              {editName ? (
                <Input
                  type="text"
                  value={testDataSetForEdit?.name}
                  onChange={(e) => dispatch(changeTestDataSetName(e.target.value))}
                  onBlur={() => setEditName(false)}
                  autoFocus
                />

              ) : (
                <div className={styles.inputBox} onClick={() => setEditName(true)}>
                  <span className={styles.inputValue}>{testDataSetForEdit?.name}</span>
                </div>
              )}
            </div>
          </Col>
          <Col>
            <Space>
              {!isSystemKeyView && (
                <Select
                  placeholder="Please select Locale"
                  className="select-app-for-default"
                  defaultValue={testDataSetForEdit && testDataSetForEdit.locale}
                  onChange={(value) => dispatch(changeTestDatasetLocale(value))}
                  value={testDataSetForEdit.locale}
                >
                  {listOfLocales &&
                    listOfLocales.map((i, k) => {
                      return (
                        <Option value={i.locales} key={k}>
                          {i.displayName}
                        </Option>
                      );
                    })}
                </Select>
              )}

              <div className={stepType !== TEST_PLAN && styles.saveResetButton}>
                <SaveResetButton
                  submitLabel={"Save"}
                  resetLabel={"Reset"}
                  submitDisable={previous_testDataSetForEdit === undefined}
                  resetDisable={previous_testDataSetForEdit === undefined}
                  handleSubmit={() => dispatch(saveTestDataSet())}
                  handleReset={() => dispatch(resetTestDataSet())}
                />
              </div>
              {stepType === TEST_PLAN && (
                <div className="closeIcon">
                  <BackButton handleBack={onBackClick} isBlack={true} />
                </div>
              )}
            </Space>
          </Col>
        </Row>

        <div className={stepType === TEST_PLAN ? styles.pageScroll : styles.pageScrollOfTestData}>
          {testDataSetView !== SYSTEM_KEYS && <DeviceTestDataSetList testDataSetForEdit={testDataSetForEdit} />}
          {testDataSetView === TEST_STEP_BLOCKS && (
            <EditTestBlockTestDataSet 
              title={"Test Block: Steps using Test Data"}
              testDataSetForEdit={testDataSetForEdit} />
          )}
          {testDataSetView === TEST_SCENARIOS && (
            <EditRecTestScenarioTestDataSet
              title={"Test Scenario: Steps using Test Data"
                + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
                + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
              testDataSetForEdit={testDataSetForEdit}
            />
          )}
          {testDataSetView === TEST_PLAN && (
            <EditTestPlanTestDataSet 
              title={"Test Plan Level Test Data"
                + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
                + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
              testDataSetForEdit={testDataSetForEdit} />
          )}
          {testDataSetView === SYSTEM_KEYS && (
            <EditSystemKeyTestDataSet title={"Test Data"} 
              testDataSetForEdit={testDataSetForEdit} />
          )}
        </div>
      </>
    )
  );
};

export default EditTestDataSet;
