import { async } from "../api/automation";
import { BuildErrorInvestigationStateModal } from "../Components/ErrorInvestigation/BuildErrorInvestigationStateModal";
import {
  CHANGE_ERROR_INVESTIGATION_DATA,
  ERROR_INVESTIGATION_LOADING,
  ERROR_TYPE,
  FETCH_ERROR_INVESTIGATION_DATA,
  FETCH_OTHER_RUN_LIST,
  OTHER_RUNS_OPTION,
  PREVIOUS_ERROR_INVESTIGATION_DATA,
  RECORD_PER_PAGE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
} from "../Constants";
import { showNotification, updateResponse } from "../Util";
import { getRunResultDetailsByRunNumber, getTestPlanStepsList } from "./TestPlanAction";

export function handleChangeErrorInvestigationData(ErrorInvestigationData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_ERROR_INVESTIGATION_DATA, data: ErrorInvestigationData });
  };
}

export function handlePreviousChangeErrorInvestigationData(ErrorInvestigationData) {
  return async (dispatch) => {
    dispatch({ type: PREVIOUS_ERROR_INVESTIGATION_DATA, data: ErrorInvestigationData });
  };
}

export function fetchErrorInvestigationDetails(id) {
  return async (dispatch, getState) => {
    dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: true });
    return async("textExecutionErrorInvestigationDetail/getByTestStepId", "GET", { id }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCH_ERROR_INVESTIGATION_DATA, response });
        dispatch({
          type: CHANGE_ERROR_INVESTIGATION_DATA,
          data: new BuildErrorInvestigationStateModal(response?.data),
        });
        dispatch({
          type: PREVIOUS_ERROR_INVESTIGATION_DATA,
          data: new BuildErrorInvestigationStateModal(response?.data),
        });
      }
      dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: false });
    });
  };
}

export function fetchOtherRuns(id, pageNumber, searchOption) {
  return async (dispatch, getState) => {
    dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: true });
    let preObj = {
      id: id,
      pageNumber: pageNumber,
      recordPerPage: RECORD_PER_PAGE,
      filter: searchOption === OTHER_RUNS_OPTION.ALL ? null : searchOption,
      previousId: getState().ErrorInvestigationReducer?.errorInvestigationDetails?.previousInvestigationId,
    };
    return async("textExecutionErrorInvestigationDetail/getOtherRuns", "GET", preObj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCH_OTHER_RUN_LIST, response, pageNumber });
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: false });
    });
  };
}

export function saveErrorInvestigationDetails(projectId, testStepId, setOpenErrorInvestigationModal, RunNumber) {
  return async (dispatch, getState) => {
    dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: true });
    let previousTestStepId = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.previousTestStepId;
    let scenarioId = getState().TestPlanReducer.runResultScenarioId;
    const preObj = {
      testStepId: parseInt(testStepId),
      projectId: projectId,
      id: getState().ErrorInvestigationReducer?.errorInvestigationDetails?.id || 0,
      otherTestStepId: getState().ErrorInvestigationReducer?.errorInvestigationDetails?.otherTestStepId || [],
      previousTestStepId: previousTestStepId || 0,
    };
    if (!previousTestStepId) {
      preObj.errorDescription = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.errorDescription;
      preObj.failureReason = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.failureReason;
    }
    if (preObj.failureReason === "Product Bug") {
      preObj.bugUrl = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.bugUrl;
      preObj.bugStatus = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.bugStatus || "bugStatus";
      preObj.bugId = getState().ErrorInvestigationReducer?.errorInvestigationDetails?.bugId || 0;
    }
    return async("textExecutionErrorInvestigationDetail/customSave", "POST", preObj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: FETCH_ERROR_INVESTIGATION_DATA, response });
        showNotification(SUCCESS_TYPE, response?.message);
        dispatch(getRunResultDetailsByRunNumber({ runNumber: RunNumber },"",projectId));
        scenarioId && dispatch(getTestPlanStepsList({ scenarioId }));
      } else {
        showNotification(ERROR_TYPE, response?.message);
      }
      setOpenErrorInvestigationModal(false);
      dispatch({ type: ERROR_INVESTIGATION_LOADING, isLoading: false });
    });
  };
}
