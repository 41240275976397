import { Button, Col, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTestDataSetDeviceAssignDataSet } from "../../../../../actions/TestDataSet/TestDataSetRedirectAction";
const { Option } = Select;

const DeviceTestDataSetList = (props) => {
  const { testDataSetForEdit } = props;
  const { systemKeyList } = useSelector((state) => state.TestDataSetReducer);
  const dispatch = useDispatch();
  return (
    <Row>
      <Col span={24} className="display-flex align-items-center mt-20">
        <Col className="m-10">
          <b>Assign Device Test Data Set</b>
        </Col>
        <Col className="mr-10">
          <Select
            placeholder="Please select Test Data Set"
            className="select-app-for-default"
            defaultValue={testDataSetForEdit && testDataSetForEdit.systemKey?.id}
            onChange={(value) => dispatch(changeTestDataSetDeviceAssignDataSet(value))}
            value={testDataSetForEdit.systemKeyId ? testDataSetForEdit.systemKeyId : testDataSetForEdit.systemKey?.id}>
            {systemKeyList &&
              systemKeyList.map((i, k) => {
                return (
                  <Option value={i.id} key={k}>
                    {i.name}
                  </Option>
                );
              })}
          </Select>
        </Col>
        <Col>
          <Button type="link">View Test Data Set Values</Button>
        </Col>
      </Col>
    </Row>
  );
};

export default DeviceTestDataSetList;
