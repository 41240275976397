import React, { useState } from "react";
import _ from "lodash";
import { Drawer, Spin, Col, Row, Input } from "antd";
import Search from "antd/lib/input/Search";
import { useDispatch, useSelector } from "react-redux";
import EmptyRecords from "../../../../../CommonComponents/EmptyRecords/EmptyRecords";
import { changeAdvanceElementSelectorDrawerVisibility } from "../../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RenderDeviceSource from "../RenderDeviceSource/RenderDeviceSource";
import RenderDeviceAttributes from "../RenderDeviceAttributes/RenderDeviceAttributes";
import { CLOSE_ICON_GRAY, SEARCH_ICON } from "../../../../../../CdnImagePath";
import VerticalLine from "../../../../../CommonComponents/VerticalLine";
import styles from "./AdvanceSelectorChildElementDrawer.module.scss";

const fetchChildrenOfParentPathForAndroid = (pageSource, parentElementBounds) => {
  let parent = undefined;
  for (let i = 0; i < pageSource.length; i++) {
    if (pageSource[i]?.attributes["bounds"] === parentElementBounds.value) {
      parent = pageSource[i];
      break;
    }
    if (parent === undefined && pageSource[i].children?.length > 0) {
      parent = fetchChildrenOfParentPathForAndroid(pageSource[i].children, parentElementBounds);
    }
  }
  return parent;
};
const fetchChildrenOfParentPathForIOS = (pageSource, parentElementBounds) => {
  let parent = undefined;
  for (let i = 0; i < pageSource.length; i++) {
    if (
      pageSource[i]?.attributes["x"] === parentElementBounds.x &&
      pageSource[i]?.attributes["y"] === parentElementBounds.y &&
      pageSource[i]?.attributes["width"] === parentElementBounds.width &&
      pageSource[i]?.attributes["height"] === parentElementBounds.height
    ) {
      parent = pageSource[i];
      break;
    }
    if (parent === undefined && pageSource[i].children?.length > 0) {
      parent = fetchChildrenOfParentPathForIOS(pageSource[i].children, parentElementBounds);
    }
  }
  return parent;
};
const AdvanceSelectorChildElementDrawer = (props) => {
  const { hoveredElement,currentDeviceScreenSource, findElementProcessing } = useSelector((state) => state.RecordingReducer);
  
  const { selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);
  const { isAdvanceElementSelectorDrawerVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const [attributes, setAttributes] = useState({});
  const [path, setPath] = useState();
  const {
    projectId,
    searchElements,
    setSearchElements,
    refRecStepSelectedElementPath,
    refRecStepElementXpaths,
    parentElement,
  } = props;
  let parentElementBounds = parentElement?.recElementProperties?.filter(
    (recElementProperty) => recElementProperty.name === "bounds"
  );
  let selectElementPageSource = fetchChildrenOfParentPathForAndroid(
    [currentDeviceScreenSource],
    parentElementBounds?.length > 0 ? parentElementBounds[0] : ""
  );
  if (parentElementBounds?.length === 0) {
    parentElementBounds = parentElement.recElementProperties.filter(
      (recElementProperty) =>
        recElementProperty.name === "x" ||
        recElementProperty.name === "y" ||
        recElementProperty.name === "width" ||
        recElementProperty.name === "height"
    );
    let bounds = {};
    parentElementBounds &&
      parentElementBounds.map((bound) => {
        bounds[bound.name] = bound.value;
      });
    selectElementPageSource = fetchChildrenOfParentPathForIOS(
      [currentDeviceScreenSource],
      Object.keys(bounds).length > 0 ? bounds : ""
    );
  }

  return (
    <Drawer
      placement="right"
      width={"850"}
      onClose={() => dispatch(changeAdvanceElementSelectorDrawerVisibility(false))}
      visible={isAdvanceElementSelectorDrawerVisible}
      closable={false}
      className="advancedSelectorDrawer"
    >
      <Spin spinning={findElementProcessing}>
        <Row>
          <Col span={9} className={styles.borderShadow}>
            <RenderDeviceAttributes attributes={attributes} platform={selectedDeviceByUdid?.platformType?.name}/>
            {_.isEmpty(attributes) && <EmptyRecords description="Element Not Selected" />}
          </Col>
          <Col span={15}>
            <div className={styles.drawerTitleOrCloseIcon}>
              <span className={styles.drawerTitle}>Advanced Selector (All Child Elements)</span>
              <span>
                <img
                  src={CLOSE_ICON_GRAY}
                  alt="close"
                  className="cursor-pointer"
                  onClick={() => dispatch(changeAdvanceElementSelectorDrawerVisibility(false))}
                />
              </span>
            </div>

            <div className={styles.searchBoxOrBorder}>
              <div className={styles.searchTitle}> Select Element From List:</div>
              <div className="mt-5">
                <Input
                  placeholder="Search element.."
                  className="searchElementBox"
                  value={searchElements}
                  onChange={(e) => setSearchElements(e.target.value)}
                  prefix={<img src={SEARCH_ICON} alt="search" className="mr-5" />}
                />
              </div>
            </div>
            <VerticalLine className={styles.border} />
            <div className={styles.selectorData}>
              <RenderDeviceSource
                child={selectElementPageSource?.children}
                setAttributes={setAttributes}
                path={path}
                setPath={setPath}
                targetUniqueId={selectedDeviceByUdid?.targetUniqueId}
                serverId={selectedDeviceByUdid?.autoServerDetail?.id}
                currentDeviceScreenSource={selectElementPageSource}
                x={0}
                y={0}
                projectId={projectId}
                searchElements={searchElements}
                parentElementBounds={parentElementBounds?.length > 0 ? parentElementBounds[0] : undefined}
                parentElement={parentElement}
              />
              {_.isEmpty(selectElementPageSource) && <EmptyRecords description={"No Elements Found"} />}
            </div>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};

export default AdvanceSelectorChildElementDrawer;
