import { Row, Spin } from "antd";
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  getAWSDeviceFarm,
  getAwsDeviceFarmPoolsLists,
  getAwsProjectsList,
  saveAwsDeviceFarmProject,
} from "../../../actions/ProjectSettingsActions";
import AWSDeviceFarmKeys from "./AWSDeviceFarmKeys";
import AWSDeviceFarmProjects from "./AWSDeviceFarmProjects";
import {
  CHANGE_AWS_DEVICE_FARM_TAB_DATA,
  GET_AWS_PROJECTS,
  UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS,
} from "../../../Constants";
import { BuildAWSDeviceFarmModal } from "../BuildProjectSettingModal";
import { Title } from "../../CoreComponents/StyledComponent/StyledComponents";
import SaveResetButton from "../../CoreComponents/SaveResetComponent/SaveResetButton";

export const Container = styled.div`
  border: 1px solid black;
  padding: 10px;
`;

const AWSDeviceFarmManager = (props) => {
  const { projectId, selectedTab } = props;
  const dispatch = useDispatch();
  const {
    awsDeviceFarmTabDetails,
    projectLoading,
    previous_awsDeviceFarmTabDetails,
    getAWSDeviceFarms,
    awsDeviceLoading,
  } = useSelector((state) => state.ProjectSettingsReducer);

  useEffect(() => {
    dispatch({ type: UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS });
    dispatch(getAWSDeviceFarm(props.projectId));
  }, [projectId]);

  const resetAwsDeviceFarmData = () => {
    if (previous_awsDeviceFarmTabDetails.accessKeyId === undefined) {
      dispatch({
        type: CHANGE_AWS_DEVICE_FARM_TAB_DATA,
        data: new BuildAWSDeviceFarmModal(getAWSDeviceFarms),
      });
      dispatch({ type: GET_AWS_PROJECTS, response: { data: [] } });
    } else {
      dispatch({
        type: CHANGE_AWS_DEVICE_FARM_TAB_DATA,
        data: _.cloneDeep(previous_awsDeviceFarmTabDetails),
      });
      dispatch(getAwsProjectsList());
      dispatch(getAwsDeviceFarmPoolsLists());
    }
  };

  return (
    <Spin spinning={projectLoading || awsDeviceLoading} tip="Loading" className="spinner-center">
      <Row justify="space-between" className="innerHeader p-12">
        <div className="mainHeaderText ml-10">AWS Device Farm</div>
        <SaveResetButton
          submitLabel={"Update"}
          resetLabel={"Reset"}
          submitDisable={
            _.isEmpty(awsDeviceFarmTabDetails?.awsDeviceFarmProjects) ||
            JSON.stringify(awsDeviceFarmTabDetails?.awsDeviceFarmProjects) ===
              JSON.stringify(previous_awsDeviceFarmTabDetails?.awsDeviceFarmProjects)
          }
          resetDisable={JSON.stringify(awsDeviceFarmTabDetails) === JSON.stringify(previous_awsDeviceFarmTabDetails)}
          handleSubmit={() => dispatch(saveAwsDeviceFarmProject(projectId))}
          handleReset={resetAwsDeviceFarmData}
        />
      </Row>
      <Row justify="center" className="pageScroll">
        <AWSDeviceFarmKeys selectedTab={selectedTab} />
        <AWSDeviceFarmProjects />
      </Row>
    </Spin>
  );
};

export default AWSDeviceFarmManager;
