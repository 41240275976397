import React from "react";
import _ from "lodash";
import cloneDeep from "lodash/cloneDeep";
import AssignedTestDataListViewV2 from "./AssignedTestDataListViewV2/AssignedTestDataListViewV2";

const EditTestBlockTestDataSetV2 = (props) => {
  const { title, testDataSetForEdit, isForView, refTestDataList } = props;

  return (
    <>
      {(testDataSetForEdit?.testDataValues && !_.isEmpty(testDataSetForEdit?.testDataValues)) &&
        <AssignedTestDataListViewV2
          title={title
            + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
            + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
          testDataValues={testDataSetForEdit?.testDataValues}
          isForView={isForView}
          refTestDataList={refTestDataList ? refTestDataList : cloneDeep(testDataSetForEdit?.testDataValues)}
        />
      }
    </>
  );
};

export default EditTestBlockTestDataSetV2;