import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { All_RECORDS, MY_PLANS, OWNED, RECORD_10_PER_PAGE, SHARED, SHARED_WITH_ME } from "../../../Constants";
import { getTestPlanDetailsList } from "../../../actions/TestPlanAction";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import styles from "./TestPlanDetailsV2.module.scss";

const filterPermission = [
  { id: OWNED, value: MY_PLANS },
  { id: SHARED, value: SHARED_WITH_ME },
  { id: "", value: All_RECORDS },
];

const TestPlanDetailsHeaderV2 = (props) => {
  const dispatch = useDispatch();
  const {
    testPlanTab,
    activeFilter,
    setActiveFilter,
    setRights,
    projectId,
    testJobDetail,
    searchKeyword,
    showSearchBox,
  } = props;
  const { currentTestPlanPage } = useSelector((state) => state.TestPlanReducer);

  const handleFilters = useCallback(
    (e) => {
      setActiveFilter(e);
      setRights(e);
      dispatch(
        getTestPlanDetailsList({
          pageNumber: currentTestPlanPage,
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeyword: searchKeyword,
          projectId: projectId,
          rights: e?.id,
          selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
        })
      );
    },
    [setActiveFilter, setRights, dispatch, currentTestPlanPage, searchKeyword, projectId, testJobDetail]
  );

  return (
    <>
      <div className={testPlanTab ? styles["searchBox_In_Jobs"] : styles["test_Plan_Details"]}>
        <div className="display__flex">
          <div>
            {!testPlanTab && (
              <div className="display__flex">
                <HeaderButtonsGroupV2
                  data={filterPermission}
                  activeButton={activeFilter?.id}
                  onClick={(e) => handleFilters(e)}
                />
              </div>
            )}
          </div>
        </div>
        <div className="display__flex">
          {showSearchBox()}
        </div>
      </div>
    </>
  );
};

export default TestPlanDetailsHeaderV2;
