import { Radio } from "antd";
import React, { useEffect, useState } from "react";
import {
  ALL_RUNS,
  ALL_RUNS_DISPLAY_TEXT,
  COMPLETED_JOB,
  COMPLETED_JOB_DISPLAY_TEXT,
  IN_QUEUE_JOB,
  IN_QUEUE_JOB_DISPLAY_TEXT,
  RUNNING_JOB,
  RUNNING_JOB_DISPLAY_TEXT,
  TEST_PLAN_RESULT_TYPE,
} from "../../Constants";
import TestPlanResult from "../TestPlan/TestPlanResult/TestPlanResultLists/TestPlanResult";

const ReportManager = (props) => {
  const { projectId, orgId } = props;
  const [selectedExecutionManagerSubTab, setSelectedExecutionManagerSubTab] = useState(ALL_RUNS);

  useEffect(() => {
    setSelectedExecutionManagerSubTab(ALL_RUNS);
  }, [projectId]);

  return (
    <div>
      {selectedExecutionManagerSubTab === ALL_RUNS && (
        <TestPlanResult orgId={orgId} isForModal={true} projectId={projectId} />
      )}
      {selectedExecutionManagerSubTab === RUNNING_JOB && (
        <TestPlanResult
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.IN_PROGRESS}
        />
      )}
      {selectedExecutionManagerSubTab === IN_QUEUE_JOB && (
        <TestPlanResult
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED}
        />
      )}
      {selectedExecutionManagerSubTab === COMPLETED_JOB && (
        <TestPlanResult
          orgId={orgId}
          isForModal={true}
          projectId={projectId}
          searchStatus={TEST_PLAN_RESULT_TYPE.DONE}
        />
      )}
    </div>
  );
};

export default ReportManager;
