import React from "react";
import { useDispatch } from "react-redux";
import { Input } from "antd";
import { ELEMENT_ACTION } from "../../../../../Constants";
import { hasNotAllowedNegativeValue } from "../../../../../Util";
import { changeSwipePercentageOverElement } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerticalLine from "../../../../CommonComponents/VerticalLine";
import SwipeDirectionLabelAndIconsV2 from "./SwipeDirectionLabelAndIconsV2";
import styles from "./SwipeElementV2.module.scss";

const SwipeElementV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, disabled, data } = props;
  //Destructure object
  const { action, recSwipeAttributes } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  return (
    <React.Fragment>
      <div className={`${styles["swipe_Element_Details"]} ${action === ELEMENT_ACTION.SWIPE && styles["swipeAction"]}`}>
        <SwipeDirectionLabelAndIconsV2 isForView={isForView} disabled={disabled} data={data} />
        <div className={styles["swipe_Length_Details"]}>
          <div className={styles["swipe_Label"]}> Swipe Length:</div>
          <div className={`${styles["swipe_Length_Input_And_Text"]} ${(isForView || disabled) && styles['disabled_Input']}`}>
            <Input
              type="number"
              disabled={isForView || disabled}
              placeholder="Enter Swipe Percentage"
              value={recSwipeAttributes ? recSwipeAttributes.percentage : ""}
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              onChange={(e) =>
                !isForView && !disabled
                  ? dispatch(changeSwipePercentageOverElement(e.target.value, data.tag))
                  : undefined
              }
              className={styles["percentage_Input"]}
            />
            <div className={styles["element_Size_Text"]}>
              {`% ${action === ELEMENT_ACTION.SWIPE ? "of Screen Size" : "of Element Size"}`}
            </div>
          </div>
        </div>
      </div>
      <VerticalLine className={styles["swipe_Border_Bottom"]} />
    </React.Fragment>
  );
};

export default SwipeElementV2;
