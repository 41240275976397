import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  performRecTestStepAction,
  resetRecTestStepAction,
  saveRecTestStepActionWithCurrentDeviceImage,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

import {
  ACTIONS,
  DEVICE_INTERACTION,
  ELEMENT_ACTION,
  LAUNCH_APP,
  SELECTOR_TYPE,
  STEP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
  recElementSettings,
  recTestStepSettings,
} from "../../../../Constants";
import {
  getRecElement,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  isValidVerificationProperties,
} from "../../../../Util";
import { findRecTestStepByTag } from "../../../../reducer/RecTestStepReducer";
import TextButton from "../../../CommonComponents/TextButton/TextButton";
import {
  disableStepPerformSaveButton,
  disableStepResetButton,
  disableStepSaveButton,
} from "../../ValidateStepMandatoryProperty";
import styles from "./PerformRecTestStepAction.module.scss";

const PerformRecTestStepAction = (props) => {
  const {
    action,
    recTestStepId,
    recTestStepTag,
    data,
    resetImage,
    saveImage,
    SaveAndPerformImage,
    saveIcon,
    performSaveIcon,
    resetIcon,
  } = props;

  const { recTestStepList, selectedDeviceByUdid, testBlockStep } = useSelector((state) => state.RecTestStepReducer);

  const { unSavedRecStepTag, unSavedRecElementGuid } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
  const unSavedRecElement = getRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements, unSavedRecElementGuid);
  const dispatch = useDispatch();
  let isSameStepForEdit = unSavedRecTestStep?.tag === data?.tag;

  const saveButtonDisable =
    !isSameStepForEdit ||
    disableStepSaveButton(unSavedRecTestStep, unSavedRecElement) ||
    data?.recTestStepProperty?.stepName >= 0;
  const resetButtonDisable =
    data?.id === undefined || !isSameStepForEdit || disableStepResetButton(unSavedRecTestStep, unSavedRecElement);
  const performSaveButtonDisable =
    !isSameStepForEdit || disableStepPerformSaveButton(unSavedRecTestStep, unSavedRecElement, selectedDeviceByUdid);

  return (
    <div className={styles.performActionsButton}>
      {![undefined, 0].includes(data?.id) && (
        <TextButton
          title="Reset"
          disabled={resetButtonDisable}
          onClick={() => dispatch(resetRecTestStepAction(recTestStepTag,recTestStepList))}
          image={resetImage}
          icon={resetIcon}
        />
      )}
      <TextButton
        title="Save"
        disabled={saveButtonDisable}
        onClick={() => dispatch(saveRecTestStepActionWithCurrentDeviceImage(recTestStepTag, undefined))}
        image={saveImage}
        icon={saveIcon}
      />
      <TextButton
        disabled={performSaveButtonDisable}
        title={getPerformActionText(action, recTestStepId)}
        onClick={() => dispatch(performRecTestStepAction(recTestStepId, recTestStepTag))}
        image={getPerformActionText(action) && SaveAndPerformImage}
        icon={performSaveIcon}
      />
    </div>
  );
};

export default PerformRecTestStepAction;

function getPerformActionText(action, recTestStepId) {
  switch (action) {
    case ACTIONS:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case DEVICE_INTERACTION.HOME:
    case DEVICE_INTERACTION.BACK:
    case DEVICE_INTERACTION.ENTER:
    case ELEMENT_ACTION.SWIPE:
    case SWIPE_OVER_ELEMENT:
      return "Perform & Save";
    case VERIFICATIONS:
    case STEP:
      return "Verify & Save";
    case LAUNCH_APP:
      return "Launch App & Save";
    default:
      break;
  }
}
function _disableResetButton(recTestStep, unSavedRecTestStep, unSavedRecElement) {
  if (unSavedRecTestStep?.id === undefined) {
    return true;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recElements")) {
    return false;
  }
  if (unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    if (
      (isStepHavePrecondition(unSavedRecTestStep) || isStepHaveLoopStep(unSavedRecTestStep)) &&
      unSavedRecTestStep?.recTestStepProperty?.previous_recStepVerifyElementProperties.length === 0
    ) {
      return true;
    }
    return false;
  }
  if (
    recTestStepSettings.filter((recTestStepPropertyKey) =>
      unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
    ).length > 0
  ) {
    return false;
  }
  if (
    unSavedRecElement?.id &&
    recElementSettings.filter((recElementKey) => unSavedRecElement?.hasOwnProperty(recElementKey)).length > 0
  ) {
    return false;
  }
  if (unSavedRecElement?.id && unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    return false;
  }
  return true;
}

function disableSaveButton(recTestStep, unSavedRecTestStep, unSavedRecElement) {
  /**
   * Enable Condition
   * 1. Setting Changes [unsavedRecTestStep]
   * 2. Loop Condition Step : Verification Property Changes [unsavedRecTestStep]
   * 3. Pre-conditional Step : Verification Property Changes [unsavedRecTestStep]
   * 4. Verification Step : Verification Property Changes [unsavedRecTestStep]
   * 5. Other RecTestStep Properties Changes:
   * 6. Custom Element Property Changes [unsavedRecTestStep,unSavedRecElement]
   * 7. Element Setting Changes [unsavedRecTestStep,unSavedRecElement]
   * 8. Element Identification Rules : Verification Property Changes [unsavedRecTestStep,unSavedRecElement]
   *  */

  // Check for the Element Setting and Step Properties Changed
  if (
    (unSavedRecTestStep && unSavedRecElement === undefined) ||
    (unSavedRecTestStep &&
      unSavedRecElement &&
      (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT ||
        (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN &&
          unSavedRecElement.recElementLocatorProperties.length > 0 &&
          unSavedRecElement.recElementLocatorProperties.filter((recElementLocatorProperty) =>
            [0, undefined].includes(recElementLocatorProperty.id)
          ).length === 0)))
  ) {
    if (isStepHavePrecondition(unSavedRecTestStep)) {
      if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.preConditionTitle)) {
        return true;
      }
    }
    // Check Verification properties of Precondition Step,Loop Condition Step,Verifications
    if (recTestStep?.recTestStepProperty?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
      // Check change in Verification properties of PreCondition Step
      if (isStepHavePrecondition(unSavedRecTestStep)) {
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        return false;
      }
      // Check change in Verification properties of Loop Condition Step
      if (
        isStepHaveLoopStep(unSavedRecTestStep) &&
        (unSavedRecTestStep.recTestStepProperty?.addLoopCondition ||
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0)
      ) {
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        return false;
      }
      // Check change in Verification properties of Verifications Step
      if (unSavedRecTestStep.recTestStepProperty?.action === VERIFICATIONS) {
        if (
          unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        if (unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length === 0) {
          return true;
        }
        return false;
      }
    }

    // Change in RecTestStep Property Settings
    if (
      unSavedRecTestStep?.id &&
      recTestStepSettings.filter((recTestStepPropertyKey) =>
        unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
      ).length > 0
    ) {
      return false;
    }

    //Change in RecTestStep of Different operation
    if (
      [
        LAUNCH_APP,
        ELEMENT_ACTION.PAUSE,
        ELEMENT_ACTION.CAPTURE_SCREENSHOT,
        ELEMENT_ACTION.SWIPE,
        ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
      ].includes(unSavedRecTestStep?.recTestStepProperty?.action)
    ) {
      if (unSavedRecTestStep?.recTestStepProperty?.action === LAUNCH_APP) {
        return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.packageName);
      }
      if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.PAUSE) {
        return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.pauseTime);
      }
      if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.CAPTURE_SCREENSHOT) {
        return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.captureScreenShotDelayTime);
      }
      if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.SWIPE) {
        return (
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.direction) ||
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.percentage)
        );
      }
      if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA) {
        return (
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.insertValueToTestData) ||
          (isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestData) &&
            isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestDataId))
        );
      }
      return false;
    }

    if (unSavedRecElement?.id) {
      if (recElementSettings.filter((recElementKey) => unSavedRecElement?.hasOwnProperty(recElementKey)).length > 0) {
        return false;
      }
      if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
        if (
          unSavedRecElement?.recStepVerifyElementProperties?.length > 0 &&
          !isValidVerificationProperties(unSavedRecElement?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        return false;
      }
    }
    if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
      if (
        (unSavedRecElement?.testData === undefined || unSavedRecElement?.useTestData === 0) &&
        isNullOrUndefinedOrEmpty(unSavedRecElement?.searchValue)
      ) {
        return true;
      }
      if (
        (unSavedRecElement?.testData && unSavedRecElement?.useTestData === 1) ||
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.searchValue)
      ) {
        return false;
      }
    }
    if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
      if (
        (unSavedRecElement?.testData === undefined || unSavedRecElement?.useTestData === 0) &&
        isNullOrUndefinedOrEmpty(unSavedRecElement?.searchValue)
      ) {
        return true;
      }
      if (
        (unSavedRecElement?.testData && unSavedRecElement?.useTestData === 1) ||
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecElement?.searchValue)
      ) {
        return false;
      }
    }
  }

  // Change in RecElement of SELECT_IMAGE_ON_SCREEN
  if (
    unSavedRecElement &&
    unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN &&
    unSavedRecElement?.id
  ) {
    //Change in RecELement Setting
    if (recElementSettings.filter((recElementKey) => unSavedRecElement?.hasOwnProperty(recElementKey)).length > 0) {
      return false;
    }
    //Change in RecTestStep Properties
    if (
      recTestStepSettings.filter((recTestStepPropertyKey) =>
        unSavedRecTestStep?.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
      ).length > 0
    ) {
      return false;
    }
  }
  return true;
}

function disablePerformAndSave(recTestStep, unSavedRecTestStep, unSavedRecElement, selectedDeviceByUdid) {
  /**
   * 1. New Element
   * 2. Update Element
   * 3. Custom New Element
   */
  if (unSavedRecElement && selectedDeviceByUdid) {
    if (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
      // Check for New Element
      if (
        unSavedRecElement.recElementLocatorProperties.filter((recElementLocatorProperty) =>
          [0, undefined].includes(recElementLocatorProperty.id)
        ).length === 0
      ) {
        if (
          recTestStepSettings.filter((recTestStepPropertyKey) =>
            recTestStep.recTestStepProperty?.hasOwnProperty(recTestStepPropertyKey)
          ).length > 0
        ) {
          return true;
        }
        if (recElementSettings.filter((recElementKey) => unSavedRecElement.hasOwnProperty(recElementKey)).length > 0) {
          return true;
        }
        if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
          return true;
        }
      }

      // Check Element is selected on Device or not
      if (unSavedRecElement.recElementLocatorProperties.length === 0 || unSavedRecElement.recElementProperties === 0) {
        return true;
      }
      // Check Verification properties is partially added during recElement
      if (
        unSavedRecElement.recStepVerifyElementProperties.length > 0 &&
        !isValidVerificationProperties(unSavedRecElement.recStepVerifyElementProperties)
      ) {
        return true;
      }
      // Check Verification properties is partially added during Verification Step in Precondition
      if (isStepHavePrecondition(unSavedRecTestStep)) {
        if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.preConditionTitle)) {
          return true;
        }
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
      }
      // Check Verification properties is partially added during Verification Step in Loop Condition
      if (
        isStepHaveLoopStep(unSavedRecTestStep) &&
        (unSavedRecTestStep.recTestStepProperty?.addLoopCondition ||
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0)
      ) {
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
      }
      // Check Verification properties is partially added during Verification Step
      if (unSavedRecTestStep.recTestStepProperty?.action === VERIFICATIONS) {
        if (
          unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        if (unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length === 0) {
          return true;
        }
      }
    }
    // For Custom Element
    if (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
      if (unSavedRecTestStep.id === undefined && selectedDeviceByUdid === undefined) {
        return true;
      }
      // Check Verification properties is partially added during Verification Step in Precondition
      if (isStepHavePrecondition(unSavedRecTestStep)) {
        if (isNullOrUndefinedOrEmpty(unSavedRecTestStep.recTestStepProperty?.preConditionTitle)) {
          return true;
        }
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
      }
      // Check Verification properties is partially added during Verification Step in Loop Condition
      if (
        isStepHaveLoopStep(unSavedRecTestStep) &&
        (unSavedRecTestStep.recTestStepProperty?.addLoopCondition ||
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0)
      ) {
        if (unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0) {
          return true;
        }
        if (
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
      }
      // Check Verification properties is partially added during Verification Step
      if (unSavedRecTestStep.recTestStepProperty?.action === VERIFICATIONS) {
        if (
          unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length > 0 &&
          !isValidVerificationProperties(unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        if (unSavedRecTestStep?.recTestStepProperty?.recStepVerifyElementProperties?.length === 0) {
          return true;
        }
      }
      if (
        (unSavedRecElement?.testData === undefined || unSavedRecElement?.useTestData === 0) &&
        isNullOrUndefinedOrEmpty(unSavedRecElement?.searchValue)
      ) {
        return true;
      }
      if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
        if (
          unSavedRecElement?.recStepVerifyElementProperties?.length > 0 &&
          !isValidVerificationProperties(unSavedRecElement?.recStepVerifyElementProperties)
        ) {
          return true;
        }
        return false;
      }
    }
    if (unSavedRecElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
      if (unSavedRecTestStep.id === undefined && selectedDeviceByUdid === undefined) {
        return true;
      }
      if (
        isNullOrUndefinedOrEmpty(unSavedRecElement?.selectedImageCoordinates) ||
        isNullOrUndefinedOrEmpty(unSavedRecElement?.elementImage)
      ) {
        return true;
      }
    }
    return false;
  }
  // Operation properties if empty need to handle
  if (
    selectedDeviceByUdid &&
    [
      LAUNCH_APP,
      ELEMENT_ACTION.PAUSE,
      ELEMENT_ACTION.CAPTURE_SCREENSHOT,
      ELEMENT_ACTION.SWIPE,
      ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA,
    ].includes(unSavedRecTestStep?.recTestStepProperty?.action)
  ) {
    if (unSavedRecTestStep?.recTestStepProperty?.action === LAUNCH_APP) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.packageName);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.PAUSE) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.pauseTime);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.CAPTURE_SCREENSHOT) {
      return isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.captureScreenShotDelayTime);
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.SWIPE) {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.direction) ||
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.recSwipeAttributes?.percentage)
      );
    }
    if (unSavedRecTestStep?.recTestStepProperty?.action === ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA) {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.insertValueToTestData) ||
        (isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestData) &&
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.outputTestDataId))
      );
    }
    return false;
  }
  return true;
}
