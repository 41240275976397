import { Col, Empty, Row } from "antd";
import React from "react";
import { TEST_PLAN_RESULT_TYPE } from "../../../../Constants";
import SvgLoader from "../../../../Util/SvgLoader";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getDescriptionText = (elementDetail) => {
  if (
    (elementDetail?.testExecutionElementProperties && elementDetail?.testExecutionElementProperties.length > 0) ||
    elementDetail?.selectedImageCoordinates
  ) {
    return (
      <span>
        <FontAwesomeIcon className="vertical-align-top" icon={faCheckCircle} color="#12bf24" />
        {" Passed : Image not captured!"}
      </span>
    );
  } else {
    return "Element not found";
  }
};

const ImageEvidenceTab = (props) => {
  const { failureImage, result, testExecutionElementDetail, preconditionResult,aiGeneratedRecordedStep,aiGeneratedRecordedPagesource,aiGeneratedRecordedScreenshot,isAiStep} = props;

  const isFailureImage = (failureImage, result, preconditionResult) => {
    if (
      failureImage &&
      preconditionResult !== TEST_PLAN_RESULT_TYPE.PASSED &&
      (result === TEST_PLAN_RESULT_TYPE.FAILED ||
        result === TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED ||
        result === TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET ||
        result === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED)
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    testExecutionElementDetail && (
      <div className="w-100">
        <Row className="w-100">
          <Col className="text-align-left" xs={24} sm={24} md={24} lg={24} xl={24}>
            <span className="font-weight-600">Element Name : </span>
            <span>{testExecutionElementDetail.name}</span>
          </Col>
        </Row>
        {isAiStep===1? <Row className="w-100 mt-10">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span className="font-weight-600">Recorded Image</span>
            <div>
              {aiGeneratedRecordedScreenshot ? (
                <img src={aiGeneratedRecordedScreenshot} className="recorded_img" alt=""></img>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
            <div>
              {aiGeneratedRecordedPagesource && (
                <a href={aiGeneratedRecordedPagesource} target="_blank">
                  Recorded Page Source Log
                </a>
              )} 
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span className="font-weight-600">Execution Image</span>
            <div>
              {isFailureImage(failureImage, result, preconditionResult) ? (
                <img src={failureImage} alt="" height="500" width="300"></img>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={getDescriptionText(testExecutionElementDetail)}
                />
              )}
            </div>
          </Col>
        </Row>
        :""}
        {isAiStep===0?
        <Row className="w-100 mt-10">
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span className="font-weight-600">Recorded Image</span>
            <div>
              {testExecutionElementDetail && testExecutionElementDetail.elementImage ? (
                <img src={testExecutionElementDetail.elementImage} className="recorded_img" alt=""></img>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <span className="font-weight-600">Execution Image</span>
            <div>
              {isFailureImage(failureImage, result, preconditionResult) ? (
                <img src={failureImage} alt="" height="500" width="300"></img>
              ) : (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={getDescriptionText(testExecutionElementDetail)}
                />
              )}
            </div>
          </Col>
        </Row>:""
        }
      </div>
    )
  );
};

export default ImageEvidenceTab;
