import { Popover, Spin, Collapse, Row, Col, DatePicker } from "antd";
import moment from "moment";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { fetchExecutionReports } from "../../actions/ExecutionReportsAction";
import { ANDROID_LOGO, DOWN_ARROW_ICON, RIGHT_ARROW_ICON } from "../../CdnImagePath";
import { dateHourMinuteFormat, defaultDateTimeFormat, USAGE_REPORT } from "../../Constants";
import { getCurrentMonthRange, getMomentDateRange } from "../../Util";
import DatePickerBox from "../CommonComponents/DatePickerBox";
import PaginationComponent from "../CoreComponents/PaginationComponent/PaginationComponent";
import HeaderComponent from "../Header/Header";
import { Label } from "../CoreComponents/StyledComponent/StyledComponents";
import TestPlanResult from "../TestPlan/TestPlanResult/TestPlanResultLists/TestPlanResult";
import { getTestPlanAndScenarioResultList } from "../../actions/TestPlanAction";

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const TotalHoursDive = styled.div`
  align-self: end;
  font-weight: bolder;
  font-size: large;
`;

const StyledCollapse = styled(Collapse)`
  .ant-collapse-item {
    margin-bottom: 0px !important;
    border-bottom: none !important;
  }
`;

const StyledCol = styled(Col)`
  margin: 10px 20px 10px 20px;
  border: 1.2px solid #c3c5d2;
  border-radius: 10px 10px 0px 0px;
`;

const StyledHeader = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 0px;
  background: #f6f6f6;
  font-weight: 500;
  font-size: 12px;
  color: #4a4c69;
  border-radius: 10px 10px 0px 0px;
`;

const ExecutionReport = (props) => {
  const dispatch = useDispatch();
  const { executionReportsData, totalPageItems, currentPage, totalPage, totalItems, loading, totalExecutionHours } = useSelector(
    (state) => state.ExecutionReportReducer
  );
  const orgId = props?.match?.params?.organizationId;
  const [dateRange, setDateRange] = useState(["", ""]);

  useEffect(() => {
    dispatch(fetchExecutionReports(orgId, moment(getCurrentMonthRange()[0]).format(dateHourMinuteFormat), moment(getCurrentMonthRange()[1]).format(dateHourMinuteFormat)));
  }, []);

  const showDeviceList = (devices) => {
    return (
      <Popover
        content={devices?.map((i, index) => (
          <>
            <img src={ANDROID_LOGO} alt="android" className="mr-10 vertical-align-inherit" />
            <span className="text-light1 font-size-14 font-weight-500">{i?.brandName}</span>
            <div className={devices?.length - 1 !== index ? "label borderForDevice mb-10" : "label mb-10"}>
              Device ID: <span className="text">{i?.targetUniqueId}</span>
            </div>
          </>
        ))}
        title="Devices"
        placement="bottom"
        overlayClassName={devices?.length > 3 && "showDeviceDetails"}
      >
        <span className="font-color-blue cursor-pointer font-weight-medium ml-5">{devices?.length}</span>
      </Popover>
    );
  };

  return (
    <>
      <HeaderComponent
        showHumbergerMenu={true}
        showCloseSession={false}
        buttonState={USAGE_REPORT}
        organizationId={orgId}
      />
      <div className="display-flex justify-space-between">
        <h1 className="pageHeader ml-5">Execution Report</h1>
      </div>
      <Spin spinning={loading} className="spinner-center">
        <div className="ml-10 mr-10 pageScroll">
          <div className="display-flex justify-space-between align-items-center ml-20 mr-20">
            <TotalHoursDive>Total Execution Hours: {totalExecutionHours}</TotalHoursDive>
            <div className="">
              <RangePicker
                className="w-100"
                showTime={false}
                format={defaultDateTimeFormat}
                onChange={(dateStrings) => {
                  setDateRange(dateStrings !== null ? getMomentDateRange(dateStrings[0], dateStrings[1]) : [], []);
                  dispatch(
                    fetchExecutionReports(
                      orgId,
                      dateStrings && dateStrings[0] && moment(dateStrings[0]).format(dateHourMinuteFormat),
                      dateStrings && dateStrings[0] && moment(dateStrings[1]).format(dateHourMinuteFormat)
                    )
                  );
                }}
                defaultValue={[moment(getCurrentMonthRange()[0], defaultDateTimeFormat), moment(getCurrentMonthRange()[1], defaultDateTimeFormat)]}
              />
            </div>
          </div>
          {!_.isEmpty(executionReportsData) && (
            <Row justify="end" className="mr-20 mt-10">
              <PaginationComponent
                currentPageItems={totalPageItems}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPage={totalPage}
                callPaginationAPI={(pageNumber) =>
                  dispatch(
                    fetchExecutionReports(
                      orgId,
                      dateRange && dateRange[0] && moment(dateRange[0]).format("YYYY-MM-DD HH:mm:ss"),
                      dateRange && dateRange[0] && moment(dateRange[1]).format("YYYY-MM-DD HH:mm:ss"),
                      pageNumber
                    )
                  )
                }
              />
            </Row>
          )}

          <StyledCol>
            <StyledHeader>
              <div className="ml-20">Projects</div>
            </StyledHeader>
            <StyledCollapse
              accordion
              expandIconPosition={"start"}
              expandIcon={({ isActive }) =>
                isActive ? <img src={DOWN_ARROW_ICON} alt="down" /> : <img src={RIGHT_ARROW_ICON} alt="arrow" />
              }
              onChange={(e) => {
                e !== undefined &&
                  dispatch(
                    getTestPlanAndScenarioResultList(executionReportsData?.find((i, index) => index == e)?.projectId,true)
                  );
              }}
            >
              {!_.isEmpty(executionReportsData) &&
                executionReportsData.map((data, i) => {
                  return (
                    <>
                      <Panel
                        key={i}
                        header={
                          <div className="display-flex justify-space-betweens">
                            <div className="text">
                              <span className="font-color-darkBlue font-weight-500 font-size-14">
                                {data?.projectName}
                              </span>
                              <Row>
                                <Col span={24}>
                                  <Label>Execution Hours: </Label>
                                  <span>{data?.executionUsage}</span>
                                </Col>
                                <Col span={24}>
                                  <Label>Executed on devices: </Label>
                                  <span>
                                    {data?.deviceList ? showDeviceList(data?.deviceList) : data?.deviceList?.length}
                                  </span>
                                </Col>
                                <Col span={24}>
                                  <Label>Total scenarios: </Label>
                                  <span>{data?.totalScenarios}</span>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        }
                      >
                        <TestPlanResult executionReportProjectId={data?.projectId} executionReportOrgId={orgId} />
                      </Panel>
                      <div className="p-5 bg-color-whiteGray"></div>
                    </>
                  );
                })}
            </StyledCollapse>
          </StyledCol>
        </div>
      </Spin>
    </>
  );
};

export default ExecutionReport;
