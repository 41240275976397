import { Button, Modal, Select } from "antd";
import { default as React, useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { inviteMember } from "../../actions/ProjectsAction";
import { addMemberToTeam } from "../../actions/TeamsAction";
import { setLoaderForInvitation } from "../../actions/InvitationAction";
import { showNotification } from "../../Util";

const { Option } = Select;

export const InvitationLabel = styled.h2`
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
`;

export const InvitationModal = styled(Modal)`
  .ant-modal-content {
  }
  .ant-modal-footer {
    padding: 12px;
  }
  .ant-input {
    border-radius: 7px;
    margin-bottom: 10px;
  }
`;

const InviteMember = (props) => {
  const { modalStatus, modalClosed, projectId, teamId, orgId } = props;
  const [selectedMembers, setSelectedMembers] = useState([]);
  const dispatch = useDispatch();
  let { inviteMembers } = useSelector((state) => state.ProjectsReducer);
  const { invitationLoader } = useSelector((state) => state.InvitationReducer);

  useEffect(() => {
    if (inviteMembers) {
      handleCloseModal();
    }
  }, [modalClosed, inviteMembers]);

  const handleCloseModal = () => {
    setSelectedMembers([]);
    dispatch(setLoaderForInvitation({ data: false }));
    modalClosed();
  };
  const handleSaveButton = () => {
    if (selectedMembers.length > 0) {
      if (projectId) {
        let preparedObject = {
          inviteeMail: selectedMembers,
          projectId: projectId && projectId,
        };
        dispatch(inviteMember(preparedObject));
      } else {
        let preparedObject = {
          teamMembers: selectedMembers,
          teamId: teamId && teamId,
          orgId: orgId
        };
        dispatch(addMemberToTeam(preparedObject));
        // dispatch(inviteMember(preparedObject));
      }
    } else {
      showNotification("error", "Please Select Member.", "");
    }
    modalClosed();
  };

  const children = [];
  const { myOrgUsers } = useSelector((state) => state.TeamsReducer);
  if (myOrgUsers) {
    for (const element of myOrgUsers) {
      children.push(<Option key={element.email}>{element.email}</Option>);
    }
  }
  function handleChange(value) {
    setSelectedMembers(value);
  }
  return (
    <>
      <InvitationModal
        title=<span className="mobile-title">Invite Members</span>
        visible={modalStatus}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <Button
            key="submit"
            className="orange font-size-12"
            type="primary"
            loading={invitationLoader}
            onClick={() => handleSaveButton()}
            disabled={_.isEmpty(selectedMembers)}
          >
            Invite
          </Button>,
        ]}
      >
        <InvitationLabel>
          Members<span className="text-danger">*</span>
        </InvitationLabel>
        <Select
          className="w-100"
          mode="tags"
          placeholder="Add Members by Email"
          value={selectedMembers}
          onChange={handleChange}
        >
          {children}
        </Select>
      </InvitationModal>
    </>
  );
};

export default InviteMember;
