import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Divider, Popover, Spin, Tooltip } from "antd";
import { isEmpty } from "lodash";
import {
  TEST_PLAN_LOADING,
  TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET,
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
} from "../../../../Constants";
import {
  customDateRangeFilterList,
  deleteModal,
  getSelectedJobStatus,
  isSelectedAllIds,
  removeSelectedIds,
} from "../../../../Util";
import {
  abortTestPlanRunResult,
  changeTestPlanResultListPageNumber,
  deleteTestPlanRunResult,
  getTestPlanAndScenarioResultList,
  handleChangeReportManagerSearch,
  resetReportManagerSearch,
} from "../../../../actions/TestPlanAction";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonHeaderV2 from "../../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import DatePickerBox from "../../../CommonComponents/DatePickerBox";
import SelectAllBoxV2 from "../../../CommonComponents/SelectAllBoxV2/SelectAllBoxV2";
import CustomDatePicker from "../../../CoreComponents/CustomDatePicker/CustomDatePicker";
import FilterComponent from "../../../CoreComponents/FilterComponent/FilterComponent";
import PaginationComponentV2 from "../../../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../../../CoreComponents/SearchComponent/CommonSearchV2";
import {
  AbortTag,
  BadgeStyle,
  FailTag,
  InQueueTag,
  PassTag,
  RunningTag,
  UploadingResultTag,
} from "../../../CoreComponents/StyledComponent/StyledComponents";
import TableComponentV2 from "../../../CoreComponents/TableComponent/TableComponentV2";
import TestPlanResultDeviceDetails from "../../../TestPlan/TestPlanResultDetails/TestPlanResultDeviceDetails";
import InfoJobDetailsColumnV2 from "./TestPlanResultColumnsV2/InfoJobDetailsColumnV2";
import TestCoverageDetailsColumnV2 from "./TestPlanResultColumnsV2/TestCoverageDetailsColumnV2";
import TestPlanDetailsColumnV2 from "./TestPlanResultColumnsV2/TestPlanDetailsColumnV2";
import TestPlanResultColumnV2 from "./TestPlanResultColumnsV2/TestPlanResultColumnV2";
import SvgLoader from "../../../../Util/SvgLoader";
import { ABORT_ICON, REPORT_DETAILS_ICON, TRASH_ICON } from "../../../../Constants/SvgConstants";
import styles from "./TestPlanResultV2.module.scss";

export const resultTag = (resultObj, row) => {
  const labels = [];
  let pendingScenarioToCreate = row && row?.testCoverageAcrossDevices?.scenarioCount;
  let totalProcessed = 0;

  if (resultObj) {
    if (resultObj?.IN_PROGRESS) {
      labels.push(
        <RunningTag key="IN_PROGRESS">
          <BadgeStyle color="#306a9f" />
          {resultObj?.IN_PROGRESS} {TEST_PLAN_RESULT_TYPE?.IN_PROGRESS_TXT}
        </RunningTag>
      );
      totalProcessed = resultObj?.IN_PROGRESS + totalProcessed;
    }

    if (resultObj?.Passed) {
      labels.push(
        <PassTag key="Passed">
          <BadgeStyle color="#297042" />
          {resultObj?.Passed} {TEST_PLAN_RESULT_TYPE?.PASSED}
        </PassTag>
      );
      totalProcessed = resultObj?.Passed + totalProcessed;
    }

    if (resultObj?.ABORTED) {
      labels.push(
        <AbortTag key="ABORTED">
          <BadgeStyle color="#7D5E0F" />
          {resultObj?.ABORTED} {TEST_PLAN_RESULT_TYPE?.ABORTED}
        </AbortTag>
      );
      totalProcessed = resultObj?.ABORTED + totalProcessed;
    }
    if (resultObj?.TERMINATED) {
      labels.push(
        <AbortTag key="ABORTED">
          <BadgeStyle color="#7D5E0F" />
          {resultObj?.TERMINATED} {TEST_PLAN_RESULT_TYPE?.TERMINATED}
        </AbortTag>
      );
      totalProcessed = resultObj?.TERMINATED + totalProcessed;
    }

    if (resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY}{" "}
          {TEST_PLAN_RESULT_TYPE?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
        </span>
      );
      totalProcessed = resultObj?.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY + totalProcessed;
    }

    if (resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED) {
      labels.push(
        <InQueueTag key="WAITING_FOR_DEVICE_TO_EXECUTED">
          <BadgeStyle color="#808080" />
          {resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED} {TEST_PLAN_RESULT_TYPE?.WAITING_FOR_DEVICE_TO_EXECUTED_TXT}
        </InQueueTag>
      );
      totalProcessed = resultObj?.WAITING_FOR_DEVICE_TO_EXECUTED + totalProcessed;
    }
    if (resultObj?.UPLOADING_RESULT) {
      labels.push(
        <UploadingResultTag key="UPLOADING_RESULT">
          <BadgeStyle />
          {resultObj?.UPLOADING_RESULT} {TEST_PLAN_RESULT_TYPE?.UPLOADING_RESULT_TXT}
        </UploadingResultTag>
      );
      totalProcessed = resultObj?.UPLOADING_RESULT + totalProcessed;
    }

    if (resultObj?.Failed) {
      labels.push(
        <FailTag key="Failed">
          <BadgeStyle color="#b43131" />
          {resultObj?.Failed} {TEST_PLAN_RESULT_TYPE?.FAILED}
        </FailTag>
      );
      totalProcessed = resultObj?.Failed + totalProcessed;
    }

    if (resultObj?.Skipped) {
      labels.push(
        <span key="Skipped" className="skipped-text ml-5">
          {resultObj?.Skipped} {TEST_PLAN_RESULT_TYPE?.SKIPPED}
        </span>
      );
      totalProcessed = resultObj?.Skipped + totalProcessed;
    }

    if (resultObj?.CONTINUE_AFTER_FAILED) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.CONTINUE_AFTER_FAILED} {TEST_PLAN_RESULT_TYPE?.CONTINUE_AFTER_FAILED}
        </span>
      );
      totalProcessed = resultObj?.CONTINUE_AFTER_FAILED + totalProcessed;
    }

    if (resultObj?.SCHEDULED) {
      labels.push(
        <span key="CONTINUE_AFTER_FAILED" className="CONTINUE_AFTER_FAILED-text ml-5">
          {resultObj?.SCHEDULED} {TEST_PLAN_RESULT_TYPE?.SCHEDULED}
        </span>
      );
      totalProcessed = resultObj?.CONTINUE_AFTER_FAILED + totalProcessed;
    }

    if (pendingScenarioToCreate - totalProcessed > 0) {
      labels.push(
        <InQueueTag>
          <BadgeStyle color="#808080" />
          {pendingScenarioToCreate - totalProcessed} Preparing For Execution
        </InQueueTag>
      );
    }
  }
  return labels;
};

const TestPlanResultV2 = (props) => {
  const dispatch = useDispatch();
  const {
    testPlanResultData,
    currentTestPlanResultPage,
    totalTestPlanResultPage,
    testPlanLoading,
    totalPageItem,
    reportManagerSearch,
    totalTestPlanResultItems,
  } = useSelector((state) => state.TestPlanReducer);
  const { projectId, executionReportProjectId, executionReportOrgId } = props;
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [isCustomRangePicker, setIsCustomRangePicker] = useState(false);
  const [selectAllRun, setSelectAllRun] = useState([]);
  const [openDeleteTestReportsModal, setOpenDeleteTestReportsModal] = useState({ visible: false, id: null });
  const [openAbortTestReportModal, setOpenAbortTestReportModal] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const intervalRef = useRef(null);

  useEffect(() => {
    dispatch(resetReportManagerSearch());
    dispatch(changeTestPlanResultListPageNumber(1));
    dispatch({ type: TEST_PLAN_LOADING, response: { isLoading: true } });
    if (!executionReportProjectId) {
      dispatch(getTestPlanAndScenarioResultList(projectId, "", true, pageSize));
    }
    setSelectAllRun([]);
  }, [projectId, pageSize]);

  useEffect(() => {
    if (testPlanResultData && !isEmpty(testPlanResultData) && !executionReportProjectId) {
      intervalRef.current = setInterval(
        () => dispatch(getTestPlanAndScenarioResultList(projectId, "", "", pageSize)),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [testPlanResultData]);

  const deleteConfirm = (id, name, testRailTestRunId) => {
    setOpenDeleteTestReportsModal({ visible: true, id });
  };

  const handleHideDeleteOnInProgress = (runResultDetails) => {
    if (
      (runResultDetails?.runResult === "ABORTED" || runResultDetails?.runResult === "DONE") &&
      !runResultDetails?.resultObj?.UPLOADING_RESULT
    ) {
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Delete">
          <div className={styles["test_Reports__Buttons"]}>
            <SvgLoader
              iconName={TRASH_ICON}
              handleClick={() =>
                deleteConfirm(
                  runResultDetails?.runId,
                  runResultDetails?.testJobExecName,
                  runResultDetails.testRailTestRunId
                )
              }
            />
          </div>
        </Tooltip>
      );
    } else if (
      runResultDetails?.runResult &&
      ["IN_PROGRESS", "TERMINATED", "ASSIGNED_TO_DEVICES", "PREPARING_RUN_TO_EXECUTE"].includes(
        runResultDetails?.runResult
      )
    ) {
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Abort">
          <div className={styles["test_Reports__Buttons"]}>
            <SvgLoader iconName={ABORT_ICON} handleClick={() => setOpenAbortTestReportModal(runResultDetails.runId)} />
          </div>
        </Tooltip>
      );
    }
  };

  const openTestPlanResultDetails = (data, orgId) => {
    dispatch({ type: TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET });
    const url = `/${orgId || executionReportOrgId}/project/${
      projectId || executionReportProjectId
    }/TestPlanResultDetails/${data.RunNumber}`;
    var win = window.open(url, "_blank");
    win.focus();
  };

  const checkIsNotInProgress =
    testPlanResultData &&
    testPlanResultData?.filter(
      (j) =>
        j?.runResult &&
        (["DONE", "IN_QUEUE"].includes(j?.runResult) ||
          (j?.runResult === "ABORTED" &&
            (j?.scenarios === undefined ||
              j?.scenarios?.filter((scenario) =>
                ["WAITING_FOR_DEVICE_TO_EXECUTED", "IN-PROGRESS"].includes(scenario.state)
              ).length === 0)))
    );

  const showDeviceList = (devices, deviceServiceFarm) => {
    return (
      <Popover
        content={devices?.map((i, index) => {
          return (
            <TestPlanResultDeviceDetails
              deviceResultDetails={{
                targetUniqueId: i?.targetUniqueId,
                brandName: i?.brand,
                name: i?.name,
                locale: i?.locale,
                platformName: i?.platformName,
                osVer: i?.osVersion,
                devicesLength: devices?.length,
                currentIndex: index,
              }}
              deviceServiceFarm={deviceServiceFarm}
            />
          );
        })}
        title="Devices"
        placement="bottom"
        overlayClassName={devices?.length > 3 && "showDeviceDetails"}
      >
        <span className={styles.deviceListPopover}>{devices?.length}</span>
      </Popover>
    );
  };

  const filterList = [
    {
      title: "All Jobs",
      name: "",
    },
    {
      title: "Running Jobs",
      name: "IN_PROGRESS",
    },
    {
      title: "In-Queue Jobs",
      name: "IN_QUEUE",
    },
    {
      title: "Completed Jobs",
      name: "DONE",
    },
  ];

  const columns = [
    {
      title: (
        <div className={styles["checkBox-in-header"]}>
          {isEmpty(selectAllRun) && !executionReportProjectId && !isEmpty(checkIsNotInProgress) && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllRun([
                      ...selectAllRun,
                      ...checkIsNotInProgress
                        ?.filter((i) => !selectAllRun.includes(i?.runId) && i?.runId)
                        ?.map((i) => i?.runId),
                    ]);
                  } else {
                    setSelectAllRun(
                      removeSelectedIds(
                        selectAllRun,
                        checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                      )
                    );
                  }
                }}
              />
            </span>
          )}
          Job Info
        </div>
      ),
      dataIndex: "jobInfo",
      width: "30%",
    },
    {
      title: "Test Plan",
      dataIndex: "testPlan",
    },
    {
      title: "Test Coverage",
      dataIndex: "testCoverage",
    },
    {
      title: "Results",
      dataIndex: "results",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const testResultDetails =
    testPlanResultData &&
    testPlanResultData?.map((row) => ({
      key: row?.runId,
      jobInfo: (
        <InfoJobDetailsColumnV2
          row={row}
          openTestPlanResultDetails={openTestPlanResultDetails}
          showDeviceList={showDeviceList}
          selectAllRun={selectAllRun}
          setSelectAllRun={setSelectAllRun}
          orgId={props.orgId}
        />
      ),
      testCoverage: <TestCoverageDetailsColumnV2 row={row} showDeviceList={showDeviceList} />,
      testPlan: <TestPlanDetailsColumnV2 row={row} />,
      results: <TestPlanResultColumnV2 row={row} />,
      action: (
        <div className={styles.actionIcon}>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Report Details">
            <div>
              <SvgLoader
                iconName={REPORT_DETAILS_ICON}
                handleClick={() => openTestPlanResultDetails(row, props.orgId)}
              />
            </div>
          </Tooltip>
          {handleHideDeleteOnInProgress(row)}
        </div>
      ),
    }));

  const generateModalProps = () => {
    if (openAbortTestReportModal) {
      return {
        handleOk: () => {
          dispatch(abortTestPlanRunResult({ runId: openAbortTestReportModal }, projectId, executionReportProjectId));
          setOpenAbortTestReportModal(null);
        },
        handleCancel: () => setOpenAbortTestReportModal(null),
        modalOpen: !!openAbortTestReportModal,
        modalContent: "Are you sure you want to abort this item?",
        modalTitle: "Confirm Abort",
        okText: "Abort",
      };
    } else if (openDeleteTestReportsModal?.visible) {
      return {
        handleOk: () => {
          dispatch(
            deleteTestPlanRunResult(
              {
                runIds: openDeleteTestReportsModal?.id ? [openDeleteTestReportsModal?.id] : selectAllRun,
                removeFromTestRail: true,
                projectId: projectId,
              },
              projectId,
              executionReportProjectId,
              setSelectAllRun
            )
          );
          setOpenDeleteTestReportsModal({ visible: false, id: null });
        },
        handleCancel: () => setOpenDeleteTestReportsModal({ visible: false, id: null }),
        modalOpen: openDeleteTestReportsModal?.visible,
        modalContent: `Are you sure you want to delete ${
          selectAllRun && selectAllRun.length > 1 ? "these items?" : "this item?"
        } This action cannot be undone.`,
        modalTitle: "Confirm Deletion",
        okText: "Delete",
      };
    }
  };

  return (
    <>
      <Spin spinning={testPlanLoading} tip="Loading" className={styles["spinner_Center"]}>
        {!executionReportProjectId && <CommonHeaderV2 headerText={"Test Reports"} />}
        <div>
          {!executionReportProjectId && (
            <div className={styles["searchBox"]}>
              <div>
                <CommonSearchV2
                  placeHolder="Search By Run Number, Test Plan Name"
                  searchDetails={(searchText) => {
                    dispatch(handleChangeReportManagerSearch({ ...reportManagerSearch, searchKeyword: searchText }));
                    dispatch(getTestPlanAndScenarioResultList(projectId, true, "", pageSize));
                  }}
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
              <div className={styles.datePickerBox}>
                {isCustomRangePicker ? (
                  <DatePickerBox
                    dateRange={[reportManagerSearch?.startDate, reportManagerSearch?.endDate]}
                    dateChange={(dateStrings) => {
                      dispatch(
                        handleChangeReportManagerSearch({
                          ...reportManagerSearch,
                          startDate: dateStrings !== null ? dateStrings[0] : "",
                          endDate: dateStrings !== null ? dateStrings[1] : "",
                        })
                      );
                      dateStrings === null && setIsCustomRangePicker(false);
                      dispatch(getTestPlanAndScenarioResultList(projectId, true, "", pageSize));
                    }}
                  />
                ) : (
                  <CustomDatePicker
                    filterList={customDateRangeFilterList}
                    handleChangeCustomDateFilter={(date) => {
                      dispatch(
                        handleChangeReportManagerSearch({
                          ...reportManagerSearch,
                          startDate: date !== "custom" ? date[0] : "",
                          endDate: date !== "custom" ? date[1] : "",
                        })
                      );
                      date === "custom"
                        ? setIsCustomRangePicker(true)
                        : dispatch(getTestPlanAndScenarioResultList(projectId, true, "", pageSize));
                    }}
                  />
                )}
              </div>
              <FilterComponent
                filterList={filterList}
                handleFilterChange={(filter) => {
                  dispatch(changeTestPlanResultListPageNumber(1));
                  dispatch(
                    handleChangeReportManagerSearch({
                      ...reportManagerSearch,
                      status: filter,
                    })
                  );
                  dispatch(getTestPlanAndScenarioResultList(projectId, true, "", pageSize));
                }}
                selectedFilter={getSelectedJobStatus(reportManagerSearch?.status)}
              />
            </div>
          )}
          <Divider className="m-0" />
          <div className="table_Details_scroll">
            <div className="table_Details__wrapper">
              <div className={executionReportProjectId ? styles.selectBoxInReport : styles.selectAllBox}>
                {!executionReportProjectId && !isEmpty(selectAllRun) && (
                  <div className={styles["select--all-box"]}>
                    <SelectAllBoxV2
                      isChecked={isSelectedAllIds(
                        selectAllRun,
                        checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                      )}
                      isDisable={
                        isEmpty(testPlanResultData) ||
                        (testPlanResultData &&
                          testPlanResultData?.filter((j) => j?.runResult === "IN_PROGRESS")?.length ===
                            testPlanResultData?.length)
                      }
                      selectAllIds={selectAllRun}
                      handleChangeCheck={(isChecked) => {
                        if (isChecked) {
                          setSelectAllRun([
                            ...selectAllRun,
                            ...checkIsNotInProgress
                              ?.filter((i) => !selectAllRun.includes(i?.runId) && i?.runId)
                              ?.map((i) => i?.runId),
                          ]);
                        } else {
                          setSelectAllRun(
                            removeSelectedIds(
                              selectAllRun,
                              checkIsNotInProgress?.map((i) => ({ id: i?.runId }))
                            )
                          );
                        }
                      }}
                      deleteConfirm={deleteConfirm}
                    />
                  </div>
                )}
              </div>
              <TableComponentV2
                columns={columns}
                data={testResultDetails}
                selected={selectAllRun}
                className={`table__wrapper ${!isEmpty(selectAllRun) && " hide_column_name"}`}
              />
              <div className={`pagination_Section ${executionReportProjectId && styles.paginationInExecutionReport}`}>
                {!isEmpty(testPlanResultData) && (
                  <PaginationComponentV2
                    currentPageItems={totalPageItem}
                    totalItems={totalTestPlanResultItems}
                    currentPage={currentTestPlanResultPage}
                    totalPage={totalTestPlanResultPage}
                    callPaginationAPI={(pageNumber) => {
                      if (pageNumber !== currentTestPlanResultPage) {
                        dispatch(changeTestPlanResultListPageNumber(pageNumber));
                        dispatch(getTestPlanAndScenarioResultList(projectId, true, "", pageSize));
                      }
                    }}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                  />
                )}
              </div>
            </div>
          </div>
          <CommonConfirmationModalV2 {...generateModalProps()} isDeleteModal={true} />
        </div>
      </Spin>
    </>
  );
};
export default TestPlanResultV2;
