import { Row, Collapse, Select, Button, Tabs } from "antd";
import { default as React, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { useDispatch, useSelector, connect } from "react-redux";
import styled from "styled-components";
import * as commonActions from "../../actions/CommonActions";
import * as actions from "../../actions/TeamsAction";
import { xpressComponentType } from "../../Constants";
import { getMyTeamMembers, myProjectsFromTeamId } from "../../actions/ProjectsAction";

import { getMyOrgUsers } from "../../actions/TeamsAction";

import ProjectCards from "./ProjectCards";

import MembersByTeam from "./MembersByTeam";
import AddMemberToTeam from "./AddMemberToTeam";
import { Link } from "react-router-dom";
import { Title } from "../CoreComponents/StyledComponent/StyledComponents";
import BackButton from "../CoreComponents/BackButtonComponent/BackButton";
import history from "../../history";
import CreateProject from "./CreateProject";
import { XPRESS_LOGO } from "../../CdnImagePath";

const { Panel } = Collapse;
const { Option } = Select;
const { TabPane } = Tabs;

export const StyledProjectCollapse = styled(Collapse)`
  background: none;
  border: 0px;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
  .ant-collapse-content {
    border: 0px;
    background: none;
  }
  .ant-collapse-content-box {
    border: 0px;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    padding: 0px 30px;
    .ant-list {
      .ant-list-item {
        border: 0;
        padding: 8px 0;
        .ant-avatar {
          margin-right: 16px;
        }
      }
    }
  }

  .ant-collapse-item {
    border: 0px;

    .ant-collapse-header {
      color: black !important;
    }
  }
`;

export const StyledPanel = styled(Panel)`
  color: black;
  text-align: left;
  padding: 15px 30px 15px 15px;
  font-size: 14px;
  font-weight: 600;
`;

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #ce6d42;
    color: #fff;
  }
`;
export const MyProjectsByTeam = (props) => {
  const [modalStatus, setModalStatus] = useState(false);
  const [isCreateProject, setIsCreateProject] = useState(false);
  const [selectedTab, setSelectedTab] = useState("teamMembers");
  const orgId = props.match.params.organizationId;
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.match.params.teamID != null) {
      dispatch(myProjectsFromTeamId(props.match.params.teamID));
      dispatch(getMyTeamMembers(props.match.params.teamID));
    }
  }, []);

  useEffect(() => {
    if (props.route && props.route.componentId) {
      props.commonActions.getChildComponentByTypeAndParentId({
        componentType: xpressComponentType.rights,
        parentId: props.route.componentId,
      });
    }
    dispatch(getMyOrgUsers(props.match.params.organizationId));
  }, []);

  const { myProjectsList, myTeamMembers, teamNameOfProject } = useSelector((state) => state.ProjectsReducer);
  const children = [];
  const { myOrgUsers } = useSelector((state) => state.TeamsReducer);

  if (myOrgUsers) {
    for (let i = 0; i < myOrgUsers.length; i++) {
      children.push(<Option key={myOrgUsers[i].email}>{myOrgUsers[i].email}</Option>);
    }
  }

  const modalClosed = () => {
    setModalStatus(false);
  };

  const modalClick = () => {
    setModalStatus(true);
  };
  let removeUserRights =
    props.CommonReducer.childComponentList &&
    props.CommonReducer.childComponentList.filter((item) => item.name == "Remove_User").length > 0;

  return (
    <>
      <Row justify="space-between" align="middle" className="innerheader-fixed">
        <div className="display-flex align-items-center">
          <Link to="/">
            <a>
              <img src={XPRESS_LOGO} className={"logo"} alt="Company Logo" />
            </a>
          </Link>
          <span className="headerDetail ml-15">Development Team</span>
        </div>
        <div className="display-flex align-items-center">
          <div className="close-seperator">
            <BackButton handleBack={() => history.push(`/${orgId}/teams`)} />
          </div>
        </div>
      </Row>
      {isCreateProject ? (
        <CreateProject orgId={orgId} onClose={() => setIsCreateProject(false)} teamNameOfProject={teamNameOfProject} />
      ) : (
        <div className="mt-70 ">
          <div>
            <Tabs
              className="m-10"
              defaultActiveKey={"teamMembers"}
              activeKey={selectedTab}
              onChange={(e) => setSelectedTab(e)}
            >
              <TabPane tab={"Team Members"} key={"teamMembers"}>
                <div>
                  <div className="mb-20 display-flex justify-space-between">
                    <span className="font-color-darkBlue font-weight-700 font-size-22 ">Team Members</span>{" "}
                    <ButtonContainer>
                      <Button size="large" type="primary" onClick={() => modalClick()}>
                        Add member
                      </Button>
                    </ButtonContainer>
                  </div>
                  <MembersByTeam myTeamMembers={myTeamMembers} removeUserRights={removeUserRights} />
                </div>
              </TabPane>

              <TabPane tab={"Assigned Projects"} key={"assignedProjects"}>
                <div className="mb-20 display-flex justify-space-between">
                  <span className="font-color-darkBlue font-weight-700 font-size-22 ">Assigned Projects</span>
                  <Button size="large" className="orange" type="primary" onClick={() => setIsCreateProject(true)}>
                    Add Project
                  </Button>
                </div>
                <ProjectCards data={myProjectsList} orgId={orgId} teamNameOfProject={teamNameOfProject} />
              </TabPane>
            </Tabs>
          </div>
          <AddMemberToTeam modalClosed={modalClosed} modalStatus={modalStatus} children={children} />
        </div>
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    HomeReducer: state.HomeReducer,
    CommonReducer: state.CommonReducer,
    ServerListReducer: state.ServerListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProjectsByTeam);
