import _ from "lodash";
import { Button, Col, Input, Row, Select, Space, Spin, Tooltip } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addApplicationStringLocaleAndValue,
  addApplicationStrings,
  addApplicationStringsFormVisible,
  changeApplicationStringName,
  changeApplicationStringValue,
  deleteApplicationStringLocale,
  getListOfLocalesForApplicationStrings,
  selectApplicationStringLocale,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import styled from "styled-components";
import { StyledInput } from "../../CoreComponents/StyledComponent/StyledComponents";
import { resetApplicationStringsData } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import styles from "./AddApplicationStrings.module.scss";
import SvgLoader from "../../../Util/SvgLoader";
import { ICON_DELETE_WITH_BG } from "../../../Constants/SvgConstants";
import { PlusCircleOutlined } from "@ant-design/icons";
import { CONFIRM_MODAL_CONTENT, TOOLTIP_BG_COLOR } from "../../../Constants";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

export const StyledSelect = styled(Select)`
  border: 1px solid #b0b1c1 !important;
  border-radius: 5px;
  .ant-select-selector {
    width: 99% !important;
    border-radius: 5px !important;
  }
`;

const AddApplicationStrings = (props) => {
  const dispatch = useDispatch();

  const {
    getListOfLocales,
    applicationStringName,
    applicationStringValue,
    applicationStringIsImport,
    selectLocaleOfApplicationString,
    applicationStringLoader,
    editApplicationStringData,
    previous_editApplicationStringData,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const addDisableForEditApplicationString = editApplicationStringData?.every(
    (item) => !Object.values(item).some((value) => value === "")
  );

  const enableSaveApplicationStringButton = editApplicationStringData
    ? !_.isEqual(editApplicationStringData, previous_editApplicationStringData) && addDisableForEditApplicationString
    : applicationStringName !== undefined &&
      applicationStringValue !== undefined &&
      selectLocaleOfApplicationString !== undefined;

  const backButton = editApplicationStringData
    ? !_.isEqual(editApplicationStringData, previous_editApplicationStringData)
    : applicationStringName !== undefined ||
      applicationStringValue !== undefined ||
      selectLocaleOfApplicationString !== undefined;

  const onBackClick = () => {
    if (backButton) {
      setOpenConfirmModal(true);
    } else {
      dispatch(addApplicationStringsFormVisible(false));
    }
  };

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
  }, [dispatch]);

  return (
    <>
      <Spin spinning={applicationStringLoader} tip="Loading">
        <Row justify="space-between">
          <div className={styles.applicationText}>
            <b>{`${editApplicationStringData ? "Edit" : "Add"} Application Strings`}</b>
          </div>
          <Space size={10} className={styles.addOrCancelBUtton}>
            <Button
              disabled={!enableSaveApplicationStringButton}
              type="primary"
              className={styles.addButton}
              onClick={() =>
                dispatch(
                  addApplicationStrings(
                    applicationStringName,
                    applicationStringValue,
                    selectLocaleOfApplicationString,
                    editApplicationStringData ? editApplicationStringData : [],
                    applicationStringIsImport
                  )
                )
              }
            >
              {editApplicationStringData ? "Update" : "Add"}
            </Button>
            <Button onClick={() => onBackClick()}>Cancel</Button>
          </Space>
        </Row>

        <Row className={styles.content} justify="center">
          <Col xs={24} lg={9} className={styles.text}>
            <span className={styles.label}> Key Name</span>
            <div className={styles.applicationNameOrAddIcon}>
              <Input
                type="text"
                className={styles.applicationKeyInput}
                disabled={editApplicationStringData}
                value={applicationStringName}
                placeholder="Application String Name"
                onChange={(e) => dispatch(changeApplicationStringName(e.target.value))}
              />
              {editApplicationStringData && (
                <Button disabled={!addDisableForEditApplicationString}>
                  <Tooltip title={"Add Locale and value"} color={TOOLTIP_BG_COLOR}>
                    <PlusCircleOutlined
                      onClick={() =>
                        addDisableForEditApplicationString && dispatch(addApplicationStringLocaleAndValue())
                      }
                      style={{ fontSize: "150%" }}
                    />
                  </Tooltip>
                </Button>
              )}
            </div>
            {editApplicationStringData ? (
              <Col xs={24}>
                {editApplicationStringData?.map((row) => {
                  return (
                    <div className={styles.selectOrValue} key={row.id}>
                      <div className={styles.localeOrSelect}>
                        <span>Locale:</span>
                        <Select
                          showSearch={true}
                          className="selectApplicationLocale"
                          value={row?.locale}
                          placeholder="Select Application String Locale"
                          onChange={(e) => {
                            dispatch(selectApplicationStringLocale(e, row?.id));
                          }}
                        >
                          {getListOfLocales &&
                            getListOfLocales?.map((i) => (
                              <Select.Option value={i?.locales}>{i?.locales}</Select.Option>
                            ))}
                        </Select>
                      </div>
                      <div className={styles.inputValue}>
                        <span>Value:</span>
                        <Input
                          className={styles.applicationValue}
                          type="text"
                          value={row?.value}
                          placeholder="Application String Value"
                          onChange={(e) => dispatch(changeApplicationStringValue(e.target.value, row?.id))}
                        />
                      </div>
                      {editApplicationStringData.length > 1 && (
                        <Tooltip title="Remove Application Locale and Value" color={TOOLTIP_BG_COLOR}>
                          <div className={styles.applicationDelete}>
                            <SvgLoader
                              iconName={ICON_DELETE_WITH_BG}
                              handleClick={() => dispatch(deleteApplicationStringLocale(row?.id))}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </Col>
            ) : (
              <Col>
                <span className={styles.label}>Value</span>
                <StyledInput
                  className={styles.applicationValue}
                  type="text"
                  value={applicationStringValue}
                  placeholder="Application String Value"
                  onChange={(e) => dispatch(changeApplicationStringValue(e.target.value))}
                />
                <span className={styles.label}>Locale:</span>
                <Select
                  showSearch={true}
                  className="selectApplicationLocale"
                  value={selectLocaleOfApplicationString}
                  placeholder="Select Application String Locale"
                  onChange={(e) => {
                    dispatch(selectApplicationStringLocale(e));
                  }}
                >
                  {getListOfLocales &&
                    getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)}
                </Select>
              </Col>
            )}
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(addApplicationStringsFormVisible(false));
          dispatch(resetApplicationStringsData());
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default AddApplicationStrings;
