import React from "react";
import _ from "lodash";
import { Checkbox, Tooltip, Space } from "antd";
import { DELETE_ICON_Nobg } from "../../CdnImagePath";
import { TOOLTIP_BG_COLOR } from "../../Constants";

const SelectAllBox = (props) => {
  const { isChecked, isDisable, deleteConfirm, selectAllIds, handleChangeCheck, recTestStep } = props;
  return (
    !isDisable && (
      <div className="display-flex align-items-center h-20 mt-10 mb-10 ml-5">
        <div className={isChecked ? "active-select-all" : "select-all"}>
          <Tooltip color={TOOLTIP_BG_COLOR} title={isChecked ? "Deselect All" : "Select All"} placement="bottomLeft">
            <Checkbox
              checked={isChecked}
              disabled={isDisable}
              onChange={(e) => {
                handleChangeCheck(e.target.checked);
              }}
            >
              <span className="font-size-12 text-light2">
                {selectAllIds?.length === 0 ? "Select All" : "Selected:"}{" "}
                {selectAllIds?.length !== 0 && selectAllIds?.length}{" "}
              </span>
            </Checkbox>
          </Tooltip>
        </div>
        {!_.isEmpty(selectAllIds) && (
          <div className="ml-15 select-action-hover">
            <Tooltip
              color={TOOLTIP_BG_COLOR}
              title={recTestStep && "To Delete The Custom Item Press (Ctrl / Command Key) + (Select Item)."}
            >
              <Space onClick={() => deleteConfirm()}>
                <img src={DELETE_ICON_Nobg} alt="delete" />
                <span>Delete</span>
              </Space>
            </Tooltip>
          </div>
        )}
      </div>
    )
  );
};

export default SelectAllBox;
