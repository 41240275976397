import { Button, Col, Input, Modal, Row, Select, Space, Tabs, Collapse, Empty, Spin } from "antd";
import React, { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  NOTES_IN_TEST_BLOCK,
  NOTES_IN_TEST_DATA,
  NOTES_IN_TEST_SCENARIO,
  TEST_DATA_SCOPE,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
} from "../../../Constants";
import {
  changeNewTestDataName,
  changeNewTestDataScope,
  changeNewTestDataValue,
  hideAddTestDataForm,
  saveNewTestData,
  fetchAssociatedTestBlocks,
  deleteTestData,
} from "../../../actions/TestDataAction";
import { StyledSelect } from "../../CoreComponents/StyledComponent/StyledComponents";
import styles from "./AddTestData.module.scss";

const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;

const AddTestData = (props) => {
  const { setOpenAddTestDataModal, openAddTestDataModal } = props;
  const {
    newTestDataId,
    newTestDataName,
    newTestDataValue,
    requestSaveTestDataForm,
    newTestDataScope,
    searchValue,
    filterValue,
    addTestDataFormVisible,
    testStepListAssociatedWithTestData,
    testStepListAssociatedWithTestDataLoading,
    prevTestData,
    newTestData,
  } = useSelector((props) => props.TestDataReducer);

  const { stepType } = useSelector((props) => props.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  let enableSaveTestDataButton =
    !requestSaveTestDataForm && newTestDataName && newTestDataName.trim().length > 0 && newTestDataScope;
  let scopeDropdownList = [];
  let isDisabled =
    (newTestDataId && _.isEqual(newTestData, prevTestData)) ||
    (!newTestDataId &&
      (!newTestDataValue ||
        newTestDataValue === "" ||
        !newTestDataScope ||
        !newTestDataName ||
        newTestDataName === ""));
  scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.PROJECT}> {TEST_DATA_SCOPE.PROJECT} </Option>);
  if (stepType === TEST_SCENARIOS) {
    scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.TEST_SCENARIO}> {TEST_DATA_SCOPE.TEST_SCENARIO} </Option>);
  } else if (stepType === TEST_STEP_BLOCKS) {
    scopeDropdownList.push(
      <Option value={TEST_DATA_SCOPE.TEST_STEP_BLOCK}> {TEST_DATA_SCOPE.TEST_STEP_BLOCK} </Option>
    );
  }

  const projectOrSystemKeyScopeInScenario =
    stepType === TEST_SCENARIOS &&
    (newTestDataScope === TEST_DATA_SCOPE.SYSTEM_KEYS || newTestDataScope === TEST_DATA_SCOPE.PROJECT);
  const deleteTestDataConfirmation = (id, testDataName) => {
    confirm({
      title: (
        <>
          <div>{`Are you sure you want to delete '${testDataName}'?`}</div>
          <div className={styles?.deleteModalNote}>
            Note: Test Data attached with Test Blocks, Scenarios will be removed.
          </div>
        </>
      ),
      onOk() {
        dispatch(deleteTestData(id, false, props.selectedStepId));
      },
    });
  };

  const handleOnChange = (e) => {
    if (e === TEST_DATA_SCOPE.TEST_SCENARIO) {
      dispatch(fetchAssociatedTestBlocks(newTestDataId, 1));
    } else {
      dispatch(fetchAssociatedTestBlocks(newTestDataId));
    }
  };

  useEffect(() => {
    if (newTestDataId !== undefined) {
      projectOrSystemKeyScopeInScenario
        ? dispatch(fetchAssociatedTestBlocks(newTestDataId, 1))
        : dispatch(
            fetchAssociatedTestBlocks(newTestDataId, newTestDataScope === TEST_DATA_SCOPE.TEST_SCENARIO ? 1 : 0)
          );
    }
  }, [dispatch, newTestDataId]);

  const generateCollapse = (data) => {
    if (data instanceof Array) {
      return data.map((x) => {
        return (
          <Collapse expandIconPosition="start">
            <Panel header={x?.name} key={x?.id} showArrow={!_.isEmpty(x?.data)}>
              {!_.isEmpty(x?.data) ? generateCollapse(x.data) : ""}
            </Panel>
          </Collapse>
        );
      });
    } else {
      return (
        <Collapse expandIconPosition="start">
          <Panel header={data?.name} key={data?.id}>
            {!_.isEmpty(data?.data) ? generateCollapse(data.data) : ""}
          </Panel>
        </Collapse>
      );
    }
  };

  const hideTestDataModal = () => {
    dispatch(hideAddTestDataForm());
    if (setOpenAddTestDataModal) {
      setOpenAddTestDataModal(false);
    }
  };

  const generateTabPane = (tabLabel, tabKey) => {
    return (
      <Tabs.TabPane key={tabKey} tab={tabLabel}>
        {!_.isEmpty(testStepListAssociatedWithTestData) ? (
          testStepListAssociatedWithTestData.map((x) => {
            if (!_.isEmpty(x)) {
              return generateCollapse(x);
            }
          })
        ) : (
          <Empty description="No records are found!" />
        )}
      </Tabs.TabPane>
    );
  };

  return (
    <>
      <Modal
        title={<span className="ant-drawer-title">{newTestDataId ? "Edit Key" : "Add"}</span>}
        width={1000}
        visible={openAddTestDataModal || addTestDataFormVisible}
        onCancel={() => hideTestDataModal()}
        footer={[
          <div className={styles.modalFooter}>
            <Space size={10}>
              <Button
                className={styles.grayBtn}
                onClick={() => {
                  hideTestDataModal();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                className={styles.blue}
                disabled={isDisabled}
                onClick={() =>
                  enableSaveTestDataButton
                    ? dispatch(
                        saveNewTestData(
                          newTestDataName,
                          newTestDataValue,
                          newTestDataId,
                          props.projectId,
                          newTestDataScope,
                          searchValue,
                          props.selectedStepId
                        )
                      )
                    : ""
                }
              >
                {newTestDataId ? "Update" : "Add"}
              </Button>
            </Space>
          </div>,
        ]}
      >
        <Row>
          <Col className={styles.testDataDetails} md={24}>
            {!newTestDataId && (
              <div className={styles.scopeDetails}>
                <>
                  <div className="mr-5 testDataLabel">Scope:</div>
                  <StyledSelect
                    className="w-150 mt-5"
                    placeholder="Select scope"
                    value={newTestDataScope}
                    onChange={(e) => dispatch(changeNewTestDataScope(e))}
                  >
                    {scopeDropdownList}
                  </StyledSelect>
                </>
              </div>
            )}
            <div className="mt-10 display-flex align-items-center">
              <div className="mr-5 testDataLabel">Key:</div>
              {newTestDataId ? (
                newTestDataName
              ) : (
                <Input
                  type="text"
                  value={newTestDataName}
                  placeholder="Add Key"
                  onChange={(e) => dispatch(changeNewTestDataName(e.target.value))}
                  className="mt-5"
                />
              )}
            </div>
            <div className="mt-10 display-flex align-items-center">
              <div className="testDataLabel">Value:</div>
              <Input
                type="text"
                value={newTestDataValue}
                placeholder="Add Value"
                onChange={(e) => dispatch(changeNewTestDataValue(e.target.value))}
                className="mt-5"
              />
            </div>
            {newTestDataId && (
              <div className="mt-10 display-flex align-items-center">
                <span
                  className="textRed cursor-pointer"
                  onClick={(e) => {
                    deleteTestDataConfirmation(
                      newTestDataId,
                      newTestDataName,
                      props.projectId,
                      searchValue,
                      filterValue
                    );
                  }}
                >
                  Delete
                </span>
              </div>
            )}
          </Col>
        </Row>

        {newTestDataId && (
          <div className="mt-10 font-size-12 mb-10 display-flex">
            <span className="font-weight-700 mr-5">Note:</span>
            {NOTES_IN_TEST_DATA}
          </div>
        )}
        {newTestDataId && (
          <Spin spinning={testStepListAssociatedWithTestDataLoading}>
            <div>
              <Tabs
                defaultActiveKey={
                  stepType === TEST_SCENARIOS &&
                  (newTestDataScope === TEST_DATA_SCOPE.SYSTEM_KEYS || newTestDataScope === TEST_DATA_SCOPE.PROJECT)
                    ? TEST_DATA_SCOPE.TEST_SCENARIO
                    : TEST_DATA_SCOPE.TEST_STEP_BLOCK
                }
                onChange={(e) => handleOnChange(e)}
              >
                {projectOrSystemKeyScopeInScenario &&
                  generateTabPane(
                    newTestDataScope === TEST_DATA_SCOPE.TEST_STEP_BLOCK
                      ? "Test Scenarios using this Test Block"
                      : "Test Scenario Steps",
                    TEST_DATA_SCOPE.TEST_SCENARIO
                  )}
                {newTestDataScope !== TEST_DATA_SCOPE.TEST_SCENARIO &&
                  generateTabPane("Test Block Steps", TEST_DATA_SCOPE.TEST_STEP_BLOCK)}
                {!projectOrSystemKeyScopeInScenario &&
                  generateTabPane(
                    newTestDataScope === TEST_DATA_SCOPE.TEST_STEP_BLOCK
                      ? "Test Scenarios using this Test Block"
                      : "Test Scenario Steps",
                    TEST_DATA_SCOPE.TEST_SCENARIO
                  )}
              </Tabs>
            </div>
          </Spin>
        )}
      </Modal>
    </>
  );
};

export default AddTestData;
