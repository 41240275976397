import { GET_AWS_DEVICE_FARM_BUILDS, GET_AWS_DEVICE_FARM_POOLS, GET_AWS_DEVICE_FARM_PROJECTS } from "../Constants";
let defaultState = {
  awsDeviceFarmProjects: undefined,
  awsDeviceFarmPools: undefined,
  awsDeviceFarmBuilds: undefined,
  selectedAwsDevicePoolIds: "",
  selectedAwsDeviceBuildId: "",
};

export default function AwsDeviceFarmReducer(state = defaultState, action) {
  switch (action.type) {
    case GET_AWS_DEVICE_FARM_PROJECTS:
      return {
        ...state,
        awsDeviceFarmProjects: action.response.data,
      };
    case GET_AWS_DEVICE_FARM_POOLS:
      return {
        ...state,
        awsDeviceFarmPools: action.response.data,
      };
    case GET_AWS_DEVICE_FARM_BUILDS:
      return {
        ...state,
        awsDeviceFarmBuilds: action.response.data,
      };
    default:
      return state;
  }
}
