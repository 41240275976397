import { Button, Col, Input, Row, Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../actions/Recording";
import { customTextVerificationDropdownValue } from "../../../../Constants";
import { getUniqueId } from "../../../../Util";

class CustomTextVerificationItem extends Component {
  constructor(props) {
    super(props);
    this.addRow = this.addRow.bind(this);
    this.removeRow = this.removeRow.bind(this);
  }

  addRow(key) {
    this.props.insertCustomTextVerificationRow(key);
  }

  removeRow(key) {
    this.props.removeCustomTextVerificationRow(key);
  }

  render() {
    let referenceTypes = Object.keys(customTextVerificationDropdownValue);
    const { item, updateCustomRefTypeValue, updateCustomRefTextValue, updateCustomRefOptionValue, elementTexts } =
      this.props;
    return (
      <React.Fragment>
        <Row className={"custom-verification-row"}>
          <Col span={10}>
            <Select
              value={item.key}
              className={"custom-verification-dropdown-control custom-control"}
              onChange={(e) => {
                updateCustomRefTypeValue(e, item.order);
              }}>
              {referenceTypes.map((type, index) => {
                return (
                  <Select.Option key={"refTypeOpt_" + index} value={type}>
                    {type}
                  </Select.Option>
                );
              })}
            </Select>
          </Col>
          <Col span={10}>
            {item.key == "Text" ? (
              <Input
                type="text"
                value={item.value}
                onChange={(e) => {
                  updateCustomRefTextValue(e.target.value, item.order);
                }}
                placeholder={"Enter Custom Text"}
                className={"custom-verification-input-control"}
              />
            ) : (
              <Select
                className={"custom-verification-dropdown-control custom-control"}
                value={item.value}
                onChange={(e) => {
                  updateCustomRefOptionValue(e, item.order);
                }}>
                {elementTexts.map((type, index) => {
                  return (
                    <Select.Option key={index} value={type.id}>
                      {type.value}
                    </Select.Option>
                  );
                })}
              </Select>
            )}
          </Col>
          <Col span={2}>
            <Button
              key={getUniqueId()}
              id={"removebutton_" + item.order}
              type="danger"
              className="menu-item preview-action  pause-icon"
              onClick={(e) => {
                this.removeRow(item.order);
              }}>
              -
            </Button>
          </Col>
          <Col span={2}>
            <Button
              key={getUniqueId()}
              id={"addbutton_" + item.order}
              type="primary"
              className="menu-item preview-action  pause-icon"
              onClick={(e) => {
                this.addRow(item.order);
              }}>
              +
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(actions, dispatch);
}

function mapStateToProps(state) {
  return state.RecordingReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomTextVerificationItem);
