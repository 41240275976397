import { Button, Col, Row, Select, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import {
  cloneTestDataSetByTestPlanId,
  fetchTestDataSetByProfileId,
  setTestDataSetAssigneeDetail,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import PaginationComponent from "../../../../../CoreComponents/PaginationComponent/PaginationComponent";
import TableComponent from "../../../../../CoreComponents/TableComponent/TableComponent";
import styles from "./AllTestDataSetV2.module.scss";

const columns = [
  {
    title: "Profile Details",
    dataIndex: "testDataDetails",
  },
  {
    title: "Test Data Keys",
    dataIndex: "dataSets",
  },
  {
    title: "Use For Execution",
    dataIndex: "isDefault",
  },
  {
    title: "Actions",
    dataIndex: "action",
    align: "end",
  },
];
const TestDataSetDetailsListV2 = ({ stepType, selectTestDataSetValue, testDataSet }) => {
  const dispatch = useDispatch();

  const { totalCounts, currentPage, totalPageCount, pageItems, testDataSetAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { testDataSetList, fetchingTestDataSetList } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      <div className={`${stepType === TEST_PLAN ? styles[`page-Scroll`] : styles["page-Scroll-Of-TestData"]}`}>
        <Row className="width-full mt-20">
          <Col span={24} className={styles[`test-data-set-details-list`]}>
            <Button
              type="primary"
              size="large"
              className={styles["new-profile-btn"]}
              onClick={() => dispatch(cloneTestDataSetByTestPlanId(testDataSet.id, stepType))}
            >
              New Profile
            </Button>
          </Col>
          <Col span={24}>
            <Spin spinning={fetchingTestDataSetList} tip="Loading" className="spinner-center">
              <TableComponent data={testDataSet} columns={columns} />
            </Spin>
          </Col>
        </Row>
        <div className={styles[`float-right`]}>
          {!isEmpty(testDataSetList) &&
            (testDataSetList.length > 1 || (testDataSetList.length === 1 && testDataSetList[0].name !== "Default")) && (
              <PaginationComponent
                currentPageItems={pageItems}
                totalItems={totalCounts}
                currentPage={currentPage}
                totalPage={totalPageCount}
                callPaginationAPI={(pageNumber) => dispatch(fetchTestDataSetByProfileId("", pageNumber))}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default TestDataSetDetailsListV2;