import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import { BUILD_TEST_PLAN, GET_TEST_PLAN_BY_ID } from "../../../../../Constants";
import { DRAG_SCENARIO_IMG } from "../../../../../Constants/SvgConstants";
import { isArrayNotUndefinedAndNotNull } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { getByProjectIdAndNotAssocWithTestPlan } from "../../../../../actions/RecTestScenarioAction";
import { getTestPlanByTestPlanId } from "../../../../../actions/TestPlanAction";
import CommonSearchV2 from "../../../../CoreComponents/SearchComponent/CommonSearchV2";
import TestScenarioDataV2 from "./TestScenarioDataV2";
import styles from "./BuildTestPlanV2.module.scss";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  width: "100%",
  userSelect: "none",
  margin: `0 0 ${grid}px 0`,
  borderRadius: 5,
  boxShadow: "0px 4px 6px rgba(120, 120, 120, 0.05)",
  ...draggableStyle,
});

const getListStyle = () => ({
  width: "100%",
  background: "#FFFFFF",
  border: "1px solid #d6d6d6",
  borderRadius: "10px",
  height: "calc(100vh - 140px)",
});

const BuildNewTestPlanV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, setSaveTestPlan, saveTestPlan, testPlanData, setTestPlanData, searchScenario, setSearchScenario } =
    props;
  const { testPlanDetails, getTestPlanById, selectedTestPlanTab } = useSelector((state) => state.TestPlanReducer);
  const { recTestScenarioListByProjectId } = useSelector((state) => state.RecTestScenarioReducer);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getTestPlanByTestPlanId(testPlanDetails ? testPlanDetails?.id : 0));
  }, [testPlanDetails]);

  useEffect(() => {
    if (selectedTestPlanTab === BUILD_TEST_PLAN) {
      dispatch(
        getByProjectIdAndNotAssocWithTestPlan(projectId, "", testPlanDetails ? testPlanDetails?.id : 0, setIsLoading)
      );
    }
  }, [testPlanDetails, selectedTestPlanTab]);

  useEffect(() => {
    if (selectedTestPlanTab) {
      setTestPlanData([[], []]);
      if (selectedTestPlanTab !== BUILD_TEST_PLAN) {
        dispatch({ type: GET_TEST_PLAN_BY_ID, getTestPlanById: [] });
      }
    }
  }, [selectedTestPlanTab]);

  useEffect(() => {
    if (isArrayNotUndefinedAndNotNull(recTestScenarioListByProjectId)) {
      let testPlanData = getTestPlanById;
      const listOfScenarios = recTestScenarioListByProjectId.filter(
        (value) => !testPlanData.map((temp) => temp.name).includes(value.name)
      );
      setTestPlanData([listOfScenarios, testPlanData]);
    }
  }, [recTestScenarioListByProjectId, getTestPlanById, setTestPlanData]);

  useEffect(() => {
    if (testPlanData && !isEmpty(testPlanData[1])) {
      setSaveTestPlan({
        ...saveTestPlan,
        testScenario: testPlanData[0],
        testPlanList: testPlanData[1],
      });
    }
  }, [testPlanData]);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;

      if (!destination) {
        return;
      }
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;

      if (sInd === dInd) {
        const items = reorder(testPlanData[sInd], source.index, destination.index);
        const newState = [...testPlanData];
        newState[sInd] = items;
        setTestPlanData(newState);
      } else {
        const result = move(testPlanData[sInd], testPlanData[dInd], source, destination);
        const newState = [...testPlanData];
        newState[sInd] = result[sInd];
        newState[dInd] = result[dInd];
        setTestPlanData(newState);
      }
    },
    [testPlanData, setTestPlanData]
  );

  const getEmptyDataContent = (text) => (
    <>
      <SvgLoader iconName={DRAG_SCENARIO_IMG} />
      <span className={styles["empty_Data_Text"]}>{text}</span>
    </>
  );

  const showEmptyDataImage = (ind) => {
    if (isEmpty(testPlanData[0]) && !isEmpty(testPlanData[1]) && ind === 0) {
      return getEmptyDataContent("Drag Scenarios here to remove them from the Test Plan.");
    } else if (isEmpty(testPlanData[0]) && isEmpty(testPlanData[1]) && ind === 0) {
      return getEmptyDataContent("No scenarios were found in this project");
    } else if (!isEmpty(testPlanData[0]) && isEmpty(testPlanData[1]) && ind === 1) {
      return getEmptyDataContent("Drag Scenarios here to add them to the Test Plan.");
    }
  };

  return (
    <Spin spinning={isLoading} tip="Loading" className={styles["spinner_Center"]}>
      <Row className={styles["test-plan-row"]}>
        <Col span={24} className={styles["drag-drop-box"]}>
          <DragDropContext onDragEnd={onDragEnd}>
            {testPlanData?.map((ele, ind) => (
              <Droppable key={ind} droppableId={`${ind}`}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    <Row>
                      {ind === 0 && (
                        <Row className={styles["all_Scenarios_Header"]}>
                          <Col span={10}>
                            <div className={styles["all_Scenario_Text"]}>All Scenarios</div>
                          </Col>
                          <Col span={14}>
                            <CommonSearchV2
                              searchDetails={(searchWord) =>
                                dispatch(
                                  getByProjectIdAndNotAssocWithTestPlan(
                                    projectId,
                                    searchWord,
                                    isArrayNotUndefinedAndNotNull(testPlanDetails) ? testPlanDetails.id : 0
                                  )
                                )
                              }
                              placeHolder="Search Scenario"
                              searchKeyword={searchScenario}
                              setSearchKeyword={setSearchScenario}
                              className={styles["search_Test_Scenario"]}
                              testPlanSearch
                              autoFocus={false}
                            />
                          </Col>
                        </Row>
                      )}
                      {ind === 1 && (
                        <Row className={styles["test_Plan_Header"]}>
                          <Col span={10}>
                            <div className={styles["test_Plan-Title"]}>
                              Test Plan<span className="text-danger">*</span>
                            </div>
                          </Col>
                          <Col span={14}>
                            <Input
                              placeholder="Please Enter Test Plan Name"
                              className={`common_Search_Box ${
                                !isEmpty(ele) && saveTestPlan?.testPlanName === "" && styles["border-red"]
                              }`}
                              onChange={(e) => setSaveTestPlan({ ...saveTestPlan, testPlanName: e.target.value })}
                              value={saveTestPlan?.testPlanName}
                              autoFocus
                            />
                          </Col>
                        </Row>
                      )}
                    </Row>
                    {!isLoading && (
                      <Row className={`${isEmpty(ele) ? styles["empty_Data_Container"] : styles["draggable-row"]} `}>
                        {isEmpty(ele) ? (
                          <Row justify="center" align="middle" className={styles["empty_Row"]}>
                            {showEmptyDataImage(ind)}
                          </Row>
                        ) : (
                          ele?.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                >
                                  <TestScenarioDataV2 scenarioData={item} />
                                </div>
                              )}
                            </Draggable>
                          ))
                        )}
                      </Row>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </Col>
      </Row>
    </Spin>
  );
};

export default BuildNewTestPlanV2;
