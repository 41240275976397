import { Checkbox, Col, Select, Spin, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  selectedAppForLaunch,
  changeRemoveAppPreviousSession,
  selectedAppActivityForLaunch,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";
import { DELETE_ICON_Nobg } from "../../../../CdnImagePath";
import { fetchAppActivity } from "../../../../actions/RecTestStep/RecTestStepCommonAction";
import styles from "./LaunchAppList.module.scss";

export const StyleLabel = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #636467;
`;

export const StyledInsertTextOuterText = styled.div`
  background: #dcdcdc;
  width: 150px;
  padding: 8px;
`;

export const StyledSelect = styled(Select)`
  border-radius: 0px;
  border: 1px solid #e9e9e9 !important;
  .ant-select-selector {
    border: none !important;
    width: 99% !important;
    border-radius: 0 !important;
  }
`;

export default (props) => {
  const { isForView, data, isInputDisabled, disabled } = props;
  const { packageName, packageActivity } = data.recTestStepProperty;
  const dispatch = useDispatch();
  const { selectedDeviceByUdid, requestingLaunchAppActivity, selectedDeviceIdForRecording } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, installedApps, selectedAppActivities } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  useEffect(() => {
    if (
      selectedDeviceIdForRecording &&
      packageName &&
      packageActivity &&
      selectedDeviceByUdid?.platformType?.name === "ANDROID"
    ) {
      dispatch(fetchAppActivity(packageName));
    }
  }, []);

  useEffect(() => {
    if (
      selectedDeviceIdForRecording &&
      packageName &&
      packageActivity &&
      selectedDeviceByUdid?.platformType?.name === "ANDROID"
    ) {
      dispatch(fetchAppActivity(packageName));
    }
  }, [selectedDeviceIdForRecording]);
  return (
    <React.Fragment>
      <Spin spinning={requestingLaunchAppActivity}>
        <div className={styles.launchAppTitle}>
          <div className={styles.launchAppLabel}>
            <div className={styles.actionTitle}>Action: </div>
            <div className={styles.launchAppText}>Launch App</div>
          </div>
        </div>
        <Col span={24} className="display-flex">
          <StyledInsertTextOuterText>Package Name</StyledInsertTextOuterText>
          <StyledSelect
            className="w-80"
            disabled={isForView || isInputDisabled || disabled}
            value={packageName}
            onChange={(value) => {
              dispatch(selectedAppForLaunch(value, data.tag));
            }}
            showSearch
            addonBefore="App Package Name"
          >
            {installedApps.map((v, k) => {
              return (
                <Select.Option key={k} value={v.name}>
                  {v.name}
                </Select.Option>
              );
            })}
          </StyledSelect>
        </Col>
        {selectedDeviceByUdid?.platformType?.name === "ANDROID" && (
          <>
            <Col span={24} className="display-flex mt-10">
              <StyledInsertTextOuterText>Activity Name</StyledInsertTextOuterText>
              <StyledSelect
                className="w-80"
                disabled={isForView || isInputDisabled || disabled}
                value={packageActivity}
                onChange={(value) => {
                  dispatch(selectedAppActivityForLaunch(value, data.tag));
                }}
                showSearch
              >
                {selectedAppActivities &&
                  selectedAppActivities.length > 0 &&
                  selectedAppActivities.map((v, k) => {
                    return (
                      <Select.Option key={k} value={v}>
                        {v}
                      </Select.Option>
                    );
                  })}
              </StyledSelect>
              <Tooltip title={"Remove App Activity"} color={TOOLTIP_BG_COLOR}>
                <img
                  src={DELETE_ICON_Nobg}
                  alt="remove"
                  className={!packageActivity ? "cursor-not-allowed ml-5" : "cursor-pointer ml-5"}
                  disabled={!packageActivity}
                  onClick={() => {
                    dispatch(selectedAppActivityForLaunch(null, data.tag));
                  }}
                />
              </Tooltip>
            </Col>
          </>
        )}
        <Col span={24} className="mt-10">
          <Checkbox
            disabled={
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag) ||
              selectedDeviceByUdid?.platformType?.name !== "ANDROID" ||
              disabled
            }
            checked={data.recTestStepProperty?.removeAppPreviousSession === 1 ? true : false}
            onChange={(e) =>
              selectedDeviceByUdid?.platformType?.name === "ANDROID"
                ? dispatch(changeRemoveAppPreviousSession(e.target.checked ? 1 : 0, data.tag))
                : undefined
            }
          >
            <div className="font-size-12 font-weight-400 lightGrayText">
              <div>
                Clear cache to remove previous session before Launching App{" "}
                <span className="font-weight-600">(Android App Only)</span>{" "}
              </div>
            </div>
          </Checkbox>
        </Col>
      </Spin>
    </React.Fragment>
  );
};
