import React from "react";
import { defaultDateTimeFormat } from "../../../../Constants";
import { displayUTCDateFormatWithToolTip } from "../../../../Util";
import styles from "./AppBuildListColumnsV2.module.scss";

const BuildHistoryColumnV2 = ({ row }) => {
  return (
    <>
      <div>
        <span className={styles["label"]}>Build Date and Time: </span>
        <span className={styles["label-name"]}>
          {row?.buildDate ? displayUTCDateFormatWithToolTip(row?.buildDate, defaultDateTimeFormat) : "NA"}
        </span>
      </div>
      <div>
        <span className={styles["label"]}>Created on: </span>
        <span className={styles["label-name"]}>
          {displayUTCDateFormatWithToolTip(row?.buildDate, defaultDateTimeFormat)}
        </span>{" "}
      </div>
      <div>
        <span className={styles["label"]}>Uploaded by: </span> {row?.uploadedBy || "NA"} on{" "}
        {row?.uploadedOn ? displayUTCDateFormatWithToolTip(row?.uploadedOn, defaultDateTimeFormat) : "NA"}
      </div>
    </>
  );
};

export default BuildHistoryColumnV2;
