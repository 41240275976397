import { TEST_ELEMENT, ALERT_USER, ALERT_CANCEL, LOGGED_IN_USER_DETAILS } from "../Constants";
import {
  forceStopDeviceLogs,
  stopDeviceScreenStreaming as stopStreaming,
  stopIOSScreenStreaming as stopIOSStreaming,
  recordingStop,
  flushTheDataOfRecording,
  stopAppiumStreaming,
} from "./Recording";

//   import {async} from "../../api/automation/";
export function checkTestElement() {
  return (dispatch) => {
    dispatch({ type: TEST_ELEMENT });
  };
}

export function forceStopLogs(serverId, targetUniqueId) {
  return (dispatch, getState) => {
    let isLogStarted = getState().RecordingReducer.logStarted;
    if (isLogStarted) {
      if (window.confirm("This will stop the device logs.")) {
        dispatch(forceStopDeviceLogs({ serverId, targetUniqueId }));
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };
}

export function stopScreenStreaming(serverId, targetUniqueId, projectId) {
  return (dispatch) => {
    dispatch(stopAppiumStreaming(serverId, targetUniqueId, true, projectId));
  };
}

export function alertUser() {
  return (dispatch, getState) => {
    dispatch({ type: ALERT_USER });
  };
}
export function logStarted() {
  return (dispatch, getState) => {
    return getState().RecordingReducer.deviceSessionLogStarted;
  };
}
export function alertCancel() {
  return (dispatch, getState) => {
    dispatch({ type: ALERT_CANCEL });
  };
}
export function loggedInUserDetail(response) {
  return (dispatch, getState) => {
    dispatch({ type: LOGGED_IN_USER_DETAILS, response });
  };
}

export function getCurrentRecordingState() {
  return (dispatch, getState) => {
    return getState().RecordingReducer.recordingState;
  };
}

export function stopRecordingService(serverId, targetUniqueId, platForm) {
  return (dispatch, getState) => {
    dispatch(recordingStop(serverId, targetUniqueId, platForm));
    dispatch(flushTheDataOfRecording());
  };
}
