import { Select } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTIONS,
  ACTIONS_OPTIONS,
  ELEMENT_ACTION,
  STEP,
  SWIPE_OVER_ELEMENT,
  VERIFICATIONS,
} from "../../../../../Constants";
import LaunchAppList from "../../LaunchApp/LaunchAppList";
import EnterText from "../EnterText/EnterText";
import VerifyTestData from "../../Verifications/VerifyTestData";
import SwipeElement from "../SwipeElement/SwipeElement";
import TapByIndex from "../TapByIndex/TapByIndex";
import { updateActionValue } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import TestDataOption from "../EnterText/TestDataOption";
import VerifyExistence from "../../Verifications/VerifyExistence";
import styles from "./ActionsVerificationItems.module.scss";

const ActionsVerificationItems = (props) => {
  const { data, isSkipStep, isPreviewRunning, isForView, isInputDisabled, projectId, showPostActionOrSettings } = props;
  const { showTestDataVisibility } = useSelector((state) => state.RecTestStepCommonViewReducer);
  let { disabled } = props;
  const { action } = data.recTestStepProperty;
  const dispatch = useDispatch();
  disabled = disabled || isPreviewRunning || isSkipStep;

  const showTestDataContent =
    !isInputDisabled &&
    ((showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) ||
      (data.recTestStepProperty?.testData && data.recTestStepProperty?.testData.id ? true : false));

  let testDataChecked =
    (showTestDataVisibility[data.tag] !== undefined && showTestDataVisibility[data.tag]) ||
    (data.recTestStepProperty?.testData && data.recTestStepProperty?.testData.id)
      ? true
      : false;

  return (
    <React.Fragment>
      <div>
        {getActionType(data?.recTestStepProperty?.action) === ACTIONS && (
          <div className={styles.actionsSelection}>
            <div className={styles.contentInCenter}>
              <div className={styles.selectActionsOrText}>
                <div className={styles.actionTitle}>Action</div>
                <Select
                  className={`${"selectActions"} ${disabled && styles.selectBoxBgColor}`}
                  value={action === "actions" ? ACTIONS_OPTIONS : action}
                  disabled={disabled}
                  defaultValue={ELEMENT_ACTION.TAP}
                  dropdownMatchSelectWidth
                  onChange={(value) => {
                    dispatch(updateActionValue(value, data.tag));
                  }}>
                  <Select.Option value={ELEMENT_ACTION.TAP}>Tap</Select.Option>
                  <Select.Option value={ELEMENT_ACTION.TAP_BY_INDEX}>Tap By Index</Select.Option>
                  <Select.Option value={ELEMENT_ACTION.LONG_PRESS}>Long Press</Select.Option>
                  <Select.Option value={ELEMENT_ACTION.SEND_KEYS}>Enter Text</Select.Option>
                  <Select.Option value={ELEMENT_ACTION.CLEAR_TEXT}>Clear Text</Select.Option>
                  <Select.Option value={SWIPE_OVER_ELEMENT}>Swipe Element</Select.Option>
                </Select>
              </div>
              <div className={styles.contentInCenter}>
                {action === ELEMENT_ACTION.SEND_KEYS && (
                  <>
                    <div className={styles.showActionDetails}>
                      <EnterText
                        {...props}
                        showTestDataContent={showTestDataContent}
                        testDataChecked={testDataChecked}
                        disabled={disabled}
                      />
                      <TestDataOption
                        isForView={isForView}
                        data={data}
                        isInputDisabled={isInputDisabled}
                        projectId={projectId}
                        showTestDataContent={showTestDataContent}
                        testDataChecked={testDataChecked}
                        disabled={disabled}
                      />
                    </div>
                  </>
                )}
                {action === ELEMENT_ACTION.TAP_BY_INDEX && (
                  <div className={styles.enterTextOrTapIndex}>
                    <TapByIndex {...props} />
                  </div>
                )}
                {action === SWIPE_OVER_ELEMENT ? <SwipeElement {...props} /> : ""}
              </div>
            </div>
          </div>
        )}
        <div className={styles.actionDetails}>
          {action === ELEMENT_ACTION.LAUNCH_APP ? <LaunchAppList {...props} /> : ""}
          {action === VERIFICATIONS && (
            <VerifyExistence
              disabled={disabled}
              recTestStep={data}
              recTestSteps={props.recTestSteps}
              showPostActionOrSettings={showPostActionOrSettings}
            />
          )}
          {action === STEP &&
            (data.recTestStepProperty?.isStepHasVerifyTestData === 1 &&
            data.recTestStepProperty?.recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") ? (
              <VerifyTestData {...props} />
            ) : (
              <VerifyExistence
                disabled={disabled}
                recTestStep={data}
                recTestSteps={props.recTestSteps}
                showPostActionOrSettings={showPostActionOrSettings}
              />
            ))}
        </div>
      </div>
    </React.Fragment>
  );
};

ActionsVerificationItems.propTypes = {
  data: PropTypes.object.isRequired,
};

const getActionType = (action) => {
  switch (action) {
    case ELEMENT_ACTION.SWIPE:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.TAP_BY_COORDINATES:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case ELEMENT_ACTION.CLEAR:
    case ELEMENT_ACTION.SWIPE_OVER_ELEMENT:
      return ACTIONS;
    case VERIFICATIONS:
    case STEP:
      return VERIFICATIONS;
    default:
      return ACTIONS;
  }
};
export default ActionsVerificationItems;
