import React from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Space } from "antd";
import { CREATE_ICON, VIEW_INVITATION_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import { myProjects, visibleCreateProjectModal } from "../../../actions/ProjectsAction";
import history from "../../../history";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./ProjectDetailsV2.module.scss";

const ProjectsHeaderV2 = ({ searchKeyword, setSearchKeyword, orgId, filter }) => {
  const dispatch = useDispatch();

  const { invitationList } = useSelector((state) => state.InvitationReducer);

  return (
    <div className={styles["projects-header"]}>
      <div className={styles["header-title"]}>Projects</div>
      <div className="display-flex mr-20">
        <Space size={10}>
          <CommonSearchV2
            searchDetails={(searchWord) => dispatch(myProjects(orgId, 1, searchWord, filter))}
            placeHolder="Search By Project and Team Name"
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
          />
          {invitationList?.data == null || (invitationList?.data != null && isEmpty(invitationList?.data)) ? null : (
            <CustomButtonV2
              buttonLabel={"View Invitations"}
              handleClick={() => history.push(`/${orgId}/InvitationList`)}
              buttonIcon={VIEW_INVITATION_ICON}
              className="common_Green_Button"
            />
          )}
          <CustomButtonV2
            buttonLabel={"Create New Project"}
            handleClick={() => dispatch(visibleCreateProjectModal(true))}
            buttonIcon={CREATE_ICON}
            iconColor={WHITE_COLOR}
            className="common_Green_Button"
          />
        </Space>
      </div>
    </div>
  );
};

export default ProjectsHeaderV2;
