import { default as React } from "react";
import { ALL_JOBS, JOB_DESIGNER } from "../../../Constants";
import AllJobsV2 from "./AllJobsV2/AllJobsV2";
import BuildJobV2 from "./BuildJobV2/BuildJobV2";

const JobManagerV2 = (props) => {
  const {
    projectId,
    orgId,
    selectedExecutionManagerSubTab,
    setCurrentMenuOptionsSelected,
    rights,
    setRights,
    showDeviceView,
  } = props;
  return (
    <div>
      {selectedExecutionManagerSubTab === ALL_JOBS && (
        <AllJobsV2
          projectId={projectId}
          orgId={orgId}
          setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
          rights={rights}
          setRights={setRights}
        />
      )}
      {selectedExecutionManagerSubTab === JOB_DESIGNER && (
        <BuildJobV2 projectId={projectId} orgId={orgId} rights={rights} showDeviceView={showDeviceView} />
      )}
    </div>
  );
};

export default JobManagerV2;
