import {
    SAVE_SSO_CONFIG,
    GET_SSO_CONFIG,
    HANDLE_CERT,
    HANDLE_ISSUE_URL,
    HANDLE_SIGN_ON_URL,
    // HANDLE_SIGN_ON_LOGOUT_REQUEST_URL,
    HANDLE_SIGN_ON_LOGOUT_SERVICE_URL,
    RESET_SSO_FORM_FILLED,
    SAVE_SSO_CONFIGURATION_REQUEST,
    SAVE_SSO_CONFIGURATION_REQUEST_FAILED,
    ENABLE_LOGOUT_SERVICE
} from "../Constants";

let defaultState = {
    configResult: undefined,
    SSOConfigs: null,
    certValue: "",
    issueURLValue: "",
    signOnURLValue: "",
    // signOnLogoutRequestURL: "",
    signOnLogoutServiceURL: "",
    ssoSaveRequested : false,
    tanentID: undefined,
    disableSaveConfig:true,
    enableLogoutService: false
};

export default function AdminReducer(state = defaultState, action){
    let resp = "";
    switch (action.type) {  
        case SAVE_SSO_CONFIG:
            resp = action.response;
            setConfigResult(resp.data);
            return{
                ...state,
                configResult: resp.data,
                ssoSaveRequested:false
            }
            
        case GET_SSO_CONFIG:
            resp = action.response;
            setConfigResult(resp.data);
            return{
                ...state,
                configResult: resp.data,
                disableSaveConfig:true,
            }
        case HANDLE_CERT:
            return {
                ...state,
                certValue: action.data,
                disableSaveConfig:false,
            }
        case HANDLE_ISSUE_URL:
            return {
                ...state,
                issueURLValue : action.data,
                disableSaveConfig:false,
            }
        case HANDLE_SIGN_ON_URL:
            return {
                ...state,
                signOnURLValue : action.data,
                disableSaveConfig:false,
            }
        // case HANDLE_SIGN_ON_LOGOUT_REQUEST_URL:
        //     return {
        //         ...state,
        //         signOnLogoutRequestURL : action.data,
        //         disableSaveConfig:false,
        //     }
        case ENABLE_LOGOUT_SERVICE: 
            return {
                ...state,
                enableLogoutService : action.value
            }
        case HANDLE_SIGN_ON_LOGOUT_SERVICE_URL:
            return {
                ...state,
                signOnLogoutServiceURL : action.data,
                disableSaveConfig:false,
            } 
        case RESET_SSO_FORM_FILLED:
            return{
                certValue: "",
                issueURLValue: "",
                signOnURLValue: "",
                // signOnLogoutRequestURL: "",
                signOnLogoutServiceURL: "",
                disableSaveConfig:true,
                enableLogoutService: false
            }
        case SAVE_SSO_CONFIGURATION_REQUEST:
            return {
                ...state,
                ssoSaveRequested:true
            }
        case SAVE_SSO_CONFIGURATION_REQUEST_FAILED:
            return {
                ...state,
                ssoSaveRequested:false
            }
        default:
            return state;
        }
        


function setConfigResult(data){
    if(data != null || data != undefined){
        state.certValue  = data.cert;
        state.issueURLValue = data.entityId;
        state.signOnURLValue = data.singleSignOnServiceUrl;
        // state.signOnLogoutRequestURL = data.singleLogoutServiceResponseUrl;
        state.enableLogoutService = data.enableLogoutService
        state.signOnLogoutServiceURL = data.singleLogoutServiceUrl;
        state.tanentID = data.tenantId;
    }
   
}

}