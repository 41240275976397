import React from "react";
import SvgLoader from "../../../Util/SvgLoader";
import { EMPTY_RECORDS_IMAGE, EMPTY_REC_TEST_STEP_IMG } from "../../../Constants/SvgConstants";
import styles from "./EmptyRecords.module.scss";

const EmptyRecords = ({ description, setMargin = false, emptyRecTestSteps, emptyElementImage }) => {
  return (
    <div className={`${styles.emptyRecords} ${setMargin && styles.margin} `}>
      <SvgLoader
        iconName={
          emptyElementImage ? emptyElementImage : emptyRecTestSteps ? EMPTY_REC_TEST_STEP_IMG : EMPTY_RECORDS_IMAGE
        }
        defaultCursor={true}
      />
      <div className={emptyRecTestSteps && styles.content}>
        <span className={styles.description}>{description}</span>
      </div>
    </div>
  );
};

export default EmptyRecords;
