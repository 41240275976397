import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faCloudUploadAlt, faCog, faCogs, faDownload, faFilter, faGlobe, faInfoCircle, faKeyboard, faMouse, faPause, faPlay, faPlus, faSave, faStop, faTag, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import 'antd/dist/antd.css';
import React from 'react';
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import CookieBanner from "../src/Components/TermsAndCondition/CookieBanner";
import rootReducer from './reducer/CombineReducer';
// import Routes from './Routes.jsx';
import RoutesV2 from './RoutesV2.jsx';
import * as serviceWorker from './serviceWorker';
import './stylesheets/style.css';
import './stylesheets/common.css';
import './styles/index.scss'
import CookieBannerV2 from "./Components/TermsAndConditionV2/CookieBannerV2/CookieBannerV2.js";
library.add(faCloudUploadAlt);
library.add(faTimesCircle);
library.add(faTrash);
library.add(faPlay);
library.add(faCogs);
library.add(faPlus);
library.add(faGlobe);
library.add(faStop);
library.add(faDownload);
library.add(faFilter);
library.add(faArrowLeft);
library.add(faSave);
library.add(faTag);
library.add(faPause);
library.add(faCog);
library.add(faKeyboard);
library.add(faMouse);
library.add(faInfoCircle);

const clearReducer = (state, action) => {
    return rootReducer(state, action);
};
const store = createStore(clearReducer,
    applyMiddleware(
        thunkMiddleware
    )
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <CookieBannerV2 />
        {/* <Routes /> */}
        <RoutesV2 />
    </Provider>
);


serviceWorker.unregister();