import { Collapse, Input, Row } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { changeRecTestStepPreConditionTitle } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepDetail from "../StepInnerComponents/RecTestStepDetail/RecTestStepDetail";
import UnSavedRecTestStepPopConfirm from "./UnSavedRecTestStepPopConfirm";
const { Panel } = Collapse;

export const RecTestStepConditionalCollapse = styled(Collapse)`
  border: ${(props) => (props.unSavedStep ? "1px solid #ff4d4fab" : props.selected ? "1px solid #1890ff" : "")};
  background-color: ${(props) =>
    props.isTestBlock ? "#ffffff !important" : props.isSkipStep ? "#b7b7b7 !important" : "#ffffff !important"};
  border: 1px solid #d9d9d9 !important;
  .ant-collapse {
    margin: 0px !important;
    background-color: white;
    margin-bottom: 5px !important;
  }

  ,
  .ant-collapse-item {
    margin: 0px !important;
    .ant-collapse-header {
      border: 0px !important;
      border-radius: 0px !important;
    }
  }
`;

const RecTestStepPreCondition = (props) => {
  const { recTestStep, isDeviceLoaded, projectId, isSkipStep, disabled, recTestSteps ,isTestBlock} = props;
  const dispatch = useDispatch();
  const { unSavedRecStepTag, unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);
  return (
    recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 && (
      <div>
        {recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.some(
          (i) => i?.type === "VerifyExistence"
        ) && (
          <UnSavedRecTestStepPopConfirm
            recTestStep={recTestStep}
            unSavedRecStepTag={unSavedRecStepTag}
            isForConditionTitle={true}
            unSavedRecElementGuid={unSavedRecElementGuid}
            recTestStepList={recTestSteps}
          />
        )}
        <Row className="mb-5">
          <Input
            className="w-85"
            addonBefore="Title"
            collapsible={"false"}
            placeholder="Provide a title/description for the Verification"
            type="text"
            value={recTestStep.recTestStepProperty?.preConditionTitle}
            disabled={disabled || isSkipStep}
            onChange={(e) => {
              !disabled && dispatch(changeRecTestStepPreConditionTitle(e.target.value, recTestStep.tag));
            }}
          />
        </Row>
        <RecTestStepDetail
          isSkipStep={recTestStep.recTestStepProperty?.isSkipStep || props.isSkipStep}
          data={recTestStep}
          parentIndex={recTestStep.orderId}
          isDeviceLoaded={isDeviceLoaded}
          projectId={projectId}
          recTestSteps={recTestSteps}
          disabled={disabled}
          isTestBlock={isTestBlock}
        />
      </div>
    )
  );
};

export default RecTestStepPreCondition;
