import { Button, Form } from "antd";
import _ from "lodash";
import Search from "antd/lib/input/Search";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPreviousSearchValue } from "../../actions/CommonActions";
import { hasBlankSpaces, isNullOrUndefined, isSameValue } from "../../Util";
import DatePickerBox from "./DatePickerBox";

const SearchBox = (props) => {
  const dispatch = useDispatch();
  const { previousSearchValue } = useSelector((state) => state.CommonReducer);
  const {
    searchDetails,
    placeHolder,
    searchKeyword,
    setSearchKeyword,
    resetSearchDetails,
    dateChange,
    dateRange,
    isDateSearch,
    searchByFrequency,
  } = props;
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (searchKeyword !== null && searchKeyword === "") {
      searchDetails();
      dispatch(setPreviousSearchValue(searchKeyword));
      setIsError(false);
    }
  }, [searchKeyword]);

  const searchData = () => {
    if (
      (searchKeyword && !hasBlankSpaces(searchKeyword) && !isSameValue(previousSearchValue, searchKeyword)) ||
      (isDateSearch && !_.isEmpty(dateRange[0])) ||
      !isNullOrUndefined(searchByFrequency)
    ) {
      searchDetails();
      dispatch(setPreviousSearchValue(searchKeyword));
    } else {
      setIsError(true);
    }
  };

  return (
    <>
      <div className="m-10">
        <Search
          className={"border-radius-4 " + (isError ? "search-error" : "search-text-input-device-usage")}
          onChange={(e) => {
            setSearchKeyword(e.target.value);
            hasBlankSpaces(e.target.value) || isSameValue(previousSearchValue, e.target.value)
              ? setIsError(true)
              : setIsError(false);
          }}
          placeholder={placeHolder}
          allowClear
          value={searchKeyword}
          onSearch={() => searchData()}
        />
      </div>
      {isDateSearch && (
        <div className="mt-10 mr-10">
          <DatePickerBox dateChange={dateChange} dateRange={dateRange} />
        </div>
      )}
      <div className="mt-10 mr-10">
        <Button type="primary" onClick={(e) => searchData()} disabled={isError}>
          Search
        </Button>
      </div>
      <div className="mt-10 mr-20">
        <Button
          type="primary"
          onClick={(e) => {
            setIsError(false);
            resetSearchDetails();
            dispatch(setPreviousSearchValue(null));
          }}
        >
          Reset
        </Button>
      </div>
    </>
  );
};

export default SearchBox;
