import { Fragment, default as React, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Collapse, Row, Spin } from "antd";
import styled from "styled-components";
import { CURRENT_TIME, DEVICE_EXECUTION_FARM, TEST_PLAN_RESULT_TYPE } from "../../../Constants";
import { PanelCol, VideoStyled, VideoTextHeaderRow } from "./TestPlanResultDetails";
const { Panel } = Collapse;

const panelHeaderFormat = (text) => {
  return <VideoTextHeaderRow>{text}</VideoTextHeaderRow>;
};

export const DownloadFolder = styled.div`
  padding: 2px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  text-align: center;
  a {
    color: white !important;
  }
`;

const TestPlanResultScenarioEvidence = (props) => {
  const { scenarioData } = props;
  const { liveStreamURL, runResultDetails, testPlanResultDetails } = useSelector((state) => state.TestPlanReducer);
  const [activeKey, setActiveKey] = useState([]);

  const isInProgress = scenarioData?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS;
  const isWaitingDevice = scenarioData?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED;

  const handleCollapseChange = (key) => {
    if (!isInProgress || !isWaitingDevice) {
      setActiveKey(key);
    }
  };

  const showVideoEvidencePanelDetails = () => {
    return (
      <PanelCol>
        <VideoStyled width="320" height="400" controls autoplay>
          <source src={scenarioData?.screenRecordLink} />
        </VideoStyled>
      </PanelCol>
    );
  };

  return (
    <Fragment>
      <Collapse expandIconPosition="right" activeKey={activeKey} onChange={handleCollapseChange}>
        {runResultDetails[0]?.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS ? (
          isInProgress && liveStreamURL[scenarioData?.scenarioId] !== undefined ? (
            <Panel showArrow={true} header={panelHeaderFormat("Live Device")} key={"livedevice"}>
              <div className="display-flex justify-center">
                {liveStreamURL[scenarioData?.scenarioId] !== undefined ? (
                  <img
                    id="screenshotimage"
                    height="350"
                    src={liveStreamURL[scenarioData?.scenarioId] + "?t=" + CURRENT_TIME}
                    alt="Device Screen"
                  />
                ) : (
                  <Spin spinning={true} />
                )}
              </div>
            </Panel>
          ) : (
            <>
              {scenarioData && scenarioData?.screenRecordLink ? (
                <Panel showArrow={true} header={panelHeaderFormat("Video Evidence")} key={"videoEvidence"}>
                  {showVideoEvidencePanelDetails()}
                </Panel>
              ) : undefined}
            </>
          )
        ) : (
          <Panel showArrow={true} header={panelHeaderFormat("Video Evidence")} key={"videoEvidence"}>
            {isInProgress || isWaitingDevice ? (
              <span className="light_Gray_Text">Video will be available once the execution is completed</span>
            ) : (
              showVideoEvidencePanelDetails()
            )}
          </Panel>
        )}
        {testPlanResultDetails[0]?.isLogs === 1 && (
          <Panel showArrow={true} header={panelHeaderFormat("Logs")} key={"logs"}>
            {isInProgress || isWaitingDevice ? (
              <span className="light_Gray_Text">Logs will be available once the execution is completed</span>
            ) : (
              <Row gutter={10}>
                {scenarioData?.adbDeviceLogFileLink && (
                  <PanelCol>
                    <a href={scenarioData?.adbDeviceLogFileLink} target="_deviceLog">
                      Device Logs
                    </a>
                  </PanelCol>
                )}
                {scenarioData?.deviceLogFileLink && (
                  <PanelCol>
                    <a href={scenarioData?.deviceLogFileLink} target="_deviceLog">
                      Appium Device Logs
                    </a>
                  </PanelCol>
                )}
                {scenarioData?.driverLogFileLink && (
                  <PanelCol>
                    <a href={scenarioData?.driverLogFileLink} target="_driverlog">
                      Driver Logs
                    </a>
                  </PanelCol>
                )}
                {scenarioData?.scenarioLogFileLink && (
                  <PanelCol>
                    <a href={scenarioData?.scenarioLogFileLink} target="_scenariolog">
                      Scenario Logs
                    </a>
                  </PanelCol>
                )}
                {scenarioData?.failurePagesourceLink && (
                  <PanelCol>
                    <a href={scenarioData?.failurePagesourceLink} target="_scenariolog">
                      Failure Pagesource
                    </a>
                  </PanelCol>
                )}
              </Row>
            )}
          </Panel>
        )}
        {/* <Panel showArrow={true} header={panelHeaderFormat("Image Evidence")} key={"imageEvidence"}>
              {scenarioData.failureScreenshotLink ? (
                <div className="display-flex justify-center w-100">
                  <PanelCol>
                    <img src={scenarioData.failureScreenshotLink} alt="" height="500" width="300"></img>
                  </PanelCol>
                </div>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </Panel> */}
        {!isInProgress && scenarioData?.scenarioFolderLink && (
          <DownloadFolder className="justify-center">
            <Button type="primary" disabled={scenarioData?.result === TEST_PLAN_RESULT_TYPE.IN_PROGRESS}>
              <a className="" href={scenarioData?.scenarioFolderLink} target="_blank">
                Download Screenshots & Logs
              </a>
            </Button>
          </DownloadFolder>
        )}
      </Collapse>
    </Fragment>
  );
};

export default TestPlanResultScenarioEvidence;
