import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from './../../actions/CommonActions';
import { Row, Col, Button} from 'antd';
import { Link } from 'react-router-dom';
import Lodestone from "../../images/Lodestone.png";

class GenerateToken extends Component {

 constructor(props) {
  super(props);
  this.state = { setUserNameError: false, userName: "", password: "", isAuthenicating: false, grant_type: "" };
  this.generateToken = this.generateToken.bind(this);
  this.handleChangeEmail = this.handleChangeEmail.bind(this);
 }

 componentDidMount() {
  this.setState({ grant_type: "password" });
 }

 generateToken(e) {
  e.preventDefault();
  if(this.state.email == ""){
    this.setState({ setEmailError: true });
    return;
  }
  // this.setState({ isAuthenicating: true }); 
  this.props.generateToken({"email":this.state.email});
 }
 
 handleChangeEmail(e) {
  this.setState({ email: e.target.value });
 }


 render() {
   const {showPasswordSendNotificaton} = this.props;
  return (
    <div className={"limiter"}>
    <div className={"container-login100"}>
      <Row className={"wrap-login100"}>
        <Col className={"login100-form validate-form"}>
          <div className={"logo-container"}>
            <img src={Lodestone} className={"center-logo"}/>
          </div>
          <span className={"login100-form-title p-b-16"}>
            Welcome To
          </span>
          <span className={"login100-form-title p-b-48 login-product-name"}>
            Xpress
          </span>
          <div className={"wrap-input100 validate-input"}>
            <input className={"input100" + (this.state.setEmailError ? " error " : "") } type="email" name="email"  placeholder="Email" 
            onChange={this.handleChangeEmail}/>  
          </div>
          
          {showPasswordSendNotificaton ? 
            <div className="success-email-sent-message">
              An Email was sent to your registerd email. It containa a link to login.
            </div>
          : null
          }
          <div className="btn-auth">
              <Button type="primary" size='large' block className='orage' onClick={this.generateToken}>
                Generate Token
              </Button>
           
          </div>
           <div className={"already-on-xpress"}>
              Already on Xpress?<Link to={"/Login"} > Log In </Link>
          </div>
        </Col>
      </Row>
    </div>
  </div>
  
    );
 }

}

function mapDispatchToProps(dispatch) {
 return  bindActionCreators(actions, dispatch)
}

function mapStateToProps(state) {
 return state.CommonReducer
}

export default connect(
 mapStateToProps,
 mapDispatchToProps
)(GenerateToken); 

