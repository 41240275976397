import React from "react";
import { Avatar, Modal, Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { hideDeleteUserModal, removeMemberFromTeam, showDeleteUserModal } from "../../actions/TeamsAction";
import { DELETE_ICON } from "../../CdnImagePath";

export default (props) => {
  const { myTeamMembers, removeUserRights } = props;
  const { teamNameOfProject, deleteUserId, deleteUserName, deleteUserModalVisible } = useSelector(
    (state) => state.ProjectsReducer
  );
  const dispatch = useDispatch();

  return (
    <div className="mt-20">
      {myTeamMembers &&
        myTeamMembers.map((item) => {
          return (
            <Row span={24} className={"memberRow"}>
              <Col span={24} className={"memberCol"}>
                <Avatar className="avatar-font" style={{ backgroundColor: "#FCC7C7" }}>
                  {item?.userName.split(" ")
                      .map((i) => i[0])
                      .join("")
                  }
                  {/* {myTeamMembers?.map((i) =>
                    i?.userName
                      .split(" ")
                      .map((i) => i[0])
                      .join("")
                  )} */}
                </Avatar>
                <titleName className="memberList">
                  {item?.userName}
                  {/* <a key="list-loadmore-edit">edit</a> */}
                </titleName>
                <div className="memberRemove">
                  {removeUserRights && item?.isTL === 0 ? (
                    <img
                      src={DELETE_ICON}
                      alt="Delete"
                      className="pr-10 cursor-pointer"
                      onClick={() => dispatch(showDeleteUserModal(item.userId, item.userName))}
                    />
                  ) : null}
                </div>
              </Col>
            </Row>
          );
        })}
      <Modal
        title="Remove User"
        visible={deleteUserModalVisible}
        onOk={() => {
          dispatch(
            removeMemberFromTeam({
              userId: deleteUserId,
              teamId: parseInt(localStorage.getItem("teamId"))
            })
          );
        }}
        onCancel={() => { dispatch(hideDeleteUserModal()) }}
      >
        Remove {deleteUserName} from {teamNameOfProject} team?
      </Modal>
    </div>
  );
};
