import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeAwsDeviceFarmTabData, getAwsProjectsList } from "../../../actions/ProjectSettingsActions";
import { Container } from "./AWSDeviceFarmManager";
import _ from "lodash";
import { AWS_DEVICE_FARM, GET_AWS_PROJECTS } from "../../../Constants";
import { isTrim } from "../../../Util";
import { Label, Required, StyledInput } from "../../CoreComponents/StyledComponent/StyledComponents";

const AWSDeviceFarmKeys = ({ selectedTab }) => {
  const dispatch = useDispatch();

  const { awsDeviceFarmTabDetails, previous_awsDeviceFarmTabDetails } = useSelector(
    (state) => state.ProjectSettingsReducer
  );

  const isSameKeys =
    (JSON.stringify(awsDeviceFarmTabDetails?.accessKeyId) ===
      JSON.stringify(previous_awsDeviceFarmTabDetails?.accessKeyId) ||
      isTrim(awsDeviceFarmTabDetails?.accessKeyId)) &&
    (JSON.stringify(awsDeviceFarmTabDetails?.secretAccessKey) ===
      JSON.stringify(previous_awsDeviceFarmTabDetails?.secretAccessKey) ||
      isTrim(awsDeviceFarmTabDetails?.secretAccessKey));

  const searchAwsProjects = () => {
    if (!isSameKeys) {
      dispatch({ type: GET_AWS_PROJECTS, response: { data: [] } });
    }
    dispatch(getAwsProjectsList());
  };

  return (
    <Col span={16}>
      <Row className="mt-30 mb-30 text">
        <Col span={4} offset={1} className="font-size-16 font-weight-500 font-color-darkBlue">
          Basic Details
        </Col>
        <Col span={19} className="text">
          <Label> Access Key</Label>
          <Required>*</Required>
          <StyledInput
            className="mb-20 mt-5"
            onChange={(e) =>
              dispatch(
                changeAwsDeviceFarmTabData({
                  ...awsDeviceFarmTabDetails,
                  accessKeyId: e.target.value,
                })
              )
            }
            placeholder="Please Enter Access Key"
            name="accessKeyId"
            value={awsDeviceFarmTabDetails.accessKeyId}
            autoFocus
          />
          <Label>Secret Key</Label>
          <Required>*</Required>
          <StyledInput
            className="mb-20 mt-5"
            onChange={(e) =>
              dispatch(
                changeAwsDeviceFarmTabData({
                  ...awsDeviceFarmTabDetails,
                  secretAccessKey: e.target.value,
                })
              )
            }
            placeholder="Please Enter Secret Key"
            name="secretAccessKey"
            value={awsDeviceFarmTabDetails.secretAccessKey}
          />
          <Button
            className="width-50 orange"
            disabled={!awsDeviceFarmTabDetails.accessKeyId || !awsDeviceFarmTabDetails.secretAccessKey || isSameKeys}
            onClick={searchAwsProjects}
          >
            Search
          </Button>
        </Col>
      </Row>
      <hr />
    </Col>
  );
};

export default AWSDeviceFarmKeys;
