import { Collapse, Popover, Space } from "antd";
import React from "react";
import TableComponent from "../../../../CoreComponents/TableComponent/TableComponent";
import { EYE_ICON } from "../../../../../CdnImagePath";
import { SYSTEM_KEYS } from "../../../../../Constants";
import { elementImage } from "../../../../../Util";

const { Panel } = Collapse;

const ViewDefaultTestDataListView = (props) => {
  const data = (listOfItem) => {
    return [
      ...listOfItem.map((row, i) =>
        row?.isForDefaultRefTestDataSetAssoc === 0
          ? {
              sr: row.stepOrderPath,
              ...row,
            }
          : {}
      ),
    ];
  };
  const prepareColumns = () => {
    let columns = [];
    if (props.stepType !== SYSTEM_KEYS) {
      columns = [
        {
          title: "Step No.",
          align: "center",
          width: "7%",
          render: (row) => <div className="font-color-darkBlue p-0">{row.sr}</div>,
        },
        {
          title: "Step",
          width: "17.5%",
          align: "center",
          render: (row) => {
            return (
              <Popover
                content={
                  <span>
                    {row.stepOrderPath} {row.stepPath}
                  </span>
                }
                title="Step Path"
              >
                {/* {(row.stepOrderPath ? "S-" + (row.stepOrderPath + ":") : "") + (row.stepPath ? row.stepPath : "")} */}
                {row.stepName}
              </Popover>
            );
          },
        },
        {
          title: "Used For",
          width: "15%",
          align: "center",
          render: (row) => (
            <Space>
              <span className="font-color-darkBlue p-0">{row.useFor}</span>
              {elementImage(row?.stepImage)? (
                <Popover
                  content={
                    row.stepImage.length > 0
                      ? row.stepImage.map((value, key) => {
                          return (
                            <img
                              key={key}
                              className="stepImage"
                              src={value}
                              height="450"
                              weight="225"
                              alt="Screenshot"
                            />
                          );
                        })
                      : ""
                  }
                  placement="left"
                  title="Element Image"
                  trigger="hover"
                >
                  <img src={EYE_ICON} alt="Element Screenshot" />
                </Popover>
              ) : (
                ""
              )}
            </Space>
          ),
        },
      ];
    }

    columns = [
      ...columns,
      {
        title: "Key",
        align: "center",
        width: "15%",
        render: (row) =>
          row?.isForDefaultRefTestDataSetAssoc === 0 ? (
            <div className="font-color-darkBlue p-0">
              {row.name}
              <br />
              {"[Scope:" + row.scope + "]"}
            </div>
          ) : (
            ""
          ),
      },
      {
        title: "Value",
        width: "20%",
        align: "center",
        render: (row) => {
          let refTestData = undefined;
          if (row?.defaultRefTestDataSetAssocId !== 0) {
            refTestData = props.refTestDataList.find(
              (refTestDataObj) => refTestDataObj.testDataSetAssocId === row.defaultRefTestDataSetAssocId
            );
          }
          return refTestData === undefined ? (
            <div className="font-color-darkBlue p-0">
              {row?.defaultValue && row.defaultValue !== "" && row.testDataCustomValue !== row.defaultValue
                ? row.defaultValue
                : row.value}
            </div>
          ) : (
            <div className="font-color-darkBlue p-0">
              <span className="font-weight-bold">Referenced :</span>
              <span>{refTestData.stepPath}</span>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  return (
    <div className="m-10">
      {props.testDataValues && (
        <Collapse defaultActiveKey={[]}>
          <Panel collapsible={true} header={props.title} key={"Default"}>
            <TableComponent data={data(props.testDataValues)} columns={prepareColumns()} testDataSetModal={true} />
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default ViewDefaultTestDataListView;
