import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import AddDeviceStrings from "./AddDeviceStrings/AddDeviceStrings";
import DeviceStringsList from "./DeviceStringsList/DeviceStringsList";

const DeviceStrings = (props) => {
  const { visibleAddDeviceStringForm } = useSelector((state) => state.RecTestStepCommonViewReducer);

  return (
    <Row>
      <Col span={24}>
        {visibleAddDeviceStringForm ? (
          <AddDeviceStrings projectId={props.projectId} />
        ) : (
          <DeviceStringsList projectId={props.projectId} />
        )}
      </Col>
    </Row>
  );
};

export default DeviceStrings;
