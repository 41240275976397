import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import { PARALLEL_OS, PARALLEL_SPEED, SCHEDULE_TEST_JOB } from "../../../../../Constants";
import DeviceTab from "./DeviceTab";
import ExecutionSettingsTab from "./ExecutionSettingsTab";
import SelectBuildTab from "./SelectBuildTab";
import TestPlanTab from "./TestPlanTab";

const renderJobCriteriaOptions = (props) => {
  const { orgId, projectId, selectedCriteria, testJobDetail, previousTestJobDetail, showDeviceView } = props;
  switch (selectedCriteria) {
    case SCHEDULE_TEST_JOB.STEP1:
      return <TestPlanTab projectId={projectId} testJobDetail={testJobDetail} />;
    case SCHEDULE_TEST_JOB.STEP2:
      return (
        <DeviceTab
          projectId={projectId}
          orgId={orgId}
          testJobDetail={testJobDetail}
          previousTestJobDetail={previousTestJobDetail}
          showDeviceView={showDeviceView}
        />
      );
    case SCHEDULE_TEST_JOB.STEP3:
      return (
        <SelectBuildTab
          projectId={projectId}
          testJobDetail={testJobDetail}
          orgId={orgId}
          showDeviceView={showDeviceView}
        />
      );
    case SCHEDULE_TEST_JOB.STEP4:
      return <ExecutionSettingsTab testJobDetail={testJobDetail} projectId={projectId} />;
    default:
      break;
  }
};
const JobCriteriaOptions = (props) => {
  const { testJobDetail } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (testJobDetail?.devices?.length > 1) {
      dispatch(
        changeTestJobData({
          ...testJobDetail,
          executionType: testJobDetail.executionType === PARALLEL_SPEED ? PARALLEL_SPEED : PARALLEL_OS,
        })
      );
    }
  }, [testJobDetail?.devices]);

  return <div className="jobContainer">{renderJobCriteriaOptions(props)}</div>;
};

export default JobCriteriaOptions;
