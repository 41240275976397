import { Checkbox } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { changeShowTestData } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./EnterTextV2.module.scss";

const TestDataOptionV2 = (props) => {
  const dispatch = useDispatch();
  const { data, testDataChecked, inputDisabled, disabled } = props;

  return (
    <div className={styles["checkBox_And_Text"]}>
      <Checkbox
        className={"selection-checkBox"}
        disabled={inputDisabled || disabled}
        onChange={(e) => {
          dispatch(changeShowTestData(e.target.checked, data.tag));
        }}
        checked={testDataChecked}
      />
      <span className={styles["input_Or_Select_Label"]}> Use Test Data</span>
    </div>
  );
};

export default TestDataOptionV2;
