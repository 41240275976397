import { Button, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { editDeviceList } from "../../actions/OrgDeviceAction";
import { DEVICE_TYPE, SUCCESS_STATUS } from "../../Constants";
import { DataCol } from "./EditDeviceDetailsModal";
import _ from "lodash";
import { getDeviceName } from "../../Util";

const { Option } = Select;

export const Label = styled.span`
  font-size: 13px;
  font-weight: 400;
  padding: 0px;
  color: #6E7282;
`;

export const SelectCol = styled(Select)`
  width: 100%;
  margin-bottom: 10px;
  display: inline-block;
  background-color: #e6e3e4;
  .ant-select-selection--multiple {
    min-height: 64px;
  }
`;

const DeviceAssignmentModal = (props) => {
  const dispatch = useDispatch();
  const { postAssignDeviceRes, getAllDevice, isLoading } = useSelector((state) => state.OrgDeviceReducer);

  const { modalStatus, modalClosed, myProjectsList, orgId, myOrgUsers,searchKeyword } = props;
  const [selectedDevice, setSelectedDevice] = useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState();

  const handleCloseModal = () => {
    modalClosed();
    resetData();
  };

  function handleChangeDeviceType(value) {
    setSelectedDeviceType(value);
  }

  function isValid() {
    return selectedDeviceType && selectedDevice && selectedDevice.length > 0;
  }

  const addDeviceAssignment = () => {
    if (isValid()) {
      const deviceObject = {
        orgId: orgId,
        targetUniqueIds: selectedDevice,
        projectIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedProject,
        userIds: selectedDeviceType === DEVICE_TYPE.SHARED ? [] : selectedUser,
        isSharedDevice: selectedDeviceType === DEVICE_TYPE.SHARED ? 1 : 0,
      };
      dispatch(editDeviceList(deviceObject,searchKeyword));
    }
  };

  const resetData = () => {
    setSelectedDevice([]);
    setSelectedDeviceType([]);
    setSelectedProject([]);
    setSelectedUser([]);
  };

  const deviceList = [];
  if (!_.isEmpty(getAllDevice)) {
    for (let i = 0; i < getAllDevice.length; i++) {
      deviceList.push(
        <Option key={getAllDevice[i].targetUniqueId}>
          {getDeviceName(getAllDevice[i])} ({getAllDevice[i].osVer})
        </Option>
      );
    }
  }

  const projects = [];
  if (!_.isEmpty(myProjectsList)) {
    for (let i = 0; i < myProjectsList.length; i++) {
      projects.push(
        <Option value={JSON.stringify(myProjectsList[i].projectId)}>{myProjectsList[i].projectName}</Option>
      );
    }
  }

  const users = [];
  if (!_.isEmpty(myOrgUsers)) {
    for (let i = 0; i < myOrgUsers.length; i++) {
      users.push(<Option value={JSON.stringify(myOrgUsers[i].id)}>{myOrgUsers[i].email}</Option>);
    }
  }

  useEffect(() => {
    if (postAssignDeviceRes && postAssignDeviceRes.status === SUCCESS_STATUS) {
      handleCloseModal();
      resetData();
    } else {
      handleCloseModal();
    }
  }, [postAssignDeviceRes]);

  return (
    <>
      <Modal
        title="Device Assignment"
        visible={modalStatus}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={addDeviceAssignment} disabled={!isValid()}>
            Assign
          </Button>,
        ]}>
        <div className="mb-10">
          <DataCol>Select Device:</DataCol>
          <SelectCol
            listItemHeight={20}
            listHeight={500}
            placeholder="Please Select Device"
            mode="tags"
            className="select-app-for-default"
            value={selectedDevice}
            onChange={(e) => setSelectedDevice(e)}
            tokenSeparators={[","]}>
            {deviceList}
          </SelectCol>
        </div>
        <div className="mb-10">
          <DataCol> Device Sharing:</DataCol>
          <Select
            className="w-100"
            placeholder="Select Device sharing"
            value={selectedDeviceType}
            onChange={handleChangeDeviceType}>
            <Option key={DEVICE_TYPE.SHARED}>Share with All Projects</Option>
            <Option key={DEVICE_TYPE.CUSTOM}>Custom</Option>
          </Select>
        </div>
        {selectedDeviceType === DEVICE_TYPE.CUSTOM && (
          <>
            <div className="mb-10">
              <DataCol>Share With Projects:</DataCol>
              <SelectCol
                placeholder="Please Select Project"
                mode="tags"
                className="select-app-for-default"
                value={selectedProject}
                onChange={(e) => setSelectedProject(e)}
                tokenSeparators={[","]}>
                {projects}
              </SelectCol>
            </div>
            <div className="mb-10">
              <DataCol> Share With Users:</DataCol>
              <SelectCol
                placeholder="Please Select User"
                mode="tags"
                className="select-app-for-default"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e)}
                tokenSeparators={[","]}>
                {users}
              </SelectCol>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};

export default DeviceAssignmentModal;
