import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Col, Radio, Tooltip } from "antd";
import { faBug, faLanguage, faMobileAlt, faTable, faThList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DEVICE_VIEW,
  EXECUTION_VIEW,
  INVESTIGATION_VIEW,
  LOCALE_VIEW,
  SCENARIO_VIEW,
  TOOLTIP_BG_COLOR,
} from "../../../../Constants";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import TestPlanResultScenarioDetails from "../TestPlanResultScenarioDetails";
import TestPlanResultDeviceView from "./TestPlanResultDeviceView";
import TestPlanResultScenarioView from "./TestPlanResultScenarioView";
import TestPlanResultLocaleView from "./TestPlanResultLocaleView";
import { setSelectedRunResultView } from "../../../../actions/TestPlanAction";
import TestPlanResultInvestigationView from "./TestPlanResultInvestigationView";

const TestPlanResultViewManager = (props) => {
  const dispatch = useDispatch();
  const { runResultDetails, testPlanResultDetails, selectedRunResultView, scenarioLoading } = useSelector(
    (state) => state.TestPlanReducer
  );
  const { isTestBlock } = props;

  return (
    <>
      <Col span={24} className="display-flex ml-20 mt-10">
        <Radio.Group
          buttonStyle="solid"
          size="large"
          value={selectedRunResultView}
          onChange={(e) => dispatch(setSelectedRunResultView(e.target.value))}
        >
          <Tooltip color={TOOLTIP_BG_COLOR} title="Execution view">
            <Radio.Button value={EXECUTION_VIEW} className="mr-10 border-radius-4">
              <FontAwesomeIcon icon={faTable} />
            </Radio.Button>
          </Tooltip>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Device View">
            <Radio.Button
              value={DEVICE_VIEW}
              className="ml-10 mr-10 border-radius-4"
            >
              <FontAwesomeIcon icon={faMobileAlt} />
            </Radio.Button>
          </Tooltip>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Scenario View">
            <Radio.Button
              value={SCENARIO_VIEW}
              className="ml-10 border-radius-4"
            >
              <FontAwesomeIcon icon={faThList} />
            </Radio.Button>
          </Tooltip>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Locale view">
            <Radio.Button
              value={LOCALE_VIEW}
              className="ml-10 border-radius-4"
            >
              <FontAwesomeIcon icon={faLanguage} />
            </Radio.Button>
          </Tooltip>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Investigation view">
            <Radio.Button
              value={INVESTIGATION_VIEW}
              className="ml-10 border-radius-4"
            >
              <FontAwesomeIcon icon={faBug} />
            </Radio.Button>
          </Tooltip>
        </Radio.Group>
      </Col>

      {selectedRunResultView === EXECUTION_VIEW && !scenarioLoading && (
        <Col span={24}>
          <TestPlanResultDetailsScenario>
            {!_.isEmpty(testPlanResultDetails) &&
              testPlanResultDetails?.map((v, k) => (
                <TestPlanResultScenarioDetails
                  key={v.scenarioIds}
                  scenariosList={v.scenarios}
                  runResultDetails={runResultDetails}
                  isTestBlock={isTestBlock}
                />
              ))}
          </TestPlanResultDetailsScenario>
        </Col>
      )}
      {selectedRunResultView === DEVICE_VIEW && !scenarioLoading && (
        <Col span={24}>
          <TestPlanResultDeviceView isTestBlock={isTestBlock} />
        </Col>
      )}
      {selectedRunResultView === SCENARIO_VIEW && !scenarioLoading && (
        <Col span={24}>
          <TestPlanResultScenarioView isTestBlock={isTestBlock} />
        </Col>
      )}
      {selectedRunResultView === LOCALE_VIEW && !scenarioLoading && (
        <Col span={24}>
          <TestPlanResultLocaleView isTestBlock={isTestBlock} />
        </Col>
      )}
      {selectedRunResultView === INVESTIGATION_VIEW && !scenarioLoading && (
        <Col span={24}>
          <TestPlanResultInvestigationView isTestBlock={isTestBlock} />
        </Col>
      )}
    </>
  );
};

export default TestPlanResultViewManager;
