import React from "react";
import { Tooltip } from "antd";
import { TEST_DATA_SET_NAME } from "../../../../../../../Constants/TooltipConstants";
import styles from "../AllTestDataSetV2.module.scss";

const TestDataDetailsColumnV2 = ({ testDataSet }) => {
  const { id, name, createdBy, updatedBy } = testDataSet || {};
  return (
    <div>
      {!id ? (
        <Tooltip title={TEST_DATA_SET_NAME} color="white">
          <div className={styles["test-data-name"]}>
            <span className={styles["test-Data-Set-Title"]}>{name}</span>
          </div>
        </Tooltip>
      ) : (
        <div>
          <span className={styles["test-Data-Set-Title"]}>{name}</span>
        </div>
      )}
      {createdBy && (
        <div>
          <span className={styles["label"]}>Created By: </span>
          <span className={styles["test-data-set-details"]}>{createdBy}</span>
        </div>
      )}
      {updatedBy && (
        <div>
          <span className={styles["label"]}>Updated By: </span>
          <span className={styles["test-data-set-details"]}>{updatedBy}</span>
        </div>
      )}
    </div>
  );
};

export default TestDataDetailsColumnV2;