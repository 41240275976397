import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Input, Modal, Popover, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { fetchProjectList, saveProjects } from "../../../actions/ServerAction";
import { IOS } from "../../../Constants";
import { isNullOrUndefinedOrEmpty } from "../../../Util";
import deviceTeamIdImg from "../../../images/deviceTeamIdImg.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;
const ProjectListModal = (props) => {
  const { orgId, serverId, serverIp, openProjectModal, setOpenProjectModal, deviceData } = props;
  const dispatch = useDispatch();
  const { projectsList } = useSelector((state) => state.ServerReducer);
  const [selectedProject, setSelectedProject] = useState([]);
  const [iosTeamId, setIosTeamId] = useState(null);

  useEffect(() => {
    if (deviceData?.listOfProjectIds) {
      setSelectedProject(deviceData?.listOfProjectIds && deviceData?.listOfProjectIds.map((i) => i.toString()));
      setIosTeamId(deviceData?.iosDevelopmentTeamId);
    }
  }, [deviceData]);

  useEffect(() => {
    dispatch(fetchProjectList(orgId));
  }, []);

  let isDisabled = (deviceData?.platform === IOS && isNullOrUndefinedOrEmpty(iosTeamId)) || _.isEmpty(selectedProject);

  return (
    <Modal
      title="Project List"
      visible={openProjectModal}
      onOk={() => setOpenProjectModal(false)}
      onCancel={() => setOpenProjectModal(false)}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            dispatch(
              saveProjects(orgId, serverId, serverIp, deviceData, false, selectedProject.map(Number), iosTeamId)
            );
            setOpenProjectModal(false);
          }}
          disabled={
            isDisabled ||
            (_.isEqual(selectedProject.map(Number), deviceData?.listOfProjectIds) &&
              _.isEqual(iosTeamId, deviceData?.iosDevelopmentTeamId))
          }
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <div className="font-size-14 font-weight-500 ml-5">Projects</div>
        <Select
          className="w-100 m-5"
          mode="tags"
          placeholder="Select Project"
          onChange={(e) => setSelectedProject(e)}
          value={selectedProject}
        >
          {!_.isEmpty(projectsList) &&
            projectsList.map(
              (i, j) =>
                i?.platform === deviceData?.platform && (
                  <Option key={i} value={i.projectId.toString()}>
                    {i.projectName}
                  </Option>
                )
            )}
        </Select>
      </div>
      {deviceData?.platform === IOS && (
        <div className="mt-5">
          <div className="display-flex">
            <div className="font-size-14 font-weight-500 ml-5">IOS Development Team Id</div>
            <Popover
              content={
                <img className="stepImage" src={deviceTeamIdImg} height="400" weight="400" alt="IOS Development" />
              }
              title="IOS Development Team Image"
              trigger="hover"
            >
              <FontAwesomeIcon icon={faInfoCircle} className="ml-20 align-self-center" />
            </Popover>
          </div>
          <Input
            className="w-100 m-5 border-radius-4"
            placeholder="Enter >IOS Development Team Id"
            onChange={(e) => {
              setIosTeamId(e.target.value);
            }}
            value={iosTeamId}
          />
        </div>
      )}
    </Modal>
  );
};

export default ProjectListModal;
