import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import { ACTION_VIEW, BUILD_TEST_SCENARIO, CUSTOM, PRIVATE, PUBLIC } from "../../Constants";
import { removeSelectedIds } from "../../Util";
import {
  duplicateRecTestScenario,
  rectTestScenarioPermissionAPI,
  setRecTestScenarioIdForPreview,
  setSelectedScenario,
} from "../../actions/RecTestScenarioAction";
import { setDeviceAssigneeDetail } from "../../actions/TestDataSet/TestDataSetCommonAction";
import { editScenario } from "../../actions/TestPlanAction";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import PermissionDrawerV2 from "../CoreComponents/PermissionDrawer/PermissionDrawerV2/PermissionDrawerV2";
import TableComponentV2 from "../CoreComponents/TableComponent/TableComponentV2";
import PreviewRunDrawer from "../RecTestScenario/PreviewRunDrawer";
import AddedToTestPlanColumnV2 from "./RecTestScenarioListColumnsV2/AddedToTestPlanColumnV2";
import RecTestScenarioActionsColumnV2 from "./RecTestScenarioListColumnsV2/RecTestScenarioActionsColumnV2";
import ScenarioInfoColumnV2 from "./RecTestScenarioListColumnsV2/ScenarioInfoColumnV2";
import StepDetailsColumV2 from "./RecTestScenarioListColumnsV2/StepDetailsColumnV2";
import styles from "./RecTestScenarioV2.module.scss";

const RecTestScenarioListV2 = (props) => {
  const dispatch = useDispatch();
  const {
    projectId,
    selectAllScenario,
    setSelectAllScenario,
    navigateToEditBuildScenario,
    isScenarioLoadModel,
    setOpenDeleteTestScenarioModal,
  } = props;

  const { recTestScenarioList, fetchingRecTestScenarioList } = useSelector((state) => state.RecTestScenarioReducer);

  const [openPreviewRunDrawer, setOpenPreviewRunDrawer] = useState(false);
  const [recTestScenarioName, setRecTestScenarioName] = useState(null);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionScenario, setPermissionScenario] = useState(null);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [openCloneTestScenarioModal, setOpenCloneTestScenarioModal] = useState({ visible: false, data: {} });

  const showCheckBoxInTableHeader = useMemo(() => {
    return (
      isEmpty(selectAllScenario) &&
      !isScenarioLoadModel &&
      !isEmpty(recTestScenarioList) &&
      recTestScenarioList?.some((i) => i?.allowDelete === ACTION_VIEW)
    );
  }, [selectAllScenario, isScenarioLoadModel, recTestScenarioList]);

  const columns = [
    {
      title: (
        <div className={showCheckBoxInTableHeader ? styles["checkBox-in-header"] : styles["table-header"]}>
          {showCheckBoxInTableHeader && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllScenario([
                      ...selectAllScenario,
                      ...(
                        recTestScenarioList &&
                        recTestScenarioList?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllScenario.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllScenario(removeSelectedIds(selectAllScenario, recTestScenarioList));
                  }
                }}
              />
            </span>
          )}
          Scenario Information
        </div>
      ),
      dataIndex: "scenarioInformation",
      width: 500,
    },
    {
      title: "Step Details",
      dataIndex: "stepDetails",
      width: 200,
    },
    {
      title: "Added To Test Plans",
      dataIndex: "addedToTestPlans",
      width: 300,
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "right",
      hidden: isScenarioLoadModel,
    },
  ]?.filter((item) => !item.hidden);

  useEffect(() => {
    if (permissionScenario?.recTestScenarioRights?.data) {
      setMembers([...permissionScenario.recTestScenarioRights.data]);
    }
  }, [permissionScenario]);

  // reset privacy
  const setPermissionData = () => {
    if (permissionScenario?.isPrivate && !isEmpty(permissionScenario?.recTestScenarioRights?.data)) {
      setPreviousPermission(permissionScenario?.recTestScenarioRights?.data);
      setMembers([...permissionScenario?.recTestScenarioRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  // save privacy
  const savePermission = (Value, data, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      recTestScenairoId: data?.id,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !isEmpty(result) ? result : [],
    };
    dispatch(rectTestScenarioPermissionAPI(obj, projectId));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const handleActionChange = (actionName, data) => {
    setPermissionScenario(() => ({ ...data }));
    switch (actionName) {
      case "deviceStrings":
        dispatch(setDeviceAssigneeDetail({ id: data?.id, name: data?.name }));
        navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
        dispatch(editScenario(data, projectId));
        break;
      case "duplicate":
        setOpenCloneTestScenarioModal({ visible: true, data: { ...data } });
        break;
      case "previewHistory":
        setOpenPreviewRunDrawer(true);
        dispatch(setRecTestScenarioIdForPreview(data?.id));
        setRecTestScenarioName(data?.name);
        break;
      case "delete":
        setOpenDeleteTestScenarioModal({ visible: true, data: { ...data } });
        break;
      case PRIVATE:
      case PUBLIC:
        savePermission(actionName, data);
        break;

      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const recTestScenarioDetails = recTestScenarioList?.map((row, index) => ({
    key: row?.id,
    scenarioInformation: (
      <ScenarioInfoColumnV2
        row={row}
        selectAllScenario={selectAllScenario}
        isScenarioLoadModel={isScenarioLoadModel}
        setSelectAllScenario={setSelectAllScenario}
        projectId={projectId}
        navigateToEditBuildScenario={navigateToEditBuildScenario}
      />
    ),
    stepDetails: <StepDetailsColumV2 row={row} />,
    addedToTestPlans: <AddedToTestPlanColumnV2 row={row} index={index} />,
    action: !isScenarioLoadModel && (
      <RecTestScenarioActionsColumnV2
        row={row}
        projectId={projectId}
        navigateToEditBuildScenario={navigateToEditBuildScenario}
        handleActionChange={handleActionChange}
        isScenarioLoadModel={isScenarioLoadModel}
      />
    ),
  }));

  return (
    <Spin spinning={fetchingRecTestScenarioList} tip="Loading" className={styles.spinnerCenter}>
      <div>
        <TableComponentV2
          selected={selectAllScenario}
          columns={columns}
          data={recTestScenarioDetails}
          className={`table__wrapper ${!isEmpty(selectAllScenario) && " hide_column_name"}`}
          handleSelectRow={(selectedRow) => ({
            onClick: () => {
              let data = recTestScenarioList && recTestScenarioList?.find((i) => i?.id === selectedRow?.key);
              dispatch(
                setSelectedScenario({
                  id: data?.id,
                  name: data?.name,
                  tags: data?.tags,
                  testRailTestCaseId: data?.testRailTestCaseId,
                  testRailTestSuiteId: data?.testRailTestSuiteId,
                  platform: data?.platform,
                })
              );
            },
          })}
          selectedRowId={[props.selectedScenarioId]}
        />
      </div>
      <PreviewRunDrawer
        openPreviewRunDrawer={openPreviewRunDrawer}
        setOpenPreviewRunDrawer={setOpenPreviewRunDrawer}
        recTestScenarioName={recTestScenarioName}
        projectId={projectId}
      />
      <PermissionDrawerV2
        openPermissionDrawer={openPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        permissionData={permissionScenario}
        savePermission={savePermission}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        setPermissionData={setPermissionData}
        ownerEmailId={permissionScenario?.createdByEmail}
        notShowExecutionCheck={true}
        drawerTitle={"Test Scenario"}
      />
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            duplicateRecTestScenario({
              recTestScenarioId: openCloneTestScenarioModal?.data?.id,
              projectId: projectId,
            })
          );
          setOpenCloneTestScenarioModal({ visible: false, data: {} });
        }}
        handleCancel={() => setOpenCloneTestScenarioModal({ visible: false, data: {} })}
        modalOpen={openCloneTestScenarioModal?.visible}
        modalTitle={"Confirm Duplication"}
        modalContent={"Are you sure you want to duplicate this item?"}
        okText={"Duplicate"}
      />
    </Spin>
  );
};

export default RecTestScenarioListV2;
