import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { POST_OPERATION_SETTINGS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { BLUE_COLOR, DISABLE_ICON_COLOR, SELECT_TEST_DATA_ICON } from "../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  changeInsertValueToTestData,
  selectOutputTestData,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { ButtonContainer } from "../../SingleColumnView/ImportRecTestStepActionModal/ImportRecTestStepActionModal";
import { StyledSelect } from "./QuickActions/QuickActions";
import ManageTestData from "../../SingleColumnView/ManageTestData";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import styles from "./Actions.module.scss";

export default (props) => {
  const { disabled, data } = props;
  const { testDataList, unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const openTestDataDrawer = () => {
    dispatch(visibleTestDataDrawer(POST_OPERATION_SETTINGS, data?.tag));
    dispatch(selectRecTestSteps(data));
  };
  return (
    <React.Fragment>
      <Row className="display-flex">
        <Col span={24} className="font-weight-500 font-size-13 mt-10 display-flex align-items-center gap-10">
          <span>Assign Value</span>
          <Input
            type="text"
            disabled={
              disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
            }
            placeholder="Enter text"
            value={data.recTestStepProperty?.insertValueToTestData}
            onChange={(e) => dispatch(changeInsertValueToTestData(data.tag, e.target.value))}
            className="w-25 mt-5 border-1-lightGray"
          />
          <span> to Test Data Key:</span>{" "}
          <StyledSelect
            placeholder="Select a Read/Write Variable"
            className="w-30"
            disabled={
              disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
            }
            value={
              data.recTestStepProperty?.outputTestData
                ? data.recTestStepProperty?.outputTestData.id
                : data.recTestStepProperty?.outputTestDataId
            }
            onChange={(value) => dispatch(selectOutputTestData(data.tag, value))}
          >
            {testDataList &&
              testDataList.length > 0 &&
              testDataList.map((v, k) => {
                return (
                  <Select.Option key={v.id} value={v.id}>
                    {v.name}
                  </Select.Option>
                );
              })}
          </StyledSelect>
          <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
            <div
              className={`${styles.selectTestData} ${disabled && styles.disableSelectTestData}`}
              onClick={() => {
                !disabled && openTestDataDrawer();
              }}
            >
              <SvgLoader
                iconName={SELECT_TEST_DATA_ICON}
                disabled={disabled}
                iconColor={disabled ? DISABLE_ICON_COLOR : BLUE_COLOR}
              />
            </div>
          </Tooltip>
          <ButtonContainer>
            <Button
              type="primary"
              disabled={
                disabled ||
                ((data?.recTestStepProperty?.outputTestData?.id === undefined ||
                  data?.recTestStepProperty?.outputTestDataId === undefined) &&
                  data?.recTestStepProperty?.insertValueToTestData === undefined)
              }
              onClick={() => {
                dispatch(selectOutputTestData(data.tag, undefined));
                dispatch(changeInsertValueToTestData(data.tag, undefined));
              }}
            >
              Remove
            </Button>
          </ButtonContainer>
        </Col>
      </Row>
      <ManageTestData {...props} tabName={POST_OPERATION_SETTINGS} />
    </React.Fragment>
  );
};
