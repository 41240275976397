import { Tree } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PARENT_ELEMENT_ARROW_ICON } from "../../../../../../Constants/SvgConstants";
import { searchAdvanceSelectorElements } from "../../../../../../Util";
import SvgLoader from "../../../../../../Util/SvgLoader";
import { selectElement, selectHoveredElement, unselectHoveredElement } from "../../../../../../actions/Recording";
import styles from "./RenderDeviceSource.module.scss";

const RenderDeviceSource = (props) => {
  const dispatch = useDispatch();
  const { deviceDetail } = useSelector((state) => state.RecordingReducer);
  const {
    child,
    setAttributes,
    path,
    setPath,
    serverId,
    targetUniqueId,
    currentDeviceScreenSource,
    x,
    y,
    projectId,
    searchElements,
    parentElementBounds,
  } = props;

  let keys = [];

  const prepareDeviceElementTreeData = (deviceScreenSource, path, parentElement) => {
    let treeViewData = [];
    deviceScreenSource &&
      deviceScreenSource.map((screenSource, i) => {
        keys.push(screenSource?.path);
        if (
          parentElementBounds &&
          screenSource?.path !== undefined &&
          screenSource.path.includes(currentDeviceScreenSource.path + ".")
        ) {
          screenSource.path = screenSource?.path?.split(currentDeviceScreenSource.path + ".")[1];
        }
        const isElementOutOfDeviceScreen =
          parseInt(screenSource?.attributes.x) > parseInt(deviceDetail?.screenSize?.split("x")[0]) ||
          parseInt(screenSource?.attributes.y) > parseInt(deviceDetail?.screenSize?.split("x")[1]);

        const selectedElement = path === screenSource?.path;
        if (searchAdvanceSelectorElements(searchElements, screenSource)) {
          treeViewData.push({
            title: (
              <div className={styles.tagNameOrIcon}>
                {parentElement && (
                  <div className={styles.parentElementArrowIcon}>
                    <SvgLoader iconName={PARENT_ELEMENT_ARROW_ICON} />
                  </div>
                )}

                <div
                  key={"AdvanceSelector-" + screenSource.path}
                  className={`${!parentElement && styles.stepsInTreeView} ${selectedElement && styles.selectElement} ${
                    screenSource?.attributes.visible === "false" && "cursor__not_allowed"
                  } `}>
                  <div className={`${styles.element} ${!selectedElement && styles.bgWhite}`}>
                    <span
                      className={`${parentElement ? styles.parentTagName : styles.childTagName} ${
                        !selectedElement && styles.bgWhite
                      } ${!selectedElement && parentElement && styles.bgWhite}`}
                      onMouseOver={() => {
                        if (!isElementOutOfDeviceScreen) {
                          dispatch(selectHoveredElement(screenSource?.path, currentDeviceScreenSource));
                        }
                        setAttributes(screenSource?.attributes);
                      }}
                      onMouseOut={() => (!isElementOutOfDeviceScreen ? dispatch(unselectHoveredElement) : undefined)}
                      onClick={() => {
                        if (!isElementOutOfDeviceScreen) {
                          setPath(screenSource?.path);
                          setPath(screenSource?.path);
                          dispatch(
                            selectElement(
                              screenSource?.path,
                              currentDeviceScreenSource,
                              serverId,
                              targetUniqueId,
                              x,
                              y,
                              screenSource?.attributes?.bounds,
                              undefined,
                              projectId,
                              parentElement
                            )
                          );
                        }
                      }}>
                      {screenSource?.tagName}
                    </span>
                  </div>
                </div>
              </div>
            ),
            data: screenSource,
            key: screenSource?.path,
            children: !_.isEmpty(screenSource?.children) && prepareDeviceElementTreeData(screenSource?.children, path),
          });
        } else if (!_.isEmpty(screenSource?.children)) {
          treeViewData = treeViewData.concat(prepareDeviceElementTreeData(screenSource?.children, path));
        }
      });
    return treeViewData;
  };

  const treeData = prepareDeviceElementTreeData(!_.isEmpty(child) ? child : "", path, true);

  return (
    <>
      <div className="w-100 treeData">
        <Tree
          showLine={{ hideLeafIcon: false }}
          treeData={treeData}
          switcherIcon={[]}
          expandedKeys={keys}
          checkable={false}
          className="import-scenario-step-tree"
          selectable={false}
        />
      </div>
    </>
  );
};

export default RenderDeviceSource;
