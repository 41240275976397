import React from "react";
import { useSelector } from "react-redux";
import AssignedTestDataListViewV2 from "./AssignedTestDataListViewV2/AssignedTestDataListViewV2";

const EditSystemKeyTestDataSetV2 = ({ title }) => {
  const { testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      <AssignedTestDataListViewV2 title={title} testDataValues={testDataSetForEdit?.testDataValues} />
    </>
  );
};

export default EditSystemKeyTestDataSetV2;