import { Button, Collapse, Input, Modal, Select, Space, Spin, Tooltip } from "antd";
import { isEmpty, isEqual } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  NOTES_IN_TEST_DATA,
  TEST_DATA_SCOPE,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
} from "../../../Constants";
import {
  BLUE_CLOSE_COLLAPSE_ICON,
  BLUE_OPEN_COLLAPSE_ICON,
  CLOSE_ICON_RED,
  DELETE_ICON_RED_BORDER,
} from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import {
  changeNewTestDataName,
  changeNewTestDataScope,
  changeNewTestDataValue,
  deleteTestData,
  fetchAssociatedTestBlocks,
  hideAddTestDataForm,
  saveNewTestData,
} from "../../../actions/TestDataAction";
import HeaderButtonsGroupV2 from "../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./AddTestDataV2.module.scss";

const { Option } = Select;
const { Panel } = Collapse;
const { confirm } = Modal;

const AddTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { setOpenAddTestDataModal, openAddTestDataModal } = props;
  const {
    newTestDataId,
    newTestDataName,
    newTestDataValue,
    requestSaveTestDataForm,
    newTestDataScope,
    searchValue,
    filterValue,
    addTestDataFormVisible,
    testStepListAssociatedWithTestData,
    testStepListAssociatedWithTestDataLoading,
    prevTestData,
    newTestData,
  } = useSelector((props) => props.TestDataReducer);
  const { stepType } = useSelector((props) => props.RecTestStepCommonViewReducer);

  const [openDeleteTestDataModal, setOpenDeleteTestDataModal] = useState(null);

  useEffect(() => {
    if (newTestDataId !== undefined) {
      projectOrSystemKeyScopeInScenario
        ? dispatch(fetchAssociatedTestBlocks(newTestDataId, 1))
        : dispatch(
            fetchAssociatedTestBlocks(newTestDataId, newTestDataScope === TEST_DATA_SCOPE.TEST_SCENARIO ? 1 : 0)
          );
    }
  }, [dispatch, newTestDataId]);

  let enableSaveTestDataButton =
    !requestSaveTestDataForm && newTestDataName && newTestDataName.trim().length > 0 && newTestDataScope;
  let scopeDropdownList = [];
  let isDisabled =
    (newTestDataId && isEqual(newTestData, prevTestData)) ||
    (!newTestDataId &&
      (!newTestDataValue ||
        newTestDataValue === "" ||
        !newTestDataScope ||
        !newTestDataName ||
        newTestDataName === ""));
  scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.PROJECT}> {TEST_DATA_SCOPE.PROJECT} </Option>);
  if (stepType === TEST_SCENARIOS) {
    scopeDropdownList.push(<Option value={TEST_DATA_SCOPE.TEST_SCENARIO}> {TEST_DATA_SCOPE.TEST_SCENARIO} </Option>);
  } else if (stepType === TEST_STEP_BLOCKS) {
    scopeDropdownList.push(
      <Option value={TEST_DATA_SCOPE.TEST_STEP_BLOCK}> {TEST_DATA_SCOPE.TEST_STEP_BLOCK} </Option>
    );
  }

  const projectOrSystemKeyScopeInScenario =
    stepType === TEST_SCENARIOS &&
    (newTestDataScope === TEST_DATA_SCOPE.SYSTEM_KEYS || newTestDataScope === TEST_DATA_SCOPE.PROJECT);

  const stepScope = TEST_DATA_SCOPE.TEST_SCENARIO;
  const [activeFilter, setActiveFilter] = useState(
    stepType === TEST_SCENARIOS &&
      (newTestDataScope === TEST_DATA_SCOPE.SYSTEM_KEYS || newTestDataScope === TEST_DATA_SCOPE.PROJECT)
      ? { id: stepScope, value: "Test Scenario Steps" }
      : { id: TEST_DATA_SCOPE.TEST_STEP_BLOCK, value: "Test Block Steps" }
  );

  const filterPermission = [
    { id: stepScope, value: "Test Scenario Steps" },
    { id: TEST_DATA_SCOPE.TEST_STEP_BLOCK, value: "Test Block Steps" },
  ];

  if (!projectOrSystemKeyScopeInScenario) {
    filterPermission.push({ id: stepScope, value: "Test Scenario Steps" });
    filterPermission.shift();
  }

  const handleFilters = (e) => {
    setActiveFilter(e);
    if (e?.id === TEST_DATA_SCOPE.TEST_SCENARIO) {
      dispatch(fetchAssociatedTestBlocks(newTestDataId, 1));
    } else {
      dispatch(fetchAssociatedTestBlocks(newTestDataId));
    }
  };

  const generateCollapse = (data) => {
    if (data instanceof Array) {
      return data.map((x) => (
        <Collapse
          key={x?.id}
          className="test_Data_Collapse"
          expandIconPosition="start"
          expandIcon={({ isActive }) => (
            <SvgLoader iconName={isActive ? BLUE_OPEN_COLLAPSE_ICON : BLUE_CLOSE_COLLAPSE_ICON} />
          )}
        >
          <Panel header={x?.name} key={x?.id}>
            <span>{!isEmpty(x?.data) ? generateCollapse(x.data) : ""}</span>
          </Panel>
        </Collapse>
      ));
    } else {
      return (
        <Collapse
          key={data?.id}
          className="test_Data_Collapse"
          expandIconPosition="start"
          expandIcon={({ isActive }) => (
            <SvgLoader iconName={isActive ? BLUE_OPEN_COLLAPSE_ICON : BLUE_CLOSE_COLLAPSE_ICON} />
          )}
        >
          <Panel header={data?.name} key={data?.id}>
            <span>{!isEmpty(data?.data) ? generateCollapse(data.data) : ""}</span>
          </Panel>
        </Collapse>
      );
    }
  };

  const hideTestDataModal = () => {
    dispatch(hideAddTestDataForm());
    if (setOpenAddTestDataModal) {
      setOpenAddTestDataModal(false);
    }
  };
  
  return (
    <>
      <Modal
        title={
          <div className={styles["add_Test_Data_label"]}>
            <div className={styles["drawer_Title"]}>{newTestDataId ? "Edit Key" : "Add Test Data"}</div>
            <SvgLoader
              iconName={CLOSE_ICON_RED}
              handleClick={() => {
                hideTestDataModal();
              }}
              width={40}
              height={40}
            />
          </div>
        }
        width={newTestDataId ? 800 : 500}
        visible={openAddTestDataModal || addTestDataFormVisible}
        onCancel={() => hideTestDataModal()}
        footer={null}
        closable={false}
        destroyOnClose
        className={"rounded_Border_Modal" + (newTestDataId ? " edit_Test_Data_Set_Modal" : "")}
      >
        <div className={newTestDataId && styles["edit_Test_Data_Details"]}>
          <div>
            {!newTestDataId && (
              <div className={styles["custom-input-wrapper"]}>
                <div className={`${styles["custom-input-label"]}`}>Scope</div>
                <Select
                  className={styles["modal-select-box"]}
                  placeholder="Select scope"
                  value={newTestDataScope}
                  onChange={(e) => dispatch(changeNewTestDataScope(e))}
                >
                  {scopeDropdownList}
                </Select>
              </div>
            )}
          </div>
          {newTestDataId ? (
            <div className={styles["edit_Test_Data_Key"]}>
              <div className={styles["test_Data_Text"]}>
                <span className={styles["test_Data_Label"]}>Key:</span>
                <span>{newTestDataName}</span>
              </div>
              <div>
                <Tooltip title={"Delete"} color={TOOLTIP_BG_COLOR}>
                  <SvgLoader
                    iconName={DELETE_ICON_RED_BORDER}
                    handleClick={() => {
                      setOpenDeleteTestDataModal(newTestDataId);
                    }}
                    width={40}
                    height={40}
                  />
                </Tooltip>
              </div>
            </div>
          ) : (
            <div className={styles["custom-input-wrapper"]}>
              <div className={`${styles["custom-input-label"]} `}>Key</div>

              <Input
                className={styles["modal-input"]}
                type="text"
                value={newTestDataName}
                placeholder="Add Key"
                onChange={(e) => dispatch(changeNewTestDataName(e.target.value))}
              />
            </div>
          )}

          <div className={`${styles["custom-input-wrapper"]} ${newTestDataId && styles["edit_Input_Box"]}`}>
            <div className={`${styles["custom-input-label"]} `}>Value</div>
            <Input
              className={styles["modal-input"]}
              type="text"
              value={newTestDataValue}
              placeholder="Add Value"
              onChange={(e) => dispatch(changeNewTestDataValue(e.target.value))}
            />
          </div>
          {newTestDataId && (
            <div className={`${styles["edit_Test_Data_Key"]} ${styles["edit_Note"]}`}>
              <span className={styles["test_Data_Label"]}>Note:</span>
              <span>{NOTES_IN_TEST_DATA}</span>
            </div>
          )}
        </div>

        {newTestDataId && (
          <div div className={styles["edit_Test_Data"]}>
            <HeaderButtonsGroupV2
              data={filterPermission}
              activeButton={activeFilter?.id}
              onClick={(e) => handleFilters(e)}
            />

            <div
              className={`${styles["test_Data_Scroll"]} ${
                testStepListAssociatedWithTestDataLoading && styles["no_Data_Text"]
              }`}
            >
              <Spin spinning={testStepListAssociatedWithTestDataLoading}>
                {!isEmpty(testStepListAssociatedWithTestData) && testStepListAssociatedWithTestData !== undefined ? (
                  <div>
                    {testStepListAssociatedWithTestData.map((x) => {
                      if (!isEmpty(x)) {
                        return generateCollapse(x);
                      }
                      return null;
                    })}
                  </div>
                ) : (
                  !testStepListAssociatedWithTestDataLoading &&
                  isEmpty(testStepListAssociatedWithTestData) &&
                  testStepListAssociatedWithTestData !== undefined && (
                    <span className={styles["no_Data_Text"]}>No Data</span>
                  )
                )}
              </Spin>
            </div>
          </div>
        )}
        <div className={styles["modalFooter"]}>
          <Space size={10}>
            <Button
              className={styles["cancelBtn"]}
              onClick={() => {
                hideTestDataModal();
              }}
            >
              <span> Cancel</span>
            </Button>
            <Button
              type="primary"
              className={styles["update_Or_Add_Btn"]}
              disabled={isDisabled}
              onClick={() =>
                enableSaveTestDataButton
                  ? dispatch(
                      saveNewTestData(
                        newTestDataName,
                        newTestDataValue,
                        newTestDataId,
                        props.projectId,
                        newTestDataScope,
                        newTestDataScope === TEST_DATA_SCOPE.SYSTEM_KEYS ? 1 : 0,
                        props.selectedStepId
                      )
                    )
                  : ""
              }
            >
              <span> {newTestDataId ? "Update" : "Add"}</span>
            </Button>
          </Space>
        </div>
      </Modal>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(deleteTestData(openDeleteTestDataModal, false, props.selectedStepId));
          setOpenDeleteTestDataModal(null);
        }}
        handleCancel={() => setOpenDeleteTestDataModal(null)}
        modalOpen={!!openDeleteTestDataModal}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this test data?"}
        okText={"Delete"}
        isDeleteModal={true}
      />
    </>
  );
};

export default AddTestDataV2;
