import { Spin, Tooltip } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { SYSTEM_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../Constants";
import TestDataSetHeader from "./TestDataSetHeader/TestDataSetHeader";
import ViewRecTestScenarioDefaultTestDataSet from "./ViewRecTestScenarioDefaultTestDataSet";
import ViewTestBlockDefaultTestDataSet from "./ViewTestBlockDefaultTestDataSet";
import ViewTestPlanDefaultTestDataSet from "./ViewTestPlanDefaultTestDataSet";

const ViewDefaultTestDataSet = (props) => {
  const { fetchingTestDataSetList, testDataSetView, testDataSetList } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);

  let defaultTestData = testDataSetList && testDataSetList.length > 0 ? testDataSetList[0] : [];
  return (
    <Spin spinning={fetchingTestDataSetList}>
      {props.setIsViewTestData && (
        <Tooltip title="Test Data Profile having default values for Test Data Keys">
          <TestDataSetHeader
            editTestDataSetName={"Base Profile"}
            setIsEditTestDataSet={props.setIsViewTestData}
            isDefaultView={true}
            selectedTestPlanName={""}
            stepType={props.stepType}
          />
        </Tooltip>
      )}
      <div
        className={!props.assignTestDataSetModal && (stepType === TEST_PLAN ? "pageScroll" : "pageScrollOfTestData")}>
        {testDataSetView === TEST_STEP_BLOCKS && (
          <ViewTestBlockDefaultTestDataSet
            title={"Test Block: Steps using Test Data"}
            defaultTestData={defaultTestData}
          />
        )}
        {testDataSetView === TEST_SCENARIOS && (
          <ViewRecTestScenarioDefaultTestDataSet
            title={"Test Scenario: Steps using Test Data"
                + " [Steps using Test Data: " +  defaultTestData?.stepCount
                + " Unique Test Data Keys: " + defaultTestData?.uniqueTestDataCount + "]"}
            defaultTestData={defaultTestData}
          />
        )}
        {testDataSetView === TEST_PLAN && (
          <ViewTestPlanDefaultTestDataSet 
              title={"Test Plan Level Test Data"
                + " [Steps using Test Data: " +  defaultTestData?.stepCount
                + " Unique Test Data Keys: " + defaultTestData?.uniqueTestDataCount + "]"} 
            defaultTestData={defaultTestData} />
        )}
        {props.stepType === SYSTEM_KEYS && (
          <ViewTestBlockDefaultTestDataSet
            title={props.title}
            defaultTestData={defaultTestData}
            stepType={props.stepType}
          />
        )}
      </div>
    </Spin>
  );
};

export default ViewDefaultTestDataSet;
