import { Col, Row, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTestDataSetById } from "../../actions/TestDataSet/TestDataSetRedirectAction";
import {
  cloneSystemKeyByTestDataSetId,
  deleteTestDataSet,
  fetchSystemKeyByProjectId,
} from "../../actions/TestDataSetAction";
import { COPY_ICON, DELETE_ICON, EDIT_ICON, EYE_ICON } from "../../CdnImagePath";
import { SYSTEM_KEYS, TOOLTIP_BG_COLOR } from "../../Constants";
import { cloneModal, deleteModal } from "../../Util";
import PaginationComponent from "../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";
import { Label } from "../DeviceDetails/DeviceAssignmentModal";
import EditTestDataSet from "../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/EditTestDataSet/EditTestDataSet";
import ViewDefaultTestDataSet from "../TestPlan/TestPlanManager/TestPlanTabs/BuildTestDataSet/ViewDefaultTestDataSet";

const SystemKeyTestDataSetList = (props) => {
  const dispatch = useDispatch();
  const { testDataSetAssigneeDetails } = useSelector((state) => state.TestDataSetReducer);
  const {
    testDataSetList,
    fetchingTestDataSetList,
    totalPageCount,
    totalCounts,
    currentPage,
    testDataSetForEdit,
    pageItems,
  } = useSelector((state) => state.TestDataSetReducer);

  const [isEditTestDataSet, setIsEditTestDataSet] = useState(false);
  const [isViewTestData, setIsViewTestData] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    dispatch(fetchSystemKeyByProjectId(searchKeyword, currentPage));
  }, [testDataSetAssigneeDetails, props.projectId]);

  const TestDataSetActions = (testDataSet) => {
    return (
      <div className="display-flex justify-flex-end align-self-center font-size-18">
        {testDataSet.id && (
          <span className="m-5 cursor-pointer">
            <Tooltip color={TOOLTIP_BG_COLOR} title="Edit Test Data set">
              <img
                src={EDIT_ICON}
                alt="edit"
                onClick={() => {
                  dispatch(getTestDataSetById(testDataSet?.id));
                  setIsEditTestDataSet(true);
                }}
              />
            </Tooltip>
          </span>
        )}
        <span className="m-5 ">
          <Tooltip color={TOOLTIP_BG_COLOR} title="Clone">
            <img
              src={COPY_ICON}
              alt="copy"
              onClick={() =>
                cloneModal(testDataSet?.name, `Default`, () => {
                  dispatch(cloneSystemKeyByTestDataSetId(testDataSet?.id));
                })
              }
            />
          </Tooltip>
        </span>
        {(testDataSet.id && (
          <span className="m-5">
            <Tooltip color={TOOLTIP_BG_COLOR} title="Delete">
              <img
                src={DELETE_ICON}
                alt="delete"
                onClick={() =>
                  deleteModal(`Are you sure you want to delete "${testDataSet?.name}"?`, () => {
                    dispatch(deleteTestDataSet(testDataSet?.id));
                  })
                }
              />
            </Tooltip>
          </span>
        )) || (
          <span className="m-5">
            <Tooltip color={TOOLTIP_BG_COLOR} title="View">
              <img
                src={EYE_ICON}
                alt="show"
                onClick={() => {
                  setIsViewTestData(true);
                  dispatch(fetchSystemKeyByProjectId(searchKeyword, currentPage));
                }}
              />
            </Tooltip>
          </span>
        )}
      </div>
    );
  };

  const columns = [
    {
      title: "Test Data Set Details",
      dataIndex: "testDataDetails",
    },
    {
      title: "Data Sets",
      dataIndex: "dataSets",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "end",
    },
  ];

  const testDataSet =
    testDataSetList &&
    (testDataSetList.length > 1 ||
      (testDataSetList.length === 1 && testDataSetList[0].name !== "Default") ||
      searchKeyword === "") &&
    testDataSetList?.map((row) => ({
      key: row?.id,
      testDataDetails: (
        <div>
          <div>
            <Label>Data Set Name: </Label>
            <span className="dataSetTitle">{row?.name}</span>
          </div>
          {row?.createdBy !== null && row?.createdBy && (
            <div>
              <Label>Created By: </Label>
              <span className="text-light1">{row?.createdBy}</span>
            </div>
          )}
          {row?.updatedBy !== null && row?.updatedBy && (
            <div>
              <Label>Updated By: </Label>
              <span className="text-light1">{row?.updatedBy}</span>
            </div>
          )}
        </div>
      ),
      dataSets: (
        <div>
          <Label>Count of Key/Value Pairs: </Label>
          <span className="text-light1">{row?.count}</span>
        </div>
      ),
      action: TestDataSetActions(row),
    }));

  return (
    <div className="pageScroll">
      {!isEditTestDataSet && !isViewTestData && (
        <>
          <div className="display-flex justify-end mb-10 p-10">
            <CommonSearch
              placeHolder="Search by Data Set name"
              searchDetails={(searchText) => dispatch(fetchSystemKeyByProjectId(searchText))}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </div>
          <div className="w-100 p-10">
            <Spin spinning={fetchingTestDataSetList} tip="Loading" className="spinner-center">
              <TableComponent data={testDataSet} columns={columns} />
            </Spin>
          </div>
          <div className="pull-right">
            {!_.isEmpty(testDataSetList) &&
              (testDataSetList.length > 1 ||
                (testDataSetList.length === 1 && testDataSetList[0].name !== "Default")) && (
                <PaginationComponent
                  currentPageItems={pageItems}
                  totalItems={totalCounts}
                  currentPage={currentPage}
                  totalPage={totalPageCount}
                  callPaginationAPI={(pageNumber) => dispatch(fetchSystemKeyByProjectId(searchKeyword, pageNumber))}
                />
              )}
          </div>
        </>
      )}
      {isEditTestDataSet && (
        <Spin spinning={fetchingTestDataSetList} className="mt-per-50">
          {testDataSetForEdit && (
            <div>
              <EditTestDataSet setIsEditTestDataSet={setIsEditTestDataSet} isSystemKeyView={true} />
            </div>
          )}
        </Spin>
      )}

      {isViewTestData && (
        <div>
          <ViewDefaultTestDataSet
            title={"System Key Test Data Profile (Base-Profile)"}
            stepType={SYSTEM_KEYS}
            setIsViewTestData={setIsViewTestData}
          />
        </div>
      )}
    </div>
  );
};

export default SystemKeyTestDataSetList;
