import { Popconfirm } from "antd";
import { default as React } from "react";
import { useDispatch } from "react-redux";
import {
  resetRecTestStepAction,
  saveRecTestStepAction,
  updateRecTestStepAction,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { STEP } from "../../../Constants";
import {
  getUnSavedRecElement,
  isEmpty,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isStepLoopStepChange,
  isStepPreConditionChange,
} from "../../../Util";

const checkPopConfirmVisibilityForStep = (
  recTestStep,
  unSavedRecStepTag,
  isForConditionTitle,
  unSavedRecElementGuid
) => {
  let status =
    recTestStep.id !== undefined &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    recTestStep.tag === unSavedRecStepTag &&
    recTestStep.recTestStepProperty?.action === "step" &&
    isNullOrUndefinedOrEmpty(unSavedRecElementGuid) &&
    recTestStep.recTestStepProperty?.previous_stepName !== undefined &&
    Object.keys(getUnSavedRecElement(recTestStep.recTestStepProperty?.recElements)).length === 0;
  if (isForConditionTitle) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      status = isStepPreConditionChange(unSavedRecStepTag, recTestStep);
    }
    if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      status = isStepLoopStepChange(unSavedRecStepTag, recTestStep);
    }
  }
  return status;
};
const checkPopconfirmVisibilityForAction = (recTestStep, unSavedRecStepTag) => {
  return (
    recTestStep.id !== undefined &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    recTestStep.tag === recTestStep &&
    recTestStep.recTestStepProperty?.action === "step"
  );
};
const UnSavedRecTestStepPopConfirm = (props) => {
  const { recTestStep, unSavedRecStepTag, setRectTestStepId, isForConditionTitle, unSavedRecElementGuid, recTestStepList } =
    props;

  const dispatch = useDispatch();
  const handleConfirm = () => {
    if (unSavedRecStepTag === recTestStep.tag) {
      // if (recTestStep?.recTestStepProperty?.action === STEP) {
      //   dispatch(saveRecTestStepAction(recTestStep));
      // } else {
      dispatch(updateRecTestStepAction(recTestStep));
      // }
    } else {
      return undefined;
    }
  };
  return (
    <Popconfirm
      key={recTestStep.id}
      placement="top"
      visible={
        recTestStep?.recTestStepProperty?.stepName.length >= 1
          ? checkPopConfirmVisibilityForStep(recTestStep, unSavedRecStepTag, isForConditionTitle, unSavedRecElementGuid)
          : checkPopconfirmVisibilityForAction(recTestStep, unSavedRecStepTag, unSavedRecElementGuid)
      }
      title="Do you want to Save The Changes?"
      onConfirm={() => handleConfirm()}
      onCancel={() => {
        if (setRectTestStepId) setRectTestStepId(null);
        unSavedRecStepTag === recTestStep.tag && dispatch(resetRecTestStepAction(recTestStep.tag,recTestStepList));
      }}
      okText="Save"
      cancelText="Cancel"
    />
  );
};
export default UnSavedRecTestStepPopConfirm;
