import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import { getPlatformDevice, myTeams, getTeamMembersByTeamId } from "../../../../actions/TeamsAction";
import { getOrgMembersByOrgId } from "../../../../actions/OrganizationAction";
import styles from "./CreateProjectV2.module.scss";

const CreateProjectV2 = ({ orgId, projectData, setProjectData, teamNameOfProject }) => {
  const dispatch = useDispatch();
  const nameInput = useRef(null);
  const { teamIdOfProject } = useSelector((state) => state.ProjectsReducer);
  const { platformDataList, listOfTeamMembers } = useSelector((state) => state.TeamsReducer);
  const { myTeamsList } = useSelector((state) => state.TeamsReducer);
  const { listOfOrgMembers } = useSelector((state) => state.OrganizationReducer);

  useEffect(() => {
    dispatch(getPlatformDevice());
    dispatch(myTeams(orgId));
  }, []);

  const orgMemberList = useMemo(() => {
    return (listOfOrgMembers || []).map((element) => (
      <Option key={element.email} value={element.email}>
        {element.email}
      </Option>
    ));
  }, [listOfOrgMembers]);

  const teamMemberList = useMemo(() => {
    return (listOfTeamMembers || []).map((element) => (
      <Option key={element.email} value={element.email}>
        {element.email}
      </Option>
    ));
  }, [listOfTeamMembers]);

  const defaultTeamMembers = listOfTeamMembers.map((member) => member.email);

  const childrenTeam = useMemo(() => {
    return (myTeamsList || []).map((element) => (
      <Option key={element.teamId} value={element.teamId}>
        {element.teamName}
      </Option>
    ));
  }, [myTeamsList]);

  const platformList = useMemo(() => {
    return (platformDataList || []).map((element) => (
      <Option key={element.id} value={element.id}>
        {element.name}
      </Option>
    ));
  }, [platformDataList]);

  useEffect(() => {
    if (projectData?.teamId) {
      dispatch(getTeamMembersByTeamId(projectData?.teamId));
      dispatch(getOrgMembersByOrgId(orgId, projectData.teamId));
    }
  }, [projectData?.teamId]);

  useEffect(() => {
    if (nameInput && nameInput.current) {
      setTimeout(() => {
        nameInput?.current?.focus();
      }, 1000);
    }
  }, [nameInput]);

  useEffect(() => {
    setProjectData((prevData) => ({ ...prevData, teamMembers: defaultTeamMembers }));
  }, [teamMemberList]);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleSelectChange = useCallback((name, value) => {
    setProjectData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  return (
    <>
      <div className={styles["create-project-title"]}>Create New Project</div>
      <div className={styles["scroll-content"]}>
        <div className={styles["details-title"]}>Project Details</div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>
            Project Name
            <span className={styles["field-required"]}>*</span>
          </div>
          <Input
            className={styles["modal-input"]}
            name="name"
            value={projectData?.name}
            onChange={handleInputChange}
            placeholder="For Example: Development Or QA"
            ref={nameInput}
          />
        </div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]} `}>
            App Name
            <span className={styles["field-required"]}>*</span>
          </div>
          <Input
            className={styles["modal-input"]}
            name="appName"
            value={projectData?.appName}
            onChange={handleInputChange}
            placeholder="Please Enter App name"
          />
        </div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>
            Project Description
            <span className={styles["field-required"]}>*</span>
          </div>
          <TextArea
            className={styles["modal-textArea"]}
            rows={4}
            name="description"
            value={projectData?.description}
            onChange={handleInputChange}
            placeholder="Insert Project Description."
          />
        </div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>
            Platform
            <span className={styles["field-required"]}>*</span>
          </div>
          <Select
            className={styles["modal-select-box"]}
            value={projectData?.selectedPlatform}
            placeholder={"Select Platform"}
            onChange={(e) => handleSelectChange("platformTypeId", e)}
          >
            {platformList}
          </Select>
        </div>
        <div className={styles["details-title"]}>Team Details</div>
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>
            Team
            <span className={styles["field-required"]}>*</span>
          </div>

          <Select
            className={styles["modal-select-box"]}
            placeholder="Select Team"
            defaultValue={teamIdOfProject}
            value={teamNameOfProject ? teamNameOfProject : projectData?.teamId}
            onChange={(e) => handleSelectChange("teamId", e)}
          >
            {childrenTeam}
          </Select>
        </div>

        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>Team Members</div>
          <Select
            className={styles["modal-select-box"]}
            placeholder="Selected Team Members"
            mode="multiple"
            tokenSeparators={[","]}
            defaultValue={teamMemberList}
            value={projectData?.teamMembers}
            onChange={(e) => handleSelectChange("teamMembers", e)}
          >
            {teamMemberList}
          </Select>
        </div>

        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>Additional Members</div>
          <Select
            className={styles["modal-select-box"]}
            mode="multiple"
            tokenSeparators={[","]}
            placeholder={"Add Members who are not part of the Team"}
            value={projectData?.orgMembers}
            onChange={(e) => handleSelectChange("orgMembers", e)}
          >
            {orgMemberList}
          </Select>
        </div>
      </div>
    </>
  );
};

export default CreateProjectV2;
