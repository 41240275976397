import { Collapse, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { IOS } from "../../../../Constants";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import TestPlanResultScenarioDetails from "../TestPlanResultScenarioDetails";
import ios_icon from "../../../../images/ios_icon.svg";
import android_icon from "../../../../images/android_icon.svg";
import DeviceHoverCard from "../../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import { highlightResult } from "../../../../Util";
import TestPlanResultScenarioDetailsV2 from "../../../TestPlanV2/TestPlanResultDetailsV2/TestPlanResultScenarioDetailsV2";

const { Panel } = Collapse;

const TestPlanResultDeviceView = ({isTestBlock,runNumber}) => {
  const { testPlanResultDetails, runResultDetails} = useSelector((state) => state.TestPlanReducer);

  return (
    <TestPlanResultDetailsScenario className="mt-50">
      {testPlanResultDetails != null &&
        testPlanResultDetails[0] &&
        testPlanResultDetails[0]?.devices?.map((v, k) => {
          return (
            <>
              <Collapse className="border-none" expandIconPosition="right" key={k}>
                <Panel
                className="mt-10"
                  showArrow={true}
                  header={
                    <Row className="display-flex">
                      <div className="mr-10">
                        {v?.platformName === IOS ? (
                          <img className="device-img" alt="IOS" src={ios_icon} />
                        ) : (
                          <img className="device-img" alt="ANDROID" src={android_icon} />
                        )}
                      </div>
                      <div>
                        <div className="mb-5">
                          <span className="font-weight-700 mr-10">Device Name: </span>
                          <span>
                            <DeviceHoverCard deviceDetails={v} />
                          </span>
                        </div>
                        <div>
                          <span className="font-weight-700 mr-10">Result: </span>
                          {highlightResult(v?.totalResult?.result)}
                        </div>
                      </div>
                    </Row>
                  }
                >
                  <TestPlanResultScenarioDetailsV2
                    key={k}
                    scenariosList={v.scenarios}
                    runResultDetails={runResultDetails}
                    isTestBlock={isTestBlock}
                    runNumber={runNumber}
                  />
                </Panel>
              </Collapse>
            </>
          );
        })}
    </TestPlanResultDetailsScenario>
  );
};

export default TestPlanResultDeviceView;
