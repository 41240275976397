import { RECORD_10_PER_PAGE, SUCCESS_STATUS, UPDATE_TEST_DATA_SET_DATA } from "../../Constants";
import { updateResponse } from "../../Util";
import * as TestDataSetCommonAction from "./TestDataSetCommonAction";
import _ from "lodash";
import { fetchTestDataSetByProfileId } from "./TestDataSetRedirectAction";

export function _fetchTestDataSetByProfileId(itemId, search = "", pageNumber = 1, showCount = true,includeAllData=true) {
  return (dispatch) => {
    let data = {
      search: search,
      testPlanId: itemId,
      pageNumber: pageNumber,
      recordPerPage: RECORD_10_PER_PAGE,
      showCount,
      includeAllData
    };
    dispatch(TestDataSetCommonAction._getDataProfileByItemId(data));
  };
}

export function _cloneTestDataSetByTestPlanId(testDataSetId, testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    let request = { testPlanId: testDataSetAssigneeDetail.id, testDataSetId: testDataSetId, projectId: projectId };
    new Promise((resolve) => resolve(dispatch(TestDataSetCommonAction.callCloneTestDataSet(request)))).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch(_fetchTestDataSetByProfileId(testDataSetAssigneeDetail.id));
        }
      }
    );
  };
}

export function _getTestDataSetById(testDataSetId, testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    let data = { testDataSetId, testPlanId: testDataSetAssigneeDetail.id };
    dispatch(TestDataSetCommonAction._callTestDataSetById(data));
  };
}

export function _changeTestDataCustomValue(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataIsCustomReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", value);
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

function _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, key, value) {
  // Update For Scenario Level Test data
  updateTestDataSetForEdit["testDataValues"] = TestDataSetCommonAction.updateTestDataValues(
    testDataRow,
    updateTestDataSetForEdit?.testDataValues,
    key,
    value
  );
  // Update For Test Scenario level Test data
  updateTestDataSetForEdit["testScenarioTestDataSaveReq"] = updateTestDataSetForEdit?.testScenarioTestDataSaveReq.map(
    (testScenarioTestData) => {
      testScenarioTestData["testDataValues"] = TestDataSetCommonAction.updateTestDataValues(
        testDataRow,
        testScenarioTestData["testDataValues"],
        key,
        value
      );
      testScenarioTestData["testBlockTestDataSaveReq"] = testScenarioTestData?.testBlockTestDataSaveReq.map(
        (testBlockTestData) => {
          testBlockTestData["testDataValues"] = TestDataSetCommonAction.updateTestDataValues(
            testDataRow,
            testBlockTestData["testDataValues"],
            key,
            value
          );
          return testBlockTestData;
        }
      );
      return testScenarioTestData;
    }
  );
  return updateTestDataSetForEdit;
}

export function _saveTestDataSet(testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = { ...updateTestDataSetForEdit, testPlan: { id: testDataSetAssigneeDetail?.id } };
    new Promise((resolve) =>
      resolve(dispatch(TestDataSetCommonAction._callSaveTestDataSet(updateTestDataSetForEdit)))
    ).then(() => {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    });
  };
}

export function _makeTestDataSetDefault(checked, testDataSet) {
  return async (dispatch, getState) => {
    const { testDataSetAssigneeDetail, testDataSetList } = getState().TestDataSetReducer;
    let data = {};
    if (testDataSet.id === undefined) {
      let alreadySelected = testDataSetList.filter((item) => item.isDefault === 1);
      if (alreadySelected.length > 0) {
        data = {
          testPlanId: testDataSetAssigneeDetail.id,
          testDataSetAssigneeDetail,
          isDefault: 0,
          testDataSetId: alreadySelected[0].id,
        };
      }
    } else {
      data = {
        testPlanId: testDataSetAssigneeDetail.id,
        testDataSetAssigneeDetail,
        isDefault: checked ? 1 : 0,
        testDataSetId: testDataSet.id,
      };
    }

    new Promise((resolve) => resolve(dispatch(TestDataSetCommonAction._callMakeTestDataSetDefault(data)))).then(
      (response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail.id));
        }
      }
    );
  };
}

export function _resetTestDataCustomValue(testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      undefined
    );
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", false);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      undefined
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}
