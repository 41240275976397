import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { Checkbox, Col, Row, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { changeTestJobScheduleData } from "../../../../actions/TestJobActions";
import { TOOLTIP_BG_COLOR, weekDays } from "../../../../Constants";
import { totalHours, totalMin, getOrdinal } from "../../../../Util";
import { SearchOutlined } from "@ant-design/icons";

const RecurringSchedule = (props) => {
  const dispatch = useDispatch();
  const { timeZoneList, testJobScheduleDetail } = props;
  const [selectedAllDays, setSelectedAllDays] = useState(false);
  const [selectedAllMinutes, setSelectedAllMinute] = useState(false);
  const [selectedAllHours, setSelectedAllHours] = useState(false);
  const [onFocus, setOnFocus] = useState(false);

  useEffect(() => {
    setSelectedAllDays(_.isEqual(testJobScheduleDetail?.days, weekDays) && true);
    setSelectedAllMinute(_.isEqual(testJobScheduleDetail?.minutes, totalMin) && true);
    setSelectedAllHours(_.isEqual(testJobScheduleDetail?.hours, totalHours) && true);
  }, [testJobScheduleDetail]);

  const handleChange = (e, name, value) => {
    if (e) {
      if (name === "days") {
        dispatch(
          changeTestJobScheduleData({
            ...testJobScheduleDetail,
            days: [...value],
            minutes: _.isEmpty(testJobScheduleDetail?.minutes) ? ["0"] : testJobScheduleDetail?.minutes,
            hours: _.isEmpty(testJobScheduleDetail?.hours) ? ["0"] : testJobScheduleDetail?.hours,
          })
        );
      } else {
        dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, [name]: [...value] }));
      }
    } else {
      if (name === "days") {
        dispatch(
          changeTestJobScheduleData({
            ...testJobScheduleDetail,
            days: [],
            minutes: _.isEmpty(testJobScheduleDetail?.minutes) ? ["0"] : testJobScheduleDetail?.minutes,
            hours: _.isEmpty(testJobScheduleDetail?.hours) ? ["0"] : testJobScheduleDetail?.hours,
          })
        );
      } else {
        dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, [name]: [] }));
      }
    }
  };

  const setDays = (days) => {
    let newArray = [...testJobScheduleDetail.days];
    if (newArray.includes(days)) {
      const dId = newArray.indexOf(days);
      newArray.splice(dId, 1);
    } else {
      newArray = [...testJobScheduleDetail.days, days];
    }
    dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, days: newArray }));
    if (newArray.length) {
      dispatch(
        changeTestJobScheduleData({
          ...testJobScheduleDetail,
          days: newArray,
          minutes: _.isEmpty(testJobScheduleDetail?.minutes) ? ["0"] : testJobScheduleDetail?.minutes,
          hours: _.isEmpty(testJobScheduleDetail?.hours) ? ["0"] : testJobScheduleDetail?.hours,
        })
      );
    }
  };

  const setMinutes = (minute) => {
    let newArray = [...testJobScheduleDetail.minutes];
    if (newArray.includes(minute)) {
      const dId = newArray.indexOf(minute);
      newArray.length !== 1 && newArray.splice(dId, 1);
    } else {
      newArray = [...testJobScheduleDetail.minutes, minute];
    }
    dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, minutes: newArray }));
  };

  const setHours = (hour) => {
    let newArray = [...testJobScheduleDetail.hours];
    if (newArray.includes(hour)) {
      const dId = newArray.indexOf(hour);
      newArray.length !== 1 && newArray.splice(dId, 1);
    } else {
      newArray = [...testJobScheduleDetail.hours, hour];
    }
    dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, hours: newArray }));
  };

  return (
    <div>
      <div>
        <Row className="mb-5 display-flex justify-space-between">
          <Col span={24} className="cursor-pointer mt-5">
            <Col span={4} className="mr-5 font-size-14 mb-5 font-weight-500 align-self-flex-start">
              Week days
            </Col>
            <Col className="display-flex flex-wrap">
              <span
                onClick={(e) => handleChange(selectedAllDays ? false : true, "days", weekDays)}
                className={selectedAllDays ? "selectDay bg-lightPurple font-color-white " : "selectDay"}
              >
                All
              </span>
              {weekDays.map((days, index) => (
                <span
                  onClick={() => setDays(days)}
                  key={index}
                  className={
                    testJobScheduleDetail?.days.includes(days)
                      ? "selectDay bg-lightPurple font-color-white "
                      : "selectDay"
                  }
                >
                  {days}
                </span>
              ))}
            </Col>
          </Col>
        </Row>

        <Row className="mb-5 display-flex justify-space-between">
          <Col span={24} className="cursor-pointer mt-5">
            <Col span={4} className="mr-5 font-size-14 mb-5 font-weight-500 align-self-flex-start">
              Hours
            </Col>
            <Col className="display-flex flex-wrap">
              <span
                onClick={(e) => handleChange(selectedAllHours ? false : true, "hours", totalHours)}
                className={selectedAllHours ? "selectDay bg-lightPurple font-color-white " : "selectDay"}
              >
                All
              </span>
              {totalHours.map((hour, index) => (
                <span
                  key={index}
                  onClick={(e) => {
                    setHours(hour);
                  }}
                  className={
                    testJobScheduleDetail?.hours.includes(hour)
                      ? "selectDay bg-lightPurple font-color-white"
                      : "selectDay"
                  }
                >
                  {hour < 10 ? "0" + hour : hour}
                </span>
              ))}
            </Col>
          </Col>
        </Row>

        <Row className="mb-5 display-flex justify-space-between">
          <Col span={24} className="cursor-pointer mt-5">
            <Col span={4} className="mr-5 font-size-14 mb-5 font-weight-500 align-self-flex-start">
              Minutes
            </Col>
            <Col className="display-flex flex-wrap">
              <span
                onClick={(e) => handleChange(selectedAllMinutes ? false : true, "minutes", totalMin)}
                className={selectedAllMinutes ? "selectDay bg-lightPurple font-color-white " : "selectDay"}
              >
                All
              </span>
              {totalMin.map((minute, index) => (
                <span
                  key={index}
                  onClick={() => {
                    setMinutes(minute);
                  }}
                  className={
                    testJobScheduleDetail?.minutes.includes(minute)
                      ? "selectDay bg-lightPurple font-color-white"
                      : "selectDay"
                  }
                >
                  {minute < 10 ? "0" + minute : minute}
                </span>
              ))}
            </Col>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="font-size-14 mt-10">
            <div span={4} className="mr-5 font-size-14 font-weight-500 align-self-flex-start">
              Timezone
            </div>
            <div className="w-100">
              <Select
                showSearch
                onFocus={() => setOnFocus(true)}
                onBlur={() => setOnFocus(false)}
                suffixIcon={onFocus && <SearchOutlined className="rotate-180" />}
                className="ant-collapse w-40"
                value={testJobScheduleDetail.timeZoneName || undefined}
                placeholder="Select TimeZone"
                onChange={(e) => {
                  setOnFocus(false);
                  dispatch(changeTestJobScheduleData({ ...testJobScheduleDetail, timeZoneName: e }));
                }}
              >
                {timeZoneList}
              </Select>
            </div>
          </Col>
        </Row>

        {testJobScheduleDetail && !_.isEmpty(testJobScheduleDetail?.days) && (
            <Col span={24} className="scheduleTextContainer mt-10">
              Execution on Every{" "}
              <span className="font-weight-500">
                {testJobScheduleDetail && testJobScheduleDetail?.minutes.toString().replaceAll(",", ",  ")}
                {getOrdinal(testJobScheduleDetail?.minutes.slice(-1).pop())}
              </span>{" "}
              minute past the{" "}
              <span className="font-weight-500">
                {testJobScheduleDetail && testJobScheduleDetail?.hours.toString().replaceAll(",", ",  ")}{" "}
                {getOrdinal(testJobScheduleDetail?.hours.slice(-1).pop())}
              </span>{" "}
              hour on{" "}
              <span className="text-transform-capital font-weight-500 ">
                {testJobScheduleDetail && testJobScheduleDetail?.days.toString().replaceAll(",", ", ").toLowerCase()}
              </span>{" "}
              <span className="font-weight-500">({testJobScheduleDetail?.timeZoneName})</span>
            </Col>
        )}
      </div>
    </div>
  );
};

export default RecurringSchedule;
