import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ALL, All_RECORDS, MY_JOBS, ONETIME, OWNED, RECURRING, SHARED, SHARED_WITH_ME } from "../../../../Constants";
import { getSelectedFrequencyStatus } from "../../../../Util";
import { changeBuildJobListFilter, getTestJob } from "../../../../actions/TestJobActions";
import HeaderButtonsGroupV2 from "../../../CommonComponents/HeaderButtonsGroupV2/HeaderButtonsGroupV2";
import FilterComponentV2 from "../../../CoreComponents/FilterComponentV2/FilterComponentV2";
import CommonSearchV2 from "../../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./AllJobsV2.module.scss";

const filterPermission = [
  { id: OWNED, value: MY_JOBS },
  { id: SHARED, value: SHARED_WITH_ME },
  { id: "", value: All_RECORDS },
];

const filterList = [
  {
    title: "One time",
    name: ONETIME,
  },
  {
    title: "Recurring",
    name: RECURRING,
  },
  {
    title: "All",
    name: ALL,
  },
];

const AllJobsDetailsHeaderV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, searchKeyword, searchByFrequency, setSearchKeyword, setSearchByFrequency } = props;
  const { testJobListFilter } = useSelector((state) => state.TestJobReducer);

  const handleFilters = (e) => {
    dispatch(changeBuildJobListFilter(e, projectId, searchKeyword));
  };

  return (
    <div className={styles["jobs_Details_Header"]}>
      <div className="display__flex">
        <HeaderButtonsGroupV2
          data={filterPermission}
          activeButton={testJobListFilter?.id}
          onClick={(e) => handleFilters(e)}
        />
      </div>
      <div className={styles["search_Box_And_Filter"]}>
        <div>
          <CommonSearchV2
            searchDetails={(searchWord) =>
              dispatch(getTestJob(1, projectId, searchWord, searchByFrequency, testJobListFilter))
            }
            placeHolder="Search By Job Name"
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            className={styles["search_jobs"]}
          />
        </div>

        <FilterComponentV2
          filterList={filterList}
          handleFilterChange={(filter) => {
            setSearchByFrequency(filter);
            dispatch(getTestJob(1, projectId, searchKeyword, filter, testJobListFilter));
          }}
          selectedFilter={getSelectedFrequencyStatus(searchByFrequency)}
        />
      </div>
    </div>
  );
};

export default AllJobsDetailsHeaderV2;
