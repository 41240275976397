import { Col, Row } from "antd";
import React from "react";
import { operatorChange } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerifyElementPropertyV2 from "./VerifyElementPropertyV2";
import VerifyElementTextV2 from "./VerifyElementTextV2";
import VerificationBuilderV2 from "./VerificationBuilderV2";
import styles from "./VerificationsV2.module.scss";

const VerificationGroupV2 = (props) => {
  const { data, disabled } = props;
  const prepareGroupItems = (itemsData) => {
    let items = [];
    itemsData.childRecStepVerifyElementProperties.map((v, k) => {
      let Item = verificationRowComponent[v.type];
      if (Item) {
        items.push(
          <React.Fragment>
            <Item
              key={k}
              data={v}
              uuid={itemsData.uuid}
              recTestStep={props.recTestStep}
              disabled={disabled}
              recElementVerification={props.recElementVerification}
              operatorChange={v.type === "GROUP" ? operatorChange : () => {}}
              type={"GROUP"}
            />
            {itemsData.childRecStepVerifyElementProperties.length > 1 &&
              itemsData.childRecStepVerifyElementProperties.length - 1 !== k && (
                <div className={styles["verification-Operator"]}>
                  <div className={styles["verification_operator_label"]}>{data.operator}</div>
                </div>
              )}
          </React.Fragment>
        );
      }
    });
    return items;
  };

  return (
    <React.Fragment>
      <Row className={styles['verification-group-main-handler']}>
        <Col md={24}>
          {data.operator !== undefined && (
            <VerificationBuilderV2
              disabled={props.disabled}
              isElementSelected={props.disabled}
              operator={data.operator}
              uuid={data.uuid ? data.uuid : data.id}
              recTestStep={props.recTestStep}
              recElement={props.recElementVerification}
              operatorChange={operatorChange}
            />
          )}
          {data?.childRecStepVerifyElementProperties ? prepareGroupItems(data) : ""}
        </Col>
      </Row>
    </React.Fragment>
  );
};
const verificationRowComponent = {
  VerifyElementProperty: VerifyElementPropertyV2,
  VerifyElementText: VerifyElementTextV2,
  GROUP: VerificationGroupV2,
};
export default VerificationGroupV2;
