import _ from "lodash";
import {
  ADD_GROUP_QUERY_BUILDER,
  ADD_TAG_QUERY_BUILDER,
  ALL_TEST_PLANS,
  CHANGED_FILTER_NAME_FIELD,
  CHANGE_AWS_DEVICE_BUILD_SELECTION,
  CHANGE_AWS_DEVICE_POOL_SELECTION,
  CHANGE_AWS_DEVICE_PROJECT_SELECTION,
  CHANGE_REPEAT_STEP,
  CHANGE_EXECUTION_FARM_SELECTION,
  CHANGE_LODESTONE_DEVICE_FARM_SELECTION,
  CHANGE_LODESTONE_DEVICE_SELECTION,
  CHANGE_QUERY_BUILDER_OPERATOR,
  CHANGE_REPORT_RESULT_TAB,
  CHANGE_SELECTED_ALL_SCENARIO_OPTION,
  CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW,
  CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER,
  CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS,
  CHANGE_TEST_PLAN_DETAILS,
  CHANGE_TEST_PLAN_EXECUTE_COUNT,
  CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER,
  CHANGE_TEST_PLAN_TAB,
  CLEAR_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS,
  CLICKED_SAVED_SCENARIO_FILTER,
  CLOSE_TEST_PLAN_TREND_DRAWER,
  COMPARISON_SINGLE_LOCALE,
  CREATE_NEW_FILTER_FLAG,
  DELETE_TEST_PLAN_DETAIL,
  EMAIL_VALIDITY_CHECKED,
  EXECUTION_VIEW,
  EXPORT_SCENARIO_LOADING,
  FETCHING_TEST_PLAN_LIST,
  FETCHING_TEST_PLAN_RECORDER_SCENARIO,
  FETCHING_TEST_PLAN_RESULT_LIST,
  FETCHING_TEST_PLAN_STEP_LIST,
  GET_FREE_DEVICES_LIST,
  GET_QUERY_BUILD_DATA,
  GET_REF_LOCALE_FOR_PREVIEW,
  GET_REF_RUN_NUMBER_FOR_PREVIEW,
  GET_SCENARIO_FILTER,
  GET_SCENARIO_FILTER_DATA,
  GET_TAG_BY_TEST_PLAN_ID,
  GET_TEST_PLAN_BY_ID,
  GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA,
  HANDLE_CHANGE_REPORT_MANAGER_DATA,
  HIDE_SCHEDULE_TEST_PLAN_MODAL,
  OPEN_TEST_PLAN_TREND_DRAWER,
  PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER,
  PREVIEW_DOWNLOAD_BY_RUN_NUMBER,
  REMOVE_GROUP_QUERY_BUILDER,
  REMOVE_TAG_QUERY_BUILDER,
  REQUESTED_FOR_DOWNLOAD_REPORT_LOGS,
  REQUEST_FOR_DOWNLOAD_REPORT_LOGS,
  RESET_QUERY_BUILDER,
  RESET_REPORT_MANAGER_DATA,
  RESET_SCHEDULED_TEST_PLAN,
  SAVE_SCENARIO_FILTER,
  SAVE_SCHEDULED_TEST_PLAN,
  SAVE_TEST_PLAN,
  SCENARIO_LOADING,
  SCHEDULER_BUILD_LABEL,
  SCHEDULER_SPINNER,
  SCHEDULE_SAVE_CHECKED,
  SELECTED_RUN_RESULT_VIEW,
  SET_RESULT_TYPE,
  SET_RUN_RESULT_SCENARIO_ID,
  SET_SCHEDULE_TEST_PLAN,
  SHOW_SCHEDULE_TEST_PLAN_MODAL,
  STEP,
  SUCCESS_STATUS,
  TAG_IN_QUERY_BUILDER,
  TEST_PLAN_DETAILS_LIST,
  TEST_PLAN_LIST,
  TEST_PLAN_LOADING,
  TEST_PLAN_RECORDED_TEST_SCENARIO_LIST,
  TEST_PLAN_REPORT_LIST,
  TEST_PLAN_RESULT_BY_RUN_NUMBER,
  TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET,
  TEST_PLAN_RESULT_LIST,
  TEST_PLAN_STEPS_LIST,
  TEST_STEP_LIST_BY_SCENARIO_ID,
  UPDATED_TEST_PLAN_SAVED,
  UPDATE_TAG_VALUE_QUERY_BUILDER,
  CHANGE_RETRY_STEP,
  RUN_DETAILS_BY_RUN_NUMBERS,
  RUN_DETAILS_LOADING,
  TEST_PLAN_STEPS_LIST_ID,
  REMOVE_TEST_PLAN_STEPS_LIST_ID,
  EDIT_TEST_PLAN,
  GET_TEST_STEP_STATUS_FOR_RESULT,
  GET_TEST_BLOCK_RESULT_DATA,
  TEST_PLAN_CHILD_STEP_LOADING,
} from "../Constants";
import * as util from "./../Util/index";
let defaultState = {
  testPlanList: undefined,
  testPlanSchedulingSpinner: false,
  fetchingTestPlanList: false,
  scheduleEnabled: false,
  emailValidChecked: false,
  createNewFilter: false,
  clickedSaveScenario: true,
  tagInQueryBuilder: false,
  FilterNameChanged: false,
  testPlanResultList: undefined,
  fetchingTestPlanResultList: false,
  scheduleTestPlanModalVisible: false,
  selectedTestScenarioId: undefined,
  selectedScheduleTestPlanDetail: undefined,
  activeTestStepAction: undefined,
  testPlanRecordedTestScenario: undefined,
  schedulerBuildLabel: " Upload the Build",
  selectedTestScenarioName: undefined,
  testStepList: undefined,
  fetchingTestStepList: false,
  selectedTestStepDetail: undefined,
  fetchingTestPlanRecordedTestScenario: false,
  testPlanExecuteCount: 1,
  testPlanResultData: [],
  tagQueryBuilderValues: {
    data: [],
    operator: "AND",
    uuid: util.getUniqueId(),
  },
  testPlanDetailData: [],
  fetchTestPlanAndScenario: false,
  fetchTestPlanDetails: false,
  testPlanSaved: false,
  currentTestPlanResultPage: 1,
  currentTestPlanPage: 1,
  totalTestPlanResultPage: 0,
  totalTestPlanPage: 0,
  testPlanResultStepsDetails: [],
  runResultDetails: [],
  deviceResultDetails: [],
  freeDeviceListForRecording: undefined,
  testPlanResultDetails: null,
  testRunDetails: [],
  saveTestPlanMsg: null,
  deleteRunResult: null,
  saveScheduleMsg: null,
  clearTestPlanDetail: null,
  saveScenarioFilterMsg: null,
  scenarioFilter: null,
  scenarioFilterData: null,
  scenarioFilterDataResponse: null,
  getTagsDetails: null,
  tagValueOfScheduler: undefined,
  selectedExecutionFarm: undefined,
  selectedDeviceFarmIds: undefined,
  selectedDeviceIds: [],
  selectedAwsDeviceBuildArn: undefined,
  liveStreamURL: [],
  testPlanDetails: undefined,
  getTestPlanById: [],
  testPlanLoading: false,
  testRailDetails: [],
  selectedTestPlanTab: ALL_TEST_PLANS,

  scheduleTestPlan: null,
  selectedTestScenarioForResultLogs: [],
  selectedTestScenarioForIds: [],
  scenarioLoading: false,
  previewRunDetails: undefined,
  testPlanTrendDrawerVisible: false,
  testPlanTrendData: undefined,
  selectedTestPlanForTrend: undefined,
  refRunNumberForPreviewList: [],
  refRunNumberLocaleForPreviewList: [],
  refScenarioGuid: {},
  selectedAllScenario: false,
  selectedComparisonPreviewView: COMPARISON_SINGLE_LOCALE,
  allScenario: [],
  getTestStepDetails: [],
  pageItems: "",
  reportManagerSearch: {
    searchKeyword: "",
    startDate: "",
    endDate: "",
    status: "",
  },
  selectedReportTab: "reportDetails",
  resultType: "",
  selectedRunResultView: EXECUTION_VIEW,
  runResultScenarioId: null,
  testStepResults: [],
  currentRepeatStep: {},
  currentRetryStep: {},
  runDetails: [],
  runDetailsLoading: false,
  testPlanStepsList: [],
  unSavedTestPlanChanges: false,
  testBlockResultDataByRunId: null,
  testPlanChildStepLoading: false,
  loadingScenarios: [],
  previousScenarioId: null,
  loadingSteps: [],
  previousStepId: null,
};

export default function TestPlanReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case FETCHING_TEST_PLAN_LIST:
      return {
        ...state,
        fetchingTestPlanList: true,
      };
    case FETCHING_TEST_PLAN_RESULT_LIST:
      return {
        ...state,
        fetchingTestPlanResultList: true,
      };
    case SHOW_SCHEDULE_TEST_PLAN_MODAL:
      resetTestPlanScheduleModal(state);
      return {
        ...state,
        scheduleTestPlanModalVisible: true,
        selectedScheduleTestPlanDetail: action.testPlanData,
      };
    case HIDE_SCHEDULE_TEST_PLAN_MODAL:
      return {
        ...state,
        scheduleTestPlanModalVisible: false,
        selectedScheduleTestPlanDetail: undefined,
        testPlanRecordedTestScenario: undefined,
      };

    case CHANGE_TEST_PLAN_TAB:
      response = action?.data;
      return {
        ...state,
        selectedTestPlanTab: action?.selectedTab,
      };

    case SET_SCHEDULE_TEST_PLAN:
      return {
        ...state,
        scheduleTestPlan: action?.isScheduleTestPlan,
      };
    case CHANGE_REPORT_RESULT_TAB:
      return {
        ...state,
        selectedReportTab: action?.value,
      };
    case SET_RESULT_TYPE:
      return {
        ...state,
        resultType: action?.value,
      };

    case TEST_PLAN_LIST:
      response = action.response;
      if (response.status === SUCCESS_STATUS) {
        return {
          ...state,
          testPlanList: response.data,
          fetchingTestPlanList: false,
        };
      } else {
        return {
          ...state,
          testPlanList: undefined,
          fetchingTestPlanList: false,
        };
      }
    case TEST_PLAN_RESULT_LIST:
      response = action.response;
      if (response.status === SUCCESS_STATUS) {
        return {
          ...state,
          testPlanResultList: response.data,
          fetchingTestPlanResultList: false,
        };
      } else {
        return {
          ...state,
          testPlanResultList: undefined,
          fetchingTestPlanResultList: false,
        };
      }
    case TEST_PLAN_RECORDED_TEST_SCENARIO_LIST:
      response = action.response;
      if (response.status === SUCCESS_STATUS) {
        return {
          ...state,
          testPlanRecordedTestScenario: response.data.data,
          fetchingTestPlanRecordedTestScenario: false,
        };
      } else {
        return {
          ...state,
          testPlanRecordedTestScenario: undefined,
          fetchingTestPlanRecordedTestScenario: false,
        };
      }
    case GET_TEST_PLAN_BY_ID:
      response = action.response;
      return {
        ...state,
        getTestPlanById: response.data.data,
        fetchingTestPlanResultList: false,
      };
    case FETCHING_TEST_PLAN_RECORDER_SCENARIO:
      return {
        ...state,
        testPlanRecordedTestScenario: undefined,
        fetchingTestPlanRecordedTestScenario: true,
      };
    case CHANGE_TEST_PLAN_EXECUTE_COUNT:
      return {
        ...state,
        testPlanExecuteCount: action.value,
      };
    case TEST_PLAN_REPORT_LIST:
      response = action.response;
      return {
        ...state,
        fetchTestPlanAndScenario: true,
        // testPlanResultData: response?.data?.testPlanList,
        testPlanResultData: response?.data?.testPlanList || state.testPlanResultData, // Use previous data if response is undefined
        totalTestPlanResultItems: response?.data?.testPlanTotalItems,
        totalTestPlanResultPage: response?.data?.testPlanTotalPages,
        totalPageItem: response?.data?.pageItems,
      };

    case CHANGE_TEST_PLAN_RESULT_LIST_PAGE_NUMBER:
      return {
        ...state,
        currentTestPlanResultPage: action.value,
      };

    case TEST_PLAN_DETAILS_LIST:
      response = action.response;
      return {
        ...state,
        fetchTestPlanDetails: true,
        testPlanDetailData: response?.data?.data,
        currentTestPlanPage: response?.data?.currentPage,
        totalTestPlanPage: response?.data?.totalItems,
        pageItems: response?.data?.pageItems,
        totalPage: response?.data?.totalCount,
      };

    case TEST_PLAN_LOADING:
      response = action.response;
      return {
        ...state,
        loadingScenarios: action.response.isLoading
          ? [...state.loadingScenarios, action.response.scenarioId]
          : state.loadingScenarios.filter((id) => id !== action.response.scenarioId),
        previousScenarioId: action.response.previousScenarioId || state.previousScenarioId,
        testPlanLoading:action.response.isLoading
      };

    case TEST_PLAN_CHILD_STEP_LOADING:
      response = action.response;
      return {
        ...state,
        loadingSteps: response?.isLoading
          ? [...state.loadingSteps, response?.parentStepId]
          : state.loadingSteps.filter((id) => id !== action?.response?.parentStepId),
        previousStepId: response?.parentStepId || state.previousStepId,
      };

    case SCENARIO_LOADING:
      response = action.response;
      return {
        ...state,
        scenarioLoading: response.isLoading,
        previewRunDetails: response.isLoading ? undefined : state.previewRunDetails,
      };

    case EXPORT_SCENARIO_LOADING:
      response = action.response;
      return {
        ...state,
        scenarioLoading: response.isLoading,
      };

    case TEST_PLAN_STEPS_LIST:
      response = action.response;
      let [first] = Object.keys(response.data);
      state.testPlanResultStepsDetails[first] = response.data[first];
      state.liveStreamURL[first] = response.data.liveStreamURL;

      return {
        ...state,
        getTestStepDetails: response?.data,
      };

    case TEST_PLAN_STEPS_LIST_ID:
      return {
        ...state,
        testPlanStepsList: [...state.testPlanStepsList, action.scenarioId],
      };

    case REMOVE_TEST_PLAN_STEPS_LIST_ID:
      if (state.testPlanStepsList?.includes(action?.scenarioId)) {
        const testPlanStepsId = state.testPlanStepsList?.indexOf(action?.scenarioId);
        state.testPlanStepsList.splice(testPlanStepsId, 1);
      }
      return {
        ...state,
        testPlanStepsList: state?.testPlanStepsList,
      };

    case TEST_PLAN_RESULT_BY_RUN_NUMBER:
      response = action.response.data;
      return {
        ...state,
        runResultDetails: response.runDetails,
        deviceResultDetails: response.deviceDetails,
        testPlanResultDetails: response.testPlanDetails,
        testRailDetails: response.testRailDetails,
        testRunDetails: response,
      };

    case GET_TEST_BLOCK_RESULT_DATA:
      return {
        ...state,
        testBlockResultDataByRunId: action?.response,
      };

    case PREVIEW_DOWNLOAD_BY_RUN_NUMBER:
      response = action.response.data;
      if (action.isForFirstTime) {
        state = { ...state, ...selectAllScenario(state, response) };
        state.selectedAllScenario = true;
        state.selectedComparisonPreviewView = COMPARISON_SINGLE_LOCALE;
      }
      return {
        ...state,
        previewRunDetails: response,
        allScenario: getAllScenario(state, response),
      };

    case PREVIEW_ALL_LOCALES_DOWNLOAD_BY_RUN_NUMBER:
      response = action.response.data;
      if (action.isForFirstTime) {
        state.selectedTestScenarioForResultLogs = response && response?.map((i) => i?.scenarioFolderLink);
        state.selectedTestScenarioForIds = response && response?.map((i) => i?.id);
        state.selectedAllScenario = true;
      }
      return {
        ...state,
        previewRunDetails: response,
      };

    case TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET:
      return {
        ...state,
        runResultDetails: [],
        deviceResultDetails: [],
        testPlanResultDetails: [],
        testRailDetails: [],
      };

    case SELECTED_RUN_RESULT_VIEW:
      return {
        ...state,
        selectedRunResultView: action?.selectedView,
      };

    case SET_RUN_RESULT_SCENARIO_ID:
      return {
        ...state,
        runResultScenarioId: action?.scenarioId,
      };

    case SAVE_TEST_PLAN:
      response = action.response;
      state.saveTestPlanMsg = null;
      if (response.status === SUCCESS_STATUS) {
        state.saveTestPlanMsg = response.message;
      }
      return {
        ...state,
      };

    case UPDATED_TEST_PLAN_SAVED:
      return {
        ...state,
        saveTestPlanMsg: null,
      };

    case CLICKED_SAVED_SCENARIO_FILTER:
      state.clickedSaveScenario = action.data;
      return {
        ...state,
      };

    case SCHEDULER_SPINNER:
      state.testPlanSchedulingSpinner = action.schedulerSpinner;
      return {
        ...state,
      };

    case SCHEDULER_BUILD_LABEL:
      if (action.testPlanList.length != 0) {
        state.schedulerBuildLabel = getSchedulerLabelValue(action.selectedTestPlanId, action.testPlanList);
        let k;
        k =
          state.schedulerBuildLabel === " Upload only .apk file"
            ? (state.acceptedBuildFiles = 1)
            : state.schedulerBuildLabel === " Upload only .ipa file"
            ? (state.acceptedBuildFiles = 2)
            : (state.acceptedBuildFiles = 0);

        state.acceptedBuildFiles = k;
      } else {
        state.schedulerBuildLabel = " Upload the Build";
        state.acceptedBuildFiles = 0;
      }
      return {
        ...state,
      };

    case SAVE_SCHEDULED_TEST_PLAN:
      response = action.response;
      state.saveScheduleMsg = null;
      if (response && response.status === SUCCESS_STATUS) {
        state.saveScheduleMsg = response.message;
        state.testPlanSchedulingSpinner = false;
      } else {
        state.testPlanSchedulingSpinner = false;
      }

      return {
        ...state,
      };
    case RESET_SCHEDULED_TEST_PLAN:
      return {
        ...state,
        tagValueOfScheduler: undefined,
        scenarioFilterData: null,
        scenarioFilterDataResponse: null,
        saveScheduleMsg: null,
        freeDeviceListForRecording: undefined,
        selectedExecutionFarm: undefined,
        selectedDeviceFarmIds: undefined,
        selectedDeviceIds: [],
        selectedAwsDeviceBuildArn: undefined,
      };

    case DELETE_TEST_PLAN_DETAIL:
      response = action.response;
      state.clearTestPlanDetail = null;
      if (response && response.status === SUCCESS_STATUS) {
        state.clearTestPlanDetail = response.message;
      }
      return {
        ...state,
      };

    case FETCHING_TEST_PLAN_STEP_LIST:
      return {
        ...state,
        fetchingTestStepList: true,
      };

    case GET_FREE_DEVICES_LIST:
      return {
        ...state,
        selectedRecTestScenarioId: action.recTestScenarioId,
        selectedRecTestScenarioPlatform: action.recTestScenarioPlatform,
        freeDeviceListForRecording: action.response.data,
        selectedFreeDeviceForRecording: undefined,
      };

    case TEST_STEP_LIST_BY_SCENARIO_ID:
      if (action.response && action.response.status === SUCCESS_STATUS) {
        if (action.updateSelectedRecTestStepDetail && state.selectedTestStepDetail) {
          state.selectedTestStepDetail = findTestStepDetailFromTestStepList(
            action.response.data,
            state.selectedTestStepDetail.id
          );
        } else if (
          action.response.data &&
          action.response.data.length > 0 &&
          action.response.data[0].recTestSteps &&
          action.response.data[0].recTestSteps.length > 0
        ) {
          if (action.response.data[0].recTestSteps[0].recTestStepProperty?.action === STEP) {
            state.selectedTestStepDetail = action.response.data[0].recTestSteps[0];
          } else {
            state.selectedTestStepDetail = action.response.data[0];
          }
          if (state.selectedTestStepDetail) {
            state.activeTestStepAction = state.selectedTestStepDetail["recTestSteps"][0];
          }
        }

        return {
          ...state,
          selectedTestScenarioId: action.testScenarioId,
          selectedTestScenarioName: action.testScenarioName,
          testStepList: action.response.data,
          fetchingTestStepList: false,
          // selectedRecTestStepDetail: undefined,
        };
      } else {
        return {
          ...state,
          selectedTestScenarioId: action.testScenarioId,
          selectedTestScenarioName: action.testScenarioName,
          testStepList: undefined,
          selectedTestStepDetail: undefined,
          fetchingTestStepList: false,
        };
      }
    case RUN_DETAILS_BY_RUN_NUMBERS:
      return {
        ...state,
        runDetails: action.response,
      };
    case RUN_DETAILS_LOADING:
      return {
        ...state,
        runDetailsLoading: action.isLoading,
      };
    case CREATE_NEW_FILTER_FLAG:
      state.createNewFilter = action.data;
      if (!action.data) {
        state.tagInQueryBuilder = false;
      }
      return {
        ...state,
      };

    //Tag in query logic in progress
    case TAG_IN_QUERY_BUILDER:
      let count = [];
      state.tagInQueryBuilder = state.tagQueryBuilderValues.data.map((s) => findTagInQueryBuilder(s, count));
      if (state.tagInQueryBuilder.length > 0) {
        let m = state.tagInQueryBuilder[0].findIndex((s) => s === "0");
        if (m !== -1) {
          state.tagInQueryBuilder = true;
        } else {
          state.tagInQueryBuilder = false;
        }
      } else {
        state.tagInQueryBuilder = true;
      }
      return {
        ...state,
      };

    case CHANGED_FILTER_NAME_FIELD:
      state.FilterNameChanged = action.data;
      return {
        ...state,
      };

    case GET_QUERY_BUILD_DATA:
      return {
        ...state,
      };
    case CHANGE_QUERY_BUILDER_OPERATOR:
      state.tagQueryBuilderValues = updateOperatorValue(
        state.tagQueryBuilderValues,
        action.value,
        action.uuid,
        action.firstGroup
      );
      return {
        ...state,
      };

    case UPDATE_TAG_VALUE_QUERY_BUILDER:
      state.tagQueryBuilderValues = updateTagValue(state.tagQueryBuilderValues, action.value, action.uuid);
      return {
        ...state,
      };

    case SCHEDULE_SAVE_CHECKED:
      state.scheduleEnabled = action.data;
      return {
        ...state,
      };

    case EMAIL_VALIDITY_CHECKED:
      state.emailValidChecked = action.data;
      return {
        ...state,
      };

    case ADD_GROUP_QUERY_BUILDER:
      state.tagQueryBuilderValues = addNewGroup(state.tagQueryBuilderValues, action.uuid);
      return {
        ...state,
      };

    case REMOVE_GROUP_QUERY_BUILDER:
      state.tagQueryBuilderValues = removeGroupRow(
        state.tagQueryBuilderValues,
        action.uuid,
        action.parentUuidId,
        action.isFirstGroup
      );
      return {
        ...state,
      };

    case REMOVE_TAG_QUERY_BUILDER:
      state.tagQueryBuilderValues = removeTagField(state.tagQueryBuilderValues, action.uuid);
      return {
        ...state,
      };

    case ADD_TAG_QUERY_BUILDER:
      state.tagQueryBuilderValues = addNewTagInQueryBuilder(
        state.tagQueryBuilderValues,
        action.uuid,
        action.firstGroup
      );
      return {
        ...state,
      };

    case CHANGE_SELECTED_TAG_VALUE_OF_SCHEDULER:
      state.tagValueOfScheduler = action.data;
      return {
        ...state,
      };

    case SAVE_SCENARIO_FILTER:
      response = action.response;
      state.saveScenarioFilterMsg = null;
      if (response && response.status == SUCCESS_STATUS) {
        state.saveScenarioFilterMsg = response.message;
      }
      return { ...state };

    case GET_SCENARIO_FILTER:
      response = action.response;
      state.scenarioFilter = response.data;
      return { ...state };

    case GET_SCENARIO_FILTER_DATA:
      response = action.response;
      state.scenarioFilterData = response;
      state.scenarioFilterDataResponse = response.filterData[0];
      return { ...state };

    case GET_TAG_BY_TEST_PLAN_ID:
      response = action.response;
      state.getTagsDetails = response.data;
      return { ...state };

    case RESET_QUERY_BUILDER:
      state.tagQueryBuilderValues = {
        data: [],
        operator: "AND",
        uuid: util.getUniqueId(),
      };
      return { ...state };
    case CHANGE_EXECUTION_FARM_SELECTION:
      return { ...state, selectedExecutionFarm: action.value, selectedDeviceFarmIds: undefined, selectedDeviceIds: [] };
    case CHANGE_LODESTONE_DEVICE_FARM_SELECTION:
      return { ...state, selectedDeviceFarmIds: action.deviceFarmIds };
    case CHANGE_LODESTONE_DEVICE_SELECTION:
      return { ...state, selectedDeviceIds: action.deviceIds };
    case CHANGE_AWS_DEVICE_PROJECT_SELECTION:
      return {
        ...state,
        selectedDeviceFarmIds: action.awsDeviceProjectId,
      };
    case CHANGE_AWS_DEVICE_POOL_SELECTION:
      return {
        ...state,
        selectedDeviceIds: action.awsDevicePoolIds,
      };
    case CHANGE_AWS_DEVICE_BUILD_SELECTION:
      return {
        ...state,
        selectedAwsDeviceBuildArn: action.awsDeviceBuildArn,
      };
    case CHANGE_TEST_PLAN_DETAILS:
      response = action.data;
      let testPlanDetails = undefined;
      if (response) {
        testPlanDetails = response;
      }
      return { ...state, testPlanDetails };

    case REQUEST_FOR_DOWNLOAD_REPORT_LOGS:
      return { ...state, requestForDownloadReportLogs: true };
    case REQUESTED_FOR_DOWNLOAD_REPORT_LOGS:
      return { ...state, requestForDownloadReportLogs: false };

    case CHANGE_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS:
      let selectedTestScenarioForResultLogs = [...state.selectedTestScenarioForResultLogs];
      let selectedTestScenarioForIds = [...state.selectedTestScenarioForIds];
      if (action?.isChecked) {
        if (action?.selectAll) {
          state = { ...state, ...selectAllScenario(state) };
          state.selectedAllScenario = true;
        } else {
          action.testScenarioLink.map((value) => selectedTestScenarioForResultLogs.push(value));
          action.testScenarioId.map((value) => selectedTestScenarioForIds.push(value));
          state.selectedAllScenario = state.allScenario.length === selectedTestScenarioForResultLogs.length;
        }
      } else {
        state.selectedAllScenario = false;
        action.testScenarioLink.map((value) =>
          selectedTestScenarioForResultLogs.splice(selectedTestScenarioForResultLogs.indexOf(value), 1)
        );
        action.testScenarioId.map((value) =>
          selectedTestScenarioForIds.splice(selectedTestScenarioForIds.indexOf(value), 1)
        );
      }
      return { ...state, selectedTestScenarioForResultLogs, selectedTestScenarioForIds };
    case CHANGE_SELECTED_ALL_SCENARIO_OPTION:
      if (action.checked) {
        state = { ...state, ...selectAllScenario(state) };
      } else {
        state.selectedTestScenarioForResultLogs = [];
        state.selectedTestScenarioForIds = [];
      }
      return {
        ...state,
        selectedAllScenario: action.checked,
      };
    case CLEAR_SELECTED_TEST_SCENARIO_FOR_RESULT_LOGS:
      return { ...state, selectedTestScenarioForResultLogs: [] };
    case OPEN_TEST_PLAN_TREND_DRAWER:
      return { ...state, testPlanTrendDrawerVisible: true };
    case CLOSE_TEST_PLAN_TREND_DRAWER:
      return { ...state, testPlanTrendDrawerVisible: false };
    case GET_TEST_PLAN_SCENARIO_WISE_TREND_DATA:
      return { ...state, selectedTestPlanForTrend: action.testPlanData, testPlanTrendData: action.response.data };
    case GET_REF_RUN_NUMBER_FOR_PREVIEW:
      return {
        ...state,
        refRunNumberForPreviewList: action.response.data,
      };
    case GET_REF_LOCALE_FOR_PREVIEW:
      let refRunNumberLocaleForPreviewList = [];
      let refScenarioGuid = [];
      if (action.response.data && action.response.data.length > 0) {
        refScenarioGuid = _(action.response.data)
          .groupBy((object) => object.locale)
          .value();
        refRunNumberLocaleForPreviewList = Object.keys(refScenarioGuid);
      }
      return {
        ...state,
        refRunNumberLocaleForPreviewList,
        refScenarioGuid,
      };

    case CHANGE_SELECTED_COMPARISON_PREVIEW_VIEW:
      return {
        ...state,
        selectedComparisonPreviewView: action.value,
      };
    case HANDLE_CHANGE_REPORT_MANAGER_DATA:
      return {
        ...state,
        reportManagerSearch: action?.value,
      };

    case RESET_REPORT_MANAGER_DATA:
      return {
        ...state,
        reportManagerSearch: {
          searchKeyword: "",
          startDate: "",
          endDate: "",
          status: "",
        },
      };

    case CHANGE_REPEAT_STEP:
      return {
        ...state,
        currentRepeatStep: action?.value,
      };

    case CHANGE_RETRY_STEP:
      return {
        ...state,
        currentRetryStep: action?.value,
      };

    case EDIT_TEST_PLAN:
      return {
        ...state,
        unSavedTestPlanChanges: action.value,
      };

    default:
      return state;
  }
}

function resetTestPlanScheduleModal(state) {
  state.scheduleTestPlanModalVisible = false;
  state.testPlanExecuteCount = 1;
  state.fetchingTestPlanRecordedTestScenario = false;
  state.testPlanRecordedTestScenario = undefined;
}

function findTestStepDetailFromTestStepList(recTestList, selectedRecTestStepDetailId) {
  let obj = undefined;
  for (let i = 0; i < recTestList.length; i++) {
    if (recTestList[i].id === selectedRecTestStepDetailId) {
      obj = recTestList[i];
      break;
    } else if (
      recTestList[i].recTestSteps &&
      recTestList[i].recTestSteps.length > 0 &&
      recTestList[i].recTestStepProperty?.action === STEP
    ) {
      obj = findTestStepDetailFromTestStepList(recTestList[i].recTestSteps, selectedRecTestStepDetailId);
      if (obj) {
        break;
      }
    }
  }
  return obj;
}

function updateOperatorValue(tagQueryBuilderValues, value, uuid, firstGroup) {
  if (firstGroup) {
    tagQueryBuilderValues.operator = value;
  } else {
    tagQueryBuilderValues.data.map((s) => changeOperatorValuesofGroup(s, value, uuid));
  }
  return tagQueryBuilderValues;
}

function addNewTagInQueryBuilder(tagQueryBuilderValues, uuid, checkFirstGroup) {
  let tagObject = {
    uuid: util.getUniqueId(),
    tag: [],
  };

  if (checkFirstGroup) {
    tagQueryBuilderValues.data.push(tagObject);
  } else {
    tagQueryBuilderValues.data.map((s) => insertTag(s, uuid, tagObject));
  }

  return tagQueryBuilderValues;
}

function updateTagValue(tagQueryBuilderValues, value, uuid) {
  tagQueryBuilderValues.data.map((s) => changeTagValues(s, value, uuid));
  return tagQueryBuilderValues;
}

function addNewGroup(tagQueryBuilderValues, uuid) {
  let newGroup = {
    data: [],
    addTag: true,
    operator: "AND",
    uuid: util.getUniqueId(),
  };
  if (uuid == tagQueryBuilderValues.uuid) {
    tagQueryBuilderValues.data.push(newGroup);
  } else {
    tagQueryBuilderValues.data.map((s) => insertGroup(s, uuid, newGroup));
  }

  return tagQueryBuilderValues;
}

function removeGroupRow(tagQueryBuilderValues, uuid, parentUuidId, isFirstGroup) {
  let ind = tagQueryBuilderValues.data.findIndex((s) => s.uuid == uuid);
  if (ind != -1) {
    tagQueryBuilderValues.data.splice(ind, 1);
  } else {
    tagQueryBuilderValues.data.map((s) => deleteGroupRow(s, uuid, s));
  }

  return tagQueryBuilderValues;
}

function removeTagField(tagQueryBuilderValues, uuid) {
  let ind = tagQueryBuilderValues.data.findIndex((s) => s.uuid == uuid);
  if (ind != -1) {
    tagQueryBuilderValues.data.splice(ind, 1);
  } else {
    tagQueryBuilderValues.data.map((s) => deleteTagRow(s, uuid, s));
  }
  return tagQueryBuilderValues;
}

function insertGroup(s, uuid, newGroup) {
  if (s.operator) {
    if (s.uuid == uuid) {
      s.data.push(newGroup);
    } else {
      s.data.map((k) => insertGroup(k, uuid, newGroup));
    }
    return s;
  }
  if (!s.operator) {
    return s;
  }
}

function insertTag(s, uuid, tagObject) {
  if (s.operator) {
    if (s.uuid == uuid) {
      s.data.push(tagObject);
      return s;
    } else {
      s.data.map((k) => insertTag(k, uuid, tagObject));
    }
  }
  if (!s.operator) {
    return s;
  }
}

function deleteGroupRow(s, uuid, prev) {
  if (!s.operator) {
    return s;
  }

  if (s.operator) {
    if (s.uuid == uuid) {
      let ind = prev.data.findIndex((s) => s.uuid == uuid);
      prev.data.splice(ind, 1);
      return prev;
    } else {
      s.data.map((k) => deleteGroupRow(k, uuid, s));
    }
  }
}

function deleteTagRow(s, uuid, prev) {
  if (!s.operator) {
    if (s.uuid == uuid) {
      let ind = prev.data.findIndex((s) => s.uuid == uuid);
      prev.data.splice(ind, 1);
      return prev;
    } else {
      return s;
    }
  }

  if (s.operator) {
    s.data.map((k) => deleteTagRow(k, uuid, s));
  }
}

function changeOperatorValuesofGroup(s, value, uuid) {
  if (!s.operator) {
    return s;
  }
  if (s.operator) {
    if (s.uuid == uuid) {
      s.operator = value;
    } else {
      s.data.map((k) => changeOperatorValuesofGroup(k, value, uuid));
    }
  }
}

function changeTagValues(s, value, uuid) {
  if (!s.operator) {
    if (s.uuid == uuid) {
      s.tag = value;
      return s;
    } else {
      return s;
    }
  }
  if (s.operator) {
    s.data.map((k) => changeTagValues(k, value, uuid));
  }
}

function getSchedulerLabelValue(selectedTestPlanId, testPlanList) {
  let androidCount = 0,
    IosCount = 0;
  let ind = testPlanList.findIndex((s) => s.id === selectedTestPlanId);
  androidCount = testPlanList[ind].recTestScenarios.filter((s) => s.platform === "ANDROID").length;
  IosCount = testPlanList[ind].recTestScenarios.filter((s) => s.platform !== "ios").length;

  let txt =
    androidCount === testPlanList[ind].recTestScenarios.length
      ? " Upload only .apk file"
      : IosCount === testPlanList[ind].recTestScenarios.length
      ? " Upload only .ipa file"
      : " Upload the Build";

  return txt;
}

//logic of checking query builder having tag Field
function findTagInQueryBuilder(s, count) {
  if (s.operator) {
    if (s.data.length != 0) {
      count = s.data.map((k) => findTagInQueryBuilder(k, count));
    }
  } else {
    if (s.tag.length != 0) {
      count.push("1");
    } else {
      count.push("0");
    }
  }

  return count;
}
function getAllScenario(state, data) {
  let testScenarios = [];
  if (data === undefined) {
    state.previewRunDetails &&
      state.previewRunDetails.map((i) => {
        i.testScenarios.map((scenario) => testScenarios.push(scenario));
      });
  } else {
    data &&
      data.map((i) => {
        i.testScenarios.map((scenario) => testScenarios.push(scenario));
      });
  }
  return testScenarios;
}
function selectAllScenario(state, data) {
  let testScenarios = getAllScenario(state, data);
  let selectedTestScenarioForResultLogs = testScenarios && testScenarios.map((scenario) => scenario.scenarioFolderLink);
  let selectedTestScenarioForIds = testScenarios && testScenarios.map((scenario) => scenario.id);
  return { selectedTestScenarioForResultLogs, selectedTestScenarioForIds };
}
