import { notification } from "antd";
import {
  FAILED_STATUS,
  GET_ORGANIZATION,
  GET_ORG_STEP_DETAIL,
  JOIN_ORGANIZATION,
  LIST_OF_ORG_MEMBERS,
  ORGANIZATION_LOADING,
  SAVE_ORGANIZATION,
  SAVE_PROJECT_DATA,
  SAVE_TEAM_DATA,
  SET_ORGANIZATION_CURRENT_STEP,
  SUCCESS_STATUS,
} from "../Constants";
import _ from "lodash";
import { showMessage } from "../Util";

let defaultState = {
  getOrgStepData: null,
  createOrgResponse: null,
  createTeamResponse: null,
  projectData: null,
  getOrgName: null,
  joinOrgName: null,
  listOfOrgMembers: [],
  isOrganizationLoading: false,
  organizationCurrentStep:0
};

export default function OrganizationReducer(state = defaultState, action) {
  let response = undefined;

  switch (action.type) {
    case GET_ORG_STEP_DETAIL:
      response = action.response;
      state.getOrgStepData = response.data;
      return { ...state };

    case SAVE_ORGANIZATION:
      response = action.response;
      state.orgId = null;

      return {
        ...state,
        createOrgResponse: response,
      };
    case SAVE_TEAM_DATA:
      response = action.response;
      state.teamId = null;
      return {
        ...state,
        createTeamResponse:response
      };
    case SAVE_PROJECT_DATA:
      response = action.response;
      state.projectData = null;

      return {
        ...state,
        projectData:response.message
      };
    case JOIN_ORGANIZATION:
      response = action.response;
      state.joinOrgName = null;

      if (response.status === SUCCESS_STATUS) {
        notification["success"]({
          message: SUCCESS_STATUS,
          description: response.message,
        });
        state.joinOrgName = response.message;
      } else if (response.status !== SUCCESS_STATUS) {
        notification["error"]({
          message: FAILED_STATUS,
          description: response.message,
        });
      }
      return {
        ...state,
      };
    case GET_ORGANIZATION:
      return {
        ...state,
        getOrgName: response,
      };
    case LIST_OF_ORG_MEMBERS:
      response = action.response;
      return {
        ...state,
        listOfOrgMembers: response.data ? response.data : [],
      };

    case ORGANIZATION_LOADING:
      return {
        ...state,
        isOrganizationLoading: action.isLoading,
      };

    case SET_ORGANIZATION_CURRENT_STEP:
      return {
        ...state,
        organizationCurrentStep: action.response,
      };
    default:
      return state;
  }
}