import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DEVICE_EXECUTION_FARM } from "../../../Constants";
import { generateExternalFarmTestURL } from "../../../actions/TestPlanAction";

const ExternalFarmTestResultLink = ({ data, runResultDetails }) => {
  const dispatch = useDispatch();
  const [externalFarmTestResultLink, setExternalFarmTestResultLink] = useState(data?.externalFarmTestCaseUrl);
  const [hasDispatched, setHasDispatched] = useState(false);

  function getExpirationDate(externalFarmTestResultLink) {
    const regex = /[?&]expireAt=(\d+)/;
    const match = externalFarmTestResultLink.match(regex);
    if (match) {
      return new Date(parseInt(match[1], 10));
    }
    return null;
  }

  function isExpirationDateValid(expirationDate) {
    const today = new Date();
    return expirationDate.setHours(0, 0, 0, 0) > today.setHours(0, 0, 0, 0);
  }

  useEffect(() => {
    if (runResultDetails[0]?.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST && !hasDispatched) {
      const expirationDate = getExpirationDate(externalFarmTestResultLink);
      if (expirationDate) {
        if (!isExpirationDateValid(expirationDate)) {
          dispatch(
            generateExternalFarmTestURL(
              data?.scenarioId,
              DEVICE_EXECUTION_FARM.LAMBDA_TEST,
              setExternalFarmTestResultLink
            )
          );
          setHasDispatched(true);
        }
      } else {
        dispatch(
          generateExternalFarmTestURL(
            data?.scenarioId,
            DEVICE_EXECUTION_FARM.LAMBDA_TEST,
            setExternalFarmTestResultLink
          )
        );
        setHasDispatched(true);
      }
    }
  }, [dispatch, runResultDetails, data, hasDispatched]);

  if (!externalFarmTestResultLink) {
    return null;
  }

  const linkText =
    runResultDetails[0]?.deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS
      ? "SauceLabs Result"
      : "LambdaTest Result";

  return (
    <div>
      <a
        href={externalFarmTestResultLink}
        target="_blank"
        className="font-color-lightBlue font-weight-500"
        rel="noopener noreferrer"
      >
        {linkText}
      </a>
    </div>
  );
};

export default ExternalFarmTestResultLink;
