import React from "react";
import {isEmpty} from "lodash";
import styles from "./OtherElementsOptionsModalV2.module.scss";

const ElementListV2 = (props) => {
  const { title, elements, selectedElementPath, onClick, getElementText } = props;

  return (
    <div className={styles["element_Details_Column"]}>
      <div
        className={`${styles["element_Title"]} ${
          title.includes("Non") ? styles["non_Clickable_Title"] : styles["clickable_Title"]
        }`}
      >
        {title}
      </div>
      <div className={`${styles["element_Details"]} ${isEmpty(elements) && styles["empty_Data"]}`}>
        {elements.length > 0 ? (
          elements.map((v, k) => (
            <div
              key={k}
              className={`${
                selectedElementPath === v.path ? styles["clickable-item-selected"] : styles["clickable-item"]
              }`}
              onClick={() => onClick(v.path)}
            >
              <span>{getElementText(v, k, selectedElementPath)}</span>
            </div>
          ))
        ) : (
          <div>
            <span className={styles["no-data"]}>No Data</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ElementListV2;