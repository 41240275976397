import { Col, Collapse, Row, Spin } from "antd";
import { default as React, Fragment } from "react";
import styled from "styled-components";
import { LOOP_CONDITION, PRE_CONDITION, TEST_PLAN_RESULT_TYPE, verificationExistenceDisplayName } from "../../../Constants";
import TestPlanResultStepElementDetail from "./TestPlanResultStepElementDetails";

const { Panel } = Collapse;
const StepsDataRow = styled(Row)`
  display: flex;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  color: "#65676b";
  background-color: "#338fcc";
`;

const StepsData = styled.span`
  margin-left: 5px;
  font-weight: 500;
`;

export const highlightResult = (resultString, stepResult) => {
  if (resultString === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    return (
      <Fragment>
        <Spin size="small" className="mr-5" />
        <span className="badge bg-info p-5 font-weight-900 w-60 "> {TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT}</span>
      </Fragment>
    );
  } else if (resultString === TEST_PLAN_RESULT_TYPE.PASSED) {
    return <span className="badge bg-success  font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.PASSED}</span>;
  } else if (resultString === TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY) {
    return (
      <span className="badge bg-secondary  font-weight-900 w-60 ">
        {TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
      </span>
    );
  } else if (resultString === TEST_PLAN_RESULT_TYPE.FAILED) {
    return <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>;
  } else if (stepResult === TEST_PLAN_RESULT_TYPE.TERMINATED) {
    return (
      <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.TERMINATED}</span>
    );
  } else if (resultString === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    if (stepResult === TEST_PLAN_RESULT_TYPE.ABORTED) {
      return (
        <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.ABORTED}</span>
      );
    } else {
      return (
        <span className="badge bg-warning text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
      );
    }
  } else if (resultString === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
    return <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>;
  } else {
    return <span className="default-result-text">{resultString}</span>;
  }
};

const leftBorderColor = (data) => {
  if (data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    return "border-left-inProgress";
  } else if (data === TEST_PLAN_RESULT_TYPE.PASSED) {
    return "border-left-pass";
  } else if (data === TEST_PLAN_RESULT_TYPE.FAILED) {
    return "border-left-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    return "border-left-skip";
  } else if (data === TEST_PLAN_RESULT_TYPE.ABORTED) {
    return "border-left-abort";
  } else if (data === TEST_PLAN_RESULT_TYPE.TERMINATED) {
    return "border-left-abort";
  } else {
    return "";
  }
};

const TestPlanResultDetailPreconditionList = (props) => {
  const {
    isStepHasPreCondition,
    isLoopStep,
    preConditionList,
    recordedStepImageLink,
    failureImage,
    result,
    modifiedResult,
    continueAfterFail,
    testExecutionElementDetails,
    failurePageSourceLink,
    loopCount,
    executedLoopCount,
  } = props;
  return (
    <>
      <div className="font-weight-600 mb-5">
        {isLoopStep === 1 ? (
          <>
            <StepsDataRow>
              <Col span={24}>
                {"Repeat Below Steps Till"}:<StepsData>{loopCount}</StepsData>
                {" times"}
              </Col>
            </StepsDataRow>
          </>
        ) : (
          ""
        )}
      </div>
      {preConditionList && preConditionList.length > 0 && (
        <React.Fragment>
          {isLoopStep === 1 && <StepsDataRow>OR</StepsDataRow>}
          <div className="font-weight-600 mb-5">
            {isStepHasPreCondition ? "If Verification Succeeds" : "Repeat till Condition Succeeds"}
          </div>
          {preConditionList.map((v, k) => {
            return (
              <Collapse className={leftBorderColor(v.preconditionResult)} key={k} expandIconPosition="right">
                <Panel
                  className="panel-background-color"
                  showArrow={true}
                  header={
                    <StepsDataRow>
                      <Col span={23}>
                        {isLoopStep === 1 ? LOOP_CONDITION : PRE_CONDITION}:<StepsData>{v.preConditionTitle}</StepsData>
                      </Col>
                      <Col span={1}>
                        {v.preconditionResult
                          ? highlightResult(v.preconditionResult, modifiedResult)
                          : ""}
                      </Col>
                      <Col span={24}>
                        Verify: <StepsData>{" Element " + verificationExistenceDisplayName[v?.key]}</StepsData>
                      </Col>
                      <Col span={24}>
                        Element Matching Criteria: <StepsData>{v.elementOperator}</StepsData>
                      </Col>
                    </StepsDataRow>
                  }
                  key={k}>
                  <TestPlanResultStepElementDetail
                    recordedStepImageLink={recordedStepImageLink}
                    testExecutionElementDetails={testExecutionElementDetails}
                    failureImage={failureImage ? failureImage : props.data.failureImage}
                    result={result}
                    continueAfterFail={continueAfterFail}
                    modifiedResult={modifiedResult}
                    preconditionResult={true}
                    failurePageSourceLink={
                      failurePageSourceLink ? failurePageSourceLink : props.data.failurePageSourceLink
                    }
                    testDataName={props.testDataName}
                    testDataValue={props.testDataValue}
                    testDataCustomValue={props.testDataCustomValue}
                    outputTestDataName={props.data.outputTestDataName}
                    outputTestDataValue={props.data.outputTestDataValue}
                    selectedElementImageUrl={props.selectedElementImageUrl}
                    selectorType={props.selectorType}
                    retryCounts={props.retryCounts}
                    retryIndex={props.retryIndex}
                    retrySteps={props.retrySteps}
                    repeatSteps={props.repeatSteps}
                    testStepFailDetails={props.testStepFailDetails}
                    action={props.action}
                    testExecutionErrorInvestigationDetails={props.testExecutionErrorInvestigationDetails}
                    text={props.text}
                    data={props.data}
                    isStepHasPreCondition={isStepHasPreCondition}
                  />
                </Panel>
              </Collapse>
            );
          })}
          <div className="font-weight-600 mb-5 font-color-blue">
            {isStepHasPreCondition
              ? "then Execute"
              : "Steps to Repeat " + " (Repeat " + executedLoopCount + " / " + loopCount + " times)"}
          </div>
        </React.Fragment>
      )}
    </>
  );
};

export default TestPlanResultDetailPreconditionList;
