import _ from "lodash";
import React, { useEffect, useState } from "react";
import { TEST_BLOCK } from "../../../../../Constants";
import AssignedTestDataListView from "./AssignedTestDataListView/AssignedTestDataListView";
import EditTestBlockTestDataSet from "./EditTestBlockTestDataSet";
import { Collapse, Empty } from "antd";
const EditRecTestScenarioTestDataSet = (props) => {
  const { title, testDataSetForEdit, isForView } = props;
  const [refTestDataList, setRefTestDataList] = useState([]);
  useEffect(() => {
    let _refTestDataList = _.cloneDeep(testDataSetForEdit?.testDataValues);
    for (let index = 0; index < testDataSetForEdit?.testBlockTestDataSaveReq?.length; index++) {
      Array.prototype.push.apply(
        _refTestDataList,
        _.cloneDeep(testDataSetForEdit?.testBlockTestDataSaveReq[index]?.testDataValues)
      );
    }
    setRefTestDataList(_refTestDataList);
  }, testDataSetForEdit);

  return (
    <>
      {!_.isEmpty(testDataSetForEdit?.testDataValues) &&
        <AssignedTestDataListView
          title={title}
          testDataValues={testDataSetForEdit?.testDataValues}
          isForView={isForView}
          refTestDataList={refTestDataList}
        />
      }
      <div className="m-10">
        {testDataSetForEdit?.testBlockTestDataSaveReq && testDataSetForEdit?.testBlockstepCount > 0 &&
          <Collapse defaultActiveKey={[]}>
            <Collapse.Panel 
              collapsible={true} 
              header={"Test Block: Steps using Test Data"
                + " [Steps using Test Data: " + testDataSetForEdit?.testBlockstepCount
                + " Unique Test Data Keys: " + testDataSetForEdit?.testBlockuniqueTestDataCount + "]"}
              key={"Default"}>
              {testDataSetForEdit?.testBlockTestDataSaveReq &&
                testDataSetForEdit?.testBlockTestDataSaveReq.map((testBlockTestData) => {
                  return (
                    <EditTestBlockTestDataSet
                      title={"Test Block:" + testBlockTestData?.name}
                      testDataSetForEdit={testBlockTestData}
                      isForView={isForView}
                      refTestDataList={refTestDataList}
                      rowIdentifyData={{
                        type: TEST_BLOCK,
                        id: testBlockTestData?.id,
                        recTestScenarioRecTestStepAssocId: testBlockTestData?.recTestScenarioRecTestStepAssocId,
                      }}
                    />
                  );
                })}
            </Collapse.Panel>
          </Collapse>
        }
      </div>
    </>
  );
};

export default EditRecTestScenarioTestDataSet;
