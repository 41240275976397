import { message } from "antd";
import { Cookies } from "react-cookie";
import { async, login } from "../api/automation";
import {
  ACCEPT_INVITATION_REQUEST,
  CHANGE_LOGIN_OTP,
  CHANGE_LOGIN_PASSWORD,
  CHANGE_LOGIN_USER_EMAIL,
  CHANGE_LOGIN_USER_NAME,
  CHANGE_LOGIN_USER_TOKEN,
  CHANGE_PASSWORD,
  CHANGE_SIGNUP_EMAIL,
  CHANGE_SIGNUP_FIRST_NAME,
  CHANGE_SIGNUP_LAST_NAME,
  CHANGE_SIGNUP_PASSWORD,
  CHANGE_SIGNUP_USER_NAME,
  CHECK_USER_EMAIL_EXISTS,
  CHECK_USER_NAME_EXISTS,
  ENTER_OTP,
  ENTER_OTP_OFF,
  ERROR_STATUS,
  FAILED_GENERATE_TOKEN,
  FORGOT_PASSWORD,
  GENERATE_TOKEN,
  GENERATE_TOKEN_REQUEST,
  GET_CHILD_COMPONENTS,
  GET_ROUTE_LIST,
  LOGIN,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  UPDATE_PREVIOUS_SEARCH_VALUE,
  SET_PASSWORD_VALIDATE_ERROR,
  SET_SIGNUP_ERROR,
  SIGNUP_REQUEST,
  SIGN_UP,
  SIGN_UP_FAIL,
  SIGN_UP_VERIFICATION_CONFIRMED,
  SUCCESS_STATUS,
  USER_LOGIN_SSO_BACK,
  USER_SIGNUP_SSO_BACK,
  VALIDATE_USER_LOGIN_SSO,
  VALIDATE_USER_LOGIN_SSO_REQUEST,
  VALIDATE_USER_SIGNUP_SSO,
  VALIDATE_USER_SIGNUP_SSO_REQUEST,
  VERIFY_SIGNUP_REQUEST,
  xpressComponentType,
  FETCH_LIST_OF_MEMBER,
  ERROR_MESSAGE,
  CHANGE_SIGNUP_FULL_NAME,
  HIDE_TEST_BUILD_DATA_SET_TAB,
} from "../Constants";
import history from "../history";
import { updateResponse } from "../Util";
import { loggedInUserDetail } from "./HomeAction";
import { getOrgList } from "./TeamsAction";
const cookies = new Cookies();

export function Login(access_token) {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN_REQUEST });
    return login(
      "xpressUser/loginWith2fa",
      "POST",
      "userEmail=" + getState().CommonReducer.loginUserEmail + "&twoFaCode=" + access_token + "&grant_type=password"
    ).then((response) => {
      response = updateResponse(response);
      message.destroy();
      if (response.status !== "Error") {
        localStorage.setItem("xpressToken", response.access_token.access_token);
        cookies.set("xpressToken", response.access_token.access_token);
        localStorage.setItem("xpressDeviceToken", response.device_token);
        cookies.set("xpressDeviceToken", response.access_token.access_token);
        localStorage.setItem("requestedUser", response.userName);
        cookies.set("requestedUser", response.userName);
        dispatch(getOrgList());
        async("component/getChildComponentByTypeAndParentId", "GET", {
          componentType: xpressComponentType.menu,
          parentId: 0,
        }).then((res) => {
          dispatch({ type: GET_ROUTE_LIST, response: updateResponse(res) });
          if (response.isOrgCreated === 0 || response.isTeamCreated === 0) {
            history.push(`/createOrganization`);
          }
        });
        dispatch({ type: LOGIN_SUCCESS, response: updateResponse(response) });
        dispatch({ type: LOGIN, response: updateResponse(response.access_token) });
      } else if (response.status === "Error") {
        message.error({
          content: response.message,
          className: 'custom_Message',
          duration: 5,
        });
        dispatch({ type: LOGIN, response });
      }
    });
  };
}

export function LoginWithUserPass() {
  return async (dispatch, getState) => {
    dispatch({ type: LOGIN_REQUEST });
    return login(
      "oauth/token",
      "POST",
      "username=" +
        getState().CommonReducer.loginUserName +
        "&password=" +
        getState().CommonReducer.loginPassword +
        "&grant_type=password"
    ).then((response) => {
      dispatch({ type: LOGIN, response: updateResponse(response) });
    });
  };
}

export function verifySignupRequest(data) {
  return async (dispatch) => {
    localStorage.clear();
    cookies.set("xpressToken", "", { expires: 0 });
    cookies.set("xpressEmail", "", { expires: 0 });
    cookies.remove("xpressToken");
    cookies.remove("xpressEmail");
    return async("xpressUser/verifySignupRequest", "POST", data).then((response) => {
      if (response.data.type === "private") {
        localStorage.setItem("xpressToken", response.data.access_token.access_token);
        localStorage.setItem("userID", response.data.userId);
        cookies.set("xpressToken", response.data.access_token.access_token);
        cookies.set("xpressDeviceToken", response.data.access_token.access_token);
        async("component/getChildComponentByTypeAndParentId", "GET", {
          componentType: xpressComponentType.menu,
          parentId: 0,
        }).then((res) => {
          dispatch({ type: GET_ROUTE_LIST, response: updateResponse(res) });
          if (response.data.orgId) {
            window.location.href = "/JoinOrganization";
          } else {
            window.location.href = "/createOrganization";
          }
        });
        dispatch({ type: LOGIN, response: updateResponse(response.data.access_token) });
      } else {
        if (response.status === ERROR_STATUS) {
          window.location.href = "/InvalidVerification";
        }
      }
    });
  };
}

export function acceptInvitation(data, isComingFromLink) {
  return async (dispatch) => {
    return async(`invitation/acceptRequest?inviteToken=${data}`, "POST").then((response) => {
      if (response.status === SUCCESS_STATUS && response.data?.userId !== 0) {
        if (localStorage.getItem("xpressToken")) {
          if (isComingFromLink) {
            history.push("/" + response.data.orgId + "/projects");
            dispatch(getOrgList());
          }
        } else {
          history.push("/Login");
        }
        dispatch({ type: ACCEPT_INVITATION_REQUEST, response });
      } else if (response?.status === SUCCESS_STATUS && response.data.userId === 0) {
        history.push("/SignUp");
      } else {
        history.push("/Login");
      }
    });
  };
}

export function SignUp() {
  return async (dispatch, getState) => {
    dispatch({ type: SIGNUP_REQUEST });
    return async("xpressUser/validateUserSignUp", "POST", { email: getState().CommonReducer.signupEmail.trim() }).then(
      (response) => {
        if (response.status === SUCCESS_STATUS) {
          return async("xpressUser/signup", "POST", {
            userName: getState().CommonReducer.signupUserName,
            name: getState().CommonReducer.signupFullName,
            password: getState().CommonReducer.signupPassword,
            email: getState().CommonReducer.signupEmail.trim(),
          }).then((response) => {
            if (response.message == "Saml Details") {
              // if(response.data && response.data){
              //   window.location.href = response.data;
              //   return;
              // }
              dispatch({ type: SIGN_UP_FAIL, response });
              message.success({
                content: "Your domain is registered with SSO for Xpress. Please signup with SSO.",
                className: 'custom_Message',
                duration: 10,
              });
            } else {
              dispatch({ type: SIGN_UP, response });
            }
          });
        } else {
          dispatch({ type: SIGN_UP, response });
        }
      }
    );
  };
}
export function signupVerificationMsgConfirmed() {
  return async (dispatch) => {
    dispatch({ type: SIGN_UP_VERIFICATION_CONFIRMED });
  };
}
export function getRoutes(data) {
  return async (dispatch, getState) => {
    return async("component/getChildComponentByTypeAndParentId", "GET", data).then((response) => {
      dispatch({ type: GET_ROUTE_LIST, response: updateResponse(response) });
      response = updateResponse(response);
      if (
        response.status === SUCCESS_STATUS &&
        response.userDetails.userEmail != null &&
        response.userDetails.userName != null
      ) {
        dispatch(loggedInUserDetail(response));
      } else {
        localStorage.clear();
        cookies.set("xpressToken", "", { expires: 0 });
        cookies.set("xpressEmail", "", { expires: 0 });
        cookies.remove("xpressToken");
        cookies.remove("xpressEmail");
        window.location.href = "/login";
      }
    });
  };
}

export function getChildComponentByTypeAndParentId(data) {
  return async (dispatch, getState) => {
    return async("component/getChildComponentByTypeAndParentId", "GET", data).then((response) => {
      dispatch({ type: GET_CHILD_COMPONENTS, response });
      response = updateResponse(response);
      if (
        response.status === SUCCESS_STATUS &&
        response.userDetails.userEmail != null &&
        response.userDetails.userName != null
      ) {
        dispatch(loggedInUserDetail(response));
      } else {
        localStorage.clear();
        cookies.set("xpressToken", "", { expires: 0 });
        cookies.set("xpressEmail", "", { expires: 0 });
        history.push("/login");
      }
    });
  };
}

export function forgotPassword(data) {
  return (dispatch) => {
    return async("xpressUser/forgotPassword", "POST", data).then((response) =>
      dispatch({ type: FORGOT_PASSWORD, response })
    );
  };
}
export const resetErrorMsg = () => {
  return (dispatch) => {
    dispatch({ type: VALIDATE_USER_LOGIN_SSO, response: { message: "" } });
  };
};

export function generateToken(data) {
  return async (dispatch) => {
    dispatch({ type: GENERATE_TOKEN_REQUEST });
    return async("xpressUser/generateToken", "POST", data).then((response) => {
      if (response.status === ERROR_STATUS) {
        dispatch({ type: FAILED_GENERATE_TOKEN, response });
      } else if (response.status === SUCCESS_STATUS) {
        message.destroy();
        if (response.message == "Saml Details") {
          if (response.data && response.data) {
            // window.location.href = response.data;
            // return;
            message.success({
              content: "Your domain is registered with SSO. You need to login with SSO and then launch Xpress.",
              className: 'custom_Message',
              duration: 10,
            });
            dispatch({ type: FAILED_GENERATE_TOKEN, response });
          } else {
            dispatch({ type: FAILED_GENERATE_TOKEN, response });
          }
        } else {
          message.success({
            content: "An Email was sent to your registered email. It contains a token to login.",
            className: 'custom_Message',
            duration: 10,
          });
          dispatch({ type: GENERATE_TOKEN, response });
        }
      }
    });
  };
}

export function ChangePassword(data, customerId) {
  return (dispatch) => {
    let response = null;
    response = updateResponse(response);
    dispatch({ type: CHANGE_PASSWORD, response });
  };
}

export function checkUserNameExists(userName) {
  return (dispatch) => {
    let response = null;
    return async("xpressUser/checkUserName", "GET", { userName: userName }).then((response) =>
      dispatch({ type: CHECK_USER_NAME_EXISTS, response })
    );
  };
}
export function checkUserEmailExists(userEmail) {
  return (dispatch) => {
    let response = null;
    return async("xpressUser/checkUserEmail", "GET", { userEmail: userEmail }).then((response) =>
      dispatch({ type: CHECK_USER_EMAIL_EXISTS, response })
    );
  };
}
export function logout() {
  return (dispatch) => {
    let response = null;
    return async("xpressUser/logout", "POST").then((response) => {
      if (response.status === SUCCESS_STATUS) {
        localStorage.clear();
        cookies.set("xpressToken", "", { expires: 0 });
        cookies.set("xpressEmail", "", { expires: 0 });
        window.location.reload();
        dispatch({ type: LOGOUT, response });
      }
    });
  };
}

export function hangleChangeFirstName(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_FIRST_NAME, value });
  };
}
export function handleChangeFullName(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_FULL_NAME, value });
  };
}

export function handleChangeLastName(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_LAST_NAME, value });
  };
}
export function handleChangeUserName(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_USER_NAME, value: value.trim() });
  };
}
export function handleChangeEmail(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_EMAIL, value });
  };
}
export function handleChangePassword(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_SIGNUP_PASSWORD, value });
  };
}
export function setError(field, errorStatus) {
  return (dispatch) => {
    return dispatch({ type: SET_SIGNUP_ERROR, field, errorStatus });
  };
}
export function setPasswordValidateError() {
  return (dispatch) => {
    return dispatch({ type: SET_PASSWORD_VALIDATE_ERROR });
  };
}
export function handleChangeLoginUserName(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_LOGIN_USER_NAME, value: value.trim() });
  };
}
export function handleChangeLoginPassword(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_LOGIN_PASSWORD, value });
  };
}
export function handleChangeLoginUserEmail(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_LOGIN_USER_EMAIL, value: value.trim() });
  };
}
export function setLoginUserToken(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_LOGIN_USER_TOKEN, value: value.trim() });
  };
}
export function enterOtp() {
  return (dispatch) => {
    return dispatch({ type: ENTER_OTP });
  };
}
export function enterOtpOff() {
  return (dispatch) => {
    return dispatch({ type: ENTER_OTP_OFF });
  };
}
export function handleChangeLogInOTP(value) {
  return (dispatch) => {
    return dispatch({ type: CHANGE_LOGIN_OTP, value: value.trim() });
  };
}
export function errorLogin() {
  return (dispatch, getState) => {
    if (getState().CommonReducer.loginUserEmailError !== undefined && getState().CommonReducer.loginUserEmailError) {
      message.error("Invalid Email!!", 5);
    } else if (getState().CommonReducer.loginOtpError !== undefined && getState().CommonReducer.loginOtpError) {
      message.error("Invalid Token!!", 5);
    }
  };
}

export function validateUser(userEmail) {
  return async (dispatch) => {
    dispatch({ type: VALIDATE_USER_SIGNUP_SSO_REQUEST });
    return async("xpressUser/validateUserSignUp", "POST", { email: userEmail }).then((response) =>
      dispatch({ type: VALIDATE_USER_SIGNUP_SSO, response })
    );
  };
}
export const resetSignUpErrorMsg = () => {
  return (dispatch) => {
    dispatch({ type: VALIDATE_USER_SIGNUP_SSO, response: { message: "" } });
  };
};
export function validateUserForLogin(userEmail) {
  return async (dispatch) => {
    dispatch({ type: VALIDATE_USER_LOGIN_SSO_REQUEST });
    return async("xpressUser/validateUser", "GET", { userEmail: userEmail }).then((response) =>
      dispatch({ type: VALIDATE_USER_LOGIN_SSO, response })
    );
  };
}

export function SignUpBack() {
  return (dispatch) => {
    dispatch({ type: USER_SIGNUP_SSO_BACK });
  };
}

export function loginBack() {
  return (dispatch) => {
    dispatch({ type: USER_LOGIN_SSO_BACK });
  };
}

export function setPreviousSearchValue(value) {
  return (dispatch) => {
    dispatch({ type: UPDATE_PREVIOUS_SEARCH_VALUE, searchValue: value });
  };
}

export function fetchListOfMember() {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    return async("projectUserAssoc/getMembersByProjectId", "GET", { projectId }).then((response) =>
      dispatch({ type: FETCH_LIST_OF_MEMBER, response })
    );
  };
}

export const hideTestBuildDataSetTab = () => {
  return (dispatch) => {
    dispatch({ type: HIDE_TEST_BUILD_DATA_SET_TAB });
  };
};
