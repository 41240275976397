import { Button, Col, Row } from "antd";
import React from "react";
import styles from "./InvalidVerificationV2.module.scss";
import history from "../../../history";

const InvalidVerificationV2 = () => {
  return (
    <div className={styles["inValid_Verification_Container"]}>
      <Row className={styles["inValid_Verification_Wrapper"]}>
        <Col className="width-full">
          <div className={styles["inValidation_Text"]}>
            The verification link you clicked has already been used or has expired. Please sign up again to receive a
            new verification link.
          </div>
          <div>
            <Button
              type="primary"
              size="large"
              block
              onClick={() => {
                history.push("/SignUp");
              }}
              className={styles["btn_Sign_Up"]}
            >
              Sign Up
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InvalidVerificationV2;
