import React, { useState } from "react";
import _ from "lodash";
import { Radio, Row } from "antd";
import { NEW_INVESTIGATION, PREVIOUS_INVESTIGATION } from "../../../Constants";
import PreviousInvestigation, { ErrorStyledRow } from "./PreviousInvestigation";
import NewInvestigation from "./NewInvestigation";
import { fetchOtherRuns, handleChangeErrorInvestigationData } from "../../../actions/ErrorInvestigationActions";
import { useDispatch, useSelector } from "react-redux";

const NewAndPreviousInvestigationManager = (props) => {
  const dispatch = useDispatch();
  const { failedTestStepId, searchOption, projectId } = props;
  const { errorInvestigationDetails } = useSelector((state) => state.ErrorInvestigationReducer);
  const [currentOptions, setCurrentOptions] = useState(NEW_INVESTIGATION);

  return (
    <>
      <ErrorStyledRow className="mb-10">
        <Row className="m-5 w-100">
          <Radio.Group value={currentOptions} onChange={(e) => setCurrentOptions(e.target.value)} buttonStyle="solid">
            <Radio.Button
              onClick={() => {
                dispatch(
                  handleChangeErrorInvestigationData({
                    ...errorInvestigationDetails,
                    previousTestStepId: 0,
                    previousInvestigationId: 0,
                  })
                );
                dispatch(fetchOtherRuns(failedTestStepId, 1, searchOption));
              }}
              value={NEW_INVESTIGATION}
            >
              {NEW_INVESTIGATION}
            </Radio.Button>
            <Radio.Button value={PREVIOUS_INVESTIGATION} className="sub-header-options">
              {PREVIOUS_INVESTIGATION}
            </Radio.Button>
          </Radio.Group>
        </Row>
        {currentOptions === NEW_INVESTIGATION && <NewInvestigation />}
        {currentOptions === PREVIOUS_INVESTIGATION && (
          <PreviousInvestigation failedTestStepId={failedTestStepId} searchOption={searchOption} projectId={projectId}/>
        )}
      </ErrorStyledRow>
    </>
  );
};

export default NewAndPreviousInvestigationManager;
