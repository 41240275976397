import { Drawer, Popover, Space, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_DELETE_WITH_BG } from "../../../../Constants/SvgConstants";
import { getUniqueId, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { editRecTestStep } from "../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  addVerificationRow,
  deviceDrawerVisible,
  getDeviceStrings,
  selectedDeviceStringValue,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import FilterComponent from "../../../CoreComponents/FilterComponent/FilterComponent";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";
import styles from "./DeviceStringDrawer.module.scss";

const DeviceStringDrawer = (props) => {
  const dispatch = useDispatch();
  const { recElement, projectId, recTestStep, disabled } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const {
    showDeviceDrawerVisible,
    getDeviceString,
    getListOfLocales,
    filterFetchingDeviceStringsList,
    deviceStringLoader,
    selectedRecStepForOperation,
    selectedElementGuidForDetail,
    selectedRecTestStepTag,
    selectedLocale,
    unSavedRecElementGuid,
    guidForOpenDeviceDrawer,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const handleSelectTestData = (selectData, elementGuid) => {
    dispatch(editRecTestStep(selectedRecStepForOperation?.tag));
    dispatch(selectedDeviceStringValue(selectData, selectedRecTestStepTag, selectedElementGuidForDetail));
    if (selectData !== undefined) {
      const newUuid = getUniqueId();
      dispatch(addVerificationRow("VerifyElementText", undefined, recTestStep.tag, recElement?.guid, newUuid));
      dispatch(
        updateVerificationKey("Equals to Locale value of Device String", newUuid, recTestStep.tag, recElement?.guid)
      );
    }
  };

  useEffect(() => {
    setSearchKeyword("");
  }, [showDeviceDrawerVisible, filterFetchingDeviceStringsList]);

  const showDeviceStringValues = (values) => {
    const val = values?.filter((i, j) => {
      return j >= 2 && <div className={styles.ellipse}>{i.locale + " : " + i.value}</div>;
    });
    if (values.length > 2) {
      return (
        <div className={styles.showValues}>
          {values?.map((s, index) => {
            return index < 2 && <div className={styles.ellipse}>{s.locale + " : " + s.value}</div>;
          })}
          <Popover
            width="50%"
            content={
              <div className={styles.showPopover}>
                {values?.map((i, j) => {
                  return j >= 2 && <div className={styles.ellipse}>{i.locale + " : " + i.value}</div>;
                })}
              </div>
            }
            title="Values"
            trigger="hover"
            placement="bottom"
          >
            <span className={styles.showLength}>
              {val?.length === 1 ? `+${val?.length} other` : `+${val?.length} others`}
            </span>
          </Popover>
        </div>
      );
    } else {
      return (
        <>
          {!_.isEmpty(values)
            ? values.map((s) => <div className={styles.ellipse}>{s.locale + " : " + s.value}</div>)
            : " N/A"}
        </>
      );
    }
  };

  const uniqueLocales = getDeviceString?.data
  ?.flatMap((ele) => ele?.values?.map((ele) => ele?.locale))
  ?.filter((item, index, array) => array?.indexOf(item) === index);
  const filteredLocales = getListOfLocales && getListOfLocales?.filter((ele) => uniqueLocales?.includes(ele?.locales));

  const filterList = [
    {
      title: "All",
      displayName: "All",
      displayCountry: "All",
      locales: "",
    },
    ...filteredLocales,
  ];

  const columns = [
    {
      title: "Key",
      dataIndex: "name",
      width: 400,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "",
      dataIndex: "selectData",
    },
  ];

  const deviceStringData =
    getDeviceString &&
    getDeviceString?.data?.map((row) => ({
      key: row?.key,
      name: row?.key,
      value: (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={row?.values.map((data) => {
            return <div>{data.locale + " : " + data.value}</div>;
          })}
        >
          {row?.values?.length + `${row?.values?.length === 1 ? " Locale " : " Locales"}`}
        </Tooltip>
      ),
      selectData: (
        <span
          className={`${styles.attachText} ${disabled && styles.disableAttachText}`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault();
              handleSelectTestData(row, recElement.guid, recTestStep.tag);
              dispatch(deviceDrawerVisible(false));
            }
          }}
        >
          Attach
        </span>
      ),
    }));

  const selectedDeviceStringColumns = [
    {
      title: "Key",
      dataIndex: "name",
      width: 400,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const selectedDeviceStringData = [
    {
      key: recElement?.deviceStrings?.key,
      name: recElement?.deviceStrings?.key,
      value: (
        <div>
          {!_.isEmpty(recElement?.deviceStrings?.values) && showDeviceStringValues(recElement?.deviceStrings?.values)}
        </div>
      ),
      locales: <div>{recElement?.deviceStrings?.locale}</div>,
      action: (
        <div>
          <Tooltip title={"Remove device string"} color={TOOLTIP_BG_COLOR}>
            <SvgLoader
              iconName={ICON_DELETE_WITH_BG}
              disabled={
                props.disabled ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                  unSavedRecElementGuid !== recElement.guid)
              }
              handleClick={() => {
                if (!props.disabled && (!unSavedRecElementGuid || unSavedRecElementGuid === recElement.guid)) {
                  dispatch(selectedDeviceStringValue(undefined, recTestStep?.tag, recElement?.guid));
                }
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  return (
    <Drawer
      className={guidForOpenDeviceDrawer !== recElement?.guid && styles.hideDrawer}
      title="Device Strings"
      visible={showDeviceDrawerVisible}
      width={700}
      onClose={() => {
        dispatch(deviceDrawerVisible(false));
      }}
    >
      <Spin spinning={deviceStringLoader} tip="Loading">
        {recElement?.deviceStrings?.key && (
          <>
            <div className={styles.deviceTitle}>Attached Device String Key:</div>
            <div className={styles.selectedString}>
              <TableComponent columns={selectedDeviceStringColumns} data={selectedDeviceStringData} />
            </div>
          </>
        )}

        <div className={styles.searchBoxOrFilter}>
          <Space size={5}>
            {showDeviceDrawerVisible && (
              <CommonSearch
                placeHolder="Search"
                searchDetails={(searchText) =>
                  dispatch(
                    getDeviceStrings(projectId, searchText, selectedLocale, "", recElement.guid, recTestStep.tag)
                  )
                }
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            )}
            <FilterComponent
              filterList={filterList}
              handleFilterChange={(e) => {
                dispatch(getDeviceStrings(projectId, searchKeyword, e, "", recElement.guid, recTestStep.tag));
              }}
              selectedFilter={filterFetchingDeviceStringsList}
              string={true}
            />
          </Space>
        </div>
        <div className={styles.tableComponent}>
          <TableComponent columns={columns} data={deviceStringData} />
        </div>

        {!_.isEmpty(getDeviceString?.data) && (
          <div className={styles.pagination}>
            <PaginationComponent
              currentPageItems={getDeviceString?.pageItems}
              totalItems={getDeviceString?.totalItems}
              currentPage={getDeviceString?.currentPage}
              totalPage={getDeviceString?.totalCount}
              callPaginationAPI={(pageNumber) => {
                dispatch(
                  getDeviceStrings(
                    projectId,
                    searchKeyword,
                    selectedLocale,
                    pageNumber,
                    recElement.guid,
                    recTestStep.tag
                  )
                );
              }}
            />
          </div>
        )}
      </Spin>
    </Drawer>
  );
};

export default DeviceStringDrawer;
