import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../actions/HomeAction";
import HeaderComponent from "../Header/Header";
import Devices from "../DevicesView/Devices";
import { Col, Row } from "antd";
import { FARMS, xpressComponentType } from "../../Constants";
import * as commonActions from "../../actions/CommonActions";
import DeviceFarmList from "../DeviceFarmList/DeviceFarmList";
import { ComponentContainer } from "../../stylesheets/style";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: props.color,
    };
  }

  componentWillMount() {
    const { checkTestElement } = this.props.actions;
    checkTestElement();
    if (this.props.route && this.props.route.componentId) {
      this.props.commonActions.getChildComponentByTypeAndParentId({
        componentType: xpressComponentType.pageComponents,
        parentId: this.props.route.componentId,
      });
    }
  }

  render() {
    let editRightsForDeviceNote =
      this.props.CommonReducer.childComponentList &&
      this.props.CommonReducer.childComponentList.filter((item) => item.name == "Device_Notes_Edit").length > 0;
    let setDefaultsAppRightsForDevice =
      this.props.CommonReducer.childComponentList &&
      this.props.CommonReducer.childComponentList.filter((item) => item.name == "DeviceDefaultAppSet").length > 0;
    let projectId = localStorage.getItem("projectId");
    return (
      <React.Fragment>
        <HeaderComponent
          showHumbergerMenu={true}
          text=""
          showCloseSession={false}
          buttonState={FARMS}
          organizationId={this.props.match.params.organizationId}
        />
        <ComponentContainer className={"componentContainerPadding"}>
          <Row>
            <Col className={"server-list-container"} offset={1}>
              <DeviceFarmList routeServerId={this.props.match.params.deviceFarmId} projectId={projectId} />
            </Col>
          </Row>
          {this.props.ServerListReducer.deviceFarmList && this.props.ServerListReducer.deviceFarmList.length > 0 && (
            <Row>
              <Col span={24}>
                {
                  <Devices
                    setDefaultsAppRightsForDevice={setDefaultsAppRightsForDevice}
                    editRightsForDeviceNote={editRightsForDeviceNote}
                    adminReleaseDevice={
                      this.props.CommonReducer.childComponentList &&
                      this.props.CommonReducer.childComponentList.filter((item) => item.name == "Admin_Release_Device")
                        .length == 1
                    }
                  />
                }
              </Col>
            </Row>
          )}
        </ComponentContainer>
      </React.Fragment>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    commonActions: bindActionCreators(commonActions, dispatch),
  };
}

function mapStateToProps(state) {
  return {
    HomeReducer: state.HomeReducer,
    CommonReducer: state.CommonReducer,
    ServerListReducer: state.ServerListReducer,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
