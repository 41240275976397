import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateVerificationKey,
  updateVerificationValue,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import { StyledSelect } from "../LaunchApp/LaunchAppList";
import { TOOLTIP_BG_COLOR, VERIFY_TEST_DATA } from "../../../../Constants";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTasks } from "@fortawesome/free-solid-svg-icons";
import ManageTestData from "../../SingleColumnView/ManageTestData";

const VerifyTestData = (props) => {
  const { data, disabled, recElementVerification } = props;
  const { testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { projectId } = useSelector((state) => state.ProjectsReducer);

  const dispatch = useDispatch();
  let recStepVerifyElementProperty =
    data?.recTestStepProperty?.recStepVerifyElementProperties &&
    data?.recTestStepProperty?.recStepVerifyElementProperties.length > 0
      ? data?.recTestStepProperty?.recStepVerifyElementProperties[0]
      : [];
  recStepVerifyElementProperty.uuid = recStepVerifyElementProperty?.id
    ? recStepVerifyElementProperty?.id
    : recStepVerifyElementProperty?.uuid;
    
  return (
    <React.Fragment>
      <Row className="gap-5">
        <Col span={6}>Select Test Data value to verify</Col>
        <Col span={1}>
          <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
            <Button
              className={!props.disabled && "blue"}
              disabled={
                disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
              }
              onClick={() => {
                dispatch(
                  visibleTestDataDrawer(
                    VERIFY_TEST_DATA,
                    data?.tag,
                    recElementVerification?.guid,
                    recStepVerifyElementProperty
                  )
                );
                dispatch(selectRecTestSteps(data));
              }}
            >
              <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
            </Button>
          </Tooltip>
        </Col>

        <Col span={7}>
          <StyledSelect
            placeholder="Please select Test Data (Type: Output)"
            className="w-100"
            disabled={
              disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
            }
            value={
              recStepVerifyElementProperty.key
                ? parseInt(recStepVerifyElementProperty.key)
                : recStepVerifyElementProperty.key
            }
            onChange={(value) => {
              dispatch(
                updateVerificationKey(
                  value,
                  recStepVerifyElementProperty.uuid,
                  data?.tag,
                  recElementVerification?.guid,
                  testDataList,
                  recStepVerifyElementProperty.value
                )
              );
            }}
          >
            {testDataList &&
              testDataList.length > 0 &&
              testDataList.map((v, k) => {
                return (
                    <Select.Option key={v.id} value={v.id}>
                      {v.name + (v.value ? " (" + v.value + ")" : "")}
                    </Select.Option>
                );
              })}
          </StyledSelect>
        </Col>
        <Col span={7}>
          <Input
            type="text"
            disabled={
              disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data?.tag)
            }
            placeholder="Value to compare with Test Data"
            value={recStepVerifyElementProperty.value}
            onChange={(e) =>
              dispatch(
                updateVerificationValue(
                  e.target.value,
                  recStepVerifyElementProperty.uuid,
                  data.tag,
                  true,
                  recElementVerification?.guid,
                  recStepVerifyElementProperty.key,
                  testDataList
                )
              )
            }
          />
        </Col>
      </Row>
      <ManageTestData {...props} projectId={projectId} tabName={VERIFY_TEST_DATA}/>
    </React.Fragment>
  );
};
export default VerifyTestData;
