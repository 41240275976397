import { Button, Col, Row, Spin, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  loadEditTestDataDetail,
  deleteTestData,
  fetchTestDataByProjectId,
  fetchTestData,
  showAddProjectKeysForm,
  loadEditProjectStringDetail,
  fetchProjectKeysData,
} from "../../actions/TestDataAction";
import { TEST_DATA_SCOPE } from "../../Constants";
import { Label } from "../DeviceDetails/DeviceAssignmentModal";
import { PlusCircleOutlined } from "@ant-design/icons";
import PaginationComponent from "../CoreComponents/PaginationComponent/PaginationComponent";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";
import _ from "lodash";
import CommonSearch from "../CoreComponents/SearchComponent/CommonSearch";
import { showAddTestDataForm } from "../../actions/TestDataAction";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const { confirm } = Modal;

const TestDataList = styled.div`
  max-height: 200px;
  overflow-y: scroll;
`;
const StyledTestDataSelectButton = styled(Button)`
  margin-right: 5px;
`;
const StyledRow = styled(Row)`
  display: flex;
  justify-content: end;
  align-items: center;
  margin: 10px;
`;

const TestData = (props) => {
  const { projectId, projectKeys } = props;
  const { fetchingTestDataList } = useSelector((props) => props.TestDataReducer);
  const { testDataList, totalTestDataCounts, currentTestDataPage, totalTestDataPageCount, testDataPageItems } =
    useSelector((props) => props.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(null);

  useEffect(() => {
    if (projectKeys) {
      dispatch(fetchProjectKeysData(projectId));
    } else {
      dispatch(fetchTestDataByProjectId(projectId, 1, ""));
    }
  }, [projectId]);

  const TestDataSetActions = (v) => {
    return (
      <>
        <StyledTestDataSelectButton
          type="primary"
          danger
          onClick={(e) => {
            setOpenConfirmModal(v.id);
          }}
        >
          Delete
        </StyledTestDataSelectButton>
        <Button
          type="info"
          onClick={() =>
            projectKeys ? dispatch(loadEditProjectStringDetail(v.id)) : dispatch(loadEditTestDataDetail(v.id))
          }
        >
          Edit
        </Button>
      </>
    );
  };

  const searchDetails = (searchText) => {
    if (projectKeys) {
      dispatch(fetchProjectKeysData(projectId, searchText));
    } else {
      dispatch(fetchTestDataByProjectId(projectId, 1, searchText));
    }
  };

  const callPaginationAPI = (pageNumber) => {
    if (projectKeys) {
      dispatch(fetchProjectKeysData(projectId, "", pageNumber));
    } else {
      dispatch(fetchTestDataByProjectId(projectId, pageNumber, searchKeyword));
    }
  };

  const addTestData = () => {
    if (projectKeys) {
      dispatch(showAddProjectKeysForm(projectId));
    } else {
      dispatch(showAddTestDataForm(projectId));
    }
  };
  const testDataListNew =
    testDataList &&
    testDataList?.map((row) => ({
      key: row?.id,
      testDataDetails: (
        <div>
          <div>
            {!projectKeys && <Label>Data Name: </Label>}
            <span className="dataSetTitle">{row?.name}</span>
          </div>
          {row?.createdBy !== null && row?.createdBy && (
            <div>
              <Label>Created By: </Label>
              <span className="text-light1">{row?.createdBy}</span>
            </div>
          )}
          {row?.updatedBy !== null && row?.updatedBy && (
            <div>
              <Label>Updated By: </Label>
              <span className="text-light1">{row?.updatedBy}</span>
            </div>
          )}
        </div>
      ),
      scope: <>{row?.scope}</>,
      type: <>{row?.type}</>,
      defaultValue: <span className="word-break-all">{row?.value}</span>,
      action: TestDataSetActions(row),
    }));

  const columns = [
    {
      title: projectKeys ? "Key Name" : "Name",
      dataIndex: "testDataDetails",
    },
    {
      title: "Scope",
      dataIndex: "scope",
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Default Value",
      dataIndex: "defaultValue",
      width: 350,
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
    },
  ];
  return (
    <div className="pageScroll">
      <Row>
        <Col span={24} className="display-flex justify-end">
          <StyledRow>
            <Col className="mr-10">
              <PlusCircleOutlined disabled={false} onClick={() => addTestData()} />
            </Col>
            <Col>
              <CommonSearch
                placeHolder="Search by Data name"
                searchDetails={(searchText) => searchDetails(searchText)}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </Col>
          </StyledRow>
        </Col>
      </Row>
      <>
        <div className="w-100 mt-10 p-10">
          <Spin spinning={fetchingTestDataList} tip="Loading" className="spinner-center">
            <TableComponent data={testDataListNew} columns={columns} />
          </Spin>
        </div>
        <div className="pull-right">
          {!_.isEmpty(testDataList) && (
            <PaginationComponent
              currentPageItems={testDataPageItems}
              totalItems={totalTestDataCounts}
              currentPage={currentTestDataPage}
              totalPage={totalTestDataPageCount}
              callPaginationAPI={callPaginationAPI}
            />
          )}
        </div>
      </>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(deleteTestData(openConfirmModal, projectKeys ? false : true, "", projectKeys, true));
          setOpenConfirmModal(null);
        }}
        handleCancel={() => setOpenConfirmModal(null)}
        modalOpen={!!openConfirmModal}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this item?"}
        okText={"Delete"}
        isDeleteModal={true}
        modalNote="Note: Test Data set attached with this Test Data will be removed."
      />
    </div>
  );
};

export default TestData;
