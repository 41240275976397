import { Button, Col, Input, Row, Select, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomReferenceVerification,
  ELEMENT_ACTION,
  Number,
  ReferenceTestDataDropdown,
  ReferenceTextDropdown,
  STEP,
  TEST_BLOCK,
  TEST_DATA_SCOPE,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
  TextBox,
  VERIFICATIONS,
  VERIFICATION_FOR_TEST_DATA,
  splitValue,
  verifyApplicationStrings,
  verifyDeviceStrings,
  verifyElementTextValues,
  verifyElementTextValuesForRecElement,
} from "../../../../Constants";
import { getRecElement, hasNotAllowedNegativeValue, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import {
  removeVerificationRow,
  updateReferenceStepValue,
  updateReferenceTestDataForVerification,
  updateVerificationKey,
  updateVerificationValue,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

import { faTasks } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import ManageTestData from "../../SingleColumnView/ManageTestData";
import CustomTextVerification from "./CustomTextVerification";
import RemoveVerificationRow from "./RemoveVerificationRow";

const VerifyElementText = (props) => {
  let { data, recTestSteps, disabled, recTestStep, recElementVerification } = props;
  const { recTestStepList } = useSelector((state) => state.RecTestStepReducer);
  const { projectId } = useSelector((state) => state.ProjectsReducer);
  const { stepType, unSavedRecStepTag, testDataList, unSavedRecElementGuid } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  if (recTestSteps === undefined) {
    recTestSteps = recTestStepList;
  }
  let keys = Object.keys(recElementVerification ? verifyElementTextValuesForRecElement : verifyElementTextValues);
  if (recElementVerification.applicationStrings) {
    keys = [...keys, ...verifyApplicationStrings];
  }
  if (recElementVerification.deviceStrings) {
    keys = [...keys, ...verifyDeviceStrings];
  }

  let recElement = recElementVerification
    ? recElementVerification
    : getRecElement(recTestStep.recTestStepProperty?.recElements, unSavedRecElementGuid);

  const dispatch = useDispatch();
  if (verifyElementTextValues && verifyElementTextValues[data.key] === "ReferenceTextDropdown") {
  }
  const prepareCustomElementText = (data) => {
    return (
      data &&
      data.map((item, index) => {
        if (item.key === "Text") {
          return <span key={index}>{item.value}</span>;
        } else if (item.key === "ReferenceText" && item.value !== "") {
          const property = recTestSteps.filter((eitem) => eitem.id === item.value || eitem.tag === item.value);
          return (
            property.length > 0 &&
            property[0].recTestStepProperty && (
              <span
                key={index}
                className="span-reference-text-preview-item"
                title={"Step Name: " + property[0].recTestStepProperty?.stepName}>
                {property[0].recTestStepProperty?.uniqueByText}
              </span>
            )
          );
        }
      })
    );
  };
  const prepareReferenceActionStepList = (data, recTestStep) => {
    let referenceActionStep = [];
    data &&
      data.map((item, index) => {
        if (recTestStep.tag !== item.tag) {
          if (
            item.recTestStepProperty?.action === ELEMENT_ACTION.SEND_KEYS ||
            item.recTestStepProperty?.action === ELEMENT_ACTION.TAP ||
            item.recTestStepProperty?.action === ELEMENT_ACTION.TAP_BY_INDEX ||
            item.recTestStepProperty?.action === ELEMENT_ACTION.LONG_PRESS ||
            item.recTestStepProperty?.action === ELEMENT_ACTION.CLEAR_TEXT ||
            item.recTestStepProperty?.action === VERIFICATIONS
          ) {
            referenceActionStep.push({
              id: item.id,
              name: item.recTestStepProperty?.stepName,
              action: item.recTestStepProperty?.action,
              uuid: item.tag,
            });
          } else if (item.recTestStepProperty?.action === STEP) {
            referenceActionStep = [
              ...referenceActionStep,
              ...prepareReferenceActionStepList(item.recTestSteps, recTestStep),
            ];
          }
        }
      });
    return referenceActionStep;
  };
  data.uuid = data.id ? data.id : data.uuid;
  return (
    <React.Fragment>
      <Row className="verification-row">
        <Col md={23}>
          Verify Element Text
          <Select
            disabled={disabled}
            className={"control"}
            value={data.key}
            onChange={(value) =>
              dispatch(updateVerificationKey(value, data.uuid, recTestStep.tag, recElementVerification?.guid))
            }>
            {keys.map((v, k) => {
              return (
                <Select.Option key={k} value={v}>
                  {v}
                </Select.Option>
              );
            })}
          </Select>
          {verifyElementTextValues[data.key] === TextBox && (
            <Input
              type="text"
              className={"control"}
              value={data.value}
              disabled={disabled}
              placeholder={data.key}
              // onKeyDown={this.changeVerificationValue}
              onChange={(e) =>
                dispatch(
                  updateVerificationValue(
                    e.target.value,
                    data.uuid,
                    recTestStep.tag,
                    false,
                    recElementVerification?.guid
                  )
                )
              }
            />
          )}
          {verifyElementTextValues[data.key] === Number && (
            <Input
              disabled={disabled}
              type="number"
              className={"control verification-input-textbox"}
              placeholder={data.key}
              value={data.value}
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              // onKeyDown={this.changeVerificationValue}
              onChange={(e) =>
                dispatch(
                  updateVerificationValue(
                    e.target.value,
                    data.uuid,
                    recTestStep.tag,
                    false,
                    recElementVerification?.guid
                  )
                )
              }
            />
          )}
          {verifyElementTextValues[data.key] === ReferenceTextDropdown && (
            <Select
              disabled={disabled}
              style={{ width: 200 }}
              value={data.referenceStep ? data.referenceStep.tag + splitValue + data.referenceStep.id : ""}
              onChange={(value) =>
                dispatch(updateReferenceStepValue(value, data.uuid, recTestStep.tag, recElementVerification?.guid))
              }>
              {prepareReferenceActionStepList(recTestSteps, recTestStep).map((v, k) => {
                return (
                  <Select.Option key={k} value={v.uuid + splitValue + (v.id ? v.id : 0)}>
                    <span className="font-weight-500 text-transform-capital"> {v.action} :</span>
                    {v.name}
                  </Select.Option>
                );
              })}
            </Select>
          )}
          {verifyElementTextValues[data.key] === ReferenceTestDataDropdown && (
            <>
              <Select
                placeholder="Select a Test Data Key"
                className="w-100"
                disabled={
                  disabled ||
                  (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== recTestStep.tag)
                }
                value={data.refTestData ? data.refTestData.id : data.refTestDataId}
                onChange={(value) => {
                  let selectedTestData = testDataList.filter((item) => item.id === value);
                  dispatch(
                    updateReferenceTestDataForVerification(
                      data.key,
                      data.uuid,
                      recTestStep.tag,
                      selectedTestData.length > 0 ? selectedTestData[0] : undefined,
                      recElementVerification?.guid
                    )
                  );
                }}>
                {testDataList &&
                  testDataList.length > 0 &&
                  testDataList.map((v, k) => {
                    if (
                      (v.scope === TEST_DATA_SCOPE.TEST_STEP_BLOCK && stepType === TEST_STEP_BLOCKS) ||
                      (v.scope === TEST_DATA_SCOPE.TEST_SCENARIO && stepType === TEST_SCENARIOS) ||
                      v.scope === TEST_DATA_SCOPE.PROJECT ||
                      (v?.id === (data.refTestData && data.refTestData?.id) ? data.refTestData?.id : data.refTestDataId)
                    )
                      return (
                        <Select.Option key={v.id} value={v.id}>
                          {v.name + (v.value ? " (" + v.value + ")" : "")}
                        </Select.Option>
                      );
                  })}
              </Select>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <Button
                  className={props.disabled ? " ml-10" : "blue ml-10"}
                  disabled={props.disabled}
                  onClick={() => {
                    dispatch(
                      visibleTestDataDrawer(
                        VERIFICATION_FOR_TEST_DATA,
                        recTestStep?.tag,
                        recElementVerification?.guid,
                        data
                      )
                    );
                    dispatch(selectRecTestSteps(data));
                  }}>
                  <FontAwesomeIcon icon={faTasks}></FontAwesomeIcon>
                </Button>
              </Tooltip>
            </>
          )}
          {verifyElementTextValues[data.key] === CustomReferenceVerification && data.child === undefined ? (
            <CustomTextVerification disabled={disabled} uuid={data.uuid} />
          ) : (
            verifyElementTextValues[data.key] === CustomReferenceVerification &&
            data.child &&
            data.child.length > 0 && (
              <Row>
                <Col>{prepareCustomElementText(data.child)}</Col>
              </Row>
            )
          )}
        </Col>
        <Col md={1}>
          <RemoveVerificationRow
            disabled={disabled}
            parentId={data.uuid}
            recTestStep={props.recTestStep}
            removeVerificationRow={removeVerificationRow}
            recElement={recElement}
          />
        </Col>
        <ManageTestData {...props} projectId={projectId} tabName={VERIFICATION_FOR_TEST_DATA} />
      </Row>
    </React.Fragment>
  );
};
export default VerifyElementText;
