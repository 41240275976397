import { async } from "../api/automation";
import {
  All_RECORDS,
  BUILD_TEST_SCENARIO,
  CHANGE_FREE_DEVICE_FOR_RECORDING,
  CHANGE_REC_TEST_SCENARIO_NAME,
  CHANGE_SCENARIO_LIST_FILTER,
  CHANGED_REC_TEST_SCENARIO_NAME,
  CLEAR_UNSAVED_STEP,
  CLONE_REC_TEST_SCENARIO,
  dateHourMinuteFormat,
  DELETE_REC_TEST_SCENARIO,
  ERROR_TYPE,
  FETCH_PREVIEW_RUNS,
  FETCHING_REC_TEST_SCENARIO_LIST,
  GET_TEST_SCENARIO_STEPS_DATA,
  GET_TEST_SUITS,
  HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  HIDE_DEVICE_SELECTION_MODAL,
  HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE,
  LOAD_REC_TEST_SCENARIO_TAGS,
  MY_SCENARIOS,
  OWNED,
  REC_TEST_SCENARIO_LIST,
  REC_TEST_SCENARIO_LIST_BY_PROJECT_ID,
  REC_TEST_STEP_LIST,
  RECORD_10_PER_PAGE,
  RECORD_SCENARIO_UPDATE_SPINNER,
  REQUEST_ADD_NEW_SCENARIO,
  REQUESTED_ADD_NEW_SCENARIO,
  RESET_ALL_REC_TEST_SCENARIO_STEP_IDS,
  RESET_DEVICE_SCREEN_SOURCE,
  RESET_REC_TEST_SCENARIO_NAME,
  RESET_RECORDED_SCENARIO,
  SEARCH_SCENARIO_KEYWORD,
  SELECT_ALL_REC_TEST_SCENARIO_STEP_IDS,
  SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED,
  SELECTED_TAGS_RECORD_SCENARIO,
  SET_SELECTED_SCENARIO,
  SET_TEC_TEST_SCENARIO_ID,
  SET_TEST_RAIL_CASE_ID,
  SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  SHOW_DEVICE_SELECTION_MODAL,
  SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TAG_LIST_RECORD_SCENARIO,
  TEST_RAIL_CASE_LOADING,
  TEST_RAIL_CASES,
  UPDATE_SCENARIO_NAME,
  UPDATE_TAG_SCENARIO_FIELD,
} from "../Constants";
import history from "../history";
import { getFormateDateAndTime, isNullOrUndefined, showNotification, updateResponse } from "../Util";
import { recordingStop, startDeviceScreenStreaming } from "./Recording";
import { resetEditRecTestStep } from "./RecTestStep/RecTestStepCommonAction";
import { changeRecordScenarioTagsList, clearPreview } from "./RecTestStep/RecTestStepRedirectAction";
import { acquireDevice, generateDeviceToken } from "./ServerListAction";
import { editScenario } from "./TestPlanAction";

export function getByProjectIdAndNotAssocWithTestPlan(projectId, searchKeyword, testPlanId = 0, setIsLoading) {
  return async (dispatch) => {
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    if (setIsLoading) setIsLoading(true);
    async("recTestScenario/getByProjectIdAndNotAssocWithTestPlan", "GET", {
      projectId: projectId,
      searchKeyword: searchKeyword,
      testPlanId: testPlanId,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: REC_TEST_SCENARIO_LIST_BY_PROJECT_ID, response });
        dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false });
        if (setIsLoading) setIsLoading(false);
        return true;
      } else {
        showNotification("error", "Failed To Load Scenario List.", "");
        dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false });
        if (setIsLoading) setIsLoading(false);
        return false;
      }
    });
  };
}
export function changeScenarioListFilter(value, projectId, searchKeyword) {
  return async (dispatch, getState) => {
    const { searchScenarioKeyword } = getState().RecTestScenarioReducer;
    dispatch({ type: CHANGE_SCENARIO_LIST_FILTER, value });
    dispatch(getRecTestScenarioList("", 1, projectId, searchScenarioKeyword, "", "", value));
  };
}
export function getRecTestScenarioList(
  platform,
  pageNumber,
  projectId,
  searchKeyword,
  startDate,
  endDate,
  rights = undefined
) {
  return async (dispatch, getState) => {
    const { scenarioListFilter, searchScenarioKeyword } = getState().RecTestScenarioReducer;
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    async("recTestScenario/getScenariosForPlatform", "GET", {
      pageNumber: pageNumber,
      recordsPerPage: RECORD_10_PER_PAGE,
      platformName: platform,
      projectId: projectId,
      searchKeyword: searchScenarioKeyword,
      startDate: startDate,
      endDate: endDate,
      rights: rights ? rights?.id : scenarioListFilter?.id,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: REC_TEST_SCENARIO_LIST, response, pageNumber });
        dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false });
        return true;
      } else {
        showNotification("error", "Failed To Load Scenario List.", "");
        dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false });
        return false;
      }
    });
  };
}

export function showCloneScenarioConfirmationModal(recTestScenarioId) {
  return async (dispatch) => {
    dispatch({ type: SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL, recTestScenarioId });
  };
}
export function hideCloneScenarioConfirmationModal() {
  return async (dispatch) => {
    dispatch({ type: HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL });
  };
}
export function showDeleteScenarioConfirmationModal(recTestScenarioData) {
  return async (dispatch) => {
    dispatch({ type: SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL, recTestScenarioData });
  };
}

export function hideDeleteScenarioConfirmationModal() {
  return async (dispatch) => {
    dispatch({ type: HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL });
  };
}

export function showDeviceSelectionModal(recTestScenarioId, recTestScenarioPlatform) {
  return async (dispatch) => {
    dispatch({ type: SHOW_DEVICE_SELECTION_MODAL, recTestScenarioId, recTestScenarioPlatform });
  };
}
export function hideDeviceSelectionModal() {
  return async (dispatch) => {
    dispatch({ type: HIDE_DEVICE_SELECTION_MODAL });
  };
}

export function changeFreeDeviceForRecording(targetUniqueId) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_FREE_DEVICE_FOR_RECORDING, targetUniqueId });
  };
}

export function navigateToRecordingScreen() {
  return async (dispatch, getState) => {
    if (getState().RecTestScenarioReducer.selectedFreeDeviceForRecording) {
      const platform = getState().RecTestScenarioReducer.selectedRecTestScenarioPlatform;
      const targetUniqueId = getState().RecTestScenarioReducer.selectedFreeDeviceForRecording;
      const selectedRecTestScenarioId = getState().RecTestScenarioReducer.selectedRecTestScenarioId;
      const deviceObj = getState().RecTestScenarioReducer.freeDeviceListForRecording.filter(
        (item) => item["device"]["targetUniqueId"]
      );
      dispatch(generateDeviceToken());
      dispatch(acquireDevice(deviceObj[0]["device"], deviceObj[0]["serverDetail"])).then(
        dispatch(
          startDeviceScreenStreaming(
            platform,
            deviceObj[0]["serverDetail"].id,
            targetUniqueId,
            deviceObj[0]["device"]["deviceFarmId"],
            deviceObj[0]["deviceMinicapNodeServicePort"],
            selectedRecTestScenarioId
          )
        )
      );
    }
  };
}

export function goToTestScenario() {
  return () => {
    history.push("/testScenario");
  };
}

export function setRecTestScenarioIdForPreview(id) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_TEC_TEST_SCENARIO_ID, id: id });
  };
}

export function clearAllPreviews(setOpenPreviewRunDrawer) {
  return async (dispatch, getState) => {
    return async("runResult/clearRunResultByScenarioId", "GET", {
      recTestScenarioId: getState().RecTestScenarioReducer.recTestScenarioIdForPreview,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(fetchPreviewRunById());
        setOpenPreviewRunDrawer(false);
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}
export function fetchPreviewRunById(searchText, startDate, endDate, pageNumber) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    let data = {
      recTestScenarioId: getState().RecTestScenarioReducer.recTestScenarioIdForPreview,
      recordsPerPage: RECORD_10_PER_PAGE,
      pageNumber: pageNumber || 1,
      searchKeyword: searchText || "",
      startDate: startDate ? getFormateDateAndTime(startDate, dateHourMinuteFormat) : "",
      endDate: endDate ? getFormateDateAndTime(endDate, dateHourMinuteFormat) : "",
    };
    return async("recTestScenario/getPreviewRunsById", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: FETCH_PREVIEW_RUNS, response });
      dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: false });
    });
  };
}
export function duplicateRecTestScenario(data) {
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    return async("recTestScenario/cloneScenario", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: CLONE_REC_TEST_SCENARIO, response });
      //Re call for update reducer variable
      dispatch({ type: CLONE_REC_TEST_SCENARIO });
      dispatch(getRecTestScenarioList("", 1, data.projectId, "", "", ""));
    });
  };
}

export function detachScenarioFromRecording() {
  return async (dispatch, getState) => {
    dispatch(setSelectedScenario({}));
    dispatch(resetEditRecTestStep());
    dispatch({ type: SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED });
    dispatch({ type: CLEAR_UNSAVED_STEP });
    dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
    dispatch(recordingStop());
  };
}

export function showRecTestScenarioListModalVisible(projectId) {
  return async (dispatch) => {
    dispatch(changeScenarioListFilter({ id: "", value: All_RECORDS }, projectId, ""));
    dispatch({ type: SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE });
  };
}

export function hideRecTestScenarioListModalVisible() {
  return async (dispatch) => {
    dispatch({ type: HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE });
  };
}

export function changeRecTestScenarioName(value, isUpdateVisible) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data: isUpdateVisible });
    dispatch({ type: CHANGE_REC_TEST_SCENARIO_NAME, value });
  };
}
export function resetRecTestScenarioDetails() {
  return async (dispatch) => {
    dispatch({ type: RESET_REC_TEST_SCENARIO_NAME });
  };
}

export function resetScenarioRecorded() {
  return async (dispatch) => {
    dispatch({ type: RESET_RECORDED_SCENARIO });
  };
}
export function selectAllRecScenarioStepIds(recTestStepId, MultiViewDesign, recTestStepList, childRecTestSteps) {
  return async (dispatch, getState) => {
    dispatch({
      type: SELECT_ALL_REC_TEST_SCENARIO_STEP_IDS,
      recTestStepId,
      MultiViewDesign,
      recTestStepList,
      childRecTestSteps,
      stepType: getState().RecTestStepCommonViewReducer.stepType,
    });
  };
}
export function resetAllRecScenarioStepIds() {
  return async (dispatch) => {
    dispatch({ type: RESET_ALL_REC_TEST_SCENARIO_STEP_IDS });
  };
}

export function updateRecTestScenarioName(selectedTags, updateSpinner, testRailTestCaseId, testRailSuiteId, projectId) {
  let updatedSpinner = false;
  return async (dispatch, getState) => {
    dispatch({ type: RECORD_SCENARIO_UPDATE_SPINNER, response: { isLoading: true } });
    async("recTestScenario/updateScenarioName", "POST", {
      name: getState().RecTestStepReducer.selectedRecTestScenarioName,
      id: getState().RecTestStepReducer.selectedRecTestScenarioId,
      tags: selectedTags || [],
      listOfTestRailTestCaseIds: testRailTestCaseId,
      testRailTestSuiteId: testRailSuiteId,
      projectId: projectId,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", response.message, "");
        dispatch({ type: CHANGED_REC_TEST_SCENARIO_NAME, response });
        dispatch(updateScenarioOrTagField(true));
        dispatch({ type: RECORD_SCENARIO_UPDATE_SPINNER, response: { isLoading: false } });
        dispatch({ type: UPDATE_SCENARIO_NAME });
        dispatch({ type: SET_TEST_RAIL_CASE_ID, testCaseID: testRailTestCaseId });
      } else {
        showNotification("error", response.message, "");
        dispatch(changeRecordScenarioTagsList([]));
        dispatch({ type: RECORD_SCENARIO_UPDATE_SPINNER, response: { isLoading: false } });
      }
    });
  };
}

export function createNewRecTestScenario(platform, isUpdateVisible, projectId, navigateToEditBuildScenario) {
  let disableUpdateButton = true;
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_ADD_NEW_SCENARIO });
    async("recTestScenario/customSave", "POST", {
      platform: platform,
      projectId: projectId,
      isActive: true,
    }).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", "Successfully Created", "");
        dispatch(clearPreview());
        dispatch({
          type: REC_TEST_STEP_LIST,
          response: [],
          recTestScenarioId: response.data.id,
          recTestScenarioName: response.data.name,
        });
        dispatch({ type: SET_SELECTED_SCENARIO, data: response.data });
        dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data: isUpdateVisible });
        dispatch(changeRecordScenarioTagsList([]));
        navigateToEditBuildScenario(BUILD_TEST_SCENARIO);
        dispatch(editScenario(response.data, projectId));
        dispatch({ type: REQUESTED_ADD_NEW_SCENARIO });
      } else {
        dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data: disableUpdateButton });
        showNotification("error", response.message, "");
      }
      dispatch(changeScenarioListFilter({ id: OWNED, value: MY_SCENARIOS }, projectId, ""));
    });
  };
}

export function deleteRecTestScenario(data, setSelectAllScenario) {
  let updateRecordScenarioButton = true;
  let scenarioNameValue = undefined;
  let val = [];
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    if (data.recTestScenarioId == getState().RecTestStepReducer.selectedRecTestScenarioId) {
      dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data: updateRecordScenarioButton });
      dispatch({ type: CHANGE_REC_TEST_SCENARIO_NAME, value: scenarioNameValue });
      dispatch({ type: RESET_RECORDED_SCENARIO });
      dispatch({ type: TAG_LIST_RECORD_SCENARIO, val });
    }
    return async("recTestScenario/deleteRecTestScenario", "POST", data).then((response) => {
      response = updateResponse(response);
      setSelectAllScenario([]);
      dispatch({ type: DELETE_REC_TEST_SCENARIO, response });
      dispatch({ type: RESET_RECORDED_SCENARIO });
      //Re call for update reducer variable
      dispatch({ type: DELETE_REC_TEST_SCENARIO });
      dispatch(getRecTestScenarioList("", 1, data.projectId, "", "", ""));
    });
  };
}

export function publishRecTestScenario(data, setSelectAllScenario, rights) {
  let updateRecordScenarioButton = true;
  let scenarioNameValue = undefined;
  let val = [];
  return async (dispatch, getState) => {
    dispatch({ type: FETCHING_REC_TEST_SCENARIO_LIST, status: true });
    if (data.recTestScenarioId === getState().RecTestStepReducer.selectedRecTestScenarioId) {
      dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data: updateRecordScenarioButton });
      dispatch({ type: CHANGE_REC_TEST_SCENARIO_NAME, value: scenarioNameValue });
      dispatch({ type: RESET_RECORDED_SCENARIO });
      dispatch({ type: TAG_LIST_RECORD_SCENARIO, val });
    }
    return async("recTestScenario/publishRecTestScenario", "POST", data).then((response) => {
      response = updateResponse(response);
      setSelectAllScenario([]);
      dispatch({ type: DELETE_REC_TEST_SCENARIO, response });
      dispatch({ type: RESET_RECORDED_SCENARIO });
      //Re call for update reducer variable
      dispatch({ type: DELETE_REC_TEST_SCENARIO });
      dispatch(getRecTestScenarioList("", 1, data.projectId, "", "", "", rights));
    });
  };
}

export function rectTestScenarioPermissionAPI(obj, projectId) {
  return async (dispatch) => {
    async("recTestScenarioRight/customSave", "POST", obj).then((response) => {
      response = updateResponse(response);
      dispatch(getRecTestScenarioList("", 1, projectId, "", "", ""));
    });
  };
}

export function loadRecTestScenarioTags(projectId) {
  return async (dispatch) => {
    let proId = {
      projectId: projectId,
    };
    async("recTestScenarioTag/getByProjectId", "GET", proId).then((response) => {
      dispatch({ type: LOAD_REC_TEST_SCENARIO_TAGS, response });
    });
  };
}

export function selectedTagValues(data) {
  return async (dispatch) => {
    dispatch({ type: SELECTED_TAGS_RECORD_SCENARIO, data });
  };
}

export function updateScenarioOrTagField(data) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_TAG_SCENARIO_FIELD, data });
  };
}

export function getScenariosAndTestStepsAndTestDataById(id) {
  return async (dispatch, getState) => {
    return async("recTestScenario/getScenariosAndTestStepsAndTestDataById", "GET", { id }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_SCENARIO_STEPS_DATA, response, id });
      }
    });
  };
}

export function getTestRailCases(data) {
  return async (dispatch) => {
    dispatch({ type: TEST_RAIL_CASE_LOADING, response: { isLoading: true } });
    return async("testRail/getTestCaseByProjectId", "GET", {
      projectId: data.projectId,
      offset: data.pageNumber || 1,
      limit: data.recordPerPage,
      searchKeyword: isNullOrUndefined(data.searchKeyword) ? "" : data.searchKeyword,
      suiteId: data.suiteId ? data.suiteId : 0,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_RAIL_CASE_LOADING, response: { isLoading: false } });
        dispatch({ type: TEST_RAIL_CASES, response, data });
      } else {
        dispatch({ type: TEST_RAIL_CASE_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function getTestSuits(projectId) {
  return async (dispatch) => {
    return async("testRail/getTestSuitsByProjectId", "GET", { projectId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_SUITS, response });
      }
    });
  };
}

export function setSelectedScenario(data) {
  return async (dispatch) => {
    dispatch({ type: SET_SELECTED_SCENARIO, data });
  };
}

export function changeSearchScenarioKeyword(value) {
  return async (dispatch) => {
    dispatch({ type: SEARCH_SCENARIO_KEYWORD, value });
  };
}
