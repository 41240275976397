import React from "react";
import { isEmpty } from "lodash";
import styles from "./AllJobsDetailsColumnsV2.module.scss";

const TestCoverageColumnV2 = (props) => {
  const { row, showDeviceList } = props;
  return (
    <>
      <div>
        <span className={styles["label"]}>
          Devices:{" "}
          {!isEmpty(row?.devices) ? (
            showDeviceList(row?.devices,row?.deviceServiceFarm)
          ) : (
            <span className={styles["label-name"]}> Unknown </span>
          )}{" "}
        </span>
      </div>
      <div>
        <span className={styles["label"]}>OS Coverage: </span>
        <span className={styles["label-name"]}>
          {" "}
          {row?.testCoverageDetails?.totalOsToCover?.toString().replaceAll(",", ", ")}
        </span>
      </div>
      {row?.uniqueLocales && (
        <div>
          <span className={styles["label"]}>Locales:</span>{" "}
          <span className={styles["label-name"]}> {row?.uniqueLocales?.toString().replaceAll(",", ", ")}</span>
        </div>
      )}
      <div>
        <span className={styles["label"]}>Tests: </span>
        <span className={styles["label-name"]}>Scenarios: {row?.testCoverageDetails?.totalScenarioToExecute}, </span>
        <span className={styles["label-name"]}>Steps: {row?.testCoverageDetails?.totalStepsToExecute}, </span>
        <span className={styles["label-name"]}>ScreenShots: {row?.testCoverageDetails?.totalScreenShotToCapture}</span>
      </div>
    </>
  );
};

export default TestCoverageColumnV2;
