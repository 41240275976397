import React, { useEffect, useState } from "react";
import { Cookies } from "react-cookie";
import { Button } from "antd";
import styles from "./CookieBannerV2.module.scss";

const CookieBannerV2 = () => {
  const [showCookieHeader, setShowCookieHeader] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    if (cookies.get("xpressTermsAndService") === undefined) {
      setShowCookieHeader(true);
    }
  }, []);

  const acceptCookie = (e) => {
    var date, expires;
    var name = "xpressTermsAndService";
    date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000); // 30 days
    expires = "; expires=" + date.toGMTString();
    document.cookie = name + "=" + name + expires + "; path=/";
    setShowCookieHeader(false);
  };

  return (
    showCookieHeader && (
      <>
        <div className={styles["cookie_Consent"]}>
          <div className={styles["cookie_Text"]}>
            We use cookies to enhance user experience and analyse site usage. By continuing to browse or closing this
            banner, you acknowledge that you have read and agree to our&nbsp;
            <a href="/TermsAndPrivacyPolicy" target="_blank">
              Terms Of Service and Privacy Policy
            </a>
            .
          </div>
          <Button onClick={acceptCookie} type={"primary"}>
            Ok
          </Button>
        </div>
      </>
    )
  );
};

export default CookieBannerV2;