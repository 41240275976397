import { Button, Col, Input, Row, Select, Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_EXECUTION_FARM } from "../../../Constants";
import { disableValidateCredential, isResetDisableForCloudTesting } from "../../../Util";
import {
  getExternalFarmConfig,
  getExternalFarmDataCenterList,
  handleDisableButton,
  setExternalFarmValidation,
  updateExternalFarmDetail,
  updateLambdaTestDetail,
  validateExternalFarmCredentials,
} from "../../../actions/ProjectSettingsActions";
import ErrorComponent from "../../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButton from "../../CoreComponents/SaveResetComponent/SaveResetButton";
import styles from "./LambdaTestV2.module.scss";

const initialLambdaTestDetails = {
  userName: "",
  accessKey: "",
  dataCenter: "",
};

const { Option } = Select;

const LambdaTestV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const {
    externalFarmDataCenterList,
    externalFarmDetails,
    isDisabled,
    isValidateExternalFarmCredentials,
    projectLoading,
    externalFarmLoading,
    externalFarmName,
  } = useSelector((state) => state.ProjectSettingsReducer);

  const [lambdaTestDetails, setLambdaTestDetails] = useState(initialLambdaTestDetails);
  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(getExternalFarmDataCenterList(DEVICE_EXECUTION_FARM.LAMBDA_TEST));
    dispatch(getExternalFarmConfig(projectId, DEVICE_EXECUTION_FARM.LAMBDA_TEST));
  }, [projectId]);

  useEffect(() => {
    if (!isEmpty(externalFarmDetails) && externalFarmName === DEVICE_EXECUTION_FARM.LAMBDA_TEST) {
      setLambdaTestDetails({
        userName: externalFarmDetails?.userName,
        accessKey: externalFarmDetails?.accessKey,
        dataCenter: externalFarmDetails?.dataCenter,
      });
      dispatch(handleDisableButton(true));
    }
  }, [externalFarmDetails]);

  useEffect(() => {
    setLambdaTestDetails(initialLambdaTestDetails);
  }, [projectId]);
  const validateCredentials = () => {
    dispatch(
      validateExternalFarmCredentials(
        lambdaTestDetails.userName,
        lambdaTestDetails.accessKey,
        lambdaTestDetails.dataCenter,
        DEVICE_EXECUTION_FARM.LAMBDA_TEST,
        setError
      )
    );
  };
  useEffect(() => {
    if (isValidateExternalFarmCredentials) {
      dispatch(handleDisableButton(false));
    } else {
      dispatch(handleDisableButton(true));
    }
  }, [isValidateExternalFarmCredentials]);

  useEffect(() => {
    if (projectLoading) {
      dispatch(handleDisableButton(true));
    }
  }, [projectLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLambdaTestDetails({ ...lambdaTestDetails, [name]: value });
    dispatch(setExternalFarmValidation(false));
    setError(false);
  };

  const handleDataCenterChange = (e) => {
    setLambdaTestDetails({ ...lambdaTestDetails, dataCenter: e });
    setError(false);
  };

  const saveLambdaTestDetail = () => {
    if (lambdaTestDetails && isValidateExternalFarmCredentials) {
      dispatch(
        updateExternalFarmDetail(
          {
            userName: lambdaTestDetails?.userName,
            accessKey: lambdaTestDetails?.accessKey,
            dataCenter: lambdaTestDetails?.dataCenter,
            id: externalFarmDetails ? externalFarmDetails?.id : 0,
            project: { id: parseInt(projectId) },
            farmName: DEVICE_EXECUTION_FARM.LAMBDA_TEST,
          },
          DEVICE_EXECUTION_FARM.LAMBDA_TEST
        )
      );
      dispatch(handleDisableButton(true));
    }
  };

  const resetLambdaTestDetails = () => {
    if (!isEmpty(externalFarmDetails)) {
      setLambdaTestDetails({
        userName: externalFarmDetails.userName,
        accessKey: externalFarmDetails.accessKey,
        dataCenter: externalFarmDetails.dataCenter,
      });
    } else {
      setLambdaTestDetails(initialLambdaTestDetails);
    }
    dispatch(setExternalFarmValidation(false));
    setError(false);
    dispatch(handleDisableButton(true));
  };

  let dataCenterList = [];
  if (!isEmpty(externalFarmDataCenterList)) {
    externalFarmDataCenterList?.map((data) => {
      dataCenterList.push(<Option key={data?.key}>{data?.key}</Option>);
    });
  }

  return (
    <Spin spinning={projectLoading || externalFarmLoading} tip="Loading">
      <div className={styles["header"]}>
        <div className={styles["header_Text"]}>LambdaTest</div>
        <SaveResetButton
          submitLabel={isEmpty(externalFarmDetails) ? "Save" : "Update"}
          resetLabel={"Reset"}
          submitDisable={isDisabled}
          resetDisable={isResetDisableForCloudTesting(lambdaTestDetails, externalFarmDetails)}
          handleSubmit={saveLambdaTestDetail}
          handleReset={resetLambdaTestDetails}
        />
      </div>
      <Row justify="center">
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <Row className={styles["row"]} gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Basic Details</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <span className={styles["label"]}>Username</span>
              <span className={styles["required"]}>*</span>
              <Input
                className={styles["input_Box"]}
                name="userName"
                placeholder="Please Enter Username"
                value={lambdaTestDetails.userName}
                onChange={(e) => handleChange(e)}
                autoComplete="off"
                autoFocus
              />
              <span className={styles["label"]}>Access Key</span>
              <span className={styles["required"]}>*</span>
              <Input
                className={styles["input_Box"]}
                name="accessKey"
                value={lambdaTestDetails.accessKey}
                placeholder="Please Enter Access key"
                onChange={(e) => handleChange(e)}
                autoComplete="off"
              />
              <span className={styles["label"]}>Data Center</span>
              <span className={styles["required"]}>*</span>
              <Select
                className="w-100 h-40 mt-5"
                placeholder="Select Data Center"
                onChange={(e) => handleDataCenterChange(e)}
                value={lambdaTestDetails.dataCenter || undefined}
              >
                {dataCenterList}
              </Select>
              <Button
                type="primary"
                className={styles["validate_Btn"]}
                onClick={() => validateCredentials()}
                disabled={disableValidateCredential(lambdaTestDetails, externalFarmDetails)}
              >
                Validate credentials
              </Button>

              {error && (
                <div className="mt-10">
                  <ErrorComponent errorMessage="Please check credentials are invalid." />
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default LambdaTestV2;
