import React from "react";
import { useDispatch } from "react-redux";
import { Tooltip } from "antd";
import { isEmpty } from "lodash";
import { ACTION_NOT_VIEW, JOB_DESIGNER, TOOLTIP_BG_COLOR, executionModeShortText } from "../../../../../Constants";
import { getPermissionType, getSelectedFrequencyStatus, permissionTypeIcon } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { changeExecutionManagerTabAndSubTab, getTestJobByJobId } from "../../../../../actions/TestJobActions";
import SelectionCheckBoxV2 from "../../../../CommonComponents/SelectionCheckBoxV2/SelectionCheckBoxV2";
import ShowTooltipV2 from "../../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./AllJobsDetailsColumnsV2.module.scss";


const JobInfoColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, showDeviceList, selectAllTestJob, setSelectAllTestJob } = props;

  const handleOnClick = () => {
    dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, false));
    dispatch(getTestJobByJobId({ testJobId: row?.id }));
  };

  return (
    <div className={`${row?.allowDelete === ACTION_NOT_VIEW && styles["table-details"]}  display__flex ml-5`}>
      <SelectionCheckBoxV2 row={row} selectAllData={selectAllTestJob} setSelectAllData={setSelectAllTestJob} />
      <div
        className={`${row?.allowDelete === ACTION_NOT_VIEW && styles["permission_Icons_And_Name"]} ${
          styles["icon-and-details"]
        }`}
      >
        <div className={styles["permission-tooltip"]}>
          <Tooltip title={getPermissionType(row?.isPrivate, row?.testJobRights?.data)} color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={permissionTypeIcon(getPermissionType(row?.isPrivate, row?.testJobRights?.data))} />
            </div>
          </Tooltip>
        </div>
        <div className={styles["build_Jobs_Info_Details"]}>
          <ShowTooltipV2 text={row?.name} className={styles["step-name"]} handleOnClick={() => handleOnClick()} />

          <div>
            <span className={styles["label"]}> Execution Mode: </span>{" "}
            <span className={styles["label-name"]}>{executionModeShortText[row?.executionType]}</span>
          </div>
          <div>
            <span className={styles["label"]}>Execution Farm: </span>
            <span className={styles["label-name"]}>{row?.deviceServiceFarm}</span>
          </div>
          <div>
            <span className={styles["label"]}>Build to be used: </span>
            <Tooltip color={TOOLTIP_BG_COLOR} title={row?.buildToBeUsedTitle === "URL" && row?.buildToBeUsed}>
              <span className={`${styles["label-name"]} ${row?.buildToBeUsedTitle === "URL" && styles["blue_Text"]}`}>
                {row?.buildToBeUsedTitle}
              </span>
            </Tooltip>
          </div>
          <div>
            <span className={styles["label"]}>Devices to run on: </span>
            {!isEmpty(row?.devices) ? (
              showDeviceList(row?.devices,row?.deviceServiceFarm)
            ) : (
              <span className={styles["label-name"]}> Unknown </span>
            )}
          </div>
          {row?.lastRun?.status === "SCHEDULED" && (
            <div>
              <span className={styles["label"]}>Recurring Schedule: </span>
              {getSelectedFrequencyStatus(row?.frequency)}
            </div>
          )}
        </div>
        {row?.currentAndTotalTestDataProfile && (
          <div>
            <span className={styles["label"]}>Current TestData Profile & Total TestData Keys in Profile:</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default JobInfoColumnV2;
