import React from "react";
import _ from "lodash";
import styled from "styled-components";
import { Tabs } from "antd";
import ExecutionDetailsTab from "../ElementDetails/ExecutionDetailsTab";
import ElementsDetail from "./ElementsDetail";
import { isActionNeedToSelectElement } from "../../../../Util";
import { STEP } from "../../../../Constants";
import TestStepVerifyElementPropertiesDetail from "./TestStepVerifyElementPropertiesDetail";

const { TabPane } = Tabs;

export const StyledInnerTabs = styled(Tabs)`
  .ant-tabs-nav {
    width: 100%;
  }

  .ant-tabs-tab {
    width: calc(100% - 8px);
    text-align: center;
  }
`;

const ExecutionInfo = (props) => {
  const {
    outputTestDataName,
    outputTestDataValue,
    testDataName,
    testDataValue,
    testDataCustomValue,
    selectedElementImageUrl,
    selectorType,
    retryCounts,
    retryIndex,
    failurePageSourceLink,
    retrySteps,
    testStepFailDetails,
    testExecutionElementDetail,
    action,
    text,
    isStepHasPreCondition,
    aiGeneratedStep,
    aiGeneratedRecordedStep,
    aiGeneratedRecordedPagesource
  } = props;
  return (
    <>
      <Tabs className={retrySteps ? "tabsRetry" : "ant-tabs-nav"} defaultActiveKey="1">
        {(isActionNeedToSelectElement(action) || (action === STEP && isStepHasPreCondition === 1)) && (
          <TabPane className="ant-tabs-tab" xs={24} sm={12} md={12} lg={12} xl={12} tab="Element Details" key="1">
            <ElementsDetail
              outputTestDataName={outputTestDataName}
              outputTestDataValue={outputTestDataValue}
              testExecutionElementDetail={testExecutionElementDetail}
              elementText={text}
              failurePageSourceLink={failurePageSourceLink}
            />
          </TabPane>
        )}
        <TabPane className="ant-tabs-tab" xs={24} sm={12} md={12} lg={12} xl={12} tab="Other Details" key="3">
          <ExecutionDetailsTab
            testDataName={testDataName}
            testDataValue={testDataValue}
            testDataCustomValue={testDataCustomValue}
            selectedElementImageUrl={selectedElementImageUrl}
            selectorType={selectorType}
            retryCounts={retryCounts}
            retryIndex={retryIndex}
            testStepFailDetails={testStepFailDetails}
            failurePageSourceLink={failurePageSourceLink}
            aiGeneratedStep={aiGeneratedStep}
            aiGeneratedRecordedStep={aiGeneratedRecordedStep}
            aiGeneratedRecordedPagesource={aiGeneratedRecordedPagesource}
            text={text}
          />
        </TabPane>
      </Tabs>
    </>
  );
};
export default ExecutionInfo;
