import { Tabs } from "antd";
import React, { useState } from "react";
import ApplicationStrings from "../TestDataSetManagement/ApplicationStrings";
import DeviceStrings from "../TestDataSetManagement/DeviceStrings";
import { APPLICATION_STRINGS, DEVICE_STRINGS } from "../../Constants";
import { useDispatch } from "react-redux";
import { fetchSystemKeyByProjectId } from "../../actions/TestDataSetAction";
const { TabPane } = Tabs;

const ProjectStrings = (props) => {
  const { projectId } = props;
  const [getActiveKey, setActiveKey] = useState(APPLICATION_STRINGS);
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    setActiveKey(e);
    dispatch(fetchSystemKeyByProjectId());
  };

  return (
    <div className="deviceTab">
      <Tabs defaultActiveKey={APPLICATION_STRINGS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
        <TabPane tab={"Application Strings"} key={APPLICATION_STRINGS}>
          <ApplicationStrings projectId={projectId} />
        </TabPane>
        <TabPane tab={"Device Strings"} key={DEVICE_STRINGS}>
          <DeviceStrings projectId={projectId} />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ProjectStrings;
