import { Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  changeSwipeDirectionOverElement,
  changeSwipePercentageOverElement,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { ELEMENT_ACTION, SWIPE_DIRECTION_TEXT } from "../../../../../Constants";
import { hasNotAllowedNegativeValue } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  ICON_ARROW_DOWN,
  ICON_ARROW_LEFT,
  ICON_ARROW_RIGHT,
  ICON_ARROW_UP,
  SHADOW_GRAY_COLOR,
} from "../../../../../Constants/SvgConstants";
import styles from "./SwipeElement.module.scss";

export default (props) => {
  const { isForView, disabled, data } = props;
  const { recTestStepProperty } = props.data;
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <div
        className={`${styles.swipeElement} ${
          recTestStepProperty?.action === ELEMENT_ACTION.SWIPE && styles.swipeAction
        }`}>
        <div className={styles.swipeColumn}>
          <div>
            <div className={styles.swipeLabel}>
              {recTestStepProperty?.action === ELEMENT_ACTION.SWIPE
                ? "Swipe Direction (from center of page)"
                : "Swipe Over Element"}
            </div>
            <span className={styles.swipeDirectionText}>
              {
                SWIPE_DIRECTION_TEXT[
                  recTestStepProperty?.recSwipeAttributes ? recTestStepProperty?.recSwipeAttributes.direction : ""
                ]
              }
            </span>
          </div>
          <div className={!isForView && !disabled ? "cursor__pointer" : "cursor__not_allowed"}>
            <div
              className={`${styles.swipeUp} ${styles.swipeDirectionIcons} ${
                recTestStepProperty?.recSwipeAttributes &&
                recTestStepProperty?.recSwipeAttributes.direction === "UP" &&
                styles.selectedSwipe
              }`}
              onClick={() =>
                !isForView && !disabled ? dispatch(changeSwipeDirectionOverElement("UP", data.tag)) : undefined
              }>
              <SvgLoader iconName={ICON_ARROW_UP} iconColor={SHADOW_GRAY_COLOR}/>
            </div>
            <div className="display__flex">
              <div
                onClick={() =>
                  !isForView && !disabled ? dispatch(changeSwipeDirectionOverElement("LEFT", data.tag)) : undefined
                }
                className={`${styles.swipeDirectionIcons} ${
                  recTestStepProperty?.recSwipeAttributes &&
                  recTestStepProperty?.recSwipeAttributes.direction === "LEFT" &&
                  styles.selectedSwipe
                }`}>
                <SvgLoader iconName={ICON_ARROW_LEFT} iconColor={SHADOW_GRAY_COLOR}/>
              </div>
              <div
                className={`${styles.swipeRight} ${styles.swipeDirectionIcons}  ${
                  recTestStepProperty?.recSwipeAttributes &&
                  recTestStepProperty?.recSwipeAttributes.direction === "RIGHT" &&
                  styles.selectedSwipe
                }`}
                onClick={() =>
                  !isForView && !disabled ? dispatch(changeSwipeDirectionOverElement("RIGHT", data.tag)) : undefined
                }>
                <SvgLoader iconName={ICON_ARROW_RIGHT} iconColor={SHADOW_GRAY_COLOR}/>
              </div>
            </div>
            <div
              className={`${styles.swipeDown} ${styles.swipeDirectionIcons}  ${
                recTestStepProperty?.recSwipeAttributes &&
                recTestStepProperty?.recSwipeAttributes.direction === "DOWN" &&
                styles.selectedSwipe
              }`}
              onClick={() =>
                !isForView && !disabled ? dispatch(changeSwipeDirectionOverElement("DOWN", data.tag)) : undefined
              }>
              <SvgLoader iconName={ICON_ARROW_DOWN} iconColor={SHADOW_GRAY_COLOR}/>
            </div>
          </div>
        </div>
        <div className={`${styles.swipeColumn} ${styles.swipeLength}`}>
          <div className={styles.swipeLabel}> Swipe Length:</div>
          <div className={styles.swipeLengthInputOrText}>
            <Input
              type="number"
              disabled={isForView || disabled}
              placeholder="Enter Swipe Percentage"
              value={recTestStepProperty?.recSwipeAttributes ? recTestStepProperty?.recSwipeAttributes.percentage : ""}
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              onChange={(e) =>
                !isForView && !disabled
                  ? dispatch(changeSwipePercentageOverElement(e.target.value, data.tag))
                  : undefined
              }
              className={styles.percentageInput}
            />
            <div className={styles.elementSizeText}>
              {`% ${recTestStepProperty?.action === ELEMENT_ACTION.SWIPE ? "of Screen Size" : "of Element Size"}`}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
