export class BuildRegisterServerModal {
  constructor(responseData) {
    //For Get API
    if (responseData) {
      this.id = responseData["id"];
      this.serverName = responseData["serverName"];
      this.serverType = responseData["serverType"] && responseData["serverType"]["id"];
      this.localIp = responseData["localIp"];
      this.isActive = responseData["isActive"] || 0;
      this.serverIp = responseData["serverIp"];
    } else {
      this.id = 0;
      this.serverName = "";
      this.serverType = "";
      this.localIp = "http://127.0.0.1:8080/";
      this.isActive = 1;
      this.serverIp = "http://127.0.0.1:8080/";
    }
  }
}
