import React from "react";
import { useSelector } from "react-redux";
import { IOS } from "../../../Constants";
import { IOS_LOGO } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";

const DeviceLoadingSpinnerV2 = ({ platformType, releaseDevice }) => {
  const { deviceUnloading } = useSelector((state) => state.RecTestStepReducer);

  return (
    <>
      {platformType?.name === IOS ? (
        <div class="ios-device-loader-spinner-V2-wrapper">
          <div class="apple-logo">
            <SvgLoader iconName={IOS_LOGO} height={50} width={50} />
          </div>
          <div class="progress-bar">
            <div class="progress"></div>
          </div>
          <div className="loading-text">
            {deviceUnloading || releaseDevice ? "Releasing Device..." : "Preparing Device..."}
          </div>
        </div>
      ) : (
        <div className="android-device-loader-spinner-V2-wrapper">
          <div className="spinner">
            <div className="dot dot1"></div>
            <div className="dot dot2"></div>
            <div className="dot dot3"></div>
            <div className="dot dot4"></div>
          </div>
          <div className="loading-text">
            {deviceUnloading || releaseDevice ? "Releasing Device..." : "Preparing Device..."}
          </div>
        </div>
      )}
    </>
  );
};

export default DeviceLoadingSpinnerV2;
