import _ from "lodash";
import { RECORD_10_PER_PAGE, SUCCESS_STATUS, UPDATE_TEST_DATA_SET_DATA } from "../../Constants";
import { updateResponse } from "../../Util";
import * as TestDataSetCommonAction from "./TestDataSetCommonAction";
import { fetchTestDataSetByProfileId } from "./TestDataSetRedirectAction";
import { _setTestDataSetProfileForPreview } from "../RecTestStep/RecTestStepCommonAction";
export function _fetchTestDataSetByProfileId(itemId, search = "", pageNumber = 1, showCount = true) {
  return (dispatch) => {
    let data = null;
    data = {
      search: search,
      recTestScenarioId: itemId,
      pageNumber: pageNumber,
      recordPerPage: RECORD_10_PER_PAGE,
      showCount,
    };
    dispatch(TestDataSetCommonAction._getDataProfileByItemId(data));
  };
}

export function _cloneTestDataSetByTestPlanId(testDataSetId, testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    let request = {
      recTestScenarioId: testDataSetAssigneeDetail.id,
      testDataSetId: testDataSetId,
      projectId: projectId,
    };
    new Promise((resolve) => resolve(dispatch(TestDataSetCommonAction.callCloneTestDataSet(request)))).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch(_fetchTestDataSetByProfileId(testDataSetAssigneeDetail.id));
        }
      }
    );
  };
}

export function _changeTestDataCustomValue(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      value
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

export function _changeTestDataIsCustomReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", value);
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}

function _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, key, value) {
  // Update For Scenario Level Test data
  updateTestDataSetForEdit["testDataValues"]["testDataValue"] = TestDataSetCommonAction.updateTestDataValues(
    testDataRow,
    updateTestDataSetForEdit?.testDataValues,
    key,
    value
  );
  // Update For Test Blocks level Test data
  updateTestDataSetForEdit["testDataValues"]["testBlockTestDataSaveReq"] =
    updateTestDataSetForEdit?.testBlockTestDataSaveReq.map((testBlockData) => {
      testBlockData["testDataValues"] = TestDataSetCommonAction.updateTestDataValues(
        testDataRow,
        testBlockData["testDataValues"],
        key,
        value
      );
      return testBlockData;
    });
  return updateTestDataSetForEdit;
}

export function _getTestDataSetById(testDataSetId, testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    let data = { testDataSetId, recTestScenarioId: testDataSetAssigneeDetail?.id };
    dispatch(TestDataSetCommonAction._callTestDataSetById(data));
  };
}

export function _saveTestDataSet(testDataSetAssigneeDetail) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = { ...updateTestDataSetForEdit, recTestScenario: { id: testDataSetAssigneeDetail?.id } };
    new Promise((resolve) =>
      resolve(dispatch(TestDataSetCommonAction._callSaveTestDataSet(updateTestDataSetForEdit)))
    ).then(() => {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    });
  };
}

export function _makeTestDataSetDefault(checked, testDataSet) {
  return async (dispatch, getState) => {
    const { testDataSetAssigneeDetail, testDataSetList } = getState().TestDataSetReducer;
    let data = {};
    if (testDataSet.id === undefined) {
      let alreadySelected = testDataSetList.filter((item) => item.isDefault === 1);
      if (alreadySelected.length > 0) {
        data = {
          recTestScenarioId: testDataSetAssigneeDetail.id,
          testDataSetAssigneeDetail,
          isDefault: 0,
          testDataSetId: alreadySelected[0].id,
        };
      }
    } else {
      data = {
        recTestScenarioId: testDataSetAssigneeDetail.id,
        testDataSetAssigneeDetail,
        isDefault: checked ? 1 : 0,
        testDataSetId: testDataSet.id,
      };
    }

    new Promise((resolve) => resolve(dispatch(TestDataSetCommonAction._callMakeTestDataSetDefault(data)))).then(
      (response) => {
        if (response.status === SUCCESS_STATUS) {
          dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail.id));
          dispatch(_setTestDataSetProfileForPreview(undefined, undefined));
        }
      }
    );
  };
}

export function _resetTestDataCustomValue(testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetForEdit } = getState().TestDataSetReducer;
    let updateTestDataSetForEdit = _.cloneDeep(testDataSetForEdit);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "refTestDataSetAssocId",
      undefined
    );
    updateTestDataSetForEdit = _changeTestDataDetail(updateTestDataSetForEdit, testDataRow, "isCustomChecked", false);
    updateTestDataSetForEdit = _changeTestDataDetail(
      updateTestDataSetForEdit,
      testDataRow,
      "testDataCustomValue",
      undefined
    );
    dispatch({ type: UPDATE_TEST_DATA_SET_DATA, updateTestDataSetForEdit });
  };
}
