import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Col, Row, Select, Space } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { SUCCESS_TYPE } from "../../../Constants";
import { showNotification } from "../../../Util";
import {
  addEmptyLocaleOptionForImport,
  changeRemoveAppStringFromLocale,
  importApplicationStringsFormVisible,
  removeEmptyLocaleOptionForImport,
  selectApplicationStringFileForImport,
  selectApplicationStringLocaleForImport,
  uploadApplicationStringsXml,
} from "../../../actions/ApplicationStrings";
import {
  getListOfLocalesForApplicationStrings
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import UploadBuild from "../../CommonComponents/UploadBuild/UploadBuild";
import { BadgeStyle, Label, PassTag, UploadingResultTag } from "../../CoreComponents/StyledComponent/StyledComponents";
import styles from "./AddApplicationStrings.module.scss";

export const StyledSelect = styled(Select)`
  border: 1px solid #b0b1c1 !important;
  border-radius: 5px;
  .ant-select-selector {
    width: 99% !important;
    border-radius: 5px !important;
  }
`;

const ImportApplicationStrings = (props) => {
  const { projectId } = props;
  const dispatch = useDispatch();

  const { getListOfLocales } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { importApplicationStringsData, removePreviousDataFromLocale } = useSelector(
    (state) => state.ApplicationStringReducer
  );
  const onBackClick = () => {
    dispatch(importApplicationStringsFormVisible(false));
  };
  const [fileList, setFileList] = useState([]);
  const [addNewBuild, setAddNewBuild] = useState(false);
  const [selectStringsIndex, setSelectStringsIndex] = useState([]);

  const addFileIntoList = (file, guid) => {
    dispatch(selectApplicationStringFileForImport(file, guid));
    setFileList((prev) => {
      return {
        ...prev,
        [guid]: file,
      };
    });
  };

  useEffect(() => {
    if (!_.isEmpty(importApplicationStringsData) && importApplicationStringsData.every((i) => i?.status === "done")) {
      const intervalId = setInterval(() => {
        onBackClick();
        showNotification(SUCCESS_TYPE, "Strings have been uploaded successfully", true);
      }, 500);
      return () => clearInterval(intervalId);
    }
  }, [importApplicationStringsData]);

  useEffect(() => {
    Object.keys(fileList).forEach((key) => {
      if (importApplicationStringsData.filter((i) => i.guid === key).length === 0) {
        delete fileList[key];
      }
    });
    importApplicationStringsData.forEach((item) => {
      if (fileList[item?.guid]) {
        fileList[item?.guid][0].status = item?.status;
      }
    });
  }, [importApplicationStringsData, fileList]);

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
  }, []);

  const uploadApplicationStringLoader =
    importApplicationStringsData.filter((i) => i?.status === "uploading").length > 0;
  const uploadIsDone =
    importApplicationStringsData.filter((i) => i?.status === "done").length === importApplicationStringsData.length;

  return (
    // <Spin spinning={uploadApplicationStringLoader} tip="Loading">
    <>
      <Row justify="space-between">
        <div className={styles.applicationText}>
          <b>Import Application Strings</b>
        </div>
        <Space>
          <Button disabled={uploadApplicationStringLoader} onClick={() => onBackClick()}>
            {uploadIsDone ? "Close" : "Cancel"}
          </Button>
          <Button
            disabled={
              uploadIsDone ||
              uploadApplicationStringLoader ||
              importApplicationStringsData.some((i) => i?.appFile === undefined)
            }
            onClick={() => dispatch(uploadApplicationStringsXml())}
          >
            Upload
          </Button>
        </Space>
      </Row>

      <Row className={styles.content} justify="center">
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Label>
                <span className="mr-5">
                  <Checkbox
                    checked={removePreviousDataFromLocale === 1}
                    onChange={(e) => dispatch(changeRemoveAppStringFromLocale(e.target.checked === true ? 1 : 0))}
                  />
                </span>
                Remove Previous Value for Locale
              </Label>
            </Col>
          </Row>
          <div className="add_Application_String_Scroll">
            <Row>
              <Col span={8}>
                <span>Locale</span>
              </Col>
              <Col span={12}>
                <span>XMl File</span>
              </Col>
              <Col span={4}>
                <span>Action</span>
              </Col>
            </Row>
            {importApplicationStringsData?.map((row, index) => {
              return (
                <Row>
                  <Col span={8}>
                    <Select
                      showSearch={true}
                      className="selectApplicationLocale"
                      value={row?.locale}
                      placeholder="Select Application String Locale"
                      onChange={(e) => {
                        dispatch(selectApplicationStringLocaleForImport(e, row?.guid));
                      }}
                    >
                      {getListOfLocales &&
                        getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)}
                    </Select>
                  </Col>
                  <Col span={12}>
                    <UploadBuild
                      addNewBuild={addNewBuild}
                      setAddNewBuild={setAddNewBuild}
                      existingBuildName={
                        fileList[row?.guid] && fileList[row?.guid].length > 0 ? fileList[row?.guid][0].name : undefined
                      }
                      setFileList={(file) => {
                        addFileIntoList(file, row?.guid);
                      }}
                      fileList={fileList[row?.guid]}
                      placeholder={"Upload Xml File"}
                      tooltip="Upload Xml File"
                      acceptFileTypes=".xml"
                      setSelectStringsIndex={setSelectStringsIndex}
                      index={index}
                      selectStringsIndex={selectStringsIndex}
                    />
                  </Col>
                  <Col span={4}>
                    {row.status === undefined && (
                      <Button className="m-5" onClick={() => dispatch(removeEmptyLocaleOptionForImport(row?.guid))}>
                        Remove
                      </Button>
                    )}
                    {row.status && (
                      <>
                        {row.status === "done" && (
                          <PassTag>
                            <BadgeStyle color="#297042" />
                            Uploaded
                          </PassTag>
                        )}
                        {row.status === "uploading" && (
                          <UploadingResultTag>
                            <BadgeStyle />
                            Uploading..
                          </UploadingResultTag>
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              );
            })}
          </div>
          <div className="justify-center">
            <Button
              disabled={uploadApplicationStringLoader}
              onClick={() => dispatch(addEmptyLocaleOptionForImport(projectId))}
            >
              Add Another Locale
            </Button>
          </div>
        </Col>
      </Row>
    </>
    // </Spin>
  );
};

export default ImportApplicationStrings;
