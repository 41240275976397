import React, { useEffect, useState } from "react";
import _ from "lodash";
import { SEARCH_ICON } from "../../../CdnImagePath";
import { hasBlankSpaces } from "../../../Util";
import { StyledSearch } from "../StyledComponent/StyledComponents";

const CommonSearch = (props) => {
  const { setSearchKeyword, placeHolder, searchDetails, importSteps,inputRef } = props;
  const [isError, setIsError] = useState(false);

  const debounce = _.debounce((e) => {
    const searchWord = e.target.value;
    setSearchKeyword(e.target.value);
    if (hasBlankSpaces(searchWord)) {
      setIsError(true);
    } else {
      setIsError(false);
      searchDetails(searchWord);
    }
  }, 900);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 1000);
    }
  }, [inputRef]);

  return (
    <StyledSearch
      ref={inputRef}
      className={!importSteps && "ant-input-lg width-250"}
      error={isError || undefined}
      onChange={debounce}
      placeholder={placeHolder}
      allowClear
      prefix={<img src={SEARCH_ICON} alt="search" className="mr-5" />}
      autoFocus
    />
  );
};

export default CommonSearch;
