import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { Row, Col, DatePicker, Input, Select, Spin, Checkbox, TimePicker, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  ALL_BUILDS,
  ALPHA,
  ANDROID,
  AWS,
  CONFIRM_MODAL_CONTENT,
  DEV,
  INITIAL_APP_BUILD_DATA,
  IOS,
  OTHER,
  PROD,
  RC_BETA,
  allowedAppActivtyForFarm,
} from "../../../Constants";
import styled from "styled-components";
import {
  handleSaveBuild,
  isNotNullAndNotEmptyAndNotUndefined,
  isNotNullBuildDetails,
  isNullOrUndefined,
} from "../../../Util";
import UploadBuild from "../../CommonComponents/UploadBuild/UploadBuild";
import { useDispatch, useSelector } from "react-redux";
import { changeAppManagerTab, editAppBuild } from "../../../actions/Recording";
import SaveResetButton from "../../CoreComponents/SaveResetComponent/SaveResetButton";
import BackButton from "../../CoreComponents/BackButtonComponent/BackButton";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./AddBuild.module.scss";

const { Option } = Select;
const { TextArea } = Input;

library.add(faCloudUploadAlt);

const Label = styled.div`
  padding-bottom: 5px;
  padding-top: 10px;
  font-size: 14px;
`;

const AddAppBuild = (props) => {
  const { buildId, devicePushBuild, setSaveBuild, saveBuild, appBuildData, setAppBuildData } = props;
  const dispatch = useDispatch();
  const { platform } = useSelector((state) => state.ProjectsReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);
  const { pushBuildModalVisible } = useSelector((state) => state.RecordingReducer);
  const {
    appBuildDetailsById,
    getUploadBuildOptions,
    sharedWithFarmInAddAppBuild,
    buildUploadRequest,
    overwriteBuildSaveRequest,
    getAWSProjectsList,
  } = useSelector((state) => state.RecordingReducer);
  const [fileList, setFileList] = useState("");
  const [addNewBuild, setAddNewBuild] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  useEffect(() => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      setAppBuildData(appBuildDetailsById);
    } else {
      setAddNewBuild(true);
      setAppBuildData(INITIAL_APP_BUILD_DATA);
    }
  }, [appBuildDetailsById]);

  let cloudList = [];
  if (getUploadBuildOptions) {
    for (let i = 0; i < getUploadBuildOptions.length; i++) {
      cloudList.push(<Option key={getUploadBuildOptions[i]}>{getUploadBuildOptions[i]}</Option>);
    }
  }
  let projectsList = [];
  if (getAWSProjectsList) {
    for (let i = 0; i < getAWSProjectsList.length; i++) {
      projectsList.push(
        <Option key={getAWSProjectsList[i].id} value={getAWSProjectsList[i].id}>
          {getAWSProjectsList[i].awsProjectName}
        </Option>
      );
    }
  }

  const selectedFarms = [];

  if (deviceFarmList && deviceFarmList.length > 0) {
    deviceFarmList.map((v, k) => {
      return selectedFarms.push(v.id);
    });
  }

  const handleChangeBuildForm = (e, filedName) => {
    let _appBuildData = { ...appBuildData };
    if (filedName) {
      _appBuildData[filedName] = e;
    } else {
      const { name, value } = e.target;
      _appBuildData[name] = value;
    }
    setAppBuildData(_appBuildData);
  };
  const setInputTagsAndAppFile = (uploadBuildFile, uploadBuildFileName) => {
    let addFileNameInTag = "";
    let nameToAddInBuildTag = uploadBuildFileName;
    if (appBuildData?.tag === "") {
      addFileNameInTag = nameToAddInBuildTag + ",";
    } else if (appBuildData?.tag !== "") {
      if (appBuildData?.tag.includes(appBuildData?.buildName)) {
        addFileNameInTag = appBuildData?.tag.replace(appBuildData?.buildName, nameToAddInBuildTag);
      }
    } else {
      addFileNameInTag = nameToAddInBuildTag + "," + appBuildData?.tag;
    }

    setAppBuildData({
      ...appBuildData,
      tag: addFileNameInTag,
      buildName: uploadBuildFileName,
      appFile: uploadBuildFile,
    });
  };

  const resetBuildData = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      setAppBuildData(appBuildDetailsById);
      dispatch(editAppBuild(false));
      setAddNewBuild(false);
    } else {
      setAppBuildData(INITIAL_APP_BUILD_DATA);
      setFileList([]);
    }
  };
  const disableResetButton = useCallback(() => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return _.isEqual(appBuildData, appBuildDetailsById);
    } else {
      return _.isEqual(appBuildData, INITIAL_APP_BUILD_DATA);
    }
  }, [appBuildData, appBuildDetailsById]);

  useEffect(() => {
    if (disableResetButton()) {
      dispatch(editAppBuild(false));
    } else {
      dispatch(editAppBuild(true));
    }
  }, [disableResetButton, dispatch]);

  const disableUploadButton = () => {
    if (isNotNullAndNotEmptyAndNotUndefined(appBuildDetailsById)) {
      return _.isEqual(appBuildData, appBuildDetailsById) || isNotNullBuildDetails(appBuildData);
    } else {
      return isNotNullBuildDetails(appBuildData);
    }
  };

  const onBackClick = () => {
    if (disableResetButton()) {
      dispatch(changeAppManagerTab(ALL_BUILDS));
    } else {
      setOpenConfirmModal(true);
    }
  };

  return (
    <>
      <Spin
        spinning={
          !pushBuildModalVisible &&
          (buildUploadRequest || (!isNullOrUndefined(overwriteBuildSaveRequest) && overwriteBuildSaveRequest))
        }
        tip="Loading"
        className="spinner-center"
      >
        {!devicePushBuild && (
          <Row justify="space-between" align="middle" className="fixInnerHeader">
            <Col>
              <div className="mb-0 mainHeaderText ml-10">Build Uploader</div>
            </Col>
            <Col>
              <Space>
                <SaveResetButton
                  submitLabel={"Save"}
                  resetLabel={"Reset"}
                  submitDisable={disableUploadButton()}
                  resetDisable={disableResetButton()}
                  handleSubmit={() =>
                    handleSaveBuild(
                      selectedFarms,
                      sharedWithFarmInAddAppBuild,
                      selectedFarms,
                      buildId,
                      appBuildData,
                      platform,
                      dispatch
                    )
                  }
                  handleReset={resetBuildData}
                />
                <div className="closeIcon">
                  <BackButton handleBack={onBackClick} isBlack={true} />
                </div>
              </Space>
            </Col>
          </Row>
        )}

        <Row justify={!devicePushBuild && "center"} className={devicePushBuild ? styles.scrollbar : "pageScroll"}>
          <Col xs={!devicePushBuild && { span: 24 }} lg={!devicePushBuild && { span: 14 }}>
            <Row className={!devicePushBuild && "mt-30 mb-30"} gutter={50}>
              {!devicePushBuild && (
                <Col xs={!devicePushBuild && { span: 24, offset: 0 }} lg={!devicePushBuild && { span: 6, offset: 1 }}>
                  <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Basic Details</h3>
                </Col>
              )}
              <Col xs={!devicePushBuild && { span: 24 }} lg={!devicePushBuild && { span: 17 }} className="text">
                <div className={devicePushBuild && styles.nameOrVersion}>
                  <div className={devicePushBuild && styles.width}>
                    <Label>Display Name</Label>
                    <div>
                      <Input
                        className="border-radius-4"
                        placeholder="Please Enter Display Name"
                        name="displayName"
                        value={appBuildData?.displayName}
                        onChange={(e) => {
                          handleChangeBuildForm(e, "");
                        }}
                        autocomplete="off"
                        autoFocus
                      />
                    </div>
                  </div>
                  <div className={devicePushBuild && styles.width}>
                    <Label>
                      Build Version<span className="text-danger">*</span>
                    </Label>
                    <div>
                      <Input
                        className="border-radius-4"
                        placeholder="Please Enter Build Version"
                        name="buildVersion"
                        value={appBuildData?.buildVersion}
                        onChange={(e) => {
                          handleChangeBuildForm(e, "");
                        }}
                        autocomplete="off"
                      />
                    </div>
                  </div>
                </div>
                <Label>Tags</Label>
                <div>
                  <TextArea
                    className="w-100"
                    rows={4}
                    name="tag"
                    placeholder="Provide Your Own/Team Tags For Easy Identification Separated With (,)"
                    value={appBuildData?.tag}
                    onChange={(e) => {
                      handleChangeBuildForm(e);
                    }}
                  />
                </div>

                <div>
                  <Row className={devicePushBuild && styles.nameOrVersion}>
                    <Col span={!devicePushBuild && 13} className={devicePushBuild ? styles.width : "mr-10"}>
                      <Label>
                        Build Date<span className="text-danger">*</span>
                      </Label>
                      <DatePicker
                        className="w-100"
                        placeholder="Please Select Build Date"
                        disabledDate={(current) => {
                          return current > new Date();
                        }}
                        onChange={(e) => {
                          handleChangeBuildForm(e, "buildDate");
                        }}
                        value={appBuildData?.buildDate || null}
                      />
                    </Col>
                    <Col span={devicePushBuild ? 11 : 10} className={devicePushBuild ? styles.width : "mr-10"}>
                      <Label>
                        Build Time<span className="text-danger">*</span>
                      </Label>
                      <TimePicker
                        className="w-100"
                        placeholder="Please Select Build Time"
                        onChange={(e) => {
                          handleChangeBuildForm(e, "buildTime");
                        }}
                        value={appBuildData?.buildTime || null}
                      />
                    </Col>
                  </Row>
                </div>
                <div className={devicePushBuild && styles.nameOrVersion}>
                  <div className={devicePushBuild && styles.width}>
                    <Label>
                      Build Type<span className="text-danger">*</span>
                    </Label>
                    <div>
                      <Select
                        className={devicePushBuild ? "setAppLocalValue" : "w-100 h-40"}
                        placeholder="Please Select Build Type"
                        onChange={(e) => {
                          handleChangeBuildForm(e, "buildType");
                        }}
                        value={appBuildData?.buildType || undefined}
                      >
                        <Option key={DEV}>{DEV}</Option>
                        <Option key={ALPHA}>{ALPHA}</Option>
                        <Option key={RC_BETA}>{RC_BETA}</Option>
                        <Option key={PROD}>{PROD}</Option>
                        <Option key={OTHER}>{OTHER}</Option>
                      </Select>
                    </div>
                  </div>
                  <div className={devicePushBuild ? styles.width : "w-100"}>
                    <UploadBuild
                      addNewBuild={addNewBuild}
                      setAddNewBuild={setAddNewBuild}
                      existingBuildName={appBuildData?.existingBuild && appBuildData?.existingBuild?.name}
                      setFileList={setFileList}
                      fileList={fileList}
                      placeholder={
                        platform === ANDROID
                          ? "Select APK For Upload"
                          : platform === IOS
                          ? "Select IPA For Upload"
                          : "Add Build"
                      }
                      tooltip="Pick Up Old APK"
                      labelName="Upload"
                      setUploadBuildFile={setInputTagsAndAppFile}
                      acceptFileTypes={platform === ANDROID ? ".apk" : ".ipa"}
                      devicePushBuild={devicePushBuild}
                    />
                  </div>
                </div>

                <div className="mt-10">
                  <div className="app-build-upload-note">
                    <FontAwesomeIcon className="mr-5" icon={faInfoCircle}></FontAwesomeIcon>
                    Upload Application Builds to Xpress App Manager. You or your Team may then install the build on
                    devices directly from the App Server. (using 'Install App' option on your Device).
                  </div>
                </div>

                <Label>
                  <span className={styles.checkBox}>
                    <Checkbox
                      checked={appBuildData?.isPushToCloud}
                      onChange={(e) => {
                        let _appBuildData = { ...appBuildData };
                        _appBuildData["isPushToCloud"] = e.target.checked === true ? 1 : 0;
                        handleChangeBuildForm(e.target.checked === true ? 1 : 0, "isPushToCloud");
                        if (!e.target.checked) {
                          _appBuildData["uploadToCloud"] = "";
                          _appBuildData["awsDeviceFarmProject"] = "";
                          _appBuildData["appActivity"] = "";
                        }
                        setAppBuildData(_appBuildData);
                      }}
                    />
                  </span>
                  Push To Cloud Device Farm
                </Label>

                {appBuildData?.isPushToCloud === 1 && (
                  <>
                    <Label className="mr-10 font-size-14">Service:</Label>
                    <Select
                      className="w-100 h-40"
                      placeholder={"Select Option"}
                      value={appBuildData?.uploadToCloud || undefined}
                      onChange={(e) => {
                        handleChangeBuildForm(e, "awsDeviceFarmProject");
                        handleChangeBuildForm(e, "uploadToCloud");
                      }}
                    >
                      {cloudList}
                    </Select>
                    {appBuildData?.uploadToCloud === AWS && (
                      <>
                        <Label>
                          Aws Device Farm Project<span className="text-danger">*</span>
                        </Label>
                        <div>
                          <Select
                            className="w-100 h-40"
                            placeholder={"Select Project"}
                            value={appBuildData?.awsDeviceFarmProject || undefined}
                            onChange={(e) => {
                              handleChangeBuildForm(e, "awsDeviceFarmProject");
                            }}
                          >
                            {projectsList}
                          </Select>
                        </div>
                      </>
                    )}
                    {allowedAppActivtyForFarm.includes(appBuildData?.uploadToCloud) && platform === ANDROID ? (
                      <div className={devicePushBuild && styles.width}>
                        <Label>
                          App Activity<span className="text-danger">*</span>
                        </Label>
                        <div>
                          <Input
                            className="border-radius-4"
                            placeholder="Please Enter App Activity"
                            name="appActivity"
                            value={appBuildData?.appActivity}
                            onChange={(e) => {
                              handleChangeBuildForm(e, "");
                            }}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </Col>
            </Row>
            {devicePushBuild && (
              <Label>
                <span className={styles.checkBox}>
                  <Checkbox
                    checked={saveBuild}
                    onChange={(e) => (e.target.checked ? setSaveBuild(true) : setSaveBuild(false))}
                  />
                </span>
                Save build to Build Manage
              </Label>
            )}
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeAppManagerTab(ALL_BUILDS));
          dispatch(editAppBuild(false));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default AddAppBuild;
