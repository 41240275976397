import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hideDeleteRecElementConfirmationModal,
  removeElementIntoStep,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const RecElementDeleteModalV2 = () => {
  const dispatch = useDispatch();
  const {
    deleteRecElementModalVisible,
    selectedRecElementForDelete,
    selectedRecTestStepTagForDelete,
    selectedRecTestStepIdForDelete,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  return (
    <>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            removeElementIntoStep(
              selectedRecTestStepIdForDelete,
              selectedRecTestStepTagForDelete,
              selectedRecElementForDelete
            )
          );
        }}
        handleCancel={() => dispatch(hideDeleteRecElementConfirmationModal())}
        modalOpen={deleteRecElementModalVisible}
        modalTitle={"Confirm Deletion"}
        modalContent={"Are you sure you want to delete this element?"}
        okText={"Delete"}
        isDeleteModal={true}
      />
    </>
  );
};

export default RecElementDeleteModalV2;
