import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";

export const CommonCol = styled(Row)`
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  font-weight: 500;
  text-align: left;
  width: 100%;
`;

export const DataCol = styled(Col)`
  word-wrap: break-word;
  text-align: left;
`;

export const StyledCol = styled(Row)`
  height: "900px";
  border: 1px solid #e8e8e8;
  border-top: 0px !important;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  width: 100%;
`;

const ErrorInvestigationDetailsTab = (props) => {
  const { errorInvestigationDetails } = props;
  return (
    <Row className="w-100">
      <CommonCol className="w-100">
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Items
        </DataCol>
        <DataCol sm={12} md={12} lg={12} xl={8}>
          Values
        </DataCol>
      </CommonCol>
      <Row className="w-100">
        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Error Description
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.errorDescription ? errorInvestigationDetails?.errorDescription : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Error Founded on
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.errorFoundOn ? errorInvestigationDetails?.errorFoundOn : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Failure Reason
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.failureReason ? errorInvestigationDetails?.failureReason : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Error InvestigateOn
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.errorInvestigatedOn ? errorInvestigationDetails?.errorInvestigatedOn : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Product BugStatus
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.productBug?.bugStatus
              ? errorInvestigationDetails?.productBug?.bugStatus
              : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Product BugURL
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.productBug?.bugUrl ? errorInvestigationDetails?.productBug?.bugUrl : "N/A"}
          </DataCol>
        </StyledCol>

        <StyledCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Product BugId
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            {errorInvestigationDetails?.productBug?.id ? errorInvestigationDetails?.productBug?.id : "N/A"}
          </DataCol>
        </StyledCol>
      </Row>
    </Row>
  );
};

export default ErrorInvestigationDetailsTab;
