import { Col, Row } from "antd";
import React from "react";
import styled from "styled-components";
import _ from "lodash";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import { ANDROID, IOS, elementDetailDisplayText } from "../../../../Constants";

export const CommonCol = styled(Row)`
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
  font-weight: 500;
  text-align: left;
`;

export const DataCol = styled(Col)`
  word-wrap: break-word;
  text-align: left;
`;

export const StyledCol = styled(Row)`
  height: "900px";
  border: 1px solid #e8e8e8;
  border-top: 0px !important;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;

export const StyledDiv = styled.div`
  border: 1px solid #e8e8e8;
  padding: 5px 10px;
  font-size: 12px;
  color: black;
`;

const ExpectedElement = (props) => {
  const { testExecutionElementDetail, devicePlatform } = props;
  let properties = {};
  for (let i = 0; i < testExecutionElementDetail?.testStepRecordedElementProperties.length; i++) {
    properties[testExecutionElementDetail?.testStepRecordedElementProperties[i]?.key] =
      testExecutionElementDetail?.testStepRecordedElementProperties[i]?.value;
  }
  return (
    <div>
      <StyledDiv span={24} className="font-weight-600">
        Expected Element Properties
      </StyledDiv>
      <>
        <CommonCol span={24}>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Items
          </DataCol>
          <DataCol sm={12} md={12} lg={12} xl={8}>
            Values
          </DataCol>
        </CommonCol>
        {devicePlatform?.toUpperCase() === IOS && (
          <>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Type"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["type"] ? properties["type"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Name"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["name"] ? properties["name"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Label"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["label"] ? properties["label"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Value"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["value"] ? properties["value"] : "NA"}
              </DataCol>
            </StyledCol>
          </>
        )}
        {devicePlatform?.toUpperCase() === ANDROID && (
          <>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Class"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["class"] ? properties["class"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"resource-id"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["resource-id"] ? properties["resource-id"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Text"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["text"] ? properties["text"] : "NA"}
              </DataCol>
            </StyledCol>
            <StyledCol span={24}>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {"Content-Desc"}
              </DataCol>
              <DataCol sm={12} md={12} lg={12} xl={8}>
                {properties["content-desc"] ? properties["content-desc"] : "NA"}
              </DataCol>
            </StyledCol>
          </>
        )}
      </>
    </div>
  );
};

export default ExpectedElement;
