import { Col, Row } from "antd";
import { default as React } from "react";
import { getDeviceName, showPlatformIcon } from "../../../Util";

const TestPlanResultDeviceDetails = (props) => {
  const { deviceResultDetails, selectedDeviceDetail, deviceServiceFarm } = props;
  return (
    <div className="mt-5 mb-10">
      <Row>
        {showPlatformIcon(deviceResultDetails?.platformName || deviceResultDetails?.platform)}
        <span className="pl-10 text-light1 font-size-14 font-weight-500">
          {!selectedDeviceDetail && (deviceResultDetails?.brandName || deviceResultDetails?.deviceBrandName)}
        </span>
      </Row>
      <Row gutter={[4, 4]} className="font-size-12 font-weight-500 pl-10 mt-5">
        <Row className=" w-100">
          <Col span={12}>Device Name: {getDeviceName(deviceResultDetails || "N/A", deviceServiceFarm)}</Col>

          <Col span={12}>Device Id: {deviceResultDetails?.targetUniqueId || "N/A"}</Col>
        </Row>
        <Row className=" w-100">
          <Col span={12}>OS Version: {deviceResultDetails?.osVer || "N/A"}</Col>
          <Col span={12}>
            Locale:{" "}
            <span className="font-weight-500">{deviceResultDetails?.locale?.replaceAll(",", ",  ") || "Unknown"}</span>
          </Col>
        </Row>
      </Row>
      {deviceResultDetails?.devicesLength > 1 &&
        deviceResultDetails?.currentIndex !== deviceResultDetails?.devicesLength - 1 && (
          <div className="border-bottom-1"></div>
        )}
    </div>
  );
};

export default TestPlanResultDeviceDetails;
