import React, { useState } from "react";
import _ from "lodash";
import { Drawer, Spin, Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import EmptyRecords from "../../../../CommonComponents/EmptyRecords/EmptyRecords";
import RenderDeviceAttributes from "../DeviceSource/RenderDeviceAttributes/RenderDeviceAttributes";
import RenderDeviceSource from "../DeviceSource/RenderDeviceSource/RenderDeviceSource";
import { CLOSE_ICON_GRAY, SEARCH_ICON } from "../../../../../CdnImagePath";
import { changeAdvanceElementSelectorDrawerVisibility } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import VerticalLine from "../../../../CommonComponents/VerticalLine";
import styles from "./AdvanceSelectorAllElementDrawer.module.scss";

const AdvanceSelectorAllElementDrawer = (props) => {
  const { currentDeviceScreenSource, findElementProcessing } = useSelector((state) => state.RecordingReducer);
  const { selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);
  const { isAdvanceElementSelectorDrawerVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const [attributes, setAttributes] = useState({});
  const [path, setPath] = useState({});

  const { projectId, searchElements, setSearchElements, recElement } = props;

  return (
    <Drawer
      closable={false}
      placement="right"
      width={!_.isEmpty(attributes) ? "850" : "530"}
      visible={isAdvanceElementSelectorDrawerVisible}
      className={` advancedSelectorDrawer ${!_.isEmpty(attributes) && "deviceElement"}`}
    >
      <Spin spinning={findElementProcessing}>
        <Row>
          <Col span={9} className={`${styles.borderShadow} ${_.isEmpty(attributes) && "display__none"}`}>
            <RenderDeviceAttributes attributes={attributes} platform={selectedDeviceByUdid?.platformType?.name} />
            {_.isEmpty(attributes) && <EmptyRecords description="Element Not Selected" />}
          </Col>
          <Col span={!_.isEmpty(attributes) ? 15 : 24}>
            <div className={styles.drawerTitleOrCloseIcon}>
              <span className={styles.drawerTitle}>Element Inspector and Selector</span>
              <span>
                <img
                  src={CLOSE_ICON_GRAY}
                  alt="close"
                  className="cursor-pointer"
                  onClick={() => dispatch(changeAdvanceElementSelectorDrawerVisibility(false))}
                />
              </span>
            </div>

            <div className={styles.searchBoxOrBorder}>
              <div className={styles.searchTitle}>Select an Element from the List</div>
              <div className="mt-5">
                <Input
                  placeholder="Search element.."
                  className="searchElementBox"
                  value={searchElements}
                  onChange={(e) => setSearchElements(e.target.value)}
                  prefix={<img src={SEARCH_ICON} alt="search" className="mr-5" />}
                />
              </div>
            </div>
            <VerticalLine className={styles.border} />
            <div className={styles.selectorData}>
              <RenderDeviceSource
                child={currentDeviceScreenSource?.children}
                setAttributes={setAttributes}
                path={path}
                setPath={setPath}
                targetUniqueId={selectedDeviceByUdid?.targetUniqueId}
                serverId={selectedDeviceByUdid?.autoServerDetail?.id}
                currentDeviceScreenSource={currentDeviceScreenSource}
                x={0}
                y={0}
                projectId={projectId}
                searchElements={searchElements}
                recElement={recElement}
                attributes={attributes}
              />
              {_.isEmpty(currentDeviceScreenSource) && <EmptyRecords description={"No Elements Found"} />}
            </div>
          </Col>
        </Row>
      </Spin>
    </Drawer>
  );
};

export default AdvanceSelectorAllElementDrawer;
