import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Input } from "antd";
import { hasNotAllowedNegativeValue, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import {
  changeElementLoadTime,
  changeSwipeToBringElementToCenter,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import CustomButtonV2 from "../../../CommonComponents/CustomButtonV2/CustomButtonV2";
import styles from "./RecElementSettingsV2.module.scss";

const RecElementSettingsV2 = (props) => {
  const dispatch = useDispatch();
  const { data, recElement, disabled, isSkipStep } = props;

  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);

  //Destructure of recElement object
  const { guid, elementMaxLoadTime, swipeToBringElementToCenter } = recElement ? recElement : {};

  const disableSetDefault = useMemo(
    () =>
      disabled ||
      isSkipStep ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid),
    [disabled, guid, unSavedRecElementGuid, isSkipStep]
  );

  return (
    <div className={styles["element-Settings"]}>
      <div className={styles["settings-title"]}>Settings</div>
      <div className={styles["settings-details"]}>
        <div className={styles["label"]}>Max Wait time to find Element (secs)</div>
        <div className={styles["input-and-default-button"]}>
          <div className={styles["input-box"]}>
            <Input
              disabled={
                disabled ||
                isPreviewRunning ||
                isSkipStep ||
                (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
              }
              value={elementMaxLoadTime}
              type="number"
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              onChange={(e) => dispatch(changeElementLoadTime(e.target.value, data.tag, guid))}
              placeholder="Default Element Load Time"
              className={styles["input-settings"]}
            />
          </div>
          {elementMaxLoadTime !== 4 && (
            <CustomButtonV2
              buttonLabel="Set Default"
              handleClick={() => !disableSetDefault && dispatch(changeElementLoadTime(4, data.tag, guid))}
              disabled={disableSetDefault}
              className={`common_Purple_Btn ${styles["default-Button"]}`}
            />
          )}
        </div>
        <div className={styles["checkbox-details"]}>
          <Checkbox
            disabled={
              isPreviewRunning ||
              disabled ||
              isSkipStep ||
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid)
            }
            checked={swipeToBringElementToCenter === 1}
            onChange={(e) =>
              !disabled ? dispatch(changeSwipeToBringElementToCenter(e.target.checked, data.tag, guid)) : undefined
            }
            className={`${styles["checkbox"]} selection-checkBox`}
          />
          <span className={styles["check-Box-Text"]}>
            Attempt to bring Element into full view (to center of screen)
          </span>
        </div>
      </div>
    </div>
  );
};

export default RecElementSettingsV2;
