import { default as React, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { faYandexInternational } from "@fortawesome/free-brands-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Collapse, Row, Tooltip } from "antd";
import { TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  CONDITION_DELETE_IMAGE,
  ICON_AI,
  ICON_PERFORM_SAVE,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
  RESET_ICON,
  SAVE_ICON,
} from "../../../../Constants/SvgConstants";
import {
  getRecTestStepStatusWithImage,
  isCurrentRecTestStepDisable,
  isNotNullAndNotEmptyAndNotUndefined,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  showUnSavedNotification,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  showDeleteLoopConditionModalVisible,
  showDeleteRecTestStepPreConditionConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepLoopConditionV2 from "./RecTestStepLoopConditionV2";
import RecTestStepPreConditionV2 from "./RecTestStepPreConditionV2";
import PerformRecTestStepActionV2 from "../PerformRecTestStepActionV2/PerformRecTestStepActionV2";
import styles from "./RecTestStepConditionV2.module.scss";

const { Panel } = Collapse;

export const getConditionalIcon = (recTestStep) => {
  if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faYandexInternational} />
      </span>
    );
  } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faReply} />
      </span>
    );
  }
};
const getHeaderText = (recTestStep, testBlockStep) => {
  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          When to Run this Block
        </span>
      );
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Block till
        </span>
      );
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          When to Run this Step
        </span>
      );
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Steps till
        </span>
      );
    }
  }
};

const getTooltipText = (recTestStep, testBlockStep) => {
  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Block</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Block till Condition</span>;
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Step</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Till Condition</span>;
    }
  }
};

const RecTestStepConditionV2 = (props) => {
  const {
    recTestStep,
    disableAction,
    isSkipStep,
    isDeviceLoaded,
    isTestBlock,
    stepType,
    recTestSteps,
    setDragOverOnCollapse,
    projectId,
  } = props;
  const dispatch = useDispatch();
  const { isPreviewRunning, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  
  //Destructure of recTestStep object
  const { isTestBlock: testBlockInRecTestStep, preconditionResult } = recTestStep;
  const { isStepHasPrecondition, isLoopStep } =
    recTestStep && recTestStep.recTestStepProperty ? recTestStep.recTestStepProperty : {};

  let disabled = useMemo(
    () =>
      isCurrentRecTestStepDisable(unSavedRecStepTag, recTestStep, disableAction) ||
      isPreviewRunning ||
      (stepType === TEST_SCENARIOS && isTestBlock),
    [unSavedRecStepTag, recTestStep, disableAction, isPreviewRunning, stepType, isTestBlock]
  );

  let disabledDeleteIcon = useMemo(
    () =>
      disabled ||
      (stepType === TEST_SCENARIOS &&
        testBlockInRecTestStep === 1 &&
        (isStepHasPrecondition === 1 || isLoopStep === 1)),
    [disabled, stepType, isStepHasPrecondition, isLoopStep, testBlockInRecTestStep]
  );
  let isUnsaved = useMemo(
    () => showUnSavedNotification(recTestStep, unSavedRecStepTag),
    [recTestStep, unSavedRecStepTag]
  );

  //className of action collapse
  let recTestStepCollapseClassNames = `rec_Test_Step_Collapse ${
    !isSkipStep && "recTestStepCollapseBorder"
  } collapseStepOrderBorder stepCollapseBorder recTestStepSteps `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  }
  let isConditionStepOpen =
    (isStepHavePrecondition(recTestStep) || isStepHaveLoopStep(recTestStep)) &&
    !recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.[0]?.id;

  return isStepHasPrecondition === 1 || isLoopStep === 1 ? (
    <div
      onDragOver={(event) => {
        event.stopPropagation();
        setDragOverOnCollapse(false);
      }}
    >
      <Collapse
        className={(recTestStepCollapseClassNames += isSkipStep ? " disable_bg_color" : " bg__white")}
        expandIconPosition="start"
        defaultActiveKey={isConditionStepOpen ? 0 : 1}
        expandIcon={({ isActive }) => (
          <div className={`collapseIconOnHover ${styles["collapseIcon"]} ${isSkipStep && "collapseIconBgColor"}`}>
            <SvgLoader iconName={isActive ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP} />
          </div>
        )}
      >
        <Panel
          showArrow={true}
          header={
            <Row>
              {recTestStep.recTestStepProperty.isAIStep==1 ?
                <Col span={1}>
                  <SvgLoader iconName={ICON_AI} infoIcon={true} />
                </Col> :""
              } 
              <Col span={10}>
                <span className={styles["conditionText"]}>
                {getHeaderText(recTestStep, testBlockStep)}
              </span>
              </Col>
          </Row>
        }
          extra={
            <div className={styles["previewOrDeleteIcon"]}>
              {unSavedRecStepTag === recTestStep?.tag && (
                  <PerformRecTestStepActionV2
                    action={recTestStep?.recTestStepProperty?.action}
                    data={recTestStep}
                    recTestStepId={recTestStep?.id}
                    recTestStepTag={recTestStep?.tag}
                    disabled={
                      disabled ||
                      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== recTestStep?.tag)
                    }
                    isDeviceLoaded={isDeviceLoaded}
                    projectId={projectId}
                    isPreviewRunning={isPreviewRunning}
                    saveIcon={SAVE_ICON}
                    performSaveIcon={ICON_PERFORM_SAVE}
                    resetIcon={RESET_ICON}
                  />
                )}
              <div className={unSavedRecStepTag !== recTestStep?.tag && "showRecTestStepActionsIconOnHover"}>
                <Tooltip
                  color={TOOLTIP_BG_COLOR}
                  title={getTooltipText(recTestStep, testBlockStep)}
                  placement="topRight"
                >
                  {!isPreviewRunning && (
                    <div>
                      <SvgLoader
                        disabled={disabledDeleteIcon}
                        iconName={CONDITION_DELETE_IMAGE}
                        handleClick={() =>
                          !(disabled || (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1))
                            ? isLoopStep === 1
                              ? dispatch(showDeleteLoopConditionModalVisible(recTestStep.tag))
                              : isStepHasPrecondition === 1
                              ? dispatch(
                                  showDeleteRecTestStepPreConditionConfirmationModal(
                                    recTestStep.tag,
                                    recTestStep?.recTestStepProperty?.id
                                  )
                                )
                              : ""
                            : ""
                        }
                        width="2rem"
                        height="2rem"
                      />
                    </div>
                  )}
                </Tooltip>
              </div>
              {getRecTestStepStatusWithImage(preconditionResult, isPreviewRunning)}
            </div>
          }
        >
          {isStepHasPrecondition === 1 && (
            <RecTestStepPreConditionV2
              recTestStep={recTestStep}
              disabled={disabled}
              isDeviceLoaded={isDeviceLoaded}
              recTestSteps={recTestSteps}
              projectId={props.projectId}
              isTestBlock={isTestBlock}
            />
          )}
          {isLoopStep === 1 && isStepHasPrecondition === 0 && (
            <RecTestStepLoopConditionV2
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              recTestStep={recTestStep}
              disabled={disabled}
              unSavedRecStepTag={unSavedRecStepTag}
              isDeviceLoaded={isDeviceLoaded}
              recTestSteps={recTestSteps}
              projectId={props.projectId}
            />
          )}
        </Panel>
      </Collapse>
    </div>
  ) : (
    ""
  );
};

export default RecTestStepConditionV2;
