import React from "react";
import _ from "lodash";
import Slider from "react-slick";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import { Checkbox, Collapse, Row, Tooltip } from "antd";
import styled from "styled-components";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import { StyledCollapse } from "./TestPlanResultDetailsPreview";
import ScenarioCardForPreviewLocale from "./ScenarioCardForPreviewLocale";
import { useDispatch, useSelector } from "react-redux";
import { changeSelectedTestScenarioForResultLogs, getTestPlanStepsList } from "../../../../actions/TestPlanAction";
import { COMPARISON_SINGLE_LOCALE, TEST_PLAN_RESULT_TYPE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import { getMaxRetryStep, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import TestPlanResultDetailStepList from "../TestPlanResultDetailStepList";
import styles from "./TestPlanResultPreviewImages.module.scss";
const { Panel } = Collapse;
const StepName = styled.div`
  width: ${(props) => (props.isRefSet ? "465px !important" : "225px !important")};
`;
const StepIndex = styled.span`
  font-weight: 700;
  float: left;
  padding-right: 10px;
`;
const ImageItem = styled.div`
  width: 225px !important;
  display: inline-block;
  border: ${(props) =>
    "1px solid " + (props.result === "Passed" ? "green" : props.result === "Failed" ? "red" : "lightgrey")};
  div {
    text-align: center;
  }
  ,
  img {
    display: inline-block !important;
    float: left;
    border: ${(props) =>
      "1px solid " + (props.result === "Passed" ? "green" : props.result === "Failed" ? "red" : "lightgrey")};
  }
`;

const TestPlanResultPreviewImages = (props) => {
  const {
    previewRunDetails,
    scenarioLoading,
    selectedTestScenarioForResultLogs,
    selectedAllScenario,
    selectedComparisonPreviewView,
  } = useSelector((state) => state.TestPlanReducer);
  const { refRunNumberLocaleForPreview, refRunNumberForPreview } = props;
  let isRefSet = refRunNumberLocaleForPreview !== undefined && refRunNumberForPreview !== undefined;
  var settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: isRefSet ? 3 : 4,
    slidesToScroll: isRefSet ? 3 : 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: isRefSet ? 2 : 3,
          slidesToScroll: isRefSet ? 2 : 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: isRefSet ? 2 : 3,
          slidesToScroll: isRefSet ? 2 : 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: isRefSet ? 1 : 2,
          slidesToScroll: isRefSet ? 1 : 2,
          initialSlide: 0,
        },
      },

      {
        breakpoint: 1000,
        settings: {
          slidesToShow: isRefSet ? 1 : 2,
          slidesToScroll: isRefSet ? 1 : 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  const loadStepImage = (scenarioDetails) => {
    return (
      <div>
        <Slider {...settings}>
          {scenarioDetails &&
            scenarioDetails?.steps?.map((_step, j) => {
              let step = getMaxRetryStep(scenarioDetails?.retrySteps, _step);
              return (
                step.isSkipStep === 0 && (
                  <div className={"" + (j > 0 ? "ml-5" : "")}>
                    <Tooltip color={TOOLTIP_BG_COLOR} title={step?.name?.replaceAll("##", " / ")}>
                      <StepName
                        className="overflow-hidden overflow-ellipsis white-space-nowrap font-weight-500 mb-5 cursor-pointer"
                        isRefSet={isRefSet}
                      >
                        <StepIndex>{j + 1 + " "}</StepIndex>
                        {step?.name?.replaceAll("##", " / ").substring(0, 40)}
                      </StepName>
                    </Tooltip>

                    {step?.refAfterActionScreenshotUrl && (
                      <ImageItem className="border-1-gray">
                        <div>{refRunNumberLocaleForPreview}</div>
                        <img height={350} width={225} src={step?.refAfterActionScreenshotUrl} alt="referenceImage" />
                      </ImageItem>
                    )}
                    <ImageItem className="ml-10 " result={step?.result}>
                      <div>{scenarioDetails.locale}</div>
                      {isNotNullAndNotEmptyAndNotUndefined(step?.afterActionScreenshotUrl) ? (
                        <Zoom>
                          <picture>
                            <source media="(max-width: 800px)" srcSet={step?.afterActionScreenshotUrl} />
                            <img
                              height={350}
                              width={225}
                              className="float-right"
                              src={step?.afterActionScreenshotUrl}
                              alt={"Not Executed (" + step?.result + ")"}
                            />
                          </picture>
                        </Zoom>
                      ) : (
                        <div className={styles.notExecutedImages}>{"Not Executed (" + step?.result + ")"}</div>
                      )}
                    </ImageItem>
                  </div>
                )
              );
            })}
        </Slider>
        {_.isEmpty(scenarioDetails?.steps) && <EmptyRecords description="No Data Found" />}
      </div>
    );
  };

  const dispatch = useDispatch();
  return previewRunDetails
    ? previewRunDetails?.map((locales, k) => {
        let allScenarioFolderLink = locales.testScenarios.map((scenario) => scenario.scenarioFolderLink);
        let allScenarioIds = locales?.testScenarios.map((scenario) => scenario.id);
        return (
          <Collapse className="border-none ml-20 mr-20" expandIconPosition="right" key={k}>
            <Panel
              showArrow={true}
              header={
                <Row className="display-flex">
                  <div className="mr-10">
                    {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
                      <Checkbox
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(
                            changeSelectedTestScenarioForResultLogs(
                              e.target.checked,
                              allScenarioFolderLink,
                              false,
                              allScenarioIds
                            )
                          );
                        }}
                        checked={_.difference(allScenarioFolderLink, selectedTestScenarioForResultLogs).length === 0}
                        indeterminate={
                          _.intersection(selectedTestScenarioForResultLogs, allScenarioFolderLink).length > 0 &&
                          _.intersection(selectedTestScenarioForResultLogs, allScenarioFolderLink).length !==
                            allScenarioFolderLink.length
                        }
                      />
                    )}
                  </div>
                  <div>
                    <div>
                      <span className="font-weight-700">Locale: </span>
                      <span className="font-weight-500">{locales?.locale}</span>
                    </div>
                    <div>
                      <span className="font-weight-600">Total Scenarios: </span>
                      <span className="font-weight-500">{locales?.totalScenarioCount}</span>
                    </div>
                  </div>
                </Row>
              }>
              <TestPlanResultDetailsScenario className="mt-50">
                {locales &&
                  locales?.testScenarios?.map((v, k) => {
                    return (
                      v && (
                        <StyledCollapse className="border-none" expandIconPosition="right" key={k}>
                          <Panel
                            showArrow={true}
                            header={
                              <ScenarioCardForPreviewLocale
                                key={k}
                                scenarioData={v}
                                selectedTestScenarioForResultLogs={selectedTestScenarioForResultLogs}
                                selectedComparisonPreviewView={selectedComparisonPreviewView}
                              />
                            }
                          >
                            <div className="pl-20 pr-20">{loadStepImage(v)}</div>
                          </Panel>
                        </StyledCollapse>
                      )
                    );
                  })}
                {!scenarioLoading && _.isEmpty(previewRunDetails) && <EmptyRecords description="No Data Found" />}
              </TestPlanResultDetailsScenario>
            </Panel>
          </Collapse>
        );
      })
    : "";
};

export default TestPlanResultPreviewImages;
