import { async } from "../api/automation";
import {
  ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET,
  ALL_TEST_PLANS,
  ASSIGN_TEST_DATA_SET,
  CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM,
  CHANGE_TEST_DATA_SET_NAME,
  CHANGE_TEST_PLAN_DATA,
  ERROR_TYPE,
  GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID,
  GET_TEST_DATA_SET_BY_ID,
  GET_TEST_PLAN_ID_NAME_LIST,
  HIDE_ADD_TEST_DATA_SET_FORM,
  HIDE_TEST_DATA_SET_SELECTION_MODAL,
  LIST_OF_LOCALES,
  RECORD_10_PER_PAGE,
  REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET,
  SAVE_TEST_DATA_SET,
  SELECT_TEST_BLOCK__STEP_DATA,
  SHOW_ADD_TEST_DATA_SET_FORM,
  SHOW_TEST_DATA_SET_SELECTION_MODAL,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  SYSTEM_KEY_LIST,
  TEST_DATA_LIST,
  TEST_DATA_LIST_FOR_SCENARIO,
  TEST_DATA_LIST_FOR_TEST_DATA_SET,
  TEST_DATA_SET_LIST,
  TEST_DATA_SET_LIST_FOR_SELECTION,
  TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST,
  TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED,
  TEST_DATA_SET_LIST_REQUEST,
  TEST_DATA_SET_LIST_REQUESTED,
  TEST_DATA_SET_SELECTION_SCENARIO,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT,
  RESET_MANAGE_KEYS_DATA,
} from "../Constants";
import { showNotification, updateResponse } from "../Util";
import { fetchTestDatByTestDataSet } from "./TestDataAction";
import { fetchTestDataSetByProfileId, getTestDataSetById } from "./TestDataSet/TestDataSetRedirectAction";
import { changeTestPlanTab, editTestPlan } from "./TestPlanAction";

export function fetchTestPlanList(projectId) {
  return async (dispatch, getState) => {
    return async("testPlan/getListOfIdAndName", "GET", {
      projectId: projectId,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_PLAN_ID_NAME_LIST, response });
    });
  };
}

export function getSystemTestDataSet(testDataSetId) {
  return (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    let data = {
      testDataSetId: testDataSetId,
    };
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    dispatch({ type: GET_TEST_DATA_SET_BY_ID, response: undefined });
    return async("testDataSetBase/getById", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_DATA_SET_BY_ID, response });
        dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
      }
    });
  };
}

export function fetchTestDataSet(testPlanId, search = "") {
  return (dispatch) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSet/getByTestPlanId", "GET", { testPlanId, search }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_DATA_SET_LIST, response });
      dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
    });
  };
}

export function fetchSystemKeyByProjectId(search = "", pageCount = 1, showCount = true) {
  return (dispatch, getState) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    const projectId = getState().ProjectsReducer.projectId;
    if (projectId) {
      async("testDataSetBase/getDataProfileByItemId", "GET", {
        projectId: projectId,
        search: search,
        pageNumber: pageCount,
        recordPerPage: RECORD_10_PER_PAGE,
        showCount,
      }).then((response) => {
        response = updateResponse(response);
        dispatch({ type: TEST_DATA_SET_LIST, response });
        dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
      });
    } else {
      dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
    }
  };
}

export function fetchSystemKeyByProjectIdDropdown() {
  return (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    async("testDataSetBase/getSystemKeyByProjectIdDropdownList", "GET", {
      projectId: projectId,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: SYSTEM_KEY_LIST, response });
      }
    });
  };
}

export function getListOfLocales() {
  return (dispatch, getState) => {
    async("locale/getListOfLocales", "GET").then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: LIST_OF_LOCALES, response });
      }
    });
  };
}

export function fetchTestDatByTestPlanId(testPlanId) {
  return (dispatch) => {
    async("testData/findByTestPlanId", "GET", { testPlanId }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_DATA_LIST_FOR_TEST_DATA_SET, response });
    });
  };
}

export function saveTestPlan(saveTestPlanData, testPlanId, projectId) {
  return async (dispatch, getState) => {
    const { testPlanDetails } = getState().TestPlanReducer;
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    let preparedObject = {
      testPlan: {
        id: testPlanId ? testPlanId : null,
        name: saveTestPlanData?.testPlanName,
        project: { id: parseInt(projectId) },
        isPrivate: testPlanDetails !== undefined ? testPlanDetails?.isPrivate : saveTestPlanData?.isPrivate || 0,
      },
      listOfScenarios: saveTestPlanData?.testPlanList?.map((j) => j?.id) || [],
      listOfMembersToProvideRights: saveTestPlanData?.listOfMembersToProvideRights || [],
      testDataSet: null,
      testDataSetAssoc: null,
      project: { id: parseInt(projectId) },
    };
    return async("testPlan/save", "POST", preparedObject).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(changeTestPlanTab(ALL_TEST_PLANS));
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
        dispatch(editTestPlan(false));
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
      }
    });
  };
}

// export function cloneTestDataSetByTestPlanId(testPlanId, testDataSetId, stepType) {
//   return async (dispatch, getState) => {
//     let preObj = null;
//     const projectId = getState().ProjectsReducer.projectId;
//     if (stepType === TEST_STEP_BLOCKS) {
//       preObj = { recTestStepId: testPlanId, testDataSetId: testDataSetId, projectId: projectId };
//     } else if (stepType === TEST_SCENARIOS) {
//       preObj = { recTestScenarioId: testPlanId, testDataSetId: testDataSetId, projectId: projectId };
//     } else {
//       preObj = { testDataSetId: testDataSetId, projectId: projectId };
//     }
//     dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
//     return async("testDataSetBase/clone", "POST", preObj).then((response) => {
//       response = updateResponse(response);
//       if (response.status === SUCCESS_STATUS) {
//         dispatch(fetchTestDataSetByProfileId());
//       }
//     });
//   };
// }

export function cloneSystemKeyByTestDataSetId(testDataSetId) {
  return async (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    const preObj = { testDataSetId: testDataSetId, projectId: projectId };
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    return async("testDataSetBase/clone", "POST", preObj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(fetchSystemKeyByProjectId());
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: TEST_DATA_SET_LIST_REQUESTED });
    });
  };
}

export function getTestDataUsedByRecTestScenarioId() {
  return async (dispatch, getState) => {
    const { selectedRecTestScenarioId } = getState().RecTestStepReducer;
    return async("testData/getByRecTestScenarios", "POST", { recTestScenarios: [selectedRecTestScenarioId] }).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch({ type: TEST_DATA_LIST_FOR_SCENARIO, response });
        }
      }
    );
  };
}

export function deleteTestDataSet(testDataSetId, stepType) {
  return (dispatch, getState) => {
    const { testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSetBase/delete", "POST", { testDataSetId: testDataSetId, isDelete: 1 }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(hideAddTestDataSetForm());
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    });
  };
}

export function removeTestDataFromTestDataSet(testDataSetAssocId, testDataSetId) {
  return (dispatch, getState) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSet/removeTestDataFromSet", "GET", { testDataSetAssocId: testDataSetAssocId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(hideAddTestDataSetForm());
        dispatch(getTestDataSetById(testDataSetId));
      }
    });
  };
}

export function getTestDataForAddTestData(testDataSetId, id, stepType) {
  return (dispatch, getState) => {
    let data = {
      testDataSetId: testDataSetId,
      testPlanId: ![TEST_STEP_BLOCKS, TEST_SCENARIOS].includes(stepType) ? id : 0,
      recTestStepId: [TEST_STEP_BLOCKS].includes(stepType) ? id : 0,
      recTestScenarioId: [TEST_SCENARIOS].includes(stepType) ? id : 0,
    };
    async("testDataSetBase/getTestDataToAdd", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_DATA_LIST, response });
      }
    });
  };
}

export function getTestDataForAddTestDataSystemKey(testDataSetId) {
  return (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    let data = {
      testDataSetId: testDataSetId,
      projectId: projectId,
    };
    async("testDataSetBase/getTestDataToAdd", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_DATA_LIST, response });
      }
    });
  };
}

// export function saveTestData(
//   testDataSetForEdit,
//   editTestDataSetName,
//   newTestDataSets,
//   testPlanId,
//   stepType,
//   systemKey = 0,
//   locale = 0
// ) {
//   return (dispatch, getState) => {
//     // const projectId = getState().ProjectsReducer.projectId;
//     let preObj = {
//       testDataSetId: testDataSetForEdit && testDataSetForEdit?.id,
//       name: editTestDataSetName,
//       testDataSetCustomSaveReqs: newTestDataSets,
//       systemKey: systemKey === 0 ? null : { id: systemKey },
//       locale: locale,
//     };
//     async("testDataSetBase/addTestDataToSet", "POST", preObj).then((response) => {
//       response = updateResponse(response);
//       if (response.status === SUCCESS_STATUS) {
//         showNotification(SUCCESS_TYPE, response.message);
//         if (stepType) {
//           dispatch(fetchTestDataSetByProfileId());
//         } else {
//           dispatch(fetchSystemKeyByProjectId());
//         }
//       } else {
//         showNotification(ERROR_TYPE, response.message);
//       }
//     });
//   };
// }

export function saveTestDataSet(projectId, testPlanId) {
  return (dispatch, getState) => {
    const { newTestDataSetName, newTestDataVariables } = getState().TestDataSetReducer;
    let data = {
      testDataSet: {
        name: newTestDataSetName,
        project: { id: projectId },
      },
      testDataSetAssoc: [...newTestDataVariables],
      project: { id: projectId },
      testPlan: { id: testPlanId },
    };
    async("testDataSet/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: SAVE_TEST_DATA_SET, response });
        dispatch(hideAddTestDataSetForm());
        dispatch(fetchTestDataSetByProfileId(testPlanId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function updateTestDataSet(projectId, testPlanId, testDataSetId) {
  return (dispatch, getState) => {
    const { newTestDataSetName, newTestDataVariables } = getState().TestDataSetReducer;
    let data = {
      testDataSet: {
        name: newTestDataSetName,
        project: { id: projectId },
        id: testDataSetId,
      },
      testDataSetAssoc: [...newTestDataVariables],
      project: { id: projectId },
      testPlan: { id: testPlanId },
    };
    async("testDataSet/update", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(fetchTestDatByTestDataSet(testDataSetId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}
export function saveTestDataForTestDataSet(selectedTestData, testDataSetId, testPlanId) {
  return (dispatch) => {
    let data = {
      testData: { name: selectedTestData.name, value: selectedTestData.value, id: selectedTestData.id },
      testDataSet: { id: testDataSetId },
      value: selectedTestData.testDataCustomValue,
    };
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSetAssoc/save", "POST", data).then((response) => {
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(fetchTestDataSetByProfileId());
        dispatch(fetchTestDatByTestDataSet(testDataSetId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function updateTestDataValueForTestData(
  testDataSetAssocId,
  testDataCustomValue,
  testPlanId,
  testDataSetId,
  testPlanScenario
) {
  return (dispatch) => {
    let data = {
      id: testDataSetAssocId,
      value: testDataCustomValue,
    };
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSetAssoc/update", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(fetchTestDataSetByProfileId(testPlanId));
        dispatch(hideAddTestDataSetForm());
        dispatch(fetchTestDatByTestDataSet(testDataSetId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function removeTestDataForTestData(testDataSetAssocId, testDataSetId, testPlanId, testPlanScenario) {
  return (dispatch) => {
    dispatch({ type: TEST_DATA_SET_LIST_REQUEST });
    async("testDataSetAssoc/delete?testDataSetAssocId=" + testDataSetAssocId, "POST", {}).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(fetchTestDataSetByProfileId());
        dispatch(fetchTestDatByTestDataSet(testDataSetId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}

export function showAddTestDataSetForm(projectId, testPlanId) {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_TEST_DATA_SET_FORM });
    dispatch(fetchTestDatByTestPlanId(testPlanId));
  };
}
export function changeNewTestDataSetName(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_SET_NAME, value });
  };
}
export function hideAddTestDataSetForm() {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_TEST_DATA_SET_FORM });
  };
}

export function addTestDataRowInNewTestDataSet() {
  return (dispatch) => {
    dispatch({ type: ADD_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET });
  };
}

export function removeTestDataRowInNewTestDataSet(tag) {
  return (dispatch) => {
    dispatch({ type: REMOVE_TEST_DATA_ROW_IN_NEW_TEST_DATA_SET, tag });
  };
}

export function changeTestDataNameInTestDataSetForm(value, tag) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_NAME_IN_TEST_DATA_SET_FORM, value, tag });
  };
}

export function changeTestDataDefaultValueInTestDataSetForm(value, tag) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_DEFAULT_VALUE_IN_TEST_DATA_SET_FORM, value, tag });
  };
}

export function changeTestDataCustomValueInTestDataSetForm(value, tag) {
  return (dispatch) => {
    dispatch({ type: CHANGE_TEST_DATA_CUSTOM_VALUE_IN_TEST_DATA_SET_FORM, value, tag });
  };
}

export function fetchTestDataByScenarioId(data) {
  return (dispatch) => {
    async("testData/getByRecTestScenario", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID, response });
    });
  };
}

export function fetchTestDataByTestBlock(data) {
  return (dispatch) => {
    async("testData/getByRecTestStep", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID, response });
    });
  };
}

export function getTestDataSetByTestPlanId(testDataSetAssigneeId, search, stepType, parentId, showCount = true) {
  let data = null;
  if (stepType === TEST_SCENARIOS) {
    data = {
      recTestStepId: testDataSetAssigneeId,
      search: search,
      recTestScenarioRecTestStepAssocId: parentId,
      showCount,
    };
  } else {
    data = { testPlanId: testDataSetAssigneeId, search, showCount };
  }
  return (dispatch) => {
    dispatch({ type: TEST_DATA_SET_LIST_FOR_SELECTION_REQUEST });
    async("testDataSet/getDataProfileByItemId", "GET", data).then((response) => {
      dispatch({ type: TEST_DATA_SET_LIST_FOR_SELECTION, testPlanId: testDataSetAssigneeId, search, response });
      dispatch({ type: TEST_DATA_SET_LIST_FOR_SELECTION_REQUESTED });
      if (response.status === SUCCESS_STATUS && stepType === TEST_SCENARIOS) {
        async("recTestScenarioRecTestStepTestDataAssoc/findSelectedTestDataSetByAssocId", "GET", {
          assocId: parentId,
        }).then((response) => {
          dispatch({ type: ASSIGN_TEST_DATA_SET, selectedTestDataSet: response });
        });
      }
    });
  };
}

export const getSelectedTestBlock = (data) => {
  return (dispatch) => {
    dispatch({ type: SELECT_TEST_BLOCK__STEP_DATA, data });
  };
};

export const saveAssignTestData = (testDataSet, stepType) => {
  return (dispatch, getState) => {
    const { testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    if (stepType === TEST_SCENARIOS) {
      async("recTestScenarioRecTestStepTestDataAssoc/save", "POST", {
        testDataSet: { id: testDataSet ? testDataSet : 0 },
        recTestScenarioRecTestStepsAssoc: { id: testDataSetAssigneeDetail.recTestScenarioRecTestStepAssocId },
      }).then((response) => {
        response = updateResponse(response);
        dispatch({ type: GET_LIST_OF_TEST_DATA_BY_SCENARIO_ID, response });
        if (response.status === SUCCESS_STATUS) {
          async("recTestScenarioRecTestStepTestDataAssoc/findSelectedTestDataSetByAssocId", "GET", {
            assocId: testDataSetAssigneeDetail.recTestScenarioRecTestStepAssocId,
          }).then((response) => {
            dispatch({ type: TEST_DATA_SET_SELECTION_SCENARIO, response });
            dispatch(hideTestDataSetSelectionModal());
          });
        }
      });
    }
  };
};

export function changeTestPlanData(data) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_TEST_PLAN_DATA, data: data?.id });
    dispatch({ type: TEST_DATA_SET_SELECTION_SCENARIO, response: { name: data?.name, id: data?.id } });
  };
}

export function showTestDataSetSelectionModal(testDataSetAssigneeDetail) {
  return async (dispatch) => {
    dispatch({ type: SHOW_TEST_DATA_SET_SELECTION_MODAL, testDataSetAssigneeDetail });
  };
}

export function hideTestDataSetSelectionModal() {
  return async (dispatch) => {
    dispatch({ type: HIDE_TEST_DATA_SET_SELECTION_MODAL });
  };
}

export function assignTestDataSet(selectedTestDataSet) {
  return async (dispatch) => {
    dispatch({
      type: ASSIGN_TEST_DATA_SET,
      selectedTestDataSet,
    });
  };
}

export function resetPreviousTestDataSetForEdit() {
  return async (dispatch) => {
    dispatch({
      type: RESET_PREVIOUS_TEST_DATA_SET_FOR_EDIT,
    });
  };
}

export function resetManageKeysData() {
  return async (dispatch) => {
    dispatch({
      type: RESET_MANAGE_KEYS_DATA,
    });
  };
}
