import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Input, Row, Select, Space } from "antd";
import {
  fetchSystemKeyByProjectId,
  resetPreviousTestDataSetForEdit,
} from "../../../../../../actions/TestDataSetAction";
import styles from "./EditTestDataSetV2.module.scss";
import BackButton from "../../../../../CoreComponents/BackButtonComponent/BackButton";
import SaveResetButton from "../../../../../CoreComponents/SaveResetComponent/SaveResetButton";
import { CONFIRM_MODAL_CONTENT, SYSTEM_KEYS, TEST_PLAN } from "../../../../../../Constants";
import { BACK_BUTTON } from "../../../../../../CdnImagePath";
import {
  changeTestDataSetName,
  changeTestDatasetLocale,
  fetchTestDataSetByProfileId,
  resetTestDataSet,
  saveTestDataSet,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import CommonConfirmationModalV2 from "../../../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

const { Option } = Select;

const EditTestDataSetHeaderV2 = ({ stepType, setIsEditTestDataSet, isSystemKeyView }) => {
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { testDataSetForEdit, listOfLocales, previous_testDataSetForEdit, testDataSetView, testDataSetAssigneeDetail } =
    useSelector((state) => state.TestDataSetReducer);

  const { name, locale } = testDataSetForEdit || {};
  const [editName, setEditName] = useState(false);

  const onBackClick = useCallback(() => {
    if (previous_testDataSetForEdit) {
      setOpenConfirmModal(true);
    } else {
      setIsEditTestDataSet(false);
    }
    if (testDataSetView === SYSTEM_KEYS) {
      dispatch(fetchSystemKeyByProjectId());
    } else {
      dispatch(fetchTestDataSetByProfileId(testDataSetAssigneeDetail?.id));
    }
  }, [previous_testDataSetForEdit, setIsEditTestDataSet, dispatch, testDataSetView, testDataSetAssigneeDetail]);

  return (
    <>
      <Row align="middle" className={styles["edit-Test-Data-Header"]}>
        <Col className={styles["edit-Test-Data-Col"]}>
          {stepType !== TEST_PLAN && (
            <div className="close-Icon">
              <img src={BACK_BUTTON} alt="Back" onClick={onBackClick} className={styles["back-button"]} />
            </div>
          )}
          <div className={styles["test-data-set-profile-name"]}>
            <label className={styles["test-data-set-title"]}>Profile Name: </label>
            {editName ? (
              <Input
                type="text"
                value={name}
                onChange={(e) => dispatch(changeTestDataSetName(e.target.value))}
                onBlur={() => setEditName(false)}
                autoFocus
              />
            ) : (
              <div className={styles["test-data-set-name"]} onClick={() => setEditName(true)}>
                <span className="pointer">{name}</span>
              </div>
            )}
          </div>
        </Col>
        <Col>
          <Space>
            {!isSystemKeyView && (
              <Select
                placeholder="Please select Locale"
                className="select-app-for-default"
                defaultValue={testDataSetForEdit && locale}
                onChange={(value) => dispatch(changeTestDatasetLocale(value))}
                value={locale}
              >
                {listOfLocales?.map((i, k) => {
                  return (
                    <Option value={i.locales} key={k}>
                      {i.displayName}
                    </Option>
                  );
                })}
              </Select>
            )}

            <div className={stepType !== TEST_PLAN && styles.saveResetButton}>
              <SaveResetButton
                submitLabel={"Save"}
                resetLabel={"Reset"}
                submitDisable={previous_testDataSetForEdit === undefined}
                resetDisable={previous_testDataSetForEdit === undefined}
                handleSubmit={() => dispatch(saveTestDataSet())}
                handleReset={() => dispatch(resetTestDataSet())}
              />
            </div>
            {stepType === TEST_PLAN && (
              <div className="closeIcon">
                <BackButton handleBack={onBackClick} isBlack={true} />
              </div>
            )}
          </Space>
        </Col>
      </Row>
      <CommonConfirmationModalV2
        handleOk={() => {
          setIsEditTestDataSet(false);
          dispatch(resetPreviousTestDataSetForEdit());
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default EditTestDataSetHeaderV2;