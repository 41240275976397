import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import ApplicationStringsList from "./ApplicationStringsList/ApplicationStringsList";
import AddApplicationStrings from "./AddApplicationStrings/AddApplicationStrings";
import ImportApplicationStrings from "./AddApplicationStrings/ImportApplicationStrings";

const ApplicationStrings = (props) => {
  const { visibleAddApplicationStringForm } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { visibleImportApplicationStringForm } = useSelector((state) => state.ApplicationStringReducer);

  return (
    <Row>
      <Col span={24}>
        {visibleAddApplicationStringForm ? (
          <AddApplicationStrings projectId={props.projectId} />
        ) : visibleImportApplicationStringForm ? (
          <ImportApplicationStrings projectId={props.projectId} />
        ) : (
          <ApplicationStringsList projectId={props.projectId} />
        )}
      </Col>
    </Row>
  );
};

export default ApplicationStrings;
