import React from "react";
import _ from "lodash";
import { Col, Collapse, Input, Popover } from "antd";
import { useSelector } from "react-redux";
import { ErrorStyledRow } from "./PreviousInvestigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const { Panel } = Collapse;

const ErrorInvestigationFields = () => {
  const { errorInvestigationDetails } = useSelector((state) => state.ErrorInvestigationReducer);

  return (
    <>
      <ErrorStyledRow className="mb-10">
        <Collapse defaultActiveKey={1} expandIconPosition="right" className="w-100">
          <Panel
            key="1"
            showArrow={true}
            header={
              <div className="font-size-16 font-weight-500 display-flex justify-space-between">
                <div>
                  Failure Details for{" "}
                  <span className="font-weight-400">
                    {errorInvestigationDetails?.elementDisplayName} ({errorInvestigationDetails?.actionName})
                  </span>
                </div>
                <Popover
                  placement="left"
                  content={
                    <img
                      src={errorInvestigationDetails?.failureScreenshotLink}
                      height="400"
                      weight="400"
                      alt="failure"
                    />
                  }
                  title="Failure Image"
                  trigger="hover"
                >
                  <div>
                    <FontAwesomeIcon icon={faInfoCircle} className="font-size-20" />
                  </div>
                </Popover>
              </div>
            }
          >
            <Col span={24} className="display-flex mb-10">
              <Col span={6} className="font-size-14 font-weight-500 align-self-center">
                Error Element:
              </Col>
              <Col span={18}>
                <Input
                  className="border-radius-4"
                  value={errorInvestigationDetails?.elementDisplayName}
                  placeholder="Please Enter Error Element"
                  name="elementDisplayName"
                  disabled
                />
              </Col>
            </Col>
            <Col span={24} className="display-flex mb-10">
              <Col span={6} className="font-size-14 font-weight-500 align-self-center">
                Error Message:
              </Col>
              <Col span={18}>
                <Input
                  className="border-radius-4"
                  value={errorInvestigationDetails?.errorMessage}
                  placeholder="Please Enter Error Message"
                  name="errorMessage"
                  disabled
                />
              </Col>
            </Col>
          </Panel>
        </Collapse>
      </ErrorStyledRow>
    </>
  );
};

export default ErrorInvestigationFields;
