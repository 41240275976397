import { async } from "../api/automation";
import _ from "lodash";
import {
  CHANGE_NEW_TEST_DATA_NAME,
  CHANGE_NEW_TEST_DATA_VALUE,
  HIDE_ADD_TEST_DATA_FORM,
  LOAD_VALUE_ADD_TEST_DATA_FORM,
  SAVE_TEST_DATA_FORM_REQUEST,
  CHANGE_NEW_TEST_DATA_SCOPE,
  SHOW_ADD_TEST_DATA_FORM,
  TEST_DATA_LIST,
  TEST_DATA_LIST_REQUEST,
  TEST_DATA_LIST_REQUESTED,
  TEST_DATA_LIST_BY_TEST_DATASET_ID,
  ERROR_STATUS,
  TEST_DATA_SCOPE,
  SAVE_TEST_DATA_FORM_REQUESTED,
  DELETE_TEST_PLAN,
  CHANGE_NEW_TEST_DATA_TYPE,
  TEST_STEP_BLOCKS,
  TEST_SCENARIOS,
  CHANGE_TEST_DATA_LIST_SEARCH_VALUE,
  CHANGE_TEST_DATA_LIST_FILTER_VALUE,
  ERROR_TYPE,
  RECORD_10_PER_PAGE,
  SUCCESS_STATUS,
  VISIBLE_TEST_DATA_DRAWER,
  TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA,
  TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING,
  SHOW_ADD_PROJECT_KEYS_FORM,
  LOAD_EDIT_PROJECT_STRING_DETAILS,
  HIDE_ADD_PROJECT_KEYS_FORM,
  CHANGE_NEW_PROJECT_KEY_VALUE,
  SAVE_PROJECT_KEYS_FORM_REQUEST,
  SAVE_PROJECT_KEYS_FORM_REQUESTED,
  CHANGE_NEW_PROJECT_KEY_NAME,
  DEFAULT_PAGE,
} from "../Constants";
import { showNotification, updateResponse } from "../Util";
import { _getTestStepsData } from "./TestDataSet/TestDataSetCommonAction";
import { getTestBlockStepListByTestBlockId } from "./TestBlocksAction";
import { getRecTestStepListByRecTestScenarioId } from "./RecTestStepAction";
let controller = new AbortController();

export function fetchTestData(projectId, testDataDrawer, searchWord, filteredValue) {
  return (dispatch, getState) => {
    if (testDataDrawer) {
      controller.abort(); // abort previous fetch here
      controller = new AbortController();
    }
    const signal = controller.signal;
    const { testBlockStep, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    const { searchFetchingTestDataList, filterFetchingTestDataList } = getState().TestDataReducer;
    dispatch({ type: TEST_DATA_LIST_REQUEST });
    async(
      "testData/getByProjectId",
      "GET",
      {
        projectId,
        recTestScenarioId: filteredValue ? 0 : stepType === TEST_SCENARIOS ? selectedRecTestScenarioId : 0,
        recTestStepId: filteredValue ? 0 : stepType === TEST_STEP_BLOCKS && testBlockStep ? testBlockStep.id : 0,
        search: searchWord ? searchWord : searchFetchingTestDataList,
        filterValue: filteredValue ? filteredValue : filterFetchingTestDataList,
      },
      undefined,
      signal
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_DATA_LIST, response });
      } else {
        dispatch({ type: TEST_DATA_LIST, response: undefined });
      }
      if (response?.status) {
        dispatch({ type: TEST_DATA_LIST_REQUESTED });
      }
    });
  };
}

export function fetchTestDataByProjectId(projectId, pageCount = 1, searchKeyword = "") {
  return (dispatch) => {
    dispatch({ type: TEST_DATA_LIST_REQUEST });
    async("testData/getSystemKeysByProjectId", "GET", {
      projectId,
      searchKeyword: searchKeyword,
      pageNumber: pageCount,
      recordPerPage: RECORD_10_PER_PAGE,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: TEST_DATA_LIST, response: response.data });
      }
      dispatch({ type: TEST_DATA_LIST_REQUESTED });
    });
  };
}

export function changeTestDataListSearchValue(value, projectId) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_TEST_DATA_LIST_SEARCH_VALUE, value });
  };
}

export function visibleTestDataDrawer(
  openTestDataSetDrawerByTabName,
  tagForOpenDrawer,
  guidForOpenDrawer,
  verificationDataForTestDataDrawer
) {
  return (dispatch) => {
    dispatch({
      type: VISIBLE_TEST_DATA_DRAWER,
      openTestDataSetDrawerByTabName,
      tagForOpenDrawer,
      guidForOpenDrawer,
      verificationDataForTestDataDrawer,
    });
  };
}

export function changeTestDataListFilterValue(value, projectId) {
  return (dispatch, getState) => {
    dispatch({ type: CHANGE_TEST_DATA_LIST_FILTER_VALUE, value });
  };
}

export function showAddTestDataForm(projectId) {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_TEST_DATA_FORM });
  };
}

export function hideAddTestDataForm(projectId) {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_TEST_DATA_FORM });
  };
}

export function showAddProjectKeysForm(projectId) {
  return (dispatch) => {
    dispatch({ type: SHOW_ADD_PROJECT_KEYS_FORM });
  };
}

export function hideAddProjectKeysForm(projectId) {
  return (dispatch) => {
    dispatch({ type: HIDE_ADD_PROJECT_KEYS_FORM });
  };
}
export function changeNewProjectKeyName(name) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_PROJECT_KEY_NAME, name });
  };
}

export function changeNewProjectKeyValue(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_PROJECT_KEY_VALUE, value });
  };
}
export function changeNewTestDataName(name) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_TEST_DATA_NAME, name });
  };
}

export function changeNewTestDataValue(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_TEST_DATA_VALUE, value });
  };
}

export function changeNewTestDataScope(scope) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_TEST_DATA_SCOPE, scope });
  };
}
export function changeNewTestDataType(value) {
  return (dispatch) => {
    dispatch({ type: CHANGE_NEW_TEST_DATA_TYPE, value });
  };
}

export function deleteTestData(id, isSystemKey = false, selectedStepId = undefined, projectKeys,deleteProjectKey) {
  return (dispatch, getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    const { selectedTestStepBlocks } = getState().TestBlockReducer;
    const { selectedScenario } = getState().RecTestScenarioReducer;
    const { stepType, unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    dispatch({ type: DELETE_TEST_PLAN });
    async("testData/delete", "POST", { id: id }).then((response) => {
      response = updateResponse(response);
      if (response.status === ERROR_STATUS) {
        showNotification(ERROR_TYPE, response.message);
      }
      if (isSystemKey) {
        dispatch(fetchTestDataByProjectId(projectId));
      } else {
        if(deleteProjectKey){
          dispatch(fetchProjectKeysData(projectId));
        }else{
          dispatch(fetchTestData(projectId, "", "", projectKeys && projectKeys));
        }
        dispatch(hideAddTestDataForm());
        if (selectedStepId !== undefined) {
          dispatch(_getTestStepsData(selectedStepId));
        }
        if (unSavedRecStepTag === undefined) {
          if (stepType === TEST_STEP_BLOCKS && !_.isEmpty(selectedTestStepBlocks)) {
            dispatch(
              getTestBlockStepListByTestBlockId(
                selectedTestStepBlocks.id,
                selectedTestStepBlocks,
                projectId,
                selectedTestStepBlocks.platform,
                selectedTestStepBlocks.tags,
                selectedTestStepBlocks.testRailTestCaseId,
                selectedTestStepBlocks.testRailTestSuiteId
              )
            );
          }
          if (stepType === TEST_SCENARIOS && selectedScenario?.id !== undefined) {
            dispatch(getRecTestStepListByRecTestScenarioId(selectedScenario.id));
          }
        }
      }
    });
  };
}

export function saveNewTestData(
  name,
  value,
  id,
  projectId,
  scope,
  systemKey = 0,
  selectedStepId = undefined,
  filteredValue,
  addProjectKey
) {
  return (dispatch, getState) => {
    const { testBlockStep, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch({ type: SAVE_TEST_DATA_FORM_REQUEST });
    dispatch({ type: SAVE_PROJECT_KEYS_FORM_REQUEST });
    let data = {
      id,
      name,
      value,
      recTestScenario: scope === TEST_DATA_SCOPE.TEST_SCENARIO ? { id: selectedRecTestScenarioId } : undefined,
      recTestStep:
        scope === TEST_DATA_SCOPE.TEST_STEP_BLOCK && stepType === TEST_STEP_BLOCKS && testBlockStep
          ? { id: testBlockStep.id }
          : undefined,
      project: { id: projectId },
      isSystemKey: systemKey,
    };

    async("testData/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === ERROR_STATUS) {
        showNotification("error", response.message);
      } else {
        if (systemKey === 1) {
          dispatch(fetchTestDataByProjectId(projectId));
        } else {
          if (addProjectKey) {
            dispatch(fetchProjectKeysData(projectId));
          } else {
            dispatch(fetchTestData(projectId, "", "", filteredValue && filteredValue));
          }
        }
        if (selectedStepId !== undefined) {
          dispatch(_getTestStepsData(selectedStepId));
        }
      }
      dispatch({ type: SAVE_TEST_DATA_FORM_REQUESTED });
      dispatch({ type: SAVE_PROJECT_KEYS_FORM_REQUESTED });
    });
  };
}

export function loadEditTestDataDetail(testDataId, testDataDetails = undefined) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_VALUE_ADD_TEST_DATA_FORM,
      testDataId,
      testDataList: testDataDetails
        ? [
            {
              id: testDataDetails.id,
              name: testDataDetails.key,
              value: testDataDetails.value,
              scope: testDataDetails.scope,
            },
          ]
        : getState().RecTestStepCommonViewReducer.testDataList,
    });
  };
}

export function loadEditProjectStringDetail(testDataId, testDataDetails = undefined) {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_EDIT_PROJECT_STRING_DETAILS,
      testDataId,
      testDataList: testDataDetails
        ? [
            {
              id: testDataDetails.id,
              name: testDataDetails.key,
              value: testDataDetails.value,
              scope: testDataDetails.scope,
            },
          ]
        : getState().RecTestStepCommonViewReducer.testDataList,
    });
  };
}

export function fetchTestDatByTestDataSet(testDataSetId) {
  return (dispatch) => {
    async("testData/getByTestDataSetId", "GET", { testDataSetId: testDataSetId }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_DATA_LIST_BY_TEST_DATASET_ID, response });
    });
  };
}

export function fetchAssociatedTestBlocks(testDataId, isAddScenario = 0) {
  return (dispatch) => {
    dispatch({ type: TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING, flag: true });
    dispatch({ type: TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA, response: undefined });
    async("testData/getAssociatedTestBlock", "GET", { testDataId: testDataId, isAddScenario: isAddScenario }).then(
      (response) => {
        response = updateResponse(response);
        if (response.status === ERROR_STATUS) {
          dispatch({ type: TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA, response: undefined });
        } else {
          dispatch({ type: TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA, response: response?.data });
        }
        dispatch({ type: TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING, flag: false });
      }
    );
  };
}

export const fetchProjectKeysData = (projectId, searchText, pagNumber) => {
  const payload = {
    projectId,
    search: searchText || "",
    pageNumber: pagNumber || DEFAULT_PAGE,
    recordPerPage: RECORD_10_PER_PAGE,
  };
  return (dispatch) => {
    async("testData/V2/getByProjectId", "GET", payload).then((response) => {
      response = updateResponse(response);
      dispatch({ type: TEST_DATA_LIST, response: response?.data });
    });
  };
};
