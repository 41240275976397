import { Col, Collapse, Dropdown, Input, Menu, Row, Tooltip } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import _ from "lodash";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addRecTestStep,
  captureExpandedSteps,
  changeDisableStep,
  changeRecTestStepActionName,
  deleteRecTestStep,
  duplicateRecTestStep,
  makeSubStep,
  onRecTestStepDrop,
  resetRecTestStepAction,
  showDeleteRecTestStepConfirmationModal,
  showImportRecTestStepModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  onRecTestStepDragOver,
  onRecTestStepDragStart,
  selectRecTestSteps,
  startPreview,
} from "../../../../actions/RecTestStepAction";

import {
  PLAY_TYPE,
  REC_TEST_STEP_PREVIEW_STATUS_TYPE,
  RESET_DEVICE_SCREEN_SOURCE,
  STEP,
  STEP_DISABLED,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
  splitValue,
} from "../../../../Constants";
import {
  blockCollapseAction,
  deleteModal,
  disableStep,
  disableStepsDragging,
  dragHoverOnStepOrAction,
  getRecTestStepStatusWithImage,
  isArrayNotUndefinedNotNullNotEmpty,
  isCurrentRecTestStepDisable,
  isSubStep,
  showUnSavedNotification,
} from "../../../../Util";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import RecTestStepAction from "../RecTestStepAction";
import UnSavedRecTestStepPopConfirm from "../UnSavedRecTestStepPopConfirm";

import { useEffect } from "react";
import {
  BLUE_ICON_COLOR,
  BUILD_TEST_DATA_SET_ICON,
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  DISABLE_ICON_COLOR,
  DRAG_AND_DROP_ICON,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
} from "../../../../Constants/SvgConstants";
import SvgLoader from "../../../../Util/SvgLoader";
import { resetAllRecScenarioStepIds, selectAllRecScenarioStepIds } from "../../../../actions/RecTestScenarioAction";
import { setTestDataSetAssigneeDetail } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import {
  getSelectedTestBlock,
  getTestDataSetByTestPlanId,
  showTestDataSetSelectionModal,
} from "../../../../actions/TestDataSetAction";
import AssignTestDataSetModal from "../../../ExecutionManager/JobDesigner/BuildJob/JobCriteriaOptions/AssignTestDataSetModal/AssignTestDataSetModal";
import {
  addConditionalStepMenu,
  conditionalMenu,
} from "../../ScenarioHeader/ScenarioSubHeader/ConditionalScenarioStep";
import HoverActionsOnRecTestStep from "../HoverActionsOnRecTestStep/HoverActionsOnRecTestStep";
import RecTestStepCondition, { getConditionalIcon } from "../RecTestStepCondition/RecTestStepCondition";
import styles from "./RecTestStep.module.scss";

const { Panel } = Collapse;

const RecTestStep = (props) => {
  const {
    recTestStep,
    parentIndex,
    projectId,
    parentRecTestStep,
    disableAction,
    isDeviceLoaded,
    index,
    parentSkipStep,
    recTestSteps,
    stepType,
    stepName,
    isChildRecTestStep,
  } = props;
  const dispatch = useDispatch();
  const [recTestStepId, setRectTestStepId] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);
  const [actionMenuVisible, setActionMenuVisible] = useState(false);
  const [generalActionMenuVisible, setGeneralActionMenuVisible] = useState(false);
  const [isDragOver, setIsDragOver] = useState(false);
  const [isChildDragOver, setIsChildDragOver] = useState(false);
  const [dragOverOnCollapse, setDragOverOnCollapse] = useState(false);
  const [isChildStepsDragEnter, setIsChildStepsDragEnter] = useState(false);

  useEffect(() => {
    // add and remove class on hoverEffect
    const hoverMenuVisible = document.getElementsByClassName("actionsMenuVisible");
    if (hoverMenuVisible[0]) {
      const collapseHeader = hoverMenuVisible[0].getElementsByClassName("ant-collapse-header");
      if (collapseHeader[0]) {
        collapseHeader[0].classList.add("visibleActionsMenu");
        const actionsWrapper = collapseHeader[0].getElementsByClassName("showRecTestStepActionsIconOnHover");
        if (actionsWrapper[0]) {
          actionsWrapper[0].classList.add("showActionsIconsOnHover");
        }
        return () => {
          collapseHeader[0] && collapseHeader[0].classList.remove("visibleActionsMenu");
          actionsWrapper[0] && actionsWrapper[0].classList.remove("showActionsIconsOnHover");
        };
      }
    }
  }, [menuVisible, actionMenuVisible, generalActionMenuVisible]);

  function CollapseBorder(element, addClass) {
    const parentClassName = element.parentNode.parentNode.parentNode;
    if (parentClassName) {
      const stepOrderNameBorder = parentClassName.getElementsByClassName("recTestStepCollapseBorder");
      if (stepOrderNameBorder[0]) {
        if (addClass) {
          stepOrderNameBorder[0].classList.add("hoverBorderBlue");
        } else {
          stepOrderNameBorder[0].classList.remove("hoverBorderBlue");
        }
      }
    }
  }

  function collapseBorderOnHover() {
    CollapseBorder(this, true);
  }

  function collapseBorderOnHoverOut() {
    CollapseBorder(this, false);
  }

  useEffect(() => {
    //add or remove collapseBorder color
    const collapseHeader = document.querySelectorAll(".stepCollapseBorder > div > div.ant-collapse-header");
    for (let i = 0; i < collapseHeader.length; i++) {
      collapseHeader[i].addEventListener("mouseenter", collapseBorderOnHover);
      collapseHeader[i].addEventListener("mouseleave", collapseBorderOnHoverOut);
    }
    return () => {
      for (let i = 0; i < collapseHeader.length; i++) {
        collapseHeader[i].removeEventListener("mouseenter", collapseBorderOnHover);
        collapseHeader[i].removeEventListener("mouseleave", collapseBorderOnHoverOut);
      }
    };
  }, []);

  const stepNo = stepName?.split(".").length;
  const { isPreviewRunning, selectedRectTestScenarioStepId, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );

  const { unSavedRecStepTag, unSavedRecElementGuid, expandedStepTags, expandedStepAssocIds } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const { selectTestBlockStep } = useSelector((state) => state.RecTestStepReducer);
  let disabled = isCurrentRecTestStepDisable(unSavedRecStepTag, recTestStep, disableAction) || isPreviewRunning;
  const isTestBlock = stepType === TEST_SCENARIOS ? recTestStep.isTestBlock === 1 || props.isTestBlock : false;
  const isSkipStep = parentSkipStep || recTestStep.isSkipStep === 1 || isTestBlock;
  const isChildSkipStep = parentSkipStep || recTestStep.isSkipStep === 1;
  const testDataSetOptions = generateTestDataSetOptions(dispatch, recTestSteps, recTestStep);
  const hideDragAndDropIcon = isChildSkipStep || (isTestBlock && recTestStep?.parentRecTestStepId);

  const subTestBlockStep =
    stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1 && recTestStep?.parentRecTestStepId;

  const handleMultiSelectStepId = (e, id) => {
    if (
      ((!disabled && !recTestStepId && !isSkipStep) || isTestBlock) &&
      (e.ctrlKey || e.metaKey) &&
      !subTestBlockStep
    ) {
      dispatch(selectAllRecScenarioStepIds(id));
    }
  };

  useEffect(() => {
    dispatch(resetAllRecScenarioStepIds());
  }, []);

  const showToggle =
    (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 0) ||
    (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1 && !recTestStep.parentRecTestStepId) ||
    stepType === TEST_STEP_BLOCKS;
  const Steps = () => {
    return (
      <div
        key={index}
        onDragStart={(event) =>
          !disabled || (isTestBlock && recTestStep?.testBlockRecTestStepId === undefined)
            ? dispatch(
                onRecTestStepDragStart(
                  event,
                  recTestStep,
                  recTestStep.orderId,
                  parentRecTestStep ? parentRecTestStep : undefined
                )
              )
            : undefined
        }
        onDragOver={(event) =>
          !disabled || (isTestBlock && recTestStep?.testBlockRecTestStepId === undefined)
            ? (dispatch(onRecTestStepDragOver(event)),
              setIsChildStepsDragEnter(true),
              isChildStepsDragEnter && setDragOverOnCollapse(false))
            : undefined
        }
        onDrop={(event) => {
          !isChildSkipStep && dispatch(onRecTestStepDrop(event, recTestStep, parentRecTestStep, index, recTestStep));
          setDragOverOnCollapse(false);
        }}
        onDragLeave={() => setDragOverOnCollapse(false)}
        id={recTestStep?.id}
      >
        {isArrayNotUndefinedNotNullNotEmpty(recTestStep.recTestSteps) ? (
          isSubStep(recTestStep.recTestSteps) ? (
            recTestStep.recTestSteps.map((childRecTestStep, key) => {
              return (
                <RecTestStep
                  key={key}
                  index={key}
                  parentRecTestStep={recTestStep}
                  recTestStep={childRecTestStep}
                  parentIndex={recTestStep.orderId}
                  isDeviceLoaded={isDeviceLoaded}
                  disableAction={disableAction}
                  projectId={projectId}
                  parentSkipStep={isChildSkipStep}
                  stepType={stepType}
                  stepName={stepName + "." + childRecTestStep?.orderId}
                  isTestBlock={isTestBlock}
                  isChildRecTestStep
                />
              );
            })
          ) : (
            <RecTestStepAction
              recTestSteps={recTestSteps}
              key={index}
              recTestStep={recTestStep}
              parentIndex={recTestStep.orderId}
              isDeviceLoaded={isDeviceLoaded}
              projectId={projectId}
              disableAction={disableAction || isPreviewRunning || isTestBlock}
              parentRecTestStep={parentRecTestStep}
              isSkipStep={isChildSkipStep}
              isTestBlock={isTestBlock}
              stepType={stepType}
              showToggle={showToggle}
              stepName={stepName}
              stepNo={stepNo}
            />
          )
        ) : (
          <>
            {!isChildSkipStep &&
              dragHoverOnStepOrAction(
                isChildDragOver,
                setIsChildDragOver,
                dispatch,
                disabled,
                recTestStep,
                recTestStep,
                0
              )}
            <div>
              {!isChildDragOver &&
                recTestStep?.recTestStepProperty?.isStepHasPrecondition === 0 &&
                recTestStep?.recTestStepProperty?.isLoopStep === 0 && (
                  <EmptyRecords description="Add a new sub-step or an Action/Verification step!" ignoreMargin={true} />
                )}
            </div>
          </>
        )}
        {!isChildSkipStep &&
          !_.isEmpty(recTestStep.recTestSteps) &&
          isSubStep(recTestStep.recTestSteps) &&
          dragHoverOnStepOrAction(
            isChildDragOver,
            setIsChildDragOver,
            dispatch,
            disabled,
            recTestStep,
            recTestStep,
            recTestStep?.recTestSteps?.length
          )}
      </div>
    );
  };

  const selectedStep =
    selectedRecStepForOperation?.id +
      "," +
      (selectedRecStepForOperation?.recTestScenarioRecTestStepAssocId ||
        selectedRecStepForOperation?.testBlockRecTestScenarioRecTestStepAssocId) ===
    recTestStep?.id +
      "," +
      (recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep?.testBlockRecTestScenarioRecTestStepAssocId);

  const childStepDisabled =
    (isChildSkipStep && recTestStep?.isSkipStep === 0) ||
    (isChildSkipStep && !recTestStep?.isSkipStep && recTestStep?.parentRecTestStep !== null);

  const isSelected = selectedRectTestScenarioStepId?.includes(
    stepType === TEST_SCENARIOS ? recTestStep?.recTestScenarioRecTestStepAssocId : recTestStep?.id
  );
  const selectedRectTestStep = !isSelected && selectedStep;
  const isUnsaved = showUnSavedNotification(recTestStep, unSavedRecStepTag);
  const selectRecTestStep = stepType === TEST_SCENARIOS ? [...expandedStepAssocIds] : [...expandedStepTags];
  const activeCollapse = selectRecTestStep?.includes(
    stepType === TEST_SCENARIOS
      ? recTestStep.recTestScenarioRecTestStepAssocId
        ? recTestStep.recTestScenarioRecTestStepAssocId
        : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep.tag
      : recTestStep.tag
  );

  const isDisableStep = recTestStep?.isSkipStep === 1 || parentSkipStep;
  const isRecTestStepChild = isChildRecTestStep;
  const stylesRecTestStepOrderName = isRecTestStepChild
    ? isDisableStep
      ? styles.childDisableStepOrder
      : styles.childStyledStepOrder
    : isDisableStep
    ? styles.disableStepOrderName
    : styles.styledStepOrder;

  let recTestStepCollapseClassNames = `recTestStepCollapse ${
    dragOverOnCollapse && !disabled && !isChildSkipStep && "border_onDragOver"
  } ${!isDisableStep && "recTestStepCollapseBorder"} collapseStepOrderBorder stepCollapseBorder recTestStepSteps `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  } else if (isSelected) {
    recTestStepCollapseClassNames += "border-blue ";
  } else if (selectedStep) {
    recTestStepCollapseClassNames += "border_darkBlue ";
  }

  const handleDragEnter = () => {
    setDragOverOnCollapse(true);
  };

  const handleDragLeave = (event) => {
    const newTarget = event.relatedTarget;
    const id = document.getElementById(recTestStep?.id);
    if (id) {
      if (!id.contains(newTarget)) {
        setDragOverOnCollapse(false);
      }
    }
    if (!id || !id.contains(newTarget)) {
      setIsChildStepsDragEnter(false);
      setDragOverOnCollapse(false);
    }
  };

  return (
    <>
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        key={recTestStep.id}
        title={recTestStep.isSkipStep === 1 ? STEP_DISABLED : ""}
        zIndex="100"
      >
        {childStepDisabled ? (
          <div className="hoverDragAndDrop"></div>
        ) : (
          dragHoverOnStepOrAction(
            isDragOver,
            setIsDragOver,
            dispatch,
            disabled,
            recTestStep,
            parentRecTestStep,
            index
            
          )
        )}
        <div
          className={`${styles.styledStepHeader} ${
            (menuVisible || actionMenuVisible || generalActionMenuVisible) && !isDisableStep && "actionsMenuVisible"
          }`}
          name="recTestStepHeader"
          key={recTestStep.id}
          id={recTestStep?.id}
          onDragStart={(event) => {
            if (!disabled) {
              dispatch(
                onRecTestStepDragStart(
                  event,
                  recTestStep,
                  recTestStep.orderId,
                  parentRecTestStep ? parentRecTestStep : undefined
                )
              );
            }
          }}
          onDragOver={(event) =>
            !disabled
              ? (!isChildStepsDragEnter && setDragOverOnCollapse(true), dispatch(onRecTestStepDragOver(event)))
              : undefined
          }
          onDragEnd={() => setDragOverOnCollapse(false)}
          onDrop={(event) =>
            recTestStep?.recTestStepProperty?.isSkipStep === 0 && dragOverOnCollapse && !childStepDisabled
              ? (() => {
                  dispatch(
                    captureExpandedSteps(
                      recTestStep.tag,
                      recTestStep.recTestScenarioRecTestStepAssocId
                        ? recTestStep.recTestScenarioRecTestStepAssocId
                        : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep.tag,
                      true
                    )
                  );

                  dispatch(onRecTestStepDrop(event, recTestStep, recTestStep, recTestStep?.recTestSteps?.length));
                  setDragOverOnCollapse(false);
                })()
              : undefined
          }
          onDragLeave={handleDragLeave}
        >
          <div className={styles.styledStepName}>
            <UnSavedRecTestStepPopConfirm
              recTestStep={recTestStep}
              unSavedRecStepTag={unSavedRecStepTag}
              setRectTestStepId={setRectTestStepId}
              unSavedRecElementGuid={unSavedRecElementGuid}
              stepType={stepType}
            />
            <Col span={24}>
              <Collapse
                className={(recTestStepCollapseClassNames += isDisableStep ? " disable_bg_color" : " bg__white")}
                key={recTestStep.id}
                activeKey={stepType === TEST_SCENARIOS ? [...expandedStepAssocIds] : [...expandedStepTags]}
                expandIcon={({ isActive }) => (
                  <div
                    className={`collapseIconOnHover ${styles.collapseIcon} ${isDisableStep && "collapseIconBgColor"}`}
                  >
                    <SvgLoader
                      iconName={
                        activeCollapse ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP
                      }
                    />
                  </div>
                )}
                expandIconPosition="start"
                isSkipStep={recTestStep.isSkipStep === 1 || parentSkipStep}
                isTestBlock={isTestBlock}
                onChange={(values) => {
                  dispatch(
                    captureExpandedSteps(
                      recTestStep.tag,
                      recTestStep.recTestScenarioRecTestStepAssocId
                        ? recTestStep.recTestScenarioRecTestStepAssocId
                        : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep.tag,
                      values.some(
                        (value) =>
                          value ==
                          (stepType === TEST_SCENARIOS
                            ? recTestStep.recTestScenarioRecTestStepAssocId
                              ? recTestStep.recTestScenarioRecTestStepAssocId
                              : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep.tag
                            : recTestStep.tag)
                      )
                    )
                  );
                  dispatch(selectRecTestSteps(recTestStep));
                }}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onDragOver={() => {
                  setDragOverOnCollapse(true);
                }}
              >
                <Panel
                  key={
                    stepType === TEST_SCENARIOS
                      ? recTestStep.recTestScenarioRecTestStepAssocId
                        ? recTestStep.recTestScenarioRecTestStepAssocId
                        : recTestStep?.testBlockRecTestScenarioRecTestStepAssocId + splitValue + recTestStep.tag
                      : recTestStep.tag
                  }
                  showArrow={true}
                  header={
                    <Row justify="space-between" align="middle">
                      <Col className={styles.recTestStep}>
                        <div className={styles.recTestStepTextInput}>
                          <Tooltip color={TOOLTIP_BG_COLOR} title={stepName.length > 8 && stepName}>
                            <div
                              className={`${
                                !isDisableStep && "onHoverStepOrderNameBorder"
                              } ${stylesRecTestStepOrderName} ${
                                (selectedRectTestScenarioStepId?.includes(
                                  stepType === TEST_SCENARIOS
                                    ? recTestStep?.recTestScenarioRecTestStepAssocId
                                    : recTestStep?.id
                                ) &&
                                  "border-blue") ||
                                (selectedStep && "border_darkBlue")
                              }`}
                              onClick={(e) => {
                                e.stopPropagation();
                                dispatch(selectRecTestSteps({ ...recTestStep, parentSkipStep }));
                                !unSavedRecStepTag &&
                                  handleMultiSelectStepId(
                                    e,
                                    stepType === TEST_SCENARIOS
                                      ? recTestStep?.recTestScenarioRecTestStepAssocId
                                      : recTestStep?.id
                                  );
                              }}
                              increaseWidth={parentIndex && recTestStep.recTestStepProperty?.action === STEP}
                            >
                              <span
                                className={`recTestStepName ${
                                  selectedRectTestStep ? "color__blue" : "color__darkGrayColor"
                                }`}
                              >
                                {stepName?.length >= 10 ? recTestStep?.orderId : stepName}
                              </span>
                            </div>
                          </Tooltip>
                          <span className={styles.conditionalIcon}>{getConditionalIcon(recTestStep)}</span>
                          <Input
                            collapsible={"false"}
                            className={styles.inputStyle}
                            type="text"
                            placeholder="Provide a title/description"
                            value={recTestStep.recTestStepProperty?.stepName}
                            disabled={disabled || isSkipStep}
                            onChange={(e) => {
                              dispatch(resetAllRecScenarioStepIds());
                              setRectTestStepId(recTestStep.id);
                              dispatch(changeRecTestStepActionName(e.target.value, recTestStep.tag));
                            }}
                            onClick={(e) => {
                              blockCollapseAction(e);
                              dispatch(selectRecTestSteps(recTestStep));
                            }}
                            onDragOver={() => {
                              setDragOverOnCollapse(true);
                              isChildStepsDragEnter && setIsChildStepsDragEnter(true);
                            }}
                            onDragLeave={handleDragLeave}
                          />
                        </div>

                        {!isDisableStep && unSavedRecStepTag === undefined && !isPreviewRunning && (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                              dispatch(selectRecTestSteps(recTestStep));
                            }}
                            className={!selectedStep && "showRecTestStepActionsIconOnHover"}
                          >
                            <HoverActionsOnRecTestStep
                              recTestStep={recTestStep}
                              showToggle={showToggle}
                              childStepDisabled={childStepDisabled}
                              parentSkipStep={parentSkipStep}
                              disabled={disabled}
                              projectId={projectId}
                              stepName={stepName}
                              stepNo={stepNo}
                              menuVisible={menuVisible}
                              setMenuVisible={setMenuVisible}
                              actionMenuVisible={actionMenuVisible}
                              setActionMenuVisible={setActionMenuVisible}
                              generalActionMenuVisible={generalActionMenuVisible}
                              setGeneralActionMenuVisible={setGeneralActionMenuVisible}
                            />
                          </div>
                        )}

                        <div className={styles.resultIcon}>
                          {getRecTestStepStatusWithImage(
                            recTestStep?.previewStatus === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED &&
                              recTestStep?.preconditionResult === REC_TEST_STEP_PREVIEW_STATUS_TYPE.FAILED
                              ? REC_TEST_STEP_PREVIEW_STATUS_TYPE.PRECONDITION_FAILED
                              : recTestStep?.previewStatus
                          )}
                          {isDisableStep && (
                            <div className={styles.disabledTextOrSwitch}>
                              <Tooltip
                                color={TOOLTIP_BG_COLOR}
                                title={!childStepDisabled && (recTestStep.isSkipStep === 1 ? "Enable" : "Disable")}
                              >
                                {disableStep(
                                  isTestBlock,
                                  unSavedRecStepTag,
                                  childStepDisabled,
                                  parentSkipStep,
                                  recTestStep,
                                  dispatch,
                                  isPreviewRunning,
                                  stepType,
                                  "disable_Switch"
                                )}
                              </Tooltip>
                            </div>
                          )}
                          {stepType === TEST_SCENARIOS &&
                            recTestStep?.isTestBlock === 1 &&
                            recTestStep?.testBlockRecTestStepId === undefined &&
                            recTestStep?.isSkipStep === 0 && (
                              <Col span={1} className={styles.buildTestDataSet}>
                                <Dropdown
                                  className={`${styles.buildTestDataSetDropdown} ${disabled && "cursor__not_allowed"}`}
                                  overlay={testDataSetOptions}
                                  trigger={["click"]}
                                  onClick={(e) => {
                                    dispatch(
                                      resetRecTestStepAction(
                                        recTestStep?.tag,
                                        recTestStep?.recTestScenarioRecTestStepAssocId
                                      )
                                    );
                                    blockCollapseAction(e);
                                  }}
                                >
                                  <Tooltip title="Build & Assign Test Data Profile" color={TOOLTIP_BG_COLOR}>
                                    <SvgLoader
                                      iconName={BUILD_TEST_DATA_SET_ICON}
                                      iconColor={
                                        recTestStep?.selectedTestDataSet === 1 ? BLUE_ICON_COLOR : DISABLE_ICON_COLOR
                                      }
                                    />
                                  </Tooltip>
                                </Dropdown>
                              </Col>
                            )}
                        </div>
                      </Col>

                      {/* {!isSubStep(recTestStep.recTestSteps) && (
                      <Col span={1} className="display-flex justify-center">
                        <AddRecTestStepAction
                          disabled={(disabled ? true : false) || isSkipStep}
                          parentRecTestStep={recTestStep}
                          isDeviceLoaded={isDeviceLoaded}
                          projectId={projectId}
                          stepType={stepType}
                        />
                      </Col>
                    )} */}
                    </Row>
                  }
                  extra={
                    !hideDragAndDropIcon && (
                      <div
                        className={styles.dragAndDrop}
                        onDragStart={(event) => {
                          if (!disabled) {
                            dispatch(
                              onRecTestStepDragStart(
                                event,
                                recTestStep,
                                recTestStep.orderId,
                                parentRecTestStep ? parentRecTestStep : undefined
                              )
                            );
                          }
                        }}
                        onDragOver={(event) => {
                          if (!disabled) {
                            dispatch(onRecTestStepDragOver(event));
                          }
                        }}
                        draggable={disableStepsDragging(recTestStep, unSavedRecStepTag, stepType) ? false : true}
                      >
                        <SvgLoader
                          iconName={DRAG_AND_DROP_ICON}
                          width="0.61669rem"
                          height="1.33331rem"
                          cursorGrab="grab"
                        />
                      </div>
                    )
                  }
                >
                  <div>
                    <RecTestStepCondition
                      recTestSteps={recTestSteps}
                      isDeviceLoaded={isDeviceLoaded}
                      recTestStep={recTestStep}
                      disableAction={disableAction}
                      isSkipStep={isSkipStep}
                      isTestBlock={isTestBlock}
                      stepType={stepType}
                      projectId={props.projectId}
                      setDragOverOnCollapse={setDragOverOnCollapse}
                    />
                    {Steps()}
                  </div>
                  <AssignTestDataSetModal
                    testDataSetAssigneeDetail={selectTestBlockStep}
                    projectId={projectId}
                    stepType={stepType}
                  />
                </Panel>
              </Collapse>
            </Col>
          </div>
        </div>
      </Tooltip>
    </>
  );
};

const generateTestDataSetOptions = (dispatch, recTestSteps, recTestStep) => {
  let menuItems = [];
  menuItems.push(
    <Menu.Item
      key={menuItems.length + 1}
      onClick={() => {
        dispatch(showTestDataSetSelectionModal(recTestStep));
        dispatch(getSelectedTestBlock(recTestSteps?.find((i) => i?.id === recTestStep?.id)));
        dispatch(
          getTestDataSetByTestPlanId(
            recTestStep?.id,
            "",
            TEST_SCENARIOS,
            recTestStep?.recTestScenarioRecTestStepAssocId
          )
        );
      }}
    >
      Assign Test Data Set
    </Menu.Item>
  );
  menuItems.push(
    <Menu.Item
      key={menuItems.length + 1}
      onClick={(e) => {
        dispatch(setTestDataSetAssigneeDetail(recTestStep));
      }}
    >
      Edit Test Data Set
    </Menu.Item>
  );
  return <Menu>{menuItems}</Menu>;
};
const generateMenuItems = (
  props,
  isPreviewRunning,
  parentRecTestStep,
  dispatch,
  isSkipStep,
  startDeviceStreamingStatus,
  isTestBlock,
  stepType
) => {
  let menuItems = [];
  const actionStep =
    props.recTestStep.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
    (_.isEmpty(props.recTestStep.recTestSteps) ? true : false);

  const showPlayMenu =
    !isSkipStep ||
    isSkipStep === undefined ||
    (stepType === TEST_SCENARIOS &&
      ((props.recTestStep?.isSkipStep === 0 &&
        props.recTestStep?.isTestBlock === 1 &&
        !props.recTestStep?.parentRecTestStepId) ||
        (props.recTestStep?.isTestBlock === 0 && props.recTestStep?.isSkipStep === 0))) ||
    (stepType === TEST_STEP_BLOCKS &&
      ((props.recTestStep?.isSkipStep === 0 && !props.recTestStep?.parentRecTestStepId) ||
        props.recTestStep?.isSkipStep === 0));

  if (!isPreviewRunning) {
    menuItems.push(
      (!isSkipStep ||
        (props.recTestStep.isSkipStep === 0 &&
          props.recTestStep.recTestStepProperty?.isSkipStep === 0 &&
          props.recTestStep.isTestBlock === 1 &&
          props.recTestStep.recTestScenarioRecTestStepAssocId !== undefined)) && (
        <Menu.Item
          disabled={isPreviewRunning}
          key={menuItems.length + 1}
          onClick={() => {
            if (!isPreviewRunning) {
              dispatch(
                showDeleteRecTestStepConfirmationModal(
                  props.recTestStep.id,
                  props.recTestStep.tag,
                  props.recTestStep.recTestScenarioRecTestStepAssocId,
                  false
                )
              );

              deleteModal(
                "Are you sure you want to delete Step?",
                () => {
                  dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
                  dispatch(deleteRecTestStep());
                },
                "",
                "",
                true,
                dispatch
              );
            }
          }}
        >
          Delete
        </Menu.Item>
      )
    );
    menuItems.push(
      showPlayMenu && (
        <SubMenu
          key={menuItems.length + 1}
          title="Play Step"
          disabled={isPreviewRunning || !startDeviceStreamingStatus}
        >
          <Menu.Item
            key={"SubMenu-1"}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_THIS_STEP,
                      props.recTestStep.tag,
                      props.recTestStep.recTestScenarioRecTestStepAssocId || props.recTestStep.id
                    )
                  )
                : undefined
            }
          >
            Play this step
          </Menu.Item>
          <Menu.Item
            key={"SubMenu-2"}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_TILL_HERE,
                      props.recTestStep.tag,
                      props.recTestStep.recTestScenarioRecTestStepAssocId || props.recTestStep.id
                    )
                  )
                : undefined
            }
          >
            Play till here
          </Menu.Item>
          {/* {((props.index !== 0 && props.parentIndex === undefined) ||
            (props.index !== 0 && props.parentIndex === 1) ||
            props.parentIndex > 1) && ( */}
          <Menu.Item
            key={"SubMenu-3"}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_FROM_HERE,
                      props.recTestStep.tag,
                      props.recTestStep.recTestScenarioRecTestStepAssocId || props.recTestStep.id
                    )
                  )
                : undefined
            }
          >
            Play from here
          </Menu.Item>
          {/* )} */}
        </SubMenu>
      )
    );
    (stepType === TEST_SCENARIOS || stepType !== TEST_SCENARIOS) &&
      (parentRecTestStep?.recTestStepProperty?.isSkipStep === 0 || parentRecTestStep === undefined) &&
      menuItems.push(
        <Menu.Item
          disabled={isPreviewRunning}
          key={menuItems.length + 1}
          onClick={() =>
            !isPreviewRunning
              ? dispatch(changeDisableStep(props.recTestStep.isSkipStep === 1 ? 0 : 1, props.recTestStep, stepType))
              : undefined
          }
        >
          {props.recTestStep.isSkipStep === 1 ? "Enable Step" : "Disable Step"}
        </Menu.Item>
      );
  }
  if (!isPreviewRunning && !isSkipStep) {
    if (props.recTestStep.recTestStepProperty?.action === STEP) {
      menuItems.push(
        <Menu.Item
          key={menuItems.length + 1}
          onClick={() => dispatch(makeSubStep(props.recTestStep, parentRecTestStep))}
        >
          Make Group step
        </Menu.Item>
      );
    }
    if (props.recTestStep.recTestSteps && props.recTestStep.recTestSteps.length > 0) {
      menuItems.push(
        <Menu.Item key={menuItems.length + 1} onClick={() => dispatch(duplicateRecTestStep(props.recTestStep))}>
          Duplicate Step
        </Menu.Item>
      );
    }
    if (
      props.recTestStep.recTestSteps &&
      (props.recTestStep.recTestSteps.length === 0 ||
        props.recTestStep.recTestSteps[0].recTestStepProperty?.action === STEP)
    ) {
      menuItems.push(
        <Menu.Item
          key={menuItems.length + 1}
          onClick={(e) => {
            dispatch(addRecTestStep(props.recTestStep));
          }}
        >
          Add Group Step
        </Menu.Item>
      );
      menuItems.push(conditionalMenu(props.recTestStep, dispatch));
    }
  }
  props.recTestStep.isSkipStep === 0 &&
    menuItems.push(
      <Menu.Item
        key={menuItems.length + 1}
        onClick={(e) => {
          dispatch(
            showImportRecTestStepModal(
              STEP,
              props.projectId,
              props.recTestStep,
              actionStep,
              props.recTestStep.recTestSteps
            )
          );
        }}
      >
        {!actionStep && !_.isEmpty(props.recTestStep.recTestSteps) ? "Import Action" : "Import Step"}
      </Menu.Item>
    );

  if (
    props.recTestStep.recTestSteps &&
    props.recTestStep.recTestSteps.length > 0 &&
    !isTestBlock &&
    props.recTestStep.isSkipStep === 0
  ) {
    menuItems.push(
      <SubMenu disabled={isPreviewRunning || props.isSkipStep} key={menuItems.length + 1} title="Add Condition to Step">
        {addConditionalStepMenu(props.recTestStep, dispatch)}
      </SubMenu>
    );
  }
  return <Menu>{menuItems}</Menu>;
};
export default RecTestStep;
