import { Button, Checkbox, Collapse, Input, Popover, Select, Space, Tooltip } from "antd";
import _ from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTestDataCustomValue,
  changeTestDataIsCustomReferenceTestData,
  changeTestDataReferenceTestData,
  resetTestDataCustomValue,
} from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { EYE_ICON } from "../../../../../../CdnImagePath";
import { SYSTEM_KEYS, TOOLTIP_BG_COLOR } from "../../../../../../Constants";
import TableComponent from "../../../../../CoreComponents/TableComponent/TableComponent";
import styles from "./AssignedTestDataListView.module.scss";
import { elementImage } from "../../../../../../Util";

const { Panel } = Collapse;
const { Option } = Select;

const AssignedTestDataListView = (props) => {
  const { testDataValues, title, isForView = false, refTestDataList } = props;
  const { testDataSetForEdit, testDataSetView } = useSelector((state) => state.TestDataSetReducer);
  const dispatch = useDispatch();
  const data = (listOfItem) => {
    return !_.isEmpty(listOfItem)
      ? listOfItem?.map((row, i) =>
          row.isForDefaultRefTestDataSetAssoc === 0
            ? {
                sr: <span className={styles.tableData}>{row.stepOrderPath}</span>,
                ...row,
              }
            : {}
        )
      : [];
  };
  const prepareColumns = () => {
    let columns = [];
    if (testDataSetView !== SYSTEM_KEYS) {
      columns = [
        ...columns,
        {
          title: "Step No.",
          dataIndex: "sr",
          align: "center",
          width:  "7%" ,
        },
        {
          title: "Step Path",
          width: "13%",
          align: "center",

          // dataIndex: "row
          render: (row) => {
            return (
              <Popover
                content={
                  <span>
                    {row.stepOrderPath} {row.stepPath}
                  </span>
                }
                title="Step Path"
              >
                {/* {(row.stepOrderPath ? "S-" + (row.stepOrderPath + ":") : "") + (row.stepPath ? row.stepPath : "")} */}
                <div> {row.stepName}</div>
              </Popover>
            );
          },
        },
        {
          title: "Used For",
          // dataIndex: "row",
          width: "15%",
          align: "center",
          render: (row) => (
            <Space>
              <div className={styles.tableData}>{row.useFor}</div>
              {elementImage(row?.stepImage) ? (
                <Popover
                  content={
                    row.stepImage.length > 0
                      ? row.stepImage.map((value, key) => {
                          return (
                            <img
                              key={key}
                              className="stepImage"
                              src={value}
                              height="450"
                              weight="225"
                              alt="Screenshot"
                            />
                          );
                        })
                      : ""
                  }
                  placement="left"
                  title="Element Image"
                  trigger="hover"
                >
                  <img src={EYE_ICON} alt="Element Screenshot" />
                </Popover>
              ) : (
                ""
              )}
            </Space>
          ),
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: "Key",
        // dataIndex: "row",
        align: "center",
        width: "15%",
        render: (row) =>
          row.isForDefaultRefTestDataSetAssoc === 0 ? (
            <div className={styles.tableData}>
              {row.name}
              <br />
              {"[Scope:" + row.scope + "]"}
            </div>
          ) : (
            ""
          ),
      },
      {
        title: (
          <div className={styles.tableTitle}>
            <div>Value</div>
            <div className={styles.tableSubTitle}>(From Execution Profile)</div>
          </div>
        ),
        // dataIndex: "row",
        width: "20%",
        align: "center",
        render: (row) => {
          let refTestData = undefined;
          if (row.defaultRefTestDataSetAssocId !== 0) {
            refTestData =
              refTestDataList &&
              refTestDataList.find(
                (refTestDataObj) => refTestDataObj.testDataSetAssocId === row.defaultRefTestDataSetAssocId
              );
          }

          return refTestData === undefined ? (
            <div className={styles.tableData}>
              {row.defaultValue && row.defaultValue !== "" && row.value !== row.defaultValue
                ? row.defaultValue
                : row.value}
            </div>
          ) : (
            <div className={styles.tableData}>
              <span className={styles.font_weight}>Referenced :</span>
              <span>{refTestData.stepPath}</span>
            </div>
          );
        },
      },
      {
        title: (
          <div className={styles.tableTitle}>
            <div>Custom Value</div>
            <div className={styles.tableSubTitle}>(For this Profile)</div>
          </div>
        ),
        // dataIndex: "row",
        width: "20%",
        align: "center",
        render: (row) =>
          (row.scope !== "System Key" || testDataSetView === SYSTEM_KEYS) &&
          row.isForDefaultRefTestDataSetAssoc === 0 && (
            <div className={styles.tooltipOrCheckBox}>
              {testDataSetView !== SYSTEM_KEYS && (
                <Tooltip title="Reference Test Data" color={TOOLTIP_BG_COLOR}>
                  <Checkbox
                    disabled={isForView}
                    checked={row.isCustomChecked || row.refTestDataSetAssocId !== 0}
                    onClick={(e) => dispatch(changeTestDataIsCustomReferenceTestData(e.target.checked, row))}
                    className={styles.checkBox}
                  />
                </Tooltip>
              )}
              {testDataSetView !== SYSTEM_KEYS && (row.isCustomChecked || row.refTestDataSetAssocId !== 0) ? (
                <Select
                  disabled={isForView}
                  placeholder="Please Select The Custom Value"
                  className="select-app-for-default"
                  value={row.refTestDataSetAssocId || undefined}
                  onChange={(e) => dispatch(changeTestDataReferenceTestData(e, row))}
                >
                  {refTestDataList &&
                    refTestDataList.length > 0 &&
                    refTestDataList.map((i, k) => {
                      return (
                        i.testDataSetAssocId !== row.testDataSetAssocId && (
                          <Option value={i.testDataSetAssocId} key={i.testDataSetAssocId}>
                            {"(S-" + i.stepOrderPath + ") " + i.name}
                          </Option>
                        )
                      );
                    })}
                </Select>
              ) : (
                <Input
                  disabled={isForView}
                  className={styles.input}
                  size="large"
                  placeholder="Enter Custom Test Data Value"
                  name="testDataCustomValue"
                  value={row.testDataCustomValue}
                  onChange={(e) => {
                    dispatch(changeTestDataCustomValue(e.target.value, row));
                  }}
                />
              )}
            </div>
          ),
      },
    ];
    if (!isForView) {
      columns = [
        ...columns,
        {
          title: "Actions",
          // dataIndex: "row",
          align: "center",
          width: "10%",
          render: (row) =>
            row.isForDefaultRefTestDataSetAssoc === 0 && (
              <div className={styles.actions}>
                <Button type="text" className="resetButton" onClick={() => dispatch(resetTestDataCustomValue(row))}>
                  Reset
                </Button>
                {/* <span className="cursor__pointer" onClick={() => dispatch(resetTestDataCustomValue(row))}>
                  Reset
                </span> */}
              </div>
            ),
        },
      ];
    }
    return columns;
  };

  return (
    <div className={styles.details}>
      {testDataSetForEdit && (
        <Collapse className={styles.collapse} defaultActiveKey={[]}>
          <Panel
            collapsible={true}
            header={title}
            key={title}
            // extra={
            //   <div className="text-center">
            //     {testDataSetView !== SYSTEM_KEYS && (
            //       <Button
            //         disabled={_.isEmpty(dataSetList) || isForView}
            //         className="ml-10 purple"
            //         title="Load Test Data"
            //         onClick={() => dispatch(loadTestDataSet(rowIdentifyData))}>
            //         <Space size={5}>
            //           <img src={PLUS_ICON} alt="add" className="cursor-pointer" /> <span>Load Test Data</span>
            //         </Space>
            //       </Button>
            //     )}
            //   </div>
            // }
          >
            <TableComponent data={data(testDataValues)} columns={prepareColumns()} testDataSetModal={true} />
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default AssignedTestDataListView;
