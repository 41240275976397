import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { acceptInvitation } from '../../actions/CommonActions';

const Invitation = (props) => {
    const dispatch = useDispatch()
    let token = props.match.params.token
    if (token === undefined) {
        const url = new URL(window.location.href);
        token = url.searchParams.get("token");
      }
    useEffect(() => {
        dispatch(acceptInvitation(token,true))
    }, [])
    return (null)
}

export default Invitation
