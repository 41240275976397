import { Collapse } from "antd";
import { JsonEditor } from "jsoneditor-react18";
import { default as React } from "react";
import { useDispatch } from "react-redux";
import styles from "./CreateStepFromJson.module.scss";

const CreateStepFromJson = (props) => {
  const {jsonData,onChange} = props;
  const dispatch = useDispatch();
  let data=[{
    "stepName":"",
    "stepAction":"",
    "stepDescription":"",
    "elementProperties":{
        "resourceId":"",
        "text":""
    }
},{
    "stepName":"",
    "stepAction":"",
    "stepDescription":"",
    "elementProperties":{
        "resourceId":"",
        "text":""
    }
}]
 
  return (
    <div className={styles.jsonEditor}>
        <JsonEditor
            sortObjectKeys={false}
            value={jsonData}
            mode={"code"}
            onChange={onChange}
        />
    </div>
  );
};


export default CreateStepFromJson;
