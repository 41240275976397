import { Checkbox, Input } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeElementLoadTime,
  changeSwipeToBringElementToCenter,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { hasNotAllowedNegativeValue, isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import styles from "./RecElementSettings.module.scss";

const RecElementSettings = (props) => {
  const { data, recElement, disabled, isSkipStep } = props;
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const dispatch = useDispatch();

  const disableSetDefault =
    disabled ||
    isSkipStep ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement.guid);
  return (
    <div className={styles.elementSettings}>
      <div className={styles.divider}>
        <div className={styles.dividerText}>Settings</div>
      </div>
      <div className={styles.details}>
        <div className={styles.mainContinuer}>
          <div className={styles.text}>Max Wait time to find Element (secs)</div>
          <div className={styles.inputOrDefaultButton}>
            <div className={styles.inputOrText}>
              <Input
                disabled={
                  disabled ||
                  isPreviewRunning ||
                  isSkipStep ||
                  (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                    unSavedRecElementGuid !== recElement.guid)
                }
                value={recElement.elementMaxLoadTime}
                type="number"
                min={0}
                onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
                onChange={(e) => dispatch(changeElementLoadTime(e.target.value, data.tag, recElement?.guid))}
                placeholder="Default Element Load Time"
                className={styles.input}
              />
            </div>
            {recElement.elementMaxLoadTime !== 4 && (
              <div className={styles.button}>
                <div
                  className={`${styles.defaultButton} ${disableSetDefault && styles.disableButton}`}
                  onClick={() => {
                    !disableSetDefault && dispatch(changeElementLoadTime(4, data.tag, recElement?.guid));
                  }}
                >
                  <span className={disableSetDefault && styles.disableButton}>Set Default</span>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.checkBox}>
          <Checkbox
            disabled={
              isPreviewRunning ||
              disabled ||
              isSkipStep ||
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement.guid)
            }
            checked={recElement.swipeToBringElementToCenter === 1}
            onChange={(e) =>
              !disabled
                ? dispatch(changeSwipeToBringElementToCenter(e.target.checked, data.tag, recElement?.guid))
                : undefined
            }
          />
          <span className={styles.checkBoxText}>Attempt to bring Element into full view (to center of screen)</span>
        </div>
      </div>
    </div>
  );
};

export default RecElementSettings;
