import { async } from "../api/automation";
import {
  GET_INVITATION_LIST,
  SUCCESS_STATUS,
  RESEND_INVITATION_REQUEST,
  DELETE_INVITATION_REQUEST,
  INVITATION_LOADING,
  RECORD_10_PER_PAGE,
  GET_INVITATION_LOADING,
} from "../Constants";
import { updateResponse } from "../Util";

export function getInvitationList(orgId, invitationType, pageNumber) {
  return async (dispatch, getState) => {
    let data = {
      orgId: orgId,
      recordsPerPage: RECORD_10_PER_PAGE,
      pageNumber: pageNumber ? pageNumber : 1,
      invitationType: invitationType || "",
    };
    dispatch({ type: GET_INVITATION_LOADING, response: { isLoading: true } });
    return async("invitation/pendingInvitationRequest", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_INVITATION_LIST, response });
      dispatch({ type: GET_INVITATION_LOADING, response: { isLoading: false } });
    });
  };
}

export function resendInvitationRequest(data) {
  return async (dispatch, getState) => {
    return async("invitation/resend", "POST", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: RESEND_INVITATION_REQUEST, response });
    });
  };
}

export function deleteInvitationRequest(data, setSelectAllTestPlan, orgId, invitationType) {
  return async (dispatch, getState) => {
    return async("invitation/delete", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        setSelectAllTestPlan && setSelectAllTestPlan([]);
        dispatch({ type: DELETE_INVITATION_REQUEST, response });
        dispatch(getInvitationList(orgId, invitationType));
      }
    });
  };
}

export function setLoaderForInvitation(flag) {
  return async (dispatch, getState) => {
    dispatch({ type: INVITATION_LOADING, flag });
  };
}
