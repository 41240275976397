import React from "react";
import { Button, Space } from "antd";

const SaveResetButton = (props) => {
  const { submitLabel, resetLabel, submitDisable, resetDisable, handleSubmit, handleReset } = props;
  return (
    <Space size={10}>
      <Button type="text" className="resetButton" disabled={resetDisable} onClick={() => handleReset()}>
        {resetLabel}
      </Button>
      <Button type="primary" className="orange" disabled={submitDisable} onClick={() => handleSubmit()}>
        {submitLabel}
      </Button>
      
    </Space>
  );
};

export default SaveResetButton;
