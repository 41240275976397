import { Collapse, Popover, Space } from "antd";
import React from "react";
import { SYSTEM_KEYS } from "../../../../../../Constants";
import { EYE_ICON } from "../../../../../../CdnImagePath";
import TableComponent from "../../../../../CoreComponents/TableComponent/TableComponent";
import styles from "./ViewTestDataSetListV2.module.scss";
import { elementImage } from "../../../../../../Util";

const { Panel } = Collapse;

const ViewDefaultTestDataListViewV2 = ({ stepType, testDataValues, title, refTestDataList }) => {
  const renderValue = (row) => {
    let refTestData = undefined;
    if (row?.defaultRefTestDataSetAssocId !== 0) {
      refTestData = refTestDataList.find(
        (refTestDataObj) => refTestDataObj.testDataSetAssocId === row.defaultRefTestDataSetAssocId
      );
    }
    return refTestData === undefined ? (
      <div className={styles["column-details"]}>
        {row?.defaultValue && row.defaultValue !== "" && row.testDataCustomValue !== row.defaultValue
          ? row.defaultValue
          : row.value}
      </div>
    ) : (
      <div className={styles["column-details"]}>
        <span className={styles["font-weight"]}>Referenced :</span>
        <span>{refTestData.stepPath}</span>
      </div>
    );
  };

  const prepareColumns = () => {
    let columns = [];
    if (stepType !== SYSTEM_KEYS) {
      columns = [
        {
          title: "Step No.",
          dataIndex: "stepNo",
          align: "center",
          width: "7%",
        },
        {
          title: "Step",
          dataIndex: "step",
          width: "17.5%",
          align: "center",
        },
        {
          title: "Used For",
          dataIndex: "usedFor",
          width: "15%",
          align: "center",
        },
      ];
    }

    columns = [
      ...columns,
      {
        title: "Key",
        dataIndex: "testDataSetKey",
        align: "center",
        width: "15%",
      },
      {
        title: "Value",
        dataIndex: "value",
        width: "20%",
        align: "center",
      },
    ];
    return columns;
  };

  const viewTestDataSetListView = testDataValues?.map((row) => ({
    stepNo: <div className={styles["column-details"]}>{row.stepOrderPath}</div>,
    step: (
      <Popover
        content={
          <span>
            {row.stepOrderPath} {row.stepPath}
          </span>
        }
        title="Step Path"
      >
        {row.stepName}
      </Popover>
    ),
    usedFor: (
      <Space>
        <span className={styles["column-details"]}>{row.useFor}</span>
        {elementImage(row?.stepImage) ? (
          <Popover
            content={
              row?.stepImage?.length > 0
                ? row?.stepImage?.map((value, key) => {
                    return (
                      <img key={key} className="stepImage" src={value} height="450" weight="225" alt="Screenshot" />
                    );
                  })
                : ""
            }
            placement="left"
            title="Element Image"
            trigger="hover"
          >
            <img src={EYE_ICON} alt="Element Screenshot" />
          </Popover>
        ) : (
          ""
        )}
      </Space>
    ),
    testDataSetKey:
      row?.isForDefaultRefTestDataSetAssoc === 0 ? (
        <div className={styles["column-details"]}>
          {row.name}
          <br />
          {"[Scope:" + row.scope + "]"}
        </div>
      ) : (
        ""
      ),
    value: renderValue(row),
  }));

  return (
    <div className="m-10">
      {testDataValues && (
        <Collapse defaultActiveKey={[]}>
          <Panel collapsible={true} header={title} key={"Default"}>
            <TableComponent data={viewTestDataSetListView} columns={prepareColumns()} testDataSetModal={true} />
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default ViewDefaultTestDataListViewV2;
