import React from "react";
import styles from "./TestStepVerificationRules.module.scss";
import { highlightResult } from "../TestPlanResultDetailPreconditionList";
const splitTextValuesWithDoubleHash = (value) => {
  let splitValues = [];
  if (value.includes("##")) {
    value.split("##").map((i, key) => {
      splitValues.push(
        <>
          {key > 0 && <span className={styles.orBetweenText}> OR </span>}
          <span key={key}>{i}</span>
        </>
      );
    });
  } else {
    splitValues.push(<span>{value}</span>);
  }
  return splitValues;
};
const TestStepVerifyElementProperty = (props) => {
  const { recStepVerifyElementProperty, elementText } = props;
  return (
    <>
      <div className={styles.identificationRules}>
        <span className={styles.verificationType}>{recStepVerifyElementProperty?.type}</span>
        {recStepVerifyElementProperty?.key ? (
          <span className={styles.verificationKeyOrValue}> [{recStepVerifyElementProperty?.key}]</span>
        ) : undefined}
        {recStepVerifyElementProperty?.value ? (
          <span className={styles.verificationKeyOrValue}>[{recStepVerifyElementProperty?.value}]</span>
        ) : undefined}
        {recStepVerifyElementProperty?.testDataId ? (
          <span>
            [<span className={styles.verificationKeyOrValue}>Key = </span>
            <span>{recStepVerifyElementProperty?.testDataName}</span>
            <span className={styles.verificationKeyOrValue}>Value =</span>
            <span>{recStepVerifyElementProperty?.testDataDefaultValue}</span>]
          </span>
        ) : undefined}
        {recStepVerifyElementProperty?.applicationStringsKey &&
        recStepVerifyElementProperty?.applicationStringsValue ? (
          <span className={styles.verificationKeyOrValue}>
            [{splitTextValuesWithDoubleHash(recStepVerifyElementProperty?.applicationStringsValue)}]
          </span>
        ) : undefined}
        <span className="ml-5">{highlightResult(recStepVerifyElementProperty?.result)}</span>
      </div>
    </>
  );
};

export default TestStepVerifyElementProperty;
