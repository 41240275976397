import React from "react";
import { CREATE_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import CustomButtonV2 from "../CustomButtonV2/CustomButtonV2";
import styles from "./CommonHeaderV2.module.scss";

const CommonHeaderV2 = ({ headerText, handleOk, label, className }) => {
  return (
    <div className={`${styles["main-container"]} ${!className && styles["margin_Top"]}`}>
      <div className={className ? className : styles["main--header-text"]}> {headerText} </div>
      {label && (
        <div className={styles["header--or-button"]}>
          <CustomButtonV2
            iconColor={WHITE_COLOR}
            buttonIcon={CREATE_ICON}
            buttonLabel={label}
            handleClick={() => handleOk()}
            className="common_Green_Button"
          />
        </div>
      )}
    </div>
  );
};

export default CommonHeaderV2;
