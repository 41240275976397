import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { ACTION_NOT_VIEW, TEST_BLOCK_DESIGNER, TOOLTIP_BG_COLOR, defaultDateTimeFormat } from "../../../../Constants";
import { displayUTCDateFormatWithToolTip, getPermissionType, permissionTypeIcon } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import { editTestStepBlocks } from "../../../../actions/TestBlocksAction";
import SelectionCheckBoxV2 from "../../../CommonComponents/SelectionCheckBoxV2/SelectionCheckBoxV2";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./TestBlockDetailsColumnsV2.module.scss";

const TestBlockInfoColumnV2 = ({
  row,
  selectAllScenario,
  setSelectAllScenario,
  isTestStepBlocksLoadModel,
  isTestStepBlocksForImport,
  projectId,
  navigateToEditBuildScenario,
}) => {
  const dispatch = useDispatch();
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);

  //Destructure of object
  const {
    allowDelete,
    isPrivate,
    recTestStepRights,
    recTestStepProperty,
    createdBy,
    lastUpdatedBy,
    updatedOn,
    stepsWithTestData,
    currentAndTotalTestDataProfile,
  } = row ? row : {};

  const handleOnClick = () => {
    navigateToEditBuildScenario(TEST_BLOCK_DESIGNER);
    dispatch(editTestStepBlocks(row, projectId));
  };

  return (
    <div className={`${allowDelete === ACTION_NOT_VIEW && styles["table-details"]}  display__flex ml-5`}>
      {(!isTestStepBlocksLoadModel || isTestStepBlocksForImport) && (
        <SelectionCheckBoxV2 row={row} selectAllData={selectAllScenario} setSelectAllData={setSelectAllScenario} />
      )}
      <div
        className={`${allowDelete === ACTION_NOT_VIEW && styles["permission_Icons_And_Name"]} ${
          styles["icon-and-details"]
        }`}
      >
        <div className={styles["permission-tooltip"]}>
          <Tooltip title={getPermissionType(isPrivate, recTestStepRights)} color={TOOLTIP_BG_COLOR}>
            <div>
              <SvgLoader iconName={permissionTypeIcon(getPermissionType(isPrivate, recTestStepRights))} />
            </div>
          </Tooltip>
        </div>

        <div className={styles["test_Block_Info_Details"]}>
          <ShowTooltipV2
            text={recTestStepProperty?.stepName}
            className={styles["step-name"]}
            handleOnClick={() => handleOnClick()}
          />
          {loggedInUserDetails?.userEmail !== row?.createdByEmail && (
            <div>
              <span className={styles["label"]}> Created By: </span>
              <span className={styles["label-name"]}>{createdBy || "NA"}</span>
            </div>
          )}
          <div>
            <span className={styles["label"]}>Last Updated By: </span>
            <span className={styles["label-name"]}>
              {" "}
              {lastUpdatedBy || "NA"} On{" "}
              {updatedOn ? displayUTCDateFormatWithToolTip(updatedOn, defaultDateTimeFormat) : "NA"}
            </span>
          </div>

          {stepsWithTestData && (
            <div>
              {" "}
              <span className={styles["label"]}>Steps with TestData:</span>
            </div>
          )}
          {currentAndTotalTestDataProfile && (
            <div>
              {" "}
              <span className={styles["label"]}>Current TestData Profile & Total TestData Keys in Profile:</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestBlockInfoColumnV2;
