import history from "../history";
import {
  CHANGE_DEVICE_STATUS,
  ERROR_TYPE,
  GET_ALL_DEVICES,
  GET_ORG_DEVICE_LIST,
  ORG_DEVICE_LOADING,
  POST_ASSIGN_DEVICE,
  RECORD_10_PER_PAGE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
} from "../Constants";
import { async } from "../api/automation";
import { showNotification, updateResponse } from "../Util";

export function goToDeviceDetailListScreen(organizationId) {
  return () => {
    history.push(`/${organizationId}/deviceDetailsList`);
  };
}

export function getOrgDevice(data) {
  return async (dispatch, getState) => {
    dispatch({ type: ORG_DEVICE_LOADING });
    return async("device/getDevicesFromOrg", "GET", {
      orgId: data.orgId,
      pageNumber: data.pageNumber ? data.pageNumber : "1",
      recordPerPage: data.recordPerPage,
      searchKeywords: data.searchKeywords,
    }).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_ORG_DEVICE_LIST, response, data });
      dispatch({ type: ORG_DEVICE_LOADING });
    });
  };
}

export function makeDeviceOnline(orgId, targetUniqueId, currentOrgDevicePage, recordPerPage) {
  return async (dispatch, getState) => {
    let obj = {
      orgId: orgId,
      targetUniqueId: targetUniqueId,
    };
    return async("device/makeDeviceOnline", "POST", obj).then((response) => {
      response = updateResponse(response);
      dispatch(
        getOrgDevice({
          orgId: orgId,
          pageNumber: currentOrgDevicePage,
          recordPerPage: recordPerPage,
          searchKeywords: "",
        })
      );
      dispatch({ type: CHANGE_DEVICE_STATUS, response });
    });
  };
}

export function makeDeviceOffline(orgId, targetUniqueId, currentOrgDevicePage, recordPerPage) {
  return async (dispatch, getState) => {
    let obj = {
      orgId: orgId,
      targetUniqueId: targetUniqueId,
    };
    return async("device/makeDeviceOffline", "POST", obj).then((response) => {
      response = updateResponse(response);
      dispatch(
        getOrgDevice({
          orgId: orgId,
          pageNumber: currentOrgDevicePage,
          recordPerPage: recordPerPage,
          searchKeywords: "",
        })
      );
      dispatch({ type: CHANGE_DEVICE_STATUS, response });
    });
  };
}
export function editDeviceList(obj, searchKeyword, handleCloseModal) {
  return async (dispatch, getState) => {
    let { currentOrgDevicePage } = getState().OrgDeviceReducer;
    dispatch({ type: ORG_DEVICE_LOADING });
    return async("device/assignDevice", "POST", obj).then((response) => {
      response = updateResponse(response);
      dispatch({ type: POST_ASSIGN_DEVICE, response });
      if (response && response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message, "");
        dispatch(
          getOrgDevice({
            orgId: obj?.orgId,
            pageNumber: currentOrgDevicePage,
            recordPerPage: RECORD_10_PER_PAGE,
            searchKeywords: searchKeyword || "",
          })
        );
      } else {
        showNotification(ERROR_TYPE, response.message, "");
      }
      dispatch({ type: ORG_DEVICE_LOADING });
      handleCloseModal && handleCloseModal();
    });
  };
}
export function getAllDevice(obj) {
  return async (dispatch, getState) => {
    return async("device/getActiveDevicesFromOrg", "GET", obj).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_ALL_DEVICES, response });
    });
  };
}
