import {
  BuildAWSDeviceFarmModal,
  BuildGeneralTabModal,
  BuildS3ConfigTabModal,
} from "../Components/ProjectSettings/BuildProjectSettingModal";
import {
  AWS_DEVICE_LOADING,
  CHANGE_AWS_DEVICE_FARM_TAB_DATA,
  CHANGE_GENERAL_TAB_DATA,
  CHANGE_S3CONFIG_TAB_DATA,
  ERROR_TYPE,
  EXTERNAL_FARM_LOADING,
  GET_AWS_DEVICE_FARMS,
  GET_AWS_POOLS,
  GET_AWS_PROJECTS,
  GET_EXTERNAL_FARM_DATA_CENTER_LIST,
  GET_EXTERNAL_FARM_DETAILS,
  GET_LAMBDA_TEST_DETAILS,
  GET_PROJECT_DETAIL_SETTING,
  GET_TEST_RAIL_CONFIG_DATA,
  GET_TEST_RAIL_CONFIG_PROJECT_LIST,
  HANDLE_DISABLE_BUTTON,
  LAMBDA_TEST_LOADING,
  PROJECT_LOADING,
  RESET_STATE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TEST_RAIL_LOADING,
  UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS,
  VALIDATE_EXTERNAL_FARM_CREDENTIALS,
  VALIDATE_SAUCELABS_CREDENTIALS
} from "../Constants";
import { showNotification, updateResponse } from "../Util";
import { async } from "../api/automation";

export function changeGeneralTabData(generalTabData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_GENERAL_TAB_DATA, data: generalTabData });
  };
}

export function changeS3ConfigTabData(s3ConfigTabData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_S3CONFIG_TAB_DATA, data: s3ConfigTabData });
  };
}

export function changeAwsDeviceFarmTabData(awsDeviceFarmData) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_AWS_DEVICE_FARM_TAB_DATA, data: awsDeviceFarmData });
  };
}

export function getProjectDetailsForSetting(data) {
  return async (dispatch, getState) => {
    let preparedObject = {
      projectId: data,
    };
    return async("project/getProjectDetailsForSetting", "GET", preparedObject).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_PROJECT_DETAIL_SETTING, response });
      dispatch({
        type: CHANGE_GENERAL_TAB_DATA,
        data: new BuildGeneralTabModal(response.data),
      });
      dispatch({
        type: CHANGE_S3CONFIG_TAB_DATA,
        data: new BuildS3ConfigTabModal(response.data),
      });
    });
  };
}

export function updateGeneralSettings(projectId) {
  return async (dispatch, getState) => {
    dispatch({ type: PROJECT_LOADING, response: { isLoading: true } });
    let preObj = {
      id: parseInt(projectId),
      appName: getState().ProjectSettingsReducer.generalTabDetails.appName,
      name: getState().ProjectSettingsReducer.generalTabDetails.projectName,
      description: getState().ProjectSettingsReducer.generalTabDetails.projectDes,
      teamId: getState().ProjectSettingsReducer.generalTabDetails.selectedTeam,
      projectMembers: getState().ProjectSettingsReducer.generalTabDetails.selectedMembers,
    };

    return async("project/updateGeneralSettings", "POST", preObj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
        dispatch(getProjectDetailsForSetting(preObj.id));
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function updateS3Settings(projectId, fileList) {
  return async (dispatch, getState) => {
    dispatch({ type: PROJECT_LOADING, response: { isLoading: true } });
    var formData = new FormData();
    formData.append("projectId", parseInt(projectId));
    formData.append("cfDistributionDomain", getState().ProjectSettingsReducer.S3ConfigTabDetails.CfDistributionDomain);
    formData.append("cfKeyPairId", getState().ProjectSettingsReducer.S3ConfigTabDetails.CfKeyPairId);
    formData.append(
      "preSignedUrlExpirationDays",
      parseInt(getState().ProjectSettingsReducer.S3ConfigTabDetails.PreSignedUrlExpirationDays)
    );
    formData.append("s3AccessKeyId", getState().ProjectSettingsReducer.S3ConfigTabDetails.S3AccessKeyId);
    formData.append("s3Bucket", getState().ProjectSettingsReducer.S3ConfigTabDetails.S3Bucket);
    formData.append("s3BucketRegion", getState().ProjectSettingsReducer.S3ConfigTabDetails.S3BucketRegion);
    formData.append("s3Host", getState().ProjectSettingsReducer.S3ConfigTabDetails.S3Host);
    formData.append("s3SecretAccessKey", getState().ProjectSettingsReducer.S3ConfigTabDetails.S3SecretAccessKey);
    formData.append("cloudFile", fileList[0] || null);
    return async("project/updateS3Settings", "POST_WITH_FILE", formData).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });

        dispatch(getProjectDetailsForSetting(projectId));
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function getAwsProjectsList(awsDeviceFarmTabDetails) {
  return async (dispatch, getState) => {
    dispatch({ type: PROJECT_LOADING, response: { isLoading: true } });

    if (awsDeviceFarmTabDetails) {
      awsDeviceFarmTabDetails["accessKeyId"] = awsDeviceFarmTabDetails.accessKey;
    }
    const awsDeviceFarmData = awsDeviceFarmTabDetails
      ? awsDeviceFarmTabDetails
      : getState().ProjectSettingsReducer?.awsDeviceFarmTabDetails;
    let keys = {
      accessKeyId: awsDeviceFarmData?.accessKeyId,
      secretAccessKey: awsDeviceFarmData?.secretAccessKey,
    };
    return async("awsDeviceFarmProject/getAwsProjects", "POST", keys).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_AWS_PROJECTS, response });
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
    });
  };
}

export function getAwsDeviceFarmPoolsLists(awsDeviceFarmTabDetails) {
  return async (dispatch, getState) => {
    dispatch({ type: AWS_DEVICE_LOADING, response: { isLoading: true } });
    if (awsDeviceFarmTabDetails) {
      awsDeviceFarmTabDetails["accessKeyId"] = awsDeviceFarmTabDetails?.accessKey;
      awsDeviceFarmTabDetails["awsDeviceFarmProjects"] = awsDeviceFarmTabDetails?.projects;
    }
    const awsDeviceFarmData = awsDeviceFarmTabDetails
      ? awsDeviceFarmTabDetails
      : getState().ProjectSettingsReducer.awsDeviceFarmTabDetails;
    let obj = {
      accessKeyId: awsDeviceFarmData?.accessKeyId,
      secretAccessKey: awsDeviceFarmData?.secretAccessKey,
      awsDeviceFarmProjects: awsDeviceFarmData?.awsDeviceFarmProjects.map((i) => {
        return {
          awsProjectName: i.awsProjectName,
          awsProjectArn: i.awsProjectArn,
        };
      }),
    };
    return async("awsDeviceFarmPool/getAwsDeviceFarmPools", "POST", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_AWS_POOLS, response });
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: AWS_DEVICE_LOADING, response: { isLoading: false } });
    });
  };
}

export function saveAwsDeviceFarmProject(projectId) {
  return async (dispatch, getState) => {
    const awsDeviceFarmData = getState().ProjectSettingsReducer.awsDeviceFarmTabDetails;
    let obj = {
      accessKeyId: awsDeviceFarmData?.accessKeyId,
      secretAccessKey: awsDeviceFarmData?.secretAccessKey,
      awsDeviceFarmProjects: awsDeviceFarmData?.awsDeviceFarmProjects.map((i) => {
        return {
          awsProjectName: i.awsProjectName,
          awsProjectArn: i.awsProjectArn,
          awsDevicePools: i?.awsDevicePool?.map((p) => {
            return {
              awsDevicePoolArn: p.arn,
              awsDevicePoolName: p.name,
            };
          }),
        };
      }),
      projectId: projectId,
    };

    dispatch({ type: PROJECT_LOADING, response: { isLoading: true } });
    return async("awsDeviceFarmProject/save", "POST", obj).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
        dispatch({ type: UPDATE_PREVIOUS_AWS_DEVICE_FARM_TAB_DETAILS });
        dispatch(getAWSDeviceFarm(projectId));
      } else {
        showNotification(ERROR_TYPE, response.message);
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function getAWSDeviceFarm(projectId) {
  return async (dispatch) => {
    return async("awsDeviceFarmProject/getByProjectId", "GET", { projectId }).then((response) => {
      response = updateResponse(response);
      if (response.data && response.data.projects && response.data.projects.length !== 0) {
        dispatch(getAwsProjectsList(response.data));
        dispatch(getAwsDeviceFarmPoolsLists(response.data));
      }
      new Promise((resolve) => resolve());
      dispatch({ type: GET_AWS_DEVICE_FARMS, response });
      dispatch({
        type: CHANGE_AWS_DEVICE_FARM_TAB_DATA,
        data: new BuildAWSDeviceFarmModal(response.data),
      });
    });
  };
}

export function getTestRailConfig(projectId) {
  return async (dispatch, getState) => {
    dispatch({ type: PROJECT_LOADING, response: { isLoading: true } });
    return async("testRailConfig/getByProjectId", "GET", { projectId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_TEST_RAIL_CONFIG_DATA, response });
        dispatch({ type: PROJECT_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function getTestRailProjectOnLoad(data) {
  return async (dispatch, getState) => {
    return async("testRail/getProjectsByUser", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_RAIL_CONFIG_PROJECT_LIST, response });
    });
  };
}

export function getTestRailProject(data) {
  return async (dispatch, getState) => {
    dispatch({ type: TEST_RAIL_LOADING, response: { isLoading: true } });
    return async("testRail/getProjectsByUser", "GET", data).then((response) => {
      response = updateResponse(response);
      dispatch({ type: GET_TEST_RAIL_CONFIG_PROJECT_LIST, response });
      dispatch({ type: TEST_RAIL_LOADING, response: { isLoading: false } });
    });
  };
}

export function updateTestRailConfig(data) {
  return async (dispatch, getState) => {
    return async("testRailConfig/customSave", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.data);
        dispatch(getTestRailConfig(data.projectId));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}


export function setSauceLabsValidation(flag) {
  return async (dispatch) => {
    dispatch({ type: VALIDATE_SAUCELABS_CREDENTIALS, flag: flag });
  };
}

export function resetTestRailDetails() {
  return async (dispatch) => {
    dispatch({ type: RESET_STATE });
  };
}

export function handleDisableButton(flag) {
  return async (dispatch) => {
    dispatch({ type: HANDLE_DISABLE_BUTTON, flag });
  };
}

//LambdaTest
export function getLambdaTestDetail(projectId) {
  return async (dispatch) => {
    dispatch({ type: LAMBDA_TEST_LOADING, flag: true });
    return async("lambdatestConfig/getByProjectId", "GET", { projectId: projectId }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_LAMBDA_TEST_DETAILS, data: response?.data });
      }
      dispatch({ type: LAMBDA_TEST_LOADING, flag: false });
    });
  };
}

export function getExternalFarmDataCenterList(farmName) {
  return async (dispatch) => {
    return async("externalFarmDataCenters", "GET", { farmName: farmName }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_EXTERNAL_FARM_DATA_CENTER_LIST, data: response?.data });
      }
    });
  };
}

export function getExternalFarmConfig(projectId, farmName) {
  return async (dispatch) => {
    dispatch({ type: EXTERNAL_FARM_LOADING, flag: true });
    return async("externalFarmConfig", "GET", { projectId: projectId, farmName }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: GET_EXTERNAL_FARM_DETAILS, data: response?.data,farmName });
      }
      dispatch({ type: EXTERNAL_FARM_LOADING, flag: false });
    });
  };
}

export function validateExternalFarmCredentials(userName, accessKey, dataCenter, farmName, setError) {
  return async (dispatch) => {
    dispatch({ type: EXTERNAL_FARM_LOADING, flag: true });
    return async("externalFarm/validateUser", "GET", {
      userName,
      accessKey,
      dataCenter,
      farmName,
    }).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: VALIDATE_EXTERNAL_FARM_CREDENTIALS, flag: true });
      } else {
        dispatch({ type: VALIDATE_EXTERNAL_FARM_CREDENTIALS, flag: false });
        setError(true);
      }
      dispatch({ type: EXTERNAL_FARM_LOADING, flag: false });
    });
  };
}

export function setExternalFarmValidation(flag) {
  return async (dispatch) => {
    dispatch({ type: VALIDATE_EXTERNAL_FARM_CREDENTIALS, flag: flag });
  };
}

export function updateExternalFarmDetail(data) {
  return async (dispatch, getState) => {
    return async("externalFarmConfig/save", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        dispatch(getExternalFarmConfig(getState().ProjectsReducer.projectId,data?.farmName));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
    });
  };
}