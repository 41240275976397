import React from "react";
import _ from "lodash";
import { Col, Row, Space } from "antd";
import { BACK_BUTTON } from "../../../../../../CdnImagePath";
import { TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import BackButton from "../../../../../CoreComponents/BackButtonComponent/BackButton";
import { confirmModal } from "../../../../../../Util";
import styles from "./TestDataSetHeader.module.scss";

const TestDataSetHeader = (props) => {
  const { setIsEditTestDataSet, isDefaultView, editTestDataSetName, selectedTestPlanName, stepType } = props;
  const onBackClick = () => {
    if (!isDefaultView) {
      confirmModal(() => setIsEditTestDataSet(false));
    } else {
      setIsEditTestDataSet(false);
    }
  };

  return (
    <Row align="middle" className={styles.TestDataSetHeaderRow}>
      <Col className={styles.TestDataSetHeaderCol}>
        {stepType !== TEST_PLAN && (
          <div>
            <img src={BACK_BUTTON} alt="Back" onClick={onBackClick} className={styles.backIcon} />
          </div>
        )}
        <div className={styles.container}>
        <label className={styles.profileNameLabel}>Profile Name: </label>
          <span className={styles.editTestDataSetName}>{editTestDataSetName}</span>
          {stepType &&
            (selectedTestPlanName && (
              <span>
                (
                {(stepType === TEST_SCENARIOS && "Test Scenario:") ||
                  (stepType === TEST_STEP_BLOCKS && "Test Block:") ||
                  "Test Plan:"}{" "}
                {selectedTestPlanName})
              </span>
            ))}
        </div>
      </Col>
      <Col>
        <Space>
          {stepType === TEST_PLAN && (
            <div className="closeIcon">
              <BackButton handleBack={onBackClick} isBlack={true} />
            </div>
          )}
        </Space>
      </Col>
    </Row>
  );
};

export default TestDataSetHeader;