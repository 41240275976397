import { Checkbox, Col, Row, Tooltip } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { changeSelectedTestScenarioForResultLogs } from "../../../../actions/TestPlanAction";
import { COMPARISON_SINGLE_LOCALE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import DeviceHoverCard from "../../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import { InfoDataNotification } from "../../../ExecutionManager/JobDesigner/BuildJob/JobCriteria";
const getResult = (result) => {
  if (result === "Passed") {
    return <span className="badge bg-success p-5 font-weight-700 m-5">{result}</span>;
  } else if (result === "Failed") {
    return <span className="badge bg-danger p-5 font-weight-700 m-5">{result}</span>;
  } else {
    return <span className="badge bg-info p-5 font-weight-700 m-5">{result}</span>;
  }
};
const ScenarioCardForPreviewLocale = (props) => {
  const { scenarioData, selectedTestScenarioForResultLogs, selectedComparisonPreviewView } = props;
  const dispatch = useDispatch();
  return (
    <Row className="w-100">
      {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
        <Col span={1}>
          <Checkbox
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                changeSelectedTestScenarioForResultLogs(e.target.checked, [scenarioData?.scenarioFolderLink], false, [
                  scenarioData?.id,
                ])
              );
            }}
            checked={selectedTestScenarioForResultLogs?.includes(scenarioData?.scenarioFolderLink)}
          />
        </Col>
      )}
      <Col span={22}>
        <div className="font-weight-700">Scenario Name: {scenarioData?.name}</div>
        {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
          <div className="font-weight-700">Result: {getResult(scenarioData?.result)}</div>
        )}
        {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
          <div className="mb-5">
            <span className="font-weight-700 mr-10">Device Name: </span>
            <span>
              <DeviceHoverCard
                deviceDetails={{
                  ...scenarioData?.deviceDetail,
                  locale: scenarioData?.locale,
                  platformName: scenarioData?.deviceDetail?.platformType?.name,
                }}
              />
            </span>
          </div>
        )}
        {selectedComparisonPreviewView === COMPARISON_SINGLE_LOCALE && (
          <>
            <div className="font-weight-600">
              Total Screenshots Expected: {scenarioData?.expectedScreenshots ? scenarioData?.expectedScreenshots : 0}
            </div>
            <div className="font-weight-600">
              Total Screenshots Captured: {scenarioData?.capturedScreenshots ? scenarioData?.capturedScreenshots : 0}
            </div>
          </>
        )}
      </Col>
      {scenarioData?.steps?.length === 0 ? (
        <Col span={1}>
          <Tooltip color={TOOLTIP_BG_COLOR} title="No Data to compare">
            <InfoDataNotification />
          </Tooltip>
        </Col>
      ) : undefined}
    </Row>
  );
};

export default ScenarioCardForPreviewLocale;
