import React from "react";
import { Button, Space } from "antd";
import { DISABLE_COLOR, SAVE_ICON, UNDO_ICON, WHITE_COLOR } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./SaveResetButtonV2.module.scss";

const SaveResetButtonV2 = (props) => {
  const { submitLabel, resetLabel, submitDisable, resetDisable, handleSubmit, handleReset } = props;
  return (
    <Space size={10}>
      <Button  className={styles['reset_Btn']} disabled={resetDisable} onClick={() => handleReset()}>
        <Space size={10}>
          <SvgLoader iconName={UNDO_ICON} iconColor={resetDisable ? DISABLE_COLOR : "#2855BF"} />
          <span> {resetLabel}</span>
        </Space>{" "}
      </Button>
      <Button type="primary" className={styles["save_Btn"]} disabled={submitDisable} onClick={() => handleSubmit()}>
        <Space size={10}>
          <SvgLoader iconName={SAVE_ICON} iconColor={submitDisable ? DISABLE_COLOR : WHITE_COLOR} />
          <span> {submitLabel}</span>
        </Space>{" "}
      </Button>
    </Space>
  );
};

export default SaveResetButtonV2;
