//node moudule package
import { Spin } from 'antd';
import isEmpty from 'lodash/isEmpty';
import React, { Suspense, lazy, useEffect, useMemo } from 'react';
import { Cookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import { listOfRouts, xpressComponentType } from './Constants';
import history from './history';
import { renderRoutes } from './lib/react-router-config.js';

import MyProject from './Components/Projects/MyProject';
import { getRoutes } from './actions/CommonActions.js';
import { getSignUpSteps } from './actions/OrganizationAction.js';
import { getOrgList } from './actions/TeamsAction.js';
import TermsAndCondition from './Components/TermsAndCondition/TermsAndCondition.js';
import ForgotPassword from './Components/ForgotPassword/ForgotPassword.js';
import GenerateToken from './Components/GenerateToken/GenerateToken.js';
import MyFirstProject from './Components/Organization/MyFirstProject.js';
import InvalidVerificationV2 from './Components/OrganizationV2/InvalidVerificationV2/InvalidVerificationV2.js';
import Invitation from './Components/SignUp/AcceptInvitation.js';
import MyProjectsByTeamV2 from './Components/TeamsV2/MyProjectsByTeamV2/MyProjectsByTeamV2.js';
import CreateOrganizationV2 from './Components/OrganizationV2/CreateOrganizationV2.js';
import JoinOrganizationV2 from './Components/OrganizationV2/JoinOrganizationV2/JoinOrganizationV2.js';
import CreateWorkspace from './Components/Workspace/CreateWorkspace.js';
import JoinWorkspace from './Components/Workspace/JoinWorkspace.js';
import InvitationListV2 from './Components/InvitationV2/InvitationListV2.js';
import DeviceDetailsListV2 from './Components/DeviceDetailsV2/DeviceDetailsListV2/DeviceDetailsListV2.js';
import ExecutionReport from './Components/ExecutionReport/ExecutionReport.js';
import MyProjectsPageV2 from './Components/ProjectsV2/ProjectDetailsV2/MyProjectsPageV2.js';
import Home from './Components/Home/Home.js';
import Logout from './Components/Logout/Logout.js';
import SignUpV2 from './Components/SignUpV2/SignUpV2.js';
import LoginV2 from './Components/LoginV2/LoginV2.js';
const cookies = new Cookies();



const DeviceUsageReport = lazy(() => import('./Components/DeviceUsageReport/DeviceUsageReport.js'));
const VerifySignup = lazy(() => import('./Components/SignUp/VerifySignup.js'));
const Saml = lazy(() => import('./Components/Saml.js'));
const SSOConfig = lazy(() => import('./Components/Admin/SSOConfig/SSOConfig'));
const DeviceSession = lazy(() => import('./Components/DeviceSession/DeviceSession.js'));
const TestPlanResult = lazy(() => import('./Components/TestPlan/TestPlanResult/TestPlanResultLists/TestPlanResult.js'));
const TestScenario = lazy(() => import('./Components/RecTestScenario/RecTestScenarioPage/RecTestScenarioPage.js'));
// const Project = lazy(() => import('./Components/Projects/MyProjectsPage.js'));
const Team = lazy(() => import('./Components/TeamsV2/TeamDetailsV2/MyTeamsPageV2.js'));
// const TeamsProject = lazy(() => import('./Components/Teams/MyProjectsInTeamPage.js'));
// const TestPlanResultDetails = lazy(() => import('./Components/TestPlan/TestPlanResultDetails/TestPlanResultDetails'));
const TestPlanDetails = lazy(() => import('./Components/TestPlan/TestPlanDetail/TestPlanDetails/TestPlanDetails'));
const TestPlanResultDetailsPreview = lazy(() =>
	import('./Components/TestPlan/TestPlanResultDetails/PreviewDownload/TestPlanResultDetailsPreview')
);
const ServerManagement = lazy(() => import('./Components/ServerManagement/ServerManager'));

const RoutesV2 = () => {
	const dispatch = useDispatch();
	// const [pageTitle, setPageTitle] = useState('')
	const { routeList, orgList, getOrgStepData } = useSelector((state) => ({
		routeList: state.CommonReducer.routeList,
		orgList: state.TeamsReducer.orgList,
		getOrgStepData: state.OrganizationReducer.getOrgStepData,
	}));

	useEffect(() => {
		if (!Boolean(localStorage.getItem('xpressToken'))) {
			localStorage.setItem('xpressToken', cookies.get('xpressToken'));
		}
	}, []);

	useEffect(() => {
		if (localStorage.getItem('xpressToken') != '' && localStorage.getItem('xpressToken') != 'undefined') {
			dispatch(getSignUpSteps());
			dispatch(getOrgList());
			dispatch(getRoutes({ componentType: xpressComponentType.menu, parentId: 0 }));
		}
	}, [dispatch]);

	const isLogin = useMemo(() => {
		return (
			!(
				orgList &&
				orgList.organization &&
				orgList[0].organization.id &&
				history.location.pathname === '/JoinOrganization'
			) &&
			localStorage.getItem('xpressToken') &&
			localStorage.getItem('xpressToken') !== '' &&
			localStorage.getItem('xpressToken') !== 'undefined'
		);
	}, [orgList]);

	const routes = useMemo(() => {
		return buildRoutes(routeList, orgList, getOrgStepData);
	}, [getOrgStepData, orgList, routeList]);

	return (
		<Router history={history}>
			<Suspense
				fallback={
					<div className='spinner-center'>
						<Spin spinning={true}></Spin> &nbsp; <span>Loading...</span>
					</div>
				}
			>
				{isLogin ? (
					renderRoutes(routes)
				) : (
					<Switch>
						<Route exact path='/Saml/:token' component={Saml} />
						<Route exact path='/Login' component={LoginV2} />
						<Route exact path='/saml/:token/:deviceToken' component={Saml} />
						<Route exact path='/TermsAndPrivacyPolicy' component={TermsAndCondition} />
						<Route exact path='/SignUp' component={SignUpV2} />
						<Route exact path='/ForgotPassword' component={ForgotPassword} />
						<Route exact path='/GenerateToken' component={GenerateToken} />
						<Route exact path='/verifySignup/:token' component={VerifySignup} />
						<Route exact path='/MyFirstProject' component={MyFirstProject} />
						<Route exact path='/InvalidVerification' component={InvalidVerificationV2} />
						<Route exact path='/invitation/acceptRequest/:token' component={Invitation} />
						<Redirect from='*' to='/Login' />
					</Switch>
				)}
			</Suspense>
		</Router>
	);
};

const component = {
	Login: LoginV2,
	Farms: Home,
	DeviceSession: DeviceSession,
	DeviceUsageReport: DeviceUsageReport,
	SSOConfig: SSOConfig,
	TestPlanResult: TestPlanResult,
	TestScenario: TestScenario,
	Team: Team,
	Project: MyProjectsPageV2,
	Teams: MyProjectsByTeamV2,
	Projects: MyProject,
	// 'TeamsProject':TeamsProject,
	TestPlanResultDetails: MyProject,
	TestPlanDetails: TestPlanDetails,
	CreateOrganization: CreateOrganizationV2,
	JoinOrganization: JoinOrganizationV2,
	CreateWorkspace: CreateWorkspace,
	JoinWorkspace: JoinWorkspace,
	Invitation: Invitation,
	InvitationList: InvitationListV2,
	DeviceDetailsList: DeviceDetailsListV2,
	TestPlanResultDetailsPreview: TestPlanResultDetailsPreview,
	UsageReport: ExecutionReport,
	ServerManagement: ServerManagement,
};

const createRoutes = (routes, orgList, getOrgStepData) => {
	const updatedRoutes = [...routes];
	if (updatedRoutes.length !== 0 && orgList && orgList.length > 0) {
		let orgId = orgList[0].organization.id;
		updatedRoutes.push({ path: '/Logout', component: Logout });
		updatedRoutes.push({ path: '/TermsAndPrivacyPolicy', component: TermsAndCondition });
		if (getOrgStepData != null && getOrgStepData.isJoinedOrg === 0) {
			updatedRoutes.push({ path: '*', component: () => <Redirect to={'/JoinOrganization'} /> });
		} else {
			updatedRoutes.push({ path: '*', component: () => <Redirect to={`/${orgId}/projects/`} /> });
		}
	} else if (orgList) {
		updatedRoutes.push({ path: '*', component: () => <Redirect to={'/createOrganization'} /> });
	}
	return updatedRoutes;
};

const buildRoutes = (routeList, orgList, getOrgStepData) => {
	const data = routeList.filter((item) => item.name !== 'Device');
	let routes = [];
	if (!isEmpty(data)) {
		data.forEach((item) => {
			listOfRouts.push(item.name);
			if (item.routeUrl) {
				item.routeUrl = item.routeUrl.replace('-', '');
			}
			if (item.routeUrl && item.routeUrl !== '') {
				const Item = component[item.name];
				const routeData = {
					path: item.routeUrl,
					component: Item,
					componentId: item.id,
					// changePageTitle: setPageTitle,
					exact: true,
				};

				if (item.children && item.children.length > 0) {
					routeData.routes = item.children
						.filter((v1) => v1.routeUrl)
						.map((v1) => ({
							path: v1.routeUrl,
							component: component[v1.name],
							componentId: item.id,
						}));
				}
				routes.push(routeData);
			}
		});

		routes = createRoutes(routes, orgList, getOrgStepData);
	} else {
		routes.push({ path: '/saml/:token', component: Saml });
		routes.push({ path: '/Logout', component: Logout });
	}

	return routes;
};

export default RoutesV2;
