import React from "react";
import { ARROW_LEFT, ARROW_RIGHT } from "../../../CdnImagePath";
import { Space } from "antd";

const PaginationComponent = (props) => {
  const { currentPageItems, totalItems, currentPage, totalPage, callPaginationAPI, importModal } = props;

  return (
    <div className="display-flex align-items-center">
      {!importModal && (
        <span className="font-size-14 font-color-darkBlue">
          {currentPageItems} of {totalItems}
        </span>
      )}
      <Space size={5}>
        <div
          className={"pagination-prev " + (currentPage === 1 ? "cursor-not-allowed" : "cursor-pointer")}
          onClick={() => currentPage !== 1 && callPaginationAPI(currentPage - 1)}
        >
          <img src={ARROW_LEFT} alt="left" />
        </div>
        {importModal && (
          <span className="font-size-14 font-color-darkBlue">
            {currentPageItems} of {totalItems}
          </span>
        )}
        <div
          className={"pagination-next " + (totalPage !== currentPage ? "cursor-pointer" : "cursor-not-allowed")}
          onClick={() => totalPage !== currentPage && callPaginationAPI(currentPage + 1)}
        >
          <img src={ARROW_RIGHT} alt="right" />
        </div>
      </Space>
    </div>
  );
};

export default PaginationComponent;
