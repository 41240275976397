import _ from "lodash";
import moment from "moment";
import { BuildJobModal } from "../Components/ExecutionManager/JobDesigner/BuildJobModal";
import { ScheduleJobModal } from "../Components/ExecutionManager/JobDesigner/ScheduleJobModal";
import {
  ALL_JOBS,
  BUILD_VERSION_LIST,
  CHANGE_BUILD_JOB_LIST_FILTER,
  CHANGE_EXECUTION_MANAGER_TAB,
  CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER,
  CHANGE_SELECTED_TEST_JOB_CRITERIA,
  CHANGE_TEST_JOB_DATA,
  CHANGE_TEST_JOB_SCHEDULE_DATA,
  CLOSE_TEST_JOB_SCHEDULE_DRAWER,
  CREATE_BUILD_JOB,
  CURL_FETCH_FOR_JOB_LOADING,
  DEFAULT_PAGE,
  DEVICE_EXECUTION_FARM,
  EDIT_BUILD_JOB,
  EXTERNAL_FARM_DEVICE_LOADING,
  FETCHED_CURL_FOR_JOB,
  GET_EXTERNAL_FARM_DEVICE,
  GET_TEST_JOB,
  GET_TEST_JOB_ID_NAME_LIST,
  GET_TEST_JOB_QUEUE_LIST,
  GET_TEST_JOB_SCHEDULE,
  GET_TIMEZONE,
  LOADING_JOB,
  MY_PLANS,
  ONETIME,
  OPEN_TEST_JOB_SCHEDULE_DRAWER,
  OWNED,
  RECORD_10_PER_PAGE,
  REMOVE_TEST_JOB,
  RESET_GET_TEST_JOB_BY_ID_DATA,
  RESET_GET_TEST_JOB_SCHEDULE_DATA,
  RUN_RESULT_LOADING,
  SAVE_TEST_JOB,
  SCHEDULE_TEST_JOB,
  SEARCH_EXTERNAL_FARM_DEVICE_LIST,
  TEST_RAIL_SUITES_BY_TEST_PLAN_ID,
  UPDATE_TEST_JOB_ERROR_DETAIL,
} from "../Constants";
import { checkNotUndefined, checkUndefined } from "../Util";

let defaultState = {
  getTestJobList: null,
  testJobIdNameList: null,
  testJobScheduleList: null,
  testJobDetail: new BuildJobModal({}),
  testJobScheduleDetail: new ScheduleJobModal({}),
  currentTestJobPage: 1,
  totalTestJobPage: 0,
  getTimeZoneList: null,
  saveTestJobData: null,
  removeJobData: null,
  buildVersionList: null,
  jobLoading: false,
  fetchedCurlForJob: "",
  getTestRailSuitesByTestPlanID: [],
  selectedExecutionManagerSubTab: ALL_JOBS,
  testJobQueueList: null,
  testJobQueueListPageNumber: 1,
  testJobQueueListTotalItems: undefined,
  selectedTestJobCriteria: SCHEDULE_TEST_JOB.STEP1,
  testJobErrorDetail: {},
  testJobScheduleErrorFlag: true,
  previousTestJobScheduleDetail: undefined,
  previousTestJobDetail: undefined,
  runResultLoading: false,
  curlFetchForJobLoading: false,
  totalPageItems: undefined,
  sauceLabDeviceLoading: false,
  testJobListFilter: { id: OWNED, value: MY_PLANS },
  createBuildJob: false,
  externalFarmDeviceLoading: false,
  externalFarmDeviceList: [],
  externalFarmDeviceCurrentPageNumber: DEFAULT_PAGE,
  unSavedBuildJobChanges: false,
  searchExternalFarmDeviceList: null,
  originalExternalFarmDeviceList: [],
  totalExternalFarmDeviceItems: 0,
  totalExternalFarmDevicePage: 0,
};

export default function TestJobReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case GET_TEST_JOB:
      response = action.response;
      return {
        ...state,
        getTestJobList: response?.data?.data,
        currentTestJobPage: action?.pageNumber || 1,
        totalTestJobPageItems: response?.data?.totalItems,
        totalTestJobPage: response?.data?.totalCount,
        totalPageItems: response?.data?.pageItems,
      };
    case UPDATE_TEST_JOB_ERROR_DETAIL:
      return {
        ...state,
        testJobErrorDetail: action.testJobErrorDetail,
      };
    case GET_TEST_JOB_QUEUE_LIST:
      response = action.response;
      return {
        ...state,
        testJobQueueList: response.data.data,
        testJobQueueListPageNumber: action.data.pageNumber || 1,
        testJobQueueListTotalItems: response.data.totalItems,
      };
    case GET_TEST_JOB_ID_NAME_LIST:
      response = action.response;
      return {
        ...state,
        testJobIdNameList: response.data,
      };
    case GET_TEST_JOB_SCHEDULE:
      response = action.response;
      return {
        ...state,
        testJobScheduleList: response.data.data,
        currentTestJobPage: action.data.pageNumber || 1,
        totalTestJobPage: response.data.totalCount,
        totalPageItems: response?.data?.pageItems,
      };
    case CHANGE_SELECTED_TEST_JOB_CRITERIA:
      return {
        ...state,
        previousSelectedTestJobCriteria: state.selectedTestJobCriteria,
        selectedTestJobCriteria: action.selectedTestJobCriteria,
      };

    case OPEN_TEST_JOB_SCHEDULE_DRAWER:
      return {
        ...state,
        testJobSchedulerDrawerVisible: true,
        previousTestJobScheduleDetail: undefined,
      };
    case CLOSE_TEST_JOB_SCHEDULE_DRAWER:
      return {
        ...state,
        testJobSchedulerDrawerVisible: false,
        previousTestJobScheduleDetail: undefined,
      };
    case CHANGE_TEST_JOB_DATA:
      if (checkNotUndefined(action.data.id) && checkUndefined(state.previousTestJobDetail)) {
        const selectedLambdaTestDevice = action?.data?.devices?.map(
          (device, index) => `${device} ${action?.data?.deviceOs[index]}`
        );
        const devices =
          action?.data?.deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST
            ? selectedLambdaTestDevice
            : action?.data?.devices;

        const previousTestJobDetail = { ...action.data, devices };

        const deviceList = { ...action.data, devices };
        return {
          ...state,
          testJobDetail: deviceList,
          previousTestJobDetail,
        };
      } else {
        return {
          ...state,
          testJobDetail: action.data,
        };
      }
    case RESET_GET_TEST_JOB_BY_ID_DATA:
      let resetData = new BuildJobModal({});
      if (!action.clearData) {
        if (checkNotUndefined(state.previousTestJobDetail)) {
          resetData = { ...state.previousTestJobDetail };
        } else if (checkNotUndefined(state.testJobDetail.id)) {
          resetData = { ...state.testJobDetail };
        }
      }

      return {
        ...state,
        testJobDetail: resetData,
        previousTestJobDetail: action.clearData ? undefined : resetData,
        testJobErrorDetail: {},
        selectedTestJobCriteria: SCHEDULE_TEST_JOB.STEP1,
      };

    case CHANGE_TEST_JOB_SCHEDULE_DATA:
      if (checkNotUndefined(action.data.id) && checkUndefined(state.previousTestJobScheduleDetail)) {
        let previousTestJobScheduleDetail = { ...action.data };
        if (action.data["isJobScheduled"] === 0) {
          action.data = {
            ...action.data,
            executionFrequency: ONETIME,
            scheduleTime: moment(new Date()),
            scheduleDate: moment(new Date()),
            scheduleDateTime: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            days: [],
            timeZone: undefined,
            timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
            minutes: [],
            hours: [],
            isJobScheduled: 1,
            setDefaultValue: true,
          };
        }
        return {
          ...state,
          testJobScheduleDetail: action.data,
          previousTestJobScheduleDetail,
          testJobScheduleErrorFlag: false,
        };
      } else {
        return {
          ...state,
          testJobScheduleDetail: action.data,
          testJobScheduleErrorFlag: action.errorInScheduleJob,
        };
      }
    case RESET_GET_TEST_JOB_SCHEDULE_DATA:
      let resetScheduleData = new ScheduleJobModal({});
      if (!action.clearData) {
        if (checkNotUndefined(state.previousTestJobScheduleDetail)) {
          resetScheduleData = { ...state.previousTestJobScheduleDetail };
        } else if (state.testJobScheduleDetail.isJobScheduled === 1) {
          resetScheduleData = { ...state.testJobScheduleDetail };
        }
      }

      return {
        ...state,
        testJobScheduleDetail: resetScheduleData,
        previousTestJobScheduleDetail: action.clearData ? undefined : resetScheduleData,
        testJobScheduleErrorFlag: action.errorInScheduleJob,
      };

    case SAVE_TEST_JOB:
      response = action.response;
      return {
        ...state,
        saveTestJobData: response.message,
      };

    case FETCHED_CURL_FOR_JOB:
      response = action.response;
      return {
        ...state,
        fetchedCurlForJob: response.data.cURL,
      };
    case REMOVE_TEST_JOB:
      response = action.response;
      return {
        ...state,
        removeJobData: response,
      };

    case GET_TIMEZONE:
      response = action.response;
      return {
        ...state,
        getTimeZoneList: response.data,
      };

    case LOADING_JOB:
      response = action.response;
      return {
        ...state,
        jobLoading: response.isLoading,
      };

    case RUN_RESULT_LOADING:
      response = action.response;
      return {
        ...state,
        runResultLoading: response.isLoading,
      };
    case CURL_FETCH_FOR_JOB_LOADING:
      response = action.response;
      return {
        ...state,
        curlFetchForJobLoading: response.isLoading,
      };

    case BUILD_VERSION_LIST:
      response = action.response;
      return {
        ...state,
        buildVersionList: response.data,
      };

    case TEST_RAIL_SUITES_BY_TEST_PLAN_ID:
      response = action.response;
      return {
        ...state,
        getTestRailSuitesByTestPlanID: response.data,
      };
    case CHANGE_EXECUTION_MANAGER_TAB:
      return {
        ...state,
        selectedExecutionManagerSubTab: action.executionManagerSubTab,
      };

    case CHANGE_BUILD_JOB_LIST_FILTER:
      return {
        ...state,
        testJobListFilter: action.value,
      };
    case CREATE_BUILD_JOB:
      return {
        ...state,
        createBuildJob: action?.createBuildJob,
      };

    case EXTERNAL_FARM_DEVICE_LOADING:
      response = action.response;
      return {
        ...state,
        externalFarmDeviceLoading: response.isLoading,
      };

    case GET_EXTERNAL_FARM_DEVICE:
      response = action?.response;
      const deviceList =
        action?.farmName === DEVICE_EXECUTION_FARM.LAMBDA_TEST
          ? response?.data?.map((i) => {
              return {
                device: {
                  ...i,
                  targetUniqueId: i?.deviceName + " " + i?.platformVersion,
                },
              };
            })
          : response?.data;
      return {
        ...state,
        externalFarmDeviceList: deviceList || [],
        originalExternalFarmDeviceList: deviceList || [], // Store the original list
        totalExternalFarmDeviceItems: response?.data?.length,
        totalExternalFarmDevicePage: Math.ceil(response?.data?.length / RECORD_10_PER_PAGE),
      };

    case SEARCH_EXTERNAL_FARM_DEVICE_LIST:
      const searchText = action?.searchText?.toLowerCase() || "";
      let filteredData = state.originalExternalFarmDeviceList;

      if (searchText) {
        filteredData = filteredData.filter((device) => {
          const { name, osVer, deviceName, platformVersion } = device?.device;
          return (
            name?.toLowerCase().includes(searchText) ||
            osVer?.toLowerCase().includes(searchText) ||
            deviceName?.toLowerCase().includes(searchText) ||
            platformVersion?.toLowerCase().includes(searchText)
          );
        });
      }

      return {
        ...state,
        searchExternalFarmDeviceList: searchText,
        externalFarmDeviceList: filteredData,
        totalExternalFarmDeviceItems: filteredData.length,
        totalExternalFarmDevicePage: Math.ceil(filteredData.length / RECORD_10_PER_PAGE),
        externalFarmDeviceCurrentPageNumber: DEFAULT_PAGE,
      };

    case CHANGE_EXTERNAL_FARM_DEVICE_LIST_PAGE_NUMBER:
      return {
        ...state,
        externalFarmDeviceCurrentPageNumber: action.value,
      };

    case EDIT_BUILD_JOB:
      return {
        ...state,
        unSavedBuildJobChanges: action.value,
      };

    default:
      return state;
  }
}
