import React from "react";
import { Popover } from "antd";
import { RUN_RESULT_STATUS, defaultDateTimeFormat } from "../../../../../Constants";
import { displayUTCDateFormatWithToolTip } from "../../../../../Util";
import { AbortTag, BadgeStyle } from "../../../../CoreComponents/StyledComponent/StyledComponents";
import { durationTime } from "../../../../TestPlan/TestPlanResultDetails/TestPlanResultScenarioDetails";
import { resultTag } from "../TestPlanResultV2";
import styles from "./TestPlanResultColumnsV2.module.scss";

const TestPlanResultColumnV2 = ({ row }) => {
  return (
    <>
      <div>
        {row?.runResult === "ABORT_REQUEST" ? (
          <AbortTag>
            <BadgeStyle color="#7D5E0F" />
            Aborting in process
          </AbortTag>
        ) : row?.runResult === "ABORTED" ? (
          <AbortTag>
            <BadgeStyle color="#7D5E0F" />
            Run Aborted
          </AbortTag>
        ) : (
          <div className="result-tag">{resultTag(row?.resultObj, row)}</div>
        )}
        {row?.runResult !== "PREPARING_RUN_TO_EXECUTE" &&
          row?.screenShotCounts?.totalExpectedScreenShotCounts !== 0 && (
            <Popover
              content={
                <>
                  <div className={styles["run_Result_Details"]}>
                    <span className={styles.label}>Passed Scenarios Screenshots: </span>{" "}
                    <span className={styles["label-name"]}>
                      {row?.screenShotCounts?.totalPassedCapturedScreenShotCounts} /{" "}
                      {row?.screenShotCounts?.totalPassedExpectedScreenShotCounts
                        ? row?.screenShotCounts?.totalPassedExpectedScreenShotCounts
                        : 0}
                    </span>
                  </div>
                  <div className={styles["run_Result_Details"]}>
                    <span className={styles["label"]}>Failed/Aborted Scenarios Screenshots: </span>{" "}
                    <span className={styles["label-name"]}>
                      {row?.screenShotCounts?.totalOtherCapturedScreenShotCounts} /{" "}
                      {row?.screenShotCounts?.totalOtherExpectedScreenShotCounts
                        ? row?.screenShotCounts?.totalOtherExpectedScreenShotCounts
                        : 0}
                    </span>
                  </div>
                </>
              }
            >
              <div className={styles["run_Result_Details"]}>
                <span className={styles["label"]}>Total Screenshots Captured: </span>{" "}
                <span className={styles["label-name"]}>
                  {row?.screenShotCounts?.totalCapturedScreenShotCounts} /{" "}
                  {row?.screenShotCounts?.totalExpectedScreenShotCounts}
                </span>
              </div>
            </Popover>
          )}
        <div className={styles["run_Result_Details"]}>
          <span className={styles["label"]}>Run # : </span>
          <span className={styles["label-name"]}> {row?.RunNumber}</span>
        </div>
        <div>
          <span className={styles["label"]}>Started by: </span>
          {row?.startedBy} {row?.scheduleTime && <span className={styles.label}> on </span>}
          {row?.scheduleTime && displayUTCDateFormatWithToolTip(row.scheduleTime, defaultDateTimeFormat)}
        </div>
        {row?.startTime && (
          <div className={styles["run_Result_Details"]}>
            <span className={styles["label"]}>Execution Started: </span>
            {row?.startTime && displayUTCDateFormatWithToolTip(row.startTime, defaultDateTimeFormat)}
            {row?.executionTime &&
              row?.runResult &&
              (row.runResult === RUN_RESULT_STATUS.DONE ? (
                <>
                  <span className={styles["label-name"]}> and took</span> {durationTime(row?.executionTime.split(":"))}
                </>
              ) : (
                ""
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default TestPlanResultColumnV2;
