import { Table } from "antd";
import Column from "antd/lib/table/Column";
import React from "react";
import styled from "styled-components";

const StyledColumn = styled(Column)`
  background: #f6f6f6;
`;
const StyledTable = styled(Table)`
  padding: 0px;
  overflow: hidden;
  .ant-table table {
    table-layout: ${(props) => !props.testDataSetModal && "auto !important"};
  }
  .ant-table-pagination.ant-pagination {
    margin-right: 10px;
  }
  .ant-table-cell {
    padding: ${(props) => (props.bordered ? "0 16px" : "16px")};
    height: 45px;
    vertical-align: ${(props) => (props.bordered ? "middle" : "top")};
  }

  .ant-table-thead .ant-table-cell {
    padding: ${(props) =>
      props.isScrollable && !props.isExecution ? "0 7px !important" : props.isExecution && "0px !important"};
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    display: inline-table;
    background-color: transparent;
  }
`;

const TableComponent = (props) => {
  const {
    data,
    columns,
    handleSelectRow,
    selected,
    isScrollable,
    isBorder,
    selectedRowId,
    isExecution,
    testDataSetModal,
  } = props;

  return (
    <StyledTable
    columns={columns}
    dataSource={data}
    pagination={false}
    onRow={handleSelectRow}
    rowClassName={(record) =>
        selected?.includes(record?.key) || selectedRowId?.includes(record?.key) ? "activeRow" : ""
      }
      scroll={{
        x: testDataSetModal && 1000,
        y: "70vh",
      }}
      bordered={isBorder}
      isScrollable={isScrollable}
      isExecution={isExecution}
      testDataSetModal={testDataSetModal}
    >
      {columns.map((v, k) => {
        return <StyledColumn key={k} title={v.title} dataIndex={v.dataIndex} bordered={isBorder} />;
      })}
    </StyledTable>
  );
};

export default TableComponent;
