import { Row, Col, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  changeS3ConfigTabData,
  getProjectDetailsForSetting,
  updateS3Settings,
} from "../../actions/ProjectSettingsActions";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmptyOrNaN, showNotification } from "../../Util";
import { CHANGE_S3CONFIG_TAB_DATA, S3_CONFIGURATION } from "../../Constants";
import { BuildS3ConfigTabModal } from "./BuildProjectSettingModal";
import UploadBuild from "../CommonComponents/UploadBuild/UploadBuild";
import { Required, StyledInput, Title } from "../CoreComponents/StyledComponent/StyledComponents";
import { Label } from "../DeviceDetails/DeviceAssignmentModal";
import SaveResetButton from "../CoreComponents/SaveResetComponent/SaveResetButton";

const S3Configuration = (props) => {
  const { projectId, selectedTab } = props;

  const dispatch = useDispatch();
  const { getProjectDetailsById, projectLoading, S3ConfigTabDetails } = useSelector(
    (state) => state.ProjectSettingsReducer
  );
  const [fileList, setFileList] = useState([]);
  const [addNewBuild, setAddNewBuild] = useState(false);

  useEffect(() => {
    dispatch(getProjectDetailsForSetting(projectId));
  }, [projectId]);

  const updateS3ConfigurationData = () => {
    delete S3ConfigTabDetails["cloudFile"];
    let isValid = Object.values(S3ConfigTabDetails).every((i) => isNotNullAndNotEmptyAndNotUndefined(i));
    if (isValid) {
      dispatch(updateS3Settings(projectId, fileList));
    } else {
      showNotification("info", "Please Enter All Details.");
    }
    setFileList([]);
    setAddNewBuild(false);
  };

  const resetS3ConfigurationData = () => {
    if (!_.isEmpty(getProjectDetailsById)) {
      dispatch({
        type: CHANGE_S3CONFIG_TAB_DATA,
        data: new BuildS3ConfigTabModal(getProjectDetailsById),
      });
      fileList.length && setFileList([]);
      setAddNewBuild(false);
    } else {
      dispatch({
        type: CHANGE_S3CONFIG_TAB_DATA,
        data: new BuildS3ConfigTabModal({}),
      });
      setFileList([]);
    }
  };

  const isSameValue = () => {
    const object1 = getProjectDetailsById,
      object2 = S3ConfigTabDetails,
      keys = [
        "CfDistributionDomain",
        "CfKeyPairId",
        "PreSignedUrlExpirationDays",
        "S3AccessKeyId",
        "S3Bucket",
        "S3BucketRegion",
        "S3Host",
        "S3SecretAccessKey",
      ],
      result =
        keys.every((key) => key in object1 && key in object2 && object1[key] === object2[key]) && _.isEmpty(fileList);
    return result;
  };

  const disableUpdateButton = () => {
    let isDisable = false;
    if (!_.isEmpty(getProjectDetailsById)) {
      delete S3ConfigTabDetails["cloudFile"];
      isDisable = isSameValue() || Object.values(S3ConfigTabDetails).some((i) => isNullOrUndefinedOrEmptyOrNaN(i));
    } else {
      delete S3ConfigTabDetails["cloudFile"];
      isDisable = Object.values(S3ConfigTabDetails).some((i) => isNullOrUndefinedOrEmptyOrNaN(i));
    }
    return isDisable;
  };

  const disableResetButton = () => {
    let isDisable = false;
    if (!_.isEmpty(getProjectDetailsById)) {
      isDisable = isSameValue();
    } else {
      delete S3ConfigTabDetails["cloudFile"];
      isDisable =
        Object.values(S3ConfigTabDetails).every((i) => isNullOrUndefinedOrEmptyOrNaN(i)) && _.isEmpty(fileList);
    }
    return isDisable;
  };

  return (
    <Spin spinning={projectLoading} tip="Loading" className="spinner-center">
      <Row justify="space-between" className="innerHeader p-12">
        <div className="mainHeaderText ml-10">S3 Configurations</div>
        <SaveResetButton
          submitLabel={"Update"}
          resetLabel={"Reset"}
          submitDisable={disableUpdateButton()}
          resetDisable={disableResetButton()}
          handleSubmit={updateS3ConfigurationData}
          handleReset={resetS3ConfigurationData}
        />
      </Row>
      <Row justify="center" className="pageScroll">
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <Row className="mt-30 mb-30" gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">S3 Details</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <Label>S3 Bucket</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                placeholder="Please Enter S3Bucket "
                value={S3ConfigTabDetails.S3Bucket}
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      S3Bucket: e.target.value,
                    })
                  );
                }}
              />
              <Label>S3 Host</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="S3Host"
                value={S3ConfigTabDetails.S3Host}
                placeholder="Please Enter S3 Host"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      S3Host: e.target.value,
                    })
                  );
                }}
              />
              <Label>S3 Bucket Region</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="S3BucketRegion"
                value={S3ConfigTabDetails.S3BucketRegion}
                placeholder="Please Enter S3 Bucket Region"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      S3BucketRegion: e.target.value,
                    })
                  );
                }}
              />
              <Label>S3 Access Key Id</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="S3AccessKeyId"
                value={S3ConfigTabDetails.S3AccessKeyId}
                placeholder="Please Enter  S3 Access Key Id"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      S3AccessKeyId: e.target.value,
                    })
                  );
                }}
              />
              <Label>S3 Secret Access Key</Label>
              <Required>*</Required>
              <StyledInput
                className="mt-5"
                name="S3SecretAccessKey"
                value={S3ConfigTabDetails.S3SecretAccessKey}
                placeholder="Please Enter S3 Secret Access Key"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      S3SecretAccessKey: e.target.value,
                    })
                  );
                }}
              />
            </Col>
          </Row>
          <hr className="custom-hr" />
          <Row className="mt-30 mb-30" gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Cloud Configuration</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <Label>CF Distribution Domain</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="CfDistributionDomain"
                value={S3ConfigTabDetails.CfDistributionDomain}
                placeholder="Please Enter CF Distribution Domain"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      CfDistributionDomain: e.target.value,
                    })
                  );
                }}
              />
              <Label>Pre Signed Url Expiration Days</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="PreSignedUrlExpirationDays"
                value={S3ConfigTabDetails.PreSignedUrlExpirationDays || undefined}
                placeholder="Please Enter Pre Signed url Expiration Days"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      PreSignedUrlExpirationDays: parseInt(e.target.value) || undefined,
                    })
                  );
                }}
              />
              <Label>CF Key Pair Id</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                name="CfKeyPairId"
                value={S3ConfigTabDetails.CfKeyPairId}
                placeholder="Please Enter CF Key Pair Id"
                onChange={(e) => {
                  dispatch(
                    changeS3ConfigTabData({
                      ...S3ConfigTabDetails,
                      CfKeyPairId: e.target.value,
                    })
                  );
                }}
              />
              <UploadBuild
                addNewBuild={addNewBuild}
                setAddNewBuild={setAddNewBuild}
                existingBuildName={getProjectDetailsById && getProjectDetailsById?.fileName}
                setFileList={setFileList}
                fileList={fileList}
                placeholder={" Upload your .der File"}
                tooltip="Upload New Build"
                labelName="File Name"
                acceptFileTypes=".der"
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default S3Configuration;
