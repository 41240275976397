import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Divider, Modal, Popover, Spin } from "antd";
import { isEmpty } from "lodash";
import { updateStepType } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { saveTestJobRights } from "../../../../actions/Recording";
import { ACTION_VIEW, CUSTOM, EXECUTION_JOB, JOB_DESIGNER, PRIVATE, PUBLIC, SUCCESS_TYPE } from "../../../../Constants";
import { CustomText, removeSelectedIds, selectAllData, showNotification } from "../../../../Util";
import {
  changeExecutionManagerTabAndSubTab,
  cloneTestJob,
  getScheduleJobCurl,
  getTestJob,
  removeJob,
} from "../../../../actions/TestJobActions";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CommonHeaderV2 from "../../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import PaginationComponentV2 from "../../../CoreComponents/PaginationComponent/PaginationComponentV2";
import PermissionDrawerV2 from "../../../CoreComponents/PermissionDrawer/PermissionDrawerV2/PermissionDrawerV2";
import TableComponentV2 from "../../../CoreComponents/TableComponent/TableComponentV2";
import JobSettingModal from "../../../RecTestStep/ScenarioHeader/ScenarioHeaderOptions/JobSettingModal/JobSettingModal";
import TestPlanResultDeviceDetails from "../../../TestPlan/TestPlanResultDetails/TestPlanResultDeviceDetails";
import JobDetailsActionsColumnV2 from "./AllJobsDetailsColumnsV2/JobDetailsActionsColumnV2";
import JobHistoryColumnV2 from "./AllJobsDetailsColumnsV2/JobHistoryColumnV2";
import JobInfoColumnV2 from "./AllJobsDetailsColumnsV2/JobInfoColumnV2";
import TestCoverageColumnV2 from "./AllJobsDetailsColumnsV2/TestCoverageColumnV2";
import TestPlanColumnV2 from "./AllJobsDetailsColumnsV2/TestPlanColumnV2";
import AllJobsDetailsHeaderV2 from "./AllJobsDetailsHeaderV2";
import styles from "./AllJobsV2.module.scss";

const AllJobsV2 = (props) => {
  const dispatch = useDispatch();
  const { projectId, setCurrentMenuOptionsSelected, orgId } = props;

  const {
    currentTestJobPage,
    totalTestJobPageItems,
    totalTestJobPage,
    getTestJobList,
    fetchedCurlForJob,
    curlFetchForJobLoading,
    totalPageItems,
    testJobListFilter,
  } = useSelector((state) => state.TestJobReducer);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchByFrequency, setSearchByFrequency] = useState("");
  const [curlModal, setCurlModal] = useState(false);
  const [selectAllTestJob, setSelectAllTestJob] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [permissionTestJobs, setPermissionTestJobs] = useState(null);
  const [selectedTestJob, setSelectedTestJob] = useState(undefined);
  const [openDeleteBuildJobModal, setOpenDeleteBuildJobModal] = useState({ visible: false, data: {} });
  const [openCloneBuildJobModal, setOpenCloneBuildJobModal] = useState({ visible: false, data: {} });

  useEffect(() => {
    dispatch(getTestJob(1, projectId, searchKeyword, searchByFrequency, testJobListFilter));
    setSelectAllTestJob([]);
  }, [projectId]);

  useEffect(() => {
    dispatch(updateStepType(EXECUTION_JOB));
  }, []);

  useEffect(() => {
    if (permissionTestJobs?.testJobRights?.data) {
      setMembers([...permissionTestJobs?.testJobRights?.data]);
    }
  }, [permissionTestJobs]);

  const handleCopyText = () => {
    navigator.clipboard.writeText(fetchedCurlForJob);
    showNotification(SUCCESS_TYPE, "Copied To Clipboard!");
  };

  const deleteConfirm = () => {
    setOpenDeleteBuildJobModal({ visible: true, data: {} });
  };

  function getModalForCurl() {
    return curlModal ? (
      <Modal
        centered
        title={`cURL for Scheduling Job - ${permissionTestJobs?.name}`}
        visible={curlModal}
        onCancel={() => setCurlModal(false)}
        footer={[
          <Button
            className="lightPurple"
            onClick={() => {
              handleCopyText();
              setCurlModal(false);
            }}
          >
            Copy
          </Button>,
        ]}
        width={"90%"}
        className={"modal-build-verification"}
      >
        <div className={styles.curlContainer}>
          {curlFetchForJobLoading ? (
            <Spin className={styles.spinner}></Spin>
          ) : (
            <div className={styles.modalContent}>
              <span className={styles.modalText}>{CustomText(fetchedCurlForJob)}</span>
            </div>
          )}
        </div>
      </Modal>
    ) : null;
  }

  const generateBuildJobModalProps = () => {
    if (openDeleteBuildJobModal?.visible) {
      return {
        handleOk: () => {
          setSearchKeyword("");
          setSearchByFrequency();
          dispatch(
            removeJob(
              {
                testJobId: !isEmpty(selectAllTestJob) ? selectAllTestJob : [openDeleteBuildJobModal?.data?.id],
              },
              projectId,
              setSelectAllTestJob,
              testJobListFilter,
              searchKeyword
            )
          );
          setOpenDeleteBuildJobModal({ visible: false, data: {} });
        },
        handleCancel: () => setOpenDeleteBuildJobModal({ visible: false, data: {} }),
        modalOpen: openDeleteBuildJobModal?.visible,
        modalTitle: "Confirm Deletion",
        modalContent: `Are you sure you want to delete ${
          selectAllTestJob && selectAllTestJob.length > 1 ? "these items?" : "this item?"
        } This action cannot be undone.`,
        okText: "Delete",
        isDeleteModal: true,
      };
    } else {
      return {
        handleOk: () => {
          setOpenCloneBuildJobModal({ visible: false, data: {} });
          dispatch(cloneTestJob(openCloneBuildJobModal?.data?.id, projectId, testJobListFilter));
        },

        handleCancel: () => setOpenCloneBuildJobModal({ visible: false, data: {} }),
        modalOpen: openCloneBuildJobModal?.visible,
        modalTitle: "Confirm Duplication",
        modalContent: "Are you sure you want to duplicate this item?",
        okText: "Duplicate",
      };
    }
  };

  const showCheckBoxInTableHeader = useMemo(
    () =>
      isEmpty(selectAllTestJob) &&
      !isEmpty(getTestJobList) &&
      getTestJobList?.some((i) => i?.allowDelete === ACTION_VIEW),
    [selectAllTestJob, getTestJobList]
  );
  const columns = [
    {
      title: (
        <div className={showCheckBoxInTableHeader ? styles["checkBox-in-header"] : styles["table-header"]}>
          {showCheckBoxInTableHeader && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestJob([
                      ...selectAllTestJob,
                      ...(
                        getTestJobList &&
                        getTestJobList?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestJob.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestJob(removeSelectedIds(selectAllTestJob, getTestJobList));
                  }
                }}
              />
            </span>
          )}
          Job Info
        </div>
      ),
      dataIndex: "jobInfo",
    },
    {
      title: "Test Plan",
      dataIndex: "testPlan",
    },
    {
      title: "Test Coverage",
      dataIndex: "testCoverage",
    },
    {
      title: "Job History",
      dataIndex: "jobHistory",
      width: 280,
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "right",
    },
  ];

  const setPermissionData = () => {
    if (permissionTestJobs?.isPrivate && !isEmpty(permissionTestJobs?.testJobRights?.data)) {
      setPreviousPermission(permissionTestJobs?.testJobRights?.data);
      setMembers([...permissionTestJobs?.testJobRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const savePermission = (Value, testJobData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isExec: i?.isExec,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let testJobObj = {
      testJob: { id: testJobData?.id },
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !isEmpty(result) ? result : [],
    };
    dispatch(saveTestJobRights(testJobObj, 1, projectId, searchKeyword, searchByFrequency, testJobListFilter));
    setOpenPermissionDrawer(false);
  };

  const handleActionChange = (actionName, testJobData) => {
    setPermissionTestJobs(() => ({ ...testJobData }));
    switch (actionName) {
      case "getUrl":
        dispatch(getScheduleJobCurl({ testJobId: testJobData?.id, isManual: 1 }));
        setCurlModal(true);
        break;
      case "duplicateJob":
        setOpenCloneBuildJobModal({ visible: true, data: testJobData });

        break;
      case "deleteJob":
        setOpenDeleteBuildJobModal({ visible: true, data: testJobData });
        break;
      case PRIVATE:
        savePermission(PRIVATE, testJobData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, testJobData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const showDeviceList = (devices, deviceServiceFarm) => {
    return (
      <Popover
        content={devices?.map((i, index) => {
          return (
            <TestPlanResultDeviceDetails
              deviceResultDetails={{
                targetUniqueId: i?.targetUniqueId,
                brandName: i?.brand,
                name: i?.name,
                locale: i?.locale,
                platformName: i?.platformName,
                osVer: i?.osVersion,
                devicesLength: devices?.length,
                currentIndex: index,
              }}
              deviceServiceFarm={deviceServiceFarm}
            />
          );
        })}
        title="Devices"
        placement="bottom"
        overlayClassName={devices?.length > 3 && "showDeviceDetails"}
      >
        <span className={styles.deviceListPopover}>{devices?.length}</span>
      </Popover>
    );
  };

  const testJobDetails =
    getTestJobList &&
    getTestJobList?.map((row) => ({
      key: row?.id,
      jobInfo: (
        <JobInfoColumnV2
          row={row}
          showDeviceList={showDeviceList}
          selectAllTestJob={selectAllTestJob}
          setSelectAllTestJob={setSelectAllTestJob}
        />
      ),
      testPlan: <TestPlanColumnV2 row={row} />,
      testCoverage: <TestCoverageColumnV2 row={row} showDeviceList={showDeviceList} />,
      jobHistory: <JobHistoryColumnV2 row={row} orgId={orgId} projectId={projectId} />,
      action: (
        <JobDetailsActionsColumnV2
          row={row}
          setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
          handleActionChange={handleActionChange}
          setSelectedTestJob={setSelectedTestJob}
        />
      ),
    }));
  return (
    <>
      <CommonHeaderV2
        headerText={"Execution Jobs"}
        label={"Build Job"}
        handleOk={() => {
          dispatch(changeExecutionManagerTabAndSubTab(JOB_DESIGNER, true));
        }}
      />
      <AllJobsDetailsHeaderV2
        projectId={projectId}
        searchKeyword={searchKeyword}
        searchByFrequency={searchByFrequency}
        setSearchKeyword={setSearchKeyword}
        setSearchByFrequency={setSearchByFrequency}
      />
      <Divider className="m-0" />
      <div className="table_Details_scroll">
        <div className="table_Details__wrapper">
          {!isEmpty(selectAllTestJob) && (
            <div className={styles["select--all-box"]}>
              {selectAllData(selectAllTestJob, setSelectAllTestJob, getTestJobList, deleteConfirm)}
            </div>
          )}
          <TableComponentV2
            columns={columns}
            data={testJobDetails}
            selected={selectAllTestJob}
            className={`table__wrapper ${!isEmpty(selectAllTestJob) && " hide_column_name"}`}
          />

          <div className="pagination_Section">
            {!isEmpty(getTestJobList) && (
              <PaginationComponentV2
                currentPageItems={totalPageItems}
                totalItems={totalTestJobPageItems}
                currentPage={currentTestJobPage}
                totalPage={totalTestJobPage}
                callPaginationAPI={(pageNumber) =>
                  currentTestJobPage !== pageNumber &&
                  dispatch(getTestJob(pageNumber, projectId, searchKeyword, searchByFrequency, testJobListFilter))
                }
              />
            )}
          </div>
        </div>
      </div>
      {getModalForCurl()}
      <PermissionDrawerV2
        openPermissionDrawer={openPermissionDrawer}
        setOpenPermissionDrawer={setOpenPermissionDrawer}
        onClose={() => setOpenPermissionDrawer(false)}
        setPermissionData={setPermissionData}
        members={members}
        setMembers={setMembers}
        previousPermission={previousPermission}
        savePermission={savePermission}
        permissionData={permissionTestJobs}
        ownerEmailId={permissionTestJobs?.createdBy?.email}
        drawerTitle={"Execution Job"}
      />
      <JobSettingModal
        testPlanId={selectedTestJob?.testPlanId}
        testJobId={selectedTestJob?.id}
        setCurrentMenuOptionsSelected={setCurrentMenuOptionsSelected}
      />
      <CommonConfirmationModalV2 {...generateBuildJobModalProps()} />
    </>
  );
};

export default AllJobsV2;
