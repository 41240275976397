import { faCamera, faClock, faRecycle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Collapse, Input, Menu, Row, Tooltip } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { Fragment, default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DISABLE,
  ELEMENT_ACTION,
  ENABLE,
  PLAY_TYPE,
  REC_TEST_STEP_PREVIEW_STATUS_TYPE,
  RESET_DEVICE_SCREEN_SOURCE,
  STEP_DISABLED,
  TEST_SCENARIOS,
  TOOLTIP_BG_COLOR,
  VERIFICATIONS,
  splitValue,
} from "../../../../Constants";
import {
  blockCollapseAction,
  checkAnyPreviousContainsInObject,
  deleteModal,
  disableStep,
  disableStepsDragging,
  dragHoverOnStepOrAction,
  getIsActionOrVerificationsOrSteps,
  getRecTestStepStatusWithImage,
  getStepActionIcon,
  getStepActionIconTooltip,
  isCurrentRecTestStepDisable,
  isNotNullAndNotEmptyAndNotUndefined,
  onDeleteAction,
} from "../../../../Util";
import { selectAllRecScenarioStepIds } from "../../../../actions/RecTestScenarioAction";
import {
  onRecTestStepDragOver,
  onRecTestStepDragStart,
  selectRecTestSteps,
  startPreview,
} from "../../../../actions/RecTestStepAction";

import {
  captureExpandedSteps,
  changeDisableStep,
  changeRecTestStepActionName,
  deleteRecTestStep,
  onRecTestStepDrop,
  showDeleteRecTestStepConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepDetail from "../../StepInnerComponents/RecTestStepDetail/RecTestStepDetail";
import UnSavedRecTestStepPopConfirm from "../UnSavedRecTestStepPopConfirm";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  ACTION_ICON_COLOR,
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  DRAG_AND_DROP_ICON,
  ICON_PERFORM_SAVE,
  IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
  RESET_ICON,
  SAVE_ICON,
} from "../../../../Constants/SvgConstants";
import { useEffect } from "react";
import HoverActionsOnRecTestStep from "../HoverActionsOnRecTestStep/HoverActionsOnRecTestStep";
import { useState } from "react";
import PerformRecTestStepAction from "../PerformRecTestStepAction/PerformRecTestStepAction";
import VerticalLine from "../../../CommonComponents/VerticalLine";
import styles from "./RecTestStepActionDetail.module.scss";

const { Panel } = Collapse;

const generateMenu = (
  isPreviewRunning,
  data,
  dispatch,
  disabled,
  props,
  startDeviceStreamingStatus,
  isSkipStep,
  stepType
) => {
  let menuItems = [];
  menuItems.push(
    !disabled && props.isSkipStep === false && !isSkipStep && data.recTestStepProperty?.isSkipStep === 0 && (
      <Menu.Item
        disabled={isPreviewRunning}
        key={menuItems.length + 1}
        onClick={() => {
          if (!disabled) {
            dispatch(
              showDeleteRecTestStepConfirmationModal(
                data?.id,
                data?.tag,
                data?.recTestScenarioRecTestStepAssocId,
                false
              )
            );
            deleteModal(
              "Are you sure you want to delete Step?",
              () => {
                dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
                dispatch(deleteRecTestStep(null));
              },
              "",
              "",
              true,
              dispatch
            );
          }
        }}
      >
        {`Delete ${getIsActionOrVerificationsOrSteps(data.recTestStepProperty?.action)}`}
      </Menu.Item>
    )
  );
  !disabled &&
    data.recTestStepProperty?.id !== undefined &&
    props.isSkipStep === false &&
    menuItems.push(
      <Menu.Item
        disabled={isPreviewRunning}
        key={menuItems.length + 1}
        onClick={() => (!disabled ? dispatch(changeDisableStep(isSkipStep ? 0 : 1, data)) : undefined)}
      >
        {isSkipStep ? ENABLE : DISABLE} {getIsActionOrVerificationsOrSteps(data.recTestStepProperty?.action)}
      </Menu.Item>
    );
  menuItems.push(
    !disabled &&
      props.isSkipStep === false &&
      !isSkipStep &&
      data.recTestStepProperty?.id !== undefined &&
      data.recTestStepProperty?.isSkipStep === 0 && (
        <SubMenu
          key={menuItems.length + 1}
          title={`Play ${getIsActionOrVerificationsOrSteps(data.recTestStepProperty?.action)}`}
          disabled={isPreviewRunning || !startDeviceStreamingStatus}
        >
          <Menu.Item
            key={"PLAY_THIS_STEP" + menuItems.length + 1}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_THIS_STEP,
                      data.tag,
                      data.recTestScenarioRecTestStepAssocId || data.id
                    )
                  )
                : undefined
            }
          >
            {data.recTestStepProperty?.action === VERIFICATIONS ? "Play this verification" : "Play this action"}
          </Menu.Item>
          <Menu.Item
            key={"PLAY_TILL_HERE" + menuItems.length + 1}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_TILL_HERE,
                      data.tag,
                      data.recTestScenarioRecTestStepAssocId || data.id
                    )
                  )
                : undefined
            }
          >
            Play till here
          </Menu.Item>
          <Menu.Item
            key={"PLAY_FROM_HERE" + menuItems.length + 1}
            onClick={() =>
              !isPreviewRunning
                ? dispatch(
                    startPreview(
                      props.projectId,
                      PLAY_TYPE.PLAY_FROM_HERE,
                      data.tag,
                      data.recTestScenarioRecTestStepAssocId || data.id
                    )
                  )
                : undefined
            }
          >
            Play from here
          </Menu.Item>
        </SubMenu>
      )
  );

  return <Menu>{menuItems}</Menu>;
};

export default (props) => {
  const {
    data,
    disableAction,
    projectId,
    isDeviceLoaded,
    index,
    parentRecTestStep,
    recTestSteps,
    showToggle,
    stepName,
    stepNo,
  } = props;

  const [isDragOver, setIsDragOver] = useState(false);
  const [dragOverOnCollapse, setDragOverOnCollapse] = useState(false);

  const { isPreviewRunning, selectedRectTestScenarioStepId, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, unSavedRecElementGuid, stepType, expandedStepTags, expandedStepAssocIds } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();

  const isSkipStep =
    stepType === TEST_SCENARIOS && data.isTestBlock === 0
      ? props.isSkipStep || data.isSkipStep === 1 || data?.recTestStepProperty?.isSkipStep === 1
      : false;
  // Disable the Action when any action has change or newly created
  let disabled = isCurrentRecTestStepDisable(unSavedRecStepTag, data, disableAction) || isPreviewRunning;
  /**
   * Preview
   *  Everything Should Disable
   *      Delete Button
   *      Edit of Step Name
   *      Import of Step Name
   *      Continue After Fail
   *  UnSavedStep
   *      Delete Button
   *      Edit of Step Name
   *      Import of Step Name
   *      Continue After Fail
   */
  const [menuVisibleInActions, setMenuVisibleInActions] = useState(false);

  useEffect(() => {
    // add and remove class on hoverEffect
    const hoverMenuVisible = document.getElementsByClassName("visibleActionMenu");
    if (hoverMenuVisible[0]) {
      const collapseHeader = hoverMenuVisible[0].getElementsByClassName("ant-collapse-header");
      if (collapseHeader[0]) {
        collapseHeader[0].classList.add("actionsMenuOnHover");
        const actionsWrapper = collapseHeader[0].getElementsByClassName("showActionsIconOnHover");
        if (actionsWrapper[0]) {
          actionsWrapper[0].classList.add("iconsOnHoverOfActions");
        }
        return () => {
          collapseHeader[0] && collapseHeader[0].classList.remove("actionsMenuOnHover");
          actionsWrapper[0] && actionsWrapper[0].classList.remove("iconsOnHoverOfActions");
        };
      }
    }
  }, [menuVisibleInActions]);

  function CollapseBorder(element, addClass) {
    const parentClassName = element.parentNode.parentNode.parentNode;
    if (parentClassName) {
      const stepOrderNameBorder = parentClassName.getElementsByClassName("recTestStepCollapseBorder");
      if (stepOrderNameBorder[0]) {
        if (addClass) {
          stepOrderNameBorder[0].classList.add("hoverBorderBlue");
        } else {
          stepOrderNameBorder[0].classList.remove("hoverBorderBlue");
        }
      }
    }
  }

  function collapseBorderOnHover() {
    CollapseBorder(this, true);
  }

  function collapseBorderOnHoverOut() {
    CollapseBorder(this, false);
  }

  useEffect(() => {
    //add or remove collapseBorder color
    const collapseHeader = document.querySelectorAll(".actionCollapseBorder > div > div.ant-collapse-header");
    for (let i = 0; i < collapseHeader.length; i++) {
      collapseHeader[i].addEventListener("mouseenter", collapseBorderOnHover);
      collapseHeader[i].addEventListener("mouseleave", collapseBorderOnHoverOut);
    }
    return () => {
      for (let i = 0; i < collapseHeader.length; i++) {
        collapseHeader[i].removeEventListener("mouseenter", collapseBorderOnHover);
        collapseHeader[i].removeEventListener("mouseleave", collapseBorderOnHoverOut);
      }
    };
  }, []);

  const PanelHeader = (data, disabled) => {
    return (
      <>
        <Row justify="space-between" align="middle">
          <Col className={styles.recTestStep}>
            <div className={styles.recTestStepTextInput}>
              <Tooltip color={TOOLTIP_BG_COLOR} title={stepName?.length > 6 && `${stepName}.${data.orderId}`}>
                <div
                  className={`${!isDisableStep && "onHoverStepOrderNameBorder"} ${stylesRecTestStepOrderName} ${
                    (selectedRectTestScenarioStepId?.includes(
                      stepType === TEST_SCENARIOS ? data?.recTestScenarioRecTestStepAssocId : data?.id
                    ) &&
                      "border-blue") ||
                    (selectedStep && "border_darkBlue")
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMultiSelectStepId(e);
                    dispatch(selectRecTestSteps(data));
                  }}
                >
                  <span className={`recTestStepName ${selectedRectTestStep ? "color__blue" : "color__darkGrayColor"}`}>
                    {data.orderId}
                  </span>
                </div>
              </Tooltip>
              <div className={styles.actionIcon}>
                <Tooltip title={getStepActionIconTooltip(data?.recTestStepProperty?.action)} color={TOOLTIP_BG_COLOR}>
                  {" "}
                  {data.recTestStepProperty?.uniqueByIndex && data.recTestStepProperty?.uniqueByIndex > 0 ? (
                    <Fragment>
                      <SvgLoader
                        iconName={getStepActionIcon(data.recTestStepProperty?.action)}
                        width="1.38rem"
                        height="1.75rem"
                      />
                      {data.recTestStepProperty?.action === ELEMENT_ACTION.TAP_BY_INDEX && (
                        <span className={styles.styledTapByIndexValue} title="Tap on index value">
                          {data.recTestStepProperty?.uniqueByIndex}
                        </span>
                      )}
                    </Fragment>
                  ) : (
                    <SvgLoader
                      iconName={getStepActionIcon(data.recTestStepProperty?.action)}
                      iconColor={ACTION_ICON_COLOR}
                      width="1.38rem"
                      height="1.75rem"
                    />
                  )}
                </Tooltip>
              </div>
              <Input
                className={styles.inputStyle}
                collapsible={"false"}
                type="text"
                placeholder="Provide a title/description for the action"
                value={data.recTestStepProperty?.stepName}
                disabled={
                  disabled ||
                  props.isSkipStep ||
                  data.isSkipStep === 1 ||
                  (stepType === TEST_SCENARIOS && data.isTestBlock === 1)
                }
                onChange={(e) => dispatch(changeRecTestStepActionName(e.target.value, data.tag))}
                onClick={(e) => {
                  blockCollapseAction(e);
                  dispatch(selectRecTestSteps(data));
                }}
                onDragOver={() => {
                  setDragOverOnCollapse(true);
                }}
                onDragLeave={handleDragLeave}
              />
            </div>
            {!isDisableStep && unSavedRecStepTag === undefined && !isPreviewRunning && (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  dispatch(selectRecTestSteps(data));
                }}
                className={!selectedStep && "showActionsIconOnHover"}
              >
                <HoverActionsOnRecTestStep
                  recTestStep={data}
                  showToggle={showToggle}
                  parentSkipStep={isSkipStep}
                  disabled={disabled}
                  projectId={projectId}
                  stepName={stepName}
                  stepNo={stepNo}
                  menuVisible={menuVisibleInActions}
                  setMenuVisible={setMenuVisibleInActions}
                  ignoreFailureDisable={disabled || props.isSkipStep}
                />
              </div>
            )}
            <div className={styles.switchOrPreviewResult}>
              <div className={styles.displayIcon}>
                {data.recTestStepProperty?.continueAfterFail === 1 && (
                  <Tooltip title="Ignore Failure & continue execution" color={TOOLTIP_BG_COLOR}>
                    <span className={styles.stylesCaptureScreenshot}>
                      <SvgLoader iconName={IGNORE_FAILURE_AND_CONTINUE_EXECUTION_ICON} />
                    </span>
                  </Tooltip>
                )}
                {data.recTestStepProperty?.isCaptureScreenshot === 1 && (
                  <Tooltip
                    color={TOOLTIP_BG_COLOR}
                    title={
                      "Capture screenshot after " +
                      data.recTestStepProperty?.captureScreenShotDelayTime +
                      " milliseconds"
                    }
                  >
                    <span className={styles.stylesCaptureScreenshot}>
                      <FontAwesomeIcon icon={faCamera} />
                    </span>
                  </Tooltip>
                )}
                {data?.recTestStepProperty?.pauseTime !== 0 && (
                  <Tooltip
                    color={TOOLTIP_BG_COLOR}
                    title={"Delay Execution for " + data.recTestStepProperty?.pauseTime + " milliseconds"}
                  >
                    <span className={styles.stylesCaptureScreenshot}>
                      <FontAwesomeIcon icon={faClock} />
                    </span>
                  </Tooltip>
                )}
                {data?.recTestStepProperty?.retryCount !== 0 && (
                  <Tooltip color={TOOLTIP_BG_COLOR} title={"Retry Count: " + data.recTestStepProperty?.retryCount}>
                    <span className={styles.stylesCaptureScreenshot}>
                      <FontAwesomeIcon icon={faRecycle} />
                    </span>
                  </Tooltip>
                )}
              </div>

              {data?.previewStatus && <VerticalLine className={styles.borderRight} />}

              <div className={styles.previewIcon}>
                {getRecTestStepStatusWithImage(
                  data?.previewStatus === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED &&
                    data?.preconditionResult === REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED
                    ? REC_TEST_STEP_PREVIEW_STATUS_TYPE.PRECONDITION_FAILED
                    : data?.previewStatus
                )}
              </div>
              {isDisableStep && (
                <div className={styles.disabledTextOrSwitch}>
                  <Tooltip
                    color={TOOLTIP_BG_COLOR}
                    title={!props.isSkipStep && (data.isSkipStep === 1 ? "Enable" : "Disable")}
                  >
                    {disableStep(
                      props.isTestBlock,
                      unSavedRecStepTag,
                      props.isSkipStep,
                      isSkipStep,
                      data,
                      dispatch,
                      isPreviewRunning,
                      stepType,
                      "disable_Switch"
                    )}
                  </Tooltip>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </>
    );
  };

  const isDisableStep = props.isSkipStep || data.isSkipStep === 1;

  const handleMultiSelectStepId = (e) => {
    if ((e.ctrlKey || e.metaKey) && !isDisableStep && !unSavedRecStepTag) {
      e.stopPropagation();
      dispatch(
        selectAllRecScenarioStepIds(stepType === TEST_SCENARIOS ? data?.recTestScenarioRecTestStepAssocId : data?.id)
      );
    }
  };

  const selectedStep =
    selectedRecStepForOperation?.id +
      "," +
      (selectedRecStepForOperation?.recTestScenarioRecTestStepAssocId ||
        selectedRecStepForOperation?.testBlockRecTestScenarioRecTestStepAssocId) ===
    data?.id + "," + (data?.recTestScenarioRecTestStepAssocId || data?.testBlockRecTestScenarioRecTestStepAssocId);

  const isUnsaved =
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag === data.tag) ||
    checkAnyPreviousContainsInObject(data.recTestStepProperty);

  const isSelected =
    stepType === TEST_SCENARIOS
      ? selectedRectTestScenarioStepId?.includes(data?.recTestScenarioRecTestStepAssocId)
      : selectedRectTestScenarioStepId?.includes(data?.id);

  const selectedRectTestStep = !isSelected && selectedStep;

  let recTestStepCollapseClassNames = `recTestStepCollapse ${
    dragOverOnCollapse && !isDisableStep && "border_onDragOver"
  } ${!isDisableStep && "recTestStepCollapseBorder"} actionCollapseBorder actionStepOrderBorder recTestStepActions `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  } else if (isSelected) {
    recTestStepCollapseClassNames += "border-blue ";
  } else if (selectedStep) {
    recTestStepCollapseClassNames += "border_darkBlue ";
  }

  const stylesRecTestStepOrderName = isDisableStep ? styles.childDisableStepOrder : styles.childStyledStepOrder;
  const selectedAction =
    stepType === TEST_SCENARIOS && data.recTestScenarioRecTestStepAssocId
      ? [...expandedStepAssocIds]
      : [...expandedStepTags];
  const activeCollapse = selectedAction?.includes(
    stepType === TEST_SCENARIOS && data.recTestScenarioRecTestStepAssocId
      ? data.recTestScenarioRecTestStepAssocId
      : data.tag
  );

  const handleDragLeave = (event) => {
    const newTarget = event.relatedTarget;
    const id = document.getElementById(data?.id);
    if (id) {
      if (!id.contains(newTarget)) {
        setDragOverOnCollapse(false);
      }
    }
  };

  return (
    <>
      <Tooltip color={TOOLTIP_BG_COLOR} title={data.isSkipStep === 1 ? STEP_DISABLED : ""}>
        {props.isSkipStep ? (
          <div className="hoverDragAndDrop"></div>
        ) : (
          dragHoverOnStepOrAction(
            isDragOver,
            setIsDragOver,
            dispatch,
            disabled || props.isSkipStep || data.isSkipStep === 1,
            data,
            parentRecTestStep,
            index
          )
        )}
        <div
          className={`${styles.styledStepHeader} ${menuVisibleInActions && !isDisableStep && "visibleActionMenu"}`}
          onDragStart={(event) =>
            !disabled || !props.isSkipStep || data.isSkipStep === 0
              ? dispatch(onRecTestStepDragStart(event, data, index, parentRecTestStep))
              : undefined
          }
          onDragOver={(event) =>
            !disabled || !props.isSkipStep || data.isSkipStep === 0
              ? (setDragOverOnCollapse(true), dispatch(onRecTestStepDragOver(event)))
              : undefined
          }
          onDrop={(event) =>
            !disabled || !props.isSkipStep || data.isSkipStep === 0
              ? (setDragOverOnCollapse(false), dispatch(onRecTestStepDrop(event, data, undefined, index)))
              : undefined
          }
          onDragLeave={handleDragLeave}
          id={data?.id}
        >
          <UnSavedRecTestStepPopConfirm
            recTestStep={data}
            key={data?.id}
            disabled={disabled}
            unSavedRecStepTag={unSavedRecStepTag}
            unSavedRecElementGuid={unSavedRecElementGuid}
          />
          <Collapse
            unSavedStep={
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag === data.tag) ||
              checkAnyPreviousContainsInObject(data.recTestStepProperty)
            }
            activeKey={
              stepType === TEST_SCENARIOS && data.recTestScenarioRecTestStepAssocId
                ? [...expandedStepAssocIds]
                : [...expandedStepTags]
            }
            className={(recTestStepCollapseClassNames += isDisableStep ? "disable_bg_color " : "bg__white ")}
            expandIcon={() => (
              <div className={`collapseIconOnHover ${styles.collapseIcon} ${isDisableStep && "collapseIconBgColor"}`}>
                <SvgLoader
                  iconName={activeCollapse ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP}
                />
              </div>
            )}
            onChange={(values) => {
              dispatch(
                captureExpandedSteps(
                  data.tag,
                  data.recTestScenarioRecTestStepAssocId,
                  values.some(
                    (value) =>
                      value ==
                      (stepType === TEST_SCENARIOS && data.recTestScenarioRecTestStepAssocId
                        ? data.recTestScenarioRecTestStepAssocId
                        : data.tag)
                  )
                )
              );
              dispatch(selectRecTestSteps(data));
            }}
            onDragLeave={handleDragLeave}
          >
            <Panel
              executionStatus={data.previewStatus}
              key={
                stepType === TEST_SCENARIOS && data.recTestScenarioRecTestStepAssocId
                  ? data.recTestScenarioRecTestStepAssocId
                  : data.tag
              }
              header={PanelHeader(data, disabled, isSkipStep)}
              extra={
                <div className={styles.actionIcon}>
                  {unSavedRecStepTag === data?.tag && (
                    <div onClick={(e) => e.stopPropagation()} className={styles.performActions}>
                      <PerformRecTestStepAction
                        action={data.recTestStepProperty?.action}
                        data={data}
                        recTestStepId={data.id}
                        recTestStepTag={data.tag}
                        disabled={
                          disabled ||
                          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                        }
                        isDeviceLoaded={isDeviceLoaded}
                        projectId={projectId}
                        isPreviewRunning={isPreviewRunning}
                        saveIcon={SAVE_ICON}
                        performSaveIcon={ICON_PERFORM_SAVE}
                        resetIcon={RESET_ICON}
                      />
                      <Tooltip color={TOOLTIP_BG_COLOR} title="Remove Action">
                        <span
                          className={styles.deleteActionIcon}
                          onClick={() => {
                            onDeleteAction(data, dispatch);
                          }}
                          disabled={
                            disabled ||
                            (stepType === TEST_SCENARIOS &&
                              data.isTestBlock === 1 &&
                              (data.recTestStepProperty?.isStepHasPrecondition === 1 ||
                                data.recTestStepProperty?.isLoopStep === 1))
                          }
                        >
                          <FontAwesomeIcon icon={faTrash} color={"red"} />
                        </span>
                      </Tooltip>
                    </div>
                  )}
                  {!isSkipStep && (
                    <div
                      className={styles.dragAndDrop}
                      onDragStart={(event) =>
                        !disabled ? dispatch(onRecTestStepDragStart(event, data, index, parentRecTestStep)) : undefined
                      }
                      onDragOver={(event) => (!disabled ? dispatch(onRecTestStepDragOver(event)) : undefined)}
                      draggable={disableStepsDragging(data, unSavedRecStepTag, stepType) ? false : true}
                      onDrop={(event) =>
                        !disabled ? dispatch(onRecTestStepDrop(event, data, parentRecTestStep, index)) : undefined
                      }
                    >
                      <SvgLoader
                        iconName={DRAG_AND_DROP_ICON}
                        width="0.61669rem"
                        height="1.33331rem"
                        cursorGrab="grab"
                      />
                    </div>
                  )}
                </div>
              }
            >
              <RecTestStepDetail
                data={data}
                recTestSteps={recTestSteps}
                parentIndex={data.orderId}
                disabled={disabled}
                isSkipStep={isSkipStep || props.isSkipStep}
                isTestBlock={props.isTestBlock}
                projectId={projectId}
                isDeviceLoaded={isDeviceLoaded}
              />
            </Panel>
          </Collapse>
        </div>
      </Tooltip>
    </>
  );
};
