import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import { isEmpty } from "lodash";
import {
  addAdditionalStepDetail,
  getStepAfterActionScreenshotUrl,
  getStepEndTimeById,
  getStepResultById,
  getStepStartTimeById,
  getStepTimeById,
} from "../../../Util";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import TestPlanResultDetailStepListV2 from "./TestPlanResultDetailStepListV2";

const ParentTestPlanResultDetailsStepV2 = (props) => {
  const {
    parentSteps,
    data,
    isTestBlock,
    activeScenarioId,
    runResultDetails,
    setCurrentActiveScenarioId,
    currentActiveScenarioId,
  } = props;
  const {
    testPlanResultStepsDetails,
    testBlockResultDataByRunId,
    testPlanLoading,
    loadingScenarios,
    previousScenarioId,
  } = useSelector((state) => state.TestPlanReducer);

  const [testPlanStepsResult, setTestPlanStepsResult] = useState(undefined);

  const isLoading = loadingScenarios.includes(data?.scenarioId) || loadingScenarios.includes(previousScenarioId);

  if (isLoading && isEmpty(parentSteps)) {
    return <Spin spinning={true} className="spinner-center-testPlanResult" />;
  }
  if (isEmpty(parentSteps)) {
    return !testPlanLoading && <EmptyRecords description={"No Data Found"} />;
  }

  const processedParentSteps = parentSteps?.map((v) => {
    const result =
      testPlanStepsResult !== undefined
        ? getStepResultById(testPlanStepsResult[data?.scenarioId]?.stepResults, v.id)
        : v?.result;
    const runId = runResultDetails?.find((i) => i)?.runId;
    return isTestBlock === 0
      ? addAdditionalStepDetail(v, result, testPlanResultStepsDetails, data?.scenarioId)
      : addAdditionalStepDetail(v, result, testBlockResultDataByRunId, runId);
  });

  return processedParentSteps?.map((step, index) => {
    const duration =
      testPlanStepsResult !== undefined
        ? getStepTimeById(testPlanStepsResult[data?.scenarioId]?.stepResults, step.id)
        : step?.duration.length > 0 && step?.duration.split(":");
    const startTime =
      testPlanStepsResult !== undefined
        ? getStepStartTimeById(testPlanStepsResult[data?.scenarioId]?.stepResults, step.id)
        : step?.startTime;
    const endTime =
      testPlanStepsResult !== undefined
        ? getStepEndTimeById(testPlanStepsResult[data?.scenarioId]?.stepResults, step.id)
        : step?.endTime;
    const afterActionScreenshotUrl =
      testPlanStepsResult !== undefined
        ? getStepAfterActionScreenshotUrl(testPlanStepsResult[data?.scenarioId]?.stepResults, step.id)
        : step?.afterActionScreenshotUrl;
    return (
      <React.Fragment key={index}>
        <TestPlanResultDetailStepListV2
          key={index}
          scenarioData={data}
          isActive={activeScenarioId && activeScenarioId.includes(data?.scenarioId)}
          isTestBlock={isTestBlock}
          stepResultData={step}
          activeScenarioId={activeScenarioId}
          parentStep={true}
          index={index}
          stepCount={"S" + (index + 1)}
          testPlanResult={step?.modifiedResult !== null ? step?.modifiedResult : step?.result}
          setCurrentActiveScenarioId={setCurrentActiveScenarioId}
          currentActiveScenarioId={currentActiveScenarioId}
          stepResultDuration={duration}
          startTime={startTime}
          endTime={endTime}
          setTestPlanStepsResult={setTestPlanStepsResult}
          testPlanStepsResult={testPlanStepsResult}
          afterActionScreenshotUrl={afterActionScreenshotUrl}
        />
      </React.Fragment>
    );
  });
};

export default ParentTestPlanResultDetailsStepV2;
