import _ from "lodash";

import { Col, Drawer, Row, Space, Tabs, Button } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { SEARCH_ICON } from "../../../CdnImagePath";
import { TEST_DATA_SCOPE, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../Constants";
import {
  changeTestDataListFilterValue,
  changeTestDataListSearchValue,
  fetchTestData,
  visibleTestDataDrawer,
  showAddTestDataForm,
} from "../../../actions/TestDataAction";
import { StyledSearch } from "../../CoreComponents/StyledComponent/StyledComponents";
import AddTestData from "../../TestData/AddTestData/AddTestData";
import TestData from "../StepInnerComponents/Actions/TestData";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ManageTestData = (props) => {
  const { data, projectId, setCurrentActionTab } = props;
  const { stepType, testDataList } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { filterFetchingTestDataList } = useSelector((state) => state.TestDataReducer);
  const dispatch = useDispatch();
  const [openAddTestDataModal, setOpenAddTestDataModal] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const { addTestDataFormVisible, showTestDataDrawer } = useSelector((state) => state.TestDataReducer);
  useEffect(() => {
    if (testDataList === undefined) {
      dispatch(fetchTestData(projectId));
      dispatch(changeTestDataListFilterValue(TEST_DATA_SCOPE.ALL, projectId));
    }
  }, [filterFetchingTestDataList, projectId, showTestDataDrawer]);

  useEffect(() => {
    dispatch(changeTestDataListSearchValue(""));
  }, [dispatch, showTestDataDrawer]);

  const handleOnChange = (e) => {
    dispatch(changeTestDataListFilterValue(e, projectId));
    dispatch(fetchTestData(projectId, e, inputValue));
  };

  const debounce = useCallback(
    _.debounce((searchWord) => {
      dispatch(fetchTestData(projectId, true, searchWord));
    }, 700),
    []
  );

  const handleChangeValue = (e) => {
    setInputValue(e.target.value);
    debounce(e.target.value);
  };
  return (
    <Drawer
      title="Test Data"
      visible={showTestDataDrawer}
      width={700}
      onClose={() => {
        dispatch(changeTestDataListFilterValue(TEST_DATA_SCOPE.ALL, projectId));
        dispatch(fetchTestData(projectId));
        dispatch(visibleTestDataDrawer());
      }}>
      <StyledDiv>
        <Space size={5}>
          <StyledSearch
            type="text"
            placeholder="Search Test Data"
            value={inputValue}
            onChange={(e) => {
              handleChangeValue(e);
            }}
            prefix={<img src={SEARCH_ICON} alt="search" className="mr-5" />}
          />
        </Space>
        <div>
          <Button
            type="primary"
            size="large"
            className="blue ml-10"
            onClick={() => dispatch(showAddTestDataForm(projectId))}>
            Add Test Data
          </Button>
        </div>
        {addTestDataFormVisible && (
          <AddTestData
            recTestStepTag={data.tag}
            recTestStepId={data.Id}
            projectId={props.projectId}
            openAddTestDataModal={openAddTestDataModal}
            setOpenAddTestDataModal={setOpenAddTestDataModal}
          />
        )}
      </StyledDiv>
      <Tabs defaultActiveKey={TEST_DATA_SCOPE.ALL} onChange={(e) => handleOnChange(e)}>
        <Tabs.TabPane key={TEST_DATA_SCOPE.ALL} tab={TEST_DATA_SCOPE.ALL}>
          <Row>
            <Col span={24}>
              <TestData
                recTestStepAction={data?.recTestStepProperty?.action}
                recTestStepTag={data?.tag}
                recTestStepId={data?.Id}
                setCurrentActionTab={setCurrentActionTab}
                projectId={projectId}
                isSkipStep={props.isSkipStep}
                data={data}
                setOpenAddTestDataModal={setOpenAddTestDataModal}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane key={TEST_DATA_SCOPE.PROJECT} tab={TEST_DATA_SCOPE.PROJECT}>
          <Row>
            <Col span={24}>
              <TestData
                recTestStepAction={data?.recTestStepProperty?.action}
                recTestStepTag={data?.tag}
                recTestStepId={data?.Id}
                setCurrentActionTab={setCurrentActionTab}
                projectId={projectId}
                isSkipStep={props.isSkipStep}
                data={data}
                setOpenAddTestDataModal={setOpenAddTestDataModal}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
        {stepType === TEST_SCENARIOS && (
          <Tabs.TabPane key={TEST_DATA_SCOPE.TEST_SCENARIO} tab={TEST_DATA_SCOPE.TEST_SCENARIO}>
            <Row>
              <Col span={24}>
                <TestData
                  recTestStepAction={data?.recTestStepProperty?.action}
                  recTestStepTag={data?.tag}
                  recTestStepId={data?.Id}
                  setCurrentActionTab={setCurrentActionTab}
                  projectId={projectId}
                  isSkipStep={props.isSkipStep}
                  data={data}
                  setOpenAddTestDataModal={setOpenAddTestDataModal}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
        )}
        {stepType === TEST_STEP_BLOCKS && (
          <Tabs.TabPane key={TEST_DATA_SCOPE.TEST_STEP_BLOCK} tab={TEST_DATA_SCOPE.TEST_STEP_BLOCK}>
            <Row>
              <Col span={24}>
                <TestData
                  recTestStepAction={data?.recTestStepProperty?.action}
                  recTestStepTag={data?.tag}
                  recTestStepId={data?.Id}
                  setCurrentActionTab={setCurrentActionTab}
                  projectId={projectId}
                  isSkipStep={props.isSkipStep}
                  data={data}
                  setOpenAddTestDataModal={setOpenAddTestDataModal}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
        )}
        <Tabs.TabPane key={"System Key"} tab="System Key">
          <Row>
            <Col span={24}>
              <TestData
                recTestStepAction={data?.recTestStepProperty?.action}
                recTestStepTag={data?.tag}
                recTestStepId={data?.Id}
                setCurrentActionTab={setCurrentActionTab}
                projectId={projectId}
                isSkipStep={props.isSkipStep}
                data={data}
                setOpenAddTestDataModal={setOpenAddTestDataModal}
              />
            </Col>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Drawer>
  );
};

export default ManageTestData;
