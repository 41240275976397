import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tabs } from "antd";
import SystemKeyTestData from "../SystemKeyTestData";
import SystemKeyTestDataSetList from "../SystemKeyTestDataSetList";
import { fetchTestDataByProjectId } from "../../../actions/TestDataAction";
import { fetchSystemKeyByProjectId } from "../../../actions/TestDataSetAction";
import { KEYS, PROFILES, SYSTEM_KEYS } from "../../../Constants";
import { changeTestDataSetView, setTestDataSetAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";

const { TabPane } = Tabs;
const SystemKeys = (props) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const [getActiveKey, setActiveKey] = useState(KEYS);

  useEffect(() => {
    dispatch(changeTestDataSetView(SYSTEM_KEYS));
    dispatch(setTestDataSetAssigneeDetail({ id: props.projectId }));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setTestDataSetAssigneeDetail(undefined));
    };
  }, []);

  const handleOnChange = (e) => {
    setActiveKey(e);
    if (e === KEYS) {
      dispatch(fetchTestDataByProjectId(props.projectId));
    } else {
      dispatch(fetchSystemKeyByProjectId());
    }
  };
  return (
    <Tabs defaultActiveKey={KEYS} activeKey={getActiveKey} onChange={(e) => handleOnChange(e)}>
      <TabPane tab={"Keys"} key={KEYS}>
        <SystemKeyTestData projectId={projectId} />
      </TabPane>
      <TabPane tab={"Profiles"} key={PROFILES}>
        <SystemKeyTestDataSetList projectId={props.projectId} stepType={SYSTEM_KEYS} />
      </TabPane>
    </Tabs>
  );
};

export default SystemKeys;
