import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import { resetEditRecTestStep } from "../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  changeRecTestStepPreConditionTitle,
  updateRecTestStepAction,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepDetailV2 from "../../StepInnerComponents/RecTestStepDetailV2/RecTestStepDetailV2";
import styles from "./RecTestStepConditionV2.module.scss";

const RecTestStepPreConditionV2 = (props) => {
  const dispatch = useDispatch();
  const { recTestStep, isDeviceLoaded, projectId, isSkipStep, disabled, recTestSteps, isTestBlock } = props;
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  
  //Destructure object
  const { isSkipStep: skipStepOfStep, preConditionTitle } =
    recTestStep && recTestStep.recTestStepProperty ? recTestStep.recTestStepProperty : {};

  return (
    <div>
      <div className={styles["inputTitleOrInputBox"]}>
        <div className={styles['title_And_Input_Box']}>
          <span className={styles["inputTitle"]}>Title</span>
          <Input
            className={styles["inputStyle"]}
            collapsible={"false"}
            placeholder="Provide a title/description for the Verification"
            type="text"
            value={preConditionTitle}
            disabled={disabled || isSkipStep}
            onChange={(e) => {
              !disabled && dispatch(changeRecTestStepPreConditionTitle(e.target.value, recTestStep.tag));
            }}
          />
        </div>
      </div>
      <RecTestStepDetailV2
        isSkipStep={skipStepOfStep || props.isSkipStep}
        data={recTestStep}
        parentIndex={recTestStep.orderId}
        isDeviceLoaded={isDeviceLoaded}
        projectId={projectId}
        recTestSteps={recTestSteps}
        disabled={disabled}
        isTestBlock={isTestBlock}
      />
    </div>
  );
};

export default RecTestStepPreConditionV2;
