import { Col, Collapse, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { highlightResult } from "../../../../Util";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import LocaleViewDeviceCard from "./LocaleViewDeviceCard";

const { Panel } = Collapse;

const TestPlanResultLocaleView = ({ isTestBlock ,runNumber}) => {
  const { testPlanResultDetails, runResultDetails } = useSelector((state) => state.TestPlanReducer);

  return (
    <Col span={24} className="mb-50">
      <TestPlanResultDetailsScenario>
        {testPlanResultDetails != null &&
          testPlanResultDetails[0] &&
          testPlanResultDetails[0]?.scenarios?.map((v, k) => {
            return (
              <>
                <Collapse className="border-none" expandIconPosition="right" key={k}>
                  <Panel
                    className="mt-10"
                    showArrow={true}
                    header={
                      <Row className="display-flex">
                        <div className="width-20 font-weight-500">{k + 1}</div>
                        <div>
                          <div>
                            <span className="font-weight-700">Locale: </span>
                            <span className="font-weight-500">{v?.locale}</span>
                          </div>
                          <div>
                            <span className="font-weight-700">Total Devices to Execute on: </span>
                            {highlightResult(v?.resultCount)}
                          </div>
                        </div>
                      </Row>
                    }
                  >
                    <LocaleViewDeviceCard
                      deviceDetails={v?.devices}
                      key={k}
                      runResultDetails={runResultDetails}
                      isTestBlock={isTestBlock}
                      runNumber={runNumber}
                    />
                  </Panel>
                </Collapse>
              </>
            );
          })}
      </TestPlanResultDetailsScenario>
    </Col>
  );
};

export default TestPlanResultLocaleView;
