import React from "react";
import { Checkbox } from "antd";
import { ACTION_VIEW } from "../../../Constants";

const SelectionCheckBoxV2 = (props) => {
  const { row, selectAllData, setSelectAllData } = props;
  return (
    row.allowDelete === ACTION_VIEW && (
      <div>
        <Checkbox
          className="selection-checkBox mr-26"
          checked={selectAllData && selectAllData?.includes(row?.id)}
          onChange={(e) => {
            e.target.checked
              ? setSelectAllData([...selectAllData, row.id])
              : setSelectAllData(selectAllData && selectAllData?.filter((i) => i !== row?.id));
          }}
        />
      </div>
    )
  );
};

export default SelectionCheckBoxV2;
