import { Button, Input, Modal, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ANDROID, IOS } from "../../../Constants";
import {
  closeAddEmulatorModalVisible,
  createVirtualDevice,
  getSupportedDeviceOSVersion,
  getSupportedVirtualDevice,
  updateAddEmulator,
} from "../../../actions/ServerAction";
const Option = Select.Option;
const AddEmulatorModal = (props) => {
  const{orgId}=props;
  const dispatch = useDispatch();
  const { addEmulatorModalVisible, addEmulatorObj,emualtorSupportedDeviceList,emualtorSupportedOSList} = useSelector(
    (state) => state.ServerReducer
  );
  return (
    <>
      <Modal
        title="Add Emulator"
        visible={addEmulatorModalVisible}
        onOk={() => dispatch(closeAddEmulatorModalVisible(false))}
        onCancel={() => dispatch(closeAddEmulatorModalVisible(false))}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              dispatch(
                createVirtualDevice(orgId)
              );
              dispatch(closeAddEmulatorModalVisible(false));
            }}
            disabled={false}
          >
            Save
          </Button>,
        ]}
      >
        <div>
          <div className="font-size-14 font-weight-500 ml-5">Platform</div>
          <Select
            className="w-100 m-5"
            placeholder="Select Platform"
            onChange={(value) => {
              dispatch(
                updateAddEmulator({ ...addEmulatorObj, platformType: value })
              );
              dispatch(getSupportedDeviceOSVersion(value));
              dispatch(getSupportedVirtualDevice(value));
            }}
            value={addEmulatorObj?.platformType}
          >
            <Option key={"ANDROID"} value={ANDROID}>
              Android
            </Option>
            <Option key={"IOS"} value={IOS}>
              ios
            </Option>
          </Select>
          <div className="font-size-14 font-weight-500 ml-5">Supported OS List</div>
          <Select
            className="w-100 m-5"
            placeholder="Select OS"
            onChange={(value) => {
              dispatch(
                updateAddEmulator({ ...addEmulatorObj, osVersion: value })
              );
            }}
            value={addEmulatorObj?.osVersion}
          >
            {emualtorSupportedOSList && emualtorSupportedOSList.map(value =>{
              return(
                <Option key={value} value={value}>{value}</Option>
              );
            })}
          </Select>
          <div className="font-size-14 font-weight-500 ml-5">Supported Device List</div>
          <Select
            showSearch={true}
            className="w-100 m-5"
            placeholder="Select Device"
            onChange={(value) => {
              dispatch(
                updateAddEmulator({ ...addEmulatorObj, androidAvdDeviceName: value })
              );
            }}
            value={addEmulatorObj?.androidAvdDeviceName}
          >
           {emualtorSupportedOSList && emualtorSupportedDeviceList.map(value =>{
              return(
                <Option key={value} value={value}>{value}</Option>
              );
            })}
          </Select>
          <div className="font-size-14 font-weight-500 ml-5">Device Name</div>
          <Input
              placeHolder={"Device Name"}
              value={addEmulatorObj?.deviceName}
              onChange={(e)=> dispatch(
                updateAddEmulator({ ...addEmulatorObj, deviceName: e.target.value.replace(" ","_") })
              )}
              allowClear
            />
        </div>
        
      </Modal>
    </>
  );
};

export default AddEmulatorModal;
