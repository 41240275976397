import React from "react";
import { getFormateDateAndTime } from "../../Util";
import generatedGitInfo from "../../generatedGitInfo.json";
import { TOOLTIP_BG_COLOR, dateHourMinuteFormat } from "../../Constants";
import { Tooltip } from "antd";
import styles from "./FooterV2.module.scss";

const FooterV2 = () => {
  return (
    <div className={styles["footer"]}>
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={
          <div>
            <div>Branch: {generatedGitInfo.gitBranch}</div>
            <div>Date: {generatedGitInfo.gitCommitDate}</div>
            <div>Last Build On: {getFormateDateAndTime(generatedGitInfo?.buildDate, dateHourMinuteFormat)}</div>
            <div>Commit Id: {generatedGitInfo.gitCommitHash}</div>
          </div>
        }
      >
        Version 2.0
      </Tooltip>
    </div>
  );
};

export default FooterV2;
