import React, { useEffect } from "react";
import { Checkbox, Col, Pagination, Row, Select } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchOtherRuns, handleChangeErrorInvestigationData } from "../../../actions/ErrorInvestigationActions";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import { OTHER_RUNS_OPTION, RECORD_PER_PAGE } from "../../../Constants";
import { fetchOtherRunIds, openTestPlanResultUrl } from "../../../Util";

const SameInvestigationAndOtherRunsFailure = (props) => {
  const dispatch = useDispatch();
  const { failedTestStepId, orgId, searchOption, setSearchOption, 
        currentSelectedId, setCurrentSelectedId, projectId } = props;
  const { errorInvestigationDetails, currentPage, totalPage, fetchOtherRunList } = useSelector(
    (state) => state.ErrorInvestigationReducer
  );

  useEffect(() => {
    setCurrentSelectedId(errorInvestigationDetails?.otherTestStepId);
  }, [fetchOtherRunList, errorInvestigationDetails?.otherTestStepId]);

  const onChangeHandler = (e) => {
    let selectedID = [...currentSelectedId];
    if (selectedID.includes(e.target.value)) {
      const dId = selectedID.indexOf(e.target.value);
      selectedID.splice(dId, 1);
    } else {
      selectedID.push(e.target.value);
    }
    setCurrentSelectedId(_.uniq(selectedID));
    dispatch(
      handleChangeErrorInvestigationData({
        ...errorInvestigationDetails,
        otherTestStepId: _.uniq(selectedID),
      })
    );
  };

  return (
    <Row className="mb-10 w-100">
      <Row className="p-10 ">
        <h3>Apply to other Runs with same failure</h3>
      </Row>
      <div className="Container w-100">
        <div className="w-40 mb-10">
          <Select
            className="w-100 text-transform-capital"
            placeholder="Please Select Option"
            value={searchOption}
            onChange={(e) => {
              setSearchOption(e);
              dispatch(fetchOtherRuns(failedTestStepId, 1, e));
              setCurrentSelectedId([]);
            }}
          >
            {Object.values(OTHER_RUNS_OPTION)?.map((i) => (
              <Select.Option className="text-transform-capital" key={i} value={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </div>
        <Row className="w-100 display-flex justify-evenly p-10 font-weight-500 bg-color-gray">
          <Col span={2} className="align-self-center">
            {!_.isEmpty(fetchOtherRunList) && (
              <Checkbox
                checked={
                  fetchOtherRunIds(searchOption, errorInvestigationDetails?.allOtherRunIds)?.length ===
                  errorInvestigationDetails?.otherTestStepId?.length
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    dispatch(
                      handleChangeErrorInvestigationData({
                        ...errorInvestigationDetails,
                        otherTestStepId: fetchOtherRunIds(searchOption, errorInvestigationDetails?.allOtherRunIds),
                      })
                    );
                    setCurrentSelectedId(fetchOtherRunIds(searchOption, errorInvestigationDetails?.allOtherRunIds));
                  } else {
                    dispatch(
                      handleChangeErrorInvestigationData({
                        ...errorInvestigationDetails,
                        otherTestStepId: [],
                      })
                    );
                    setCurrentSelectedId([]);
                  }
                }}
              />
            )}
          </Col>
          <Col span={4} className="text-align-center">
            Run Number
          </Col>
          <Col span={4} className="text-align-center">
            Status
          </Col>
          <Col span={4} className="text-align-center">
            Investigation
          </Col>
          <Col span={4} className="text-align-center">
            Error Description
          </Col>
        </Row>
        {!_.isEmpty(fetchOtherRunList) &&
          fetchOtherRunList?.map((i, j) => {
            return (
              <>
                <Row key={j} className="w-100 display-flex justify-evenly p-10 border-top">
                  <Col span={2} className="align-self-center">
                    <Checkbox
                      onChange={(e) => onChangeHandler(e)}
                      value={i.testStepId}
                      checked={
                        !_.isEmpty(currentSelectedId)
                          ? currentSelectedId.includes(i.testStepId)
                          : !_.isEmpty(errorInvestigationDetails?.otherTestStepId)
                          ? errorInvestigationDetails?.otherTestStepId.includes(i.testStepId)
                          : ""
                      }
                    />
                  </Col>
                  <Col span={4} className="text-align-center align-self-center word-break">
                    <div
                      className="font-color-blue cursor-pointer text-decoration-underline"
                      onClick={() => openTestPlanResultUrl(orgId, i?.runNumber, projectId)}
                    >
                      Run-{i?.runNumber}
                    </div>
                  </Col>
                  <Col span={4} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails ? "Investigated" : "Pending"}
                  </Col>
                  <Col span={4} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails?.failureReason || "N/A"}
                  </Col>
                  <Col span={4} className="text-align-center align-self-center word-break">
                    {i?.investigationDetails?.errorDescription || "N/A"}
                  </Col>
                </Row>
              </>
            );
          })}
        {_.isEmpty(fetchOtherRunList) && <EmptyRecords description={"No Data Found"} setMargin={true}/>}
        {!_.isEmpty(fetchOtherRunList) && (
          <Pagination
            className="mt-10 display-flex justify-flex-end"
            defaultCurrent={currentPage}
            current={currentPage}
            total={totalPage}
            defaultPageSize={RECORD_PER_PAGE}
            onChange={(page, pageSize) => dispatch(fetchOtherRuns(failedTestStepId, page, searchOption))}
          />
        )}
      </div>
    </Row>
  );
};

export default SameInvestigationAndOtherRunsFailure;
