import { default as React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "antd";
import { ALL_TEST_PLANS, BUILD_TEST_PLAN, TEST_DATA_SET_LIST } from "../../../Constants";
import { setTestDataSetAssigneeDetail } from "../../../actions/TestDataSet/TestDataSetCommonAction";
import { changeTestPlanDetails, changeTestPlanTab } from "../../../actions/TestPlanAction";
import TestDataSetTabsV2 from "../../CommonComponents/TestStepsData/TestDataSetTabsV2";
import TestPlanDetailsV2 from "../../TestPlanV2/TestPlanDetailsV2/TestPlanDetailsV2";
import BuildTestPlanV2 from "./TestPlanTabs/BuildTestPlanV2/BuildTestPlanV2";
import TestPlanTrend from "./TestPlanTrend";

const TestPlanManager = (props) => {
  const dispatch = useDispatch();
  const { selectedDeviceTabActionKey } = useSelector((state) => state.RecordingReducer);
  const { selectedTestPlanTab, testPlanTrendDrawerVisible, testPlanDetails } = useSelector(
    (state) => state.TestPlanReducer
  );
  const { testDataSetAssigneeDetail } = useSelector((state) => state.TestDataSetReducer);

  useEffect(() => {
    if (selectedDeviceTabActionKey !== ALL_TEST_PLANS) {
      dispatch(changeTestPlanTab(ALL_TEST_PLANS));
    }
  }, [selectedDeviceTabActionKey, props.projectId, dispatch]);

  useEffect(() => {
    if (testDataSetAssigneeDetail) {
      dispatch({ type: TEST_DATA_SET_LIST, undefined });
      dispatch(setTestDataSetAssigneeDetail(undefined));
    }
    if (selectedTestPlanTab !== BUILD_TEST_PLAN) {
      dispatch(changeTestPlanDetails(undefined));
    }
  }, [selectedTestPlanTab, dispatch]);

  useEffect(() => {
    dispatch(setTestDataSetAssigneeDetail(undefined));
  }, [props.projectId]);

  return (
    <>
      {testDataSetAssigneeDetail ? (
        <div className="m-0">
          <TestDataSetTabsV2 projectId={props.projectId} selectedStepId={testPlanDetails?.id} />
        </div>
      ) : selectedTestPlanTab === ALL_TEST_PLANS ? (
        <Col span={24}>
          <TestPlanDetailsV2
            projectId={props.projectId}
            setCurrentMenuOptionsSelected={props.setCurrentMenuOptionsSelected}
            orgId={props.orgId}
          />
        </Col>
      ) : selectedTestPlanTab === BUILD_TEST_PLAN ? (
        <Col span={24}>
          <BuildTestPlanV2 projectId={props.projectId} />
        </Col>
      ) : (
        ""
      )}

      {testPlanTrendDrawerVisible && <TestPlanTrend />}
    </>
  );
};

export default TestPlanManager;
