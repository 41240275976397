import { Col, Collapse, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import LocaleViewDeviceCard from "./LocaleViewDeviceCard";
import { highlightResult } from "../../../../Util";

const { Panel } = Collapse;

const TestPlanResultInvestigationView = ({ isTestBlock, runNumber }) => {
  const { testPlanResultDetails, runResultDetails } = useSelector((state) => state.TestPlanReducer);

  return (
    <Col span={24} className="mb-50">
      <TestPlanResultDetailsScenario>
        {testPlanResultDetails[0]?.scenarios?.map((v, k) => {
          return (
            <Collapse className="border-none" expandIconPosition="right" key={v?.scenarioIds}>
              <Panel
                className="mt-10"
                showArrow={true}
                header={
                  <Row className="display-flex">
                    <div className="width-20 font-weight-500">{k + 1}</div>
                    <div>
                      <div>
                        <span className="font-weight-700">Failure Category: </span>
                        <span className="font-weight-500">{v?.productBug}</span>
                      </div>
                      <div>
                        <span className="font-weight-700">Total Failures: </span>
                        {highlightResult(v?.resultCount, true)}
                      </div>
                    </div>
                  </Row>
                }
              >
                <LocaleViewDeviceCard
                  deviceDetails={v?.devices}
                  key={v?.scenarioIds}
                  runResultDetails={runResultDetails}
                  isTestBlock={isTestBlock}
                  runNumber={runNumber}
                />
              </Panel>
            </Collapse>
          );
        })}
      </TestPlanResultDetailsScenario>
    </Col>
  );
};

export default TestPlanResultInvestigationView;
