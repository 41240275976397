import { Col, Row, Spin } from "antd";
import { default as React } from "react";
import { useSelector } from "react-redux";
import Devices from "../DevicesView/Devices";
import DeviceFarmList from "./DeviceFarmList";
const DeviceFarmView = (props) => {
  const { deviceLoading } = useSelector((state) => state.ManageFarmReducer);
  const {
    projectId,
    orgId,
    setDefaultsAppRightsForDevice,
    editRightsForDeviceNote,
    childComponentList,
    deviceFarmList,
  } = props;
  return (
    <Spin spinning={deviceLoading} tip="Loading" className="spinner-center">
     <Row className="w-100"> <DeviceFarmList projectId={projectId} orgId={orgId} /></Row>
      {deviceFarmList && deviceFarmList.length > 0 && (
        <Row>
          <Col span={24}>
            {
              <Devices
                orgId={orgId}
                projectId={projectId}
                setDefaultsAppRightsForDevice={setDefaultsAppRightsForDevice}
                editRightsForDeviceNote={editRightsForDeviceNote}
                adminReleaseDevice={
                  childComponentList &&
                  childComponentList.filter((item) => item.name === "Admin_Release_Device").length === 1
                }
              />
            }
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default DeviceFarmView;
