import { Popover } from "antd";
import isEmpty from "lodash/isEmpty";
import React from "react";
import ShowTooltipV2 from "../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./TestBlockDetailsColumnsV2.module.scss";

const UsedInScenarioColumnV2 = ({ row, index }) => {
  const showFirstJob = (testScenario) => {
    return (
      <ShowTooltipV2 text={testScenario} className={styles["used-In-Scenario-Details"]} />
    );
  };

  const showListOfTestScenario = (testScenario, index) => {
    if (testScenario?.length > 1) {
      return (
        <>
          <span key={index} className={`${styles["label-name"]} ${styles["popover_Name"]}`}>
            {showFirstJob(testScenario[0])}
            <Popover
              key={"Popover-" + index}
              content={testScenario?.slice(1)?.map((i, index) => (
                <div
                  className={` ${styles["popover-contents"]} ${
                    testScenario?.length - 2 !== index && styles["border-bottom"]
                  }`}
                >
                  <span className={styles["data"]}>{i}</span>
                </div>
              ))}
              title="Test Scenario Names"
            >
              <span className={styles["popover-details"]}>
                {testScenario?.slice(1).length === 1
                  ? `+${testScenario?.slice(1).length} other`
                  : `+${testScenario?.slice(1).length} others`}
              </span>
            </Popover>
          </span>
        </>
      );
    } else {
      return <span key={index}>{!isEmpty(testScenario) ? showFirstJob(testScenario[0]) : "N/A"}</span>;
    }
  };
  return (
    <div key={index}>
      <span className={styles["label-name"]}>Total: </span>
      <span className={styles["step-And-Test-Plan-Details"]}>{row?.testScenarios?.length}</span>
      <div>{row?.testScenarios?.length >= 1 && showListOfTestScenario(row?.testScenarios, row?.id)}</div>
    </div>
  );
};

export default UsedInScenarioColumnV2;
