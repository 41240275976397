import { Button, Drawer, Radio, Select } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeTestJobScheduleData,
  closeTestJobScheduleDrawer,
  disableScheduleJob,
  getTimeZone,
  resetTestScheduleJobData,
  saveScheduleJob,
} from "../../../../actions/TestJobActions";
import { CLOSE_ICON_GRAY } from "../../../../CdnImagePath";
import { ONETIME, RECURRING } from "../../../../Constants";
import { checkUndefined } from "../../../../Util";
import { Label } from "../../../CoreComponents/StyledComponent/StyledComponents";
import OneTimeSchedule from "./OneTimeSchedule";
import RecurringSchedule from "./RecurringSchedule";
const { Option } = Select;

const BuildJobSchedule = (props) => {
  const dispatch = useDispatch();
  const { projectId, rights } = props;
  const {
    getTimeZoneList,
    testJobScheduleDetail,
    testJobScheduleErrorFlag,
    previousTestJobScheduleDetail,
    testJobSchedulerDrawerVisible,
    jobLoading,
  } = useSelector((state) => state.TestJobReducer);

  useEffect(() => {
    dispatch(getTimeZone());
  }, []);

  const timeZoneList = [];
  if (!_.isEmpty(getTimeZoneList?.timeZones)) {
    for (let i = 0; i < getTimeZoneList?.timeZones.length; i++) {
      timeZoneList.push(
        <Option key={getTimeZoneList?.timeZones[i]?.name} value={getTimeZoneList?.timeZones[i]?.name}>
          {getTimeZoneList?.timeZones[i]?.name}
        </Option>
      );
    }
  }
  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>Schedule Test Run</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => dispatch(closeTestJobScheduleDrawer())}
            />
          </span>
        </div>
      }
      placement="right"
      closable={false}
      width={"736"}
      onClose={() => dispatch(closeTestJobScheduleDrawer())}
      visible={testJobSchedulerDrawerVisible}
      bodyStyle={{ padding: 0 }}>
      <>
        <div className="p-20 bg-color-white">
          {(previousTestJobScheduleDetail !== undefined && previousTestJobScheduleDetail.isJobScheduled === 0) ||
          testJobScheduleDetail?.isJobScheduled === 1 ? (
            <>
              <div className="mb-20">
                <span className="font-size-13">Job: </span>
                <span className="font-blueDark font-weight-600 ">{testJobScheduleDetail?.name}</span>
              </div>
              <div className="font-size-14 font-weight-500 mb-10">Repeat</div>
              <div>
                <Radio.Group
                  onChange={(e) => {
                    dispatch(
                      changeTestJobScheduleData({
                        ...testJobScheduleDetail,
                        executionFrequency: e.target.value,
                        isJobScheduled: 1,
                        scheduleTime:
                          e.target.value === ONETIME
                            ? moment(new Date())
                            : previousTestJobScheduleDetail
                            ? previousTestJobScheduleDetail.scheduleTime
                            : undefined,
                        scheduleDate:
                          e.target.value === ONETIME
                            ? moment(new Date())
                            : previousTestJobScheduleDetail
                            ? previousTestJobScheduleDetail.scheduleDate
                            : undefined,
                        scheduleDateTime:
                          e.target.value === ONETIME
                            ? moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                            : previousTestJobScheduleDetail
                            ? previousTestJobScheduleDetail.scheduleDateTime
                            : undefined,
                        timeZoneName: Intl.DateTimeFormat().resolvedOptions().timeZone,
                        days: previousTestJobScheduleDetail ? previousTestJobScheduleDetail.days : [],
                        hours: previousTestJobScheduleDetail ? previousTestJobScheduleDetail.hours : [],
                        minutes: previousTestJobScheduleDetail ? previousTestJobScheduleDetail.minutes : [],
                      })
                    );
                  }}
                  value={testJobScheduleDetail.executionFrequency}>
                  <Radio
                    className={testJobScheduleDetail?.executionFrequency === ONETIME ? "activeRadioBox" : "radioBox"}
                    value={ONETIME}>
                    One Time
                  </Radio>
                  <Radio
                    className={testJobScheduleDetail?.executionFrequency === RECURRING ? "activeRadioBox" : "radioBox"}
                    value={RECURRING}>
                    Recurring
                  </Radio>
                </Radio.Group>
              </div>
            </>
          ) : (
            "Scheduler is Off"
          )}
        </div>
        <div className="schedule">
          {(previousTestJobScheduleDetail !== undefined && previousTestJobScheduleDetail.isJobScheduled === 0) ||
          testJobScheduleDetail?.isJobScheduled === 1 ? (
            <>
              {testJobScheduleDetail.executionFrequency === ONETIME && (
                <OneTimeSchedule testJobScheduleDetail={testJobScheduleDetail} timeZoneList={timeZoneList} />
              )}
              {testJobScheduleDetail.executionFrequency === RECURRING && (
                <RecurringSchedule testJobScheduleDetail={testJobScheduleDetail} timeZoneList={timeZoneList} />
              )}
            </>
          ) : (
            "Scheduler is Off"
          )}
        </div>
        <div className="position-absolute bottom-0 w-100">
          <div className="float-left mt-10 mb-10 ml-20">
            <Button
              type="primary"
              className="mr-20 grayBtn"
              disabled={
                jobLoading ||
                (previousTestJobScheduleDetail?.isJobScheduled === 0 &&
                  previousTestJobScheduleDetail?.executionFrequency === undefined)
              }
              loading={jobLoading}
              onClick={(e) =>
                !jobLoading && !testJobScheduleErrorFlag
                  ? dispatch(disableScheduleJob(projectId, rights?.id))
                  : undefined
              }>
              Disable Job
            </Button>
          </div>
          <div className="float-right mt-10 mb-10">
            <Button
              className="mr-20 lightPurple"
              disabled={
                jobLoading ||
                previousTestJobScheduleDetail?.isJobScheduled === 0 ||
                checkUndefined(previousTestJobScheduleDetail) ||
                JSON.stringify(testJobScheduleDetail) === JSON.stringify(previousTestJobScheduleDetail)
              }
              type="primary"
              onClick={(e) => {
                dispatch(resetTestScheduleJobData());
              }}>
              {"Cancel"}
            </Button>
            <Button
              type="primary"
              className="mr-20 blue"
              disabled={
                jobLoading ||
                testJobScheduleErrorFlag ||
                (previousTestJobScheduleDetail !== undefined &&
                  JSON.stringify(testJobScheduleDetail) === JSON.stringify(previousTestJobScheduleDetail))
              }
              loading={jobLoading}
              onClick={(e) =>
                !jobLoading && !testJobScheduleErrorFlag
                  ? dispatch(saveScheduleJob(projectId, ""))
                  : undefined
              }>
              Save
            </Button>
          </div>
        </div>
      </>
    </Drawer>
  );
};

export default BuildJobSchedule;
