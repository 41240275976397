import React from "react";
import { Checkbox, Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { DELETE_ICON_NEW, RED_COLOR } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./SelectAllBoxV2.module.scss";

const SelectAllBoxV2 = (props) => {
  const { isChecked, isDisable, deleteConfirm, selectAllIds, handleChangeCheck, recTestStep } = props;
  return (
    !isDisable && (
      <div className={styles["selected-Box-And-Delete-Icon"]}>
        <Tooltip color={TOOLTIP_BG_COLOR} title={isChecked ? "Deselect All" : "Select All"} placement="bottomLeft">
          <div className={styles["select-all"]}>
            <Checkbox
              checked={isChecked}
              disabled={isDisable}
              onChange={(e) => {
                handleChangeCheck(e.target.checked);
              }}
              className="selection-checkBox checkBox-In-Table-Header"
            >
              <div className={styles["selected-length"]}>
                {isEmpty(selectAllIds) ? (
                  "Select All"
                ) : (
                  <>
                    <span>{selectAllIds?.length}</span> <span>Selected</span>
                  </>
                )}
              </div>
            </Checkbox>
          </div>
        </Tooltip>
        {!isEmpty(selectAllIds) && (
          <Tooltip
            color={TOOLTIP_BG_COLOR}
            title={recTestStep && "To Delete The Custom Item Press (Ctrl / Command Key) + (Select Item)."}
          >
            <div className={styles["delete-Icon-And-Text"]} onClick={() => deleteConfirm()}>
              <SvgLoader iconName={DELETE_ICON_NEW} iconColor={RED_COLOR} />
              <div className={styles["delete-Text"]}>Delete</div>
            </div>
          </Tooltip>
        )}
      </div>
    )
  );
};

export default SelectAllBoxV2;
