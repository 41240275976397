import React, { useState } from "react";
import { Modal} from "antd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { hideRecTestScenarioListModalVisible, resetAllRecScenarioStepIds } from "../../actions/RecTestScenarioAction";
import { getRecTestStepListByRecTestScenarioId } from "../../actions/RecTestStepAction";
import RecTestScenarioPage from "./RecTestScenarioPage/RecTestScenarioPage";
import { SET_SELECTED_SCENARIO } from "../../Constants";

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0px !important;
  }
`;

const RecScenarioListModal = (props) => {
  const dispatch = useDispatch();
  const { recTestScenarioListModalVisible, selectedScenario } = useSelector((state) => state.RecTestScenarioReducer);
  const [searchKeyword, setSearchKeyword] = useState("");

  return (
    <StyledModal
      width="70%"
      title={"Recorded Scenario List"}
      visible={recTestScenarioListModalVisible}
      okText={<div>Load</div>}
      okButtonProps={{
        disabled: selectedScenario.id === undefined,
      }}
      onOk={() => {
        if (selectedScenario.id !== undefined) {
          dispatch(
            getRecTestStepListByRecTestScenarioId(
              selectedScenario.id,
              selectedScenario.name,
              selectedScenario.tags,
              selectedScenario.testRailTestCaseId,
              selectedScenario.testRailTestSuiteId,
              props.projectId,
              selectedScenario.platform
            )
          );
          setSearchKeyword();
          dispatch(hideRecTestScenarioListModalVisible());
          dispatch(resetAllRecScenarioStepIds());
        }
      }}
      onCancel={() => {
        setSearchKeyword();
        dispatch(hideRecTestScenarioListModalVisible());
        dispatch({ type: SET_SELECTED_SCENARIO, data:{} });
      }}>
      <RecTestScenarioPage
        isScenarioLoadModel={true}
        projectId={props.projectId}
        selectedScenarioId={selectedScenario.id}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
      />
    </StyledModal>
  );
};

export default RecScenarioListModal;
