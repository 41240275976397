import React from "react";
import { Dropdown, Menu, Tooltip } from "antd";
import { ICON_MORE } from "../../../CdnImagePath";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { useState } from "react";

const ActionComponent = (props) => {
  const { actions, handleActionChange, data, disabled } = props;
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const menu = (
    <Menu>
      <h4 className="dd-title">Actions</h4>
      {actions &&
        actions?.map((i) => {
          return (
            <>
              {!i?.children && (
                <Menu.Item
                  key={i?.name}
                  value={i?.name}
                  onClick={() => handleActionChange(i?.name, data)}
                  className="font-size-13 font-color-darkBlue"
                >
                  {i?.title}
                </Menu.Item>
              )}
              {i?.children && (
                <Menu.SubMenu
                  key="SubMenu"
                  title={<span className="font-size-13 font-color-darkBlue">{i?.children && i.title}</span>}
                >
                  {i?.children.map((k) => {
                    return (
                      k?.title && (
                        <Menu.Item
                          key={k?.name}
                          value={k?.name}
                          onClick={() => {
                            handleActionChange(k?.name, data);
                          }}
                        >
                          {k?.title}
                        </Menu.Item>
                      )
                    );
                  })}
                </Menu.SubMenu>
              )}
            </>
          );
        })}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight" disabled={disabled}>
      <Tooltip trigger="hover" title="More Actions" color={TOOLTIP_BG_COLOR} visible={tooltipVisible}>
        <img
          src={ICON_MORE}
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
          onClick={() => setTooltipVisible(false)}
          alt="Actions"
          className={disabled ? "pl-10 cursor-not-allowed" : "pl-10 cursor-pointer"}
        />
      </Tooltip>
    </Dropdown>
  );
};

export default ActionComponent;
