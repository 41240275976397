import { SYSTEM_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../Constants";
import * as RecTestScenarioTestDataSet from "./RecTestScenarioTestDataSet";
import * as TestBlockTestDataSet from "./TestBlockTestDataSet";
import * as TestDataSetCommonAction from "./TestDataSetCommonAction";
import * as TestPlanTestDataSet from "./TestPlanTestDataSet";
import * as SystemKeyTestDataSet from "./SystemKeyTestDataSet";

const getMethodObject = (view) => {
  switch (view) {
    case TEST_SCENARIOS:
      return RecTestScenarioTestDataSet;
    case TEST_STEP_BLOCKS:
      return TestBlockTestDataSet;
    case TEST_PLAN:
      return TestPlanTestDataSet;
    case SYSTEM_KEYS:
      return SystemKeyTestDataSet;
    default:
      return RecTestScenarioTestDataSet;
  }
};

export function fetchTestDataSetByProfileId(id, search = "", pageNumber = 1, showCount,includeAllData) {
  return (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._fetchTestDataSetByProfileId(id, search, pageNumber, showCount,includeAllData));
  };
}

export function changeTestDataSetView(testDataSetView) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction.changeTestDataSetView(testDataSetView));
  };
}

export function setTestDataSetAssigneeDetail(testDataSetAssigneeDetail) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction.setTestDataSetAssigneeDetail(testDataSetAssigneeDetail));
  };
}

export function cloneTestDataSetByTestPlanId(testDataSetId) {
  return async (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._cloneTestDataSetByTestPlanId(testDataSetId, testDataSetAssigneeDetail));
  };
}

export function changeTestDataSetName(name) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._changeTestDataSetName(name));
  };
}
export function changeTestDatasetLocale(value) {
  return (dispatch) => {
    dispatch(TestDataSetCommonAction._changeTestDatasetLocale(value));
  };
}

export function changeTestDataSetDeviceAssignDataSet(value) {
  return (dispatch) => {
    dispatch(TestDataSetCommonAction._changeTestDataSetDeviceAssignDataSet(value));
  };
}

export function changeTestDataIsCustomReferenceTestData(checked, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._changeTestDataIsCustomReferenceTestData(checked, testDataRow));
  };
}

export function changeTestDataReferenceTestData(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._changeTestDataReferenceTestData(value, testDataRow));
  };
}

export function resetTestDataCustomValue(testDataRow) {
  return (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._resetTestDataCustomValue(testDataRow));
  };
}

export function changeTestDataCustomValue(value, testDataRow) {
  return async (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._changeTestDataCustomValue(value, testDataRow));
  };
}
export function removeTestDataFromTestDataSet(value, testDataId) {
  return async (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(
      getMethodObject(testDataSetView)._cloneTestDataSetByTestPlanId(value, testDataId, testDataSetAssigneeDetail)
    );
  };
}
export function addNewTestDataInTestDataSet(value, testDataId) {
  return async (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(
      getMethodObject(testDataSetView)._cloneTestDataSetByTestPlanId(value, testDataId, testDataSetAssigneeDetail)
    );
  };
}
export function loadTestDataSet(value, testDataId) {
  return async (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(
      getMethodObject(testDataSetView)._cloneTestDataSetByTestPlanId(value, testDataId, testDataSetAssigneeDetail)
    );
  };
}

export function getTestDataSetById(testDataSetId) {
  return async (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._getTestDataSetById(testDataSetId, testDataSetAssigneeDetail));
  };
}

export function saveTestDataSet() {
  return (dispatch, getState) => {
    const { testDataSetView, testDataSetAssigneeDetail } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._saveTestDataSet(testDataSetAssigneeDetail));
  };
}
export function resetTestDataSet() {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._resetTestDataSet());
  };
}
export function makeTestDataSetDefault(checked, testDataSet) {
  return (dispatch, getState) => {
    const { testDataSetView } = getState().TestDataSetReducer;
    dispatch(getMethodObject(testDataSetView)._makeTestDataSetDefault(checked, testDataSet));
  };
}

export function changeTestDataSetTab(selectedTab) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._changeTestDataSetTab(selectedTab));
  };
}

export function getTestStepsData(selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getTestStepsData(selectedStepId));
  };
}

export function getTestStepsDataApplicationString(selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getTestStepsDataApplicationString(selectedStepId));
  };
}

export function getTestStepsDataDeviceString(selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getTestStepsDataDeviceString(selectedStepId));
  };
}

export function testDataSetInfoModal(visible, testDataKeys, selectedKeys, selectedStepsKey, selectedStepsValue) {
  return (dispatch, getState) => {
    dispatch(
      TestDataSetCommonAction._testDataSetInfoModal(
        visible,
        testDataKeys,
        selectedKeys,
        selectedStepsKey,
        selectedStepsValue
      )
    );
  };
}

export function getStepDataInfo(selectRecTestStepId, selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getStepDataInfo(selectRecTestStepId, selectedStepId));
  };
}

export function getStepDataInfoForApplicationString(applicationStringId, selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getStepDataInfoApplicationString(applicationStringId, selectedStepId));
  };
}

export function getStepDataInfoForDeviceString(deviceStringId, selectedStepId) {
  return (dispatch, getState) => {
    dispatch(TestDataSetCommonAction._getStepDataInfoDeviceString(deviceStringId, selectedStepId));
  };
}
