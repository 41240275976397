import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Collapse, Dropdown, Input, Row } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  addVerificationForRepeatStep,
  changeRecTestStepLoopCount,
  changeRecTestStepPreConditionTitle,
  showDeleteLoopConditionModalVisible,
  updateRecTestStepAction,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { hasNotAllowedNegativeValue, leftBorderColor, showUnSavedNotification } from "../../../Util";
import RecTestStepDetail from "../StepInnerComponents/RecTestStepDetail/RecTestStepDetail";
import UnSavedRecTestStepPopConfirm from "./UnSavedRecTestStepPopConfirm";
const { Panel } = Collapse;

const RecTestStepCollapse = styled(Collapse)`
  border: ${(props) => (props.unSavedStep ? "1px solid #ff4d4fab" : props.selected ? "1px solid #1890ff" : "")};
  background-color: ${(props) =>
    props.isTestBlock ? "#ffffff !important" : props.isSkipStep ? "#b7b7b7 !important" : "#ffffff !important"};
  border: 1px solid #d9d9d9 !important;
  .ant-collapse {
    margin: 0px !important;
    background-color: white;
    margin-bottom: 5px !important;
  }

  ,
  .ant-collapse-item {
    margin: 0px !important;
    .ant-collapse-header {
      border: 0px !important;
      border-radius: 0px !important;
    }
  }
`;
const StyledRecTestStepOptionsButton = styled(Dropdown)`
  color: #338fcc;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
const RecTestStepLoopCondition = (props) => {
  const { recTestStep, isDeviceLoaded, projectId, isSkipStep, disabled, isTestBlock, recTestSteps } = props;
  const dispatch = useDispatch();
  const { unSavedRecStepTag, unSavedRecElementGuid, isLoopCountSaved } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  return (
    recTestStep?.recTestStepProperty?.isLoopStep === 1 && (
      <div>
        <Row>
          <Col span={14} className="mt-5">
            <div className="testDataLabel">Count Reach to</div>
          </Col>
        </Row>
        <Col span={4} className="mt-5">
          <div className="display-flex align-items-center">
            <Input
              type="number"
              className="w-85 mb-5"
              value={recTestStep.recTestStepProperty?.loopCount}
              disabled={disabled || isSkipStep}
              min={0}
              onKeyDown={(e) => hasNotAllowedNegativeValue(e)}
              onChange={(e) => !disabled && dispatch(changeRecTestStepLoopCount(e.target.value, recTestStep.tag))}
            />
            <span className="pl-5 display-flex align-items-center labelDevice mb-10">count</span>
          </div>
        </Col>
        {(recTestStep.recTestStepProperty?.addLoopCondition === undefined ||
          recTestStep.recTestStepProperty?.addLoopCondition === false) &&
          recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length === 0 && (
            <>
              <Button
                disabled={
                  recTestStep.recTestStepProperty.isLoopCountSaved === undefined &&
                  recTestStep.recTestStepProperty.previous_loopCount === undefined
                }
                type="primary"
                onClick={() => dispatch(updateRecTestStepAction(recTestStep))}
                className="mt-5">
                Save
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(addVerificationForRepeatStep(recTestStep.tag))}
                className="mt-5 ml-5">
                Add Element Verification
              </Button>
            </>
          )}
        {(recTestStep.recTestStepProperty?.addLoopCondition ||
          recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0) && (
          <>
            <div>And Verification Succeeds</div>
            <UnSavedRecTestStepPopConfirm
              recTestStep={recTestStep}
              unSavedRecStepTag={unSavedRecStepTag}
              isForConditionTitle={true}
              unSavedRecElementGuid={unSavedRecElementGuid}
            />
            <RecTestStepCollapse
              unSavedStep={showUnSavedNotification(recTestStep, unSavedRecStepTag)}
              expandIconPosition="left"
              isSkipStep={isSkipStep}
              isTestBlock={isTestBlock}
              className={leftBorderColor(recTestStep.previewStatus)}>
              <Panel
                showArrow={true}
                header={
                  <Input
                    className="w-95 mt-5 mb-5"
                    addonBefore="Title"
                    collapsible={"false"}
                    type="text"
                    placeholder="Provide a title/description for the verification"
                    value={recTestStep.recTestStepProperty?.preConditionTitle}
                    disabled={disabled || isSkipStep}
                    onChange={(e) => {
                      !disabled && dispatch(changeRecTestStepPreConditionTitle(e.target.value, recTestStep.tag));
                    }}
                  />
                }
                extra={
                  <FontAwesomeIcon
                    icon={faTrash}
                    disabled={recTestStep.recTestStepProperty?.isLoopStep === 1}
                    color={recTestStep.recTestStepProperty?.isLoopStep === 1 ? "red" : ""}
                    onClick={() => dispatch(showDeleteLoopConditionModalVisible(recTestStep.tag, true))}
                  />
                }>
                <RecTestStepDetail
                  isSkipStep={recTestStep.recTestStepProperty?.isSkipStep || props.isSkipStep}
                  data={recTestStep}
                  parentIndex={recTestStep.orderId}
                  isDeviceLoaded={isDeviceLoaded}
                  projectId={projectId}
                  recTestSteps={recTestSteps}
                  disabled={disabled}
                  isTestBlock={isTestBlock}
                />
              </Panel>
            </RecTestStepCollapse>
          </>
        )}
      </div>
    )
  );
};

export default RecTestStepLoopCondition;
