import React from "react";
import { Col, Row, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { TEST_SCENARIOS } from "../../../../Constants";
import { changeRecTestStepElementXpathActive } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import styles from "./RecStepElementXpaths.module.scss";

const RecStepElementXpaths = (props) => {
  const dispatch = useDispatch();
  const { unSavedRecElementGuid, stepType, disableSteps } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { data, recElement, isPreviewRunning, isSkipStep } = props;
  const isTestBlock = stepType === TEST_SCENARIOS && data?.isTestBlock === 1;
  return (
    <>
      {recElement && recElement.recElementLocatorProperties && recElement.recElementLocatorProperties.length > 0 && (
        <Row className={styles.row}>
          <Col span={4}>
            <span className={`${styles.title} ${styles.titleActive}`}>Active</span>
          </Col>
          <Col span={8}>
            <span className={styles.title}>Method</span>
          </Col>
          <Col span={12}>
            <span className={styles.title}>Method Identifier</span>
          </Col>
        </Row>
      )}
      {recElement && recElement.recElementLocatorProperties && recElement.recElementLocatorProperties.length > 0 ? (
        recElement.recElementLocatorProperties.map((v, k) => {
          return (
            <div key={k} className={styles.recElement}>
              <Row>
                <Col className={styles.elementColumn} span={4}>
                  <Checkbox
                    disabled={
                      isPreviewRunning ||
                      isSkipStep ||
                      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                        unSavedRecElementGuid !== recElement.guid) ||
                      isTestBlock ||
                      recElement?.isActive === 0 ||
                      disableSteps
                    }
                    checked={v.isActive === 1}
                    onChange={(e) =>
                      dispatch(
                        changeRecTestStepElementXpathActive(e.target.checked, v.value, data.tag, recElement.guid)
                      )
                    }
                  />
                </Col>
                <Col className={`${styles.elementColumn} ${styles.generatedMethodCol}`} span={8}>
                  {v.generatedMethod}
                </Col>
                <Col className={styles.xpathCol} span={12}>
                  <span>{v.name} :</span>
                  {v.value}
                  {v.totalChildCount > 0 ? (
                    <span>{" Current Position=" + v.currentChildIndex + ", Total elements=" + v.totalChildCount}</span>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          );
        })
      ) : (
        <EmptyRecords description="No Element Selection" />
      )}
    </>
  );
};

export default RecStepElementXpaths;
