import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Collapse, Row } from "antd";
import { isEmpty } from "lodash";
import {
  BLUE_CLOSE_COLLAPSE_ICON,
  BLUE_OPEN_COLLAPSE_ICON,
  DRAG_AND_DROP_ICON,
} from "../../../../../Constants/SvgConstants";
import { isArrayNotUndefinedNotNullNotEmpty } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { getScenariosAndTestStepsAndTestDataById } from "../../../../../actions/RecTestScenarioAction";
import ShowTooltipV2 from "../../../../CommonComponents/ShowTooltipV2/ShowTooltipV2";
import styles from "./BuildTestPlanV2.module.scss";

const { Panel } = Collapse;

const TestScenarioDataV2 = ({ scenarioData }) => {
  const dispatch = useDispatch();
  const { testScenarioAndStepsAndDataLists } = useSelector((state) => state.RecTestScenarioReducer);

  const handleTestData = (data) => {
    return (
      <>
        <Row className={styles["test-data-row "]}>
          <Col className={styles["test-data-col"]} span={8}>
            Name
          </Col>
          <Col className={styles["test-data-col"]} span={8}>
            Value
          </Col>
          <Col className={styles["test-data-col"]} span={8}>
            Custom value
          </Col>
        </Row>

        <Row>
          <Col className={styles["test-data-col"]} span={8}>
            {data.name}
          </Col>
          <Col className={styles["test-data-col"]} span={8}>
            {data.value}
          </Col>
          <Col className={styles["test-data-col"]} span={8}>
            {data.customValue}
          </Col>
        </Row>
      </>
    );
  };

  const handleScenarioChild = (data) => {
    return (
      isArrayNotUndefinedNotNullNotEmpty(data) &&
      data.map((v, k) => {
        return (
          <Collapse
            defaultActiveKey={[1]}
            key={k}
            className="test_Data_Collapse test_Plan_Collapse"
            expandIconPosition="start"
            expandIcon={({ isActive }) => (
              <SvgLoader iconName={isActive ? BLUE_OPEN_COLLAPSE_ICON : BLUE_CLOSE_COLLAPSE_ICON} />
            )}
          >
            <Panel header={v.name}>
              {!isEmpty(v.testData) && handleTestData(v.testData)}
              {isArrayNotUndefinedNotNullNotEmpty(v.recTestSteps) && handleScenarioChild(v.recTestSteps)}
            </Panel>
          </Collapse>
        );
      })
    );
  };

  return (
    <Collapse
      className="test_Data_Collapse test_Plan_Collapse"
      expandIconPosition="start"
      expandIcon={({ isActive }) => (
        <SvgLoader iconName={isActive ? BLUE_OPEN_COLLAPSE_ICON : BLUE_CLOSE_COLLAPSE_ICON} />
      )}
      key={scenarioData}
      accordion
      onChange={() => {
        dispatch(getScenariosAndTestStepsAndTestDataById(scenarioData.id));
      }}
    >
      <Panel
        showArrow={true}
        header={
          <div className={styles["test-data-panel"]}>
            <ShowTooltipV2 text={scenarioData?.name} className={styles["scenario_Name"]} />
            <SvgLoader iconName={DRAG_AND_DROP_ICON} cursorGrab="grab" />
          </div>
        }
        key={scenarioData}
      >
        {isArrayNotUndefinedNotNullNotEmpty(testScenarioAndStepsAndDataLists[scenarioData.id]) ? (
          testScenarioAndStepsAndDataLists[scenarioData.id].map((v, k) => {
            return (
              <Collapse
                defaultActiveKey={[1]}
                key={k}
                className="test_Data_Collapse"
                expandIconPosition="start"
                expandIcon={({ isActive }) => (
                  <SvgLoader iconName={isActive ? BLUE_OPEN_COLLAPSE_ICON : BLUE_CLOSE_COLLAPSE_ICON} />
                )}
              >
                <Panel header={v.name}>
                  {isArrayNotUndefinedNotNullNotEmpty(v.recTestSteps) && handleScenarioChild(v.recTestSteps)}
                </Panel>
              </Collapse>
            );
          })
        ) : (
          <span className={styles["data_Not_Found_Text"]}>Data not found</span>
        )}
      </Panel>
    </Collapse>
  );
};

export default TestScenarioDataV2;
