import {
  CHANGE_NEW_TEST_DATA_NAME,
  CHANGE_NEW_TEST_DATA_SCOPE,
  CHANGE_NEW_TEST_DATA_TYPE,
  CHANGE_NEW_TEST_DATA_VALUE,
  CHANGE_TEST_DATA_LIST_FILTER_VALUE,
  CHANGE_TEST_DATA_LIST_SEARCH_VALUE,
  DELETE_TEST_PLAN,
  HIDE_ADD_TEST_DATA_FORM,
  LOAD_VALUE_ADD_TEST_DATA_FORM,
  RESET_ADD_TEST_DATA_FORM,
  SAVE_TEST_DATA_FORM_REQUEST,
  SAVE_TEST_DATA_FORM_REQUESTED,
  SHOW_ADD_TEST_DATA_FORM,
  VISIBLE_TEST_DATA_DRAWER,
  TEST_DATA_LIST_FOR_SCENARIO,
  TEST_DATA_LIST_REQUEST,
  TEST_DATA_LIST_REQUESTED,
  TEST_DATA_SCOPE,
  TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA,
  TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING,
  SHOW_ADD_PROJECT_KEYS_FORM,
  LOAD_EDIT_PROJECT_STRING_DETAILS,
  HIDE_ADD_PROJECT_KEYS_FORM,
  CHANGE_NEW_PROJECT_KEY_VALUE,
  SAVE_PROJECT_KEYS_FORM_REQUEST,
  SAVE_PROJECT_KEYS_FORM_REQUESTED,
  CHANGE_NEW_PROJECT_KEY_NAME,
} from "../Constants";

let defaultState = {
  showTestDataVisibility: {},
  fetchingTestDataList: false,
  addTestDataFormVisible: false,
  newTestDataId: undefined,
  newTestDataName: undefined,
  newTestDataValue: undefined,
  newTestDataScope: undefined,
  requestSaveTestDataForm: false,
  deleteTestData: undefined,
  searchFetchingTestDataList: "",
  totalCounts: 0,
  currentPage: 1,
  totalPageCount: 0,
  pageItems: "",
  filterFetchingTestDataList: TEST_DATA_SCOPE.ALL,
  testDataListForScenario: undefined,
  showTestDataDrawer: false,
  openTestDataSetDrawer: undefined,
  testStepListAssociatedWithTestData: undefined,
  testStepListAssociatedWithTestDataLoading: false,
  prevTestData: {},
  newTestData: {},
};

export default function TestDataReducer(state = defaultState, action) {
  switch (action.type) {
    // case TEST_DATA_LIST:
    //   if (action.response) {
    //     state.testDataList = action.response.data.data;
    //     state.totalCounts = action.response.data.totalItems;
    //     state.currentPage = action.response.data.currentPage;
    //     state.totalPageCount = action.response.data.totalCount;
    //     state.pageItems = action.response.data.pageItems;
    //   }
    //   return {
    //     ...state,
    //   };
    case TEST_DATA_LIST_FOR_SCENARIO:
      return {
        ...state,
        testDataListForScenario: action.response.data,
      };
    case TEST_DATA_LIST_REQUEST:
      return {
        ...state,
        fetchingTestDataList: true,
      };
    case TEST_DATA_LIST_REQUESTED:
      return {
        ...state,
        fetchingTestDataList: false,
      };
    case CHANGE_TEST_DATA_LIST_SEARCH_VALUE:
      return {
        ...state,
        searchFetchingTestDataList: action.value,
      };
    case CHANGE_TEST_DATA_LIST_FILTER_VALUE:
      return {
        ...state,
        filterFetchingTestDataList: action.value,
      };
    case SHOW_ADD_TEST_DATA_FORM:
      return {
        ...state,
        addTestDataFormVisible: true,
      };
    case HIDE_ADD_TEST_DATA_FORM:
      return {
        ...state,
        addTestDataFormVisible: false,
        newTestDataId: undefined,
        newTestDataName: undefined,
        newTestDataValue: undefined,
        newTestDataScope: undefined,
        prevTestData: {},
        newTestData: {},
      };

    case SHOW_ADD_PROJECT_KEYS_FORM:
      return {
        ...state,
        addProjectKeyForm: true,
      };

    case HIDE_ADD_PROJECT_KEYS_FORM:
      return {
        ...state,
        addProjectKeyForm: false,
        newProjectKeyId: undefined,
        newProjectKeyName: undefined,
        newProjectKeyValue: undefined,
        newProjectKeyScope: undefined,
        prevProjectKey: {},
        newProjectKey: {},
      };

    case LOAD_EDIT_PROJECT_STRING_DETAILS:
      let projectList = action.testDataList.filter((item) => item.id === action.testDataId)[0];
      return {
        ...state,
        addProjectKeyForm: true,
        newProjectKeyId: projectList?.id,
        newProjectKeyValue: projectList?.value,
        newProjectKeyName: projectList?.name,
        newProjectKeysScope: projectList.scope,
        prevProjectKey: projectList,
        newProjectKey: projectList,
        previous_newProjectKeyValue: projectList?.value,
      };

    case CHANGE_NEW_PROJECT_KEY_VALUE:
      return {
        ...state,
        newProjectKeyValue: action.value,
        newProjectKeys: { ...state.newProjectKeys, value: action.value },
      };

    case CHANGE_NEW_PROJECT_KEY_NAME:
      return {
        ...state,
        newProjectKeyName: action.name,
        newProjectKeys: { ...state.newTestData, name: action.name },
      };
    case CHANGE_NEW_TEST_DATA_NAME:
      return {
        ...state,
        newTestDataName: action.name,
        newTestData: { ...state.newTestData, name: action.name },
      };
    case CHANGE_NEW_TEST_DATA_VALUE:
      return {
        ...state,
        newTestDataValue: action.value,
        newTestData: { ...state.newTestData, value: action.value },
      };
    case CHANGE_NEW_TEST_DATA_SCOPE:
      return {
        ...state,
        newTestDataScope: action.scope,
        newTestData: { ...state.newTestData, scope: action.scope },
      };
    case LOAD_VALUE_ADD_TEST_DATA_FORM:
      let testData = action.testDataList.filter((item) => item.id === action.testDataId)[0];
      return {
        ...state,

        addTestDataFormVisible: true,
        newTestDataId: testData.id,
        newTestDataValue: testData.value,
        newTestDataName: testData.name,
        newTestDataScope: testData.scope,
        prevTestData: testData,
        newTestData: testData,
        previous_newTestDataValue: testData?.value,

      };

    case SAVE_TEST_DATA_FORM_REQUEST:
      return {
        ...state,
        requestSaveTestDataForm: true,
      };
    case SAVE_TEST_DATA_FORM_REQUESTED:
      return {
        ...state,
        requestSaveTestDataForm: false,
        newTestDataId: undefined,
        newTestDataName: undefined,
        newTestDataValue: undefined,
        newTestDataScope: undefined,
        addTestDataFormVisible: false,
        prevTestData: {},
        newTestData: {},
      };

    case SAVE_PROJECT_KEYS_FORM_REQUEST:
      return {
        ...state,
        requestSaveProjectKeyForm: true,
      };
    case SAVE_PROJECT_KEYS_FORM_REQUESTED:
      return {
        ...state,
        requestSaveProjectKeyForm: false,
        newProjectKeyId: undefined,
        newProjectKeyName: undefined,
        newProjectKeyValue: undefined,
        newProjectKeyScope: undefined,
        addProjectKeyForm: false,
        prevProjectKey: {},
        newProjectKey: {},
      };
    case RESET_ADD_TEST_DATA_FORM:
      return {
        ...state,
        newTestDataName: undefined,
        newTestDataValue: undefined,
        newTestDataScope: undefined,
        prevTestData: {},
        newTestData: {}
      };
    case DELETE_TEST_PLAN:
      return {
        ...state,
        deleteTestData: action.response,
      };

    case VISIBLE_TEST_DATA_DRAWER:
      return {
        ...state,
        showTestDataDrawer: !!action.openTestDataSetDrawerByTabName,
        openTestDataSetDrawerByTabName: action.openTestDataSetDrawerByTabName,
        tagForOpenDrawer: action?.tagForOpenDrawer ? action?.tagForOpenDrawer : undefined,
        guidForOpenDrawer: action?.guidForOpenDrawer ? action?.guidForOpenDrawer : undefined,
        verificationDataForTestDataDrawer: action?.verificationDataForTestDataDrawer
          ? action?.verificationDataForTestDataDrawer
          : undefined,
      };
    case TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA:
      return {
        ...state,
        testStepListAssociatedWithTestData: action.response,
      };
    case TEST_STEPS_LIST_ASSOCIATED_WITH_TEST_DATA_LOADING:
      return {
        ...state,
        testStepListAssociatedWithTestDataLoading: action.flag
      }
    default:
      return state;
  }
}
