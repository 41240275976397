import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { TEST_SCENARIOS } from "../../../Constants";
import { dragHoverOnStepOrAction } from "../../../Util";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import RecTestStepV2 from "./RecTestStepV2/RecTestStepV2";

const RecTestStepListV2 = ({ disableAction, isDeviceLoaded, projectId, recTestSteps, parentStep, stepType }) => {
  const { fetchingRecTestStepList, testBlockStep, recTestStepList } = useSelector((state) => state.RecTestStepReducer);
  const [isDragOver, setIsDragOver] = useState(false);
  const dispatch = useDispatch();

  const stepList = () => {
    if (isEmpty(recTestSteps)) {
      if (
        !fetchingRecTestStepList &&
        (stepType === TEST_SCENARIOS
          ? !recTestSteps?.length
          : !testBlockStep?.recTestStepProperty?.isStepHasPrecondition &&
            !testBlockStep?.recTestStepProperty?.isLoopStep)
      ) {
        return (
            <EmptyRecords
              description={
                stepType === TEST_SCENARIOS
                  ? "Please add Steps to build your Test Scenario."
                  : "Please add Steps to build your Test Block."
              }
              emptyRecTestSteps
            />
        );
      }
    } else {
      return (
        <>
          {recTestSteps?.map((recTestStep, index) => {
            const isTestBlock = stepType === TEST_SCENARIOS ? recTestStep?.isTestBlock === 1 : false;
            const isSkipStep = recTestStep?.isSkipStep === 1;
            return (
              <RecTestStepV2
                recTestStep={recTestStep}
                key={index}
                index={index}
                disableAction={disableAction}
                isDeviceLoaded={isDeviceLoaded}
                projectId={projectId}
                recTestStepList={recTestStepList}
                stepType={stepType}
                parentSkipStep={isSkipStep}
                parentRecTestStep={parentStep}
                stepId={"S" + recTestStep?.orderId}
                isTestBlock={isTestBlock}
              />
            );
          })}
          {dragHoverOnStepOrAction(
            isDragOver,
            setIsDragOver,
            dispatch,
            false,
            recTestSteps[recTestSteps.length - 1],
            parentStep,
            recTestSteps.length
          )}
        </>
      );
    }
  };

  return stepList();
};

export default RecTestStepListV2;
