
import { Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEP_ITEMS } from "../../../../../Constants";
import { addConditionToStep } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepOperation from "../RecTestStepOperation/RecTestStepOperation";
import { CONDITIONAL_STEP } from "../../../../../Constants/TooltipConstants";
import styles from "./ActionsComponents.module.scss";
import { ICON_CONDITIONAL_STEP } from "../../../../../Constants/SvgConstants";

export const addConditionalStepMenu = (selectedRecStepForOperation, dispatch) => {
  const isConditionalStepMenu =
    selectedRecStepForOperation?.recTestStepProperty?.isStepHasPrecondition === 0 &&
    selectedRecStepForOperation?.recTestStepProperty?.isLoopStep === 0;

  if (!isConditionalStepMenu) {
    return null;
  }

  return (
    <Menu className={styles.actionsDropdown}>
      <Menu.Item
        key="SubMenu-1"
        onClick={() =>
          dispatch(addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT))
        }
      >
        <span className={styles.menuItemsTitle}> When to Run this Step By Verifying Element</span>
      </Menu.Item>
      <Menu.Item
        key="SubMenu-2"
        onClick={() =>
          dispatch(addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA))
        }
      >
        <span className={styles.menuItemsTitle}> When to Run this Step By Verifying Test Data</span>
      </Menu.Item>
      <Menu.Item
        key="SubMenu-3"
        onClick={() => dispatch(addConditionToStep(selectedRecStepForOperation.tag, STEP_ITEMS.REPEAT_TILL))}
      >
        <span className={styles.menuItemsTitle}> Repeat Steps Till</span>
      </Menu.Item>
    </Menu>
  );
};

const ConditionalRecTestStep = (props) => {
  const { disabled, conditionMenuVisible, setConditionMenuVisible } = props;
  const dispatch = useDispatch();
  const { selectedRecStepForOperation } = useSelector((state) => state.RecTestStepReducer);

  return (
    <div className={`${styles.subHeader} ${styles.actionIconInHeader}`}>
      <RecTestStepOperation
        name="Condition"
        handleClick={() => addConditionalStepMenu(selectedRecStepForOperation, dispatch)}
        disabled={disabled}
        tooltipTitle={CONDITIONAL_STEP}
        imageName={ICON_CONDITIONAL_STEP}
        isMenuItems
        menuVisible={conditionMenuVisible}
        setMenuVisible={setConditionMenuVisible}
      />
    </div>
  );
};

export default ConditionalRecTestStep;
