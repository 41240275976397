import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import { CALENDER_ICON } from "../../../CdnImagePath";
import { getCustomDateFilter } from "../../../Util";
import { FilterButton } from "../StyledComponent/StyledComponents";

const CustomDatePicker = ({ filterList, handleChangeCustomDateFilter }) => {
  const [selectedItem, setSelectedItem] = useState("All");

  const handleChange = (selectedFilter, value) => {
    const filter = getCustomDateFilter(selectedFilter);
    handleChangeCustomDateFilter(filter);
    setSelectedItem(value);
  };

  const menu = (
    <Menu>
      <Menu.Item className="submenu-title">
        <span className="ml-15">Select Date Range</span>
      </Menu.Item>
      {filterList?.map((s, k) => {
        return (
          <Menu.Item key={s.name} value={s.name} onClick={() => handleChange(s.name, s.title)}>
            {s.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <span>
      <Dropdown overlay={menu}>
        <FilterButton>
          <span className="capitalize"> {selectedItem !== "All" ? selectedItem : "Select Date Range"} </span>
          <img src={CALENDER_ICON} alt="Calender" />
        </FilterButton>
      </Dropdown>
    </span>
  );
};

export default CustomDatePicker;
