import { combineReducers } from "redux";
import HomeReducer from "./../reducer/HomeReducer";
import ServerListReducer from "./../reducer/ServerListReducer";
import RecordingReducer from "./../reducer/RecordingReducer";
import CommonReducer from "./../reducer/CommonReducer";
import AdminReducer from "./../reducer/AdminReducer";
import TestPlanReducer from "./../reducer/TestPlanReducer";
import RecTestScenarioReducer from "./../reducer/RecTestScenarioReducer";
import RecTestStepReducer from "./../reducer/RecTestStepReducer";
import AwsDeviceFarmReducer from "./../reducer/AwsDeviceFarmReducer";
import TeamsReducer from "./../reducer/TeamsReducer";
import ProjectsReducer from "./../reducer/ProjectsReducer";
import TestDataReducer from "./../reducer/TestDataReducer";
import TestDataSetReducer from "./../reducer/TestDataSetReducer";
import OrganizationReducer from "./../reducer/OrganizationReducer";
import InvitationReducer from "../reducer/InvitationReducer";
import OrgDeviceReducer from "../reducer/OrgDeviceReducer";
import ManageFarmReducer from "../reducer/ManageFarmReducer";
import BuildTestPlanReducer from "../reducer/BuildTestPlanReducer";
import TestJobReducer from "../reducer/TestJobReducer";
import ProjectSettingsReducer from "../reducer/ProjectSettingsReducer";
import ServerReducer from "../reducer/ServerReducer";
import ErrorInvestigationReducer from "../reducer/ErrorInvestigationReducer";
import ExecutionReportReducer from "../reducer/ExecutionReportReducer";
import TestBlockReducer from "../reducer/TestBlockReducer";
import RecTestStepMultiColumnViewReducer from "../reducer/RecTestStepMultiColumnViewReducer";
import RecTestStepCommonViewReducer from "../reducer/RecTestStepCommonViewReducer";
import ApplicationStringReducer from "../reducer/ApplicationStringReducer";

const rootReducer = combineReducers({
  HomeReducer,
  ServerListReducer,
  RecordingReducer,
  CommonReducer,
  AdminReducer,
  TestPlanReducer,
  RecTestScenarioReducer,
  RecTestStepReducer,
  AwsDeviceFarmReducer,
  TeamsReducer,
  ProjectsReducer,
  TestDataReducer,
  TestDataSetReducer,
  OrganizationReducer,
  InvitationReducer,
  OrgDeviceReducer,
  ManageFarmReducer,
  BuildTestPlanReducer,
  TestJobReducer,
  ProjectSettingsReducer,
  ServerReducer,
  ErrorInvestigationReducer,
  ExecutionReportReducer,
  TestBlockReducer,
  RecTestStepMultiColumnViewReducer,
  RecTestStepCommonViewReducer,
  ApplicationStringReducer,
});

export default rootReducer;
