import { Button, Col, Row } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getWorkspace, joinWorkspace } from '../../actions/WorkspaceAction';

const JoinWorkspace = () => {
    const dispatch = useDispatch();
    const orgName= localStorage.getItem("orgName");

    useEffect(() => {
        dispatch(getWorkspace());
    }, [])

    const handleSaveButton = () => {
    };
    return (
        <React.Fragment>
            <div className={"limiter"}>
                <div className={"container-login100"}>
                    <Row className={"wrap-login100"}>
                        <Col className={"login100-form validate-form"}>
                            <div>
                                <h1>Join Your Workspace!</h1>
                            </div>
                            <div className="display-flex justify-space-between">
                                <h3 className="align-self-flex-end max-width-50 word-break ">{orgName}</h3>
                                <div className={"container-login100-form-btn"}>
                                    <div className={"wrap-login100-form-btn"}>
                                        <Button
                                            type="primary"
                                            className="pl-50 pr-50"
                                            onClick={() => handleSaveButton()}
                                        >
                                            Join
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default JoinWorkspace
