import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PLAY_TYPE } from "../../../../../Constants";
import {
  ICON_PLAY_FOR_SUB_HEADER,
  ICON_PLAY_FROM_HERE,
  ICON_PLAY_TILL_HERE,
  ICON_STOP,
} from "../../../../../Constants/SvgConstants";
import { PLAY_FROM_HERE, PLAY_THIS_STEP, PLAY_TILL_HERE, STOP } from "../../../../../Constants/TooltipConstants";
import { selectRecTestStepForPlay, startPreview, stopPreview } from "../../../../../actions/RecTestStepAction";
import RecTestStepOperationV2 from "./RecTestStepOperationV2";
import styles from "./StepActionsComponents.module.scss";

const RecTestStepPlayActionsV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, projectId, recTestStepOnHover, recTestStep } = props;

  const { isPreviewRunning, requestingToStopPreview, requestingForPreview, selectedRecTestStepForPlay } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const { unSavedRecTestStep, unSavedRecElementGuid, unSavedRecTestStepTag, isLoadingForAIStep } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const isDisableAll = useMemo(() => {
    return (
      unSavedRecTestStepTag !== undefined ||
      unSavedRecElementGuid !== undefined ||
      disabled ||
      isPreviewRunning ||
      !startDeviceStreamingStatus ||
      unSavedRecTestStep !== undefined ||
      recTestStep?.isSkipStep === 1
    );
  }, [
    unSavedRecTestStepTag,
    unSavedRecElementGuid,
    disabled,
    isPreviewRunning,
    startDeviceStreamingStatus,
    unSavedRecTestStep,
    recTestStep,
  ]);

  const stopPlayThisStep = useMemo(() => {
    return isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP);
  }, [isPreviewRunning, requestingForPreview]);

  const stopPlayFromHere = useMemo(() => {
    return isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE);
  }, [isPreviewRunning, requestingForPreview]);

  const stopPlayTillHere = useMemo(() => {
    return isPreviewRunning && requestingForPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE);
  }, [isPreviewRunning, requestingForPreview]);

  const handleClick = useCallback(
    (type) => {
      if (!isPreviewRunning) {
        dispatch(
          startPreview(
            projectId,
            type,
            recTestStep?.tag,
            recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep?.id
          )
        );
        dispatch(selectRecTestStepForPlay(recTestStep));
      }
    },
    [isPreviewRunning, dispatch, projectId, recTestStep]
  );

  const recTestStepPlaySteps = [
    {
      name: stopPlayThisStep ? "Stop" : "Play",
      spinning:
        isLoadingForAIStep === null
          ? stopPlayThisStep
            ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP)
            : requestingForPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP) &&
              !isPreviewRunning &&
              recTestStep?.tag === selectedRecTestStepForPlay?.tag
          : "",
      stopButton: stopPlayThisStep,
      handleClick: () =>
        isLoadingForAIStep === null
          ? stopPlayThisStep
            ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_THIS_STEP)
              ? dispatch(stopPreview())
              : undefined
            : !isDisableAll && handleClick(PLAY_TYPE.PLAY_THIS_STEP)
          : "",
      disabled: (!stopPlayThisStep && isDisableAll) || isLoadingForAIStep !== null,
      tooltipTitle: stopPlayThisStep ? STOP : PLAY_THIS_STEP,
      imageName: stopPlayThisStep ? ICON_STOP : ICON_PLAY_FOR_SUB_HEADER,
      iconColor: recTestStepOnHover ? "#00C361" : "#3F5065",
    },
    {
      name: stopPlayFromHere ? "Stop" : "Play from here",
      spinning:
        isLoadingForAIStep === null
          ? stopPlayFromHere
            ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE)
            : requestingForPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE) &&
              !isPreviewRunning &&
              recTestStep?.tag === selectedRecTestStepForPlay?.tag
          : "",
      stopButton: stopPlayFromHere,
      handleClick: () =>
        isLoadingForAIStep === null
          ? stopPlayFromHere
            ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_FROM_HERE)
              ? dispatch(stopPreview())
              : undefined
            : !isDisableAll && handleClick(PLAY_TYPE.PLAY_FROM_HERE)
          : "",
      disabled: (!stopPlayFromHere && isDisableAll) || isLoadingForAIStep !== null,
      tooltipTitle: stopPlayFromHere ? STOP : PLAY_FROM_HERE,
      imageName: stopPlayFromHere ? ICON_STOP : ICON_PLAY_FROM_HERE,
    },
    {
      name: stopPlayTillHere ? "Stop" : "Play till here",
      spinning:
        isLoadingForAIStep === null
          ? stopPlayTillHere
            ? requestingToStopPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE)
            : requestingForPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE) &&
              !isPreviewRunning &&
              recTestStep?.tag === selectedRecTestStepForPlay?.tag
          : "",
      stopButton: stopPlayTillHere,
      handleClick: () =>
        isLoadingForAIStep === null
          ? stopPlayTillHere
            ? !requestingToStopPreview?.includes(PLAY_TYPE.PLAY_TILL_HERE)
              ? dispatch(stopPreview())
              : undefined
            : !isDisableAll && handleClick(PLAY_TYPE.PLAY_TILL_HERE)
          : "",
      disabled: (!stopPlayTillHere && isDisableAll) || isLoadingForAIStep !== null,
      tooltipTitle: stopPlayTillHere ? STOP : PLAY_TILL_HERE,
      imageName: stopPlayTillHere ? ICON_STOP : ICON_PLAY_TILL_HERE,
    },
  ];

  return (
    <div className={`${styles["subHeader"]} ${!recTestStepOnHover && styles["actionIconInHeader"]} subHeader`}>
      {recTestStepPlaySteps?.map((playStep, index) => (
        <RecTestStepOperationV2 key={index} {...playStep} hideTooltipTitle recTestStepOnHover={recTestStepOnHover} />
      ))}
    </div>
  );
};

export default RecTestStepPlayActionsV2;
