import _get from "lodash/get";

export const isValidUrl = (value) => {
  return /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i.test(value);
};

const cu = (path) => {
  const REACT_APP_PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
  const originUrl = _get(window, "location.origin");
  let showPubUrl;
  // isValidUrl is breaking in storybook so we are using this syntax
  if (isValidUrl) {
    showPubUrl = isValidUrl(REACT_APP_PUBLIC_URL);
  } else {
    showPubUrl = !originUrl;
  }
  return (showPubUrl ? REACT_APP_PUBLIC_URL : originUrl) + "/images/" + path;
};

export const DELETE_ICON = cu("common/deleteIcon.svg");
export const EDIT_ICON = cu("common/editIcon.svg");
export const COPY_ICON = cu("common/copyIcon.svg");
// export const MORE_ICON = cu("common/moreIcon.svg");
export const PLAY_ICON = cu("common/playIcon.svg");
export const FILTER_ICON = cu("common/filterIcon.svg");
export const DOWNLOAD_ICON = cu("common/downloadIcon.svg");
export const SEARCH_ICON = cu("common/searchIcon.svg");
export const ARROW_LEFT = cu("common/arrowLeft.svg");
export const ARROW_RIGHT = cu("common/arrowRight.svg");
// export const MENU_ICON = cu("common/menuIcon.svg");
export const LINK_ICON = cu("common/linkIcon.svg");
export const PROJECTS_ICON = cu("common/projectsIcon.svg");
export const TEAM_ICON = cu("common/teamIcon.svg");
export const DEVICE_ICON = cu("common/deviceIcon.svg");
export const ARROW_DOWN = cu("common/arrowdown.svg");
export const USER_ICON = cu("common/userIcon.svg");
export const PROFILE_ICON = cu("common/profile.svg");
export const MENU_BUTTON = cu("common/menuButton.svg");
export const UPLOAD_ICON = cu("common/uploadIcon.svg");
export const DELETE_ICON_Nobg = cu("common/deleteOnly.svg");
export const TICK_ICON = cu("common/tickIcon.svg");
export const CLOSE_ICON = cu("common/closeIcon.svg");
export const CLOSE_ICON_GRAY = cu("common/closeIconGray.svg");
export const CLOSE_ICON_BLUE = cu("common/closeIconBlue.svg");
export const LINE_ICON = cu("common/lineIcon.svg");
export const WARNING_ICON = cu("common/warningIcon.svg");
export const RIGHT_ARROW_ICON = cu("common/rightArrow.svg");
export const DOWN_ARROW_ICON = cu("common/downArrow.svg");
export const ANDROID_ICON = cu("common/androidIcon.svg");
// export const HELP_LOGO = cu("common/helpLogo.svg");
export const INFO_ICON = cu("common/infoIcon.svg");
export const ERROR_ICON = cu("common/errorIcon.svg");
export const SUCCESS_ICON = cu("common/successIcon.svg");
export const WARNING = cu("common/warning.svg");
export const CALENDER_ICON = cu("common/calenderIcon.svg");
export const ANDROID_LOGO = cu("common/androidLogo.svg");
export const IOS_LOGO = cu("common/IOSLogo.svg");
export const DRAG_ICON = cu("common/drag.svg");
export const BACK_BUTTON = cu("common/btn-back.svg");
// export const DEVICE_SERVER_ICON = cu("common/server-svgrepo-com.svg");
export const EXECUTION_REPORT_ICON = cu("common/executionReportIcon.svg");
export const REPORT_ICON = cu("common/reportIcon.svg");
export const DEVICE_SERVER_MANAGEMENT_ICON = cu("common/deviceServerManagementIcon.svg");
export const EYE_ICON = cu("common/eyeIcon.svg");
export const BACK_TO_TOP_ICON = cu("common/backToTopIcon.svg");
// export const SCHEDULE_ICON = cu("common/scheduleIcon.svg");
export const WEB_ICON = cu("common/webIcon.svg");
export const PREVIEW_RUN_ICON = cu("common/previewRunIcon.svg");
export const SETTING_ICON = cu("common/settingIcon.svg");
export const DISABLE_PREVIEW_RUN_ICON = cu("common/disablePreviewRunIcon.svg");
// export const SCHEDULE_JOB_ICON = cu("common/scheduleJobIcon.svg");
export const LOCK_ICON = cu("common/lockIcon.svg");
export const PLUS_ANIMATION_ICON = cu("common/dragAnimation.svg");
// export const BUILD_DATA_ICON = cu("common/buildDataSetIcon.svg");
// export const TRENDS_ICON = cu("common/trendsIcon.svg");
export const PLUS_ICON = cu("common/plusIcon.svg");
export const LOGOUT_ICON = cu("common/logoutIcon.svg");
export const PROJECT_ICON = cu("common/projectIcon.svg");
export const PROJECTCHECK_ICON = cu("common/projectCheckIcon.svg");
// export const DISABLE_CHECK_ICON = cu("common/disableCheckIcon.svg");
export const SHARE_ICON = cu("common/share.svg");
export const INVITE_USER_ICON = cu("common/inviteUser.svg");
export const SEND_ICON = cu("common/sendIcon.svg");
export const NO_PROJECT = cu("common/noProjects.svg");
export const NO_TEAMS = cu("common/noTeams.svg");
export const ORG_CHECK_ICON = cu("common/orgCheckIcon.svg");
export const LOGO_ICON = cu("common/logo.svg");
export const EMAIL_ICON = cu("common/emailIcon.svg");
export const STEP1 = cu("common/step1.svg");
export const STEP2 = cu("common/step2.svg");
export const STEP3 = cu("common/step3.svg");
export const PENDING_ICON = cu("common/pendingIcon.svg");
export const CURRENT_JOB = cu("common/currentJob.svg");
export const INVALID_ICON = cu("common/inValidIcon.svg");
export const BUILD_TEST_DATA_SET_ICON = cu("common/buildDataSetHeaderIcon.svg");
export const BUILD_TEST_DATA_SET_DISABLED_ICON = cu("common/buildDataSetHeaderDisabledIcon.svg");
export const XPRESS_LOGO = cu("common/XpressLogo.svg");
// export const PROJECT_ARROW_DOWN = cu("common/dArrow.svg");
export const CLOSE_ICON_HEADER = cu("common/closeIconHeader.svg");
// export const STEP_CLOSE_ICON = cu("common/stepCloseIcon.svg");
export const ADD_STEP = cu("common/addStep.svg");
export const ICON_EDIT = cu("common/iconEdit.svg");
export const ICON_BUILD_TEST_DATA = cu("common/iconBuildTestDataSet.svg");
export const ICON_MORE = cu("common/iconMore.svg");
export const ICON_SCHEDULE = cu("common/iconSchedule.svg");
export const ICON_SCHEDULE_JOB = cu("common/iconScheduleJob.svg");
export const ICON_FILTER = cu("common/iconFilter.svg");
export const ICON_DRAG_AND_DROP = cu("common/iconDragDrop.svg");
export const ICON_DELETE = cu("common/iconDelete.svg");
// export const ICON_COPY = cu("common/iconCopy.svg");
export const ICON_REPORT = cu("common/iconReport.svg");
export const ICON_TRENDS = cu("common/iconTrends.svg");
export const ICON_PLAY = cu("common/playIcon.svg");
export const ICON_DOWNLOAD = cu("common/iconDownload.svg");
export const ADD_ACTION_ICON = cu("common/addActionIcon.svg");
export const LAUNCH_APP_ICON = cu("iconForMultiColumnView/launchAppIcon.svg");
export const ACTION_ICON = cu("iconForMultiColumnView/actionIcon.svg");
export const HOME_ICON = cu("iconForMultiColumnView/pressHomeIcon.svg");
export const ENTER_ICON = cu("iconForMultiColumnView/pressEnterIcon.svg");
export const BACK_ICON = cu("iconForMultiColumnView/pressBackIcon.svg");
export const VERIFICATION_ICON = cu("iconForMultiColumnView/verificationIcon.svg");
export const SWIPE_ICON = cu("iconForMultiColumnView/swipeIcon.svg");
export const PAUSE_ICON = cu("iconForMultiColumnView/pauseIcon.svg");
export const SCREENSHOT_ICON = cu("iconForMultiColumnView/screenShotIcon.svg");
// export const ADD_ACTION_ARROW_ICON = cu("iconForMultiColumnView/addActionArrowIcon.svg");
// export const ADD_ACTION_TEXT_ICON = cu("iconForMultiColumnView/addActionTextIcon.svg");
// export const UP_ARROW = cu("iconForMultiColumnView/upArrow.svg");
// export const CLOSE_ICON_SM = cu("iconForMultiColumnView/closeActionSM.svg");
// export const ADD_QUICK_ACTION_ICON = cu("iconForMultiColumnView/addQuickActionIcon.svg");
// export const MORE_ADD_STEPS_ICON = cu("iconForMultiColumnView/moreAddStepsIcon.svg");
// export const BACK_BUTTON_IMG = cu("iconForMultiColumnView/BackButtonImg.svg");
// export const EMPTY_STEP_IMG = cu("iconForMultiColumnView/emptyStepImg.svg");
export const INSERT_VALUE_ICON = cu("iconForMultiColumnView/insertValueIcon.svg");
export const REPEAT_ICON = cu("iconForMultiColumnView/repeat.svg");
export const VIEW_ICON = cu("iconForMultiColumnView/viewIcon.svg");
// export const PLAY_DROPDOWN_ICON = cu("iconForMultiColumnView/playDropDownIcon.svg");
// export const TEST_BLOCK_STEP_ICON = cu("iconForMultiColumnView/testBlockStepIcon.svg");
export const SWIPE_UP_ICON = cu("iconForMultiColumnView/swipeUp.svg");
export const SWIPE_DOWN_ICON = cu("iconForMultiColumnView/swipeDown.svg");
export const SWIPE_LEFT_ICON = cu("iconForMultiColumnView/swipeLeft.svg");
export const SWIPE_RIGHT_ICON = cu("iconForMultiColumnView/swipeRight.svg");
export const SELECT_IMAGE = cu("common/SelectImage.svg");
export const CLEAR_TEXT = cu("iconForMultiColumnView/clearText.svg");
export const ACTIVE_SWIPE_UP_ICON = cu("iconForMultiColumnView/activeSwipeUp.svg");
export const ACTIVE_SWIPE_DOWN_ICON = cu("iconForMultiColumnView/activeSwipeDown.svg");
export const ACTIVE_SWIPE_RIGHT_ICON = cu("iconForMultiColumnView/activeSwipeRight.svg");
export const ACTIVE_SWIPE_LEFT_ICON = cu("iconForMultiColumnView/activeSwipeLeft.svg");
export const CLOSE_ICON_BlACK = cu("common/closeIconBlack.svg");
// export const PREVIEW_ICON = cu("common/previewIcon.svg");
export const ABORT_STATUS_ICON = cu("statusIcon/abort.png");
export const FAILED_STATUS_ICON = cu("statusIcon/failed.png");
export const SKIP_STATUS_ICON = cu("statusIcon/skip.png");
export const SUCCESS_STATUS_ICON = cu("statusIcon/success.png");
export const CONTINUE_AFTER_FAIL_ICON = cu("statusIcon/continueAfterFail.png");
export const DEVICE_IMG = cu("common/deviceImg.svg");

//update design fro scenario
export const ADD_STEP_ICON = cu("common/addStepIcon.svg");

//deviceIcons
export const GOOGLE_PIXEL_4A_ICON = cu("deviceIcons/googlePixel4AIcon.png");
export const SAMSUNG_A02_ICON = cu("deviceIcons/samsungA02Icon.png");
export const SAMSUNG_A52_ICON = cu("deviceIcons/samsungA52Icon.png");
export const SAMSUNG_M04_ICON = cu("deviceIcons/samsungMo4Icon.png");
export const SAMSUNG_A05_ICON = cu("deviceIcons/samsungA05Icon.png");
export const MOTOROLA_MOTO_G04_ICON = cu("deviceIcons/motorolaMotoG04Icon.png");
export const SAMSUNG_M12_ICON = cu("deviceIcons/samsungM12Icon.png");
export const GOOGLE_PIXEL_6_PRO_ICON = cu("deviceIcons/googlePixel6ProIcon.png");
export const ANDROID_IMG=cu("deviceIcons/androidImg.png");
export const IPHONE_11_ICON = cu("deviceIcons/iPhone11Icon.png");
export const IPHONE_X_ICON = cu("deviceIcons/iPhoneXIcon.png");
export const IPHONE_SE_ICON=cu("deviceIcons/iPhoneSEIcon.png");
export const IPHONE_XR_ICON=cu("deviceIcons/iPhoneXRIcon.png");
export const IPHONE_12_ICON=cu("deviceIcons/iPhone12Icon.png");
export const IPHONE_ICON=cu("deviceIcons/iPhoneIcon.png");
