import React from "react";
import { Button, Modal, Space } from "antd";
import { LOGO_XPRESS } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import styles from "./CommonModalV2.module.scss";

const CommonModalV2 = (props) => {
  const { modalVisible, children, modalClose, cancelText, saveBtnDisabled, handleSubmit } = props;
  return (
    <Modal
      visible={modalVisible}
      width={550}
      footer={null}
      closable={false}
      className="common-modal-wrapper"
      onCancel={modalClose}
      destroyOnClose
    >
      <div>
        <div className="mt-20">
          <div>
            <SvgLoader iconName={LOGO_XPRESS} width={112} height={33} />
          </div>
        </div>
        {children}
        <Space size={10}>
          <Button type="primary" className={styles["cancelBtn"]} onClick={modalClose}>
            <span>{cancelText}</span>
          </Button>
          <Button type="primary" className={styles["saveBtn"]} disabled={saveBtnDisabled} onClick={handleSubmit}>
            <span>Save</span>
          </Button>
        </Space>
      </div>
    </Modal>
  );
};

export default CommonModalV2;
