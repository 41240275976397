import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Input, Row } from 'antd'
import React from 'react'
import styled from 'styled-components';

export const StyledInput = styled(Input)`
  font-size: 14px;
  color: #555555;
  line-height: 1.2;
  display: block;
  width: 100%;
  height: 30px;
  background: transparent;
  padding: 0 5px;
  margin-top: 5px;
  border: 1px solid rgba(145, 145, 145, 0.65) !important;
  border-radius: 4px !important;
`;

export const StyledRow = styled(Row)`
   width: auto;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  padding: 35px 55px 55px 55px;
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.1);
`;

export const StyledButton = styled(Button)`
  font-size: 12px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px !important;
  margin-top: 20px
`;

const MyFirstProject = () => {
    return (
        <React.Fragment>
            <div className={"limiter"}>
                <div className={"container-login100"}>
                    <StyledRow>
                        <Col className={"login100-form validate-form"}>
                            <div><span className="font-size-20 font-weight-600 font-color-black ">
                                <FontAwesomeIcon className="font-size-16 mr-5" icon={faArrowLeft}></FontAwesomeIcon>
                                Congratulations,you've created your first project in Xpress!
                            </span>
                            </div>
                            <div className="mt-15"><span className="font-size-16 font-weight-400">Who's working on yourproject with you?</span></div>
                            <div className="mt-50"><span className="font-size-14 font-weight-300">Email address</span></div>
                            <div className={"wrap-input100 validate-input"}>
                                <StyledInput className={"input100"}
                                    type="text" name="UserEmail" placeholder="Teammate's Email"
                                // onChange={(e) => { validateUserInputForEmail(e.target.value) }}
                                />
                            </div>
                            <div className={"wrap-input100 validate-input"}>
                                <StyledInput className={"input100 "}
                                    type="text" name="UserEmail" placeholder="Teammate's Email"
                                // onChange={(e) => { validateUserInputForEmail(e.target.value) }}
                                />
                            </div>
                            <div className={"wrap-input100 validate-input"}>
                                <StyledInput className={"input100 "}
                                    type="text" name="UserEmail" placeholder="Teammate's Email"
                                // onChange={(e) => { validateUserInputForEmail(e.target.value) }}
                                />
                            </div>
                            <div className={"wrap-input100 validate-input"}>
                                <StyledInput className={"input100 "}
                                    type="text" name="UserEmail" placeholder="Teammate's Email"
                                // onChange={(e) => { validateUserInputForEmail(e.target.value) }}
                                />
                            </div>
                            <div className={"container-login100-form-btn"}>
                                <div className={"wrap-login100-form-btn"}>
                                    <StyledButton
                                        type="primary"
                                    >
                                        Take me to my Project
                                    </StyledButton>
                                </div>
                            </div>
                        </Col>
                    </StyledRow>
                </div>
            </div>
        </React.Fragment>
    )
}

export default MyFirstProject
