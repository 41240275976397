import { Col, Popover } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEP } from "../../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmpty } from "../../../../Util";
import { reRecordRecTestStepElement } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./RecTestStepImageSelectionDetail.module.scss";

const RecTestStepImageSelectionDetail = (props) => {
  const { data, recElement, isSkipStep, parentRecElement } = props;
  const dispatch = useDispatch();
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, unSavedRecElementGuid, requestingPerformRecTestStepAction } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  let showReRecordButton =
    (parentRecElement === undefined &&
      ((data.recTestStepProperty?.action === STEP &&
        data.recTestStepProperty?.isStepHasPrecondition === 1 &&
        isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
        data.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
        data.recTestStepProperty?.recStepVerifyElementProperties.length ===
          data.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length) ||
        (data.recTestStepProperty?.action === STEP &&
          data.recTestStepProperty?.isLoopStep === 1 &&
          data.recTestStepProperty?.loopCount > 0 &&
          isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
          (data.recTestStepProperty?.recElements.length === 0 ||
            (data.recTestStepProperty?.recElements.length > 0 &&
              data.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
              data.recTestStepProperty?.recStepVerifyElementProperties.length ===
                data.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined)
                  .length))) ||
        (data.recTestStepProperty?.action !== STEP &&
          data.id !== undefined &&
          isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
          isNullOrUndefinedOrEmpty(unSavedRecElementGuid)))) ||
    (parentRecElement !== undefined &&
      isNullOrUndefinedOrEmpty(unSavedRecElementGuid) &&
      (recElement.elementPurpose === "ACTION" ||
        (recElement.elementPurpose === "VERIFICATION" &&
          recElement.recStepVerifyElementProperties.length > 0 &&
          recElement.recStepVerifyElementProperties.length ===
            recElement.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length)));
  let selectionImage =
    (recElement.id === undefined || recElement.previous_elementImage !== undefined) &&
    recElement.elementImage !== undefined &&
    !recElement.elementImage.includes("https://")
      ? "data:image/jpeg;base64," + recElement.elementImage
      : recElement.elementImage;
  let disableReRecordButton =
    selectedDeviceByUdid === undefined ||
    !startDeviceStreamingStatus ||
    requestingPerformRecTestStepAction ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
      unSavedRecStepTag !== data.tag &&
      unSavedRecElementGuid !== recElement.guid);

  const disableReplaceElement = isPreviewRunning || disableReRecordButton || isSkipStep;
  return selectionImage ? (
    <>
      <div className={styles.selectImage}>
        <Popover
          content={<img className={styles.stepImageOnHover} src={selectionImage} alt="Screenshot" width="200" />}
          placement="left"
          title="Device Selection Image"
          trigger="hover">
          <img className={styles.stepImage} src={selectionImage} alt="Screenshot" height="100" width="100" />
        </Popover>
        <div className={styles.bounds}>
          {recElement.selectedImageCoordinates
            ? "Bounds : [" +
              recElement.selectedImageCoordinates.x +
              "," +
              recElement.selectedImageCoordinates.y +
              "][" +
              (recElement.selectedImageCoordinates.x + recElement.selectedImageCoordinates.width) +
              "," +
              (recElement.selectedImageCoordinates.y + recElement.selectedImageCoordinates.height) +
              "]"
            : ""}
        </div>
      </div>
      <div>
        {showReRecordButton &&
          (isPreviewRunning || !disableReRecordButton || isSkipStep) &&
          (recElement.childRecElements === undefined || recElement?.childRecElements?.length === 0) && (
            <div
              className={`${styles.replaceElement} ${disableReplaceElement && styles.disableButton}`}
              onClick={() =>
                !disableReplaceElement &&
                dispatch(reRecordRecTestStepElement(data.tag, recElement.guid, parentRecElement))
              }>
              Replace Element
            </div>
          )}
      </div>
    </>
  ) : (
    <Col span={24}>
      <div className="font-size-13 font-weight-500 mt-12 orangeText">Draw on the Device screen to select an area</div>
    </Col>
  );
};

export default RecTestStepImageSelectionDetail;
