import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import { RESET_DEVICE_SCREEN_SOURCE } from "../../../../Constants";
import {
  hideDeleteLoopConditionModalVisible,
  removeLoopConditionFromRecTestStep,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";

const RecTestStepLoopConditionDeleteModalV2 = () => {
  const { deleteRecTestStepLoopConditionModalVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  return (
    <CommonConfirmationModalV2
      handleOk={() => {
        dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
        dispatch(removeLoopConditionFromRecTestStep());
      }}
      handleCancel={() => dispatch(hideDeleteLoopConditionModalVisible())}
      modalOpen={deleteRecTestStepLoopConditionModalVisible}
      modalTitle={"Confirm Deletion"}
      modalContent={"Are you sure you want to delete this condition?"}
      okText={"Delete"}
      isDeleteModal={true}
    />
  );
};

export default RecTestStepLoopConditionDeleteModalV2;
