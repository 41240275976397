import { notification } from "antd";
import {
  ADD_TEAM_MEMBER,
  FAILED_STATUS,
  GET_ORG_LIST,
  REMOVE_TEAM_MEMBER,
  REQUEST_MY_TEAMS,
  REQUEST_PLATFORM,
  REQUEST_USERS_FROM_ORG,
  SAVE_TEAM,
  SELECTED_TEAM_MEMBERS,
  SUCCESS_STATUS,
  TEAM_LIST,
  UPDATED_TEAM_SAVED,
  ADD_MEMBER_LOADER,
  CREATE_TEAM_LOADER,
  SUCCESS_TYPE,
  ERROR_TYPE,
  LIST_OF_TEAM_MEMBERS,
  GET_TEAM_DETAILS_LOADER,
} from "../Constants";
import { showNotification } from "../Util";

let defaultState = {
  myTeamsList: undefined,
  platformDataList: null,
  saveTeamMsg: null,
  addMemberTeamMsg: null,
  removeTeamMemberMsg: null,
  myOrgUsers: [],
  orgList: undefined,
  createTeamLoader: false,
  addMemberToTeamLoader: false,
  currentTeamListPage: null,
  totalTeamListPage: null,
  totalPageItemsForTeam: null,
  totalItemsForTeam: null,
  listOfTeamMembers: [],
  getTeamDetailsLoader:false
};

export default function TeamsReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case REQUEST_MY_TEAMS:
      response = action.response;
      return {
        ...state,
        myTeamsList: response?.data?.data,
        currentTeamListPage: response?.data?.currentPage,
        totalTeamListPage: response?.data?.totalCount,
        totalPageItemsForTeam: response?.data?.pageItems,
        totalItemsForProject: response?.data?.totalItems,
      };

    case REQUEST_PLATFORM:
      response = action.response.data;
      return {
        ...state,
        platformDataList: response,
      };

    case TEAM_LIST:
      state.selectTeamMembers = action.val;
      return {
        ...state,
      };

    case REQUEST_USERS_FROM_ORG:
      response = action.response.data;
      return {
        ...state,
        myOrgUsers: response,
      };

    case GET_ORG_LIST:
      response = action?.response?.data?.data;
      return {
        ...state,
        orgList: response,
      };

    case SAVE_TEAM:
      response = action.response;
      return {
        ...state,
        saveTeamMsg: response.status,
      };

    case ADD_TEAM_MEMBER:
      response = action.response;
      return {
        ...state,
        addMemberTeamMsg: response.status,
      };

    case LIST_OF_TEAM_MEMBERS:
      response = action.response;
      return {
        ...state,
        listOfTeamMembers: response.data ? response.data : []
      }

    case REMOVE_TEAM_MEMBER:
      response = action.response;
      state.removeTeamMemberMsg = null;

      if (response.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        state.removeTeamMemberMsg = response.message;
      } else if (response.status !== SUCCESS_STATUS) {
        showNotification(ERROR_TYPE, response.message);
      }
      return {
        ...state,
      };

    case UPDATED_TEAM_SAVED:
      return {
        ...state,
        saveTeamMsg: null,
        addMemberTeamMsg: null,
      };

    case CREATE_TEAM_LOADER:
      return {
        ...state,
        createTeamLoader: action.data,
      };

    case ADD_MEMBER_LOADER:
      return {
        ...state,
        addMemberToTeamLoader: action.data,
      };

    case GET_TEAM_DETAILS_LOADER:
      return{
        ...state,
        getTeamDetailsLoader:action.isLoading
      }

    default:
      return state;
  }
}
