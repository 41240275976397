import {
  SERVER_LIST,
  SERVER_DEVICES,
  ACQUIRE_DEVICE,
  CONFIGURE_LOCAL_SERVICE_SETTING,
  RELEASE_DEVICE,
  INVALID_USER,
  RELEASE_DEVICE_BY_ID,
  CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST,
  CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED,
  ACQUIRE_RELEASE_DEVICE_REQUEST,
  CURRENT_DEVICE_EDIT_NOTE_UDID,
  CHANGE_CURRENT_DEVICE_NOTE,
  HIDE_DEVICE_NOTE_MODAL,
  CURRENT_DEVICE_NOTE_HISTORY,
  UPDATE_DEVICE_NOTE,
  DEVICE_FARM_LIST,
  ERROR_STATUS,
  LIST_OF_DEVICE_DEFAULT_APPS,
  SHOW_SET_DEFAULT_APP_MODAL,
  HIDE_SET_DEFAULT_APP_MODAL,
  LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP,
  CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED,
  SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE,
  CHANGE_DELETED_DEFAULT_APP_LIST,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS,
  SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED,
  SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN,
  SET_DEVICE_INTERVAL_ID,
  REMOVE_DEVICE_INTERVAL_ID,
  RESET_REMOTE_DEVICE_DATA,
  RESET_DEVICE_FARM_LIST,
  RUN_DETAILS,
  RUN_DETAILS_LOADING,
  VISIBLE_DEVICE_MORE_DETAILS_MODAL,
  RELEASE_DEVICE_LOADING,
} from "../Constants";
import history from "../history";
let defaultState = {
  serversList: undefined,
  deviceFarmList: null,
  deviceFarmId: undefined,
  selectedServerDevices: null,
  isAcquired: false,
  serverId: undefined,
  callToLocalServer: false,
  selectedDevice: undefined,
  checkAndRestartAndroidNodeServiceRequest: false,
  checkAndRestartAndroidNodeServiceRequestDeviceId: undefined,
  acquireReleaseDeviceServiceRequest: false,
  acquireReleaseDeviceServiceRequestDeviceId: undefined,
  currentDeviceEditNoteUdid: undefined,
  currentDeviceNote: "",
  currentDeviceNoteHistory: undefined,
  currentDeviceNoteHistory: undefined,
  singleDeviceFarmEnable: true,
  serverDownMessage: undefined,
  //default app Save variables
  listOfDeviceDefaultApps: [],
  currentDeviceSelectedForDefaultAppSet: undefined,
  appListForDefaultAppSet: [],
  selectedAppListForDefaultAppSet: [],
  savingTheDefaultAppDetails: false,
  deletedDefaultAppForDeviceList: [],
  deletingTheDefaultAppDetails: false,
  noDefaultAppSet: false,
  setDeviceCleanOnOpenSession: undefined,
  deviceTokenInterval: null,
  runDetails: undefined,
  runDetailsLoading: false,
  visibleMoreDetailOfDevice: false,
  udidForOpenModal: null,
  devicesMoreDetails: [],
  releaseDeviceTargetUniqueId:null
};

export default function ServerListReducer(state = defaultState, action) {
  switch (action.type) {
    case SERVER_LIST:
      return {
        ...state,
        serversList: action.response.data,
      };
    case DEVICE_FARM_LIST:
      return {
        ...state,
        deviceFarmList: action.response.data,
        deviceFarmId: action.response.data.length == 1 ? action.response.data[0] : undefined,
        singleDeviceFarmEnable: action.response.data.length == 1,
      };
    case RESET_DEVICE_FARM_LIST:
      return {
        ...state,
        deviceFarmList: [],
      };
    case RESET_REMOTE_DEVICE_DATA:
      return {
        ...state,
        deviceFarmId: undefined,
        selectedServerDevices: null,
      };
    case SERVER_DEVICES:
      // if (action.data.id !== "") {
      // history.push("/farms/"+action.data.deviceFarmId)
      // }
      return {
        ...state,
        selectedServerDevices: action.devices ? action.devices : null,
        deviceFarmId: action?.data?.id,
        // serverDownMessage:
        //   action.response && action.response.status === ERROR_STATUS ? action.response.message : undefined,
      };
    case ACQUIRE_DEVICE:
      state.selectedServerDevices &&
        state.selectedServerDevices != null &&
        state.selectedServerDevices.map((v, k) => {
          if (action.device.targetUniqueId === v.device.targetUniqueId) {
            v.isDeviceAcquired = true;
            v.deviceAcquireUserName = action.response.deviceAcquireUserName;
            v.deviceAcquireBy = action.response.deviceAcquireBy;
          }
        });
      return {
        ...state,
        selectedDevice: action.device,
        acquireReleaseDeviceServiceRequest: false,
        acquireReleaseDeviceServiceRequestDeviceId: undefined,
      };
    case RELEASE_DEVICE:
      state.selectedServerDevices &&
        state.selectedServerDevices != null &&
        state.selectedServerDevices.map((v, k) => {
          if (action.device.targetUniqueId === v.device.targetUniqueId) {
            v.isDeviceAcquired = false;
            v.deviceAcquireUserName = null;
            v.deviceAcquireBy = null;
          }
        });
      return {
        ...state,
        selectedDevice: undefined,
        acquireReleaseDeviceServiceRequest: false,
        acquireReleaseDeviceServiceRequestDeviceId: undefined,
      };
    case RELEASE_DEVICE_BY_ID:
      return {
        ...state,
        // serverId: "",
        selectedDevice: undefined,
      };
    case CURRENT_DEVICE_EDIT_NOTE_UDID:
      return {
        ...state,
        currentDeviceEditNoteUdid: action.targetUniqueId,
        currentDeviceNote: action.deviceNote,
      };
    case CHANGE_CURRENT_DEVICE_NOTE:
      return {
        ...state,
        currentDeviceNote: action.value,
      };
    case HIDE_DEVICE_NOTE_MODAL:
      return {
        ...state,
        currentDeviceNote: "",
        currentDeviceEditNoteUdid: undefined,
        currentDeviceNoteHistory: undefined,
      };
    case UPDATE_DEVICE_NOTE:
      return {
        ...state,
        currentDeviceNote: "",
        currentDeviceEditNoteUdid: undefined,
        currentDeviceNoteHistory: undefined,
      };

    case CURRENT_DEVICE_NOTE_HISTORY:
      return {
        ...state,
        currentDeviceNoteHistory: action.response.data,
      };

    case CONFIGURE_LOCAL_SERVICE_SETTING:
      return {
        ...state,
        callToLocalServer: action.data.callToLocalServer,
        serverId: action.data.serverId,
      };
    case CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUEST:
      return {
        ...state,
        checkAndRestartAndroidNodeServiceRequest: true,
        checkAndRestartAndroidNodeServiceRequestDeviceId: action.targetUniqueId,
        deviceScreen: undefined,
      };
    case CHECK_AND_RESTART_ANDROID_NODE_SERVICE_REQUESTED:
      return {
        ...state,
        checkAndRestartAndroidNodeServiceRequest: false,
        checkAndRestartAndroidNodeServiceRequestDeviceId: undefined,
      };
    case ACQUIRE_RELEASE_DEVICE_REQUEST:
      return {
        ...state,
        acquireReleaseDeviceServiceRequest: true,
        acquireReleaseDeviceServiceRequestDeviceId: action?.device?.targetUniqueId,
      };

    case INVALID_USER:
      state.selectedServerDevices &&
        state.selectedServerDevices != null &&
        state.selectedServerDevices.map((v, k) => {
          if (action.data.targetUniqueId === v.device.targetUniqueId) {
            v.isDeviceAcquired = false;
            v.deviceAcquireUserName = null;
            v.deviceAcquireBy = null;
          }
        });
      return {
        ...state,
        selectedDevice: undefined,
      };
    case LIST_OF_DEVICE_DEFAULT_APPS:
      return {
        ...state,
        listOfDeviceDefaultApps: action.response.data,
        noDefaultAppSet: action.response.data ? action.response.data.length == 0 : false,
      };
    case SHOW_SET_DEFAULT_APP_MODAL:
      return {
        ...state,
        currentDeviceSelectedForDefaultAppSet: action.targetUniqueId,
      };
    case HIDE_SET_DEFAULT_APP_MODAL:
      return {
        ...state,
        currentDeviceSelectedForDefaultAppSet: undefined,
        appListForDefaultAppSet: [],
        listOfDeviceDefaultApps: [],
        deletedDefaultAppForDeviceList: [],
        setDeviceCleanOnOpenSession: undefined,
        selectedAppListForDefaultAppSet: [],
      };
    case LIST_OFF_APPS_INSTALLED_FOR_DEFAULT_APP:
      return {
        ...state,
        appListForDefaultAppSet: action.response.data,
      };
    case CHANGE_THE_SELECTED_APP_IN_DEFAULT_SET:
      return {
        ...state,
        selectedAppListForDefaultAppSet: action.value,
      };
    case SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE:
      return {
        ...state,
        savingTheDefaultAppDetails: true,
      };
    case SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_SUCCESS:
      return {
        ...state,
        savingTheDefaultAppDetails: false,
        currentDeviceSelectedForDefaultAppSet: undefined,
        appListForDefaultAppSet: [],
        listOfDeviceDefaultApps: [],
        deletedDefaultAppForDeviceList: [],
        setDeviceCleanOnOpenSession: undefined,
        selectedAppListForDefaultAppSet: [],
      };
    case SAVE_DEFAULT_APPS_REQUEST_FOR_DEVICE_FAILED:
      return {
        ...state,
        savingTheDefaultAppDetails: false,
      };
    case CHANGE_DELETED_DEFAULT_APP_LIST:
      return {
        ...state,
        deletedDefaultAppForDeviceList: action.value,
      };
    case SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE:
      return {
        ...state,
        deletingTheDefaultAppDetails: true,
      };
    case SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_SUCCESS:
      return {
        ...state,
        deletingTheDefaultAppDetails: false,
        deletedDefaultAppForDeviceList: [],
      };
    case SAVE_DEFAULT_APPS_DELETE_REQUEST_FOR_DEVICE_FAILED:
      return {
        ...state,
        deletingTheDefaultAppDetails: false,
      };
    case SET_DEVICE_CLEAN_UP_ON_SESSION_OPEN:
      return {
        ...state,
        setDeviceCleanOnOpenSession: action.value,
      };

    case SET_DEVICE_INTERVAL_ID:
      return {
        ...state,
        deviceTokenInterval: action.value,
      };
    case REMOVE_DEVICE_INTERVAL_ID:
      return {
        ...state,
        deviceTokenInterval: null,
      };
    case VISIBLE_DEVICE_MORE_DETAILS_MODAL:
      return {
        ...state,
        visibleMoreDetailOfDevice: action?.visible,
        udidForOpenModal: action?.udidForOpenModal,
        devicesMoreDetails: action?.devicesMoreDetails || [],
        sharedWithDetails: action?.sharedWithDetails,
        stepTypeForDeviceModal: action?.typeForDeviceModals,
      };

    case RELEASE_DEVICE_LOADING:
      return{
        ...state,
        releaseDeviceTargetUniqueId:action?.response?.releaseDeviceTargetUniqueId
      }
    default:
      return state;
  }
}
