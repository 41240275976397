import {
  CHANGE_FREE_DEVICE_FOR_RECORDING,
  CHANGE_SCENARIO_LIST_FILTER,
  CLONE_REC_TEST_SCENARIO,
  DELETE_REC_TEST_SCENARIO,
  ERROR_TYPE,
  FETCHING_REC_TEST_SCENARIO_LIST,
  FETCH_PREVIEW_RUNS,
  GET_TEST_SCENARIO_STEPS_DATA,
  GET_TEST_SCENARIO_STEP_DATA,
  GET_TEST_SUITS,
  HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  HIDE_DEVICE_SELECTION_MODAL,
  HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE,
  LOAD_REC_TEST_SCENARIO_TAGS,
  MY_SCENARIOS,
  OWNED,
  RECORD_SCENARIO_UPDATE_SPINNER,
  REC_TEST_SCENARIO_LIST,
  REC_TEST_SCENARIO_LIST_BY_PROJECT_ID,
  REQUESTED_ADD_NEW_SCENARIO,
  REQUEST_ADD_NEW_SCENARIO,
  SEARCH_SCENARIO_KEYWORD,
  SELECTED_TAGS_RECORD_SCENARIO,
  SET_SELECTED_SCENARIO,
  SET_TEC_TEST_SCENARIO_ID,
  SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL,
  SHOW_DEVICE_SELECTION_MODAL,
  SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  TEST_RAIL_CASES,
  TEST_RAIL_CASE_LOADING,
  UPDATE_TAG_SCENARIO_FIELD,
} from "../Constants";
import { showNotification } from "../Util";

let defaultState = {
  recTestScenarioList: undefined,
  recTestScenarioListByProjectId: [],
  updatingRecordingScenarioSpinner: false,
  currentRecTestScenariosPage: 1,
  totalRecTestScenariosPage: 0,
  changeScenarioTagField: true,
  fetchingRecTestScenarioList: false,
  recTestScenarioListPageNumber: 1,
  deleteRecTestScenarioModalVisible: false,
  cloneRecTestScenarioModalVisible: false,
  deviceSelectionModalVisible: false,
  selectedRecTestScenarioId: undefined,
  selectedRecTestScenarioPlatform: undefined,
  freeDeviceListForRecording: undefined,
  selectedFreeDeviceForRecording: undefined,
  recTestScenarioListModalVisible: false,
  cloneRecTestScenario: null,
  deleteRecTestScenario: null,
  cloneRecTestScenario: null,
  requestingForNewScenario: false,
  requestingToLoadScenario: false,
  recTestScenarioTagsData: [],
  selectedTagsObject: [],
  testScenarioAndStepsAndDataList: [],
  testScenarioAndStepsAndDataLists: {},
  testRailCases: [],
  currentTestRailCases: 1,
  totalTestRailCases: 0,
  testSuitsData: [],
  testRailUrl: null,
  testRailCaseLoading: false,
  selectedScenario: {},
  previewRunsByScenarioId: [],
  recTestScenarioIdForPreview: null,
  scenarioListFilter: { id: OWNED, value: MY_SCENARIOS },
  searchScenarioKeyword: "",
};

export default function RecTestScenarioReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case SEARCH_SCENARIO_KEYWORD:
      return {
        ...state,
        searchScenarioKeyword: action.value,
      };
    case REQUEST_ADD_NEW_SCENARIO:
      return {
        ...state,
        requestingForNewScenario: true,
      };
    case REQUESTED_ADD_NEW_SCENARIO:
      return {
        ...state,
        requestingForNewScenario: false,
      };
    case SET_TEC_TEST_SCENARIO_ID:
      return {
        ...state,
        recTestScenarioIdForPreview: action?.id,
      };

    case FETCH_PREVIEW_RUNS:
      return {
        ...state,
        previewRunsByScenarioId: action?.response?.data,
      };
    case FETCHING_REC_TEST_SCENARIO_LIST:
      return {
        ...state,
        fetchingRecTestScenarioList: action.status,
        deleteRecTestScenarioModalVisible: false,
        cloneRecTestScenarioModalVisible: false,
        deviceSelectionModalVisible: false,
      };
    case SHOW_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL:
      response = action.recTestScenarioData;
      return {
        ...state,
        deleteRecTestScenarioModalVisible: true,
        selectedRecTestScenarioId: response?.id,
        selectedRecTestScenarioName: response?.name,
      };
    case SHOW_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL:
      return {
        ...state,
        cloneRecTestScenarioModalVisible: true,
        selectedRecTestScenarioId: action.recTestScenarioId,
      };
    case HIDE_DELETE_REC_TEST_SCENARIO_CONFIRMATION_MODAL:
      return {
        ...state,
        deleteRecTestScenarioModalVisible: false,
        selectedRecTestScenarioId: undefined,
      };
    case HIDE_CLONE_REC_TEST_SCENARIO_CONFIRMATION_MODAL:
      return {
        ...state,
        cloneRecTestScenarioModalVisible: false,
        selectedRecTestScenarioId: undefined,
      };
    case CHANGE_SCENARIO_LIST_FILTER:
      return {
        ...state,
        scenarioListFilter: action.value,
      };
    case SHOW_DEVICE_SELECTION_MODAL:
      return {
        ...state,
        deviceSelectionModalVisible: true,
        selectedRecTestScenarioId: action.recTestScenarioId,
        selectedRecTestScenarioPlatform: action.recTestScenarioPlatform,
        selectedFreeDeviceForRecording: undefined,
      };
    case HIDE_DEVICE_SELECTION_MODAL:
      return {
        ...state,
        deviceSelectionModalVisible: false,
        selectedRecTestScenarioId: undefined,
        selectedRecTestScenarioPlatform: undefined,
        freeDeviceListForRecording: undefined,
        selectedFreeDeviceForRecording: undefined,
      };
    case CHANGE_FREE_DEVICE_FOR_RECORDING:
      return {
        ...state,
        selectedFreeDeviceForRecording: action.targetUniqueId,
      };
    case REC_TEST_SCENARIO_LIST:
      response = action.response;
      if (response.status === SUCCESS_STATUS) {
        return {
          ...state,
          recTestScenarioList: response.data.data,
          totalRecTestScenariosPage: response.data.totalCount,
          fetchingRecTestScenarioList: false,
          currentRecTestScenariosPage: response.data?.currentPage,
          totalPageItem: response?.data?.pageItems,
          totalItems: response?.data?.totalItems,
        };
      } else {
        return {
          ...state,
          recTestScenarioList: undefined,
          fetchingRecTestScenarioList: false,
        };
      }
    case REC_TEST_SCENARIO_LIST_BY_PROJECT_ID:
      response = action.response;
      if (response.status === SUCCESS_STATUS) {
        return {
          ...state,
          recTestScenarioListByProjectId: response.data,
          recTestScenarioList: response.data,
        };
      } else {
        return {
          ...state,
          recTestScenarioList: undefined,
        };
      }
    case SHOW_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE:
      return {
        ...state,
        recTestScenarioListModalVisible: true,
      };
    case HIDE_REC_TEST_SCENARIO_LIST_MODAL_VISIBLE:
      return {
        ...state,
        recTestScenarioListModalVisible: false,
      };
    case DELETE_REC_TEST_SCENARIO:
      response = action.response;
      state.deleteRecTestScenario = null;
      if (response && response.status == SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        state.deleteRecTestScenario = response.message;
      } else if (response && response.status != SUCCESS_STATUS) {
        showNotification(ERROR_TYPE, response.message);
        state.deleteRecTestScenario = response.message;
      }
      return {
        ...state,
      };

    case LOAD_REC_TEST_SCENARIO_TAGS:
      response = action.response;
      if (response && response.status == SUCCESS_STATUS) {
        state.recTestScenarioTagsData = response.data;
      }
      return {
        ...state,
      };

    case SELECTED_TAGS_RECORD_SCENARIO:
      state.selectedTagsObject = madeTagObjectList(state.recTestScenarioTagsData, action.data);
      return {
        ...state,
      };
    case RECORD_SCENARIO_UPDATE_SPINNER:
      state.updatingRecordingScenarioSpinner = action.response.isLoading;
      return {
        ...state,
      };
    case UPDATE_TAG_SCENARIO_FIELD:
      state.changeScenarioTagField = action.data;
      return {
        ...state,
      };
    case GET_TEST_SCENARIO_STEP_DATA:
      response = action.response;
      state.testScenarioAndStepsAndDataList = response.data;
      return {
        ...state,
      };

    case GET_TEST_SCENARIO_STEPS_DATA:
      response = action.response;
      state.testScenarioAndStepsAndDataLists[action.id] = response.data;
      return {
        ...state,
      };

    case CLONE_REC_TEST_SCENARIO:
      response = action.response;
      state.cloneRecTestScenario = null;
      if (response && response.status == SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response.message);
        state.cloneRecTestScenario = response.message;
      } else if (response && response.status != SUCCESS_STATUS) {
        showNotification(ERROR_TYPE, response.message);
        state.cloneRecTestScenario = response.message;
      }
      return {
        ...state,
      };

    case TEST_RAIL_CASES:
      response = action.response;
      return {
        ...state,
        testRailCases: response.data.cases,
        currentTestRailCases: action.data.pageNumber || 1,
        totalTestRailCases: response.data.size * 10,
        testRailUrl: response.data.testRailUrl,
      };

    case TEST_RAIL_CASE_LOADING:
      response = action.response;
      return {
        ...state,
        testRailCaseLoading: response.isLoading,
      };

    case GET_TEST_SUITS:
      response = action.response;
      return {
        ...state,
        testSuitsData: response.data,
      };

    case SET_SELECTED_SCENARIO:
      response = action.data;
      return {
        ...state,
        selectedScenario: response,
      };

    default:
      return state;
  }
}

function madeTagObjectList(recTestScenarioTagsData, tagArray) {
  let arr = [];
  tagArray.map((s) => {
    let foundTag = recTestScenarioTagsData.find((k) => k.name == s);
    if (foundTag) {
      arr.push({ id: foundTag.id, name: foundTag.name });
    } else {
      arr.push({ name: s });
    }
  });
  return arr;
}
