import { default as React, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_INTERACTION_MODE, STEP } from "../../../../../Constants";
import { LOAD_DEVICE_ICON } from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmpty } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { changeAdvanceElementSelectorDrawerVisibility } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import AdvanceSelectorAllElementDrawer from "../../../../RecTestStep/SingleColumnView/AdvanceSelector/AdvanceSelectorAllElementDrawer/AdvanceSelectorAllElementDrawer";
import AdvanceSelectorChildElementDrawer from "../../../../RecTestStep/SingleColumnView/AdvanceSelector/DeviceSource/AdvanceSelectorChildElementDrawer/AdvanceSelectorChildElementDrawer";
import ApplicationStringDrawer from "../../../../RecTestStep/SingleColumnView/ApplicationStringDrawer/ApplicationStringDrawer";
import DeviceStringDrawer from "../../../../RecTestStep/SingleColumnView/DeviceStringDrawer/DeviceStringDrawer";
import RecTestStepElementDetailsV2 from "./RecTestStepElementDetailsV2";
import styles from "./RecTestStepElementSelectionDetailV2.module.scss";

const textToDisplayOfElement = (recElementProperties) => {
  let textToDisplayKey = "None";
  let textToDisplayValue = "None";
  let classToDisplay = "None";
  if (recElementProperties && recElementProperties.length > 0) {
    recElementProperties.forEach((element) => {
      if (
        element["name"] === "class" ||
        element["name"] === "type" ||
        element["name"] === "content-desc" ||
        element["name"] === "text" ||
        element["name"] === "value" ||
        element["name"] === "label" ||
        element["name"] === "name"
      ) {
        textToDisplayValue = element["value"];
        textToDisplayKey = element["name"];
      }
    });
    let classAttributes = recElementProperties.filter((item) => item["name"] === "class" || item["name"] === "type");
    classToDisplay = classAttributes.length > 0 ? classAttributes[0]["value"] : classToDisplay;
  }
  return { name: textToDisplayKey, value: textToDisplayValue, class: classToDisplay };
};

const RecTestStepElementSelectionDetailV2 = (props) => {
  const dispatch = useDispatch();

  const { data, recElement, parentRecElement, isDeviceLoaded, isTestBlock, projectId, disabled, isSkipStep, stepId } =
    props;

  const {
    isAdvanceElementSelectorDrawerVisible,
    unSavedRecStepTag,
    unSavedRecElementGuid,
    showApplicationDrawerVisible,
    guidForOpenApplicationDrawer,
    showDeviceDrawerVisible,
    guidForOpenDeviceDrawer,
    getDeviceString,
    getApplicationString,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { currentDeviceScreenSource, deviceInteractionMode } = useSelector((state) => state.RecordingReducer);
  const [searchElements, setSearchElements] = useState(null);

  //Destructure objects
  const { action, isStepHasPrecondition, recStepVerifyElementProperties, isLoopStep, loopCount, recElements } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const { guid } = recElement ? recElement : {};

  const recElementDisplayObj = textToDisplayOfElement(recElement.recElementProperties);

  const disableInspector = useMemo(
    () =>
      isNullOrUndefinedOrEmpty(currentDeviceScreenSource) ||
      ![DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(deviceInteractionMode) ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid),
    [currentDeviceScreenSource, deviceInteractionMode, unSavedRecElementGuid, guid]
  );

  // Re-Record Button when to display
  const hasAllItemsWithId = (array) => {
    return array.length > 0 && array.every((item) => item.id !== undefined);
  };

  const checkStepConditions = () => {
    return (
      action === STEP &&
      isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
      ((isStepHasPrecondition === 1 && hasAllItemsWithId(recStepVerifyElementProperties)) ||
        (isLoopStep === 1 &&
          loopCount > 0 &&
          (recElements.length === 0 || (recElements.length > 0 && hasAllItemsWithId(recStepVerifyElementProperties)))))
    );
  };

  const checkNonStepConditions = () => {
    return (
      action !== STEP &&
      data.id !== undefined &&
      isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
      isNullOrUndefinedOrEmpty(unSavedRecElementGuid)
    );
  };

  // Function to determine if the re-record button should be shown
  const shouldShowReRecordButton = () => {
    if (parentRecElement === undefined) {
      return checkStepConditions() || checkNonStepConditions();
    } else {
      return (
        isNullOrUndefinedOrEmpty(unSavedRecElementGuid) &&
        (recElement.elementPurpose === "ACTION" ||
          (recElement.elementPurpose === "VERIFICATION" &&
            recElement.recStepVerifyElementProperties.length > 0 &&
            hasAllItemsWithId(recElement.recStepVerifyElementProperties)))
      );
    }
  };

  // Assigning the final result
  const showReRecordButton = shouldShowReRecordButton();

  const getAdvanceSelector = (isAdvanceElementSelectorDrawerVisible, deviceInteractionMode, parentRecElement) => {
    if (
      isAdvanceElementSelectorDrawerVisible &&
      deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT &&
      parentRecElement === undefined
    ) {
      return (
        <>
          <AdvanceSelectorAllElementDrawer
            searchElements={searchElements}
            setSearchElements={setSearchElements}
            recElement={recElement}
          />
        </>
      );
    } else if (
      isAdvanceElementSelectorDrawerVisible &&
      deviceInteractionMode === DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD &&
      parentRecElement
    ) {
      return (
        <AdvanceSelectorChildElementDrawer
          searchElements={searchElements}
          setSearchElements={setSearchElements}
          recElement={recElement}
          parentElement={parentRecElement}
        />
      );
    }
    return "";
  };

  const loadDeviceNote = (note) => {
    return (
      <div className={styles["load-device-icon-and-text"]}>
        <span className={styles["load-device-text"]}>{note}</span>
        <span className={styles['load_Device_Icon']}>
          <SvgLoader iconName={LOAD_DEVICE_ICON} />
        </span>
      </div>
    );
  };


  return isDeviceLoaded || recElement.id !== undefined ? (
    <div>
      {((isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) && isDeviceLoaded && !disableInspector) ||
        [DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(deviceInteractionMode)) && (
        <div className={styles["inspector_Button_And_Text"]}>
          {loadDeviceNote("Select an Element on the Device or use Inspector")}
          <div
            onClick={() => {
              if (!disableInspector) {
                setSearchElements(null);
                isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) &&
                  dispatch(changeAdvanceElementSelectorDrawerVisibility(true));
              }
            }}
            className={`${styles.inspector_Button} ${disableInspector && styles["disable_Inspector_Button"]}`}
          >
            Inspector
          </div>
        </div>
      )}
      <RecTestStepElementDetailsV2
        recElement={recElement}
        recElementDisplayObj={recElementDisplayObj}
        projectId={projectId}
        data={data}
        showReRecordButton={showReRecordButton}
        disabled={disabled}
        isSkipStep={isSkipStep}
        parentRecElement={parentRecElement}
        stepId={stepId}
        isTestBlock={isTestBlock}
      />

      {getAdvanceSelector(isAdvanceElementSelectorDrawerVisible, deviceInteractionMode, parentRecElement)}
      <ApplicationStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={projectId}
        disabled={disabled || isSkipStep}
      />
      
      <DeviceStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={projectId}
        disabled={disabled || isSkipStep}
      />
    </div>
  ) : (
    loadDeviceNote("Please Load the Device")
  );
};

export default RecTestStepElementSelectionDetailV2;
