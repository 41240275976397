import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import { TOOLTIP_BG_COLOR, defaultDateTimeFormat } from "../../../../../Constants";
import {
  displayUTCDateFormatWithToolTip,
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  openTestPlanResultDetails,
} from "../../../../../Util";
import styles from "./AllJobsDetailsColumnsV2.module.scss";

const JobHistoryColumnV2 = (props) => {
  const dispatch = useDispatch();
  const { row, orgId, projectId } = props;
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  return (
    <div>
      {loggedInUserDetails?.userEmail !== row?.createdBy?.email && (
        <div>
          <span className={styles["label"]}>Created By: </span>
          <Tooltip
            color={TOOLTIP_BG_COLOR}
            title={
              row.createDate ? (
                <>
                  {getDateTimeInFormat(row?.createDate)} (
                  {getDateTimeInUTCFormat(row?.createDate, defaultDateTimeFormat)} GMT)
                </>
              ) : (
                "NA"
              )
            }
          >
            <span className={`${styles["label-name"]} ${styles["blue_Text"]}`}>{row?.createdBy?.name || "NA"}</span>
          </Tooltip>
        </div>
      )}
      <div>
        <span className={styles["label"]}>Last Updated By: </span>
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={
            row.updateDate ? (
              <>
                {getDateTimeInFormat(row?.updateDate)} ({getDateTimeInUTCFormat(row?.updateDate, defaultDateTimeFormat)}{" "}
                GMT)
              </>
            ) : (
              "NA"
            )
          }
        >
          <span className={`${styles["label-name"]} ${styles["blue_Text"]}`}>{row?.updatedBy?.name || "NA"}</span>
        </Tooltip>
      </div>
      <div>
        <span className={styles["label-name"]}>Last Job Execution: </span>
        <span
          className={`${styles["step-And-Test-Plan-Details"]} ${styles["last-Job-Execution-Text"]}`}
          onClick={() => openTestPlanResultDetails(dispatch, orgId, projectId, row?.lastRun.runNumber)}
        >
          #{row?.lastRun.runNumber}
        </span>
      </div>
      <div>
        <span className={styles["label"]}>Last Executed On: </span>
        <span className={styles["label-name"]}>
          {" "}
          {row?.lastRun?.scheduleTime
            ? displayUTCDateFormatWithToolTip(row?.lastRun?.scheduleTime, defaultDateTimeFormat)
            : "NA"}
        </span>
      </div>
    </div>
  );
};

export default JobHistoryColumnV2;
