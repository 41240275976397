import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Progress, Row, message } from "antd";
import { ERROR_TYPE, ORGANIZATION_STEP_FLOW } from "../../Constants";
import {
  LOGO_XPRESS,
  ORGANIZATION_STEP_1_IMG,
  ORGANIZATION_STEP_2_IMG,
  ORGANIZATION_STEP_3_IMG,
} from "../../Constants/SvgConstants";
import { showMessage, validateEmail } from "../../Util";
import SvgLoader from "../../Util/SvgLoader";
import { onProjectSubmit, onTeamSubmit, saveOrganization, setOrganizationCurrentStep } from "../../actions/OrganizationAction";
import history from "../../history";
import BannerV2 from "../BannerV2/BannerV2";
import CreateOrganizationStepsV2 from "./CreateOrganizationStepsV2";
import styles from "./OrganizationV2.module.scss";

export const CreateOrganizationV2 = (props) => {
  const dispatch = useDispatch();
  let { loginSuccess } = useSelector((state) => state.CommonReducer);
  let { createOrgResponse, createTeamResponse, getOrgStepData, isOrganizationLoading, organizationCurrentStep } =
    useSelector((state) => state.OrganizationReducer);

  const [organizationName, setOrganizationName] = useState(null);
  const [teamName, setTeamName] = useState(null);
  const [projectName, setProjectName] = useState(null);
  const [selectedTeamUser, setSelectedTeamUser] = useState([]);
  const [selectProjectUser, setSelectProjectUser] = useState([]);
  const [organizationProcessStep, setOrganizationProcessStep] = useState(ORGANIZATION_STEP_FLOW.STEP1);
  const [orgIdByLogin, setOrgIdByLogin] = useState();

    useEffect(() => {
    if (getOrgStepData && getOrgStepData.orgId && getOrgStepData.teamId === 0) {
      setOrganizationName(getOrgStepData.orgName);
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP2);
      dispatch(setOrganizationCurrentStep(34));
    } else if (getOrgStepData && getOrgStepData.projectId) {
      history.push(`${getOrgStepData.orgId}/projects`);
    } else if (getOrgStepData && getOrgStepData.orgId && getOrgStepData.teamId) {
      setTeamName(getOrgStepData.teamName);
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP3);
    } else {
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP1);
    }
  }, [getOrgStepData]);

  useEffect(() => {
    if (createOrgResponse && createOrgResponse.id && createOrgResponse.name) {
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP2);
    }
  }, [createOrgResponse]);

  useEffect(() => {
    if (createTeamResponse && createTeamResponse.id && createTeamResponse.name) {
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP3);
    }
  }, [createTeamResponse]);

  const handleChange = (value, setUser) => {
    message.destroy();
    if (value && value.length > 0) {
      const isValid = value.every((x) => validateEmail(x));
      if (isValid) {
        setUser(value);
      } else {
        showMessage(ERROR_TYPE, "Please Enter Valid Email.");
      }
    }
  };

  const handleOrganization = () => {
    message.destroy();
    if (organizationName) {
      dispatch(saveOrganization({ orgName: organizationName }));
    } else {
      showMessage("error", "Please Insert Organization Name");
    }
  };

  //step2
  const handleCreateTeam = () => {
    message.destroy();
    if (teamName) {
      let teamObj = {
        name: teamName,
        orgId: createOrgResponse && createOrgResponse.id ? createOrgResponse.id : getOrgStepData.orgId,
        teamMembers: selectedTeamUser,
      };
      setSelectedTeamUser(selectedTeamUser);
      dispatch(onTeamSubmit(teamObj));
    } else {
      showMessage("error", "Please Insert Team Name");
    }
  };
  const handleSkip = () => {
    if (organizationProcessStep === ORGANIZATION_STEP_FLOW.STEP2) {
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP3);
      dispatch(setOrganizationCurrentStep(64));
    } else if (getOrgStepData && getOrgStepData.orgId) {
      history.push(`${getOrgStepData.orgId}/projects`);
      dispatch(setOrganizationCurrentStep(100));
    } else if (createOrgResponse && createOrgResponse.id && createOrgResponse.name) {
      history.push(`${createOrgResponse.id}/projects`);
      dispatch(setOrganizationCurrentStep(100));
    }
  };

  //step3
  const handleCreateProject = () => {
    message.destroy();
    if (projectName) {
      let projectObj = {
        name: projectName,
        orgId: createOrgResponse && createOrgResponse.id ? createOrgResponse.id : getOrgStepData.orgId,
        teamId: createTeamResponse && createTeamResponse.id ? createTeamResponse.id : getOrgStepData.teamId,
        projectMembers: selectProjectUser,
      };
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP3);
      dispatch(onProjectSubmit(projectObj));
    } else {
      showMessage("error", "Please Project Name.");
    }
  };

  useEffect(() => {
    if (loginSuccess && loginSuccess.isTeamCreated === 0) {
      setOrganizationProcessStep(ORGANIZATION_STEP_FLOW.STEP2);
      setOrganizationName(loginSuccess.orgName);
      setOrgIdByLogin(loginSuccess.orgId);
    }
  }, [loginSuccess]);

  const visibleBanner = () => {
    const stepImages = {
      [ORGANIZATION_STEP_FLOW.STEP1]: ORGANIZATION_STEP_1_IMG,
      [ORGANIZATION_STEP_FLOW.STEP2]: ORGANIZATION_STEP_2_IMG,
      [ORGANIZATION_STEP_FLOW.STEP3]: ORGANIZATION_STEP_3_IMG,
    };
    return stepImages[organizationProcessStep] || null;
  };

  const handleNextBtn = () => {
    const stepHandlers = {
      [ORGANIZATION_STEP_FLOW.STEP1]: handleOrganization,
      [ORGANIZATION_STEP_FLOW.STEP2]: handleCreateTeam,
      [ORGANIZATION_STEP_FLOW.STEP3]: handleCreateProject,
    };
    const handler = stepHandlers[organizationProcessStep];
    if (handler) handler();
  };

  const getStepProps = () => {
    switch (organizationProcessStep) {
      case ORGANIZATION_STEP_FLOW.STEP1:
        return {
          heading: "Create Organization",
          inputLabel: "Organization Name",
          inputPlaceholder: "Enter Organization Name",
          inputValue: organizationName,
          onInputChange: setOrganizationName,
        };
      case ORGANIZATION_STEP_FLOW.STEP2:
        return {
          heading: "Create a New Team",
          inputLabel: "Team Name",
          inputPlaceholder: "Enter Team Name",
          inputValue: teamName,
          selectLabel: "Team Members",
          selectPlaceholder: "Add Team Member by Email",
          selectValue: selectedTeamUser,
          onInputChange: setTeamName,
          onSelectChange: (value) => handleChange(value, setSelectedTeamUser),
        };
      case ORGANIZATION_STEP_FLOW.STEP3:
        return {
          heading: "Create a New Project",
          inputLabel: "Project Name",
          inputPlaceholder: "Enter Project Name",
          inputValue: projectName,
          selectLabel: "Project Members",
          selectPlaceholder: "Add Project Specific Members by Email",
          selectValue: selectProjectUser,
          onInputChange: setProjectName,
          onSelectChange: (value) => handleChange(value, setSelectProjectUser),
        };
      default:
        return {};
    }
  };

  return (
    <Col span={24} className={styles["container"]}>
      <Col span={15}>
        <BannerV2 imagName={visibleBanner()} />
      </Col>
      <Col span={9} className="auth_card_wrap">
        <Row className="auth_card">
          <Col span={24}>
            <SvgLoader iconName={LOGO_XPRESS} width={112} height={33} />
            <span className={styles["steps_Text"]}>{`Step 0${organizationProcessStep}/03`}</span>
            <Progress percent={organizationCurrentStep} showInfo={false} />
            <CreateOrganizationStepsV2 {...getStepProps()} />
            <div>
              <Button
                type="primary"
                size="large"
                block
                onClick={() => handleNextBtn()}
                loading={isOrganizationLoading}
                className={styles["btn_Next"]}
              >
                {organizationProcessStep === ORGANIZATION_STEP_FLOW.STEP3 ? "Finish" : "Next"}
              </Button>
            </div>
            {organizationProcessStep !== ORGANIZATION_STEP_FLOW.STEP1 && (
              <div onClick={() => handleSkip()} className={styles["skip_Button"]}>
                Skip
              </div>
            )}
          </Col>
        </Row>
      </Col>
    </Col>
  );
};

export default CreateOrganizationV2;
