import React from "react";
import _ from "lodash";
import { Select } from "antd";
import { ALL_COMPLETED_SCENARIO, FAILED_SCENARIO, PASSED_SCENARIO, TEST_PLAN_RESULT_TYPE } from "../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import { useDispatch, useSelector } from "react-redux";
import { changeReportResultTab, setResultType } from "../../../actions/TestPlanAction";
import { StyledSelect } from "../../RecTestStep/SingleColumnView/RecTestStepCustomElementDetail/RecTestStepCustomElementDetail";

const SelectScenarios = (props) => {
  const dispatch = useDispatch();
  const { resultType } = useSelector((state) => state?.TestPlanReducer);
  const { testPlanResultDetails } = props;

  let selectAllScenario =
    testPlanResultDetails &&
    testPlanResultDetails?.map(
      (i) =>
        i?.scenarios &&
        i?.scenarios?.filter(
          (j) => j?.result === TEST_PLAN_RESULT_TYPE.PASSED || j?.result === TEST_PLAN_RESULT_TYPE.FAILED
        )
    );

  let selectAllPassScenario =
    testPlanResultDetails &&
    testPlanResultDetails?.map(
      (i) => i?.scenarios && i?.scenarios?.filter((j) => j?.result === TEST_PLAN_RESULT_TYPE.PASSED)
    );

  let selectAllFailScenario =
    testPlanResultDetails &&
    testPlanResultDetails?.map(
      (i) => i?.scenarios && i?.scenarios?.filter((j) => j?.result === TEST_PLAN_RESULT_TYPE.FAILED)
    );

  const isShowCheckBox =
    testPlanResultDetails &&
    !_.flatten(selectAllScenario).includes(undefined) &&
    _.flatten(selectAllScenario) &&
    _.flatten(selectAllScenario).some((i) => isNotNullAndNotEmptyAndNotUndefined(i?.scenarioFolderLink));

  const handleChange = (resultType) => {
    dispatch(setResultType(resultType));
    dispatch(changeReportResultTab("previewStepDetails"));
  };

  return (
    <>
      {isShowCheckBox && (
        <StyledSelect
          className="w-30"
          placeholder="Please Select Test Data"
          value={resultType ? resultType : "All"}
          onChange={(value) => {
            handleChange(value);
          }}
        >
          <Select.Option value={"All"} className={_.isEmpty(_.flatten(selectAllScenario)) && "disabled"}>
            {ALL_COMPLETED_SCENARIO}
          </Select.Option>
          <Select.Option value={"Passed"} className={_.isEmpty(_.flatten(selectAllPassScenario)) && "disabled"}>
            {PASSED_SCENARIO}
          </Select.Option>
          <Select.Option value={"Failed"} className={_.isEmpty(_.flatten(selectAllFailScenario)) && "disabled"}>
            {FAILED_SCENARIO}
          </Select.Option>
        </StyledSelect>
      )}
    </>
  );
};

export default SelectScenarios;
