import React from "react";
import TestStepVerifyElementPropertyGroup from "./TestStepVerifyElementPropertyGroup";
const TestStepVerifyElementPropertiesDetail = (props) => {
  const { data, operator, elementText } = props;
  return (
    <>
      <TestStepVerifyElementPropertyGroup data={data} operator={operator} elementText={elementText} />
    </>
  );
};

export default TestStepVerifyElementPropertiesDetail;
