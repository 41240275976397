import _ from "lodash";
import {
  ASSIGN_DEVICE_TO_FARM,
  CREATE_FARM,
  DEVICE_LOADING,
  DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN,
  FETCHING_DEVICE_LIST,
  GET_DEVICE,
  GET_DEVICE_FARM,
  RESET_SEARCH_DEVICE_BY_FARM,
  SEARCH_DEVICE_BY_FARM,
} from "../Constants";
let defaultState = {
  farmData: null,
  getDeviceFarmList: null,
  getSearchDeviceList: null,
  getDevicesData: [],
  assignDeviceToFarmList: null,
  deviceLoading: false,
  deviceLoader: false,
  fetchingDeviceList: false,
  isSearch: false,
};

export default function ManageFarmReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case CREATE_FARM:
      response = action.response;
      return {
        ...state,
        farmData: response,
      };

    case GET_DEVICE_FARM:
      response = action.response;
      return {
        ...state,
        getDeviceFarmList: response.data,
      };

    case SEARCH_DEVICE_BY_FARM:
      let getSearchDeviceList = [];
      if (getSearchDeviceList == null) {
        getSearchDeviceList = [];
      }
      if (action?.response?.length === 0) {
        getSearchDeviceList = [];
      } else {
        getSearchDeviceList = getSearchDeviceList.filter(
          (device) =>
            device?.miscDetails?.deviceDetail?.autoServerDetail?.id !==
            (action.response &&
              action.response.length > 0 &&
              action.response[0]?.miscDetails?.deviceDetail?.autoServerDetail?.id)
        );
        action.response &&
          action.response.map((device) => {
            device["serverDetail"] = device?.miscDetails?.deviceDetail?.autoServerDetail;
            getSearchDeviceList.push(device);
          });
        getSearchDeviceList = getSearchDeviceList.sort((a, b) => {
          return a?.miscDetails?.deviceDetail?.id - b?.miscDetails?.deviceDetail?.id;
        });
      }
      return {
        ...state,
        getSearchDeviceList: getSearchDeviceList,
      };

    case RESET_SEARCH_DEVICE_BY_FARM:
      return {
        ...state,
        getSearchDeviceList: null,
      };

    case GET_DEVICE:
      response = action.response;
      return {
        ...state,
        getDevicesData: response.data,
      };

    case DEVICE_LOADING:
      response = action.response;
      return {
        ...state,
        deviceLoading: response.isLoading,
      };

    case ASSIGN_DEVICE_TO_FARM:
      response = action.response;
      return {
        ...state,
        assignDeviceToFarmList: response,
      };

    case DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN:
      response = action.response;
      return {
        ...state,
        deviceLoader: response?.isLoading,
      };

    case FETCHING_DEVICE_LIST:
      return {
        ...state,
        fetchingDeviceList: action?.payload,
        isSearch: action?.isSearch,
      };
    default:
      return state;
  }
}
