import React from "react";
import styles from "./TestBlockDetailsColumnsV2.module.scss";

const TestBlockStepDetailsColumnV2 = ({ row }) => {
  return (
    <>
      <div>
        <span className={styles["label-name"]}>Total Steps: </span>
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.totalSteps}</span>
      </div>
      <div>
        <span className={styles["label-name"]}>Screenshots: </span>
        <span className={styles["step-And-Test-Plan-Details"]}>{row?.stepsWithScreenShot}</span>
      </div>
    </>
  );
};

export default TestBlockStepDetailsColumnV2;
