import { Button, Collapse, Drawer, Input, Select } from "antd";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../../../../CdnImagePath";
import { AI_SCENARIO_GENERATE_STEP_TYPE, STEP } from "../../../../../Constants";
import { deleteModal } from "../../../../../Util";
import {
  changeAIModelType,
  changeScenarioGenerateStepType,
  changeScenarioObjective,
  fetchGenerateStepStatus,
  generateScenarioSteps,
  hideAIGenerateStepsFromRecTestStep,
  stopGenerateStepsFromAIModal,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./GenerateScenarioStepsModal.module.scss";

const GenerateScenarioStepsModal = (props) => {
  const { selectedRecTestScenarioId} = props;
  const dispatch = useDispatch();
  const intervalRef = useRef(null);
  const inputRef=useRef(null);

  const {
    showGenerateStepsFromAiModal,
    scenarioObjective,
    scenarioGenerateInstructions,
    scenarioGenerateRequestLoading,
    selectedModelType,
    aiGenerationsFor,
    scenarioGenerateStepType,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid } = useSelector((state) => state.RecTestStepReducer);
  useEffect(() => {
    if (scenarioGenerateRequestLoading) {
      intervalRef.current = setInterval(
        () => (dispatch(fetchGenerateStepStatus({
          objective: scenarioObjective,
          rules: scenarioGenerateInstructions,
          deviceId: selectedDeviceByUdid?.targetUniqueId,
          recTestScenarioId: selectedRecTestScenarioId,
          modelType: selectedModelType,
        }))),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [scenarioGenerateRequestLoading]);

  useEffect(() => {
    if (inputRef && inputRef.current) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 1000);
    }
  }, [showGenerateStepsFromAiModal]);
  return (
    <Drawer
      title={
        <div className="display-flex justify-space-between">
          <span>{aiGenerationsFor===STEP ? "Generate Steps" :"Generate Scenario Steps"}</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor-pointer"
              onClick={() => {
                scenarioGenerateRequestLoading
                  ? deleteModal(
                      `Are you sure you want to stop generation of steps?`,
                      () => {
                        dispatch(
                          stopGenerateStepsFromAIModal({
                            objective: scenarioObjective,
                            rules: scenarioGenerateInstructions,
                            deviceId: selectedDeviceByUdid?.targetUniqueId,
                            recTestScenarioId: selectedRecTestScenarioId,
                            modelType: selectedModelType,
                          })
                        );
                      },
                      "",
                      true
                    )
                  : dispatch(hideAIGenerateStepsFromRecTestStep());
              }}
            />
          </span>
        </div>
      }
      maskClosable={false}
      placement="right"
      closable={false}
      width={700}
      onClose={() => {
        dispatch(hideAIGenerateStepsFromRecTestStep());
      }}
      visible={showGenerateStepsFromAiModal}
      bodyStyle={{ padding: 15 }}
    >
      <div className="mt-5">
        <div>Model Type:</div>
        <Select
            className="w-100 h-40"
            placeholder={"Select Model"}
            value={selectedModelType}
            onChange={(e) => {
              dispatch(changeAIModelType(e))
            }}>
              <Select.Option value="gpt-4o-2024-05-13">Speed</Select.Option>
              <Select.Option value="gpt-4-turbo">Reasoning</Select.Option>
            </Select>
      </div>
      {aiGenerationsFor===STEP  ? (
        <div className="mt-5">
          <div >
            High-Level Objective:
          </div>
          <Input
            className={"mt-5"}
            value={scenarioObjective}
            placeholder="Eg:'Create a post with the text 'Exploring the new park in our neighborhood!' and verify that the post has been created successfully.'"
            onChange={(e) => dispatch(changeScenarioObjective(e.target.value))}
            ref={inputRef}
          />
          <Select
                variant="outlined"
                className={styles["ai_objective_select"]}
                value={scenarioGenerateStepType}
                onChange={(value) => dispatch(changeScenarioGenerateStepType(value))}
                placeholder="Generate Steps"
          >
            <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.APPEND_WITH_EXISTING_STEPS}>
              Save current steps and append
            </Select.Option>
            <Select.Option value={AI_SCENARIO_GENERATE_STEP_TYPE.CLEAR_EXISTING_STEPS_AND_START}>
              Clear and regenerate
            </Select.Option>
          </Select>
          {scenarioGenerateRequestLoading ? (
            <Button
              type="primary"
              size="large"
              className="blue mt-5"
              disabled={
                selectedDeviceByUdid === undefined ||
                scenarioObjective == null || scenarioObjective.length == 0
              }
              onClick={() =>
                dispatch(
                  stopGenerateStepsFromAIModal({
                    objective: scenarioObjective,
                    rules: scenarioGenerateInstructions,
                    deviceId: selectedDeviceByUdid?.targetUniqueId,
                    modelType: selectedModelType,
                  })
                )
              }
            >
              Stop
            </Button>
          ) : (
            <Button
              type="primary"
              size="large"
              className="blue mt-5"
              loading={scenarioGenerateRequestLoading}
              disabled={
                selectedDeviceByUdid === undefined ||
                scenarioObjective == null || scenarioObjective.length == 0
              }
              onClick={() =>
                dispatch(
                  generateScenarioSteps({
                    objective: scenarioObjective,
                    rules: scenarioGenerateInstructions,
                    deviceId: selectedDeviceByUdid?.targetUniqueId,
                    modelType: selectedModelType,
                  })
                )
              }
            >
              Start
            </Button>
          )}
        </div>
      ):""}
       <div>
        <div></div>
        <Collapse className="mt-5" expandIconPosition="start" key={0}>
          <Collapse.Panel
            header={<b>Guidelines for Framing High-Level Objectives</b>}
            key={0}
          >
            <pre><b>1.</b> Be Clear and Specific<br/>&ensp;
              • Clearly state the action you want to perform.<br/>&ensp;
              • Avoid ambiguity by specifying the action and the target.<br/><br/>&nbsp;

              <b>Examples:</b><br/>&ensp;
                • Create a post with the text ‘hello’.<br/>&ensp;
                • Delete the post with the text ‘hi friend’.<br/><br/>

            <b>2.</b> Focus on Actions<br/>&ensp;
              • Concentrate on the main action you want to perform.<br/>&ensp;
              • Use actionable language/verbs that indicate a clear task.<br/><br/>&nbsp;

              <b>Examples:</b><br/>&ensp;
              • Click on the menu item ‘pizza’ from the top menu, scroll if not found.<br/>&ensp;
              • Navigate to the ‘Profile’ page and choose a new, random profile picture.<br/><br/>

            <b>3.</b> Include Necessary Details<br/>&ensp;
              • Provide important details such as the specific text, item, or element involved in the action.<br/><br/>&nbsp;

              <b>Examples:</b><br/>&ensp;
              • Search for ‘cabo’ and create a new board named ‘Mexico’ with a random pin.<br/>&ensp;
              • Scroll to the bottom of the page and click on the ‘Contact Support’ button.<br/><br/>

            <b>4.</b> Clearly Provide Verification Steps or End Conditions if Needed<br/>&ensp;
              • Specify any necessary verification steps or end conditions.<br/><br/>&nbsp;

              <b>Examples:</b><br/>&ensp;
              • Complete the onboarding process and verify that it is completed by confirming you are on the home screen.<br/>
              </pre>
          </Collapse.Panel>
        </Collapse>
      </div>
    </Drawer>
  );
};

export default GenerateScenarioStepsModal;
