import React, { useState } from "react";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { Tooltip, Typography } from "antd";
const { Paragraph } = Typography;

const ShowTooltipV2 = ({ text, className, handleOnClick }) => {
  const [truncated, setTruncated] = useState(false);

  return (
    <Tooltip title={truncated ? text : undefined} color={TOOLTIP_BG_COLOR}>
      <Paragraph
        ellipsis={{
          symbol: <></>,
          rows: 1,
          expandable: true,
          onEllipsis: (ellipsis) => setTruncated(ellipsis),
        }}
        className={`${className} ${truncated && "cursor__pointer"}`}
        onClick={handleOnClick}
      >
        {text}
      </Paragraph>
    </Tooltip>
  );
};

export default ShowTooltipV2;
