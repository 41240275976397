import React from "react";
import { DatePicker } from "antd";
import { defaultDateTimeFormat } from "../../Constants";

const { RangePicker } = DatePicker;

const DatePickerBox = (props) => {
  const { dateRange, dateChange } = props;
  return (
    <div className="pl-10">
      <RangePicker
        className="w-100"
        showTime={false}
        format={defaultDateTimeFormat}
        value={dateRange ? dateRange : ""}
        onChange={dateChange}
        allowClear={true}
      />
    </div>
  );
};

export default DatePickerBox;
