import { Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { GROUP_ACTION, verifyElementPropertiesText } from "../../../../../Constants";
import {
  removeVerificationRow,
  updateVerificationKey,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RemoveVerificationRowV2 from "./RemoveVerificationRowV2";
import styles from "./VerificationsV2.module.scss";

const VerifyElementPropertyV2 = (props) => {
  const { data, disabled, recTestStep, recElementVerification } = props;

  const dispatch = useDispatch();
  data.uuid = data.id ? data.id : data.uuid;

  verifyElementPropertiesText.forEach((item) => {
    const verifyElement = recElementVerification?.recElementProperties?.find((e) => e.name === item.key);
    if (verifyElement) {
      item.value = verifyElement?.value;
    }
  });

  const checkableIndex = verifyElementPropertiesText.findIndex((item) => item.key === "checkable");
  const focusableIndex = verifyElementPropertiesText.findIndex((item) => item.key === "focusable");

  if (checkableIndex !== -1 || focusableIndex !== -1) {
    const checkableValue = verifyElementPropertiesText[checkableIndex].value === "true";

    verifyElementPropertiesText[checkableIndex].value = checkableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not checkable")].value =
      checkableValue ? "true" : "true";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "checked")].value =
      checkableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not checked")].value =
      checkableValue ? "true" : "false";

    const focusableValue = verifyElementPropertiesText[focusableIndex].value === "true";
    verifyElementPropertiesText[focusableIndex].value = focusableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not focusable")].value =
      focusableValue ? "true" : "true";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "focused")].value =
      focusableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not focused")].value =
      focusableValue ? "true" : "false";
  }

  return (
    <React.Fragment>
      <div
        className={`${styles["verify-Element-Label-And-Select"]} ${
          props.type === "GROUP" && styles["group-Verification-Rules"]
        }`}
      >
        <div className={styles["verify-Element-Label"]}>Verify Element State</div>
        <div className={styles["selectBox-and-Remove-Icon"]}>
          <Select
            disabled={disabled}
            className={"select-Verify-Value"}
            value={data.key}
            onChange={(value) =>
              dispatch(updateVerificationKey(value, data.uuid, recTestStep.tag, recElementVerification?.guid))
            }
          >
            {verifyElementPropertiesText
              ?.filter((e) => {
                return e.value !== "false";
              })
              ?.map((v, k) => {
                return (
                  <Select.Option key={k} value={v.name}>
                    {v.name}
                  </Select.Option>
                );
              })}
          </Select>
          <div>
            <RemoveVerificationRowV2
              disabled={disabled}
              parentId={data.uuid}
              recTestStep={props.recTestStep}
              removeVerificationRow={removeVerificationRow}
              recElement={recElementVerification}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default VerifyElementPropertyV2;
