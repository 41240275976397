import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Select, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { OPERATION, POST_OPERATION_SETTINGS, TOOLTIP_BG_COLOR } from "../../../../../Constants";
import {
  CROSS_REMOVE_ICON,
  DEEP_BLUE_COLOR,
  DISABLE_COLOR,
  DISABLE_ICON_COLOR,
  RED_COLOR,
  SELECT_TEST_DATA_ICON,
} from "../../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  changeInsertValueToTestData,
  selectOutputTestData,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../../actions/TestDataAction";
import ManageTestDataV2 from "../../ManageTestDataV2/ManageTestDataV2";
import styles from "./QuickActionsV2.module.scss";

const InsertValueToOutputTestDataV2 = (props) => {
  const dispatch = useDispatch();
  const { disabled, data } = props;
  const { testDataList, unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { openTestDataSetDrawerByTabName, tagForOpenDrawer } = useSelector((props) => props.TestDataReducer);

  //Destructure object
  const { tag } = data ? data : {};

  const { outputTestData, outputTestDataId, insertValueToTestData } =
    data && data?.recTestStepProperty ? data?.recTestStepProperty : {};
  const { idOfOutputTestData } = outputTestData ? outputTestData : {};

  const disabledInputOrSelectBox = useMemo(
    () => disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tag),
    [disabled, unSavedRecStepTag, tag]
  );

  const disableRemoveSelectVariable = useMemo(
    () =>
      disabled ||
      ((idOfOutputTestData === undefined || outputTestDataId === undefined) && insertValueToTestData === undefined),
    [disabled, idOfOutputTestData, outputTestDataId, insertValueToTestData]
  );

  const openTestDataDrawer = () => {
    dispatch(visibleTestDataDrawer(POST_OPERATION_SETTINGS, tag));
    dispatch(selectRecTestSteps(data));
  };

  return (
    <>
      <div className={styles["insert_Value_Output_Data_Details"]}>
        <div className={styles["label_And_Input"]}>
          <span className={styles["actions_Label"]}>Assign Value</span>
          <Input
            type="text"
            disabled={disabledInputOrSelectBox}
            placeholder="Enter text"
            value={insertValueToTestData}
            onChange={(e) => dispatch(changeInsertValueToTestData(data.tag, e.target.value))}
            className={`${styles["assign_Value_Input_Box"]} ${disabledInputOrSelectBox && styles["selectBoxBgColor"]}`}
          />
        </div>
        <div className={styles["label_And_Select_Box"]}>
          <span className={styles["actions_Label"]}> to Test Data Key:</span>{" "}
          <div className={styles["select_Box_And_Remove_Btn"]}>
            <div
              className={`${styles["select_Test_Data_And_Icon"]} ${
                disabledInputOrSelectBox && styles["selectBoxBgColor"]
              }`}
            >
              <Select
                placeholder="Select a Read/Write Variable"
                className={styles["select_Test_Data"]}
                disabled={disabledInputOrSelectBox}
                value={idOfOutputTestData || outputTestDataId}
                onChange={(value) => dispatch(selectOutputTestData(data.tag, value))}
              >
                {testDataList &&
                  !isEmpty(testDataList) &&
                  testDataList.map((v, k) => {
                    return (
                      <Select.Option key={v.id} value={v.id}>
                        {v.name}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <div
                  className={`${styles["selectTestData"]} ${disabled && styles["disableSelectTestData"]}`}
                  onClick={() => {
                    !disabled && openTestDataDrawer();
                  }}
                >
                  <SvgLoader
                    iconName={SELECT_TEST_DATA_ICON}
                    disabled={disabled}
                    iconColor={disabled ? DISABLE_ICON_COLOR : DEEP_BLUE_COLOR}
                  />
                </div>
              </Tooltip>
            </div>
            <div
              className={`${styles["remove_Select_Variable_Btn"]}  ${
                disableRemoveSelectVariable && styles["disable_Button"]
              }
            `}
              onClick={() => !disableRemoveSelectVariable && dispatch(selectOutputTestData(data?.tag, undefined))}
            >
              <SvgLoader
                iconName={CROSS_REMOVE_ICON}
                disabled={disableRemoveSelectVariable}
                iconColor={disableRemoveSelectVariable ? DISABLE_COLOR : RED_COLOR}
                width="1.13rem"
                height="1rem"
              />
              <span>Remove</span>
            </div>
          </div>
        </div>
      </div>
      {openTestDataSetDrawerByTabName === OPERATION && data?.tag === tagForOpenDrawer && (
        <ManageTestDataV2 {...props} tabName={OPERATION} />
      )}
    </>
  );
};

export default InsertValueToOutputTestDataV2;
