import React from "react";
import { isEmpty } from "lodash";
import styles from "./TestPlanResultColumnsV2.module.scss";

const TestCoverageDetailsColumnV2 = ({ row, showDeviceList }) => {
  return (
    <>
      <div>
        <span className={styles["label"]}>
          Devices:
          {!isEmpty(row?.devices) ? (
            showDeviceList(row?.devices,row?.deviceServiceFarm)
          ) : (
            <span className={styles["label-name"]}> Unknown </span>
          )}{" "}
        </span>
      </div>
      <div>
        <span className={styles["label"]}>OS: </span>
        {row?.osCovered?.replaceAll(",", ", ")}
      </div>
      {row?.localeCovered && (
        <div>
          <span className={styles["label"]}>Locales:</span> {row?.localeCovered?.replaceAll(",", ", ")}
        </div>
      )}
      <div>
        <span className={styles["label"]}>Tests: </span>
        <span className={styles["label-name"]}>
          {" "}
          Scenarios: {row?.testCoverageAcrossDevices?.scenarioCount}, Steps:{" "}
          {row?.testCoverageAcrossDevices?.steps?.stepsCount}, Screenshots:{" "}
          {row?.testCoverageAcrossDevices?.steps?.screenshotCount}
        </span>
      </div>
    </>
  );
};

export default TestCoverageDetailsColumnV2;
