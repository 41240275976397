import React from "react";
import { Dropdown, Menu } from "antd";
import { FILTER_ICON } from "../../../CdnImagePath";
import { FilterButton } from "../StyledComponent/StyledComponents";

const FilterComponent = ({ filterList, handleFilterChange, selectedFilter, string }) => {
  const menu = (
    <Menu className={string ? "w-200 filterListScroll" : "w-200"} onClick={(e) => handleFilterChange(e.key)}>
      <Menu.Item className="submenu-title">
        <span className="ml-15">Filter</span>
      </Menu.Item>
      {filterList?.map((s, k) => {
        return (
          <Menu.Item key={string ? s.locales : s.name} value={string ? s.displayName : s.name}>
            {string ? `${s.locales} ${s.locales && "- "}${s.displayCountry}` : s.title}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <span>
      <Dropdown overlay={menu}>
        <FilterButton>
          {selectedFilter ? selectedFilter : string ? "All" : "Filter"}
          <img src={FILTER_ICON} alt="filter" />
        </FilterButton>
      </Dropdown>
    </span>
  );
};

export default FilterComponent;
