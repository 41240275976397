import { faYandexInternational } from "@fortawesome/free-brands-svg-icons";
import { faReply, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse, Tooltip } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import { REC_TEST_STEP_PREVIEW_STATUS_TYPE, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP,
  ICON_AI,
  OPEN_COLLAPSE_ICON_FOR_PARENT_STEP,
} from "../../../../Constants/SvgConstants";
import {
  getRecTestStepStatusWithImage,
  isCurrentRecTestStepDisable,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  showUnSavedNotification,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  showDeleteLoopConditionModalVisible,
  showDeleteRecTestStepPreConditionConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import RecTestStepLoopCondition from "../RecTestStepLoopCondition";
import RecTestStepPreCondition from "../RecTestStepPreCondition";
import styles from "./RecTestStepCondition.module.scss";

const { Panel } = Collapse;

export const getConditionalIcon = (recTestStep) => {
  if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faYandexInternational} />
      </span>
    );
  } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
    return (
      <span className="mr-5 font-color-orange">
        <FontAwesomeIcon icon={faReply} />
      </span>
    );
  }
};
const getHeaderText = (recTestStep, testBlockStep) => {
  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          When to Run this Block
        </span>
      );
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Block till
        </span>
      );
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          When to Run this Step
        </span>
      );
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return (
        <span>
          {getConditionalIcon(recTestStep)}
          Repeat Steps till
        </span>
      );
    }
  }
};

const getTooltipText = (recTestStep, testBlockStep) => {
  if (recTestStep.tag === testBlockStep?.tag) {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Block</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Block till Condition</span>;
    }
  } else {
    if (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1) {
      return <span>Remove When to Run this Step</span>;
    } else if (recTestStep.recTestStepProperty?.isLoopStep === 1) {
      return <span>Remove Repeat Till Condition</span>;
    }
  }
};

const RecTestStepCondition = (props) => {
  const {
    recTestStep,
    disableAction,
    isSkipStep,
    isDeviceLoaded,
    isTestBlock,
    stepType,
    recTestSteps,
    setDragOverOnCollapse,
  } = props;
  const dispatch = useDispatch();
  const { isPreviewRunning, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag } = useSelector((state) => state.RecTestStepCommonViewReducer);
  let disabled =
    isCurrentRecTestStepDisable(unSavedRecStepTag, recTestStep, disableAction) ||
    isPreviewRunning ||
    (stepType === TEST_SCENARIOS && isTestBlock);
  let disabledDeleteIcon =
    disabled ||
    (stepType === TEST_SCENARIOS &&
      recTestStep.isTestBlock === 1 &&
      (recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 ||
        recTestStep.recTestStepProperty?.isLoopStep === 1));
  let isUnsaved = showUnSavedNotification(recTestStep, unSavedRecStepTag);
  let recTestStepCollapseClassNames = `recTestStepCollapse ${
    !isSkipStep && "recTestStepCollapseBorder"
  } collapseStepOrderBorder stepCollapseBorder recTestStepSteps `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  }
  let isConditionStepOpen =
    (isStepHavePrecondition(recTestStep) || isStepHaveLoopStep(recTestStep)) &&
    !recTestStep.recTestStepProperty?.recStepVerifyElementProperties?.[0]?.id;

  return recTestStep?.recTestStepProperty?.isStepHasPrecondition === 1 ||
    recTestStep?.recTestStepProperty?.isLoopStep === 1 ? (
    <div
      onDragOver={(event) => {
        event.stopPropagation();
        setDragOverOnCollapse(false);
      }}
    >
      <Collapse
        className={(recTestStepCollapseClassNames += isSkipStep ? " disable_bg_color" : " bg__white")}
        expandIconPosition="start"
        defaultActiveKey={isConditionStepOpen ? 0 : 1}
        expandIcon={({ isActive }) => (
          <div className={`collapseIconOnHover ${styles.collapseIcon} ${isSkipStep && "collapseIconBgColor"}`}>
            <SvgLoader iconName={isActive ? OPEN_COLLAPSE_ICON_FOR_PARENT_STEP : CLOSE_COLLAPSE_ICON_FOR_PARENT_STEP} />
          </div>
        )}
      >
        <Panel
          showArrow={true}
          header={
            <span className={styles.conditionText}>{getHeaderText(recTestStep, testBlockStep)}</span>
        }
          extra={
            <div className={styles.previewOrDeleteIcon}>
              <div className="showRecTestStepActionsIconOnHover">
                <Tooltip color={TOOLTIP_BG_COLOR} title={getTooltipText(recTestStep, testBlockStep)}>
                  {!isPreviewRunning && (
                    <span
                      className={`${styles.deleteIcon} ${disabledDeleteIcon && "cursor__not_allowed"} `}
                      onClick={() =>
                        !(disabled || (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1))
                          ? recTestStep.recTestStepProperty?.isLoopStep === 1
                            ? dispatch(showDeleteLoopConditionModalVisible(recTestStep.tag))
                            : recTestStep.recTestStepProperty?.isStepHasPrecondition === 1
                            ? dispatch(
                                showDeleteRecTestStepPreConditionConfirmationModal(
                                  recTestStep.tag,
                                  recTestStep?.recTestStepProperty?.id
                                )
                              )
                            : ""
                          : ""
                      }
                      disabled={disabledDeleteIcon}
                    >
                      <FontAwesomeIcon
                        icon={faTrash}
                        color={
                          recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 ||
                          recTestStep.recTestStepProperty?.isLoopStep === 1
                            ? "red"
                            : ""
                        }
                        className={
                          recTestStep?.preconditionResult === REC_TEST_STEP_PREVIEW_STATUS_TYPE.PASSED
                            ? styles.marginTop
                            : ""
                        }
                      />
                    </span>
                  )}
                </Tooltip>
              </div>
              {getRecTestStepStatusWithImage(recTestStep?.preconditionResult, isPreviewRunning)}
            </div>
          }
        >
          {recTestStep?.recTestStepProperty?.isStepHasPrecondition === 1 && (
            <RecTestStepPreCondition
              recTestStep={recTestStep}
              disabled={disabled}
              isDeviceLoaded={isDeviceLoaded}
              recTestSteps={recTestSteps}
              projectId={props.projectId}
              isTestBlock={isTestBlock}
            />
          )}
          {recTestStep?.recTestStepProperty?.isLoopStep === 1 &&
            recTestStep?.recTestStepProperty?.isStepHasPrecondition === 0 && (
              <RecTestStepLoopCondition
                isSkipStep={isSkipStep}
                isTestBlock={isTestBlock}
                recTestStep={recTestStep}
                disabled={disabled}
                unSavedRecStepTag={unSavedRecStepTag}
                isDeviceLoaded={isDeviceLoaded}
                recTestSteps={recTestSteps}
                projectId={props.projectId}
              />
            )}
        </Panel>
      </Collapse>
    </div>
  ) : (
    ""
  );
};

export default RecTestStepCondition;
