import { Button, Input, Modal, Row, Space, Spin, Tooltip } from "antd";
import _ from "lodash";
import { default as React, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
} from "../../../../Constants";
import { DISABLE_COLOR, ICON_CLOSE, IMPORT_ICON, SEARCH_ICON, WHITE_COLOR } from "../../../../Constants/SvgConstants";
import {
  importSubChildSteps,
  importSubTestBlockChildSteps,
  isArrayNotUndefinedNotNullNotEmpty,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  hideImportRecTestStepModal,
  importRecTestStepOrAction,
  importStepFromJson,
  searchValueFromImportRecTestStepOrActionTree,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { fetchScenariosAndRecTestStepsByPlatform } from "../../../../actions/RecTestStepAction";
import { getListOfBlocksByProjectId } from "../../../../actions/TestBlocksAction";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import AllTestBlockStepList from "../../../TestBlocks/AllTestBlockStepList/AllTestBlockStepList";
import ImportTestStepActionsModal from "../../ScenarioHeader/ScenarioSubHeader/ImportTestStepActionsModal/ImportTestStepActionsModal";
import styles from "./ImportRecTestStepActionModal.module.scss";
import CreateStepFromJson from "../../CreateStepFromJson/CreateStepFromJson";

export const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #444aea;
    color: #fff;
  }
  .ant-btn-primary:hover {
    background-color: #444aea;
    color: #fff;
  }
  .ant-btn-primary[disabled] {
    background: #e6e6e6;
    color: #6e7282;
  }
`;

const ImportRecTestStepActionModal = (props) => {
  const {
    requestGetRecTestScenarioStepActionList,
    totalScenarioCounts,
    currentScenarioPage,
    totalScenarioItems,
    pageScenarioItems,
    importRecTestScenarioStepList,
    selectRecTestStepId,
    listOfBlocksByProjectId,
    requestingDeleteRecTestStep,
  } = useSelector((state) => state.RecTestStepReducer);
  const { testBlockListFilter, fetchingTestBlockList } = useSelector((state) => state.TestBlockReducer);
  const {
    requestingForImportRecTestStepOrAction,
    disableRecTestStepActionsForImport,
    disableRecTestStepForImport,
    importRecTestStepModalVisible,
    selectedRecTestStepOrActionForImport,
    selectedRecTestStepOrActionForImportObject,
    stepType,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();
  const [isImportOriginal, setIsImportOriginal] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [stepJsonDataForImport, setStepJsonDataForImport] = useState({});
  const [currentActionTab, setCurrentActionTab] = useState(
    stepType === TEST_SCENARIOS ? "IMPORT_FROM_SCENARIO" : "IMPORT_FROM_TEST_BLOCK_STEPS"
  );
  const disableImportTestBlock = stepType === TEST_SCENARIOS && disableRecTestStepForImport;
  const selectImportStepType = currentActionTab === "IMPORT_FROM_SCENARIO";
  const { projectId } = props;
  useEffect(() => {
    setStepJsonDataForImport({});
    setIsImportOriginal(false);
    setCurrentActionTab(stepType === TEST_SCENARIOS ? "IMPORT_FROM_SCENARIO" : "IMPORT_FROM_TEST_BLOCK_STEPS");
    if (importRecTestStepModalVisible) {
      return () => {
        setInputValue("");
      };
    }
    return () => {};
  }, [importRecTestStepModalVisible,stepType]);

  useEffect(() => {
    setInputValue("");
    if (currentActionTab === "IMPORT_FROM_SCENARIO") {
      dispatch(fetchScenariosAndRecTestStepsByPlatform(projectId));
    } else {
      dispatch(getListOfBlocksByProjectId(projectId, 1, "", testBlockListFilter, importRecTestStepModalVisible));
    }
  }, [currentActionTab, importRecTestStepModalVisible]);

  const handleImportSteps = () => {
    let requestData = [];
    if (currentActionTab == "IMPORT_FROM_JSON") {
      dispatch(importStepFromJson(stepJsonDataForImport));
    } else {
      if (currentActionTab === "IMPORT_FROM_SCENARIO") {
        importRecTestScenarioStepList.forEach((element) => {
          if (selectedRecTestStepOrActionForImport.some((i) => i === element.id)) {
            requestData.push({ recTestScenario: { id: element.id } });
          } else if (!_.isEmpty(element.recTestSteps)) {
            element.recTestSteps.forEach((j) => {
              if (selectedRecTestStepOrActionForImport.includes(j.recTestScenarioRecTestStepAssocId)) {
                requestData.push({
                  recTestScenario: { id: element.id },
                  recTestScenarioRecTestStepsAssoc: { id: j.recTestScenarioRecTestStepAssocId },
                  parentRecTestStep: { id: selectRecTestStepId },
                });
              } else if (!_.isEmpty(j.recTestSteps)) {
                importSubChildSteps(
                  element.id,
                  j.recTestScenarioRecTestStepAssocId,
                  j.recTestSteps,
                  requestData,
                  selectedRecTestStepOrActionForImport,
                  disableRecTestStepForImport
                );
              }
            });
          }
        });
      } else {
        selectedRecTestStepOrActionForImportObject.forEach((element) => {
          if (selectedRecTestStepOrActionForImport.some((i) => i === element.id)) {
            requestData.push({
              recTestStep: { id: element?.id, action: element?.action },
              project: { id: projectId },
            });
          } else if (!_.isEmpty(element.recTestSteps)) {
            element.recTestSteps.forEach((j) => {
              if (selectedRecTestStepOrActionForImport.includes(j.id)) {
                requestData.push({
                  recTestStep: { id: j?.id, action: j?.action },
                  project: { id: projectId },
                });
              } else if (!_.isEmpty(j.recTestSteps)) {
                importSubTestBlockChildSteps(
                  element.id,
                  j.id,
                  j.recTestSteps,
                  requestData,
                  selectedRecTestStepOrActionForImport,
                  projectId
                );
              }
            });
          }
        });
      }
      dispatch(importRecTestStepOrAction(isImportOriginal, requestData));
      setInputValue("");
    }
  };

  const debounce = useCallback(
    _.debounce((searchWord) => {
      if (currentActionTab === "IMPORT_FROM_SCENARIO") {
        dispatch(searchValueFromImportRecTestStepOrActionTree(searchWord, projectId, currentScenarioPage));
      } else {
        dispatch(
          searchValueFromImportRecTestStepOrActionTree(
            searchWord,
            projectId,
            listOfBlocksByProjectId?.currentPage,
            TEST_STEP_BLOCKS
          )
        );
      }
    }, 900),
    [currentActionTab]
  );

  const handleChangeValue = (e) => {
    setInputValue(e.target.value);
    debounce(e.target.value);
  };

  const callPaginationAPI = (pageNumber) => {
    selectImportStepType
      ? dispatch(fetchScenariosAndRecTestStepsByPlatform(projectId, inputValue, pageNumber))
      : dispatch(
          getListOfBlocksByProjectId(
            projectId,
            pageNumber,
            inputValue,
            testBlockListFilter,
            importRecTestStepModalVisible
          )
        );
  };

  let currentPageItems = selectImportStepType ? pageScenarioItems : listOfBlocksByProjectId?.pageItems;
  let totalItems = selectImportStepType ? totalScenarioItems : listOfBlocksByProjectId?.totalItems;
  let currentPage = selectImportStepType ? currentScenarioPage : listOfBlocksByProjectId?.currentPage;
  let totalPage = selectImportStepType ? totalScenarioCounts : listOfBlocksByProjectId?.totalCount;

  const closeImportModal = () => {
    dispatch(hideImportRecTestStepModal());
    setCurrentActionTab(stepType === TEST_SCENARIOS ? "IMPORT_FROM_SCENARIO" : "IMPORT_FROM_TEST_BLOCK_STEPS");
    dispatch({ type: REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION });
    setInputValue("");
  };

  let spinner = selectImportStepType
    ? !isArrayNotUndefinedNotNullNotEmpty(importRecTestScenarioStepList)
    : _.isEmpty(listOfBlocksByProjectId?.data);
  return (
    <Modal
      width="70%"
      title={
        <div className={styles.modalTitleDiv}>
          <div className={styles.modalTitleDivHeader}>
            <span className={styles.modalTitle}>
              {!disableRecTestStepForImport ? "Import Recorded steps group" : "Import Recorded Actions/verifications"}
            </span>
            <div className={`${styles.importTitleDiv} ${stepType === TEST_STEP_BLOCKS && styles.importHeader}`}>
              {stepType === TEST_SCENARIOS && (
                <div className={styles.title}>
                  <div
                    onClick={() => setCurrentActionTab("IMPORT_FROM_SCENARIO")}
                    className={`${styles.text} ${selectImportStepType && styles.selectedStepType}`}
                  >
                    Import From Scenario
                  </div>
                </div>
              )}
              <Tooltip
                title={
                  stepType === TEST_SCENARIOS && "Individual actions/verifications cannot be imported from a Test Block"
                }
                color={TOOLTIP_BG_COLOR}
              >
                <div className={styles.title}>
                  <div
                    onClick={() => !disableImportTestBlock && setCurrentActionTab("IMPORT_FROM_TEST_BLOCK_STEPS")}
                    className={`${styles.text} ${
                      currentActionTab === "IMPORT_FROM_TEST_BLOCK_STEPS" && styles.selectedStepType
                    } ${disableImportTestBlock && "cursor__not_allowed"}`}
                  >
                    Add Test Blocks
                  </div>
                </div>
              </Tooltip>
              <Tooltip title={"Import from JSON"} color={TOOLTIP_BG_COLOR}>
                <div className={styles.title}>
                  <div
                    onClick={() => setCurrentActionTab("IMPORT_FROM_JSON")}
                    className={`${styles.text} ${currentActionTab === "IMPORT_FROM_JSON" && styles.selectedStepType} ${
                      disableImportTestBlock && "cursor__not_allowed"
                    }`}
                  >
                    Import from JSON
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
          <div className={styles.searchBoxDiv}>
            <Input
              placeHolder={selectImportStepType ? "Search Scenario" : " Search TestBlockSteps"}
              value={inputValue}
              onChange={handleChangeValue}
              allowClear
              prefix={<SvgLoader iconName={SEARCH_ICON} />}
              className={`${styles.searchBox} searchScenarioOrTestBlockForImport`}
              autoFocus
            />
          </div>
        </div>
      }
      visible={importRecTestStepModalVisible}
      closable={false}
      onCancel={() => closeImportModal()}
      footer={[
        <div className={`${styles.modalFooter} ${!spinner && styles.footerButtons}`}>
          {!spinner && (
            <div>
              <PaginationComponent
                currentPageItems={currentPageItems}
                totalItems={totalItems}
                currentPage={currentPage}
                totalPage={totalPage}
                callPaginationAPI={callPaginationAPI}
                importModal
              />
            </div>
          )}
          <Space size={10}>
            <Button
              type="primary"
              className={styles.cancelBtn}
              onClick={() => {
                dispatch(hideImportRecTestStepModal());
                setCurrentActionTab(
                  stepType === TEST_SCENARIOS ? "IMPORT_FROM_SCENARIO" : "IMPORT_FROM_TEST_BLOCK_STEPS"
                );
                dispatch({ type: REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION });
                setInputValue("");
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              onClick={() => handleImportSteps()}
              loading={requestingForImportRecTestStepOrAction}
              className={styles.importBtn}
              disabled={currentActionTab != "IMPORT_FROM_JSON" && _.isEmpty(selectedRecTestStepOrActionForImport)}
            >
              <SvgLoader
                iconName={IMPORT_ICON}
                iconColor={
                  currentActionTab != "IMPORT_FROM_JSON" && _.isEmpty(selectedRecTestStepOrActionForImport)
                    ? DISABLE_COLOR
                    : WHITE_COLOR
                }
              />
              <span>Import</span>
            </Button>
          </Space>
        </div>,
      ]}
      destroyOnClose
      className="importStepsOrActionsModal"
    >
      <Row className={styles.modalDetails}>
        {currentActionTab != "IMPORT_FROM_JSON" ? (
          selectImportStepType ? (
            <div className={styles.width}>
              <Spin spinning={requestGetRecTestScenarioStepActionList} className={styles.spinning_center}>
                {!_.isEmpty(importRecTestScenarioStepList) ? (
                  importRecTestScenarioStepList.length > 0 &&
                  importRecTestScenarioStepList.map((recTestStep, index) => (
                    <ImportTestStepActionsModal
                      recTestStep={recTestStep}
                      key={index}
                      index={index}
                      projectId={projectId}
                      stepType={stepType}
                      recTestStepList={importRecTestScenarioStepList}
                      currentActionTab={currentActionTab}
                      name={recTestStep.name}
                      disableRecTestStepActionsForImport={disableRecTestStepActionsForImport}
                      disableRecTestStepForImport={disableRecTestStepForImport}
                    />
                  ))
                ) : (
                  <div className={styles.emptyRecords}>
                    <EmptyRecords description="No Recorded Scenario" ignoreMargin={true} />
                  </div>
                )}
              </Spin>
            </div>
          ) : (
            <div className={styles.width}>
              <Spin
                spinning={fetchingTestBlockList || requestingDeleteRecTestStep}
                tip="Loading"
                className={styles.spinning_center}
              >
                {!_.isEmpty(listOfBlocksByProjectId?.data) ? (
                  <AllTestBlockStepList
                    projectId={props.projectId}
                    isTestStepBlocksLoadModel={true}
                    isTestStepBlocksForImport={true}
                    currentActionTab={currentActionTab}
                  />
                ) : (
                  !fetchingTestBlockList && (
                    <div className={styles.emptyRecords}>
                      <EmptyRecords description="No Recorded Blocks" />
                    </div>
                  )
                )}
              </Spin>
            </div>
          )
        ) : (
          <div className={styles.width}>
            <CreateStepFromJson jsonData={stepJsonDataForImport} onChange={setStepJsonDataForImport} />
          </div>
        )}
      </Row>
    </Modal>
  );
};

export default ImportRecTestStepActionModal;
