import { CHANGE_DEVICE_STATUS, GET_ALL_DEVICES, GET_ORG_DEVICE_LIST, ORG_DEVICE_LOADING, POST_ASSIGN_DEVICE } from "../Constants";

let defaultState = {
    getOrgDeviceList: null,
    currentOrgDevicePage: 1,
    totalOrgDevicePage: 0,
    postAssignDeviceRes: null,
    getAllDevice: null,
    getDeviceStatusRes: null,
    isLoading: false,
};

export default function OrgDeviceReducer(state = defaultState, action) {
    let response = undefined;
    switch (action.type) {
        case GET_ORG_DEVICE_LIST:
            response = action.response;
            return {
                ...state,
                getOrgDeviceList: response.data.data,
                currentOrgDevicePage: response.data.currentPage,
                totalOrgDevicePage: response.data.totalCount,
                totalDevices:response?.data?.totalItems,
                totalDeviceListPage:response.data.totalCount
            };

        case POST_ASSIGN_DEVICE:
            response = action.response;
            return {
                ...state,
                postAssignDeviceRes: response
            };

        case ORG_DEVICE_LOADING:
            return {
                ...state,
                isLoading: !state.isLoading
            };

        case GET_ALL_DEVICES:
            response = action.response;
            return {
                ...state,
                getAllDevice: response.data
            };

        case CHANGE_DEVICE_STATUS:
            response = action.response;
            return {
                ...state,
                getDeviceStatusRes: response.data
            };

        default:
            return state;
    }
}