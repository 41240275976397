import { Fragment, default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Collapse, Popover, Row, Spin } from "antd";
import { isEmpty } from "lodash";
import styled from "styled-components";
import { RUN_RESULT_STATUS, TEST_PLAN_RESULT_TYPE, defaultDateTimeFormat } from "../../../Constants";
import {
  addAdditionalStepDetail,
  addTimes,
  fetchParentSteps,
  getDateTimeInFormat,
  getDateTimeInUTCFormat,
  getStepResultById,
  isNotNullAndNotEmptyAndNotUndefined,
  leftBorderColor,
  navigateToTestRail,
} from "../../../Util";
import { getTestPlanStepsList, setRunResultScenarioId, testPlanStepsListId } from "../../../actions/TestPlanAction";
import DeviceHoverCard from "../../CommonComponents/DeviceHoverCard/DeviceHoverCard";
import EmptyRecords from "../../CommonComponents/EmptyRecords/EmptyRecords";
import ErrorInvestigationModal from "../../ErrorInvestigation/ErrorInvestigationModal";
import ExecutionProfileDetailsModal from "../../TestPlan/TestPlanResultDetails/ExecutionProfileDetails/ExecutionProfileDetailsModal";
import ExternalFarmTestResultLink from "../../TestPlan/TestPlanResultDetails/ExternalFarmTestResultLink";
import {
  DeviceCardHeader,
  DeviceCardTitle,
  StyledCol,
} from "../../TestPlan/TestPlanResultDetails/TestPlanResultDetailsStyled";
import TestPlanResultScenarioEvidence from "../../TestPlan/TestPlanResultDetails/TestPlanResultScenarioEvidence";
import ParentTestPlanResultDetailsStepV2 from "./ParentTestPlanResultDetailsStepV2";
import TestPlanResultDetailStepListV2 from "./TestPlanResultDetailStepListV2";
const { Panel } = Collapse;

const ScenarioDataRow = styled(Row)`
  display: flex;
  border-radius: 5px;
  width: 100%;
  color: "#65676b";
  background-color: "#338fcc";
`;

export const TestPlanScenarioHeader = styled.div`
  display: flex;
  border-radius: 5px;
  margin-left: 10px;
  color: "#65676b";
  background-color: "#338fcc";
`;

export const StyledScenarioDataCol = styled.div`
  width: 80%;
  height: auto;
`;

export const StyledScenarioDataIndexCol = styled.div`
  width: 3%;
  height: auto;
`;

export const StyledDiv = styled.span`
  margin-left: 60px;
  height: auto;
`;

export const getTimeTitle = (title, duration, startTime, endTime) => {
  return duration && (startTime || endTime) ? (
    <Popover
      placement="bottom"
      title={
        <DeviceCardHeader>
          <DeviceCardTitle>{title}</DeviceCardTitle>
        </DeviceCardHeader>
      }
      content={
        <Row gutter={[16, 16]}>
          <StyledCol>
            <Col className="p-0" span={12}>
              Start Time:
            </Col>
            {getDateTimeInFormat(startTime)} ({getDateTimeInUTCFormat(startTime, defaultDateTimeFormat)} GMT)
          </StyledCol>
          <Row className="w-100 pl-10 font-size-12">
            <Col className="p-0" span={12}>
              End Time:
            </Col>
            {getDateTimeInFormat(endTime)} ({getDateTimeInUTCFormat(endTime, defaultDateTimeFormat)} GMT)
          </Row>
        </Row>
      }
    >
      <span className="font-weight-500 ml-2">{duration || "0 secs"}</span>
    </Popover>
  ) : (
    <span className="font-weight-500 ml-2">{duration || "0 secs"}</span>
  );
};
export const highlightResult = (
  resultString,
  setOpenErrorInvestigationModal,
  testStepId,
  setFailedTestStepId,
  testExecutionErrorInvestigationDetails,
  data
) => {
  let result = "";
  if (resultString === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    result = (
      <span>
        {" "}
        <Spin size="small" className="mr-5" />{" "}
        <span className="badge bg-info p-5 font-weight-900"> {TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT}</span>
      </span>
    );
  } else if (resultString === "Passed") {
    result = <span className="badge bg-success p-5 font-weight-900">{resultString}</span>;
  } else if (resultString === "TERMINATED") {
    result = <span className="badge bg-abort p-5 font-weight-900">{resultString}</span>;
  } else if (resultString === "QUEUED_WAITING_FOR_DEVICE_AVAILABILITY") {
    result = (
      <span className="badge bg-secondary p-5 font-weight-900">
        {TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
      </span>
    );
  } else if (resultString === "Failed") {
    result = (
      <div>
        <div className="badge bg-danger p-5 font-weight-700 m-5">{resultString}</div>
        {!data && (
          <div className="m-5">
            <Button
              className="investigation-button bg-info pull-right"
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                setOpenErrorInvestigationModal(true);
                setFailedTestStepId(testStepId);
              }}
            >
              {isNotNullAndNotEmptyAndNotUndefined(testExecutionErrorInvestigationDetails)
                ? testExecutionErrorInvestigationDetails?.failureReason
                : "Needs investigation"}
            </Button>
          </div>
        )}
      </div>
    );
  } else if (resultString === RUN_RESULT_STATUS.WAITING_FOR_DEVICE_TO_EXECUTED) {
    return (
      <span className="badge bg-secondary p-5 font-weight-900">
        {TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED_TXT}
      </span>
    );
  } else if (resultString === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT) {
    return <span className="badge bg-uploading p-5 font-weight-900">{TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT_TXT}</span>;
  } else if (resultString === RUN_RESULT_STATUS.ABORTED) {
    return <span className="badge bg-abort p-5 font-weight-900">{TEST_PLAN_RESULT_TYPE.ABORTED}</span>;
  } else {
    result = <span className="default-result-text">{resultString}</span>;
  }
  return result;
};

export const durationTime = (durationTime) => {
  let time = "";
  if (durationTime && durationTime.length > 0) {
    time += durationTime[0] !== "00" && durationTime[0] !== "-00" ? durationTime[0] + " Hours " : "";
    time += durationTime[1] !== "00" && durationTime[0] !== "-00" ? durationTime[1] + " Minutes " : "";
    time += durationTime[2] !== "00" && durationTime[0] !== "-00" ? durationTime[2] + " Seconds  " : "";
  }
  return time;
};

export const isShowDuration = (durationTime) => {
  if (durationTime && durationTime.length > 0) {
    return (
      (durationTime[0] !== "00" && durationTime[0] !== "-00") || durationTime[1] !== "00" || durationTime[2] !== "00"
    );
  }
};

const isTestRailData = (data) => {
  return (
    isNotNullAndNotEmptyAndNotUndefined(data.testRailTestCaseLabel) &&
    isNotNullAndNotEmptyAndNotUndefined(data.testRailTestCaseUrl)
  );
};

const TestPlanResultScenarioDetailsV2 = (props) => {
  const { scenariosList, runResultDetails, isTestBlock,runNumber} = props;
  const dispatch = useDispatch();
  const [openErrorInvestigationModal, setOpenErrorInvestigationModal] = useState(false);
  const [failedTestStepId, setFailedTestStepId] = useState(null);
  const [ActiveScenario, setActiveScenario] = useState(null);
  const [activeScenarioId, setActiveScenarioId] = useState([]);
  const [displayExecutionProfile, setDisplayExecutionProfile] = useState(false);
  const [currentExecutionProfile, setCurrentExecutionProfile] = useState({ id: 0, name: "" });
  const [currentActiveScenarioId, setCurrentActiveScenarioId] = useState(null);

  const { testPlanResultStepsDetails, testStepResults } = useSelector(
    (state) => state.TestPlanReducer
  );
  const getTestStepsDetailsOnClick = (data, k) => {
    const scenarioId = data?.scenarioId;
    dispatch(setRunResultScenarioId(scenarioId));
    let scenarioIds = [...activeScenarioId];
    if (scenarioIds.includes(scenarioId)) {
      const dId = scenarioIds.indexOf(scenarioId);
      scenarioIds.splice(dId, 1);
    } else {
      scenarioIds.push(scenarioId);
    }
    if (
      data?.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ||
      data?.state === TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT ||
      data?.state === TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED
    ) {
      setActiveScenarioId(scenarioIds);
    }
    if (k !== ActiveScenario) {
      dispatch(getTestPlanStepsList({ scenarioId: scenarioId },()=>{},"",true));
      dispatch(testPlanStepsListId(scenarioId));
      setActiveScenario(k);
      setCurrentActiveScenarioId(scenarioId)
    } else {
      setActiveScenario(null);
      setCurrentActiveScenarioId(null)
    }
  };

  const getScenarioRow = (data, index) => {
    const duration = data && data.duration ? data.duration.split(":") : "";
    const totalDuration = addTimes(addTimes(data.waitDuration, data.duration), data?.reportProcessingDuration);
    const runId = runResultDetails?.find((i) => i)?.runId;
    const parentSteps =
      isTestBlock === 0
        ? fetchParentSteps(testPlanResultStepsDetails, data?.scenarioId)
        : fetchParentSteps(testStepResults, runId);
    return (
      <Collapse
        className={leftBorderColor(data.result)}
        key={index}
        accordion
        ghost
        onChange={(e) => getTestStepsDetailsOnClick(data, index)}
        expandIconPosition="right"
      >
        <Panel
          className="mt-10"
          showArrow={true}
          header={
            <ScenarioDataRow>
              <Col span={1}>{index + 1}</Col>
              <Col span={15} className="font-weight-700">
                Scenario:<span className="font-weight-500 ml-2">{data.name}</span>
                <div>
                  {data.deviceName &&
                  data.deviceName.length > 0 &&
                  data.state &&
                  data.state === TEST_PLAN_RESULT_TYPE.IN_PROGRESS ? (
                    <span className="font-weight-700">Executing on: </span>
                  ) : (
                    data.deviceName &&
                    data.deviceName.length > 0 && <span className="font-weight700">Executed On: </span>
                  )}
                  <DeviceHoverCard
                    deviceDetails={{
                      ...data,
                      brandName: data?.deviceBrandName,
                      name: data?.deviceName,
                      platformName: data?.platform,
                    }}
                  />
                </div>
                <div>
                  Total Steps:<span className="font-weight-500 ml-2">{data.totalSteps ? data.totalSteps : 0}</span>
                </div>
                <div>
                  Screenshots Captured:
                  <span className="font-weight-500 ml-2">
                    {data.totalCapturedScreenShotCounts ? data.totalCapturedScreenShotCounts : 0} of{" "}
                  </span>
                  <span className="font-weight-500 ml-2">
                    {data.totalExpectedScreenShotCounts ? data.totalExpectedScreenShotCounts : 0}
                  </span>
                </div>
                {data?.state !== TEST_PLAN_RESULT_TYPE.IN_PROGRESS && (
                  <>
                    <span className="font-weight-700">Total Duration:</span>
                    {getTimeTitle(
                      "Scenario Execution Details",
                      totalDuration && durationTime(totalDuration.split(":")),
                      undefined,
                      undefined
                    )}
                    (<span className="font-weight-500">Waiting for Device Availability:</span>
                    {isShowDuration(duration) &&
                      getTimeTitle(
                        "Waiting for Device Availability",
                        durationTime(data?.waitDuration?.split(":")),
                        undefined,
                        undefined
                      )}
                    ,<span className="font-weight-500 ml-5">Execution:</span>
                    {isShowDuration(duration) &&
                      getTimeTitle(
                        "Scenario Execution Timing Details",
                        durationTime(duration),
                        data?.startTime,
                        data?.endTime
                      )}
                    ,<span className="font-weight-500 ml-5">Result Processing:</span>
                    {getTimeTitle(
                      "Report Processing Details",
                      data?.reportProcessingDuration && durationTime(data.reportProcessingDuration.split(":")),
                      data?.awsUploadStartTime,
                      data?.awsUploadEndTime
                    )}
                    )
                  </>
                )}
                <br />
                <div className="display-flex align-items-center">
                  Test Data Profile:
                  <span className="font-weight-700">
                    <Button
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setCurrentExecutionProfile({
                          ...currentExecutionProfile,
                          name: data?.executionTestDataSetName,
                          id: data?.executionTestDataSetId,
                        });
                        setDisplayExecutionProfile(true);
                      }}
                      type="link"
                      block
                    >
                      {data?.executionTestDataSetName}
                    </Button>
                  </span>
                </div>
                <div>
                  <span className="font-weight-700 mr-2">TestRail Case:</span>
                  {isTestRailData(data) ? (
                    data.testRailTestCaseLabel.split(",").map((label, i) => {
                      return (
                        <span
                          className="font-color-blue cursor-pointer text-decoration-underline mr-5"
                          onClick={(e) => {
                            navigateToTestRail(e, data, i);
                          }}
                        >
                          {label}
                        </span>
                      );
                    })
                  ) : (
                    <span className="font-weight-500 ml-2">0</span>
                  )}
                </div>
                {data?.externalFarmTestCaseUrl && (
                  <ExternalFarmTestResultLink data={data} runResultDetails={runResultDetails} />
                )}
              </Col>
              <Col span={8} className="text-right">
                {data.state
                  ? highlightResult(
                      data.state !== "UPLOADING_RESULT" && data.result ? data.result : data.state,
                      setOpenErrorInvestigationModal,
                      data.failedTestStepId,
                      setFailedTestStepId,
                      data?.testExecutionErrorInvestigationDetails
                    )
                  : "N.A."}
              </Col>
            </ScenarioDataRow>
          }
        >
          <ParentTestPlanResultDetailsStepV2
            parentSteps={parentSteps}
            data={data}
            isTestBlock={isTestBlock}
            activeScenarioId={activeScenarioId}
            runResultDetails={runResultDetails}
            setCurrentActiveScenarioId={setCurrentActiveScenarioId}
            currentActiveScenarioId={currentActiveScenarioId}
          />

          {isTestBlock === 0 && (
            <div className="mt-10">
              <TestPlanResultScenarioEvidence scenarioData={data} />
            </div>
          )}
        </Panel>
      </Collapse>
    );
  };

  return (
    <Fragment>
      {scenariosList
        ? scenariosList?.map((v, k) => {
            return getScenarioRow(v, k);
          })
        : null}
      {displayExecutionProfile && (
        <ExecutionProfileDetailsModal
          setDisplayExecutionProfile={setDisplayExecutionProfile}
          displayExecutionProfile={displayExecutionProfile}
          currentExecutionProfile={currentExecutionProfile}
        />
      )}
      {openErrorInvestigationModal && (
        <ErrorInvestigationModal
          openErrorInvestigationModal={openErrorInvestigationModal}
          setOpenErrorInvestigationModal={setOpenErrorInvestigationModal}
          failedTestStepId={failedTestStepId}
          projectId={runResultDetails[0]?.projectId}
          orgId={runResultDetails[0]?.organizationId}
          runNumber={runNumber}
        />
      )}
    </Fragment>
  );
};

export default TestPlanResultScenarioDetailsV2;
