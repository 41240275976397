import { Button, Col, Row, Select, Spin } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { DEVICE_EXECUTION_FARM } from "../../Constants";
import { disableValidateCredential, isResetDisableForCloudTesting } from "../../Util";
import {
  getExternalFarmConfig,
  getExternalFarmDataCenterList,
  handleDisableButton,
  setExternalFarmValidation,
  updateExternalFarmDetail,
  validateExternalFarmCredentials,
} from "../../actions/ProjectSettingsActions";
import ErrorComponent from "../CoreComponents/ErrorComponent/ErrorComponent";
import SaveResetButton from "../CoreComponents/SaveResetComponent/SaveResetButton";
import { Label, Required, StyledInput } from "../CoreComponents/StyledComponent/StyledComponents";
export const ButtonContainer = styled.div`
  .ant-btn-primary {
    background: #d3d4de;
    color: #111548;
  }
  .ant-btn-primary:hover {
    background: #d3d4de;
    color: #111548;
  }
  .ant-btn-primary[disabled] {
    color: rgb(0 0 0 / 58%);
    background: #9e9e9e57;
  }
  .ant-btn-primary[disabled]:hover {
    color: rgb(0 0 0 / 58%);
    background: #9e9e9e57;
  }
`;
const initialSauceLabsDetails = {
  userName: "",
  accessKey: "",
  dataCenter: "",
};
const { Option } = Select;

const SauceLabs = (props) => {
  const { projectId } = props;
  const dispatch = useDispatch();
  const {
    externalFarmDataCenterList,
    projectLoading,
    externalFarmDetails,
    externalFarmLoading,
    isDisabled,
    externalFarmName,
    isValidateExternalFarmCredentials,
  } = useSelector((state) => state.ProjectSettingsReducer);
  const [sauceLabsDetails, setSauceLabsDetails] = useState(initialSauceLabsDetails);

  const [error, setError] = useState(false);

  useEffect(() => {
    dispatch(getExternalFarmDataCenterList(DEVICE_EXECUTION_FARM.SAUCELABS));
    dispatch(getExternalFarmConfig(projectId, DEVICE_EXECUTION_FARM.SAUCELABS));
    setError(false);
  }, [projectId]);

  useEffect(() => {
    if (!_.isEmpty(externalFarmDetails) && externalFarmName === DEVICE_EXECUTION_FARM.SAUCELABS) {
      setSauceLabsDetails({
        userName: externalFarmDetails.userName,
        accessKey: externalFarmDetails.accessKey,
        dataCenter: externalFarmDetails.dataCenter,
      });
      dispatch(handleDisableButton(true));
    }
  }, [externalFarmDetails]);

  useEffect(() => {
    if (isValidateExternalFarmCredentials) {
      dispatch(handleDisableButton(false));
    } else {
      dispatch(handleDisableButton(true));
    }
  }, [isValidateExternalFarmCredentials]);

  useEffect(() => {
    if (projectLoading) {
      dispatch(handleDisableButton(true));
    }
  }, [projectLoading]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSauceLabsDetails({ ...sauceLabsDetails, [name]: value });
    dispatch(setExternalFarmValidation(false));
    setError(false);
  };

  const handleDataCenterChange = (e) => {
    setSauceLabsDetails({ ...sauceLabsDetails, dataCenter: e });
    setError(false);
  };

  let dataCenterList = [];
  if (!_.isEmpty(externalFarmDataCenterList)) {
    externalFarmDataCenterList?.map((data) => {
      dataCenterList.push(<Option key={data?.value}>{data?.key}</Option>);
    });

    const saveSauceLabsDetail = () => {
      if (sauceLabsDetails && isValidateExternalFarmCredentials) {
        dispatch(
          updateExternalFarmDetail({
            ...sauceLabsDetails,
            id: externalFarmDetails ? externalFarmDetails.id : 0,
            project: { id: parseInt(projectId) },
            farmName: DEVICE_EXECUTION_FARM.SAUCELABS,
          })
        );
        dispatch(handleDisableButton(true));
      }
    };

    const resetSauceLabsDetails = () => {
      if (!_.isEmpty(externalFarmDetails)) {
        setSauceLabsDetails({
          userName: externalFarmDetails.userName,
          accessKey: externalFarmDetails.accessKey,
          dataCenter: externalFarmDetails.dataCenter,
        });
      } else {
        setSauceLabsDetails(initialSauceLabsDetails);
      }
      dispatch(setExternalFarmValidation(false));
      setError(false);
      dispatch(handleDisableButton(true));
    };

    const validateCredentials = () => {
      dispatch(
        validateExternalFarmCredentials(
          sauceLabsDetails.userName,
          sauceLabsDetails.accessKey,
          sauceLabsDetails.dataCenter,
          DEVICE_EXECUTION_FARM.SAUCELABS,
          setError
        )
      );
    };

    return (
      <Spin spinning={projectLoading || externalFarmLoading} tip="Loading">
        <Row justify="space-between" className="innerHeader p-12">
          <div className="mainHeaderText ml-10">SauceLabs</div>
          <SaveResetButton
            submitLabel={!_.isEmpty(sauceLabsDetails) ? "Update" : "Save"}
            resetLabel={"Reset"}
            submitDisable={isDisabled}
            resetDisable={isResetDisableForCloudTesting(sauceLabsDetails, externalFarmDetails)}
            handleSubmit={saveSauceLabsDetail}
            handleReset={resetSauceLabsDetails}
          />
        </Row>
        <Row justify="center">
          <Col xs={{ span: 24 }} lg={{ span: 14 }}>
            <Row className="mt-30 mb-30" gutter={50}>
              <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
                <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Basic Details</h3>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
                <Label>Username</Label>
                <Required>*</Required>
                <StyledInput
                  className="mb-20 mt-5"
                  name="userName"
                  placeholder="Please Enter Username"
                  value={sauceLabsDetails.userName}
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  autoFocus
                />
                <Label>Access Key</Label>
                <Required>*</Required>
                <StyledInput
                  className="mb-20 mt-5"
                  name="accessKey"
                  value={sauceLabsDetails.accessKey}
                  placeholder="Please Enter Access key"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                />
                <Label>Data Center</Label>
                <Required>*</Required>
                <Select
                  className="w-100 h-40 mt-5"
                  placeholder="Select Data Center"
                  onChange={(e) => handleDataCenterChange(e)}
                  value={sauceLabsDetails.dataCenter || undefined}
                >
                  {dataCenterList}
                </Select>
                <ButtonContainer>
                  <Button
                    type="primary"
                    className="mt-10"
                    onClick={() => validateCredentials()}
                    disabled={disableValidateCredential(sauceLabsDetails, externalFarmDetails)}
                  >
                    Validate credentials
                  </Button>
                </ButtonContainer>

                {error && (
                  <div className="mt-10">
                    <ErrorComponent errorMessage="Please check credentials are invalid." />
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Spin>
    );
  }
};

export default SauceLabs;
