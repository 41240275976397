import _ from "lodash";
import { Popover, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faYandexInternational } from "@fortawesome/free-brands-svg-icons";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getTestStepsDataApplicationString,
  getStepDataInfoForApplicationString,
} from "../../actions/TestDataSet/TestDataSetRedirectAction";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";
import styled from "styled-components";
import SvgLoader from "../../Util/SvgLoader";
import { ELEMENT_ACTION, PRE_CONDITION, STEP, SWIPE_OVER_ELEMENT, VERIFICATION, VERIFICATIONS } from "../../Constants";
import {
  ICON_ADD_VERIFICATION,
  ICON_CLEAR_TEXT_ACTION,
  ICON_ENTER_TEXT_ACTION,
  ICON_GENERAL_ACTION,
  ICON_LONG_PRESS_ACTION,
  ICON_SCREENSHOT_ELEMENT_ACTION,
  ICON_SWIPE_ELEMENT_ACTION,
  ICON_TAP_ACTION,
  ICON_TAP_BY_INDEX_ACTION,
} from "../../Constants/SvgConstants";
import {
  CLEAR_TEXT,
  ENTER_TEXT,
  LONG_PRESS,
  SCREENSHOT_ELEMENT,
  SWIPE_ELEMENT,
  TAP,
  TAP_BY_INDEX,
} from "../../Constants/TooltipConstants";
import styles from "../CommonComponents/TestStepsData/TestStepsData.module.scss";

const ApplicationStringList = (props) => {
  const { selectedStepId } = props;
  const { testStepsDataLoading, getApplicationStringsData, applicationStringInfo, testDataBaseListLoading } =
    useSelector((state) => state.TestDataSetReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTestStepsDataApplicationString(selectedStepId));
  }, [selectedStepId, dispatch]);
  const [selectedKey, setSelectedKey] = useState(0);

  const columns = [
    {
      title: " ",
      dataIndex: "index",
      width: 20,
    },
    {
      title: "Key",
      dataIndex: "key",
      width: 280,
    },
    {
      title: "Locale Values",
      dataIndex: "value",
      width: 280,
    },
    {
      title: "Used In",
      dataIndex: "usedIn",
    },
    {
      title: "Attached to Elements",
      dataIndex: "elementImages",
    },
  ];

  const collapseColumns = [
    {
      title: "No.",
      dataIndex: "stepNo",
      width: 350,
    },
    {
      title: "Step",
      dataIndex: "pathToTestData",
      width: 350,
    },
    {
      title: "Attached to Elements",
      dataIndex: "elementImages",
    },
  ];

  const StyledDiv = styled.div`
    display: flex;
    gap: 10px;
    overflow-x: ${(props) => (props.flag ? "scroll" : "hidden")};
    width: 10rem;
    justify-content: center;
  `;

  const StyledActionIcon = styled.div`
    display: flex;
    align-items: center;
  `;

  const displayImages = (listOfImages, flag) => {
    return (
      <StyledDiv flag={flag}>
        {listOfImages.map((value, key) => (
          <div key={value}>
            <Popover
              key={value?.key}
              content={<img src={value} height={450} weight={225} alt="Screenshot" />}
              placement="left"
              title="Element Image"
              trigger="hover"
            >
              <img className={styles.elementImage} src={value} height={50} weight={50} alt="Screenshot" />
            </Popover>
          </div>
        ))}
      </StyledDiv>
    );
  };

  const getStepActionIcon = (row) => {
    const icon = row.stepAction;
    let icon_symbol = ICON_GENERAL_ACTION;

    if (row.isStepHasPrecondition == 1) {
      return (
        <span className="mr-5 font-color-orange">
          <FontAwesomeIcon icon={faYandexInternational} />
        </span>
      );
    }

    if (row.isLoopStep == 1) {
      return (
        <span className="mr-5 font-color-orange">
          <FontAwesomeIcon icon={faReply} />
        </span>
      );
    }

    switch (icon) {
      case ELEMENT_ACTION.TAP:
        icon_symbol = ICON_TAP_ACTION;
        break;
      case ELEMENT_ACTION.TAP_BY_INDEX:
        icon_symbol = ICON_TAP_BY_INDEX_ACTION;
        break;
      case ELEMENT_ACTION.LONG_PRESS:
        icon_symbol = ICON_LONG_PRESS_ACTION;
        break;
      case ELEMENT_ACTION.SEND_KEYS:
        icon_symbol = ICON_ENTER_TEXT_ACTION;
        break;
      case ELEMENT_ACTION.CLEAR_TEXT:
        icon_symbol = ICON_CLEAR_TEXT_ACTION;
        break;
      case SWIPE_OVER_ELEMENT:
        icon_symbol = ICON_SWIPE_ELEMENT_ACTION;
        break;
      case ELEMENT_ACTION.SCREENSHOT_ELEMENT:
        icon_symbol = ICON_SCREENSHOT_ELEMENT_ACTION;
        break;
      case VERIFICATIONS:
      case STEP:
        icon_symbol = ICON_ADD_VERIFICATION;
        break;
      default:
        break;
    }
    return <SvgLoader iconName={icon_symbol} />;
  };

  const getStepActionIconTooltip = (row) => {
    const icon = row.stepAction;
    let icon_tooltip = "General Action";

    if (row.isStepHasPrecondition == 1) {
      return PRE_CONDITION;
    }

    if (row.isLoopStep == 1) {
      return "loop";
    }

    switch (icon) {
      case ELEMENT_ACTION.TAP:
        icon_tooltip = TAP;
        break;
      case ELEMENT_ACTION.TAP_BY_INDEX:
        icon_tooltip = TAP_BY_INDEX;
        break;
      case ELEMENT_ACTION.LONG_PRESS:
        icon_tooltip = LONG_PRESS;
        break;
      case ELEMENT_ACTION.SEND_KEYS:
        icon_tooltip = ENTER_TEXT;
        break;
      case ELEMENT_ACTION.CLEAR_TEXT:
        icon_tooltip = CLEAR_TEXT;
        break;
      case SWIPE_OVER_ELEMENT:
        icon_tooltip = SWIPE_ELEMENT;
        break;
      case ELEMENT_ACTION.SCREENSHOT_ELEMENT:
        icon_tooltip = SCREENSHOT_ELEMENT;
        break;
      case VERIFICATIONS:
      case STEP:
        icon_tooltip = VERIFICATION;
        break;
      default:
        break;
    }
    return icon_tooltip;
  };

  const buildCollapse = (applicationStringInfo) => {
    return (
      !_.isEmpty(applicationStringInfo) &&
      applicationStringInfo.map((row, index) => ({
        index: index + 1,
        pathToTestData: (
          <StyledActionIcon>
            <Popover content={<span>{getStepActionIconTooltip(row)}</span>}>
              <div>{getStepActionIcon(row)}</div>
            </Popover>
            <Popover
              content={
                <span>
                  {row.stepOrderPath} {row.pathToTestData}
                </span>
              }
              title={"Step Path"}
            >
              <div className={styles.stepName}>{row.stepName}</div>
            </Popover>
          </StyledActionIcon>
        ),
        stepNo: index + 1,
        elementImages: (
          <div>
            {row?.elementImages && !_.isEmpty(row.elementImages) ? displayImages(row.elementImages, true) : "-"}
          </div>
        ),
        useFor: <div>Action: {row?.useFor}</div>,
        overrideDefault: row?.overrideDefault,
      }))
    );
  };

  const testStepsData = (data) => {
    return (
      !_.isEmpty(data) &&
      data?.map((row, index) => ({
        index: index + 1,
        key: row?.key,
        id: row?.id,
        value: row?.value ? (
          row?.values ? (
            <Popover
              key={row?.key}
              title="Locales"
              content={row?.values.map((k, i) => (
                <div key={row?.key}>
                  <span style={{ marginRight: 5 }}>{i + 1}</span>
                  {k}
                  <br></br>
                </div>
              ))}
            >
              {row?.value}
            </Popover>
          ) : (
            row?.value
          )
        ) : (
          "-"
        ),
        usedIn: `${row?.usedIn} ${row?.usedIn > 1 ? "Steps" : "Step"}`,
        elementImages:
          row?.elementImages && !_.isEmpty(row.elementImages)
            ? displayImages(row.elementImages.length > 3 ? row.elementImages.slice(0, 3) : row.elementImages, false)
            : "-",
      }))
    );
  };

  const expandedRowRender = (record, e) => {
    return (
      <>
        <Spin spinning={testDataBaseListLoading && record?.id === selectedKey} tip="Loading">
          <TableComponent columns={collapseColumns} data={buildCollapse(applicationStringInfo[record?.id])} />
        </Spin>
      </>
    );
  };

  return (
    <div className={styles.stepsData}>
      <Spin spinning={testStepsDataLoading} tip="Loading">
        <Table
          columns={columns}
          dataSource={testStepsData(getApplicationStringsData)}
          expandable={{
            fixed: false,
            onExpand: (record, e) => {
              dispatch(getStepDataInfoForApplicationString(e?.id, selectedStepId));
              setSelectedKey(e?.id);
            },
            expandedRowRender,
          }}
        />
      </Spin>
    </div>
  );
};

export default ApplicationStringList;
