import { Col, Input, Select } from "antd";
import React from "react";
import styles from "./OrganizationV2.module.scss";

const CreateOrganizationStepsV2 = (props) => {
  const {
    heading,
    inputLabel,
    inputPlaceholder,
    inputValue,
    onInputChange,
    selectLabel,
    selectPlaceholder,
    selectValue,
    onSelectChange,
  } = props;
  return (
    <Col span={24} className={"validate-form"}>
      <div className={styles["create_Organization_Heading"]}>{heading}</div>
      <div className={styles["custom-input-wrapper"]}>
        <div className={`${styles["custom-input-label"]}`}>{inputLabel}</div>
        <Input
          className={styles["modal-input"]}
          type="text"
          placeholder={inputPlaceholder}
          value={inputValue}
          onChange={(e) => onInputChange(e.target.value)}
        />
      </div>
      {selectLabel && (
        <div className={styles["custom-input-wrapper"]}>
          <div className={`${styles["custom-input-label"]}`}>{selectLabel}</div>
          <Select
            className={styles["modal-select-box"]}
            mode="tags"
            placeholder={selectPlaceholder}
            value={selectValue}
            onChange={onSelectChange}
          ></Select>
        </div>
      )}
    </Col>
  );
};

export default CreateOrganizationStepsV2;