import { Checkbox, Col, Row, Select, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OPERATION, TOOLTIP_BG_COLOR, splitValue } from "../../../../Constants";
import {
  BLUE_ICON_COLOR,
  SELECT_TEST_DATA_ICON
} from "../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  getListOfLocalesForApplicationStrings,
  selectTestData,
  updateApplicationStringsLocale,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import { visibleTestDataDrawer } from "../../../../actions/TestDataAction";
import ManageTestData from "../../SingleColumnView/ManageTestData";
import styles from "./Actions.module.scss";

const SetAppLocaleVariable = (props) => {
  const { disabled, data } = props;
  const { testDataList, unSavedRecStepTag, getListOfLocales } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  const [showTestData, setShowTestData] = useState(false);

  useEffect(() => {
    dispatch(getListOfLocalesForApplicationStrings());
    setShowTestData(data.recTestStepProperty?.testData && data.recTestStepProperty?.testData.id ? true : false);
  }, []);

  useEffect(() => {
    setShowTestData(data.recTestStepProperty?.testData && data.recTestStepProperty?.testData.id ? true : false);
  }, [data?.recTestStepProperty]);

  const disableRemoveSelectVariable = disabled || data?.recTestStepProperty?.testData === undefined;
  return (
    <React.Fragment>
      <Row className={styles.setAppLocaleVariableRow}>
        <Col span={24} className={styles.setAppLocaleVariableCol}>
          <span className={styles.localeOrSelect}>Set Application String Locale Value</span>
          <div>
            <Select
              showSearch={true}
              className="setAppLocalValue"
              value={data?.recTestStepProperty?.changeApplicationStringsLocale}
              placeholder="Select Locale"
              disabled={showTestData}
              onChange={(e) => {
                dispatch(updateApplicationStringsLocale(e, data?.tag));
                setShowTestData(false);
                dispatch(selectTestData(data.tag, undefined, undefined));
              }}
            >
              {getListOfLocales &&
                getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)}
            </Select>
          </div>
          <div className={styles.checkBoxOrText}>
            <div className={styles.checkBox}>
              <Checkbox
                disabled={disabled}
                onChange={(e) => {
                  setShowTestData(e.target.checked);
                  dispatch(updateApplicationStringsLocale(undefined, data?.tag));
                  if (!e.target.checked) {
                    dispatch(selectTestData(data.tag, undefined, undefined));
                  }
                }}
                checked={showTestData}
              />
              <span className={styles.checkBoxText}> Use Test Data</span>
            </div>
          </div>
        </Col>
      </Row>
      {showTestData ? (
        <Row className={styles.showTestDataRow}>
          <Col span={24} className={styles.showTestDataCol}>
            <div className={styles.selectVariable}>
              <span>Test Data Variable (To update locale run-time):</span>
              <Select
                placeholder="Select a Variable to update value runtime"
                className={styles.styledSelect}
                disabled={
                  disabled || (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== data.tag)
                }
                value={
                  data.recTestStepProperty?.testData && data.recTestStepProperty?.testData?.id
                    ? data.recTestStepProperty?.testData.id + splitValue + data.recTestStepProperty?.testData.value
                    : undefined
                }
                onChange={(value) =>
                  dispatch(selectTestData(data.tag, value.split(splitValue)[0], value.split(splitValue)[1]))
                }
              >
                {testDataList &&
                  testDataList.length > 0 &&
                  testDataList.map((v, k) => {
                    return (
                      <Select.Option key={v.id + splitValue + v.value} value={v.id + splitValue + v.value}>
                        {v.name + (v.value ? " (" + v.value + ")" : "")}
                      </Select.Option>
                    );
                  })}
              </Select>
              <Tooltip title="Select Test Data" color={TOOLTIP_BG_COLOR}>
                <div
                  className={`${styles.selectTestData} ${disabled && styles.disableSelectTestData}`}
                  onClick={() => {
                    if (!props.disabled) {
                      dispatch(visibleTestDataDrawer(OPERATION, data?.tag));
                      dispatch(selectRecTestSteps(data));
                    }
                  }}
                >
                  <SvgLoader iconName={SELECT_TEST_DATA_ICON} disabled={props.disabled} iconColor={BLUE_ICON_COLOR} />
                </div>
              </Tooltip>
              <div
                className={`${styles.removeSelectVariable} ${disableRemoveSelectVariable && styles.disableButton}`}
                onClick={(e) =>
                  !disableRemoveSelectVariable && dispatch(selectTestData(data.tag, undefined, undefined))
                }
              >
                Remove
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        ""
      )}
      <ManageTestData {...props} tabName={OPERATION} />
    </React.Fragment>
  );
};
export default SetAppLocaleVariable;
