import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideInputTextModal, onInsertText, passwordInput, updateInputText } from "../../../actions/Recording";
import Draggable from "react-draggable";
import { Button, Checkbox, Input } from "antd";

const InputTextModal = (props) => {
  const { IOS, serverId, targetUniqueId } = props;
  const dispatch = useDispatch();
  const { showInputText, isInputPassword, currentInputText, inputTextSubmitRequest } = useSelector(
    (state) => state.RecordingReducer
  );
  return (
    <React.Fragment>
      {showInputText && (
        <Draggable
          defaultPosition={{
            x: 0,
            y:
              document.getElementsByClassName("devices-actions").length > 0 &&
              document.getElementsByClassName("devices-actions")[0].offsetHeight,
          }}
          cancel={".input-text-box"}
          //    bounds={{
          //     left:0,
          //     top:-50,
          //     right:window.innerWidth-16-(document.getElementsByClassName("input-text-modal").length>0 && document.getElementsByClassName("input-text-modal")[0].offsetWidth),
          //     bottom:window.innerHeight-50-(document.getElementsByClassName("input-text-modal").length>0 && document.getElementsByClassName("input-text-modal")[0].offsetHeight)
          //   }}
          offsetParent={document.getElementById("root")}
          scale={1}
        >
          <div className="ant-modal-content input-text-modal">
            <button
              type="button"
              aria-label="Close"
              className="ant-modal-close"
              onClick={() => {
                dispatch(hideInputTextModal());
              }}
            >
              <span className="ant-modal-close-x">
                <i aria-label="icon: close" className="anticon anticon-close ant-modal-close-icon">
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    className=""
                    data-icon="close"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                  </svg>
                </i>
              </span>
            </button>
            <div className="ant-modal-header">
              <div className="ant-modal-title" id="rcDialogTitle0">
                Input Text
              </div>
            </div>
            <div className="ant-modal-body">
              <div>
                <Input
                  type={isInputPassword !== undefined && isInputPassword ? "password" : "text"}
                  autoFocus
                  className="w-100 input-text-box"
                  value={currentInputText}
                  onChange={(e) => {
                    dispatch(updateInputText(e.target.value));
                  }}
                  placeholder={"Enter Text Here"}
                />
                <Checkbox
                  text={"Password"}
                  className={"input-password-checkbox"}
                  onChange={(e) => dispatch(passwordInput(e.target.checked))}
                >
                  Hide character while typing(i.e for password).
                </Checkbox>
              </div>
            </div>
            <div className="ant-modal-footer">
              <div>
                <Button
                  onClick={() => {
                    dispatch(hideInputTextModal());
                  }}
                  onTouchStart={() => {
                    dispatch(hideInputTextModal());
                  }}
                >
                  <span>Cancel</span>
                </Button>
                <Button
                  type="primary"
                  loading={inputTextSubmitRequest}
                  className="ant-btn ant-btn-primary"
                  disabled={currentInputText === undefined || currentInputText.length === 0 ? true : false}
                  onClick={() => {
                    dispatch(onInsertText(serverId, targetUniqueId, currentInputText, IOS));
                  }}
                  onTouchStart={() => {
                    dispatch(onInsertText(serverId, targetUniqueId, currentInputText, IOS));
                  }}
                >
                  <span>Submit</span>
                </Button>
              </div>
            </div>
          </div>
        </Draggable>
      )}
    </React.Fragment>
  );
};

export default InputTextModal;
