import { Collapse, Empty } from "antd";
import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import EditRecTestScenarioTestDataSetV2 from "./EditRecTestScenarioTestDataSetV2";

const EditTestPlanTestDataSetV2 = ({ isForView }) => {
  const { testDataSetForEdit } = useSelector((state) => state.TestDataSetReducer);

  return (
    <>
      {testDataSetForEdit?.testScenarioTestDataSaveReq &&
        !_.isEmpty(testDataSetForEdit?.testScenarioTestDataSaveReq) &&
        testDataSetForEdit?.testScenarioTestDataSaveReq.map((testScenarioTestData) => {
        return ((!_.isEmpty(testScenarioTestData.testBlockTestDataSaveReq) || 
              !_.isEmpty(testScenarioTestData.testDataValues)) &&
                <div className="m-10">
                  <Collapse className="mt-10">
                    <Collapse.Panel 
                      header={"Scenario:" + testScenarioTestData.name
                        + " [Steps using Test Data: " +  testScenarioTestData?.totalstepCount
                        + " Unique Test Data Keys: " + testScenarioTestData?.totaluniqueTestDataCount + "]"}>
                      <EditRecTestScenarioTestDataSetV2
                        title={"Scenario Level Test Data"
                          + " [Steps using Test Data: " +  testScenarioTestData?.stepCount
                          + " Unique Test Data Keys: " + testScenarioTestData?.uniqueTestDataCount + "]"}
                        testDataSetForEdit={testScenarioTestData}
                        isForView={isForView}
                      />
                    </Collapse.Panel>
                  </Collapse>
                </div>
              );
            }
          )
      }
    </>
  );
};

export default EditTestPlanTestDataSetV2;