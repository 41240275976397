import { Drawer, Tabs } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CLOSE_ICON_GRAY } from "../../../../CdnImagePath";
import RecStepElementXpaths from "../../StepInnerComponents/RecStepElementXpaths/RecStepElementXpaths";
import { getRecElement } from "../../../../Util";
import { hideRecTestStepActionElementDetailDrawer } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import EmptyRecords from "../../../CommonComponents/EmptyRecords/EmptyRecords";
import styles from "./RecTestStepActionElementPropertiesDrawer.module.scss";

const RecTestStepActionElementPropertiesDrawer = (props) => {
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const {
    recTestStepActionElementDetailDrawerVisible,
    recTestStepActionElementDetailDrawerData,
    selectedElementGuidForDetail,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const dispatch = useDispatch();
  const [elementXpathDetail, setElementXpathDetail] = useState(undefined);
  useEffect(() => {
    if (recTestStepActionElementDetailDrawerData) {
      if (recTestStepActionElementDetailDrawerData["recTestStepActionSelectedElement"]) {
        setElementXpathDetail({
          xpath: recTestStepActionElementDetailDrawerData.recTestStepActionSelectedElement.xpath,
          customXpath: recTestStepActionElementDetailDrawerData.recTestStepActionSelectedElement.customXpath,
          systemGeneratedXpath: recTestStepActionElementDetailDrawerData.recTestStepActionSelectedElement.xpath,
        });
      } else {
        setElementXpathDetail({
          xpath: recTestStepActionElementDetailDrawerData.recTestStepProperty?.xpath,
          customXpath: recTestStepActionElementDetailDrawerData.recTestStepProperty?.customXpath,
          systemGeneratedXpath: recTestStepActionElementDetailDrawerData.recTestStepProperty?.xpath,
        });
      }
    }
  }, [recTestStepActionElementDetailDrawerData]);
  let recElement = getRecElement(
    recTestStepActionElementDetailDrawerData?.recTestStepProperty?.recElements,
    selectedElementGuidForDetail
  );
  return (
    <Drawer
      title={
        <div className={styles.drawer}>
          <span className={styles.drawerTitle}>Element Details</span>
          <span>
            <img
              src={CLOSE_ICON_GRAY}
              alt="close"
              className="cursor__pointer"
              onClick={() => dispatch(hideRecTestStepActionElementDetailDrawer())}
            />
          </span>
        </div>
      }
      className="elementDetailsDrawer"
      width={500}
      placement="right"
      closable={false}
      getContainer={false}
      visible={recTestStepActionElementDetailDrawerVisible}
    >
      <Tabs>
        <Tabs.TabPane tab="Identifiers" key="IDENTIFIERS">
          <RecStepElementXpaths
            data={recTestStepActionElementDetailDrawerData}
            recElement={recElement}
            isPreviewRunning={isPreviewRunning}
            isSkipStep={recTestStepActionElementDetailDrawerData?.recTestStepProperty?.isSkipStep}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Properties" key="PROPERTIES">
          {recElement && (
            <>
              <div>
                {/* <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Description :</span>
                  <div className={styles.styledDiv}>{recElement.description}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Element Image :</span>{" "}
                  <div className={styles.styledDiv}>{recElement.elementImage}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Element Type :</span>
                  <div className={styles.styledDiv}>{recElement.elementType}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Guid :</span>
                  <div className={styles.styledDiv}>{recElement.guid}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Last Update Date :</span>{" "}
                  <div className={styles.styledDiv}>{recElement.lastUpdateDate}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Element Type Name :</span>{" "}
                  <div className={styles.styledDiv}>{recElement.recElementTypeName}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Element Type Platform :</span>{" "}
                  <div className={styles.styledDiv}>{recElement.recElementTypePlatform}</div>
                </div>
                <div className={styles.elementDetails}>
                  <span className={styles.propertiesValue}>Tags :</span>
                  <div className={styles.styledDiv}>{recElement.tags}</div>
                </div> */}
                {recElement.recElementProperties !== undefined ? (
                  recElement.recElementProperties.map((v, k) => {
                    return (
                      k !== 0 && (
                        <div key={k} className={styles.elementDetails}>
                          <span className={styles.propertiesValue}>{v.name + " : "}</span>
                          <div className={styles.styledDiv}>{v.value}</div>
                        </div>
                      )
                    );
                  })
                ) : (
                  <EmptyRecords description="No Data" />
                )}
              </div>
            </>
          )}
        </Tabs.TabPane>
        {/* <Tabs.TabPane tab="Locators" key="LOCATORS">
          {recElement?.recElementLocatorProperties?.length > 0 ? (
            recElement?.recElementLocatorProperties?.map((v, k) => {
              return (
                <div key={k} className={styles.elementLocators}>
                  <div className={styles.elementDetails}>
                    <span className={styles.propertiesValue}>{v.generatedMethod}</span>
                    <span className={styles.propertiesValue}>:</span>
                    <div className={styles.styledDiv}>{v.value}</div>
                  </div>
                  <div className={styles.elementDetails}>
                    <span className={styles.propertiesValue}>Total Child :</span>
                    <div className={styles.styledDiv}>{v.totalChildCount}</div>
                  </div>
                  <div className={styles.elementDetails}>
                    <span className={styles.propertiesValue}>Current Child Index:</span>
                    <div className={styles.styledDiv}>{v.currentChildIndex}</div>
                  </div>
                </div>
              );
            })
          ) : (
            <EmptyRecords description="No Data" />
          )}
        </Tabs.TabPane> */}
      </Tabs>
      {recTestStepActionElementDetailDrawerData &&
        recTestStepActionElementDetailDrawerData.recTestStepProperty &&
        recTestStepActionElementDetailDrawerData.recTestStepProperty?.recStepElementXpaths &&
        recTestStepActionElementDetailDrawerData.recTestStepProperty?.recStepElementXpaths.length > 0 &&
        recTestStepActionElementDetailDrawerData.recTestStepProperty?.recStepElementXpaths.map((v, k) => {
          return (
            <Fragment>
              <div className={styles.elementProperties}>
                <span className={styles.elementPropertiesKey}>{"Generated Method : "}</span>
                <span className={styles.propertiesValue}>{v.generatedMethod}</span>
                <br />
                <span className={styles.elementPropertiesKey}>{"xpath : "}</span>
                <span className={styles.propertiesValue}>{v.xpath}</span>
                <br />
                <span className={styles.elementPropertiesKey}>{"Default : "}</span>
                <span className={styles.propertiesValue}>{v.isDefault === 1 ? "True" : "False"}</span>
                <br />
                <span className={styles.elementPropertiesKey}>{"Element Index : "}</span>
                <span className={styles.propertiesValue}>{v.currentChildIndex}</span>
                <br />
                <span className={styles.elementPropertiesKey}>{"Total Elements : "}</span>
                <span className={styles.propertiesValue}>{v.totalChildCount}</span>
              </div>
            </Fragment>
          );
        })}
    </Drawer>
  );
};
export default RecTestStepActionElementPropertiesDrawer;
