import { Tooltip } from "antd";
import _ from "lodash";
import { default as React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DEVICE_INTERACTION_MODE, ElementType, STEP, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  APPLICATION_STRING_ICON,
  BLACK_COLOR,
  BLUE_COLOR,
  DEVICE_STRING_ICON,
  DISABLE_COLOR,
  MORE_PROPERTIES_ICON,
  NAVY_BLUE_COLOR,
  REPLACE_ELEMENT_ICON,
  WHITE_COLOR,
} from "../../../../Constants/SvgConstants";
import { ATTACH_APPLICATION_STRING, ATTACH_DEVICE_STRING } from "../../../../Constants/TooltipConstants";
import { isNotNullAndNotEmptyAndNotUndefined, isNullOrUndefinedOrEmpty } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  applicationDrawerVisible,
  changeAdvanceElementSelectorDrawerVisibility,
  deviceDrawerVisible,
  getApplicationStrings,
  getDeviceStrings,
  getListOfLocalesForApplicationStrings,
  getListOfLocalesForDeviceStrings,
  reRecordRecTestStepElement,
  showRecTestStepActionElementDetailDrawer,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../actions/RecTestStepAction";
import TextButton from "../../../CommonComponents/TextButton/TextButton";
import AdvanceSelectorAllElementDrawer from "../AdvanceSelector/AdvanceSelectorAllElementDrawer/AdvanceSelectorAllElementDrawer";
import AdvanceSelectorChildElementDrawer from "../AdvanceSelector/DeviceSource/AdvanceSelectorChildElementDrawer/AdvanceSelectorChildElementDrawer";
import ApplicationStringDrawer from "../ApplicationStringDrawer/ApplicationStringDrawer";
import styles from "./RecTestStepElementSelectionDetail.module.scss";
import DeviceStringDrawer from "../DeviceStringDrawer/DeviceStringDrawer";

const textToDisplayOfElement = (recElementProperties) => {
  let textToDisplayKey = "None";
  let textToDisplayValue = "None";
  let classToDisplay = "None";
  if (recElementProperties && recElementProperties.length > 0) {
    recElementProperties.forEach((element) => {
      if (
        element["name"] === "class" ||
        element["name"] === "type" ||
        element["name"] === "content-desc" ||
        element["name"] === "text" ||
        element["name"] === "value" ||
        element["name"] === "label" ||
        element["name"] === "name"
      ) {
        textToDisplayValue = element["value"];
        textToDisplayKey = element["name"];
      }
    });
    let classAttributes = recElementProperties.filter((item) => item["name"] === "class" || item["name"] === "type");
    classToDisplay = classAttributes.length > 0 ? classAttributes[0]["value"] : classToDisplay;
  }
  return { name: textToDisplayKey, value: textToDisplayValue, class: classToDisplay };
};

const RecTestStepElementSelectionDetail = (props) => {
  const [searchElements, setSearchElements] = useState(null);
  const dispatch = useDispatch();
  const { data, recElement, isSkipStep, parentRecElement, isDeviceLoaded, isTestBlock } = props;
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const {
    unSavedRecStepTag,
    unSavedRecElementGuid,
    requestingPerformRecTestStepAction,
    isAdvanceElementSelectorDrawerVisible,
    getListOfLocales,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { startDeviceStreamingStatus, currentDeviceScreenSource, deviceInteractionMode } = useSelector(
    (state) => state.RecordingReducer
  );

  // Re-Record Button when to display
  let showReRecordButton =
    (parentRecElement === undefined &&
      ((data.recTestStepProperty?.action === STEP &&
        data.recTestStepProperty?.isStepHasPrecondition === 1 &&
        isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
        data.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
        data.recTestStepProperty?.recStepVerifyElementProperties.length ===
          data.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length) ||
        (data.recTestStepProperty?.action === STEP &&
          data.recTestStepProperty?.isLoopStep === 1 &&
          data.recTestStepProperty?.loopCount > 0 &&
          isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
          (data.recTestStepProperty?.recElements.length === 0 ||
            (data.recTestStepProperty?.recElements.length > 0 &&
              data.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
              data.recTestStepProperty?.recStepVerifyElementProperties.length ===
                data.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined)
                  .length))) ||
        (data.recTestStepProperty?.action !== STEP &&
          data.id !== undefined &&
          isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
          isNullOrUndefinedOrEmpty(unSavedRecElementGuid)))) ||
    (parentRecElement !== undefined &&
      isNullOrUndefinedOrEmpty(unSavedRecElementGuid) &&
      (recElement.elementPurpose === "ACTION" ||
        (recElement.elementPurpose === "VERIFICATION" &&
          recElement.recStepVerifyElementProperties.length > 0 &&
          recElement.recStepVerifyElementProperties.length ===
            recElement.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length)));

  let disableReRecordButton =
    props.disabled ||
    selectedDeviceByUdid === undefined ||
    !startDeviceStreamingStatus ||
    requestingPerformRecTestStepAction ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
      unSavedRecStepTag !== data.tag &&
      unSavedRecElementGuid !== recElement.guid);

  const recElementDisplayObj = textToDisplayOfElement(recElement.recElementProperties);
  const getAdvanceSelector = (isAdvanceElementSelectorDrawerVisible, deviceInteractionMode, parentRecElement) => {
    if (
      isAdvanceElementSelectorDrawerVisible &&
      deviceInteractionMode === DEVICE_INTERACTION_MODE.SELECT &&
      parentRecElement === undefined
    ) {
      return (
        <>
          <AdvanceSelectorAllElementDrawer
            searchElements={searchElements}
            setSearchElements={setSearchElements}
            recElement={recElement}
          />
        </>
      );
    } else if (
      isAdvanceElementSelectorDrawerVisible &&
      deviceInteractionMode === DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD &&
      parentRecElement
    ) {
      return (
        <AdvanceSelectorChildElementDrawer
          searchElements={searchElements}
          setSearchElements={setSearchElements}
          recElement={recElement}
          parentElement={parentRecElement}
        />
      );
    }
    return "";
  };
  const disableReplaceElement = isPreviewRunning || disableReRecordButton || isSkipStep;
  const disableInspector =
    isNullOrUndefinedOrEmpty(currentDeviceScreenSource) ||
    ![DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(deviceInteractionMode) ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid);
  const selectApplicationButtonDisable =
    !recElementDisplayObj?.value ||
    recElementDisplayObj.value === "None" ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid);
  const selectDeviceButtonDisable =
    !recElementDisplayObj?.value ||
    recElementDisplayObj.value === "None" ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement?.guid);

  const handleClickApplicationString = () => {
    if (!selectApplicationButtonDisable) {
      dispatch(getApplicationStrings(props.projectId, "", "", "", recElement.guid, data.tag));
      _.isEmpty(getListOfLocales) && dispatch(getListOfLocalesForApplicationStrings());
      dispatch(applicationDrawerVisible(true, recElement.guid));
      dispatch(selectRecTestSteps(data));
    }
  };
  const handleClickDeviceString = () => {
    if (!selectDeviceButtonDisable) {
      dispatch(getDeviceStrings(props.projectId, "", "", "", recElement.guid, data.tag));
      _.isEmpty(getListOfLocales) && dispatch(getListOfLocalesForDeviceStrings());
      dispatch(deviceDrawerVisible(true, recElement.guid));
      dispatch(selectRecTestSteps(data));
    }
  };

  let applicationStringsValuesCount =
    recElement?.applicationStrings?.values.length > 1 ? (
      <div>
        <span>1. {recElement?.applicationStrings?.values[0]?.value}</span>
        <div className={styles.moreLength}>+{recElement?.applicationStrings?.values.length - 1} more</div>
      </div>
    ) : (
      recElement?.applicationStrings?.values[0]?.value
    );
  let deviceStringsValuesCount =
    recElement?.deviceStrings?.values.length > 1 ? (
      <div>
        <span>1. {recElement?.deviceStrings?.values[0]?.value}</span>
        <div className={styles.moreLength}>+{recElement?.deviceStrings?.values.length - 1} more</div>
      </div>
    ) : (
      recElement?.deviceStrings?.values[0]?.value
    );

  return isDeviceLoaded || recElement.id !== undefined ? (
    <div>
      {!isDeviceLoaded && !isTestBlock && (
        <div className={styles.deviceLoadMessage}>Load Device if you want to change the Element!</div>
      )}
      {((isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) && isDeviceLoaded) ||
        [DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(deviceInteractionMode)) && (
        <div className={styles.inspectorButtonOrText}>
          <div className={`${styles.text} ${disableInspector && styles.disableInspectorText}`}>
            Select an Element on the Device or use
          </div>
          <div
            onClick={() => {
              if( !disableInspector){
                setSearchElements(null);
                isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) &&
                  dispatch(changeAdvanceElementSelectorDrawerVisibility(true));
              }
             
            }}
            className={`${styles.inspectorButton} ${disableInspector && styles.disableButton}`}
          >
            Inspector
          </div>
        </div>
      )}
      <div className={styles.divider}>
        <div className={styles.dividerText}>Properties</div>
      </div>
      <div>
        {[ElementType.DIFFERENT_VERSION, ElementType.PARENT_ELEMENT].includes(recElement.elementType) ? (
          <div className={styles.recTestStepSelectorDetail}>
            <div className={styles.selectElementDetail}>
              <div className={styles.recElementDisplay}>
                <div className={styles.elementLabel}>
                  <div className={styles.label}>Identifier</div>
                  <Tooltip
                    title={recElementDisplayObj?.name?.length >= 30 && recElementDisplayObj.name}
                    color={TOOLTIP_BG_COLOR}>
                    <span
                      className={`${styles.identifier} ${recElementDisplayObj?.name?.length >= 30 && styles.ellipse}`}>
                      : {recElementDisplayObj.name}
                    </span>
                  </Tooltip>
                </div>
                <div className={styles.selectElementValue}>
                  <div className={styles.elementLabel}>
                    <div className={styles.label}>Value</div>
                    <Tooltip
                      title={recElementDisplayObj?.value?.length >= 30 && recElementDisplayObj.value}
                      color={TOOLTIP_BG_COLOR}>
                      <span
                        className={`${styles.identifier} ${styles.elementValue} ${
                          recElementDisplayObj?.value?.length >= 30 && styles.ellipse
                        }`}>
                        : {recElementDisplayObj.value}
                      </span>
                    </Tooltip>
                  </div>
                  <Tooltip
                    title={
                      <div>
                        {recElement?.applicationStrings ? (
                          <>
                            <div>
                              <span className={styles.tooltipLabel}>App String Key: </span>
                              <span>{recElement?.applicationStrings?.key}</span>
                            </div>
                            <div>
                              <span className={styles.tooltipLabel}> Values: </span>
                              <span>{recElement?.applicationStrings?.values?.length}</span>
                            </div>
                            <div>
                              <span>{applicationStringsValuesCount}</span>
                            </div>
                          </>
                        ) : (
                          <span>{ATTACH_APPLICATION_STRING}</span>
                        )}
                      </div>
                    }
                    color={TOOLTIP_BG_COLOR}>
                    <span
                      className={`${styles.applicationString} ${
                        selectApplicationButtonDisable && styles.disableApplicationString
                      }`}
                      onClick={() => {
                        !selectApplicationButtonDisable && handleClickApplicationString();
                      }}>
                      <SvgLoader
                        iconName={APPLICATION_STRING_ICON}
                        width={20}
                        iconColor={
                          selectApplicationButtonDisable
                            ? DISABLE_COLOR
                            : recElement.applicationStrings
                            ? BLUE_COLOR
                            : BLACK_COLOR
                        }
                        disabled={selectApplicationButtonDisable}
                      />
                    </span>
                  </Tooltip>
                  <Tooltip
                    title={
                      <div>
                        {recElement?.deviceStrings ? (
                          <>
                            <div>
                              <span className={styles.tooltipLabel}>Device String Key: </span>
                              <span>{recElement?.deviceStrings?.key}</span>
                            </div>
                            <div>
                              <span className={styles.tooltipLabel}> Values: </span>
                              <span>{recElement?.deviceStrings?.values?.length}</span>
                            </div>
                            <div>
                              <span>{deviceStringsValuesCount}</span>
                            </div>
                          </>
                        ) : (
                          <span>{ATTACH_DEVICE_STRING}</span>
                        )}
                      </div>
                    }
                    color={TOOLTIP_BG_COLOR}>
                    <span
                      className={`${styles.deviceString} ${selectDeviceButtonDisable && styles.disableDeviceString}`}
                      onClick={() => {
                        !selectDeviceButtonDisable && handleClickDeviceString();
                      }}>
                      <SvgLoader
                        iconName={DEVICE_STRING_ICON}
                        width={20}
                        iconColor={
                          selectDeviceButtonDisable
                            ? DISABLE_COLOR
                            : recElement.deviceStrings
                            ? BLUE_COLOR
                            : BLACK_COLOR
                        }
                        disabled={selectDeviceButtonDisable}
                      />
                    </span>
                  </Tooltip>
                </div>
              </div>
              <div className={styles.morePropertiesButton}>
                {Object.values(recElementDisplayObj)?.every((i) => i !== "None") && (
                  <>
                    <TextButton
                      title={
                        <div className={styles.iconOrText}>
                          <SvgLoader iconName={MORE_PROPERTIES_ICON} iconColor={NAVY_BLUE_COLOR}/>
                          <span className={styles.buttonText}>More Properties</span>
                        </div>
                      }
                      onClick={() => {
                        dispatch(showRecTestStepActionElementDetailDrawer(data.id, data.tag, recElement.guid, data));
                      }}
                      className={styles.moreProperties}
                    />
                  </>
                )}
                <div>
                  {showReRecordButton &&
                    (isPreviewRunning || !disableReRecordButton || isSkipStep) &&
                    (recElement.childRecElements === undefined || recElement?.childRecElements?.length === 0) && (
                      <div
                        className={`${styles.moreProperties} ${disableReplaceElement && styles.disableButton} ${
                          styles.replaceElement
                        }`}
                        onClick={() =>
                          !disableReplaceElement &&
                          dispatch(reRecordRecTestStepElement(data.tag, recElement.guid, parentRecElement))
                        }>
                        <div className={styles.iconOrText}>
                          <SvgLoader
                            iconName={REPLACE_ELEMENT_ICON}
                            iconColor={disableReplaceElement ? DISABLE_COLOR : WHITE_COLOR}
                          />
                          <span className={`${styles.replaceText} ${disableReplaceElement && styles.disableText}`}>
                            Replace Element
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ) : undefined}
        {/* <RecTestStepActionElementDetailDrawer>
                  <FontAwesomeIcon
                    onClick={() =>
                      dispatch(showRecTestStepActionElementDetailDrawer(data.id, data.tag, recElement.guid))
                    }
                    icon={faInfoCircle}
                  />
                </RecTestStepActionElementDetailDrawer>
                <AdvanceSelectorIcon
                  className="mr-10"
                  disabled={
                    isNullOrUndefinedOrEmpty(currentDeviceScreenSource) ||
                    ![DEVICE_INTERACTION_MODE.SELECT, DEVICE_INTERACTION_MODE.HIGHLIGHT_CHILD].includes(
                      deviceInteractionMode
                    )
                  }>
                  <Tooltip color={TOOLTIP_BG_COLOR} placement="left" title="Advanced Selector (All Elements)">
                    <FontAwesomeIcon
                      icon={faListUl}
                      onClick={() => {
                        setSearchElements(null);
                        isNotNullAndNotEmptyAndNotUndefined(currentDeviceScreenSource) &&
                          dispatch(changeAdvanceElementSelectorDrawerVisibility(true));
                      }}
                    />
                  </Tooltip>
                </AdvanceSelectorIcon> */}
        {/* </Col> */}
      </div>

      {getAdvanceSelector(isAdvanceElementSelectorDrawerVisible, deviceInteractionMode, parentRecElement)}
      <ApplicationStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={props.projectId}
        disabled={props.disabled || props.isSkipStep}
      />
      <DeviceStringDrawer
        recTestStep={data}
        recElement={recElement}
        projectId={props.projectId}
        disabled={props.disabled || props.isSkipStep}
      />
    </div>
  ) : (
    <div className={styles.text}>Please load the device </div>
  );
};

export default RecTestStepElementSelectionDetail;
