import { Checkbox, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { changeShowTestData } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./EnterText.module.scss";

export const StyledSearchTestDataTextBox = styled(Input)`
  width: 50%;
  margin: 10px;
`;

export const StyleAddNewTestDataIcon = styled.div`
  color: black;
  font-size: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: -4px;
  text-align: right;
  line-height: 2;
`;
export const StyledSelectedTestDataValueInput = styled(Input)`
  width: 75%;
  margin-left: 10px;
`;

const TestDataOption = (props) => {
  const dispatch = useDispatch();
  const { data, testDataChecked, inputDisabled, disabled } = props;

  return (
    <div className={`${styles.checkBoxOrText} ${styles.testDataOptions}`}>
      <Checkbox
        className={styles.checkBox}
        disabled={inputDisabled || disabled}
        onChange={(e) => {
          dispatch(changeShowTestData(e.target.checked, data.tag));
        }}
        checked={testDataChecked}
      />
      <span className={styles.checkBoxText}> Use Test Data</span>
    </div>
  );
};

export default TestDataOption;
