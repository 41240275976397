import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeviceCard from "./DeviceCard";
import { Col, Row } from "antd";
import { RESET_REMOTE_DEVICE_DATA } from "../../Constants";

export default (props) => {
  const { selectedServerDevices, deviceFarmId, serverDownMessage } = useSelector((state) => state.ServerListReducer);
  const dispatch = useDispatch();
  let usedByMeCount = 0;
  let freeCount = 0;
  let usedByOthersCount = 0;

  useEffect(() => {
    dispatch({ type: RESET_REMOTE_DEVICE_DATA });
  }, [props.projectId]);

  return deviceFarmId !== "" && selectedServerDevices ? (
    <Row>
      <Col lg={8} md={8} sm={8}>
        <div className="devicesCard ml-10">
          <label className="font-color-darkBlue font-size-16">Acquired devices</label>
          <div>
            {selectedServerDevices !== undefined
              ? selectedServerDevices.map((v, k) => {
                  if (v["deviceAcquireUserName"] !== null && v["acquiredByCurrentUser"] === true) {
                    usedByMeCount++;
                    return (
                      <DeviceCard
                        key={k}
                        orgId={props.orgId}
                        data={v}
                        setDefaultsAppRightsForDevice={props.setDefaultsAppRightsForDevice}
                        editRightsForDeviceNote={props.editRightsForDeviceNote}
                        adminReleaseDevice={props.adminReleaseDevice}
                        projectId={props.projectId}
                      ></DeviceCard>
                    );
                  }
                })
              : null}
          </div>
          {usedByMeCount === 0 ? (
            <div className="no-device-available">Please Acquire Device To Use From The "Free" Devices List.</div>
          ) : (
            ""
          )}
        </div>
      </Col>
      <Col lg={8} md={8} sm={8}>
        <div className="devicesCard">
          <label className="font-color-darkBlue font-size-16">Free Devices</label>
          <div>
            {selectedServerDevices !== undefined
              ? selectedServerDevices.map((v, k) => {
                  if (v["deviceAcquireUserName"] == null) {
                    freeCount++;
                    return (
                      <DeviceCard
                        data={v}
                        key={k}
                        setDefaultsAppRightsForDevice={props.setDefaultsAppRightsForDevice}
                        editRightsForDeviceNote={props.editRightsForDeviceNote}
                        adminReleaseDevice={props.adminReleaseDevice}
                        projectId={props.projectId}
                      ></DeviceCard>
                    );
                  }
                })
              : null}
          </div>
          {freeCount === 0 ? <div className="no-device-available"> No Device Is Free!</div> : ""}
        </div>
      </Col>
      <Col lg={8} md={8} sm={8}>
        <div className="devicesCard mr-10">
          <label className="font-color-darkBlue font-size-16">Unavailable Devices</label>
          <div>
            {selectedServerDevices !== undefined
              ? selectedServerDevices.map((v, k) => {
                  if (v["deviceAcquireUserName"] !== null && v["acquiredByCurrentUser"] === false) {
                    usedByOthersCount++;
                    return (
                      <DeviceCard
                        data={v}
                        key={k}
                        setDefaultsAppRightsForDevice={props.setDefaultsAppRightsForDevice}
                        editRightsForDeviceNote={props.editRightsForDeviceNote}
                        adminReleaseDevice={props.adminReleaseDevice}
                        projectId={props.projectId}
                      ></DeviceCard>
                    );
                  }
                })
              : null}
          </div>
          {usedByOthersCount === 0 ? (
            <div className="no-device-available"> No Devices Are Currently Acquired/Used By Your Teammates!</div>
          ) : (
            ""
          )}
        </div>
      </Col>
    </Row>
  ) : serverDownMessage ? (
    <div className="no-device-available msg-center"> {serverDownMessage} </div>
  ) : null;
};
