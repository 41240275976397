import React from "react";
import { Modal } from "antd";

const CommonConfirmationModalV2 = (props) => {
  const {
    modalOpen,
    handleOk,
    okText,
    modalContent,
    modalTitle,
    handleCancel,
    modalNote,
    isDeleteModal,
    cancelText,
    isAutoWidth,
    modalClose,
  } = props;
  return (
    <Modal
      title={<div className="modal_Header">{modalTitle}</div>}
      centered={true}
      visible={modalOpen}
      wrapClassName="common_Modal_v2"
      closable={isAutoWidth && cancelText ? true : false}
      footer={null}
      onCancel={() => (isAutoWidth && cancelText ? modalClose() : handleCancel())}
    >
      <div>
        <div className="modal_Content">{modalContent}</div>
        {modalNote && <span className="delete-modal-content-note">{modalNote}</span>}
        <div className="modal-Content-Btn">
          <div
            className={`modal_Buttons modal_Cancel_Btn ${!isAutoWidth && "modal-Button-Width"}`}
            onClick={() => handleCancel()}
          >
            {cancelText ? cancelText : "Cancel"}
          </div>
          <div
            className={`modal_Buttons ${isDeleteModal ? " delete_Modal_Delete_Btn" : "modal-Ok-Btn"} ${
              !isAutoWidth && "modal-Button-Width"
            }`}
            onClick={() => handleOk()}
          >
            {okText}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CommonConfirmationModalV2;
