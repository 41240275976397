import React from "react";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Tooltip, Upload } from "antd";
import { UPLOAD_ICON } from "../../../CdnImagePath";
import { TOOLTIP_BG_COLOR } from "../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import { removeApplicationStringsXml } from "../../../actions/ApplicationStrings";
import styles from "./UploadBuild.module.scss";

const UploadBuild = (props) => {
  const {
    setAddNewBuild,
    addNewBuild,
    existingBuildName,
    setFileList,
    fileList,
    labelName,
    placeholder,
    setUploadBuildFile,
    acceptFileTypes,
    devicePushBuild,
    index,
    setSelectStringsIndex,
    selectStringsIndex,
  } = props;

  const dispatch = useDispatch();

  const sanitizeFileName = (fileName) => {
    return fileName.replace(/\s+/g, '_').replace(/[^a-zA-Z0-9_\-.]/g, '');
  };

  const uploadProps = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      const sanitizedFile = new File([file], sanitizeFileName(file.name), { type: file.type });
      changeBuildFileName(sanitizedFile);
      return false;
    },
    accept: acceptFileTypes,
    multiple: false,
    // fileList: fileList,
  };
  const changeBuildFileName = (file) => {
    if (file) {
      setFileList([file]);
      setUploadBuildFile && setUploadBuildFile(file, file.name);
    }
  };
  return (
    <>
      <div className={!devicePushBuild && "p-5"}>
        {labelName && (
          <div className={devicePushBuild ? styles.detailsInPushBuildModal : "display-flex"}>
            <div className={devicePushBuild ? " font-size-14 font-weigh-400" : " mb-5 font-size-14 font-weigh-400"}>
              {labelName}
              <span className="text-danger">*</span>
            </div>
          </div>
        )}
        {!addNewBuild && !selectStringsIndex?.includes(index) && isNotNullAndNotEmptyAndNotUndefined(existingBuildName) && (
          <>
            <div className="display-flex mb-5">
              <span className="font-size-14 font-weight-500 font-color-darkBlue">{existingBuildName}</span>
              <span className="font-lightBlue cursor-pointer ml-20">
                <Tooltip color={TOOLTIP_BG_COLOR} title="Upload another file">
                  <img
                    src={UPLOAD_ICON}
                    alt="upload"
                    onClick={() => {
                      setSelectStringsIndex && setSelectStringsIndex([...selectStringsIndex, index]);
                      selectStringsIndex ? selectStringsIndex?.includes(index) && setAddNewBuild(true) : setAddNewBuild(true);
                      selectStringsIndex && dispatch(removeApplicationStringsXml(index));
                    }}
                  />
                </Tooltip>
              </span>
            </div>
          </>
        )}
        {(addNewBuild || selectStringsIndex?.includes(index) || !isNotNullAndNotEmptyAndNotUndefined(existingBuildName)) && (
          <>
            <Upload {...uploadProps} maxCount={1}>
              <Button className={devicePushBuild ? styles.uploadBuildInModal : "w-100"}>
                <FontAwesomeIcon icon="cloud-upload-alt" className="mr-5" />
                {placeholder}
              </Button>
            </Upload>
          </>
        )}
      </div>
    </>
  );
};

export default UploadBuild;
