import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Popover, Tooltip } from "antd";
import {
  ANDROID,
  BOTH,
  DEVICE_EXECUTION_FARM,
  LAMBDA_TEST,
  LAMBDA_TEST_ANDROID,
  SAUCELABS,
  SCENARIO_RECORDING,
  TEST_RUN_EXECUTION,
  TOOLTIP_BG_COLOR,
} from "../../../Constants";
import { INFO_ICON_NEW, SILVER_GRAY } from "../../../Constants/SvgConstants";
import { capitalizeWords, checkNotUndefined, deviceImage, lowerCaseWords } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { visibleDeviceMoreDetailsModal } from "../../../actions/ServerListAction";
import DeviceMoreDetailsModalV2 from "../DeviceMoreDetailsModalV2/DeviceMoreDetailsModalV2";
import styles from "./DeviceDetailsCardV2.module.scss";

const DeviceDetailsCardV2 = (props) => {
  const dispatch = useDispatch();
  const {
    deviceData,
    deviceTab,
    selectedDevices,
    disableDeviceSelection,
    onChangeDeviceSelection,
    showDeviceTabCheckBox,
    inUseButton,
    typeForDeviceModals,
    deviceMoreDetailsModal,
    deviceServiceFarm,
  } = props;
  const { stepTypeForDeviceModal } = useSelector((state) => state.ServerListReducer);
  const { miscDetails, device, deviceAcquireUserName, deviceAcquiredForRunNumberDetails, serverDetail } = deviceData;
  const {
    displayName,
    platformType,
    locale,
    osVer,
    targetUniqueId,
    name,
    brandName,
    platformName: lambdaTestPlatform,
    deviceName: lambdaTestDeviceName,
    platformVersion,
  } = deviceServiceFarm &&
  (deviceServiceFarm === DEVICE_EXECUTION_FARM.LAMBDA_TEST || deviceServiceFarm === DEVICE_EXECUTION_FARM.SAUCELABS)
    ? deviceData?.device
    : miscDetails?.deviceDetail;

  const { isDeviceOffline } = device;

  let shouldLoadDevice =
    miscDetails?.farmDetails?.deviceFarmUse !== SCENARIO_RECORDING ||
    (selectedDevices?.includes(device.targetUniqueId) &&
      miscDetails?.farmDetails?.deviceFarmUse === SCENARIO_RECORDING);

  const inUseShow =
    (deviceAcquireUserName && deviceAcquireUserName !== localStorage.getItem("requestedUser")) ||
    (deviceAcquiredForRunNumberDetails && deviceAcquiredForRunNumberDetails?.startedBy);

  const androidPlatform = platformType?.name === ANDROID || lambdaTestPlatform === LAMBDA_TEST_ANDROID;

  const isDeviceFarmUse = (key) => {
    switch (key) {
      case BOTH:
        return "For Recording and Execution";
      case SCENARIO_RECORDING:
        return "For Recording Only";
      case TEST_RUN_EXECUTION:
        return "For Execution Only";
      default:
        break;
    }
  };

  const getTooltipTitle = () => {
    const targetUniqueIdLength = device?.targetUniqueId?.length;
    if (
      (stepTypeForDeviceModal === SAUCELABS || stepTypeForDeviceModal === LAMBDA_TEST) &&
      targetUniqueIdLength >= 16
    ) {
      return device?.targetUniqueId;
    } else if (targetUniqueIdLength >= 37) {
      return device?.targetUniqueId;
    } else {
      return "";
    }
  };

  const getDeviceIdClass = () => {
    const targetUniqueIdLength = device?.targetUniqueId?.length;
    if (
      (stepTypeForDeviceModal === SAUCELABS || stepTypeForDeviceModal === LAMBDA_TEST) &&
      targetUniqueIdLength >= 16
    ) {
      return styles["saucelabs_Device_Id"];
    } else if (targetUniqueIdLength >= 37) {
      return styles["express_Device_Id"];
    } else {
      return "";
    }
  };

  const isCheckboxDisabled = () => {
    return (
      (checkNotUndefined(disableDeviceSelection) &&
        device?.isDeviceOffline &&
        !selectedDevices?.includes(device.targetUniqueId) &&
        deviceData?.isDeviceAcquired) ||
      !shouldLoadDevice
    );
  };
  const isChecked = () => {
    return selectedDevices?.includes(targetUniqueId);
  };

  const handleCheckboxChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    const isChecked = e.target.checked;
    let updatedDevices;
    if (!isChecked) {
      updatedDevices = selectedDevices.filter((device) => device !== value);
    } else {
      updatedDevices = [...selectedDevices, value];
    }
    onChangeDeviceSelection(updatedDevices);
  };

  const tooltipTitle = getTooltipTitle();
  const deviceIdClass = getDeviceIdClass();
  const deviceName = brandName + " " + displayName;

  const renderButton = (condition, text, buttonClass, textClass) => {
    return (
      condition &&
      !deviceMoreDetailsModal && (
        <div className={styles["in_Use_Button_Div"]}>
          <div className={`${styles["in_Use_Button"]} ${buttonClass}`}>
            <span className={`${styles["in_Use_Text"]} ${textClass}`}>{text}</span>
          </div>
        </div>
      )
    );
  };
  return (
    <div className={styles["positionOfCard"]}>
      <div>
        <div
          className={`${
            deviceMoreDetailsModal ? styles["device_Card_Details_In_Modal"] : styles["device_Card_Details"]
          } ${(inUseShow || isDeviceOffline) && !deviceMoreDetailsModal && styles["opacity_On_Card"]}`}
        >
          {!deviceMoreDetailsModal && typeForDeviceModals !== LAMBDA_TEST && (
            <div className={styles["info_Icon"]}>
              <SvgLoader
                iconName={INFO_ICON_NEW}
                iconColor={SILVER_GRAY}
                handleClick={() =>
                  dispatch(
                    visibleDeviceMoreDetailsModal(
                      true,
                      device?.targetUniqueId,
                      deviceData?.deviceAcquiredForRunNumberDetails,
                      typeForDeviceModals
                    )
                  )
                }
              />
            </div>
          )}
          <div
            className={`${deviceMoreDetailsModal && styles["device_Image_And_Details"]} ${
              typeForDeviceModals === LAMBDA_TEST && styles["device_Details_Of_Lambda_Test"]
            }`}
          >
            <div className={!deviceMoreDetailsModal && styles["device_Image"]}>
              <img src={deviceImage(deviceName, androidPlatform)} alt="device" />
            </div>
            <div className={!deviceMoreDetailsModal && styles["device_Details"]}>
              <div
                className={`${styles["device_Display_Name"]} ${
                  !deviceMoreDetailsModal && styles["display_Name_In_Card"]
                }`}
              >
                {lambdaTestDeviceName
                  ? lambdaTestDeviceName
                  : brandName
                  ? androidPlatform
                    ? `${capitalizeWords(brandName)} ${displayName}`
                    : displayName
                  : name}
              </div>
              <div className={styles.deviceOsORLocale}>
                {androidPlatform
                  ? capitalizeWords(platformType?.name || lambdaTestPlatform)
                  : lowerCaseWords(platformType?.name || lambdaTestPlatform)}
                <span className={styles["device_OsVer"]}>{osVer || platformVersion}</span>{" "}
                {locale && (
                  <>
                    <span>|</span> <span>{locale}</span>
                  </>
                )}
              </div>
              {deviceMoreDetailsModal && (
                <Tooltip title={tooltipTitle} color={TOOLTIP_BG_COLOR}>
                  <div className={styles["label"]}>
                    <span>Device ID:</span>
                    <div className={`${styles["deviceId_And_Usages"]} ${deviceIdClass}`}>{targetUniqueId}</div>
                  </div>
                </Tooltip>
              )}
              {deviceMoreDetailsModal && miscDetails?.farmDetails?.deviceFarmUse && (
                <div className={`${styles["label"]} ${styles["usages_Label"]}`}>
                  Usage:{" "}
                  <span className={styles["deviceId_And_Usages"]}>
                    {isDeviceFarmUse(miscDetails?.farmDetails?.deviceFarmUse)}
                  </span>
                </div>
              )}
              {deviceMoreDetailsModal && serverDetail?.serverIp && (
                <div className={`${styles["label"]} ${styles["usages_Label"]}`}>
                  Server IP: <span className={styles["deviceId_And_Usages"]}>{serverDetail?.serverIp}</span>
                </div>
              )}
            </div>
          </div>
          {!deviceMoreDetailsModal && (
            <div
              className={`${!inUseShow && !isDeviceOffline && inUseButton && styles["device_Card_Footer"]} ${
                deviceAcquireUserName &&
                deviceAcquireUserName === localStorage.getItem("requestedUser") &&
                styles["show_Device_Card_Footer"]
              } `}
            >
              {!inUseShow && !isDeviceOffline && inUseButton}
              {deviceTab && showDeviceTabCheckBox && shouldLoadDevice && (
                <div
                  className={`${styles["device_Card_Footer"]} ${styles.checkBoxOrIcon} ${styles["show_Device_Card_Footer"]}`}
                >
                  {miscDetails?.farmDetails?.deviceFarmUse === SCENARIO_RECORDING && (
                    <div>
                      <Popover
                        content={<div>Recording Device will ignore, during job execution</div>}
                        title="Execution Details"
                      >
                        <span>
                          <FontAwesomeIcon color={"#f7811c"} className="cursor-pointer ml-5" icon={faInfoCircle} />
                        </span>
                      </Popover>
                    </div>
                  )}
                  <div>
                    <Checkbox
                      disabled={isCheckboxDisabled()}
                      checked={isChecked()}
                      onChange={(e) => handleCheckboxChange(e)}
                      value={device?.targetUniqueId}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {renderButton(inUseShow, "In Use")}
        {renderButton(isDeviceOffline, "Offline", styles["offline_Button"], styles["offline_Text"])}
      </div>
      <DeviceMoreDetailsModalV2 data={deviceData} typeForDeviceModals={typeForDeviceModals} />
    </div>
  );
};

export default DeviceDetailsCardV2;
