import { Button, Input, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createFarm } from "../../actions/ManageFarmAction";
import { getDeviceFarmList } from "../../actions/ServerListAction";
import {
  BOTH,
  BOTH_LABEL,
  EXECUTION_LABEL,
  RECORDING_LABEL,
  SCENARIO_RECORDING,
  SUCCESS_STATUS,
  TEST_RUN_EXECUTION,
} from "../../Constants";
const { Option } = Select;

const CreateFarm = (props) => {
  const dispatch = useDispatch();
  const { modalStatus, modalClosed, projectId } = props;
  const { farmData } = useSelector((state) => state.ManageFarmReducer);

  const [farmName, setFarmName] = useState("");
  const [selectedExecutionType, setSelectedExecutionType] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (farmData && farmData.status === SUCCESS_STATUS) {
      handleCloseModal();
      setFarmName("");
      setSelectedExecutionType("");
      setIsLoading(false);
      dispatch(getDeviceFarmList({ projectId: projectId }));
    }
    handleCloseModal();
  }, [farmData]);

  const handleSaveButton = () => {
    let obj = {
      farmName: farmName,
      deviceFarmUse: selectedExecutionType,
      projectId: projectId,
    };
    if (obj) {
      dispatch(createFarm(obj));
      setIsLoading(true);
    }
  };

  function handleChange(value) {
    setSelectedExecutionType(value);
  }

  const handleCloseModal = () => {
    modalClosed();
    setFarmName("");
    setSelectedExecutionType("");
  };

  return (
    <div>
      <Modal
        title="Create Device Pool"
        visible={modalStatus}
        onOk={() => handleCloseModal()}
        onCancel={() => handleCloseModal()}
        footer={[
          <Button key="submit" type="primary" loading={isLoading} onClick={() => handleSaveButton()}>
            Create
          </Button>,
        ]}>
        <div className="mb-20">
          <span className="font-size-14 m-5"> Name: </span>
          <Input
            className="m-5"
            type="text"
            onChange={(e) => setFarmName(e.target.value)}
            value={farmName}
            placeholder="Please Enter Pool Name"
          />
        </div>
        <div>
          <span className="font-size-14 m-5"> Usage: </span>
          <Select className="mb-10 w-100 m-5" placeholder="Select Usage" onChange={handleChange}>
            <Option value={SCENARIO_RECORDING}>{RECORDING_LABEL}</Option>
            <Option value={TEST_RUN_EXECUTION}>{EXECUTION_LABEL}</Option>
            <Option value={BOTH}>{BOTH_LABEL}</Option>
          </Select>
        </div>
      </Modal>
    </div>
  );
};

export default CreateFarm;
