import { PlusCircleOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select, Space, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { CONFIRM_MODAL_CONTENT, TOOLTIP_BG_COLOR } from "../../../Constants";
import { ICON_DELETE_WITH_BG } from "../../../Constants/SvgConstants";
import { confirmModal } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import { resetDeviceStringsData } from "../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  addDeviceStringLocaleAndValue,
  addDeviceStrings,
  addDeviceStringsFormVisible,
  changeDeviceStringName,
  changeDeviceStringValue,
  deleteDeviceStringLocale,
  getListOfLocalesForDeviceStrings,
  selectDeviceStringLocale,
} from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { StyledInput } from "../../CoreComponents/StyledComponent/StyledComponents";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import styles from "./AddDeviceStrings.module.scss";

export const StyledSelect = styled(Select)`
  border: 1px solid #b0b1c1 !important;
  border-radius: 5px;
  .ant-select-selector {
    width: 99% !important;
    border-radius: 5px !important;
  }
`;

const AddDeviceStrings = (props) => {
  const dispatch = useDispatch();

  const {
    getListOfLocales,
    deviceStringName,
    deviceStringValue,
    selectLocaleOfDeviceString,
    deviceStringLoader,
    editDeviceStringData,
    previous_editDeviceStringData,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const addDisableForEditDeviceString = editDeviceStringData?.every(
    (item) => !Object.values(item).some((value) => value === "")
  );

  const enableSaveDeviceStringButton = editDeviceStringData
    ? !_.isEqual(editDeviceStringData, previous_editDeviceStringData) && addDisableForEditDeviceString
    : deviceStringName !== undefined && deviceStringValue !== undefined && selectLocaleOfDeviceString !== undefined;

  const backButton = editDeviceStringData
    ? !_.isEqual(editDeviceStringData, previous_editDeviceStringData)
    : deviceStringName !== undefined || deviceStringValue !== undefined || selectLocaleOfDeviceString !== undefined;

  const onBackClick = () => {
    if (backButton) {
      setOpenConfirmModal(true);
    } else {
      dispatch(addDeviceStringsFormVisible(false));
    }
  };

  useEffect(() => {
    dispatch(getListOfLocalesForDeviceStrings());
  }, [dispatch]);

  return (
    <>
      <Spin spinning={deviceStringLoader} tip="Loading">
        <Row justify="space-between">
          <div className={styles.deviceText}>
            <b>{`${editDeviceStringData ? "Edit" : "Add"} Device Strings`}</b>
          </div>
          <Space size={10} className={styles.addOrCancelBUtton}>
            <Button
              disabled={!enableSaveDeviceStringButton}
              type="primary"
              className={styles.addButton}
              onClick={() =>
                dispatch(
                  addDeviceStrings(
                    deviceStringName,
                    deviceStringValue,
                    selectLocaleOfDeviceString,
                    editDeviceStringData ? editDeviceStringData : []
                  )
                )
              }
            >
              {editDeviceStringData ? "Update" : "Add"}
            </Button>
            <Button onClick={() => onBackClick()}>Cancel</Button>
          </Space>
        </Row>

        <Row className={styles.content} justify="center">
          <Col xs={24} lg={9} className={styles.text}>
            <span className={styles.label}> Key Name</span>
            <div className={styles.deviceNameOrAddIcon}>
              <Input
                type="text"
                className={styles.deviceKeyInput}
                disabled={editDeviceStringData}
                value={deviceStringName}
                placeholder="Device String Name"
                onChange={(e) => dispatch(changeDeviceStringName(e.target.value))}
              />
              {editDeviceStringData && (
                <Button disabled={!addDisableForEditDeviceString}>
                  <Tooltip title={"Add Locale and value"} color={TOOLTIP_BG_COLOR}>
                    <PlusCircleOutlined
                      onClick={() => addDisableForEditDeviceString && dispatch(addDeviceStringLocaleAndValue())}
                      style={{ fontSize: "150%" }}
                    />
                  </Tooltip>
                </Button>
              )}
            </div>
            {editDeviceStringData ? (
              <Col xs={24}>
                {editDeviceStringData?.map((row) => {
                  return (
                    <div className={styles.selectOrValue} key={row.id}>
                      <div className={styles.localeOrSelect}>
                        <span>Locale:</span>
                        <Select
                          showSearch={true}
                          className="selectDeviceLocale"
                          value={row?.locale}
                          placeholder="Select Device String Locale"
                          onChange={(e) => {
                            dispatch(selectDeviceStringLocale(e, row?.id));
                          }}
                        >
                          {getListOfLocales &&
                            getListOfLocales?.map((i) => (
                              <Select.Option value={i?.locales}>{i?.locales}</Select.Option>
                            ))}
                        </Select>
                      </div>
                      <div className={styles.inputValue}>
                        <span>Value:</span>
                        <Input
                          className={styles.deviceValue}
                          type="text"
                          value={row?.value}
                          placeholder="Device String Value"
                          onChange={(e) => dispatch(changeDeviceStringValue(e.target.value, row?.id))}
                        />
                      </div>
                      {editDeviceStringData.length > 1 && (
                        <Tooltip title="Remove Device Locale and Value" color={TOOLTIP_BG_COLOR}>
                          <div className={styles.deviceDelete}>
                            <SvgLoader
                              iconName={ICON_DELETE_WITH_BG}
                              handleClick={() => dispatch(deleteDeviceStringLocale(row?.id))}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  );
                })}
              </Col>
            ) : (
              <Col>
                <span className={styles.label}>Value</span>
                <StyledInput
                  className={styles.deviceValue}
                  type="text"
                  value={deviceStringValue}
                  placeholder="Device String Value"
                  onChange={(e) => dispatch(changeDeviceStringValue(e.target.value))}
                />
                <span className={styles.label}>Locale:</span>
                <Select
                  showSearch={true}
                  className="selectDeviceLocale"
                  value={selectLocaleOfDeviceString}
                  placeholder="Select Device String Locale"
                  onChange={(e) => {
                    dispatch(selectDeviceStringLocale(e));
                  }}
                >
                  {getListOfLocales &&
                    getListOfLocales?.map((i) => <Select.Option value={i?.locales}>{i?.locales}</Select.Option>)}
                </Select>
              </Col>
            )}
          </Col>
        </Row>
      </Spin>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(addDeviceStringsFormVisible(false));
          dispatch(resetDeviceStringsData());
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default AddDeviceStrings;
