import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Collapse, Tooltip } from "antd";
import { STEP_DISABLED, TEST_SCENARIOS, TOOLTIP_BG_COLOR } from "../../../../Constants";
import {
  CONDITION_DELETE_IMAGE,
  STEPS_DRAG_AND_DROP_ICON,
  ICON_PERFORM_SAVE,
  RESET_ICON,
  SAVE_ICON,
} from "../../../../Constants/SvgConstants";
import {
  checkAnyPreviousContainsInObject,
  disableStepsDragging,
  dragHoverOnStepOrAction,
  isCurrentRecTestStepDisable,
  isNotNullAndNotEmptyAndNotUndefined,
} from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  captureExpandedSteps,
  showDeleteRecTestStepConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import {
  onRecTestStepDragOver,
  onRecTestStepDragStart,
  onRecTestStepDrop,
  selectRecTestSteps,
} from "../../../../actions/RecTestStepAction";
import UnSavedRecTestStepPopConfirm from "../../../RecTestStep/SingleColumnView/UnSavedRecTestStepPopConfirm";
import RecTestStepDetailV2 from "../../StepInnerComponents/RecTestStepDetailV2/RecTestStepDetailV2";
import PerformRecTestStepActionV2 from "../PerformRecTestStepActionV2/PerformRecTestStepActionV2";
import styles from "./RecTestStepActionDetailV2.module.scss";
import RecTestStepActionPanelHeaderV2 from "./RecTestStepActionPanelHeaderV2";

const { Panel } = Collapse;

const RecTestStepActionDetailV2 = (props) => {
  const {
    data,
    disableAction,
    projectId,
    isDeviceLoaded,
    index,
    parentRecTestStep,
    recTestSteps,
    showToggle,
    stepNo,
    stepId,
    parentSkipStep,
  } = props;
  const dispatch = useDispatch();

  const { isPreviewRunning, selectedRectTestScenarioStepId, selectedRecStepForOperation } = useSelector(
    (state) => state.RecTestStepReducer
  );
  const { unSavedRecStepTag, unSavedRecElementGuid, stepType, expandedStepTags, expandedStepAssocIds } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );

  const [isDragOver, setIsDragOver] = useState(false);
  const [dragOverOnCollapse, setDragOverOnCollapse] = useState(false);
  const [menuVisibleInActions, setMenuVisibleInActions] = useState(false);

  useEffect(() => {
    // add and remove class on hoverEffect
    const hoverMenuVisible = document.getElementsByClassName("visibleActionMenu");
    if (hoverMenuVisible[0]) {
      const collapseHeader = hoverMenuVisible[0].getElementsByClassName("ant-collapse-header");
      if (collapseHeader[0]) {
        collapseHeader[0].classList.add("actionsMenuOnHover");
        const actionsWrapper = collapseHeader[0].getElementsByClassName("showActionsIconOnHover");
        if (actionsWrapper[0]) {
          actionsWrapper[0].classList.add("iconsOnHoverOfActions");
        }
        return () => {
          collapseHeader[0] && collapseHeader[0].classList.remove("actionsMenuOnHover");
          actionsWrapper[0] && actionsWrapper[0].classList.remove("iconsOnHoverOfActions");
        };
      }
    }
  }, [menuVisibleInActions]);

  useEffect(() => {
    //add or remove collapseBorder color
    const collapseHeader = document.querySelectorAll(".actionCollapseBorder > div > div.ant-collapse-header");
    for (let i = 0; i < collapseHeader.length; i++) {
      collapseHeader[i].addEventListener("mouseenter", collapseBorderOnHover);
      collapseHeader[i].addEventListener("mouseleave", collapseBorderOnHoverOut);
    }
    return () => {
      for (let i = 0; i < collapseHeader.length; i++) {
        collapseHeader[i].removeEventListener("mouseenter", collapseBorderOnHover);
        collapseHeader[i].removeEventListener("mouseleave", collapseBorderOnHoverOut);
      }
    };
  }, []);

  function CollapseBorder(element, addClass) {
    const parentClassName = element.parentNode.parentNode.parentNode;
    if (parentClassName) {
      const stepOrderNameBorder = parentClassName.getElementsByClassName("recTestStepCollapseBorder");
      if (stepOrderNameBorder[0]) {
        if (addClass) {
          stepOrderNameBorder[0].classList.add("hover_Border_Dark_Blue");
        } else {
          stepOrderNameBorder[0].classList.remove("hover_Border_Dark_Blue");
        }
      }
    }
  }

  function collapseBorderOnHover() {
    CollapseBorder(this, true);
  }

  function collapseBorderOnHoverOut() {
    CollapseBorder(this, false);
  }

  //Destructure of data object
  const {
    isSkipStep,
    isTestBlock,
    recTestScenarioRecTestStepAssocId: scenarioAssocIdOfData,
    testBlockRecTestScenarioRecTestStepAssocId: testBlockAssocIdOfData,
    id: idOfData,
    recTestStepProperty: recTestStepPropertyOfData,
    tag: tagOfData,
    orderId,
    previewStatus,
  } = data ? data : {};
  const {
    isSkipStep: shipSTepOfRecTestProperty,
    action,
    isLoopStep,
    isStepHasPrecondition,
  } = data && data?.recTestStepProperty ? data?.recTestStepProperty : {};

  const {
    recTestScenarioRecTestStepAssocId: assocIdOfSelectedStep,
    testBlockRecTestScenarioRecTestStepAssocId: testBlockAssocIdOfSelectedStep,
    id: idOfSelectedStep,
  } = selectedRecStepForOperation ? selectedRecStepForOperation : {};

  const derivedValues = useMemo(() => {
    const disabledStep =
      stepType === TEST_SCENARIOS && isTestBlock === 0
        ? parentSkipStep || isSkipStep === 1 || shipSTepOfRecTestProperty === 1
        : false;

    const disabled = isCurrentRecTestStepDisable(unSavedRecStepTag, data, disableAction) || isPreviewRunning;

    const isDisableStep = parentSkipStep || isSkipStep === 1;

    const selectedStep =
      `${idOfSelectedStep},${assocIdOfSelectedStep || testBlockAssocIdOfSelectedStep}` ===
      `${idOfData},${scenarioAssocIdOfData || testBlockAssocIdOfData}`;

    const isUnsaved =
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag === tagOfData) ||
      checkAnyPreviousContainsInObject(recTestStepPropertyOfData);

    const isSelected =
      stepType === TEST_SCENARIOS
        ? selectedRectTestScenarioStepId?.includes(scenarioAssocIdOfData)
        : selectedRectTestScenarioStepId?.includes(idOfData);

    const selectedRectTestStep = !isSelected && selectedStep;
    const disabledAction = !disabled || !parentSkipStep || isSkipStep === 0;

    return {
      disabledStep,
      disabled,
      isDisableStep,
      selectedStep,
      isUnsaved,
      isSelected,
      selectedRectTestStep,
      disabledAction,
    };
  }, [
    stepType,
    isTestBlock,
    parentSkipStep,
    isSkipStep,
    shipSTepOfRecTestProperty,
    unSavedRecStepTag,
    data,
    disableAction,
    isPreviewRunning,
    idOfSelectedStep,
    assocIdOfSelectedStep,
    testBlockAssocIdOfSelectedStep,
    scenarioAssocIdOfData,
    idOfData,
    testBlockAssocIdOfData,
    recTestStepPropertyOfData,
    tagOfData,
    selectedRectTestScenarioStepId,
  ]);

  const { disabledStep, disabled, isDisableStep, selectedStep, isUnsaved, isSelected, disabledAction } = derivedValues;

  let recTestStepCollapseClassNames = `rec_Test_Step_Collapse ${
    dragOverOnCollapse && !isDisableStep && "border_onDragOver"
  } ${!isDisableStep && "recTestStepCollapseBorder"} actionCollapseBorder actionStepOrderBorder recTestStepActions `;
  if (isUnsaved) {
    recTestStepCollapseClassNames += "unsavedStepBorder ";
  } else if (isSelected) {
    recTestStepCollapseClassNames += "multiple_select_steps ";
  } else if (selectedStep) {
    recTestStepCollapseClassNames += "border_Blue ";
  }

  const handleDragLeave = useCallback(
    (event) => {
      const newTarget = event.relatedTarget;
      const id = document.getElementById(idOfData);
      if (id) {
        if (!id.contains(newTarget)) {
          setDragOverOnCollapse(false);
        }
      }
    },
    [idOfData, setDragOverOnCollapse]
  );

  return (
    <>
      <Tooltip color={TOOLTIP_BG_COLOR} title={isSkipStep === 1 ? STEP_DISABLED : ""}>
        {parentSkipStep ? (
          <div className="hoverDragAndDrop"></div>
        ) : (
          dragHoverOnStepOrAction(
            isDragOver,
            setIsDragOver,
            dispatch,
            disabled || parentSkipStep || isSkipStep === 1,
            data,
            parentRecTestStep,
            index
          )
        )}
        <div
          className={`${styles["styledStepHeader"]} ${menuVisibleInActions && !isDisableStep && "visibleActionMenu"}`}
          onDragStart={(event) =>
            disabledAction ? dispatch(onRecTestStepDragStart(event, data, index, parentRecTestStep)) : undefined
          }
          onDragOver={(event) =>
            disabledAction ? (setDragOverOnCollapse(true), dispatch(onRecTestStepDragOver(event))) : undefined
          }
          onDrop={(event) =>
            disabledAction
              ? (setDragOverOnCollapse(false), dispatch(onRecTestStepDrop(event, data, undefined, index)))
              : undefined
          }
          onDragLeave={handleDragLeave}
          id={idOfData}
        >
          <UnSavedRecTestStepPopConfirm
            recTestStep={data}
            key={idOfData}
            disabled={disabled}
            unSavedRecStepTag={unSavedRecStepTag}
            unSavedRecElementGuid={unSavedRecElementGuid}
          />
          <Collapse
            unSavedStep={
              (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag === tagOfData) ||
              checkAnyPreviousContainsInObject(recTestStepPropertyOfData)
            }
            activeKey={
              stepType === TEST_SCENARIOS && scenarioAssocIdOfData ? [...expandedStepAssocIds] : [...expandedStepTags]
            }
            className={(recTestStepCollapseClassNames += isDisableStep ? "disable_bg_color " : "bg__white ")}
            expandIcon={() => null}
            onChange={(values) => {
              dispatch(
                captureExpandedSteps(
                  tagOfData,
                  scenarioAssocIdOfData,
                  values.some(
                    (value) =>
                      value ==
                      (stepType === TEST_SCENARIOS && scenarioAssocIdOfData ? scenarioAssocIdOfData : tagOfData)
                  )
                )
              );
              dispatch(selectRecTestSteps(data));
            }}
            onDragLeave={handleDragLeave}
          >
            <Panel
              executionStatus={previewStatus}
              key={stepType === TEST_SCENARIOS && scenarioAssocIdOfData ? scenarioAssocIdOfData : tagOfData}
              header={
                <RecTestStepActionPanelHeaderV2
                  data={data}
                  disabled={disabled}
                  disabledStep={disabledStep}
                  stepId={stepId}
                  projectId={projectId}
                  isTestBlock={isTestBlock}
                  isDisableStep={isDisableStep}
                  parentSkipStep={parentSkipStep}
                  setDragOverOnCollapse={setDragOverOnCollapse}
                  handleDragLeave={handleDragLeave}
                  setMenuVisibleInActions={setMenuVisibleInActions}
                  menuVisibleInActions={menuVisibleInActions}
                  showToggle={showToggle}
                  stepNo={stepNo}
                  selectedStep={selectedStep}
                />
              }
              extra={
                <div className={styles["actionIcon"]}>
                  {unSavedRecStepTag === tagOfData && (
                    <div onClick={(e) => e.stopPropagation()} className={styles["performActions"]}>
                      <PerformRecTestStepActionV2
                        action={action}
                        data={data}
                        recTestStepId={idOfData}
                        recTestStepTag={tagOfData}
                        disabled={
                          disabled ||
                          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== tagOfData)
                        }
                        isDeviceLoaded={isDeviceLoaded}
                        projectId={projectId}
                        isPreviewRunning={isPreviewRunning}
                        saveIcon={SAVE_ICON}
                        performSaveIcon={ICON_PERFORM_SAVE}
                        resetIcon={RESET_ICON}
                      />
                      <Tooltip color={TOOLTIP_BG_COLOR} title="Remove Action">
                        <div className={styles["deleteActionIcon"]}>
                          <SvgLoader
                            iconName={CONDITION_DELETE_IMAGE}
                            disabled={
                              disabled ||
                              (stepType === TEST_SCENARIOS &&
                                isTestBlock === 1 &&
                                (isStepHasPrecondition === 1 || isLoopStep === 1))
                            }
                            handleClick={() =>
                              dispatch(
                                showDeleteRecTestStepConfirmationModal(
                                  data?.id,
                                  data?.tag,
                                  data?.recTestScenarioRecTestStepAssocId,
                                  false
                                )
                              )
                            }
                            width="2rem"
                            height="2rem"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  )}
                  {!disabledStep && (
                    <div
                      className={styles["dragAndDrop"]}
                      onDragStart={(event) =>
                        !disabled ? dispatch(onRecTestStepDragStart(event, data, index, parentRecTestStep)) : undefined
                      }
                      onDragOver={(event) => (!disabled ? dispatch(onRecTestStepDragOver(event)) : undefined)}
                      draggable={disableStepsDragging(data, unSavedRecStepTag, stepType) ? false : true}
                      onDrop={(event) =>
                        !disabled ? dispatch(onRecTestStepDrop(event, data, parentRecTestStep, index)) : undefined
                      }
                    >
                      <SvgLoader iconName={STEPS_DRAG_AND_DROP_ICON} width="0.75rem" height="0.5rem" cursorGrab="grab" />
                    </div>
                  )}
                </div>
              }
            >
              <RecTestStepDetailV2
                data={data}
                recTestSteps={recTestSteps}
                parentIndex={orderId}
                disabled={disabled}
                isSkipStep={disabledStep || parentSkipStep}
                isTestBlock={props.isTestBlock}
                projectId={projectId}
                isDeviceLoaded={isDeviceLoaded}
                stepId={stepId}
              />
            </Panel>
          </Collapse>
        </div>
      </Tooltip>
    </>
  );
};

export default RecTestStepActionDetailV2;
