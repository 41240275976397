import { Col, Row, Select, Spin } from "antd";
import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  changeGeneralTabData,
  getProjectDetailsForSetting,
  updateGeneralSettings,
} from "../../actions/ProjectSettingsActions";
import { getMyOrgUsers, myTeams } from "../../actions/TeamsAction";
import { ADD_MEMBERS, CHANGE_GENERAL_TAB_DATA, GENERAL } from "../../Constants";
import {
  isArrayNotUndefinedNotNullNotEmpty,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../Util";
import { BuildGeneralTabModal } from "./BuildProjectSettingModal";
import { Required, StyledInput, StyledTextArea, Title } from "../CoreComponents/StyledComponent/StyledComponents";
import { Label } from "../DeviceDetails/DeviceAssignmentModal";
import SaveResetButton from "../CoreComponents/SaveResetComponent/SaveResetButton";

const { Option } = Select;

const GeneralTab = (props) => {
  const dispatch = useDispatch();
  const { projectId, orgId, selectedTab } = props;
  const { projectLoading, generalTabDetails, getProjectDetailsById } = useSelector(
    (state) => state.ProjectSettingsReducer
  );
  const { myOrgUsers, myTeamsList } = useSelector((state) => state.TeamsReducer);

  useEffect(() => {
    dispatch(getProjectDetailsForSetting(projectId));
  }, [projectId]);

  useEffect(() => {
    dispatch(myTeams(orgId));
    dispatch(getMyOrgUsers(orgId));
  }, [orgId]);

  const membersOfChildren = [];
  const teamOfMembersOfChildren = [];

  if (myOrgUsers) {
    for (let i = 0; i < myOrgUsers.length; i++) {
      membersOfChildren.push(
        <Option key={myOrgUsers[i].email} value={myOrgUsers[i].email}>
          {myOrgUsers[i].email}
        </Option>
      );
    }
  }
  if (myTeamsList) {
    for (let i = 0; i < myTeamsList.length; i++) {
      teamOfMembersOfChildren.push(
        <Option key={myTeamsList[i].teamId} value={myTeamsList[i].teamId}>
          {myTeamsList[i].teamName}
        </Option>
      );
    }
  }

  const updateGeneralSettingsData = () => {
    if (
      isNotNullAndNotEmptyAndNotUndefined(generalTabDetails.projectName) &&
      isNotNullAndNotEmptyAndNotUndefined(generalTabDetails.appName) &&
      isArrayNotUndefinedNotNullNotEmpty(generalTabDetails.selectedMembers)
    ) {
      dispatch(updateGeneralSettings(projectId));
    } else {
      showNotification("info", "Please Enter All details.");
    }
  };

  const resetGeneralSettingsData = () => {
    if (!_.isEmpty(getProjectDetailsById)) {
      dispatch({
        type: CHANGE_GENERAL_TAB_DATA,
        data: new BuildGeneralTabModal(getProjectDetailsById),
      });
    } else {
      dispatch({
        type: CHANGE_GENERAL_TAB_DATA,
        data: new BuildGeneralTabModal({}),
      });
    }
  };

  const isValid = () =>
    _.isEqual(getProjectDetailsById.appName, generalTabDetails.appName) &&
    _.isEqual(getProjectDetailsById.projectName, generalTabDetails.projectName) &&
    _.isEqual(getProjectDetailsById.description, generalTabDetails.projectDes) &&
    _.isEqual(getProjectDetailsById.teamId, generalTabDetails.selectedTeam) &&
    _.isEqual(
      getProjectDetailsById.members.map((i) => i.email),
      generalTabDetails.selectedMembers
    );

  const disableResetButton = () =>
    (!_.isEmpty(getProjectDetailsById) && isValid()) ||
    (isNullOrUndefinedOrEmpty(generalTabDetails.appName) &&
      isNullOrUndefinedOrEmpty(generalTabDetails.projectName) &&
      isNullOrUndefinedOrEmpty(generalTabDetails.selectedTeam) &&
      isNullOrUndefinedOrEmpty(generalTabDetails.selectedMembers));

  const disableUpdateButton = () => {
    let isDisable = false;
    if (!_.isEmpty(getProjectDetailsById)) {
      isDisable =
        isValid() ||
        isNullOrUndefinedOrEmpty(generalTabDetails.appName) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.projectName) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.selectedTeam) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.selectedMembers);
    } else {
      isDisable =
        isNullOrUndefinedOrEmpty(generalTabDetails.appName) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.projectName) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.selectedTeam) ||
        isNullOrUndefinedOrEmpty(generalTabDetails.selectedMembers);
    }
    return isDisable;
  };

  return (
    <Spin spinning={projectLoading} tip="Loading" className="spinner-center">
      <Row justify="space-between" className="innerHeader p-12">
        <div className="mainHeaderText ml-10">Project Details</div>
        <SaveResetButton
          submitLabel={"Update"}
          resetLabel={"Reset"}
          submitDisable={disableUpdateButton()}
          resetDisable={disableResetButton()}
          handleSubmit={updateGeneralSettingsData}
          handleReset={resetGeneralSettingsData}
        />
      </Row>
      <Row justify="center" className="pageScroll">
        <Col xs={{ span: 24 }} lg={{ span: 14 }}>
          <Row className="mt-30 mb-30" gutter={50}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 6, offset: 1 }}>
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Basic Details</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <Label>App Name</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                color="#111548"
                value={generalTabDetails.appName}
                onChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      appName: e.target.value,
                    })
                  );
                }}
                placeholder="Please Enter App Name"
                autoFocus
              />
              <Label>Project Name</Label>
              <Required>*</Required>
              <StyledInput
                className="mb-20 mt-5"
                value={generalTabDetails.projectName}
                onChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      projectName: e.target.value,
                    })
                  );
                }}
                placeholder="For Example: Development Or QA"
              />
              <Label>Project Description</Label>
              <Required>*</Required>
              <StyledTextArea
                rows={4}
                className="mt-5"
                value={generalTabDetails.projectDes}
                onChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      projectDes: e.target.value,
                    })
                  );
                }}
                placeholder="Insert Project Description."
              />
            </Col>
          </Row>
          <hr className="custom-hr" />
          <Row className="mt-30 mb-30" gutter={50}>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 6, offset: 1 }}
              className="font-size-16 font-weight-500 font-color-darkBlue"
            >
              <h3 className="font-size-16 font-weight-500 font-color-darkBlue">Team & Members</h3>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 17 }} className="text">
              <Label>Team</Label>
              <Required>*</Required>
              <Select
                className="mb-20 mt-5 w-100"
                placeholder="Select Team"
                value={
                  myTeamsList && myTeamsList?.some((i) => i?.teamId === generalTabDetails.selectedTeam)
                    ? generalTabDetails.selectedTeam
                    : undefined
                }
                onChange={(value) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      selectedTeam: value,
                    })
                  );
                }}
              >
                {teamOfMembersOfChildren}
              </Select>
              <Label>Platform</Label>
              <Required>*</Required>
              <Select
                disabled={true}
                className="mb-20 mt-5 w-100"
                value={(generalTabDetails.platform && generalTabDetails.platform.name) || "ANDROID"}
                placeholder={"Select Platform"}
              ></Select>
              <Label>Members</Label>
              <Required>*</Required>
              <Select
                className="mt-5 w-100"
                mode="multiple"
                placeholder={ADD_MEMBERS.project}
                value={generalTabDetails.selectedMembers && generalTabDetails.selectedMembers.map((i) => i)}
                onChange={(e) => {
                  dispatch(
                    changeGeneralTabData({
                      ...generalTabDetails,
                      selectedMembers: e,
                    })
                  );
                }}
              >
                {membersOfChildren}
              </Select>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default GeneralTab;
