import { Drawer, Popover, Space, Spin, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editRecTestStep } from "../../../../actions/RecTestStep/RecTestStepCommonAction";
import {
  addVerificationRow,
  applicationDrawerVisible,
  getApplicationStrings,
  selectedAIApplicationStringValue,
  selectedApplicationStringValue,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import FilterComponent from "../../../CoreComponents/FilterComponent/FilterComponent";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import CommonSearch from "../../../CoreComponents/SearchComponent/CommonSearch";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";
import { TOOLTIP_BG_COLOR } from "../../../../Constants";
import { ICON_DELETE_WITH_BG } from "../../../../Constants/SvgConstants";
import { getUniqueId, isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import styles from "./ApplicationStringDrawer.module.scss";

const ApplicationStringDrawer = (props) => {
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  const { recElement, projectId, recTestStep, disabled } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const {
    showApplicationDrawerVisible,
    getApplicationString,
    getListOfLocales,
    filterFetchingApplicationStringsList,
    applicationStringLoader,
    selectedRecStepForOperation,
    selectedElementGuidForDetail,
    selectedRecTestStepTag,
    selectedLocale,
    unSavedRecElementGuid,
    guidForOpenApplicationDrawer,
  } = useSelector((state) => state.RecTestStepCommonViewReducer);

  const handleSelectTestData = (selectData, isAIStep) => {
    if (isAIStep == 1) {
      dispatch(selectedAIApplicationStringValue(selectData, selectedRecTestStepTag));
    } else {
      dispatch(editRecTestStep(selectedRecStepForOperation?.tag));
      dispatch(selectedApplicationStringValue(selectData, selectedRecTestStepTag, selectedElementGuidForDetail));
      if (selectData !== undefined) {
        const newUuid = getUniqueId();
        dispatch(addVerificationRow("VerifyElementText", undefined, recTestStep.tag, recElement?.guid, newUuid));
        dispatch(
          updateVerificationKey("Equals to Locale value of App String", newUuid, recTestStep.tag, recElement?.guid)
        );
      }
    }
  };

  useEffect(() => {
    setSearchKeyword("");
  }, [showApplicationDrawerVisible, filterFetchingApplicationStringsList]);

  const showApplicationStringValues = (values) => {
    const val = values?.filter((i, j) => {
      return j >= 2 && <div className={styles.ellipse}>{i.locale + " : " + i.value}</div>;
    });
    if (values.length > 2) {
      return (
        <div className={styles.showValues}>
          {values?.map((s, index) => {
            return index < 2 && <div className={styles.ellipse}>{s.locale + " : " + s.value}</div>;
          })}
          <Popover
            width="50%"
            content={
              <div className={styles.showPopover}>
                {values?.map((i, j) => {
                  return j >= 2 && <div className={styles.ellipse}>{i.locale + " : " + i.value}</div>;
                })}
              </div>
            }
            title="Values"
            trigger="hover"
            placement="bottom"
          >
            <span className={styles.showLength}>
              {val?.length === 1 ? `+${val?.length} other` : `+${val?.length} others`}
            </span>
          </Popover>
        </div>
      );
    } else {
      return (
        <>
          {!_.isEmpty(values)
            ? values.map((s) => <div className={styles.ellipse}>{s.locale + " : " + s.value}</div>)
            : " N/A"}
        </>
      );
    }
  };

  const uniqueLocales = getApplicationString?.data
    ?.flatMap((ele) => ele?.values?.map((ele) => ele?.locale))
    ?.filter((item, index, array) => array?.indexOf(item) === index);
  const filteredLocales = getListOfLocales && getListOfLocales?.filter((ele) => uniqueLocales?.includes(ele?.locales));

  const filterList = [
    {
      title: "All",
      displayName: "All",
      displayCountry: "All",
      locales: "",
    },
    ...filteredLocales,
  ];

  const columns = [
    {
      title: "Key",
      dataIndex: "name",
      width: 400,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "",
      dataIndex: "selectData",
    },
  ];

  const applicationData =
    getApplicationString &&
    getApplicationString?.data?.map((row) => ({
      key: row?.key,
      name: <div className={styles.applicationStringKey}>{row?.key}</div>,
      value: (
        <Popover
          color={TOOLTIP_BG_COLOR}
          title="Locales"
          content={row?.values.map((data, i) => {
            return (
              <div key={data}>
                <span style={{ marginRight: 5 }}>{i + 1}</span>
                {data.locale + " : " + data.value}
                <br></br>
              </div>
            );
          })}
        >
          {row?.values?.length + `${row?.values?.length === 1 ? " Locale " : " Locales"}`}
        </Popover>
      ),
      selectData: (
        <span
          className={`${styles.attachText} ${disabled && styles.disableAttachText}`}
          onClick={(e) => {
            if (!disabled) {
              e.preventDefault();
              handleSelectTestData(row, recTestStep?.recTestStepProperty?.isAIStep);
              dispatch(applicationDrawerVisible(false));
            }
          }}
        >
          Attach
        </span>
      ),
    }));

  const selectedApplicationStringColumns = [
    {
      title: "Key",
      dataIndex: "name",
      width: 400,
    },
    {
      title: "Values",
      dataIndex: "value",
    },
    {
      title: "Action",
      dataIndex: "action",
    },
  ];
  const selectedApplicationStringData =
    recTestStep?.recTestStepProperty?.isAIStep == 1
      ? [
          {
            key: recTestStep?.recTestStepProperty?.aiApplicationStringKey,
            name: (
              <div className={styles.applicationStringKey}>
                {recTestStep?.recTestStepProperty?.applicationStrings?.key}
              </div>
            ),
            value: (
              <Popover
                color={TOOLTIP_BG_COLOR}
                title="Locales"
                content={recTestStep?.recTestStepProperty?.applicationStrings?.values?.map((data, i) => {
                  return (
                    <div key={data}>
                      <span style={{ marginRight: 5 }}>{i + 1}</span>
                      {data.locale + " : " + data.value}
                      <br></br>
                    </div>
                  );
                })}
              >
                {recTestStep?.recTestStepProperty?.applicationStrings?.values?.length +
                  `${
                    recTestStep?.recTestStepProperty?.applicationStrings?.values?.length === 1 ? " Locale " : " Locales"
                  }`}
              </Popover>
            ),
            locales: <div>{recTestStep?.recTestStepProperty?.applicationStrings?.locale}</div>,
            action: (
              <div>
                <Tooltip title={"Remove application string"} color={TOOLTIP_BG_COLOR}>
                  <SvgLoader
                    iconName={ICON_DELETE_WITH_BG}
                    disabled={props.disabled}
                    handleClick={() => {
                      if (!props.disabled) {
                        dispatch(selectedAIApplicationStringValue(undefined, recTestStep?.tag));
                      }
                    }}
                  />
                </Tooltip>
              </div>
            ),
          },
        ]
      : [
          {
            key: recElement?.applicationStrings?.key,
            name: <div className={styles.applicationStringKey}>{recElement?.applicationStrings?.key}</div>,
            value: (
              <Popover
                color={TOOLTIP_BG_COLOR}
                title="Locales"
                content={recElement?.applicationStrings?.values?.map((data, i) => {
                  return (
                    <div key={data}>
                      <span style={{ marginRight: 5 }}>{i + 1}</span>
                      {data.locale + " : " + data.value}
                      <br></br>
                    </div>
                  );
                })}
              >
                {recElement?.applicationStrings?.values?.length +
                  `${recElement?.applicationStrings?.values?.length === 1 ? " Locale " : " Locales"}`}
              </Popover>
            ),
            locales: <div>{recElement?.applicationStrings?.locale}</div>,
            action: (
              <div>
                <Tooltip title={"Remove application string"} color={TOOLTIP_BG_COLOR}>
                  <SvgLoader
                    iconName={ICON_DELETE_WITH_BG}
                    disabled={
                      props.disabled ||
                      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
                        unSavedRecElementGuid !== recElement.guid)
                    }
                    handleClick={() => {
                      if (!props.disabled && (!unSavedRecElementGuid || unSavedRecElementGuid === recElement.guid)) {
                        dispatch(selectedApplicationStringValue(undefined, recTestStep?.tag, recElement?.guid));
                      }
                    }}
                  />
                </Tooltip>
              </div>
            ),
          },
        ];

  return (
    <Drawer
      className={![recElement?.guid, recTestStep?.tag].includes(guidForOpenApplicationDrawer) && styles.hideDrawer}
      title="Application Strings"
      visible={showApplicationDrawerVisible}
      width={700}
      onClose={() => {
        dispatch(applicationDrawerVisible(false));
      }}
    >
      <Spin spinning={applicationStringLoader} tip="Loading">
        {recTestStep?.recTestStepProperty?.isAIStep == 0 && recElement?.applicationStrings?.key && (
          <>
            <div className={styles.applicationTitle}>Attached App String Key:</div>
            <div className={styles.selectedString}>
              <TableComponent columns={selectedApplicationStringColumns} data={selectedApplicationStringData} />
            </div>
          </>
        )}
        {recTestStep?.recTestStepProperty?.isAIStep == 1 &&
          recTestStep?.recTestStepProperty?.aiApplicationStringKey && (
            <>
              <div className={styles.applicationTitle}>Attached App String Key:</div>
              <div className={styles.selectedString}>
                <TableComponent columns={selectedApplicationStringColumns} data={selectedApplicationStringData} />
              </div>
            </>
          )}

        <div className={styles.searchBoxOrFilter}>
          <Space size={5}>
            {showApplicationDrawerVisible && (
              <CommonSearch
                placeHolder="Search"
                searchDetails={(searchText) =>
                  dispatch(
                    getApplicationStrings(projectId, searchText, selectedLocale, "", recElement.guid, recTestStep.tag)
                  )
                }
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
                inputRef={inputRef}
              />
            )}
            <FilterComponent
              filterList={filterList}
              handleFilterChange={(e) => {
                dispatch(getApplicationStrings(projectId, searchKeyword, e, "", recElement.guid, recTestStep.tag));
              }}
              selectedFilter={filterFetchingApplicationStringsList}
              string={true}
            />
          </Space>
        </div>
        <div className={styles.tableComponent}>
          <TableComponent columns={columns} data={applicationData} />
        </div>

        {!_.isEmpty(getApplicationString?.data) && (
          <div className={styles.pagination}>
            <PaginationComponent
              currentPageItems={getApplicationString?.pageItems}
              totalItems={getApplicationString?.totalItems}
              currentPage={getApplicationString?.currentPage}
              totalPage={getApplicationString?.totalCount}
              callPaginationAPI={(pageNumber) => {
                dispatch(
                  getApplicationStrings(
                    projectId,
                    searchKeyword,
                    selectedLocale,
                    pageNumber,
                    recElement.guid,
                    recTestStep.tag
                  )
                );
              }}
            />
          </div>
        )}
      </Spin>
    </Drawer>
  );
};

export default ApplicationStringDrawer;
