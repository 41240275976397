import { async } from "../api/automation";
import {
  ADD_MULTI_COLUMN_VIEW_STEP,
  CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME,
  CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW,
  MULTI_COLUMN_VIEW_DATA_LOADING,
  MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING,
  REC_TEST_STEP_INNER_DETAILS_LOADING,
  RESET_IMPORT_REC_TEST_DATA,
  RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME,
  RESET_REC_TEST_DATA,
  SELECTED_REC_TEST_STEP_DETAIL,
  SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL,
  SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL,
  SELECTED_RENAME_STEP_TAG,
  SUCCESS_STATUS,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UPDATE_UNSAVED_REC_TEST_STEP_PATH,
} from "../Constants";
import { updateResponse } from "../Util";
import { _selectedRecTestStepTag, _updateUnSavedRecTestStep } from "./RecTestStep/RecTestStepCommonAction";
import { changeRecTestStepActionName } from "./RecTestStep/RecTestStepRedirectAction";

export function getStepBasicDetailByRecTestScenarioId(data) {
  return (dispatch, getState) => {
    const { unSavedRecTestStep } = getState().RecTestStepCommonViewReducer;
    dispatch({ type: MULTI_COLUMN_VIEW_DATA_LOADING, response: { isLoading: true } });
    async("recTestScenarioRecTestStepAssoc/getStepBasicDetailByRecTestScenarioId", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        new Promise((resolve) => {
          resolve(dispatch({ type: ADD_MULTI_COLUMN_VIEW_STEP, response }));
        }).then((res) => {
          if (unSavedRecTestStep) {
            dispatch(fetchRecTestStepDetail(unSavedRecTestStep));
          }
        });
        dispatch({ type: MULTI_COLUMN_VIEW_DATA_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function getStepBasicDetailByTestBlockId(data) {
  return (dispatch, getState) => {
    const { unSavedRecTestStep } = getState().RecTestStepCommonViewReducer;
    dispatch({ type: MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING, response: { isLoading: true } });
    async("recTestStepBlock/getStepBasicDetailByRecTestStepId", "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        new Promise((resolve) => {
          resolve(dispatch({ type: ADD_MULTI_COLUMN_VIEW_STEP, response }));
        }).then((res) => {
          if (unSavedRecTestStep) {
            dispatch(fetchRecTestStepDetail(unSavedRecTestStep));
          }
        });
        dispatch({ type: MULTI_COLUMN_VIEW_TEST_BLOCK_DATA_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function clearSelectedRecTestStepDetail() {
  return (dispatch, getState) => {
    dispatch(_updateUnSavedRecTestStep(undefined));
    dispatch({ type: CLEAR_SELECTED_REC_TEST_STEP_DETAIL_FOR_MULTI_COLUMN_VIEW });
  };
}

export function fetchRecTestStepDetail(recTestStep, loadStepConditionalStep) {
  return (dispatch, getState) => {
    const { selectedRecTestScenarioId } = getState().RecTestStepReducer;
    const { testBlockStep } = getState().TestBlockReducer;
    const { stepType } = getState().RecTestStepCommonViewReducer;
    let service = undefined;
    let data = undefined;

    if (recTestStep.isTestBlock === 1 || recTestStep.recTestScenarioRecTestStepAssocId === 0) {
      service = "recTestStep/getDetailsById";
      data = {
        id: recTestStep.recTestStepId ? recTestStep.recTestStepId : recTestStep.id,
        recTestScenarioId: stepType === TEST_SCENARIOS ? selectedRecTestScenarioId : 0,
        recTestStepId: stepType === TEST_STEP_BLOCKS ? testBlockStep.id : 0,
      };
    } else {
      service = "recTestScenarioRecTestStepAssoc/getRecTestStepById";
      data = {
        recTestScenarioRecTestStepAssocId: recTestStep.recTestScenarioRecTestStepAssocId,
        recTestScenarioId: stepType === TEST_SCENARIOS ? selectedRecTestScenarioId : 0,
        recTestStepId: stepType === TEST_STEP_BLOCKS ? testBlockStep.id : 0,
      };
    }
    dispatch({ type: REC_TEST_STEP_INNER_DETAILS_LOADING, response: { isLoading: true } });
    async(service, "GET", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        // dispatch(_selectedRecTestStepTag(response.data));
        dispatch(updateUnSavedRecTestStepPath(response.data.stepPath));
        dispatch({
          type: SELECTED_REC_TEST_STEP_DETAIL,
          response,
          recTestScenarioRecTestStepAssocId: recTestStep.recTestScenarioRecTestStepAssocId,
          recTestStepId: recTestStep.recTestStepId,
          loadStepConditionalStep,
        });
        dispatch({ type: REC_TEST_STEP_INNER_DETAILS_LOADING, response: { isLoading: false } });
      }
    });
  };
}

export function loadPreconditionStep(recTestStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SELECTED_REC_TEST_STEP_PRECONDITION_DETAIL,
      selectedRecTestStepDetail: recTestStep,
    });
  };
}

export function loadLoopConditionStep(recTestStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SELECTED_REC_TEST_STEP_LOOP_CONDITION_DETAIL,
      selectedRecTestStepDetail: recTestStep,
    });
  };
}

export function resetRecTestData() {
  return (dispatch) => {
    return dispatch({ type: RESET_REC_TEST_DATA });
  };
}

export function resetImportRecTestData() {
  return (dispatch) => {
    return dispatch({ type: RESET_IMPORT_REC_TEST_DATA });
  };
}

export function changeMultipleColumnViewStepName(value, recTestStepTag, isForConditionTitle) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_MULTIPLE_COLUMN_VIEW_STEP_NAME, value, isForConditionTitle });
  };
}

export function resetMultipleColumnViewStepName(value) {
  return async (dispatch) => {
    dispatch({ type: RESET_MULTIPLE_COLUMN_VIEW_STEP_NAME, value });
  };
}

export const selectedRenameStepTag = (selectedRenameStepTag) => {
  return async (dispatch) => {
    dispatch({ type: SELECTED_RENAME_STEP_TAG, selectedRenameStepTag });
  };
};

export const updateUnSavedRecTestStepPath = (stepPath) => {
  return async (dispatch) => {
    dispatch({ type: UPDATE_UNSAVED_REC_TEST_STEP_PATH, stepPath });
  };
};
