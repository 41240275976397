import { Col, Input, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { APP_MANAGER, BUILD_URL, DEVICE_EXECUTION_FARM, EXECUTION_JOB, USE_LATEST_BUILD, NONE } from "../../../../../Constants";
import { changeTestJobData } from "../../../../../actions/TestJobActions";
import AppBuildList from "../../../../BuildManagement/BuildList";
import AppBuildListV2 from "../../../../BuildManagementV2/AppBuildListV2/AppBuildListV2";

const SelectBuildTab = (props) => {
  const { projectId, testJobDetail, orgId ,showDeviceView} = props;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    dispatch(
      changeTestJobData({
        ...testJobDetail,
        buildType: e.target.value,
        buildDetail: { id: 0 },
        fileUrl: undefined,
        isUseLatestBuild: 0
      })
    );
  };

  return (
    <>
      <div className="m-10">
        <div className="modal-title">Build</div>
        <div className="display-flex mt-10">
          <div>
            {testJobDetail.deviceServiceFarm === DEVICE_EXECUTION_FARM.XPRESS && (
              <div className={`mr-10 ${testJobDetail.buildType === NONE ? "activeRadioBox" : "radioBox"}`}>
                <input
                  className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
                  checked={testJobDetail.buildType === NONE}
                  type="radio"
                  onChange={handleChange}
                  name="buildType"
                  value={NONE}
                />
                <span className="vertical-align-middle">Use Installed version on Device</span>
              </div>
            )}
          </div>
          <div className={`mr-10 ${testJobDetail.buildType === APP_MANAGER ? "activeRadioBox" : "radioBox"}`}>
            <input
              className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
              checked={testJobDetail.buildType === APP_MANAGER}
              type="radio"
              onChange={handleChange}
              name="buildType"
              value={APP_MANAGER}
            />
            <span className="vertical-align-middle">App Manager</span>
          </div>
          <div className={testJobDetail.buildType === BUILD_URL ? "activeRadioBox" : "radioBox"}>
            <input
              className="vertical-align-middle h-17 w-18 mr-5 cursor-pointer"
              checked={testJobDetail.buildType === BUILD_URL}
              type="radio"
              onChange={handleChange}
              name="buildType"
              value={BUILD_URL}
            />
            <span className="vertical-align-middle">Build URL</span>
          </div>
        </div>
        {testJobDetail.buildType === APP_MANAGER && (
          <Col span={24} className="mt-20">
            <AppBuildListV2
              projectId={projectId}
              executionTab={true}
              orgId={orgId}
              testJobDetail={testJobDetail}
              changeTestJobData={changeTestJobData}
              stepType={EXECUTION_JOB}
              showDeviceView={showDeviceView}
            />
          </Col>
        )}
        {testJobDetail.buildType === BUILD_URL && (
          <div className="mt-20 width-50">
            <div className="font-size-14 font-weight-500 mb-5">Build URL:</div>
            <Input
              className="w-100 border-radius-4"
              placeholder="Enter The URL For The Build To Use"
              value={testJobDetail.fileUrl || "https://"}
              onChange={(e) =>
                dispatch(changeTestJobData({ ...testJobDetail, fileUrl: e.target.value, buildDetail: { id: 0 } }))
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SelectBuildTab;
