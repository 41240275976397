import _ from "lodash";
export class BuildErrorInvestigationStateModal {
  constructor(responseData) {
    //For Get API
    if (responseData) {
      this.elementDisplayName = responseData?.errorDetails?.elementDisplayName;
      this.actionName = responseData?.errorDetails?.action;
      this.errorMessage = responseData?.errorDetails?.errorMessage;
      this.runNumber = responseData?.errorDetails?.runNumber;
      this.failureReason = responseData?.investigationDetails?.failureReason;
      this.failureScreenshotLink = responseData?.errorDetails?.failureScreenshotLink;
      this.bugUrl = responseData?.productBugDetails?.bugUrl;
      this.bugStatus = responseData?.productBugDetails?.bugStatus;
      this.bugId = responseData?.productBugDetails?.id;
      this.errorDescription = responseData?.investigationDetails?.errorDescription;
      this.previousTestStepId =
        (responseData["selectedRunIds"] &&
          responseData["selectedRunIds"][0] &&
          responseData["selectedRunIds"][0]["testStepId"] &&
          responseData["selectedRunIds"][0]["testStepId"]) ||
        0;
      this.previousInvestigationId = 0;
      this.otherTestStepId = responseData?.idData?.sameInvestigation || [];
      this.selectedRunIds = responseData["selectedRunIds"] && responseData["selectedRunIds"].map((i) => i.testStepId);
      this.id = responseData?.investigationDetails?.id;
      this.allOtherRunIds = _.cloneDeep(responseData?.idData);
    } else {
      this.elementDisplayName = undefined;
      this.errorMessage = undefined;
      this.actionName = undefined;
      this.runNumber = undefined;
      this.failureReason = undefined;
      this.failureScreenshotLink = undefined;
      this.bugUrl = "";
      this.errorDescription = "";
      this.otherTestStepId = [];
      this.selectedRunIds = [];
      this.previousTestStepId = 0;
      this.previousInvestigationId = 0;
      this.id = 0;
      this.bugStatus = undefined;
      this.bugId = 0;
      this.allOtherRunIds = {};
    }
  }
}
