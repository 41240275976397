import { Col, Row, Select } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import {
  removeVerificationRow,
  updateVerificationKey,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { verifyElementPropertiesText as properties } from "../../../../Constants";
import RemoveVerificationRow from "./RemoveVerificationRow";

const VerifyElementProperty = (props) => {
  const { data, disabled, recTestStep, recElementVerification } = props;

  const dispatch = useDispatch();
  data.uuid = data.id ? data.id : data.uuid;
  let allowedProperty = recElementVerification?.recElementProperties.map(item => item.name);
  let verifyElementPropertiesText = properties.filter(item => allowedProperty.includes(item.elementPropertyName));
  verifyElementPropertiesText.forEach((item) => {
    const verifyElement = recElementVerification?.recElementProperties?.find((e) => e.name === item.key);
    if (verifyElement) {
      item.value = verifyElement?.value;
    }
  });

  const checkableIndex = verifyElementPropertiesText.findIndex((item) => item.key === "checkable");
  const focusableIndex = verifyElementPropertiesText.findIndex((item) => item.key === "focusable");

  if (checkableIndex !== -1 || focusableIndex !== -1) {
    const checkableValue = verifyElementPropertiesText[checkableIndex].value === "true";

    verifyElementPropertiesText[checkableIndex].value = checkableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not checkable")].value =
      checkableValue ? "true" : "true";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "checked")].value =
      checkableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not checked")].value =
      checkableValue ? "true" : "false";

    const focusableValue = verifyElementPropertiesText[focusableIndex].value === "true";
    verifyElementPropertiesText[focusableIndex].value = focusableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not focusable")].value =
      focusableValue ? "true" : "true";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "focused")].value =
      focusableValue ? "true" : "false";
    verifyElementPropertiesText[verifyElementPropertiesText.findIndex((item) => item.key === "not focused")].value =
      focusableValue ? "true" : "false";
  }

  return (
    <React.Fragment>
      <Row className="verification-row">
        <Col md={23}>
          Verify Element State
          <Select
            disabled={disabled}
            className={"control"}
            value={data.key}
            onChange={(value) =>
              dispatch(updateVerificationKey(value, data.uuid, recTestStep.tag, recElementVerification?.guid))
            }
          >
            {verifyElementPropertiesText
              ?.filter((e) => {
                return e.value !== "false";
              })
              ?.map((v, k) => {
                return (
                  <Select.Option key={k} value={v.name}>
                    {v.name}
                  </Select.Option>
                );
              })}
          </Select>
        </Col>
        <Col md={1}>
          <RemoveVerificationRow
            disabled={disabled}
            parentId={data.uuid}
            recTestStep={props.recTestStep}
            removeVerificationRow={removeVerificationRow}
            recElement={recElementVerification}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default VerifyElementProperty;
