import { async } from "../api/automation";
import { GET_WORKSPACE, JOIN_WORKSPACE, SAVE_WORKSPACE } from "../Constants";

export function saveWorkspace(data) {
    return async (dispatch, getState) => {
      return async(`organization/save?orgName=${data}`, "POST", {orgName:data}).then((response) => { // will be update later
        dispatch({ type: SAVE_WORKSPACE, response });
      });
    };
  }

  export function getWorkspace(data) {
    return async (dispatch, getState) => {
    
      return async("teamUserAssoc/getUsersByTeamId", "GET",).then((response) => { //will be update later
        dispatch({ type: GET_WORKSPACE, response });
      });
    };
  }

export function joinWorkspace(data) {
    return async (dispatch, getState) => {
      return async(`organization/save?orgName=${data}`, "POST", {orgName:data}).then((response) => { // will be update later
        dispatch({ type: JOIN_WORKSPACE, response });
      });
    };
  }