import React from "react";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRecTestStep,
  hideDeleteRecTestStepConfirmationModal,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { RESET_DEVICE_SCREEN_SOURCE } from "../../../../Constants";

const RecTestStepDeleteModalV2 = () => {
  const dispatch = useDispatch();
  const { deleteRecTestStepModalVisible } = useSelector((state) => state.RecTestStepCommonViewReducer);

  return (
    <CommonConfirmationModalV2
      handleOk={() => {
        dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
        dispatch(deleteRecTestStep());
      }}
      handleCancel={() => dispatch(hideDeleteRecTestStepConfirmationModal())}
      modalOpen={deleteRecTestStepModalVisible}
      modalTitle={"Confirm Deletion"}
      modalContent={"Are you sure you want to delete this item?"}
      okText={"Delete"}
      isDeleteModal={true}
    />
  );
};

export default RecTestStepDeleteModalV2;
