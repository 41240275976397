import { Checkbox, Divider, Spin } from "antd";
import isEmpty from "lodash/isEmpty";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ACTION_VIEW,
  BUILD_TEST_PLAN,
  CUSTOM,
  MY_PLANS,
  OWNED,
  PRIVATE,
  PUBLIC,
  RECORD_10_PER_PAGE,
  TEST_PLAN,
} from "../../../Constants";
import { isNullOrUndefined, removeSelectedIds, selectAllData } from "../../../Util";
import { updateStepType } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { saveTestPlanRights } from "../../../actions/Recording";
import { changeTestPlanTab, deleteTestPlanDetail, getTestPlanDetailsList } from "../../../actions/TestPlanAction";
import CommonHeaderV2 from "../../CommonComponents/CommonHeaderV2/CommonHeaderV2";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import PermissionDrawerV2 from "../../CoreComponents/PermissionDrawer/PermissionDrawerV2/PermissionDrawerV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import AssignTestDataSetModal from "../../ExecutionManager/JobDesigner/BuildJob/JobCriteriaOptions/AssignTestDataSetModal/AssignTestDataSetModal";
import JobRunningThisPlanColumnV2 from "./TestPlanDetailsColumnsV2/JobRunningThisPlanColumnV2";
import ScenariosAndStepsV2 from "./TestPlanDetailsColumnsV2/ScenariosAndStepsV2";
import TestPlanInfoColumnsV2 from "./TestPlanDetailsColumnsV2/TestPlanInfoColumnV2";
import TestPlanTableActionsColumnV2 from "./TestPlanDetailsColumnsV2/TestPlanTableActionsColumnV2";
import TestPlanDetailsHeaderV2 from "./TestPlanDetailsHeaderV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import styles from "./TestPlanDetailsV2.module.scss";

const TestPlanDetailsV2 = (props) => {
  const dispatch = useDispatch();

  const { projectId, testPlanTab, testJobDetail, orgId } = props;

  const { testPlanDetailData, currentTestPlanPage, totalTestPlanPage, testPlanLoading, pageItems, totalPage } =
    useSelector((state) => state.TestPlanReducer);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedTestPlan, setSelectedTestPlan] = useState(undefined);
  const [selectAllTestPlan, setSelectAllTestPlan] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionTestPlan, setPermissionTestPlan] = useState(null);
  const [rights, setRights] = useState({ id: OWNED, value: MY_PLANS });
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [activeFilter, setActiveFilter] = useState(rights ? rights : { id: OWNED, value: MY_PLANS });
  const [openDeleteTestPlanModal, setOpenDeleteTestPlanModal] = useState({ visible: false, data: {} });

  useEffect(() => {
    dispatch(updateStepType(TEST_PLAN));
  }, []);

  useEffect(() => {
    if (!testJobDetail?.testPlan?.id || testJobDetail?.testPlan?.id !== 0) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: 1,
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeyword: searchKeyword,
          projectId: projectId,
          selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
          rights: !testJobDetail && rights?.id,
        })
      );
    }
    setSelectAllTestPlan([]);
  }, [projectId]);

  useEffect(() => {
    if (permissionTestPlan?.tesPlanRights?.data) {
      setMembers([...permissionTestPlan?.tesPlanRights?.data]);
    }
  }, [permissionTestPlan]);

  // reset privacy
  const setPermissionData = useCallback(() => {
    if (permissionTestPlan?.isPrivate && !isEmpty(permissionTestPlan?.tesPlanRights?.data)) {
      setPreviousPermission(permissionTestPlan?.tesPlanRights?.data);
      setMembers([...permissionTestPlan?.tesPlanRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  }, [permissionTestPlan]);

  // save privacy
  const savePermission = (Value, testPlanData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      testPlan: { id: testPlanData?.id },
      testPlanName: testPlanData?.name,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !isEmpty(result) ? result : [],
    };
    dispatch(saveTestPlanRights(obj, projectId, rights?.id));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const callPaginationAPI = (pageNumber) => {
    if (currentTestPlanPage !== pageNumber) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: pageNumber,
          recordPerPage: RECORD_10_PER_PAGE,
          projectId: projectId,
          searchKeyword: isNullOrUndefined(searchKeyword) ? "" : searchKeyword,
          rights: !testJobDetail && rights?.id,
          selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
        })
      );
    }
  };

  const handleActionChange = (actionName, testPlanData) => {
    setPermissionTestPlan(() => ({ ...testPlanData }));
    switch (actionName) {
      case "delete":
        deleteConfirm(testPlanData);
        break;
      case PRIVATE:
        savePermission(PRIVATE, testPlanData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, testPlanData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const deleteConfirm = (testPlanData) => {
    setOpenDeleteTestPlanModal({ visible: true, data: { ...testPlanData } });
  };

  const showCheckBoxInTableHeader = useMemo(() => {
    return (
      isEmpty(selectAllTestPlan) &&
      !testPlanTab &&
      !isEmpty(testPlanDetailData) &&
      testPlanDetailData?.some((i) => i?.allowDelete === ACTION_VIEW)
    );
  }, [selectAllTestPlan, testPlanTab, testPlanDetailData]);

  const columns = [
    {
      title: (
        <div className={showCheckBoxInTableHeader ? styles["checkBox-in-header"] : styles["table-header"]}>
          {showCheckBoxInTableHeader && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className={"selection-checkBox mr-26"}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestPlan([
                      ...selectAllTestPlan,
                      ...(
                        testPlanDetailData &&
                        testPlanDetailData?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestPlan.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestPlan(removeSelectedIds(selectAllTestPlan, testPlanDetailData));
                  }
                }}
              />
            </span>
          )}
          Test Plan Info
        </div>
      ),
      dataIndex: "testPlanInfo",
      width: 400,
    },
    {
      title: "Scenarios & Steps",
      dataIndex: "ScenariosAndSteps",
      width: 200,
    },
    {
      title: "Jobs Running This Plan",
      dataIndex: "jobsRunningThisPlan",
      width: 300,
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "right",
    },
  ];

  const testPlanDetails = testPlanDetailData?.map((row) => ({
    key: row?.id,
    testPlanInfo: (
      <TestPlanInfoColumnsV2
        row={row}
        testPlanTab={testPlanTab}
        testJobDetail={testJobDetail}
        selectAllTestPlan={selectAllTestPlan}
        setSelectAllTestPlan={setSelectAllTestPlan}
      />
    ),
    ScenariosAndSteps: <ScenariosAndStepsV2 row={row} />,
    jobsRunningThisPlan: <JobRunningThisPlanColumnV2 row={row} projectId={projectId} orgId={orgId} />,
    action: (
      <TestPlanTableActionsColumnV2
        testPlanTab={testPlanTab}
        row={row}
        setSelectedTestPlan={setSelectedTestPlan}
        testJobDetail={testJobDetail}
        handleActionChange={handleActionChange}
      />
    ),
  }));

  const showSearchBox = (shouldAutoFocus) => {
    return (
      <CommonSearchV2
        searchDetails={(searchWord) =>
          dispatch(
            getTestPlanDetailsList({
              pageNumber: currentTestPlanPage,
              recordPerPage: RECORD_10_PER_PAGE,
              searchKeyword: searchWord,
              projectId: projectId,
              rights: rights?.id,
              selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
            })
          )
        }
        placeHolder="Search By Test Plan Name"
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        autoFocus={shouldAutoFocus}
      />
    );
  };

  return (
    <>
      <Spin spinning={testPlanLoading} tip="Loading" className={styles["spinner_Center"]}>
        <div>
          {testPlanTab && (
            <div className={styles["test_Plan_Details_wrap"]}>
              <span className={styles["header_Title"]}>Test Plans</span>
              <div>{showSearchBox(false)}</div>
            </div>
          )}
          {!testPlanTab && (
            <CommonHeaderV2
              headerText={"Test Plans"}
              label={"Build Test Plan"}
              handleOk={() => {
                dispatch(changeTestPlanTab(BUILD_TEST_PLAN));
                setRights({ id: OWNED, value: MY_PLANS });
                setActiveFilter({ id: OWNED, value: MY_PLANS });
              }}
            />
          )}
        </div>
        <div>
          {!testPlanTab && (
            <TestPlanDetailsHeaderV2
              testPlanTab={testPlanTab}
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              setRights={setRights}
              projectId={projectId}
              testJobDetail={testJobDetail}
              searchKeyword={searchKeyword}
              selectAllTestPlan={selectAllTestPlan}
              setSelectAllTestPlan={setSelectAllTestPlan}
              showSearchBox={showSearchBox}
            />
          )}
          <div>
            <Divider className="m-0" />
            <div className={!testPlanTab && "table_Details_scroll"}>
              <div className="table_Details__wrapper">
                {!testPlanTab && !isEmpty(selectAllTestPlan) && (
                  <div className={styles["select--all-box"]}>
                    {selectAllData(selectAllTestPlan, setSelectAllTestPlan, testPlanDetailData, deleteConfirm)}
                  </div>
                )}
                <TableComponentV2
                  columns={columns}
                  data={testPlanDetails}
                  selected={selectAllTestPlan}
                  className={`table__wrapper ${!isEmpty(selectAllTestPlan) && " hide_column_name"}`}
                />
                <div className="pagination_Section">
                  {!isEmpty(testPlanDetailData) && (
                    <PaginationComponentV2
                      currentPageItems={pageItems}
                      totalItems={totalTestPlanPage}
                      currentPage={currentTestPlanPage}
                      totalPage={totalPage}
                      callPaginationAPI={callPaginationAPI}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {testJobDetail !== undefined && (
          <AssignTestDataSetModal
            testDataSetAssigneeDetail={testJobDetail}
            projectId={projectId}
            testPlanDetails={selectedTestPlan}
            stepType={TEST_PLAN}
          />
        )}
        <PermissionDrawerV2
          openPermissionDrawer={openPermissionDrawer}
          onClose={() => setOpenPermissionDrawer(false)}
          permissionData={permissionTestPlan}
          savePermission={savePermission}
          members={members}
          setMembers={setMembers}
          previousPermission={previousPermission}
          setPermissionData={setPermissionData}
          ownerEmailId={permissionTestPlan?.createdBy}
          notShowExecutionCheck={true}
          drawerTitle={"Test Plan"}
        />
        <CommonConfirmationModalV2
          handleOk={() => {
            dispatch(
              deleteTestPlanDetail(
                {
                  testPlanId: openDeleteTestPlanModal?.data?.id
                    ? [openDeleteTestPlanModal?.data?.id]
                    : selectAllTestPlan,
                  isDelete: 1,
                },
                setSelectAllTestPlan,
                projectId,
                rights?.id,
                searchKeyword
              )
            );

            setOpenDeleteTestPlanModal({ visible: false, data: {} });
          }}
          handleCancel={() => setOpenDeleteTestPlanModal({ visible: false, data: {} })}
          modalOpen={openDeleteTestPlanModal?.visible}
          modalContent={`Are you sure you want to delete ${
            selectAllTestPlan && selectAllTestPlan.length > 1 ? "these items?" : "this item?"
          } This action cannot be undone.`}
          modalTitle={"Confirm Deletion"}
          okText={"Delete"}
          isDeleteModal={true}
        />
      </Spin>
    </>
  );
};

export default TestPlanDetailsV2;
