import { notification } from "antd";
import {
  CHANGE_BUILD_SCENARIO_STEP,
  CHANGE_TEST_PLAN_TAB,
  MANAGE_TEST_SCENARIO,
  SAVE_TEST_PLAN_NAME,
  SAVE_TEST_PLAN_SCENARIO,
  SAVE_TEST_PLAN_DATA,
  ALL_TEST_PLANS
} from "../Constants";

let defaultState = {
  buildScenarioStep: MANAGE_TEST_SCENARIO,
  selectedTab: ALL_TEST_PLANS,
  testPlanName: "",
  testPlanScenario: []
};

export default function BuildTestPlanReducer(state = defaultState, action) {
  let response = undefined;
  switch (action.type) {
    case CHANGE_BUILD_SCENARIO_STEP:
      response = action.data;
      state.buildScenarioStep = response;
      return {
        ...state,
      };
    case SAVE_TEST_PLAN_NAME:
      let testPlanName = undefined;
      if (action) {
        testPlanName = action.data;
      }
      return {
        ...state,
        testPlanName
      };
    case SAVE_TEST_PLAN_DATA:
      let saveTestPlaMessage = undefined;
      if (action) {
        saveTestPlaMessage = action.response;
      }
      return {
        ...state,
        saveTestPlaMessage
      };
    case SAVE_TEST_PLAN_SCENARIO:
      let testPlanScenario = [];
      if (action.data) {
        testPlanScenario = action.data;
      }
      return {
        ...state,
        testPlanScenario
      };

      default:
      return state;
  }
}
