import { Button, Col, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeTestDataSetDeviceAssignDataSet } from "../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import styles from "./EditTestDataSetV2.module.scss";

const { Option } = Select;

const DeviceTestDataSetListV2 = ({ testDataSetForEdit }) => {
  const dispatch = useDispatch();

  const { systemKeyList } = useSelector((state) => state.TestDataSetReducer);

  return (
    <Row>
      <Col span={24} className={styles["device-test-data-set-list"]}>
        <Col className="m-10">
          <b>Assign Device Test Data Set</b>
        </Col>
        <Col className="mr-10">
          <Select
            placeholder="Please select Test Data Set"
            className="width-full"
            defaultValue={testDataSetForEdit?.systemKey?.id}
            onChange={(value) => dispatch(changeTestDataSetDeviceAssignDataSet(value))}
            value={testDataSetForEdit.systemKeyId ? testDataSetForEdit.systemKeyId : testDataSetForEdit.systemKey?.id}
          >
            {systemKeyList?.map((i, k) => {
              return (
                <Option value={i.id} key={k}>
                  {i.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col>
          <Button type="link">View Test Data Set Values</Button>
        </Col>
      </Col>
    </Row>
  );
};

export default DeviceTestDataSetListV2;