import React, { useEffect } from "react";
import { Checkbox, Modal } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDeviceSelectionForBuildInstallation,
  hideInstallBuildOnDevicesModal,
  installBuildOnMultipleDevice,
  setUninstallBuildBeforeInstall,
} from "../../actions/Recording";
import { isSelectedAllIds } from "../../Util";
import PoolManagement from "../FarmManagement/PoolManagement/PoolManagement";
import { getDeviceByFarmId } from "../../actions/ServerListAction";

const DeviceListInstallBuild = (props) => {
  const { projectId, orgId, showDeviceView } = props;
  const {
    installBuildOnDeviceModalVisible,
    installBuildDeviceDetails,
    requestInstallBuildOnMultipleDevice,
    unInstallBUildBeforeInstall,
    selectedBuildDataToInstall,
  } = useSelector((state) => state.RecordingReducer);
  const { getSearchDeviceList } = useSelector((state) => state.ManageFarmReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);
  const dispatch = useDispatch();
  const onChangeDeviceSelection = (devices) => {
    dispatch(changeDeviceSelectionForBuildInstallation(devices));
  };
  let deviceList =
    getSearchDeviceList && getSearchDeviceList?.filter((item) => item?.miscDetails && !item?.device?.isDeviceOffline);

  useEffect(() => {
    if (!_.isEmpty(deviceFarmList)) {
      let FarmId = [];
      deviceFarmList.map((data) => {
        FarmId.push(data.id);
      });

      dispatch(
        getDeviceByFarmId({
          farmId: FarmId,
          deviceName: "",
          deviceFarmName: "",
          osVersion: "",
          searchKeyword: "",
          deviceFarmUse: "",
        })
      );
    }
  }, [deviceFarmList, showDeviceView]);

  return (
    <Modal
      title={`Install Build On Multiple Devices: ${selectedBuildDataToInstall?.displayName} - ${selectedBuildDataToInstall?.buildVersion} `}
      visible={installBuildOnDeviceModalVisible}
      confirmLoading={requestInstallBuildOnMultipleDevice}
      onOk={() => (!requestInstallBuildOnMultipleDevice ? dispatch(installBuildOnMultipleDevice(deviceList)) : undefined)}
      onCancel={() => (!requestInstallBuildOnMultipleDevice ? dispatch(hideInstallBuildOnDevicesModal()) : undefined)}
      okText={"Install"}
      okButtonProps={{ disabled: _.isEmpty(installBuildDeviceDetails) }}
      width={"90%"}
      className={"modal-build-verification"}>
      <>
        <div>
          <span className="ml-20">
            <Checkbox
              disabled={_.isEmpty(deviceList)}
              checked={isSelectedAllIds(
                installBuildDeviceDetails,
                deviceList?.map((i) => ({ id: i?.device?.targetUniqueId }))
              )}
              onChange={(e) =>
                e.target.checked
                  ? dispatch(changeDeviceSelectionForBuildInstallation(deviceList?.map((i) => i.device?.targetUniqueId)))
                  : dispatch(changeDeviceSelectionForBuildInstallation([]))
              }
            />
            <label> Select All</label>
          </span>
          <span className="ml-20">
            <Checkbox
              disabled={_.isEmpty(deviceList)}
              onChange={(e) => dispatch(setUninstallBuildBeforeInstall(e.target.checked ? 1 : 0))}
              value={unInstallBUildBeforeInstall}
            />
            <label> Uninstall The Build Before Install</label>
          </span>
        </div>
        <PoolManagement
          projectId={projectId}
          deviceTab={true}
          orgId={orgId}
          selectedDevices={installBuildDeviceDetails}
          previousSelectedDevices={undefined}
          disableDeviceSelection={false}
          onChangeDeviceSelection={onChangeDeviceSelection}
        />
      </>
    </Modal>
  );
};

export default DeviceListInstallBuild;
