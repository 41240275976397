import React from "react";
import { useDispatch } from "react-redux";
import { Select } from "antd";
import { operatorChange } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";

const OperatorV2 = (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <div>
        <Select
          className={"select-Operators"}
          value={props.operator}
          disabled={props.disabled}
          onChange={(value) =>
            dispatch(operatorChange(value, props.uuid, props.recTestStep.tag, props.recElement?.guid))
          }
        >
          <Select.Option value="And">AND</Select.Option>
          <Select.Option value="Or">OR</Select.Option>
        </Select>
      </div>
    </React.Fragment>
  );
};

export default OperatorV2;
