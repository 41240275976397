import React from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { visibleIdentificationRulesDrawer } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import Verifications from "../Verifications";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  ADD_ICON,
  DISABLE_COLOR,
  DISABLE_ICON_COLOR,
  EDIT_RULES_ICON,
  WHITE_COLOR,
} from "../../../../../Constants/SvgConstants";
import styles from "./VerificationRules.module.scss";
import { TEST_SCENARIOS } from "../../../../../Constants";

const VerificationRules = (props, projectId) => {
  const { recTestStepProperty } = props.data;
  const {
    recTestSteps,
    isSkipStep,
    recElement,
    data,
    selectedDeviceByUdid,
    unSavedRecElementGuid,
    disabled,
    isElementDisable,
  } = props;
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const dispatch = useDispatch();

  let recStepVerifyElementProperties,
    operator = [];
  if (props.recElement) {
    recStepVerifyElementProperties = props.recElement.recStepVerifyElementProperties;
    operator = props.recElement.verificationOperator;
  } else {
    recStepVerifyElementProperties = recTestStepProperty?.recStepVerifyElementProperties;
    operator = recTestStepProperty?.operator;
  }

  const containsVerification = recStepVerifyElementProperties && recStepVerifyElementProperties.length > 0;
  let isTestBlock = data?.isTestBlock === 1 && stepType === TEST_SCENARIOS;
  let isEditRulesButton =
    isPreviewRunning ||
    isTestBlock ||
    disabled

    
  return (
    <>
      {containsVerification ? (
        <>
          <div className={styles.rulesTitle}>
            <div className={styles.identificationRulesTitle}>Identification Rules:</div>
            <div
              className={`${styles.editRulesButton} ${isEditRulesButton && styles.disableButton}`}
              onClick={() =>
                !isEditRulesButton &&
                dispatch(visibleIdentificationRulesDrawer(true, data?.tag))
              }
            >
              <SvgLoader
                iconName={EDIT_RULES_ICON}
                disabled={isEditRulesButton}
                iconColor={isEditRulesButton ? DISABLE_COLOR : WHITE_COLOR}
              />
              <span className={`${styles.editRulesTitle} ${isEditRulesButton && styles.disableText}`}>Edit Rules</span>
            </div>
          </div>
          <div className={styles.verificationRules}>
            {recStepVerifyElementProperties &&
              recStepVerifyElementProperties.length > 0 &&
              recStepVerifyElementProperties.map((verification, key) => {
                return (
                  <React.Fragment key={key}>
                    {verification.type !== "GROUP" && (
                      <div className={styles.identificationRules}>
                        <div>
                          <span className={styles.verificationType}>{verification?.type}</span>
                          <span className={styles.verificationKeyOrValue}>
                            {verification?.key && `[${verification?.key}]`}
                          </span>
                          <span className={styles.verificationKeyOrValue}>
                            {verification?.value && `[${verification?.value}]`}
                          </span>
                          <span className={styles.verificationKeyOrValue}>
                            {verification?.refTestData && `[${verification?.refTestData?.name}]`}
                          </span>
                        </div>
                      </div>
                    )}

                    {verification.type === "GROUP" && !_.isEmpty(verification?.childRecStepVerifyElementProperties) && (
                      <div className={styles.identificationRules}>
                        {verification.type === "GROUP" &&
                          verification?.childRecStepVerifyElementProperties?.map((groupVerification, index) => {
                            return (
                              <React.Fragment>
                                <div>
                                  <span className={styles.verificationType}>{groupVerification?.type}</span>
                                  <span className={styles.verificationKeyOrValue}>
                                    {groupVerification?.key && `[${groupVerification?.key}]`}
                                  </span>
                                  <span className={styles.verificationKeyOrValue}>
                                    {groupVerification?.value && `[${groupVerification?.value}]`}
                                  </span>
                                  <span className={styles.verificationKeyOrValue}>
                                    {groupVerification?.refTestData && `[${groupVerification?.refTestData?.name}]`}
                                  </span>
                                </div>
                                <div>
                                  {verification?.childRecStepVerifyElementProperties.length > 1 &&
                                    verification?.childRecStepVerifyElementProperties.length - 1 !== index && (
                                      <div key={"OPERATOR"} className={styles.operators}>
                                        {verification?.operator}
                                      </div>
                                    )}
                                </div>
                              </React.Fragment>
                            );
                          })}
                      </div>
                    )}
                    {recStepVerifyElementProperties.length > 1 && recStepVerifyElementProperties.length - 1 !== key && (
                      <div key={"OPERATOR"} className={styles.operators}>
                        {operator}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
          </div>
        </>
      ) : (
        <div className={styles.addRules}>
          <div className={styles.rulesText}>Identification Rules</div>
          <span className={styles.addRulesText}>
            Specify Rules on Element Properties and Element States to filter and identify a particular Element.
          </span>
          <div
            className={`${styles.addOrEditRulesButton} ${disabled && styles.disableButton}`}
            onClick={() => !disabled && dispatch(visibleIdentificationRulesDrawer(true, data?.tag))}
          >
            <SvgLoader
              iconName={ADD_ICON}
              iconCOlor={disabled ? DISABLE_ICON_COLOR : WHITE_COLOR}
              disabled={disabled}
              width="1.2rem"
              height="1.6rem"
            />
          </div>
        </div>
      )}
      <Verifications
        recTestSteps={recTestSteps}
        disabled={
          props.disabled ||
          isSkipStep ||
          (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== recElement.guid)
        }
        isElementSelected={props.disabled}
        operator={recElement.verificationOperator}
        uuid={recElement.guid}
        data={data}
        recElement={recElement}
        operatorChange={recElement.verificationOperator}
        projectId={projectId}
        isDeviceLoaded={selectedDeviceByUdid !== undefined}
        isSkipStep={isSkipStep}
        isTestBlock={isTestBlock}
        isPreviewRunning={isPreviewRunning}
      />
    </>
  );
};

export default VerificationRules;
