import React from "react";
import { useSelector } from "react-redux";
import { Spin, Tooltip } from "antd";

import { SYSTEM_KEYS, TEST_PLAN, TEST_SCENARIOS, TEST_STEP_BLOCKS } from "../../../../../../Constants";
import ViewTestDataSetHeaderV2 from "./ViewTestDataSetHeaderV2";
import ViewTestBlockDefaultTestDataSetV2 from "./ViewTestBlockDefaultTestDataSetV2";
import ViewRecTestScenarioDefaultTestDataSetV2 from "./ViewRecTestScenarioDefaultTestDataSetV2";
import ViewTestPlanDefaultTestDataSetV2 from "./ViewTestPlanDefaultTestDataSetV2";

const ViewDefaultTestDataSetV2 = ({ setIsViewTestData, assignTestDataSetModal, title }) => {
  const { fetchingTestDataSetList, testDataSetView, testDataSetList } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);

  let defaultTestData = testDataSetList && testDataSetList.length > 0 ? testDataSetList[0] : [];

  return (
    <Spin spinning={fetchingTestDataSetList}>
      {setIsViewTestData && (
        <Tooltip title="Test Data Profile having default values for Test Data Keys">
          <ViewTestDataSetHeaderV2
            editTestDataSetName={"Base Profile"}
            setIsEditTestDataSet={setIsViewTestData}
            isDefaultView={true}
            selectedTestPlanName={""}
            stepType={stepType}
          />
        </Tooltip>
      )}
      <div className={!assignTestDataSetModal && (stepType === TEST_PLAN ? "page-Scroll" : "page-Scroll-Of-Test-Data")}>
        {testDataSetView === TEST_STEP_BLOCKS && (
          <ViewTestBlockDefaultTestDataSetV2
            title={"Test Block: Steps using Test Data"}
            defaultTestData={defaultTestData}
          />
        )}
        {testDataSetView === TEST_SCENARIOS && (
          <ViewRecTestScenarioDefaultTestDataSetV2
            title={"Test Scenario: Steps using Test Data"
              + " [Steps using Test Data: " +  defaultTestData?.stepCount
              + " Unique Test Data Keys: " + defaultTestData?.uniqueTestDataCount + "]"}
            defaultTestData={defaultTestData}
          />
        )}
        {testDataSetView === TEST_PLAN && (
          <ViewTestPlanDefaultTestDataSetV2 
            title={"Test Plan level Test Data"
              + " [Steps using Test Data: " +  defaultTestData?.stepCount
              + " Unique Test Data Keys: " + defaultTestData?.uniqueTestDataCount + "]"}
            defaultTestData={defaultTestData} />
        )}
        {stepType === SYSTEM_KEYS && (
          <ViewTestBlockDefaultTestDataSetV2 title={title} defaultTestData={defaultTestData} stepType={stepType} />
        )}
      </div>
    </Spin>
  );
};

export default ViewDefaultTestDataSetV2;
