import { Popover } from "antd";
import React from "react";
import TestPlanResultDeviceDetails from "../../TestPlan/TestPlanResultDetails/TestPlanResultDeviceDetails";
import { getDeviceName } from "../../../Util";
import styles from "./DeviceHoverCard.module.scss";

const DeviceHoverCard = (props) => {
  const { deviceDetails, deviceIndex } = props;
  return (
    <Popover
      placement="bottom"
      title={<span className={styles.textColorBlack}>Device Details</span>}
      content={<TestPlanResultDeviceDetails deviceResultDetails={deviceDetails} />}
    >
      <span className={`${styles.content} ${deviceIndex > 0 && styles.datIndex}`}>
        {(deviceDetails?.brandName || deviceDetails?.deviceBrandName) + "  | "}
        {`${getDeviceName(deviceDetails || "N/A")} 
        ${deviceDetails?.targetUniqueId ? `| ${deviceDetails?.targetUniqueId}` : ""} 
        | ${
          deviceDetails?.locale === "Not known" ? `Locale: ${deviceDetails?.locale}` : deviceDetails?.locale || "N/A"
        }`}
      </span>
    </Popover>
  );
};

export default DeviceHoverCard;
