import _ from "lodash";
import {
  ADD_PREVIEW_REQUEST,
  ADD_PREVIEW_REQUESTED,
  ADD_REC_TEST_STEP,
  ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW,
  ADD_TEST_BLOCK_STEP,
  BLOCK_REC_TEST_STEP_ACTION,
  CHANGED_REC_TEST_SCENARIO_NAME,
  CHANGED_REC_TEST_STEP_NAME,
  CHANGE_DISABLE_REC_STEP,
  CHANGE_REC_TEST_SCENARIO_NAME,
  CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL,
  CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION,
  CHANGE_SELECT_CHILD_ELEMENT,
  CHANGE_TAP_BY_INDEX,
  CHANGE_TEST_BLOCK_STEP_NAME,
  CHANGE_TEST_PLAN_DATA,
  CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW,
  CLEAR_RE_RECORD_ELEMENT_STEP_TAG,
  CLOSE_DEVICE,
  DELETE_REC_TEST_STEP,
  DELETE_REC_TEST_STEP_PRE_CONDITION,
  DELETE_TEST_BLOCK_STEP,
  ELEMENT_ACTION,
  FETCHED_TEST_STEP_LIST,
  FETCHING_TEST_STEP_LIST,
  FIND_DEVICE_BY_UDID,
  GET_LIST_OF_BLOCKS,
  HIDE_ADD_TEST_DATA_FORM,
  HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL,
  LOAD_REC_STEP_ACTION_DETAIL,
  LOAD_REC_STEP_DETAIL,
  MAKE_STEP_EDITABLE_REQUEST,
  MAKE_SUB_REC_TEST_STEP,
  PLAY_TYPE,
  PREVIEW_COMPLETED,
  REC_TEST_SCENARIO_STEP_ACTIONS_LIST,
  REC_TEST_STEP_LIST,
  REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION,
  REQUESTED_DELETE_REC_TEST_STEP,
  REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION,
  REQUESTED_DRAG_AND_DROP,
  REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS,
  REQUESTED_FOR_DEVICE_LOAD,
  REQUESTED_FOR_DEVICE_UNLOAD,
  REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  REQUESTING_APP_PACKAGE_ACTIVITY,
  REQUEST_DELETE_REC_TEST_STEP,
  REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION,
  REQUEST_DRAG_AND_DROP,
  REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS,
  REQUEST_FOR_DEVICE_LOAD,
  REQUEST_FOR_DEVICE_UNLOAD,
  REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  RESET_ALL_REC_TEST_SCENARIO_STEP_IDS,
  RESET_IMPORT_REC_TEST_DATA,
  RESET_RECORDED_SCENARIO,
  RESET_REC_TEST_DATA,
  RESET_REC_TEST_SCENARIO_NAME,
  RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  RESET_STATE,
  RESET_TEST_BLOCk_STEP_SCENARIO_NAME,
  RESET_TEST_RAIL_SUITE_ID,
  SAVE_REC_TEST_STEP_ACTION,
  SCENARIO_DETAIL_FOR_EDIT,
  SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED,
  SELECTED_TEST_STEP_BLOCK,
  SELECT_ALL_REC_TEST_SCENARIO_STEP_IDS,
  SELECT_REC_TEST_STEPS,
  SELECT_REC_TEST_STEP_FOR_PLAY,
  SELECT_TEST_BLOCK__STEP_DATA,
  SET_TEST_RAIL_CASE_ID,
  SET_TEST_RAIL_SUITE_ID,
  SHOW_ADD_TEST_DATA_FORM,
  SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL,
  STEP,
  STEP_ITEMS,
  STOP_PREVIEW_REQUEST,
  STOP_PREVIEW_REQUESTED,
  SUCCESS_STATUS,
  TEST_DATA_SET_SELECTION_SCENARIO,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UNBLOCK_REC_TEST_STEP_ACTION,
  UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO,
  UPDATE_PREVIEW_REQUEST,
  UPDATE_PREVIEW_STATUS,
  UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
  UPDATE_REC_TEST_STEP_ACTIONS,
  UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
  UPDATE_SCENARIO_NAME,
  UPDATE_TEST_BLOCK_STEP_NAME,
  XpathGenerateMethod,
} from "../Constants";
import { getNewRecElement, getRecElement, getUniqueId, setRecTestStepElementDetails, updateRecElement } from "../Util";
let defaultState = {
  recTestStepList: undefined,
  multiColumnViewRecTestData: undefined,
  multiColumnViewImportRecTestData: undefined,
  fetchingRecTestStepList: false,
  selectedRecTestScenarioId: undefined,
  recTestStepListTreeData: undefined,
  selectedRecTestStepDetail: undefined,

  selectedAppLaunch: undefined,
  tapByIndexValue: "",
  enterTextValue: undefined,
  appendRandomText: false,
  isServerLocal: true,
  selectedRecTestStepIdForDelete: undefined,
  isSelectedRecTestStepForDelete: false,
  selectedRecTestStepTagForDelete: undefined,
  selectedRecTestStepAssocForDelete: undefined,
  deleteRecTestStepModalVisible: false,
  selectedRecElementForDelete: undefined,
  deleteRecElementModalVisible: false,
  requestingDeleteRecTestStep: false,
  recTestStepActionSelectedElement: undefined,
  elementListByServer: [],
  deviceSelectedElementAttributes: undefined,
  blockRecTestStepAction: false,
  selectedDeviceIdForRecording: undefined,
  selectedServerIdForRecording: undefined,
  selectedDevicePlatformForRecording: undefined,
  selectedRecTestScenarioName: undefined,
  selectedTestRailTestCaseId: [],
  selectedTestRailTestSuiteId: null,
  requestingDeleteRecTestStepPrecondition: false,
  deleteRecTestStepPreconditionModalVisible: false,
  selectedRecTestStepPropertyIdForDelete: undefined,
  previewRunId: undefined,
  previewRunNumber: undefined,
  previewUserName: undefined,
  isPreviewRunning: false,
  requestingToStopPreview: [],
  scrollToRecTestStep: undefined,
  scrollToRecTestStepAction: undefined,
  changedRecTestStepName: false,
  changedRecTestScenarioName: false,
  searchedForElementBounds: undefined,

  importRecTestScenarioStepList: [],
  requestGetRecTestScenarioStepActionList: false,
  unSavedRecTestStepActionModalVisible: false,
  draggingRequest: false,
  requestingUpdateRecTestStepAction: false,
  changeRecTestStepActionName: false,
  selectedElementDetails: undefined,
  activeRecTestStepAction: undefined,
  previous_RecTestScenarioName: undefined,
  showTestDataVisibility: {},
  fetchingTestDataList: false,
  showAddTestDataForm: false,
  selectedDeviceByUdid: undefined,
  isDeviceLoaded: false,
  findDeviceByUdID: undefined,
  totalScenarioItems: 0,
  currentScenarioPage: 0,
  projectStatus: null,
  deviceLoading: false,
  deviceUnloading: false,
  isCloseDevice: false,
  selectedTabKey: {},
  requestingLaunchAppActivity: false,

  selectedScenarioPlatform: undefined,
  isDelateRecTestStepRef: 0,
  makeTestStepEditableEnabled: true,
  testBlockChildStepList: [],
  testBlockStep: undefined,

  previous_TestBlockStepName: undefined,
  selectedTestBLockStepIdForDelete: undefined,
  isSelectedTestBlockStepForDelete: false,
  requestData: [],
  checkedTestBlockImportStep: [],
  totalScenarioItems: undefined,
  pageScenarioItems: undefined,
  selectTestBlockStep: null,
  selectTestDataSet: null,
  selectTestDataSetId: null,
  selectedRecStepForOperation: undefined,
  selectedRecTestStepForPlay: undefined,
};

export default function RecTestStepReducer(state = defaultState, action) {
  let serverResponse = action.response;
  let recTestStepList = [];
  let multiColumnViewRecTestData = [];
  let selectedRecTestStepDetail = undefined;
  let newRecTestStepActionDetail = undefined;
  let activeRecTestStepAction = undefined;
  let updateRecTestStepDetail = undefined;
  let updateRecTestStepElementDetail = undefined;
  let recElements = [];
  let recElement = undefined;
  let recStepVerifyElementProperties = [];
  let testBlockStep = undefined;
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        recTestStepList: undefined,
        fetchingRecTestStepList: false,
        selectedRecTestScenarioId: undefined,
        recTestStepListTreeData: undefined,
        selectedRecTestStepDetail: undefined,
        // installedApps: [],
        selectedAppLaunch: undefined,
        tapByIndexValue: "",
        enterTextValue: undefined,
        appendRandomText: false,
        selectedRecTestStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false,
        selectedRecTestStepTagForDelete: undefined,
        deleteRecTestStepModalVisible: false,
        deleteRecElementModalVisible: false,
        requestingDeleteRecTestStep: false,
        recTestStepActionSelectedElement: undefined,
        elementListByServer: [],
        deviceSelectedElementAttributes: undefined,
        blockRecTestStepAction: false,
        selectedDeviceIdForRecording: undefined,
        selectedServerIdForRecording: undefined,
        selectedDevicePlatformForRecording: undefined,
        selectedRecTestScenarioName: undefined,
        recTestStepActionElementDetailDrawerVisible: false,
        requestingDeleteRecTestStepPrecondition: false,
        deleteRecTestStepPreconditionModalVisible: false,
        selectedRecTestStepPropertyIdForDelete: undefined,
        requestingForPreview: [],
        previewRunId: undefined,
        previewRunNumber: undefined,
        previewUserName: undefined,
        isPreviewRunning: false,
        requestingToStopPreview: [],
        scrollToRecTestStep: undefined,
        scrollToRecTestStepAction: undefined,
        changedRecTestStepName: false,
        changedRecTestScenarioName: false,
        searchedForElementBounds: undefined,
        disableRecTestStepActionsForImport: false,
        importRecTestStepModalVisible: false,
        requestingForImportRecTestStepOrAction: false,
        importRecTestScenarioStepActionList: [],
        importRecTestScenarioStepList: [],
        requestGetRecTestScenarioStepActionList: false,
        unSavedRecTestStepActionModalVisible: false,
        requestingUpdateRecTestStepAction: false,
        selectedTestRailTestCaseId: [],
        selectedTestRailTestSuiteId: null,
        selectedRectTestScenarioStepId: [],
        testBlockChildStepList: [],
        testBlockStep: undefined,
        // stepType: undefined,
        previous_TestBlockStepName: undefined,
        selectedTestBLockStepIdForDelete: undefined,
        isSelectedTestBlockStepForDelete: false,
        // selectedScenarioPlatform: undefined,
      };
    case CLOSE_DEVICE:
      return {
        ...state,
        isCloseDevice: action.deviceClose,
      };
    case FETCHING_TEST_STEP_LIST:
      return {
        ...state,
        fetchingRecTestStepList: true,
      };
    case FETCHED_TEST_STEP_LIST:
      return {
        ...state,
        fetchingRecTestStepList: false,
      };
    case LOAD_REC_STEP_DETAIL:
      if (
        action.selectedRecTestStep &&
        action.selectedRecTestStep.recTestSteps &&
        action.selectedRecTestStep.recTestSteps.length > 0 &&
        action.selectedRecTestStep.recTestSteps[0].recTestStepProperty?.action !== STEP
      ) {
        state.activeRecTestStepAction = action.selectedRecTestStep.recTestSteps[0];
      }

      return {
        ...state,
        selectedRecTestStepDetail: action.selectedRecTestStep,
      };
    case REQUEST_DRAG_AND_DROP:
      return {
        ...state,
        draggingRequest: true,
      };

    case REQUESTED_DRAG_AND_DROP:
      return {
        ...state,
        draggingRequest: false,
      };

    case LOAD_REC_STEP_ACTION_DETAIL:
      return {
        ...state,
      };
    case CHANGE_TEST_BLOCK_STEP_NAME:
      state.previous_TestBlockStepName =
        state.previous_TestBlockStepName === undefined
          ? state.selectedRecTestScenarioName
          : state.previous_TestBlockStepName;
      let value = action?.value;
      return {
        ...state,
        selectedRecTestScenarioName: value !== undefined ? value : state.previous_TestBlockStepName,
        changedTestBlockStepName: true,
      };
    case RESET_TEST_BLOCk_STEP_SCENARIO_NAME:
      if (state.testBlockStep["recTestStepProperty"]["previous_stepName"]) {
        state.testBlockStep["recTestStepProperty"]["stepName"] =
          state.testBlockStep["recTestStepProperty"]["previous_stepName"];
        delete state.testBlockStep["recTestStepProperty"]["previous_stepName"];
      }
      return {
        ...state,
        selectedRecTestScenarioName: state.previous_TestBlockStepName,
        previous_TestBlockStepName: undefined,
        changedTestBlockStepName: false,
      };
    case REC_TEST_STEP_LIST:
      let selectedRecTestStep = state.selectedRecStepForOperation
        ? findRecTestStepByTag(
            state.recTestStepList ? [...state.recTestStepList] : [],
            state.selectedRecStepForOperation.tag,
            state.testBlockStep
          )
        : undefined;
      selectedRecTestStep = selectedRecTestStep
        ? selectedRecTestStep
        : action.duplicateRecTestStep
        ? action.response.data[action.response.data.length - 1]
        : action.response.data?.length > 0
        ? action.response.data[0]
        : undefined;
      if (serverResponse && serverResponse.status === SUCCESS_STATUS) {
        if (action.stepType === TEST_STEP_BLOCKS) {
          return {
            ...state,
            recTestStepList:
              action.testBlockStep?.id !== serverResponse.data.id
                ? serverResponse.data
                : serverResponse.data.recTestSteps,
            fetchingRecTestStepList: false,
            selectedScenarioPlatform: action.platform,
            testBlockStep: action.testBlockStep,
            selectedRecTestScenarioName: action?.testBlockStep?.recTestStepProperty?.stepName,
            selectedRecStepForOperation: selectedRecTestStep,
          };
        }
        return {
          ...state,
          recTestStepList: serverResponse.data,
          fetchingRecTestStepList: false,
          selectedRecStepForOperation: selectedRecTestStep,
        };
      } else {
        return {
          ...state,
          recTestStepList: undefined,
          selectedRecTestStepDetail: undefined,
          fetchingRecTestStepList: false,
        };
      }
    case SCENARIO_DETAIL_FOR_EDIT:
      return {
        ...state,
        selectedRecTestScenarioId: action.recTestScenarioId,
        selectedRecTestScenarioName: action.recTestScenarioName,
        selectedTestRailTestCaseId: action.testRailTestCaseId,
        selectedTestRailTestSuiteId: action.testRailTestSuiteId,
        selectedScenarioPlatform: action.platform ? action.platform : state.selectedScenarioPlatform,
      };
    case SELECTED_REC_TEST_SCENARIO_ID_UNDEFINED:
      return {
        ...state,
        selectedRecTestScenarioId: undefined,
        recTestStepList: undefined,
        testBlockStep: undefined,
        selectedRecTestStepDetail: undefined,
        fetchingRecTestStepList: false,
      };
    case RESET_TEST_RAIL_SUITE_ID:
      return {
        ...state,
        selectedTestRailTestSuiteId: null,
      };

    case SET_TEST_RAIL_SUITE_ID:
      return {
        ...state,
        selectedTestRailTestSuiteId: action.testSuiteId,
      };

    case SET_TEST_RAIL_CASE_ID:
      return {
        ...state,
        selectedTestRailTestCaseId: action.testCaseID,
      };

    case CHANGE_TAP_BY_INDEX:
      state.selectedRecTestStepDetail.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
        state.selectedRecTestStepDetail.recTestSteps,
        action.recTestStepActionId,
        action.recTestStepActionTag,
        "action",
        ELEMENT_ACTION.TAP_BY_INDEX
      );

      const parentRefElement = state.elementListByServer.filter(
        (item) => item.generatedMethod === XpathGenerateMethod.PARENT_REF
      );
      let elementDetail = {};
      if (parentRefElement && parentRefElement.length > 0) {
        state.selectedRecTestStepDetail["selectedElementId"] = parentRefElement[0]["mobileElements"][action.value].id;
        elementDetail = {
          elLocation: parentRefElement[0]["mobileElements"][action.value].location,
          size: parentRefElement[0]["mobileElements"][action.value].size,
        };
      }
      return {
        ...state,
        searchedForElementBounds: elementDetail,
      };

    case RESET_RECORDED_SCENARIO:
      state.selectedRecTestScenarioId = undefined;
      return {
        ...state,
      };

    case SELECT_ALL_REC_TEST_SCENARIO_STEP_IDS:
      let _selectedRectTestScenarioStepId = [...state.selectedRectTestScenarioStepId];
      if (action?.MultiViewDesign) {
        if (!action.recTestStepId) {
          _selectedRectTestScenarioStepId =
            action?.recTestStepList !== undefined &&
            action?.recTestStepList
              ?.filter((j) => j?.isSkipStep !== 1)
              ?.map((i) => (i.isTestBlock === 1 ? i.recTestStepId : i.recTestScenarioRecTestStepAssocId));
        } else {
          if (_selectedRectTestScenarioStepId?.includes(action.recTestStepId)) {
            _selectedRectTestScenarioStepId.splice(_selectedRectTestScenarioStepId.indexOf(action.recTestStepId), 1);
          } else {
            _selectedRectTestScenarioStepId.push(action.recTestStepId);
          }
          AllChildRecTestStepId(
            action.recTestStepId,
            action?.recTestStepList,
            _selectedRectTestScenarioStepId,
            action.childRecTestSteps
          );
          const parentRecId = action.recTestStepList?.find(
            (i) => i?.recTestStepId === action.recTestStepId
          )?.parentRecTestStepId;
          const parentId = action?.recTestStepList?.find(
            (i) => i?.recTestScenarioRecTestStepAssocId === action.recTestStepId
          )?.parentRecTestScenarioRecTestStepsAssocId;
          if (parentId || parentRecId) {
            AllParentRecTestStepId(
              parentId || parentRecId,
              action?.recTestStepList,
              _selectedRectTestScenarioStepId,
              action.childRecTestSteps,
              true
            );
          }
        }
      } else {
        if (action.recTestStepId) {
          if (_selectedRectTestScenarioStepId?.includes(action.recTestStepId)) {
            _selectedRectTestScenarioStepId.splice(_selectedRectTestScenarioStepId.indexOf(action.recTestStepId), 1);
          } else {
            _selectedRectTestScenarioStepId.push(action.recTestStepId);
          }
        } else {
          _selectedRectTestScenarioStepId =
            state.recTestStepList &&
            state.recTestStepList
              ?.filter((j) => j?.isSkipStep !== 1)
              ?.map((i) => (action.stepType === TEST_SCENARIOS ? i?.recTestScenarioRecTestStepAssocId : i?.id));
        }
      }
      return {
        ...state,
        selectedRectTestScenarioStepId: _selectedRectTestScenarioStepId,
        selectedRecStepForOperation:
          _selectedRectTestScenarioStepId?.length === 0 ? state.recTestStepList[0] : undefined,
      };

    case RESET_ALL_REC_TEST_SCENARIO_STEP_IDS:
      return {
        ...state,
        selectedRectTestScenarioStepId: [],
      };

    case ADD_REC_TEST_STEP:
      // let recElementGuid = undefined;
      if (state.recTestStepList === undefined) {
        recTestStepList = [];
      } else {
        recTestStepList = !_.isEmpty(state.recTestStepList) ? [...state.recTestStepList] : [];
      }
      if (
        [STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT, STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA].includes(
          action.stepItemType
        )
      ) {
        action.response.data.recTestStepProperty["preConditionTitle"] = "";
        action.response.data.recTestStepProperty["isStepHasPrecondition"] = 1;
        action.response.data.recTestStepProperty["isStepHasVerifyTestData"] = action.withVerifyTestData ? 1 : 0;
      }
      switch (action.stepItemType) {
        case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA:
          action.response.data.recTestStepProperty["recStepVerifyElementProperties"].push(
            getEmptyVerificationRow("VerifyTestData")
          );
          break;

        // case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT:
        //   action.response.data.recTestStepProperty["recElements"].push(
        //     getNewRecElement(action.response.data.recTestStepProperty?.action, recElementGuid)
        //   );
        //   break;
        case STEP_ITEMS.REPEAT_TILL:
          action.response.data.recTestStepProperty["isLoopStep"] = 1;
          action.response.data.recTestStepProperty["loopCount"] = 5;
          action.response.data.recTestStepProperty["previous_loopCount"] = 0;
          action.response.data.recTestStepProperty["addLoopCondition"] = false;
          action.response.data.recTestStepProperty["isLoopCountSaved"] = false;
          break;
        default:
          break;
      }
      if (
        action.response.data.parentRecTestStep &&
        action.response.data.parentRecTestStep?.id !== state.testBlockStep?.id
      ) {
        updateRecTestStepDetail = findRecTestStepById(recTestStepList, action.response.data.parentRecTestStep.id);
        if (updateRecTestStepDetail["recTestSteps"] === undefined) {
          updateRecTestStepDetail["recTestSteps"] = [];
        }
        updateRecTestStepDetail["recTestSteps"].push(action.response.data);
        recTestStepList = updateRecTestStep(recTestStepList, updateRecTestStepDetail);
      } else {
        recTestStepList.push(action.response.data);
      }
      if (action.MultiView) {
        if (multiColumnViewRecTestData === undefined) {
          multiColumnViewRecTestData = [];
        } else {
          multiColumnViewRecTestData = state.multiColumnViewRecTestData ? [...state.multiColumnViewRecTestData] : [];
        }
        multiColumnViewRecTestData.push({
          action: action.response.data.recTestStepProperty?.action,
          isCaptureScreenshot: action.response.data.recTestStepProperty?.isCaptureScreenshot,
          isLoopStep: action.response.data.recTestStepProperty?.isLoopStep,
          isSkipStep: action.response.data.recTestStepProperty?.isSkipStep,
          stepName: action.response.data.recTestStepProperty?.stepName,
          orderId: action.response.data.orderId,
          isTestBlock: action.response.data.isTestBlock,
          recTestScenarioRecTestStepAssocId: action.response.data.recTestScenarioRecTestStepAssocId,
          isStepHasPrecondition: action.response.data.recTestStepProperty?.isStepHasPrecondition,
          recStepVerifyElementProperties: action.response.data.recTestStepProperty?.recStepVerifyElementProperties,
          recElements: action.response.data.recTestStepProperty?.recElements,
          recTestStepId: action.response.data.id,
          recTestStepPropertyId: action.response.data.recTestStepProperty?.id,
        });
        switch (action.stepItemType) {
          case STEP_ITEMS.REPEAT_TILL:
            action.response.data.recTestStepProperty["isLoopStep"] = 1;
            action.response.data.recTestStepProperty["loopCount"] = 5;
            action.response.data.recTestStepProperty["previous_loopCount"] = 0;
            action.response.data.recTestStepProperty["addLoopCondition"] = false;
            action.response.data.recTestStepProperty["isLoopCountSaved"] = false;
            break;
          default:
            break;
        }
      }
      return {
        ...state,
        recTestStepList,
        multiColumnViewRecTestData,
        selectedRecStepForOperation: action.response.data,
      };
    case RESET_REC_TEST_DATA:
      return {
        ...state,
        multiColumnViewRecTestData: [],
      };

    case RESET_IMPORT_REC_TEST_DATA:
      return {
        ...state,
        multiColumnViewImportRecTestData: [],
        multiColumnViewImportChildRecTestData: [],
      };
    case ADD_REC_TEST_STEP_ACTION_SINGLE_COLUMN_VIEW:
      recTestStepList = [...state.recTestStepList];
      selectedRecTestStepDetail = findRecTestStepById(
        [...state.recTestStepList],
        action.recTestStep.parentRecTestStep.id,
        state.testBlockStep
      );
      if (selectedRecTestStepDetail && selectedRecTestStepDetail["recTestSteps"] === undefined) {
        selectedRecTestStepDetail["recTestSteps"] = [];
      }
      if (state.testBlockStep?.id === action.parentRecTestStep.id) {
        recTestStepList.push(action.recTestStep);
      } else {
        selectedRecTestStepDetail["recTestSteps"].push(action.recTestStep);
        recTestStepList = updateRecTestStep(recTestStepList, selectedRecTestStepDetail);
      }
      return {
        ...state,
        selectedRecTestStepDetail,
        recTestStepList,
        activeRecTestStepAction: action.recTestStep,
        selectedRecStepForOperation: action.recTestStep,
      };
    case REQUEST_DELETE_REC_TEST_STEP:
      return {
        ...state,
        requestingDeleteRecTestStep: true,
        fetchingRecTestStepList: true,
      };
    case REQUESTED_DELETE_REC_TEST_STEP:
      return {
        ...state,
        requestingDeleteRecTestStep: false,
        fetchingRecTestStepList: false,
      };

    case REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION:
      return {
        ...state,
        requestingDeleteRecTestStepPrecondition: true,
      };
    case REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION:
      return {
        ...state,
        requestingDeleteRecTestStepPrecondition: false,
      };

    case RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT:
      recTestStepList = state.recTestStepList;
      let recTestStepTag = undefined;
      if (action.recTestStepTag) {
        recTestStepTag = action.recTestStepTag;
        updateRecTestStepDetail = findRecTestStepByTag(
          state.recTestStepList,
          action.recTestStepTag,
          state.testBlockStep
        );
      } else {
        updateRecTestStepDetail = fetchUnSavedStepList(recTestStepList);
        recTestStepTag = updateRecTestStepDetail && updateRecTestStepDetail.tag;
      }
      if (recTestStepTag) {
        updateRecTestStepDetail["recTestStepProperty"] = {
          ...updateRecTestStepDetail["recTestStepProperty"],
          recElements: updateRecTestStepDetail["recTestStepProperty"]["previous_recElements"]
            ? updateRecTestStepDetail["recTestStepProperty"]["previous_recElements"]
            : updateRecTestStepDetail["recTestStepProperty"]["recElements"],
        };
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_recElements"];
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_stepName"];
        recTestStepList = updateRecTestStep(state.recTestStepList, updateRecTestStepDetail);
        delete state.selectedTabKey[action.reRecordRecStepTag + action.recElementGuid];
      }
      return {
        ...state,
        recTestStepList,
        elementListByServer: [],
        deviceSelectedElementAttributes: undefined,
        recTestStepActionSelectedElement: undefined,
        searchedForElementBounds: undefined,
      };
    case DELETE_REC_TEST_STEP:
      let selectedRecStepForOperation =
        action.response?.data?.length > 0 ? action.response?.data[0] : undefined || state.recTestStepList[0];
      activeRecTestStepAction = undefined;
      recTestStepList = _.cloneDeep(state.recTestStepList);
      let siblingRecTestStep = undefined;
      // Get the Sibling Step or Action
      siblingRecTestStep = findSiblingRecTestStep(
        recTestStepList,
        action.selectedRecTestStepIdForDelete,
        action.selectedRecTestStepTagForDelete,
        action.selectedRecTestStepAssocForDelete
      );
      // Multiple Step with common Parent
      if (
        siblingRecTestStep === undefined &&
        action.selectedRectTestScenarioStepId &&
        action.selectedRectTestScenarioStepId.length > 1
      ) {
        let parentRecTestStep = undefined;
        action.selectedRectTestScenarioStepId.forEach((id) => {
          let _parentRecTestStep = findParentRecTestStep(
            recTestStepList,
            action.stepType === TEST_STEP_BLOCKS ? action.selectedRectTestScenarioStepId[0] : undefined,
            action.selectedRecTestStepTagForDelete,
            action.stepType === TEST_SCENARIOS ? action.selectedRectTestScenarioStepId[0] : undefined
          );
          parentRecTestStep = _parentRecTestStep;
          if (_parentRecTestStep) {
            parentRecTestStep = _parentRecTestStep;
          }
        });
        if (parentRecTestStep) {
          let childSteps = parentRecTestStep?.recTestSteps?.filter(
            (recTestStep) =>
              (action.stepType === TEST_STEP_BLOCKS &&
                !action?.selectedRectTestScenarioStepId?.includes(recTestStep.id)) ||
              (action.stepType === TEST_SCENARIOS &&
                !action?.selectedRectTestScenarioStepId?.includes(recTestStep.recTestScenarioRecTestStepAssocId))
          );
          if (childSteps && childSteps.length > 0) {
            siblingRecTestStep = childSteps[0];
          } else {
            siblingRecTestStep = parentRecTestStep;
          }
        }
      }
      if (siblingRecTestStep) {
        selectedRecStepForOperation = siblingRecTestStep;
      }
      // Get the Updated RecTestStepList after removing the action or step
      recTestStepList = removeRecTestStep(
        recTestStepList,
        action.selectedRecTestStepIdForDelete,
        action.selectedRecTestStepTagForDelete,
        action.selectedRecTestStepAssocForDelete
      );
      // For Rec Test Step
      if (state.isSelectedRecTestStepForDelete) {
        // Reset the current Step
        if (
          state.selectedRecTestStepDetail &&
          state.selectedRecTestStepDetail.id === action.selectedRecTestStepIdForDelete
        ) {
          state.selectedRecTestStepDetail = undefined;
        }
        // Select the Sibling Step
        if (siblingRecTestStep) {
          state.selectedRecTestStepDetail = siblingRecTestStep;
          if (
            siblingRecTestStep.recTestSteps &&
            siblingRecTestStep.recTestSteps.length > 0 &&
            siblingRecTestStep.recTestSteps[0].recTestStepProperty?.action !== STEP
          ) {
            activeRecTestStepAction = siblingRecTestStep.recTestSteps[0];
          }
        }
      } // Select the Sibling Action
      else {
        if (siblingRecTestStep) {
          activeRecTestStepAction = siblingRecTestStep;
        }
      }
      return {
        ...state,
        recTestStepList: action.response && action.response.data ? action.response.data : recTestStepList,
        activeRecTestStepAction,
        selectedRecTestStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false,
        selectedRecTestStepTagForDelete: undefined,
        deleteRecTestStepModalVisible: false,
        requestingDeleteRecTestStep: false,
        fetchingRecTestStepList: false,
        recTestStepActionSelectedElement: undefined,
        selectedRecElementForDelete: undefined,
        deleteRecElementModalVisible: false,
        selectedRecStepForOperation,
      };

    case DELETE_REC_TEST_STEP_PRE_CONDITION:
      recTestStepList = state.recTestStepList;
      testBlockStep = state.testBlockStep;
      let recTestStepPropertyId = state.selectedRecTestStepPropertyIdForDelete
        ? state.selectedRecTestStepPropertyIdForDelete
        : action.recTestStepPropertyId;
      if (testBlockStep?.recTestStepProperty?.id === recTestStepPropertyId) {
        testBlockStep.recTestStepProperty["continueAfterFail"] = 0;
        testBlockStep.recTestStepProperty["isStepHasPrecondition"] = 0;
        testBlockStep.recTestStepProperty["preConditionTitle"] = "";
        testBlockStep.recTestStepProperty["recStepVerifyElementProperties"] = [];
        testBlockStep.recTestStepProperty["recElements"] = [];
        Object.keys(testBlockStep.recTestStepProperty).map((v) => {
          if (v.includes("previous_")) {
            delete testBlockStep["recTestStepProperty"][v];
          }
        });
      }
      recTestStepList = removeRecTestStepPreCondition(recTestStepList, recTestStepPropertyId);
      if (
        state.selectedRecTestStepDetail &&
        state.selectedRecTestStepDetail.recTestStepProperty?.id === recTestStepPropertyId
      ) {
        if (state.selectedRecTestStepDetail.recTestSteps && state.selectedRecTestStepDetail.recTestSteps.length > 0) {
          state.activeRecTestStepAction = state.selectedRecTestStepDetail.recTestSteps[0];
        }
        removeRecTestStepPreCondition([state.selectedRecTestStepDetail], recTestStepPropertyId, state.testBlockStep);
      }

      return {
        ...state,
        recTestStepList,
        testBlockStep,
        selectedRecTestStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false,
        selectedRecTestStepTagForDelete: undefined,
        deleteRecTestStepPreconditionModalVisible: false,
        requestingDeleteRecTestStepPrecondition: false,
      };
    case UPDATE_DEVICE_DETAIL_FOR_REC_TEST_SCENARIO:
      return {
        ...state,
        selectedDeviceIdForRecording: action.targetUniqueId,
        selectedServerIdForRecording: action.serverId,
        selectedDevicePlatformForRecording: action.platform,
      };
    case BLOCK_REC_TEST_STEP_ACTION:
      return {
        ...state,
        blockRecTestStepAction: true,
      };
    case UNBLOCK_REC_TEST_STEP_ACTION:
      return {
        ...state,
        blockRecTestStepAction: false,
      };
    case UPDATE_REC_ELEMENT_DETAIL_IN_REC_TEST_STEP_SINGLE_COLUMN_VIEW:
      action.elementDetailsFromPageSource = {
        ...action.elementDetailsFromPageSource,
        screenShot: action.elementScreenshot,
      };
      state.recTestStepList = setRecTestStepElementDetails(
        state.recTestStepList,
        state.testBlockStep,
        action.unSavedRecStepTag,
        action.selectedElementAttributes,
        action.elementDetailsFromServer,
        action.elementDetailsFromPageSource
      );
      newRecTestStepActionDetail = findRecTestStepByTag(
        state.recTestStepList,
        action.unSavedRecStepTag,
        state.testBlockStep
      );
      state.recTestStepList = updateRecTestStep(state.recTestStepList, newRecTestStepActionDetail);
      return {
        ...state,
      };

    // case SAVE_REC_TEST_STEP_DEVICE_INTERACTION:
    //   if (state.selectedRecTestStepDetail && state.selectedRecTestStepDetail.id === undefined) {
    //     selectedRecTestStepDetail.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
    //       state.selectedRecTestStepDetail.recTestSteps,
    //       action.recTestStepActionId,
    //       action.recTestStepActionTag,
    //       "action",
    //       action.deviceInteractionAction
    //     );
    //     selectedRecTestStepDetail.recTestSteps = insertIntoRecTestStepActionRecTestProperty(
    //       state.selectedRecTestStepDetail.recTestSteps,
    //       action.recTestStepActionId,
    //       action.recTestStepActionTag,
    //       "stepName",
    //       "Press device " + action.deviceInteractionAction + " button"
    //     );
    //   }
    //   return {
    //     ...state,
    //   };

    case SAVE_REC_TEST_STEP_ACTION:
      // Update the rec test Step List
      if (action.response.data.isTestBlock === 1 && action.response.data.testBlockRecTestStep === null) {
        state.testBlockStep = { ...action.response.data };
      } else {
        state.recTestStepList = insertStepIntoList([...state?.recTestStepList], action.response.data);
        // If selectedRecTestStepDetail doesn't change update the data.
        // if (state.selectedRecTestStepDetail) {
        //   if (
        //     action.response.data.parentRecTestStep &&
        //     action.response.data.parentRecTestStep.id === state.selectedRecTestStepDetail.id
        //   ) {
        //     state.selectedRecTestStepDetail.recTestSteps = insertStepIntoList(
        //       [...state.selectedRecTestStepDetail.recTestSteps],
        //       action.response.data
        //     );
        //   } else if (
        //     action.response.data.id === state.selectedRecTestStepDetail.id &&
        //     action.response.data.recTestStepProperty?.action === STEP
        //   ) {
        //     action.response.data.recTestSteps = state.selectedRecTestStepDetail.recTestSteps;
        //     state.selectedRecTestStepDetail = action.response.data;
        //   }
        // }
      }
      return {
        ...state,
        elementListByServer: [],
        deviceSelectedElementAttributes: undefined,
        recTestStepActionSelectedElement: undefined,
        selectedRecStepForOperation:
          action.response.data.isTestBlock === 1 && action.response.data.parentRecTestStep === null
            ? state.recTestStepList[0]
            : action.response.data,
      };

    case CHANGED_REC_TEST_STEP_NAME:
      delete state.testBlockStep?.recTestStepProperty?.previous_stepName;
      return {
        ...state,
        changedRecTestStepName: false,
        previous_TestBlockStepName: undefined,
      };

    case UPDATE_TEST_BLOCK_STEP_NAME:
      state.testBlockStep = insertIntoRecTestStepRecTestProperty({ ...state.testBlockStep }, "stepName", action.value);
      return {
        ...state,
      };

    case CHANGE_REC_TEST_SCENARIO_NAME:
      state.previous_RecTestScenarioName =
        state.previous_RecTestScenarioName === undefined
          ? state.selectedRecTestScenarioName
          : state.previous_RecTestScenarioName;
      return {
        ...state,
        selectedRecTestScenarioName: action.value !== undefined ? action.value : state.previous_RecTestScenarioName,
        changedRecTestScenarioName: true,
      };
    case RESET_REC_TEST_SCENARIO_NAME:
      return {
        ...state,
        selectedRecTestScenarioName: state.previous_RecTestScenarioName,
        previous_RecTestScenarioName: undefined,
        changedRecTestScenarioName: false,
      };
    case UPDATE_SCENARIO_NAME:
      state.previous_RecTestScenarioName = state.selectedRecTestScenarioName;
      return {
        ...state,
      };
    case CHANGED_REC_TEST_SCENARIO_NAME:
      let response = action.response;
      return {
        ...state,
        projectStatus: response,
        changedRecTestScenarioName: false,
      };

    case REQUESTING_APP_PACKAGE_ACTIVITY:
      return {
        ...state,
        requestingLaunchAppActivity: action.status,
      };

    case MAKE_SUB_REC_TEST_STEP:
      state.recTestStepList = action.response.data;
      return {
        ...state,
      };

    case CHANGE_SELECT_CHILD_ELEMENT:
      updateRecTestStepDetail = findRecTestStepByTag(state.recTestStepList, action.recTestStepTag, state.testBlockStep);
      updateRecTestStepDetail = insertIntoRecTestStepRecTestProperty(
        updateRecTestStepDetail,
        "isRefElementSelected",
        action.checked ? 1 : 0
      );
      if (action.checked) {
        updateRecTestStepDetail["recTestStepProperty"] = {
          ...updateRecTestStepDetail["recTestStepProperty"],
          refRecStepElementXpaths: [...updateRecTestStepDetail["recTestStepProperty"]["recStepElementXpaths"]],
          refRecStepElementAttributes: [...updateRecTestStepDetail["recTestStepProperty"]["recStepElementAttributes"]],
          refRecStepSelectedElementPath: updateRecTestStepDetail["recTestStepProperty"]["recStepSelectedElementPath"],
          recStepElementXpaths: [],
          recStepElementAttributes: [],
          recStepSelectedElementPath: undefined,
        };
      } else if (
        updateRecTestStepDetail["recTestStepProperty"]["refRecStepElementXpaths"].length > 0 ||
        updateRecTestStepDetail["recTestStepProperty"]["refRecStepElementAttributes"].length > 0
      ) {
        updateRecTestStepDetail["recTestStepProperty"] = {
          ...updateRecTestStepDetail["recTestStepProperty"],
          recStepElementXpaths: [...updateRecTestStepDetail["recTestStepProperty"]["refRecStepElementXpaths"]],
          recStepElementAttributes: [...updateRecTestStepDetail["recTestStepProperty"]["refRecStepElementAttributes"]],
          recStepSelectedElementPath: updateRecTestStepDetail["recTestStepProperty"]["refRecStepSelectedElementPath"],
          refRecStepElementXpaths: [],
          refRecStepElementAttributes: [],
          refRecStepSelectedElementPath: undefined,
        };
      }

      recTestStepList = updateRecTestStep(state.recTestStepList, updateRecTestStepDetail);
      return {
        ...state,
        recTestStepList,
      };
    case ADD_PREVIEW_REQUEST:
      return {
        ...state,
        requestingForPreview: action.runStepType ? [action.runStepType] : [PLAY_TYPE.ALL_STEPS],
        runStepType: action.runStepType,
      };
    case ADD_PREVIEW_REQUESTED:
      return {
        ...state,
        requestingForPreview: [],
      };
    case STOP_PREVIEW_REQUEST:
      return {
        ...state,
        requestingToStopPreview: action.runStepType ? [action.runStepType] : [PLAY_TYPE.ALL_STEPS],
      };
    case STOP_PREVIEW_REQUESTED:
      return {
        ...state,
        requestingToStopPreview: [],
        requestingForPreview: [],
        isPreviewRunning: false,
      };
    case CLEAR_PREVIEW_IN_SINGLE_COLUMN_VIEW:
      state.recTestStepList = removePreviewStatusFromRecTestStep(
        state.recTestStepList ? [...state.recTestStepList] : []
      );
      if (
        state.selectedRecTestStepDetail &&
        state.selectedRecTestStepDetail.recTestSteps &&
        state.selectedRecTestStepDetail.recTestSteps.length > 0
      ) {
        state.selectedRecTestStepDetail.recTestSteps = removePreviewStatusFromRecTestStep([
          ...state.selectedRecTestStepDetail.recTestSteps,
        ]);
      }
      return {
        ...state,
        previewRunId: undefined,
        previewRunNumber: undefined,
        previewUserName: undefined,
        requestingToStopPreview: [],
        isPreviewRunning: false,
        previous_RecTestScenarioName: undefined,
      };
    case UPDATE_PREVIEW_REQUEST:
      return {
        ...state,
        previewRunId: action.response.data.runId,
        previewRunNumber: action.response.data.runNumber,
        previewUserName: action.response.data.userName,
        isPreviewRunning: true,
      };
    case PREVIEW_COMPLETED:
      return {
        ...state,
        isPreviewRunning: false,
        requestingForPreview: [],
        requestingToStopPreview: [],
      };
    case UPDATE_PREVIEW_STATUS:
      if (action.response.data.length > 0 && action.response.status === SUCCESS_STATUS) {
        let executedStep = action.response.data.filter((item) => item.stepStatus && item.stepStatus !== "null");
        if (executedStep.length > 0) {
          state.selectedRecTestStepDetail = findRecTestStepByRecTestScenarioRecTestStepAssocIdAndTag(
            state.recTestStepList ? _.cloneDeep(state.recTestStepList) : [],
            executedStep[executedStep.length - 1]["testBlockRecTestScenarioRecTestStepAssocId"],
            executedStep[executedStep.length - 1]["stepTagId"],
            state.testBlockStep
          );
        }
        if (
          state.selectedRecTestStepDetail &&
          state.selectedRecTestStepDetail.recTestSteps &&
          state.selectedRecTestStepDetail.recTestSteps.length > 0 &&
          state.selectedRecTestStepDetail.recTestSteps[0].recTestStepProperty?.action !== STEP
        ) {
          let childRecTestStepsPreviewStatus = state.selectedRecTestStepDetail.recTestSteps.filter(
            (item) => item.previewStatus !== undefined
          );
          childRecTestStepsPreviewStatus =
            childRecTestStepsPreviewStatus.length > 0
              ? childRecTestStepsPreviewStatus[childRecTestStepsPreviewStatus.length - 1]
              : undefined;
        }
        state.recTestStepList = updatePreviewResultStatus(
          state.recTestStepList ? _.cloneDeep(state.recTestStepList) : [],
          executedStep,
          action?.orderId
        );
        state.isPreviewRunning = action.response.data[0]["runStatus"] === "DONE" ? false : true;
      }
      return {
        ...state,
      };
    case UPDATE_REC_TEST_STEP_ACTIONS:
      state.recTestStepList = updateRecTestStepActions(
        state.recTestStepList ? [...state.recTestStepList] : [],
        action.data,
        action.parentId
      );
      state.selectedRecTestStepDetail.recTestSteps = action.data;
      return {
        ...state,
      };
    case REQUEST_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS:
      return {
        ...state,
        requestGetRecTestScenarioStepActionList: true,
      };
    case REQUESTED_FETCHING_REC_TEST_SCENARIO_STEP_ACTIONS:
      return {
        ...state,
        requestGetRecTestScenarioStepActionList: false,
      };
    case REC_TEST_SCENARIO_STEP_ACTIONS_LIST:
      let _importRecTestScenarioStepList = [...state.importRecTestScenarioStepList];

      _importRecTestScenarioStepList = action.response.data.data?.map((i) => {
        let parentData = { ...i, recTestSteps: i.recTestSteps.parentRecTestSteps || [] };
        return {
          ...parentData,
          recTestSteps: parentData.recTestSteps.map((element) => {
            return {
              ...element,
              parentRecTestScenarioRecTestStepsAssocId: parentData?.id,
              recTestSteps: handleChildData(
                element,
                i.recTestSteps.childRecTestSteps,
                i.recTestSteps?.childTestBlockSteps
              ),
            };
          }),
        };
      });
      if (action.response.status === SUCCESS_STATUS) {
        return {
          ...state,
          importRecTestScenarioStepActionList: action.response.data.data,
          totalScenarioCounts: action.response.data.totalCount,
          currentScenarioPage: action.response.data.currentPage,
          totalScenarioItems: action.response.data.totalItems,
          pageScenarioItems: action.response.data.pageItems,
          importRecTestScenarioStepList: _importRecTestScenarioStepList,
        };
      } else {
        return {
          ...state,
        };
      }
    // TODO : Not IN USE
    case CHANGE_SELECTED_REC_TEST_STEP_OR_ACTION:
      return {
        ...state,
        // selectedRecTestStepOrActionForImport: action.requestData,
        // checkedTestBlockImportStep: [...action?.checkedKeys],
      };

    case REMOVE_SELECTED_REC_TEST_STEP_OR_ACTION:
      return {
        ...state,
        checkedTestBlockImportStep: [],
      };

    case SHOW_UNSAVED_REC_TEST_STEP_ACTION_MODAL:
      return {
        ...state,
        unSavedRecTestStepActionModalVisible: true,
      };
    case HIDE_UNSAVED_REC_TEST_STEP_ACTION_MODAL:
      return {
        ...state,
        unSavedRecTestStepActionModalVisible: false,
      };
    case UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE:
      if (action.response.status === SUCCESS_STATUS) {
        updateRecTestStepDetail = findRecTestStepById(state.recTestStepList, action.recTestStepId, state.testBlockStep);
        if (updateRecTestStepDetail.recTestStepProperty?.recStepVerifyElementProperties) {
          updateRecTestStepDetail["recTestStepProperty"] = {
            ...updateRecTestStepDetail.recTestStepProperty,
            recStepVerifyElementProperties: action.data.recStepVerifyElementProperties
              ? action.data?.recStepVerifyElementProperties
              : updateRecTestStepDetail.recTestStepProperty?.recStepVerifyElementProperties,
          };
        }
        if (updateRecTestStepDetail.recTestStepProperty?.recStepElementAttributes) {
          updateRecTestStepDetail["recTestStepProperty"] = {
            ...updateRecTestStepDetail.recTestStepProperty,
            recStepElementAttributes: action.data.recStepElementAttributes
              ? action.data.recStepElementAttributes
              : updateRecTestStepDetail.recTestStepProperty?.recStepElementAttributes,
          };
        }
        updateRecTestStepDetail.recTestStepProperty = {
          ...updateRecTestStepDetail.recTestStepProperty,
          preConditionTitle:
            action.data.preConditionTitle === null
              ? action.data.preConditionTitle
              : updateRecTestStepDetail.recTestStepProperty?.preConditionTitle,
        };
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_stepName"];
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_userSuggestedStepName"];
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_continueAfterFail"];
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_loopCount"];
        delete updateRecTestStepDetail["recTestStepProperty"]["isLoopCountSaved"];
        delete updateRecTestStepDetail["recTestStepProperty"]["previous_preConditionTitle"];
      }
      recTestStepList = updateRecTestStep(state.recTestStepList, updateRecTestStepDetail);
      return {
        ...state,
        requestingUpdateRecTestStepAction: true,
        recTestStepList,
      };
    case REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE:
      return {
        ...state,
        requestingUpdateRecTestStepAction: true,
      };
    case REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE:
      return {
        ...state,
        requestingUpdateRecTestStepAction: false,
      };
    case CHANGE_REC_TEST_STEP_PRE_CONDITION_PANEL:
      return {
        ...state,
        activeRecTestStepAction: state.selectedRecTestStepDetail,
      };

    case SHOW_ADD_TEST_DATA_FORM:
      return {
        ...state,
        showAddTestDataForm: true,
      };
    case HIDE_ADD_TEST_DATA_FORM:
      return {
        ...state,
        showAddTestDataForm: false,
      };

    case CLEAR_RE_RECORD_ELEMENT_STEP_TAG:
      return {
        ...state,
      };
    case FIND_DEVICE_BY_UDID:
      let selectedDeviceByUdid = undefined;
      if (action.response && action.response.status === SUCCESS_STATUS) {
        selectedDeviceByUdid = action.response.data.data;
        selectedDeviceByUdid["deviceFarm"] = { id: action.deviceFarmId };
      }
      return {
        ...state,
        selectedDeviceByUdid,
      };
    case REQUEST_FOR_DEVICE_LOAD:
      return {
        ...state,
        deviceLoading: true,
      };
    case REQUESTED_FOR_DEVICE_LOAD:
      return {
        ...state,
        deviceLoading: false,
      };
    case REQUEST_FOR_DEVICE_UNLOAD:
      return {
        ...state,
        deviceUnloading: true,
      };
    case REQUESTED_FOR_DEVICE_UNLOAD:
      return {
        ...state,
        deviceUnloading: false,
      };

    case MAKE_STEP_EDITABLE_REQUEST:
      return {
        ...state,
        makeTestStepEditableEnabled: action.isEnable,
      };
    case GET_LIST_OF_BLOCKS:
      testBlockStep = state.testBlockStep ? state.testBlockStep : undefined;
      if (action?.response?.data?.data?.length === 0) {
        testBlockStep = undefined;
        recTestStepList = [];
      }
      return {
        ...state,
        listOfBlocksByProjectId: action?.response?.data,
        // testBlockStep,
        // recTestStepList,
      };

    case ADD_TEST_BLOCK_STEP:
      if (state.testBlockStep === undefined) {
        testBlockStep = undefined;
      } else {
        testBlockStep = state.testBlockStep ? state.testBlockStep : undefined;
      }
      if (action.withPrecondition) {
        action.response.data.recTestStepProperty["preConditionTitle"] = "";
        action.response.data.recTestStepProperty["isStepHasPrecondition"] = 1;
        action.response.data.recTestStepProperty["isStepHasVerifyTestData"] = action.withVerifyTestData ? 1 : 0;
        action.response.data.recTestStepProperty["recElements"].push(getNewRecElement(action.blockElementGuid));
      }
      if (action.withVerifyTestData) {
        action.response.data.recTestStepProperty["preConditionTitle"] = "";
        action.response.data.recTestStepProperty["isStepHasPrecondition"] = 1;
        action.response.data.recTestStepProperty["isStepHasVerifyTestData"] = action.withVerifyTestData ? 1 : 0;
        action.response.data.recTestStepProperty["recStepVerifyElementProperties"].push(
          getEmptyVerificationRow("VerifyTestData")
        );
      }
      testBlockStep = action.response.data;
      // }
      return {
        ...state,
        testBlockStep,
      };

    case DELETE_TEST_BLOCK_STEP:
      activeRecTestStepAction = undefined;
      recTestStepList = state.recTestStepList;
      testBlockStep = state.testBlockStep;
      let siblingPreTestStep = findSiblingTestBlockStep(
        recTestStepList,
        state.selectedRecTestStepIdForDelete,
        state.selectedRecTestStepTagForDelete
      );
      recTestStepList = removeTestBlockStep(
        recTestStepList,
        state.selectedRecTestStepIdForDelete
          ? [state.selectedRecTestStepIdForDelete]
          : state.selectedRectTestScenarioStepId
      );
      if (state.isSelectedRecTestStepForDelete) {
        if (
          state.selectedRecTestStepDetail &&
          state.selectedRecTestStepDetail.id === state.selectedRecTestStepIdForDelete
        ) {
          state.selectedRecTestStepDetail = undefined;
        }
        if (siblingPreTestStep) {
          state.selectedRecTestStepDetail = siblingPreTestStep;
          if (
            siblingPreTestStep.recTestSteps &&
            siblingPreTestStep.recTestSteps.length > 0 &&
            siblingPreTestStep.recTestSteps[0].recTestStepProperty?.action !== STEP
          ) {
            activeRecTestStepAction = siblingPreTestStep.recTestSteps[0];
          }
        }
      } // Select the Sibling Action
      else {
        if (siblingPreTestStep) {
          activeRecTestStepAction = siblingPreTestStep;
        }
      }
      recTestStepList = removeRecTestStep(recTestStepList, action.selectedRecTestStepIdForDelete, null);
      return {
        ...state,
        recTestStepList:
          action.response && action.response.data
            ? action.response.data.recTestSteps
            : testBlockStep?.id === action.selectedRecTestStepIdForDelete
            ? []
            : recTestStepList,
        testBlockStep: testBlockStep?.id === action.selectedRecTestStepIdForDelete ? undefined : testBlockStep,
        activeRecTestStepAction,
        selectedTestBLockStepIdForDelete: undefined,
        isSelectedRecTestStepForDelete: false,
        selectedTestBlockStepTagForDelete: undefined,
        deleteRecTestStepModalVisible: false,
        requestingDeleteRecTestStep: false,
        fetchingRecTestStepList: false,
        recTestStepActionSelectedElement: undefined,
        selectedRecElementForDelete: undefined,
        deleteRecElementModalVisible: false,
      };
    case SELECT_TEST_BLOCK__STEP_DATA:
      return {
        ...state,
        selectTestBlockStep: action?.data,
      };

    case TEST_DATA_SET_SELECTION_SCENARIO:
      return {
        ...state,
        selectTestDataSet: action?.response,
      };

    case CHANGE_TEST_PLAN_DATA:
      return {
        ...state,
        selectTestDataSetId: action?.data,
      };

    case UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW:
      recTestStepList = [...state.recTestStepList];
      if (action.updatedRecTestStep?.tag === state.testBlockStep?.tag) {
        state.testBlockStep = action.updatedRecTestStep;
      } else {
        if (
          state.selectedRecStepForOperation === undefined ||
          state.selectedRecStepForOperation?.tag === action.updatedRecTestStep?.tag
        ) {
          state.selectedRecStepForOperation = action.updatedRecTestStep;
        }
        recTestStepList = updateRecTestStep([...state.recTestStepList], action.updatedRecTestStep);
      }
      return {
        ...state,
        recTestStepList,
      };

    case SELECTED_TEST_STEP_BLOCK:
      return {
        ...state,
        selectedTestStepBlock: action.testBlockStep,
      };
    case SELECT_REC_TEST_STEPS:
      return {
        ...state,
        selectedRecStepForOperation: action.recTestStep,
        selectedRectTestScenarioStepId: [],
      };
    case CHANGE_DISABLE_REC_STEP:
      recTestStepList = findDisableRecTestStep(
        state.recTestStepList,
        action.recTestStep,
        action.checked,
        action.stepType
      );
      return {
        ...state,
      };

    case SELECT_REC_TEST_STEP_FOR_PLAY:
      return {
        ...state,
        selectedRecTestStepForPlay: action?.selectedRecTestStep,
      };
    default:
      return state;
  }
}

const findDisableRecTestStep = (recTestStepList, recTestStep, checked, stepType) => {
  if (recTestStepList?.length > 0) {
    recTestStepList.map((i) => {
      if (
        stepType === TEST_SCENARIOS
          ? i?.recTestScenarioRecTestStepAssocId === recTestStep.recTestScenarioRecTestStepAssocId
          : i?.recTestStepProperty?.id === recTestStep.recTestStepProperty?.id
      ) {
        return (i.isSkipStep = checked);
      } else {
        findDisableRecTestStep(i?.recTestSteps, recTestStep, checked, stepType);
      }
    });
  }
  return recTestStepList;
};

function removeUnSavedRecTestStep(recTestStepList) {
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].id === undefined) {
      recTestStepList.splice(i, 1);
    } else if (recTestStepList[i].recTestSteps && recTestStepList[i].recTestSteps.length > 0) {
      removeUnSavedRecTestStep(recTestStepList[i].recTestSteps);
    }
  }
  return recTestStepList;
}

function findRecTestStepDetailFromRecTestStepList(recTestList, selectedRecTestStepDetailId) {
  let obj = undefined;
  for (let i = 0; i < recTestList.length; i++) {
    if (recTestList[i].id === selectedRecTestStepDetailId) {
      obj = recTestList[i];
      break;
    } else if (
      recTestList[i].recTestSteps &&
      recTestList[i].recTestSteps.length > 0 &&
      recTestList[i].recTestStepProperty?.action === STEP
    ) {
      obj = findRecTestStepDetailFromRecTestStepList(recTestList[i].recTestSteps, selectedRecTestStepDetailId);
      if (obj) {
        break;
      }
    }
  }
  return obj;
}

export function updateRecTestStepAction(recTestStepList, updatedRecTestStepAction) {
  recTestStepList.map((v, k) => {
    if (v.tag === updatedRecTestStepAction.tag) {
      v = updatedRecTestStepAction;
    } else if (v.recTestStepProperty?.action === STEP) {
      v.recTestSteps = updateRecTestStepAction(v.recTestSteps, updatedRecTestStepAction);
    }
  });
  return recTestStepList;
}
function updateRecTestStepActions(recTestStepList, updatedRecTestStepActions, parentId) {
  recTestStepList.map((v, k) => {
    if (v.id === parseInt(parentId)) {
      v.recTestSteps = updatedRecTestStepActions;
    } else if (v.recTestStepProperty?.action === STEP) {
      v.recTestSteps = updateRecTestStepActions(v.recTestSteps, updatedRecTestStepActions, parentId);
    }
  });
  return recTestStepList;
}
function removePreviewStatusFromRecTestStep(recTestStepList) {
  recTestStepList.map((v) => {
    v.previewStatus = undefined;
    v.preconditionResult = undefined;
    if (v.recTestSteps && v.recTestSteps.length > 0) {
      v.recTestSteps = removePreviewStatusFromRecTestStep(v.recTestSteps);
    }
  });
  return recTestStepList;
}
export function findRecTestStepById(recTestStepList, id, testBlockStep, parentRecTestStep = undefined) {
  let selectedRecTestStep = undefined;
  if (testBlockStep?.id === id) {
    return testBlockStep;
  }
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].id !== undefined && recTestStepList[i].id === id) {
      // if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      selectedRecTestStep = recTestStepList[i];
      break;
      // } else if (parentRecTestStep) {
      // selectedRecTestStep = parentRecTestStep;
      // break;
      // }
    } else {
      if (
        recTestStepList[i].recTestStepProperty?.action === STEP &&
        recTestStepList[i].recTestSteps &&
        recTestStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = findRecTestStepById(
          recTestStepList[i].recTestSteps,
          id,
          testBlockStep,
          recTestStepList[i]
        );
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function findRecTestStepByRecTestScenarioRecTestStepAssocId(
  recTestStepList,
  recTestScenarioRecTestStepAssocId,
  childStepList,
  parentRecTestStep = undefined
) {
  let selectedRecTestStep = undefined;
  for (let i = 0; i < recTestStepList.length; i++) {
    if (
      recTestStepList[i].recTestScenarioRecTestStepAssocId !== undefined &&
      recTestStepList[i].recTestScenarioRecTestStepAssocId === recTestScenarioRecTestStepAssocId
    ) {
      selectedRecTestStep = recTestStepList[i];
      break;
    } else {
      if (
        recTestStepList[i].recTestStepProperty?.action === STEP &&
        recTestStepList[i].recTestSteps &&
        recTestStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = findRecTestStepByRecTestScenarioRecTestStepAssocId(
          recTestStepList[i].recTestSteps,
          recTestScenarioRecTestStepAssocId,
          recTestStepList[i]
        );
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function findRecTestStepByRecTestScenarioRecTestStepAssocIdAndTag(
  recTestStepList,
  recTestScenarioRecTestStepAssocId,
  recTestTag,
  parentRecTestStep = undefined
) {
  let selectedRecTestStep = undefined;
  for (let i = 0; i < recTestStepList.length; i++) {
    if (
      (recTestStepList[i].testBlockRecTestScenarioRecTestStepAssocId === undefined &&
        recTestStepList[i].tag === recTestTag) ||
      (recTestStepList[i].testBlockRecTestScenarioRecTestStepAssocId === recTestScenarioRecTestStepAssocId &&
        recTestStepList[i].tag === recTestTag)
    ) {
      // if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      selectedRecTestStep = recTestStepList[i];
      break;
      // } else if (parentRecTestStep) {
      // selectedRecTestStep = parentRecTestStep;
      // break;
      // }
    } else {
      if (
        recTestStepList[i].recTestStepProperty?.action === STEP &&
        recTestStepList[i].recTestSteps &&
        recTestStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = findRecTestStepByRecTestScenarioRecTestStepAssocIdAndTag(
          recTestStepList[i].recTestSteps,
          recTestScenarioRecTestStepAssocId,
          recTestTag,
          recTestStepList[i]
        );
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function findRecTestStepByAssocId(recTestStepList, id, parentRecTestStep = undefined) {
  let selectedRecTestStep = undefined;
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].id !== undefined && recTestStepList[i].recTestScenarioRecTestStepAssocId === id) {
      // if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      selectedRecTestStep = recTestStepList[i];
      break;
      // } else if (parentRecTestStep) {
      // selectedRecTestStep = parentRecTestStep;
      // break;
      // }
    } else {
      if (
        recTestStepList[i].recTestStepProperty?.action === STEP &&
        recTestStepList[i].recTestSteps &&
        recTestStepList[i].recTestSteps.length > 0
      ) {
        selectedRecTestStep = findRecTestStepByAssocId(recTestStepList[i].recTestSteps, id, recTestStepList[i]);
        if (selectedRecTestStep) {
          break;
        }
      }
    }
  }
  return selectedRecTestStep;
}

export function findRecTestStepByTag(recTestStepList, tag, testBlockStep, parentRecTestStep = undefined) {
  let selectedRecTestStep = undefined;
  if (testBlockStep?.tag === tag) {
    selectedRecTestStep = testBlockStep;
  } else {
    for (let i = 0; i < recTestStepList?.length; i++) {
      if (recTestStepList[i].tag === tag) {
        // if (recTestStepList[i].recTestStepProperty?.action === STEP) {
        selectedRecTestStep = recTestStepList[i];
        break;
        // } else if (parentRecTestStep) {
        // selectedRecTestStep = parentRecTestStep;
        // break;
        // }
      } else {
        if (
          recTestStepList[i]?.recTestStepProperty?.action === STEP &&
          recTestStepList[i].recTestSteps &&
          recTestStepList[i].recTestSteps.length > 0
        ) {
          selectedRecTestStep = findRecTestStepByTag(
            recTestStepList[i].recTestSteps,
            tag,
            testBlockStep,
            recTestStepList[i]
          );
          if (selectedRecTestStep) {
            break;
          }
        }
      }
    }
  }

  return selectedRecTestStep;
}
function updateRecElementProperties(recElements, key, value, recElementGuid) {
  let recElement = getRecElement(recElements, recElementGuid);
  recElement[key] = value;
  return updateRecElement(recElements, recElement);
}
function updateRecTestStep(recTestStepList, selectedRecTestStep) {
  for (let i = 0; i < recTestStepList.length; i++) {
    // if (recTestStepList[i].recTestStepProperty?.action === STEP) {
    if (
      (recTestStepList[i]?.tag === selectedRecTestStep?.tag &&
        recTestStepList[i]?.testBlockRecTestStepId &&
        selectedRecTestStep?.testBlockRecTestStep &&
        recTestStepList[i]?.testBlockRecTestStepId === selectedRecTestStep?.testBlockRecTestStep?.id) ||
      (recTestStepList[i]?.tag === selectedRecTestStep?.tag &&
        recTestStepList[i]?.testBlockRecTestStepId &&
        recTestStepList[i]?.testBlockRecTestStepId === selectedRecTestStep?.testBlockRecTestStepId) ||
      (recTestStepList[i]?.tag === selectedRecTestStep?.tag &&
        recTestStepList[i]?.recTestScenarioRecTestStepAssocId ===
          selectedRecTestStep?.recTestScenarioRecTestStepAssocId)
    ) {
      recTestStepList[i] = selectedRecTestStep;
    } else if (
      recTestStepList[i].recTestSteps &&
      recTestStepList[i].recTestSteps.length > 0 &&
      recTestStepList[i].recTestStepProperty?.action === STEP
    ) {
      recTestStepList[i].recTestSteps = updateRecTestStep(recTestStepList[i].recTestSteps, selectedRecTestStep);
    }
    // }
  }
  return recTestStepList;
}
function insertStepIntoList(recTestStepList, savedRecTestStep) {
  if (recTestStepList && recTestStepList.length === 0) {
    recTestStepList.push(savedRecTestStep);
  }
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].tag === savedRecTestStep.tag) {
      //  check if tag is same
      recTestStepList[i] = savedRecTestStep;
    } else if (
      //  check if Parent is same then  append into the child
      savedRecTestStep.parentRecTestStep != null &&
      recTestStepList[i].id === savedRecTestStep.parentRecTestStep.id &&
      recTestStepList[i].recTestSteps
    ) {
      // If Child is undefined
      if (recTestStepList[i].recTestSteps === undefined) {
        recTestStepList[i].recTestSteps = [];
      }
      // If Child is empty then insert into the child list
      if (recTestStepList[i].recTestSteps.length === 0) {
        recTestStepList[i].recTestSteps.push(savedRecTestStep);
      } else if (
        // If Child list is not empty check through tag if not exist then insert
        recTestStepList[i].recTestSteps.filter((item) => item.tag === savedRecTestStep.tag).length === 0
      ) {
        recTestStepList[i].recTestSteps.push(savedRecTestStep);
      } else {
        // If Child list is not empty check through tag if exist then replace with existing one
        recTestStepList[i].recTestSteps = insertStepIntoList(recTestStepList[i].recTestSteps, savedRecTestStep);
      }
    } else if (
      //  check if have sub step then call the same function
      recTestStepList[i].recTestSteps &&
      recTestStepList[i].recTestSteps.filter((item) => item.recTestStepProperty?.action === STEP).length > 0
    ) {
      recTestStepList[i].recTestSteps = insertStepIntoList(recTestStepList[i].recTestSteps, savedRecTestStep);
    }
  }
  return recTestStepList;
}

function removeRecTestStep(recTestStepList, id, tag, recTestScenarioRecTestStepAssocId) {
  for (let i = 0; i < recTestStepList?.length; i++) {
    if (
      (recTestScenarioRecTestStepAssocId &&
        recTestStepList[i].recTestScenarioRecTestStepAssocId === recTestScenarioRecTestStepAssocId) ||
      (recTestScenarioRecTestStepAssocId === undefined && (recTestStepList[i].id === id || recTestStepList[i] === tag))
    ) {
      recTestStepList.splice(i, 1);
    } else if (recTestStepList[i]["recTestSteps"] && recTestStepList[i]["recTestSteps"].length > 0) {
      recTestStepList[i]["recTestSteps"] = removeRecTestStep(
        recTestStepList[i]["recTestSteps"],
        id,
        tag,
        recTestScenarioRecTestStepAssocId
      );
    }
  }
  return recTestStepList;
}

function removeRecTestStepPreCondition(recTestStepList, id) {
  for (let i = 0; i < recTestStepList.length; i++) {
    if (recTestStepList[i].recTestStepProperty && recTestStepList[i].recTestStepProperty?.id === id) {
      recTestStepList[i].recTestStepProperty["continueAfterFail"] = 0;
      recTestStepList[i].recTestStepProperty["isStepHasPrecondition"] = 0;
      recTestStepList[i].recTestStepProperty["preConditionTitle"] = "";
      recTestStepList[i].recTestStepProperty["recStepVerifyElementProperties"] = [];
      recTestStepList[i].recTestStepProperty["recElements"] = [];
      Object.keys(recTestStepList[i].recTestStepProperty).map((v) => {
        if (v.includes("previous_")) {
          delete recTestStepList[i]["recTestStepProperty"][v];
        }
      });
    } else if (recTestStepList[i]["recTestSteps"] && recTestStepList[i]["recTestSteps"].length > 0) {
      recTestStepList[i]["recTestSteps"] = removeRecTestStepPreCondition(recTestStepList[i]["recTestSteps"], id);
    }
  }
  return recTestStepList;
}

export function insertIntoRecTestStepRecTestProperty(recTestStep, key, value) {
  if (recTestStep["recTestStepProperty"]["previous_" + key] === undefined) {
    recTestStep["recTestStepProperty"]["previous_" + key] = _.cloneDeep(recTestStep["recTestStepProperty"][key]);
  }
  recTestStep["recTestStepProperty"][key] = value;
  return recTestStep;
}

export function insertIntoRecElement(recElement, key, value) {
  if (recElement.id && recElement.id !== 0 && recElement["previous_" + key] === undefined) {
    recElement["previous_" + key] = recElement[key];
  }
  recElement[key] = value;
  return recElement;
}

// export function insertIntoRecTestStepRecTestProperty2(recTestStep, key, value) {
//   if (recTestStep["previous_" + key] === undefined) {
//     recTestStep["previous_" + key] = recTestStep[key];
//   }
//   recTestStep[key] = value;
//   return recTestStep;
// }

function insertIntoRecTestStepActionRecTestProperty(
  selectedRecTestStepActions,
  recTestStepActionId,
  recTestStepActionTag,
  key,
  value
) {
  selectedRecTestStepActions.map((v, k) => {
    if (v.id === recTestStepActionId || v.tag === recTestStepActionTag) {
      if (v["recTestStepProperty"]["previous_" + key] === undefined) {
        v["recTestStepProperty"]["previous_" + key] = v["recTestStepProperty"][key];
      }
      v["recTestStepProperty"][key] = value;
    }
  });
  return selectedRecTestStepActions;
}

export function getEmptyVerificationRow(type, key = "", newUuid) {
  if (type === "GROUP") {
    return {
      type,
      uuid: newUuid || getUniqueId(),
      key: "",
      value: "",
      childRecStepVerifyElementProperties: [],
      operator: "OR",
    };
  } else {
    return {
      type,
      uuid: newUuid || getUniqueId(),
      key,
      value: "",
    };
  }
}

function updatePreviewResultStatus(recTestStepList, previewRecTestStepStatus, parentStepPath) {
  recTestStepList.map((v, k) => {
    var stepPathList = parentStepPath === undefined ? v.orderId : parentStepPath + "." + v.orderId;
    var stepPath = stepPathList.toString();
    let stepPreviewStatus = previewRecTestStepStatus.filter(
      (item) =>
        // In Test Block
        (item.testBlockRecTestScenarioRecTestStepAssocId === 0 &&
          item.stepTagId === v.tag &&
          stepPath === item.stepOrderPath) ||
        // In Scenario
        // Check for Not Test Block Step
        // Check for Test Block
        (v.recTestScenarioRecTestStepAssocId === item.testBlockRecTestScenarioRecTestStepAssocId &&
          item.stepTagId === v.tag &&
          stepPath === item.stepOrderPath) ||
        // Check for Test Block inner steps
        (v.recTestScenarioRecTestStepAssocId === undefined &&
          item.testBlockRecTestScenarioRecTestStepAssocId === v.testBlockRecTestScenarioRecTestStepAssocId &&
          item.stepTagId === v.tag &&
          stepPath === item.stepOrderPath)
    );
    if (
      stepPreviewStatus.length > 0 &&
      stepPreviewStatus[stepPreviewStatus.length - 1].stepStatus &&
      stepPreviewStatus[stepPreviewStatus.length - 1].stepStatus !== "null"
    ) {
      v.previewStatus = stepPreviewStatus[stepPreviewStatus.length - 1].stepStatus;
      v.retryCount = stepPreviewStatus.length - 1;
    }
    if (
      stepPreviewStatus.length > 0 &&
      stepPreviewStatus[stepPreviewStatus.length - 1].stepStatus &&
      stepPreviewStatus[stepPreviewStatus.length - 1].preconditionResult !== null
    ) {
      v.preconditionResult = stepPreviewStatus[stepPreviewStatus.length - 1].preconditionResult;
      v.retryCount = stepPreviewStatus.length - 1;
    }
    // Child Level
    if (v.recTestSteps && v.recTestSteps.length > 0) {
      v.recTestSteps = updatePreviewResultStatus(v.recTestSteps, previewRecTestStepStatus, stepPath);
    }
  });
  return recTestStepList;
}

export function fetchUnSavedStepList(recTestStepList) {
  let unSavedSteps = [];
  if (recTestStepList && recTestStepList.length === 0) {
    return unSavedSteps;
  }
  recTestStepList &&
    recTestStepList.forEach((v, k) => {
      if (v.id === undefined) {
        unSavedSteps.push(v);
      } else if (v.recTestSteps && v.recTestSteps.length > 0) {
        Array.prototype.push.apply(unSavedSteps, fetchUnSavedStepList(v.recTestSteps));
      }
    });
  return unSavedSteps;
}

export function findRecTestStepActionDetail(
  recTestStepList,
  recTestStepId,
  recTestStepTag,
  testBlockStep,
  parentId = undefined,
  assocId = undefined
) {
  if (testBlockStep?.tag === recTestStepTag) {
    return testBlockStep;
  }
  let newRecTestStepAction = undefined;
  if (recTestStepList) {
    for (let i = 0; i < recTestStepList.length; i++) {
      if (recTestStepList[i].id === recTestStepId || recTestStepList[i].tag === recTestStepTag) {
        newRecTestStepAction = recTestStepList[i];
        if (assocId) {
          newRecTestStepAction["parentRecTestScenarioRecTestStepsAssoc"] = { id: assocId };
        }
        newRecTestStepAction["recTestScenarioRecTestStepsAssoc"] = {
          id: recTestStepList[i].recTestScenarioRecTestStepAssocId,
        };
        if (parentId) {
          newRecTestStepAction["parentRecTestStep"] = { id: parentId };
        }
        break;
      } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
        newRecTestStepAction = findRecTestStepActionDetail(
          recTestStepList[i].recTestSteps,
          recTestStepId,
          recTestStepTag,
          recTestStepList[i].id,
          recTestStepList[i].recTestScenarioRecTestStepAssocId
        );
        if (newRecTestStepAction) {
          break;
        }
      }
    }
  }
  return newRecTestStepAction;
}

export function markUnSavedAsRecTestStepActionDetail(recTestStepList, recTestStepId, recTestStepTag) {
  if (recTestStepList) {
    for (let i = 0; i < recTestStepList.length; i++) {
      if (recTestStepList[i].id === recTestStepId || recTestStepList[i].tag === recTestStepTag) {
        recTestStepList[i]["unSaved"] = true;
        break;
      } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
        recTestStepList[i].recTestSteps = fetchNewRecTestStepAction(recTestStepList[i].recTestSteps);
      }
    }
  }
  return recTestStepList;
}

export function fetchNewRecTestStepAction(recTestStepList, reRecordRecStepTag, testBlockStep) {
  let newRecTestStepAction = undefined;
  if (testBlockStep?.tag === reRecordRecStepTag) {
    return testBlockStep;
  }
  if (recTestStepList) {
    for (let i = 0; i < recTestStepList.length; i++) {
      if (
        ((reRecordRecStepTag === "" || reRecordRecStepTag === undefined) && recTestStepList[i].id === undefined) ||
        (recTestStepList[i].recTestStepProperty?.action === STEP &&
          recTestStepList[i].recTestStepProperty?.previous_isStepHasPrecondition === 0 &&
          (recTestStepList[i].recTestStepProperty?.recStepVerifyElementProperties.length === 0 ||
            recTestStepList[i].recTestStepProperty?.recStepVerifyElementProperties.filter(
              (item) => item.id === undefined
            ).length > 0)) ||
        (reRecordRecStepTag !== "" && recTestStepList[i].tag === reRecordRecStepTag)
      ) {
        return recTestStepList[i];
      } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
        newRecTestStepAction = fetchNewRecTestStepAction(
          recTestStepList[i].recTestSteps,
          reRecordRecStepTag,
          testBlockStep
        );
        if (newRecTestStepAction) {
          return newRecTestStepAction;
        }
      }
    }
  }
  return newRecTestStepAction;
}

function findSiblingRecTestStep(
  recTestStepList,
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  parentStep
) {
  let siblingRecTestStep = undefined;
  for (let i = 0; i < recTestStepList?.length; i++) {
    if (
      (recTestScenarioRecTestStepAssocId &&
        recTestStepList[i].recTestScenarioRecTestStepAssocId === recTestScenarioRecTestStepAssocId) ||
      (recTestScenarioRecTestStepAssocId === undefined &&
        (recTestStepList[i].id === recTestStepId || recTestStepList[i].tag === recTestStepTag))
    ) {
      if (recTestStepList.length > 1) {
        if (i >= 1) {
          siblingRecTestStep = recTestStepList[i - 1];
        } else {
          siblingRecTestStep = recTestStepList[i + 1];
        }
      } else {
        siblingRecTestStep = parentStep;
      }
      break;
    } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      siblingRecTestStep = findSiblingRecTestStep(
        recTestStepList[i].recTestSteps,
        recTestStepId,
        recTestStepTag,
        recTestScenarioRecTestStepAssocId,
        recTestStepList[i]
      );
      if (siblingRecTestStep) {
        break;
      }
    }
  }
  return siblingRecTestStep;
}

export function findParentRecTestStep(
  recTestStepList,
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  parentStep
) {
  let parentRecTestStep = undefined;
  for (let i = 0; i < recTestStepList?.length; i++) {
    if (
      (recTestScenarioRecTestStepAssocId &&
        recTestStepList[i].recTestScenarioRecTestStepAssocId === recTestScenarioRecTestStepAssocId) ||
      (recTestScenarioRecTestStepAssocId === undefined &&
        (recTestStepList[i].id === recTestStepId || recTestStepList[i].tag === recTestStepTag))
    ) {
      parentRecTestStep = parentStep;
      break;
    } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      parentRecTestStep = findParentRecTestStep(
        recTestStepList[i].recTestSteps,
        recTestStepId,
        recTestStepTag,
        recTestScenarioRecTestStepAssocId,
        recTestStepList[i]
      );
      if (parentRecTestStep) {
        break;
      }
    }
  }
  return parentRecTestStep;
}

function getTextXpath(value) {
  return (
    "//*[contains(translate(@content-desc,'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz') ," +
    value +
    ")] |" +
    "//*[contains(translate(@text,'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz') ," +
    value +
    ")] |" +
    "//*[contains(translate(@name,'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz') ," +
    value +
    ")] |" +
    "//*[contains(translate(@label,'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz') ," +
    value +
    ")] |" +
    "//*[contains(translate(@value,'ABCDEFGHIJKLMNOPQRSTUVWXYZ', 'abcdefghijklmnopqrstuvwxyz') ," +
    value +
    ")] "
  );
}

function findSiblingTestBlockStep(recTestStepList, recTestStepId, recTestStepTag, parentStep) {
  let siblingRecTestStep = undefined;
  for (let i = 0; i < recTestStepList?.length; i++) {
    if (recTestStepList[i].id === recTestStepId || recTestStepList[i].tag === recTestStepTag) {
      if (recTestStepList.length > 1) {
        if (i >= 1) {
          siblingRecTestStep = recTestStepList[i - 1];
        } else {
          siblingRecTestStep = recTestStepList[i + 1];
        }
      } else {
        siblingRecTestStep = parentStep;
      }
      break;
    } else if (recTestStepList[i].recTestStepProperty?.action === STEP) {
      siblingRecTestStep = findSiblingTestBlockStep(
        recTestStepList[i].recTestSteps,
        recTestStepId,
        recTestStepTag,
        recTestStepList[i]
      );
      if (siblingRecTestStep) {
        break;
      }
    }
  }
  return siblingRecTestStep;
}

const removeTestBlockStep = (recTestStepList, selectedRectTestScenarioStepId) =>
  recTestStepList?.filter((j) => !selectedRectTestScenarioStepId?.includes(j?.id));

const getPreviousStoredValue = (data, value) => {
  return data["previous_" + value] ? data["previous_" + value] : data[value];
};

const AllChildRecTestStepId = (recTestStepId, recTestStepList, _selectedRectTestScenarioStepId, childRecTestSteps) => {
  const checked = _selectedRectTestScenarioStepId.includes(recTestStepId);
  const childList = childRecTestSteps[recTestStepId];
  if (childList) {
    childList.forEach((element) => {
      if (element.isSkipStep === 0) {
        if (
          checked &&
          !_selectedRectTestScenarioStepId?.includes(element.recTestScenarioRecTestStepAssocId || element.recTestStepId)
        ) {
          _selectedRectTestScenarioStepId.push(element.recTestScenarioRecTestStepAssocId || element.recTestStepId);
        } else if (
          !checked &&
          _selectedRectTestScenarioStepId?.includes(element.recTestScenarioRecTestStepAssocId || element.recTestStepId)
        ) {
          _selectedRectTestScenarioStepId.splice(
            _selectedRectTestScenarioStepId.indexOf(element.recTestScenarioRecTestStepAssocId || element.recTestStepId),
            1
          );
        }
      }
      AllChildRecTestStepId(
        element.recTestScenarioRecTestStepAssocId || element.recTestStepId,
        recTestStepList,
        _selectedRectTestScenarioStepId,
        childRecTestSteps
      );
    });
  }
};

export const AllParentRecTestStepId = (
  recTestStepId,
  recTestStepList,
  _selectedRectTestScenarioStepId,
  childRecTestSteps,
  selectParentStepId
) => {
  const childList = childRecTestSteps[recTestStepId];
  if (childList) {
    const checked = childList.every((i) =>
      _selectedRectTestScenarioStepId.includes(i?.recTestScenarioRecTestStepAssocId || i.recTestStepId)
    );
    if (checked && !_selectedRectTestScenarioStepId.includes(recTestStepId) && !selectParentStepId) {
      _selectedRectTestScenarioStepId.push(recTestStepId);
    } else if (!checked && _selectedRectTestScenarioStepId.includes(recTestStepId)) {
      _selectedRectTestScenarioStepId.splice(_selectedRectTestScenarioStepId.indexOf(recTestStepId), 1);
    }

    const parentId = recTestStepList?.find(
      (i) => i?.recTestScenarioRecTestStepAssocId === recTestStepId
    )?.parentRecTestScenarioRecTestStepsAssocId;

    const parentRecId = recTestStepList?.find((i) => i?.recTestStepId === recTestStepId)?.parentRecTestStepId;
    if (parentId || parentRecId) {
      AllParentRecTestStepId(
        parentId || parentRecId,
        recTestStepList,
        _selectedRectTestScenarioStepId,
        childRecTestSteps,
        selectParentStepId
      );
    }
  }
  return _selectedRectTestScenarioStepId;
};

export const AllChildRecTestStepOrActionsId = (
  selectedId,
  _selectedRecTestStepOrActionForImport,
  childRecTestSteps
) => {
  const checked = _selectedRecTestStepOrActionForImport.includes(selectedId);
  if (childRecTestSteps) {
    childRecTestSteps.forEach((element) => {
      let selectedSteps = element.recTestScenarioRecTestStepAssocId || element.id;
      if (checked && !_selectedRecTestStepOrActionForImport.includes(selectedSteps)) {
        _selectedRecTestStepOrActionForImport.push(selectedSteps);
      } else if (!checked && _selectedRecTestStepOrActionForImport.includes(selectedSteps)) {
        _selectedRecTestStepOrActionForImport.splice(_selectedRecTestStepOrActionForImport.indexOf(selectedSteps), 1);
      }
      AllChildRecTestStepOrActionsId(selectedId, _selectedRecTestStepOrActionForImport, element.recTestSteps);
    });
  }
};

const handleChildData = (recTestStepData, childrenData, childTestBlockSteps) => {
  const list =
    recTestStepData.isTestBlock === 1
      ? childTestBlockSteps[recTestStepData.recTestStepId] || []
      : childrenData[recTestStepData.recTestScenarioRecTestStepAssocId] || [];
  return list.map((element) => {
    return { ...element, recTestSteps: handleChildData(element, childrenData, childTestBlockSteps) };
  });
};

const removeMultiColumnViewStep = (recTestStepList, selectedRectTestScenarioStepId) =>
  recTestStepList?.filter((j) => !selectedRectTestScenarioStepId?.includes(j?.recTestScenarioRecTestStepAssocId));
