import { Col, Row } from "antd";
import React from "react";
import { addVerificationRow, removeVerificationRow } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import AddVerificationGroup from "./AddVerificationGroup";
import AddVerificationRow from "./AddVerificationRow";
import Operator from "./Operator";
import RemoveVerificationRow from "./RemoveVerificationRow";

export default (props) => {
  return (
    <React.Fragment>
      <Row className="verification-builder-main-handler">
        <div>
          <Operator
            disabled={props.disabled}
            operator={props.operator}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
          />
          <div className={"add-verification-group-button ml-10"}> Add Rule:</div>
          <AddVerificationRow
            disabled={props.disabled}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
            addVerficationRow={addVerificationRow}
          />
          <div className={"add-verification-group-button"}> Add group:</div>
          <AddVerificationGroup
            disabled={props.disabled}
            uuid={props.uuid}
            recTestStep={props.recTestStep}
            recElement={props.recElement}
            addVerficationRow={addVerificationRow}
          />
          {!props.isFirstRow && (
            <RemoveVerificationRow
              disabled={props.disabled}
              parentId={props.uuid}
              recTestStep={props.recTestStep}
              recElement={props.recElement}
              removeVerificationRow={removeVerificationRow}
            />
          )}
        </div>
      </Row>
    </React.Fragment>
  );
};
