import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Input, Select, Tooltip } from "antd";
import { SYSTEM_KEYS, TOOLTIP_BG_COLOR } from "../../../../../../../../Constants";
import {
  changeTestDataCustomValue,
  changeTestDataIsCustomReferenceTestData,
  changeTestDataReferenceTestData,
} from "../../../../../../../../actions/TestDataSet/TestDataSetRedirectAction";
import styles from "../AssignedTestDataListViewV2.module.scss";

const { Option } = Select;

const CustomValueColumn = ({ testDataValue, isForView = false, refTestDataList }) => {
  const dispatch = useDispatch();
  const { testDataSetView } = useSelector((state) => state.TestDataSetReducer);
  const {
    scope,
    isForDefaultRefTestDataSetAssoc,
    isCustomChecked,
    refTestDataSetAssocId,
    testDataSetAssocId,
    testDataCustomValue,
  } = testDataValue;

  return (
    (scope !== "System Key" || testDataSetView === SYSTEM_KEYS) &&
    isForDefaultRefTestDataSetAssoc === 0 && (
      <div className={styles.tooltipOrCheckBox}>
        {testDataSetView !== SYSTEM_KEYS && (
          <Tooltip title="Reference Test Data" color={TOOLTIP_BG_COLOR}>
            <Checkbox
              disabled={isForView}
              checked={isCustomChecked || refTestDataSetAssocId !== 0}
              onClick={(e) => dispatch(changeTestDataIsCustomReferenceTestData(e.target.checked, testDataValue))}
              className={styles.checkBox}
            />
          </Tooltip>
        )}
        {testDataSetView !== SYSTEM_KEYS && (isCustomChecked || refTestDataSetAssocId !== 0) ? (
          <Select
            disabled={isForView}
            placeholder="Please Select The Custom Value"
            className="select-app-for-default"
            value={refTestDataSetAssocId || undefined}
            onChange={(e) => dispatch(changeTestDataReferenceTestData(e, testDataValue))}
          >
            {refTestDataList &&
              refTestDataList.length > 0 &&
              refTestDataList.map((i, k) => {
                return (
                  i.testDataSetAssocId !== testDataSetAssocId && (
                    <Option value={i.testDataSetAssocId} key={i.testDataSetAssocId}>
                      {"(S-" + i.stepOrderPath + ") " + i.name}
                    </Option>
                  )
                );
              })}
          </Select>
        ) : (
          <Input
            disabled={isForView}
            className={styles.input}
            size="large"
            placeholder="Enter Custom Test Data Value"
            name="testDataCustomValue"
            value={testDataCustomValue}
            onChange={(e) => {
              dispatch(changeTestDataCustomValue(e.target.value, testDataValue));
            }}
          />
        )}
      </div>
    )
  );
};

export default CustomValueColumn;
