import base64 from "base-64";
import { EventSourcePolyfill } from "event-source-polyfill";
import { Cookies } from "react-cookie";
import request from "sync-request";
import { apiPath, deviceServiceName } from "./config.js";
import history from "../../history.js";
import { showNotification, updateResponse } from "../../Util/index.js";
import { Redirect } from "react-router-dom";
import { generateDeviceToken } from "../../actions/ServerListAction.js";
const cookies = new Cookies();

export function sync(service, method, data, deviceServiceUrl) {
  let result = "";
  let apiUrl = "";
  let url = "";
  let resp = "";
  let token =
    localStorage.getItem("xpressToken") && localStorage.getItem("xpressToken") != null
      ? "Bearer " + localStorage.getItem("xpressToken")
      : "";
  let requestedUser = localStorage.getItem("requestedUser");

  if (deviceServiceUrl) {
    token =
      localStorage.getItem("xpressDeviceToken") &&
      localStorage.getItem("xpressDeviceToken") !== "undefined" &&
      localStorage.getItem("xpressDeviceToken") != null
        ? "Bearer " + localStorage.getItem("xpressDeviceToken")
        : "";
    apiUrl = deviceServiceUrl + deviceServiceName;
  } else {
    apiUrl = apiPath;
  }

  switch (method) {
    case "GET":
      url = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      try {
        result = request("GET", apiUrl + service + "?" + url, {
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            requestedUser: requestedUser,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
        });
        resp = JSON.parse(result.body);

        return resp;
      } catch (err) {
        console.log("Error in request");
        return {};
      }

    case "POST":
      result = request("POST", apiUrl + "/" + service, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        json: data,
      });
      resp = JSON.parse(result.body);

      return resp;
    case "PUT":
      result = request("PUT", apiUrl + "/" + service, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        json: data,
      });
      resp = JSON.parse(result.body);
      return resp;
    case "DELETE":
      result = request("DELETE", apiUrl + "/" + service, {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        json: data,
      });
      resp = JSON.parse(result.body);
      return resp;
    default:
      return result;
  }
}

export async function async(service, method, data = {}, deviceServiceUrl, signal, dispatch) {
  let apiUrl = "";
  let url = "";
  let token =
    localStorage.getItem("xpressToken") &&
    localStorage.getItem("xpressToken") !== "undefined" &&
    localStorage.getItem("xpressToken") != null
      ? "Bearer " + localStorage.getItem("xpressToken")
      : "";
  let requestedUser = localStorage.getItem("requestedUser");

  if (deviceServiceUrl) {
    token =
      localStorage.getItem("xpressDeviceToken") &&
      localStorage.getItem("xpressDeviceToken") !== "undefined" &&
      localStorage.getItem("xpressDeviceToken") != null
        ? "Bearer " + localStorage.getItem("xpressDeviceToken")
        : "";
    apiUrl = deviceServiceUrl + deviceServiceName;
  } else {
    apiUrl = apiPath;
  }

  const platformErrorHandler = {
    xpress: {
      401: () => {
        clearToken();
      },
      403: () => {
        // check if already redirected to projects or not
        if (history) {
          if (history?.location?.pathname !== "/projects/") {
            history.push(`/projects/`);
          }
        }
      },
    },
    device: {
      401: () => {
        dispatch(generateDeviceToken());
      },
      403: () => {
        dispatch(generateDeviceToken());
      },
    },
  };

  const handleResponse = async (res) => {
    const platformType = deviceServiceUrl ? "device" : "xpress";

    if (platformErrorHandler[platformType].hasOwnProperty(res.status)) {
      platformErrorHandler[platformType][res.status]();
    }
    if (res.status === 403) {
      return {};
    }
    return await res.json();
  };

  switch (method) {
    case "GET":
      try {
        url = Object.keys(data)
          .map(function (k) {
            return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
          })
          .join("&");
        return fetch(apiUrl + service + (url != "" ? "?" + url : ""), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            requestedUser: requestedUser,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          signal,
        })
          .then(handleResponse)
          .catch((err) => {
            if (err.name === "TypeError" || err.message === "Failed to fetch") {
              // Network error, do not show notification
              console.error("Network error:", err);
            } else if (!signal) {
              if (deviceServiceUrl) {
                dispatch(generateDeviceToken());
              } else {
                showNotification("error", "Internal Server Error", true);
              }
            }
            return {};
          });
      } catch (err) {
        console.log(err);
        return {};
      }
    case "POST":
      try {
        return fetch(apiUrl + service, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: token,
            requestedUser: requestedUser,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          body: JSON.stringify(data),
        })
          .then(handleResponse)
          .catch((err) => {
            console.log(err);
            return {};
          });
      } catch (err) {
        console.log(err);
      }
    case "POST_WITH_FILE":
      return fetch(apiUrl + service, {
        method: "POST",
        headers: {
          Authorization: token,
          requestedUser: requestedUser,
        },
        body: data,
      })
        .then(handleResponse)
        .catch((err) => {
          console.log(err);
          return {};
        });
    case "PUT":
      return fetch(apiUrl + service, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
        body: data,
      }).catch((err) => {
        console.log(err);
        return {};
      });
    case "GET_FOR_FILE":
      url = Object.keys(data)
        .map(function (k) {
          return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
        })
        .join("&");
      return fetch(apiUrl + service + (url != "" ? "?" + url : ""), {
        method: "GET",
        headers: {
          Authorization: token,
          requestedUser: requestedUser,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      })
        .then(handleResponse)
        .catch((err) => {
          console.log(err);
          return {};
        });
    default:
      return null;
  }
}

export function prepareEventSource(service, data = {}, serverUrl) {
  // const EventSource = NativeEventSource || EventSourcePolyfill;
  let apiUrl = serverUrl;
  let url = "";
  let token = "";

  // data.token = localStorage.getItem("serverAccessToken");
  if (apiUrl === "") {
    apiUrl = apiPath;
    //For Backend Service
    token = localStorage.getItem("xpressToken");
  } else {
    //For Device Service
    apiUrl += deviceServiceName;
    token = localStorage.getItem("xpressDeviceToken");
  }

  let resp = "";
  url = Object.keys(data)
    .map(function (k) {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    })
    .join("&");
  let requestedUser = localStorage.getItem("requestedUser");

  return new EventSourcePolyfill(apiUrl + service + "?" + url, {
    headers: { Authorization: "Bearer " + token, requestedUser: requestedUser },
  });
}
let notificationShown = false;
function clearToken() {
  localStorage.clear();
  cookies.set("xpressToken", "", { expires: 0 });
  cookies.set("xpressEmail", "", { expires: 0 });
  if (!notificationShown) {
    showNotification("error", "Token Expired", true);
    notificationShown = true;
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}

export function login(service, method, data = {}) {
  switch (method) {
    case "POST":
      return fetch(apiPath + service, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=utf8",
          Authorization: "Basic " + base64.encode("automation_client:lsspl#123"),
        },
        body: data,
      }).then(function (res) {
        return res.json();
      });
    // let result =  request('POST', apiPath + service, {
    //     headers: {
    //         'Content-Type': 'application/x-www-form-urlencoded;charset=utf8',
    //         'Authorization': 'Basic ' + base64.encode('automation_client:lsspl#123'),
    //         'timezoneOffset': new Date().getTimezoneOffset()
    //     },
    //     body: data
    // });
    // let resp = JSON.parse(result.body);
    // return Util.updateResponse(resp);
    // case 'POST':
    //     return fetch(apiPath + service,
    //         {
    //             method: "POST",
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded;charset=utf8',
    //                 'Authorization': 'Basic ' + base64.encode('automation_client:lsspl#123'),
    //                 'timezoneOffset': new Date().getTimezoneOffset()
    //             },
    //             body: data
    //         }).then(function (res) {
    //             return res.json();
    //         })
    default:
      return null;
  }
}
