import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";
import { ElementType, TOOLTIP_BG_COLOR } from "../../../../../Constants";
import {
  APPLICATION_STRING_ICON,
  BLACK_COLOR,
  BLUE_COLOR,
  DARK_BLUE_COLOR,
  DARK_GREEN,
  DEVICE_STRING_ICON,
  DISABLE_COLOR,
  LOAD_DEVICE_ICON,
  MORE_PROPERTIES_ICON,
  REPLACE_ELEMENT_ICON,
} from "../../../../../Constants/SvgConstants";
import { ATTACH_APPLICATION_STRING, ATTACH_DEVICE_STRING } from "../../../../../Constants/TooltipConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import {
  applicationDrawerVisible,
  deviceDrawerVisible,
  getApplicationStrings,
  getDeviceStrings,
  getListOfLocalesForApplicationStrings,
  getListOfLocalesForDeviceStrings,
  reRecordRecTestStepElement,
  showRecTestStepActionElementDetailDrawer,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { selectRecTestSteps } from "../../../../../actions/RecTestStepAction";
import styles from "./RecTestStepElementSelectionDetailV2.module.scss";

const RecTestStepElementDetailsV2 = (props) => {
  const dispatch = useDispatch();
  const {
    recElement,
    recElementDisplayObj,
    projectId,
    data,
    disabled,
    isSkipStep,
    parentRecElement,
    stepId,
    isTestBlock,
    showReRecordButton,
  } = props;
  const { unSavedRecStepTag, unSavedRecElementGuid, requestingPerformRecTestStepAction, getListOfLocales } =
    useSelector((state) => state.RecTestStepCommonViewReducer);
  const { selectedDeviceByUdid, isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);

  //Destructure object
  const { id, tag } = data ? data : {};
  const { guid, elementType, applicationStrings, deviceStrings } = recElement ? recElement : {};
  const { value, name } = recElementDisplayObj ? recElementDisplayObj : {};

  let disableReRecordButton = useMemo(
    () =>
      disabled ||
      selectedDeviceByUdid === undefined ||
      !startDeviceStreamingStatus ||
      requestingPerformRecTestStepAction ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
        unSavedRecStepTag !== tag &&
        unSavedRecElementGuid !== guid),
    [
      disabled,
      selectedDeviceByUdid,
      startDeviceStreamingStatus,
      requestingPerformRecTestStepAction,
      unSavedRecStepTag,
      unSavedRecElementGuid,
      tag,
      guid,
    ]
  );

  const disableReplaceElement = useMemo(
    () => isPreviewRunning || disableReRecordButton || isSkipStep,
    [isPreviewRunning, disableReRecordButton, isSkipStep]
  );

  let applicationStringsValuesCount =
    applicationStrings?.values.length > 1 ? (
      <div>
        <span>1. {applicationStrings?.values[0]?.value}</span>
        <div className={styles["moreLength"]}>+{applicationStrings?.values.length - 1} more</div>
      </div>
    ) : (
      applicationStrings?.values[0]?.value
    );

  let deviceStringsValuesCount =
    deviceStrings?.values.length > 1 ? (
      <div>
        <span>1. {deviceStrings?.values[0]?.value}</span>
        <div className={styles["moreLength"]}>+{deviceStrings?.values.length - 1} more</div>
      </div>
    ) : (
      deviceStrings?.values[0]?.value
    );

  const selectApplicationButtonDisable = useMemo(
    () =>
      !value ||
      value === "None" ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid),
    [value, unSavedRecElementGuid, guid]
  );

  const selectDeviceButtonDisable = useMemo(
    () =>
      !value ||
      value === "None" ||
      (isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) && unSavedRecElementGuid !== guid),
    [value, unSavedRecElementGuid, guid]
  );

  const handleClickApplicationString = () => {
    if (!selectApplicationButtonDisable) {
      dispatch(getApplicationStrings(projectId, "", "", "", guid, tag));
      isEmpty(getListOfLocales) && dispatch(getListOfLocalesForApplicationStrings());
      dispatch(applicationDrawerVisible(true, guid));
      dispatch(selectRecTestSteps(data));
    }
  };
  const handleClickDeviceString = () => {
    if (!selectDeviceButtonDisable) {
      dispatch(getDeviceStrings(projectId, "", "", "", guid, tag));
      isEmpty(getListOfLocales) && dispatch(getListOfLocalesForDeviceStrings());
      dispatch(deviceDrawerVisible(true, guid));
      dispatch(selectRecTestSteps(data));
    }
  };

  return (
    <div className={styles["properties_Settings"]}>
      <div className={styles["properties_Title"]}>Properties</div>

      <div className={styles["select_Element_details"]}>
        {[ElementType.DIFFERENT_VERSION, ElementType.PARENT_ELEMENT].includes(elementType) ? (
          <div>
            <div className={styles["rec_Element_Display"]}>
              <div className={styles["element_Label"]}>
                <div className={styles["element_Details_Label"]}>Identifier</div>
                <Tooltip title={name?.length >= 30 && name} color={TOOLTIP_BG_COLOR}>
                  <span className={`${styles["identifier"]} ${name?.length >= 30 && styles["ellipse"]}`}>: {name}</span>
                </Tooltip>
              </div>
              <div className={styles["selectElementValue"]}>
                <div className={styles["element_Label"]}>
                  <div className={styles["element_Details_Label"]}>Value</div>
                  <Tooltip title={value?.length >= 30 && value} color={TOOLTIP_BG_COLOR}>
                    <span
                      className={`${styles["identifier"]} ${styles["element_Value"]} ${
                        value?.length >= 30 && styles["ellipse"]
                      }`}
                    >
                      : {value}
                    </span>
                  </Tooltip>
                </div>
                <Tooltip
                  title={
                    <div>
                      {applicationStrings ? (
                        <>
                          <div>
                            <span className={styles["tooltip_Label"]}>App String Key: </span>
                            <span>{applicationStrings?.key}</span>
                          </div>
                          <div>
                            <span className={styles["tooltip_Label"]}> Values: </span>
                            <span>{applicationStrings?.values?.length}</span>
                          </div>
                          <div>
                            <span>{applicationStringsValuesCount}</span>
                          </div>
                        </>
                      ) : (
                        <span>{ATTACH_APPLICATION_STRING}</span>
                      )}
                    </div>
                  }
                  color={TOOLTIP_BG_COLOR}
                >
                  <span
                    className={`${styles["applicationString"]} ${
                      selectApplicationButtonDisable && styles["disableApplicationString"]
                    }`}
                    onClick={() => {
                      !selectApplicationButtonDisable && handleClickApplicationString();
                    }}
                  >
                    <SvgLoader
                      iconName={APPLICATION_STRING_ICON}
                      width={20}
                      iconColor={
                        selectApplicationButtonDisable ? DISABLE_COLOR : applicationStrings ? BLUE_COLOR : BLACK_COLOR
                      }
                      disabled={selectApplicationButtonDisable}
                    />
                  </span>
                </Tooltip>
                <Tooltip
                  title={
                    <div>
                      {deviceStrings ? (
                        <>
                          <div>
                            <span className={styles["tooltip_Label"]}>Device String Key: </span>
                            <span>{deviceStrings?.key}</span>
                          </div>
                          <div>
                            <span className={styles["tooltip_Label"]}> Values: </span>
                            <span>{deviceStrings?.values?.length}</span>
                          </div>
                          <div>
                            <span>{deviceStringsValuesCount}</span>
                          </div>
                        </>
                      ) : (
                        <span>{ATTACH_DEVICE_STRING}</span>
                      )}
                    </div>
                  }
                  color={TOOLTIP_BG_COLOR}
                >
                  <span
                    onClick={() => {
                      !selectDeviceButtonDisable && handleClickDeviceString();
                    }}
                  >
                    <SvgLoader
                      iconName={DEVICE_STRING_ICON}
                      width={20}
                      iconColor={selectDeviceButtonDisable ? DISABLE_COLOR : deviceStrings ? BLUE_COLOR : BLACK_COLOR}
                      disabled={selectDeviceButtonDisable}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className={styles["more_Properties_Button"]}>
              {Object.values(recElementDisplayObj)?.every((i) => i !== "None") && (
                <Tooltip title="More Properties" overlayClassName={"button_Tooltip_Visible"} color={TOOLTIP_BG_COLOR}>
                  <div
                    className={`${styles["icons_And_Text"]}} ${styles["more_And_Replace_Element_Btn"]}`}
                    onClick={() => {
                      dispatch(showRecTestStepActionElementDetailDrawer(id, tag, guid, data));
                    }}
                  >
                    <SvgLoader iconName={MORE_PROPERTIES_ICON} iconColor={DARK_BLUE_COLOR} />
                    <span className={`${styles["button_Text"]} ${stepId?.length > 7 && "hide_Button_Label"}`}>
                      More Properties
                    </span>
                  </div>
                </Tooltip>
              )}
              {showReRecordButton && (
                <div>
                  <Tooltip
                    title={stepId?.length > 7 && "Replace Element"}
                    overlayClassName={stepId?.length > 7 && "button_Tooltip_Visible"}
                    color={TOOLTIP_BG_COLOR}
                  >
                    <div
                      className={`${styles["icons_And_Text"]} ${styles["replace_Element"]} ${
                        styles["more_And_Replace_Element_Btn"]
                      } ${disableReplaceElement && styles["disabled_Replace_Element"]}`}
                      onClick={() => {
                        !disableReplaceElement && dispatch(reRecordRecTestStepElement(tag, guid, parentRecElement));
                      }}
                    >
                      <SvgLoader
                        iconName={REPLACE_ELEMENT_ICON}
                        iconColor={disableReplaceElement ? DISABLE_COLOR : DARK_GREEN}
                        disabled={disableReplaceElement}
                      />
                      <span
                        className={`${styles["replace_Element_Text"]} ${stepId?.length > 7 && "hide_Button_Label"} ${
                          disableReplaceElement && styles["disabled_Replace_Element_Text"]
                        }`}
                      >
                        Replace Element
                      </span>
                      {!startDeviceStreamingStatus && !isTestBlock && !disabled && (
                        <Tooltip title={"Load Device if you want to change the Element!"} color={TOOLTIP_BG_COLOR}>
                          <div>
                            <SvgLoader iconName={LOAD_DEVICE_ICON} />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default RecTestStepElementDetailsV2;
