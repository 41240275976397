import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "antd";
import { isEmpty } from "lodash";
import { ELEMENT_ACTION } from "../../../../../Constants";
import { getParentRefElementLocator } from "../../../../../Util";
import { changeTapByIndex } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./TapByIndexV2.module.scss";

const TapByIndexV2 = (props) => {
  const dispatch = useDispatch();
  const { isForView, data, isInputDisabled } = props;
  const { selectedElementsDetailList } = useSelector((state) => state.RecordingReducer);

  let parentRefElementDetails = getParentRefElementLocator(selectedElementsDetailList);

  //Destructure of data object
  const { action, uniqueByIndex } = data && data.recTestStepProperty ? data.recTestStepProperty : {};

  return (
    <>
      <div className={styles["tap_By_Index"]}>
        <div>Index: </div>
        <Select
          className={styles["select_Index"]}
          required={action === ELEMENT_ACTION.TAP_BY_INDEX}
          disabled={isForView || isInputDisabled}
          value={uniqueByIndex}
          onChange={(value) => {
            dispatch(changeTapByIndex(value - 1, ELEMENT_ACTION.TAP_BY_INDEX, data?.tag));
          }}
        >
          {!isEmpty(parentRefElementDetails) &&
            parentRefElementDetails[0].mobileElements?.map((v, k) => {
              return (
                <Select.Option key={k} value={k + 1}>
                  {k + 1}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    </>
  );
};

export default TapByIndexV2;
