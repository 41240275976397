import { Col, Popover, Row, Select, Tooltip } from "antd";
import { default as React, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SELECTOR_TYPE, TOOLTIP_BG_COLOR } from "../../../../Constants";
import _ from "lodash";
import {
  ADD_ICON,
  BLACK_COLOR,
  BLUE_COLOR,
  DISABLE_ELEMENT_ICON,
  DISABLE_ICON_COLOR,
  GENERAL_SETTINGS_ICON,
  LIGHT_GRAY_COLOR,
  WHITE_COLOR,
} from "../../../../Constants/SvgConstants";
import { isNotNullAndNotEmptyAndNotUndefined } from "../../../../Util";
import SvgLoader from "../../../../Util/SvgLoader";
import {
  addElementIntoStep,
  changeRecTestStepElementCondition,
  visibleGeneralSettingsDrawer,
} from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import TextButton from "../../../CommonComponents/TextButton/TextButton";
import RecStepGeneralSetting from "../RecStepGeneralSetting/RecStepGeneralSetting";
import RecTestStepElement from "../RecTestStepElement/RecTestStepElement";
import styles from "./RecTestStepElementList.module.scss";

const RecTestStepElementList = (props) => {
  const { data, disabled, projectId, isDeviceLoaded, isSkipStep, recTestSteps, isTestBlock, saveButtonDisable } = props;
  const { startDeviceStreamingStatus } = useSelector((state) => state.RecordingReducer);
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecStepTag, unSavedRecElementGuid, requestingPerformRecTestStepAction } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const [elementCount, setElementCount] = useState(1);
  const dispatch = useDispatch();
  const isSaveButtonDisable =
    !startDeviceStreamingStatus ||
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && data.tag !== unSavedRecStepTag) ||
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) ||
    // Reason for Comment : If we delete the UnSaved RecElement it didn't allow to add new Element
    // isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) ||
    disabled ||
    isPreviewRunning ||
    isSkipStep;
  const disableAddElement = isSaveButtonDisable || requestingPerformRecTestStepAction;
  const allowOrCondition = !_.isEmpty(data?.recTestStepProperty?.recStepVerifyElementProperties) && 
      data?.recTestStepProperty?.recStepVerifyElementProperties[0].key === "exist";
  const allowAndCondition = data?.recTestStepProperty?.action === "step" || data?.recTestStepProperty?.action === "verifications";
  const elementOperator = data?.recTestStepProperty?.recElementOperator != null && allowOrCondition ? 
      data?.recTestStepProperty.recElementOperator : (!allowOrCondition && allowAndCondition) ? "AND" : "OR";
  const isElementOperatorOptionDisabled = _.isEmpty(data?.recTestStepProperty?.recStepVerifyElementProperties) || !allowOrCondition || disableAddElement;

  const selectionImage = (recElement) => {
    return (recElement.id === undefined ||
      recElement.previous_elementImage !== undefined ||
      recElement.elementImage !== null) &&
      recElement.elementImage !== undefined &&
      !recElement.elementImage.includes("https://")
      ? "data:image/jpeg;base64," + recElement.elementImage
      : recElement.elementImage;
  };

  useEffect(() => {
    if (data?.recTestStepProperty?.recElements?.length !== elementCount) {
      setElementCount(1);
    }
  }, [data?.recTestStepProperty?.recElements?.length]);

  return (
    <Row className={styles.recElementDetails}>
      <Col span={5} className={styles.recElementsList}>
        <div className={styles.addElement}>
          <TextButton
            title={
              <Tooltip title={disableAddElement && "Load Device to add a new Element."} color={TOOLTIP_BG_COLOR}>
                <div className={styles.newElementText}>
                  <SvgLoader
                    iconName={ADD_ICON}
                    iconColor={disableAddElement ? DISABLE_ICON_COLOR : BLUE_COLOR}
                    disabled={disableAddElement}
                    width="1.6rem"
                    height="1.6rem"
                  />
                  <span className={`${disableAddElement && styles.disableAddELementText} ${styles.addElementText}`}>
                    New Element
                  </span>
                </div>
              </Tooltip>
            }
            disabled={disableAddElement}
            onClick={() => {
              dispatch(addElementIntoStep(data?.tag, undefined));
              setElementCount(data?.recTestStepProperty?.recElements?.length);
            }}
          />
          <Tooltip title="Set Element condition" color={TOOLTIP_BG_COLOR}>
            <Select 
                value={elementOperator}
                disabled={isElementOperatorOptionDisabled} 
                options={[{value: "OR", label: "OR"}, {value: "AND", label: "AND"}]}
                onChange={e => dispatch(changeRecTestStepElementCondition(e, data?.tag))}
                >
            </Select>
          </Tooltip>
          <Tooltip title={"Settings"} color={TOOLTIP_BG_COLOR}>
            <div className={styles.settingsIcon}>
              <SvgLoader
                iconName={GENERAL_SETTINGS_ICON}
                iconColor={isPreviewRunning ? DISABLE_ICON_COLOR : BLACK_COLOR}
                handleClick={() =>
                  !isPreviewRunning &&
                  dispatch(visibleGeneralSettingsDrawer(true, data.recTestStepProperty?.action, data?.tag))
                }
                disabled={isPreviewRunning}
              />
            </div>
          </Tooltip>
        </div>
        <div className={styles.allElementsList}>
          {data?.recTestStepProperty?.recElements?.map((recElement, index) => (
            <>
              <div
                className={`${styles.elementCount} ${disabled ? "cursor__not_allowed" : "cursor__pointer"} 
                ${
                  index + 1 === elementCount
                    ? recElement?.isActive === 0
                      ? styles.disableElementBgColor
                      : styles.elementBgColor
                    : ""
                }`}
                onClick={() => setElementCount(index + 1)}
              >
                <div className={styles.imageOrName}>
                  <div className={styles.disableElementIcon}>
                    <span
                      className={`${styles.elementCountText} ${
                        index + 1 === elementCount && styles.selectedElementText
                      }`}
                    >
                      Element {index + 1}
                    </span>
                  </div>
                  <div>
                    {recElement !== undefined && selectionImage(recElement) && (
                      <Popover
                        content={
                          <img
                            src={selectionImage(recElement)}
                            height={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 50 : 450}
                            weight={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 50 : 225}
                            alt="Screenshot"
                          />
                        }
                        placement="left"
                        title="Element Image"
                        trigger="hover"
                      >
                        <img
                          className={styles.screenshot}
                          src={selectionImage(recElement)}
                          height={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 15 : 30}
                          weight={recElement.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN ? 15 : 30}
                          alt="Screenshot"
                        />
                      </Popover>
                    )}
                  </div>
                </div>
                  <div>
                    {recElement?.isActive === 0 && (
                      <SvgLoader
                        iconName={DISABLE_ELEMENT_ICON}
                        iconColor={index + 1 === elementCount ? WHITE_COLOR : LIGHT_GRAY_COLOR}
                        width="18.333px"
                        height="18.333px"
                      />
                    )}
                  </div>
              </div>
              {index !== data?.recTestStepProperty?.recElements?.length - 1 && (
                <div className={styles.divider}>
                  <div className={styles.orLabel}>{elementOperator}</div>
                </div>
              )}
            </>
          ))}
        </div>
      </Col>
      {data?.recTestStepProperty?.recElements?.map((recElement, index) => {
        return (
          index + 1 === elementCount && (
            <Col span={19}>
              <div className={`${styles.element} ${recElement?.isActive === 0 && styles.disableElement}`}>
                <RecTestStepElement
                  recTestSteps={recTestSteps}
                  key={index}
                  recElement={recElement}
                  data={data}
                  disabled={disabled}
                  projectId={projectId}
                  isDeviceLoaded={isDeviceLoaded}
                  isSkipStep={isSkipStep}
                  isTestBlock={isTestBlock}
                  elementCount={elementCount}
                />
              </div>
            </Col>
          )
        );
      })}
      <RecStepGeneralSetting
        data={data}
        disabled={disabled}
        disableMaxNoOfSwipes={disabled || isSkipStep || isPreviewRunning}
        isPreviewRunning={isPreviewRunning}
        isSkipStep={isSkipStep}
        saveButtonDisable={saveButtonDisable}
      />
    </Row>
  );
};

export default RecTestStepElementList;
