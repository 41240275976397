import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer } from "antd";
import { isEmpty } from "lodash";
import {
  disableResetButtonForIdentificationRulesDrawer,
  disableSaveButtonStepForIdentificationRulesDrawer,
  getRecElement,
} from "../../../../../Util";
import {
  resetRecTestStepAction,
  saveRecTestStepActionWithCurrentDeviceImage,
  visibleIdentificationRulesDrawer,
} from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { findRecTestStepByTag } from "../../../../../reducer/RecTestStepReducer";

import { APPLICATION_STRING_INFO_MESSAGE, DEVICE_STRING_INFO_MESSAGE } from "../../../../../Constants";
import { CLOSE_ICON_RED, DISABLE_COLOR, SAVE_ICON, WHITE_COLOR } from "../../../../../Constants/SvgConstants";
import SvgLoader from "../../../../../Util/SvgLoader";
import VerificationBuilderV2 from "./VerificationBuilderV2";
import VerificationGroupV2 from "./VerificationGroupV2";
import VerifyElementPropertyV2 from "./VerifyElementPropertyV2";
import VerifyElementTextV2 from "./VerifyElementTextV2";
import styles from "./VerificationsV2.module.scss";

const verificationRowComponent = {
  VerifyElementProperty: VerifyElementPropertyV2,
  VerifyElementText: VerifyElementTextV2,
  GROUP: VerificationGroupV2,
};

const VerificationComponent = (
  operator,
  disabled,
  props,
  containsVerification,
  recStepVerifyElementProperties,
  isPreviewRunning,
  visibleIdentificationRuleDrawer,
  visibleIdentificationRulesDrawer,
  dispatch,
  data,
  unSavedRecStepTag,
  tagForOpenRulesDrawer
) => {
  const { recTestStepList, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const { unSavedRecElementGuid } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
  const unSavedRecElement = getRecElement(unSavedRecTestStep?.recTestStepProperty?.recElements, unSavedRecElementGuid);

  const disableSaveButton = disableSaveButtonStepForIdentificationRulesDrawer(unSavedRecElement);
  const resetDisableButton = disableResetButtonForIdentificationRulesDrawer(unSavedRecElement);

  //application or device string info message
  const checkStringKeys = useCallback((properties, key1, key2) => {
    return properties?.filter((item) => item.key === key1 || item.key === key2);
  }, []);

  const applicationStringKeyToCheck = useMemo(() => {
    return checkStringKeys(
      unSavedRecElement?.recStepVerifyElementProperties,
      "Equals to Locale value of App String",
      "Contains Locale value of App String"
    );
  }, [unSavedRecElement?.recStepVerifyElementProperties, checkStringKeys]);

  const deviceStringKeyToCheck = useMemo(() => {
    return checkStringKeys(
      unSavedRecElement?.recStepVerifyElementProperties,
      "Equals to Locale value of Device String",
      "Contains Locale value of Device String"
    );
  }, [unSavedRecElement?.recStepVerifyElementProperties, checkStringKeys]);

  const applicationStringsKey = useMemo(() => {
    return unSavedRecElement?.applicationStrings && applicationStringKeyToCheck?.length === 0;
  }, [unSavedRecElement?.applicationStrings, applicationStringKeyToCheck]);

  const deviceStringsKey = useMemo(() => {
    return unSavedRecElement?.deviceStrings && deviceStringKeyToCheck?.length === 0;
  }, [unSavedRecElement?.deviceStrings, deviceStringKeyToCheck]);

  return (
    <Drawer
      className={`${tagForOpenRulesDrawer !== data?.tag && styles.hideRulesDrawer} ${"identification-Rules-Drawer"}`}
      visible={visibleIdentificationRuleDrawer}
      title={<span className={styles["drawer-title"]}>Identification Rules</span>}
      onClose={() => dispatch(visibleIdentificationRulesDrawer(false))}
      width={600}
      closable={false}
      extra={
        <SvgLoader iconName={CLOSE_ICON_RED} handleClick={() => dispatch(visibleIdentificationRulesDrawer(false))} />
      }
      footer={
        props.recElement?.id ? (
          <div className={styles["drawer-Footer"]}>
            <Button
              type="primary"
              className={styles["save-button"]}
              disabled={disableSaveButton}
              onClick={() => {
                !disableSaveButton && dispatch(saveRecTestStepActionWithCurrentDeviceImage(data?.tag, undefined));
                dispatch(visibleIdentificationRulesDrawer(false));
              }}
            >
              <div className={styles["save-Icon-And-Label"]}>
                <SvgLoader iconName={SAVE_ICON} iconColor={disableSaveButton ? DISABLE_COLOR : WHITE_COLOR} />
                <span>Save</span>
              </div>
            </Button>
            <Button
              type="primary"
              className={styles["reset-Button"]}
              disabled={resetDisableButton}
              onClick={() => {
                !resetDisableButton && dispatch(resetRecTestStepAction(data?.tag));
              }}
            >
              Reset
            </Button>
          </div>
        ) : (
          ""
        )
      }
    >
      <>
        {(applicationStringsKey || deviceStringsKey) && (
          <div className={styles["infoMessage"]}>
            {applicationStringsKey && APPLICATION_STRING_INFO_MESSAGE}
            {deviceStringsKey && DEVICE_STRING_INFO_MESSAGE}
          </div>
        )}
        {operator !== undefined && (
          <div>
            <VerificationBuilderV2
              disabled={disabled}
              operator={operator}
              uuid={undefined}
              isFirstRow={true}
              recTestStep={props.data}
              recElement={props.recElement}
            />
          </div>
        )}
        {!containsVerification && (
          <div className={styles["addVerification"]}>
            After an Element is located, these Rules will filter Element based on Element Text or Element Properties.
          </div>
        )}
        <div className={styles["verification_items"]}>
          {!isEmpty(recStepVerifyElementProperties) &&
            recStepVerifyElementProperties?.map((verification, key) => {
              let Item = verificationRowComponent[verification.type];
              return (
                <React.Fragment key={key}>
                  {Item && (
                    <Item
                      key={key}
                      data={verification}
                      recTestStep={props.data}
                      disabled={disabled || isPreviewRunning}
                      recElementVerification={props.recElement}
                      recTestSteps={props.recTestSteps}
                      projectId={props.projectId}
                    />
                  )}
                  {recStepVerifyElementProperties.length > 1 && recStepVerifyElementProperties.length - 1 !== key && (
                    <div className={styles["verification-Operator"]}>
                      <div key={"OPERATOR"} className={styles["verification_operator_label"]}>
                        {operator}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              );
            })}
        </div>
      </>
    </Drawer>
  );
};

const VerificationRulesDrawerV2 = (props) => {
  const { disabled, containsVerification, operator, recStepVerifyElementProperties } = props;
  const { isPreviewRunning } = useSelector((state) => state.RecTestStepReducer);
  const { visibleIdentificationRuleDrawer, unSavedRecStepTag, tagForOpenRulesDrawer } = useSelector(
    (state) => state.RecTestStepCommonViewReducer
  );
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      {VerificationComponent(
        operator,
        disabled,
        props,
        containsVerification,
        recStepVerifyElementProperties,
        isPreviewRunning,
        visibleIdentificationRuleDrawer,
        visibleIdentificationRulesDrawer,
        dispatch,
        props.data,
        unSavedRecStepTag,
        tagForOpenRulesDrawer
      )}
    </React.Fragment>
  );
};

export default VerificationRulesDrawerV2;
