import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ELEMENT_ACTION } from "../../../../../Constants";
import { getParentRefElementLocator } from "../../../../../Util";
import { changeTapByIndex } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import styles from "./TapByIndex.module.scss";

export default (props) => {
  const { isForView, data, isInputDisabled } = props;
  const { action, uniqueByIndex } = data.recTestStepProperty;
  const { selectedElementsDetailList } = useSelector((state) => state.RecordingReducer);
  const dispatch = useDispatch();
  let parentRefElementDetails = getParentRefElementLocator(selectedElementsDetailList);
  return (
    <>
      <div className={styles.tapByIndex}>
        <div>Index: </div>
        <Select
          className={styles.selectIndex}
          required={action === ELEMENT_ACTION.TAP_BY_INDEX}
          disabled={isForView || isInputDisabled}
          value={uniqueByIndex}
          onChange={(value) => {
            dispatch(changeTapByIndex(value - 1, ELEMENT_ACTION.TAP_BY_INDEX, data.tag));
          }}
        >
          {parentRefElementDetails &&
            parentRefElementDetails.length > 0 &&
            parentRefElementDetails[0].mobileElements.map((v, k) => {
              return (
                <Select.Option key={k} value={k + 1}>
                  {k + 1}
                </Select.Option>
              );
            })}
        </Select>
      </div>
    </>
  );
};
