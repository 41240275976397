import { Checkbox, Col, Collapse, Row } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { STEP, TEST_SCENARIOS } from "../../../../../Constants";
import {
  CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL,
  OPEN_CHILD_COLLAPSE_ICON_IN_MODAL,
  OPEN_COLLAPSE_ICON_FOR_SUB_STEP,
  OPEN_COLLAPSE_ICON_IN_MODAL,
} from "../../../../../Constants/SvgConstants";
import { blockCollapseAction, getStepActionIcon, isArrayNotUndefinedNotNullNotEmpty } from "../../../../../Util";
import SvgLoader from "../../../../../Util/SvgLoader";
import { handleSelectRecTestStepOrAction } from "../../../../../actions/RecTestStep/RecTestStepRedirectAction";
import EmptyRecords from "../../../../CommonComponents/EmptyRecords/EmptyRecords";
import styles from "./ImportTestStepActionsModal.module.scss";

const { Panel } = Collapse;

const ImportTestStepActionsModal = (props) => {
  const [activeCollapse, setActiveCollapse] = useState(false);
  const dispatch = useDispatch();
  const { importRecTestScenarioStepList, listOfBlocksByProjectId } = useSelector((state) => state.RecTestStepReducer);
  const { selectedRecTestStepOrActionForImport, importRecTestScenarioStepActionList, selectChildStep, actionStep } =
    useSelector((state) => state.RecTestStepCommonViewReducer);
  const {
    recTestStep,
    index,
    projectId,
    stepType,
    currentActionTab,
    name,
    disableChildStep,
    actionName,
    parentRecTestStep,
  } = props;

  const Steps = () => {
    return (
      <div key={index}>
        {isArrayNotUndefinedNotNullNotEmpty(recTestStep?.recTestSteps) ? (
          recTestStep?.recTestSteps.map((childRecTestStep, key) => {
            return (
              <ImportTestStepActionsModal
                key={key}
                index={key}
                parentRecTestStep={recTestStep}
                recTestStep={childRecTestStep}
                parentIndex={recTestStep.orderId}
                projectId={projectId}
                stepType={stepType}
                recTestStepList={recTestStep}
                currentActionTab={currentActionTab}
                actionName={childRecTestStep?.action}
                name={childRecTestStep.stepName ? childRecTestStep.stepName : childRecTestStep.name}
                disableChildStep={
                  (stepType === TEST_SCENARIOS && currentActionTab === "IMPORT_FROM_TEST_BLOCK_STEPS") ||
                  (stepType === TEST_SCENARIOS && childRecTestStep.isTestBlock === 1)
                }
                disableRecTestStepActionsForImport={props.disableRecTestStepActionsForImport}
                disableRecTestStepForImport={props.disableRecTestStepForImport}
              />
            );
          })
        ) : (
          <EmptyRecords description="No Data Found" ignoreMargin={true} />
        )}
      </div>
    );
  };
  const selectedSteps = selectedRecTestStepOrActionForImport?.includes(
    recTestStep?.recTestScenarioRecTestStepAssocId ? recTestStep?.recTestScenarioRecTestStepAssocId : recTestStep?.id
  );

  return (
    <>
      <Col span={24} className={styles.importModalDetails}>
        <Collapse
          key={recTestStep?.id}
          expandIconPosition="start"
          expandIcon={(e) => {
            setActiveCollapse(e.isActive ? true : false);
            if (parentRecTestStep) {
              return (
                <div className={styles.expandIcon}>
                  <SvgLoader
                    iconName={e.isActive ? OPEN_CHILD_COLLAPSE_ICON_IN_MODAL : CLOSE_CHILD_COLLAPSE_ICON_IN_MODAL}
                    width={"0.81rem"}
                    height={"0.62rem"}
                  />
                </div>
              );
            } else {
              return (
                <SvgLoader
                  iconName={e.isActive ? OPEN_COLLAPSE_ICON_IN_MODAL : OPEN_COLLAPSE_ICON_FOR_SUB_STEP}
                  width={"1.31rem"}
                  height={"1.7rem"}
                />
              );
            }
          }}
          className={`${(recTestStep.action === STEP || recTestStep.action === "scenario") && styles.collapse} ${
            parentRecTestStep && "collapseOfChildSteps"
          }`}
        >
          {recTestStep.action === STEP || recTestStep.action === "scenario" ? (
            <Panel
              key={index}
              showArrow={true}
              collapsible={!(stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1)}
              header={
                <Row
                  className={`${parentRecTestStep && styles.padding} ${
                    selectedSteps && parentRecTestStep && styles.selectedStep
                  } ${activeCollapse && parentRecTestStep && styles.activeCollapse}`}
                >
                  <Col span={21}>
                    <div className={`${styles.stepName} ${!parentRecTestStep && styles.parentStepName}`}>
                      <Checkbox
                        checked={selectedRecTestStepOrActionForImport?.includes(
                          recTestStep?.recTestScenarioRecTestStepAssocId
                            ? recTestStep?.recTestScenarioRecTestStepAssocId
                            : recTestStep?.id
                        )}
                        disabled={
                          disableChildStep ||
                          !actionStep ||
                          (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1)
                        }
                        onClick={(e) => blockCollapseAction(e)}
                        onChange={() =>
                          dispatch(
                            handleSelectRecTestStepOrAction(
                              recTestStep?.recTestScenarioRecTestStepAssocId
                                ? recTestStep?.recTestScenarioRecTestStepAssocId
                                : recTestStep?.id,
                              recTestStep,
                              recTestStep.recTestSteps,
                              currentActionTab === "IMPORT_FROM_SCENARIO" && importRecTestScenarioStepList,
                              currentActionTab,
                              projectId,
                              currentActionTab === "IMPORT_FROM_SCENARIO"
                                ? importRecTestScenarioStepActionList
                                : listOfBlocksByProjectId.data
                            )
                          )
                        }
                      />
                      <div className={styles.recTestStepName}>{name}</div>
                    </div>
                  </Col>
                </Row>
              }
            >
              <div>{Steps()}</div>
            </Panel>
          ) : (
            <>
              <div className={styles.recTestStepActionsDetails}>
                <Checkbox
                  checked={selectedRecTestStepOrActionForImport?.includes(
                    recTestStep?.recTestScenarioRecTestStepAssocId
                      ? recTestStep?.recTestScenarioRecTestStepAssocId
                      : recTestStep?.id
                  )}
                  disabled={
                    (actionStep === undefined && props.disableRecTestStepActionsForImport) ||
                    (stepType === TEST_SCENARIOS && currentActionTab === "IMPORT_FROM_TEST_BLOCK_STEPS") ||
                    (stepType === TEST_SCENARIOS && recTestStep.isTestBlock === 1) ||
                    (actionStep && !_.isEmpty(selectChildStep)) ||
                    (actionStep && selectChildStep === undefined)
                  }
                  onClick={(e) => blockCollapseAction(e)}
                  onChange={() =>
                    dispatch(
                      handleSelectRecTestStepOrAction(
                        recTestStep?.recTestScenarioRecTestStepAssocId
                          ? recTestStep?.recTestScenarioRecTestStepAssocId
                          : recTestStep?.id,
                        recTestStep,
                        recTestStep.recTestSteps,
                        currentActionTab === "IMPORT_FROM_SCENARIO" && importRecTestScenarioStepList,
                        currentActionTab,
                        projectId,
                        currentActionTab === "IMPORT_FROM_SCENARIO"
                          ? importRecTestScenarioStepActionList
                          : listOfBlocksByProjectId.data
                      )
                    )
                  }
                />
                <div className={styles.actionIconOrName}>
                  <SvgLoader iconName={getStepActionIcon(actionName)} width="1.38rem" height="1.75rem" />
                  <div className={styles.recTestActionName}>{name}</div>
                </div>
              </div>
            </>
          )}
        </Collapse>
      </Col>
    </>
  );
};

export default ImportTestStepActionsModal;
