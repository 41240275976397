import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Checkbox, Row, Spin, Tabs, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { acceptInvitation } from "../../actions/CommonActions";
import { deleteInvitationRequest, getInvitationList, resendInvitationRequest } from "../../actions/InvitationAction";
import { DEFAULT_PAGE, GET_INVITATION_LOADING, RECEIVED, SENT, TOOLTIP_BG_COLOR } from "../../Constants";
import { ACCEPT_INVITATION_ICON, LOGO, RESEND_INVITATION_ICON, TRASH_ICON } from "../../Constants/SvgConstants";
import history from "../../history";
import { isSelectedAllIds, removeSelectedIds } from "../../Util";
import SvgLoader from "../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import SelectAllBoxV2 from "../CommonComponents/SelectAllBoxV2/SelectAllBoxV2";
import VerticalLine from "../CommonComponents/VerticalLine";
import BackButton from "../CoreComponents/BackButtonComponent/BackButton";
import PaginationComponentV2 from "../CoreComponents/PaginationComponent/PaginationComponentV2";
import TableComponentV2 from "../CoreComponents/TableComponent/TableComponentV2";
import styles from "./InvitationListV2.module.scss";

const InvitationListV2 = (props) => {
  const dispatch = useDispatch();
  const { resendInvitation, deleteInvitation, invitationList, acceptInvitationList } = useSelector(
    (state) => state.InvitationReducer
  );
  const { inviteMembers, invitationLoader } = useSelector((state) => state.ProjectsReducer);
  const orgId = props.match.params?.organizationId;

  const [selectAllInvitation, setSelectAllInvitation] = useState([]);
  const [selectedTab, setSelectedTab] = useState(RECEIVED);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE);

  useEffect(() => {
    dispatch(getInvitationList(orgId, selectedTab, currentPage));
  }, [
    resendInvitation,
    deleteInvitation,
    inviteMembers,
    acceptInvitationList,
    dispatch,
    orgId,
    selectedTab,
    currentPage,
  ]);

  const handleTabChange = (key) => {
    setSelectedTab(key);
    setCurrentPage(DEFAULT_PAGE);
    dispatch({ type: GET_INVITATION_LOADING, response: { isLoading: true } });
  };

  const deleteRequest = (id) => {
    setOpenConfirmationModal(id);
  };

  const resendRequest = (id) => {
    dispatch(resendInvitationRequest({ id }));
  };

  const acceptRequest = (token) => {
    dispatch(acceptInvitation(token));
  };

  const requestActions = (id, token) => (
    <div className="display-flex justify-center">
      <Tooltip color={TOOLTIP_BG_COLOR} title="Delete Request">
        <div className={styles["invitation__Buttons"]}>
          <SvgLoader
            iconName={TRASH_ICON}
            handleClick={(e) => {
              deleteRequest(id);
              e.stopPropagation();
            }}
          />
        </div>
      </Tooltip>
      {selectedTab === SENT ? (
        <>
          <Tooltip color={TOOLTIP_BG_COLOR} title="Resend Request">
            <div className={styles["invitation__Buttons"]}>
              <SvgLoader
                iconName={RESEND_INVITATION_ICON}
                handleClick={(e) => {
                  resendRequest(id);
                  e.stopPropagation();
                }}
              />
            </div>
          </Tooltip>
        </>
      ) : (
        <Tooltip color={TOOLTIP_BG_COLOR} title="Accept Request">
          <div className={styles["invitation__Buttons"]}>
            <SvgLoader
              iconName={ACCEPT_INVITATION_ICON}
              handleClick={(e) => {
                acceptRequest(token);
                e.stopPropagation();
              }}
            />
          </div>
        </Tooltip>
      )}
    </div>
  );

  const invitationListSelectionCheckboxType = (row) => (
    <Checkbox
      className="selection-checkBox mr-26"
      checked={selectAllInvitation.includes(row?.id)}
      onChange={(e) => {
        setSelectAllInvitation((prev) => (e.target.checked ? [...prev, row.id] : prev.filter((i) => i !== row?.id)));
      }}
    />
  );

  const deleteConfirm = () => {
    setOpenConfirmationModal(selectAllInvitation);
  };

  const columns = [
    {
      title: (
        <div>
          {!isEmpty(invitationList?.data) && (
            <span className={styles["header-checkBox"]}>
              <Checkbox
                className="selection-checkBox mr-26"
                checked={invitationList?.data?.every((i) => selectAllInvitation.includes(i?.id))}
                onChange={(e) => {
                  setSelectAllInvitation((prev) =>
                    e.target.checked
                      ? [...prev, ...invitationList?.data?.filter((i) => !prev.includes(i?.id))?.map((i) => i.id)]
                      : removeSelectedIds(prev, invitationList?.data)
                  );
                }}
              />
            </span>
          )}
          {selectedTab === RECEIVED ? "Invited By" : "Invited User"}
        </div>
      ),
      dataIndex: "invitedUser",
      width: 500,
    },
    { title: "Team", dataIndex: "team" },
    { title: "Actions", dataIndex: "actions", align: "center" },
  ];

  const listOfInvitationMembers = !isEmpty(invitationList?.data)
    ? invitationList?.data.map((row) => ({
        key: row?.id,
        invitedUser: (
          <div className="display-flex text">
            {invitationListSelectionCheckboxType(row)}
            <div>
              <div className={styles["email_Text"]}>{selectedTab === SENT ? row?.inviteeEmail : row?.requestedBy}</div>
            </div>
          </div>
        ),
        team: <div className={styles["team_Name"]}>{row.projectName || row.teamName}</div>,
        actions: requestActions(row.id, row.token),
      }))
    : null;

  const renderTabPane = (tabKey, header) => (
    <Tabs.TabPane tab={header} key={tabKey}>
      <Spin spinning={invitationLoader} tip="Loading" className={styles["spinner_Center"]}>
        <div className="table_Details_scroll">
          <div className="table_Details__wrapper">
            {!isEmpty(selectAllInvitation) && (
              <div className={styles["select--all-box"]}>
                <SelectAllBoxV2
                  isChecked={isSelectedAllIds(selectAllInvitation, invitationList?.data)}
                  selectAllIds={selectAllInvitation}
                  handleChangeCheck={(isChecked) => {
                    setSelectAllInvitation((prev) =>
                      isChecked
                        ? [...prev, ...invitationList?.data?.filter((i) => !prev.includes(i?.id))?.map((i) => i.id)]
                        : removeSelectedIds(prev, invitationList?.data)
                    );
                  }}
                  deleteConfirm={deleteConfirm}
                  isDisable={isEmpty(invitationList?.data)}
                />
              </div>
            )}
            <TableComponentV2
              columns={columns}
              data={listOfInvitationMembers}
              selected={selectAllInvitation}
              className={`table__wrapper ${!isEmpty(selectAllInvitation) && " hide_column_name"}`}
            />
            <div className="pagination_Section">
              {!isEmpty(invitationList?.data) && (
                <PaginationComponentV2
                  currentPageItems={invitationList?.pageItems}
                  totalItems={invitationList?.totalItems}
                  totalPage={invitationList?.totalCount}
                  currentPage={invitationList?.currentPage}
                  callPaginationAPI={(pageNumber) => {
                    setCurrentPage(pageNumber);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </Spin>
    </Tabs.TabPane>
  );

  return (
    <div className="deviceTab">
      <Row justify="space-between" align="middle" className={styles["header"]}>
        <div className="display__flex align_Items__Center">
          <Link to="/">
            <a>
              <SvgLoader iconName={LOGO} width="2.85rem" height="2.90rem" />
            </a>
          </Link>
          <VerticalLine className={styles["border_Right"]} />
          <span className={styles["header_Title"]}>Pending Invitations</span>
        </div>
        <div className="display__flex align_Items__Center">
          <div className={styles["back_Icon"]}>
            <BackButton handleBack={() => history.push(`/${orgId}/projects`)} />
          </div>
        </div>
      </Row>
      <Tabs defaultActiveKey={RECEIVED} activeKey={selectedTab} className="mt-80" onChange={handleTabChange}>
        {renderTabPane(RECEIVED, "Invitations")}
        {renderTabPane(SENT, "Invited By Me")}
      </Tabs>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(
            deleteInvitationRequest(
              {
                ids: !isEmpty(selectAllInvitation) ? selectAllInvitation : [openConfirmationModal],
              },
              setSelectAllInvitation,
              orgId,
              selectedTab
            )
          );
          setOpenConfirmationModal(false);
        }}
        handleCancel={() => setOpenConfirmationModal(false)}
        modalOpen={!!openConfirmationModal}
        modalContent={`Are you sure you want to delete ${
          selectAllInvitation.length > 1 ? "these members?" : "this member?"
        } This action cannot be undone.`}
        modalTitle="Confirm Deletion"
        okText="Delete"
        isDeleteModal
      />
    </div>
  );
};

export default InvitationListV2;
