import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import HighlighterRect from "./HighlighterRect";
import { ANDROID, DEVICE_INTERACTION_MODE } from "../../../Constants";
/**
 * Shows screenshot of running application and divs that highlight the elements' bounding boxes
 */

export default (props) => {
  const [scaleRatio, setScaleRatio] = useState(1);
  const { INTERACTIVE: TAP, SWIPE } = DEVICE_INTERACTION_MODE;
  const { deviceDetail, deviceInteractionMode, currentDeviceScreenSource, selectedElement } = useSelector(
    (state) => state.RecordingReducer
  );
  const { searchedForElementBounds } = useSelector((state) => state.RecTestStepReducer);
  const dispatch = useDispatch();

  const updateScaleRatio = () => {
    const screenshotEl = props.containerEl.querySelector("img");
    if (screenshotEl) {
      let width = undefined;
      if (deviceDetail) {
        if (deviceDetail.screenSize) {
          width = deviceDetail.screenSize.split("x")[0];
        } else {
          if (deviceDetail.screenSize) {
            width = deviceDetail.screenSize.value.width;
          }
        }

        setScaleRatio({
          scaleRatio: width ? width / screenshotEl.offsetWidth : 1,
        });
      }
    }
  };

  //DidMount
  useEffect(() => {
    updateScaleRatio();
    window.addEventListener("resize", updateScaleRatio);
  }, []);

  //unmount
  useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateScaleRatio);
    };
  }, []);

  let isAndroid = deviceDetail["deviceType"] === ANDROID;
  let isIOS = deviceDetail["deviceType"] === "IOS";

  const highlighterRects = [];
  let highlighterXOffset = 0;
  const screenshotEl = props.containerEl.querySelector("img");
  if (screenshotEl) {
    highlighterXOffset = screenshotEl.getBoundingClientRect().left - props.containerEl.getBoundingClientRect().left;
  }
  let recursive = (element, zIndex = 0) => {
    if (!element) {
      return;
    }

    highlighterRects.push(
      <HighlighterRect
        {...props}
        x={props.x}
        y={props.y}
        element={element}
        zIndex={zIndex}
        scaleRatio={scaleRatio}
        key={element.path}
        xOffset={highlighterXOffset}
        serverId={props.serverId}
        targetUniqueId={props.targetUniqueId}
        screenSizeRatio={props.screenSizeRatio}
        scaleRatioX={props.scaleRatioX}
        scaleRatioY={props.scaleRatioY}
        isIOS={isIOS}
        isAndroid={isAndroid}
        screenshotEl={screenshotEl}
      />
    );
    for (let childEl of element.children) {
      recursive(childEl, zIndex + 1);
    }
  };

  // If the use selected an element that they searched for, highlight that element
  if (searchedForElementBounds) {
    highlighterRects.push(
      <HighlighterRect
        x={props.x}
        y={props.y}
        element={selectedElement}
        serverId={props.serverId}
        targetUniqueId={props.targetUniqueId}
        key={searchedForElementBounds.elLocation}
        elSize={searchedForElementBounds.size}
        elLocation={searchedForElementBounds.elLocation}
        scaleRatio={scaleRatio}
        xOffset={highlighterXOffset}
        imgWidth={props.imgWidth}
        imgHeight={props.imgHeight}
        scaleRatioX={props.scaleRatioX}
        scaleRatioY={props.scaleRatioY}
        isIOS={isIOS}
        isAndroid={isAndroid}
        screenshotEl
      />
    );
  }

  // If we're tapping or swiping, show the 'crosshair' cursor style
  const screenshotStyle = {};
  if ([TAP, SWIPE].includes(deviceInteractionMode)) {
    screenshotStyle.cursor = "crosshair";
  }

  // Don't show highlighter rects when Search Elements modal is open
  // if (!this.props.state.inspect.isLocatorTestModalVisible || !this.props.state.inspect.isCustomSelectedElementModalVisible) {
  recursive(currentDeviceScreenSource);

  return <div>{highlighterRects}</div>;
};
