import React, { useEffect, useState } from "react";
import { Button, Modal, Spin } from "antd";
import _ from "lodash";
import ErrorInvestigationFields from "./ErrorInvestigationDetails/ErrorInvestigationFields";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchErrorInvestigationDetails,
  fetchOtherRuns,
  saveErrorInvestigationDetails,
} from "../../actions/ErrorInvestigationActions";
import { CHANGE_ERROR_INVESTIGATION_DATA, ERROR_TYPE, OTHER_RUNS_OPTION } from "../../Constants";
import {
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isTrim,
  showNotification,
  validURL,
} from "../../Util";
import { BuildErrorInvestigationStateModal } from "./BuildErrorInvestigationStateModal";
import SameInvestigationAndOtherRunsFailure from "./ErrorInvestigationDetails/SameInvestigationAndOtherRunsFailure";
import NewAndPreviousInvestigationManager from "./ErrorInvestigationDetails/NewAndPreviousInvestigationManager";

const ErrorInvestigationModal = (props) => {
  const dispatch = useDispatch();
  const { setOpenErrorInvestigationModal, openErrorInvestigationModal, failedTestStepId, projectId, orgId, runNumber } =
    props;
  const { previous_ErrorInvestigationDetails, errorInvestigationDetails, errorInvLoading } = useSelector(
    (state) => state.ErrorInvestigationReducer
  );
  const [searchOption, setSearchOption] = useState(OTHER_RUNS_OPTION.ALL);
  const [currentSelectedId, setCurrentSelectedId] = useState([]);

  useEffect(() => {
    dispatch({
      type: CHANGE_ERROR_INVESTIGATION_DATA,
      data: new BuildErrorInvestigationStateModal(),
    });
    if (isNotNullAndNotEmptyAndNotUndefined(failedTestStepId)) {
      dispatch(fetchErrorInvestigationDetails(failedTestStepId));
      dispatch(fetchOtherRuns(failedTestStepId, 1, searchOption));
    }
  }, [failedTestStepId]);


  const saveErrorInvestigationData = () => {
    if (errorInvestigationDetails?.failureReason === "Product Bug" && !validURL(errorInvestigationDetails?.bugUrl)) {
      showNotification(ERROR_TYPE, "Please Enter Valid URL.");
    } else {
      dispatch(saveErrorInvestigationDetails(projectId, failedTestStepId, setOpenErrorInvestigationModal, runNumber));
    }
  };

  const isProductBugAndURL = () =>
    (!errorInvestigationDetails?.previousTestStepId &&
      errorInvestigationDetails?.failureReason === "Product Bug" &&
      (isNullOrUndefinedOrEmpty(errorInvestigationDetails?.bugUrl) || isTrim(errorInvestigationDetails?.bugUrl))) ||
    (!errorInvestigationDetails?.previousTestStepId &&
      isNullOrUndefinedOrEmpty(errorInvestigationDetails?.failureReason));

  return (
    <>
      <Modal
        width={"60%"}
        bodyStyle={{ maxHeight: 700 }}
        className="modal_style"
        title={
          <div>
            Error Investigation For <span className="font-weight-400">{errorInvestigationDetails?.runNumber}</span>
          </div>
        }
        visible={openErrorInvestigationModal}
        onCancel={() => setOpenErrorInvestigationModal(false)}
        footer={[
          <Button
            key="reset"
            type="primary"
            disabled={_.isEqual(
              JSON.stringify(errorInvestigationDetails),
              JSON.stringify(previous_ErrorInvestigationDetails)
            )}
            onClick={() => {
              dispatch({
                type: CHANGE_ERROR_INVESTIGATION_DATA,
                data: previous_ErrorInvestigationDetails,
              });
            }}
          >
            Reset
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={saveErrorInvestigationData}
            disabled={
              _.isEqual(
                JSON.stringify(errorInvestigationDetails),
                JSON.stringify(previous_ErrorInvestigationDetails)
              ) || isProductBugAndURL()
            }
          >
            Apply
          </Button>,
        ]}
      >
        <Spin spinning={errorInvLoading} tip="Loading">
          <ErrorInvestigationFields />
          <NewAndPreviousInvestigationManager
            projectId={projectId}
            orgId={orgId}
            failedTestStepId={failedTestStepId}
            searchOption={searchOption}
          />
          <SameInvestigationAndOtherRunsFailure
            failedTestStepId={failedTestStepId}
            orgId={orgId}
            projectId={projectId}
            searchOption={searchOption}
            setSearchOption={setSearchOption}
            currentSelectedId={currentSelectedId}
            setCurrentSelectedId={setCurrentSelectedId}
          />
        </Spin>
      </Modal>
    </>
  );
};

export default ErrorInvestigationModal;
