import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { joinOrganization } from "../../../actions/OrganizationAction";
import { showNotification } from "../../../Util";
import styles from "./JoinOrganizationV2.module.scss";

const JoinOrganizationV2 = () => {
  const dispatch = useDispatch();
  let { getOrgStepData } = useSelector((state) => state.OrganizationReducer);

  useEffect(() => {
    if (getOrgStepData && getOrgStepData.isJoinedOrg === 1) {
      window.open(`/${getOrgStepData.orgId}/projects/`, "_self");
    }
  }, [getOrgStepData]);

  const handleSaveButton = () => {
    let joinObj = {
      orgId: getOrgStepData && getOrgStepData.orgId,
      userId: localStorage.getItem("userID"),
    };
    if (joinObj) {
      dispatch(joinOrganization(joinObj));
    } else {
      showNotification("error", "Something Went Wrong", "");
    }
  };

  return (
    <React.Fragment>
      <div className={styles["join_Organization_Container"]}>
        <Row className={styles["join_Organization_Wrapper"]}>
          <Col className="width-full">
            <div className={styles["join_Org_Header"]}>Join Your Organization!</div>
            <div>
              <div className={styles["orgTest_Text"]}>{getOrgStepData && getOrgStepData.orgName}</div>
              <div>
                <Button
                  type="primary"
                  size="large"
                  block
                  onClick={() => handleSaveButton()}
                  className={styles["btn_Join"]}
                >
                  Join
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default JoinOrganizationV2;
