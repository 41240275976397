import styled from 'styled-components'
import { Card, Row } from 'antd';


export const StyledCard = styled(Card)`
  margin: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
  border: 2px solid #e8e8e8;
  height: auto;
  @media (min-width: 575px) {
     margin-left: 20px;
     margin-right: 20px;
  }
`;

export const StyledCol = styled(Row)`
  border-bottom: 1px solid #e8e8e8;
  padding: 10px 10px;
  font-size: 12px;
  width: 100%;
`;

export const Logo = styled.div`
`;

export const DeviceCardHeader = styled.div`
 display: flex;
 justify-content: space-between;
`;

export const StyledFont = styled.p`
 font-size: 10px;
 color: #8c8c8c;
`;

export const TotalCaseContainer = styled.div`
display:flex;
justify-content: space-between
`;

export const DeviceCardTitle = styled.span`
 color: black;
`;

export const TotalCaseNumber = styled.span`
 color: #2808d4;
`;

export default {
  StyledCard, StyledCol, Logo, StyledFont, DeviceCardTitle
}