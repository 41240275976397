import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Popover, Row, Spin, Switch, Tooltip } from "antd";
import { isEmpty } from "lodash";
import { getAllDevice, getOrgDevice, makeDeviceOffline, makeDeviceOnline } from "../../../actions/OrgDeviceAction";
import { getProjectsByOrgId } from "../../../actions/ProjectsAction";
import { getMyOrgUsers } from "../../../actions/TeamsAction";
import { DEFAULT_PAGE, DEVICE_DETAILS, IOS, RECORD_10_PER_PAGE, TOOLTIP_BG_COLOR } from "../../../Constants";
import {
  ANDROID_DEVICE_ICON,
  CREATE_ICON,
  ICON_EDIT_NEW,
  IOS_DEVICE_ICON,
  WHITE_COLOR,
} from "../../../Constants/SvgConstants";
import { getDeviceName } from "../../../Util";
import SvgLoader from "../../../Util/SvgLoader";
import CommonConfirmationModalV2 from "../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";
import CustomButtonV2 from "../../CommonComponents/CustomButtonV2/CustomButtonV2";
import VerticalLine from "../../CommonComponents/VerticalLine";
import PaginationComponentV2 from "../../CoreComponents/PaginationComponent/PaginationComponentV2";
import CommonSearchV2 from "../../CoreComponents/SearchComponent/CommonSearchV2";
import TableComponentV2 from "../../CoreComponents/TableComponent/TableComponentV2";
import HeaderComponent from "../../Header/Header";
import AddOrEditDeviceDetailsModalV2 from "../AddOrEditDeviceDetailsModalV2/AddOrEditDeviceDetailsModalV2";
import styles from "./DeviceDetailsListV2.module.scss";

const DeviceDetailsListV2 = (props) => {
  const orgId = props.organizationId ? props.organizationId : props.match.params && props.match.params.organizationId;
  const dispatch = useDispatch();
  const { getOrgDeviceList, currentOrgDevicePage, isLoading, totalDevices, totalOrgDevicePage } = useSelector(
    (state) => state.OrgDeviceReducer
  );
  const { getProjectListByOrgId } = useSelector((state) => state.ProjectsReducer);
  const { myOrgUsers } = useSelector((state) => state.TeamsReducer);

  const [deviceAssModal, setDeviceAssModal] = useState(false);
  const [editDeviceModal, setEditDeviceModal] = useState(false);
  const [editDataList, setEditDataList] = useState();
  const [searchKeyword, setSearchKeyword] = useState("");
  const [targetUniqueId, setTargetUniqueId] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(null);

  useEffect(() => {
    dispatch(
      getOrgDevice({
        orgId: orgId,
        pageNumber: currentOrgDevicePage,
        recordPerPage: RECORD_10_PER_PAGE,
        searchKeywords: searchKeyword,
      })
    );
    dispatch(getProjectsByOrgId(orgId));
    dispatch(getMyOrgUsers(orgId));
  }, []);

  const isDeviceEdit = (data) => {
    setEditDeviceModal(true);
    setEditDataList(data);
  };
  const modalClosed = () => {
    setEditDeviceModal(false);
    setDeviceAssModal(false);
  };

  const deviceActive = (orgDevice) => {
    setTargetUniqueId(orgDevice?.targetUniqueId);
    setOpenConfirmationModal(orgDevice);
  };

  const showSharedDetails = (title, items, itemKey) => {
    return (
      <Popover
        content={
          <Row>
            <div className={styles['project_And_User_List']}>
              {!isEmpty(items) &&
                items.map((item, index) => (
                  <div key={index}>
                    <Col span={24}>
                      <span>{index + 1} </span>
                      <span className={styles['project_Name']}>{!isEmpty(item[itemKey]) && item[itemKey]}</span>{" "}
                    </Col>
                  </div>
                ))}
            </div>
          </Row>
        }
        title={title}
      >
        <span className={styles["project_And_User_Count"]}> {items?.length}</span>
      </Popover>
    );
  };

  const columns = [
    {
      title: "Device Details",
      dataIndex: "deviceDetails",
      width: "50%",
    },
    {
      title: "Shared Details",
      dataIndex: "sharedDetails",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      align: "right",
    },
  ];

  const getDeviceList =
    !isEmpty(getOrgDeviceList) &&
    getOrgDeviceList.map((row, inx) => ({
      key: inx + 1,
      deviceDetails: (
        <div className={styles["brand_Logo_And_Name"]}>
          <div className={styles["device_logo"]}>
            <SvgLoader iconName={row.platformType.name === IOS ? IOS_DEVICE_ICON : ANDROID_DEVICE_ICON} defaultCursor />
          </div>
          <div className={styles.brandNameAndOsVer}>
            <div className={styles["label"]}>
              Device Name: <span>{getDeviceName(row)}</span>
            </div>
            <div className={styles["label"]}>
              OS Version: <span>{row.osVer}</span>
            </div>
          </div>
        </div>
      ),
      sharedDetails: (
        <div className={styles["label"]}>
          <div className="display__flex">
            Projects:{" "}
            {!isEmpty(row?.projects) ? (
              showSharedDetails("Shared with projects", row?.projects, "projectName")
            ) : (
              <span className={styles["project_And_User_Count"]}>0</span>
            )}
          </div>
          <div className="display__flex">
            Users:{" "}
            {!isEmpty(row?.users) ? (
              showSharedDetails("Shared with Users", row?.users, "email")
            ) : (
              <span className={styles["project_And_User_Count"]}>0</span>
            )}
          </div>
        </div>
      ),
      actions: (
        <div className={styles["action_Icon"]}>
          {targetUniqueId === row.targetUniqueId && <Spin className={styles["icon"]} spinning={isLoading}></Spin>}
          {row.isSharedDevice === 0 && !isEmpty(row.projects) && !isEmpty(row.users) && (
            <div className={styles["icon"]}>
              <ShareButton orgDevice={row} />
            </div>
          )}
          <div className={styles["icon"]}>
            <Tooltip color={TOOLTIP_BG_COLOR} title={row.isActive === 1 ? "Deactivate Device" : "Activate Device"}>
              <Switch
                size="small"
                disabled={targetUniqueId === row.targetUniqueId && isLoading}
                checked={row.isActive === 1 ? true : false}
                onChange={(e) => {
                  deviceActive(row);
                }}
                className="device_List_Toggle"
              />
            </Tooltip>
          </div>
          <div onClick={() => isDeviceEdit(row)}>
            <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
              <div className={styles["icon"]}>
                <SvgLoader
                  iconName={ICON_EDIT_NEW}
                  handleClick={() => {
                    isDeviceEdit(row);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        </div>
      ),
    }));

  return (
    <>
      <HeaderComponent showHumbergerMenu={true} buttonState={DEVICE_DETAILS} organizationId={orgId} />
      <Spin spinning={isLoading} tip="Loading" className="spinner-center">
        <div className={styles["main-container"]}>
          <div className={styles["main--header-text"]}> Device List </div>
          <div className={styles["search_And_Add_Device"]}>
            <div>
              <CommonSearchV2
                searchDetails={(searchKeyword) =>
                  dispatch(
                    getOrgDevice({
                      orgId: orgId,
                      pageNumber: DEFAULT_PAGE,
                      recordPerPage: RECORD_10_PER_PAGE,
                      searchKeywords: searchKeyword,
                    })
                  )
                }
                placeHolder="Search By Device Name"
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </div>
            <div className={styles["header--or-button"]}>
              <CustomButtonV2
                iconColor={WHITE_COLOR}
                buttonIcon={CREATE_ICON}
                buttonLabel={"Bulk Assign"}
                handleClick={() => {
                  setDeviceAssModal(true);
                  dispatch(getAllDevice({ orgId: orgId }));
                }}
                className="common_Green_Button"
              />
            </div>
          </div>
        </div>
        <VerticalLine className={styles["border-bottom"]} />
        <div className={styles["device_Details"]}>
          <TableComponentV2 data={getDeviceList} columns={columns} className="table__wrapper" />
          {!isEmpty(getOrgDeviceList) && (
            <Row>
              <div className="pagination_Section">
                <PaginationComponentV2
                  currentPage={currentOrgDevicePage}
                  totalItems={totalDevices}
                  totalPage={totalOrgDevicePage}
                  callPaginationAPI={(page) => {
                    page !== currentOrgDevicePage &&
                      dispatch(
                        getOrgDevice({
                          orgId: orgId,
                          pageNumber: page,
                          recordPerPage: RECORD_10_PER_PAGE,
                          searchKeywords: searchKeyword ? searchKeyword : "",
                        })
                      );
                  }}
                />
              </div>
            </Row>
          )}
        </div>
      </Spin>
      {/* add */}
      <AddOrEditDeviceDetailsModalV2
        modalStatus={deviceAssModal}
        modalClosed={modalClosed}
        orgId={orgId}
        myProjectsList={getProjectListByOrgId}
        myOrgUsers={myOrgUsers}
        searchKeyword={searchKeyword}
        mode="add"
      />

      {/* edit */}
      <AddOrEditDeviceDetailsModalV2
        modalStatus={editDeviceModal}
        modalClosed={modalClosed}
        orgId={orgId}
        myProjectsList={getProjectListByOrgId}
        myOrgUsers={myOrgUsers}
        searchKeyword={searchKeyword}
        editDataList={editDataList}
        mode="edit"
      />

      <CommonConfirmationModalV2
        handleOk={() => {
          openConfirmationModal?.isActive === 0
            ? dispatch(
                makeDeviceOnline(orgId, openConfirmationModal?.targetUniqueId, currentOrgDevicePage, RECORD_10_PER_PAGE)
              )
            : dispatch(
                makeDeviceOffline(
                  orgId,
                  openConfirmationModal?.targetUniqueId,
                  currentOrgDevicePage,
                  RECORD_10_PER_PAGE
                )
              );

          setOpenConfirmationModal(null);
        }}
        handleCancel={() => setOpenConfirmationModal(null)}
        modalOpen={!!openConfirmationModal}
        modalContent={`Are you sure you want to ${
          openConfirmationModal?.isActive === 1 ? "Deactivate Device" : "Active Device"
        } ?`}
        modalTitle={`Confirm ${openConfirmationModal?.isActive === 1 ? "Deactivate Device" : "Active Device"}`}
        okText={openConfirmationModal?.isActive === 1 ? "Deactivate Device" : "Active Device"}
        isAutoWidth={true}
      />
    </>
  );
};
const ShareButton = ({ orgDevice }) => {
  const [toolTipMessage, setToolTipMessage] = useState();
  useEffect(() => {
    if (orgDevice.isSharedDevice === 1 && !isEmpty(orgDevice.projects) && !isEmpty(orgDevice.users)) {
      setToolTipMessage("Shared with all users");
    } else if (orgDevice.isSharedDevice === 0 && !isEmpty(orgDevice.projects) && isEmpty(orgDevice.users)) {
      setToolTipMessage(`Shared with ${orgDevice.projects.length} project`);
    } else if (orgDevice.isSharedDevice === 0 && isEmpty(orgDevice.projects) && !isEmpty(orgDevice.users)) {
      setToolTipMessage(`Shared with ${orgDevice.users.length} users`);
    } else if (orgDevice.isSharedDevice === 0 && !isEmpty(orgDevice.projects) && !isEmpty(orgDevice.users)) {
      setToolTipMessage(`Shared with ${orgDevice.projects.length} project and ${orgDevice.users.length} users`);
    } else {
      setToolTipMessage("");
    }
  }, [orgDevice]);

  return (
    <div>
      <span className={styles['share_Button']}>
        <Tooltip color={TOOLTIP_BG_COLOR} placement="bottom" title={toolTipMessage}>
          <Button type="primary">Share</Button>
        </Tooltip>
      </span>
    </div>
  );
};

export default DeviceDetailsListV2;
