import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { addVerificationRow } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
const StyledAddVerificationGroup = styled.div`
  cursor: ${(props) => (props.disabled ? "not-allowed!important" : "pointer")};
  svg {
    cursor: ${(props) => (props.disabled ? "not-allowed!important" : "pointer")};
  }
`;
export default (props) => {
  const dispatch = useDispatch();
  return (
    <React.Fragment>
      <StyledAddVerificationGroup
        disabled={props.disabled}
        onClick={() =>
          !props.disabled
            ? dispatch(addVerificationRow("GROUP", props.uuid, props.recTestStep.tag, props.recElement?.guid))
            : undefined
        }
        className={"add-verification-group-icon"}>
        <FontAwesomeIcon icon={faPlusCircle} />
      </StyledAddVerificationGroup>
    </React.Fragment>
  );
};
