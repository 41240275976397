import { library } from "@fortawesome/fontawesome-svg-core";
import { faCogs, faPlay, faPlus, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, Spin, Tooltip } from "antd";
import { Checkbox } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { setPreviousSearchValue } from "../../actions/CommonActions";
import {
  ACTION_VIEW,
  ADD_BUILDS,
  APP_BUILD_UPLOAD_STATUS,
  CUSTOM,
  PRIVATE,
  PUBLIC,
  TOOLTIP_BG_COLOR,
  defaultDateTimeFormat,
  SHARED,
  OWNED,
  MY_BUILDS,
  SHARED_WITH_ME,
  All_RECORDS,
  ACTION_NOT_VIEW,
} from "../../Constants";
import {
  deleteModal,
  getPermissionType,
  getUniqueId,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isSelectedAllIds,
  removeSelectedIds,
  showBuildTags,
  showPermissionTypeIcon,
  displayUTCDateFormatWithToolTip,
  getAdmins,
} from "../../Util";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  changeAppBUildListPageNumber,
  changeAppManagerTab,
  deleteAppBuild,
  fetchAppBuildData,
  getAllBuildDetails,
  getAWSDeviceFarmProjects,
  getUploadBuildOption,
  saveBuildDetailsRights,
  showInstallBuildOnDevicesModal,
} from "../../actions/Recording";
import { changeTestJobData } from "../../actions/TestJobActions";
import SelectAllBox from "../CommonComponents/SelectAllBox";
import DeviceListInstallBuild from "./DeviceListInstallBuild";
import CommonSearch from "../CoreComponents/SearchComponent/CommonSearch";
import PaginationComponent from "../CoreComponents/PaginationComponent/PaginationComponent";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";
import { Label } from "../CoreComponents/StyledComponent/StyledComponents";
import { ICON_DELETE, ICON_DOWNLOAD, ICON_EDIT } from "../../CdnImagePath";
import { useRef } from "react";
import ActionComponent from "../CoreComponents/ActionComponent/ActionComponent";
import PermissionDrawer from "../CoreComponents/PermissionDrawer/PermissionDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PermissionFilter from "../PermissionFilter/PermissionFilter";

const { confirm } = Modal;

library.add(faTimesCircle);
library.add(faTrash);
library.add(faPlay);
library.add(faCogs);
library.add(faPlus);

const AppBuildList = (props) => {
  const { setBuildId, projectId, executionTab, orgId, testJobDetail, changeTestJobData, stepType, showDeviceView } =
    props;
  const dispatch = useDispatch();
  const {
    getUploadBuildOptions,
    allAppBuildDetails,
    getAWSProjectsList,
    loadingAppManagerBuild,
    pushBuildModalVisible,
  } = useSelector((state) => state.RecordingReducer);

  const [filter, setFilter] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectAllBuild, setSelectAllBuild] = useState([]);
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const [permissionBuildDetails, setPermissionBuildDetails] = useState(null);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [rights, setRights] = useState({ id: OWNED, value: MY_BUILDS });
  const [activeFilter, setActiveFilter] = useState(rights ? rights : { id: OWNED, value: MY_BUILDS });
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  const intervalRef = useRef(null);
  const filterPermission = [
    { id: OWNED, value: MY_BUILDS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];

  useEffect(() => {
    if (permissionBuildDetails?.buildDetailRights?.data) {
      setMembers([...permissionBuildDetails?.buildDetailRights?.data]);
    }
  }, [permissionBuildDetails]);

  useEffect(() => {
    if (executionTab) {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId, "DONE", "", stepType, executionTab ? 1 : 0));
      setFilter("DONE");
    } else {
      dispatch(getAllBuildDetails(false, searchKeyword, projectId, filter, rights?.id, stepType));
      setFilter("");
    }
    setSearchKeyword(null);
    dispatch(setPreviousSearchValue(searchKeyword));
    dispatch(getUploadBuildOption(projectId));
    setSelectAllBuild([]);
  }, [projectId]);

  useEffect(() => {
    if (!_.isEmpty(getUploadBuildOptions)) {
      dispatch(getAWSDeviceFarmProjects(projectId));
    }
  }, [getUploadBuildOptions]);

  useEffect(() => {
    if (
      allAppBuildDetails &&
      !_.isEmpty(allAppBuildDetails) &&
      !pushBuildModalVisible &&
      isNullOrUndefinedOrEmpty(searchKeyword)
    ) {
      intervalRef.current = setInterval(
        () =>
          dispatch(
            getAllBuildDetails(
              false,
              searchKeyword,
              projectId,
              executionTab ? "DONE" : "",
              executionTab ? "" : rights?.id,
              stepType,
              executionTab ? 1 : 0,
              true
            )
          ),
        4000
      );
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [allAppBuildDetails, pushBuildModalVisible]);

  useEffect(() => {
    if (!pushBuildModalVisible) {
      dispatch(
        getAllBuildDetails(
          false,
          searchKeyword,
          projectId,
          executionTab ? "DONE" : "",
          executionTab ? "" : rights?.id,
          stepType,
          executionTab ? 1 : 0,
          true
        )
      );
    }
  }, [pushBuildModalVisible]);

  const fetchAppBuildDataById = (buildId) => {
    const appBuild = allAppBuildDetails && allAppBuildDetails.data.find((build) => build.id === buildId);

    let project =
      isNotNullAndNotEmptyAndNotUndefined(appBuild?.awsDeviceProjectId) &&
      !_.isEmpty(getAWSProjectsList) &&
      getAWSProjectsList.find((i) => i.id === appBuild.awsDeviceProjectId);

    const appBuildDataByID = {
      buildId: appBuild.id,
      buildVersion: appBuild?.buildVersion,
      displayName: appBuild?.displayName,
      buildDate: moment(appBuild.buildDate),
      buildTime: moment(appBuild.buildDate),
      tag: appBuild?.buildTags,
      buildType: appBuild?.buildType,
      buildName: appBuild?.fileName,
      uploadToCloud: isNotNullAndNotEmptyAndNotUndefined(appBuild.cloudProvider) ? appBuild?.cloudProvider : null,
      awsDeviceFarmProject: project?.id,
      existingBuild: {
        uid: getUniqueId(),
        name: appBuild.fileName,
        url: appBuild.fileUrl,
      },
      isPushToCloud: appBuild?.isPushToCloud,
      appActivity: appBuild?.appActivity
    };
    dispatch(fetchAppBuildData(appBuildDataByID));
    setBuildId(buildId);
    dispatch(changeAppManagerTab(ADD_BUILDS));
  };

  const checkIsPendingOrInProgress =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.every((i) =>
      [APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING]?.includes(i?.uploadStatus)
    );

  const isNotIncludePendingAndInProgress =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.filter(
      (j) => ![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(j?.uploadStatus)
    );

  const selectedId = selectAllBuild && selectAllBuild?.map((i) => i?.id);

  const buildSelectionCheckboxType = (row) => {
    return (
      <span className="mr-10">
        {![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) &&
          row.allowDelete === ACTION_VIEW && (
            <Checkbox
              checked={selectAllBuild && selectAllBuild?.map((i) => i?.id)?.includes(row?.id)}
              onChange={(e) => {
                if (e.target.checked) {
                  testJobDetail &&
                    dispatch(
                      changeTestJobData({
                        ...testJobDetail,
                        buildDetail: { ...row },
                      })
                    );
                  setSelectAllBuild([
                    ...selectAllBuild,
                    {
                      id: row?.id,
                      folderPath: row?.folderPath,
                      fileKey: row?.fileKey,
                      awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                    },
                  ]);
                } else {
                  setSelectAllBuild(selectAllBuild && selectAllBuild?.filter((i) => i?.id !== row?.id));
                }
              }}
            />
          )}
      </span>
    );
  };

  const buildSelectionRadioType = (row) => {
    return (
      <span className="mr-10">
        {![APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) && (
          <input
            className="vertical-align-middle h-17 w-18 cursor-pointer"
            type="radio"
            name="radioGroup"
            value={row?.id}
            checked={
              (selectAllBuild && selectAllBuild?.map((i) => i?.id)?.includes(row?.id)) ||
              testJobDetail?.buildDetail?.id === row?.id
            }
            onChange={(e) => {
              if (e.target.checked) {
                testJobDetail &&
                  dispatch(
                    changeTestJobData({
                      ...testJobDetail,
                      buildDetail: { ...row },
                      isUseLatestBuild: 0
                    })
                  );
                setSelectAllBuild([
                  ...selectAllBuild,
                  {
                    id: row?.id,
                    folderPath: row?.folderPath,
                    fileKey: row?.fileKey,
                    awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                  },
                ]);
              } else {
                setSelectAllBuild(selectAllBuild && selectAllBuild?.filter((i) => i?.id !== row?.id));
              }
            }}
          />
        )}
      </span>
    );
  };

  const deleteConfirm = (name, selectedBuild) => {
    deleteModal(
      name
        ? `Are you sure you want to delete "${name}"?`
        : `Do you want to delete the selected ${selectAllBuild?.length > 1 ? "Builds" : "Build"}?`,
      () => {
        dispatch(deleteAppBuild(selectedBuild || selectAllBuild, setSelectAllBuild, rights?.id, searchKeyword));
      }
    );
  };

  const showCheckBoxInTableHeader =
    _.isEmpty(selectAllBuild) &&
    !executionTab &&
    !_.isEmpty(isNotIncludePendingAndInProgress) &&
    isNotIncludePendingAndInProgress?.some((i) => i?.allowDelete === ACTION_VIEW);

  const columns = [
    {
      title: (
        <div className={!showCheckBoxInTableHeader && "table_Details"}>
          {showCheckBoxInTableHeader && (
            <span>
              <Checkbox
                className="mr-10"
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllBuild([
                      ...selectAllBuild,
                      ...isNotIncludePendingAndInProgress
                        ?.filter((i) => i.allowDelete === ACTION_VIEW && !selectedId?.includes(i?.id) && i?.id)
                        ?.map(
                          (i) =>
                            i.allowDelete === ACTION_VIEW && {
                              id: i.id,
                              folderPath: i.folderPath,
                              fileKey: i.fileKey,
                              awsDeviceFarmProjectId: i.awsDeviceProjectId,
                            }
                        ),
                    ]);
                  } else {
                    setSelectAllBuild(removeSelectedIds(selectedId, isNotIncludePendingAndInProgress));
                  }
                }}
              />
            </span>
          )}
          Build Details
        </div>
      ),
      dataIndex: "buildDetails",
    },
    {
      title: "Tags",
      dataIndex: "tags",
    },
    {
      title: "Build History",
      dataIndex: "buildHistory",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const handleAction = (row) => {
    return [
      {
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.uploadedByEmail) ||
            (row?.isPrivate === 1 &&
              !_.isEmpty(row?.buildDetailRights?.data) &&
              loggedInUserDetails?.userEmail === row?.uploadedByEmail)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      },
    ];
  };

  const setPermissionData = () => {
    if (permissionBuildDetails?.isPrivate && !_.isEmpty(permissionBuildDetails?.buildDetailRights?.data)) {
      setPreviousPermission(permissionBuildDetails?.buildDetailRights?.data);
      setMembers([...permissionBuildDetails?.buildDetailRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  const savePermission = (Value, buildData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let buildRightsObj = {
      buildDetail: { id: buildData?.id },
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !_.isEmpty(result) ? result : [],
    };
    dispatch(saveBuildDetailsRights(buildRightsObj, searchKeyword, projectId, filter, rights?.id));
    setOpenPermissionDrawer(false);
  };

  const handleActionChange = (actionName, buildData) => {
    setPermissionBuildDetails(() => ({ ...buildData }));
    switch (actionName) {
      case PRIVATE:
        savePermission(PRIVATE, buildData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, buildData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const handleFilters = (e) => {
    setActiveFilter(e);
    setRights(e);
    dispatch(getAllBuildDetails(false, searchKeyword, projectId, "", e?.id, stepType));
  };

  const testJobDetails =
    allAppBuildDetails &&
    allAppBuildDetails?.data?.map((row) => ({
      key: row?.id,
      buildDetails: (
        <div className="display-flex">
          {executionTab && buildSelectionRadioType(row)}
          {!executionTab && buildSelectionCheckboxType(row)}
          <div
            className={
              ([APP_BUILD_UPLOAD_STATUS.IN_PROGRESS, APP_BUILD_UPLOAD_STATUS.PENDING].includes(row?.uploadStatus) ||
                row.allowDelete === ACTION_NOT_VIEW) &&
              "ml-20"
            }
          >
            <div className="display-flex">
              <span className="mr-5">
                <Tooltip
                  title={getPermissionType(row?.isPrivate, row?.buildDetailRights?.data)}
                  color={TOOLTIP_BG_COLOR}
                >
                  <FontAwesomeIcon
                    icon={showPermissionTypeIcon(getPermissionType(row?.isPrivate, row?.buildDetailRights?.data))}
                  />
                </Tooltip>
              </span>

              <h3
                className="font-color-darkBlue cursor__pointer"
                onClick={() => row?.uploadStatus === "Done" && fetchAppBuildDataById(row?.id)}
              >
                {row?.displayName}
              </h3>
            </div>
            <div>
              <Label>Version: </Label> {row?.buildVersion}
            </div>
            <div>
              <Label>Type: </Label> {row?.buildType}
            </div>
            {!_.isEmpty(row?.buildLinkedTo) && (
              <div className="flex-wrap display-flex align-items-center">
                <Label>Uploaded on</Label>
                <div className="mr-5 ml-5">
                  {row?.buildLinkedTo?.map((i, index) => (
                    <span>
                      {index !== 0 && ", "}
                      <span className="font-weight-bold font-size-12">{i?.name}</span>
                    </span>
                  ))}
                </div>
                <Label>Farm</Label>
              </div>
            )}
          </div>
        </div>
      ),
      buildHistory: (
        <>
          <div>
            <Label>Build Date and Time: </Label>
            {row?.buildDate ? displayUTCDateFormatWithToolTip(row?.buildDate, defaultDateTimeFormat) : "NA"}
          </div>
          <div>
            <Label>Created on: </Label>
            {displayUTCDateFormatWithToolTip(row?.buildDate, defaultDateTimeFormat)}
          </div>
          <div>
            <Label>Uploaded by: </Label> {row?.uploadedBy || "NA"} on{" "}
            {row?.uploadedOn ? displayUTCDateFormatWithToolTip(row?.uploadedOn, defaultDateTimeFormat) : "NA"}
          </div>

          <div className="display-flex align-items-center">
            <Label>Admins: </Label>
            <div className="display-flex ml-2">
              {row?.buildDetailRights &&
                getAdmins([
                  { name: row?.uploadedBy },
                  ...row?.buildDetailRights?.data?.filter(
                    (i) => i?.isAdmin && i?.shouldAddOrRemove === 1 && i?.name !== row?.uploadedBy
                  ),
                ])}
            </div>
          </div>
        </>
      ),
      tags: <div className="display-flex">{row?.buildTags && showBuildTags(row?.buildTags?.split(","))}</div>,

      action: !executionTab && (
        <div className="display-flex justify-center p-20">
          {row?.uploadStatus === "In-Progress" ? (
            <Spin spinning={true}></Spin>
          ) : (
            <>
              <Tooltip color={TOOLTIP_BG_COLOR} title="Install Build">
                <img
                  src={ICON_DOWNLOAD}
                  alt="Edit"
                  className={row?.uploadStatus === "Done" ? "cursor-pointer " : "cursor-not-allowed"}
                  onClick={() =>
                    row?.uploadStatus === "Done" && dispatch(showInstallBuildOnDevicesModal(projectId, row))
                  }
                />
              </Tooltip>
              {row?.allowEdit === ACTION_VIEW && (
                <Tooltip color={TOOLTIP_BG_COLOR} title="Edit">
                  <img
                    src={ICON_EDIT}
                    alt="Edit"
                    className={row?.uploadStatus === "Done" ? "pl-10 cursor-pointer " : "pl-10 cursor-not-allowed"}
                    onClick={() => row?.uploadStatus === "Done" && fetchAppBuildDataById(row?.id)}
                  />
                </Tooltip>
              )}

              {row?.allowDelete === ACTION_VIEW && (
                <Tooltip color={TOOLTIP_BG_COLOR} title="Delete">
                  <img
                    src={ICON_DELETE}
                    alt="Delete"
                    className={row?.uploadStatus === "Done" ? "pl-10 cursor-pointer " : "pl-10 cursor-not-allowed"}
                    onClick={(e) =>
                      row?.uploadStatus === "Done" &&
                      deleteConfirm(row?.displayName, [
                        {
                          id: row?.id,
                          folderPath: row?.folderPath,
                          fileKey: row?.fileKey,
                          awsDeviceFarmProjectId: row?.awsDeviceProjectId,
                        },
                      ])
                    }
                  />
                </Tooltip>
              )}

              {(loggedInUserDetails?.userEmail === row?.uploadedByEmail || row?.allowAdmin === ACTION_VIEW) && (
                <ActionComponent
                  actions={handleAction(row)}
                  data={row}
                  handleActionChange={handleActionChange}
                  disabled={row?.uploadStatus !== "Done"}
                />
              )}
            </>
          )}
        </div>
      ),
    }));
  return (
    <>
      <Spin spinning={loadingAppManagerBuild} tip="Loading" className="spinner-center">
        {!executionTab && (
          <div className="innerHeader display-flex align-items-center justify-space-between">
            <div className="p-12 ml-10 mainHeaderText"> App Builds</div>
            <div className="mr-15">
              <Button
                type="primary"
                size="large"
                className="blue ml-10"
                onClick={() => dispatch(changeAppManagerTab(ADD_BUILDS))}
              >
                Upload Build
              </Button>
            </div>
          </div>
        )}
        <div className={!executionTab && "pageScroll"}>
          <div className="display-flex justify-space-between align-items-center m-15">
            <div className="display-flex">
              {!executionTab && (
                <PermissionFilter
                  permissionValue={filterPermission}
                  active={activeFilter}
                  handleFilters={handleFilters}
                />
              )}
            </div>
            <div className="display-flex">
              <div>
                <CommonSearch
                  placeHolder="Search By Build Names, Build Type, Build Version"
                  searchDetails={(searchText) =>
                    dispatch(
                      getAllBuildDetails(
                        false,
                        searchText,
                        projectId,
                        executionTab ? "DONE" : "",
                        executionTab ? "" : rights?.id,
                        stepType,
                        executionTab ? 1 : 0
                      )
                    )
                  }
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
            </div>
          </div>
          <div className="mt-10 mb-10 display-flex justify-space-between">
            {!executionTab && !_.isEmpty(selectAllBuild) && (
              <div className="mr-15">
                <SelectAllBox
                  isChecked={isSelectedAllIds(
                    selectedId,
                    isNotIncludePendingAndInProgress?.filter((i) => i.allowDelete === ACTION_VIEW)
                  )}
                  isDisable={_.isEmpty(allAppBuildDetails?.data) || checkIsPendingOrInProgress}
                  selectAllIds={selectAllBuild}
                  handleChangeCheck={(isChecked) => {
                    if (isChecked) {
                      setSelectAllBuild([
                        ...selectAllBuild,
                        ...isNotIncludePendingAndInProgress
                          ?.filter((i) => i.allowDelete === ACTION_VIEW && !selectedId?.includes(i?.id) && i?.id)
                          ?.map(
                            (i) =>
                              i.allowDelete === ACTION_VIEW && {
                                id: i.id,
                                folderPath: i.folderPath,
                                fileKey: i.fileKey,
                                awsDeviceFarmProjectId: i.awsDeviceProjectId,
                              }
                          ),
                      ]);
                    } else {
                      setSelectAllBuild(removeSelectedIds(selectedId, isNotIncludePendingAndInProgress));
                    }
                  }}
                  deleteConfirm={deleteConfirm}
                />
              </div>
            )}
            {executionTab && (
              <div className="font-size-14 font-weight-500 display-flex ">
                Available Builds
                <div className="ml-20">
                  <Checkbox
                    checked={testJobDetail?.isUseLatestBuild === 1}
                    onChange={(e) => {
                      dispatch(
                        changeTestJobData({
                          ...testJobDetail,
                          isUseLatestBuild: e.target.checked ? 1 : 0,
                          buildDetail: undefined
                        })
                      );
                      setSelectAllBuild([]);
                    }}
                  />
                  <label className="ml-5">Always pick the latest Build</label>
                </div>
                <div className="ml-20">
                  <Checkbox
                    value={testJobDetail?.unInstallBUildBeforeInstall}
                    onChange={(e) => {
                      dispatch(
                        changeTestJobData({
                          ...testJobDetail,
                          unInstallBUildBeforeInstall: e.target.checked ? 1 : 0,
                        })
                      );
                    }}
                  />
                  <label className="ml-5">Uninstall The Build Before Install</label>
                </div>
              </div>
            )}
          </div>
          <div className="mb-20 mt-10 m-15">
            <TableComponent columns={columns} data={testJobDetails} selected={selectedId} />
          </div>
          <div className="pull-right">
            {!_.isEmpty(allAppBuildDetails) && (
              <PaginationComponent
                currentPageItems={allAppBuildDetails?.pageItems}
                totalItems={allAppBuildDetails.totalItems}
                currentPage={allAppBuildDetails?.currentPage}
                totalPage={allAppBuildDetails.totalCount}
                callPaginationAPI={(pageNumber) => {
                  dispatch(changeAppBUildListPageNumber(pageNumber));
                  dispatch(getAllBuildDetails(false, searchKeyword, projectId, filter, executionTab ? "" : rights?.id));
                }}
              />
            )}
          </div>
          {window.pageYOffset > 300 && !executionTab && (
            <div className="mt-10 mb-10 display-flex justify-space-between">
              <SelectAllBox
                isChecked={isSelectedAllIds(
                  selectedId,
                  isNotIncludePendingAndInProgress?.filter((i) => i.allowDelete === ACTION_VIEW)
                )}
                isDisable={_.isEmpty(allAppBuildDetails?.data) || checkIsPendingOrInProgress}
                selectAllIds={selectAllBuild}
                handleChangeCheck={(isChecked) => {
                  if (isChecked) {
                    setSelectAllBuild([
                      ...selectAllBuild,
                      ...isNotIncludePendingAndInProgress
                        ?.filter((i) => i.allowDelete === ACTION_VIEW && !selectedId?.includes(i?.id) && i?.id)
                        ?.map(
                          (i) =>
                            i.allowDelete === ACTION_VIEW && {
                              id: i.id,
                              folderPath: i.folderPath,
                              fileKey: i.fileKey,
                              awsDeviceFarmProjectId: i.awsDeviceProjectId,
                            }
                        ),
                    ]);
                  } else {
                    setSelectAllBuild(removeSelectedIds(selectedId, isNotIncludePendingAndInProgress));
                  }
                }}
                deleteConfirm={deleteConfirm}
              />
            </div>
          )}
        </div>
        <DeviceListInstallBuild projectId={projectId} orgId={orgId}  showDeviceView={showDeviceView}/>
        <PermissionDrawer
          openPermissionDrawer={openPermissionDrawer}
          onClose={() => setOpenPermissionDrawer(false)}
          setOpenPermissionDrawer={setOpenPermissionDrawer}
          members={members}
          permissionData={permissionBuildDetails}
          setMembers={setMembers}
          setPermissionData={setPermissionData}
          savePermission={savePermission}
          previousPermission={previousPermission}
          ownerEmailId={permissionBuildDetails?.uploadedByEmail}
          notShowExecutionCheck={true}
          drawerTitle={"App Build"}
        />
      </Spin>
    </>
  );
};

export default AppBuildList;
