import { default as React } from "react";
import { Button } from "antd";
import styles from "./HeaderButtonsGroupV2.module.scss";

const HeaderButtonsGroupV2 = (props) => {
  const { data, activeButton, onClick, wrapperClass = "" } = props;

  return (
    <div className={`${styles["header--button-wrapper"]} ${wrapperClass}`}>
      {data?.map((e, index) => {
        return (
          <Button
            key={index}
            type={e?.id === activeButton ? "primary" : "default"}
            onClick={() => onClick(e)}
            className={`${e?.id === activeButton && styles["active"]}`}
          >
            {e.value} {!!e.count && `(${e.count})`}
          </Button>
        );
      })}
    </div>
  );
};

export default HeaderButtonsGroupV2;
