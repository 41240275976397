import { faAngleDoubleRight, faCamera, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Popover, Row, Spin, Tooltip } from "antd";
import { default as React } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { INFO_ICON } from "../../../CdnImagePath";
import {
  ACTION,
  PRE_CONDITION,
  STEP,
  TEST_PLAN_RESULT_TYPE,
  TOOLTIP_BG_COLOR,
  VERIFICATION,
  VERIFICATIONS,
  defaultDateTimeFormat,
} from "../../../Constants";
import { getDateTimeInFormat, getDateTimeInUTCFormat, isNotNullAndNotEmptyAndNotUndefined } from "../../../Util";
import { DeviceCardHeader, DeviceCardTitle, StyledCol } from "../TestPlanResultDetails/TestPlanResultDetailsStyled";
import { highlightResult } from "./TestPlanResultDetailPreconditionList";
import { durationTime, isShowDuration } from "./TestPlanResultScenarioDetails";
import { ICON_AI } from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import ReactJson from "react-json-view";

const StepsDataRow = styled.div`
  display: flex;
  font-size: 14px;
  border-radius: 5px;
  width: 100%;
  color: "#65676b";
  background-color: "#338fcc";
`;

const StepsDataCol = styled.div`
  width: 100%;
  height: auto;
`;

const StepsData = styled.span`
  font-weight: 500;
  margin-right: 5px;
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;

const SkippedSpan = styled.span`
  color: #ffcb32;
  margin-left: 5px;
`;

const StylesCaptureScreenshot = styled.span`
  margin-right: 10px;
  color: #338fcc;
  width: 10px;
  line-height: 2.5em;
`;

const showArrow = (continueAfterFail, subChildStepFailedAndHaveContinueAfterFailFlag) => {
  if (continueAfterFail === 1 || subChildStepFailedAndHaveContinueAfterFailFlag) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title="Ignore failure, skip grouped-steps and move to execution of next step."
        placement="bottom">
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faAngleDoubleRight} />
      </Tooltip>
    );
  }
};

const showRepeatStepCount = (isLoopStep, loopStepCount, executedLoopCount, parentLoopStep, parentLoopStepCount) => {
  if (isLoopStep === 1) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={"Repeat Steps till count reach to " + loopStepCount + "."}
        placement="bottom">
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faReply} />
      </Tooltip>
    );
  } else if (parentLoopStep === 1) {
    return (
      <Tooltip
        color={TOOLTIP_BG_COLOR}
        title={"Repeat Steps till count reach to " + parentLoopStepCount + "."}
        placement="bottom">
        <FontAwesomeIcon className="font-color-orange mr-5 mt-5" icon={faReply} />
      </Tooltip>
    );
  }
};

export const getResultElement = (result, data, setOpenErrorInvestigationModal, setTestStepId, action) => {
  switch (result) {
    case TEST_PLAN_RESULT_TYPE.IN_PROGRESS:
    case TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT:
      return (
        <div className="display-flex">
          <Spin size="small" className="mr-5" />
          <span className="badge bg-info p-5 font-weight-900  "> {TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT}</span>
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.PASSED:
      return <span className="badge bg-success p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.PASSED}</span>;
    case TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY:
      return (
        <span className="badge bg-scheduled p-5 font-weight-900 w-60 ">
          {TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT}
        </span>
      );
    case TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT:
      return (
        <span className="badge bg-uploading p-5 font-weight-900 w-60 ">
          {TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT_TXT}
        </span>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED_FOR_SCENARIO}>
          <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
        </Tooltip>
      );
    case TEST_PLAN_RESULT_TYPE.ABORTED:
      return (
        <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.ABORTED}</span>
      );
    case TEST_PLAN_RESULT_TYPE.TERMINATED:
      return (
        <span className="badge bg-abort text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.TERMINATED}</span>
      );
    case TEST_PLAN_RESULT_TYPE.SKIPPED:
      return (
        <span className="badge bg-warning text-dark p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED:
      return (
        <div className="align-self-center">
          <div className="badge bg-danger p-5 font-weight-700 m-5">{TEST_PLAN_RESULT_TYPE.FAILED}</div>
          {action !== STEP && (
            <div className="m-5">
              <Button
                className="investigation-button bg-info"
                type="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenErrorInvestigationModal(true);
                  setTestStepId(data.id);
                }}>
                {isNotNullAndNotEmptyAndNotUndefined(data?.testExecutionErrorInvestigationDetails)
                  ? data?.testExecutionErrorInvestigationDetails?.failureReason
                  : "Needs investigation"}
              </Button>
            </div>
          )}
        </div>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE_FOR_SCENARIO}>
          <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
        </Tooltip>
      );
    case TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET_FOR_SCENARIO}>
          <span className="badge bg-danger p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.FAILED}</span>
        </Tooltip>
      );
    case TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED_FOR_SCENARIO}>
          <span className="badge bg-warning p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
        </Tooltip>
      );
    case TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED}>
          <span className="badge bg-warning p-5 font-weight-900 w-60 ">{TEST_PLAN_RESULT_TYPE.SKIPPED}</span>
        </Tooltip>
      );
    default:
      return <span className="default-result-text">{result}</span>;
  }
};

const TestPlanResultDetailStep = (props) => {
  const { data, preConditionList, modifiedResult, setTestStepId, setOpenErrorInvestigationModal } = props;

  const duration = data && data.duration && data.duration.length > 0 ? data.duration.split(":") : null;
  return (
    <>
      <div className="display-flex justify-space-between w-100">
        <StepsDataRow>
          {showArrow(data.continueAfterFail, data.subChildStepFailedAndHaveContinueAfterFailFlag)}
          {data?.aiGeneratedStep ? 
              <span className="align-self-center">
              <Popover overlayStyle={{ width: "60%" }} content={
                <div>
                  <ReactJson src={JSON.parse(data?.aiGeneratedStep)} />
                </div>
                } title="AI Generated Step">
                <span>
                    <SvgLoader
                    iconName={ICON_AI}
                    infoIcon={true}
                  />
                </span>
                </Popover>
              </span>
            :""
          }
          {data?.isCaptureScreenshot === 1 && (
            <span className="align-self-center">
              <Popover
                placement="right"
                content={
                  <>
                    <img
                      src={data?.afterActionScreenshotUrl ? data?.afterActionScreenshotUrl : INFO_ICON}
                      height={data?.afterActionScreenshotUrl && 450}
                      weight={data?.afterActionScreenshotUrl && 225}
                      alt={
                        data?.result !== null
                          ? " Failed to capture screenshot"
                          : "Captured screenshot will appear here"
                      }
                    />
                    {!data?.afterActionScreenshotUrl && (
                      <span className="ml-5">
                        {data?.result !== null
                          ? " Failed to capture screenshot"
                          : "Captured screenshot will appear here"}
                      </span>
                    )}
                  </>
                }
                title="Captured Screenshot Image"
                trigger="hover">
                <StylesCaptureScreenshot>
                  {data?.result === null ? (
                    <FontAwesomeIcon icon={faCamera} />
                  ) : data?.afterActionScreenshotUrl !== null && data?.result !== null ? (
                    <FontAwesomeIcon className="green-icon" icon={faCamera} />
                  ) : (
                    <FontAwesomeIcon className="red-icon" icon={faCamera} />
                  )}
                </StylesCaptureScreenshot>
              </Popover>
            </span>
          )}
          {data?.aiGeneratedStep && 
            <span className="align-self-center">
              <Popover overlayStyle={{ width: "60%" }} content={
                <div>
                  <ReactJson src={JSON.parse(data?.aiGeneratedStep)} />
                </div>
                } title="AI Generated Step">
                <span>
                    <SvgLoader
                    iconName={ICON_AI}
                    infoIcon={true}
                  />
                </span>
                </Popover>
              </span>
              }
          {showRepeatStepCount(
            data.isLoopStep,
            data.loopStepCount,
            data.executedLoopCount,
            data.isParentLoopStep,
            data.parentLoopStepCount
          )}
          <StepsDataCol className="align-self-center">
            <span className="mr-5">
              {data.action !== STEP ? (data.action === VERIFICATIONS ? `${VERIFICATION}:` : `${ACTION}:`) : null}
            </span>
            <StepsData>
              {data.name}
              {data.retryCounts !== 0 ? "(" + data.retryCounts + ")" : ""}
            </StepsData>
            {isShowDuration(duration) && (
              <div>
                <span>Duration:</span>
                <Popover
                  placement="bottom"
                  title={
                    <DeviceCardHeader>
                      <DeviceCardTitle>{"Step Execution Details"}</DeviceCardTitle>
                    </DeviceCardHeader>
                  }
                  content={
                    <Row gutter={[16, 16]}>
                      <StyledCol>
                        <Col className="p-0" span={12}>
                          Start Time:
                        </Col>
                        {getDateTimeInFormat(data?.startTime)} (
                        {getDateTimeInUTCFormat(data?.startTime, defaultDateTimeFormat)} GMT)
                        {/* {displayUTCDateFormatWithToolTip(data?.startTime, defaultDateTimeFormat)} */}
                      </StyledCol>
                      <Row className="pl-10 font-size-12 w-100">
                        <Col className="p-0" span={12}>
                          End Time:
                        </Col>
                        {getDateTimeInFormat(data?.endTime)} (
                        {getDateTimeInUTCFormat(data?.endTime, defaultDateTimeFormat)} GMT)
                        {/* {displayUTCDateFormatWithToolTip(data?.endTime, defaultDateTimeFormat)} */}
                      </Row>
                    </Row>
                  }>
                  <span className="font-weight-500 ml-5">{durationTime(duration)}</span>
                </Popover>
              </div>
            )}
            {data.action === 'launchApp' && (
                <div>
                  <Row>
                    <Col>App Package:</Col>
                    <Col className="font-weight-500 ml-5">{data?.packageName}</Col>
                  </Row>
                  <Row>
                    <Col>App Activity:</Col>
                    <Col className="font-weight-500 ml-5">{data?.packageActivity}</Col>
                  </Row>
                  <Row>
                    <Col>Clear previous cache:</Col>
                    <Col className="font-weight-500 ml-5">{data?.removeAppPreviousSession}</Col>
                  </Row>
                </div>
              )
            }
            {data.modifiedResult === TEST_PLAN_RESULT_TYPE.SKIPPED &&
            data.isSkipStep === 0 &&
            data.isStepHasPreCondition === 1 ? (
              <SkippedSpan>Skipped due to precondition failed</SkippedSpan>
            ) : data.isSkipStep === 1 ? (
              <span className="badge bg-secondary text-dark p-5 font-weight-900">Step Disabled</span>
            ) : null}
          </StepsDataCol>
          <StyledSpan>
            {getResultElement(data.modifiedResult, data, setOpenErrorInvestigationModal, setTestStepId, data.action)}
          </StyledSpan>
        </StepsDataRow>
      </div>
      {data.isLoopStep === 0 && preConditionList && (
        <>
          {preConditionList.map((v, k) => {
            return (
              <div className="mt-10" key={k}>
                <StepsDataRow>
                  <StepsDataCol>
                    <span className="mr-5">{PRE_CONDITION}:</span>
                    <StepsData>{v.preConditionTitle}</StepsData>
                  </StepsDataCol>
                  <div>{v.preconditionResult ? highlightResult(v.preconditionResult, modifiedResult) : ""}</div>
                </StepsDataRow>
              </div>
            );
          })}
        </>
      )}
    </>
  );
};
export default TestPlanResultDetailStep;
