import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from './../../actions/CommonActions';

class Logout extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);    
  }
  
  componentWillMount(newProps) {         
      this.props.commonActions.logout();         
  }
  render(){              
     return null;
  }  
}

function mapDispatchToProps(dispatch) {
    return  bindActionCreators(actions, dispatch)
}

function mapStateToProps(state) {
    return state.CommonReducer
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Logout); 