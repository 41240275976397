import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faClock,
  faExternalLinkSquareAlt,
  faLock,
  faMobile,
  faShieldAlt,
  faSignInAlt,
  faSignOutAlt,
  faUnlockAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Modal, Row, Spin, Tabs } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as deviceActions from "../../actions/Recording";
import * as actions from "../../actions/ServerListAction";
import { ANDROID_LOGO, IOS_LOGO } from "../../CdnImagePath";
import { ANDROID, IOS } from "../../Constants";
import DeviceDefaultAppSet from "../DeviceDefaultAppSet/DeviceDefaultAppSet";
import * as util from "./../../Util/";

const { TabPane } = Tabs;
library.add(faSignInAlt);
library.add(faSignOutAlt);
library.add(faExternalLinkSquareAlt);
library.add(faMobile);
library.add(faLock);
library.add(faUnlockAlt);
library.add(faBan);

class DeviceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditOn: false,
      updatedDeviceNote: "",
      showReleaseDeviceAlert: false,
    };
    this.navigateToDeviceScreen = this.navigateToDeviceScreen.bind(this);
    this.editDeviceNote = this.editDeviceNote.bind(this);
    this.handleReleaseDevice = this.handleReleaseDevice.bind(this);
  }

  navigateToDeviceScreen(platform, serverId, device, deviceFarmId) {
    // this.props.updateDeviceDetail(device);
    this.props.actions.flushTheDashBoardAttributeInRecording();
    // if (platform === "ANDROID") {
    // this.props.actions.startDeviceScreenStreaming(platform, serverId, device.targetUniqueId, deviceFarmId);
    // } else {
    new Promise((resolve) => resolve(this.props.actions.generateDeviceToken())).then(() => {
      this.props.deviceActions.startAppiumStreaming(
        platform,
        serverId,
        device.targetUniqueId,
        deviceFarmId,
        false,
        this.props.projectId,
        this.props.orgId
      );
    });

    // }
  }

  editDeviceNote(device) {
    this.props.actions.getDeviceNotesHistory(device.targetUniqueId);
    this.props.actions.setCurrentDeviceEditNoteUdid(device.targetUniqueId, device.deviceNote);
  }
  handleReleaseDevice(device, serverDetail) {
    this.props.serverListActions.releaseDevice(device, serverDetail);
  }
  handleShowReleaseDeviceByAdminWarningModal() {
    this.setState({ showReleaseDeviceAlert: true });
  }
  handleReleaseDeviceByAdmin(device, serverDetail, devicePlatform, deviceFarmId) {
    this.props.actions.releaseDeviceByAdmin(device, serverDetail, devicePlatform, deviceFarmId);
    this.setState({ showReleaseDeviceAlert: false });
  }
  handleCloseReleaseDeviceModal() {
    this.setState({ showReleaseDeviceAlert: false });
  }

  render() {
    const {
      deviceFarmId,
      currentDeviceNote,
      currentDeviceEditNoteUdid,
      acquireReleaseDeviceServiceRequest,
      acquireReleaseDeviceServiceRequestDeviceId,
      checkAndRestartAndroidNodeServiceRequestDeviceId,
      checkAndRestartAndroidNodeServiceRequest,
      currentDeviceSelectedForDefaultAppSet,
    } = this.props;
    const {
      serverDetail,
      isDeviceAcquired,
      device,
      isDeviceAcquiredForRun,
      deviceAcquireUserName,
      acquiredByCurrentUser,
      deviceAcquireBy,
      deviceAcquireTime,
      deviceLastInteractTime,
    } = this.props.data;
    let devicePlatform = "";
    if (device["platform"] === ANDROID) {
      devicePlatform = "(Android)";
    } else if (device["platform"] === IOS) {
      devicePlatform = "(iOS)";
    }
    let actionItems = [];
    // Relase Device Icon
    if (acquiredByCurrentUser) {
      actionItems.push([
        <div
          key={actionItems.length + 1}
          onClick={() => {
            this.props.actions.releaseDevice(device, serverDetail);
          }}
          title={"Release Device"}
        >
          {acquireReleaseDeviceServiceRequestDeviceId == device.targetUniqueId && acquireReleaseDeviceServiceRequest ? (
            <Spin spinning={acquireReleaseDeviceServiceRequest} />
          ) : (
            <Button
              className="orange mt-15"
              onClick={() => {
                this.props.actions.releaseDevice(device, serverDetail);
              }}
            >
              Use Device
            </Button>
          )}
        </div>,
      ]);
    } else if (this.props.adminReleaseDevice) {
      actionItems.push([
        <div
          key={actionItems.length + 1}
          onClick={() => {
            this.handleShowReleaseDeviceByAdminWarningModal();
          }}
          title={"Release Device"}
        >
          {acquireReleaseDeviceServiceRequestDeviceId == device.targetUniqueId && acquireReleaseDeviceServiceRequest ? (
            <Spin spinning={acquireReleaseDeviceServiceRequest} />
          ) : (
            <FontAwesomeIcon icon={faSignOutAlt} />
          )}
        </div>,
      ]);
    }

    // Acquire Device Icon
    if (isDeviceAcquiredForRun) {
      actionItems.push([
        <div title={"Device is in use for automation"}>
          <FontAwesomeIcon icon={faClock} />
        </div>,
      ]);
    } else if (acquiredByCurrentUser) {
      actionItems.push([
        <div
          key={actionItems.length + 1}
          onClick={
            !checkAndRestartAndroidNodeServiceRequest
              ? this.navigateToDeviceScreen.bind(
                  this,
                  device["platform"],
                  this.props.data.serverDetail.id,
                  device,
                  deviceFarmId
                )
              : undefined
          }
          title={"Use Device"}
          className={"allow-session"}
        >
          {checkAndRestartAndroidNodeServiceRequestDeviceId == device.targetUniqueId &&
          checkAndRestartAndroidNodeServiceRequest ? (
            <Spin spinning={checkAndRestartAndroidNodeServiceRequest} />
          ) : (
            <Button className="purple mt-15 ml-10">Release Device</Button>
          )}
        </div>,
      ]);
    } else if (!this.props.adminReleaseDevice) {
      actionItems.push([
        <div
          key={actionItems.length + 1}
          className={"block-session"}
          title={"Device in Use - You may contact the current user to free the device."}
        >
          <FontAwesomeIcon icon={faLock} className={!acquiredByCurrentUser ? "disableControl opacity05" : ""} />
        </div>,
      ]);
    }

    return (
      <React.Fragment>
        <div className={"p-20 deviceCardBorder w-100 mt-20 deviceCardColor"}>
          <Row>
            <Col span={24} className={"display-flex justify-space-between"}>
              <div className={"font-color-darkBlue font-size-14 font-weight-600"}>
                {device.brand} {device.displayName}
              </div>
              <div className={devicePlatform === "(iOS)" ? "apple-device-brand-logo" : "android-device-brand-logo"}>
                {devicePlatform === "(iOS)" ? (
                  <img src={IOS_LOGO} alt="iphone" />
                ) : (
                  <img src={ANDROID_LOGO} alt="androzid" />
                )}
              </div>
            </Col>
            <span className={"deviceCardLabel font-size-13"}>OS Version: {device.osVersion}</span>
          </Row>
          {isDeviceAcquiredForRun &&
            deviceAcquireUserName != null && [
              <Row key={1}>
                <Col span={24}>
                  <div className={"deviceCardLabel font-size-13"}>Acquired by: {deviceAcquireBy}</div>
                </Col>
              </Row>,
            ]}
          {isDeviceAcquired &&
            deviceAcquireTime &&
            deviceAcquireTime != null && [
              <Row key={1}>
                <Col span={24}>
                  <div className={"deviceCardLabel font-size-13"}>Acquired: {util.getTimeAgo(deviceAcquireTime)}</div>
                </Col>
              </Row>,
            ]}
          {isDeviceAcquired && [
            <Row key={2}>
              <Col span={24}>
                <div className={"deviceCardLabel font-size-13"}>
                  {"Last Interact : " + (deviceLastInteractTime ? util.getTimeAgo(deviceLastInteractTime) : "-")}
                </div>
              </Col>
            </Row>,
          ]}

          <Row>
            {device["isDeviceOffline"]
              ? [
                  <Button
                    className={"offline-button disableControl opacity05"}
                    type="priary"
                    icon={<FontAwesomeIcon icon={faBan} />}
                  >
                    <span>
                      OFFLINE <br /> (Attempting to recover)
                    </span>
                  </Button>,
                ]
              : !isDeviceAcquired && !isDeviceAcquiredForRun
              ? [
                  <div
                    onClick={() => {
                      this.props.actions.acquireDevice(device, serverDetail);
                    }}
                    className={"allow-session"}
                    title={"Acquire Device"}
                  >
                    {acquireReleaseDeviceServiceRequestDeviceId == device.targetUniqueId && acquireReleaseDeviceServiceRequest ? (
                      <Spin spinning={acquireReleaseDeviceServiceRequest} />
                    ) : (
                      <Button
                        className="orange mt-15"
                        onClick={() => {
                          this.props.actions.acquireDevice(device, serverDetail);
                        }}
                      >
                        Acquire Device
                      </Button>
                    )}
                  </div>,
                ]
              : actionItems}
          </Row>
        </div>
        <Modal
          title="Device Notes"
          visible={device.targetUniqueId == currentDeviceEditNoteUdid}
          onOk={() => {
            currentDeviceNote &&
              currentDeviceNote.trim().length !== 0 &&
              this.props.actions.saveDeviceNote(device.targetUniqueId, this.props.currentDeviceNote);
          }}
          onCancel={this.props.actions.hideDeviceNotedModal.bind(this)}
          okText="Update"
          cancelText="Cancel"
          loading={false}
          okButtonProps={{
            disabled: currentDeviceNote && currentDeviceNote.trim().length === 0,
          }}
          className={"device-note-modal"}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab="Current Note" key="1">
              <React.Fragment>
                <Row>
                  <Col>
                    <textarea
                      required
                      value={currentDeviceNote}
                      onChange={(e) => {
                        this.props.actions.onChangeDeviceNotes(e.target.value);
                      }}
                      cols={30}
                      rows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="device-note-edited-by">
                    {device.createdNoteBy && device.deviceNoteCreatedDate
                      ? device.createdNoteBy + " Edited On " + device.deviceNoteCreatedDate
                      : ""}
                  </Col>
                </Row>
              </React.Fragment>
            </TabPane>
            <TabPane tab="History" key="2">
              <div className="device-note-history-container">
                {this.props.currentDeviceNoteHistory &&
                this.props.currentDeviceNoteHistory != undefined &&
                this.props.currentDeviceNoteHistory.length > 0 ? (
                  this.props.currentDeviceNoteHistory.map((v, k) => {
                    return (
                      <Card className="w-100">
                        <Row>
                          <Col>{v.note ? v.note : ""}</Col>
                        </Row>
                        <Row>
                          <Col className="device-note-edited-by">
                            {v.createdBy && v.updatedOn ? v.createdBy + " Edited On " + v.updatedOn : ""}
                          </Col>
                        </Row>
                      </Card>
                    );
                  })
                ) : (
                  <Row>
                    <Col className={"no-data"}>No History Data For Device.</Col>
                  </Row>
                )}
              </div>
            </TabPane>
          </Tabs>
        </Modal>
        <Modal
          title={
            <div>
              <FontAwesomeIcon icon={faShieldAlt} className={"device-security-shield-icon"}></FontAwesomeIcon>
              &nbsp;&nbsp;&nbsp;Device Data Security
            </div>
          }
          visible={device.targetUniqueId == currentDeviceSelectedForDefaultAppSet}
          onCancel={() => this.props.actions.hideDeviceSetDefaultAppModal()}
          loading={false}
          className={"default-app-set-modal"}
          centered={true}
          footer={null}
          maskClosable={false}>
          {device.targetUniqueId == currentDeviceSelectedForDefaultAppSet && (
            <DeviceDefaultAppSet
              setDefaultsAppRightsForDevice={this.props.setDefaultsAppRightsForDevice}
              serverDetail={serverDetail}
              device={device}
              deviceFarmId={deviceFarmId}
              serverId={this.props.data.serverDetail.id}></DeviceDefaultAppSet>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    deviceActions: bindActionCreators(deviceActions, dispatch),
  };
}

function mapStateToProps(state) {
  return state.ServerListReducer;
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceCard);
