import { Col, Row, Modal, Space } from "antd";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  changeExecutionManagerTabAndSubTab,
  changeSelectedTestJobCriteria,
  ediBuildJob,
  resetTestJobData,
  saveTestJob,
  updateTestJobErrorDetail,
} from "../../../../actions/TestJobActions";
import { getTestPlanDetailsList, setScheduleTestPlan } from "../../../../actions/TestPlanAction";
import { XPRESS_LOGO } from "../../../../CdnImagePath";
import {
  ALL_JOBS,
  APP_MANAGER,
  BUILD_URL,
  USE_LATEST_BUILD,
  MY_PLANS,
  OWNED,
  RECORD_10_PER_PAGE,
  SCHEDULE_TEST_JOB,
  SERIAL,
  CONFIRM_MODAL_CONTENT,
} from "../../../../Constants";
import {
  checkListIsEmpty,
  checkNotUndefined,
  checkUndefined,
  isNullOrUndefinedOrEmpty,
  showNotification,
} from "../../../../Util";
import BackButton from "../../../CoreComponents/BackButtonComponent/BackButton";
import SaveResetButton from "../../../CoreComponents/SaveResetComponent/SaveResetButton";
import { BuildJobModal } from "../BuildJobModal";
import JobCriteria from "./JobCriteria";
import JobCriteriaOptions from "./JobCriteriaOptions/JobCriteriaOptions";
import CommonConfirmationModalV2 from "../../../CommonComponents/CommonConfirmationModalV2/CommonConfirmationModalV2";

export const criteriaItem = styled(Row)`
  border: 1px solid #80808066;
  border-radius: 3px;
  padding: 10px;
  margin: 0px 10px 10px 0px;
  font-size: 14px;
  color: black;
  height: auto;
`;
const { confirm } = Modal;
const BuildJob = (props) => {
  const { projectId, orgId, showDeviceView } = props;

  const { testJobDetail, selectedTestJobCriteria, testJobErrorDetail, previousTestJobDetail, unSavedBuildJobChanges } =
    useSelector((state) => state.TestJobReducer);
  const dispatch = useDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const saveJob = () => {
    if (Object.keys(testJobErrorDetail).filter((i) => testJobErrorDetail[i] === true).length === 0) {
      if (testJobDetail?.devices?.length < 2 && testJobDetail?.executionType !== SERIAL) {
        confirm({
          title:
            "Only serial execution mode will be enabled, as you have selected one device. If you would like to execute in parallel, you have to select multiple devices",
          onOk() {
            dispatch(saveTestJob(projectId, { id: OWNED, value: MY_PLANS }));
          },
        });
      } else {
        dispatch(saveTestJob(projectId, { id: OWNED, value: MY_PLANS }));
      }
    } else {
      showNotification("error", "Error in saving the job.", "");
    }
  };
  useEffect(() => {
    dispatch(updateTestJobErrorDetail());
  }, [selectedTestJobCriteria, testJobDetail]);

  useEffect(() => {
    if (testJobDetail?.id === undefined) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: "",
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeyword: "",
          projectId: props.projectId,
          rights: "",
        })
      );
    }
  }, []);

  const isDisableSave = () => {
    const fileUrlIsEmpty =
      testJobDetail.buildType === BUILD_URL &&
      (checkUndefined(testJobDetail.fileUrl) || testJobDetail.fileUrl === "https://");
    let testJobObj = {
      devices: testJobDetail?.devices,
      name: testJobDetail?.name,
      testPlanName: testJobDetail?.testPlan?.name,
    };
    if (testJobDetail?.devices.length === 0) {
      return true;
    }
    if (testJobDetail?.buildType === APP_MANAGER) {
      if (testJobDetail?.isUseLatestBuild === 0) {
        testJobObj = { ...testJobObj, buildDetail: testJobDetail?.buildDetail?.displayName };
      } else {
        testJobObj = { ...testJobObj, isUseLatestBuild: testJobDetail?.isUseLatestBuild };
      }
    }
    if (testJobDetail?.buildType === BUILD_URL) {
      testJobObj = { ...testJobObj, fileUrl: testJobDetail?.fileUrl };
    }
    if (checkNotUndefined(testJobDetail.id)) {
      // Edit With no change
      return (
        JSON.stringify(testJobDetail) === JSON.stringify(previousTestJobDetail) ||
        (testJobDetail?.buildType === APP_MANAGER &&
          testJobDetail?.buildDetail?.id === 0 &&
          testJobDetail.isUseLatestBuild === 0) ||
        fileUrlIsEmpty
      );
    } else {
      // Save with No change
      return (
        Object.values(testJobObj).some((i) => isNullOrUndefinedOrEmpty(i) || checkListIsEmpty(i)) || fileUrlIsEmpty
      );
    }
  };

  const isDisableReset = useCallback(() => {
    // Edit With no change
    return (
      (checkNotUndefined(testJobDetail.id) &&
        JSON.stringify(testJobDetail) === JSON.stringify(previousTestJobDetail)) ||
      // Save with No change
      (checkUndefined(testJobDetail.id) && JSON.stringify(testJobDetail) === JSON.stringify(new BuildJobModal({})))
    );
  }, [testJobDetail, previousTestJobDetail]);

  const onBackClick = () => {
    if (isDisableReset()) {
      dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
    } else {
      setOpenConfirmModal(true);
    }
    dispatch(setScheduleTestPlan(null));
  };

  useEffect(() => {
    if (isDisableReset()) {
      dispatch(ediBuildJob(false));
    } else {
      dispatch(ediBuildJob(true));
    }
  }, [isDisableReset, dispatch]);
  
  return (
    <>
      <Row justify="space-between" align="middle" className="fixInnerHeader">
        <Col>
          <div className="mb-0 mainHeaderText">Build Job</div>
        </Col>
        <Col>
          <Space>
            <SaveResetButton
              submitLabel={"Save"}
              resetLabel={"Reset"}
              submitDisable={isDisableSave()}
              resetDisable={isDisableReset()}
              handleSubmit={saveJob}
              handleReset={() => {
                dispatch(changeSelectedTestJobCriteria(SCHEDULE_TEST_JOB.STEP1));
                dispatch(resetTestJobData());
                dispatch(ediBuildJob(false));
              }}
            />
            <div className="closeIcon">
              <BackButton handleBack={onBackClick} isBlack={true} />
            </div>
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24} className="display-flex pageScroll">
          <Col span={6}>
            <JobCriteria
              selectedCriteria={selectedTestJobCriteria}
              testJobDetail={testJobDetail}
              errorInStep={testJobErrorDetail}
            />
          </Col>
          <Col span={18}>
            <JobCriteriaOptions
              orgId={orgId}
              projectId={projectId}
              selectedCriteria={selectedTestJobCriteria}
              testJobDetail={testJobDetail}
              errorInStep={testJobErrorDetail}
              previousTestJobDetail={previousTestJobDetail}
              isDisableReset={isDisableReset()}
              showDeviceView={showDeviceView}
            />
          </Col>
        </Col>
      </Row>
      <CommonConfirmationModalV2
        handleOk={() => {
          dispatch(changeExecutionManagerTabAndSubTab(ALL_JOBS));
          dispatch(ediBuildJob(false));
          setOpenConfirmModal(false);
        }}
        handleCancel={() => setOpenConfirmModal(false)}
        modalOpen={openConfirmModal}
        modalContent={CONFIRM_MODAL_CONTENT}
        modalTitle={"Discard Changes"}
        okText={"Confirm"}
        isDeleteModal={true}
      />
    </>
  );
};

export default BuildJob;
