import { default as React, useState } from "react";
import RecTestStepActionDetail from "./RecTestStepActionDetail/RecTestStepActionDetail";
import { useDispatch } from "react-redux";
import { dragHoverOnStepOrAction } from "../../../Util";

const RecTestStepAction = (props) => {
  const {
    recTestStep,
    disableAction,
    isDeviceLoaded,
    projectId,
    recTestSteps,
    isTestBlock,
    stepType,
    showToggle,
    stepName,
    stepNo,
  } = props;
  const [isDragOver, setIsDragOver] = useState(false);

  const dispatch = useDispatch();
  return (
    <>
      {recTestStep.recTestSteps &&
        recTestStep.recTestSteps.map((childRecTestStep, index) => {
          return (
            <RecTestStepActionDetail
              recTestSteps={recTestSteps}
              key={index}
              index={index}
              data={childRecTestStep}
              parentIndex={recTestStep.orderId}
              disableAction={disableAction}
              isDeviceLoaded={isDeviceLoaded}
              projectId={projectId}
              parentRecTestStep={recTestStep}
              isSkipStep={props.isSkipStep}
              isTestBlock={isTestBlock}
              stepType={stepType}
              showToggle={showToggle}
              stepName={stepName}
              stepNo={stepNo}
            />
          );
        })}
      {!props.isSkipStep &&
        dragHoverOnStepOrAction(
          isDragOver,
          setIsDragOver,
          dispatch,
          false,
          recTestStep?.recTestSteps[recTestStep?.recTestSteps?.length - 1],
          recTestStep,
          recTestStep?.recTestSteps?.length
        )}
    </>
  );
};

export default RecTestStepAction;
