import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "antd";
import _ from "lodash";
import { ALL_DEFINED_BLOCKS, TEST_STEP_BLOCKS } from "../../../Constants";
import { hideTestBuildDataSetTab } from "../../../actions/CommonActions";
import { updateStepType } from "../../../actions/RecTestStep/RecTestStepRedirectAction";
import { disableKeyboard } from "../../../actions/Recording";
import ApplicationStringTab from "../../ApplicationString/ApplicationStringTab";
import DeviceStringTab from "../../DeviceString/DeviceStringTab";
import RecScenarioListModal from "../../RecTestScenario/RecScenarioListModal";
import ImportRecTestStepActionModal from "../../RecTestStep/SingleColumnView/ImportRecTestStepActionModal/ImportRecTestStepActionModal";
import RecTestStepActionElementPropertiesDrawer from "../../RecTestStep/SingleColumnView/RecTestStepActionElementPropertiesDrawer/RecTestStepActionElementPropertiesDrawer";
import UnSavedRecTestStepModal from "../../RecTestStep/SingleColumnView/UnSavedRecTestStepModal";
import StepDetailsV2 from "../../RecTestStepV2/StepDetailsV2/StepDetailsV2";
import TestBlocksHeaderV2 from "../../RecTestStepV2/TestBlockAndScenarioHeaderV2/TestBlocksHeaderV2";
import styles from "./TestBlockV2.module.scss";
import AllTestBlockStepListV2 from "../../TestBlockV2/AllTestBlockStepListV2/AllTestBlockStepListV2";
import TestDataSetTabsV2 from "../../CommonComponents/TestStepsData/TestDataSetTabsV2";
import RecElementDeleteModalV2 from "../../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecElementDeleteModalV2";
import RecTestStepDeleteModalV2 from "../../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepDeleteModalV2";
import RecTestStepPreConditionDeleteModalV2 from "../../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepPreConditionDeleteModalV2";
import RecTestStepLoopConditionDeleteModalV2 from "../../RecTestStepV2/StepInnerComponents/RecStepsConfirmationModalV2/RecTestStepLoopConditionDeleteModalV2";

const TestBlockV2 = (props) => {
  const { organizationId } = props;
  const [currentOptions, setCurrentOptions] = useState(ALL_DEFINED_BLOCKS);
  const { testDataSetAssigneeDetail, applicationStringAssigneeDetail, deviceStringAssigneeDetail } = useSelector(
    (state) => state.TestDataSetReducer
  );
  const { stepType } = useSelector((state) => state.RecTestStepCommonViewReducer);
  const { recTestStepList, testBlockStep } = useSelector((state) => state.RecTestStepReducer);
  const isPreConditionStep =
    testBlockStep?.recTestStepProperty?.isStepHasPrecondition === 1 ||
    testBlockStep?.recTestStepProperty?.isLoopStep === 1;
  const dispatch = useDispatch();
  const handleOnChange = (value) => {
    setCurrentOptions(value);
  };
  useEffect(() => {
    dispatch(updateStepType(TEST_STEP_BLOCKS));
  }, []);

  useEffect(() => {
    dispatch(hideTestBuildDataSetTab());
  }, [stepType]);

  useEffect(() => {
    setCurrentOptions(ALL_DEFINED_BLOCKS);
  }, [props.projectId]);

  return (
    <>
      <Col span={24} onFocus={() => dispatch(disableKeyboard())}>
        {currentOptions !== ALL_DEFINED_BLOCKS && (
          <TestBlocksHeaderV2
            projectId={props.projectId}
            currentOptions={currentOptions}
            setCurrentOptions={setCurrentOptions}
            orgId={organizationId}
          />
        )}
        {testDataSetAssigneeDetail && currentOptions !== ALL_DEFINED_BLOCKS ? (
          <div className={styles.tabs}>
            <TestDataSetTabsV2 projectId={props.projectId} selectedStepId={testDataSetAssigneeDetail?.id} />
          </div>
        ) : applicationStringAssigneeDetail && currentOptions !== ALL_DEFINED_BLOCKS ? (
          <div className={styles.tabs}>
            <ApplicationStringTab projectId={props.projectId} selectedStepId={applicationStringAssigneeDetail?.id} />
          </div>
        ) : deviceStringAssigneeDetail && currentOptions !== ALL_DEFINED_BLOCKS ? (
          <div className={styles.tabs}>
            <DeviceStringTab projectId={props.projectId} selectedStepId={deviceStringAssigneeDetail?.id} />
          </div>
        ) : currentOptions === ALL_DEFINED_BLOCKS ? (
          <AllTestBlockStepListV2 projectId={props.projectId} navigateToEditBuildScenario={handleOnChange} />
        ) : (
          <div className={_.isEmpty(recTestStepList) && !isPreConditionStep && styles.pageScroll}>
            <StepDetailsV2 projectId={props.projectId} organizationId={props.organizationId} stepType={stepType} />
          </div>
        )}
      </Col>

      <ImportRecTestStepActionModal projectId={props.projectId} />
      <RecElementDeleteModalV2 />
      <RecTestStepPreConditionDeleteModalV2 />
      <RecTestStepDeleteModalV2 />
      <RecTestStepLoopConditionDeleteModalV2 />
      <RecScenarioListModal projectId={props.projectId} />
      <UnSavedRecTestStepModal />
      <RecTestStepActionElementPropertiesDrawer />
    </>
  );
};

export default TestBlockV2;
