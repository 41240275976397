import React from "react";
import { useSelector } from "react-redux";
import { TEST_DATA_SCOPE } from "../../../Constants";
import TestData from "../TestData";
import AddProjectKeys from "./AddProjectKeys/AddProjectKeys";

const ProjectKeys = (props) => {
  const { addProjectKeyForm } = useSelector((state) => state.TestDataReducer);

  const { projectId } = props;
  return (
    <div>
      {addProjectKeyForm ? (
        <AddProjectKeys projectId={projectId} />
      ) : (
        <TestData projectId={projectId} projectKeys={TEST_DATA_SCOPE.PROJECT} />
      )}
    </div>
  );
};

export default ProjectKeys;
