import React, { useEffect, useState, useCallback, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Spin, Tooltip } from "antd";
import _, { isEmpty } from "lodash";
import {
  DEVICE_LOADING,
  DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN,
  TOOLTIP_BG_COLOR,
  DEVICE_FARM_USE,
} from "../../../Constants";
import {
  ADD_OR_REMOVE_DEVICE_ICON,
  DEEP_BLUE_COLOR,
  DEVICE_IMG,
  EMPTY_DEVICE_LIST_IMG,
  ICON_LOCK,
  ICON_SEARCH,
  RELEASE_ICON_NEW,
  SEARCH_DEVICE_ICON,
  SILVER_GRAY,
  USE_DEVICE_ICON,
} from "../../../Constants/SvgConstants";
import SvgLoader from "../../../Util/SvgLoader";
import { isNullOrUndefined } from "../../../Util";
import {
  getDeviceByFarmId,
  getDeviceFarmList,
  releaseDevice,
  resetDeviceFarmList,
} from "../../../actions/ServerListAction";
import history from "../../../history";
import VerticalLine from "../../CommonComponents/VerticalLine";
import DeviceLoadingV2 from "../../DeviceViewV2/DeviceLoadingV2/DeviceLoadingV2";
import EmptyRecordsV2 from "../../EmptyRecordsV2/EmptyRecordsV2";
import SearchDevices from "../../FarmManagement/SearchDevices";
import DeviceDetailsCardV2 from "../DeviceDetailsCardV2/DeviceDetailsCardV2";
import styles from "./DeviceListV2.module.scss";

const DeviceListV2 = ({
  projectId,
  buildDevice,
  handleStartSession,
  showDeviceView,
  forRecording,
  typeForDeviceModals,
  organizationId,
}) => {
  const dispatch = useDispatch();
  const searchRef = useRef(null);
  const [showSearchPanel, setShowSearchPanel] = useState(false);
  const { deviceFarmList, releaseDeviceTargetUniqueId } = useSelector((state) => state.ServerListReducer);
  const { getSearchDeviceList, deviceLoader, fetchingDeviceList, isSearch } = useSelector(
    (state) => state.ManageFarmReducer
  );
  const { selectedDeviceTabActionKey } = useSelector((state) => state.RecordingReducer);
  const { prevProjectId } = useSelector((state) => state.ProjectsReducer);

  const [farmId, setFarmId] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [searchData, setSearchData] = useState({
    deviceName: "",
    deviceFarmName: "",
    osVersion: "",
    searchKeyword: "",
    deviceFarmUse: [],
  });
  const [isDeviceNotAvailable, setIsDeviceNotAvailable] = useState(false);
  const [loadDeviceAPIPending, setLoadDeviceAPIPending] = useState(false);

  useEffect(() => {
    setSearchKeyword("");
    setShowSearchPanel(false);
    setSearchData({
      deviceName: "",
      deviceFarmName: "",
      osVersion: "",
      searchKeyword: "",
      deviceFarmUse: [],
    });
  }, [selectedDeviceTabActionKey, projectId]);

  useEffect(() => {
    if (prevProjectId !== null && prevProjectId !== parseInt(projectId)) {
      dispatch({ type: DEVICE_LOADING, response: { isLoading: true } });
      dispatch({ type: DEVICE_LOADING_FOR_DEVICE_MANAGE_SCREEN, response: { isLoading: true } });
      setIsDeviceNotAvailable(false)
    }
  }, [projectId, prevProjectId, dispatch]);

  useEffect(() => {
    !buildDevice && dispatch(getDeviceFarmList({ projectId }));
  }, [projectId]);

  useEffect(() => {
    let farmIds = [];

    if (!isEmpty(deviceFarmList)) {
      deviceFarmList.forEach((data) => {
        farmIds.push(data.id);
        setFarmId(farmIds);
      });
    }

    if (!isEmpty(farmIds) && !fetchingDeviceList && showDeviceView) {
      searchRef.current = setTimeout(() => {
        if (!fetchingDeviceList) {
          dispatch(
            getDeviceByFarmId({
              farmId: farmIds,
              deviceName: searchData.deviceName || "",
              deviceFarmName: searchData.deviceFarmName || "",
              osVersion: searchData.osVersion || "",
              searchKeyword: searchKeyword ? searchKeyword : "",
              deviceFarmUse: searchData.deviceFarmUse || "",
            })
          );
        }
      }, 8000);
      return () => {
        clearTimeout(searchRef.current);
      };
    }
  }, [deviceFarmList, searchKeyword, searchData, fetchingDeviceList]);

  useEffect(() => {
    let farmIds = [];
    if (getSearchDeviceList === null) {
      if (!isEmpty(deviceFarmList)) {
        deviceFarmList.map((data) => {
          farmIds.push(data.id);
          setFarmId(farmIds);
        });
      }
      if (!isEmpty(farmIds) && showDeviceView) {
        dispatch(
          getDeviceByFarmId(
            {
              farmId: farmIds,
              deviceName: "",
              deviceFarmName: "",
              osVersion: "",
              searchKeyword: searchKeyword ? searchKeyword : "",
              deviceFarmUse: "",
            },
            "",
            "",
            true
          )
        );
      }
    }
  }, [deviceFarmList, showDeviceView, getSearchDeviceList]);

  // useEffect(() => {
  //   let deviceToken = localStorage.getItem("xpressDeviceToken");
  //   const decodeToken = jwtDecode(deviceToken);
  //   const deviceTokenExp = decodeToken?.exp - 300;
  //   const timeUntilNextCall = deviceTokenExp * 1000 - new Date().getTime();

  //   const intervalId = setInterval(() => {
  //     dispatch(generateDeviceToken());
  //   }, timeUntilNextCall);

  //   return () => clearInterval(intervalId);
  // }, [dispatch]);

  const deviceDetails = useMemo(
    () =>
      (getSearchDeviceList &&
        getSearchDeviceList.length > 0 &&
        getSearchDeviceList.filter((data) =>
          // !data.device.isDeviceOffline &&
          [DEVICE_FARM_USE.SCENARIO_RECORDING, DEVICE_FARM_USE.BOTH].includes(
            data.miscDetails?.farmDetails?.deviceFarmUse
          )
        )) ||
      [],
    [getSearchDeviceList]
  );

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      const result =
        !isSearch &&
        !deviceLoader &&
        deviceFarmList !== null &&
        (isEmpty(deviceFarmList) || isEmpty(getSearchDeviceList));
      setIsDeviceNotAvailable(result);
    }, 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isSearch, deviceLoader, deviceFarmList, getSearchDeviceList, projectId, isDeviceNotAvailable, dispatch]);

  const isDeviceNotFound = useMemo(
    () =>
      !isEmpty(getSearchDeviceList) &&
      getSearchDeviceList !== null &&
      getSearchDeviceList.every((data) => data.device.isDeviceOffline) &&
      !deviceLoader &&
      !isSearch,
    [getSearchDeviceList, deviceLoader, isSearch]
  );

  const emptyDeviceList = useMemo(
    () => isDeviceNotAvailable || isDeviceNotFound,
    [isDeviceNotAvailable, isDeviceNotFound]
  );

  const closeSearchPanel = () => {
    setShowSearchPanel(false);
  };

  const debounce = useMemo(
    () =>
      _.debounce((payload) => {
        dispatch(resetDeviceFarmList());
        dispatch(getDeviceByFarmId(payload, "", "", true, true));
      }, 1000),
    []
  );

  const debouncedChangeHandler = useCallback(
    (e) => {
      setSearchKeyword(e.target.value);
      debounce({
        farmId: farmId,
        deviceName: searchData.deviceName || "",
        deviceFarmName: searchData.deviceFarmName || "",
        osVersion: searchData.osVersion || "",
        searchKeyword: e.target.value,
        deviceFarmUse: searchData.deviceFarmUse || "",
      });
    },
    [farmId, searchData, debounce]
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "isDeviceLoaded") {
        const pendingData = JSON.parse(event.newValue);
        if (pendingData) {
          setLoadDeviceAPIPending(pendingData?.targetUniqueId);
        } else {
          setLoadDeviceAPIPending(null);
        }
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dispatch]);

  return (
    <div className={buildDevice && styles["deviceListDetails"]}>
      <div className={buildDevice ? styles["Search_Box_And_Filter"] : styles["searchDeviceForDevicePools"]}>
        {showDeviceView && (
          <div className={`${styles["device_Search_And_Filter"]} ${buildDevice && "width-full"}`}>
            <div className={styles["device_Search_Box"]}>
              <Input
                className={styles["search_Device_Input"]}
                onChange={debouncedChangeHandler}
                placeholder="Search By Name, Pool, OS Version"
                allowClear
                value={searchKeyword}
                prefix={<SvgLoader iconName={ICON_SEARCH} />}
              />
            </div>
            <div className={styles["device_Filter"]}>
              <Tooltip title="Advanced Search" color={TOOLTIP_BG_COLOR}>
                <div
                  className={styles["search_And_Device_Icon"]}
                  onClick={() => {
                    setShowSearchPanel(true);
                    setSearchKeyword("");
                  }}
                >
                  <SvgLoader iconName={SEARCH_DEVICE_ICON} iconColor={SILVER_GRAY} />
                </div>
              </Tooltip>
              <Tooltip title="Manage Devices" color={TOOLTIP_BG_COLOR}>
                <div
                  className={styles["search_And_Device_Icon"]}
                  onClick={() => history.push(`/${organizationId}/deviceDetailsList`)}
                >
                  <SvgLoader iconName={ADD_OR_REMOVE_DEVICE_ICON} iconColor={SILVER_GRAY} />
                </div>
              </Tooltip>
            </div>
            <SearchDevices
              showSearchPanel={showSearchPanel}
              closeSearchPanel={closeSearchPanel}
              farmId={farmId}
              buildDevice={buildDevice}
              searchKeyword={searchKeyword}
              setSearchData={setSearchData}
              searchData={searchData}
            />
          </div>
        )}
      </div>
      {showDeviceView && (
        <div className={`${styles["pageScroll"]} ${styles["bg_deviceList"]}`}>
          <div
            className={`${styles["deviceListCardsDetails"]} ${
              buildDevice ? styles["deviceList"] : styles["deviceListInCenter"]
            } ${buildDevice && styles["deviceCard"]}
            `}
          >
            {!isEmpty(deviceDetails) &&
              deviceDetails?.map((data, id) => {
                return (
                  <div key={id}>
                    <DeviceDetailsCardV2
                      typeForDeviceModals={typeForDeviceModals ? typeForDeviceModals : "deviceList"}
                      buildDevice
                      deviceData={data}
                      inUseButton={
                        buildDevice && (
                          <div className={styles["use_Text_And_Icon"]}>
                            {(isNullOrUndefined(data.deviceAcquireUserName) ||
                              (data.deviceAcquireUserName !== "" &&
                                data.deviceAcquireUserName === localStorage.getItem("requestedUser"))) &&
                            loadDeviceAPIPending === data?.device?.targetUniqueId ? (
                              <div className={styles["use_Device_Text"]}>Preparing Device...</div>
                            ) : !data.isDeviceAcquiredForRun ? (
                              <>
                                <div
                                  className={styles["use_Text_And_Icon"]}
                                  onClick={() => {
                                    handleStartSession(
                                      data.device.targetUniqueId,
                                      data.miscDetails.farmDetails.deviceFarmId
                                    );
                                    clearInterval(searchRef.current);
                                  }}
                                >
                                  <SvgLoader iconName={USE_DEVICE_ICON} iconColor={DEEP_BLUE_COLOR} />
                                  <div className={styles["use_Device_Text"]}>
                                    {data.deviceAcquireUserName !== "" &&
                                    data.deviceAcquireUserName === localStorage.getItem("requestedUser")
                                      ? "Use Here"
                                      : "Use"}
                                  </div>
                                </div>

                                {data.deviceAcquireUserName !== "" &&
                                  data.deviceAcquireUserName === localStorage.getItem("requestedUser") && (
                                    <>
                                      <VerticalLine className={styles["border"]} />
                                      <Tooltip title={"Release Device"} color={TOOLTIP_BG_COLOR}>
                                        <div className="releaseDeviceIconColor">
                                          {data?.device?.targetUniqueId === releaseDeviceTargetUniqueId ? (
                                            <Spin spinning={true} className="display__flex"></Spin>
                                          ) : (
                                            <SvgLoader
                                              iconName={RELEASE_ICON_NEW}
                                              handleClick={() =>
                                                dispatch(
                                                  releaseDevice(
                                                    data.device,
                                                    data.miscDetails?.deviceDetail?.autoServerDetail,
                                                    projectId,
                                                    farmId
                                                  )
                                                )
                                              }
                                            />
                                          )}
                                        </div>
                                      </Tooltip>
                                    </>
                                  )}
                              </>
                            ) : (
                              <>
                                {data?.deviceAcquiredForRunNumberDetails &&
                                !isEmpty(data?.deviceAcquiredForRunNumber) &&
                                isEmpty(data?.deviceAcquireBy) ? (
                                  <>
                                    <div className="lockIcon">
                                      <SvgLoader iconName={ICON_LOCK} />
                                    </div>
                                    <div className={`usedText ${styles["textInCenter"]}`}>In Use By :</div>
                                    <Tooltip
                                      title={
                                        data?.deviceAcquiredForRunNumberDetails?.startedBy?.length >= 17 &&
                                        data?.deviceAcquiredForRunNumberDetails?.startedBy
                                      }
                                      color={TOOLTIP_BG_COLOR}
                                    >
                                      <div
                                        className={`scheduledBy ${
                                          data?.deviceAcquiredForRunNumberDetails?.startedBy?.length >= 17 &&
                                          styles["ellipse"]
                                        }`}
                                      >
                                        {data?.deviceAcquiredForRunNumberDetails?.startedBy || "-"}
                                      </div>
                                    </Tooltip>
                                  </>
                                ) : (
                                  <>
                                    <SvgLoader iconName={DEVICE_IMG} width="1.19rem" height="2.06rem" />
                                    <div className="usedText">In Use</div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )
                      }
                    />
                  </div>
                );
              })}
          </div>

          {(isSearch || (deviceLoader && isEmpty(getSearchDeviceList))) && (
            <div className={styles["device_Loader"]}>
              <DeviceLoadingV2 />
            </div>
          )}
          <div className={emptyDeviceList && styles["empty-Records"]}>
            {isDeviceNotAvailable && !deviceLoader && (
              <EmptyRecordsV2 emptyRecordsImg={EMPTY_DEVICE_LIST_IMG} description="No Device Available" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceListV2;
