import { Button, Spin, Tabs } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { assignDeviceToFarm } from "../../../actions/ManageFarmAction";
import { getProjectsByUserIdAndOrgId } from "../../../actions/ProjectsAction";
import { getDeviceByFarmId } from "../../../actions/ServerListAction";
import { ICON_DRAG_AND_DROP } from "../../../CdnImagePath";
import {
  BOTH,
  BOTH_LABEL,
  DEVICE,
  DEVICE_POOL,
  EXECUTION_LABEL,
  POOL_MANAGEMENT,
  RECORDING_LABEL,
  SCENARIO_RECORDING,
  TEST_RUN_EXECUTION,
} from "../../../Constants";
import { isNotNullAndNotEmptyAndNotUndefined, showDeviceTabCheckBox } from "../../../Util";
import CreateFarm from "../CreateFarm";
import DeviceListV2 from "../../FarmManagementV2/DeviceListV2/DeviceListV2";
import DeviceDetailsCardV2 from "../../FarmManagementV2/DeviceDetailsCardV2/DeviceDetailsCardV2";
import styles from "./PoolManagement.module.scss";

const { TabPane } = Tabs;

export const Spinner = styled.div`
  height: auto;
  font-weight: 500;
  font-size: 16px;
  margin: 0 auto;
  left: 35%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDiv = styled.div`
  position: "fixed";
  height: 100%;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 20px;
  background: #dcdcf4;
  border-radius: 5px 0px 0px 5px;
  font-size: 13px;
`;

export const FarmCard = styled.div`
  height: auto;
  font-weight: bold;
  font-size: 18px;
  margin: 0 auto;
  text-align: center;
  left: 35%;
  padding: 10px;
`;

const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #444aea;
    color: #fff;
  }
`;

const getItemStyle = (isDragging, draggableStyle, deviceTab) => ({
  marginTop: 10,
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: "flex",
  width: "100%",
  height: isDraggingOver ? "245px" : "max-content",
  marginTop: 15,
  background: "white",
  border: "1px solid #C3C5D2",
  borderRadius: 5,
});

const PoolManagement = (props) => {
  const {
    deviceTab,
    deviceForExecution,
    selectedDevices,
    previousSelectedDevices,
    onChangeDeviceSelection,
    disableDeviceSelection,
    showDeviceView,
  } = props;

  let { projectId, orgId } = props;
  if (projectId === undefined && props.match.params) {
    projectId = props.match.params.projectId;
  }
  if (orgId === undefined && props.match.params) {
    orgId = props.match.params.organizationId;
  }

  const { deviceLoading, getSearchDeviceList, deviceLoader } = useSelector((state) => state.ManageFarmReducer);
  const { deviceFarmList } = useSelector((state) => state.ServerListReducer);
  const userDetails = useSelector((state) => state.HomeReducer);
  const [modalStatus, setModalStatus] = useState(false);
  const [showDevices, setShowDevices] = useState(DEVICE_POOL);
  const dispatch = useDispatch();
  const { selectedDeviceTabActionKey } = useSelector((state) => state.RecordingReducer);
  const { teamId } = useSelector((state) => state.ProjectsReducer);
  useEffect(() => {
    if (!_.isEmpty(deviceFarmList)) {
      let FarmId = [];
      deviceFarmList.map((data) => {
        FarmId.push(data.id);
      });
      if (
        !_.isEmpty(FarmId) &&
        selectedDeviceTabActionKey !== "RECORDING"
      ) {
        dispatch(
          getDeviceByFarmId({
            farmId: FarmId,
            deviceName: "",
            deviceFarmName: "",
            osVersion: "",
            searchKeyword: "",
            deviceFarmUse: "",
          })
        );
      }
    }
  }, [deviceFarmList, showDeviceView]);

  useEffect(() => {
    if (teamId && userDetails && userDetails.loggedInUserDetails && userDetails.loggedInUserDetails.userID) {
      dispatch(getProjectsByUserIdAndOrgId(orgId, userDetails.loggedInUserDetails.userID));
    }
  }, [dispatch, teamId, userDetails]);

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (source && source.droppableId && destination && destination.droppableId) {
      const sInd = +source.droppableId;
      const dInd = +destination.droppableId;
      if (sInd !== dInd && destination.droppableId !== "0") {
        let obj = {
          orgId: orgId,
          targetUniqueId: result.draggableId,
          farmId: +destination.droppableId,
          projectId: projectId,
        };
        dispatch(assignDeviceToFarm(obj));
      }
    }
  };

  const hasFarmDevices = (farmId) =>
    !(
      getSearchDeviceList &&
      getSearchDeviceList.some(
        (i) => i.miscDetails && i.miscDetails.farmDetails.deviceFarmId === farmId && !i.device.isDeviceOffline
      )
    ) && !deviceLoading;

  const modalClosed = () => {
    setModalStatus(false);
  };

  const deviceFarmUseTitle = (deviceFarmUse) => {
    if (deviceFarmUse === SCENARIO_RECORDING) {
      return RECORDING_LABEL;
    } else if (deviceFarmUse === TEST_RUN_EXECUTION) {
      return EXECUTION_LABEL;
    } else if (deviceFarmUse === BOTH) {
      return BOTH_LABEL;
    }
    return;
  };

  const countTotalDevice = (farmId) =>
    getSearchDeviceList &&
    getSearchDeviceList.filter(
      (i) => i.miscDetails && i.miscDetails.farmDetails.deviceFarmId === farmId && !i.device.isDeviceOffline
    ).length;
  return (
    <>
      <Spin spinning={deviceLoader}>
        <div className="deviceTab">
          <Tabs defaultActiveKey={DEVICE_POOL} activeKey={showDevices} onChange={(e) => setShowDevices(e)}>
            <TabPane tab={"Device Pools"} key={DEVICE_POOL}>
              <div className="justify-center">
                {!deviceTab && (
                  <div className="display-flex justify-space-between mb-5 mr-10">
                    <h1 className="ml-10"> Manage Device Pools</h1>
                    <ButtonContainer>
                      <Button className="float-right" type="primary" onClick={() => setModalStatus(true)}>
                        Create Device Pool
                      </Button>
                    </ButtonContainer>
                  </div>
                )}
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className={!deviceTab && "devicePools"}>
                    <div className="m-10">
                      {(!_.isEmpty(deviceFarmList) &&
                        deviceFarmList.map((el, ind) => (
                          <div key={ind}>
                            <Droppable key={ind} droppableId={deviceTab ? "" : el.id} direction="horizontal">
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  style={getListStyle(snapshot.isDraggingOver)}
                                  {...provided.droppableProps}
                                  className="droppable_style"
                                >
                                  <div>
                                    <StyledDiv>
                                      <div>
                                        <span className="labelDevice">Device Farm Name:</span>{" "}
                                        <span className="deviceData">{el.name}</span>
                                      </div>
                                      <div>
                                        <span className="labelDevice"> Usage:</span>{" "}
                                        <span className="deviceData">{deviceFarmUseTitle(el.deviceFarmUse)}</span>
                                      </div>
                                      <div>
                                        <span className="labelDevice">Total Devices:</span>{" "}
                                        <span className="deviceData">{countTotalDevice(el.id)}</span>
                                      </div>
                                    </StyledDiv>
                                  </div>
                                  <div className={styles.devicesList}>
                                  {hasFarmDevices(el.id) && !deviceTab && (
                                    <Spinner className="font-size-16 font-weight-400">
                                      {snapshot.isDraggingOver ? (
                                        <div className={styles.dragAndDropDeviceIcon}>
                                          <span className={styles.dragAndDropDeviceIconText}>
                                            Drag And Drop Devices Here
                                          </span>
                                        </div>
                                      ) : (
                                        <img src={ICON_DRAG_AND_DROP} alt="Drag And Drop Devices Here" />
                                      )}
                                    </Spinner>
                                  )}
                                    {getSearchDeviceList &&
                                      !deviceLoader &&
                                      !_.isEmpty(getSearchDeviceList) &&
                                      getSearchDeviceList.map((item, index) => {
                                        return (
                                          item.miscDetails &&
                                          (!item.device.isDeviceOffline ||
                                            (isNotNullAndNotEmptyAndNotUndefined(selectedDevices) &&
                                              selectedDevices.includes(item.device.targetUniqueId) &&
                                              item.device.isDeviceOffline) ||
                                            (isNotNullAndNotEmptyAndNotUndefined(previousSelectedDevices) &&
                                              previousSelectedDevices.includes(item.device.targetUniqueId) &&
                                              item.device.isDeviceOffline)) &&
                                          item.miscDetails.farmDetails.deviceFarmId === el.id && (
                                            <Draggable
                                              key={item.device.targetUniqueId}
                                              draggableId={deviceTab ? "" : item.device.targetUniqueId}
                                              index={index}
                                            >
                                              {(provided, snapshot) => (
                                                <div
                                                  span={18}
                                                  className={deviceTab && "cursor-default"}
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style,
                                                    deviceTab
                                                  )}
                                                >
                                                  <DeviceDetailsCardV2
                                                    typeForDeviceModals={POOL_MANAGEMENT}
                                                    deviceData={item}
                                                    farmList={el}
                                                    deviceTab={deviceTab}
                                                    showDeviceTabCheckBox={() =>
                                                      showDeviceTabCheckBox(item, selectedDevices)
                                                    }
                                                    selectedDevices={selectedDevices}
                                                    disableDeviceSelection={disableDeviceSelection}
                                                    onChangeDeviceSelection={onChangeDeviceSelection}
                                                  />
                                                </div>
                                              )}
                                            </Draggable>
                                          )
                                        );
                                      })}
                                  </div>
                                </div>
                              )}
                            </Droppable>
                          </div>
                        ))) ||
                        (!deviceLoading && !_.isEmpty(deviceFarmList) && (
                          <Spinner>
                            <Spin spinning={true}></Spin> &nbsp;&nbsp;&nbsp;
                            <span>Preparing Device Farm List</span>
                          </Spinner>
                        ))}
                    </div>
                  </div>
                </DragDropContext>
              </div>
            </TabPane>
            {!deviceTab && (
              <TabPane tab={"Device"} key={DEVICE}>
                <DeviceListV2
                  projectId={projectId}
                  hideDeviceViewButtonVisibility={false}
                  showDeviceView={true}
                  typeForDeviceModals="deviceTab"
                  organizationId={orgId}
                />
              </TabPane>
            )}
          </Tabs>
        </div>
        <CreateFarm modalClosed={modalClosed} modalStatus={modalStatus} projectId={projectId} />
      </Spin>
    </>
  );
};

export default PoolManagement;
