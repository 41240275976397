import _ from "lodash";
import React from "react";
import styles from "./TestStepVerificationRules.module.scss";
import TestStepVerifyElementProperty from "./TestStepVerifyElementProperty";
const TestStepVerifyElementPropertyGroup = (props) => {
  const { data, operator, elementText } = props;
  return (
    data &&
    data.length > 0 &&
    data.map((verification, key) => {
      return (
        <>
          {key > 0 && (
            <div key={"OPERATOR"} className={styles.operators}>
              {operator}
            </div>
          )}
          {verification.type === "GROUP" ? (
            <div className={styles.identificationRulesGroup} key={key}>
              <TestStepVerifyElementPropertyGroup
                data={verification.childTestStepVerifyElementProperties}
                operator={verification.operator}
                elementText={elementText}
              />
            </div>
          ) : (
            <TestStepVerifyElementProperty
              recStepVerifyElementProperty={verification}
              result={verification?.result}
              elementText={elementText}
            />
          )}
        </>
      );
    })
  );
};

export default TestStepVerifyElementPropertyGroup;
