import { Button, Collapse, Popover } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SYSTEM_KEYS } from "../../../../../../../Constants";
import { resetTestDataCustomValue } from "../../../../../../../actions/TestDataSet/TestDataSetRedirectAction";

import UsedForColumn from "./TestDataValuesColumns/UsedForColumn";
import CustomValueColumn from "./TestDataValuesColumns/CustomValueColumn";
import TableComponent from "../../../../../../CoreComponents/TableComponent/TableComponent";

import styles from "./AssignedTestDataListViewV2.module.scss"

const { Panel } = Collapse;

const AssignedTestDataListViewV2 = (props) => {
  const { testDataValues, title, isForView = false, refTestDataList } = props;
  const { testDataSetForEdit, testDataSetView } = useSelector((state) => state.TestDataSetReducer);
  const dispatch = useDispatch();

  const renderValue = (row) => {
    let refTestData = undefined;
    if (row.defaultRefTestDataSetAssocId !== 0) {
      refTestData = refTestDataList?.find(
        (refTestDataObj) => refTestDataObj.testDataSetAssocId === row.defaultRefTestDataSetAssocId
      );
    }
    return refTestData === undefined ? (
      <div className={styles.tableData}>
        {row.defaultValue && row.defaultValue !== "" && row.value !== row.defaultValue ? row.defaultValue : row.value}
      </div>
    ) : (
      <div className={styles.tableData}>
        <span className={styles.font_weight}>Referenced :</span>
        <span>{refTestData.stepPath}</span>
      </div>
    );
  };

  const prepareColumns = () => {
    let columns = [];
    if (testDataSetView !== SYSTEM_KEYS) {
      columns = [
        ...columns,
        {
          title: "Step No.",
          dataIndex: "sr",
          align: "center",
          width: "7%",
        },
        {
          title: "Step Path",
          dataIndex: "stepPath",
          width: "13%",
          align: "center",
        },
        {
          title: "Used For",
          dataIndex: "usedFor",
          width: "15%",
          align: "center",
        },
      ];
    }
    columns = [
      ...columns,
      {
        title: "Key",
        dataIndex: "testDataSetKey",
        align: "center",
        width: "15%",
      },
      {
        title: (
          <div className={styles.tableTitle}>
            <div>Value</div>
            <div className={styles.tableSubTitle}>(From Execution Profile)</div>
          </div>
        ),
        dataIndex: "value",
        width: "20%",
        align: "center",
      },
      {
        title: (
          <div className={styles.tableTitle}>
            <div>Custom Value</div>
            <div className={styles.tableSubTitle}>(For this Profile)</div>
          </div>
        ),
        dataIndex: "customValue",
        width: "20%",
        align: "center",
      },
    ];
    if (!isForView) {
      columns = [
        ...columns,
        {
          title: "Actions",
          dataIndex: "actions",
          align: "center",
          width: "10%",
        },
      ];
    }
    return columns;
  };

  const testDataSetListView = testDataValues?.map((row) => ({
    sr: row.isForDefaultRefTestDataSetAssoc === 0 && <span className={styles.tableData}>{row.stepOrderPath}</span>,
    stepPath: (
      <Popover
        content={
          <span>
            {row.stepOrderPath} {row.stepPath}
          </span>
        }
        title="Step Path"
      >
        <div> {row.stepName}</div>
      </Popover>
    ),
    usedFor: <UsedForColumn testDataValue={row} />,
    testDataSetKey:
      row.isForDefaultRefTestDataSetAssoc === 0 ? (
        <div className={styles.tableData}>
          {row.name}
          <br />
          {"[Scope:" + row.scope + "]"}
        </div>
      ) : (
        ""
      ),
    value: renderValue(row),
    customValue: <CustomValueColumn testDataValue={row} refTestDataList={refTestDataList} isForView={isForView} />,
    actions: row.isForDefaultRefTestDataSetAssoc === 0 && (
      <div className={styles.actions}>
        <Button type="text" className="resetButton" onClick={() => dispatch(resetTestDataCustomValue(row))}>
          Reset
        </Button>
      </div>
    ),
  }));

  return (
    <div className={styles.details}>
      {testDataSetForEdit && (
        <Collapse className={styles.collapse} defaultActiveKey={[]}>
          <Panel collapsible={true} header={title} key={title}>
            <TableComponent data={testDataSetListView} columns={prepareColumns()} testDataSetModal={true} />
          </Panel>
        </Collapse>
      )}
    </div>
  );
};

export default AssignedTestDataListViewV2;