import _ from "lodash";
import React from "react";
import AssignedTestDataListView from "./AssignedTestDataListView/AssignedTestDataListView";
import { Empty } from "antd";
const EditTestBlockTestDataSet = (props) => {
  const { title, testDataSetForEdit, isForView, refTestDataList } = props;

  return (
    <>
      {
        (testDataSetForEdit?.testDataValues && !_.isEmpty(testDataSetForEdit?.testDataValues)) &&
          <AssignedTestDataListView
            title={title
              + " [Steps using Test Data: " +  testDataSetForEdit?.stepCount
              + " Unique Test Data Keys: " + testDataSetForEdit?.uniqueTestDataCount + "]"}
            testDataValues={testDataSetForEdit?.testDataValues}
            isForView={isForView}
            refTestDataList={refTestDataList ? refTestDataList : _.cloneDeep(testDataSetForEdit?.testDataValues)}
          />
      }
    </>
  );
};

export default EditTestBlockTestDataSet;
