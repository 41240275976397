import { Card, Avatar } from "antd";
import _ from "lodash";
import { default as React, useState } from "react";
import { faCog, faPlus } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { Typography } from "antd";
import history from "../../history";
import InviteMember from "./InviteMember";
import { useDispatch } from "react-redux";
import { resetDeviceFarmList, resetDeviceList } from "../../actions/ServerListAction";
import { DEVICE_LOADED, TEST_SCENARIOS } from "../../Constants";
import { Label } from "../CoreComponents/StyledComponent/StyledComponents";
import { getRandomColor, showPlatformTag } from "../../Util";
import { INVITE_USER_ICON, SEND_ICON } from "../../CdnImagePath";
import TableComponent from "../CoreComponents/TableComponent/TableComponent";

const { Title } = Typography;

library.add(faCog);
library.add(faPlus);

export const ProjectRow = styled(Card)`
  background: white;
  border: none;
  overflow: hidden;
  height: 120px;
  text-align: center;
  max-width: 15vw;
  min-width: 10vw;
  min-height: 10vh;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  border: 0;
  margin-bottom: 1.5rem;
  border-radius: 10px;

  font-size: 30px;
  margin-top: 10px;
  :hover {
    box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.15);
  }
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);

  @media (max-width: 550px) {
    max-height: 180px;
    max-width: 250px;
    font-size: 18px;
  }
  @media (max-width: 320px) {
    max-height: 150px;
    font-size: 14px;
  }

  .ant-card-body {
    padding: 0px;
  }

  cursor: pointer;
`;

export const ProjectIcons = styled(FontAwesomeIcon)`
  position: absolute;
  bottom: 10px;
  right: 8px !important;
  height: 18px !important;
  width: 18px !important;
  opacity: 0.2;
  :hover {
    opacity: 0.8;
  }
`;

export const titleName = styled(Title)`
  font-size: 14px !important;
  display: block;
`;

export default (props) => {
  const dispatch = useDispatch();
  const { data, orgId, teamNameOfProject } = props;
  const [modalStatus, setModalStatus] = useState(false);
  const [projectId, setProjectId] = useState(null);

  const goInsideProject = (projectId, orgId) => {
    localStorage.setItem("projectId", projectId);
    const url = `/${orgId}/project/${projectId}/${TEST_SCENARIOS}`;
    history.push(url);
    dispatch(resetDeviceFarmList());
    dispatch(resetDeviceList());
    dispatch({ type: DEVICE_LOADED, isLoaded: false });
  };

  const modalClosed = () => {
    setModalStatus(false);
  };

  const columns = [
    {
      title: "Project",
      dataIndex: "project",
    },
    {
      title: "Operating System",
      dataIndex: "os",
    },
    {
      title: "Team Name",
      dataIndex: "teamName",
    },
    {
      title: "Members",
      dataIndex: "members",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const projectData =
    !_.isEmpty(data) &&
    data?.map((row) => ({
      key: row?.id,
      project: (
        <div>
          <div className="display-flex">
            <h3 className="font-color-darkBlue">{row?.projectName}</h3>
          </div>
          {row?.appName && (
          <div>
            <Label>App Name: </Label> {row?.appName}
          </div>
          )}

          {row?.buildType && (
            <div>
              <Label>Type: </Label> {row?.buildType}
            </div>
          )}
        </div>
      ),
      os: showPlatformTag(row?.platform),
      members: (
        <div className="avatarCard">
          <div className="display-flex justify-space-between align-items-center">
            <div>
              <Avatar.Group maxCount={4}>
                {row &&
                  row?.projectMembers?.map((i) => {
                    return (
                      <Avatar
                        className="avatar-font"
                        style={{
                          backgroundColor: getRandomColor(),
                        }}
                      >
                        {i?.name
                          ?.split(" ")
                          ?.map((i) => i[0])
                          ?.join("")}
                      </Avatar>
                    );
                  })}
              </Avatar.Group>
            </div>
          </div>
        </div>
      ),
      teamName: row?.teamName ? row?.teamName : teamNameOfProject,
      action: (
        <div>
          <img
            src={INVITE_USER_ICON}
            alt="user"
            className="cursor-pointer"
            onClick={(event) => {
              event.stopPropagation();
              setModalStatus(true);
              setProjectId(row?.projectId);
            }}
          />
          <img
            src={SEND_ICON}
            alt="redirect"
            className="ml-10 cursor-pointer"
            onClick={() => goInsideProject(row?.projectId, orgId)}
          />
        </div>
      ),
    }));

  return (
    <>
      <TableComponent columns={columns} data={projectData} />
      <InviteMember modalClosed={modalClosed} modalStatus={modalStatus} projectId={projectId} />
    </>
  );
};
