import { async } from "../api/automation";
import { FETCH_EXECUTION_REPORTS, LOADING_REPORTS, RECORD_PER_PAGE } from "../Constants";

export function fetchExecutionReports(orgId, startDate, endDate, pageNumber) {
  return async (dispatch) => {
    dispatch({ type: LOADING_REPORTS, isLoading: true });
    let data = {
      orgId: orgId,
      startDate: startDate ? startDate : "",
      endDate: endDate ? endDate : "",
      recordsPerPage: RECORD_PER_PAGE,
      pageNumber: pageNumber || 1,
    };
    return async("project/getUsageReportByOrgId", "GET", data).then((response) => {
      dispatch({ type: LOADING_REPORTS, isLoading: false });
      dispatch({ type: FETCH_EXECUTION_REPORTS, response });
    });
  };
}
