import { Col, Collapse, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { highlightResult } from "../../../../Util";
import { TestPlanResultDetailsScenario } from "../TestPlanResultDetails";
import ScenarioViewDeviceCard from "./ScenarioViewDeviceCard";

const { Panel } = Collapse;

const TestPlanResultScenarioView = ({ isTestBlock, runNumber }) => {
  const { testPlanResultDetails, runResultDetails } = useSelector((state) => state.TestPlanReducer);

  return (
    <Col span={24} className="mb-50">
      <TestPlanResultDetailsScenario>
        {testPlanResultDetails != null &&
          testPlanResultDetails[0] &&
          testPlanResultDetails[0]?.scenarios?.map((v, k) => {
            return (
              <>
                <Collapse className="border-none" expandIconPosition="right" key={k}>
                  <Panel
                    className="mt-10"
                    showArrow={true}
                    header={
                      <Row className="display-flex">
                        <Col span={1}>{k + 1}</Col>
                        <Col span={20}>
                          <div>
                            <div>
                              <span className="font-weight-700">Scenario: </span>
                              <span className="font-weight-500">{v?.name}</span>
                            </div>
                            <div>
                              <span className="font-weight-700">Total Devices to Execute on: </span>
                              {highlightResult(v?.resultCount)}
                            </div>
                            <div>
                              <span className="font-weight-700">Total Locales to Execute on: </span>
                              <span className="font-weight-500">{v?.totalLocale?.replaceAll(",", ",  ") || "N.A"}</span>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    }
                  >
                    <ScenarioViewDeviceCard
                      deviceDetails={v?.devices}
                      key={k}
                      runResultDetails={runResultDetails}
                      isTestBlock={isTestBlock}
                      runNumber={runNumber}
                    />
                  </Panel>
                </Collapse>
              </>
            );
          })}
      </TestPlanResultDetailsScenario>
    </Col>
  );
};

export default TestPlanResultScenarioView;
