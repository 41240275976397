import { FETCH_EXECUTION_REPORTS, LOADING_REPORTS } from "../Constants";

let defaultState = {
  executionReportsData: [],
  currentPage: 1,
  totalPage: 0,
  totalPageItems: 0,
  totalItems: 0,
  loading: false,
  totalExecutionHours: 0
};

export default function ExecutionReportReducer(state = defaultState, action) {
  switch (action.type) {
    case FETCH_EXECUTION_REPORTS:
      let response = action?.response;
      return {
        ...state,
        executionReportsData: response.data,
        currentPage: response?.currentPage,
        totalPage: response?.totalCount,
        totalPageItems: response?.pageItems,
        totalItems: response?.totalItems,
        totalExecutionHours: response?.totalExecutionHours
      };
    case LOADING_REPORTS:
      return {
        ...state,
        loading: action.isLoading,
      };
    default:
      return state;
  }
}
