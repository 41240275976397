import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Checkbox, Col, Popover, Spin, Tooltip } from "antd";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { saveTestPlanRights } from "../../../../actions/Recording";
import { setTestDataSetAssigneeDetail } from "../../../../actions/TestDataSet/TestDataSetCommonAction";
import { fetchTestDataSetByProfileId } from "../../../../actions/TestDataSet/TestDataSetRedirectAction";
import { getTestDataSetByTestPlanId, showTestDataSetSelectionModal } from "../../../../actions/TestDataSetAction";
import { changeTestJobData } from "../../../../actions/TestJobActions";
import {
  changeTestPlanDetails,
  changeTestPlanTab,
  deleteTestPlanDetail,
  getRunInsight,
  getTestPlanDetailsList,
} from "../../../../actions/TestPlanAction";
import { ICON_BUILD_TEST_DATA, ICON_EDIT, ICON_TRENDS } from "../../../../CdnImagePath";
import {
  ACTION_NOT_VIEW,
  ACTION_VIEW,
  All_RECORDS,
  BUILD_TEST_PLAN,
  CUSTOM,
  defaultDateTimeFormat,
  MY_PLANS,
  OWNED,
  PRIVATE,
  PUBLIC,
  RECORD_10_PER_PAGE,
  SHARED,
  SHARED_WITH_ME,
  TEST_PLAN,
  TOOLTIP_BG_COLOR,
} from "../../../../Constants";
import {
  deleteModal,
  displayUTCDateFormatWithToolTip,
  getAdmins,
  getPermissionType,
  isNullOrUndefined,
  isSelectedAllIds,
  removeSelectedIds,
  showPermissionTypeIcon,
} from "../../../../Util";
import SelectAllBox from "../../../CommonComponents/SelectAllBox";
import ActionComponent from "../../../CoreComponents/ActionComponent/ActionComponent";
import PaginationComponent from "../../../CoreComponents/PaginationComponent/PaginationComponent";
import PermissionDrawer from "../../../CoreComponents/PermissionDrawer/PermissionDrawer";
import CommonSearch from "../../../CoreComponents/SearchComponent/CommonSearch";
import { Label } from "../../../CoreComponents/StyledComponent/StyledComponents";
import TableComponent from "../../../CoreComponents/TableComponent/TableComponent";
import AssignTestDataSetModal from "../../../ExecutionManager/JobDesigner/BuildJob/JobCriteriaOptions/AssignTestDataSetModal/AssignTestDataSetModal";
import PermissionFilter from "../../../PermissionFilter/PermissionFilter";
import { updateStepType } from "../../../../actions/RecTestStep/RecTestStepRedirectAction";
import { BUILD_TEST_DATA_SET } from "../../../../Constants/TooltipConstants";
import styles from "./TestPlanDetails.module.scss";

export const StyledTestPlanDetailsCol = styled.div`
  height: auto;
  width: 100%;
`;

export const TestPlanDataRow = styled.div`
  margin: 1%;
  background: white;
  padding: 20px;
  border: 0px;
  margin-bottom: 0px;
  position: relative;
  min-width: 0px;
  overflow-wrap: break-word;
  background-clip: border-box;
  border-radius: 10px;
  display: flex;
  :hover {
    background-color: ${(props) => (props.selected ? "#338fcc" : "#e8e8e885")};
  }
`;

export const ActionsButtonCol = styled(Col)`
  display: flex;
  font-size: 18px;
  justify-content: flex-end;
`;

export const StyledButton = styled.div`
  color: #000000a6;
  margin-left: 5px;
  margin-right: 5px;
  cursor: pointer;
`;

export const StyleAddNewIcon = styled.div`
  color: black;
  font-size: 25px;
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
`;

export default (props) => {
  const dispatch = useDispatch();
  const { testPlanDetailData, currentTestPlanPage, totalTestPlanPage, testPlanLoading, pageItems, totalPage } =
    useSelector((state) => state.TestPlanReducer);

  const { projectId, testPlanTab, testJobDetail } = props;
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedTestPlan, setSelectedTestPlan] = useState(undefined);
  const [selectAllTestPlan, setSelectAllTestPlan] = useState([]);
  const [openPermissionDrawer, setOpenPermissionDrawer] = useState(false);
  const [permissionTestPlan, setPermissionTestPlan] = useState(null);
  const [rights, setRights] = useState({ id: OWNED, value: MY_PLANS });
  const [activeFilter, setActiveFilter] = useState(rights ? rights : { id: OWNED, value: MY_PLANS });
  const [members, setMembers] = useState([]);
  const [previousPermission, setPreviousPermission] = useState([]);
  const { loggedInUserDetails } = useSelector((state) => state.HomeReducer);
  useEffect(() => {
    dispatch(updateStepType(TEST_PLAN));
  }, []);

  const filterPermission = [
    { id: OWNED, value: MY_PLANS },
    { id: SHARED, value: SHARED_WITH_ME },
    { id: "", value: All_RECORDS },
  ];

  useEffect(() => {
    if (testJobDetail?.testPlan?.id === undefined || testJobDetail?.testPlan?.id !== 0) {
      dispatch(
        getTestPlanDetailsList({
          pageNumber: 1,
          recordPerPage: RECORD_10_PER_PAGE,
          searchKeyword: searchKeyword,
          projectId: projectId,
          selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
          rights: !testJobDetail && rights?.id,
        })
      );
    }
    setSelectAllTestPlan([]);
  }, [projectId]);

  useEffect(() => {
    if (permissionTestPlan?.tesPlanRights?.data) {
      setMembers([...permissionTestPlan?.tesPlanRights?.data]);
    }
  }, [permissionTestPlan]);

  // reset privacy
  const setPermissionData = () => {
    if (permissionTestPlan?.isPrivate && !_.isEmpty(permissionTestPlan?.tesPlanRights?.data)) {
      setPreviousPermission(permissionTestPlan?.tesPlanRights?.data);
      setMembers([...permissionTestPlan?.tesPlanRights?.data]);
    } else {
      setMembers([]);
      setPreviousPermission([]);
    }
  };

  // save privacy
  const savePermission = (Value, testPlanData, customData) => {
    const result =
      customData &&
      customData?.map((i) => ({
        userId: i?.userId,
        isEdit: i?.isEdit,
        isDelete: i?.isDelete,
        isView: i?.isView,
        isAdmin: i?.isAdmin,
        shouldAddOrRemove: i?.shouldAddOrRemove,
      }));
    let obj = {
      testPlan: { id: testPlanData?.id },
      testPlanName: testPlanData?.name,
      isPrivate: Value === PRIVATE || Value === CUSTOM ? 1 : 0,
      listOfMembers: Value === CUSTOM && !_.isEmpty(result) ? result : [],
    };
    dispatch(saveTestPlanRights(obj, projectId, rights?.id));
    setOpenPermissionDrawer(false);
    setMembers([]);
  };

  const callPaginationAPI = (pageNumber) => {
    dispatch(
      getTestPlanDetailsList({
        pageNumber: pageNumber,
        recordPerPage: RECORD_10_PER_PAGE,
        projectId: projectId,
        searchKeyword: isNullOrUndefined(searchKeyword) ? "" : searchKeyword,
        rights: !testJobDetail && rights?.id,
        selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
      })
    );
  };

  const handleAction = (row) => {
    let arr = [];
    if (row.allowDelete === ACTION_VIEW) {
      arr.push({ title: "Delete", name: "delete" });
    }
    if (loggedInUserDetails?.userEmail === row?.createdBy || row?.allowAdmin === ACTION_VIEW) {
      arr.push({
        title: "Permissions",
        name: "permissions",
        children: [
          ((row?.isPrivate !== 1 && loggedInUserDetails?.userEmail === row?.createdBy) ||
            (row?.isPrivate === 1 &&
              !_.isEmpty(row?.tesPlanRights?.data) &&
              loggedInUserDetails?.userEmail === row?.createdBy)) && {
            title: "Make Private",
            name: PRIVATE,
          },
          row?.isPrivate !== 0 && {
            title: "Make Public",
            name: PUBLIC,
          },
          {
            title: "Custom",
            name: CUSTOM,
          },
        ],
      });
    }
    return arr;
  };

  const handleActionChange = (actionName, testPlanData) => {
    setPermissionTestPlan(() => ({ ...testPlanData }));
    switch (actionName) {
      case "delete":
        deleteConfirm(testPlanData?.id, testPlanData?.name);
        break;
      case PRIVATE:
        savePermission(PRIVATE, testPlanData);
        break;
      case PUBLIC:
        savePermission(PUBLIC, testPlanData);
        break;
      case CUSTOM:
        setOpenPermissionDrawer(true);
        break;
      default:
        break;
    }
  };

  const testPlanSelectionRadioType = (row) => {
    return (
      <div>
        <span className={styles.marginRight}>
          <input
            className={styles.radioButtons}
            type="radio"
            name="radioGroup"
            value={row?.id}
            checked={
              testJobDetail?.testPlan && testJobDetail?.testPlan?.id ? testJobDetail?.testPlan?.id === row?.id : ""
            }
            onChange={(e) => {
              e.stopPropagation();
              dispatch(
                changeTestJobData({
                  ...testJobDetail,
                  testPlan: { id: row?.id, name: row?.name },
                  testDataSet: { name: "Default" },
                })
              );
            }}
          />
        </span>
      </div>
    );
  };

  const testPlanSelectionCheckboxType = (row) => {
    if (row.allowDelete === ACTION_VIEW) {
      return (
        <div>
          <Checkbox
            className={styles.marginRight}
            checked={selectAllTestPlan && selectAllTestPlan?.includes(row?.id)}
            onChange={(e) => {
              e.target.checked
                ? setSelectAllTestPlan([...selectAllTestPlan, row.id])
                : setSelectAllTestPlan(selectAllTestPlan && selectAllTestPlan?.filter((i) => i !== row?.id));
            }}
          />
        </div>
      );
    }
  };

  const showListOfJobs = (jobs) => {
    if (jobs?.length > 1) {
      return (
        <div className="display__flex">
          <div className={styles.ellipse}>
            <span className={styles.data}>{jobs[0].name}</span>
          </div>
          <Popover
            content={jobs?.slice(1)?.map((i, index) => (
              <div className={` ${styles.popoverContents} ${jobs?.length - 2 !== index && styles.borderBottom}`}>
                <span className={styles.jobsInPopover}>{i?.name}</span>
                <div className={styles.jobIdOrName}>
                  <span>Job : </span> <span className={styles.jobId}>{i?.id}</span>
                </div>
              </div>
            ))}
            title="Jobs"
            placement="bottom"
          >
            <span className={styles.jobsDetailsInPopover}>
              {jobs?.slice(1).length === 1 ? `+${jobs?.slice(1).length} other` : `+${jobs?.slice(1).length} others`}
            </span>
          </Popover>
        </div>
      );
    } else {
      return <span className={styles.data}>{!_.isEmpty(jobs) ? jobs && jobs[0]?.name : "NA"}</span>;
    }
  };

  const deleteConfirm = (id, name) => {
    deleteModal(
      name
        ? `Do you want to delete "${name}"?`
        : `Do you want to delete the selected ${selectAllTestPlan?.length > 1 ? "Test Plans" : "Test Plan"}?`,
      () => {
        dispatch(
          deleteTestPlanDetail(
            { testPlanId: id ? [id] : selectAllTestPlan, isDelete: 1 },
            setSelectAllTestPlan,
            projectId,
            rights?.id,
            searchKeyword
          )
        );
      },
      "Note: Test Jobs associated with this Test Plan will also be deleted!!"
    );
  };
  const handleFilters = (e) => {
    setActiveFilter(e);
    setRights(e);
    dispatch(
      getTestPlanDetailsList({
        pageNumber: currentTestPlanPage,
        recordPerPage: RECORD_10_PER_PAGE,
        searchKeyword: searchKeyword,
        projectId: props.projectId,
        rights: e?.id,
        selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
      })
    );
  };

  const showCheckBoxInTableHeader =
    _.isEmpty(selectAllTestPlan) &&
    !testPlanTab &&
    !_.isEmpty(testPlanDetailData) &&
    testPlanDetailData?.some((i) => i?.allowDelete === ACTION_VIEW);

  const columns = [
    {
      title: (
        <div className={!showCheckBoxInTableHeader && "table_Details"}>
          {showCheckBoxInTableHeader && (
            <span>
              <Checkbox
                className={styles.marginRight}
                onChange={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestPlan([
                      ...selectAllTestPlan,
                      ...(
                        testPlanDetailData &&
                        testPlanDetailData?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestPlan.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestPlan(removeSelectedIds(selectAllTestPlan, testPlanDetailData));
                  }
                }}
              />
            </span>
          )}
          Test Plan Info
        </div>
      ),
      dataIndex: "testPlanInfo",
    },
    {
      title: "Scenarios & Steps",
      dataIndex: "ScenariosAndSteps",
    },
    {
      title: "Job Running This Plan",
      dataIndex: "jobRunningThisPlan",
    },
    {
      title: "Actions",
      dataIndex: "action",
      align: "center",
    },
  ];

  const testPlanDetails =
    testPlanDetailData &&
    testPlanDetailData?.map((row) => ({
      key: row?.id,
      testPlanInfo: (
        <div
          className={`display__flex ${styles.detailsOfTable} ${!testPlanTab && row.allowDelete === ACTION_NOT_VIEW && "table_Details"}`}
        >
          <div>
            {testPlanTab && testPlanSelectionRadioType(row)}
            {!testPlanTab && testPlanSelectionCheckboxType(row)}
          </div>

          <div>
            <div className={styles.tooltipOrStepName}>
              <span className={styles.permissionTooltip}>
                <Tooltip title={getPermissionType(row?.isPrivate, row?.tesPlanRights?.data)} color={TOOLTIP_BG_COLOR}>
                  <FontAwesomeIcon
                    icon={showPermissionTypeIcon(getPermissionType(row?.isPrivate, row?.tesPlanRights?.data))}
                  />
                </Tooltip>
              </span>
              <span className={styles.testPlanName}>{row?.name}</span>
            </div>
            <div className={styles.admins}>
              <span className={styles.label}>Admins: </span>{" "}
              <div className={styles.adminsDetails}>
                {row?.tesPlanRights &&
                  getAdmins([
                    { name: row?.createdByName },
                    ...row?.tesPlanRights?.data?.filter(
                      (i) => i?.isAdmin && i?.shouldAddOrRemove === 1 && i?.name !== row?.createdByName
                    ),
                  ])}
              </div>
            </div>
            <div>
              <span className={styles.label}>Updated By: </span>{" "}
              <span className={styles.textColor}>
                {row?.updatedByName || "NA"} on{" "}
                {displayUTCDateFormatWithToolTip(row?.modifiedOn, defaultDateTimeFormat)}
              </span>
            </div>
          </div>
          {row?.currentAndTotalTestDataProfile && (
            <div>
              <span className={styles.label}>Current TestData Profile & Total TestData Keys in Profile:</span>
            </div>
          )}
        </div>
      ),
      jobRunningThisPlan: (
        <div className={styles.detailsOfTable}>
          <div className="display__flex">
            <div>
              <span className={styles.label}>Total: </span> {row?.listOfJobs?.length}
              <div className={styles.textColor}>{row?.listOfJobs && showListOfJobs(row?.listOfJobs)}</div>
            </div>
          </div>
          <div className={styles.headerOfExecutionDetails}>Most Recent Execution</div>
          <div>
            <span className={styles.label}>Job Name: </span>
            {row?.lastExecutedJob !== null ? <span className={styles.textColor}>{row?.lastExecutedJob}</span> : "NA"}
          </div>
          <div>
            <span className={styles.label}>Executed On: </span>
            <span className={styles.textColor}>
              {row?.lastExecuted != null
                ? displayUTCDateFormatWithToolTip(row?.lastExecuted, defaultDateTimeFormat)
                : "NA"}
            </span>
          </div>
        </div>
      ),
      ScenariosAndSteps: (
        <div className={styles.detailsOfTable}>
          <div>
            <span className={styles.label}>Scenarios: </span>{" "}
            <span className={styles.textColor}>{row?.testPlanCountDetails?.totalScenario}</span>
          </div>
          <div>
            <span className={styles.label}>Steps: </span>{" "}
            <span className={styles.textColor}>{row?.testPlanCountDetails?.totalSteps}</span>
          </div>
          <div>
            <span className={styles.label}>Screenshots: </span>{" "}
            <span className={styles.textColor}>{row?.testPlanCountDetails?.captureScreenShotCounts}</span>
          </div>
        </div>
      ),
      action: (
        <>
          {testPlanTab ? (
            <Button
              type="primary"
              className={styles.hightOfButtons}
              disabled={testJobDetail.testPlan ? testJobDetail?.testPlan?.id !== row?.id : false}
              onClick={(e) => {
                e.stopPropagation();
                setSelectedTestPlan(row);
                dispatch(fetchTestDataSetByProfileId(testJobDetail?.testPlan?.id, "", 1, false));
                dispatch(showTestDataSetSelectionModal(row));
              }}
            >
              Test Data
            </Button>
          ) : (
            <div className={styles.actionIcon}>
              {row.allowEdit === ACTION_VIEW && (
                <Tooltip title="Edit" color={TOOLTIP_BG_COLOR}>
                  <img
                    src={ICON_EDIT}
                    alt="Edit"
                    className="cursor__pointer"
                    onClick={() => {
                      dispatch(changeTestPlanDetails(row));
                      dispatch(changeTestPlanTab(BUILD_TEST_PLAN));
                    }}
                  />
                </Tooltip>
              )}
              <Tooltip title={BUILD_TEST_DATA_SET} color={TOOLTIP_BG_COLOR}>
                <img
                  src={ICON_BUILD_TEST_DATA}
                  alt="BuildDataIcon"
                  className={styles.icons}
                  onClick={() => {
                    dispatch(setTestDataSetAssigneeDetail(row));
                    dispatch(changeTestPlanDetails(row));
                  }}
                />
              </Tooltip>
              <Tooltip title="Test Plan Trends" color={TOOLTIP_BG_COLOR}>
                <img
                  src={ICON_TRENDS}
                  alt="TreadsIcon"
                  className={styles.icons}
                  onClick={() => {
                    dispatch(getRunInsight(row, moment(new Date()).subtract(2, "days"), moment(new Date())));
                  }}
                />
              </Tooltip>
              {(row.allowDelete === ACTION_VIEW || row.allowAdmin === ACTION_VIEW) && (
                <ActionComponent actions={handleAction(row)} data={row} handleActionChange={handleActionChange} />
              )}
            </div>
          )}
        </>
      ),
    }));

  return (
    <>
      <Spin spinning={testPlanLoading} tip="Loading" className={styles.spinnerCenter}>
        <div className={!testPlanTab ? styles.headerInJobs : styles.headerInTestPlans}>
          <span className={styles.headerTitle}>Test Plans</span>
          {!testPlanTab && (
            <div className={styles.container}>
              <Button
                size="large"
                type="primary"
                className={`${styles.marginRight} ${styles.button}`}
                onClick={() => {
                  dispatch(changeTestPlanTab(BUILD_TEST_PLAN));
                  setRights({ id: OWNED, value: MY_PLANS });
                  setActiveFilter({ id: OWNED, value: MY_PLANS });
                }}
              >
                Build Test Plan
              </Button>
            </div>
          )}
        </div>
        <div className={!testPlanTab && styles.pageScroll}>
          <div className={styles.testPlanDetails}>
            <div className="display__flex">
              <div>
                {!testPlanTab && (
                  <div className="display__flex">
                    <PermissionFilter
                      permissionValue={filterPermission}
                      active={activeFilter}
                      handleFilters={handleFilters}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="display__flex">
              <div>
                <CommonSearch
                  searchDetails={(searchWord) =>
                    dispatch(
                      getTestPlanDetailsList({
                        pageNumber: currentTestPlanPage,
                        recordPerPage: RECORD_10_PER_PAGE,
                        searchKeyword: searchWord,
                        projectId: props.projectId,
                        rights: rights?.id,
                        selectedTestPlanId: testJobDetail?.testPlan?.id || 0,
                      })
                    )
                  }
                  placeHolder="Search By Test Plan Name"
                  searchKeyword={searchKeyword}
                  setSearchKeyword={setSearchKeyword}
                />
              </div>
            </div>
          </div>

          {!testPlanTab && !_.isEmpty(selectAllTestPlan) && (
            <div className={styles.selectAllBox}>
              <SelectAllBox
                isChecked={isSelectedAllIds(
                  selectAllTestPlan,
                  testPlanDetailData?.filter((i) => i.allowDelete === ACTION_VIEW)
                )}
                selectAllIds={selectAllTestPlan}
                handleChangeCheck={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestPlan([
                      ...selectAllTestPlan,
                      ...(
                        testPlanDetailData &&
                        testPlanDetailData?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestPlan.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestPlan(removeSelectedIds(selectAllTestPlan, testPlanDetailData));
                  }
                }}
                deleteConfirm={deleteConfirm}
                isDisable={_.isEmpty(testPlanDetailData)}
              />
            </div>
          )}
          <div className={styles.tableComponents}>
            <TableComponent columns={columns} data={testPlanDetails} selected={selectAllTestPlan} />
          </div>
          <div className={`${styles.pullRight} ${testPlanTab && styles.pagination}`}>
            {!_.isEmpty(testPlanDetailData) && (
              <PaginationComponent
                currentPageItems={pageItems}
                totalItems={totalTestPlanPage}
                currentPage={currentTestPlanPage}
                totalPage={totalPage}
                callPaginationAPI={callPaginationAPI}
              />
            )}
          </div>
          {window.pageYOffset > 300 && !testPlanTab && (
            <div className={`${styles.selectAllBox} ${styles.marginBottom}`}>
              <SelectAllBox
                isChecked={isSelectedAllIds(
                  selectAllTestPlan,
                  testPlanDetailData?.filter((i) => i.allowDelete === ACTION_VIEW)
                )}
                selectAllIds={selectAllTestPlan}
                handleChangeCheck={(isChecked) => {
                  if (isChecked) {
                    setSelectAllTestPlan([
                      ...selectAllTestPlan,
                      ...(
                        testPlanDetailData &&
                        testPlanDetailData?.filter(
                          (i) => i.allowDelete === ACTION_VIEW && !selectAllTestPlan.includes(i?.id) && i?.id
                        )
                      )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
                    ]);
                  } else {
                    setSelectAllTestPlan(removeSelectedIds(selectAllTestPlan, testPlanDetailData));
                  }
                }}
                deleteConfirm={deleteConfirm}
                isDisable={_.isEmpty(testPlanDetailData)}
              />
            </div>
          )}
        </div>

        {testJobDetail !== undefined && (
          <AssignTestDataSetModal
            testDataSetAssigneeDetail={testJobDetail}
            projectId={projectId}
            testPlanDetails={selectedTestPlan}
            stepType={TEST_PLAN}
          />
        )}
        <PermissionDrawer
          openPermissionDrawer={openPermissionDrawer}
          onClose={() => setOpenPermissionDrawer(false)}
          permissionData={permissionTestPlan}
          savePermission={savePermission}
          members={members}
          setMembers={setMembers}
          previousPermission={previousPermission}
          setPermissionData={setPermissionData}
          ownerEmailId={permissionTestPlan?.createdBy}
          notShowExecutionCheck={true}
          drawerTitle={"Test Plan"}
        />
      </Spin>
    </>
  );
};
