import { faCopy } from "@fortawesome/free-regular-svg-icons";
import { faKey, faLock, faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Popover, Spin, Switch, Tooltip, message, notification } from "antd";
import _ from "lodash";
import moment from "moment-timezone";
import { default as React } from "react";
import { Cookies } from "react-cookie";
import uniqid from "uniqid";
import xpath from "xpath";
import {
  ANDROID_IMG,
  ANDROID_LOGO,
  ARROW_LEFT,
  ARROW_RIGHT,
  ERROR_ICON,
  GOOGLE_PIXEL_4A_ICON,
  GOOGLE_PIXEL_6_PRO_ICON,
  INFO_ICON,
  IOS_LOGO,
  IPHONE_11_ICON,
  IPHONE_12_ICON,
  IPHONE_ICON,
  IPHONE_SE_ICON,
  IPHONE_XR_ICON,
  IPHONE_X_ICON,
  MOTOROLA_MOTO_G04_ICON,
  SAMSUNG_A02_ICON,
  SAMSUNG_A05_ICON,
  SAMSUNG_A52_ICON,
  SAMSUNG_M04_ICON,
  SAMSUNG_M12_ICON,
  SUCCESS_ICON,
  WARNING,
  WARNING_ICON,
  WEB_ICON,
} from "../CdnImagePath";
import SelectAllBoxV2 from "../Components/CommonComponents/SelectAllBoxV2/SelectAllBoxV2";
import { BuildTag } from "../Components/CoreComponents/StyledComponent/StyledComponents";
import RecTestStep from "../Components/RecTestStep/SingleColumnView/RecTestStep/RecTestStep";
import {
  A,
  ACTIONS,
  ACTION_VIEW,
  ANDROID,
  CUSTOM,
  DEVICE_EXECUTION_FARM,
  DEVICE_FARM_USE,
  DEVICE_INTERACTION,
  DEVICE_STATUS,
  ELEMENT_ACTION,
  ELEMENT_DEFAULT_LOAD_TIME,
  ERROR_STATUS,
  ERROR_TYPE,
  ElementIdentifyMethod,
  ElementType,
  GOOGLE_PIXEL_4A,
  GOOGLE_PIXEL_6_PRO,
  GROUP_ACTION,
  I,
  INFO_TYPE,
  INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT,
  IOS,
  IPHONE_11,
  IPHONE_12,
  IPHONE_SE,
  IPHONE_X,
  IPHONE_XR,
  LANDSCAPE,
  LAUNCH_APP,
  MOTOROLA_MOTO_G04,
  ONETIME,
  OTHER_RUNS_OPTION,
  PORTRAIT,
  PRIVATE,
  PUBLIC,
  RECORDING_SET_NAME,
  RECURRING,
  REC_TEST_STEP_PREVIEW_STATUS_TYPE,
  REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP,
  REPORT_MANAGER,
  RESET_DEVICE_SCREEN_SOURCE,
  SAMSUNG_A02,
  SAMSUNG_A05,
  SAMSUNG_A52,
  SAMSUNG_M04,
  SAMSUNG_M12,
  SELECTOR_TYPE,
  SET_APP_LOCALE,
  SET_APP_LOCALE_TXT,
  STEP,
  SUCCESS_TYPE,
  SWIPE_DIRECTION,
  SWIPE_OVER_ELEMENT,
  TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET,
  TEST_PLAN_RESULT_TYPE,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  TOOLTIP_BG_COLOR,
  VERIFICATIONS,
  WARNING_TYPE,
  WEB,
  XPATH_ELEMENT_ATTRIBUTES_SPLITTER,
  XPATH_ELEMENT_SPLITTER,
  XpathGenerateMethod,
  actionOnElementMapping,
  allowedAppActivtyForFarm,
  appStatic,
  dateHourMinuteFormat,
  defaultDateTimeFormat,
  parseCoordinates,
  textNumericSymbol,
  verificationDropdownValuesMandatory,
  verificationExistenceValues,
  verifyApplicationStrings,
  verifyDeviceStrings,
} from "../Constants";
import {
  CUSTOM_ICON,
  ICON_ADD_DELAY_ACTION,
  ICON_ADD_VERIFICATION,
  ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION,
  ICON_CLEAR_TEXT_ACTION,
  ICON_ENTER_TEXT_ACTION,
  ICON_KEYPAD_ENTER_ACTION,
  ICON_LAUNCH_APP_ACTION,
  ICON_LONG_PRESS_ACTION,
  ICON_NAVIGATE_BACK_ACTION,
  ICON_NAVIGATE_HOME_ACTION,
  ICON_SCREENSHOT_PAGE_ACTION,
  ICON_SCROLL_PAGE_ACTION,
  ICON_SWIPE_ELEMENT_ACTION,
  ICON_TAP_ACTION,
  ICON_TAP_BY_INDEX_ACTION,
  PRIVATE_ICON,
  PUBLIC_ICON,
  RESULT_CONTINUE_AFTER_FAIL_ICON,
  RESULT_FAIL_ICON,
  RESULT_PRE_CONDITION_FAIL_ICON,
  RESULT_SKIPPED_ICON,
  RESULT_SUCCESS_ICON,
} from "../Constants/SvgConstants";
import { saveRecTestStepAction } from "../actions/RecTestStep/RecTestScenarioRecTestStepSingleColumn";
import { editRecTestStep } from "../actions/RecTestStep/RecTestStepCommonAction";
import {
  changeDisableStep,
  deleteRecTestStep,
  onRecTestStepDrop,
  showDeleteRecTestStepConfirmationModal,
} from "../actions/RecTestStep/RecTestStepRedirectAction";
import { onRecTestStepDragOver, showDragError } from "../actions/RecTestStepAction";
import { resetMultipleColumnViewStepName } from "../actions/RecTestStepMultiColumnViewAction";
import { farmSelectInAddBuild, saveBuildDetails, setAddAppBuildError } from "../actions/Recording";
import history from "../history";
import {
  findRecTestStepByTag,
  insertIntoRecTestStepRecTestProperty,
  updateRecTestStepAction,
} from "../reducer/RecTestStepReducer";
import SvgLoader from "./SvgLoader";

// export function updateReducer(TYPE, response) {
//   return {
//     type: TYPE,
//     response: response,
//   };
// }
const cookies = new Cookies();
// Array functions
export function inArray(target, array) {
  if (array.length > 0) {
    for (let i = 0; i < array.length; i++) {
      if (array[i] === target) {
        return true;
      }
    }
  }
  return false;
}
// export function isItemInArray(key, value, ItemArray) {
//   if (ItemArray.length !== 0) {
//     for (let i = 0; i < ItemArray.length; i++) {
//       if (ItemArray[i][key] === value) {
//         return true;
//       }
//     }
//   }
//   return false;
// }
// export function getItemKeyInArray(key, value, ItemArray) {
//   if (ItemArray.length !== 0) {
//     for (let i = 0; i < ItemArray.length; i++) {
//       if (ItemArray[i][key] === value) {
//         return i;
//       }
//     }
//   }
//   return null;
// }
// export function updateMulitArray(items, newItem) {
//   let filtered = [];
//   let len = items.length;
//   let i = 0;
//   let cond = false;
//   let index = "";
//   if (len !== 0) {
//     for (i; i < len; i++) {
//       let item = items[i];
//       if (item.itemID === newItem.itemID && item.permissionID === newItem.permissionID) {
//         cond = true;
//         index = i;
//       }
//     }
//     if (cond) {
//       items.splice(index, 1);
//       filtered = items;
//     } else {
//       filtered = items;
//       filtered.push(newItem);
//     }
//   } else {
//     filtered.push(newItem);
//   }
//   return filtered;
// }
// export function removeFromArray(arr, what) {
//   let a = arguments,
//     L = a.length,
//     ax;
//   while (L > 1 && arr.length) {
//     while ((ax = arr.indexOf(what)) !== -1) {
//       arr.splice(ax, 1);
//     }
//   }
//   return arr;
// }
// export function getItemListfromArray(itemKey, itemList) {
//   let data = [];
//   itemList.forEach((v, k) => {
//     if (v[itemKey] !== null) {
//       data.push(v[itemKey]);
//     }
//   });
//   return data;
// }
// export function getValueOFDifferentItemInArray(findKey, fromKey, fromValue, singleDataArray) {
//   let value;
//   singleDataArray.forEach((v, k) => {
//     if (v[fromKey] === fromValue) {
//       if (v[findKey] === "0" || v[findKey] === undefined) {
//         value = null;
//       } else {
//         value = v[findKey];
//       }
//     }
//   });
//   return value;
// }
// export function getRowFromArray(data, key, value) {
//   let selectedData = [];
//   data.forEach((v, k) => {
//     if (v[key] === value) {
//       selectedData.push(v);
//     }
//   });
//   return selectedData;
// }
// export function updateArray(data, arrayData) {
//   let temp = arrayData;
//   if (this.inArray(data, temp)) {
//     let index = temp.indexOf(data);
//     temp.splice(index, 1);
//   } else {
//     temp.push(data);
//   }
//   return temp;
// }
// export function maxDateFromArray(data, key) {
//   if (data.length !== 0) {
//     let tempDate = data[0][key];
//     data.forEach((v, k) => {
//       if (v[key] !== null && v[key] !== "" && Date.parse(tempDate) < Date.parse(v[key])) {
//         tempDate = v[key];
//       }
//     });
//     return tempDate;
//   } else {
//     return "";
//   }
// }

// export function getKeysFromArray(teams) {
//   let teamids = "";
//   teams.forEach((val, key) => {
//     teamids += val.value + ",";
//   });
//   return teamids.substring(0, teamids.length - 1);
// }

//String Functions
export function removeSpace(data) {
  return data.replace(" ", "");
}
export function length(data) {
  return data.replace(" ", "").length;
}

export function xmlToJSON(source) {
  let xmlDoc;

  // Replace strings with Unicode format &#012345 with #012345
  // The &# unicode format breaks the parser
  if (source != null) {
    source = source.replace(/&#([0-9]{4,})/g, "#$1");

    let recursive = (xmlNode, parentPath, index) => {
      // Translate attributes array to an object
      let attrObject = {};
      for (let attribute of xmlNode.attributes || []) {
        attrObject[attribute.name] = attribute.value;
      }

      // Dot Separated path of indices
      let path = index !== undefined && `${!parentPath ? "" : parentPath + "."}${index}`;

      return {
        children: [...xmlNode.children].map((childNode, childIndex) => recursive(childNode, path, childIndex)),
        tagName: xmlNode.tagName,
        attributes: attrObject,
        xpath: getOptimalXPath(xmlDoc, xmlNode, uniqueAttributes),
        path,
      };
    };
    xmlDoc = new DOMParser().parseFromString(source, "application/xml");
    let sourceXML = xmlDoc.children[0];
    return recursive(sourceXML);
  }
}

export function getOptimalXPath(doc, domNode, uniqueAttributes = ["id"]) {
  try {
    // BASE CASE #1: If this isn't an element, we're above the root, return empty string
    if (!domNode.tagName || domNode.nodeType !== 1) {
      return "";
    }

    // BASE CASE #2: If this node has a unique attribute, return an absolute XPath with that attribute
    for (let attrName of uniqueAttributes) {
      const attrValue = domNode.getAttribute(attrName);
      if (attrValue) {
        let xpath = `//${domNode.tagName || "*"}[@${attrName}="${attrValue}"]`;
        let othersWithAttr;

        // If the XPath does not parse, move to the next unique attribute
        try {
          othersWithAttr = xpath.select(xpath, doc);
        } catch (ign) {
          continue;
        }

        // If the attribute isn't actually unique, get it's index too
        if (othersWithAttr.length > 1) {
          let index = othersWithAttr.indexOf(domNode);
          xpath = `(${xpath})[${index + 1}]`;
        }
        return xpath;
      }
    }

    // Get the relative xpath of this node using tagName
    let xpath = `/${domNode.tagName}`;

    // If this node has siblings of the same tagName, get the index of this node
    if (domNode.parentNode) {
      // Get the siblings
      const childNodes = Array.prototype.slice
        .call(domNode.parentNode.childNodes, 0)
        .filter((childNode) => childNode.nodeType === 1 && childNode.tagName === domNode.tagName);

      // If there's more than one sibling, append the index
      if (childNodes.length > 1) {
        let index = childNodes.indexOf(domNode);
        xpath += `[${index + 1}]`;
      }
    }

    // Make a recursive call to this nodes parents and prepend it to this xpath
    return getOptimalXPath(doc, domNode.parentNode, uniqueAttributes) + xpath;
  } catch (ign) {
    // If there's an unexpected exception, abort and don't get an XPath
    return null;
  }
}
const uniqueAttributes = ["name", "content-desc", "id", "accessibility-id"];

export function getLocators(attributes, sourceXML) {
  const res = {};
  // for (let [strategyAlias, strategy] of STRATEGY_MAPPINGS) {
  //   const value = attributes[strategyAlias];
  //   if (value && isUnique(strategyAlias, value, sourceXML)) {
  //     res[strategy] = attributes[strategyAlias];
  //   }
  // }
  return res;
}

export function isUnique(attrName, attrValue, sourceXML) {
  // If no sourceXML provided, assume it's unique
  if (!sourceXML) {
    return false;
  }
  const doc = new DOMParser().parseFromString(sourceXML, "text/xml");
  try {
    return xpath.select(`//*[@${attrName}="${attrValue}"]`, doc).length < 2;
  } catch (e) {
    return false;
  }
}

// Map of the optimal strategies.
// const STRATEGY_MAPPINGS = [
//   ["name", "accessibility id"],
//   ["content-desc", "accessibility id"],
//   ["id", "id"],
//   ["rntestid", "id"],
//   ["resource-id", "id"],
// ];

export function getElementCustomXpath(xml, selectedElement = {}) {
  if (Object.keys(selectedElement).length > 0) {
    selectedElement["sourceXML"] = xml;
    selectedElement["customXpath"] = "";
    let elementXpath = "";
    selectedElement["recElementLocatorProperties"] = [];
    if (isAndroid(xml)) {
      elementXpath = getAndroidDeviceElementXpath(xml, selectedElement);
    } else {
      if (isIOS(selectedElement)) {
        let elementThroughDom = getIOSDeviceElementFullXPath(xml, selectedElement, "", 0);
        // Find by Unique Type and Name
        let uniqueTypeAndNameElementXpath = getIOSElementXPathByUniqueTypeAndName(xml, selectedElement);
        if (uniqueTypeAndNameElementXpath !== "") {
          elementXpath = uniqueTypeAndNameElementXpath;
        }
        // Find Xpath through Unique Parent
        let uniqueParentElementXpath = getIOSElementXPathByUniqueParentTag(
          xml,
          elementThroughDom.path,
          selectedElement
        );
        if (elementXpath === "" && uniqueParentElementXpath !== "") {
          elementXpath = uniqueParentElementXpath;
        }
        // Appium Desktop Generated Xpath
        if (
          selectedElement["recElementLocatorProperties"] &&
          selectedElement["recElementLocatorProperties"].length === 0
        ) {
          let appiumXpath = selectedElement.xpath;
          appiumXpath = getIOSDeviceElementXpathByRemoveSameTag(appiumXpath, selectedElement);
          if (elementXpath === "" && appiumXpath !== "") {
            elementXpath = appiumXpath;
          }
        }
      }
    }
    selectedElement["customXpath"] = elementXpath;
  }
  return selectedElement;
}
export function getIOSDeviceElementFullXPath(element, selectedElement, path, childIndex, totalChild = 0) {
  // text,Class,resource Id,contentDesc separate by ##
  // Two item are separate by $$
  if (element.path && selectedElement.path === element.path) {
    // path+=element.tagName;
    path +=
      element.tagName +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      (Object.keys(element.attributes).length > 0 && element.attributes["name"] !== undefined
        ? element.attributes["name"]
        : "") +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      childIndex +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      totalChild +
      XPATH_ELEMENT_SPLITTER;
    return { path: path, element: element };
  } else if (element.children !== null) {
    var result = null;
    for (var i = 0; result === null && i < element.children.length; i++) {
      let sameTagName = element.children.filter((obj) => obj.tagName === element.children[i].tagName);
      var selectedElementIndex = sameTagName
        .map(function (item) {
          return item.path;
        })
        .indexOf(element.children[i].path);
      var tempPath =
        path +
        element.tagName +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        (Object.keys(element.attributes).length > 0 && element.attributes["name"] !== undefined
          ? element.attributes["name"]
          : "") +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        childIndex +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        totalChild +
        XPATH_ELEMENT_SPLITTER;
      // +
      // (childIndex > 0 ? "[" + childIndex + "]" : "") +
      // "$$";
      // var tempPath=path+element.tagName+"/";
      result = getIOSDeviceElementFullXPath(
        element.children[i],
        selectedElement,
        tempPath,
        sameTagName.length > 1 ? selectedElementIndex + 1 : 1,
        element.children.length
      );
      if (result != null) {
        return result;
      }
    }
    selectedElement["customXpathMethod"] = XpathGenerateMethod.ACTION_BY_LOCATOR;
    return result;
  }
  return null;
}

export function getIOSElementXPathByUniqueTypeAndName(xml, selectedElement) {
  if (selectedElement.attributes["name"] !== undefined) {
    var selectedResourceId = JSON.stringify(xml).split('"name":"' + selectedElement.attributes["name"] + '"');
    if (selectedResourceId.length <= 2) {
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_NAME,
        name: ElementIdentifyMethod.XPATH,
        value: "//" + selectedElement.attributes["type"] + "[@name='" + selectedElement.attributes["name"] + "']",
      });
      return "//" + selectedElement.attributes["type"] + "[@name='" + selectedElement.attributes["name"] + "']";
    }
  }
}

export function getIOSElementXPathByUniqueParentTag(domJson, xpath, selectedElement) {
  let elements = xpath.split(XPATH_ELEMENT_SPLITTER);
  let customXpath = "";
  let totalChildCount = "";
  let currentChildIndex = "";
  for (let i = elements.length - 2; i > 0; i--) {
    let elementDetails = elements[i].split(XPATH_ELEMENT_ATTRIBUTES_SPLITTER);

    if (elementDetails.length === 4) {
      let tagCount = JSON.stringify(domJson).split('"tagName":"' + elementDetails[0] + '"').length;
      let nameCount = JSON.stringify(domJson).split('"name":"' + elementDetails[1] + '"').length;
      //To check the element is unique by splitting in DOM Json
      if (tagCount === 2) {
        customXpath =
          "//" +
          elementDetails[0] +
          // (elementDetails[1] !== "" ? elementDetails[1] : "") +
          (customXpath !== "" ? "/" + customXpath : customXpath);
        if (currentChildIndex === "" && totalChildCount === "") {
          currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
          totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
        }
        break;
      } else if (i !== elements.length - 2 && nameCount === 2) {
        customXpath =
          "//" +
          elementDetails[0] +
          "[@name='" +
          elementDetails[1] +
          "']" +
          (customXpath !== "" ? "/" + customXpath : customXpath);
        if (currentChildIndex === "" && totalChildCount === "") {
          currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
          totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
        }
        break;
      } else if (i === elements.length - 2) {
        customXpath = elementDetails[0];
        if (currentChildIndex === "" && totalChildCount === "") {
          currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
          totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
        }
      } else {
        customXpath =
          elementDetails[0] +
          (elementDetails[2] !== "" ? "[" + elementDetails[2] + "]" : "") +
          (customXpath !== "" ? "/" + customXpath : customXpath);
      }
      // let nextElementDetails = i > 0 ? elements[i - 1].split("##") : [];
      //   if (tagCount === 2 && elementDetails[0] !== "XCUIElementTypeCollectionView") {
      //     customXpath =
      //       elementDetails[0] +
      //       // (elementDetails[1] !== "" ? elementDetails[1] : "") +
      //       (customXpath !== "" ? "/" + customXpath : customXpath);
      //     currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
      //     totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
      //     break;
      //   } else if (
      //     i !== elements.length - 2 &&
      //     nameCount === 2 &&
      //     elementDetails[0] !== "XCUIElementTypeCollectionView"
      //   ) {
      //     customXpath =
      //       "//" +
      //       elementDetails[0] +
      //       "[@name='" +
      //       elementDetails[1] +
      //       "']" +
      //       (customXpath !== "" ? "/" + customXpath : customXpath);
      //     currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
      //     totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
      //     break;
      //   } else if (nextElementDetails[0] === "XCUIElementTypeCollectionView") {
      //     currentChildIndex = elementDetails[2] !== "" ? elementDetails[2] : "";
      //     customXpath =
      //       "//" +
      //       nextElementDetails[0] +
      //       "/" +
      //       elementDetails[0] +
      //       (currentChildIndex !== "" ? "[" + currentChildIndex + "]" : "") +
      //       (customXpath !== "" ? "/" + customXpath : customXpath);
      //     totalChildCount = elementDetails[3] !== "" ? elementDetails[3] : "";
      //     break;
      //   } else {
      //     customXpath =
      //       elementDetails[0] +
      //       (elementDetails[2] !== "" ? "[" + elementDetails[2] + "]" : "") +
      //       (customXpath !== "" ? "/" + customXpath : customXpath);
      //   }
    }
  }
  if (customXpath !== "") {
    selectedElement["recElementLocatorProperties"].push({
      isActive: 1,
      generatedMethod: XpathGenerateMethod.PARENT_REF,
      name: ElementIdentifyMethod.XPATH,
      value: customXpath,
      totalChildCount,
      currentChildIndex,
    });
  }
  return customXpath;
}
export function getAndroidDeviceElementXpath(xml, selectedElement) {
  let elementXpath = "";
  if (selectedElement) {
    selectedElement["recElementLocatorProperties"] = [];
    //Unique Resource ID
    elementXpath = getElementXPathByUniqueResourceID(xml, selectedElement);
    //Unique Resource ID And Unique Text
    getAndroidElementXPathByUniqueResourceIDAndText(xml, selectedElement);
    //Unique Class And Unique Text
    getAndroidElementXPathByUniqueClassAndText(xml, selectedElement);
    //Unique Text
    getAndroidElementXPathByUniqueText(xml, selectedElement);
    //Unique Parent
    getAndroidElementXPathByParentRefAndPath(xml, selectedElement);

    //Unique Resource ID And Unique Text
    if (elementXpath === undefined || elementXpath === "") {
      elementXpath = selectedElement["recElementLocatorProperties"].filter(
        (item) => item["generatedMethod"] === XpathGenerateMethod.UNIQUE_RESOURCE_ID_AND_TEXT
      );
      elementXpath = elementXpath.length > 0 ? elementXpath[0]["xpath"] : undefined;
    }
    //Unique Text
    if (elementXpath === undefined || elementXpath === "") {
      elementXpath = selectedElement["recElementLocatorProperties"].filter(
        (item) => item["generatedMethod"] === XpathGenerateMethod.UNIQUE_TEXT
      );
      elementXpath = elementXpath.length > 0 ? elementXpath[0]["xpath"] : undefined;
    }
    //Unique Parent
    if (elementXpath === undefined || elementXpath === "") {
      elementXpath = selectedElement["recElementLocatorProperties"].filter(
        (item) => item["generatedMethod"] === XpathGenerateMethod.PARENT_REF
      );
      elementXpath = elementXpath.length > 0 ? elementXpath[0]["xpath"] : undefined;
    }
  }

  return elementXpath;
}
export function getIOSDeviceElementXpathByRemoveSameTag(elementXpath, selectedElement) {
  let elementsPath = elementXpath.split("/");
  if (elementsPath.length > 1) {
    let previous = "";
    let symbolForShorterXpathAdded = false;
    let newXpath = "";
    for (let i = 2; i <= elementsPath.length - 1; i++) {
      if (previous === "") {
        newXpath += "//" + elementsPath[i] + "/";
        previous = elementsPath[i];
      } else {
        if (elementsPath[i] === previous && elementsPath[i + 1] === previous && i !== elementsPath.length - 2) {
          if (!symbolForShorterXpathAdded) {
            newXpath += "/";
            symbolForShorterXpathAdded = true;
          }
        } else {
          if (i === elementsPath.length - 1) {
            newXpath += elementsPath[i];
          } else {
            newXpath += elementsPath[i] + "/";
          }
          previous = elementsPath[i];
          symbolForShorterXpathAdded = false;
        }
      }
    }
    selectedElement["recElementLocatorProperties"].push({
      isActive: 1,
      generatedMethod: XpathGenerateMethod.ACTION_BY_LOCATOR,
      name: ElementIdentifyMethod.XPATH,
      value: newXpath,
    });
    return newXpath;
  }
  return "";
}
export function getElementXPathByUniqueResourceID(xml, selectedElement) {
  if (selectedElement.attributes["resource-id"] !== undefined) {
    var selectedResourceId = JSON.stringify(xml).split('"' + selectedElement.attributes["resource-id"] + '"');
    if (selectedResourceId.length <= 2) {
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_RESOURCE_ID,
        name: ElementIdentifyMethod.XPATH,
        value: "//*[@resource-id='" + selectedElement.attributes["resource-id"] + "']",
      });
      return "//*[@resource-id='" + selectedElement.attributes["resource-id"] + "']";
    }
  }
  return "";
}

export function getAndroidElementXPathByUniqueClassAndText(xml, selectedElement) {
  if (
    checkNotUndefinedAndNullAndBlank(selectedElement.attributes["class"]) &&
    checkNotUndefinedAndNullAndBlank(selectedElement.attributes["text"])
  ) {
    let count = getAndroidElementCountByTextAndClass(
      xml,
      selectedElement.attributes["text"],
      selectedElement.attributes["class"],
      0
    );
    if (count === 1) {
      selectedElement["customXpathMethod"] = XpathGenerateMethod.UNIQUE_CLASS_AND_TEXT;
      let text = selectedElement.attributes["text"];
      selectedElement["uniqueByText"] = text;
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_CLASS_AND_TEXT,
        name: ElementIdentifyMethod.XPATH,
        value:
          "//" +
          selectedElement.attributes["class"] +
          "[@text=" +
          (text.includes("'") ? '"' : "'") +
          text +
          (text.includes("'") ? '"' : "'") +
          "]",
      });
      return (
        "//" +
        selectedElement.attributes["class"] +
        "[@text=" +
        (text.includes("'") ? '"' : "'") +
        text +
        (text.includes("'") ? '"' : "'") +
        "]"
      );
    }
  }
  return "";
}

export function getAndroidElementXPathByUniqueResourceIDAndText(xml, selectedElement) {
  if (
    checkNotUndefinedAndNullAndBlank(selectedElement.attributes["resource-id"]) &&
    checkNotUndefinedAndNullAndBlank(selectedElement.attributes["text"])
  ) {
    let count = getAndroidElementCountByTextAndResourceID(
      xml,
      selectedElement.attributes["text"],
      selectedElement.attributes["resource-id"],
      0
    );
    if (count === 1) {
      selectedElement["customXpathMethod"] = XpathGenerateMethod.UNIQUE_RESOURCE_ID_AND_TEXT;
      let text = selectedElement.attributes["text"];
      selectedElement["uniqueByText"] = text;
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_RESOURCE_ID_AND_TEXT,
        name: ElementIdentifyMethod.XPATH,
        value:
          "//*[@resource-id='" +
          selectedElement.attributes["resource-id"] +
          "' and @text=" +
          (text.includes("'") ? '"' : "'") +
          text +
          (text.includes("'") ? '"' : "'") +
          "]",
      });
      return (
        "//*[@resource-id='" +
        selectedElement.attributes["resource-id"] +
        "' and @text=" +
        (text.includes("'") ? '"' : "'") +
        text +
        (text.includes("'") ? '"' : "'") +
        "]"
      );
    }
  }
  return "";
}
export function getAndroidElementXPathByUniqueText(xml, selectedElement) {
  if (selectedElement.attributes["text"] !== undefined) {
    var text = "";
    var _text = getStringfyText(selectedElement.attributes["text"]);
    var content_desc = getStringfyText(selectedElement.attributes["content-desc"]);
    var selectedElementText = JSON.stringify(xml).split('"text":"' + _text + '"');
    var selectedElementContentDesc = JSON.stringify(xml).split('"content-desc":"' + content_desc + '"');
    if (selectedElementText.length == 2) {
      selectedElement["customXpathMethod"] = XpathGenerateMethod.UNIQUE_TEXT;
      selectedElement["uniqueByText"] = selectedElement.attributes["text"];
      text = selectedElement.attributes["text"];
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_TEXT,
        name: ElementIdentifyMethod.XPATH,
        value: "//*[@text=" + (text.includes("'") ? '"' : "'") + text + (text.includes("'") ? '"' : "'") + "]",
      });
      return "//*[@text=" + (text.includes("'") ? '"' : "'") + text + (text.includes("'") ? '"' : "'") + "]";
    } else if (selectedElementContentDesc.length == 2) {
      selectedElement["customXpathMethod"] = XpathGenerateMethod.UNIQUE_TEXT;
      selectedElement["uniqueByText"] = selectedElement.attributes["content-desc"];
      text = selectedElement.attributes["content-desc"];
      selectedElement["recElementLocatorProperties"].push({
        isActive: 1,
        generatedMethod: XpathGenerateMethod.UNIQUE_TEXT,
        name: ElementIdentifyMethod.XPATH,
        value:
          "//*[@content-desc=" + ((text.includes("'") ? '"' : "'") + text + (text.includes("'") ? '"' : "'")) + "]",
      });
      return "//*[@content-desc=" + ((text.includes("'") ? '"' : "'") + text + (text.includes("'") ? '"' : "'")) + "]";
    }
  }
  return "";
}

function getStringfyText(text) {
  if (text) {
    let updatedText = JSON.stringify(text);
    return updatedText.substring(1, updatedText.length - 1);
  }
  return text;
}

export function getAndroidElementXPathByParentRefAndPath(xml, selectedElement) {
  let elementPath = "";
  elementPath = getAndroidElementPathByPath(xml, selectedElement, "");
  if (elementPath !== undefined) {
    selectedElement["customXpathMethod"] = XpathGenerateMethod.PARENT_REF;
    if (selectedElement.attributes["text"]) {
      selectedElement["uniqueByText"] = selectedElement.attributes["text"];
    } else if (selectedElement.attributes["content-desc"]) {
      selectedElement["uniqueByText"] = selectedElement.attributes["content-desc"];
    }
    return getAndroidElementXPathFromDom(elementPath.path, xml, selectedElement);
  }
  return "";
}

export function getAndroidElementCountByTextAndResourceID(element, childText, childResourceId, count) {
  if (
    element.attributes.text === childText &&
    element.attributes["resource-id"] &&
    element.attributes["resource-id"] == childResourceId
  ) {
    return (count += 1);
  } else if (element.children !== null) {
    for (var i = 0; i < element.children.length; i++) {
      let tempCount = getAndroidElementCountByTextAndResourceID(element.children[i], childText, childResourceId, count);
      if (tempCount != count && tempCount > count) {
        count += tempCount;
      }
    }
    return count;
  }
  return 0;
}
export function getAndroidElementCountByTextAndClass(element, childText, childClass, count) {
  if (
    element.attributes.text === childText &&
    element.attributes["class"] &&
    element.attributes["class"] == childClass
  ) {
    return (count += 1);
  } else if (element.children !== null) {
    for (var i = 0; i < element.children.length; i++) {
      let tempCount = getAndroidElementCountByTextAndClass(element.children[i], childText, childClass, count);
      if (tempCount != count && tempCount > count) {
        count += tempCount;
      }
    }
    return count;
  }
  return 0;
}

// export function getAndroidElementPathByPath(element,selectedElement,path){
//   // text,Class,resource Id,contentDesc seperate by ##
//   // Two item are seperate by $$
//   if(element.path && selectedElement.path==element.path){
//       path+=(element.attributes["text"]?element.attributes["text"]:"")+"##"+element.attributes.class+"##"+(element.attributes["resource-id"]?element.attributes["resource-id"]:"")+"##"+(element.attributes["content-desc"]?element.attributes["content-desc"]:"");
//       return {path:path,element:element};
//   }else if (element.children !== null){
//       var parentPath=path;
//       var result = null;
//       for(var i=0; result === null && i < element.children.length; i++){
//           var tempPath=path+(element.attributes["text"]?element.attributes["text"]:"")+"##"+element.attributes.class+(element.attributes["resource-id"]?"##"+element.attributes["resource-id"]:"")+"##"+(element.attributes["content-desc"]?element.attributes["content-desc"]:"")+"$$";
//           result = getAndroidElementPathByPath(element.children[i], selectedElement,tempPath);
//           if(result!=null){
//               return result;
//           }
//       }
//       return result;
//   }
//   return null;
// }

// export function getAndroidElementXPathFromDom(elementPath,domJsonObj){
//   // console.log(elementPath);
//   var finalStartResourceId="";
//   var classResourceIdList=elementPath.split("$$");
//   let appendDoubleSlash=true;
//   for(var i=classResourceIdList.length-2;i>0;i--){
//       var textClassResourceId=classResourceIdList[i].split("##");
//       if(textClassResourceId.length==4 && JSON.stringify(classResourceIdList).split('"'+textClassResourceId[2]+'"').length==2){
//           finalStartResourceId="//*[@resource-id='"+textClassResourceId[2]+"']/"+finalStartResourceId;
//           appendDoubleSlash=false;
//           break;
//       }else if(textClassResourceId.length==4 && JSON.stringify(classResourceIdList).split(textClassResourceId[1]).length==2){
//           finalStartResourceId="//"+textClassResourceId[1]+"/"+finalStartResourceId;
//           appendDoubleSlash=false;
//           break;
//       }else{
//         if(textClassResourceId[1]){
//           finalStartResourceId=(textClassResourceId[1].includes("android")?textClassResourceId[1]:"")+"/"+finalStartResourceId;
//         }
//       }
//   }
//   var textClassResourceId=classResourceIdList[classResourceIdList.length-1].split("##");
//   if(textClassResourceId.length==4){
//     if(textClassResourceId[1] && textClassResourceId[1].includes("android")){
//       finalStartResourceId=finalStartResourceId+textClassResourceId[1];
//       if(textClassResourceId[2]){
//         finalStartResourceId=finalStartResourceId+"[@resource-id='"+textClassResourceId[2]+"']"
//       }else if(textClassResourceId[0]){
//         finalStartResourceId=finalStartResourceId+"[@text='"+textClassResourceId[0]+"']"
//       }else if(textClassResourceId[3]){
//         finalStartResourceId=finalStartResourceId+"[@content-desc='"+textClassResourceId[3]+"']"
//       }
//     }else{
//       finalStartResourceId=finalStartResourceId.slice(0, -1);
//     }

//   }
//   return (appendDoubleSlash?"//":"")+finalStartResourceId;
// }

export function getAndroidElementPathByPath(element, selectedElement, path, index, totalChild) {
  /* Created the unique string of element <text$$Class$$resource Id$$contentDesc$$index>. 
  Each element is seperated by ##
  */
  if (element.path && selectedElement.path === element.path) {
    path +=
      (element.attributes["text"] ? element.attributes["text"] : "") +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      element.attributes.class +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      (element.attributes["resource-id"] ? element.attributes["resource-id"] : "") +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      (element.attributes["content-desc"] ? element.attributes["content-desc"] : "") +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      (index !== undefined ? index + 1 : "") +
      XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
      (totalChild ? totalChild : 0);
    return { path: path, element: element };
  } else if (element.children !== null) {
    var parentPath = path;
    var result = null;
    var childClassIndexDetails = {};
    for (var i = 0; result === null && i < element.children.length; i++) {
      // Capture the child class with index
      if (childClassIndexDetails[element.children[i].attributes.class] == undefined) {
        childClassIndexDetails[element.children[i].attributes.class] = 0;
      } else {
        childClassIndexDetails[element.children[i].attributes.class] += 1;
      }
      //capture Parent Path in temp variable
      var tempPath =
        path +
        (element.attributes["text"] ? element.attributes["text"] : "") +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        element.attributes.class +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        (element.attributes["resource-id"] ? element.attributes["resource-id"] : "") +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        (element.attributes["content-desc"] ? element.attributes["content-desc"] : "") +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        (index != undefined ? index + 1 : 0) +
        XPATH_ELEMENT_ATTRIBUTES_SPLITTER +
        (element.children ? element.children.length : 0) +
        XPATH_ELEMENT_SPLITTER;
      result = getAndroidElementPathByPath(
        element.children[i],
        selectedElement,
        tempPath,
        childClassIndexDetails[element.children[i].attributes.class],
        element.children ? element.children.length : 0
      );
      if (result != null) {
        return result;
      }
    }
    return result;
  }
  return null;
}
export function getAndroidElementXPathFromDom(elementPath, domJsonObj, selectedElement) {
  /*
  0. text
  1. Class
  2. resource Id
  3. contentDesc
  4. index
  5. children Length
  */
  var finalStartResourceId = "";
  var classResourceIdList = elementPath.split(XPATH_ELEMENT_SPLITTER);
  let appendDoubleSlash = true;
  for (var i = classResourceIdList.length - 2; i > 0; i--) {
    var textClassResourceId = classResourceIdList[i].split(XPATH_ELEMENT_ATTRIBUTES_SPLITTER);
    //Unique Parent Resource ID
    if (
      textClassResourceId.length === 6 &&
      JSON.stringify(domJsonObj).split('"' + textClassResourceId[2] + '"').length == 2
    ) {
      finalStartResourceId = "//*[@resource-id='" + textClassResourceId[2] + "']/" + finalStartResourceId;
      appendDoubleSlash = false;
      break;
    } else if (
      textClassResourceId.length === 6 &&
      JSON.stringify(domJsonObj).split(textClassResourceId[1]).length === 2
    ) {
      //Unique Tag class
      finalStartResourceId = "//" + textClassResourceId[1] + "/" + finalStartResourceId;
      appendDoubleSlash = false;
      break;
    } else {
      if (textClassResourceId[1]) {
        finalStartResourceId =
          (textClassResourceId[1].includes("android")
            ? textClassResourceId[1] + (textClassResourceId[4] !== "" ? "[" + textClassResourceId[4] + "]" : "")
            : "") +
          "/" +
          finalStartResourceId;
      }
    }
  }
  var textClassResourceId = classResourceIdList[classResourceIdList.length - 1].split(
    XPATH_ELEMENT_ATTRIBUTES_SPLITTER
  );

  selectedElement["recElementLocatorProperties"].push({
    isActive: 1,
    generatedMethod: XpathGenerateMethod.PARENT_REF,
    name: ElementIdentifyMethod.XPATH,
    value:
      (appendDoubleSlash ? "//" : "") +
      finalStartResourceId +
      // (appendDoubleSlash ? "//" : "") +
      textClassResourceId[1],
    totalChildCount: classResourceIdList[classResourceIdList.length - 1].split(XPATH_ELEMENT_ATTRIBUTES_SPLITTER)[5],
    currentChildIndex: classResourceIdList[classResourceIdList.length - 1].split(XPATH_ELEMENT_ATTRIBUTES_SPLITTER)[4],
  });
  if (textClassResourceId.length === 6) {
    if (textClassResourceId[1] && textClassResourceId[1].includes("android")) {
      finalStartResourceId = finalStartResourceId + textClassResourceId[1];
      if (textClassResourceId[2]) {
        finalStartResourceId = finalStartResourceId + "[@resource-id='" + textClassResourceId[2] + "']";
      } else if (textClassResourceId[0]) {
        finalStartResourceId = finalStartResourceId + "[@text='" + textClassResourceId[0] + "']";
      } else if (textClassResourceId[3]) {
        finalStartResourceId = finalStartResourceId + "[@content-desc='" + textClassResourceId[3] + "']";
      } else if (textClassResourceId[4] !== "") {
        finalStartResourceId = finalStartResourceId + ("[" + textClassResourceId[4] + "]");
      }
    } else {
      finalStartResourceId = finalStartResourceId.slice(0, -1);
    }
  }
  return (appendDoubleSlash ? "//" : "") + finalStartResourceId;
}

export function isAndroid(source) {
  if (source.children) {
    return source.children.length >= 1 && source.children[0].tagName.includes("android");
  } else {
    return source.tagName.includes("XCUIElementType");
  }
}

export function isIOS(selectedElement) {
  return selectedElement.tagName.includes("XCUIElementType");
}

export function saveJSON(data, filename) {
  if (!data) {
    console.error("No data");
    return;
  }

  if (!filename) filename = "console.json";

  if (typeof data === "object") {
    data = JSON.stringify(data, function replacer(key, value) {
      if (key == "methodBody") {
        return value.replace(/\\"/g, '"');
      }
      return value;
    });
  }
  var blob = new Blob([data], { type: "text/json" }),
    e = document.createEvent("MouseEvents"),
    a = document.createElement("a");

  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  a.dataset.downloadurl = ["text/json", a.download, a.href].join(":");
  e.initMouseEvent("click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
  a.dispatchEvent(e);
}

// export function makeKeyValuePairObject(key, value) {
//   let obj = {};
//   obj[key] = value;
//   return obj;
// }

// export function readFile(file) {
//   var reader = new FileReader();
//   reader.onload = function (event) {
//     return event.target.result;
//   };
//   reader.onerror = function (event) {
//     console.error("File could not be read! Code " + event.target.error.code);
//     return null;
//   };
//   reader.readAsText(file);
// }

// export function getSessionObjectFromAllSession(targetUniqueId, deviceType) {
//   let connectedDevices = {};
//   if (localStorage.getItem("connectedDevices")) connectedDevices = JSON.parse(localStorage.getItem("connectedDevices"));
//   else return null;
//   let devices = {};
//   if (deviceType == "Android") {
//     devices = connectedDevices["androidconnecteddevices"];
//   } else if (deviceType == "iOS") {
//     devices = connectedDevices["iosconnecteddevices"];
//   } else {
//     return null;
//   }
//   let device = devices.filter((object) => object.targetUniqueId === targetUniqueId);
//   return device.length == 1 ? device[0] : null;
// }

// export function getDesiredCapabilitesBySessionId(sessioId) {
//   let connectedDevices = {};
//   if (localStorage.getItem("connectedDevices")) connectedDevices = JSON.parse(localStorage.getItem("connectedDevices"));
//   else return null;
//   let devices = [];
//   devices = connectedDevices["androidconnecteddevices"];
//   devices = devices.concat(connectedDevices["iosconnecteddevices"]);

//   let device = devices.filter((object) => object.appiumSession.sessionId === sessioId);
//   return device.length == 1 ? device[0] : null;
// }

export function getServiceMethod(action, recElement) {
  switch (action) {
    case ELEMENT_ACTION.TAP:
      if (recElement?.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
        return "tapOnElement";
      } else if (recElement?.elementSelectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
        return "tapOnElement";
      }
      return "tapOnElement";
    case ELEMENT_ACTION.TAP_BY_INDEX:
      return "tapOnElement";
    case ELEMENT_ACTION.LONG_PRESS:
      return "longTapOnElement";
    case ELEMENT_ACTION.SEND_KEYS:
      return "addText";
    case ELEMENT_ACTION.CLEAR_TEXT:
      return "clearText";
    case ELEMENT_ACTION.LAUNCH_APP:
      return "launchAppForRecording";
    case VERIFICATIONS:
    case STEP:
      return "verifyElementProperty";
    case SWIPE_OVER_ELEMENT:
      return "swipeElement";
    case ELEMENT_ACTION.SWIPE:
      return "swipe";
    case DEVICE_INTERACTION.HOME:
    case DEVICE_INTERACTION.BACK:
    case DEVICE_INTERACTION.ENTER:
      return "performDeviceInteraction";
    default:
      return null;
  }
}
export function getServiceData(state, serverId, targetUniqueId) {
  let data = {
    serverId,
    targetUniqueId,
    selectedElementId: state.selectedElementId,
    value: state.selectedElement ? state.selectedElement.customXpath : null,
  };
  data["recTestStepProperty"] = getStepPropertiesObject(state);
  data["referenceRecTestStepProperty"] = prepareReferenceStepProperties(state.elementList);
  switch (state.selectedAction) {
    case SWIPE_OVER_ELEMENT:
      data["swipeOverElementDirection"] = state.swipeOverElementDirection;
      data["swipeOverElementPercentage"] = state.swipeOverElementPercentage;
      return data;
    case ELEMENT_ACTION.SWIPE:
      data["swipeDirection"] = state.swipeOverElementDirection;
      data["swipePercentage"] = state.swipeOverElementPercentage;
      return data;
    case ELEMENT_ACTION.SEND_KEYS:
      let text = state.enterTextValue;
      if (state.appendRandomText) {
        text += getUniqueId();
      }
      data["textToInsert"] = text;
      return data;
    case ELEMENT_ACTION.LAUNCH_APP:
      data["packageName"] = state.selectedAppLaunch;
      data["removeAppPreviousSession"] = state.closeLaunchAppPreviousSession;
      return data;
    default:
      return data;
  }
}
export function prepareReferenceStepProperties(elementList) {
  let data = {};
  elementList.map((v, k) => {
    let text = [];
    if (v.recTestStepProperty?.recStepElementAttributes) {
      text = v.recTestStepProperty?.recStepElementAttributes.filter((item) => item.key == "text" && item.value != "");
      if (text.length == 0) {
        text = v.recTestStepProperty?.recStepElementAttributes.filter(
          (item) => item.key == "content-desc" && item.value != ""
        );
      }
    }
    data[v.id] = {
      xpath: v.recTestStepProperty?.xpath,
      textRef: text.length > 0 ? text[0]["value"] : "",
    };
  });
  return data;
}
export function removeStepById(state) {
  let exisitngElementList = state.elementList;
  if (state.currentStepIndex > -1 && exisitngElementList.length > 0 && state.isStepAdeded) {
    if (state.selectedGroup === undefined) {
      exisitngElementList.splice(state.currentStepIndex, 1);
    } else {
      removeUnsavedStepFromGroup(state.elementList);
    }
  }
}

export function insertStep(state, blankStepId, suggestedStepName, stepIndex, isGroup, currentSteps) {
  let stepName = state.currentStepName ? state.currentStepName : suggestedStepName;
  let exisitngElementList = currentSteps ? currentSteps : state.elementList;
  let insertStepIndex = stepIndex == undefined ? state.currentStepIndex : stepIndex;
  if (insertStepIndex > -1 && exisitngElementList.length > 0) {
    exisitngElementList.splice(insertStepIndex + 1, 0, {
      tag: blankStepId,
      recTestStepProperty: { stepName: stepName, action: isGroup ? STEP : "" },
    });
  } else {
    //for Blank Step
    exisitngElementList.push({
      tag: blankStepId,
      recTestStepProperty: { stepName: stepName, action: isGroup ? STEP : "" },
    });
  }
}

export function insertBreadScrumItems(breadScrumItems, state, GroupRecTestStep) {
  if (GroupRecTestStep.recTestStepProperty && GroupRecTestStep.recTestStepProperty?.action == STEP) {
    let item = {
      stepName: GroupRecTestStep.recTestStepProperty?.stepName,
      tag: GroupRecTestStep.tag,
    };
    let stepName = "Unsaved Recording Set Name";
    if (state.recordingSetName) {
      stepName = state.recordingSetName;
    } else if (state.executionSetName) {
      stepName = state.executionSetName;
    }

    if (breadScrumItems.length == 0) {
      breadScrumItems.push({ stepName: stepName, tag: RECORDING_SET_NAME });
    }
    breadScrumItems.push(item);
  }
  return breadScrumItems;
}

export function updateBreadScrumItems(breadScrumItems, state, itemTag) {
  let endIndex = 0;
  endIndex = itemTag == undefined ? breadScrumItems.length - 1 : itemTag.index + 1;
  endIndex = endIndex >= 0 ? endIndex : 0;
  breadScrumItems = endIndex ? breadScrumItems.splice(0, endIndex) : [];
  return breadScrumItems;
}

export function prepareBreadScrumOnRefesh(breadScrumItems, state, elementList) {
  let tags = getStepTagsFromHashMap();

  tags.map((item, index) => {
    let step = findCurrentGroupByTag(elementList, item);
    if (step !== undefined) {
      breadScrumItems = insertBreadScrumItems(breadScrumItems, state, step);
    }
  });

  return breadScrumItems;
}

export function checkIsLastBreadScrumItems(breadScrumItems, state, itemTag) {
  return breadScrumItems[breadScrumItems.length - 1].tag == itemTag;
}

export function getStepPropertiesObject(state) {
  let recTestStepProperty = undefined;
  switch (state.actionOnElement) {
    case ACTIONS:
      if (state.selectedElement) {
        recTestStepProperty = prepareActionStep(state);
      }
      break;
    case VERIFICATIONS:
      recTestStepProperty = prepareVerificationStep(state);
      break;
      break;
    case LAUNCH_APP:
      recTestStepProperty = prepareLauchAppStep(state);
      break;
    case STEP:
      recTestStepProperty = prepareGroupStep(state);
      break;
  }
  return recTestStepProperty;
}
export function insertStepIntoElementList(state, stepName, deviceInteraction, stepImage) {
  let currentSteps = undefined;
  if (state.selectedGroup) {
    currentSteps = findCurrentGroup(state.elementList, state.selectedGroup.id);
    if (currentSteps) {
      currentSteps = currentSteps.recTestSteps;
    }
  }
  if (currentSteps == undefined) {
    currentSteps = state.elementList;
  }

  let exisitngElementList = currentSteps;
  // currentStepName:undefined,
  //  actionOnElement:ACTIONS,
  //  selectedAction:"1",
  // tapByIndexValue:undefined,
  // enterTextValue:undefined,
  // appendRandomText:false,
  // testDataModalVisible:false,
  // testDataVariableName:undefined,
  // testDataVariableDefaultValue:undefined,
  // testDataAppendRandomText:false,
  // testDataVariables:[],
  // elementList:[]
  let recTestStepProperty = undefined;
  if (deviceInteraction === undefined) {
    switch (state.actionOnElement) {
      case ACTIONS:
        if (state.selectedElement) {
          recTestStepProperty = prepareActionStep(state);
        }
        break;
      case VERIFICATIONS:
        recTestStepProperty = prepareVerificationStep(state);
        break;
      case SWIPE_OVER_ELEMENT:
        recTestStepProperty = prepareSwipeOverElementStep(state, state.actionOnElement);
        break;
      case LAUNCH_APP:
        recTestStepProperty = prepareLauchAppStep(state);
        break;
      case STEP:
        recTestStepProperty = prepareGroupStep(state);
        break;
    }
  } else {
    recTestStepProperty = prepareActionStep(state, deviceInteraction);
  }
  if (recTestStepProperty) {
    if (!deviceInteraction) {
      recTestStepProperty.stepName = state.currentStepName
        ? state.currentStepName
        : state.suggestedStepName
        ? state.suggestedStepName
        : stepName;
    }
    // if(state.currentStepIndex >-1 && exisitngElementList.length > 0){
    //   exisitngElementList.splice(state.currentStepIndex +1,0,{id:blankStepId,recTestStepProperty:recTestStepProperty});
    //  }else{
    //   exisitngElementList.push({id:blankStepId,recTestStepProperty:recTestStepProperty});
    //  }
    recTestStepProperty.screenShot = stepImage;
    // insertPropertyIntoElement(state.elementList,recTestStepProperty,state.currentStepId);

    exisitngElementList.map((v, k) => {
      if (v.tag == state.currentStepId) {
        v.recTestStepProperty = recTestStepProperty;
        v.takeElementScreenshot = state.takeStepScreenShot ? 1 : 0;
        v.removeAppPreviousSession = state.closeLaunchAppPreviousSession ? 1 : 0;
      }
    });
  }
  return state.elementList;
}
function insertPropertyIntoElement(elementList, recTestStepProperty, currentStepId) {
  elementList.map((v, k) => {
    if (v.id == currentStepId) {
      v["recTestStepProperty"] = recTestStepProperty;
    } else if (v.recTestStepProperty?.action == STEP) {
      insertPropertyIntoElement(v.recTestSteps, recTestStepProperty, currentStepId);
    }
  });
}
function prepareSwipeOverElementStep(state, deviceInteraction) {
  return {
    action: deviceInteraction,
    recSwipeAttributes: {
      direction: state.swipeOverElementDirection,
      percentage: state.swipeOverElementPercentage,
    },
    xpathGenerateMethod: state.selectedElement.customXpathMethod ? state.selectedElement.customXpathMethod : "DEFAULT",
    bounds: prepareBounds(state),
    systemGeneratedXpath: state.selectedElement.xpath,
    customXpath: state.selectedElement.customXpath,
    xpath: state.selectedElement.customXpath !== "" ? state.selectedElement.customXpath : state.selectedElement.xpath,
    actionValue: "",
    uniqueByText: undefined,
    uniqueByIndex: -1,
    elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
    stepName: deviceInteraction,
    userInputValue: undefined,
    recStepElementAttributes: state.selectedElement
      ? generateKeyValueArray(state.selectedElement.attributes)
      : undefined,
    appendRandomText: undefined,
    testDataVariableDefaultValue: undefined,
    isInputValueFetchFromTestData: undefined,
    testDataVariableName: undefined,
    testDataAppendRandomText: undefined,
    continueAfterFail: state.continueAfterFail,
    screenShot: state.selectedElementScreenShot.split("data:image/png;base64,")[1],
    operator: state.currentStepVerificationList.operator,
    recStepVerifyElementProperties: undefined,
  };
}
function prepareActionStep(state, deviceInteraction) {
  let text = "";
  if (state.enterTextValue) {
    text = state.enterTextValue;
  } else if (state.selectedElement && state.selectedElement.attributes && state.selectedElement.attributes.text) {
    text = state.selectedElement.attributes.text;
  } else if (
    state.selectedElement &&
    state.selectedElement.attributes &&
    state.selectedElement.attributes["content-desc"]
  ) {
    text = state.selectedElement.attributes["content-desc"];
  }
  if (
    text &&
    state.elementTexts.indexOf(state.elementTexts.filter((item) => item.id === state.referenceStepId)) === -1
  ) {
    let data = { id: state.currentStepId, value: text, isSavedStep: false };
    state.elementTexts.push(data);
  }

  if (deviceInteraction) {
    return {
      action: deviceInteraction,
      recSwipeAttributes: state.swipeDirection && getSwipeObject(state, state.swipeDirection),
      xpathGenerateMethod: undefined,
      bounds: undefined,
      systemGeneratedXpath: undefined,
      customXpath: undefined,
      xpath: undefined,
      actionValue: undefined,
      uniqueByText: undefined,
      uniqueByIndex: -1,
      elementMaxLoadTime: undefined,
      stepName: deviceInteraction,
      userInputValue: undefined,
      recStepElementAttributes: state.selectedElement
        ? generateKeyValueArray(state.selectedElement.attributes)
        : undefined,
      appendRandomText: undefined,
      testDataVariableDefaultValue: undefined,
      isInputValueFetchFromTestData: undefined,
      testDataVariableName: undefined,
      testDataAppendRandomText: undefined,
      continueAfterFail: state.continueAfterFail,
      screenShot: state.selectedElementScreenShot.split("data:image/png;base64,")[1],
      operator: state.currentStepVerificationList.operator,
      recStepVerifyElementProperties: state.currentStepVerificationList.data,
    };
  } else {
    return {
      action: state.selectedAction,
      xpathGenerateMethod: state.selectedElement.customXpathMethod
        ? state.selectedElement.customXpathMethod
        : "DEFAULT",
      bounds: prepareBounds(state),
      systemGeneratedXpath: state.selectedElement.xpath,
      customXpath: state.selectedElement.customXpath,
      xpath: state.selectedElement.customXpath !== "" ? state.selectedElement.customXpath : state.selectedElement.xpath,
      actionValue:
        state.selectedAction === ELEMENT_ACTION.SEND_KEYS &&
        state.selectedElement.args &&
        state.selectedElement.args.length == 1
          ? state.selectedElement.args[0]
          : "",
      uniqueByText: state.selectedElement.uniqueByText,
      uniqueByIndex: state.tapByIndexValue === "" ? -1 : state.tapByIndexValue,
      elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
      stepName: state.currentStepName,
      userInputValue: state.enterTextValue,
      recStepElementAttributes: state.selectedElement
        ? generateKeyValueArray(state.selectedElement.attributes)
        : undefined,
      appendRandomText: state.appendRandomText,
      testDataVariableDefaultValue: state.testDataVariableDefaultValue,
      isInputValueFetchFromTestData: state.testDataVariableName && state.testDataVariableDefaultValue,
      testDataVariableName: state.testDataVariableName,
      testDataAppendRandomText: state.testDataAppendRandomText,
      continueAfterFail: state.continueAfterFail,
      screenShot: state.selectedElementScreenShot.split("data:image/png;base64,")[1],
      operator: state.currentStepVerificationList.operator,
      recStepVerifyElementProperties: state.currentStepVerificationList.data,
    };
  }
}
function prepareVerificationStep(state) {
  return {
    action: VERIFICATIONS,
    xpathGenerateMethod: state.selectedElement
      ? state.selectedElement.customXpathMethod
        ? state.selectedElement.customXpathMethod
        : "DEFAULT"
      : undefined,
    bounds: prepareBounds(state),
    systemGeneratedXpath: state.selectedElement ? state.selectedElement.xpath : undefined,
    customXpath: state.selectedElement ? state.selectedElement.customXpath : undefined,
    xpath: state.selectedElement
      ? state.selectedElement.customXpath !== ""
        ? state.selectedElement.customXpath
        : state.selectedElement.xpath
      : undefined,
    actionValue: state.selectedElement
      ? state.selectedAction === ELEMENT_ACTION.SEND_KEYS &&
        state.selectedElement.args &&
        state.selectedElement.args.length == 1
        ? state.selectedElement.args[0]
        : ""
      : undefined,
    uniqueByText: state.selectedElement ? state.selectedElement.uniqueByText : undefined,
    uniqueByIndex: state.tapByIndexValue === "" ? -1 : state.tapByIndexValue,
    elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
    stepName: state.currentStepName,
    userInputValue: state.enterTextValue,
    recStepElementAttributes: state.selectedElement
      ? generateKeyValueArray(state.selectedElement.attributes)
      : undefined,
    appendRandomText: state.appendRandomText,
    testDataVariableDefaultValue: state.testDataVariableDefaultValue,
    isInputValueFetchFromTestData: state.testDataVariableName && state.testDataVariableDefaultValue,
    testDataVariableName: state.testDataVariableName,
    testDataAppendRandomText: state.testDataAppendRandomText,
    continueAfterFail: state.continueAfterFail,
    screenShot: state.selectedElementScreenShot.split("data:image/png;base64,")[1],
    operator: state.currentStepVerificationList.operator,
    recStepVerifyElementProperties: state.currentStepVerificationList.data,
  };
}
export function prepareRecordingEditStep(state, savedElement) {
  let saveElementProperties = savedElement["recTestStepProperty"];
  let selectedElement = {};
  state.selectedAction = saveElementProperties["action"];
  state.actionOnElement = actionOnElementMapping[saveElementProperties["action"]];
  selectedElement["customXpathMethod"] = saveElementProperties["xpathGenerateMethod"];
  selectedElement[""] = saveElementProperties["bounds"];
  selectedElement["xpath"] = saveElementProperties["systemGeneratedXpath"];
  selectedElement["customXpath"] = saveElementProperties["customXpath"];
  selectedElement["attributes"] = prepareObjectByKeyValue(saveElementProperties["recStepElementAttributes"]);
  if (savedElement["actionValue"] && saveElementProperties.action === ELEMENT_ACTION.SEND_KEYS) {
    selectedElement["args"][0] = saveElementProperties["actionValue"];
  }
  selectedElement["uniqueByText"] = saveElementProperties["uniqueByText"];
  state.tapByIndexValue = saveElementProperties["uniqueByIndex"] !== -1 ? saveElementProperties["uniqueByIndex"] : "";
  state.currentStepName = saveElementProperties["stepName"];
  state.enterTextValue = saveElementProperties["userInputValue"];
  state.appendRandomText = saveElementProperties["appendRandomText"];
  state.testDataVariableDefaultValue = saveElementProperties["testDataVariableDefaultValue"];
  state.testDataVariableName = saveElementProperties["testDataVariableName"];
  state.testDataAppendRandomText = saveElementProperties["testDataAppendRandomText"];
  state.continueAfterFail = saveElementProperties["continueAfterFail"];
  state.selectedElementScreenShot = saveElementProperties["screenShot"]
    ? "data:image/png;base64," + saveElementProperties["screenshot"]
    : "";
  state.currentStepVerificationList["operator"] = saveElementProperties["operator"]
    ? saveElementProperties["operator"]
    : "And";
  state.currentStepVerificationList["data"] = saveElementProperties["recStepVerifyElementProperties"];
  state.selectedAppLaunch = saveElementProperties["packageName"];
  if (
    saveElementProperties["recSwipeAttributes"] &&
    saveElementProperties["recSwipeAttributes"]["direction"] &&
    saveElementProperties["recSwipeAttributes"]["percentage"]
  ) {
    state.swipeDirection = saveElementProperties["recSwipeAttributes"]["direction"];
    switch (saveElementProperties["recSwipeAttributes"]["direction"]) {
      case SWIPE_DIRECTION.DOWN:
        state.swipeDownPercentage = saveElementProperties["recSwipeAttributes"]["percentage"];
        break;
      case SWIPE_DIRECTION.LEFT:
        state.swipeLeftPercentage = saveElementProperties["recSwipeAttributes"]["percentage"];
        break;
      case SWIPE_DIRECTION.RIGHT:
        state.swipeRightPercentage = saveElementProperties["recSwipeAttributes"]["percentage"];
        break;
      case SWIPE_DIRECTION.UP:
        state.swipeUpPercentage = saveElementProperties["recSwipeAttributes"]["percentage"];
        break;
      default:
        state.swipeUpPercentage = undefined;
        break;
    }
  }
  if (saveElementProperties["alwaysRun"] === 1) {
    state.selectedGroupAction = GROUP_ACTION.ALWAYS_RUN;
  } else if (saveElementProperties["runAfterElementVerification"] === 1) {
    state.selectedGroupAction = GROUP_ACTION.CUSTOM;
  }
  return selectedElement;
}

function prepareLauchAppStep(state) {
  return {
    action: LAUNCH_APP,
    packageName: state.selectedAppLaunch,
    removeAppPreviousSession: state.closeLaunchAppPreviousSession ? 1 : 0,
    stepName: state.currentStepName,
    continueAfterFail: state.continueAfterFail,
    takeStepScreenShot: state.takeStepScreenShot,
  };
}

function prepareGroupStep(state) {
  switch (state.selectedGroupAction) {
    case GROUP_ACTION.ALWAYS_RUN:
      return {
        action: STEP,
        alwaysRun: 1,
        runAfterElementVerification: 0,
        stepName: state.currentStepName,
        operator: state.currentStepVerificationList.operator,
      };
    case GROUP_ACTION.CUSTOM:
      let tempData = prepareVerificationStep(state);
      tempData["action"] = STEP;
      tempData["alwaysRun"] = 0;
      tempData["runAfterElementVerification"] = 1;
      return tempData;
  }
}

export function generateKeyValueArray(listItems) {
  let data = [];
  let keys = listItems ? Object.keys(listItems) : [];
  keys.map((key) => {
    data.push({ name: key, value: listItems[key] });
  });
  return data;
}
function prepareObjectByKeyValue(data) {
  let result = {};
  data &&
    data.length > 0 &&
    data.map((v, k) => {
      result[v.key] = v.value;
    });
  return result;
}
export function prepareBounds(state) {
  let bounds = "";
  if (state.selectedElement) {
    if (isAndroid(state.currentDeviceScreenSource)) {
      bounds = state.selectedElement.attributes ? state.selectedElement.attributes.bounds : "";
    }
    if (isIOS(state.selectedElement)) {
      bounds = state.selectedElement.attributes
        ? "[" + state.selectedElement.attributes.x + "," + state.selectedElement.attributes.y + "]"
        : "";
    }
  }
  return bounds;
}

export function getSwipeObject(direction, newRecTestStepActionDetail) {
  let swipe = { direction: direction };
  switch (direction) {
    case SWIPE_DIRECTION.DOWN:
      swipe["percentage"] = newRecTestStepActionDetail.swipeDownPercentage;
      break;
    case SWIPE_DIRECTION.LEFT:
      swipe["percentage"] = newRecTestStepActionDetail.swipeLeftPercentage;
      break;
    case SWIPE_DIRECTION.RIGHT:
      swipe["percentage"] = newRecTestStepActionDetail.swipeRightPercentage;
      break;
    case SWIPE_DIRECTION.UP:
      swipe["percentage"] = newRecTestStepActionDetail.swipeUpPercentage;
      break;
    default:
      swipe["percentage"] = 0;
      break;
  }
  return swipe;
}

export function isContainTextBoxProperties(selectedElementProperties) {
  return selectedElementProperties
    ? selectedElementProperties.clickable === "true" &&
        selectedElementProperties.enabled === "true" &&
        selectedElementProperties.focusable === "true" &&
        selectedElementProperties.focused === "true"
    : false;
}
export function selectedAction(elementAction, selectedElement, selectedElementAttributes) {
  if (elementAction === ACTIONS && selectedElement && selectedElement.length > 0 && selectedElementAttributes) {
    selectedElement[0].recElementLocatorProperties = selectedElement[0].recElementLocatorProperties.filter(
      (item) => item.mobileElements.length > 0
    );
    if (selectedElement.length > 0) {
      if (
        isContainTextBoxProperties(selectedElementAttributes) &&
        selectedElement[0].recElementLocatorProperties.filter((item) => item.mobileElements.length === 1).length > 0
      ) {
        return ELEMENT_ACTION.SEND_KEYS;
      } else if (
        !isContainTextBoxProperties(selectedElementAttributes) &&
        selectedElement[0].recElementLocatorProperties.filter((item) => item.mobileElements.length === 1).length > 0
      ) {
        return ELEMENT_ACTION.TAP;
      } else if (
        !isContainTextBoxProperties(selectedElementAttributes) &&
        selectedElement[0].recElementLocatorProperties.filter((item) => item.mobileElements.length > 1).length > 0
      ) {
        return ELEMENT_ACTION.TAP_BY_INDEX;
      }
    }
  }
  return elementAction;
}
export function actionValue(value) {
  if (value === ELEMENT_ACTION.TAP_BY_INDEX) {
    return "Tap on <<text>> Index";
  } else if (value === ELEMENT_ACTION.SEND_KEYS) {
    return "Insert text into <<text>>";
  } else if (value === ELEMENT_ACTION.CLEAR_TEXT) {
    return "Clear Text";
  } else if (value === ELEMENT_ACTION.TAP) {
    return "Tap on <<text>>";
  } else if (value === ELEMENT_ACTION.LAUNCH_APP) {
    return "Launch App";
  } else if (value === ELEMENT_ACTION.LONG_PRESS) {
    return "Long press on <<text>>";
  } else if (value === VERIFICATIONS) {
    return "Verify <<text>>";
  } else if (value === STEP) {
    return "Verify";
  }
  return "Verify <<text>>";
}

export function capitalizeWords(str) {
  if (str) {
    str = str.toLowerCase().split(" ");
    if (str.length > 0) {
      str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1);
    }
    return str.join(" ");
  }
  return str;
}

export function lowerCaseWords(str) {
  if (str) {
    return str.charAt(0).toLowerCase() + str.slice(1).toUpperCase();
  }
}
export function validKeyPress(key) {
  return textNumericSymbol.includes(key);
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
export function isInvalidTokenMessage(response) {
  if (response.message && response.message === "invalid_token") {
    localStorage.clear();
    cookies.set("xpressDeviceToken", "", { expires: 0 });
    return true;
  }
  return false;
}
export function updateResponse(response) {
  isInvalidTokenMessage(response);
  if (response.error && response.error_description) {
    if (response.error === "invalid_token") {
      localStorage.clear();
      cookies.set("xpressToken", "", { expires: 0 });
      cookies.set("xpressEmail", "", { expires: 0 });
      // window.location.reload();
      // showNotification("error", "Token Expired", "");
    }
    response = {
      status: ERROR_STATUS,
      msg: response.error_description,
      data: [],
    };
  } else if (checkForInValidUserActionPermissionDenied(response)) {
    showNotification("error", "Invalid User Action Permission Denied!");
  }
  return response;
}
export function checkForInValidUserActionPermissionDenied(response) {
  return response.message === "Invalid User Action Permission Denied!";
}
export function escapeSpecialCaseChar(text) {
  let replacedText = text.replace(/[ -[\]{}&:;<>()*+?.,\\^$|#\s]/g, "\\$&");
  replacedText = replacedText.replace(/["]/g, "\\\\$&");
  replacedText = replacedText.replace(/[']/g, "\\$&");
  // console.log(replacedText);
  return replacedText;
}

export function escapeSpecialCaseCharAndroid(text) {
  if ("\\" == text) {
    return "\\\\";
  } else if ('"' == text) {
    // console.log("in double quote");
    return '\\"';
  } else if ("'" == text) {
    return "'";
  }

  // let replacedText = text.replace(/[\]/["]/g, '\\$&');
  return text;
}

export function formatDate(date) {
  var d = new Date(date),
    dformat =
      [d.getMonth() + 1, d.getDate(), d.getFullYear()].join("/") +
      " " +
      [d.getHours(), d.getMinutes(), d.getSeconds()].join(":");
  return dformat;
}

export function updateStepOrderId(stepList) {
  stepList.map((v, k) => {
    if (v.tag == null || v.tag.split(JSON.stringify(stepList)).length > 2) {
      v.tag = getUniqueId();
    }
    v.orderId = k + 1;
  });
  return stepList;
}

export function isPortrait(orientation) {
  return orientation && orientation.toLowerCase() === PORTRAIT.toLowerCase();
}
export function getTimeAgo(time) {
  if (!time) return;
  time = time.replace(/\.\d+/, ""); // remove milliseconds
  time = time.replace(/-/, "/").replace(/-/, "/");
  time = time.replace(/T/, " ").replace(/Z/, " UTC");
  time = time.replace(/([\+\-]\d\d)\:?(\d\d)/, " $1$2"); // -04:00 -> -0400
  time = new Date(time * 1000 || time);

  var now = new Date();
  var seconds = ((now.getTime() - time) * 0.001) >> 0;
  var minutes = seconds / 60;
  var hours = minutes / 60;
  var days = hours / 24;
  var years = days / 365;

  return (
    templates.prefix +
    ((seconds < 45 && template("seconds", seconds)) ||
      (seconds < 90 && template("minute", 1)) ||
      (minutes < 45 && template("minutes", minutes)) ||
      (minutes < 90 && template("hour", 1)) ||
      (hours < 24 && template("hours", hours)) ||
      (hours < 42 && template("day", 1)) ||
      (days < 30 && template("days", days)) ||
      (days < 45 && template("month", 1)) ||
      (days < 365 && template("months", days / 30)) ||
      (years < 1.5 && template("year", 1)) ||
      template("years", years)) +
    templates.suffix
  );
}
export function getSecandsToTimeString(timestamp) {
  if (!timestamp) return;
  var hours = Math.floor(timestamp / 60 / 60);
  var minutes = Math.floor(timestamp / 60) - hours * 60;
  var seconds = timestamp % 60;
  return (hours != 0 ? hours + " hr " : "") + minutes + " min " + seconds + " sec";
}
var templates = {
  prefix: "",
  suffix: " ago",
  seconds: "less than a min",
  minute: "about a min",
  minutes: "%d mins",
  hour: "about an hr",
  hours: "about %d hrs",
  day: "a day",
  days: "%d days",
  month: "about a month",
  months: "%d months",
  year: "about a year",
  years: "%d years",
};
var template = function (t, n) {
  return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
};

export function isLandscape(orientation) {
  return orientation && orientation.toLowerCase() == LANDSCAPE.toLowerCase();
}

// 0 # Protrait
// 1 # Landscape
// 2 # Protrait Reversed
// 3 # Landscape Reversed

export function isPotraitType(typeValue) {
  return typeValue == "0" || typeValue == "2";
}

// export function isLandscapeType(typeValue) {
//   return typeValue == "1" || typeValue == "3";
// }

export function showDeviceAction(deviceActions, actionName, deviceState) {
  if (deviceActions) {
    for (var i = 0, len = deviceActions.length; i < len; i++) {
      if (actionName == deviceActions[i].actions) {
        if (deviceActions[i].isForRecording == 1 && deviceState == DEVICE_STATUS.RECORDING) return true;
        else if (deviceState != DEVICE_STATUS.RECORDING) {
          return true;
        }
      }
    }
  }

  return false;
}

export function removeIosElement(
  element,
  scaleRatioX,
  scaleRatioY,
  imgWidth,
  imgHeight,
  stepAction,
  isAdvanceElementSelectorDrawerVisible
) {
  let x1 = parseInt(element.attributes.x),
    x2 = parseInt(element.attributes.x) + parseInt(element.attributes.width),
    y1 = parseInt(element.attributes.y),
    y2 = parseInt(element.attributes.y) + parseInt(element.attributes.height);
  // Twitter Notifications Page was acusing the issue
  if (element.attributes["accessible"] && element.attributes["accessible"] == "false") {
    return true;
  }
  if (!isAdvanceElementSelectorDrawerVisible) {
    let top = parseInt(element.attributes.y) + element.attributes.height / scaleRatioY;
    let width = (x2 - x1) / scaleRatioX;
    let height = (y2 - y1) / scaleRatioY;
    if (
      element.attributes["name"] === undefined &&
      (parseInt(width) >= parseInt(imgWidth) ||
        parseInt(height) >= parseInt(imgHeight) ||
        parseInt(top) + parseInt(height) > imgHeight) &&
      stepAction !== SWIPE_OVER_ELEMENT
    ) {
      return true;
    }
    if (element.attributes["visible"] == "false") {
      return true;
    }
  }
  return false;
}

export function removeAndroidElement(
  element,
  scaleRatioX,
  scaleRatioY,
  deviceScreenWidth,
  deviceScreenHeight,
  selectedAction,
  isAdvanceElementSelectorDrawerVisible
) {
  const { x1, y1, x2, y2 } = parseCoordinates(element);
  if (x1 && y1 && x2 && y2) {
    // let left = (x1 / scaleRatioX);
    // let top = (y1 / scaleRatioY);
    let width = (x2 - x1) / scaleRatioX;
    let height = (y2 - y1) / scaleRatioY;

    if ("android.widget.FrameLayout" == element.attributes["class"]) {
      if (
        selectedAction !== SWIPE_OVER_ELEMENT &&
        !isAdvanceElementSelectorDrawerVisible &&
        parseInt(width) >= parseInt(deviceScreenWidth * 0.8)
      ) {
        return true;
      }
    }

    // Element Width is 80% greater than device Screen Width and element has more than one child
    if (
      selectedAction !== SWIPE_OVER_ELEMENT &&
      !isAdvanceElementSelectorDrawerVisible &&
      parseInt(width) >= parseInt(deviceScreenWidth * 0.8) &&
      element.children.length > 0 &&
      (element.attributes["clickable"] == "false" ||
        element.children.filter((item) => item.attributes["clickable"] == "true").length > 0)
    ) {
      return true;
    }
    // Element Height is 80% greater than device Screen Height
    else if (parseInt(height) >= parseInt(deviceScreenHeight * 0.8)) {
      return true;
    } else if (
      selectedAction !== SWIPE_OVER_ELEMENT &&
      !isAdvanceElementSelectorDrawerVisible &&
      element.children.length == 1 &&
      element.children[0].children.length == 0 &&
      element.children[0].attributes["text"] != "" &&
      (element.children[0].attributes["content-desc"] != undefined ||
        element.children[0].attributes["content-desc"] != "") &&
      ((element.attributes["content-desc"] == undefined && element.attributes["content-desc"] == "") ||
        element.attributes["text"] == "")
    ) {
      return true;
    }
    // else if( (element.attributes['class'] == 'android.view.View' && element.attributes['text'] != undefined && element.attributes['text'] == "") &&
    // (element.attributes['content-desc'] == undefined || element.attributes['content-desc'] == "")){
    //   return true;
    // }
    else if (
      selectedAction !== SWIPE_OVER_ELEMENT &&
      !isAdvanceElementSelectorDrawerVisible &&
      element.attributes["text"] != undefined &&
      element.attributes["text"] == "" &&
      (element.attributes["content-desc"] == undefined || element.attributes["content-desc"] == "") &&
      element.children.length > 0
    ) {
      return true;
    }
  }
  return false;
}

export function removeChildElement(element) {
  if (
    element.children.length == 1 &&
    element.children[0].children.length == 0 &&
    element.children[0].attributes["text"] == "" &&
    (element.children[0].attributes["content-desc"] == undefined ||
      element.children[0].attributes["content-desc"] == "") &&
    ((element.attributes["content-desc"] != undefined && element.attributes["content-desc"] != "") ||
      element.attributes["text"] != "")
  ) {
    element.children = [];
  }
}
export function getSelectedElementText(selectedElement) {
  let text = "";
  if (selectedElement?.attributes) {
    if (checkNotUndefinedAndNullAndBlank(selectedElement.attributes.text)) {
      text = selectedElement.attributes.text;
    } else if (checkNotUndefinedAndNullAndBlank(selectedElement.attributes["content-desc"])) {
      text = selectedElement.attributes["content-desc"];
    } else if (checkNotUndefinedAndNullAndBlank(selectedElement.attributes["label"])) {
      text = selectedElement.attributes["label"];
    } else if (checkNotUndefinedAndNullAndBlank(selectedElement.attributes["value"])) {
      text = selectedElement.attributes["value"];
    } else {
      text = selectedElement.attributes["class"];
    }
  }
  return text;
}

export function isVerificationListIsNotEmpty(verificationList) {
  //Check Length of element
  if (verificationList && verificationList.length === 0) {
    return false;
  }
  return verificationList ? validateVerificationsFieldsForMandatory(verificationList) : false;
}

function validateVerificationsFieldsForMandatory(verificationList) {
  let status = true;
  if (verificationList && verificationList.length === 0) {
    status = false;
  }
  for (let i = 0; i < verificationList.length; i++) {
    if (verificationList[i].type === "GROUP") {
      status = validateVerificationsFieldsForMandatory(verificationList[i].childRecStepVerifyElementProperties);
      if (!status) {
        break;
      }
    } else if (
      verificationList[i].key === "" &&
      verificationList[i].type !== "VerifyExistence" &&
      verificationList[i].key === verificationExistenceValues.does_not_exist
    ) {
      status = false;
      break;
    } else if (
      inArray(verificationList[i].key, verificationDropdownValuesMandatory) &&
      (verificationList[i].referenceStepTag === undefined || verificationList[i].referenceStepTag === "") &&
      ((["Contains to reference text", "Equals to reference text"].includes(verificationList[i].key) &&
        verificationList[i].referenceStep === undefined) ||
        verificationList[i].key === "Equals to content description")
    ) {
      status = false;
      break;
    } else if (
      inArray(verificationList[i].key, verificationDropdownValuesMandatory) &&
      (verificationList[i].refTestData === undefined || verificationList[i].refTestData?.id === undefined) &&
      ["Contains value of Test Data Key", "Equals value of Test Data Key"].includes(verificationList[i].key)
    ) {
      status = false;
      break;
    } else if (
      inArray(verificationList[i].key, verificationDropdownValuesMandatory) &&
      verificationList[i].value === "" &&
      !(
        ["Contains to reference text", "Equals to reference text"].includes(verificationList[i].key) ||
        verificationList[i].key === "Equals to content description"
      )
    ) {
      status = false;
      break;
    } else if (
      inArray(verificationList[i].key, verificationDropdownValuesMandatory) &&
      verificationList[i].value === "" &&
      !["Contains value of Test Data Key", "Equals value of Test Data Key"].includes(verificationList[i].key)
    ) {
      status = false;
      break;
    } else if (
      verificationList[i].type === "VerifyExistence" &&
      verificationList[i].key ===
        verificationExistenceValues.does_not_exist(
          verificationList[i].referenceStep === undefined || verificationList[i].referenceStep.tag === undefined
        )
    ) {
      status = false;
      break;
    } else if (
      verificationList[i].key === "Equals to custom reference text" &&
      (verificationList[i].child === undefined || verificationList[i].child.length === 0)
    ) {
      status = false;
      break;
    }
  }
  return status;
}

export function getAllowedElementsForReference(data, referenceActionStep = [], self) {
  if (
    self !== undefined &&
    referenceActionStep.filter((item) => item.tag === self.tag || item.id === self.id).length === 0
  ) {
    referenceActionStep.push({
      id: self.id,
      name:
        self.recTestStepProperty === undefined || self.recTestStepProperty?.stepName === undefined
          ? "Self"
          : self.recTestStepProperty?.action === "step"
          ? self.recTestStepProperty?.preConditionTitle
          : self.recTestStepProperty?.stepName,
      tag: self.tag,
      action: self.recTestStepProperty?.action,
    });
  }
  if (data) {
    for (let i = 0; i < data.length; i++) {
      if (
        (data[i].recTestStepProperty?.action === ELEMENT_ACTION.SEND_KEYS ||
          data[i].recTestStepProperty?.action === ELEMENT_ACTION.TAP ||
          data[i].recTestStepProperty?.action === ELEMENT_ACTION.TAP_BY_INDEX ||
          data[i].recTestStepProperty?.action === ELEMENT_ACTION.LONG_PRESS ||
          data[i].recTestStepProperty?.action === VERIFICATIONS) &&
        referenceActionStep.filter((_item) => _item.tag === data[i].tag).length === 0
      ) {
        referenceActionStep.push({
          id: data[i].id,
          name:
            data[i].recTestStepProperty?.stepName === undefined ? data[i].name : data[i].recTestStepProperty?.stepName,
          tag: data[i].tag,
          action: data[i].recTestStepProperty?.action,
        });
      }
      // else if (data[i].tag === self.tag || data[i].id === self.id) {
      //   return referenceActionStep;
      // }
      else if (data[i].recTestStepProperty?.action === STEP) {
      } else if (data[i].tag === self.tag || data[i].id === self.id) {
        return referenceActionStep;
      } else if (data[i].recTestStepProperty?.action === STEP) {
        referenceActionStep = [...getAllowedElementsForReference(data[i].recTestSteps, referenceActionStep, self)];
      }
    }
  }

  // data &&
  //   data.map((item, index) => {
  //     if (
  //        (item.recTestStepProperty?.action === ELEMENT_ACTION.SEND_KEYS ||
  //         item.recTestStepProperty?.action === ELEMENT_ACTION.TAP ||
  //         item.recTestStepProperty?.action === ELEMENT_ACTION.TAP_BY_INDEX ||
  //         item.recTestStepProperty?.action === ELEMENT_ACTION.LONG_PRESS ||
  //         item.recTestStepProperty?.action === VERIFICATIONS) &&
  //       referenceActionStep.filter((_item) => _item.tag === item.tag).length === 0
  //     ) {
  //       referenceActionStep.push({
  //         id: item.id,
  //         name: item.recTestStepProperty?.stepName === undefined ? item.name : item.recTestStepProperty?.stepName,
  //         tag: item.tag,
  //         action: item.recTestStepProperty?.action,
  //       });
  //     } else if (item.recTestStepProperty?.action === STEP) {
  //       referenceActionStep = [...getAllowedElementsForReference(item.recTestSteps, referenceActionStep)];
  //     }
  //   });
  return referenceActionStep;
}

export function showTextVerification(element) {
  if (element) {
    if (
      checkNotUndefinedAndNullAndBlank(element["content-desc"]) ||
      checkNotUndefinedAndNullAndBlank(element["text"]) ||
      checkNotUndefinedAndNullAndBlank(element["label"]) ||
      checkNotUndefinedAndNullAndBlank(element["value"])
    ) {
      return true;
    }
  }
  return false;
}

export function getVerificationPresenceValues(element, isForView) {
  let VerificationPresenceValues = {
    "Presence of element": "By Locator",
  };

  if (showTextVerification(element)) {
    VerificationPresenceValues = {
      "Presence of element By Text": "By Text",
      "Presence of element": "By Locator",
    };
  }
  return VerificationPresenceValues;
}

export function calculateAndroidDeviceScreenSize(props, inner) {
  let baseWidth = 611;
  let baseHeight = 1100;
  let padding = 0.05;
  if (
    props.deviceDetail &&
    props.deviceDetail["deviceModel"] &&
    props.deviceDetail["deviceModel"].includes("Portal+")
  ) {
    baseWidth = 400; //1080 //baseHeight
    baseHeight = 711; //1920 //baseWidth
    padding = 0.01;
  } else if (
    props.deviceDetail &&
    props.deviceDetail["deviceModel"] &&
    props.deviceDetail["deviceModel"].includes("Portal")
  ) {
    baseWidth = 800;
    baseHeight = 1280;
    padding = props.recording ? 0.15 : 0.01;
  }

  const imgBaseWidth = props.currentScreenOrientation == "Portrait" ? baseWidth : baseHeight;
  const imgBaseHeight = props.currentScreenOrientation == "Portrait" ? baseHeight : baseWidth;

  //this calculates the padding %
  const height = inner.clientHeight - inner.clientHeight * padding;
  const width = inner.clientWidth - inner.clientWidth * padding;

  let imgWidth = width;
  //calculates hight based on aspect ratio
  let imgHeight = (imgWidth * imgBaseHeight) / imgBaseWidth;

  //if height is greater than the inner container, set the maximun size and recalculate width base on max-height
  if (imgHeight > height && props.currentScreenOrientation == "Portrait") {
    imgHeight = height;
    imgWidth = (imgHeight * imgBaseWidth) / imgBaseHeight;
  }
  return { height: imgHeight, width: imgWidth };
}
export function showNotification(type, message, showNotification) {
  if (type === ERROR_TYPE || showNotification) {
    notification[type]({
      message: <div className="text-dark font-weight-500 text-transform-capital">{type}</div>,
      description: <div className="text-light1 font-size-12">{message}</div>,
      duration: 1.5,
      icon: <img className="mt-10" src={getNotificationIcon(type)} alt="icon" />,
    });
  }

  // return {height:700,width:410};
}

// Notification icon
const getNotificationIcon = (notificationType) => {
  switch (notificationType) {
    case SUCCESS_TYPE:
      return SUCCESS_ICON;
    case ERROR_TYPE:
      return ERROR_ICON;
    case WARNING_TYPE:
      return WARNING;
    case INFO_TYPE:
      return INFO_ICON;
    default:
      break;
  }
};

// export function redirectUserOnInvalidAction(message, deviceFarmId) {
//   if (message === INVALID_USER_PERMISSION_MESSAGE_FROM_API) {
//     notification["error"]({
//       message: "Device Session",
//       description: "Permission denied for accessing device. Please acquire before using!!",
//     });
//     history.push("/farms/" + (deviceFarmId ? deviceFarmId : ""));
//   }
// }

let defaultPorts = { http: 80, https: 443 };

export function TLSColumn(t) {
  return t.request && "https" === t.request.scheme ? "col-tls-https" : "col-tls-http";
}

export function prityURL(e) {
  var t = "";
  return defaultPorts[e.scheme] !== e.port && (t = ":" + e.port), e.scheme + "://" + e.pretty_host + t + e.path;
}

export function IconColumn(e) {
  if (!e.response) return "resource-icon-plain";
  var t = getContentType(e.response) || "";
  return 304 === e.response.status_code
    ? "resource-icon-not-modified"
    : 300 <= e.response.status_code && e.response.status_code < 400
    ? "resource-icon-redirect"
    : t.indexOf("image") >= 0
    ? "resource-icon-image"
    : t.indexOf("javascript") >= 0
    ? "resource-icon-js"
    : t.indexOf("css") >= 0
    ? "resource-icon-css"
    : t.indexOf("html") >= 0
    ? "resource-icon-document"
    : "resource-icon-plain";
}

//TODO
export function methodStatus(e) {
  var t = e,
    s = void 0;
  if (t.error && "Connection killed" === t.error.msg) {
    return "fa-times";
  } else if (t.error) {
    return "fa-exclamation";
  }
}

export function MethodColumn(t) {
  return t.request.method;
}

export function StatusCodeColorColumn(t) {
  let s = "darkred";
  t.response && 100 <= t.response.status_code && t.response.status_code < 200
    ? (s = "green")
    : t.response && 200 <= t.response.status_code && t.response.status_code < 300
    ? (s = "darkgreen")
    : t.response && 300 <= t.response.status_code && t.response.status_code < 400
    ? (s = "lightblue")
    : t.response && 400 <= t.response.status_code && t.response.status_code < 500
    ? (s = "lightred")
    : t.response && 500 <= t.response.status_code && t.response.status_code < 600 && (s = "lightred");
  return s;
}

export function StatusCodeColumn(t) {
  return t.response && t.response.status_code;
}

export function SizeColumn(t) {
  return formatSize((t.response && t.response.contentLength) || 0);
}

export function TimeColumn(t) {
  return t.response ? formatTimeDelta(1e3 * (t.response.timestamp_end - t.request.timestamp_start)) : "...";
}

export function formatSize(e) {
  if (0 === e) return "0";
  for (var t = ["b", "kb", "mb", "gb", "tb"], r = 0; r < t.length && !(Math.pow(1024, r + 1) > e); r++);
  var o;
  return (o = e % Math.pow(1024, r) == 0 ? 0 : 1), (e / Math.pow(1024, r)).toFixed(o) + t[r];
}

export function formatTimeDelta(e) {
  for (var t = e, r = [1e3, 60, 60], o = 0; Math.abs(t) >= r[o] && o < r.length; ) {
    t /= r[o];
    o++;
  }
  return Math.round(t) + ["ms", "s", "min", "h"][o];
}

// export function formatTimeStamp(e) {
//   return new Date(1e3 * e).toISOString().replace("T", " ").replace("Z", "");
// }

export function getContentType(e) {
  var t = get_first_header(e, /^Content-Type$/i);
  if (t) return t.split(";")[0].trim();
}

export function get_first_header(e, t) {
  if (
    (e._headerLookups ||
      Object.defineProperty(e, "_headerLookups", {
        value: {},
        configurable: !1,
        enumerable: !1,
        writable: !1,
      }),
    !(t in e._headerLookups))
  ) {
    for (var r, s = 0; s < e.headers.length; s++)
      if (e.headers[s][0].match(t)) {
        r = e.headers[s];
        break;
      }
    e._headerLookups[t] = r ? r[1] : void 0;
  }
  return e._headerLookups[t];
}

export function checkListIsEmpty(data) {
  return data.length === 0;
}
export function checkNull(data) {
  return data === null;
}
export function checkUndefined(data) {
  return data === undefined;
}
export function checkNotUndefined(data) {
  return data !== undefined;
}
export function checkNotUndefinedAndNull(data) {
  return checkNotUndefined(data) && data !== null;
}
export function checkNotUndefinedAndNullAndBlank(data) {
  return data !== undefined && data !== null && data !== "";
}

export function checkNotUndefinedAndNotNullAndListNotEmpty(data) {
  return data !== undefined && data !== null && data.length > 0;
}

export function checkRecTestStepIsNotStep(recTestStep) {
  return recTestStep !== undefined && recTestStep !== null && recTestStep.recTestStepProperty?.action !== STEP;
}

export function checkRecTestStepIsStep(recTestStep) {
  return recTestStep !== undefined && recTestStep !== null && recTestStep.recTestStepProperty?.action === STEP;
}

export function hasLengthGreaterThanOne(data) {
  return data && data?.length > 1;
}

export function validURL(str) {
  var pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
}

export function isMobileDevice() {
  return typeof window.orientation !== "undefined" || navigator.userAgent.indexOf("IEMobile") !== -1;
}

export function findCurrentGroup(steps, id) {
  let data = undefined;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i].recTestStepProperty?.action == STEP) {
      if (steps[i].id == id) {
        data = steps[i];
        break;
      }
      data = findCurrentGroup(steps[i].recTestSteps, id);
      if (data != undefined) {
        break;
      }
    }
  }
  return data;
}

export function removeUnsavedStepFromGroup(steps) {
  steps &&
    steps.map((v, k) => {
      if (v.recTestStepProperty?.action === STEP && v.id === undefined) {
        steps.splice(k, 1);
      } else if (v.recTestStepProperty?.action !== STEP && v.id === undefined) {
        steps.splice(k, 1);
      } else if (v.recTestStepProperty?.action == STEP) {
        removeUnsavedStepFromGroup(v.recTestSteps);
      }
    });
}

export function findCurrentGroupByTag(steps, tag) {
  let data = undefined;
  steps &&
    steps.map((v, k) => {
      if (v.recTestStepProperty?.action == STEP && v.tag == tag) {
        data = v;
        return;
      } else if (v.recTestStepProperty?.action == STEP) {
        data = findCurrentGroupByTag(v.recTestSteps, tag);
        return;
      }
    });
  return data;
}

export function backToScenarioList(from, id) {
  let path = history.location.pathname;
  if (from === "recording") {
    path = path.replace(/[0-9]+\/recording\//g, "");
    path = path.replace(/\/\//g, "/");
  } else if (from === "execute") {
    path = path.replace(/[0-9]+\/execute\//g, "");
    path = path.replace(/\/\//g, "/");
  }
  history.push(path);
}
export function updatePathOnBreadCrumsChange(tag) {
  if (tag === RECORDING_SET_NAME) {
    history.push("#");
  } else if (window.location.hash !== "") {
    let tagIndex = window.location.hash.indexOf(tag);
    if (tagIndex > 1) {
      let hash = window.location.hash.slice(0, tagIndex);
      if (hash.lenght > 1 && hash.slice(-1) === "#") {
        hash = hash.slice(0, hash.length - 1);
        history.push(hash);
      }
    } else if (tagIndex == 1) {
      history.push("#" + tag);
    } else {
      history.push("#");
    }
  }
}

export function getCurrentStepTagFromHashMap(elementList) {
  let tags = history.location.hash.split("#").filter((tag) => tag != "");
  return tags.length > 0 ? tags[tags.length - 1] : "";
}

export function getStepTagsFromHashMap(elementList) {
  return history.location.hash.split("#").filter((tag) => tag != "");
}

function findParentElement(elementList, childId, parentObj) {
  let data = undefined;
  elementList.map((v, k) => {
    if (v.tag == childId) {
      data = parentObj;
      return;
    } else if (v.recTestSteps && v.recTestSteps.length > 0) {
      data = findParentElement(v.recTestSteps, childId, v);
      return;
    }
  });
  return data;
}
// export function updateStartPreviewInURL(runId) {
//   history.push(history.location.pathname + (history.location.pathname.slice(-1) !== "/" ? "/" : "") + runId);
// }
function getTextFromRecElementProperty(recElementProperties) {
  let text = "";
  let fetchedRecElementProperties = recElementProperties.filter(
    (recElementProperty) =>
      ["text", "content-desc", "label", "value", "name"].includes(recElementProperty.name) &&
      recElementProperty.value !== ""
  );
  if (fetchedRecElementProperties.length > 0) {
    fetchedRecElementProperties.map((fetchedRecElementProperty, index) => {
      if (
        fetchedRecElementProperty.value !== undefined &&
        fetchedRecElementProperty.value !== "" &&
        !text.includes("'" + fetchedRecElementProperty.value + "'")
      ) {
        text += (index !== 0 ? " or '" : "'") + fetchedRecElementProperty.value + "'";
      }
    });
  }
  return text;
}
export function suggestedStepNameFromElement(selectedElement, value) {
  if (selectedElement) {
    let text = selectedElement.attributes.text;
    if (
      (text === undefined || text === "") &&
      selectedElement.attributes["content-desc"] !== undefined &&
      selectedElement.attributes["content-desc"] !== ""
    ) {
      text = selectedElement.attributes["content-desc"];
    } else if (
      (text === undefined || text === "") &&
      selectedElement.attributes["label"] !== undefined &&
      selectedElement.attributes["label"] !== ""
    ) {
      text = selectedElement.attributes["label"];
    } else if (
      (text === undefined || text === "") &&
      selectedElement.attributes["value"] !== undefined &&
      selectedElement.attributes["value"] !== ""
    ) {
      text = selectedElement.attributes["value"];
    } else if (
      (text === undefined || text === "") &&
      selectedElement.attributes["name"] !== undefined &&
      selectedElement.attributes["name"] !== ""
    ) {
      text = selectedElement.attributes["name"];
    }
    if (value !== SWIPE_OVER_ELEMENT) {
      return actionValue(value).replace("<<text>>", text ? text : "element");
    } else {
      return "Swipe Over Element";
    }
  }
  return "";
}
export function suggestedStepName(selectedElements, value) {
  let text = "";
  selectedElements.map((selectedElement, index) => {
    if (selectedElement && selectedElement.recElementProperties.length > 0) {
      text += (index !== 0 ? " or " : "") + getTextFromRecElementProperty(selectedElement.recElementProperties);
    } else if (selectedElement && selectedElement.searchKey !== "") {
      if (isNotNullAndNotEmptyAndNotUndefined(selectedElement.searchValue)) {
        text += (index !== 0 ? " or '" : "'") + (selectedElement.searchValue + "'");
      } else if (
        selectedElement.useTestData === 1 &&
        isNotNullAndNotEmptyAndNotUndefined(selectedElement?.testData?.name)
      ) {
        text += (index !== 0 ? " or '" : "'") + (selectedElement.testData.name + "'");
      }
    }
  });
  if (value !== SWIPE_OVER_ELEMENT) {
    return actionValue(value).replace("<<text>>", text ? text : "element");
  } else {
    return "Swipe Over Element";
  }
}

export function endDeviceSession(
  action,
  platform,
  serverId,
  targetUniqueId,
  deviceFarmId,
  isLogStarted,
  isProxyStarted
) {
  // if (platform === "A" || platform === "ANDROID") {
  //   action.stopDeviceScreenStreaming(serverId, targetUniqueId);
  // } else
  if (action) {
    // if (platform === I || platform === "ios" || platform === A || platform === ANDROID) {
    //   action.stopIOSScreenStreaming(serverId, targetUniqueId);
    // }
    forceStopLogs(action, serverId, targetUniqueId, isLogStarted);

    stopProxy(action, serverId, targetUniqueId, platform, deviceFarmId, isProxyStarted);
  }
  history.push("/farms/" + (deviceFarmId ? deviceFarmId : ""));
}
export function getUniqueId() {
  let prefix = uniqid.process();
  let suffix = uniqid.time();
  return uniqid(prefix, suffix);
}
function forceStopLogs(action, serverId, targetUniqueId, isLogStarted) {
  if (isLogStarted) {
    if (window.confirm("This will stop the device logs.")) {
      action.forceStopDeviceLogs({ serverId, targetUniqueId });
    }
  }
}

function stopProxy(action, serverId, targetUniqueId, platform, deviceFarmId, isProxyStarted) {
  if (isProxyStarted) {
    action.stopProxy({
      targetUniqueId: targetUniqueId,
      serverId: serverId,
      platformName: platform,
      deviceFarmId: deviceFarmId,
    });
  }
}

export function prepareTreeViewData(data, unSavedRecTestStep, isForView, isRecordingStarted, parentId, unSavedAction) {
  let treeViewData = [];
  data &&
    data.map((v, index) => {
      const selectable = !isForView ? (v.recTestStepProperty?.action === STEP ? true : false) : false;
      const isHaveChildStep =
        v.recTestSteps && v.recTestSteps.filter((item) => item.recTestStepProperty?.action === STEP).length > 0;
      if (v.recTestStepProperty?.action === STEP) {
        treeViewData.push({
          className: getPreviewStatusClassName(v.previewStatus) + " " + (isHaveChildStep ? "parent" : ""),
          title: (
            <RecTestStep
              index={index + 1}
              selectable={selectable}
              key={v.id}
              data={v}
              unSavedAction={unSavedAction}
              isForView={isForView}
              isRecordingStarted={isRecordingStarted}
              parentId={parentId}
              selected={unSavedRecTestStep ? v.tag === unSavedRecTestStep.tag || v.id === unSavedRecTestStep.id : false}
            />
          ),
          data: v,
          parentId: parentId,
          selected: unSavedRecTestStep ? v.id === unSavedRecTestStep.id || v.tag === unSavedRecTestStep.tag : false,
          selectable: selectable,
          key: v.id,
          // switcherIcon: ({ selected }) => { return (selected ? <FontAwesomeIcon icon={faChevronDown} /> : <FontAwesomeIcon icon={faChevronRight} />) },
          children:
            v.recTestSteps && v.recTestSteps.length > 0
              ? prepareTreeViewData(v.recTestSteps, unSavedRecTestStep, isForView, isRecordingStarted, v.id)
              : [],
        });
      }
    });
  return treeViewData;
}

export function prepareReactTestStepAndActionTreeData(
  recTestStepList,
  searchValueForImportRecTestStepAction,
  disableRecTestStepActionsForImport,
  disableRecTestStepForImport,
  parentId,
  importType = undefined,
  stepType
) {
  let treeViewData = [];
  recTestStepList &&
    recTestStepList.map((v, k) => {
      if (
        searchValueForImportRecTestStepAction === undefined ||
        searchValueForImportRecTestStepAction === "" ||
        parentId ||
        JSON.stringify(v).toUpperCase().includes(searchValueForImportRecTestStepAction.toUpperCase())
      ) {
        let checkable = true;
        if (
          (disableRecTestStepActionsForImport &&
            (ELEMENT_ACTION.SWIPE === v.action ||
              ELEMENT_ACTION.TAP === v.action ||
              ELEMENT_ACTION.LONG_PRESS === v.action ||
              ELEMENT_ACTION.TAP_BY_INDEX === v.action ||
              ELEMENT_ACTION.SEND_KEYS === v.action ||
              ELEMENT_ACTION.LAUNCH_APP === v.action ||
              ELEMENT_ACTION.SWIPE_OVER_ELEMENT === v.action ||
              DEVICE_INTERACTION.HOME === v.action ||
              DEVICE_INTERACTION.BACK === v.action ||
              ELEMENT_ACTION.PAUSE === v.action ||
              ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA === v.action ||
              ELEMENT_ACTION.CAPTURE_SCREENSHOT === v.action ||
              VERIFICATIONS === v.action)) ||
          (disableRecTestStepForImport && (v.action === STEP || v.action == "scenario")) ||
          (stepType === TEST_SCENARIOS && importType === TEST_STEP_BLOCKS && parentId)
        ) {
          checkable = false;
        }
        let key = "";
        if (importType === TEST_STEP_BLOCKS) {
          key = v.id;
        } else {
          if (v.action === "scenario") {
            key = v.action === "scenario" ? "scenario#" + v.id : "";
          } else if (v.action === STEP && v.parentAssocId === null) {
            key = "scenario#" + v.recTestScenarioId + "-step#" + v.assocId;
          } else if (v.action === STEP && v.parentAssocId !== null) {
            key = "scenario#" + v.recTestScenarioId + "-step#" + v.parentAssocId + "-subStep#" + v.assocId;
          } else {
            key = "scenario#" + v.recTestScenarioId + "-step#" + v.parentAssocId + "-action#" + v.assocId;
          }
        }
        treeViewData.push({
          className: v.action === "scenario" ? "scenario" : STEP,
          title: v.name,
          data: v,
          key,
          parentId: parentId,
          checkable,
          disabled: !checkable,
          selectable: false,
          children:
            v.recTestSteps && v.recTestSteps.length > 0
              ? prepareReactTestStepAndActionTreeData(
                  v.recTestSteps,
                  searchValueForImportRecTestStepAction,
                  disableRecTestStepActionsForImport,
                  disableRecTestStepForImport,
                  v.id,
                  importType,
                  stepType
                )
              : [],
        });
      }
    });
  return treeViewData;
}
// Scenario treeview data of create test plan functionality
export function prepareCreateTestPlanTreeData(
  recTestStepList,
  disableRecTestStepActionsForImport,
  disableRecTestStepForImport,
  parentId,
  checkableNode
) {
  let treeViewData = [];
  recTestStepList &&
    recTestStepList.map((v, k) => {
      {
        let checkable = true;
        if (
          disableRecTestStepActionsForImport &&
          (ELEMENT_ACTION.SWIPE === v.action ||
            ELEMENT_ACTION.TAP === v.action ||
            ELEMENT_ACTION.LONG_PRESS === v.action ||
            ELEMENT_ACTION.TAP_BY_INDEX === v.action ||
            ELEMENT_ACTION.SEND_KEYS === v.action ||
            ELEMENT_ACTION.LAUNCH_APP === v.action ||
            ELEMENT_ACTION.SWIPE_OVER_ELEMENT === v.action ||
            DEVICE_INTERACTION.HOME === v.action ||
            DEVICE_INTERACTION.BACK === v.action ||
            VERIFICATIONS === v.action)
        ) {
          checkable = false;
        }
        treeViewData.push({
          className: v.action === "scenario" ? "scenario" : STEP,
          title: v.name,
          data: v,
          key: v.id,
          parentId: parentId,
          checkable: checkableNode,
          selectable: false,
          children:
            v.recTestSteps && v.recTestSteps.length > 0
              ? prepareCreateTestPlanTreeData(
                  v.recTestSteps,
                  disableRecTestStepActionsForImport,
                  disableRecTestStepForImport,
                  v.id,
                  false
                )
              : [],
        });
      }
    });
  return treeViewData;
}

export function getStepActionIcon(action) {
  switch (action) {
    case ELEMENT_ACTION.TAP:
      return ICON_TAP_ACTION;
    case ELEMENT_ACTION.TAP_BY_INDEX:
      return ICON_TAP_BY_INDEX_ACTION;
    case ELEMENT_ACTION.LONG_PRESS:
      return ICON_LONG_PRESS_ACTION;
    case ELEMENT_ACTION.SEND_KEYS:
      return ICON_ENTER_TEXT_ACTION;
    case ELEMENT_ACTION.CLEAR_TEXT:
      return ICON_CLEAR_TEXT_ACTION;
    case ELEMENT_ACTION.LAUNCH_APP:
      return ICON_LAUNCH_APP_ACTION;
    // case STEP:
    //   return faFolder;
    case SWIPE_OVER_ELEMENT:
      return ICON_SWIPE_ELEMENT_ACTION;
    case ELEMENT_ACTION.SWIPE:
      return ICON_SCROLL_PAGE_ACTION;
    case VERIFICATIONS:
      return ICON_ADD_VERIFICATION;
    case "HOME":
      return ICON_NAVIGATE_HOME_ACTION;
    case "ENTER":
      return ICON_KEYPAD_ENTER_ACTION;
    case "BACK":
      return ICON_NAVIGATE_BACK_ACTION;
    case ELEMENT_ACTION.PAUSE:
      return ICON_ADD_DELAY_ACTION;
    case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
      return ICON_ASSIGN_VALUE_TO_TEST_DATA_ACTION;
    case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
      return ICON_SCREENSHOT_PAGE_ACTION;
    default:
      return ICON_TAP_ACTION;
  }
}

export function getStepActionIconTooltip(action) {
  switch (action) {
    case ELEMENT_ACTION.TAP:
      return "Tap";
    case ELEMENT_ACTION.TAP_BY_INDEX:
      return "Tap By Index";
    case ELEMENT_ACTION.LONG_PRESS:
      return "Long Press";
    case ELEMENT_ACTION.SEND_KEYS:
      return "Send Key";
    case ELEMENT_ACTION.CLEAR_TEXT:
      return "Clear Text";
    case ELEMENT_ACTION.LAUNCH_APP:
      return "Launch App";
    case SWIPE_OVER_ELEMENT:
      return "Swipe Over Element";
    case ELEMENT_ACTION.SWIPE:
      return "Swipe";
    case VERIFICATIONS:
      return "Verifications";
    case "HOME":
      return "Home";
    case "ENTER":
      return "Enter";
    case "BACK":
      return "Back";
    case ELEMENT_ACTION.PAUSE:
      return "Pause";
    case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
      return "Insert Value To Output Test Data";
    case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
      return "Capture Screenshot";
    default:
      return undefined;
  }
}

export function platformAbbrevMapping(platformFullName) {
  switch (platformFullName) {
    case ANDROID:
      return A;
    case IOS:
      return I;
    case A:
      return ANDROID;
    case I:
      return IOS;
    default:
      return "";
  }
}

export function getPreviewStatusClassName(previewStatus) {
  if (previewStatus && previewStatus !== "null")
    switch (previewStatus) {
      case "Passed":
        return "passed";
      case "Skipped":
        return "skipped";
      case "Failed":
        return "failed";
      case "inprogress":
        return "inprogress";
      case "CONTINUE_AFTER_FAILED":
        return "CONTINUE_AFTER_FAILED";
      default:
        return "";
    }
}

// export function getPreviewStatusBorder(previewStatus, selected) {
//   if (previewStatus && previewStatus !== "null")
//     switch (previewStatus) {
//       case "Passed":
//         return selected ? "2px solid #7cd175 !important;" : "1px solid #7cd175 !important;";
//       case "Skipped":
//         return selected ? "2px solid #f7ba7b !important;" : "1px solid #f7ba7b !important;";
//       case "Failed":
//         return selected ? "2px solid #ff4d4f !important" : "1px solid #ff4d4f !important";
//       case "inprogress":
//         return selected ? "2px solid #a13597 !important" : "1px solid #a13597 !important";
//       case "CONTINUE_AFTER_FAILED":
//         return selected ? "2px solid #a13597 !important" : "1px solid #a13597 !important";
//       default:
//         return selected ? "1px solid #d9d9d9" : "1px solid #1890ff";
//     }
//   return "1px solid #d9d9d9!important;";
// }

export function isActionNeedToSelectElement(action) {
  switch (action) {
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case SWIPE_OVER_ELEMENT:
    case ACTIONS:
    case VERIFICATIONS:
      return true;
    case ELEMENT_ACTION.LAUNCH_APP:
    case ELEMENT_ACTION.SWIPE:
    case ELEMENT_ACTION.PAUSE:
    case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
    case DEVICE_INTERACTION.HOME:
    case DEVICE_INTERACTION.BACK:
    case DEVICE_INTERACTION.ENTER:
    case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
      return false;
    default:
      return false;
  }
}

const isBoundBelongsTo = (bounds, x_coordinate, y_coordinate, selectedElementBounds) => {
  let splitSelectedElementBounds = selectedElementBounds.match(/[0-9]+/g);
  if (bounds) {
    let splitted = bounds.match(/[0-9]+/g);
    return (
      (parseInt(x_coordinate) >= parseInt(splitted[0]) &&
        parseInt(x_coordinate) <= parseInt(splitted[2]) &&
        parseInt(y_coordinate) >= parseInt(splitted[1]) &&
        parseInt(y_coordinate) <= parseInt(splitted[3])) ||
      // Other elements inside the range of selected Element
      (parseInt(splitted[0]) >= parseInt(splitSelectedElementBounds[0]) &&
        parseInt(splitted[2]) <= parseInt(splitSelectedElementBounds[2]) &&
        parseInt(splitted[1]) >= parseInt(splitSelectedElementBounds[1]) &&
        parseInt(splitted[3]) <= parseInt(splitSelectedElementBounds[3])) ||
      //
      // Other elements outside 10% of selected Element
      (parseInt(splitted[0]) >=
        parseInt(splitSelectedElementBounds[0]) - parseInt(splitSelectedElementBounds[0]) * 0.1 &&
        parseInt(splitted[2]) <=
          parseInt(splitSelectedElementBounds[2]) + parseInt(splitSelectedElementBounds[2]) * 0.1 &&
        parseInt(splitted[1]) >=
          parseInt(splitSelectedElementBounds[1]) - parseInt(splitSelectedElementBounds[1]) * 0.1 &&
        parseInt(splitted[3]) <=
          parseInt(splitSelectedElementBounds[3]) + parseInt(splitSelectedElementBounds[3]) * 0.1)

      // Other elements inside the range of selected Element
    );
  }
  return false;
};
export const findOtherElementsInPage = (
  elements,
  x_coordinate,
  y_coordinate,
  savedElements,
  scaleRatioX,
  scaleRatioY,
  deviceScreenSize,
  selectedElementAttributes,
  platform
) => {
  let imgWidth = deviceScreenSize.split("x")[0];
  let imgHeight = deviceScreenSize.split("x")[1];
  elements.map((v) => {
    if (platform === ANDROID) {
      if (isBoundBelongsTo(v.attributes.bounds, x_coordinate, y_coordinate, selectedElementAttributes.bounds)) {
        if (
          !removeAndroidElement(v, scaleRatioX, scaleRatioY, imgWidth, imgHeight, undefined) &&
          !removeChildElement(v) &&
          v.attributes.class !== "hierarchy"
        ) {
          savedElements.push(v);
        }
      }
    }
    if (platform === IOS) {
      if (
        isBoundBelongsTo(
          prepareIosBounds(v.attributes),
          x_coordinate,
          y_coordinate,
          prepareIosBounds(selectedElementAttributes)
        )
      ) {
        if (!removeIosElement(v, scaleRatioX, scaleRatioY, imgWidth, imgHeight, undefined)) {
          savedElements.push(v);
        }
      }
    }
    if (v.children && v.children.length > 0) {
      findOtherElementsInPage(
        v.children,
        x_coordinate,
        y_coordinate,
        savedElements,
        scaleRatioX,
        scaleRatioY,
        deviceScreenSize,
        selectedElementAttributes,
        platform
      );
    }
  });
};
export function prepareIosBounds(attributes) {
  return (
    "[" +
    attributes["x"] +
    "," +
    attributes["y"] +
    "][" +
    (parseInt(attributes["x"]) + parseInt(attributes["width"])) +
    "," +
    (parseInt(attributes["y"]) + parseInt(attributes["height"])) +
    "]"
  );
}

// export function actionNeedElementSelection(action) {
//   switch (action) {
//     case ELEMENT_ACTION.TAP:
//     case ELEMENT_ACTION.TAP_BY_INDEX:
//     case ELEMENT_ACTION.LONG_PRESS:
//     case ELEMENT_ACTION.SEND_KEYS:
//     case ELEMENT_ACTION.CLEAR_TEXT:
//     case VERIFICATIONS:
//     case SWIPE_OVER_ELEMENT:
//       return true;
//     case ELEMENT_ACTION.LAUNCH_APP:
//     case ELEMENT_ACTION.SWIPE:
//     case DEVICE_INTERACTION.HOME:
//     case DEVICE_INTERACTION.BACK:
//       return false;
//     default:
//       return true;
//   }
// }

export function getIsActionOrVerifications(action) {
  switch (action) {
    // case ACTIONS:
    // case ELEMENT_ACTION.TAP:
    // case ELEMENT_ACTION.TAP_BY_INDEX:
    // case ELEMENT_ACTION.LONG_PRESS:
    // case ELEMENT_ACTION.SEND_KEYS:
    // case ELEMENT_ACTION.CLEAR_TEXT:
    // case DEVICE_INTERACTION.HOME:
    // case DEVICE_INTERACTION.BACK:
    // case DEVICE_INTERACTION.ENTER:
    //   return "Action";
    // case VERIFICATIONS:
    // case STEP:
    //   return "Verifications";
    // case SWIPE_OVER_ELEMENT:
    //   return "Swipe Over Element";
    // case ELEMENT_ACTION.SWIPE:
    //   return "Swipe Direction";
    // case ELEMENT_ACTION.LAUNCH_APP:
    //   return "Launch App";
    // case ELEMENT_ACTION.PAUSE:
    //   return "Pause Run";
    // case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
    //   return "Insert value to Output Test Data ";
    // case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
    //   return "Capture Screenshot";
    // default:
    //   return "";
    case ACTIONS:
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case SWIPE_OVER_ELEMENT:
      return "OPERATION";
    case ELEMENT_ACTION.LAUNCH_APP:
    case ELEMENT_ACTION.PAUSE:
    case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
    case DEVICE_INTERACTION.HOME:
    case DEVICE_INTERACTION.BACK:
    case DEVICE_INTERACTION.ENTER:
    case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
    case ELEMENT_ACTION.SWIPE:
      return "GENERAL OPERATION";
    case VERIFICATIONS:
    case STEP:
      return "Verifications";
    default:
      return "";
  }
}

export function getIsActionOrVerificationsOrSteps(action) {
  switch (action) {
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.LONG_PRESS:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case SWIPE_OVER_ELEMENT:
    case ELEMENT_ACTION.SWIPE:
    case ELEMENT_ACTION.LAUNCH_APP:
      return "Action";
    case VERIFICATIONS:
      return "Verification";
    case STEP:
      return "Step";
    default:
      return "";
  }
}

// export function shouldShowActionCardTitle(action) {
//   switch (action) {
//     case ELEMENT_ACTION.TAP:
//     case ELEMENT_ACTION.TAP_BY_INDEX:
//     case ELEMENT_ACTION.LONG_PRESS:
//     case ELEMENT_ACTION.SEND_KEYS:
//     case ELEMENT_ACTION.CLEAR_TEXT:
//     case VERIFICATIONS:
//     case STEP:
//     case SWIPE_OVER_ELEMENT:
//       return false;
//     case ELEMENT_ACTION.SWIPE:
//     case ELEMENT_ACTION.LAUNCH_APP:
//     case ELEMENT_ACTION.PAUSE:
//     case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
//       return true;
//     default:
//       return false;
//   }
// }

// export function getIsVerifications(action) {
//   switch (action) {
//     case VERIFICATIONS:
//     case STEP:
//       return "Verifications";
//     default:
//       return "";
//   }
// }

// export function checkActionOrVerificationInputDisabled(
//   disableAction,
//   elementListByServer,
//   unSavedRecStepTag,
//   recTestStep
// ) {
//   let isInputDisabled =
//     //Check Recording should Started
//     disableAction &&
//     recTestStep.tag !== unSavedRecStepTag &&
//     elementListByServer.length === 0 &&
//     recTestStep.id === undefined;
//   if (
//     recTestStep.recTestStepProperty !== undefined &&
//     unSavedRecStepTag !== undefined &&
//     unSavedRecStepTag.length === 0
//   ) {
//     if (!isInputDisabled && recTestStep.recTestStepProperty && recTestStep.recTestStepProperty?.action === STEP) {
//       // Check for the Pre condition
//       if (
//         !(
//           recTestStep.recTestStepProperty &&
//           recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined)
//             .length === 0
//         )
//       ) {
//         isInputDisabled = true;
//       }
//     } else if (!isInputDisabled && recTestStep.id !== undefined) {
//       //check its should not saved Step
//       isInputDisabled = true;
//     }
//   }
//   //  else if (!isInputDisabled && recTestStep.id !== undefined) {
//   //   isInputDisabled = true;
//   // }
//   return isInputDisabled;
// }

export function isEmpty(value) {
  return value === "";
}

export function isTrim(value) {
  return value.trim() === "";
}

export function isNullOrUndefined(value) {
  return value === undefined || value === null;
}

export function isNullOrUndefinedOrEmpty(value) {
  return value === undefined || value === null || value === "";
}

export function isNullOrUndefinedOrEmptyOrNaN(value) {
  return value === undefined || value === null || value === "" || value === isNaN();
}

export function isNotNullAndNotEmptyAndNotUndefined(value) {
  return value !== undefined && value !== null && value !== "";
}

export function isArrayNotUndefinedNotNullNotEmpty(value) {
  return value && value !== null && value.length > 0;
}

export function isArrayNotUndefinedAndNotNull(value) {
  return value && value !== null;
}

export function hasBlankSpaces(str) {
  return str.match(/^\s+$/) !== null;
}

export function hasNotAllowedNegativeValue(e) {
  return (e.key === "-" || e.key === "e" || e.key === "E" || e.key === "+") && e.preventDefault();
}

export function isSameValue(str1, str2) {
  return str1 === str2;
}
export function isSubStep(recTestSteps) {
  return (
    recTestSteps &&
    recTestSteps.length > 0 &&
    recTestSteps[0].recTestStepProperty &&
    recTestSteps[0].recTestStepProperty?.action === STEP
  );
}

export const blockCollapseAction = (e) => {
  e.stopPropagation();
};

export const isElementSelectedForStep = (recElements) => {
  let unSavedRecElements = recElements.filter((recElement) => recElement.id === undefined || recElement.id === 0);
  for (let i = 0; i < unSavedRecElements.length; i++) {
    if (
      unSavedRecElements[i].elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN &&
      unSavedRecElements[i].recElementLocatorProperties.length === 0 &&
      unSavedRecElements[i].recElementProperties.length === 0
    ) {
      return false;
    } else if (
      unSavedRecElements[i].elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT &&
      (unSavedRecElements[i].searchKey === "" ||
        (isNullOrUndefinedOrEmpty(unSavedRecElements[i].searchValue) &&
          (isNullOrUndefinedOrEmpty(unSavedRecElements[i].useTestData) ||
            unSavedRecElements[i].useTestData === 0 ||
            (unSavedRecElements[i].useTestData === 1 &&
              (isNullOrUndefinedOrEmpty(unSavedRecElements[i].testData) ||
                Object.keys(unSavedRecElements[i].testData).length === 0)))))
    ) {
      return false;
    }
  }
  return true;
};
export const isCurrentRecTestStepDisable = (unSavedRecStepTag, recTestStep, disableAction) => {
  return (
    // check if global disable action
    disableAction ||
    recTestStep?.isSkipStep === 1 ||
    recTestStep?.parentSkipStep === 1 ||
    // check current step is un-saved
    (isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) && unSavedRecStepTag !== recTestStep?.tag)
  );
};

export const isStepPreConditionChange = (unSavedRecStepTag, recTestStep) => {
  return (
    recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    unSavedRecStepTag === recTestStep.tag &&
    recTestStep.id !== undefined &&
    recTestStep.recTestStepProperty?.previous_preConditionTitle !== undefined &&
    recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
    recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id === undefined).length === 0
  );
};
export const isNewPreconditionAdded = (recTestStep) => {
  return (
    recTestStep?.recTestStepProperty?.isStepHasPrecondition === 1 &&
    (recTestStep?.recTestStepProperty?.previous_preConditionTitle === undefined ||
      recTestStep?.recTestStepProperty?.previous_preConditionTitle === "") &&
    recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length === 0
  );
};

export const isNewLoopStepConditionAdded = (recTestStep) => {
  return (
    recTestStep?.recTestStepProperty?.isLoopStep === 1 &&
    recTestStep.recTestStepProperty?.previous_loopCount === undefined &&
    recTestStep.recTestStepProperty?.isLoopCountSaved !== undefined &&
    (recTestStep?.recTestStepProperty?.previous_preConditionTitle === undefined ||
      recTestStep?.recTestStepProperty?.previous_preConditionTitle === "") &&
    recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id !== undefined).length === 0
  );
};
export const isStepLoopStepChange = (unSavedRecStepTag, recTestStep) => {
  return (
    recTestStep?.recTestStepProperty?.isLoopStep === 1 &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    unSavedRecStepTag === recTestStep.tag &&
    recTestStep.id !== undefined &&
    (recTestStep.recTestStepProperty?.previous_loopCount > 0 ||
      (recTestStep.recTestStepProperty?.previous_preConditionTitle !== undefined &&
        recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
        recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id === undefined)
          .length === 0))
  );
};

export const isActionChange = (unSavedRecStepTag, recTestStep) => {
  return (
    recTestStep?.recTestStepProperty?.action !== STEP &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    unSavedRecStepTag === recTestStep.tag &&
    recTestStep?.id !== undefined &&
    (recTestStep.recTestStepProperty?.previous_stepName !== undefined ||
      recTestStep.recTestStepProperty?.previous_isContinueAfterFail !== undefined)
  );
};

export const showUnSavedNotification = (recTestStep, unSavedRecStepTag) => {
  let unSavedStatus = false;
  unSavedStatus = recTestStep?.tag === unSavedRecStepTag;
  //Check for Self Step
  if (!unSavedStatus && recTestStep?.recTestStepProperty?.isStepHasPrecondition === 1) {
    unSavedStatus = isStepPreConditionChange(unSavedRecStepTag, recTestStep);
    unSavedStatus = !unSavedStatus ? isNewPreconditionAdded(recTestStep) : unSavedStatus;
    unSavedStatus = !unSavedStatus
      ? recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
        recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id === undefined).length >
          0
      : unSavedStatus;
  }
  //Check for LoopStep
  if (!unSavedStatus && recTestStep?.recTestStepProperty?.isLoopStep === 1) {
    unSavedStatus = isStepLoopStepChange(unSavedRecStepTag, recTestStep);
    unSavedStatus = !unSavedStatus ? isNewLoopStepConditionAdded(recTestStep) : unSavedStatus;
    unSavedStatus = !unSavedStatus
      ? recTestStep.recTestStepProperty?.recStepVerifyElementProperties.length > 0 &&
        recTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter((item) => item.id === undefined).length >
          0
      : unSavedStatus;
  }
  if (!unSavedStatus && recTestStep?.recTestStepProperty?.action !== STEP) {
    unSavedStatus = isActionChange(unSavedRecStepTag, recTestStep);
  }

  // Check for Child Partially Saved
  if (
    !unSavedStatus &&
    recTestStep.recTestSteps &&
    recTestStep.recTestSteps.length > 0 &&
    recTestStep.recTestSteps[0].recTestStepProperty?.action === STEP
  ) {
    unSavedStatus = getPartiallySavedConditionalStep(unSavedRecStepTag, recTestStep.recTestSteps) !== undefined;
    for (let i = 0; i < recTestStep.recTestSteps.length; i++) {
      // Check for Child Step Action is unsaved
      if (
        !unSavedStatus &&
        recTestStep.recTestSteps[i].recTestSteps &&
        recTestStep.recTestSteps[i].recTestSteps.length > 0 &&
        recTestStep.recTestSteps[i].recTestSteps[0].recTestStepProperty?.action !== STEP
      ) {
        unSavedStatus =
          recTestStep.recTestSteps[i].recTestSteps.filter(
            (actionRecTestStep) =>
              actionRecTestStep.id === undefined ||
              checkAnyPreviousContainsInObject(actionRecTestStep.recTestStepProperty)
          ).length > 0;
      }
      if (unSavedStatus) {
        break;
      }
    }
  }
  if (
    !unSavedStatus &&
    recTestStep.recTestSteps &&
    recTestStep.recTestSteps.length > 0 &&
    recTestStep.recTestSteps[0].recTestStepProperty?.action !== STEP
  ) {
    unSavedStatus =
      recTestStep.recTestSteps.filter(
        (actionRecTestStep) =>
          actionRecTestStep.id === undefined || checkAnyPreviousContainsInObject(actionRecTestStep.recTestStepProperty)
      ).length > 0;
  }
  return unSavedStatus;
};

export const getPartiallySavedConditionalStep = (unSavedRecStepTag, recTestSteps) => {
  let recTestStep = undefined;
  for (let i = 0; i < recTestSteps?.length; i++) {
    if (
      isStepPreConditionChange(unSavedRecStepTag, recTestSteps[i]) ||
      isStepLoopStepChange(unSavedRecStepTag, recTestSteps[i]) ||
      isActionChange(unSavedRecStepTag, recTestSteps[i])
    ) {
      recTestStep = recTestSteps[i];
      break;
    } else if (recTestSteps[i].recTestSteps && recTestSteps[i].recTestSteps.length > 0) {
      recTestStep = getPartiallySavedConditionalStep(unSavedRecStepTag, recTestSteps[i].recTestSteps);
      if (recTestStep) {
        break;
      }
    }
  }
  return recTestStep;
};
export const totalMin = [];
for (let i = 0; i < 60; i++) {
  totalMin.push(i.toString());
  i = i + 4;
}

export const totalHours = [];
for (let i = 0; i < 24; i++) {
  totalHours.push(i.toString());
}

export const handleSelectProjectName = (val, orgId, setSelectProjectName, currentMenuOptionsSelected, runNumber) => {
  setSelectProjectName && setSelectProjectName(val);
  const reportUrl = "/" + orgId + "/project/" + val + "/" + REPORT_MANAGER;
  const url = "/" + orgId + "/project/" + val + "/" + currentMenuOptionsSelected;
  history.push(runNumber ? reportUrl : url);
};

export const checkElementExistIntoList = (list, key, compareValue) => {
  return list && list.filter((item) => item[key] === compareValue).length > 0;
};

// export function appendTooltipComponent(component, message) {
//   return (
//     <Tooltip color={TOOLTIP_BG_COLOR} title={message}>
//       {component}
//     </Tooltip>
//   );
// }

export function cloneArray(array) {
  return JSON.parse(JSON.stringify(array));
}

export function getDateTimeInFormat(value) {
  return moment(value).format(defaultDateTimeFormat);
}

export function getDateTimeInUTCFormat(value, format) {
  return moment(value).utc().format(format);
}

export function displayUTCDateFormatWithToolTip(date, format) {
  return (
    <Tooltip color={TOOLTIP_BG_COLOR} title={getDateTimeInUTCFormat(date, format) + " (GMT)"}>
      {getFormateDateAndTime(date, format)}
    </Tooltip>
  );
}

export function disablePastDate(current) {
  return moment().add(-1, "days") >= current || moment().add(1, "month") <= current;
}

export function isSameDate(selectedDate) {
  return moment(selectedDate) === moment(new Date());
}

export const getMomentDateRange = (start, finish) => {
  return [moment(start, appStatic.dateFormatForAttendance), moment(finish, appStatic.dateFormatForAttendance)];
};

export const getCurrentMonthRange = () => {
  const now = new Date();
  const firstDay = moment(new Date(now.getFullYear(), now.getMonth(), 1)).format(defaultDateTimeFormat);
  const lastDay = moment(new Date(now.getFullYear(), now.getMonth() + 1, 0)).format(defaultDateTimeFormat);
  return [firstDay, lastDay];
};

export const getPastHours = (date) => {
  var hours = [];
  if (isSameDate(date)) {
    for (var i = 0; i < moment().hour(); i++) {
      hours.push(i);
    }
  }
  return hours;
};

export const getPastMinutes = (selectedHour, date) => {
  var minutes = [];
  if (isSameDate(date)) {
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().minute(); i++) {
        minutes.push(i);
      }
    }
  }
  return minutes;
};

export const getPastSeconds = (selectedHour, selectedMinute, date) => {
  var seconds = [];
  if (isSameDate(date)) {
    if (selectedHour === moment().hour()) {
      for (var i = 0; i < moment().second(); i++) {
        seconds.push(i);
      }
    }
  }
  return seconds;
};

export const isStepHavePrecondition = (step) => {
  return step?.recTestStepProperty?.action === STEP && step?.recTestStepProperty?.isStepHasPrecondition === 1;
};

export const isStepHaveLoopStep = (step) => {
  return step?.recTestStepProperty?.action === STEP && step?.recTestStepProperty?.isLoopStep === 1;
};

export const redirectToURL = (url) => {
  const URL = url.toString();
  var win = window.open(URL, "_blank");
  win.focus();
};
// export const isValidAppUrl = (url) => {
//   let isValid = false;
//   if (validURL(url)) {
//     let ext = url.substring(url.indexOf(".") + 1);
//     if (ext === "apk" || ext === "ipa") {
//       isValid = true;
//     }
//     return isValid;
//   }
// };

// export const getDateFromDateTime = (dateTime, format) => {
//   return moment(dateTime).format(format ? format : "YYYY-MM-DD");
// };

// export const getTimeFromDateTime = (dateTime, format) => {
//   return moment(dateTime).format(format ? format : "HH:mm:ss");
// };

export const openTestPlanResultUrl = (orgId, data, projectId) => {
  const url = `/${orgId}/project/${projectId}/TestPlanResultDetails/${data}`;
  var win = window.open(url, "_blank");
  win.focus();
};

export const checkActionHaveRelativePointOption = (action) => {
  switch (action) {
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.TAP_BY_INDEX:
    case ELEMENT_ACTION.LONG_PRESS:
      return true;
    default:
      return false;
  }
};

export const fetchOtherRunIds = (action, allOtherRunIds) => {
  switch (action) {
    case OTHER_RUNS_OPTION.ALL:
      return allOtherRunIds?.all;
    case OTHER_RUNS_OPTION.PENDING:
      return allOtherRunIds?.pending;
    case OTHER_RUNS_OPTION.INVESTIGATED:
      return allOtherRunIds?.investigated;
    default:
  }
};

export const disableStepsDragging = (step, unSavedRecStepTag, stepType) => {
  return (
    (stepType === TEST_SCENARIOS && step.recTestScenarioRecTestStepAssocId === undefined) ||
    (stepType === TEST_STEP_BLOCKS && step.id === undefined) ||
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag)
  );
};

export const getOrdinal = (d) => {
  if (d > 3 && d < 21) return "th";
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
export const leftBorderColor = (data) => {
  if (data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    return "border-left-inProgress";
  } else if (data === TEST_PLAN_RESULT_TYPE.PASSED) {
    return "border-left-pass";
  } else if (data === TEST_PLAN_RESULT_TYPE.FAILED) {
    return "border-left-fail";
  } else if (data === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    return "border-left-skip";
  } else if (data === TEST_PLAN_RESULT_TYPE.ABORTED) {
    return "border-left-abort";
  } else if (data === TEST_PLAN_RESULT_TYPE.TERMINATED) {
    return "border-left-abort";
  } else {
    return "";
  }
};

export const navigateToTestRail = (e, data, i) => {
  e.stopPropagation();
  const testRailUrl =
    isNotNullAndNotEmptyAndNotUndefined(data?.testRailTestCaseUrl) &&
    data?.testRailTestCaseUrl.split(",").filter((j, ind) => ind === i);
  redirectToURL(testRailUrl);
};

export const getDeviceName = (deviceDetail, deviceServiceFarm) => {
  return deviceServiceFarm && deviceServiceFarm !== DEVICE_EXECUTION_FARM.XPRESS
    ? deviceDetail?.name
    : deviceDetail?.displayName && deviceDetail?.displayName !== "null"
    ? deviceDetail?.brandName + " " + deviceDetail?.displayName
    : deviceDetail?.brandName
    ? deviceDetail?.brandName + " " + deviceDetail?.name
    : "";
};

// export function redirectToPreviewDownload(orgId, runNumber, resultType) {
//   const url = `/${orgId}/TestPlanResultDetails/${runNumber}/previewDownloads/${resultType}`;
//   var win = window.open(url, "_blank");
//   win.focus();
// }

export const CustomText = (text) => {
  const newText = text.replaceAll("'", "*'").split("*");
  return (
    <span className={"font-size-13 white-space-pre-line"}>
      {newText.map((newText) => {
        if (newText.startsWith("'") && !newText.startsWith("' ")) {
          return <span style={{ color: "#444AEA" }}>{newText} </span>;
        }
        return <span>{newText} </span>;
      })}
    </span>
  );
};

export const searchAdvanceSelectorElements = (searchText, data) => {
  return (
    isNullOrUndefined(searchText) ||
    data?.attributes?.["resource-id"]?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.class?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.text?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.["content-desc"]?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.name?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.label?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.type?.toLowerCase()?.includes(searchText.toLowerCase()) ||
    data?.attributes?.value?.toLowerCase()?.includes(searchText.toLowerCase())
  );
};

export const isSelectedAllIds = (selectedIds, records) => {
  let selectedRecords = records && records?.filter((i) => selectedIds?.includes(i?.id));
  return records?.length && selectedRecords?.length === records?.length;
};

export const removeSelectedIds = (selectedIds, records) => {
  let id = [...selectedIds];
  records &&
    records.forEach((element) => {
      let index = id?.indexOf(element?.id);
      id.splice(index, 1);
    });
  return id;
};

export function addTimes(startTime, endTime) {
  var times = [0, 0, 0];
  var max = times.length;

  var a = (startTime || "").split(":");
  var b = (endTime || "").split(":");

  // normalize time values
  for (var i = 0; i < max; i++) {
    a[i] = isNaN(parseInt(a[i])) ? 0 : parseInt(a[i]);
    b[i] = isNaN(parseInt(b[i])) ? 0 : parseInt(b[i]);
  }

  // store time values
  for (var i = 0; i < max; i++) {
    times[i] = a[i] + b[i];
  }

  var hours = times[0];
  var minutes = times[1];
  var seconds = times[2];

  if (seconds >= 60) {
    var m = (seconds / 60) << 0;
    minutes += m;
    seconds -= 60 * m;
  }

  if (minutes >= 60) {
    var h = (minutes / 60) << 0;
    hours += h;
    minutes -= 60 * h;
  }

  return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2) + ":" + ("0" + seconds).slice(-2);
}

export const getDuration = (data) => {
  return data && data?.duration ? data?.duration.split(":") : "";
};

export const getTotalDuration = (data) => {
  return addTimes(addTimes(data?.waitDuration, data?.duration), data?.reportProcessingDuration);
};
export function getDatesInRange(startDate, endDate, includeGivenRangeDate = false) {
  const date = new Date(startDate.getTime());
  if (includeGivenRangeDate) {
    date.setDate(date.getDate());
  } else {
    date.setDate(date.getDate() + 1);
  }

  const dates = [];

  // Exclude end date
  while (date < new Date(endDate) + (includeGivenRangeDate ? 1 : 0)) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export const getUnSavedRecElement = (recElements, unSavedRecElementGuid) => {
  let filteredRecElements = undefined;
  for (let i = 0; i < recElements?.length; i++) {
    if (recElements[i].id === 0 || recElements[i].id === undefined || recElements[i].guid === unSavedRecElementGuid) {
      filteredRecElements = recElements[i];
      break;
    } else if (recElements[i].childRecElements && recElements[i].childRecElements.length > 0) {
      filteredRecElements = getUnSavedRecElement(recElements[i].childRecElements, unSavedRecElementGuid);
      if (filteredRecElements && Object.keys(filteredRecElements).length > 0) {
        break;
      }
    }
  }
  return filteredRecElements ? filteredRecElements : {};
};

export const getUnSavedRecElementCount = (recElements) => {
  return recElements?.filter((item) => item?.id === undefined || item?.id === 0).length;
};

export const updateUnSavedRecElement = (recElements, recElement) => {
  for (let i = 0; i < recElements.length; i++) {
    if (recElements[i].guid === recElement.guid) {
      recElements[i] = {
        ...recElements[i],
        ...recElement,
      };
    } else if (recElements[i].childRecElements && recElements[i].childRecElements.length > 0) {
      for (let j = 0; j < recElements[i].childRecElements.length; j++) {
        if (recElements[i].childRecElements[j].guid === recElement.guid) {
          recElements[i].childRecElements[j] = {
            ...recElements[i].childRecElements[j],
            ...recElement,
          };
        }
      }
    }
  }
  return recElements;
};

export const getNewRecElement = (action, recElementGuid = getUniqueId()) => {
  return {
    guid: recElementGuid,
    elementType: ElementType.DIFFERENT_VERSION,
    elementSelectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
    elementPurpose: undefined,
    recElementLocatorProperties: [],
    recElementProperties: [],
    elementMaxLoadTime: 2,
    swipeToBringElementToCenter: 0,
    horizontalRelativePoint: 50,
    verticalRelativePoint: 50,
    verificationOperator: "OR",
    recStepVerifyElementProperties: [],
    isActive: 1,
  };
};

export const resetElementProperties = (recElement) => {
  return {
    ...recElement,
    applicationStrings: undefined,
    recElementLocatorProperties: [],
    recElementProperties: [],
    recStepVerifyElementProperties: [],
  };
};

// export const isRecElementChanged = (recTestStepProperty, recElement) => {
//   let previousRecElement = getRecElement(recTestStepProperty?.previous_recElements, recElement.guid);
//   if (recElement.elementSelectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
//     if (previousRecElement) {
//       if (isRecElementOtherDetailsChanged(recTestStepProperty, recElement)) {
//         return true;
//       } else if (isRecElementPropertiesChanged(recTestStepProperty, recElement)) {
//         return true;
//       }
//     } else if (recElement.id === undefined || recElement.id === 0) {
//       return true;
//     }
//   }
//   if (recElement.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
//     if (
//       (recElement.useTestData === undefined || recElement.useTestData === 0) &&
//       isNotNullAndNotEmptyAndNotUndefined(recElement.searchValue)
//     ) {
//       return true;
//     } else if (
//       recElement.useTestData === 1 &&
//       recElement.testData !== undefined &&
//       recElement.testData?.id !== undefined
//     ) {
//       return true;
//     } else if (recElement.id === undefined || recElement.id === 0) {
//       return true;
//     }
//   }

//   return false;
// };

export const isRecElementOtherDetailsChanged = (recTestStepProperty, recElement) => {
  let previousRecElement = recTestStepProperty?.previous_recElements
    ? getRecElement(recTestStepProperty?.previous_recElements, recElement.guid)
    : [];
  if (previousRecElement) {
    if (previousRecElement.name !== recElement.name) {
      return true;
    } else if (previousRecElement.description !== recElement.description) {
      return true;
    } else if (previousRecElement.tags !== recElement.tags) {
      return true;
    } else if (previousRecElement.searchKey !== recElement.searchKey) {
      return true;
    } else if (previousRecElement.searchValue !== recElement.searchValue) {
      return true;
    } else if (previousRecElement.useTestData !== recElement.useTestData) {
      return true;
    } else if (previousRecElement.elementPurpose !== recElement.elementPurpose) {
      return true;
    }
  } else if (recElement.id === undefined || recElement.id === 0) {
    return true;
  }
  return false;
};

export const isRecElementPropertiesChanged = (recTestStepProperty, recElement) => {
  let previousRecElement = getRecElement(recTestStepProperty?.previous_recElements, recElement.guid);
  if (previousRecElement) {
    if (
      JSON.stringify(previousRecElement.recElementLocatorProperties) !==
      JSON.stringify(recElement.recElementLocatorProperties)
    ) {
      return true;
    } else if (
      JSON.stringify(previousRecElement.recElementProperties) !== JSON.stringify(recElement.recElementProperties)
    ) {
      return true;
    }
  } else if (
    recTestStepProperty?.previous_recElements?.length > 0 ||
    recTestStepProperty?.previous_recElements?.length === 0
  ) {
    return true;
  }
  return false;
};

export const isRecElementsPropertyChanged = (recTestStepProperty) => {
  let status = false;
  recTestStepProperty.recElements &&
    recTestStepProperty.recElements.map((recElement) => {
      if (isRecElementOtherDetailsChanged(recTestStepProperty, recElement)) {
        status = true;
        return;
      } else if (recElement.childRecElements && recElement.childRecElements.length > 0) {
        recElement.childRecElements.map((childRecElement) => {
          if (isRecElementOtherDetailsChanged(recTestStepProperty, childRecElement)) {
            status = true;
            return;
          }
        });
        if (status) {
          return;
        }
      }
    });
  return status;
};

export const isRecElementsChanged = (recTestStepProperty) => {
  let status = false;
  return recTestStepProperty?.recElements?.map((recElement) => {
    if (isRecElementPropertiesChanged(recTestStepProperty, recElement)) {
      status = true;
      return status;
    } else if (recElement.childRecElements && recElement.childRecElements.length > 0) {
      recElement.childRecElements.map((childRecElement) => {
        if (isRecElementPropertiesChanged(recTestStepProperty, childRecElement)) {
          status = true;
          return status;
        }
        return status;
      });
    }
    return status;
  });
};

export const getParentRefElementLocator = (recElement) => {
  let parentRefElementDetails = undefined;
  if (recElement.elementType === ElementType.DIFFERENT_VERSION) {
    parentRefElementDetails = recElement.recElementLocatorProperties.filter(
      (item) => item.generatedMethod === XpathGenerateMethod.PARENT_REF
    );
  }
  return parentRefElementDetails;
};

export const highlightResult = (resultString, isInvestigation) => {
  if (resultString) {
    let k = resultString.split(",");
    let arr = [];
    let m = k.map((s, p) => {
      if (s.includes("Total Scenario To Execute")) {
        arr.push(
          <span className="badge bg-secondary font-weight-700">
            {TEST_PLAN_RESULT_TYPE.TOTAL_SCENARIOS + ":" + s.split(":")[1]}
          </span>
        );
      } else if (s.includes("Total Devices") && !isInvestigation) {
        arr.push(
          <span className="badge bg-secondary font-weight-700">
            {TEST_PLAN_RESULT_TYPE.TOTAL_DEVICES + ":" + s.split(":")[1]}
          </span>
        );
      } else if (s.includes(TEST_PLAN_RESULT_TYPE.IN_PROGRESS)) {
        arr.push(
          <span>
            <Spin size="small" className="ml-5 mr-5" />
            <span className="badge bg-info font-weight-700">
              {" "}
              {s.split(":")[1]}
              {"(" + TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT + ")"}
            </span>
          </span>
        );
      } else if (s.includes("Passed")) {
        arr.push(
          <span className="badge bg-success font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(Passed)"}
          </span>
        );
      } else if (s.includes("Aborted")) {
        arr.push(
          <span className="badge bg-abort font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(Aborted)"}
          </span>
        );
      } else if (s.includes("TERMINATED")) {
        arr.push(
          <span className="badge bg-abort font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(Terminated)"}
          </span>
        );
      } else if (s.includes("QUEUED_WAITING_FOR_DEVICE_AVAILABILITY")) {
        arr.push(
          <span className="badge bg-secondary font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(" + TEST_PLAN_RESULT_TYPE.QUEUED_WAITING_FOR_DEVICE_AVAILABILITY_TXT + ")"}
          </span>
        );
      } else if (s.includes("Failed")) {
        arr.push(
          <span className="badge bg-danger font-weight-700 ml-5">
            {s.split(":")[1]}
            {!isInvestigation && "(Failed)"}
          </span>
        );
      } else if (s.includes(TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED)) {
        arr.push(
          <span className="badge bg-secondary font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(" + TEST_PLAN_RESULT_TYPE.WAITING_FOR_DEVICE_TO_EXECUTED_TXT + ")"}
          </span>
        );
      } else if (s.includes(TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT)) {
        arr.push(
          <span className="badge bg-uploading font-weight-700 ml-5">
            {s.split(":")[1]}
            {"(" + TEST_PLAN_RESULT_TYPE.UPLOADING_RESULT_TXT + ")"}
          </span>
        );
      } else if (s.includes("Skipped")) {
        arr.push(<span className="skipped-text ml-5">{s.split(":")[1]}</span>);
      } else if (s.includes("CONTINUE_AFTER_FAILED")) {
        arr.push(<span className="CONTINUE_AFTER_FAILED-text ml-5">{s.split(":")[1]}</span>);
      } else if (s.includes("Total")) {
      } else {
        arr.push(<span className="badge bg-secondary font-weight-700 ml-5">{s.split(":")[1]}</span>);
      }

      return s;
    });
    return arr;
  }
};

export const customDateRangeFilterList = [
  {
    title: "Today",
    name: "today",
  },
  {
    title: "Yesterday",
    name: "yesterday",
  },
  {
    title: "This Week",
    name: "thisWeek",
  },
  {
    title: "Last Week",
    name: "lastWeek",
  },
  {
    title: "This Month",
    name: "thisMonth",
  },
  {
    title: "Last Month",
    name: "lastMonth",
  },
  {
    title: "This Year",
    name: "thisYear",
  },
  {
    title: "Last Year",
    name: "lastYear",
  },
  {
    title: "Custom",
    name: "custom",
  },
  {
    title: "All",
    name: "all",
  },
];

export const getCustomDateFilter = (value) => {
  switch (value) {
    case "today":
      return [moment().format(dateHourMinuteFormat), moment().format(dateHourMinuteFormat)];
    case "yesterday":
      return [
        moment().subtract(1, "day").format(dateHourMinuteFormat),
        moment().subtract(1, "day").format(dateHourMinuteFormat),
      ];
    case "thisWeek":
      return [
        moment().startOf("week").format(dateHourMinuteFormat),
        moment().endOf("week").format(dateHourMinuteFormat),
      ];
    case "lastWeek":
      return [
        moment().subtract(1, "weeks").startOf("isoWeek").format(dateHourMinuteFormat),
        moment().subtract(1, "weeks").endOf("isoWeek").format(dateHourMinuteFormat),
      ];
    case "thisMonth":
      return [
        moment().startOf("month").format(dateHourMinuteFormat),
        moment().endOf("month").format(dateHourMinuteFormat),
      ];
    case "lastMonth":
      return [
        moment().subtract(1, "months").startOf("month").format(dateHourMinuteFormat),
        moment().subtract(1, "months").endOf("month").format(dateHourMinuteFormat),
      ];
    case "thisYear":
      return [
        moment().startOf("year").format(dateHourMinuteFormat),
        moment().endOf("year").format(dateHourMinuteFormat),
      ];
    case "lastYear":
      return [
        moment().subtract(1, "years").startOf("year").format(dateHourMinuteFormat),
        moment().subtract(1, "years").endOf("year").format(dateHourMinuteFormat),
      ];
    case "all":
      return ["", ""];
    case "custom":
      return "custom";
    default:
      break;
  }
};

export const getSelectedJobStatus = (value) => {
  switch (value) {
    case "IN_PROGRESS":
      return "Running Jobs";
    case "IN_QUEUE":
      return "In-Queue Jobs";
    case "DONE":
      return "Completed Jobs";
    default:
      break;
  }
};

export const getSelectedFrequencyStatus = (value) => {
  switch (value) {
    case ONETIME:
      return "One time";
    case RECURRING:
      return "Recurring";
    default:
      break;
  }
};

export const getFormateDateAndTime = (date, format) => {
  return date && moment(date).format(format);
};

export const showBuildTags = (tags) => {
  if (tags.length > 3) {
    return (
      <div className="display-flex">
        {tags.slice(0, 3)?.map((s) => (
          <BuildTag className="ellipse">{s}</BuildTag>
        ))}
        <Popover
          width="50%"
          content={
            <div className="display-flex flex-wrap " style={{ maxWidth: "500px" }}>
              {tags?.map((i) => (
                <BuildTag>{i}</BuildTag>
              ))}
            </div>
          }
          title="Tags"
          trigger="hover"
          placement="bottom"
        >
          <span className="font-color-blue cursor-pointer font-weight-medium mt-10">
            {tags?.slice(3).length === 1 ? `+${tags?.slice(3).length} other` : `+${tags?.slice(3).length} others`}
          </span>
        </Popover>
      </div>
    );
  } else {
    return (
      <>
        {!_.isEmpty(tags)
          ? tags
              .filter((i) => i)
              .map((s) => (
                <Tooltip color={TOOLTIP_BG_COLOR} title={s}>
                  <BuildTag className="ellipse cursor-pointer">{s}</BuildTag>
                </Tooltip>
              ))
          : " N/A"}
      </>
    );
  }
};

export const showPlatformIcon = (platform) => {
  switch (platform) {
    case ANDROID:
      return <img src={ANDROID_LOGO} alt="android" />;
    case IOS:
      return <img src={IOS_LOGO} alt="IOS" />;
    case WEB:
      return <img src={WEB_ICON} alt="Web" />;
    default:
      break;
  }
};

export const showPlatformTag = (platform) => {
  switch (platform) {
    case ANDROID:
      return <span className="projectlist-tag fontGreen">Android</span>;
    case IOS:
      return <span className="projectlist-tag fontBlue">{platform}</span>;
    case WEB:
      return <span className="projectlist-tag">{platform}</span>;
    default:
      break;
  }
};

export const getMaxRetryStep = (retrySteps, step) => {
  if (retrySteps?.length > 0) {
    let sameRetrySteps = retrySteps.filter((retryStep) => retryStep.tag === step.tag);
    let sortedRetrySteps = sameRetrySteps?.length > 0 ? _.sortBy(sameRetrySteps, "retryIndex") : [];
    return sortedRetrySteps?.length > 0 ? sortedRetrySteps[sortedRetrySteps.length - 1] : step;
  }
  return step;
};

export const checkAnyPreviousContainsInObject = (recTestStepProperty) => {
  if (recTestStepProperty && Object.keys(recTestStepProperty).filter((key) => key.includes("previous_")).length > 0) {
    return true;
  }
  for (let i = 0; i < recTestStepProperty?.recElements.length; i++) {
    if (Object.keys(recTestStepProperty?.recElements[i]).filter((key) => key.includes("previous_")).length > 0) {
      return true;
    }
  }
  return false;
};

export const cloneModal = (name, content, handleOk) => {
  Modal.confirm({
    title: (
      <div className="confirmModalTitle">
        <span className="confirmModalIcon">
          <FontAwesomeIcon icon={faCopy} />
        </span>
        <span className="modalTitleColor">Clone {content}</span>
      </div>
    ),
    content: (
      <div>
        <div className="modal_content">{`Are you sure you want to clone the following ${content}?`}</div>
        <div className="cloneModalContent">{name}</div>
      </div>
    ),
    icon: "",
    okText: "Yes",
    cancelText: "No",
    okButtonProps: { className: "ml-10 purple", type: "primary" },
    cancelButtonProps: { className: "white" },
    onOk: () => handleOk(),
    centered: true,
  });
};

export const detachModal = (name, content, handleOk) => {
  Modal.confirm({
    title: (
      <div className="display-flex align-items-center modal-title">
        <img src={WARNING} alt="error" width={16} height={16} />
        Detach {name}
      </div>
    ),
    content: <div className="modal-content">{content}</div>,
    icon: "",
    okText: "Detach",
    cancelText: "Cancel",
    okButtonProps: { className: "ml-10 purple", type: "primary" },
    cancelButtonProps: { className: "white" },
    onOk: () => handleOk(),
    centered: true,
  });
};

// const handleOnChangeValue = (flag, dispatch) => {
//   const dispatch = useDispatch();
//   dispatch({type: IS_DELETE_REC_TEST_STEP_REF, flag});
// }

export const deleteModal = (content, handleOk, note, isAbort, isTestBlockInTestScenario) => {
  Modal.confirm({
    title: (
      <div className="display-flex align-items-center modal-title">
        <img src={ERROR_ICON} alt="error" height={16} width={16} />
        {isAbort ? "Abort" : isTestBlockInTestScenario ? "Remove" : "Delete"}
      </div>
    ),
    content: (
      <>
        <div className="modal-content">{content}</div>
        <span className="mt-20 font-size-12 text-danger">{note}</span>
        {/* {isCheck && <Checkbox onChange={(e) => dispatch({type: IS_DELETE_REC_TEST_STEP_REF, flag: e.target.checked ? 1 : 0})}>
            Also, delete reference from other scenarios?</Checkbox>} */}
      </>
    ),
    icon: "",
    okText: isAbort ? "Abort" : isTestBlockInTestScenario ? "Remove" : "Delete",
    cancelText: "Cancel",
    okButtonProps: { className: "ml-10 purple", type: "primary" },
    cancelButtonProps: { className: "white" },
    onOk: () => handleOk(),
    centered: true,
  });
};

// export const publishModal = (content, handleOk, note, isAbort, isCheck, dispatch) => {
//   Modal.confirm({
//     title: (
//       <div className="display-flex align-items-center modal-title">
//         <img src={ERROR_ICON} alt="error" height={16} width={16} />
//         Publish
//       </div>
//     ),
//     content: (
//       <>
//         <div className="modal-content">{content}</div>
//         <span className="mt-20 font-size-12 text-danger">{note}</span>
//       </>
//     ),
//     icon: "",
//     okText: "Publish",
//     cancelText: "Cancel",
//     okButtonProps: { className: "ml-10 purple", type: "primary" },
//     cancelButtonProps: { className: "white" },
//     onOk: () => handleOk(),
//     centered: true,
//   });
// };

export const getPermissionType = (isPrivate, permissionRights) => {
  if (isPrivate === 1 && _.isEmpty(permissionRights)) {
    return PRIVATE;
  } else if (isPrivate === 0) {
    return PUBLIC;
  } else {
    return CUSTOM;
  }
};

export const showPermissionTypeIcon = (permissionType) => {
  if (permissionType === PRIVATE) {
    return faLock;
  } else if (permissionType === PUBLIC) {
    return faLockOpen;
  } else if (permissionType === CUSTOM) {
    return faKey;
  }
};

export const getRecElement = (elements, elementGuid) => {
  let requestedRecElement = undefined;
  if (elements && elementGuid) {
    for (let i = 0; i < elements.length; i++) {
      if (elements[i] && elements[i].guid === elementGuid) {
        requestedRecElement = elements[i];
        break;
      }
      if (elements[i] && elements[i].childRecElements && elements[i].childRecElements.length > 0) {
        requestedRecElement = getRecElement(elements[i].childRecElements, elementGuid);
        if (requestedRecElement !== undefined) {
          break;
        }
      }
    }
  }
  return requestedRecElement;
};

export const updateRecElement = (elements, recElement) => {
  for (let i = 0; i < elements?.length; i++) {
    if (elements[i].guid === recElement.guid) {
      elements[i] = {
        ...recElement,
      };
      break;
    }
    if (elements[i].childRecElements && elements[i].childRecElements.length > 0) {
      elements[i].childRecElements = updateRecElement(elements[i].childRecElements, recElement);
    }
  }
  return elements;
};
export const removeRecElements = (elements, recElementGuid) => {
  let newElementList = [];
  if (elements) {
    for (let i = 0; i < elements.length; i++) {
      if (!recElementGuid.includes(elements[i].guid)) {
        newElementList.push({ ...elements[i] });
        newElementList[newElementList.length - 1]["childRecElements"] = [];
        if (elements[i]?.childRecElements && elements[i].childRecElements.length > 0) {
          let childRecElements = removeRecElements(elements[i].childRecElements, recElementGuid);
          if (childRecElements && childRecElements.length > 0) {
            newElementList[newElementList.length - 1]["childRecElements"] = [...childRecElements];
          }
        }
      }
    }
  }
  return newElementList;
};

export const getParentRecElement = (elements, recElementGuid, parentRecElement = undefined) => {
  let selectedParentRecElement = undefined;
  for (let i = 0; i < elements.length; i++) {
    if (elements[i].id === 0 || elements[i].id === undefined || elements[i].guid === recElementGuid) {
      return parentRecElement;
    }
    if (elements[i].childRecElements && elements[i].childRecElements.length > 0) {
      selectedParentRecElement = getParentRecElement(elements[i].childRecElements, recElementGuid, elements[i]);
      if (selectedParentRecElement !== undefined) {
        break;
      }
    }
  }
  return selectedParentRecElement;
};

export const isElementFound = (element) => {
  if (element.childRecElements && element.childRecElements.length > 0) {
    return (
      element.childRecElements.filter(
        (childRecElement) =>
          childRecElement?.recElementLocatorProperties?.filter(
            (recElementLocatorProperty) => recElementLocatorProperty.mobileElements?.length > 0
          ).length > 0
      ).length > 0
    );
  } else {
    return (
      element?.recElementLocatorProperties?.filter(
        (recElementLocatorProperty) => recElementLocatorProperty.mobileElements?.length > 0
      ).length > 0
    );
  }
};

export const getRandomColor = () => {
  var letters = "BCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

export const getRandomDarkColor = () => {
  var letters = "0123456789ABCDEF".split("");
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 15)];
  }
  return color;
};

// export const shouldButtonEnableForSave = (recTestStep, unSavedRecStepTag, recTestStepId, unSavedRecElementGuid) => {
//   let isActionDisabled = false;
//   if (
//     getUnSavedRecElementCount(recTestStep?.recTestStepProperty?.recElements)?.length > 1 ||
//     (getUnSavedRecElementCount(recTestStep?.recTestStepProperty?.recElements)?.length === 1 &&
//       (recTestStep?.recTestStepProperty?.recElements[0].childRecElements === undefined ||
//         recTestStep?.recTestStepProperty?.recElements[0].childRecElements?.length === 0))
//   ) {
//     isActionDisabled = true;
//   } else {
//     if (recTestStep && recTestStep?.recTestStepProperty) {
//       if (unSavedRecStepTag === "" && recTestStep.id !== undefined) {
//         isActionDisabled = true;
//       }
//       if (
//         recTestStep?.recTestStepProperty?.isCaptureScreenshot &&
//         isNullOrUndefinedOrEmpty(recTestStep?.recTestStepProperty?.captureScreenShotDelayTime)
//       ) {
//         isActionDisabled = true;
//       }
//       if (recTestStep?.recTestStepProperty?.selectorType === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
//         if (recTestStep?.recTestStepProperty?.selectedElementImage === undefined) {
//           isActionDisabled = true;
//         } else {
//           isActionDisabled = false;
//         }
//       } else {
//         // if (recTestStep.recTestStepProperty?.selectorType === SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN) {
//         switch (recTestStep.recTestStepProperty?.action) {
//           case ELEMENT_ACTION.TAP:
//           case ELEMENT_ACTION.LONG_PRESS:
//           case ELEMENT_ACTION.CLEAR_TEXT:
//             if (
//               (isNullOrUndefinedOrEmpty(recTestStep.id) &&
//                 !isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) &&
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.selectedElementImage)) ||
//               (isNullOrUndefinedOrEmpty(recTestStep.id) &&
//                 !isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) &&
//                 !isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.selectedElementImage)) ||
//               (!isNullOrUndefinedOrEmpty(recTestStep.id) &&
//                 isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
//                 unSavedRecStepTag !== recTestStep.tag &&
//                 !storedPreviousData(recTestStep, "previous_action"))
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.TAP_BY_INDEX:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               (!isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) ||
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.uniqueByIndex))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               !isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.uniqueByIndex)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               recTestStep.id !== undefined &&
//               !editForGivenRecTestStepTag(recTestStep.id, recTestStep.tag, unSavedRecStepTag)
//             ) {
//               isActionDisabled = true;
//             }
//             if (
//               !isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) &&
//               isNullOrUndefinedOrEmpty(recTestStep.id)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.LAUNCH_APP:
//             if (isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.packageName)) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStepId) &&
//               !editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.packageName)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.SWIPE:
//             if (
//               (isNullOrUndefinedOrEmpty(recTestStepId) &&
//                 isNullOrUndefinedOrEmpty(recTestStep?.recTestStepProperty?.recSwipeAttributes?.direction)) ||
//               isNullOrUndefinedOrEmpty(recTestStep?.recTestStepProperty?.recSwipeAttributes?.percentage)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               (editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes.direction)) ||
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes.percentage)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case SWIPE_OVER_ELEMENT:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               (!isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) ||
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes.direction) ||
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes.percentage))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               !isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
//               (isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes?.direction) ||
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.recSwipeAttributes?.percentage))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               recTestStep.id !== undefined &&
//               !editForGivenRecTestStepTag(recTestStep.id, recTestStep.tag, unSavedRecStepTag)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.SEND_KEYS:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               (!isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) ||
//                 (isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.testData) &&
//                   isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty["userInputValue"])))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               !isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.testData) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty["userInputValue"])
//             ) {
//               isActionDisabled = true;
//             } else if (
//               recTestStep.id !== undefined &&
//               !editForGivenRecTestStepTag(recTestStep.id, recTestStep.tag, unSavedRecStepTag)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.PAUSE:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStepId) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.pauseTime)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.pauseTime)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStepId) &&
//               (isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.outputTestData) ||
//                 isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.insertValueToTestData))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.outputTestData) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.insertValueToTestData)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStepId) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.captureScreenShotDelayTime)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.captureScreenShotDelayTime)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case DEVICE_INTERACTION.BACK:
//           case DEVICE_INTERACTION.HOME:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStepId) &&
//               recTestStep.recTestStepProperty?.isCaptureScreenshot &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.captureScreenShotDelayTime)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               editForGivenRecTestStepTag(recTestStep.tag, unSavedRecStepTag) &&
//               recTestStep.recTestStepProperty?.isCaptureScreenshot &&
//               isNullOrUndefinedOrEmpty(recTestStep.recTestStepProperty?.captureScreenShotDelayTime)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           case VERIFICATIONS:
//             let recTestStepVerificationProperties = [];
//             recTestStepVerificationProperties =
//               recTestStepVerificationProperties.length === 0 &&
//               recTestStep?.recTestStepProperty?.recStepVerifyElementProperties
//                 ? [...recTestStep?.recTestStepProperty?.recStepVerifyElementProperties]
//                 : [];
//             if (recTestStepVerificationProperties.length === 0) {
//               if (
//                 recTestStep?.recTestStepProperty?.recElements?.filter(
//                   (recElement) => recElement?.recStepVerifyElementProperties?.length === 0
//                 ).length > 0
//               ) {
//                 isActionDisabled = true;
//               }
//             }
//             // if (
//             //   recTestStep?.recTestStepProperty &&
//             //   isNullOrUndefinedOrEmpty(recTestStep.id) &&
//             //   ((!isElementSelectedForStep(recTestStep?.recTestStepProperty) &&
//             //     recTestStep?.recTestStepProperty?.recStepVerifyElementProperties.length === 1 &&
//             //     recTestStep?.recTestStepProperty?.recStepVerifyElementProperties[0]?.type !== "VerifyAbsence" &&
//             //     recTestStep?.recTestStepProperty?.recStepVerifyElementProperties[0]?.referenceStep?.tag ===
//             //       recTestStep.tag) ||
//             //     !isVerificationListIsNotEmpty(recTestStep?.recTestStepProperty?.recStepVerifyElementProperties))
//             // ) {
//             //   isActionDisabled = true;
//             // } else if (
//             //   !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//             //   !isNullOrUndefinedOrEmpty(reRecordRecStepTag) &&
//             //   !isVerificationListIsNotEmpty(recTestStep?.recTestStepProperty?.recStepVerifyElementProperties)
//             // ) {
//             //   isActionDisabled = true;
//             // } else if (
//             //   recTestStep.id !== undefined &&
//             //   !editForGivenRecTestStepTag(recTestStep.id, recTestStep.tag, reRecordRecStepTag)
//             // ) {
//             //   isActionDisabled = true;
//             // }

//             break;
//           case STEP:
//             if (
//               isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
//               (!isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) ||
//                 !isVerificationListIsNotEmpty(recTestStep.recTestStepProperty?.recStepVerifyElementProperties))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               !isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
//               recTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
//               [0, undefined].includes(recTestStep?.recTestStepProperty?.isStepHasVerifyTestData) &&
//               !isVerificationListIsNotEmpty(recTestStep.recTestStepProperty?.recStepVerifyElementProperties) &&
//               recTestStep.recTestStepProperty?.previous_recStepVerifyElementProperties !== undefined
//             ) {
//               isActionDisabled = true;
//             } else if (
//               isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               recTestStep.recTestStepProperty?.isLoopStep === 1 &&
//               (!isElementSelectedForStep(recTestStep.recTestStepProperty?.recElements) ||
//                 !isVerificationListIsNotEmpty(recTestStep.recTestStepProperty?.recStepVerifyElementProperties))
//             ) {
//               isActionDisabled = true;
//             } else if (
//               !isNullOrUndefinedOrEmpty(recTestStep.id) &&
//               !isNullOrUndefinedOrEmpty(unSavedRecStepTag) &&
//               recTestStep?.recTestStepProperty?.isStepHasVerifyTestData === 1 &&
//               !isVerificationListIsNotEmpty(recTestStep.recTestStepProperty?.recStepVerifyElementProperties)
//             ) {
//               isActionDisabled = true;
//             } else if (
//               recTestStep.id !== undefined &&
//               !editForGivenRecTestStepTag(recTestStep.id, recTestStep.tag, unSavedRecStepTag)
//             ) {
//               isActionDisabled = true;
//             }
//             break;
//           default:
//             isActionDisabled = true;
//             break;
//         }
//       }
//     }
//   }
//   return isActionDisabled;
// };

const storedPreviousData = (data, key) => {
  return Object.keys(data.recTestStepProperty).filter((v) => v.includes(key)).length > 0;
};

const editForGivenRecTestStepTag = (recTestStepId, recTestStepTag, unSavedRecStepTag) => {
  return (
    !isNullOrUndefinedOrEmpty(recTestStepId) &&
    isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
    unSavedRecStepTag === recTestStepTag
  );
};

export const getAdmins = (admin) => {
  if (admin && admin?.length > 1) {
    return (
      <div className="display-flex">
        {admin.slice(0, 1)?.map((s) => s?.name)}
        <Popover
          width="50%"
          content={
            <div className="flex-wrap " style={{ maxWidth: "500px" }}>
              {admin?.slice(1)?.map((i, index) => (
                <div className={admin?.length - 2 !== index ? "borderForDevice mt-5 mb-10" : "mt-5 mb-10"}>
                  <div className="ml-5">{i?.name}</div>
                </div>
              ))}
            </div>
          }
          title="Admins"
          trigger="hover"
          placement="bottom"
        >
          <span className="font-color-blue cursor-pointer font-weight-medium ml-5">
            {`+${admin?.slice(1).length} more`}
          </span>
        </Popover>
      </div>
    );
  } else {
    return <>{!_.isEmpty(admin) ? <span className="cursor-pointer">{admin && admin[0]?.name}</span> : "N/A"}</>;
  }
};

export const setRecTestStepElementDetails = (
  recTestStepList,
  recTestStepTag,
  testBlockStep,
  selectedElementAttributes,
  elementDetailsFromServer,
  elementDetailsFromPageSource
) => {
  //Fetch new Created Rec Test Step Actions
  let newRecTestStepActionDetail = findRecTestStepByTag(recTestStepList, recTestStepTag, testBlockStep);
  // newRecTestStepActionDetail = fetchNewRecTestStepAction(state.recTestStepList, keys[0]);
  // Prepare selected Action
  if (newRecTestStepActionDetail) {
    newRecTestStepActionDetail["recTestStepProperty"]["action"] = selectedAction(
      newRecTestStepActionDetail["recTestStepProperty"]["action"],
      [elementDetailsFromServer],
      elementDetailsFromPageSource && elementDetailsFromPageSource.attributes
        ? elementDetailsFromPageSource.attributes
        : undefined
    );
    // Set the Element Id for interaction tap by index option value through localtion and size
    let parentRefElementDetails = getParentRefElementLocator(elementDetailsFromServer);
    if (
      parentRefElementDetails &&
      parentRefElementDetails.length > 0 &&
      parentRefElementDetails[0].mobileElements.length > 1
    ) {
      parentRefElementDetails[0].mobileElements.map((v, k) => {
        // prepare location and size object
        let selectedElementLocationAndSize = prepareElementLocationAndSizeObject(selectedElementAttributes);
        if (
          JSON.stringify(v.location) === JSON.stringify(selectedElementLocationAndSize.location) &&
          JSON.stringify(v.size) === JSON.stringify(selectedElementLocationAndSize.size)
        ) {
          newRecTestStepActionDetail["selectedElementId"] = v.id;
          newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"] = k + 1;
        }
      });
    } else if (
      elementDetailsFromServer &&
      elementDetailsFromServer.recElementLocatorProperties.length > 0 &&
      elementDetailsFromServer.recElementLocatorProperties[0].mobileElements.length > 0
    ) {
      let selectedElementId = elementDetailsFromServer.recElementLocatorProperties[0].mobileElements[0].id;
      newRecTestStepActionDetail["selectedElementId"] = selectedElementId;
    } else if (parentRefElementDetails[0].mobileElements.length > 0) {
      newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"] =
        parentRefElementDetails[0].mobileElements.length;
    }
    if (
      newRecTestStepActionDetail.recTestStepProperty?.recElements.length > 1 &&
      getUnSavedRecElementCount(newRecTestStepActionDetail.recTestStepProperty?.recElements) === 1
    ) {
      newRecTestStepActionDetail.recTestStepProperty["previous_recElements"] = [
        ...newRecTestStepActionDetail.recTestStepProperty?.recElements,
      ];
    }
    newRecTestStepActionDetail.recTestStepProperty.recElements = updateUnSavedRecElement(
      newRecTestStepActionDetail.recTestStepProperty?.recElements,
      elementDetailsFromServer
    );
    if (
      newRecTestStepActionDetail.recTestStepProperty?.action !== STEP &&
      newRecTestStepActionDetail.recTestStepProperty?.userSuggestedStepName === undefined &&
      newRecTestStepActionDetail.recTestStepProperty?.id === undefined
    ) {
      newRecTestStepActionDetail = insertIntoRecTestStepRecTestProperty(
        newRecTestStepActionDetail,
        "stepName",
        suggestedStepName(
          newRecTestStepActionDetail.recTestStepProperty?.recElements,
          newRecTestStepActionDetail["recTestStepProperty"]["action"]
        )
      );
    } else {
      newRecTestStepActionDetail.recTestStepProperty["preConditionTitle"] = suggestedStepNameFromElement(
        elementDetailsFromPageSource,
        VERIFICATIONS
      );
    }
  }
  recTestStepList = updateRecTestStepAction(recTestStepList, newRecTestStepActionDetail);
  return recTestStepList;
};

// export const getRecTestStepActions = (actionName, _buildDesignerType) => {
//   switch (_buildDesignerType) {
//     case TEST_SCENARIOS:
//       break;
//     case TEST_STEP_BLOCKS:
//       break;
//     default:
//       break;
//   }
// };

export const removeSpaceFromVerifications = (recStepVerifyElementProperties) => {
  for (let i = 0; i < recStepVerifyElementProperties.length; i++) {
    recStepVerifyElementProperties[i]["value"] = recStepVerifyElementProperties[i]["value"]
      ? recStepVerifyElementProperties[i]["value"].trim()
      : recStepVerifyElementProperties[i]["value"];
    if (
      recStepVerifyElementProperties[i].childRecStepVerifyElementProperties &&
      recStepVerifyElementProperties[i].childRecStepVerifyElementProperties.length > 0
    ) {
      recStepVerifyElementProperties[i].childRecStepVerifyElementProperties = removeSpaceFromVerifications(
        recStepVerifyElementProperties[i].childRecStepVerifyElementProperties
      );
    }
  }
  return recStepVerifyElementProperties;
};

// export function getScenarioStepActionIcon(action) {
//   switch (action) {
//     case ELEMENT_ACTION.TAP:
//     case ELEMENT_ACTION.TAP_BY_INDEX:
//     case ELEMENT_ACTION.LONG_PRESS:
//       return ACTION_ICON;
//     case ELEMENT_ACTION.CLEAR_TEXT:
//       return CLEAR_TEXT;
//     case ELEMENT_ACTION.LAUNCH_APP:
//       return LAUNCH_APP_ICON;
//     case ELEMENT_ACTION.PAUSE:
//       return PAUSE_ICON;
//     case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
//       return SCREENSHOT_ICON;
//     case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
//       return INSERT_VALUE_ICON;
//     case SWIPE_OVER_ELEMENT:
//     case ELEMENT_ACTION.SWIPE:
//       return SWIPE_ICON;
//     case VERIFICATIONS:
//       return VERIFICATION_ICON;
//     case "HOME":
//       return HOME_ICON;
//     case "ENTER":
//       return ENTER_ICON;
//     case "BACK":
//       return BACK_ICON;
//     default:
//       return INSERT_VALUE_ICON;
//   }
// }

export const importSubChildSteps = (
  selectScenarioId,
  parentId,
  childSteps,
  requestData,
  selectedRecTestStepOrActionForImport,
  disableRecTestStepForImport
) => {
  if (childSteps) {
    childSteps.forEach((element) => {
      if (
        element.recTestScenarioRecTestStepAssocId &&
        selectedRecTestStepOrActionForImport.includes(element.recTestScenarioRecTestStepAssocId)
      ) {
        if (disableRecTestStepForImport || element.action === STEP) {
          requestData.push({
            recTestScenario: { id: selectScenarioId },
            recTestScenarioRecTestStepsAssoc: { id: element.recTestScenarioRecTestStepAssocId },
            recTestScenarioRecTestStepsAssocParent: { id: parentId },
          });
        }
      }
      importSubChildSteps(
        selectScenarioId,
        element.recTestScenarioRecTestStepAssocId,
        element.recTestSteps,
        requestData,
        selectedRecTestStepOrActionForImport,
        disableRecTestStepForImport
      );
    });
  }
};

export const importSubTestBlockChildSteps = (
  selectScenarioId,
  parentId,
  childSteps,
  requestData,
  selectedRecTestStepOrActionForImport,
  projectId
) => {
  if (childSteps) {
    childSteps.forEach((element) => {
      if (selectedRecTestStepOrActionForImport.includes(element.id)) {
        requestData.push({
          recTestStep: { id: element?.id, action: element?.action },
          project: { id: projectId },
        });
      }
      if (element.action === STEP) {
        importSubTestBlockChildSteps(
          selectScenarioId,
          element.id,
          element.recTestSteps,
          requestData,
          selectedRecTestStepOrActionForImport,
          projectId
        );
      }
    });
  }
};

// export const getConditionalStep = (selectSteps, recTestStep, isStepSelected, loadStepConditionalStep) => {
//   let items = [];
//   let className = "conditionStepsInTreeView stepsInTreeView ml-40 leaf-line display-flex align-items-center";
//   if (isStepSelected && loadStepConditionalStep) {
//     className += " selectSteps ";
//   } else {
//     className += " deSelectSteps ";
//   }
//   if (recTestStep.isLoopStep === 1 || recTestStep.isStepHasPrecondition === 1) {
//     items.push({
//       title: (
//         <div className={"conditionStepsInTreeView stepsInTreeView ml-40 labelDevice leaf-line"}>
//           <div className="labelDevice ml-less-30 leaf-line">
//             {recTestStep.isLoopStep === 1 ? "Repeat Until" : ""}
//             {recTestStep.isStepHasPrecondition === 1 ? "If" : ""}
//           </div>
//         </div>
//       ),
//       data: recTestStep,
//       key: "pre-1" + (recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep.recTestStepId),
//       parentId: recTestStep?.recTestScenarioRecTestStepAssocId,
//       selectable: false,
//       children: [],
//     });
//   }
//   items.push({
//     title: (
//       <>
//         <div className={className}>
//           <div className={"display-flex" + (recTestStep.isSkipStep === 1 ? " skipStep " : "")}>
//             <div className="stepsName">
//               <img src={recTestStep.isLoopStep === 1 ? REPEAT_ICON : VERIFICATION_ICON} alt="repeat" />
//             </div>
//             <Tooltip
//               color={TOOLTIP_BG_COLOR}
//               title={recTestStep.preConditionTitle ? recTestStep.preConditionTitle : "Condition is met"}
//             >
//               <div>{recTestStep.preConditionTitle ? recTestStep.preConditionTitle : "Condition is met"}</div>
//             </Tooltip>
//           </div>
//         </div>
//       </>
//     ),
//     loadStepConditionalStep: true,
//     data: recTestStep,
//     key: "pre-2" + (recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep.recTestStepId),
//     parentId: recTestStep?.recTestScenarioRecTestStepAssocId,
//     selectable: false,
//     children: [],
//   });
//   if (recTestStep.isLoopStep === 1 || recTestStep.isStepHasPrecondition === 1) {
//     items.push({
//       title: (
//         <div className="ml-40 leaf-line">
//           <div className="labelDevice p-b-16 ml-less-30 leaf-line">
//             {recTestStep.isLoopStep === 1 ? "Actions to Repeat" : "Then"}
//           </div>
//         </div>
//       ),
//       data: recTestStep,
//       key: "pre-3" + (recTestStep?.recTestScenarioRecTestStepAssocId || recTestStep.recTestStepId),
//       parentId: recTestStep?.recTestScenarioRecTestStepAssocId,
//       selectable: false,
//       children: [],
//     });
//   }
//   return items;
// };

export function prepareElementLocationAndSizeObject(selectedElementAttributes) {
  let startPostion = undefined;
  let endPostion = undefined;
  if (selectedElementAttributes.bounds) {
    startPostion = selectedElementAttributes.bounds.split("][")[0].split("[")[1].split(",");
    endPostion = selectedElementAttributes.bounds.split("][")[1].split("[")[0].split(",");
  } else {
    startPostion = [selectedElementAttributes.x, selectedElementAttributes.y];
    endPostion = [
      Number(startPostion[0]) + Number(selectedElementAttributes.width),
      Number(startPostion[1]) + Number(selectedElementAttributes.height),
    ];
  }
  return {
    location: {
      x: parseInt(startPostion[0]),
      y: parseInt(startPostion[1]),
    },
    size: {
      width: parseInt(endPostion[0]) - parseInt(startPostion[0]),
      height: parseInt(endPostion[1]) - parseInt(startPostion[1]),
    },
  };
}

// export const multiColumnStepBorderColor = (data) => {
//   if (data === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
//     return "border-inProgress previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.FAILED) {
//     return "border-fail previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.PASSED) {
//     return "border-pass previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.SKIPPED) {
//     return "border-abort previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
//     return "border-continue-after-fail previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.ABORTED) {
//     return "border-abort previewRun-border";
//   } else if (data === TEST_PLAN_RESULT_TYPE.TERMINATED) {
//     return "border-abort previewRun-border";
//   } else {
//     return "";
//   }
// };
export const resetStepName = (value, dispatch, setStepRename) => {
  return dispatch(resetMultipleColumnViewStepName(value)), setStepRename(false);
};
// export const saveRenameStepName = (updateStepActionName, recTestStep, dispatch, setStepRename) => {
//   return (
//     <div className="display-flex gap-10 cursor-pointer align-items-center">
//       <div
//         className="textBlue ml-less-25 align-items-center"
//         onClick={() =>
//           updateStepActionName
//             ? dispatch(callUpdateRecTestStepAction(recTestStep, undefined, setStepRename, updateStepActionName))
//             : undefined
//         }
//       >
//         Save
//       </div>
//       <div className="mb-2">
//         <img
//           src={CLOSE_ICON}
//           alt="cancel"
//           width={13}
//           onClick={() => resetStepName(recTestStep?.recTestStepProperty?.stepName, dispatch, setStepRename)}
//         />
//       </div>
//     </div>
//   );
// };

export const confirmModal = (
  handleOk,
  title = "Are you sure, you want to exit? Your changes will be lost!",
  name = "Discard Changes",
  buttonText = "Confirm"
) => {
  return Modal.confirm({
    title: (
      <div className="display-flex align-items-center modal-title">
        <img src={WARNING_ICON} alt="error" height={16} width={16} />
        {name}
      </div>
    ),
    content: (
      <>
        <div className="modal-content">{title}</div>
      </>
    ),
    width: 500,
    icon: "",
    okText: buttonText,
    cancelText: "Cancel",
    okButtonProps: { className: "ml-10 purple", type: "primary" },
    cancelButtonProps: { className: "white" },
    onOk: () => handleOk(),
    centered: true,
  });
};

export const selectedRecTestStepPath = (stepList, selectedRecTestStep) => {
  let selectedRecTestStepPath = [];
  const handleSelectedRecTestStepPath = (recTestStepList, selectedRecStepForOperation, selectRecTestPath = []) => {
    if (recTestStepList?.length > 0) {
      for (let index = 0; index < recTestStepList.length; index++) {
        const element = recTestStepList[index];
        let selectedStepPath = [...selectRecTestPath];
        if (selectedStepPath?.length === 0) {
          selectedStepPath.push("S" + element.orderId);
        } else {
          selectedStepPath.push(selectedStepPath[selectedStepPath.length - 1] + "." + element.orderId);
        }
        if (
          element?.recTestScenarioRecTestStepAssocId
            ? element?.recTestScenarioRecTestStepAssocId ===
              selectedRecStepForOperation?.recTestScenarioRecTestStepAssocId
            : element?.id === selectedRecStepForOperation?.id
        ) {
          selectedRecTestStepPath = selectedStepPath;
          break;
        } else {
          handleSelectedRecTestStepPath(element.recTestSteps, selectedRecStepForOperation, selectedStepPath);
        }
      }
    }
  };
  handleSelectedRecTestStepPath(stepList, selectedRecTestStep);
  return selectedRecTestStepPath;
};

export function isValidVerificationProperties(recStepVerifyElementProperties) {
  if (recStepVerifyElementProperties) {
    for (let index = 0; index < recStepVerifyElementProperties.length; index++) {
      let status = true;
      if (recStepVerifyElementProperties[index].type === "GROUP") {
        if (recStepVerifyElementProperties[index].childRecStepVerifyElementProperties?.length === 0) {
          status = false;
        } else {
          status = isValidVerificationProperties(
            recStepVerifyElementProperties[index].childRecStepVerifyElementProperties
          );
        }
      }
      if (!status) {
        return status;
      }
      status = isValidVerificationProperty(recStepVerifyElementProperties[index]);
      if (!status) {
        return status;
      }
    }
    return true;
  }
  return false;
}
export function isValidVerificationProperty(recStepVerifyElementProperty) {
  // if (recStepVerifyElementProperty.type === "VerifyPresence") {
  //   if (isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.key)) {
  //     return false;
  //   }
  //   if (
  //     recStepVerifyElementProperty?.key === "Presence of element By Text" &&
  //     isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.value)
  //   ) {
  //     return false;
  //   }
  // }
  // if (
  //   recStepVerifyElementProperty.type === "VerifyExistence" &&
  //   recStepVerifyElementProperty.key === verificationExistenceValues.does_not_exist
  // ) {
  //   if (
  //     isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.referenceStep) &&
  //     isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.value)
  //   ) {
  //     return false;
  //   }
  // }
  if (recStepVerifyElementProperty.type === "VerifyElementProperty") {
    if (isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.key)) {
      return false;
    }
  }
  if (recStepVerifyElementProperty.type === "VerifyElementText") {
    if (isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.key)) {
      return false;
    }
    if (
      !["Is empty", "Is not empty", ...verifyApplicationStrings, ...verifyDeviceStrings].includes(
        recStepVerifyElementProperty.key
      ) &&
      isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.referenceStep) &&
      isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.refTestData) &&
      isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.refTestDataId) &&
      isNullOrUndefinedOrEmpty(recStepVerifyElementProperty.value)
    ) {
      return false;
    }
  }
  return true;
}

export const showDeviceTabCheckBox = (data, selectedDevices) => {
  return (
    [DEVICE_FARM_USE.TEST_RUN_EXECUTION, DEVICE_FARM_USE.BOTH]?.includes(
      data?.miscDetails?.farmDetails?.deviceFarmUse
    ) ||
    (selectedDevices.includes(data?.device?.targetUniqueId) && data.device.isDeviceOffline)
  );
};

export const getRecTestStepStatusWithImage = (result, isPreviewRunning) => {
  switch (result) {
    case "IN_PROGRESS":
      return <Spin spinning={true} className="mr-5"></Spin>;
    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.PASSED:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP.PASSED} placement="topRight">
          <span>
            <SvgLoader iconName={RESULT_SUCCESS_ICON} width="1.5rem" height="1.5rem" />
          </span>
        </Tooltip>
      );
    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.SKIPPED:
      return (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP.SKIPPED}
          placement="topRight"
        >
          <span>
            <SvgLoader iconName={RESULT_SKIPPED_ICON} width="1.5rem" height="1.5rem" />
          </span>
        </Tooltip>
      );
    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.FAILED:
      return (
        <Tooltip color={TOOLTIP_BG_COLOR} title={REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP.FAILED} placement="topRight">
          <span>
            <SvgLoader iconName={RESULT_FAIL_ICON} width="1.5rem" height="1.5rem" />
          </span>
        </Tooltip>
      );
    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.CONTINUE_AFTER_FAILED:
      return (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP.CONTINUE_AFTER_FAILED}
          placement="topRight"
        >
          <span>
            <SvgLoader iconName={RESULT_CONTINUE_AFTER_FAIL_ICON} width="1.5rem" height="1.5rem" />
          </span>
        </Tooltip>
      );
    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.PRECONDITION_FAILED:
      return (
        <Tooltip
          color={TOOLTIP_BG_COLOR}
          title={REC_TEST_STEP_PREVIEW_STATUS_TYPE_TOOLTIP.PRECONDITION_FAILED}
          placement="topRight"
        >
          <span>
            <SvgLoader iconName={RESULT_PRE_CONDITION_FAIL_ICON} width="1.5rem" height="1.5rem" />
          </span>
        </Tooltip>
      );

    case REC_TEST_STEP_PREVIEW_STATUS_TYPE.IN_PROGRESS:
      return <Spin spinning={true} className="mr-5"></Spin>;

    default:
      return <span className="default-result-text">{result}</span>;
  }
};

export const conditionalStepPagination = (conditionalStep, currentStep, data, changeConditionalStep, retrySteps) => {
  const changeStepNumber = currentStep[data?.id] ? currentStep[data?.id] : retrySteps ? conditionalStep?.length : 1;
  return (
    <div className="display-flex align-items-center font-size-14 font-color-darkBlue">
      <div
        className={"pagination-prev " + (changeStepNumber === 1 ? "cursor-not-allowed ml-0" : "cursor-pointer ml-0")}
        onClick={(e) => {
          e.stopPropagation();

          changeStepNumber !== 1 &&
            changeConditionalStep({
              ...currentStep,
              [data.id]: changeStepNumber - 1,
            });
        }}
      >
        <img src={ARROW_LEFT} alt="left" />
      </div>
      <span className="display-flex mt-5">
        {changeStepNumber}/{conditionalStep?.length}
      </span>
      <div
        className={
          "pagination-next " +
          (conditionalStep?.length !== changeStepNumber ? "cursor-pointer ml-2" : "cursor-not-allowed ml-2")
        }
        onClick={(e) => {
          e.stopPropagation();
          conditionalStep?.length !== changeStepNumber &&
            changeConditionalStep({
              ...currentStep,
              [data.id]: changeStepNumber + 1,
            });
        }}
      >
        <img src={ARROW_RIGHT} alt="right" />
      </div>
    </div>
  );
};

// export const isVerificationOfElementExist = (recTestStepProperty) => {
//   return (
//     recTestStepProperty?.action === VERIFICATIONS &&
//     recTestStepProperty?.recStepVerifyElementProperties &&
//     recTestStepProperty?.recStepVerifyElementProperties.length === 1 &&
//     recTestStepProperty?.recStepVerifyElementProperties[0].key !== verificationExistenceValues.does_not_exist
//   );
// };

export const checkDropRecTestStepIsChildOfDragRecTestStep = (dropRecTestStep, dragRecTestStep) => {
  if (dragRecTestStep.recTestSteps && dragRecTestStep.recTestSteps.length > 0) {
    return findRecTestStepByTag(dragRecTestStep.recTestSteps, dropRecTestStep.tag) !== undefined;
  }
  return false;
};

export const showSubStep = (recTestStep, actionStep) => {
  return (
    (recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action === STEP) ||
      recTestStep?.recTestSteps?.length >= 0 ||
      recTestStep?.recTestStepProperty?.action === STEP) &&
    !actionStep
  );
};

export const showActionStep = (recTestStep, subActionStep) => {
  return (
    (recTestStep?.recTestStepProperty?.action !== STEP ||
      recTestStep?.recTestSteps?.some((i) => i?.recTestStepProperty?.action !== STEP)) &&
    !subActionStep
  );
};

export const disableStep = (
  isTestBlock,
  unSavedRecStepTag,
  childStepDisabled,
  parentSkipStep,
  recTestStep,
  dispatch,
  isPreviewRunning,
  stepType,
  className,
  menuVisible
) => {
  let isChildTestBlock =
    stepType === TEST_SCENARIOS && recTestStep?.parentName && recTestStep?.isTestBlock === 1 && isTestBlock;
  return (
    <Switch
      className={className}
      size="small"
      disabled={unSavedRecStepTag !== undefined || childStepDisabled || isChildTestBlock}
      checked={recTestStep?.isSkipStep === 1 || parentSkipStep}
      onChange={() =>
        !isChildTestBlock &&
        !menuVisible &&
        !isPreviewRunning &&
        dispatch(changeDisableStep(recTestStep.isSkipStep === 1 ? 0 : 1, recTestStep, stepType))
      }
    />
  );
};

export const updateContinueOnFailure = (recTestStep, dispatch, checked) => {
  const unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...recTestStep }, "continueAfterFail", checked);
  dispatch(editRecTestStep(recTestStep?.tag));
  dispatch(saveRecTestStepAction({ imageData: "" }, unSavedRecTestStep?.id, unSavedRecTestStep?.tag));
};

export const onDeleteAction = (recTestStep, dispatch, isTestBlockInTestScenario) => {
  dispatch(
    showDeleteRecTestStepConfirmationModal(
      recTestStep?.id,
      recTestStep?.tag,
      recTestStep?.recTestScenarioRecTestStepAssocId,
      false
    )
  );
  deleteModal(
    `Are you sure you want to ${isTestBlockInTestScenario ? "remove" : "delete"} 
      ${recTestStep?.recTestStepProperty?.stepName ? `'${recTestStep?.recTestStepProperty?.stepName}'` : "Step"} 
      ?`,
    () => {
      dispatch({ type: RESET_DEVICE_SCREEN_SOURCE });
      dispatch(deleteRecTestStep());
    },
    "",
    "",
    isTestBlockInTestScenario
  );
};

export const showMenuTitle = (recTestStep, menuItem) => {
  if (recTestStep?.recTestStepProperty?.action === STEP) {
    return `${menuItem} this Step`;
  } else if (recTestStep?.recTestStepProperty?.action === VERIFICATIONS) {
    return `${menuItem} this Verification`;
  } else {
    return `${menuItem} this Action`;
  }
};

export const updatePreConditionalTitle = (unSavedRecTestStep, selectedTestData, value) => {
  const text = value ? `"${value}"` : "";
  const testData = selectedTestData ? `"${selectedTestData}"` : "";
  const result =
    text !== "" || testData !== "" ? `Verify that text ${text} matches with the value of test Data ${testData}` : "";
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "preConditionTitle", result);
};

export const getMappedStepName = (stepAction) => {
  switch (stepAction) {
    case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
      return INSERT_VALUE_TO_OUTPUT_TEST_DATA_TXT;
    case SET_APP_LOCALE:
      return SET_APP_LOCALE_TXT;
    default:
      return stepAction;
  }
};

export const disableStepForGeneralSettingsDrawer = (unSavedRecTestStep) => {
  const properties = unSavedRecTestStep?.recTestStepProperty;

  // Check if RecTestStep is not saved
  if (unSavedRecTestStep?.id === undefined) {
    return true;
  }

  const propertyKeys = [
    "retryCount",
    "swipeDirection",
    "swipeCountToFindElement",
    "reverseSwipeElementNotFound",
    "horizontalRelativePoint",
    "verticalRelativePoint",
  ];

  // Check if any property has changed
  for (const key of propertyKeys) {
    const previousKey = `previous_${key}`;
    if (properties?.hasOwnProperty(previousKey) && properties[previousKey] !== properties[key]) {
      return false;
    }
  }

  return true;
};

export const disableResetButtonForIdentificationRulesDrawer = (unSavedRecElement) => {
  if (unSavedRecElement?.hasOwnProperty("previous_recStepVerifyElementProperties")) {
    return false;
  }
  return true;
};

export const disableSaveButtonStepForIdentificationRulesDrawer = (unSavedRecElement) => {
  const checkEmptyKeys = isValidVerificationProperties(unSavedRecElement?.recStepVerifyElementProperties);
  if (unSavedRecElement?.applicationStrings) {
    if (
      unSavedRecElement?.recStepVerifyElementProperties?.some(
        (i) => i?.key === "Equals to Locale value of App String" || i?.key === "Contains Locale value of App String"
      ) &&
      checkEmptyKeys
    ) {
      return false;
    } else {
      return true;
    }
  } else if (unSavedRecElement?.deviceStrings) {
    if (
      unSavedRecElement?.recStepVerifyElementProperties?.some(
        (i) =>
          i?.key === "Equals to Locale value of Device String" || i?.key === "Contains Locale value of Device String"
      ) &&
      checkEmptyKeys
    ) {
      return false;
    } else {
      return true;
    }
  } else if (checkEmptyKeys) {
    return false;
  }
  return true;
};

// export function getStepActionName(action) {
//   switch (action) {
//     case ELEMENT_ACTION.TAP:
//       return "Tap";
//     case ELEMENT_ACTION.TAP_BY_INDEX:
//       return "Tap By Index";
//     case ELEMENT_ACTION.LONG_PRESS:
//       return "Long Press";
//     case ELEMENT_ACTION.SEND_KEYS:
//       return "Enter Text";
//     case ELEMENT_ACTION.CLEAR_TEXT:
//       return "Clear Text";
//     case ELEMENT_ACTION.LAUNCH_APP:
//       return "Launch App";
//     case SWIPE_OVER_ELEMENT:
//       return "Swipe Over Element";
//     case ELEMENT_ACTION.SWIPE:
//       return "Scroll Page";
//     case VERIFICATIONS:
//       return "Verification";
//     case "HOME":
//       return "Navigate to Home";
//     case "ENTER":
//       return "Keypad Enter";
//     case "BACK":
//       return "Navigate Back";
//     case ELEMENT_ACTION.PAUSE:
//       return "Add Delay";
//     case ELEMENT_ACTION.INSERT_VALUE_TO_OUTPUT_TEST_DATA:
//       return "Assign Value to Test Data";
//     case ELEMENT_ACTION.CAPTURE_SCREENSHOT:
//       return "Screenshot Page";
//     default:
//       return undefined;
//   }
// }

export const removeSelectedKeys = (selectedKeys, data) => {
  let selectedStrings = [...selectedKeys];
  data &&
    data.forEach((element) => {
      let index = selectedStrings?.indexOf(element?.key);
      selectedStrings.splice(index, 1);
    });
  return selectedStrings;
};

export const isNotNullBuildDetails = (appBuildData) => {
  const object1 = appBuildData,
    keys = ["buildVersion", "buildDate", "buildTime", "buildType", "buildName"];
  return keys?.some((key) => key in object1 && isNullOrUndefinedOrEmpty(object1[key]));
};

export const validateForm = (buildId, appBuildData, dispatch, platform) => {
  let formValid = true;
  if (!buildId && isNullOrUndefined(appBuildData?.appFile)) {
    dispatch(setAddAppBuildError("setBuildFileError", true));
    message.error("Please select a Build File", 5);
    formValid = false;
  } else {
    dispatch(setAddAppBuildError("setBuildFileError", false));
  }
  if (isNullOrUndefinedOrEmpty(appBuildData?.buildVersion)) {
    dispatch(setAddAppBuildError("setBuildVersionError", true));
    message.error("Please Enter The Build Version", 5);
    formValid = false;
  } else {
    dispatch(setAddAppBuildError("setBuildVersionError", false));
  }
  if (isNullOrUndefined(appBuildData?.buildType)) {
    dispatch(setAddAppBuildError("setBuildTypeError", true));
    message.error("Please Select Build Type", 5);
    formValid = false;
  } else {
    dispatch(setAddAppBuildError("setBuildTypeError", false));
  }
  if (isNullOrUndefinedOrEmpty(appBuildData?.buildDate.toString())) {
    dispatch(setAddAppBuildError("setBuildDateError", true));
    message.error("Please Select The Date", 5);
    formValid = false;
  } else {
    dispatch(setAddAppBuildError("setBuildDateError", false));
  }
  if (isNullOrUndefinedOrEmpty(appBuildData?.buildTime.toString())) {
    dispatch(setAddAppBuildError("setBuildTimeError", true));
    message.error("Please Select Time", 5);
    formValid = false;
  } else {
    dispatch(setAddAppBuildError("setBuildTimeError", false));
  }
  if (allowedAppActivtyForFarm.includes(appBuildData?.uploadToCloud) && platform === ANDROID) {
    if (isNullOrUndefinedOrEmpty(appBuildData?.appActivity)) {
      dispatch(setAddAppBuildError("setAppActivity", true));
      message.error("Please Add App Activity", 5);
      formValid = false;
    } else {
      dispatch(setAddAppBuildError("setAppActivity", false));
    }
  }

  return formValid;
};

export const handleSaveBuild = (
  farms,
  sharedWithFarmInAddAppBuild,
  selectedFarms,
  buildId,
  appBuildData,
  platform,
  dispatch,
  pushBuild,
  deviceId,
  setAppBuildData
) => {
  if (
    !isArrayNotUndefinedNotNullNotEmpty(sharedWithFarmInAddAppBuild) &&
    isArrayNotUndefinedNotNullNotEmpty(selectedFarms)
  ) {
    dispatch(farmSelectInAddBuild(farms));
  }
  let validForm = undefined;
  validForm = validateForm(buildId, appBuildData, dispatch, platform);
  if (validForm) {
    appBuildData.buildDateAndTime =
      moment(appBuildData.buildDate).format(appStatic.defaultDateFormat) +
      " " +
      moment(appBuildData.buildTime).format(appStatic.timeFormat);
    dispatch(saveBuildDetails(appBuildData, deviceId, pushBuild, setAppBuildData));
  }
};

export const dragHoverOnStepOrAction = (
  dragOver,
  setDragOver,
  dispatch,
  disabled,
  recTestStep,
  parentRecTestStep,
  index
) => {
  return (
    <div className="dragOverClass">
      <div
        className={`hoverDragAndDrop ${dragOver ? "hoverEffectClass" : ""}`}
        onDragEnter={() => {
          setDragOver(true);
        }}
        onDragLeave={() => setDragOver(false)}
        onDragOver={(event) => dispatch(onRecTestStepDragOver(event))}
        onDrop={(event) => {
          setDragOver(false);
          dispatch(onRecTestStepDrop(event, recTestStep, parentRecTestStep, index));
        }}
      >
        <span className={`dragAndDrop ${dragOver ? "dragAndDropText" : ""}`}>Dragged Item will be dropped here!</span>
      </div>
    </div>
  );
};

export const selectAllData = (selectAllData, setSelectAllData, data, deleteConfirm) => {
  return (
    <SelectAllBoxV2
      isChecked={isSelectedAllIds(
        selectAllData,
        data?.filter((i) => i.allowDelete === ACTION_VIEW)
      )}
      selectAllIds={selectAllData}
      handleChangeCheck={(isChecked) => {
        if (isChecked) {
          setSelectAllData([
            ...selectAllData,
            ...(
              data && data?.filter((i) => i.allowDelete === ACTION_VIEW && !selectAllData.includes(i?.id) && i?.id)
            )?.map((i) => i.allowDelete === ACTION_VIEW && i.id),
          ]);
        } else {
          setSelectAllData(removeSelectedIds(selectAllData, data));
        }
      }}
      deleteConfirm={deleteConfirm}
      isDisable={isEmpty(data)}
    />
  );
};

export const elementImage = (stepImage) => {
  return stepImage && stepImage.length > 0 && stepImage?.every((i) => i !== null);
};

//Update UI

export const showTagOfPlatform = (platform) => {
  switch (platform) {
    case ANDROID:
      return <span className="project-platform-tag android">Android</span>;
    case IOS:
      return <span className="project-platform-tag ios">{platform}</span>;
    case WEB:
      return <span className="project-platform-tag">{platform}</span>;
    default:
      break;
  }
};

export const permissionTypeIcon = (permissionType) => {
  if (permissionType === PRIVATE) {
    return PRIVATE_ICON;
  } else if (permissionType === PUBLIC) {
    return PUBLIC_ICON;
  } else if (permissionType === CUSTOM) {
    return CUSTOM_ICON;
  }
};

// export const deviceImage = (deviceName, androidPlatform) => {
//   switch (deviceName) {
//     case GOOGLE_PIXEL_4A:
//       return GOOGLE_PIXEL_4A_IMG;
//     case SAMSUNG_A02:
//       return SAMSUNG_A02_IMG;
//     case ONE_PLUS_6T:
//       return ONE_PLUS_6T_IMG;
//     case GOOGLE_PIXEL_6_PRO:
//       return GOOGLE_PIXEL_6_PRO_IMG;
//     case SAMSUNG_A52:
//       return SAMSUNG_A52_IMG;
//     case MOTOROLA_G04:
//       return MOTOROLA_G04_IMG;
//     case SAMSUNG_M04:
//       return SAMSUNG_M04_IMG;
//     case SAMSUNG_A05M:
//       return SAMSUNG_A05M_IMG;
//     case VIVO:
//       return VIVO_IMG;
//     case IPHONE_SE:
//       return IPHONE_SE_IMG;
//     case IPHONE_11:
//     case IPHONE_11_MWLY2:
//       return IPHONE_11_IMG;
//     default:
//       return androidPlatform ? ANDROID_PHONE_IMG : IPHONE_IMG;
//   }
// };

export const deviceImage = (deviceName, androidPlatform) => {
  switch (deviceName) {
    case GOOGLE_PIXEL_4A:
      return GOOGLE_PIXEL_4A_ICON;
    case GOOGLE_PIXEL_6_PRO:
      return GOOGLE_PIXEL_6_PRO_ICON;
    case SAMSUNG_A02:
      return SAMSUNG_A02_ICON;
    case SAMSUNG_A52:
      return SAMSUNG_A52_ICON;
    case SAMSUNG_M04:
      return SAMSUNG_M04_ICON;
    case SAMSUNG_A05:
      return SAMSUNG_A05_ICON;
    case MOTOROLA_MOTO_G04:
      return MOTOROLA_MOTO_G04_ICON;
    case IPHONE_11:
      return IPHONE_11_ICON;
    case IPHONE_X:
      return IPHONE_X_ICON;
    case IPHONE_SE:
      return IPHONE_SE_ICON;
    case IPHONE_XR:
      return IPHONE_XR_ICON;
    //dev
    case SAMSUNG_M12:
      return SAMSUNG_M12_ICON;
    case IPHONE_12:
      return IPHONE_12_ICON;
    default:
      return androidPlatform ? ANDROID_IMG : IPHONE_ICON;
  }
};

export const disableValidateCredential = (details, platformDetails) =>
  isNullOrUndefinedOrEmpty(details?.userName) ||
  isNullOrUndefinedOrEmpty(details?.accessKey) ||
  isNullOrUndefinedOrEmpty(details?.dataCenter) ||
  (_.isEqual(details?.userName, platformDetails?.userName) &&
    _.isEqual(details?.accessKey, platformDetails?.accessKey) &&
    _.isEqual(details?.dataCenter, platformDetails?.dataCenter));

export const isResetDisableForCloudTesting = (details, platformDetails) => {
  if (!_.isEmpty(platformDetails) && details) {
    return (
      _.isEqual(details?.userName, platformDetails?.userName) &&
      _.isEqual(details?.accessKey, platformDetails?.accessKey) &&
      _.isEqual(details?.dataCenter, platformDetails?.dataCenter)
    );
  } else {
    return Object.values(details).every((i) => isNullOrUndefinedOrEmpty(i));
  }
};

export const showMessage = (type, content, duration) => {
  message[type]({
    content: content,
    className: "custom_Message",
    duration: duration && duration,
  });
};

export const validForNLP = (action) => {
  switch (action) {
    case ELEMENT_ACTION.TAP:
    case ELEMENT_ACTION.SEND_KEYS:
    case ELEMENT_ACTION.CLEAR_TEXT:
    case ELEMENT_ACTION.LONG_PRESS:
    case VERIFICATIONS:
    case STEP:
    case SWIPE_OVER_ELEMENT:
      return true;
    default:
      return false;
  }
  return false;
};

export const getStepResultById = (stepsArray, stepId) => {
  const step = stepsArray?.find((item) => item?.id === stepId);
  return step ? step.result : null;
};

export const getStepTimeById = (stepsArray, stepId) => {
  const step = stepsArray?.find((item) => item?.id === stepId);
  return step ? step?.duration.length > 0 && step.duration.split(":") : null;
};

export const getStepStartTimeById = (stepsArray, stepId) => {
  const step = stepsArray?.find((item) => item?.id === stepId);
  return step ? step.startTime : null;
};

export const getStepEndTimeById = (stepsArray, stepId) => {
  const step = stepsArray?.find((item) => item?.id === stepId);
  return step ? step.endTime : null;
};

export const getStepAfterActionScreenshotUrl = (stepsArray, stepId) => {
  const step = stepsArray?.find((item) => item?.id === stepId);
  return step ? step.afterActionScreenshotUrl : null;
};

export const getResult = (
  resultString,
  isStepHasPreCondition,
  preconditionResult,
  continueAfterFail,
  subChildStepFailedAndHaveContinueAfterFailFlag,
  parentModifiedResult,
  isSkipStep,
  isLoopStep
) => {
  let result = resultString;
  //In-Progress Result
  if (resultString === TEST_PLAN_RESULT_TYPE.IN_PROGRESS) {
    result = TEST_PLAN_RESULT_TYPE.IN_PROGRESS_TXT;
  } else if (resultString === TEST_PLAN_RESULT_TYPE.PASSED) {
    result = TEST_PLAN_RESULT_TYPE.PASSED;
  }
  //Failed Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.FAILED) {
    if (preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED && isStepHasPreCondition === 1 && continueAfterFail === 1) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED;
    } else if (
      preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED &&
      isStepHasPreCondition === 1 &&
      (continueAfterFail === undefined || continueAfterFail === 0)
    ) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_PRECONDITION_NOT_MET;
    } else if (
      preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED &&
      isLoopStep === 1 &&
      (continueAfterFail === undefined || continueAfterFail === 0)
    ) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_LOOP_STEP_CONDITION_NOT_MET;
    } else if (preconditionResult === TEST_PLAN_RESULT_TYPE.PASSED && resultString === TEST_PLAN_RESULT_TYPE.FAILED) {
      result = TEST_PLAN_RESULT_TYPE.FAILED_ACTION_VERIFICATION_FAILED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.FAILED;
    }
  }
  //Skipped Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.SKIPPED) {
    if (isSkipStep === 1) {
      result = TEST_PLAN_RESULT_TYPE.STEP_DISABLED_SKIPPED;
    } else if (parentModifiedResult === TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED && isSkipStep !== 1) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED;
    }
  }
  //Continue After Failed Result
  else if (resultString === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) {
    if (preconditionResult === TEST_PLAN_RESULT_TYPE.FAILED || subChildStepFailedAndHaveContinueAfterFailFlag) {
      result = TEST_PLAN_RESULT_TYPE.SKIPPED_PRECONDITION_FAILED;
    } else {
      result = TEST_PLAN_RESULT_TYPE.FAILED_IGNORE_AND_CONTINUE;
    }
  }
  return result;
};

const getExecutionType = (data, subChildFailedAndHaveContinueAfterFailFlag) => {
  if (data.action === STEP) {
    if (data.isStepHasPreCondition === 0 && data.continueAfterFail === 0 && data.isLoopStep === 0) {
      if (subChildFailedAndHaveContinueAfterFailFlag) {
        return "Only if Precondition Met";
      } else {
        return "Always (no precondition)";
      }
    } else if (data.isStepHasPreCondition === 1) {
      return "Only if Precondition Met";
    } else if (data.isLoopStep === 1) {
      return "Repeat till Condition Met";
    }
  } else {
    if (data.continueAfterFail === 1) {
      return "Ignore Failure and Continue Execution";
    } else {
      return "Always";
    }
  }
};
export const getSubChildStepFailedAndHaveContinueAfterFailFlag = (steps, scenarioId, parentId) => {
  if (
    steps &&
    scenarioId &&
    steps[scenarioId] &&
    steps[scenarioId].childSteps &&
    steps[scenarioId].childSteps[parentId]
  ) {
    return (
      steps[scenarioId].childSteps[parentId].filter(
        (item) =>
          (item.action === STEP && item.result === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED) ||
          (item.action !== STEP &&
            item.result === TEST_PLAN_RESULT_TYPE.CONTINUE_AFTER_FAILED &&
            item.continueAfterFail === 1)
      ).length === steps[scenarioId].childSteps[parentId].length
    );
  }
  return false;
};
export const addAdditionalStepDetail = (
  data,
  testPlanResult,
  testPlanResultStepsDetails,
  scenarioId,
  parentModifiedResult,
  isLoopStep,
  loopStepCount
) => {
  // update subChildStepFailedAndHaveContinueAfterFailFlag
  data["subChildStepFailedAndHaveContinueAfterFailFlag"] = getSubChildStepFailedAndHaveContinueAfterFailFlag(
    testPlanResultStepsDetails,
    scenarioId,
    data.id
  );
  // Update Result
  data["modifiedResult"] = getResult(
    testPlanResult,
    data.isStepHasPreCondition,
    data.preconditionResult,
    data.continueAfterFail,
    data["subChildStepFailedAndHaveContinueAfterFailFlag"],
    parentModifiedResult,
    data.isSkipStep,
    isLoopStep
  );
  if (data["isLoopStep"] === 0 && isLoopStep !== 0) {
    data["isParentLoopStep"] = isLoopStep;
    data["parentLoopStepCount"] = loopStepCount;
  }

  // Update Execution Type
  data["executionType"] = getExecutionType(data, data["subChildStepFailedAndHaveContinueAfterFailFlag"]);
  return data;
};

export const fetchParentSteps = (testPlanResultStepsDetails, scenarioId) => {
  if (
    testPlanResultStepsDetails &&
    testPlanResultStepsDetails[scenarioId] &&
    testPlanResultStepsDetails[scenarioId].parentSteps
  ) {
    return testPlanResultStepsDetails[scenarioId].parentSteps;
  }
  return [];
};

export const openTestPlanResultDetails = (dispatch, orgId, projectId, runNumber) => {
  dispatch({ type: TEST_PLAN_RESULT_BY_RUN_NUMBER_RESET });
  const url = `/${orgId}/project/${projectId}/TestPlanResultDetails/${runNumber}`;
  var win = window.open(url, "_blank");
  win.focus();
};

export const handleDragError = (errorType, dispatch) => {
  const errorMessages = {
    invalidDrop: "Dropping where it is actually invalid",
    groupStepToAction: "Cannot add Group Step to a step containing only Action and Verification steps",
    actionToStep: "Cannot add Action and Verification to a step containing only step",
    actionToParentStep: "Can not move action/verification inside other action/verifications",
    dropInTestBlockSteps: "Can not move test scenario inside test block",
    default: "Error in drag element",
  };

  showDragError(errorMessages[errorType] || errorMessages.default, dispatch);
};

export const handleShowDragError = (
  dispatch,
  dragRecTestStep,
  droppingRecTestStep,
  droppingParentRecTestStep,
  stepType
) => {
  const isStepToNonStep = checkRecTestStepIsStep(dragRecTestStep) && checkRecTestStepIsNotStep(droppingRecTestStep);
  const isStepToGroupStep =
    checkRecTestStepIsStep(dragRecTestStep) &&
    droppingParentRecTestStep &&
    checkRecTestStepIsStep(droppingRecTestStep) &&
    checkRecTestStepIsNotStep(droppingParentRecTestStep?.recTestSteps?.[0]);
  const isStepIsTestBlockInScenario =
    stepType &&
    stepType === TEST_SCENARIOS &&
    dragRecTestStep.isTestBlock === 0 &&
    droppingParentRecTestStep?.isTestBlock === 1;
  const isNonStepToStep =
    checkRecTestStepIsStep(droppingRecTestStep) &&
    checkRecTestStepIsNotStep(dragRecTestStep) &&
    droppingParentRecTestStep &&
    !isStepIsTestBlockInScenario;
  const isNonStepToParentStep =
    checkRecTestStepIsNotStep(dragRecTestStep) && !droppingParentRecTestStep && !isStepIsTestBlockInScenario;

  const errorType =
    (isStepToNonStep || isStepToGroupStep) && !isStepIsTestBlockInScenario
      ? "groupStepToAction"
      : isNonStepToStep
      ? "actionToStep"
      : isNonStepToParentStep
      ? "actionToParentStep"
      : isStepIsTestBlockInScenario
      ? "dropInTestBlockSteps"
      : "default";

  switch (errorType) {
    case "groupStepToAction":
      handleDragError("groupStepToAction", dispatch);
      break;
    case "actionToStep":
      handleDragError("actionToStep", dispatch);
      break;
    case "actionToParentStep":
      handleDragError("actionToParentStep", dispatch);
      break;
    case "dropInTestBlockSteps":
      handleDragError("dropInTestBlockSteps", dispatch);
      break;
    default:
      handleDragError("default", dispatch);
  }
};

