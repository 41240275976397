import _ from "lodash";
import {
  ADD_APPLICATION_STRING_LOCALE_AND_VALUE,
  ADD_CONDITION_TO_STEP,
  ADD_DEVICE_STRING_LOCALE_AND_VALUE,
  APPLICATION_STRING_LOADER,
  APPLICATION_STRING_TAB,
  CAPTURE_EXPANDED_ELEMENTS,
  CAPTURE_EXPANDED_STEPS,
  CAPTURE_SCREENSHOT,
  CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY,
  CHANGE_DISABLE_STEP,
  CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE,
  CHANGE_SWIPE_PERCENTAGE,
  CLEAR_EXPANDED_ELEMENTS,
  CLEAR_EXPANDED_STEPS,
  CLOSE_PREVIEW_SETTING_DRAWER,
  DEFAULT_PAGE,
  DELETE_APPLICATION_STRING_LOCALE,
  DELETE_DEVICE_STRING_LOCALE,
  DELETE_REC_TEST_STEP_PRE_CONDITION,
  DEVICE_STRING_LOADER,
  EDIT_REC_TEST_STEP,
  EDIT_REC_TEST_STEP_ELEMENT,
  ELEMENT_ACTION,
  ELEMENT_DEFAULT_LOAD_TIME,
  ERROR_TYPE,
  ElementType,
  GET_APPLICATION_STRINGS,
  GET_DEVICE_STRINGS,
  GET_LIST_OF_LOCALS,
  HANDLE_SELECT_REC_TEST_STEP_ACTION,
  HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE,
  HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL,
  HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL,
  HIDE_IMPORT_REC_TEST_STEP_MODAL,
  HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER,
  IS_DELETE_REC_TEST_STEP_REF,
  LAUNCH_APP,
  LOAD_EDIT_APPLICATION_STRING_DETAILS,
  LOAD_EDIT_DEVICE_STRING_DETAILS,
  OPEN_PREVIEW_SETTING_DRAWER,
  RECORD_15_PER_PAGE,
  REGEX_INTEGER,
  REMOVE_CONDITION_FROM_REC_TEST_STEP,
  REMOVE_ELEMENT_INTO_STEP,
  REQUESTED_CLEAR_PREVIEW,
  REQUESTED_DELETE_REC_TEST_STEP,
  REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION,
  REQUESTED_PERFORM_REC_TEST_STEP_ACTION,
  REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  REQUESTING_APP_PACKAGE_ACTIVITY,
  REQUEST_CLEAR_PREVIEW,
  REQUEST_DELETE_REC_TEST_STEP,
  REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION,
  REQUEST_PERFORM_REC_TEST_STEP_ACTION,
  REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
  RESET_APPLICATION_STRING_DATA,
  RESET_DEVICE_STRING_DATA,
  RESET_EDIT_STEP,
  RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
  RESET_TEST_DATA_CONTENT,
  RESET_TEST_DATA_SET_LIST_FOR_SELECTION,
  RESET_UNSAVED_ELEMENTS,
  SCENARIO_GENERATE_REQUEST_START,
  SCENARIO_GENERATE_REQUEST_STOP,
  SCENARIO_GENERATED_STEPS,
  SELECTOR_TYPE,
  SET_APP_LOCALE_FOR_PREVIEW,
  SET_ELEMENT_DETAILS,
  SET_SELECTED_REC_STEP_TAB_KEY,
  SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW,
  SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE,
  SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL,
  SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL,
  SHOW_IMPORT_REC_TEST_STEP_MODAL,
  SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER,
  STEP,
  STEP_ITEMS,
  SUCCESS_STATUS,
  SUCCESS_TYPE,
  SWIPE,
  SWIPE_DIRECTION,
  SWIPE_OVER_ELEMENT,
  TAG_LIST_RECORD_SCENARIO,
  TEST_SCENARIOS,
  TEST_STEP_BLOCKS,
  UPDATE_INSTALLED_APP_ACTIVITY_LIST,
  UPDATE_INSTALLED_APP_LIST,
  UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW,
  UPDATE_SELECTED_REC_TEST_STEP,
  UPDATE_STEP_TYPE,
  UPDATE_UNSAVED_REC_TEST_STEP,
  UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT,
  VERIFICATIONS,
  VISIBLE_GENERAL_SETTINGS_DRAWER,
  VISIBLE_IDENTIFICATION_RULES_DRAWER,
  XpathGenerateMethod,
  splitValue,
  verificationExistenceValues,
  UPDATE_LAST_GENERATED_STEP_INDEX,
  SCENARIO_GENERATE_DEBUG_REQUEST_START,
  SCENARIO_GENERATE_DEBUG_REQUEST_STOP,
  DEVICE_INTERACTION_MODE,
} from "../../Constants";
import {
  checkActionHaveRelativePointOption,
  getMappedStepName,
  getNewRecElement,
  getParentRecElement,
  getParentRefElementLocator,
  getRecElement,
  getServiceMethod,
  getSwipeObject,
  getUnSavedRecElement,
  getUniqueId,
  isActionNeedToSelectElement,
  isNotNullAndNotEmptyAndNotUndefined,
  isNullOrUndefinedOrEmpty,
  isRecElementsChanged,
  isRecElementsPropertyChanged,
  isStepHaveLoopStep,
  isStepHavePrecondition,
  prepareElementLocationAndSizeObject,
  removeSpaceFromVerifications,
  resetElementProperties,
  selectedAction,
  showNotification,
  suggestedStepName,
  suggestedStepNameFromElement,
  updatePreConditionalTitle,
  updateRecElement,
  updateResponse,
  updateUnSavedRecElement,
} from "../../Util";
import { async } from "../../api/automation";
import {
  addVerificationRow,
  fetchRecTestStepVerificationProperty,
  getPreviousStoredValue,
  operatorChange,
  removeVerificationForApplicationStrings,
  removeVerificationRow,
  updateRecTestStepVerificationProperty,
  updateReferenceStepValue,
  updateReferenceTestDataForVerification,
  updateVerificationKey,
  updateVerificationValue,
} from "../../reducer/RecTestStepCommonViewReducer";
import {
  findParentRecTestStep,
  findRecTestStepActionDetail,
  findRecTestStepById,
  findRecTestStepByTag,
  getEmptyVerificationRow,
  insertIntoRecElement,
  insertIntoRecTestStepRecTestProperty,
} from "../../reducer/RecTestStepReducer";
import { fetchScenariosAndRecTestStepsByPlatform } from "../RecTestStepAction";
import { fetchRecTestStepDetail } from "../RecTestStepMultiColumnViewAction";
import {
  changeDeviceInteractionMode,
  recordingStart,
  recordingStop,
  resetElement,
  selectElement,
  stopPageSource,
} from "../Recording";
import { getListOfBlocksByProjectId } from "../TestBlocksAction";
import {
  addApplicationStringsFormVisible,
  addDeviceStringsFormVisible,
  addRecTestStep,
  deleteRecTestStep,
  getApplicationStrings,
  getDeviceStrings,
  hideAIGenerateStepsRow,
  hideDeleteRecElementConfirmationModal,
  hideDeleteRecTestStepPreConditionConfirmationModal,
  importStepFromJson,
  saveRecTestStepAction,
} from "./RecTestStepRedirectAction";
import { fetchTestDataByScenarioId } from "../TestDataSetAction";
import { fetchTestData } from "../TestDataAction";

let importStepFromJsonInProgress = false

export async function callAddRecTestStep(requestData) {
  return async("recTestStep/customSave", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callDeleteRecTestStep(requestData) {
  return async("recTestStep/customDelete", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callDeleteRecTestBlockStep(requestData) {
  return async("recTestStepBlock/customDeleteBlock", "POST", requestData).then((response) => {
    return response;
  });
}
export async function callDuplicateRecTestStep(requestData) {
  return async("recTestStep/duplicateRecTestStep", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callDuplicateRecTestBlockStep(requestData) {
  return async("recTestStepBlock/duplicateRecTestStep", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callMakeGroupRecTestStep(requestData) {
  return async("recTestStep/makeCurrentStepSubStep", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callImportRecTestStep(requestData) {
  return async("recTestScenarioRecTestStepAssoc/importRecTestStep", "POST", requestData).then((response) => {
    return response;
  });
}
export async function callDragAndDropRecTestStep(requestData, url) {
  return async(url, "POST", requestData).then((response) => {
    return response;
  });
}

export async function callDisabledRecTestStep(requestData) {
  return async("recTestStepProperty/disableStep", "POST", requestData).then((response) => {
    return response;
  });
}

export async function callDisabledRecTestScenarioRecTestStep(requestData) {
  return async("recTestScenarioRecTestStepAssoc/disableStep", "POST", requestData).then((response) => {
    return response;
  });
}

export function getNewRecTestStepObj(orderId, projectId, stepItemType) {
  let recTestStep = {
    tag: getUniqueId(),
    orderId,
    recTestStepProperty: {
      action: STEP,
      operator: "AND",
      selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
      recElements: [],
      stepName: "New Step-" + orderId,
    },
    project: { id: projectId },
    recTestSteps: [],
  };
  return recTestStep;
}

export function getNewRecTestStepActionObj(stepAction, parentRecTestStep, recElementGuid) {
  let recTestStep = undefined;
  recTestStep = {
    tag: getUniqueId(),
    isTestBlock: 0,
    recTestStepProperty: {
      action: stepAction,
      stepName: getMappedStepName(stepAction),
      operator: "AND",
      uuid: getUniqueId(),
      elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
      retryCount: 0,
      invertResult: 0,
      isSkipStep: 0,
      pauseTime: 0,
      recStepVerifyElementProperties:
        stepAction === VERIFICATIONS ? [getEmptyVerificationRow("VerifyExistence", "exist")] : [],
      isCaptureScreenshot: stepAction === CAPTURE_SCREENSHOT ? 1 : 0,
      captureScreenShotDelayTime: 0,
      isStepHasPrecondition: 0,
      horizontalRelativePoint: checkActionHaveRelativePointOption(stepAction) ? 50 : 0,
      verticalRelativePoint: checkActionHaveRelativePointOption(stepAction) ? 50 : 0,
      selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
      isRefElementSelected: 0,
      recSwipeAttributes:
        stepAction === SWIPE_OVER_ELEMENT || stepAction === ELEMENT_ACTION.SWIPE
          ? { direction: SWIPE_DIRECTION.UP, percentage: 50 }
          : undefined,
      recElements: isActionNeedToSelectElement(stepAction) ? [getNewRecElement(stepAction, recElementGuid)] : [],
    },
    parentRecTestStep: { id: parentRecTestStep.id },
    parentRecTestScenarioRecTestStepsAssoc: { id: parentRecTestStep.recTestScenarioRecTestStepAssocId },
  };
  return recTestStep;
}

export function addUnsavedValueAfterRecTestStepSave(recTestStep, stepItemType, newRecElementGuid) {
  switch (stepItemType) {
    case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT:
      recTestStep["recTestStepProperty"] = {
        ...recTestStep["recTestStepProperty"],
        preConditionTitle: "",
        isStepHasPrecondition: 1,
        operator: "AND",
        recElements: [getNewRecElement(recTestStep.recTestStepProperty?.action, newRecElementGuid)],
        recStepVerifyElementProperties: [getEmptyVerificationRow("VerifyExistence", "exist")],
      };
      break;
    case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA:
      recTestStep["recTestStepProperty"] = {
        ...recTestStep["recTestStepProperty"],
        preConditionTitle: "",
        isStepHasPrecondition: 1,
        isStepHasVerifyTestData: 1,
        recStepVerifyElementProperties: [getEmptyVerificationRow("VerifyTestData")],
        operator: "AND",
      };
      break;
    case STEP_ITEMS.REPEAT_TILL:
      recTestStep["recTestStepProperty"] = {
        ...recTestStep["recTestStepProperty"],
        isLoopStep: 1,
        loopCount: 5,
        addLoopCondition: false,
        isLoopCountSaved: false,
      };
      break;
    default:
      break;
  }
  return recTestStep;
}

export function _updateRecElementDetailInRecTestStep(
  elementScreenShot,
  selectedElementAttributes,
  elementDetailsFromServer,
  elementDetailsFromPageSource,
  unSavedRecTestStep,
  unSavedRecElement
) {
  unSavedRecTestStep["recTestStepProperty"]["action"] = selectedAction(
    unSavedRecTestStep["recTestStepProperty"]["action"],
    [elementDetailsFromServer],
    elementDetailsFromPageSource && elementDetailsFromPageSource.attributes
      ? elementDetailsFromPageSource.attributes
      : undefined
  );
  // Set the Element Id for interaction tap by index option value through localtion and size
  let parentRefElementDetails = getParentRefElementLocator(elementDetailsFromServer);
  if (
    !_.isEmpty(parentRefElementDetails) &&
    parentRefElementDetails.length > 0 &&
    !_.isEmpty(parentRefElementDetails[0].mobileElements) &&
    parentRefElementDetails[0].mobileElements.length > 1
  ) {
    parentRefElementDetails[0].mobileElements.map((v, k) => {
      // prepare location and size object
      let selectedElementLocationAndSize = prepareElementLocationAndSizeObject(selectedElementAttributes);
      if (
        JSON.stringify(v.location) === JSON.stringify(selectedElementLocationAndSize.location) &&
        JSON.stringify(v.size) === JSON.stringify(selectedElementLocationAndSize.size)
      ) {
        unSavedRecTestStep["selectedElementId"] = v.id;
        unSavedRecTestStep["recTestStepProperty"]["uniqueByIndex"] = k + 1;
      }
    });
  } else if (
    elementDetailsFromServer &&
    elementDetailsFromServer.recElementLocatorProperties.length > 0 &&
    elementDetailsFromServer.recElementLocatorProperties[0].mobileElements.length > 0
  ) {
    let selectedElementId = elementDetailsFromServer.recElementLocatorProperties[0].mobileElements[0].id;
    unSavedRecTestStep["selectedElementId"] = selectedElementId;
  } else if (parentRefElementDetails[0].mobileElements.length > 0) {
    unSavedRecTestStep["recTestStepProperty"]["uniqueByIndex"] = parentRefElementDetails[0].mobileElements.length;
  }
  // if (
  //   unSavedRecTestStep.recTestStepProperty?..recElements.length > 1 &&
  //   getUnSavedRecElementCount(unSavedRecTestStep.recTestStepProperty?.recElements, unSavedRecElement.guid) === 1
  // ) {
  //   unSavedRecTestStep.recTestStepProperty["previous_recElements"] = [
  //     ...unSavedRecTestStep.recTestStepProperty?.recElements,
  //   ];
  // }
  unSavedRecTestStep.recTestStepProperty.recElements = updateUnSavedRecElement(
    unSavedRecTestStep.recTestStepProperty?.recElements,
    elementDetailsFromServer
  );
  if (unSavedRecTestStep.recTestStepProperty?.recElements?.length === 1) {
    if (
      unSavedRecTestStep.recTestStepProperty?.action !== STEP &&
      unSavedRecTestStep.recTestStepProperty?.userSuggestedStepName === undefined &&
      unSavedRecTestStep.recTestStepProperty?.id === undefined
    ) {
      unSavedRecTestStep["recTestStepProperty"] = {
        ...unSavedRecTestStep["recTestStepProperty"],
        stepName: suggestedStepName(
          unSavedRecTestStep.recTestStepProperty?.recElements,
          unSavedRecTestStep["recTestStepProperty"]["action"]
        ),
      };
    } else {
      unSavedRecTestStep.recTestStepProperty["preConditionTitle"] = suggestedStepNameFromElement(
        elementDetailsFromPageSource,
        VERIFICATIONS
      );
    }
  }

  return unSavedRecTestStep;
}

export const getDuplicateRecTestStepObj = (recTestStepList, duplicateRecTestStep) => {
  let recTestStep = { ...duplicateRecTestStep };
  const parentRecTestStep = findParentRecTestStep(recTestStepList, recTestStep.id);

  if (parentRecTestStep && parentRecTestStep?.recTestSteps) {
    recTestStep["orderId"] = parentRecTestStep?.recTestSteps?.length + 1;
  } else {
    recTestStep["orderId"] = recTestStepList?.length + 1;
  }
  if (parentRecTestStep) {
    recTestStep["parentRecTestStep"] = { id: parentRecTestStep["id"] };
    recTestStep["recTestScenarioRecTestStepsAssoc"] = { id: recTestStep.recTestScenarioRecTestStepAssocId };
    recTestStep["parentRecTestScenarioRecTestStepsAssoc"] = {
      id: parentRecTestStep.recTestScenarioRecTestStepAssocId,
    };
  }
  return recTestStep;
};

export const makeSubRecTestStepObj = (
  recTestStep,
  recTestScenarioRecTestStepAssocId,
  getState,
  parentRecTestStepId,
  recTestStepsId,
  recTestStepList
) => {
  return {
    tag: getUniqueId(),
    orderId: recTestStep?.orderId,
    recTestScenario: {
      id: getState().RecTestStepReducer.selectedRecTestScenarioId,
    },
    recTestScenarioRecTestStepAssocIds:
      recTestStepsId === undefined
        ? recTestStep?.map((i) => i?.recTestScenarioRecTestStepAssocId) ||
          recTestStepList?.map((i) => i?.recTestScenarioRecTestStepAssocId)
        : [recTestScenarioRecTestStepAssocId],
    recTestStepProperty: {
      action: STEP,
      operator: "AND",
      stepName: "Parent Step-" + (recTestStepList.length + 1),
      recElements: [],
    },
    recTestSteps:
      recTestStepsId === undefined
        ? recTestStep?.map((i) => ({
            id: i?.recTestStepId,
            orderId: i?.orderId,
          }))
        : [{ id: recTestStepsId, orderId: 1 }],
    parentRecTestScenarioRecTestStepsAssoc: parentRecTestStepId ? { id: parentRecTestStepId } : undefined,
  };
};

export const commonDeleteRecTestStep = (getState) => {
  let recTestScenarioRecTestStepsAssocs = {
    projectId: getState().ProjectsReducer.projectId,
    isDeleteReference: getState().RecTestStepReducer.isDelateRecTestStepRef,
  };
  return recTestScenarioRecTestStepsAssocs;
};

export function editRecTestStep(recTestStepTag) {
  return (dispatch) => {
    dispatch({ type: EDIT_REC_TEST_STEP, recTestStepTag });
  };
}

export function editRecTestStepElement(recTestStepTag, recElementGuid) {
  return (dispatch) => {
    dispatch({ type: EDIT_REC_TEST_STEP_ELEMENT, recTestStepTag, recElementGuid });
  };
}

export function resetEditRecTestStep() {
  return (dispatch) => {
    dispatch({ type: RESET_EDIT_STEP });
  };
}
export function resetPreviewSettings() {
  return (dispatch) => {
    dispatch(_setTestDataSetProfileForPreview(undefined, undefined));
    dispatch(_setAppLocaleProfileForPreview(undefined));
    dispatch(_setAppLocaleProfileForPreview(undefined));
    dispatch({ type: RESET_TEST_DATA_SET_LIST_FOR_SELECTION });
  };
}

export function resetTestDataContent(unSavedRecTestStep) {
  return (dispatch) => {
    dispatch({ type: RESET_TEST_DATA_CONTENT, unSavedRecTestStep });
  };
}
function disableExtraXpathForElement(recElements){
  recElements.forEach(element => {
    if(element.elementSelectorType=== SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN){
      // If unique_resource_Id_text is there disable everything
      if(element.recElementLocatorProperties.filter(property => property.generatedMethod == XpathGenerateMethod.UNIQUE_RESOURCE_ID_AND_TEXT).length>0 && element.recElementLocatorProperties.filter(property => [XpathGenerateMethod.UNIQUE_TEXT,XpathGenerateMethod.UNIQUE_CLASS_AND_TEXT].includes(property.generatedMethod)).length>0){
        element.recElementLocatorProperties.forEach(property=>{
          if([XpathGenerateMethod.UNIQUE_TEXT,XpathGenerateMethod.UNIQUE_CLASS_AND_TEXT,XpathGenerateMethod.PARENT_REF].includes(property.generatedMethod)){
            property.isActive=0;
          }
        })
      }
      // If resource Id is there disable everything
      if(element.recElementLocatorProperties.filter(property => property.generatedMethod == XpathGenerateMethod.UNIQUE_RESOURCE_ID).length>0){
        element.recElementLocatorProperties.forEach(property=>{
          if(![XpathGenerateMethod.UNIQUE_RESOURCE_ID].includes(property.generatedMethod)){
            property.isActive=0;
          }
        })
      }
    }
  })
  return recElements;
}
export function _performRecTestStepAction(unSavedRecTestStep) {
  return (dispatch, getState) => {
    const { selectedDeviceIdForRecording, selectedServerIdForRecording,selectedRecTestScenarioId } = getState().RecTestStepReducer;
    // Fetch from RecTestStepReducer
    const { unSavedRecElementGuid, recTestStepActionSelectedElement,scenarioGenerateInstructions,selectedModelType } = getState().RecTestStepCommonViewReducer;

    const { serverDetail } = getState().RecordingReducer;
    const nonDeviceElements = unSavedRecTestStep.recTestStepProperty?.recElements.filter(
      (item) =>
        [SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN, SELECTOR_TYPE.CUSTOM_ELEMENT].includes(item.elementSelectorType) &&
        item.guid === unSavedRecElementGuid
    );
    unSavedRecTestStep.recTestStepProperty["recElements"] = disableExtraXpathForElement(unSavedRecTestStep.recTestStepProperty?.recElements);
    if (
      (unSavedRecTestStep["recTestStepProperty"]["isAIStep"]==1 ||
      (recTestStepActionSelectedElement ||
        unSavedRecTestStep.id !== undefined ||
        unSavedRecTestStep.recTestStepProperty?.action === LAUNCH_APP ||
        unSavedRecTestStep.recTestStepProperty?.action === ELEMENT_ACTION.SWIPE ||
        nonDeviceElements.length > 0 ||
        (unSavedRecTestStep.recTestStepProperty?.action === VERIFICATIONS &&
          unSavedRecTestStep.recTestStepProperty?.recStepVerifyElementProperties.filter(
            (item) => item.type === "VerifyExistence" && item.key === verificationExistenceValues.does_not_exist
          ).length > 0))) &&
      unSavedRecTestStep
    ) {
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });

      if (validateRecTestStepActionDetail(unSavedRecTestStep, dispatch)) {
        let serviceMethod = getServiceMethod(
          unSavedRecTestStep["recTestStepProperty"]["action"],
          getUnSavedRecElement(unSavedRecTestStep["recTestStepProperty"]["recElements"])
        );
        let serviceData = {};
        if(unSavedRecTestStep["recTestStepProperty"]["isAIStep"]==1){
          serviceMethod="executeAIStep";
          serviceData = {
            ...serviceData,
            deviceId:selectedDeviceIdForRecording,
            recTestScenarioId:selectedRecTestScenarioId,
            rules:scenarioGenerateInstructions,
            modelType:selectedModelType,
          }
        }
        
        serviceData["recTestStepProperty"] = _.cloneDeep(unSavedRecTestStep["recTestStepProperty"]);
        serviceData["recTestStepProperty"]["recElements"] = serviceData["recTestStepProperty"].recElements.filter(
          (item) => item.guid === unSavedRecElementGuid
        );
        if ([SWIPE_OVER_ELEMENT, VERIFICATIONS, STEP].includes(unSavedRecTestStep["recTestStepProperty"]["action"])) {
          unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"] = removeSpaceFromVerifications(
            unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"]
          );
          serviceData["recTestStepProperty"] = unSavedRecTestStep["recTestStepProperty"];
        } else if (unSavedRecTestStep["recTestStepProperty"]["action"] === ELEMENT_ACTION.SWIPE) {
          serviceData = {
            swipeDirection: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["direction"],
            swipePercentage: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["percentage"],
            targetUniqueId: selectedDeviceIdForRecording,
            serverId: selectedServerIdForRecording,
          };
        } else {
          serviceData["recTestStepProperty"] = unSavedRecTestStep["recTestStepProperty"];
        }
        //Remove Elements which doesn't have the property
        if (serviceData["recTestStepProperty"] && serviceData["recTestStepProperty"]["recElements"]) {
          serviceData["recTestStepProperty"]["recElements"] = serviceData["recTestStepProperty"]["recElements"].filter(
            (item) =>
              item.recElementLocatorProperties.length > 0 ||
              item.searchKey !== undefined ||
              item.selectorType !== SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN
          );
        }
        serviceData = {
          ...serviceData,
          targetUniqueId: selectedDeviceIdForRecording,
          serverId: selectedServerIdForRecording,
        };
        if (serviceMethod) {
          return async("device/" + serviceMethod, "POST", serviceData, serverDetail?.serverIp,undefined,dispatch).then((response) => {
            // addSelectedElementDetailIntoRecTestStepActionDetail(recTestStepActionSelectedElement, unSavedRecTestStep);
            return response;
          });
        }
      }
    } else {
      showNotification("error", "Please Select The Element.", "");
    }
    return null;
  };
}

function validateRecTestStepActionDetail(newRecTestStepActionDetail, dispatch) {
  let validated = true;
  let action = newRecTestStepActionDetail["recTestStepProperty"]["action"];
  let uniqueByIndex = newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"];
  if (action === ELEMENT_ACTION.TAP_BY_INDEX && (uniqueByIndex === undefined || uniqueByIndex === "")) {
    validated = false;
    showNotification("error", "Error in performing actions.", "Please Select The Element.");
    dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
  }
  return validated;
}

function addSelectedElementDetailIntoRecTestStepActionDetail(
  recTestStepActionSelectedElement,
  newRecTestStepActionDetail
) {
  let uniqueByIndex = newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"]
    ? newRecTestStepActionDetail["recTestStepProperty"]["uniqueByIndex"]
    : -1;
  if (newRecTestStepActionDetail["recTestStepProperty"]["stepName"] === undefined) {
    newRecTestStepActionDetail["recTestStepProperty"]["stepName"] =
      newRecTestStepActionDetail["recTestStepProperty"]["action"];
  }
  if (recTestStepActionSelectedElement) {
    newRecTestStepActionDetail["recTestStepProperty"] = {
      ...newRecTestStepActionDetail["recTestStepProperty"],
      actionValue:
        newRecTestStepActionDetail["recTestStepProperty"]["action"] === ELEMENT_ACTION.SEND_KEYS &&
        recTestStepActionSelectedElement.args &&
        recTestStepActionSelectedElement.args.length === 1
          ? recTestStepActionSelectedElement.args[0]
          : "",
      // For Unique by text
      uniqueByText: recTestStepActionSelectedElement.uniqueByText,
      // Screenshot of Element
      screenShot: recTestStepActionSelectedElement["screenShot"].split("data:image/png;base64,")[1],
    };
  }
  newRecTestStepActionDetail["recTestStepProperty"] = {
    ...newRecTestStepActionDetail["recTestStepProperty"],
    // If unique By Index is empty pass -1
    uniqueByIndex,
    //Default Value to load element
    elementMaxLoadTime: ELEMENT_DEFAULT_LOAD_TIME,
  };
}

export function _saveRecTestStepAction(
  unSavedRecTestStep,
  responseData,
  recTestStepId,
  recTestStepTag,
  recElementGuid
) {
  return (dispatch, getState) => {
    let { recTestStepList, selectedRecTestScenarioId } = getState().RecTestStepReducer;
    let { testBlockStep } = getState().TestBlockReducer;
    let { unSavedRecStepTag, stepType } = getState().RecTestStepCommonViewReducer;

    let parentRecTestStepDetail = findParentRecTestStep(recTestStepList, recTestStepId, recTestStepTag);
    if (unSavedRecTestStep) {
      if (
        unSavedRecStepTag &&
        isNotNullAndNotEmptyAndNotUndefined(unSavedRecStepTag) &&
        parentRecTestStepDetail &&
        unSavedRecTestStep.id !== parentRecTestStepDetail.id
      ) {
        unSavedRecTestStep["parentRecTestStep"] = {
          id: parentRecTestStepDetail.id,
        };
        unSavedRecTestStep["parentRecTestScenarioRecTestStepsAssoc"] = {
          id: parentRecTestStepDetail.recTestScenarioRecTestStepAssocId,
        };
      }
      if (
        unSavedRecTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
        testBlockStep &&
        testBlockStep.tag === unSavedRecTestStep.tag
      ) {
        unSavedRecTestStep["recTestSteps"] = [...recTestStepList];
      } else {
        //Add Rec Test Scenario reference
        unSavedRecTestStep["recTestScenario"] = {
          id: selectedRecTestScenarioId,
        };
      }
      if (unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"]) {
        unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"] = removeSpaceFromVerifications(
          unSavedRecTestStep["recTestStepProperty"]["recStepVerifyElementProperties"]
        );
      }
      if (responseData && responseData["recElements"]) {
        unSavedRecTestStep["recTestStepProperty"]["recElements"] = updateUnSavedRecElement(
          unSavedRecTestStep["recTestStepProperty"]["recElements"],
          responseData["recElements"][0]
        );
      } else if (responseData && responseData["imageData"] && recElementGuid) {
        let recElement = getRecElement(unSavedRecTestStep["recTestStepProperty"]["recElements"], recElementGuid);
        recElement.elementImage = responseData["imageData"];
        unSavedRecTestStep["recTestStepProperty"]["recElements"] = updateRecElement(
          unSavedRecTestStep["recTestStepProperty"]["recElements"],
          recElement
        );
      }
      if (stepType === TEST_STEP_BLOCKS) {
        const projectId = getState().ProjectsReducer.projectId;
        unSavedRecTestStep = {
          ...unSavedRecTestStep,
          recTestScenario: null,
          project: { id: projectId },
          testBlockStep: unSavedRecTestStep.id !== testBlockStep.id ? { id: testBlockStep.id } : undefined,
          isTestBlock: 1,
        };
      }
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
      return async("recTestStep/customSave", "POST", unSavedRecTestStep).then((response) => {
        stopPageSource();
        return response;
      });
    }
  };
}

export function fetchAppActivity(selectedApp) {
  return (dispatch, getState) => {
    const { serverDetail } = getState().RecordingReducer;
    const { selectedDeviceIdForRecording } = getState().RecTestStepReducer;
    dispatch({ type: REQUESTING_APP_PACKAGE_ACTIVITY, status: true });
    async(
      "device/getInstalledAppActivity",
      "GET",
      {
        targetUniqueId: selectedDeviceIdForRecording,
        serverId: serverDetail?.id,
        appPackageName: selectedApp,
      },
      serverDetail ? serverDetail.serverIp : "",undefined,dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: UPDATE_INSTALLED_APP_ACTIVITY_LIST, response });
      }
      dispatch({ type: REQUESTING_APP_PACKAGE_ACTIVITY, status: false });
    });
  };
}

//Common Function
export function _getInstalledApp(targetUniqueId, serverId) {
  return async (dispatch, getState) => {
    const { serverDetail } = getState().RecordingReducer;
    async(
      "device/getInstalledApp",
      "GET",
      {
        targetUniqueId: targetUniqueId,
        serverId: serverId,
      },
      serverDetail ? serverDetail.serverIp : "",undefined,dispatch
    ).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: UPDATE_INSTALLED_APP_LIST, response });
      }
    });
  };
}

export function _selectedAppForLaunch(selectedApp, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "packageName", selectedApp);
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "packageActivity", undefined);
  return unSavedRecTestStep;
}
export function _selectedAppActivityForLaunch(selectedAppActivity, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "packageActivity", selectedAppActivity);
  return unSavedRecTestStep;
}

export function _changeRemoveAppPreviousSession(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "removeAppPreviousSession", checked);
  return unSavedRecTestStep;
}
export function _updateUnSavedRecTestStep(unSavedRecTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_UNSAVED_REC_TEST_STEP, unSavedRecTestStep });
  };
}

export function _selectedRecTestStepTag(unSavedRecTestStep) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_SELECTED_REC_TEST_STEP, unSavedRecTestStep });
  };
}

export function _updateUnSavedRecTestStepAndRecElementGuid(unSavedRecTestStep, recElementGuid) {
  return async (dispatch, getState) => {
    dispatch({ type: UPDATE_UNSAVED_REC_TEST_STEP_AND_REC_ELEMENT, unSavedRecTestStep, recElementGuid });
  };
}

//separate Function
export function _updateActionValue(value, unSavedRecTestStep) {
  unSavedRecTestStep["recTestStepProperty"] = {
    ...unSavedRecTestStep["recTestStepProperty"],
    previous_userInputValue: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "userInputValue"),
    previous_uniqueByIndex: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "uniqueByIndex"),
    previous_testData: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "testData"),
    previous_testDataCustomValue: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "testDataCustomValue"
    ),
    previous_appendRandomText: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "appendRandomText"),
    previous_continueAfterFail: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "continueAfterFail"),
    previous_preConditionTitle: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "preConditionTitle"),
    previous_stepName: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "stepName"),
    previous_action: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "action"),
    previous_isStepHasPrecondition: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "isStepHasPrecondition"
    ),
    previous_loopCount: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "loopCount"),
    previous_ignoreTestPlanTestData: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "ignoreTestPlanTestData"
    ),
    previous_packageName: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "packageName"),
    previous_swipeToFindElement: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "swipeToFindElement"
    ),
    previous_swipeCountToFindElement: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "swipeCountToFindElement"
    ),
    previous_elementMaxLoadTime: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "elementMaxLoadTime"
    ),
    previous_retryCount: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "retryCount"),
    previous_isLoopStep: getPreviousStoredValue(unSavedRecTestStep["recTestStepProperty"], "isLoopStep"),
    previous_horizontalRelativePoint: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "horizontalRelativePoint"
    ),
    previous_verticalRelativePoint: getPreviousStoredValue(
      unSavedRecTestStep["recTestStepProperty"],
      "verticalRelativePoint"
    ),
    previous_showTestDataContent:
      unSavedRecTestStep["recTestStepProperty"].action === ELEMENT_ACTION.SEND_KEYS ? true : false,
    userInputValue: undefined,
    uniqueByIndex: undefined,
    testData: undefined,
    testDataCustomValue: undefined,
    appendRandomText: undefined,
  };
  // Update Action
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "action", value);
  // Update Suggested Step Name
  if (unSavedRecTestStep.id === undefined) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      unSavedRecTestStep,
      "stepName",
      suggestedStepName(unSavedRecTestStep.recTestStepProperty?.recElements, value)
    );
  }
  if (checkActionHaveRelativePointOption(value)) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "horizontalRelativePoint", 50);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "verticalRelativePoint", 50);
  }
  if (value === SWIPE_OVER_ELEMENT || value === ELEMENT_ACTION.SWIPE) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "recSwipeAttributes", {
      direction: SWIPE_DIRECTION.UP,
      percentage: 50,
    });
  }
  // Update Tap by index
  if (value === ELEMENT_ACTION.TAP_BY_INDEX) {
    // const parentRefElement = action.elementListByServer?.filter(
    //   (item) => item.generatedMethod === XpathGenerateMethod.PARENT_REF
    // );
    // if (parentRefElement?.length > 0) {
    //   unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    //     unSavedRecTestStep,
    //     "uniqueByIndex",
    //     parentRefElement[0].currentChildIndex
    //   );
    // }
  }
  return unSavedRecTestStep;
}

//separate Function
export function _changeTapByIndex(value, action, recTestStepId, recTestStepTag) {
  return async (dispatch, getState) => {
    const { selectedServerIdForRecording, selectedDeviceIdForRecording } = getState().RecTestStepReducer;
    const { elementListByServer } = getState().RecTestStepCommonViewReducer;
    const { currentDeviceScreenSource } = getState().RecordingReducer;
    const parentRefElementDetails = getParentRefElementLocator(elementListByServer);
    let bounds = undefined;
    if (parentRefElementDetails && parentRefElementDetails.length > 0) {
      bounds =
        "[" +
        parentRefElementDetails[0].mobileElements[value].location.x +
        "," +
        parentRefElementDetails[0].mobileElements[value].location.y +
        "][" +
        (parseInt(parentRefElementDetails[0].mobileElements[value].location.x) +
          parseInt(parentRefElementDetails[0].mobileElements[value].size.width)) +
        "," +
        (parseInt(parentRefElementDetails[0].mobileElements[value].location.y) +
          parseInt(parentRefElementDetails[0].mobileElements[value].size.height)) +
        "]";
    }
    dispatch(
      selectElement(
        undefined,
        currentDeviceScreenSource,
        selectedServerIdForRecording,
        selectedDeviceIdForRecording,
        undefined,
        undefined,
        bounds,
        { value, action, recTestStepId, recTestStepTag }
      )
    );
    // dispatch({ type: CHANGE_TAP_BY_INDEX, value, action, recTestStepActionId, recTestStepActionTag });
  };
}

//separate Function
export function _changeEnterText(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "userInputValue", value);
  return unSavedRecTestStep;
}

export function _updateApplicationStringsLocale(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    unSavedRecTestStep,
    "changeApplicationStringsLocale",
    value
  );
  return unSavedRecTestStep;
}

//separate Function
export function _changeAppendRandomText(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "appendRandomText", checked);
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecordScenarioTagsList(val) {
  return (dispatch) => {
    dispatch({ type: TAG_LIST_RECORD_SCENARIO, val });
  };
}

//Common Function
export function _showDeleteRecTestStepConfirmationModal(
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  isSelectedRecTestStepForDelete
) {
  return (dispatch) => {
    dispatch({
      type: SHOW_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL,
      recTestStepId,
      recTestStepTag,
      recTestScenarioRecTestStepAssocId,
      isSelectedRecTestStepForDelete,
    });
  };
}

//Common Function
export function _hideDeleteRecTestStepConfirmationModal(flag = 0) {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_REC_TEST_STEP_CONFIRMATION_MODAL });
    dispatch({ type: IS_DELETE_REC_TEST_STEP_REF, flag });
  };
}

//Common Function
export function _showDeleteRecElementConfirmationModal(
  recTestStepId,
  recTestStepTag,
  recTestScenarioRecTestStepAssocId,
  recElementGuid
) {
  return (dispatch) => {
    dispatch({
      type: SHOW_DELETE_REC_ELEMENT_CONFIRMATION_MODAL,
      recTestStepId,
      recTestStepTag,
      recTestScenarioRecTestStepAssocId,
      recElementGuid,
    });
  };
}

//Common Function
export function _selectChildElementPurpose(value, recElementGuid, unSavedRecTestStep) {
  if (value === "ACTION") {
    let parentRecElement = getParentRecElement(unSavedRecTestStep.recTestStepProperty?.recElements, recElementGuid);
    if (
      parentRecElement.childRecElements.filter((childRecElement) => childRecElement.elementPurpose === "ACTION")
        .length > 0
    ) {
      showNotification(ERROR_TYPE, "One Action is allowed for child element");
      return unSavedRecTestStep;
    }
  }
  let recElement = getRecElement(_.cloneDeep(unSavedRecTestStep?.recTestStepProperty?.recElements), recElementGuid);
  recElement.elementPurpose = value;
  if (value === "VERIFICATION") {
    recElement.verificationOperator = "AND";
    if (recElement.selectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
      let recStepVerifyElementProperty = getEmptyVerificationRow("VerifyPresence");
      recStepVerifyElementProperty["key"] = "By Locator";
      recElement.recStepVerifyElementProperties = [];
      recElement.recStepVerifyElementProperties.push(recStepVerifyElementProperty);
    }
  } else if (value === "ACTION") {
    recElement.verificationOperator = undefined;
    recElement.recStepVerifyElementProperties = undefined;
  }
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    unSavedRecTestStep,
    "recElements",
    updateRecElement(_.cloneDeep(unSavedRecTestStep?.recTestStepProperty?.recElements), recElement)
  );
  return unSavedRecTestStep;
}

//Common Function
export function _hideDeleteRecElementConfirmationModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_REC_ELEMENT_CONFIRMATION_MODAL });
  };
}

//Common Function
export function _showDeleteRecTestStepPreConditionConfirmationModal(recTestStepTag, recTestStepPropertyId) {
  return (dispatch) => {
    dispatch({
      type: SHOW_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL,
      recTestStepTag,
      recTestStepPropertyId,
    });
  };
}

//Common Function
export function _hideDeleteRecTestStepPreConditionConfirmationModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_REC_TEST_STEP_PRE_CONDITION_CONFIRMATION_MODAL });
  };
}

// TODO:Pending to move from RecTestStepReducer
export function _resetElementSelectionAndStopRecording() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_REC_TEST_STEP_ACTION_SELECTED_ELEMENT,
      reRecordRecStepTag: getState().RecTestStepCommonViewReducer.unSavedRecStepTag,
      recElementGuid: getState().RecTestStepCommonViewReducer.unSavedRecElementGuid,
    });
    dispatch(resetEditRecTestStep());
    dispatch(recordingStop());
  };
}

// TODO:Pending to move from RecTestStepReducer
export function _deleteRecTestStepPreCondition(recTestStep, recElement) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_REC_TEST_STEP_PRE_CONDITION });
    const { selectedRecTestStepPropertyIdForDelete } = getState().RecTestStepReducer;
    let recTestStepPropertyId = selectedRecTestStepPropertyIdForDelete
      ? selectedRecTestStepPropertyIdForDelete
      : recTestStep?.recTestStepProperty?.id;
    recElement = recElement ? recElement : getUnSavedRecElement(recTestStep?.recTestStepProperty?.recElements);
    // Delete the unsaved step directly
    if (
      recTestStepPropertyId === undefined ||
      (recElement && !_.isEmpty(recElement) && (recElement?.id === undefined || recElement?.id === 0))
    ) {
      dispatch({ type: DELETE_REC_TEST_STEP_PRE_CONDITION, deleteFromUnSavedStep: true, recTestStepPropertyId });
      dispatch(_resetElementSelectionAndStopRecording());
      dispatch(hideDeleteRecTestStepPreConditionConfirmationModal());
      // Delete the saved step directly from server
    } else if (recTestStepPropertyId !== undefined) {
      dispatch(_resetElementSelectionAndStopRecording());
      async("recTestStepProperty/deleteByRecTestStepPropertyPreCondition", "POST", {
        id: recTestStepPropertyId,
      }).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          dispatch(resetEditRecTestStep());
          dispatch({ type: DELETE_REC_TEST_STEP_PRE_CONDITION, deleteFromUnSavedStep: false, recTestStepPropertyId });
          showNotification("success", "Successfully Deleted Step.", "");
        } else {
          dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION });
          showNotification("error", response.message, "");
        }
      });
      dispatch(hideDeleteRecTestStepPreConditionConfirmationModal());
    } else {
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP_PRE_CONDITION });
      showNotification("error", "Error in Deleting The Step.", "");
    }
  };
}

//Common Function
export function _changeRecTestStepActionName(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(_.cloneDeep(unSavedRecTestStep), "stepName", value);
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "userSuggestedStepName",
    value
  );
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecTestStepActionSelectedElement(selectedElement) {
  return (dispatch) => {
    dispatch({ type: CHANGE_REC_TEST_STEP_ACTION_SELECTED_ELEMENT, selectedElement });
  };
}

//Common Function
export function _changeRecTestStepElementXpathActive(checked, value, unSavedRecTestStep, unSavedRecElement) {
  let recElementLocatorProperties = _.cloneDeep(unSavedRecElement.recElementLocatorProperties);
  recElementLocatorProperties.forEach((element) => {
    if (element.value === value) element.isActive = checked ? 1 : 0;
  });
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "recElementLocatorProperties",
    recElementLocatorProperties
  );
  unSavedRecTestStep.recTestStepProperty.recElements = updateRecElement(
    unSavedRecTestStep.recTestStepProperty?.recElements,
    unSavedRecElement
  );
  return unSavedRecTestStep;
}

//Common Function
export function _setElementDetails(
  elementDetailsFromServer,
  selectedElementAttributes,
  elementDetailsFromPageSource,
  elementScreenshot,
  highlightElementInDevice
) {
  return (dispatch, getState) => {
    const { unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    dispatch({
      type: SET_ELEMENT_DETAILS,
      elementDetailsFromServer,
      selectedElementAttributes,
      elementDetailsFromPageSource,
      elementScreenshot,
      highlightElementInDevice,
      recTestStepTag: unSavedRecStepTag,
    });
  };
}

//Common Function TODO:Pending to fix this method
export function _swipe(direction, recTestStepTag) {
  return async (dispatch, getState) => {
    let { recTestStepList, selectedDeviceIdForRecording, selectedServerIdForRecording } = getState().RecTestStepReducer;
    let newRecTestStepAction = findRecTestStepActionDetail(recTestStepList, null, recTestStepTag);
    let swipe = getSwipeObject(direction, newRecTestStepAction);

    let logedInUser = getState().RecordingReducer.logedInUser;

    if (swipe) {
      dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
      return async(
        "device/swipe",
        "POST",
        {
          swipeDirection: direction,
          swipePercentage: swipe.percentage,
          targetUniqueId: selectedDeviceIdForRecording,
          serverId: selectedServerIdForRecording,
          logedInUser,
        },
        getState().RecordingReducer.serverDetail.serverIp,undefined,dispatch
      ).then((response) => {
        response = updateResponse(response);
        if (response.status === SUCCESS_STATUS) {
          //Update newRecTestStepActionDetail Object
          dispatch({ type: SWIPE, swipe });
          //Call Save Step API
          new Promise((resolve) => {
            resolve(dispatch(saveRecTestStepAction(response.data, null, recTestStepTag)));
          }).then(() => {
            dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
          });
        } else {
          showNotification("error", "Error", response.message);
          dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
        }
      });
    } else {
      showNotification("error", "Error in swipe.", "");
    }
  };
}

//Common Function
export function _changeSwipePercentage(direction, value) {
  return (dispatch) => {
    if (value !== "" && REGEX_INTEGER.test(value)) {
      dispatch({ type: CHANGE_SWIPE_PERCENTAGE, direction, value });
    }
  };
}

//Common Function
export function _saveDeviceInteraction(deviceInteractionAction, screenshot, unSavedRecTestStep) {
  if (unSavedRecTestStep && unSavedRecTestStep.id === undefined) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "action", deviceInteractionAction);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      unSavedRecTestStep,
      "stepName",
      "Press device " + deviceInteractionAction + " button"
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _addVerificationRow(verificationType, uuid, recElementGuid, unSavedRecTestStep, newUuid) {
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    recStepVerifyElementProperties = addVerificationRow(
      _.cloneDeep(recStepVerifyElementProperties),
      uuid,
      verificationType,
      newUuid
    );
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _removeVerificationRow(uuid, recElementGuid, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    if (recStepVerifyElementProperties === undefined) {
      recStepVerifyElementProperties = [];
    } else {
      recStepVerifyElementProperties = removeVerificationRow(_.cloneDeep(recStepVerifyElementProperties), uuid);
    }
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _operatorChange(value, uuid, recElementGuid, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    if (uuid === undefined && recElementGuid === undefined) {
      unSavedRecTestStep.recTestStepProperty.operator = value;
    } else if (uuid === undefined && recElementGuid !== undefined) {
      let recElement = getRecElement(unSavedRecTestStep.recTestStepProperty?.recElements, recElementGuid);
      recElement["verificationOperator"] = value;
    } else if (uuid) {
      recStepVerifyElementProperties = operatorChange(_.cloneDeep(recStepVerifyElementProperties), uuid, value);
    }
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _updateVerificationKey(
  key,
  uuid,
  recElementGuid,
  unSavedRecTestStep,
  testDataList,
  verifyElementPropertyValue
) {
  if (unSavedRecTestStep) {
    let selectTestDataName = testDataList?.find((i) => i?.id === key)?.name;
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") &&
      updatePreConditionalTitle(unSavedRecTestStep, selectTestDataName, verifyElementPropertyValue);
    recStepVerifyElementProperties = updateVerificationKey(_.cloneDeep(recStepVerifyElementProperties), uuid, key);
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _updateVerificationValue(
  value,
  uuid,
  updateElementOnEditMode = false,
  recElementGuid,
  unSavedRecTestStep,
  selectedTestData,
  testDataList
) {
  let selectTestData = testDataList?.find((i) => i?.id === parseInt(selectedTestData))?.name;
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    recStepVerifyElementProperties?.some((i) => i?.type === "VerifyTestData") &&
      updatePreConditionalTitle(unSavedRecTestStep, selectTestData, value);

    recStepVerifyElementProperties = updateVerificationValue(_.cloneDeep(recStepVerifyElementProperties), uuid, value);
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _updateReferenceStepValue(value, uuid, recElementGuid, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid
    );
    recStepVerifyElementProperties = updateReferenceStepValue(_.cloneDeep(recStepVerifyElementProperties), uuid, value);
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      recElementGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _updateReferenceTestDataForVerification(
  value,
  uuid,
  refTestData,
  unSavedRecTestStep,
  elementVerificationGuid
) {
  if (unSavedRecTestStep) {
    let recStepVerifyElementProperties = fetchRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      elementVerificationGuid
    );
    recStepVerifyElementProperties = updateReferenceTestDataForVerification(
      _.cloneDeep(recStepVerifyElementProperties),
      uuid,
      refTestData
    );
    unSavedRecTestStep = updateRecTestStepVerificationProperty(
      { ...unSavedRecTestStep },
      elementVerificationGuid,
      recStepVerifyElementProperties
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipeDirectionOverElement(direction, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "recSwipeAttributes", {
      percentage: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["percentage"],
      direction: direction,
      id: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["id"],
    });
    unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["direction"] = direction;
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipePercentageOverElement(percentage, unSavedRecTestStep) {
  if (percentage <= 100) {
    if (unSavedRecTestStep) {
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "recSwipeAttributes", {
        percentage: percentage,
        direction: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["direction"],
        id: unSavedRecTestStep["recTestStepProperty"]["recSwipeAttributes"]["id"],
      });
    }
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changePauseTime(pauseTime, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "pauseTime", pauseTime);
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeIsCaptureScreenshot(isCaptureScreenshot, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      "isCaptureScreenshot",
      isCaptureScreenshot
    );
    if (isCaptureScreenshot) {
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        { ...unSavedRecTestStep },
        "captureScreenShotDelayTime",
        2000
      );
    } else {
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
        { ...unSavedRecTestStep },
        "captureScreenShotDelayTime",
        0
      );
    }
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeCaptureScreenShotDelayTime(captureScreenShotDelayTime, unSavedRecTestStep) {
  if (unSavedRecTestStep) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      "captureScreenShotDelayTime",
      captureScreenShotDelayTime
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeIsLoopStep(checked, unSavedRecTestStep) {
    if (unSavedRecTestStep) {
      unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "isLoopStep", checked ? 1 : 0);
    }
    return unSavedRecTestStep;
}

//Common Function
export function _changeStepName(value, unSavedRecTestStep) {
  unSavedRecTestStep.recTestStepProperty.stepName = value;
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipeToFindElement(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "swipeToFindElement",
    value ? 1 : 0
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "swipeDirection",
    value ? SWIPE_DIRECTION.UP : undefined
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "swipeCountToFindElement",
    value ? 3 : 0
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "reverseSwipeElementNotFound",
    0
  );
  return unSavedRecTestStep;
}

export function _changeReverseSwipeElementNotFound(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "reverseSwipeElementNotFound",
    value ? 1 : 0
  );
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipeToBringElementToCenter(value, unSavedRecTestStep, unSavedRecElement) {
  if (unSavedRecElement) {
    unSavedRecElement = insertIntoRecElement(unSavedRecElement, "swipeToBringElementToCenter", value ? 1 : 0);
    unSavedRecTestStep.recTestStepProperty.recElements = updateRecElement(
      [...unSavedRecTestStep.recTestStepProperty?.recElements],
      unSavedRecElement
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipeCountToFindElement(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    { ...unSavedRecTestStep },
    "swipeCountToFindElement",
    value
  );
  return unSavedRecTestStep;
}

//Common Function
export function _changeSwipeDirectionToFindElement(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    _.cloneDeep(unSavedRecTestStep),
    "swipeDirection",
    value ? value : SWIPE_DIRECTION.UP
  );
  return unSavedRecTestStep;
}

//Common Function
export function _changeElementLoadTime(value, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "elementMaxLoadTime", value);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeRetryCount(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "retryCount", value);
  return unSavedRecTestStep;
}

//Common Function
export function _changeInvertResult(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "invertResult", checked);
  return unSavedRecTestStep;
}

//Common Function
export function _changeHorizontalRelativePoint(value, unSavedRecTestStep) {
  if (value <= 100 && value >= 0) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      "horizontalRelativePoint",
      value
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeVerticalRelativePoint(value, unSavedRecTestStep) {
  if (value <= 100 && value >= 0) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      "verticalRelativePoint",
      value
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeContinueOnFailure(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    { ...unSavedRecTestStep },
    "continueAfterFail",
    checked ? 1 : 0
  );
  return unSavedRecTestStep;
}

//Common Function
export function _changeDisableStep(checked, recTestSteps) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
    const { unSavedRecTestStep } = getState().RecTestStepCommonViewReducer;
    const { selectedRecTestStepDetail } = getState().RecTestStepMultiColumnViewReducer;

    return async("recTestStepProperty/disableStep", "POST", {
      id: unSavedRecTestStep.recTestStepProperty?.id,
      isSkipStep: checked ? 1 : 0,
    }).then((response) => {
      if (selectedRecTestStepDetail !== undefined) {
        dispatch(fetchRecTestStepDetail(selectedRecTestStepDetail));
      }
      if (response.status === SUCCESS_STATUS) {
        dispatch({ type: CHANGE_DISABLE_STEP, checked });
        dispatch(resetEditRecTestStep());
      } else {
        showNotification("error", response.message, "");
      }
      dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
    });
  };
}

//Common Function
export function _changeIsStepHasPrecondition(checked, unSavedRecTestStep) {
  if (unSavedRecTestStep.recTestStepProperty["previous_isStepHasPrecondition"] === undefined) {
    unSavedRecTestStep.recTestStepProperty["previous_isStepHasPrecondition"] =
      unSavedRecTestStep.recTestStepProperty["isStepHasPrecondition"];
  }
  unSavedRecTestStep.recTestStepProperty["isStepHasPrecondition"] = checked ? 1 : 0;
  if (checked) {
    unSavedRecTestStep.recTestStepProperty["uuid"] = getUniqueId();
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecTestStepPreConditionTitle(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "preConditionTitle", value);
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecTestStepElementCondition(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "recElementOperator", value);
  return unSavedRecTestStep;
}

//Common Function
export function _showDeleteLoopConditionModalVisible(recTestStepTag, unSavedRecTestStep, deleteLoopCondition) {
  return (dispatch) => {
    dispatch({ type: SHOW_DELETE_LOOP_CONDITION_MODAL_VISIBLE, recTestStepTag, deleteLoopCondition });
  };
}

//Common Function
export function _hideDeleteLoopConditionModalVisible() {
  return (dispatch) => {
    dispatch({ type: HIDE_DELETE_LOOP_CONDITION_MODAL_VISIBLE });
  };
}

//Common Function
export function _changeRecTestStepLoopCount(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "loopCount", value);
  return unSavedRecTestStep;
}

export function _removeLoopConditionFromRecTestStep(updateRecTestStep, deleteLoopConditionOnly) {
  return async (dispatch, getState) => {
    const { deleteLoopCondition } = getState().RecTestStepCommonViewReducer;
    deleteLoopConditionOnly = deleteLoopCondition ? deleteLoopCondition : deleteLoopConditionOnly;
    let updateRecTestStepProperty = {
      ...updateRecTestStep?.recTestStepProperty,
    };
    if (!deleteLoopConditionOnly) {
      updateRecTestStepProperty = {
        ...updateRecTestStep?.recTestStepProperty,
        isStepHasPrecondition: 0,
        preConditionTitle: undefined,
        isLoopStep: 0,
        loopCount: 0,
        recStepVerifyElementProperties: [],
        recElements: [],
        addLoopCondition: [],
        recTestStep: { id: updateRecTestStep?.id },
      };
    } else if (updateRecTestStep?.recTestStepProperty?.isLoopStep === 1) {
      updateRecTestStepProperty = {
        ...updateRecTestStep?.recTestStepProperty,
        isStepHasPrecondition: 0,
        preConditionTitle: undefined,
        recStepVerifyElementProperties: [],
        recElements: [],
        addLoopCondition: undefined,
        recTestStep: { id: updateRecTestStep?.id },
      };
    }
    updateRecTestStep["recTestStepProperty"] = updateRecTestStepProperty;
    if (updateRecTestStepProperty?.isLoopCountSaved === false || updateRecTestStepProperty?.previous_loopCount === 0) {
      dispatch(recordingStop());
      dispatch(_hideDeleteLoopConditionModalVisible());
      return updateRecTestStep;
    }

    return async("recTestStepProperty/updateRecTestStepAction", "POST", updateRecTestStepProperty).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(recordingStop());
        dispatch(_hideDeleteLoopConditionModalVisible());
        return updateRecTestStep;
      }
    });
  };
}
//Common Function
export function _addVerificationForRepeatStep(recElementGuid, unSavedRecTestStep) {
  unSavedRecTestStep["recTestStepProperty"] = {
    ...unSavedRecTestStep["recTestStepProperty"],
    addLoopCondition: true,
    recStepVerifyElementProperties: [getEmptyVerificationRow("VerifyExistence", "exist")],
    recElements: [getNewRecElement(unSavedRecTestStep.recTestStepProperty?.action, recElementGuid)],
    previous_recStepVerifyElementProperties: [],
    // previous_loopCount: undefined,
    operator: "AND",
    selectorType: SELECTOR_TYPE.SELECT_ELEMENT_ON_SCREEN,
  };
  return unSavedRecTestStep;
}

//Common Function
export function _changeShowTestData(checked, unSavedRecTestStep) {
  if (!checked) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "testData", undefined);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      { ...unSavedRecTestStep },
      "testDataCustomValue",
      undefined
    );
  } else {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "userInputValue", undefined);
  }
  return unSavedRecTestStep;
}

//Common Function
export function _selectTestData(testDataId, testDataValue, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    { ...unSavedRecTestStep },
    "testData",
    testDataId
      ? {
          id: testDataId,
          value: testDataValue,
        }
      : undefined
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    { ...unSavedRecTestStep },
    "testDataCustomValue",
    undefined
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "userInputValue", undefined);
  return unSavedRecTestStep;
}

//Common Function
export function _selectOutputTestData(testDataId, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "outputTestData", {
    id: testDataId,
  });
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "outputTestDataId", undefined);
  return unSavedRecTestStep;
}

export function _selectAIStepTestData(testDataId,testDatavalue, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "aiTargetElementTestData", {
    id: testDataId,
    value: testDatavalue,
  });
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "aiTargetElementTestDataId", undefined);
  return unSavedRecTestStep;
}

//Common Function
export function _changeInsertValueToTestData(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "insertValueToTestData", value);
  return unSavedRecTestStep;
}

//Common Function
export function _selectOutputTestDataForRecElement(testData, unSavedRecTestStep, unSavedRecElement) {
  let testDataItems = testData?.split(splitValue);
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "testData", {
    id: parseInt(testDataItems[0]),
    name: testDataItems[1],
    value: testDataItems[2],
  });
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "name",
    "Custom Element by test data '" + testDataItems[1] + "'"
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    unSavedRecTestStep,
    "preConditionTitle",
    suggestedStepName(recElements, unSavedRecTestStep.recTestStepProperty?.action)
  );
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeTestDataCustomValue(value, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty({ ...unSavedRecTestStep }, "testDataCustomValue", value);
  return unSavedRecTestStep;
}

//Common Function
export function _changeIgnoreTestPlanData(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    { ...unSavedRecTestStep },
    "ignoreTestPlanTestData",
    checked ? 1 : 0
  );
  return unSavedRecTestStep;
}
export const getImportRecTestStepOrActionObj = (
  requestData,
  isImportOriginal,
  projectId,
  selectedRecTestScenarioId,
  selectedRecTestStepDetail,
  recTestStepList,
  selectRecTestStep
) => {
  let recTestStepDetail = findRecTestStepById(recTestStepList ? recTestStepList : [], selectedRecTestStepDetail);

  let data = {
    importSteps: requestData,
    isKeepOriginal: isImportOriginal ? 1 : 0,
    isMakeStepEditable: isImportOriginal ? 1 : 0,
    project: { id: projectId },
  };
  data.recTestScenario = { id: selectedRecTestScenarioId };
  if (selectedRecTestStepDetail) {
    data = {
      ...data,
      recTestScenarioRecTestStepsAssocParent: {
        id: selectedRecTestStepDetail,
      },
    };
  } else if (
    recTestStepDetail &&
    recTestStepDetail.recTestStepProperty?.action === STEP &&
    recTestStepDetail.recTestSteps &&
    (recTestStepDetail.recTestSteps.length === 0 ||
      (recTestStepDetail.recTestSteps.length > 0 &&
        recTestStepDetail.recTestSteps[0].recTestStepProperty?.action === STEP))
  ) {
    data = {
      ...data,
      recTestScenarioRecTestStepsAssocParent: { id: recTestStepDetail?.recTestScenarioRecTestStepAssocId },
    };
  } else if (selectRecTestStep) {
    data = {
      ...data,
      recTestScenarioRecTestStepsAssocParent: { id: selectRecTestStep?.recTestScenarioRecTestStepAssocId },
    };
  }
  return data;
};

export const getDisabledRecTestStepOrActionObj = (recTestStepPropertyId, checked) => {
  return {
    id: recTestStepPropertyId,
    isSkipStep: checked ? 1 : 0,
  };
};

export const validateDragInMultiColumnScenarioScreen = (dragRecTestStep, droppingRecTestStep) => {
  if (dragRecTestStep !== undefined && dragRecTestStep !== null && dragRecTestStep.action !== STEP) {
    return false;
  } else if (
    dragRecTestStep !== undefined &&
    dragRecTestStep !== null &&
    dragRecTestStep.action !== STEP &&
    droppingRecTestStep !== undefined &&
    droppingRecTestStep !== null &&
    droppingRecTestStep.action === STEP
  ) {
    return false;
  } else if (
    dragRecTestStep !== undefined &&
    dragRecTestStep !== null &&
    dragRecTestStep.action !== STEP &&
    droppingRecTestStep === undefined
  ) {
    return false;
  }
  return true;
};

export function _updateRecTestStepAction(data) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE });
    return async("recTestStepProperty/updateRecTestStepAction", "POST", data).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        dispatch(resetEditRecTestStep());
      }
      dispatch({
        type: REQUESTED_UPDATE_REC_TEST_STEP_ACTION_STEP_NAME_CONTINUE_ON_FAILURE,
        recTestStepActionId: data.recTestStep.id,
        recTestStepActionTag: data.recTestStep.tag,
      });
      return response;
    });
  };
}

export const prepareUpdateRecTestStepActionObj = (
  recTestStep,
  updateConditionMethod,
  condition,
  updateStepActionName,
  changedRecTestStepConditionTitleName
) => {
  let data = {
    recTestStep: {
      id: recTestStep?.id || recTestStep?.recTestStepId,
      tag: recTestStep?.recTestStepProperty?.tag || recTestStep?.tag,
    },
    id: recTestStep?.recTestStepPropertyId || recTestStep?.recTestStepProperty?.id,
    stepName: !changedRecTestStepConditionTitleName ? updateStepActionName : recTestStep.recTestStepProperty?.stepName,
    preConditionTitle: changedRecTestStepConditionTitleName
      ? updateStepActionName
      : recTestStep?.recTestStepProperty?.preConditionTitle || recTestStep.preConditionTitle,
    isSkipStep: recTestStep?.recTestStepProperty?.isSkipStep ? 1 : 0 || recTestStep.isSkipStep ? 1 : 0,
    isStepHasPrecondition: recTestStep?.recTestStepProperty?.isStepHasPrecondition || recTestStep.isStepHasPrecondition,
    isLoopStep: recTestStep?.recTestStepProperty?.isLoopStep || recTestStep.isLoopStep,
    loopCount: recTestStep?.recTestStepProperty?.loopCount || recTestStep.loopCount,
    continueAfterFail: recTestStep?.recTestStepProperty?.continueAfterFail
      ? recTestStep?.recTestStepProperty?.continueAfterFail
      : 0 || recTestStep.continueAfterFail
      ? recTestStep.continueAfterFail
      : 0,
    recStepVerifyElementProperties: recTestStep?.recTestStepProperty?.recStepVerifyElementProperties,
  };

  if (updateConditionMethod && condition) {
    data["recStepVerifyElementProperties"] = [];
    data["recElements"] = [];
    data["preConditionTitle"] = null;
    switch (condition) {
      case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT:
      case STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_TEST_DATA:
        data["isStepHasPrecondition"] = 0;
        break;
      case STEP_ITEMS.REPEAT_TILL:
        data["isLoopStep"] = 0;
        data["loopCount"] = 0;
        break;
      default:
        break;
    }
  }
  return data;
};

//Common Function
export function _changeRecElementName(name, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "name", name);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeDisableElement(checked, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "isActive", checked ? 1 : 0);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecElementDescription(description, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "description", description);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeRecElementTags(tags, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "tags", tags);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _removeElementIntoStep(unSavedRecTestStep, unSavedRecElement) {
  return async (dispatch, getState) => {
    const { stepType, unSavedRecStepTag, unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
    const { recTestStepList, testBlockStep } = getState().RecTestStepReducer;
    let unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
    let recElementCount = unSavedRecTestStep.recTestStepProperty?.recElements.length;
    if (
      unSavedRecTestStep.recTestStepProperty?.action !== STEP &&
      unSavedRecTestStep.recTestStepProperty?.recElements.length === 1 &&
      unSavedRecTestStep.recTestStepProperty?.recElements[0].guid === unSavedRecElementGuid &&
      unSavedRecTestStep.id !== undefined
    ) {
      dispatch(deleteRecTestStep(undefined, stepType));
    } else if (
      unSavedRecTestStep.recTestStepProperty?.action === STEP &&
      unSavedRecTestStep.recTestStepProperty?.isStepHasPrecondition === 1 &&
      unSavedRecTestStep.recTestStepProperty?.recElements.length === 1 &&
      unSavedRecTestStep.recTestStepProperty?.recElements[0].guid === unSavedRecElementGuid &&
      unSavedRecTestStep.id === undefined
    ) {
      dispatch(
        _deleteRecTestStepPreCondition(
          unSavedRecTestStep?.recTestStepProperty?.id,
          unSavedRecTestStep.recTestStepProperty?.recElements[0].id
        )
      );
      dispatch(resetEditRecTestStep());
      dispatch(hideDeleteRecElementConfirmationModal());
    } else if (
      unSavedRecTestStep.recTestStepProperty?.action === STEP &&
      unSavedRecTestStep.recTestStepProperty?.isLoopStep === 1 &&
      unSavedRecTestStep.recTestStepProperty?.recElements.length === 1 &&
      unSavedRecTestStep.recTestStepProperty?.recElements[0].guid === unSavedRecElementGuid &&
      unSavedRecTestStep.id !== undefined
    ) {
      dispatch(_removeLoopConditionFromRecTestStep(unSavedRecTestStep, true));
      dispatch(resetEditRecTestStep());
      dispatch(hideDeleteRecElementConfirmationModal());
    } else {
      let recElement = getRecElement(unSavedRecTestStep.recTestStepProperty?.recElements, unSavedRecElementGuid);
      let recElementId = undefined;
      let recElementGuids = [];
      if (recElement && recElement.id !== undefined && recElement.id !== 0) {
        recElementId = recElement.id;
        recElementGuids.push(recElement.guid);
        if (recElement.childRecElements && recElement.childRecElements.length > 0) {
          recElement.childRecElements.map((recElement) => {
            recElementId += "," + recElement.id;
          });
        }
      }
      if (recElementId !== undefined) {
        async("recElement/deleteRecElement ", "POST", {
          recTestStepProperty: { id: unSavedRecTestStep.recTestStepProperty?.id },
          recElementId,
        }).then((response) => {
          response = updateResponse(response);
          if (response.status === SUCCESS_STATUS) {
            dispatch({
              type: REMOVE_ELEMENT_INTO_STEP,
              unSavedRecTestStep,
              unSavedRecStepTag,
              recElementGuid: [unSavedRecElementGuid],
            });
            dispatch(resetEditRecTestStep());
            dispatch(hideDeleteRecElementConfirmationModal());
          } else {
            showNotification("error", "Error in delete Element");
          }
        });
      } else {
        if (recElement.id === undefined || recElement.id === 0) {
          dispatch({
            type: REMOVE_ELEMENT_INTO_STEP,
            unSavedRecTestStep,
            unSavedRecStepTag,
            recElementGuid: [unSavedRecElementGuid],
          });
        }
        dispatch({ type: RESET_UNSAVED_ELEMENTS, unSavedRecTestStep, unSavedRecStepTag });
        dispatch(recordingStop());
        dispatch(resetElement());
        if (unSavedRecStepTag && unSavedRecElementGuid && recElementCount > 1) {
          dispatch(resetEditRecTestStep());
        }
      }
      // dispatch(resetEditRecTestStep());
    }
  };
}

//Common Function
export function _changeCustomElementSearch(value, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "searchKey", value);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeCustomElementSearchValue(value, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "searchValue", value);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "name", "Custom Element by text '" + value + "'");
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  if (
    (isStepHavePrecondition(unSavedRecTestStep) || isStepHaveLoopStep(unSavedRecTestStep)) &&
    isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.preConditionTitle) &&
    isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.preConditionTitle)
  ) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      unSavedRecTestStep,
      "preConditionTitle",
      suggestedStepName(recElements, unSavedRecTestStep.recTestStepProperty?.action)
    );
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeIsAIStep(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "isAIStep", checked?1:0);
  if(!checked){
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "aiUseApplicationString",0);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "aiApplicationStringKey",undefined);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "aiUseTestData",0);
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "aiTargetElementTestDataId",undefined);
  }
  return unSavedRecTestStep;
}

//Common Function
export function _changeCustomElementFilterTypeValue(value, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "filterType", value);
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

//Common Function
export function _changeCustomElementAddTestData(checked, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "useTestData", checked ? 1 : 0);
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "searchValue",
    checked ? undefined : unSavedRecElement.searchValue
  );
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "testData",
    !checked ? undefined : unSavedRecElement.testData
  );
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

export function _changeCustomElementAddApplicationString(checked, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "useApplicationString", checked ? 1 : 0);
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "searchValue",
    checked ? undefined : unSavedRecElement.searchValue
  );
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "applicationStrings",
    !checked ? undefined : unSavedRecElement.applicationStrings
  );
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "applicationStringsKey",
    !checked ? undefined : unSavedRecElement.applicationStringsKey
  );
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

export function _changeAIStepUseTestData(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiUseTestData",checked ? 1 : 0)
  if(!checked){
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiTargetElementTestDataId",undefined)
  }
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiUseApplicationString",0)
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiApplicationStringKey",undefined)
  return unSavedRecTestStep;
}

export function _changeAIStepUseApplicationString(checked, unSavedRecTestStep) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiUseApplicationString",checked ? 1 : 0)
  if(!checked){
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiApplicationStringKey",undefined)
  }
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiUseTestData",0)
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep,"aiTargetElementTestDataId",undefined)
  return unSavedRecTestStep;
}

//Common Function
export function _changeCustomElementAddFilterType(checked, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "useFilterType", checked ? 1 : 0);
  unSavedRecElement = insertIntoRecElement(
    unSavedRecElement,
    "filterType",
    !checked ? undefined : unSavedRecElement.filterType
  );
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

export function _addElementIntoStep(parentElementGuid, unSavedRecTestStep) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  let updateRecTestStepElementDetail = getNewRecElement(unSavedRecTestStep?.recTestStepProperty?.action);
  if (parentElementGuid !== undefined) {
    recElements.map((recElement) => {
      if (parentElementGuid === recElement.guid) {
        recElement.elementType = ElementType.PARENT_ELEMENT;
        if (recElement.childRecElements === undefined) {
          recElement.childRecElements = [];
        }
        updateRecTestStepElementDetail = {
          ...updateRecTestStepElementDetail,
          elementPurpose:
            recElement?.childRecElements.filter((item) => item.elementPurpose === "ACTION").length === 0
              ? "ACTION"
              : "VERIFICATION",
        };
        updateRecTestStepElementDetail = {
          ...updateRecTestStepElementDetail,
          verificationOperator: "AND",
          recStepVerifyElementProperties: [],
        };
        if (unSavedRecTestStep.selectedTabKey) {
          unSavedRecTestStep.selectedTabKey = {};
        }
        unSavedRecTestStep.selectedTabKey =
          updateRecTestStepElementDetail.elementPurpose === "VERIFICATION" ? "VERIFICATION_PROPERTIES" : undefined;

        recElement.childRecElements.push(updateRecTestStepElementDetail);
      }
    });
  } else {
    recElements.push(updateRecTestStepElementDetail);
  }
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "recElements", recElements);
  return unSavedRecTestStep;
}

export function _setSelectedTabKey(key) {
  return (dispatch, getState) => {
    dispatch({ type: SET_SELECTED_REC_STEP_TAB_KEY, key });
  };
}

//Common Function
export function _updateStepType(stepType) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_STEP_TYPE, stepType });
  };
}

//Common Function
export const _handleSelectRecTestStepOrAction = (
  selectedId,
  recTestStep,
  childRecTestSteps,
  recTestStepList,
  currentActionTab,
  projectId,
  importRecTestScenarioStepActionList
) => {
  return async (dispatch) => {
    dispatch({
      type: HANDLE_SELECT_REC_TEST_STEP_ACTION,
      selectedId,
      recTestStep,
      childRecTestSteps,
      recTestStepList,
      currentActionTab,
      projectId,
      importRecTestScenarioStepActionList,
    });
  };
};

//Common Function
export function _changeAdvanceElementSelectorDrawerVisibility(visibleAction) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_ADVANCE_ELEMENT_SELECTOR_DRAWER_VISIBILITY, visibleAction });
  };
}

//Common Function
export function _changeSelectorType(value, unSavedRecTestStep, unSavedRecElement) {
  unSavedRecElement = getNewRecElement("", unSavedRecElement.guid);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "elementSelectorType", value);
  if (value === SELECTOR_TYPE.SELECT_IMAGE_ON_SCREEN) {
    unSavedRecElement["name"] = "Image Element";
  }
  if (value === SELECTOR_TYPE.CUSTOM_ELEMENT) {
    unSavedRecElement["searchKey"] = "text";
  }
  unSavedRecTestStep.recTestStepProperty.recElements = updateRecElement(
    unSavedRecTestStep.recTestStepProperty?.recElements,
    unSavedRecElement
  );
  return unSavedRecTestStep;
}

//Common Function Need to check
export function _resetRecTestStepAction(unSavedRecElementGuid, unSavedRecTestStep) {
  let unSavedRecElement = getUnSavedRecElement(
    unSavedRecTestStep["recTestStepProperty"]["recElements"],
    unSavedRecElementGuid
  );
  console.log(`unSavedRecTestStep["recTestStepProperty"]`,unSavedRecTestStep["recTestStepProperty"])
  unSavedRecTestStep["recTestStepProperty"] = {
    ...unSavedRecTestStep["recTestStepProperty"],
    userInputValue:
      unSavedRecTestStep["recTestStepProperty"].previous_userInputValue !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_userInputValue
        : unSavedRecTestStep["recTestStepProperty"].userInputValue,
    continueAfterFail:
      unSavedRecTestStep["recTestStepProperty"].previous_continueAfterFail !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_continueAfterFail
        : unSavedRecTestStep["recTestStepProperty"].continueAfterFail,
    preConditionTitle:
      unSavedRecTestStep["recTestStepProperty"].previous_preConditionTitle !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_preConditionTitle
        : unSavedRecTestStep["recTestStepProperty"].preConditionTitle,
    stepName:
      unSavedRecTestStep["recTestStepProperty"].previous_stepName !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_stepName
        : unSavedRecTestStep["recTestStepProperty"].stepName,
    action:
      unSavedRecTestStep["recTestStepProperty"].previous_action !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_action
        : unSavedRecTestStep["recTestStepProperty"].action,
    uniqueByIndex:
      unSavedRecTestStep["recTestStepProperty"].previous_uniqueByIndex !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_uniqueByIndex
        : unSavedRecTestStep["recTestStepProperty"].uniqueByIndex,
    isStepHasPrecondition:
      unSavedRecTestStep["recTestStepProperty"].previous_isStepHasPrecondition !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_isStepHasPrecondition
        : unSavedRecTestStep["recTestStepProperty"].isStepHasPrecondition,
    testData: unSavedRecTestStep["recTestStepProperty"].hasOwnProperty("previous_testData")
      ? unSavedRecTestStep["recTestStepProperty"].previous_testData
      : unSavedRecTestStep["recTestStepProperty"].testData,
    testDataCustomValue:
      unSavedRecTestStep["recTestStepProperty"].previous_testDataCustomValue !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_testDataCustomValue
        : unSavedRecTestStep["recTestStepProperty"].testDataCustomValue,
    ignoreTestPlanTestData:
      unSavedRecTestStep["recTestStepProperty"].previous_ignoreTestPlanTestData !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_ignoreTestPlanTestData
        : unSavedRecTestStep["recTestStepProperty"].ignoreTestPlanTestData,
    packageName:
      unSavedRecTestStep["recTestStepProperty"].previous_packageName !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_packageName
        : unSavedRecTestStep["recTestStepProperty"].packageName,
    packageActivity:
      unSavedRecTestStep["recTestStepProperty"].previous_packageActivity !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_packageActivity
        : unSavedRecTestStep["recTestStepProperty"].packageActivity,
    appendRandomText:
      unSavedRecTestStep["recTestStepProperty"].previous_appendRandomText !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_appendRandomText
        : unSavedRecTestStep["recTestStepProperty"].appendRandomText,
    swipeToFindElement:
      unSavedRecTestStep["recTestStepProperty"].previous_swipeToFindElement !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_swipeToFindElement
        : unSavedRecTestStep["recTestStepProperty"].swipeToFindElement,
    swipeCountToFindElement:
      unSavedRecTestStep["recTestStepProperty"].previous_swipeCountToFindElement !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_swipeCountToFindElement
        : unSavedRecTestStep["recTestStepProperty"].swipeCountToFindElement,
    reverseSwipeElementNotFound:
      unSavedRecTestStep["recTestStepProperty"].previous_reverseSwipeElementNotFound !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_reverseSwipeElementNotFound
        : unSavedRecTestStep["recTestStepProperty"].reverseSwipeElementNotFound,
    elementMaxLoadTime:
      unSavedRecTestStep["recTestStepProperty"].previous_elementMaxLoadTime !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_elementMaxLoadTime
        : unSavedRecTestStep["recTestStepProperty"].elementMaxLoadTime,
    retryCount:
      unSavedRecTestStep["recTestStepProperty"].previous_retryCount !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_retryCount
        : unSavedRecTestStep["recTestStepProperty"].retryCount,
    invertResult:
      unSavedRecTestStep["recTestStepProperty"].previous_invertResult !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_invertResult
        : unSavedRecTestStep["recTestStepProperty"].invertResult,
    testDataId:
      unSavedRecTestStep["recTestStepProperty"].previous_testDataId !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_testDataId
        : unSavedRecTestStep["recTestStepProperty"].testDataId,
    recStepVerifyElementProperties:
      unSavedRecTestStep["recTestStepProperty"].previous_recStepVerifyElementProperties !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_recStepVerifyElementProperties
        : unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties,
    recSwipeAttributes:
      unSavedRecTestStep["recTestStepProperty"].previous_recSwipeAttributes !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_recSwipeAttributes
        : unSavedRecTestStep["recTestStepProperty"].recSwipeAttributes,
    pauseTime:
      unSavedRecTestStep["recTestStepProperty"].previous_pauseTime !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_pauseTime
        : unSavedRecTestStep["recTestStepProperty"].pauseTime,
    isLoopStep:
      unSavedRecTestStep["recTestStepProperty"].previous_isLoopStep !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_isLoopStep
        : unSavedRecTestStep["recTestStepProperty"].isLoopStep,
    loopCount:
      unSavedRecTestStep["recTestStepProperty"].previous_loopCount !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_loopCount
        : unSavedRecTestStep["recTestStepProperty"].loopCount,
    swipeDirection:
      unSavedRecTestStep["recTestStepProperty"].previous_swipeDirection !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_swipeDirection
        : unSavedRecTestStep["recTestStepProperty"].swipeDirection,
    recElements:
      unSavedRecTestStep["recTestStepProperty"].previous_recElements !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_recElements
        : unSavedRecTestStep["recTestStepProperty"].recElements,
    horizontalRelativePoint:
      unSavedRecTestStep["recTestStepProperty"].previous_horizontalRelativePoint !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_horizontalRelativePoint
        : unSavedRecTestStep["recTestStepProperty"].horizontalRelativePoint,
    verticalRelativePoint:
      unSavedRecTestStep["recTestStepProperty"].previous_verticalRelativePoint !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_verticalRelativePoint
        : unSavedRecTestStep["recTestStepProperty"].verticalRelativePoint,
    isCaptureScreenshot:
      unSavedRecTestStep["recTestStepProperty"].previous_isCaptureScreenshot !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_isCaptureScreenshot
        : unSavedRecTestStep["recTestStepProperty"].isCaptureScreenshot,
    changeApplicationStringsLocale:
      unSavedRecTestStep["recTestStepProperty"].previous_changeApplicationStringsLocale !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_changeApplicationStringsLocale
        : unSavedRecTestStep["recTestStepProperty"].changeApplicationStringsLocale,
    captureScreenShotDelayTime:
      unSavedRecTestStep["recTestStepProperty"].previous_captureScreenShotDelayTime !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_captureScreenShotDelayTime
        : unSavedRecTestStep["recTestStepProperty"].captureScreenShotDelayTime,
    outputTestDataId:
      unSavedRecTestStep["recTestStepProperty"].previous_outputTestDataId !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_outputTestDataId
        : unSavedRecTestStep["recTestStepProperty"].outputTestDataId,
    outputTestData: unSavedRecTestStep["recTestStepProperty"].hasOwnProperty("previous_outputTestData")
      ? unSavedRecTestStep["recTestStepProperty"].previous_outputTestData
      : unSavedRecTestStep["recTestStepProperty"].outputTestData,
    insertValueToTestData:
      unSavedRecTestStep["recTestStepProperty"].previous_insertValueToTestData !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_insertValueToTestData
        : unSavedRecTestStep["recTestStepProperty"].insertValueToTestData,
    removeAppPreviousSession:
      unSavedRecTestStep["recTestStepProperty"].previous_removeAppPreviousSession !== undefined
        ? unSavedRecTestStep["recTestStepProperty"].previous_removeAppPreviousSession
        : unSavedRecTestStep["recTestStepProperty"].removeAppPreviousSession,
    recElementOperator: 
      unSavedRecTestStep["recTestStepProperty"].previous_recElementOperator !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_recElementOperator
      : unSavedRecTestStep["recTestStepProperty"].recElementOperator,
    aiUseApplicationString: 
      unSavedRecTestStep["recTestStepProperty"].previous_aiUseApplicationString !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_aiUseApplicationString
      : unSavedRecTestStep["recTestStepProperty"].aiUseApplicationString,
    aiUseTestData: 
      unSavedRecTestStep["recTestStepProperty"].hasOwnProperty("previous_aiUseTestData")
      ? unSavedRecTestStep["recTestStepProperty"].previous_aiUseTestData
      : unSavedRecTestStep["recTestStepProperty"].aiUseTestData,
    aiTargetElementTestData: 
      unSavedRecTestStep["recTestStepProperty"].hasOwnProperty("previous_aiTargetElementTestData")
      ? unSavedRecTestStep["recTestStepProperty"].previous_aiTargetElementTestData
      : unSavedRecTestStep["recTestStepProperty"].aiTargetElementTestData,
    aiTargetElementTestDataId: 
      unSavedRecTestStep["recTestStepProperty"].previous_aiTargetElementTestDataId !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_aiTargetElementTestDataId
      : unSavedRecTestStep["recTestStepProperty"].aiTargetElementTestDataId,
    aiApplicationStringKey: 
      unSavedRecTestStep["recTestStepProperty"].previous_aiApplicationStringKey !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_aiApplicationStringKey
      : unSavedRecTestStep["recTestStepProperty"].aiApplicationStringKey,
    applicationStrings: 
      unSavedRecTestStep["recTestStepProperty"].previous_applicationStrings !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_applicationStrings
      : unSavedRecTestStep["recTestStepProperty"].applicationStrings,
      isAIStep: 
      unSavedRecTestStep["recTestStepProperty"].previous_isAIStep !== undefined
      ? unSavedRecTestStep["recTestStepProperty"].previous_isAIStep
      : unSavedRecTestStep["recTestStepProperty"].isAIStep,
  };

  if (unSavedRecTestStep["recTestStepProperty"].hasOwnProperty("previous_recElements")) {
    unSavedRecTestStep["recTestStepProperty"]["recElements"] = unSavedRecTestStep["recTestStepProperty"].hasOwnProperty(
      "previous_recElements"
    )
      ? unSavedRecTestStep["recTestStepProperty"]["previous_recElements"]
      : unSavedRecTestStep["recTestStepProperty"]["recElements"];
  } else {
    if (unSavedRecElement) {
      unSavedRecElement = {
        ...unSavedRecElement,
        retryCount:
          unSavedRecElement["previous_retryCount"] !== undefined
            ? unSavedRecElement["previous_retryCount"]
            : unSavedRecElement["retryCount"],
        elementMaxLoadTime:
          unSavedRecElement["previous_elementMaxLoadTime"] !== undefined
            ? unSavedRecElement["previous_elementMaxLoadTime"]
            : unSavedRecElement["elementMaxLoadTime"],
        swipeToBringElementToCenter:
          unSavedRecElement["previous_swipeToBringElementToCenter"] !== undefined
            ? unSavedRecElement["previous_swipeToBringElementToCenter"]
            : unSavedRecElement["swipeToBringElementToCenter"],
        name:
          unSavedRecElement["previous_name"] !== undefined
            ? unSavedRecElement["previous_name"]
            : unSavedRecElement["name"],
        isActive:
          unSavedRecElement["previous_isActive"] !== undefined
            ? unSavedRecElement["previous_isActive"]
            : unSavedRecElement["isActive"],
        searchValue:
          unSavedRecElement["previous_searchValue"] !== undefined
            ? unSavedRecElement["previous_searchValue"]
            : unSavedRecElement["searchValue"],
        filterType:
          unSavedRecElement["previous_filterType"] !== undefined
            ? unSavedRecElement["previous_filterType"]
            : unSavedRecElement["filterType"],
        testData:
          unSavedRecElement.hasOwnProperty("previous_testData") !== undefined
            ? unSavedRecElement["previous_testData"]
            : unSavedRecElement["testData"],
        useTestData:
          unSavedRecElement["previous_useTestData"] !== undefined
            ? unSavedRecElement["previous_useTestData"]
            : unSavedRecElement["useTestData"],
        recStepVerifyElementProperties:
          unSavedRecElement["previous_recStepVerifyElementProperties"] !== undefined
            ? unSavedRecElement["previous_recStepVerifyElementProperties"]
            : unSavedRecElement["recStepVerifyElementProperties"],
        searchKey:
          unSavedRecElement["previous_searchKey"] !== undefined
            ? unSavedRecElement["previous_searchKey"]
            : unSavedRecElement["searchKey"],
        applicationStrings: unSavedRecElement.hasOwnProperty("previous_applicationStrings")
          ? unSavedRecElement["previous_applicationStrings"]
          : unSavedRecElement["applicationStrings"],
        deviceStrings: unSavedRecElement.hasOwnProperty("previous_deviceStrings")
          ? unSavedRecElement["previous_deviceStrings"]
          : unSavedRecElement["deviceStrings"],
          useApplicationString:
          unSavedRecElement.hasOwnProperty("previous_useApplicationString")
          ? unSavedRecElement["previous_useApplicationString"]
          : unSavedRecElement["useApplicationString"],
          applicationStringsKey:
          unSavedRecElement.hasOwnProperty("previous_applicationStringsKey")
          ? unSavedRecElement["previous_applicationStringsKey"]
          : unSavedRecElement["applicationStringsKey"]
      };

      delete unSavedRecElement["previous_retryCount"];
      delete unSavedRecElement["previous_elementMaxLoadTime"];
      delete unSavedRecElement["previous_swipeToBringElementToCenter"];
      delete unSavedRecElement["previous_name"];
      delete unSavedRecElement["previous_isActive"];
      delete unSavedRecElement["previous_searchValue"];
      delete unSavedRecElement["previous_filterType"];
      delete unSavedRecElement["previous_searchValue"];
      delete unSavedRecElement["previous_testData"];
      delete unSavedRecElement["previous_useTestData"];
      delete unSavedRecElement["previous_searchKey"];
      delete unSavedRecElement["previous_recStepVerifyElementProperties"];
      delete unSavedRecElement["previous_applicationStrings"];
      delete unSavedRecElement["previous_deviceStrings"];
      delete unSavedRecElement["previous_recElementLocatorProperties"];
      delete unSavedRecElement["previous_useApplicationString"];
      delete unSavedRecElement["previous_applicationStringsKey"];
      
      unSavedRecTestStep["recTestStepProperty"]["recElements"] = updateRecElement(
        _.cloneDeep(unSavedRecTestStep["recTestStepProperty"]["recElements"]),
        unSavedRecElement
      );
    }
  }
  if (
    unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties &&
    unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.length > 0
  ) {
    let removeIndex = [];
    unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.map((v, k) => {
      if (v.id === undefined) {
        removeIndex.push(k);
      }
    });
    if (removeIndex.length > 0) {
      for (var i = removeIndex.length - 1; i >= 0; i--)
        unSavedRecTestStep["recTestStepProperty"].recStepVerifyElementProperties.splice(removeIndex[i], 1);
    }
  }
  // if (
  //   unSavedRecTestStep["recTestStepProperty"].recElements &&
  //   unSavedRecTestStep["recTestStepProperty"].recElements.length > 0
  // ) {
  //   let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  //   let recElement = getUnSavedRecElement(recElements, unSavedRecElementGuid);
  //   recElements = updateUnSavedRecElement(
  //     recElements,
  //     recElement && Object.keys(recElement).length > 1
  //       ? resetElementProperties(recElement)
  //       : getNewRecElement(unSavedRecTestStep?.recTestStepProperty?.action, unSavedRecElementGuid)
  //   );
  //   unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "recElements", recElements);
  // }
  // if (unSavedRecTestStep.recTestStepProperty?.previous_packageName === undefined) {
  //   unSavedRecTestStep.recTestStepProperty?.packageName = "";
  // }
  // if (unSavedRecTestStep.recTestStepProperty?.previous_packageActivity === undefined) {
  //   unSavedRecTestStep.recTestStepProperty?.packageActivity = "";
  // }

  delete unSavedRecTestStep["recTestStepProperty"]["previous_userInputValue"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_continueAfterFail"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_preConditionTitle"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_stepName"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_action"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_uniqueByIndex"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_isStepHasPrecondition"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_testData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_testDataCustomValue"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_ignoreTestPlanTestData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_packageName"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_packageActivity"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_appendRandomText"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_swipeToFindElement"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_swipeCountToFindElement"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_elementMaxLoadTime"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_retryCount"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_testDataId"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_showTestDataContent"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_recStepVerifyElementProperties"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_recSwipeAttributes"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_isSkipStep"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_pauseTime"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_isLoopStep"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_horizontalRelativePoint"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_verticalRelativePoint"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_outputTestData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_loopCount"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_swipeDirection"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_recElements"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_userSuggestedStepName"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_outputTestDataId"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_insertValueToTestData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_captureScreenShotDelayTime"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_isCaptureScreenshot"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_changeApplicationStringsLocale"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_removeAppPreviousSession"];  
  delete unSavedRecTestStep["recTestStepProperty"]["previous_recElementOperator"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_removeAppPreviousSession"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_reverseSwipeElementNotFound"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_aiUseTestData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_aiTargetElementTestData"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_aiTargetElementTestDataId"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_aiUseApplicationString"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_aiApplicationStringKey"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_applicationStrings"];
  delete unSavedRecTestStep["recTestStepProperty"]["previous_isAIStep"];
  console.log(`unSavedRecTestStep["recTestStepProperty"]`,unSavedRecTestStep["recTestStepProperty"])
  return unSavedRecTestStep;
}

// Common Function
export function _searchValueFromImportRecTestStepOrActionTree(value, projectId, currentPageNumber, currentActionTab) {
  return (dispatch, getState) => {
    const { stepType } = getState().RecTestStepCommonViewReducer;
    dispatch({ type: CHANGE_SEARCH_VALUE_FOR_IMPORT_REC_TEST_STEP_OR_ACTION_TREE, value });
    if (stepType === TEST_STEP_BLOCKS || (stepType === TEST_SCENARIOS && currentActionTab === TEST_STEP_BLOCKS)) {
      dispatch(getListOfBlocksByProjectId(projectId, currentPageNumber, value, "", true));
    } else {
      dispatch(fetchScenariosAndRecTestStepsByPlatform(projectId, value, currentPageNumber));
    }
  };
}

//Common Function
export function _showImportRecTestStepModal(typeText, projectId, recTestStep, actionStep, selectChildStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SHOW_IMPORT_REC_TEST_STEP_MODAL,
      typeText,
      recTestStep,
      actionStep,
      selectChildStep,
    });
  };
}

//Common Function
export function _hideImportRecTestStepModal() {
  return (dispatch) => {
    dispatch({ type: HIDE_IMPORT_REC_TEST_STEP_MODAL });
  };
}

//Common Function
export function _addConditionToStep(condition, unSavedRecTestStep) {
  return (dispatch) => {
    dispatch({ type: ADD_CONDITION_TO_STEP, condition, unSavedRecTestStep });
    if (condition === STEP_ITEMS.WHEN_TO_RUN_BY_VERIFYING_ELEMENT) {
      dispatch(recordingStart());
    }
    dispatch(_captureExpandedSteps(unSavedRecTestStep.tag, unSavedRecTestStep.recTestScenarioRecTestStepAssocId, true));
  };
}

//Common Function
export function _saveRecTestStepActionWithCurrentDeviceImage(unSavedRecTestStep) {
  return (dispatch, getState) => {
    const { selectedDeviceIdForRecording } = getState().RecTestStepReducer;
    const { unSavedRecElementGuid } = getState().RecTestStepCommonViewReducer;
    const { serverDetail } = getState().RecordingReducer;
    if (unSavedRecTestStep) {
      if (
        isRecElementsChanged(unSavedRecTestStep.recTestStepProperty) ||
        getUnSavedRecElement(unSavedRecTestStep.recTestStepProperty?.recElements, unSavedRecElementGuid)
      ) {
        dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
        let data = {
          targetUniqueId: selectedDeviceIdForRecording,
        };
        let recElement = getRecElement(unSavedRecTestStep.recTestStepProperty?.recElements, unSavedRecElementGuid);
        if (
          isNotNullAndNotEmptyAndNotUndefined(unSavedRecElementGuid) &&
          recElement &&
          recElement.elementSelectorType !== SELECTOR_TYPE.CUSTOM_ELEMENT &&
          recElement.recElementLocatorProperties &&
          recElement.recElementLocatorProperties[0]?.mobileElements
        ) {
          data = {
            ...data,
            elementId: recElement?.recElementLocatorProperties[0].mobileElements
              ? recElement?.recElementLocatorProperties[0].mobileElements[0].id
              : undefined,
            parentElementId: recElement?.recElementLocatorProperties[0].mobileElements
              ? recElement?.recElementLocatorProperties[0].mobileElements[0]?.parentElementId
              : undefined,
            elementGuid: recElement.guid,
          };
          unSavedRecTestStep.recTestStepProperty &&
            unSavedRecTestStep.recTestStepProperty.recElements.forEach((recElement) => {
              let recElementLocatorPropertiesObj = recElement?.recElementLocatorProperties.filter(
                (recElementLocatorProperty) => recElementLocatorProperty?.mobileElements?.length > 0
              );
              if (recElementLocatorPropertiesObj?.length > 0) {
                data = {
                  ...data,
                  elementId: recElementLocatorPropertiesObj[0].mobileElements[0].id,
                  elementGuid: recElement.guid,
                };
                return;
              }
            });
          return async("device/captureDeviceScreenShot", "GET", data, serverDetail.serverIp,undefined,dispatch).then((response) => {
            if (response.status === SUCCESS_STATUS) {
              new Promise((resolve) => {
                let unSavedRecElement = getUnSavedRecElement(
                  unSavedRecTestStep.recTestStepProperty?.recElements,
                  data.elementGuid
                );
                unSavedRecElement = insertIntoRecElement(unSavedRecElement, "elementImage", response.data);
                unSavedRecTestStep.recTestStepProperty.recElements = updateRecElement(
                  unSavedRecTestStep.recTestStepProperty?.recElements,
                  unSavedRecElement
                );
                resolve(
                  dispatch(
                    saveRecTestStepAction(
                      { imageData: response.data },
                      unSavedRecTestStep.id,
                      unSavedRecTestStep.tag,
                      data.elementGuid
                    )
                  )
                );
              }).then(() => {
                dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
              });
            } else {
              showNotification("error", response.message, "");
              dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
            }
            return true;
          });
        } else {
          dispatch(
            saveRecTestStepAction(undefined, unSavedRecTestStep.id, unSavedRecTestStep.tag, unSavedRecElementGuid)
          );
        }
      } else if (isRecElementsPropertyChanged(unSavedRecTestStep.recTestStepProperty)) {
        dispatch({ type: REQUEST_PERFORM_REC_TEST_STEP_ACTION });
        new Promise((resolve) => {
          resolve(dispatch(saveRecTestStepAction({ imageData: "" }, unSavedRecTestStep.id, unSavedRecTestStep.tag)));
        }).then(() => {
          dispatch({ type: REQUESTED_PERFORM_REC_TEST_STEP_ACTION });
        });
      }
    }
  };
}

export function _reRecordRecTestStepElement(recElementGuid, unSavedRecTestStep) {
  let recElements = _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements);
  //Remove Change ELement
  let recElement = getUnSavedRecElement(recElements, recElementGuid);
  //Add Empty  ELement
  recElements = updateUnSavedRecElement(
    recElements,
    recElement && Object.keys(recElement).length > 0
      ? resetElementProperties(recElement)
      : getNewRecElement(unSavedRecTestStep?.recTestStepProperty?.action, recElementGuid)
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(unSavedRecTestStep, "recElements", recElements);
  return unSavedRecTestStep;
}

export function _removeConditionFromRecTestStep(recTestStepId, recTestStepTag, condition) {
  return async (dispatch) => {
    dispatch({ type: REMOVE_CONDITION_FROM_REC_TEST_STEP, recTestStepId, recTestStepTag, condition });
  };
}

export function _removeRunData(runId) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_CLEAR_PREVIEW });
    return async("recording/removeRunData", "POST", { runId: runId }).then((removeDataResponse) => {
      removeDataResponse = updateResponse(removeDataResponse);
      dispatch({ type: REQUESTED_CLEAR_PREVIEW });
      return removeDataResponse;
    });
  };
}

export function _captureDeviceSelection(startPositionX, startPositionY, width, height) {
  return async (dispatch, getState) => {
    const { recTestStepList, testBlockStep, selectedDeviceIdForRecording } = getState().RecTestStepReducer;
    const { unSavedRecElementGuid, unSavedRecStepTag } = getState().RecTestStepCommonViewReducer;
    let data = {
      startPositionX,
      startPositionY,
      width,
      height,
      targetUniqueId: selectedDeviceIdForRecording,
    };
    async("device/captureDeviceSelection", "GET", data, getState().RecordingReducer.serverDetail.serverIp,undefined,dispatch).then(
      (response) => {
        response = updateResponse(response);
        let unSavedRecTestStep = findRecTestStepByTag(recTestStepList, unSavedRecStepTag, testBlockStep);
        let unSavedRecElement = getUnSavedRecElement(
          unSavedRecTestStep?.recTestStepProperty?.recElements,
          unSavedRecElementGuid
        );
        unSavedRecElement = insertIntoRecElement(unSavedRecElement, "elementImage", response.data);
        unSavedRecElement = insertIntoRecElement(unSavedRecElement, "selectedImageCoordinates", {
          x: startPositionX,
          y: startPositionY,
          width,
          height,
        });
        unSavedRecTestStep.recTestStepProperty.recElements = updateRecElement(
          _.cloneDeep(unSavedRecTestStep.recTestStepProperty?.recElements),
          unSavedRecElement
        );
        if (
          isStepHavePrecondition(unSavedRecTestStep) &&
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.preConditionTitle) &&
          isNullOrUndefinedOrEmpty(unSavedRecTestStep?.recTestStepProperty?.preConditionTitle)
        ) {
          unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
            unSavedRecTestStep,
            "preConditionTitle",
            suggestedStepName(
              unSavedRecTestStep?.recTestStepProperty?.recElements,
              unSavedRecTestStep.recTestStepProperty?.action
            )
          );
        }
        dispatch({ type: UPDATE_REC_TEST_STEP_SINGLE_COLUMN_VIEW, unSavedRecTestStep });
      }
    );
  };
}
export function _deleteTestBlock(selectedRecTestStepIds = [], searchKeyword, setSelectAllTestStepBlocks) {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_DELETE_REC_TEST_STEP });
    const testBlockListFilter = getState().TestBlockReducer.testBlockListFilter;
    let recTestSteps = {
      recTestSteps: selectedRecTestStepIds && selectedRecTestStepIds?.map((i) => ({ id: i })),
      projectId: getState().ProjectsReducer.projectId,
      isDeleteReference: getState().RecTestStepReducer.isDelateRecTestStepRef,
    };
    callDeleteRecTestBlockStep(recTestSteps).then((response) => {
      response = updateResponse(response);
      if (response.status === SUCCESS_STATUS) {
        showNotification("success", "Successfully Deleted Step.", "");
      } else {
        showNotification("error", response.message, "");
      }
      setSelectAllTestStepBlocks([]);
        dispatch(
          getListOfBlocksByProjectId(getState().ProjectsReducer.projectId, 1, searchKeyword, testBlockListFilter)
        );
      dispatch({ type: REQUESTED_DELETE_REC_TEST_STEP });
    });
  };
}

export function _showRecTestStepActionElementDetailDrawer(
  recTestStepId,
  recTestStepTag,
  recElementGuid,
  selectedRecTestStepDetail,
  disableSteps
) {
  return (dispatch, getState) => {
    dispatch({
      type: SHOW_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER,
      recTestStepId,
      recTestStepTag,
      recElementGuid,
      selectedRecTestStepDetail,
      disableSteps,
    });
  };
}

export function _hideRecTestStepActionElementDetailDrawer() {
  return (dispatch, getState) => {
    dispatch({ type: HIDE_REC_TEST_STEP_ACTION_ELEMENT_DETAIL_DRAWER });
  };
}

export function _captureExpandedSteps(recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd) {
  return (dispatch, getState) => {
    dispatch({ type: CAPTURE_EXPANDED_STEPS, recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd });
  };
}

export function _clearExpandedSteps() {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_EXPANDED_STEPS });
  };
}

export function _captureExpandedElements(recElementGuid, recTestStepTag, recTestScenarioRecTestStepAssocId, shouldAdd) {
  return (dispatch, getState) => {
    dispatch({
      type: CAPTURE_EXPANDED_ELEMENTS,
      recElementGuid,
      recTestStepTag,
      recTestScenarioRecTestStepAssocId,
      shouldAdd,
    });
  };
}

export function _clearExpandedElements() {
  return (dispatch, getState) => {
    dispatch({ type: CLEAR_EXPANDED_ELEMENTS });
  };
}

export function _getApplicationStrings(
  projectId,
  searchText,
  locale,
  currentPageNumber,
  recElementGuid,
  recTestStepTag,
  verificationGuid,
  filter = ""
) {
  return async (dispatch, getState) => {
    const data = {
      projectId: projectId,
      search: searchText,
      locale: locale,
      pageNumber: currentPageNumber ? currentPageNumber : DEFAULT_PAGE,
      recordPerPage: RECORD_15_PER_PAGE,
      filter: filter,
    };
    dispatch({ type: APPLICATION_STRING_LOADER, isLoading: true });
    async("applicationStrings/getByProjectIdAndUniqueKey", "GET", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch({
          type: GET_APPLICATION_STRINGS,
          data: response?.data,
          locale,
          recElementGuid,
          recTestStepTag,
          verificationGuid,
          key: "",
          filter: filter,
        });
      } else {
        dispatch({
          type: GET_APPLICATION_STRINGS,
          data: [],
          locale,
          recElementGuid,
          recTestStepTag,
          verificationGuid,
          key: "",
          filter: filter,
        });
      }
      dispatch({ type: APPLICATION_STRING_LOADER, isLoading: false });
    });
  };
}
export function _getDeviceStrings(
  projectId,
  searchText,
  locale,
  currentPageNumber,
  recElementGuid,
  recTestStepTag,
  verificationGuid,
  filter = ""
) {
  return async (dispatch, getState) => {
    const data = {
      projectId: projectId,
      search: searchText,
      locale: locale,
      pageNumber: currentPageNumber ? currentPageNumber : DEFAULT_PAGE,
      recordPerPage: RECORD_15_PER_PAGE,
      filter: filter,
    };
    dispatch({ type: DEVICE_STRING_LOADER, isLoading: true });
    async("deviceStrings/getByProjectIdAndUniqueKey", "GET", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch({
          type: GET_DEVICE_STRINGS,
          data: response?.data,
          locale,
          recElementGuid,
          recTestStepTag,
          verificationGuid,
          key: "",
          filter: filter,
        });
      }
      dispatch({ type: DEVICE_STRING_LOADER, isLoading: false });
    });
  };
}

export const addApplicationStrings = (recElement) => {
  let recStepVerifyElementProperties = [];
  recStepVerifyElementProperties = [
    {
      key: "Equals to Locale value of App String",
      referenceStep: undefined,
      testData: undefined,
      type: "VerifyElementText",
      uuid: recElement.guid,
      value: "",
    },
  ];
  return recStepVerifyElementProperties;
};

export function _selectedApplicationStringValue(selectData, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep?.recTestStepProperty?.recElements);
  if (unSavedRecElement?.elementSelectorType === SELECTOR_TYPE.CUSTOM_ELEMENT) {
    unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
      unSavedRecTestStep,
      "preConditionTitle",
      suggestedStepName(recElements, unSavedRecTestStep.recTestStepProperty?.action)
    );
    unSavedRecElement = insertIntoRecElement(
      unSavedRecElement,
      "name",
      "Custom Element by application string '" + selectData?.key + "'"
    );
  }
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "applicationStrings", selectData);
  if (selectData === undefined && !_.isEmpty(unSavedRecElement.recStepVerifyElementProperties)) {
    unSavedRecElement = insertIntoRecElement(unSavedRecElement, "applicationStringsKey", selectData);
    unSavedRecElement = insertIntoRecElement(
      unSavedRecElement,
      "recStepVerifyElementProperties",
      removeVerificationForApplicationStrings([...unSavedRecElement.recStepVerifyElementProperties])
    );
  }
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

export function _selectedAIApplicationStringValue(selectData, unSavedRecTestStep, dispatch) {
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    unSavedRecTestStep,
    "applicationStrings",
    selectData
  );
  unSavedRecTestStep = insertIntoRecTestStepRecTestProperty(
    unSavedRecTestStep,
    "aiApplicationStringKey",
    selectData?.key
  );
  return unSavedRecTestStep;
}

export function _getListOfLocalesForApplicationStrings() {
  return async (dispatch) => {
    async("locale/getListOfLocales", "GET").then((response) => {
      dispatch({ type: GET_LIST_OF_LOCALS, data: response?.data });
    });
  };
}

export function resetApplicationStringsData() {
  return (dispatch) => {
    dispatch({ type: RESET_APPLICATION_STRING_DATA });
  };
}

export function _addApplicationStrings(key, value, locale, objectList, id, isImport) {
  return async (dispatch, getState) => {
    const object = objectList?.map((i) => {
      return {
        ...i,
        project: { id: getState().ProjectsReducer.projectId },
        importFromXml: isImport,
      };
    });
    const data = {
      id: id || 0,
      key,
      value: value || "",
      locale: locale || "",
      project: { id: getState().ProjectsReducer.projectId },
      objectList: object || [],
      importFromXml: isImport,
    };
    dispatch({ type: APPLICATION_STRING_LOADER, isLoading: true });
    async("applicationStrings/save", "POST", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response?.message);
        dispatch(addApplicationStringsFormVisible(false));
      } else {
        showNotification(ERROR_TYPE, response?.message);
      }
      dispatch({ type: APPLICATION_STRING_LOADER, isLoading: false });
      dispatch(resetApplicationStringsData());
    });
  };
}

export const _visibleGeneralSettingsDrawer = (visible, actionForOpenDrawer, recTestStepTag) => {
  return (dispatch) => {
    dispatch({ type: VISIBLE_GENERAL_SETTINGS_DRAWER, visible, actionForOpenDrawer, recTestStepTag });
  };
};

export const _visibleIdentificationRulesDrawer = (visible, tagForOpenDrawer) => {
  return (dispatch) => {
    dispatch({ type: VISIBLE_IDENTIFICATION_RULES_DRAWER, visible, tagForOpenDrawer });
  };
};

export const _loadEditApplicationStringDetail = (projectId, editApplicationStringKey, isImport) => {
  const data = {
    projectId,
    key: editApplicationStringKey,
  };
  return (dispatch) => {
    async("applicationStrings/getByProjectIdAndKey", "GET", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch({
          type: LOAD_EDIT_APPLICATION_STRING_DETAILS,
          data: response?.data,
          editApplicationStringKey,
          isImport,
        });
      }
      dispatch({ type: APPLICATION_STRING_LOADER, isLoading: false });
    });
  };
};
export const _deleteApplicationStringLocale = (deleteApplicationStringLocaleId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_APPLICATION_STRING_LOCALE, deleteApplicationStringLocaleId });
  };
};

export const _deleteApplicationString = (deleteApplicationStringKey, tab, pageState) => {
  return (dispatch, getState) => {
    let projectId = getState().ProjectsReducer.projectId;
    const data = {
      project: { id: projectId },
      listOfKeys: deleteApplicationStringKey || [],
      importFromXml: _.isEmpty(deleteApplicationStringKey) && tab === APPLICATION_STRING_TAB.IMPORTED ? 1 : 0,
      isCustom: _.isEmpty(deleteApplicationStringKey) && tab === APPLICATION_STRING_TAB.CUSTOM ? 1 : 0,
    };
    dispatch({ type: APPLICATION_STRING_LOADER, isLoading: true });
    async("applicationStrings/delete", "POST", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch(getApplicationStrings(projectId, "", "", pageState, "", "", "", tab));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: APPLICATION_STRING_LOADER, isLoading: false });
    });
  };
};

export const _addApplicationStringLocaleAndValue = () => {
  return (dispatch) => {
    dispatch({ type: ADD_APPLICATION_STRING_LOCALE_AND_VALUE });
  };
};

/////////////

export const _loadEditDeviceStringDetail = (projectId, editDeviceStringKey) => {
  const data = {
    projectId,
    key: editDeviceStringKey,
  };
  return (dispatch) => {
    async("deviceStrings/getByProjectIdAndKey", "GET", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch({
          type: LOAD_EDIT_DEVICE_STRING_DETAILS,
          data: response?.data,
          editDeviceStringKey: editDeviceStringKey,
        });
      }
      dispatch({ type: DEVICE_STRING_LOADER, isLoading: false });
    });
  };
};
export const _deleteDeviceStringLocale = (deleteDeviceStringLocaleId) => {
  return (dispatch) => {
    dispatch({ type: DELETE_DEVICE_STRING_LOCALE, deleteDeviceStringLocaleId });
  };
};

export const _deleteDeviceString = (deleteDeviceStringKey, filter, pageState) => {
  return (dispatch, getState) => {
    let projectId = getState().ProjectsReducer.projectId;
    const data = {
      project: { id: projectId },
      key: deleteDeviceStringKey,
    };
    async("deviceStrings/delete", "POST", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        dispatch(getDeviceStrings(projectId, "", "", pageState, "", "", "", filter));
      } else {
        showNotification(ERROR_TYPE, response.message);
      }
      dispatch({ type: DEVICE_STRING_LOADER, isLoading: false });
    });
  };
};

export const _addDeviceStringLocaleAndValue = () => {
  return (dispatch) => {
    dispatch({ type: ADD_DEVICE_STRING_LOCALE_AND_VALUE });
  };
};

export function _selectedDeviceStringValue(selectData, unSavedRecTestStep, unSavedRecElement) {
  let recElements = _.cloneDeep(unSavedRecTestStep?.recTestStepProperty?.recElements);
  unSavedRecElement = insertIntoRecElement(unSavedRecElement, "deviceStrings", selectData);
  if (selectData === undefined && !_.isEmpty(unSavedRecElement.recStepVerifyElementProperties)) {
    unSavedRecElement = insertIntoRecElement(
      unSavedRecElement,
      "recStepVerifyElementProperties",
      removeVerificationForApplicationStrings([...unSavedRecElement?.recStepVerifyElementProperties])
    );
  }
  recElements = updateRecElement(recElements, unSavedRecElement);
  unSavedRecTestStep.recTestStepProperty.recElements = recElements;
  return unSavedRecTestStep;
}

export function _getListOfLocalesForDeviceStrings() {
  return async (dispatch) => {
    async("locale/getListOfLocales", "GET").then((response) => {
      dispatch({ type: GET_LIST_OF_LOCALS, data: response?.data });
    });
  };
}

export function resetDeviceStringsData() {
  return (dispatch) => {
    dispatch({ type: RESET_DEVICE_STRING_DATA });
  };
}

export function _addDeviceStrings(key, value, locale, objectList, id) {
  return async (dispatch, getState) => {
    const object = objectList?.map((i) => {
      return {
        ...i,
        project: { id: getState().ProjectsReducer.projectId },
      };
    });
    const data = {
      id: id || 0,
      key,
      value: value || "",
      locale: locale || "",
      project: { id: getState().ProjectsReducer.projectId },
      objectList: object || [],
    };
    dispatch({ type: DEVICE_STRING_LOADER, isLoading: true });
    async("deviceStrings/save", "POST", data).then((response) => {
      if (response?.status === SUCCESS_STATUS) {
        showNotification(SUCCESS_TYPE, response?.message);
        dispatch(addDeviceStringsFormVisible(false));
      } else {
        showNotification(ERROR_TYPE, response?.message);
      }
      dispatch({ type: DEVICE_STRING_LOADER, isLoading: false });
      dispatch(resetDeviceStringsData());
    });
  };
}

export function _setTestDataSetProfileForPreview(testDataSetId, testDataSetName) {
  return (dispatch) => {
    dispatch({ type: SET_TEST_DATA_SET_PROFILE_FOR_PREVIEW, testDataSetId, testDataSetName });
  };
}

export function _setAppLocaleProfileForPreview(appLocale) {
  return (dispatch) => {
    dispatch({ type: SET_APP_LOCALE_FOR_PREVIEW, appLocale });
  };
}

export function _openPreviewSettingDrawer() {
  return (dispatch) => {
    dispatch({ type: OPEN_PREVIEW_SETTING_DRAWER });
  };
}

export function _closePreviewSettingDrawer() {
  return (dispatch) => {
    dispatch({ type: CLOSE_PREVIEW_SETTING_DRAWER });
  };
}

export function _generateScenarioSteps(data, selectedRecTestStep) {
  return async (dispatch, getState) => {
    const { serverDetail } = getState().RecordingReducer;
    dispatch({ type: SCENARIO_GENERATE_REQUEST_START, isLoadingStepId: selectedRecTestStep?.id });
    dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
    const response = await async("device/generateSteps", "POST", data, serverDetail.serverIp, undefined, dispatch);
    if (response.data === "") {
      dispatch(resetElement());
      dispatch(recordingStop());
      dispatch(resetEditRecTestStep());
         }
    if (response.status == "Error") {
      showNotification("error", response.message, "");
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
      return;
    }
  };
}

export function _generateScenarioStepsDebug(data) {
  return async (dispatch, getState) => {
    const { serverDetail } = getState().RecordingReducer;
    dispatch({ type: SCENARIO_GENERATE_DEBUG_REQUEST_START });
    dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.BLOCK));
    const response = await async("device/generateSteps", "POST", data, serverDetail.serverIp,undefined,dispatch);
      if(response.status=="Error"){
        showNotification("error", response.message, "");
        dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
        return;
      }
  };
}

export function _stopGenerateStepsFromAIModal(data) {
  return async (dispatch,getState) => {
    const { serverDetail } = getState().RecordingReducer;
    const response = await async("device/stopGenerateSteps", "POST", data, serverDetail.serverIp,undefined,dispatch);
    dispatch({type:SCENARIO_GENERATE_REQUEST_STOP})
    if(response.status=="Error"){
      showNotification("error", response.message, "");
      return
    }
    dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
    // dispatch({ type: SCENARIO_GENERATED_STEPS, data: response.data });
  };
}

export function _fetchGenerateStepStatus(data) {
  return async (dispatch,getState) => {
    const projectId = getState().ProjectsReducer.projectId;
    const { serverDetail } = getState().RecordingReducer;
    const { lastAIGeneratedStepIndex } = getState().RecTestStepCommonViewReducer;
    if(importStepFromJsonInProgress){
      return
    }
    importStepFromJsonInProgress=true
    const response = await async("device/generateStepsStatus", "POST", data, serverDetail.serverIp,undefined,dispatch);
    if(response.status=="Error"){
      showNotification("error", response.message, "");
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
      return
    }
    if(response.data){
      let newSteps = response.data.filter(item => item.step > lastAIGeneratedStepIndex && item.screenshot && item.pagesource)
      if(newSteps && newSteps.length>0){
        new Promise(resolve => resolve(dispatch({type:UPDATE_LAST_GENERATED_STEP_INDEX,value:newSteps[newSteps.length-1].step}))).then(resp => resp)
        console.log("newSteps",newSteps)
        new Promise(resolve => resolve(dispatch(importStepFromJson(newSteps)))).then(resp => resp)
      }
    }
    if(response.message=="DONE"){
      dispatch({ type: SCENARIO_GENERATED_STEPS, data: response.data });
      dispatch({type:SCENARIO_GENERATE_REQUEST_STOP})
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
      dispatch(hideAIGenerateStepsRow());
      dispatch(fetchTestData(projectId));
      importStepFromJsonInProgress=false
      return
    }
    dispatch({ type: SCENARIO_GENERATED_STEPS, data: response.data });
    importStepFromJsonInProgress=false

  };
}

export function _fetchGenerateStepDebugStatus(data) {
  return async (dispatch,getState) => {
    const { serverDetail } = getState().RecordingReducer;
    const response = await async("device/generateStepsStatus", "POST", data, serverDetail.serverIp,undefined,dispatch);
    if(response.status=="Error"){
      showNotification("error", response.message, "");
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
      return
    }
    if(response.message=="DONE"){
      dispatch({ type: SCENARIO_GENERATED_STEPS, data: response.data });
      dispatch({type:SCENARIO_GENERATE_DEBUG_REQUEST_STOP})
      dispatch(changeDeviceInteractionMode(DEVICE_INTERACTION_MODE.INTERACTIVE));
      return 
    }
    dispatch({ type: SCENARIO_GENERATED_STEPS, data: response.data });
  };
}
